export const INITIAL_STATE_TABLOIDE_DIGITALV2 = {
  new_imagem_produto: {},
  imagem_add_produto: null,
  id_tabloide: null,
  link_tabloide: null,
  tabloides: [],
  auto_message: {},
  produtos: [],
  paginas_fisico: [],
  paginas_fisico_all: {
    response: [],
    responseCategoria: {}
  },
  responseCategoria_index: {},
  tabloids_templates_fisico: [],
  tabloids_etiquetas: [],
  products_add: [],
  json: {},
  xlsxS3: '',
  editor: null,
  loading_tabloides: false,
  loading_post_file: false,
  loading_post_tabloide: false,
  loading_post_link: false,
  loadind_detalhes: false,
  loading_auto_message: false,
  loading_tabloide_fisico: false,
  loadind_tabloides_template_fisico: false,
  loadind_tabloides_etiquetas: false,
  produtosLength: 0,

  selectedShareableItem: [],
  isDistributionOpened: false,
  isLoadingAdsDistributionAdd: false,
  flyersToSelectWhenRedirectToOffer: null,

  adsDistribution: {
    currentTab: '1',
    currentChannel: null,
    selectedChannels: [],
    selectedChannelsFormData: [],
    validateForm: false,
    isValidatingForm: false
  },

  frequenceScheduledPosts: {
    amountRepeat: 1,
    monthlyDayPublish: null,
    typeRepeat: { children: 'Dias', value: 1 },
    hour: new Date(),
    weeklyDaysPublish: {},
    postsRepeat: 0,
    descriptions: ''
  },

  selectedScheduledItems: []
};

export default (state = INITIAL_STATE_TABLOIDE_DIGITALV2, action) => {
  let tmp = null;
  switch (action.type) {
    case 'SET_TEMPLATE':
      return {
        ...state,
        tabloide: {
          ...state.tabloide,
          json: action.payload,
          tabloide: { ...state.tabloide, json: action.payload }
        }
      };
    case 'SET_TABLOIDE':
      return { ...state, tabloide: action.payload };
    case 'SET_EDITOR':
      return { ...state, editor: action.payload };
    case 'SET_JSON':
      return {
        ...state,
        json: action.payload.json,
        id_tabloide: action.payload.id
      };
    case 'POST_TABLOIDE':
      return {
        ...state,
        id_tabloide: action.payload,
        loading_post_tabloide: false
      };
    case 'POST_TABLOIDE_LINK':
      return {
        ...state,
        link_tabloide: action.payload,
        loading_post_link: false
      };
    case 'GET_TABLOIDES':
      return { ...state, tabloides: action.payload, loading_tabloides: false };
    case 'AUTO_MESSAGE':
      return {
        ...state,
        auto_message: { ...state.auto_message, [action.payload.channel]: action.payload.message },
        loading_auto_message: false
      };
    case 'GET_DETALHES':
      return {
        ...state,
        tabloide: { ...state.tabloide, produtos: action.payload },
        loadind_detalhes: false
      };
    case 'GET_OFERTA_FISICO':
      return {
        ...state,
        paginas_fisico: action.payload
      };
    case 'GET_OFERTA_FISICO_ALL':
      return {
        ...state,
        paginas_fisico_all: action.payload
      };
    case 'GET_OFERTA_FISICO_ALL_ZERA_CATEGORIA_INDEX':
      tmp = {};
      if (action.payload.responseCategoria)
        Object.keys(action.payload.responseCategoria).forEach((element, index) => {
          tmp[element] = 2;
        });
      return {
        ...state,
        responseCategoria_index: { ...tmp }
      };
    case 'CHANGE_CATEGORIA_INDEX':
      tmp = { ...state.responseCategoria_index };
      tmp[action.payload] = tmp[action.payload] + 1;
      return {
        ...state,
        responseCategoria_index: { ...tmp }
      };
    case 'CHANGE_CATEGORIA_INDEX_SUBTRACT':
      tmp = { ...state.responseCategoria_index };
      tmp[action.payload] = tmp[action.payload] - 1;
      return {
        ...state,
        responseCategoria_index: { ...tmp }
      };
    case 'GET_IMAGEM_PRODUTO':
      return {
        ...state,
        imagem_add_produto: action.payload.produto ? action.payload.produto.LINK_S3 : null
      };
    case 'GET_TABLOIDS_TEMPLATES_FISICO':
      return {
        ...state,
        tabloids_templates_fisico: action.payload,
        loadind_tabloides_template_fisico: false
      };
    case 'GET_TABLOIDS_ETIQUETAS':
      return {
        ...state,
        tabloids_etiquetas: action.payload,
        loadind_tabloides_etiquetas: false
      };
    case 'GET_PRODUCT_ADD':
      return {
        ...state,
        products_add: action.payload
      };
    case 'CLEAN_PAGINAS':
      return {
        ...state,
        paginas_fisico: []
      };
    case 'CLEAN_PRODUCTS_FOR_ADD':
      return {
        ...state,
        products_add: []
      };
    case 'POST_FILE':
      return {
        ...state,
        produtos: action.payload.lista_produtos,
        xlsxS3: action.payload.arquivo_tabloide,
        loading_post_file: false
      };
    case 'SET_PRODUCTS_LENGTH':
      return { ...state, produtosLength: action.payload };
    case 'PRODUTO_IMAGEM':
      return { ...state, new_imagem_produto: { ...action.payload } };
    case 'LOAD_TABLOIDES':
      return { ...state, loading_tabloides: true };
    case 'LOAD_POST_FILE':
      return { ...state, loading_post_file: true };
    case 'LOAD_POST_TABLOIDE':
      return { ...state, loading_post_tabloide: true };
    case 'LOAD_DETALHES':
      return { ...state, loadind_detalhes: true };
    case 'LOAD_AUTO_MESSAGE':
      return { ...state, loading_auto_message: true };
    case 'LOAD_TABLOIDE_FISICO':
      return { ...state, loading_tabloide_fisico: true };
    case 'LOAD_TABLOIDES_TEMPLATE_FISICO':
      return { ...state, loadind_tabloides_template_fisico: true };
    case 'LOAD_TABLOIDES_ETIQUETAS':
      return { ...state, loadind_tabloides_etiquetas: true };
    case 'FINISH_TABLOIDE_FISICO':
      return { ...state, loading_tabloide_fisico: false };
    case 'LOAD_POST_TABLOIDE_LINK':
      return {
        ...state,
        link_tabloide: action.payload,
        loading_post_link: true
      };
    case 'SET_FLYERS_TO_SELECT_WHEN_REDIRECT_TO_OFFER':
      return { ...state, flyersToSelectWhenRedirectToOffer: action.payload };
    case 'SET_SELECTED_SHAREABLE_ITEM':
      return { ...state, selectedShareableItem: action.payload };
    case 'CLEAR_SELECTED_SHAREABLE_ITEM':
      return { ...state, selectedShareableItem: [] };
    case 'SET_IS_DISTRIBUTION_OPENED':
      return { ...state, isDistributionOpened: action.payload };
    case 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          selectedChannels: action.payload
        }
      };
    case 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          selectedChannelsFormData: action.payload
        }
      };
    case 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA_ADD':
      const compare = field => ('post' in field ? 'post' : 'text' in field ? 'text' : 'post');

      let stateWithoutCurrentForm = state.adsDistribution.selectedChannelsFormData.filter(
        currentFormData => {
          return currentFormData.name !== action.payload.name;
        }
      );

      // ADD MESMO POST OU TEXT PARA TODOS
      stateWithoutCurrentForm = stateWithoutCurrentForm.map(tmp => ({
        ...tmp,
        data: {
          ...tmp.data,
          [compare(tmp.data)]: action.payload.data[compare(action.payload.data)]
        }
      }));

      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          selectedChannelsFormData: [...stateWithoutCurrentForm, action.payload]
        }
      };
    case 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA_REMOVE':
      const stateWithoutCurrentFormRemove = state.adsDistribution.selectedChannelsFormData.filter(
        currentFormData => {
          return currentFormData.name !== action.payload;
        }
      );

      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          selectedChannelsFormData: [...stateWithoutCurrentFormRemove]
        }
      };
    case 'SET_ADS_DISTRIBUTION_CURRENT_TAB':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          currentTab: action.payload
        }
      };
    case 'SET_ADS_DISTRIBUTION_CURRENT_CHANNEL':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          currentChannel: action.payload
        }
      };
    case 'SET_ADS_DISTRIBUTION_VALIDATE_FORM':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          validateForm: action.payload
        }
      };
    case 'SET_ADS_DISTRIBUTION_IS_VALIDATING_FORM':
      return {
        ...state,
        adsDistribution: {
          ...state.adsDistribution,
          isValidatingForm: action.payload
        }
      };
    case 'SET_FREQUENCE_SCHEDULED_POSTS':
      return {
        ...state,
        frequenceScheduledPosts: {
          ...action.payload
        }
      };
    case 'CLEAR_FREQUENCE_SCHEDULED_POSTS':
      return {
        ...state,
        frequenceScheduledPosts: {
          amountRepeat: 1,
          monthlyDayPublish: null,
          typeRepeat: { children: 'Dias', value: 1 },
          hour: new Date(),
          weeklyDaysPublish: {},
          postsRepeat: 0
        }
      };
    case 'SET_SELECTED_POSTS_REPEAT': {
      return {
        ...state,
        frequenceScheduledPosts: {
          ...state.frequenceScheduledPosts,
          postsRepeat: action.payload
        }
      };
    }
    case 'CLEAR_SELECTED_POSTS_REPEAT': {
      return {
        ...state,
        frequenceScheduledPosts: {
          ...state.frequenceScheduledPosts,
          postsRepeat: 0
        }
      };
    }
    case 'SET_SELECTED_SCHEDULED_ITEMS': {
      return {
        ...state,
        selectedScheduledItems: [...action.payload]
      };
    }
    case 'CLEAR_SELECTED_SCHEDULED_ITEMS':
      return { ...state, selectedScheduledItems: [] };
    case 'CLEAR_ADS_DISTRIBUTION':
      return {
        ...state,
        adsDistribution: {
          currentTab: '1',
          currentChannel: null,
          selectedChannels: [],
          selectedChannelsFormData: [],
          validateForm: false
        }
      };
    case 'INIT_LOADING_ADS_DISTRIBUTION_ADD':
      return {
        ...state,
        isLoadingAdsDistributionAdd: true
      };
    case 'FINISH_LOADING_ADS_DISTRIBUTION_ADD':
      return {
        ...state,
        isLoadingAdsDistributionAdd: false
      };
    default:
      return state;
  }
};

