
type props={
  Open: boolean,
  onClose: any
}

export function  ReadyModels(props: props){
  return (<></>
  );
}

