import React, { Component, Fragment } from 'react';
import ItemAttachment from './ItemAttachment';

export default class Attachment extends Component {
  componentDidMount() {
    this.props.onAttachment(this.props.id);
  }
  componentWillUnmount() {
    const { listAttachment } = this.props;
    listAttachment.splice(0, listAttachment.length);
  }
  render() {
    const { listAttachment } = this.props;
    return (
      <Fragment>
        <section
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '32% 32% 32%',
            marginTop: '5vh',
          }}
        >
          {listAttachment.map((attachment, key) => (
            <ItemAttachment key={key} data={attachment} />
          ))}
        </section>
      </Fragment>
    );
  }
}
