import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Panel,
  ShowMore,
  ListReport,
  ReactDate,
  MyButton,
} from '../../../utils/components';
import { getBirthReportPeriod } from '../actions/actionsRelatorios';
import DateCustom from '../../../utils/helpers/Date';
import '../style/AniversariantePorPeriodo.css';
class RelatorioPorPeriodo extends Component {
  onChangeDate(state, value) {
    this.setState({ [state]: value });
  }
  searchDateReport() {
    const { initialDate, lastDate } = this.state;
    this.props.getBirthReportPeriod(
      {
        initialDate,
        lastDate,
      },
      'period',
      this.props.selectedStores,
    );
  }
  render() {
    return (
      <Panel noHeader>
        <section className="containerFiltro">
          <section className="containerData">
            <ReactDate
              placeholder="Início"
              onChange={date =>
                this.onChangeDate(
                  'initialDate',
                  new DateCustom(date, 'YYYY-MM-DD').getDate(),
                )
              }
            />
            <ReactDate
              placeholder="Fim"
              onChange={date =>
                this.onChangeDate(
                  'lastDate',
                  new DateCustom(date, 'YYYY-MM-DD').getDate(),
                )
              }
            />
          </section>
          <MyButton
            onClick={() => this.searchDateReport()}
            bsSize="sm"
            bsstyle="primary"
          >
            Buscar
          </MyButton>
        </section>
        <ShowMore
          loading={this.props.isLoading}
          data={this.props.listReportBirthDate}
          dataFormat={(row, data) => <ListReport row={row} data={data} />}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ ReportsReducer }) => ({ ...ReportsReducer });

const mapDispatchToProps = { getBirthReportPeriod };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatorioPorPeriodo);
