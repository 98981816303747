import React, { useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@material-ui/core';
import { MouseRounded, ExpandMoreRounded } from '@material-ui/icons';
import { FunnelChart } from '../../../../../utils/components';
import { BG_PRIMARY } from '../../../../../utils/helpers/Constants';
import useStyles from '../styles';

export default function ClickRate({ data = [] }) {
  const classes = useStyles(),
    [expanded, setExpanded] = useState(true);

  const expandedClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} variant="rounded">
              <MouseRounded />
            </Avatar>
          }
          action={
            <IconButton
              size="small"
              onClick={expandedClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreRounded />
            </IconButton>
          }
          title="Taxe de cliques"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={2} style={{ color: BG_PRIMARY }}>
              <Grid item xs={12}>
                <FunnelChart data={data} />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
