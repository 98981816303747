import { makeStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { connect } from 'react-redux';

import ImagePolotno from './ImagePolotno';
import Alert from '../../../../../utils/helpers/Alert';
import makeId from '../../../../../utils/helpers/makeId';
import { clearSelectedShareableItem, setSelectedShareableItem } from '../../../action';
import { useDefaultStyles } from '../../../style';

const _alert = new Alert();

const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    maxWidth: '180px',
    // maxHeight: '250px',
    marginRight: 16,
    marginBottom: 16,
    width: 'auto',
    height: 'auto',
    width: '16%',
    '&:hover': {
      // background: 'rgba(255, 255, 255, 0.5)',
      // opacity: 0.5,
      '& $cardCheckbox': {
        visibility: 'visible'
      },
      '& $newMoreOptionsProductCard': {
        visibility: 'visible'
      }
    },

    [theme.breakpoints.down('lg')]: {
      'max-width': '180px'
    },
    [theme.breakpoints.down('md')]: {
      'max-width': '140px'
    },
    [theme.breakpoints.down('sm')]: {
      'max-width': '100px'
    },
    [theme.breakpoints.down('xs')]: {
      'max-width': '60px'
    }
  },
  card: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent'
  },
  cardCheckbox: {
    padding: '0px',
    backgroundColor: 'black',
    color: '#2813AD !important',
    position: 'absolute !important',
    visibility: 'hidden',
    zIndex: 100
  },
  selectedCheckbox: {
    visibility: 'visible !important'
  },
  selectedCard: {
    // border: '2px solid #127FFF !important',
    border: '2px solid #2813AD !important',
    opacity: 0.5
  },
  moreOptionsProductCard: {
    // marginLeft: '200px',
    color: '#36325e',
    display: 'inline-box',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    marginTop: '9px',
    marginLeft: '154px',
    visibility: 'hidden',
    zIndex: 2,
    width: '24px',
    height: '24px',
    border: 'solid black 1px',
    borderRadius: '4px',
    background: '#000000',
    opacity: 0.1
  },
  newMoreOptionsProductCard: {
    color: '#2813AD !important',
    display: 'inline-box',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    marginTop: '9px',
    marginLeft: '150px',
    visibility: 'hidden',
    zIndex: 2,
    width: '24px',
    height: '24px',
    border: '#2813AD solid 1px',
    borderRadius: '4px',
    background: '#2813AD',
    // opacity: 0.1

    [theme.breakpoints.down('md')]: {
      marginLeft: 110
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 70
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 35
    }
  },
  icoMoreOptionsProductCard1: {
    display: 'inline',
    border: 'solid 1px',
    width: '5px',
    height: '5px',
    background: '#FFFFFF',
    top: '3px',
    left: '8.7px',
    position: 'absolute',
    borderRadius: '3px'
  },
  icoMoreOptionsProductCard2: {
    display: 'inline',
    border: 'solid 1px',
    width: '5px',
    height: '5px',
    background: '#FFFFFF',
    top: '8.7px',
    left: '8.7px',
    position: 'absolute',
    borderRadius: '3px'
  },
  icoMoreOptionsProductCard3: {
    display: 'inline',
    border: 'solid 1px',
    width: '5px',
    height: '5px',
    background: '#FFFFFF',
    left: '8.7px',
    bottom: '3px',
    position: 'absolute',
    borderRadius: '3px'
  }
}));

function ProductCard({
  item,
  type,
  disabled,
  menuOut,
  onClickImage,
  onClickMenu,
  isSelected = true,
  selectedShareableItem,
  setSelectedShareableItem,
  isGeracao1,
  onReprocessar,
  design,
  ...props
}) {
  const classes = useStyles();
  const magnumClasses = useDefaultStyles();

  const [viewImage, setViewImage] = useState(true);
  const [onDivOver, setOnDivOver] = useState(false);

  const hasSelectedThisCard = cardId =>
    selectedShareableItem.some(card => card.id === cardId) ? true : false;

  const handleToggleCheckbox = (checked, mediaData) => {
    const cardData = JSON.parse(mediaData);
    const { id: postID } = cardData;

    if (checked && hasSelectedThisCard(postID) === false) {
      if (selectedShareableItem.length <= 10) {
        setSelectedShareableItem([...selectedShareableItem, cardData]);
      } else {
        /**  _alert.alertWarning(
          'Importante!',
          'A seleção de Posts e Vídeo tem um limite máximo de até 10 Itens por Distribuição.'
        )*/
        setSelectedShareableItem([...selectedShareableItem, cardData]);
      }
    } else {
      setSelectedShareableItem(
        selectedShareableItem.filter(currentItemData =>
          currentItemData.id !== postID ? true : false
        )
      );
    }
  };

  return (
    <div
      key={item.id + '-' + item.name}
      aria-disabled={disabled}
      disabled={disabled}
      className={classes.checkboxContainer}
      title={item.name ? item.name + ' - id: ' + item.id : ''}
      style={{ position: 'relative' }}
      onMouseOver={() => setOnDivOver(true)}
      onMouseOut={() => setOnDivOver(false)}
    >
      <div>
        {isGeracao1 && item.link && !item.link_html && (
          <button onClick={() => onReprocessar(item.id)}>Reprocessar</button>
        )}
        {isGeracao1 && !item.link && !item.link_html && <span>carregando...</span>}
      </div>
      {isSelected && (
        // <Checkbox
        //   className={[
        //     classes.cardCheckbox,
        //     'checkboxItem',
        //     hasSelectedThisCard(item.id) ? classes.selectedCheckbox : ''
        //   ].join(' ')}
        //   sx={{ '& .MuiSvgIcon-root': { fontSize: 34 } }}
        //   aria-label="Global Card Checkbox"
        //   id={`magnum-item-selected-${item.id}`}
        //   value={JSON.stringify(item)}
        //   checked={hasSelectedThisCard(item.id)}
        //   onChange={function ({ target }) {
        //     console.log({ target: [target.checked, target.value] });
        //     handleToggleCheckbox(target.checked, target.value);
        //   }}
        // />
        <div
          id={`magnum-item-selected-${item.id}`}
          className={[
            classes.cardCheckbox,
            'checkboxItem',
            hasSelectedThisCard(item.id) ? classes.selectedCheckbox : ''
          ].join(' ')}
          style={{
            position: 'absolute',
            top: 6,
            left: 5,
            height: 30,
            width: 30,
            backgroundColor: hasSelectedThisCard(item.id) ? '#2813AD' : 'white',
            zIndex: 1,
            borderRadius: 4,
            // border: hasSelectedThisCard(item.id) ? '' : '3px solid #2813AD',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => {
            handleToggleCheckbox(!hasSelectedThisCard(item.id), JSON.stringify(item));
          }}
        >
          {hasSelectedThisCard(item.id) && <CheckIcon style={{ color: 'white', margin: '' }} />}
        </div>
      )}
      {!menuOut && !(hasSelectedThisCard(item.id) && selectedShareableItem?.length > 1) && (
        <span className={classes.newMoreOptionsProductCard} onClick={e => onClickMenu(e, item)}>
          <div className={classes.icoMoreOptionsProductCard1}></div>
          <div className={classes.icoMoreOptionsProductCard2}></div>
          <div className={classes.icoMoreOptionsProductCard3}></div>
        </span>
      )}
      <div
        className={magnumClasses.gridCard}
        onClick={() => onClickImage(item)}
        style={{ marginRight: 16 }}
      >
        <div
          className={
            type === 'Tv - Indoor'
              ? magnumClasses.gridMediaQueryHeight102
              : type === 'Splash 22x20'
                ? magnumClasses.gridMediaQueryHeight164
                : type === 'Cartaz A3 - Paisagem' || type === 'Cartaz A4 - Paisagem'
                  ? magnumClasses.gridMediaQueryHeight128
                  : type === 'Post' || type === 'Video'
                    ? magnumClasses.gridMediaQueryHeight180
                    : magnumClasses.gridMediaQueryHeight250
          }
        >
          {
            (item.preview_url || item.image_url) ?
              <img
                className={[
                  magnumClasses.gridImage,
                  isSelected && hasSelectedThisCard(item.id) ? classes.selectedCard : '',
                  onDivOver ? magnumClasses.gridImageOver : ''
                ].join(' ')}
                crossOrigin="anonymous"
                src={(item.preview_url || item.image_url) + '?' + makeId(6)}
                title={item.name ? item.name + ' - id: ' + item.id : ''}
                loading="lazy"
                style={{ padding: 1 }}
              />
              :
              <ImagePolotno
                design={design}
                style={{ display: "initial" }}
                className={[
                  magnumClasses.gridImage,
                  isSelected && hasSelectedThisCard(item.id) ? classes.selectedCard : '',
                  onDivOver ? magnumClasses.gridImageOver : ''
                ].join(' ')}
                alt={`art-${item.id}`}
                beforeLoad={() => {
                  setViewImage(false);
                }}
                afterLoad={() => {
                  setViewImage(true);
                }}
                onImageLoad={setViewImage}
              />
          }

        </div>
      </div>
      {menuOut &&
        menuOut.map((menu, index) => (
          <div key={index} style={{ margin: 15 }}>
            <div
              className={magnumClasses.gridMoreOptions}
              style={{ marginTop: 6 }}
              onClick={menu.onClick}
            >
              {menu.icon({
                style: {
                  marginRight: '5px'
                }
              })}
              {menu.text}
            </div>
          </div>
        ))}
    </div>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  setSelectedShareableItem,
  clearSelectedShareableItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
