import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: '#616161 !important',
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    display: 'flex !important',
    justifyContent: 'space-evenly !important',
    alignItems: 'center !important',
    borderRadius: '50% !important',
    height: '1.75rem !important',
    width: '1.75rem !important',
    border: '1px solid #D3D3D3 !important',
    lineHeight: '1.218rem !important',
    textAlign: 'center'
  },
  iconChecked: {
    color: 'white !important',
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    display: 'flex !important',
    justifyContent: 'space-evenly !important',
    alignItems: 'center !important',
    borderRadius: '50% !important',
    height: '1.75rem !important',
    width: '1.75rem !important',
    lineHeight: '1.218rem !important',
    background: '#2813AD !important',
    textAlign: 'center'
  },
  gridGlobal: {
    display: 'flex !important',
    justifyContent: 'start !important',
    alignItems: 'center !important',
    gap: '1.5rem !important'
  },
  titulo: {
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    lineHeight: '1.218rem !important',
    color: '#1D1D1D !important'
  },
  checkBoxContainer: {
    '&.MuiButtonBase-root, &.MuiCheckbox-root': {
      marginRight: '16px',
      padding: '0',
      background: '#FFF'
    }
  }
}));

