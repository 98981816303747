import moment from 'moment';

import { SelectedShareableItemProps } from '../../../../modules/TabloideDigitalV2/reducer/types';
import { API_SMS_V2 } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';

const CLICK_TRACKER = ' {{clickTracker}}';

export type SelectedContactType =
  | {
      cod: 'all';
      label: 'todos';
    }
  | {
      cod: 'ca';
      label: 'contatos ativos';
    }
  | {
      cod: 'ci';
      label: 'contatos inativos';
    }
  | {
      cod: 'cop';
      label: 'somente número em cópia';
    };

type Instance = {
  id: string;
  ativo: string;
  codigo: string;
  id_empresa: number;
  id_loja: number;
  nome: string;
  own_number: string;
  shared_instancia: number;
  status: {
    connected: boolean;
  };
  tipo_instancia: number;
};

type DistributionSmsProps = {
  offerId: number;
  offerName: string;
  numbersInCopy: string;
  scheduleDate: string;
  selectedInstance: Instance;
  selectedContactType: SelectedContactType;
  text: string;
  selectedItems: SelectedShareableItemProps;
  credits: any;
};

/**
 * Check profile_type from contactType and returns one of the accepted values
 * @param selectedContactType
 * @returns 0 | 1 | 2
 */
function verifyProfileType(selectedContactType: SelectedContactType) {
  switch (selectedContactType.cod) {
    // ci = contatos inativos AND 0 to profile_type
    case 'ci':
      return 0;

    // ca = contatos ativos AND 1 to profile_type
    case 'ca':
      return 1;

    // all = contatos inativos e ativos AND 2 to profile_type
    case 'all':
      return 2;

    /**
     * The selectedContactType.cod could still be cop
     *
     * cop = only for numbers in copy
     *
     * But for that case, there is boolean value 'onlyInCopy' which limits sending
     * to onlyInCopy contacts and disregards the profile_type
     */
    default:
      return 0;
  }
}

const distributeSms = async ({
  offerId,
  offerName,
  numbersInCopy,
  text,
  scheduleDate,
  selectedItems,
  selectedInstance,
  selectedContactType,
  credits
}: DistributionSmsProps) => {
  let result: any[] = [];

  for (let item of selectedItems) {
    const isVideo = item.link ? item.link.endsWith('.mp4') : false;

    let resultTmp = await api(
      `${API_SMS_V2}store`,
      {
        idInstancia: selectedInstance.id,
        nomeCampanha: offerName,
        message: text + CLICK_TRACKER,
        dataInicio: scheduleDate
          ? moment(scheduleDate).format('DD/MM/YYYY HH:mm')
          : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
        ...( item.preview_url && {preview_url: item.preview_url} ),
        whatsapp_midia: true,
        whatsapp_url: isVideo ? item.link : item.image_url,
        id_propaganda: offerId,
        id_propaganda_imagem: item.id,
        custoCampanha: credits * 1000,
        totalLimit: credits * 1000, // não é mais usado no backend
        redirectTracker: isVideo ? item.link : item.image_url,
        origin: 'Estúdio',
        profile_type: verifyProfileType(selectedContactType),
        onlyInCopy: selectedContactType.cod === 'cop' ? true : false,
        numbersCopy: numbersInCopy && numbersInCopy.replace(/\s/g, '').split(',')
      },
      'POST',
      null,
      null,
      false
    );

    result.push(resultTmp);
  }

  return result;
};

export { distributeSms };
