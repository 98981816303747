import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '../../../../utils/components/CustomTable';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

function Providers() {
  const classes = useStyles();

  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function getProviders() {
    try {
      setLoading(true);
      const response = await Fetch(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/providers`
      );

      setProviders(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box pt={4}>
            <Typography variant="h3" noWrap>
              Fornecedores
            </Typography>
          </Box>
        </Grid>

        <Grid item container justify="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              history.push('/trade/provider/new');
            }}
          >
            Adicionar Fornecedor
          </Button>
        </Grid>

        <Grid item>
          <Box pt={4} pb={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              <Table
                search
                actions={[
                  {
                    icon: 'exit_to_app',
                    tooltip: 'Visualizar Fornecedor',
                    onClick: (event, rowData) => {
                      history.push(`/trade/provider/${rowData.id}`);
                    },
                  },
                  {
                    icon: 'edit',
                    tooltip: 'Editar Fornecedor',
                    onClick: (event, rowData) => {
                      history.push(`/trade/provider/edit/${rowData.id}`);
                    },
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover Empresa',
                    onClick: (event, rowData) => {},
                  },
                ]}
                columns={[
                  { field: 'id', title: 'ID' },
                  { field: 'cnpj', title: 'CNPJ' },
                  { field: 'name', title: 'Razão Social' },
                  { field: 'trade_name', title: 'Nome Fantasia' },
                  // { field: 'created_at', title: 'Criado em' },
                  // { field: 'updated_at', title: 'Razão Social' },
                ]}
                isLoading={loading}
                data={providers}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Providers;
