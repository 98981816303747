import React, { useState } from 'react';
import { connect } from 'react-redux';

//Using Components
import { Stepper, Step, StepButton } from '@material-ui/core';
import { Modal, MyButton } from '../../../utils/components';
import TabAudiencia from './Tabs/TabAudiencia';
import TabRules from './Tabs/TabRules';
import TabReview from './Tabs/TabReview';

// Styles
import useStyles from './styles';

const steps = ['Selecione uma audiência', 'Regras', 'Review'];

function ModalEnrichment(props) {
  const classes = useStyles(),
    [activeStep, setActiveStep] = useState(0);

  function ComponentFooter() {
    return (
      <div className={classes.footerModal}>
        {activeStep > 0 ? (
          <MyButton
            onClick={() => setActiveStep(activeStep - 1)}
            color="default"
          >
            Voltar
          </MyButton>
        ) : (
          <span />
        )}
        {activeStep === 2 ? (
          <MyButton
            onClick={() => {
              props.onSave();
              props.onClose();
            }}
          >
            Enriquecer
          </MyButton>
        ) : (
          <MyButton onClick={() => setActiveStep(activeStep + 1)}>
            Próximo
          </MyButton>
        )}
      </div>
    );
  }

  return (
    <Modal
      size="sm"
      showModal={props.open}
      onClose={props.onClose}
      header="Enriquecer"
      footer={<ComponentFooter />}
    >
      <Stepper disabled nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepButton onClick={() => setActiveStep(index)}>{step}</StepButton>
          </Step>
        ))}
      </Stepper>
      <section
        style={{ padding: '1rem 5rem 10rem', display: 'grid', gap: '2rem' }}
      >
        {activeStep === 0 && (
          <TabAudiencia
            price={props.price}
            prospeccao={props.prospeccao}
            enrichment={props.enrichment}
            onChange={props.onChange}
            onSelectAudiencia={idProspeccao =>
              props.onSelectAudiencia(idProspeccao)
            }
          />
        )}
        {activeStep === 1 && (
          <TabRules enrichment={props.enrichment} onChange={props.onChange} />
        )}
        {activeStep === 2 && (
          <TabReview enrichment={props.enrichment} price={props.price} />
        )}
      </section>
    </Modal>
  );
}
export default connect(null, null)(ModalEnrichment);
