import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputBase,
  Link,
  List,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Chat as ChatIcon,
  CheckRounded as CheckIcon,
  CloseRounded as CloseIcon,
  EditRounded as EditIcon,
  FilterListRounded as FilterIcon,
  GroupRounded as GroupIcon,
  MicRounded as MicRoundedIcon,
  PersonAdd as PersonAddIcon,
  FiberManualRecord as RecordingIcon,
  Search as SearchIcon,
  SendRounded as SendIcon
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import GetBlobDuration from 'get-blob-duration';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactMic } from 'react-mic';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DSAWS from '../../../utils/helpers/DSAWS';
import { asyncForEach } from '../../../utils/helpers/Functions';
import getInitials from '../../../utils/helpers/getInitials';
import { Celular2 } from '../../../utils/helpers/Masks';
import { ativarContatos } from '../../Chatbot/actions';
import useInterval from '../../Chatbot/utils/UseInterval';
import {
  closeConnection,
  createContact,
  endTicket,
  getUsers,
  isConnected,
  listChats,
  listContacts,
  listMessages,
  openConnection,
  persistConnection,
  readMessage,
  receiveMessage,
  sendAudio,
  sendImage,
  sendText,
  sendVideo,
  setAttendant,
  transferTicket,
  updateContact
} from '../actions/actions';
import useStyles from '../styles';
import '../styles/rootMessageList.css';
import DialogAgents from './DialogAgents';
import DialogContact from './DialogContacts';
import DialogEndTicket from './DialogEndTicket';
import DialogMedia from './DialogMedia';
import InstanceSelect from './InstanceSelect';
import ItemChat, { ItemChatEmpty } from './ItemChat';
import MenuEmoji from './MenuEmoji';
import MenuFiles from './MenuFiles';
import MessageList from './MessageList';
import ModalContacts from './ModalContacts';
import Notification from './Notification';
import PreviewMidia from './PreviewMidia';

const _audio = new Audio(
  'https://datasalesio-imagens.s3.amazonaws.com/audio/notification-open-chat.ogg'
);

const typesFilter = [
  { value: 0, label: 'Conversas' },
  { value: 1, label: 'Minhas conversas' },
  { value: 2, label: 'Sem atendentes' },
  { value: 3, label: 'Conversas em grupos' },
  { value: 4, label: 'Conversas privadas' }
];

/*
0 - Listar todas as conversas
1 - Vai listar somente as conversas que estão atribuidas ao meu usuario
2 - Vai listar conversas sem atendentes
3 - Listar todos os grupos
4 - Listar todas as conversas privadas
*/

function InputBaseGlobal({ sendMessage, uploadMidia, setMessageGlobal, messageGlobal }) {
  const classes = useStyles();
  const inputRef = useRef();
  const [selectionMessage, setSelectionMessage] = useState({
    start: 0,
    end: 0
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (message !== messageGlobal) {
      setMessage(messageGlobal);
    }
  }, [messageGlobal]);

  function onEmoji(emoji) {
    if (Boolean(message)) {
      const newText = `${message.slice(0, selectionMessage.start)}${emoji}${message.slice(
        selectionMessage.end
      )}`;
      setMessage(newText);
    } else {
      setMessage(emoji);
    }
  }

  function onSelectMessage() {
    const newPosition = {
      start: inputRef.current.selectionStart,
      end: inputRef.current.selectionEnd
    };
    setSelectionMessage(newPosition);
  }

  return (
    <>
      <MenuEmoji onChange={onEmoji} />
      <InputBase
        // onKeyPress={({ key }) => !uploadMidia && key === 'Enter' && sendMessage(message)}
        onKeyDown={({ keyCode, ctrlKey, shiftKey, altKey }) => {
          if (keyCode === 13 && !(ctrlKey || shiftKey || altKey) && !uploadMidia) {
            sendMessage(message);
            setMessage('');
          }
        }}
        onChange={({ target: { value } }) => value !== '\n' && setMessage(value)}
        inputRef={inputRef}
        onSelect={onSelectMessage}
        className={classes.inputMessage}
        value={message}
        placeholder="Digite uma mensagem"
        fullWidth
        multiline
        onBlur={() => setMessageGlobal(message)}
      />
    </>
  );
}

function Chat(props) {
  const companyInfo = JSON.parse(localStorage.getItem('companyId')),
    userId = JSON.parse(localStorage.getItem('userId')),
    classes = useStyles(),
    history = useHistory(),
    { enqueueSnackbar, closeSnackbar } = useSnackbar(),
    [socket, setSocket] = useState({}),
    [typeChat, setTypeChat] = useState(0),
    [contacts, setContacts] = useState([]),
    [imutableChats, setImutableChats] = useState([]),
    [chats, setChats] = useState({ continuation: false, list: [] }),
    [messages, setMessages] = useState({
      continuation: false,
      lastId: null,
      list: []
    }),
    [withoutConnection, setWithoutConnection] = useState(false),
    [loadingChats, setLoadingChats] = useState(false),
    [loadingContacts, setLoadingContacts] = useState(false),
    [loadingMessages, setLoadingMessages] = useState(false),
    [loadingEndChat, setLoadingEndChat] = useState(false),
    [statusConnectionWhatsapp, setStatusConnectionWhatsapp] = useState(true),
    [connectedWS, setConnectedWS] = useState(0),
    [modalContacts, setModalContacts] = useState(false),
    [dialogContact, setDialogContact] = useState(false),
    [editContact, setEditContact] = useState(false),
    [dialogEditChat, setDialogAgents] = useState(false),
    [dialogEndTicket, setDialogEndTicket] = useState(false),
    [dialogMidia, setDialogMidia] = useState(null),
    [contact, setContact] = useState({ name_chat: '', number_chat: '' }),
    [instance, setInstance] = useState({
      id: null,
      name: null,
      shared_instance: false
    }),
    [chatActive, setChatActive] = useState({}),
    [openMessageVoice, setOpenMessageVoice] = useState(false),
    [sendVoiceMessage, setSendVoiceMessage] = useState(false),
    [uploadMidia, setUploadMidia] = useState(false),
    [message, setMessage] = useState(''),
    [voiceMessage, setVoiceMessage] = useState({ uri: '', blob: '' }),
    [durationVoiceMessage, setDurationVoiceMessage] = useState(0),
    [previewMidia, setPreviewMidia] = useState(false),
    [fileMidia, setFileMidia] = useState([]),
    [showBtnEditContact, setShowBtnEditContact] = useState(false),
    [infiniteScrollChats, setInfiniteScrollChats] = useState(1);

  useEffect(() => {
    openConnection((status, state, _socket) => {
      setConnectedWS(state);
      setSocket(_socket);
      props.getUsers();
    });
    return () => {
      closeConnection();
    };
  }, []);

  useEffect(() => {
    if (connectedWS === 1) {
      async function verifyConnection() {
        const connection = await isConnected(instance.id);
        setStatusConnectionWhatsapp(connection.content.connected);
      }

      verifyConnection();
    }
  }, [instance]);

  useEffect(() => {
    if (Boolean(voiceMessage.uri) && sendVoiceMessage) {
      _sendVoiceMessage();
    }
  }, [voiceMessage.uri, sendVoiceMessage]);

  useEffect(() => {
    if (connectedWS === 1 && statusConnectionWhatsapp) {
      _listChats();
      _listContacts();
    }
  }, [instance, statusConnectionWhatsapp]);

  useEffect(() => {
    if (connectedWS === 1 && statusConnectionWhatsapp && contact.number_chat) {
      _listMessages();
    }
  }, [contact.number_chat]);

  useInterval(async () => {
    if (connectedWS === 1 && !statusConnectionWhatsapp) {
      const connection = await isConnected(instance.id);
      setStatusConnectionWhatsapp(connection.content.connected);
    }
  }, [20000]);

  useInterval(() => {
    persistConnection();
  }, [2 * 60000]);

  function onChangeInstance(_instance) {
    setContacts([]);
    setImutableChats([]);
    setChats({ continuation: false, list: [] });
    setMessages({
      continuation: false,
      lastId: null,
      list: []
    });
    setContact({ name_chat: '', number_chat: '' });
    setChatActive({});
    setStatusConnectionWhatsapp(true);
    setInstance({
      id: _instance.id,
      name: _instance.nome,
      shared_instance: _instance.shared_instance
    });
  }

  async function _sendMessage(message, inFirst = true) {
    if (message) {
      const tempChat = imutableChats,
        indexChat = tempChat.findIndex(chat => chat.ID === message.id_chat);
      let newChat = {};
      if (indexChat > -1) {
        if (inFirst) {
          const oldChat = tempChat.splice(indexChat, 1);
          newChat = {
            ...oldChat[0],
            NAME: message.name_chat ? message.name_chat : oldChat[0].NAME,
            UNREAD: message.unread
              ? oldChat[0].UNREAD + 1
              : message.unreadZero
              ? 0
              : oldChat[0].UNREAD,
            MESSAGE: message.message,
            MESSAGE_DATE: message.data_envio || new Date(),
            TYPE: message.type,
            SHORT_TICKET: message.ticket_short,
            TICKET: message.ticket_id,
            MANUAL_SERVICE: message.manual_service,
            USER: message.user_id,
            USERNAME: message.username ? message.username : oldChat[0].USERNAME,
            ACTIVE: 1,
            PROFILE_THUMBNAIL: message.profile_thumbnail || oldChat[0].PROFILE_THUMBNAIL
          };
          tempChat.unshift(newChat);
        } else {
          tempChat[indexChat] = {
            ...tempChat[indexChat],
            NAME: message.name_chat ? message.name_chat : tempChat[indexChat].NAME,
            UNREAD: message.unreadZero ? 0 : tempChat[indexChat].UNREAD,
            SHORT_TICKET: message.ticket_short,
            TICKET: message.ticket_id,
            MANUAL_SERVICE: message.manual_service,
            USER: message.user_id,
            USERNAME: message.username ? message.username : tempChat[indexChat].USERNAME,
            ACTIVE: 1,
            PROFILE_THUMBNAIL: message.profile_thumbnail || tempChat[indexChat].PROFILE_THUMBNAIL
          };
        }
      } else {
        newChat = {
          ID: message.id_chat,
          MESSAGE: message.message,
          MESSAGE_DATE: message.data_envio,
          NAME: message.name_chat,
          NUMBER: message.phone,
          TYPE: message.type,
          SHORT_TICKET: message.ticket_short,
          TICKET: message.ticket_id,
          MANUAL_SERVICE: message.manual_service,
          UNREAD: 0,
          USER: message.user_id,
          USERNAME: message.username,
          ACTIVE: 1,
          PROFILE_THUMBNAIL: message.profile_thumbnail
        };
        tempChat.unshift(newChat);
      }
      setChats({ ...chats, list: tempChat });
      setImutableChats(tempChat);
    }
  }

  async function _receiveMessage(message) {
    if (message) {
      const { content } = await receiveMessage(message);
      if (content.message.id_instancia === instance.id) {
        const updateChat = {
          id_chat: content.chat.id_chat,
          name_chat: content.chat.name_chat,
          ticket_id: content.chat.ticket_id,
          ticket_short: content.chat.ticket_short,
          user_id: content.chat.user_id,
          message: content.message.message,
          type: content.message.type,
          phone: content.message.phone,
          data_envio: content.message.data_envio,
          manual_service: content.chat.manual_service,
          profile_thumbnail: content.chat.profile_thumbnail,
          unread: true
        };
        if (typeChat === 0) {
          _sendMessage(updateChat);
        } else if (
          typeChat === 1 &&
          updateChat.user_id === userId &&
          Boolean(updateChat.ticket_id) &&
          Boolean(updateChat.manual_service)
        ) {
          _sendMessage(updateChat);
        } else if (
          typeChat === 2 &&
          Boolean(updateChat.ticket_id) &&
          Boolean(updateChat.manual_service) &&
          !Boolean(updateChat.user_id)
        ) {
          _sendMessage(updateChat);
        }

        if (chatActive.ID === content.chat.id_chat) {
          setChatActive({
            ...chatActive,
            SHORT_TICKET: content.chat.ticket_short,
            TICKET: content.chat.ticket_id,
            MANUAL_SERVICE: content.chat.manual_service
          });
        }
        if (chatActive.NUMBER === content.message.phone) {
          const newListMessages = [...messages.list, content.message];
          setMessages({
            ...messages,
            list: newListMessages.sort((a, b) => new Date(a.data_envio) - new Date(b.data_envio))
          });
          await _audio.play();
        } else if (!Boolean(content.message.i_send)) {
          const chat = {
            ID: content.chat.id_chat,
            IS_GROUP: content.chat.is_group,
            MESSAGE: content.message.message,
            MESSAGE_DATE: content.message.data_envio,
            NAME: content.chat.name_chat,
            NUMBER: content.message.phone,
            PROFILE_THUMBNAIL: content.chat.profile_thumbnail,
            TYPE: content.message.type,
            USER: content.chat.user_id,
            SHORT_TICKET: content.chat.ticket_short,
            TICKET: content.chat.ticket_id,
            MANUAL_SERVICE: content.chat.manual_service
          };

          enqueueSnackbar('', {
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            autoHideDuration: 6000,
            onClick: () => {
              selectChat(chat);
              closeSnackbar();
            },
            content: () => Notification(content)
          });
        }
      }
    }
  }

  async function _listContacts() {
    try {
      setLoadingContacts(true);
      const { content } = await listContacts(instance.id);
      setContacts(content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingContacts(false);
    }
  }

  async function _listChats(myChats, openChats, complete = true) {
    try {
      setLoadingChats(true);

      const { content } = await listChats(instance.id, myChats, openChats);

      setWithoutConnection(Boolean(content.alert));
      if (complete) {
        setChats({
          continuation: content.continuation,
          list: [...chats.list, ...content.chats]
        });
        setImutableChats([...imutableChats, ...content.chats]);
      } else {
        setChats({
          continuation: content.continuation,
          list: content.chats
        });
        setImutableChats(content.chats);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingChats(false);
    }
  }

  async function _listMessages(showLoader = true) {
    try {
      if (showLoader) {
        setLoadingMessages(true);
      }
      const { content } = await listMessages(instance.id, contact.number_chat, messages.lastId);
      setWithoutConnection(Boolean(content.alert));
      setMessages({
        lastId: content.lastId,
        continuation: content.continuation,
        list: [...content.messages, ...messages.list].sort(
          (a, b) => new Date(a.data_envio) - new Date(b.data_envio)
        )
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingMessages(false);
    }
  }

  async function _createContact(contact) {
    try {
      const requestContact = {
        cellphone: contact.numero,
        instance_id: instance.id,
        name: contact.nome_contato,
        active: 1
      };

      await createContact(requestContact);
      enqueueSnackbar('Contato criado com sucesso !', {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
        variant: 'success'
      });
      _listContacts();
    } catch (err) {
      console.log('ERRO CREATE CONTACT => ', err);
    } finally {
      setDialogContact(false);
      setEditContact(false);
    }
  }

  async function _updateContact(_contact) {
    try {
      const requestContact = {
          contact_id: contact.id,
          cellphone: _contact.numero,
          instance_id: instance.id,
          name: _contact.nome_contato,
          active: 1
        },
        updateChat = {
          phone: requestContact.cellphone,
          id_chat: chatActive.ID,
          ticket_id: chatActive.TICKET,
          user_id: chatActive.USER,
          message: chatActive.MESSAGE,
          unread: chatActive.UNREAD,
          unreadZero: chatActive.unreadZero,
          data_envio: chatActive.MESSAGE_DATE,
          type: chatActive.TYPE,
          ticket_short: chatActive.SHORT_TICKET,
          manual_service: chatActive.MANUAL_SERVICE,
          profile_thumbnail: chatActive.PROFILE_THUMBNAIL,
          name_chat: requestContact.name,
          username: chatActive.USERNAME
        };
      await updateContact(requestContact);
      _sendMessage(updateChat, false);
      setChatActive({
        ...chatActive,
        NAME: requestContact.name,
        NUMBER: requestContact.cellphone
      });
      enqueueSnackbar('Contato editado com sucesso !', {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
        variant: 'success'
      });
      _listContacts();
    } catch (err) {
      console.log('ERRO CREATE CONTACT => ', err);
    } finally {
      setDialogContact(false);
      setEditContact(false);
    }
  }

  async function _readMessages(chat) {
    try {
      let newChat = {
        phone: chat.NUMBER,
        id_chat: chat.ID,
        ticket_id: chat.TICKET,
        user_id: chat.USER,
        message: chat.MESSAGE,
        unread: chat.UNREAD,
        unreadZero: chat.unreadZero,
        data_envio: chat.MESSAGE_DATE,
        type: chat.TYPE,
        ticket_short: chat.SHORT_TICKET,
        manual_service: chat.MANUAL_SERVICE,
        profile_thumbnail: chat.PROFILE_THUMBNAIL,
        name_chat: chat.NAME,
        username: chat.USERNAME
      };

      const payload = {
        instance_id: instance.id,
        phone: newChat.phone,
        chat_id: newChat.id_chat,
        ticket_id: newChat.ticket_id
      };

      await readMessage(payload);

      // if (!Boolean(newChat.user_id) && Boolean(newChat.ticket_id)) {
      //   const { content } = await setAttendant(payload);
      //   const user = props.users.find(user => user.id === parseInt(content.USER, 10));

      //   newChat = {
      //     ...newChat,
      //     user_id: content.USER,
      //     username: user ? user.name : null,
      //   };
      // }

      _sendMessage(newChat, false);
    } catch (err) {
      console.log('READ MESSAGE => ', err);
    }
  }

  async function _endChat() {
    try {
      const _chat = {
        instance_id: instance.id,
        chat_id: chatActive.ID,
        ticket_id: chatActive.TICKET,
        phone: chatActive.NUMBER
      };
      setDialogEndTicket(false);
      setLoadingEndChat(true);
      setContact({ name_chat: '', number_chat: '' });
      setChatActive({});
      await endTicket(_chat);
      enqueueSnackbar('Chamado encerrado !', {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
        variant: 'success'
      });
      changeTypeChats(2);
    } catch (err) {
      console.log('ERRO END TICKET => ', err);
    } finally {
      setLoadingEndChat(false);
    }
  }

  async function _transferChat(chat, transfer) {
    try {
      const user = props.users.find(user => user.id === chat.USER);
      const _contact = {
          name_chat: chat.NAME,
          number_chat: chat.NUMBER,
          id: contact.id
        },
        newChat = {
          ...chat,
          NAME: _contact.name_chat,
          USER: chat.USER,
          USERNAME: user.name
        },
        updateChat = {
          phone: chat.NUMBER,
          id_chat: chat.ID,
          ticket_id: chat.TICKET,
          user_id: chat.USER,
          message: chat.MESSAGE,
          unread: chat.UNREAD,
          unreadZero: chat.unreadZero,
          data_envio: chat.MESSAGE_DATE,
          type: chat.TYPE,
          ticket_short: chat.SHORT_TICKET,
          manual_service: chat.MANUAL_SERVICE,
          profile_thumbnail: chat.PROFILE_THUMBNAIL,
          name_chat: chat.NAME,
          username: user.name
        },
        transferChat = {
          ticket_id: transfer.ticket,
          instance_id: instance.id,
          chat_id: chat.ID,
          newuser_id: transfer.to,
          phone: chat.NUMBER
        };
      console.log(transferChat);
      await transferTicket(transferChat);
      setContact(contact);
      setChatActive(newChat);
      _sendMessage(updateChat, false);
      enqueueSnackbar('Chamado transferido !', {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 2000,
        variant: 'success'
      });
    } catch (err) {
      console.log('ERRO CREATE CONTACT => ', err);
    } finally {
      setDialogAgents(false);
    }
  }

  function filterChats({ target: { value } }) {
    if (Array.isArray(imutableChats) && imutableChats.length) {
      const filterChats = imutableChats.filter(chat => {
        const name = chat.NAME || chat.NUMBER;
        return name.toUpperCase().indexOf(value.toUpperCase()) > -1;
      });
      setChats({ ...chats, list: filterChats });
    }
  }

  function selectChat(chat) {
    if (chat.NUMBER !== chatActive.NUMBER) {
      const contact = {
          id: chat.CONTACT_ID,
          name_chat: chat.NAME,
          number_chat: chat.NUMBER
        },
        updateChat = { ...chat, unreadZero: true };

      _readMessages(updateChat);

      setMessages({ continuation: false, lastId: null, list: [] });
      setContact(contact);
      setChatActive(chat);
    }
  }

  function selectContact(contact) {
    if (contact.numero !== chatActive.NUMBER) {
      let chat = imutableChats.find(_imutable => _imutable.NUMBER === contact.numero);
      const newContact = {
        name_chat: contact.nome_contato,
        number_chat: contact.numero
      };
      if (!chat) {
        chat = {
          MUTED: 0,
          NAME: newContact.name_chat,
          NUMBER: newContact.number_chat,
          SPAM: 0,
          UNREAD: 0
        };
      }

      setMessages({ continuation: false, lastId: null, list: [] });
      setContact(newContact);
      setChatActive(chat);
    }
    setModalContacts(false);
  }

  function changeTypeChats(type) {
    switch (type) {
      case 0:
        _listChats(null, null, false);
        break;
      case 1:
        _listChats(true, null, false);
        break;
      case 2:
        _listChats(null, true, false);
        break;
      case 3:
        _listChats(3, true, false);
        break;
      case 4:
        _listChats(4, true, false);
        break;
    }
    setTypeChat(type);
  }

  function _nextPageMessages() {
    _listMessages(false);
  }

  async function sendMessage(msg = null) {
    try {
      const localMessage = msg ? msg : message;
      if (Boolean(localMessage)) {
        const payload = {
            instance_id: instance.id,
            message: localMessage,
            chat: chatActive,
            phone: chatActive.NUMBER
          },
          sendMessage = {
            message_id: (Math.random() + 1).toString(36).toUpperCase().substring(2),
            id_instancia: payload.instance_id,
            message: localMessage,
            i_send: 1,
            is_group: Boolean(chatActive.IS_GROUP),
            id_chat: chatActive.ID,
            phone: payload.phone,
            name_chat: chatActive.NAME,
            ticket_id: chatActive.TICKET,
            ticket_short: chatActive.SHORT_TICKET,
            type: 'TEXT'
          };
        setMessage('');
        setMessages({
          ...messages,
          list: [...messages.list, sendMessage]
        });
        _sendMessage(sendMessage);
        const send = await sendText(payload);
        if (
          chatActive.TICKET !== send.content.ticket_id ||
          chatActive.USER !== send.content.user_id
        ) {
          _sendMessage({
            ...sendMessage,
            username: send.content.username,
            ticket_id: send.content.ticket_id,
            ticket_short: send.content.ticket_short
          });
          setChatActive({
            ...chatActive,
            TICKET: send.content.ticket_id,
            SHORT_TICKET: send.content.ticket_short,
            USERNAME: send.content.username,
            USER: send.content.user_id,
            MANUAL_SERVICE: 1
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function _sendVoiceMessage() {
    try {
      setOpenMessageVoice(false);
      setSendVoiceMessage(false);
      setUploadMidia(true);
      if (Boolean(voiceMessage.blob)) {
        const voice = {
            file: voiceMessage.blob,
            type: 'audio/ogg; codecs=opus',
            bucketName: 'files-whatsapp',
            folderName: `${companyInfo}`,
            subFolderName: 'audio/',
            extension: 'ogg'
          },
          { Location } = await DSAWS.saveS3(voice),
          payload = {
            instance_id: instance.id,
            audio: Location,
            chat: chatActive,
            phone: chatActive.NUMBER
          },
          { content } = await sendAudio(payload),
          sendMessage = {
            message_id: content.message_id,
            id_instancia: payload.instance_id,
            message: payload.audio,
            i_send: 1,
            is_group: Boolean(chatActive.IS_GROUP),
            id_chat: chatActive.ID,
            ticket_id: content.ticket_id,
            ticket_short: content.ticket_short,
            phone: payload.phone,
            name_chat: chatActive.NAME,
            type: 'AUDIO',
            duration: durationVoiceMessage,
            username: content.username
          };
        _sendMessage(sendMessage);
        setMessages({
          ...messages,
          list: [...messages.list, sendMessage]
        });
        setVoiceMessage({ uri: '', blob: '' });
        if (chatActive.TICKET !== content.ticket_id) {
          setChatActive({
            ...chatActive,
            TICKET: content.ticket_id,
            SHORT_TICKET: content.ticket_short,
            USERNAME: content.username,
            USER: content.user_id,
            MANUAL_SERVICE: 1
          });
        }
      }
    } catch (err) {
      console.log('ERRO UPLOAD VOICE', err);
    } finally {
      setUploadMidia(false);
    }
  }

  async function _sendImage(files = []) {
    if (Boolean(files.length)) {
      setUploadMidia(true);
      const images = [];
      await asyncForEach(files, async file => {
        try {
          const payload = {
              instance_id: instance.id,
              image: file.url,
              chat: chatActive,
              phone: chatActive.NUMBER,
              caption: Boolean(file.caption) ? file.caption : null
            },
            { content } = await sendImage(payload),
            sendMessage = {
              message_id: content.message_id,
              id_instancia: payload.instance_id,
              message: payload.image,
              i_send: 1,
              is_group: Boolean(chatActive.IS_GROUP),
              id_chat: chatActive.ID,
              ticket_id: content.ticket_id,
              ticket_short: content.ticket_short,
              phone: payload.phone,
              name_chat: chatActive.NAME,
              type: 'IMAGE',
              caption: payload.caption,
              username: content.username
            };
          _sendMessage(sendMessage);
          if (chatActive.TICKET !== content.ticket_id) {
            setChatActive({
              ...chatActive,
              TICKET: content.ticket_id,
              SHORT_TICKET: content.ticket_short,
              USERNAME: content.username,
              USER: content.user_id,
              MANUAL_SERVICE: 1
            });
          }
          images.push(sendMessage);
        } catch (err) {
          console.log('ERRO IMAGE UPLOAD => ', err);
        }
      });
      setMessages({
        ...messages,
        list: [...messages.list, ...images]
      });
      setUploadMidia(false);
    }
  }

  async function _sendVideo(files = []) {
    if (Boolean(files.length)) {
      setUploadMidia(true);
      const videos = [];
      await asyncForEach(files, async file => {
        try {
          const payload = {
              instance_id: instance.id,
              video: file.url,
              chat: chatActive,
              phone: chatActive.NUMBER,
              caption: Boolean(file.caption) ? file.caption : null
            },
            { content } = await sendVideo(payload),
            sendMessage = {
              message_id: content.message_id,
              id_instancia: payload.instance_id,
              message: payload.video,
              i_send: 1,
              is_group: Boolean(chatActive.IS_GROUP),
              id_chat: chatActive.ID,
              ticket_id: content.ticket_id,
              ticket_short: content.ticket_short,
              phone: payload.phone,
              name_chat: chatActive.NAME,
              type: 'VIDEO',
              caption: payload.caption,
              username: content.username
            };
          _sendMessage(sendMessage);
          if (chatActive.TICKET !== content.ticket_id) {
            setChatActive({
              ...chatActive,
              TICKET: content.ticket_id,
              SHORT_TICKET: content.ticket_short,
              USERNAME: content.username,
              USER: content.user_id,
              MANUAL_SERVICE: 1
            });
          }
          videos.push(sendMessage);
        } catch (err) {
          console.log('ERRO VIDEO UPLOAD => ', err);
        }
      });
      setMessages({
        ...messages,
        list: [...messages.list, ...videos]
      });
      setUploadMidia(false);
    }
  }

  socket.onmessage = message => _receiveMessage(JSON.parse(message.data));

  const hasNumberSelected = Boolean(contact.number_chat);

  console.log(typesFilter);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid container spacing={2}>
        {connectedWS === 3 && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              O sistema perdeu a conexão com o chat,{' '}
              <Link style={{ color: '#FFF' }} onClick={() => window.location.reload()}>
                atualize
              </Link>{' '}
              para renovar a conexão.
            </Alert>
          </Grid>
        )}
        {connectedWS === 2 && (
          <Grid item xs={12}>
            <Alert
              icon={<CircularProgress color="inherit" size="1.2rem" />}
              variant="filled"
              severity="warning"
            >
              Fechando conexão com o chat.
            </Alert>
          </Grid>
        )}
        {connectedWS === 0 && (
          <Grid item xs={12}>
            <Alert
              icon={<CircularProgress color="inherit" size="1.2rem" />}
              variant="filled"
              severity="info"
            >
              Abrindo conexão com o chat, aguarde alguns segundos.
            </Alert>
          </Grid>
        )}
        {connectedWS === 1 && (
          <Fragment>
            <Grid item container xs={4}>
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cardContent}>
                  {/* Messages Sidebar */}
                  <Grid container spacing={0}>
                    {!statusConnectionWhatsapp ||
                      (withoutConnection && (
                        <Grid item xs={12}>
                          <Alert variant="filled" severity="error">
                            Whatsapp sem conexão!{' '}
                            <Link
                              style={{ color: '#FFF' }}
                              onClick={() => history.push(`/whatsapp/instancias`)}
                            >
                              Conecte-se
                            </Link>{' '}
                            para poder usar o chat.
                          </Alert>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <div className={classes.header}>
                        <Box display="flex">
                          <Avatar className={classes.avatar}>{getInitials()}</Avatar>
                          <InstanceSelect
                            {...props}
                            instance={instance}
                            onClick={onChangeInstance}
                          />
                        </Box>
                        <Box>
                          <Tooltip title="Nova conversa">
                            <IconButton onClick={() => setModalContacts(true)}>
                              <ChatIcon className={classes.iconNewMessage} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Novo contato">
                            <IconButton onClick={() => setDialogContact(true)}>
                              <PersonAddIcon className={classes.iconNewMessage} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </Grid>

                    {/* Messages Component */}

                    <Grid item xs={12}>
                      <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)" bgcolor="#f0f0f0" px={2}>
                        <InputBase
                          fullWidth
                          size="small"
                          placeholder="Procurar uma conversa"
                          onChange={filterChats}
                          className={classes.inputSearchChats}
                          startAdornment={
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)" px={2} py={1}>
                        {typesFilter.map((type, index) => (
                          <Chip
                            style={{ marginRight: '1vw' }}
                            clickable
                            key={index.toString()}
                            label={type.label}
                            color="primary"
                            size="small"
                            icon={<FilterIcon />}
                            variant={type.value === typeChat ? 'default' : 'outlined'}
                            onClick={() => changeTypeChats(type.value)}
                          />
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {loadingChats ? (
                        <section className={classes.loadMessagesChats}>
                          <CircularProgress />
                        </section>
                      ) : (
                        <List id="scrollableList" className="chatList">
                          <InfiniteScroll
                            dataLength={chats.list.slice(0, infiniteScrollChats * 10).length}
                            // next={_nextPageChat}
                            next={() => setInfiniteScrollChats(prev => prev + 1)}
                            hasMore={infiniteScrollChats * 10 <= chats.list.length}
                            scrollableTarget="scrollableList"
                            endMessage={
                              <ItemChatEmpty textEmpty="Nenhuma conversa foi encontrada" />
                            }
                          >
                            {chats.list.slice(0, infiniteScrollChats * 10).map((chat, index) => (
                              <ItemChat
                                key={index.toString()}
                                data={chat}
                                chatActive={chatActive}
                                onClick={() => !loadingMessages && selectChat(chat)}
                              />
                            ))}
                          </InfiniteScroll>
                        </List>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item container xs={8}>
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cardContent}>
                  {loadingEndChat ? (
                    <Box
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h6" color="textSecondary">
                          Finalizando chamado
                        </Typography>
                        <CircularProgress size="1.5rem" />
                      </Box>
                    </Box>
                  ) : (
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={classes.chatHeader}>
                          {hasNumberSelected ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              onMouseEnter={() => setShowBtnEditContact(true)}
                              onMouseLeave={() => setShowBtnEditContact(false)}
                            >
                              <Avatar className={classes.avatar}>
                                {chatActive.PROFILE_THUMBNAIL ? (
                                  <img
                                    src={chatActive.PROFILE_THUMBNAIL}
                                    alt={chatActive.NAME || chatActive.NUMBER}
                                    className={classes.avatar}
                                    style={{ margin: 0 }}
                                  />
                                ) : chatActive.IS_GROUP ? (
                                  <GroupIcon />
                                ) : null}
                              </Avatar>

                              <Typography variant="h6" color="textSecondary">
                                {chatActive.SHORT_TICKET ? `${chatActive.SHORT_TICKET} - ` : ''}
                                {chatActive.NAME || Celular2(chatActive.NUMBER)}
                              </Typography>
                              <Grow in={showBtnEditContact}>
                                <IconButton
                                  onClick={() => {
                                    setDialogContact(true);
                                    setEditContact(true);
                                  }}
                                  style={{ marginLeft: '.5vw' }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grow>
                            </Box>
                          ) : (
                            <Typography variant="body1">
                              Selecione uma conversa ou inicie uma nova
                            </Typography>
                          )}

                          {hasNumberSelected && chatActive.MANUAL_SERVICE ? (
                            <Box flexGrow={1} display="flex" justifyContent="end">
                              <Button color="primary" onClick={() => setDialogAgents(true)}>
                                Transferir conversa
                              </Button>
                              <Button color="secondary" onClick={() => _endChat()}>
                                Encerrar conversa
                              </Button>
                            </Box>
                          ) : null}
                        </div>
                      </Grid>

                      <Grid hidden={previewMidia} item xs={12}>
                        {hasNumberSelected && (
                          <Box className={classes.backgrounsMessages}>
                            {loadingMessages ? (
                              <section className={classes.loadMessages}>
                                <CircularProgress />
                              </section>
                            ) : (
                              <MessageList
                                onClickMidia={item => setDialogMidia(item)}
                                dataSource={messages.list}
                                hasMore={messages.continuation}
                                next={_nextPageMessages}
                              />
                            )}
                          </Box>
                        )}
                      </Grid>
                      {hasNumberSelected && (
                        <Grid hidden={previewMidia} item xs={12}>
                          <Box className={classes.rootWriteMessage}>
                            <MenuFiles
                              onPreview={files => {
                                setPreviewMidia(true);
                                setFileMidia(files);
                              }}
                            />

                            <InputBaseGlobal
                              sendMessage={sendMessage}
                              uploadMidia={uploadMidia}
                              setMessageGlobal={setMessage}
                              messageGlobal={message}
                            />

                            {Boolean(uploadMidia) ? (
                              <CircularProgress size="2em" />
                            ) : Boolean(message) ? (
                              <IconButton onClick={() => sendMessage()}>
                                <SendIcon />
                              </IconButton>
                            ) : !Boolean(openMessageVoice) ? (
                              <IconButton onClick={() => setOpenMessageVoice(true)}>
                                <MicRoundedIcon />
                              </IconButton>
                            ) : (
                              <Box
                                width="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-around"
                              >
                                <IconButton
                                  disabled={uploadMidia}
                                  onClick={() => setOpenMessageVoice(false)}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <Box display="flex" alignItems="center">
                                  <RecordingIcon className="fiberRecord" /> &nbsp;
                                  <Typography>Gravando</Typography>
                                </Box>
                                <IconButton>
                                  <CheckIcon
                                    onClick={() => {
                                      setOpenMessageVoice(false);
                                      setSendVoiceMessage(true);
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )}
                      <PreviewMidia
                        open={previewMidia}
                        files={fileMidia}
                        onVideo={video => _sendVideo(video)}
                        onImage={image => _sendImage(image)}
                        onClose={() => {
                          setPreviewMidia(false);
                          setFileMidia([]);
                        }}
                      />
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <section style={{ display: 'none' }}>
        <ReactMic
          mimeType="audio/ogg"
          record={openMessageVoice}
          onStop={async voice => {
            const duration = await GetBlobDuration(voice.blobURL);
            setDurationVoiceMessage(duration);
            setVoiceMessage({ uri: voice.blobURL, blob: voice.blob });
          }}
        />
      </section>

      {/* <DialogEndTicket
        open={dialogEndTicket}
        onClose={() => setDialogEndTicket(false)}
        onSave={() => _endChat()}
      /> */}

      {!!modalContacts && (
        <ModalContacts
          loading={loadingContacts}
          open={modalContacts}
          contacts={contacts}
          labelKey="nome_contato"
          onSelect={select => selectContact(select)}
          onClose={() => setModalContacts(false)}
        />
      )}

      <DialogContact
        open={dialogContact}
        contact={contact}
        edit={editContact}
        onClose={() => {
          setDialogContact(false);
          setEditContact(false);
        }}
        onSave={value => _createContact(value)}
        onEdit={value => _updateContact(value)}
      />

      <DialogAgents
        open={dialogEditChat}
        users={props.users}
        chat={chatActive}
        onClose={() => setDialogAgents(false)}
        onSave={(_chat, _transfer) => _transferChat(_chat, _transfer)}
      />

      <DialogMedia
        open={dialogMidia}
        midia={dialogMidia}
        chat={chatActive}
        onClose={() => setDialogMidia(null)}
      />
    </Container>
  );
}

const mapStateToProps = ({ ChatReducer }) => ({ ...ChatReducer });

const mapDispatchToProps = { getUsers, ativarContatos };

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
