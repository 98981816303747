import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';

function useUser() {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUserProfiles = async () => {
    try {
      setLoading(true);

      const response = await Fetch(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/pages`,
        null,
        'GET',
        null,
        null,
        false,
      );

      setData(response.content);
    } catch (err) {
      setError(err);
      enqueueSnackbar('Erro ao carregar perfis', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, data, getUserProfiles };
}

export default useUser;
