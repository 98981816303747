import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const isLoadingBlacklist = () => dispatch =>
  dispatch({ type: "LOAD_BLACKLIST" });

export const isLoadingFamilias = () => dispatch =>
  dispatch({ type: "LOAD_FAMILIAS" });

export const isLoadingProdutos = () => dispatch =>
  dispatch({ type: "LOAD_PRODUTOS" });

export const isLoadingPessoas = () => dispatch =>
  dispatch({ type: "LOAD_PESSOAS" });

export const getPessoas = data => dispatch => {
  dispatch(isLoadingPessoas());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getPessoaByCpf`,
    data
  ).then(payload => dispatch({ type: "GET_PESSOAS", payload: payload }));
};

export const getFamalias = () => async dispatch => {
  dispatch(isLoadingFamilias());
  const payload = await Fetch(
    `https://4hx27tk197.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/produto/getFamilias`
    // `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // {
    //   idStores
    // }
  );
  dispatch({ type: "GET_FAMILIAS", payload });
};

export const getProdutosByFamilia = familia => async dispatch => {
  dispatch(isLoadingProdutos());
  const payload = await Fetch(
    `https://4hx27tk197.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/produto/getProdutosByfamilia`,

    {
      familia
    }
  );
  dispatch({ type: "GET_PRODUTOS", payload });
};

export const getBlacklist = () => async dispatch => {
  dispatch(isLoadingBlacklist());
  const payload = await Fetch(
    `https://bzdnpxi1l3.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/getTabloideBlacklist`
  );

  dispatch({ type: "GET_BLACKLIST", payload });
};

export const postBlacklist = data => async dispatch => {
  dispatch(isLoadingBlacklist());
  const payload = await Fetch(
    `https://bzdnpxi1l3.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/postTabloideBlacklist`,
    data,
    "POST"
  );
  dispatch({ type: "POST_BLACKLIST", payload });
};

export const deleteBlacklist = idBlacklist => async dispatch => {
  dispatch(isLoadingBlacklist());
  await Fetch(
    `https://bzdnpxi1l3.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/deleteBlacklist`,
    { idBlacklist },
    "PUT"
  );
  dispatch(getBlacklist());
};
