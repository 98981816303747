// Others
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { AutoFixHighOutlined } from '@mui/icons-material';
// Material Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SentimentSatisfiedRounded from '@mui/icons-material/SentimentSatisfiedRounded';
import SmsIcon from '@mui/icons-material/Sms';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PickerEmoji from 'emoji-picker-react';
import { useFormik } from 'formik';
import { SelectedShareableItemProps } from 'modules/TabloideDigitalV2/reducer/types';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useAdsDistribution from '../../../../hooks/api/distribution/useAdsDistribution';
import { StateProps } from '../../../../store/types';
import { Checkbox } from '../../../../utils/components';
import {
  AMBIENTE,
  API_SOCIAL_NETWORKS_V2,
  API_WHATSAPP_V2,
  CELULARES,
  DATASALES_CRM_API,
  HOST_TRACKER,
  PARETO
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CreateAccount from '../../../SocialNetworks/pages/Accounts/components/CreateAccount';
import { findAutoMessage } from '../../action';
import Slider, { ValueLabelComponent } from '../CreateAds/components/Slider';
import { FormModel } from '../CreateSms/index';
import FormObserver from '../FormObserver';
import useStyles from './styles';

export const SOCIAL_NETWORKS_VALIDATION_SCHEMA = yup.object().shape({
  noText: yup.boolean(),
  profile: yup.object().required('*campo obrigatório').nullable(),
  platforms: yup.array(yup.object()).required('*campo obrigatório'),
  title: yup
    .string()
    .when('noText', {
      is: false,
      then: yup.string().required('*campo obrigatório') //.max(160),
    })
    .nullable(),
  scheduleDate: yup.date().notRequired().nullable()
});

const state = {
  message: '',
  files: [],
  selectedCelular: CELULARES,
  optionList: '',
  nomeCampanha: '',
  dataInicioCampanha: moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
  dataFimCampanha: moment_tz(moment().add(7, 'days'))
    .tz('America/Sao_Paulo')
    .format('YYYY-MM-DD HH:mm:ss'),
  pareto: PARETO[0],
  key: 6,
  numero: '',
  numerosList: [],
  hostTracker: HOST_TRACKER[0]
};

type CreatePostProps = {
  backgroundId: number;
  imageId?: number;
  imageUrl?: string;
  offerId: number;
  offerName: string;
  onClose: () => void;
  isPost: boolean;
  selectedItems: SelectedShareableItemProps;
  showOnlyFacebookAndInstagram: boolean;
  preLoadedProfiles: [];
  isFromSchedulePost?: boolean;
};

const CreatePost = ({
  imageId,
  imageUrl,
  offerId,
  offerName,
  onClose,
  isPost,
  selectedItems: carouselSelectedItems,
  showOnlyFacebookAndInstagram,
  preLoadedProfiles = [],
  backgroundId,
  isFromSchedulePost
}: CreatePostProps) => {
  const dispatch = useDispatch();
  const {
    adsDistribution: { validateForm, selectedChannelsFormData, currentTab, currentChannel },
    selectedShareableItem,
    loading_auto_message,
    auto_message
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);

  const { validateThisForm } = useAdsDistribution();

  const classes = useStyles();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const savedData = selectedChannelsFormData?.filter(
    currentChannel => currentChannel.name === 'social-networks'
  )[0]?.data;

  const [onClickAutoMessage, setOnClickAutoMessage] = useState(false);
  const [isInstagramBusinessAccount, setIsInstagramBusinessAccount] = useState<number>(1);
  const [profiles, setProfiles] = useState(preLoadedProfiles || []);
  const [instances, setInstances] = useState([]);
  const [profilesIsLoading, setProfilesIsLoading] = useState(false);
  const [linkToInstances, setLinkToInstances] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsToSend, setGroupsToSend] = useState([]);
  const inputRef = useRef();
  const [anchorEmoji, setAnchorEmoji] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [disabledSocialNetworks, setDisabledSocialNetworks] = useState(false);
  const [selectionText, setSelectionText] = useState({
    selectionStart: 0,
    selectionEnd: 0
  });
  const [postValue, setPostValue] = useState(
    selectedChannelsFormData[0]?.data?.post || selectedChannelsFormData[0]?.data?.text || ''
  );
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadedLogin, setLoadedLogin] = useState(null);
  const [balanceSms, setBalanceSms] = useState(1000);

  const [isConnectWithFacebookOpen, setIsConnectWithFacebookOpen] = useState(false);

  const instancesIsLoading = instances.length === 0;

  const history = useHistory();

  const haveDateScheduled = !!carouselSelectedItems?.[0]?.dateScheduled;

  const hasCarouselCardsSelected = () =>
    !haveDateScheduled && !!carouselSelectedItems && carouselSelectedItems.length > 1;

  const hasCarouselOnlyOneCardsSelected = () =>
    !haveDateScheduled && carouselSelectedItems && carouselSelectedItems.length == 1;

  useEffect(() => {
    history.push(history.location.pathname + '?newDistribution=CreatePost');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  const _companyID = localStorage.getItem('companyId');
  const is1525 = _companyID ? JSON.parse(_companyID) === 1525 : false; // id de empresa fake (essa nao deve ver stories)

  const social_networks = !is1525
    ? [
        {
          label: 'Facebook',
          icon: <FacebookIcon />,
          value: 'facebook'
        },
        {
          label:
            'Instagram' +
            (!isPost ? (!hasCarouselCardsSelected() ? ' (Disponível apenas para Post)' : '') : ''),
          icon: <InstagramIcon />,
          value: 'instagram'
        },
        ...(showOnlyFacebookAndInstagram
          ? []
          : [
              // {
              //   label: 'Instagram Story',
              //   icon: <InstagramIcon />,
              //   value: 'instagram_story',
              // },
              {
                label: 'Twitter',
                icon: <TwitterIcon />,
                value: 'twitter'
              },
              {
                label: 'LinkedIn',
                icon: <LinkedInIcon />,
                value: 'linkedIn'
              },
              {
                label: 'YouTube',
                icon: <YouTubeIcon />,
                value: 'youtube'
              },
              {
                label: 'SMS',
                icon: <SmsIcon />,
                value: 'sms'
              },
              {
                label: 'WhatsApp',
                icon: <WhatsAppIcon />,
                value: 'whatsapp'
              },
              // {
              //   label: 'WhatsApp Status',
              //   icon: <WhatsAppIcon />,
              //   value: 'whatsapp_status',
              // },
              {
                label: 'Telegram',
                icon: <TelegramIcon />,
                value: 'telegram'
              }
            ])
      ]
    : [
        {
          label: 'Facebook',
          icon: <FacebookIcon />,
          value: 'facebook'
        },
        {
          label: 'Instagram' + (!isPost ? ' (Disponível apenas para Post)' : ''),
          icon: <InstagramIcon />,
          value: 'instagram'
        }
      ];

  const getUserProfiles = async () => {
    try {
      setProfilesIsLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/pages`,
        null,
        'GET',
        null,
        null,
        false
      );

      if (response.content) {
        setProfiles(response.content);
        formik.setFieldValue('profile', response.content[0]);
      }
    } catch (err) {
      enqueueSnackbar('Erro ao carregar perfis', { variant: 'error' });
    } finally {
      setProfilesIsLoading(false);
    }
  };

  useEffect(() => {
    // if (userProfiles && userProfiles.length <= 0) getUserProfiles();

    (async () => {
      try {
        const response = await api(
          `${API_WHATSAPP_V2}instances/client/list`,
          null,
          'GET',
          null,
          null,
          false
        );

        setInstances(response);
      } catch (err) {
        enqueueSnackbar('Erro ao carregar listas', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar]);

  const loadGroups = async idClient => {
    try {
      const response = await api(
        `${API_WHATSAPP_V2}groups/list`,
        {
          client: idClient
        },
        'GET',
        null,
        null,
        false
      );

      setGroups(response.content);
    } catch (err) {
      enqueueSnackbar('Erro ao carregar grupos', { variant: 'error' });
    }
  };

  const plataformsFilter = value => {
    if (formRef.current) {
      formRef.current.setFieldValue(
        'platforms',
        formRef.current.values.platforms.filter(element => element.value !== value)
      );
    }
  };

  const handleSubmitProfile = async values => {
    setIsConnectWithFacebookOpen(false);
    getUserProfiles();
  };

  /**
   * @method handleSubmitNotTurbochargedCampaignCarousel
   * @return void
   * @param values -> form submited data values
   * @description submit data to not turbocharged campaign in carousel
   */
  async function handleSubmitNotTurbochargedCampaignCarousel({
    title: name,
    post: message,
    profile,
    scheduleDate,
    ...params
  }) {
    let dataToSend = {
      name,
      message,
      send_date: moment(scheduleDate).format('DD/MM/YYYY HH:mm'),
      start_date_campaign: state.dataInicioCampanha,
      end_date_campaign: state.dataFimCampanha,
      is_midia: true,
      urls: [],
      advertising_id: offerId,
      page_id: profile.page_id,
      fb_id: profile.fb_user_id
    };

    enqueueSnackbar('Fazendo a Distribuição...', {
      variant: 'info'
    });

    for (let selectedCard of carouselSelectedItems) {
      const currentCardLink = selectedCard.link ? selectedCard.link : selectedCard.image_url;
      dataToSend.urls.push({
        item_advertisement_id: selectedCard.id,
        url: currentCardLink,
        is_video_media: currentCardLink.endsWith('.mp4')
      });
    }

    try {
      await api(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/instagram/carousel`,
        dataToSend,
        'POST',
        null,
        null,
        false
      );

      onClose();
      enqueueSnackbar('Distribuição em carrossel enviada com sucesso!', {
        variant: 'success'
      });

      /**
       * @description Fechar a Janela "Meus Clientes após Distribuir campanha"
       */
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit(values) {
    try {
      enqueueSnackbar('Distribuindo...', {
        variant: 'info'
      });

      const platforms = values.platforms.map(item => item.value);

      let index = platforms.indexOf('whatsapp');

      if (index > -1) {
        if (!values?.instance?.id) throw new Error('Selecione uma instância!');

        const isVideo = imageUrl.endsWith('.mp4');

        if (values?.shippingType === 'contacts' || values?.shippingType === 'onlyInCopy') {
          await api(
            `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create`,
            {
              onlyInCopy: values?.shippingType === 'onlyInCopy',
              idInstancia: values.instance.id,
              nomeCampanha: offerName,
              numero: state.numero,
              numerosList: values.phones && values.phones.replace(/\s/g, '').split(','),
              message: postValue,
              dataInicio: values.scheduleDate
                ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
                : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
              dataFim: moment(state.dataFim).format('DD/MM/YYYY HH:mm'),
              dataInicioCampanha: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
              dataFimCampanha: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
              nameList: state.optionList.label,
              origin: 'Minha Base',
              pareto: state.pareto.id,
              selectedCelular:
                state.selectedCelular && state.selectedCelular.map(value => value.value),
              redirectTracker: state.redirectTracker,
              custoCampanha: 0,
              cupom: state.cupom,
              whatsapp_midia: true,
              whatsapp_url: imageUrl,
              hostTracker: !state.hostTracker ? '' : state.hostTracker.host,
              id_propaganda: offerId,
              id_propaganda_imagem: imageId,
              ...(isVideo ? { messageType: 'VIDEO' } : {})
            },
            'POST',
            null,
            null,
            false
          ).then(() => {
            enqueueSnackbar('Campanha de Whatsapp enviada com sucesso!', {
              variant: 'success'
            });
          });
        }

        if (values?.shippingType === 'onlyGroups' && groupsToSend.length > 0) {
          for (let k = 0; k < groupsToSend.length; k++) {
            const group = groupsToSend[k];
            await api(
              `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create-group`,
              {
                idInstancia: values.instance.id,
                groupId: group,
                nomeCampanha: offerName,
                message: postValue,
                dataInicio: values.scheduleDate
                  ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
                  : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
                dataFim: moment(state.dataFim).format('DD/MM/YYYY HH:mm'),
                dataInicioCampanha: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
                dataFimCampanha: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
                whatsapp_midia: true,
                whatsapp_url: imageUrl,
                ...(isVideo ? { messageType: 'VIDEO' } : {})
              },
              'POST',
              null,
              null,
              false
            ).then(() => {
              enqueueSnackbar('Campanha de Whatsapp Grupos enviada com sucesso!', {
                variant: 'success'
              });
            });
          }
        }

        plataformsFilter(platforms[index]);

        platforms.splice(index, 1);
      }

      index = platforms.indexOf('whatsapp_status');

      if (index > -1) {
        if (!values?.instance?.id) throw new Error('Selecione uma instância!');

        await api(
          `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create`,
          {
            tipo_campanha: 'WHATSAPP_STATUS',
            idInstancia: values.instance.id,
            nomeCampanha: offerName,
            numero: null,
            numerosList: null,
            message: postValue,
            dataInicio: values.scheduleDate
              ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
              : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
            dataFim: null,
            dataInicioCampanha: null,
            dataFimCampanha: null,
            nameList: null,
            origin: 'Minha Base',
            pareto: state.pareto.id,
            selectedCelular: null,
            redirectTracker: null,
            custoCampanha: 0,
            cupom: null,
            whatsapp_midia: true,
            whatsapp_url: imageUrl,
            hostTracker: null,
            id_propaganda: offerId,
            id_propaganda_imagem: imageId
          },
          'POST',
          null,
          null,
          false
        ).then(() => {
          plataformsFilter(platforms[index]);

          platforms.splice(index, 1);

          enqueueSnackbar('Status de Whatsapp enviado com sucesso!', {
            variant: 'success'
          });
        });
      }

      index = platforms.indexOf('sms');

      if (index > -1) {
        if (!values?.instance?.id) throw new Error('Selecione uma instância!');

        const payloadRequest = {
          idInstancia: values.instance.id,
          nomeCampanha: offerName,
          message: postValue,
          dataInicio: values.scheduleDate
            ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
            : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
          whatsapp_midia: true,
          whatsapp_url: imageUrl,
          id_propaganda: offerId,
          id_propaganda_imagem: imageId,
          custoCampanha: values.smsLimits,
          totalLimit: balanceSms,
          redirectTracker: imageUrl,
          origin: 'Estúdio',
          profile_type: values.profileSMS.value
        };
        await api(
          `${DATASALES_CRM_API}sms-v2-${AMBIENTE}/store`,
          payloadRequest,
          'POST',
          null,
          null,
          false
        );

        plataformsFilter(platforms[index]);
        platforms.splice(index, 1);
        enqueueSnackbar('Sms enviado com sucesso!', { variant: 'success' });
      }

      index = platforms.indexOf('facebook');

      if (index > -1) {
        await api(
          `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/facebook`,
          {
            name: 'Post Facebook',
            message: postValue,
            send_date: values.scheduleDate
              ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
              : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
            start_date_campaign: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
            end_date_campaign: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
            is_midia: !!imageUrl,
            url: imageUrl,
            advertising_id: offerId,
            image_advertisement_id: imageId,
            page_id: values.profile.page_id,
            fb_id: values.profile.fb_user_id
          },
          'POST',
          null,
          null,
          false
        );

        plataformsFilter(platforms[index]);

        platforms.splice(index, 1);

        enqueueSnackbar('Post de Facebook enviado com sucesso!', {
          variant: 'success'
        });
      }

      index = platforms.indexOf('instagram');

      if (index > -1) {
        await api(
          `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/instagram`,
          {
            name: 'Post Instagram',
            message: postValue,
            send_date: values.scheduleDate
              ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
              : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
            start_date_campaign: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
            end_date_campaign: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
            is_midia: !!imageUrl,
            url: imageUrl,
            advertising_id: offerId,
            image_advertisement_id: imageId,
            page_id: values.profile.page_id,
            fb_id: values.profile.fb_user_id
          },
          'POST',
          null,
          null,
          false
        );

        plataformsFilter(platforms[index]);

        platforms.splice(index, 1);

        enqueueSnackbar('Post de Instagram enviado com sucesso!', {
          variant: 'success'
        });
      }

      index = platforms.indexOf('instagram_storie');

      if (index > -1) {
        await api(
          `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/instagramStory`,
          {
            username: loadedLogin,
            url: imageUrl,
            caption: postValue
          },
          'POST',
          null,
          null,
          false
        );

        plataformsFilter(platforms[index]);

        platforms.splice(index, 1);

        enqueueSnackbar('Story de Instagram enviado com sucesso!', {
          variant: 'success'
        });
      }

      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  /**
   * @method socialNetworks
   * @return an array of social networks supported form profile
   * @param selectedProfile -> receive and profile; default null
   * @description return an array of supported social networks from a profile/intence
   */
  const socialNetworks = (selectedProfile = null) => {
    const profile = selectedProfile || profiles[0];
    const availableNetworks = social_networks.filter(network => {
      if (
        isInstagramBusinessAccount === 1 &&
        network.value === 'instagram' &&
        profile?.instagram_business_account &&
        profile?.instagram_business_account !== null
      )
        return true;

      if (network.value === 'facebook' && profile?.fb_user_id && !hasCarouselCardsSelected())
        return true;
      return false;
    });

    return availableNetworks;
  };

  const formik = useFormik({
    initialValues: {
      noText: savedData?.noText || false,
      profile: savedData?.profile || profiles[0],
      title:
        savedData?.title ||
        (hasCarouselCardsSelected() && !hasCarouselOnlyOneCardsSelected()
          ? 'Divulgação em Carrossel'
          : 'Divulgação Simples'),
      post: '',
      scheduleDate: savedData?.scheduleDate || moment().toDate(),
      platforms: savedData?.platforms || socialNetworks(),
      shippingType: savedData?.shippingType || 'contacts',
      instance: savedData?.instance || null,
      profileSMS: savedData?.profileSMS || { label: 'Todos os contatos', value: 2 },
      smsLimits: savedData?.smsLimits || 1000
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: SOCIAL_NETWORKS_VALIDATION_SCHEMA,
    onSubmit: values => {
      hasCarouselCardsSelected() && !hasCarouselOnlyOneCardsSelected()
        ? handleSubmitNotTurbochargedCampaignCarousel(values)
        : handleSubmit(values);
    }
  });

  const justWhatsappStatus = values => {
    if (values.platforms) {
      return (
        !!values.platforms.find(k => ['whatsapp_status'].includes(k.value)) &&
        values.platforms.length === 1
      );
    }
    return false;
  };

  async function loginNOInstagram(values) {
    try {
      setLoadingLogin(true);
      setLoadedLogin(null);

      await api(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/instagramNotOffical/login`,
        {
          username: values.username,
          password: values.password
        },
        'POST',
        null,
        null,
        true
      );

      setLoadingLogin(false);
      setLoadedLogin(values.username);
    } catch (err) {
      setLoadingLogin(false);
    }
  }

  async function getSmsLimits(types = []) {
    try {
      if (types.find(({ value }) => value === 'sms')) {
        setPostValue(`${postValue}Aproveite essa oferta!\n{{clickTracker}}`);

        const { content } = await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/lastLimitHistory`,
          { id_type: 3 },
          'GET',
          null,
          null,
          false
        );
        setBalanceSms(content.balance);
      } else {
        setBalanceSms(1000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function updateSelectionText() {
    const newPosition = {
      selectionStart: inputRef.current.selectionStart,
      selectionEnd: inputRef.current.selectionEnd
    };
    setSelectionText(newPosition);
  }

  function updateSelectionTextAfterEmoji(n: any) {
    setSelectionText(p => ({
      selectionStart: p.selectionStart + n,
      selectionEnd: p.selectionEnd + n
    }));
  }

  function onEmojiText(emoji: any) {
    if (postValue) {
      const newText = `${postValue.slice(0, selectionText.selectionStart)}${emoji}${postValue.slice(
        selectionText.selectionStart
      )}`;

      setPostValue(newText);
    } else {
      setPostValue(emoji);
    }
    updateSelectionTextAfterEmoji(emoji.length);
  }

  const handleChangeAutoMessage = useCallback(() => {
    dispatch(findAutoMessage({ background_id: backgroundId, channel: 'social-network' }));
  }, [backgroundId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formik) {
        formik.setFieldValue('post', postValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [postValue]);

  useEffect(() => {
    if (formik.values.profile && hasCarouselCardsSelected()) {
      const currentProfileSocialNetworks = socialNetworks(formik.values.profile);
      const hasEnabledOptions =
        currentProfileSocialNetworks && currentProfileSocialNetworks.length > 0;
      setDisabledSocialNetworks(!hasEnabledOptions);
    }
  }, [formik.values]);

  useEffect(() => {
    validateThisForm(formik, 'social-networks');
  }, [formik.values, validateForm]);

  /**
   * @description verifies if is an instagram business account or not when user changes the Instance
   */
  useEffect(() => {
    if (!savedData) return;

    if (savedData?.profile) {
      const { fb_user_id, instagram_business_account, page_id } = savedData.profile;

      if (!instagram_business_account) {
        setIsInstagramBusinessAccount(1);
        return;
      }

      (async () => {
        setProfilesIsLoading(true);
        try {
          const response = await api(
            `${API_SOCIAL_NETWORKS_V2}pages/isinstagrambusiness?fb_user_id=${fb_user_id}&instagram_business_id=${instagram_business_account}&page_id=${page_id}`,
            null,
            'GET',
            null,
            null,
            false
          );

          setIsInstagramBusinessAccount(response.content.isInstagramBusiness);
        } catch (err) {
          enqueueSnackbar('Erro ao verificar tipo de conta no Instagram', { variant: 'error' });
        } finally {
          setProfilesIsLoading(false);
        }
      })();
    }
  }, [savedData]);

  useEffect(() => {
    if (!savedData) return;

    if ('profile' in savedData) {
      formik.setFieldValue('platforms', socialNetworks(savedData.profile));
    }
  }, [isInstagramBusinessAccount]);

  useEffect(() => {
    if ((Number(currentTab) === 2 || onClickAutoMessage) && !isFromSchedulePost) {
      if (auto_message['social-network']) {
        setPostValue(auto_message['social-network']);
      } else {
        setPostValue(auto_message.all);
      }
      setOnClickAutoMessage(false);
    }
  }, [auto_message['social-network']]);

  const isScheduled = selectedShareableItem?.[0]?.dateScheduled;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container className={classes.container} maxWidth="md">
          <div style={{ width: '90%', marginRight: 32 }}>
            <Grid container spacing={2}>
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: '24px', fontWeight: '500' }}>
                    Redes sociais
                  </Typography>
                  <Typography
                    style={{
                      padding: '8px 0 32px',
                      fontSize: '14px',
                      borderBottom: '1px solid #CFCFCF'
                    }}
                  >
                    Publique no perfil do Facebook e Instagram. As publicações do tipo de Redes
                    Sociais ficarão no seu visíveis nos perfis das suas redes sociais.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ paddingTop: '14px', fontWeight: '500' }}>
                    Selecione a conta
                    <Button
                      id="id_distribution_connect_account_createpost"
                      data-testid="dsMarketing-connect-account-createpost"
                      variant="text"
                      style={{
                        color: '#127FFF',
                        marginLeft: '2px'
                      }}
                      onClick={() => setIsConnectWithFacebookOpen(true)}
                    >
                      Conectar com Facebook
                    </Button>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="id_distribution_profile_selection_createpost_autocomplete"
                    data-testid="dsMarketing-profile-select-createpost"
                    classes={{ popper: classes.popper }}
                    fullWidth
                    clearOnBlur
                    options={profiles}
                    loading={profilesIsLoading}
                    disabled={profilesIsLoading}
                    loadingText="Carregando Perfis..."
                    // defaultValue={savedData?.profile || profiles[0]}
                    getOptionLabel={option => option.page_name || ''}
                    getOptionSelected={(option, value) => option.page_name === value.page_name}
                    value={formik.values.profile}
                    onChange={(_, newValue) => {
                      formik.setFieldValue('profile', newValue);
                      // setts social networks when prifile changes
                      formik.setFieldValue('platforms', !newValue ? [] : socialNetworks(newValue));
                    }}
                    renderInput={params => (
                      <>
                        <TextField
                          {...params}
                          name="profile"
                          variant="outlined"
                          placeholder="Selecione o perfil"
                          error={formik.errors.hasOwnProperty('profile')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {profilesIsLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                            classes: {
                              notchedOutline: Boolean(formik.errors.profile)
                                ? classes.inputBorderError
                                : classes.inputNoBorder,
                              root: classes.inputBackgroundColor
                            }
                          }}
                          helperText={
                            Boolean(formik.errors.profile) ? (
                              <Typography variant="caption">{formik.errors?.profile}</Typography>
                            ) : (
                              '*obrigatório'
                            )
                          }
                        />
                      </>
                    )}
                  />
                  {isInstagramBusinessAccount !== 1 ? (
                    <Alert
                      severity="warning"
                      style={{ color: '#000054', backgroundColor: '#FDFAE6' }}
                    >
                      Sua conta do Instagram não é do tipo <strong>empresarial</strong>. Para fazer
                      a distribuição no Instagram, você precisa ter uma conta do tipo{' '}
                      <strong>empresarial</strong>.
                      <a href="https://www.youtube.com/watch?v=mPwXxoAkfsY"> Clique aqui</a> para
                      descobrir como.
                      <br />
                      Após isso, <strong>reconecte</strong> a sua conta do instagram a sua pagina do
                      facebook e logo em seguida refaça a sua distribuição, em caso de dúvidas
                      acione o suporte da Datasales.
                    </Alert>
                  ) : null}
                </Grid>
                {!profilesIsLoading && profiles.length === 0 && (
                  <Link
                    to="/social-networks/accounts"
                    target="_blank"
                    style={{
                      width: '100%',
                      marginTop: 10
                    }}
                    onClick={() => {
                      localStorage.setItem('currentModule', null);
                      localStorage.setItem('currentServices', '"redes_sociais"');
                      localStorage.setItem('currentSettings', true);
                    }}
                  >
                    <Alert severity="info" className={classes.balanceAlert}>
                      Clique aqui para conectar suas redes sociais.
                    </Alert>
                  </Link>
                )}
              </Grid>
              <Grid item container spacing={1} xs={12}>
                <Grid item>
                  <Typography style={{ fontWeight: '500' }}>Selecione canais</Typography>
                </Grid>
                <Grid item xs={12}>
                  {disabledSocialNetworks && (
                    <Alert
                      variant="outlined"
                      severity="error"
                      className={classes.socialNetworksError}
                    >
                      A conta selecionada não tem o instagram associado. Por este motivo, não é
                      possível distribuir na conta selecionada.
                    </Alert>
                  )}
                  <Autocomplete
                    id="id_distribution_channels_select_createpost_autocomplete"
                    data-testid="dsMarketing-channels-select-createpost"
                    classes={{ popper: classes.popper }}
                    multiple
                    fullWidth
                    disabled={profilesIsLoading}
                    options={social_networks}
                    limitTags={2}
                    getOptionLabel={option => option.label}
                    getOptionDisabled={option => {
                      let enabled =
                        ['whatsapp', 'whatsapp_status', 'sms'].includes(option.value) ||
                        (['facebook'].includes(option.value) &&
                          socialNetworks(formik.values.profile).some(
                            network => network.value === 'facebook'
                          )) ||
                        (isInstagramBusinessAccount === 1 &&
                          ['instagram'].includes(option.value) &&
                          socialNetworks(formik.values.profile).some(
                            network => network.value === 'instagram'
                          ) &&
                          isPost) ||
                        (['instagram_storie'].includes(option.value) && formik.values.profile); // && values.profile.instagram_business_account && isPost

                      if (hasCarouselCardsSelected()) {
                        enabled =
                          isInstagramBusinessAccount === 1 &&
                          ['instagram'].includes(option.value) &&
                          socialNetworks(formik.values.profile).some(
                            network => network.value === 'instagram'
                          ) &&
                          isPost;

                        enabled =
                          enabled ||
                          socialNetworks(formik.values.profile).some(
                            network => network.value === 'facebook'
                          );
                      }

                      return !enabled;
                    }}
                    getOptionSelected={(option, value) => option.label === value.label}
                    value={formik.values.platforms}
                    onChange={(_, newValue) => {
                      formik.setFieldValue('platforms', newValue);

                      getSmsLimits(newValue);

                      justWhatsappStatus({
                        platforms: newValue
                      })
                        ? formik.setFieldValue('noText', true)
                        : formik.setFieldValue('noText', false);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          icon={option.icon}
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={option => (
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>{option.icon}</Grid>
                        <Grid item>
                          <Typography variant="body1">{option.label}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="platforms"
                        variant="outlined"
                        placeholder="Selecione os canais"
                        error={formik.errors.hasOwnProperty('platforms')}
                        helperText={
                          formik.errors.hasOwnProperty('platforms') ? (
                            <Typography variant="caption">{formik?.errors?.platforms}</Typography>
                          ) : (
                            '*obrigatório'
                          )
                        }
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            notchedOutline: Boolean(formik.errors.platforms)
                              ? classes.inputBorderError
                              : classes.inputNoBorder,
                            root: classes.inputBackgroundColor
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                {Boolean(formik.values.platforms?.find(k => ['instagram'].includes(k.value))) && (
                  <Alert severity="info" style={{ marginTop: 10 }}>
                    Você poderá enviar até 25 posts em um período de 24 horas.
                  </Alert>
                )}

                {formik.values.platforms?.find(el => el.value === 'instagram_storie') && (
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                      fullWidth
                      name="instagram_username"
                      variant="outlined"
                      placeholder="Username"
                      value={formik.values.instagram_username}
                      onChange={formik.handleChange}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputNoBorder,
                          root: classes.inputBackgroundColor
                        }
                      }}
                    />
                    <TextField
                      fullWidth
                      name="instagram_password"
                      variant="outlined"
                      placeholder="Senha"
                      value={formik.values.instagram_password}
                      onChange={formik.handleChange}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputNoBorder,
                          root: classes.inputBackgroundColor
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loadingLogin}
                      onClick={() => {
                        loginNOInstagram({
                          username: formik.values.instagram_username,
                          password: formik.values.instagram_password
                        });
                      }}
                    >
                      {loadingLogin ? 'Carregando...' : loadedLogin ? 'Ok' : 'Login'}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {/*Inicio - Painel configuração sms*/}

              <Collapse
                className={classes.collapseSms}
                in={Boolean(formik.values.platforms?.find(k => k.value === 'sms'))}
                timeout="auto"
                unmountOnExit
              >
                <Grid className={classes.contentInfoSms} spacing={2} container>
                  <Grid item xs={12}>
                    <Typography className={classes.typographyTitleSms}>Perfis de SMS</Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      classes={{
                        popper: classes.popper,
                        inputRoot: classes.typographyAutocompleteSms
                      }}
                      getOptionLabel={option => option.label}
                      getOptionSelected={(option, value) => option.label === value.label}
                      options={[
                        { label: 'Todos os contatos', value: 2 },
                        { label: 'Contatos ativos', value: 1 },
                        { label: 'Contatos inativos', value: 0 }
                      ]}
                      value={formik.values.profileSMS}
                      onChange={(_, newValue) => formik.setFieldValue('profileSMS', newValue)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.inputNoBorder,
                              root: classes.inputBackgroundColor
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typographyTitleSms}>Instância</Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      classes={{
                        popper: classes.popper,
                        inputRoot: classes.typographyAutocompleteSms
                      }}
                      disabled={instancesIsLoading}
                      options={instances}
                      loading={instancesIsLoading}
                      getOptionLabel={option => option.nome}
                      getOptionSelected={(option, value) => option.nome === value.nome}
                      value={formik.values.instance}
                      onChange={(_, newValue) => formik.setFieldValue('instance', newValue)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.inputNoBorder,
                              root: classes.inputBackgroundColor
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typographyTitleSms}>Créditos de SMS</Typography>
                    <Slider
                      ValueLabelComponent={ValueLabelComponent}
                      defaultValue={1000}
                      valueLabelDisplay="auto"
                      step={1000}
                      min={1000}
                      max={balanceSms}
                      value={formik.values.smsLimits}
                      valueLabelFormat={value => value / 1000}
                      onChange={(_, newValue) => formik.setFieldValue('smsLimits', newValue)}
                      classes={{
                        thumb: classes.sliderThumb,
                        rail: classes.sliderRail
                      }}
                    />
                    <Typography
                      style={{ color: '#616161', fontWeight: 500 }}
                      className={classes.typographyTitleSms}
                    >
                      Esta ação será enviada para {formik.values.smsLimits / 1000} contatos. Cada
                      contato equivale a 1 crédito.
                    </Typography>
                  </Grid>
                  <Grid className={classes.contentInfoSmsAlert} item xs={12}>
                    <Typography className={classes.typographyTitleSms} color="primary">
                      <InfoIcon className={classes.iconAlertSms} />
                      SMS suportam até 160 caractéres.
                    </Typography>
                  </Grid>
                  <Grid className={classes.contentInfoSmsAlert} item xs={12}>
                    <Typography className={classes.typographyTitleSms} color="primary">
                      <InfoIcon className={classes.iconAlertSms} />
                      Contatos ativos possuem interação dentro de 60 dias.
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>

              {/*Fim - Painel configuração sms*/}
              <Grid item container xs={12}>
                <Collapse
                  style={{ width: '100%' }}
                  in={Boolean(
                    formik.values.platforms.find(k =>
                      ['whatsapp', 'whatsapp_status'].includes(k.value)
                    )
                  )}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2}>
                    <Grid item container spacing={1} xs={12}>
                      <Grid item>
                        <Typography>Selecione uma instância</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          classes={{ popper: classes.popper }}
                          fullWidth
                          disabled={instancesIsLoading}
                          options={instances}
                          loading={instancesIsLoading}
                          loadingText="Carregando..."
                          getOptionLabel={option => option.nome}
                          getOptionSelected={(option, value) => option.nome === value.nome}
                          value={formik.values.instance}
                          onChange={async (_, newValue) => {
                            formik.setFieldValue('instance', newValue);
                            let isConnected;

                            if (newValue?.id) {
                              isConnected = await api(
                                `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/zapi/is-connected`,
                                {
                                  client: newValue?.id
                                }
                              );

                              if (!isConnected?.content?.connected) {
                                setLinkToInstances(true);
                              } else {
                                setLinkToInstances(false);
                              }

                              loadGroups(newValue.id);
                            } else {
                              setLinkToInstances(false);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={
                                formik.touched.instance &&
                                Boolean(
                                  formik.values.platforms.find(k =>
                                    ['whatsapp', 'whatsapp_status'].includes(k.value)
                                  )
                                ) &&
                                !formik.values.instance
                              }
                              // helperText={'Error'}
                              placeholder="Selecione uma instância"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {instancesIsLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {linkToInstances && (
                        <Link
                          to="/whatsapp/instancias"
                          target="_blank"
                          style={{
                            width: '100%',
                            marginTop: 10
                          }}
                          onClick={() => {
                            // setLinkToInstances(false);
                            localStorage.setItem('currentModule', null);
                            localStorage.setItem('currentServices', '"whatsapp"');
                            localStorage.setItem('currentSettings', true);
                          }}
                        >
                          <Alert severity="info" className={classes.balanceAlert}>
                            Instância não conectada. Clique aqui para conectar!
                          </Alert>
                        </Link>
                      )}
                    </Grid>

                    {!justWhatsappStatus(formik.values) && (
                      <>
                        <Grid item container spacing={1} xs={12}>
                          <Grid item>
                            <Typography>Números copiados na campanha</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="phones"
                              variant="outlined"
                              placeholder="Números copiados na campanha"
                              value={formik.values.phones}
                              disabled={instancesIsLoading}
                              onChange={formik.handleChange}
                              error={formik.touched.phones && Boolean(formik.errors.phones)}
                              helperText={
                                <Typography variant="caption">
                                  *Separe os números por <strong>vírgula</strong>.
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid item container spacing={1} xs={12}>
                          <Grid item>
                            <Typography>Tipo de disparo</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              style={{
                                width: '100%'
                              }}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={formik.values.shippingType}
                                onChange={event => {
                                  formik.setFieldValue('shippingType', event.target.value);
                                }}
                                // label="Tipo de disparo"
                              >
                                <MenuItem value={'contacts'}>
                                  Disparo individual para os contatos
                                </MenuItem>
                                <MenuItem value={'onlyInCopy'}>
                                  Disparo para os numeros em cópia
                                </MenuItem>
                                <MenuItem value={'onlyGroups'}>Disparo para grupos</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {groups.length > 0 && formik.values.shippingType === 'onlyGroups' && (
                          <Grid item container spacing={1} xs={12} style={{ margin: '10px 0px' }}>
                            <div
                              style={{
                                width: '100%',
                                backgroundColor: 'lightgrey',
                                height: 1
                              }}
                            />
                            <Grid item xs={12} style={{ margin: '10px 0px' }}>
                              <Grid item>
                                <Typography>Enviar para os grupos</Typography>
                              </Grid>
                              {groups.map(group => (
                                <Grid item xs={12} style={{ marginLeft: 20 }}>
                                  <Checkbox
                                    name={group.NAME}
                                    classNameLabel={classes.checkLabel}
                                    label={group.NAME}
                                    onChange={({ target: { checked } }) => {
                                      let tmp = [...groupsToSend];
                                      if (checked) {
                                        if (tmp.indexOf(group.ID) === -1) {
                                          tmp.push(group.ID);
                                        }
                                      } else {
                                        tmp = tmp.filter(e => e !== group.ID);
                                      }
                                      setGroupsToSend(tmp);
                                    }}
                                  />

                                  <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                      navigator.clipboard.writeText(group.INVITE);
                                      alert('Link de convite copiado para áre de trabalho');
                                    }}
                                    aria-label="close"
                                  >
                                    <GroupAddIcon />
                                  </IconButton>
                                </Grid>
                              ))}
                            </Grid>
                            <div
                              style={{
                                width: '100%',
                                backgroundColor: 'lightgrey',
                                height: 1
                              }}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Collapse>
              </Grid>

              {!isScheduled && (
                <Grid item container spacing={1} xs={12}>
                  <Grid item>
                    <Typography style={{ fontWeight: '500' }}>
                      Data e horário das publicações
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <KeyboardDateTimePicker
                      id="id_distribution_date_select_createpost_autocomplete"
                      data-testid="dsMarketing-date-select-createpost"
                      ampm={false}
                      disablePast
                      fullWidth
                      disabled={profilesIsLoading}
                      inputVariant="outlined"
                      format="DD/MM/YYYY HH:mm"
                      value={formik.values.scheduleDate || new Date()}
                      onChange={date => {
                        formik.setFieldValue('scheduleDate', date);
                      }}
                      error={Boolean(formik.errors.scheduleDate)}
                      helperText={formik.errors.scheduleDate}
                      InputProps={{
                        readOnly: true,
                        classes: {
                          notchedOutline: classes.inputNoBorder,
                          root: classes.inputBackgroundColor
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {!isScheduled && (
                <>
                  <Grid item container spacing={1} xs={12}>
                    <Grid
                      width="100%"
                      item
                      style={{ display: 'flex' }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: '500' }}>
                        Qual texto da publicação
                      </Typography>
                      <Button
                        onClick={() => {
                          setOnClickAutoMessage(true);
                          handleChangeAutoMessage();
                        }}
                        disabled={loading_auto_message}
                        color="primary"
                        variant="text"
                        size="small"
                        startIcon={<AutoFixHighOutlined />}
                      >
                        {loading_auto_message ? 'Digitando ...' : 'Outra sugestão de mensagem'}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Popover
                        disableAutoFocus
                        disableEnforceFocus
                        disableRestoreFocus
                        anchorEl={anchorEmoji}
                        open={Boolean(anchorEmoji)}
                        onClose={() => setAnchorEmoji(null)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                      >
                        <PickerEmoji
                          onEmojiClick={(_, { emoji }) => onEmojiText(emoji)}
                          disableAutoFocus
                          disableSearchBar
                          disableSkinTonePicker
                          groupNames={{
                            smileys_people: 'Smileys e pessoas',
                            animals_nature: 'Animais e natureza',
                            food_drink: 'Comidas e bebidas',
                            travel_places: 'Viagens e lugares',
                            activities: 'Atividades',
                            objects: 'Objetos',
                            symbols: 'Símbolos',
                            flags: 'Bandeiras',
                            recently_used: 'Recentes'
                          }}
                          pickerStyle={{ boxShadow: '' }}
                        />
                      </Popover>

                      <TextField
                        id="id_distribution_textpost_createpost_autocomplete"
                        data-testid="dsMarketing-textpost-createpost"
                        fullWidth
                        name="post"
                        variant="outlined"
                        multiline
                        placeholder="Digite o seu texto"
                        minRows={12}
                        value={postValue}
                        onChange={e => {
                          setPostValue(e.target.value);
                        }}
                        disabled={loading_auto_message}
                        error={formik.touched.post && Boolean(formik.errors.post)}
                        onSelect={updateSelectionText}
                        inputRef={inputRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                size="small"
                                onClick={({ currentTarget }) => setAnchorEmoji(currentTarget)}
                              >
                                <SentimentSatisfiedRounded />
                              </IconButton>
                            </InputAdornment>
                          ),
                          classes: {
                            notchedOutline: classes.inputNoBorder,
                            root: classes.inputBackgroundColor
                          }
                        }}
                      />
                      {Boolean(Object.keys(formik.errors).length) && (
                        <Alert className={classes.disclaimerError} severity="error">
                          Preencha todos os campos obrigatórios
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Container>

        {/* {hasCarouselCardsSelected() && (
          <FormObserver channel="social-networks" values={formik.values} />
        )} */}
        <FormObserver channel="social-networks" values={formik.values} />
      </form>

      <CreateAccount
        open={isConnectWithFacebookOpen}
        onSubmit={handleSubmitProfile}
        onClose={() => setIsConnectWithFacebookOpen(false)}
        initiateWithoutModal={true}
      />
    </>
  );
};

export default CreatePost;
