import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Box,
  Avatar,
  Slider,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import { PlayArrowRounded, PauseRounded, Person } from '@material-ui/icons';
import useDate from '../../../../../hooks/date';
import { Celular2 } from '../../../../../utils/helpers/Masks';
import { COLOR_PRIMARY } from '../../../../../utils/helpers/Constants';

function MessageAudio({
  audio,
  self = false,
  avatar,
  onPlay = () => {},
  onPause = () => {},
  bgColorSelf = COLOR_PRIMARY,
  bgColorNoSelf = '#fff',
  item,
}) {
  audio = item && item.message;

  const player = useRef(null),
    { duration, getDate } = useDate(),
    [playAudio, setPlayAudio] = useState(false),
    [durationAudio, setDurationAudio] = useState(0),
    [currentString, setCurrentString] = useState('0:00'),
    [currentAudio, setCurrentAudio] = useState(0);

  useEffect(() => {
    const newCurrent = duration(durationAudio),
      stringCurrent = `${newCurrent.minutes}:${newCurrent.seconds}`;
    setCurrentString(stringCurrent);
  }, [durationAudio]);

  function _setDurationAudio(duration) {
    const tempDuration = duration === Infinity ? durationAudio : duration;
    setDurationAudio(tempDuration);
  }

  function _setCurrentAudio(current) {
    setCurrentAudio(current);
    const newCurrent = duration(current),
      stringCurrent = `${newCurrent.minutes}:${newCurrent.seconds}`;
    setCurrentString(stringCurrent);
  }

  function _playAudio() {
    player.current.play();
    setPlayAudio(true);
    onPlay();
  }

  function _playPause() {
    player.current.pause();
    setPlayAudio(false);
    onPause();
  }

  return (
    <Box
      flex="0 0 auto"
      display="flex"
      marginTop="5px"
      marginLeft={item.i_send ? '0px' : '14px'}
      marginBottom="6px"
      marginRight={item.i_send ? '10px' : '0px'}
      paddingLeft={item.i_send ? '20%' : '0px'}
      paddingRight={item.i_send ? '0px' : '20%'}
      justifyContent={item.i_send ? 'flex-end' : 'flex-start'}
      style={{
        opacity: 1,
        transform: 'none',
        transition: item.i_send
          ? 'opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          : 'opacity 257ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 171ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
    >
      <Grid
        style={{
          width: '22vw',
          display: 'flex',
          alignItems: 'center',
          minWidth: '0%',
          boxShadow: '0 1px .5px rgba(0, 0, 0, 0.13)',
          padding: '3px',
          borderRadius: '7.5px',
          backgroundColor: item.i_send ? bgColorSelf : bgColorNoSelf,
          color: item.i_send ? '#fff' : 'rgba(0, 0, 0, 0.45)',
        }}
        container
        spacing={1}
      >
        {item.is_group && !item.i_send && (
          <Grid item xs={12}>
            <Typography
              noWrap
              style={{
                fontSize: 11,
                padding: '3px 4px 5px 6px',
              }}
            >
              {`${Celular2(item.participant_phone)} ~${item.participant_name}`}
            </Typography>
          </Grid>
        )}

        <Grid item xs={3} style={{ display: self ? 'initial' : 'none' }}>
          <Avatar src={avatar} style={{ width: 55, height: 55 }}>
            <Person fontSize="large" />
          </Avatar>
        </Grid>
        <Grid item xs={2} style={{ placeSelf: 'center' }}>
          <ButtonBase
            onClick={() => (Boolean(playAudio) ? _playPause() : _playAudio())}
            color="primary"
            size="small"
          >
            {Boolean(playAudio) ? (
              <PauseRounded fontSize="large" />
            ) : (
              <PlayArrowRounded fontSize="large" />
            )}
          </ButtonBase>
        </Grid>
        <Grid item xs={7}>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="100%" display="flex" visibility="hidden">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
                  {currentString}
                </Typography>
                <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
                  {getDate('HH:mm')}
                </Typography>
              </Box>
            </Box>
            <Slider
              style={{ color: '#79886D' }}
              max={durationAudio}
              value={currentAudio}
              step={0.0000000001}
              onChange={(e, value) => {
                player.current.currentTime = value;
              }}
            />
            <Box width="100%" display="flex">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
                  {currentString}
                </Typography>
                <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
                  {getDate('HH:mm', item.data_envio)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: !self ? 'flex' : 'none',
            justifyContent: 'flex-end',
          }}
        >
          <Avatar src={avatar} style={{ width: 55, height: 55 }}>
            <Person fontSize="large" />
          </Avatar>
        </Grid>
        <audio
          style={{ display: 'none' }}
          ref={player}
          src={audio}
          onEnded={() => {
            setPlayAudio(false);
            setCurrentAudio(0);
          }}
          onDurationChange={({ currentTarget: { duration } }) =>
            _setDurationAudio(duration)
          }
          onTimeUpdate={({ currentTarget: { currentTime } }) =>
            _setCurrentAudio(currentTime)
          }
        />
      </Grid>
    </Box>
  );
}

MessageAudio.propTypes = {
  audio: PropTypes.string.isRequired,
  self: PropTypes.bool.isRequired,
  avatar: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
};

export default MessageAudio;
