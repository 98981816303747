import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import DialogTitle from '../../../../utils/components/DialogTitle';
import {
  CANVA_DESIGNS,
  DATASALES_CRM_API,
  AMBIENTE,
} from '../../../../utils/helpers/Constants';
import useStyles from './styles';
import api from '../../../../utils/helpers/Fetch';

function Canva({ 
  open, 
  offerId, 
  onClose, 
  openCanvaFromStudio,
  designCanvaFromStudio,
}) {

  const classes = useStyles();

  const [canvaIsOpen, setCanvaIsOpen] = useState(false);
  const [title, setTitle] = useState();
  const [designType, setDesignType] = useState();

  function fetchDesignLabel(){
      const fetchLabel = CANVA_DESIGNS.find(design => design.value  === designCanvaFromStudio);
      return fetchLabel.label;
  }

  async function handleDesignPublish(designId, designTitle, exportUrl) {
    try {
      await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/canva-design`,
        {
          offerId,
          designTitle: `${designId} - ${designTitle}`,
          exportUrl,
          designType: openCanvaFromStudio ? fetchDesignLabel() : designType.label,
        },
        'POST',
        null,
        null,
        false
      );

      onClose();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (canvaIsOpen || openCanvaFromStudio) {
      (async function () {
        if (window.Canva && window.Canva.DesignButton) {
          if (!window.canvaApi) {
            const api = await window.Canva.DesignButton.initialize({
              apiKey: 'DR3K-4oq6hMxDwyCDkUO1LPl',
            });

            window.canvaApi = api;
          }

          window.canvaApi.createDesign({
            design: {
              title: openCanvaFromStudio ? 'Propaganda 1' : title,
              type: openCanvaFromStudio ? designCanvaFromStudio : designType.value,
            },

            onDesignPublish: ({ exportUrl, designTitle, designId }) => {
              handleDesignPublish(designId, designTitle, exportUrl);
            },
            onDesignClose: () => {
              setCanvaIsOpen(false);
              onClose();
            },
          });
        }
      })();
    }
  }, [canvaIsOpen, title, designType,openCanvaFromStudio]);

  function handleCanvaOpen() {
    if (!title || !designType) return;

    setCanvaIsOpen(true);
  }

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleDesignTypeChange(value) {
    setDesignType(value);
  }

  if (canvaIsOpen) {
    return null;
  }

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      { !openCanvaFromStudio && (<>
      <DialogTitle onClose={onClose}>Canva</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Titulo"
              fullWidth
              value={title}
              variant="outlined"
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              classes={{ popper: classes.popper }}
              options={CANVA_DESIGNS}
              getOptionLabel={option => option.label}
              fullWidth
              value={designType}
              onChange={(_, value) => {
                handleDesignTypeChange(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Escolha um tipo de design"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!title || !designType}
          onClick={handleCanvaOpen}
        >
          Continuar
        </Button>
      </DialogActions>
      </>)}
    </Dialog>
  );
}

export default Canva;
