import React from 'react';
import {
  DatePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';

export default props => {
  if (props.withKeyboard) {
    return (
      <KeyboardDatePicker
        {...props}
        fullWidth
        minDateMessage="A data não deve ser anterior a data mínima"
        maxDateMessage="A data não deve ser posterior à data máxima"
        autoOk={props.autoOk || true}
        animateYearScrolling={props.animate || true}
        invalidDateMessage={props.invalidDateMessage || false}
        className={props.className}
        variant={props.variant || 'inline'}
        inputVariant="outlined"
        format={props.format || 'DD/MM/YYYY'}
        label={props.label}
        value={props.value}
        InputAdornmentProps={{ position: 'start' }}
        onChange={props.onChange}
      />
    );
  } else if (props.dateAndTime) {
    return (
      <KeyboardDateTimePicker
        {...props}
        fullWidth
        inputVariant="outlined"
        minDateMessage="A data não deve ser anterior a data mínima"
        maxDateMessage="A data não deve ser posterior à data máxima"
        ampm={false}
        autoOk={props.autoOk || true}
        animateYearScrolling={props.animate || true}
        invalidDateMessage={props.invalidDateMessage || false}
        className={props.className}
        variant={props.variant || 'inline'}
        format={props.format || 'DD/MM/YYYY HH:mm'}
        label={props.label}
        value={props.value}
        InputAdornmentProps={{ position: 'start' }}
        onChange={props.onChange}
      />
    );
  } else if (props.time) {
    return (
      <KeyboardTimePicker
        {...props}
        fullWidth
        inputVariant="outlined"
        minDateMessage="A data não deve ser anterior a data mínima"
        maxDateMessage="A data não deve ser posterior à data máxima"
        ampm={false}
        autoOk={props.autoOk || true}
        animateYearScrolling={props.animate || true}
        invalidDateMessage={props.invalidDateMessage || false}
        className={props.className}
        variant={props.variant || 'inline'}
        format={props.format || 'DD/MM/YYYY HH:mm'}
        label={props.label}
        value={props.value}
        InputAdornmentProps={{ position: 'start' }}
        onChange={props.onChange}
      />
    );
  } else {
    return (
      <DatePicker
        {...props}
        fullWidth
        autoOk={props.autoOk || true}
        minDateMessage="A data não deve ser anterior a data mínima"
        maxDateMessage="A data não deve ser posterior à data máxima"
        animateYearScrolling={props.animate || true}
        invalidDateMessage={props.invalidDateMessage || false}
        className={props.className}
        variant={props.variant || 'inline'}
        inputVariant="outlined"
        format={props.format || 'DD/MM/YYYY'}
        label={props.label}
        value={props.value}
        InputAdornmentProps={{ position: 'start' }}
        onChange={props.onChange}
      />
    );
  }
};
