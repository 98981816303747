import { Box } from '@material-ui/core';
import React from 'react';

function TabPanel(props) {
  const { children, value, tab } = props;

  return tab !== value ? <div /> : <Box>{children}</Box>;
}

export default TabPanel;
