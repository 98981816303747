import React from 'react';

import OctadeskIcon from '../../../../../../../assets/icons/marketing/Octadesk.svg';
import { useStyles } from '../../../styles';

export default function SMSWithoutCreditsMessage() {
  const classes = useStyles();

  return (
    <div
      className={classes.zeroCreditsContainer}
      data-testid="adsDistribution-WhereToDistribute-SMSWithoutCreditsMessage-component"
    >
      <p className={classes.zeroCreditsMessageBox} role="paragraph">
        É necessário ter créditos de SMS para selecionar esta opção.
        <br />
        <span className={classes.smsMessage2}>
          Clique neste ícone e solicite a compra com a nossa equipe.
        </span>
        <img src={OctadeskIcon} style={{ height: '30px' }} />
      </p>
    </div>
  );
}
