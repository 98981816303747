const INITIAL_STATE = {
  listReportBirthDate: [],
  listReportSMS: [],
  selectedStores: [],
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_BIRTH_REPORT':
      return {
        ...state,
        listReportBirthDate: action.payload,
        isLoading: false,
      };
    case 'GET_SMS':
      return {
        ...state,
        listReportSMS: action.payload,
        isLoading: false,
      };
    case 'SELECT_STORE':
      return { ...state, selectedStores: action.payload };
    case 'LOADING_REPORT':
      return { ...state, isLoading: true };
    default:
      return state;
  }
};
