/* eslint-disable */
import {
  Box,
  Avatar,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BarChart,
  CountCard,
  DatePicker,
  Panel,
  PieChart,
  Checkbox,
} from '../../../utils/components';
import {
  CELULARES,
  COLOR_PRIMARY,
  EMAILS,
  MES,
  PARETO,
} from '../../../utils/helpers/Constants';
import MyDate from '../../../utils/helpers/Date';
import { DivisaoUnidade, MoedaBrasil } from '../../../utils/helpers/Format';
import {
  getCabecalhoProspects,
  getListaSMSById,
  salvarTemplate,
} from '../../Campanhas/action/actionCampanha';
import {
  changeFiltro,
  changeFiltroSpread,
  changePerformance,
  clearFilter,
  excluirFiltro,
  filtroConsulta,
  getListaFiltro,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  getPeopleProspecteiPF,
  saveListProspecsPF,
  savePromocao,
  setFiltro,
  showFilter,
} from '../../Insights/actions/actions';
import {
  editarCampanha,
  getPessoasByIDCampanha,
  getResgateCampanha,
  getTodasPerformanceMes,
  getTodasPerformancePeriodo,
  setCampanha,
} from '../action/action';
import '../style/performance.css';
import ListaResgateDetalhe from './ListaResgateDetalhe';
import ModalEditarCampanha from './ModalEditarCampanha';
import ModalListaDetalhe from './ModalListaDetalhe';
import ModalPerfis from './ModalPerfis';

class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      showModalDetalhe: false,
      nome_campanha: '',
      filtroDataInicio: moment().subtract(30, 'days'),
      automacao: 0,
      filtroDataFim: moment(),
      filtroMes: MES[new Date().getMonth()],
      dataInicio: moment_tz(moment())
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      dataFim: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      dataInicioCampanha: moment_tz(moment())
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      dataFimCampanha: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      pareto: PARETO[0],
      optionList: '',
      nomeLista: '',
      disabled: true,
      showModal: false,
      showModalPerfis: false,
      showModalDetalhesCampanha: false,
      showModalSMS: false,
      showModalCarta: false,
      showModalEmail: false,
      showModalRedeSocial: false,
      showModalSalvarFiltro: false,
      showModalEditarCampanha: false,
      nomeCampanha: '',
      message: '',
      numero: '',
      nomeRemetente: '',
      remetente: '',
      email: '',
      subject: '',
      template: '',
      templateName: '',
      listasPromocao: [],
      selectedCelular: CELULARES,
      selectedTemplate: [],
      numerosList: [],
      emailList: [],
      selectedEmail: EMAILS,
      campanha: {},
      tipo_usuario: '',
      hasCreateCampaign: false,
      hasShippingMonitor: false,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  componentDidMount() {
    this.props.getListaFiltro();
    this.props.getCabecalhoProspects();

    const { filtroDataInicio, filtroDataFim, automacao } = this.state,
      request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
        grupoCampanha: 'CONCLUIDO',
        automacao,
      },
      permissions = JSON.parse(localStorage.getItem('permissions')),
      has_campanha_simplificado = permissions.relacionamento
        ? permissions.relacionamento.find(
            ({ serviceName }) => serviceName === 'campanha_simplificado'
          )
        : false,
      hasCreateCampaign = permissions.relacionamento
        ? permissions.relacionamento.find(
            ({ serviceName }) => serviceName === 'criar_campanha'
          )
        : false,
      hasShippingMonitor = permissions.relacionamento
        ? permissions.relacionamento.find(
            ({ serviceName }) => serviceName === 'monitor_de_envios'
          )
        : false;

    this.setState({
      hasCreateCampaign: Boolean(hasCreateCampaign),
      hasShippingMonitor: Boolean(hasShippingMonitor),
    });
    this.props.getTodasPerformanceMes(request);
    this.props.getResgateCampanha(request);

    if (has_campanha_simplificado) {
      this.setState({ tipo_usuario: 'simplificado' });
    }
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onEditCampanha(state, value) {
    this.setState({ campanha: { ...this.state.campanha, [state]: value } });
  }

  editCampanha() {
    this.setState({ showModalEditarCampanha: false });
    const {
      ID_CAMPANHA,
      nome_campanha,
      data_inicio_campanha,
      data_fim_campanha,
      automacao,
    } = this.state.campanha;
    const objCampanha = {
        id_campanha: ID_CAMPANHA,
        nome_campanha,
        automacao,
        data_inicio_campanha:
          moment(data_inicio_campanha).format('DD/MM/YYYY HH:mm'),
        data_fim_campanha: moment(data_fim_campanha).format('DD/MM/YYYY HH:mm'),
      },
      request = {
        filtroDataInicio: new MyDate(
          this.state.filtroDataInicio,
          'YYYY-MM-DD'
        ).getDate(),
        filtroDataFim: new MyDate(
          this.state.filtroDataFim,
          'YYYY-MM-DD'
        ).getDate(),
        automacao: this.state.automacao,
      };

    this.props.editarCampanha(objCampanha, request);
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim, automacao } = this.state;
      const request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
        grupoCampanha: 'CONCLUIDO',
        automacao,
      };
      this.props.getTodasPerformanceMes(request);
      if (!Boolean(automacao)) {
        this.props.getResgateCampanha(request);
      }
    });
  }

  selecionaLista(lista) {
    if (lista) {
      const filtro_completo = JSON.parse(lista.filtro_completo),
        filtro = JSON.parse(lista.filtro);
      this.props.filtroConsulta(filtro);
      this.props.changeFiltroSpread(filtro_completo);
      this.props.getPeopleProspecteiPF(filtro);
    } else {
      this.props.filtroConsulta({});
      this.props.getPeopleProspecteiPF();
      this.props.changeFiltroSpread();
    }

    this.props.setFiltro(lista);
  }

  salvarTemplate() {
    const date = new Date(),
      objTemplate = {
        template: this.state.template,
        templateName: this.state.templateName,
        date: moment(date.getDate()).format('DD/MM/YYYY HH:mm'),
      };

    this.props.salvarTemplate(objTemplate);
  }

  onKeyPressed(e) {
    const { nome_campanha, filtroDataInicio, filtroDataFim, automacao } =
        this.state,
      request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
        automacao,
      };

    if (e.keyCode === 13 && nome_campanha.length > 0) {
      this.props.getTodasPerformanceMes({ nome_campanha, ...request });
    } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
      this.onChangePeriodo();
    }
  }

  render() {
    const { classes, resgateCampanha } = this.props,
      {
        campanhas,
        visaoGeral,
        vendasHora,
        vendasLoja,
        vendasDia,
        genero,
        faixaEtaria,
      } = this.props.campanhas,
      {
        key,
        nome_campanha,
        showModalPerfis,
        filtroDataInicio,
        filtroDataFim,
        automacao,
        showModalEditarCampanha,
        showModalDetalhe,
        hasCreateCampaign,
        hasShippingMonitor,
      } = this.state,
      data = key === 0 ? visaoGeral : campanhas,
      request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
        automacao,
      },
      isContatosWhatsapp = lista =>
        lista.tipo_campanha === 'WHATSAPP' &&
        lista.id_lista_prospeccao === null;

    return (
      <div className={classes.root}>
        <Box className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Resultados das campanhas
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            size="lg"
            placeholder="Pesquisar campanha..."
            autoComplete="false"
            onChange={e => this.setState({ nome_campanha: e.target.value })}
            onKeyDown={this.onKeyPressed}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      nome_campanha.length > 0
                        ? this.props.getTodasPerformanceMes({
                            nome_campanha,
                            ...request,
                          })
                        : this.onChangePeriodo()
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className={classes.actions}>
          <Box
            display="flex"
            marginTop="20px"
            marginBottom="20px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="70%"
              marginRight="20px"
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por período
              </Typography>
              <Box display="flex" flexDirection="row">
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date =>
                    this.onChangePeriodo('filtroDataInicio', date)
                  }
                />

                <DatePicker
                  label="Até"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
                <Checkbox
                  value={Boolean(automacao)}
                  label="Automações"
                  onChange={({ target: { checked } }) =>
                    this.onChangePeriodo('automacao', Boolean(checked) ? 1 : 0)
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ cursor: !hasCreateCampaign ? 'not-allowed' : 'auto' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              disabled={!hasCreateCampaign}
              to={`/criar-campanha/${this.state.tipo_usuario}`}
              style={{
                backgroundColor: !hasCreateCampaign ? null : COLOR_PRIMARY,
                marginRight: 15,
              }}
            >
              Criar Campanha
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom="20px"
        >
          <Box>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 0 && classes.activeButton}
              onClick={() => this.setState({ key: 0 })}
            >
              Visão geral
            </Button>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 1 && classes.activeButton}
              onClick={() => this.setState({ key: 1 })}
            >
              Campanhas
            </Button>
          </Box>
        </Box>

        <div
          className={classes.content}
          style={
            this.props.loadingPerformance
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          {this.props.loadingPerformance ? (
            <CircularProgress style={{ color: COLOR_PRIMARY }} />
          ) : (
            data &&
            data.map((lista, key) => (
              <Fragment>
                <Panel
                  key={key}
                  avatar={
                    <Avatar className={classes.avatar}>
                      {lista.tipo_campanha === 'EMAIL' ? (
                        <Tooltip title="Email">
                          <Icon>email</Icon>
                        </Tooltip>
                      ) : lista.tipo_campanha === 'SMS' ? (
                        <Tooltip title="SMS">
                          <Icon>sms</Icon>
                        </Tooltip>
                      ) : lista.tipo_campanha === 'CARTA' ? (
                        <Tooltip title="Carta">
                          <Icon
                            size={16}
                            className={clsx('fas fa-envelope-open')}
                          />
                        </Tooltip>
                      ) : lista.tipo_campanha === 'FACEBOOK' ? (
                        <Tooltip title="Facebook">
                          <Icon
                            size={16}
                            className={clsx('fab fa-facebook-f')}
                          />
                        </Tooltip>
                      ) : lista.tipo_campanha === 'INSTAGRAM' ? (
                        <Tooltip title="Instagram">
                          <Icon
                            size={16}
                            className={clsx('fab fa-instagram')}
                          />
                        </Tooltip>
                      ) : lista.tipo_campanha === 'LINKEDIN' ? (
                        <Tooltip title="Linkedin">
                          <Icon
                            size={16}
                            className={clsx('fab fa-linkedin-in')}
                          />
                        </Tooltip>
                      ) : lista.tipo_campanha === 'WHATSAPP' ? (
                        <Tooltip title="Whatsapp">
                          <Icon size={16} className={clsx('fab fa-whatsapp')} />
                        </Tooltip>
                      ) : (
                        <Icon>sms</Icon>
                      )}
                    </Avatar>
                  }
                  title={`${
                    lista.ID_CAMPANHA ? `#${lista.ID_CAMPANHA} -` : ''
                  } ${lista.nome_campanha}`}
                  subheader={` ${moment(lista.data_inicio_campanha).format(
                    'DD/MM/YYYY'
                  )} até ${moment(lista.data_fim_campanha).format(
                    'DD/MM/YYYY'
                  )}`}
                  style={{ marginBottom: 20 }}
                  action={
                    lista.ID_CAMPANHA && (
                      <Fragment>
                        {!isContatosWhatsapp(lista) && (
                          <Button
                            size="large"
                            className={classes.button}
                            style={{ color: COLOR_PRIMARY }}
                            variant="text"
                            color="primary"
                            onClick={() => {
                              this.props.getPessoasByIDCampanha({
                                id_campanha: lista.ID_CAMPANHA,
                                filtroDataInicio: moment(
                                  lista.data_inicio_campanha
                                ).format('YYYY-MM-DD'),
                                filtroDataFim: moment(
                                  lista.data_fim_campanha
                                ).format('YYYY-MM-DD'),
                              });
                              this.setState({ showModalPerfis: true });
                            }}
                          >
                            Ver Perfis
                          </Button>
                        )}
                        {/* {hasShippingMonitor && (
                          <Button
                            size="large"
                            className={classes.button}
                            style={{ color: COLOR_PRIMARY }}
                            variant="text"
                            color="primary"
                            onClick={() => {
                              localStorage.setItem('currentModule', null);
                              localStorage.setItem('currentSettings', true);
                              this.props.history.push('/');
                            }}
                          >
                            Monitor de campanhas
                          </Button>
                        )} */}

                        <Button
                          size="large"
                          className={classes.button}
                          style={{ color: COLOR_PRIMARY }}
                          variant="text"
                          color="primary"
                          component={Link}
                          to={`/detalhes-campanha/${lista.ID_CAMPANHA}_${this.state.tipo_usuario}`}
                          onClick={() => this.props.setCampanha(lista)}
                        >
                          Mais Detalhes
                        </Button>
                        <Button
                          size="large"
                          className={classes.button}
                          style={{ color: COLOR_PRIMARY }}
                          variant="text"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              campanha: lista,
                              showModalEditarCampanha: true,
                            });
                          }}
                        >
                          Editar Campanha
                        </Button>
                      </Fragment>
                    )
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      width: isContatosWhatsapp(lista) ? '30%' : '100%',
                      margin: 'auto',
                    }}
                  >
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={
                        lista.pessoas_alcancadas
                          ? parseFloat(lista.pessoas_alcancadas)
                          : 0
                      }
                      title="Alcance"
                      icon="people"
                      style={{ marginRight: 20 }}
                    />

                    {!isContatosWhatsapp(lista) && (
                      <Fragment>
                        <CountCard
                          count
                          helpButton
                          helpContent={
                            <section
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                <strong>Campanha: </strong>
                                R$ {MoedaBrasil(lista.gastos_campanha || 0)}
                              </span>
                              <span>
                                <strong>Desconto: </strong>
                                R${' '}
                                {MoedaBrasil(lista.investimento_preco_2 || 0)}
                              </span>
                            </section>
                          }
                          end={
                            lista.gastos_campanha
                              ? parseFloat(lista.gastos_campanha) +
                                parseFloat(lista.investimento_preco_2)
                              : 0
                          }
                          prefix="R$ "
                          icon="build"
                          titleTypographyProps={{ variant: 'h6' }}
                          title="Investimento"
                          decimals={2}
                          style={{ marginRight: 20 }}
                        />

                        {this.state.tipo_usuario != 'simplificado' && (
                          <Fragment>
                            <CountCard
                              count
                              helpButton
                              helpContent={
                                <section
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span>
                                    <strong>Quantidade: </strong>
                                    {DivisaoUnidade(lista.qtde_vendas) || 0}
                                  </span>
                                  <span>
                                    <strong>Valor: </strong>
                                    R$ {MoedaBrasil(lista.valor_vendas || 0)}
                                  </span>
                                </section>
                              }
                              end={
                                lista.qtde_vendas
                                  ? parseFloat(lista.qtde_vendas)
                                  : 0
                              }
                              icon="score"
                              titleTypographyProps={{ variant: 'h6' }}
                              title="Vendas"
                              style={{ marginRight: 20 }}
                            />

                            <CountCard
                              count
                              helpButton
                              helpContent={
                                <section
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span>
                                    <strong>Faturamento: </strong>
                                    R$ {MoedaBrasil(lista.valor_vendas)}
                                  </span>
                                  <span>
                                    <strong>Ticket médio: </strong>
                                    R$ {MoedaBrasil(lista.ticket_medio)}
                                  </span>
                                </section>
                              }
                              end={
                                lista.valor_vendas
                                  ? parseFloat(lista.valor_vendas)
                                  : 0
                              }
                              prefix="R$ "
                              icon="add_shopping_cart"
                              titleTypographyProps={{ variant: 'h6' }}
                              title="Faturamento"
                              decimals={2}
                              style={{ marginRight: 20 }}
                            />

                            <CountCard
                              count
                              helpButton
                              helpContent={
                                <section
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span>
                                    R$ {MoedaBrasil(lista.valor_roi || 0)}
                                  </span>

                                  <span>{MoedaBrasil(lista.roi)}%</span>
                                </section>
                              }
                              prefix="R$ "
                              end={
                                lista.valor_roi
                                  ? parseFloat(lista.valor_roi)
                                  : 0
                              }
                              titleTypographyProps={{ variant: 'h6' }}
                              title="ROI"
                              decimals={2}
                              style={{ marginRight: 20 }}
                              icon="attach_money"
                            />

                            <CountCard
                              count
                              helpButton
                              helpContent={
                                <section
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span>
                                    R$ {MoedaBrasil(lista.margem || 0)}
                                  </span>

                                  <span>
                                    {MoedaBrasil(lista.margem_percentual || 0)}%
                                  </span>
                                </section>
                              }
                              end={
                                lista.margem
                                  ? parseFloat(lista.margem_percentual)
                                  : 0
                              }
                              suffix="%"
                              icon="trending_up"
                              titleTypographyProps={{ variant: 'h6' }}
                              title="Margem"
                              decimals={2}
                              style={{ marginRight: 20 }}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </Panel>
                {this.state.key === 0 &&
                  this.state.tipo_usuario != 'simplificado' && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <section className="visaoResgate">
                        <Panel
                          action={
                            <Button
                              size="large"
                              className={classes.button}
                              style={{ color: COLOR_PRIMARY }}
                              variant="text"
                              color="primary"
                              onClick={() =>
                                this.setState({ showModalDetalhe: true })
                              }
                            >
                              Ver detalhe
                            </Button>
                          }
                          avatar={
                            <Avatar className={classes.avatar}>
                              <Icon>shopping_cart</Icon>
                            </Avatar>
                          }
                          subheader={` ${moment(lista.data_inicio).format(
                            'DD/MM/YYYY'
                          )} até ${moment(lista.data_fim).format(
                            'DD/MM/YYYY'
                          )}`}
                          title="Resgate de clientes"
                          style={{ marginBottom: 20 }}
                        >
                          <ListaResgateDetalhe lista={resgateCampanha} />
                        </Panel>
                      </section>
                      <Panel
                        width="100%"
                        title="Por loja "
                        style={{ marginBottom: 20 }}
                      >
                        <BarChart
                          data={vendasLoja}
                          barDataKey="qtde_vendas"
                          xDataKey="nome_loja"
                          legendFormatter="Qtde. vendas"
                          tooltipFormatter="Qtde. vendas"
                          hide
                        />
                      </Panel>
                      <Panel
                        width="100%"
                        title="Por dia "
                        style={{ marginBottom: 20 }}
                      >
                        <BarChart
                          data={vendasDia}
                          barDataKey="qtde_vendas"
                          xDataKey="DATA_ANALISE"
                          legendFormatter="Qtde. vendas"
                          tooltipFormatter="Qtde. vendas"
                        />
                      </Panel>
                      <Panel
                        width="100%"
                        title="Vendas por hora "
                        style={{ marginBottom: 20 }}
                      >
                        <BarChart
                          data={vendasHora}
                          barDataKey="qtde_vendas"
                          xDataKey="HORA_ANALISE"
                          legendFormatter="Qtde. vendas"
                          tooltipFormatter="Qtde. vendas"
                        />
                      </Panel>
                      <Panel
                        width="49%"
                        title=" Genero"
                        style={{ marginBottom: 20 }}
                      >
                        <PieChart data={genero} />
                      </Panel>
                      <Panel
                        width="49%"
                        title="Faixa etaria"
                        style={{ marginBottom: 20 }}
                      >
                        <PieChart data={faixaEtaria} />
                      </Panel>
                    </div>
                  )}
              </Fragment>
            ))
          )}
        </div>
        {showModalPerfis && (
          <ModalPerfis
            open={showModalPerfis}
            pessoa={this.state}
            onClose={() => this.setState({ showModalPerfis: false })}
          />
        )}
        {showModalEditarCampanha && (
          <ModalEditarCampanha
            open={showModalEditarCampanha}
            campanhaState={this.state.campanha}
            onEditCampanha={() => this.editCampanha()}
            onChange={(state, value) => this.onEditCampanha(state, value)}
            onClose={() => this.setState({ showModalEditarCampanha: false })}
          />
        )}
        {showModalDetalhe && (
          <ModalListaDetalhe
            open={showModalDetalhe}
            onClose={() => this.setState({ showModalDetalhe: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },

  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({
  RoutesReducer,
  InsightsReducer,
  PerformanceReducer,
  CampaignReducer,
}) => ({
  ...PerformanceReducer,
  ...CampaignReducer,
  ...RoutesReducer,
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getListaSMSById,
  showFilter,
  setFiltro,
  changeFiltro,
  clearFilter,
  getListaFiltro,
  getPeopleProspecteiPF,
  changeFiltroSpread,
  saveListProspecsPF,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  savePromocao,
  changePerformance,
  filtroConsulta,
  excluirFiltro,
  getCabecalhoProspects,
  salvarTemplate,
  getTodasPerformanceMes,
  getPessoasByIDCampanha,
  getTodasPerformancePeriodo,
  setCampanha,
  editarCampanha,
  getResgateCampanha,
};

const PerformanceConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Performance);

export default withStyles(styles)(PerformanceConnect);
