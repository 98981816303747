import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import MyDate from '../../../utils/helpers/Date';
import { COLOR_PRIMARY, UNIDADE_TEMPO, DIAS, DIAS_MES } from '../../../utils/helpers/Constants';
import Tabs from './constVisoes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  MenuItem,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuList,
} from '@material-ui/core';
import {
  showFilter,
  setFiltro,
  changeFiltro,
  clearFilter,
  getListaFiltro,
  changeFiltroSpread,
  saveListProspecsPF,
  updateListProspecsPF,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  savePromocao,
  changePerformance,
  filtroConsulta,
  excluirFiltro,
  postReprocessaAudiencia,
  getReprocessoAudiencia,
  putReprocessaAudiencia,
  postClusterAudiencia,
} from '../actions/actions';
import { Chip, SplitButton } from '../../../utils/components/index';
import { useAlerts } from '../../../utils/helpers/Alert';
import ModalSelecionarFiltro from './ModalSelecionarFiltro';
import ModalPerfis from './ModalPerfis';
import ModalSalvarFiltro from './ModalSalvarFiltro';
import ModalReprocessaAudiencia from './ModalReprocessaAudiencia';

function ResultadoInsights(props) {
  const [key, setKey] = useState(0),
    [open, setOpen] = useState(false),
    [modalPerfis, setModalOpen] = useState(false),
    [replica, setReplica] = useState(false),
    [reprocessar, setReprocessar] = useState(false),
    [reprocessaAudiencia, setReprocessaAudiencia] = useState({}),
    [nomeFiltro, setNomeFiltro] = useState(''),
    [cluster, setClusterFiltro] = useState({}),
    [openButtonEnriquecimento, setOpenButtonEnriquecimento] = useState(false),
    anchorRef = React.useRef(null),
    classes = useStyles(),
    alerts = useAlerts(),
    history = useHistory(),
    { listaFiltro } = props;

  useEffect(() => {
    if (props.reprocessoAudiencia !== reprocessaAudiencia && props.reprocessoAudiencia) {
      const unitTimeAutomacao = UNIDADE_TEMPO.find(
        tempo => tempo.value === props.reprocessoAudiencia.UNID_TEMPO_AUTOMACAO
      );
      let diaDisparoAutomacao = props.reprocessoAudiencia.DIA_AUTOMACAO
        ? props.reprocessoAudiencia.UNID_TEMPO_AUTOMACAO !== 'months'
          ? DIAS
          : DIAS_MES
        : null;

      diaDisparoAutomacao = diaDisparoAutomacao
        ? diaDisparoAutomacao.find(
            dia => dia.value === parseInt(props.reprocessoAudiencia.DIA_AUTOMACAO, 10)
          )
        : null;
      const reprocesso = {
        id_reprocesso: props.reprocessoAudiencia.ID_REPROCESSO,
        active: props.reprocessoAudiencia.ATIVO,
        unitAutomacao: props.reprocessoAudiencia.UNID_AUTOMACAO,
        unitTimeAutomacao,
        diaDisparoAutomacao,
      };
      setReprocessaAudiencia(reprocesso);
    } else {
      setReprocessaAudiencia({});
    }
  }, [props.reprocessoAudiencia]);

  function selecionaLista(lista) {
    if (lista) {
      const filtro_completo = JSON.parse(lista.filtro_completo),
        filtro = JSON.parse(lista.filtro);
      setNomeFiltro(`${lista.nameList} ${new MyDate().getDate('DD/MM/YYYY HH:mm')}`);
      props.filtroConsulta({
        ...filtro,
        idListProspectei: lista.idListProspectei,
      });
      props.changeFiltroSpread(filtro_completo);
      props.getReprocessoAudiencia(lista.idListProspectei);
    } else {
      props.filtroConsulta({});
      props.changeFiltroSpread();
    }

    props.setFiltro(lista);
  }
  function numberToReal(numero) {
    let nmr = numero.toFixed(2).split('.');
    nmr[0] = 'R$ ' + nmr[0].split(/(?=(?:...)*$)/).join('.');
    return nmr[0];
  }

  function setTermometro(term) {
    switch (term) {
      case 1:
        return 'Quente';
      case 2:
        return 'Morno';
      case 3:
        return 'Congelando';
      case 4:
        return 'Congelado';
      default:
        return 'Congelado';
    }
  }

  function salvarReprocessoAudiencia() {
    const objAutomacao = {
      id_prospeccao: props.filtroSelecionado.idListProspectei,
      unid_tempo_automacao: reprocessaAudiencia.unitTimeAutomacao.value,
      unid_automacao: reprocessaAudiencia.unitAutomacao,
      hora_automacao: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      dia_automacao:
        reprocessaAudiencia.diaDisparoAutomacao && reprocessaAudiencia.diaDisparoAutomacao.value,
      active: Boolean(reprocessaAudiencia.active) ? 1 : 0,
      id_reprocesso: reprocessaAudiencia.id_reprocesso,
    };
    if (!Boolean(objAutomacao.id_reprocesso)) {
      props.postReprocessaAudiencia(objAutomacao);
    } else {
      props.putReprocessaAudiencia(objAutomacao);
    }
  }

  function salvarFiltro() {
    const objParameters = JSON.parse(props.filtroSelecionado.filtro),
      filtroCompleto = JSON.parse(props.filtroSelecionado.filtro_completo);
    setReplica(false);
    setReprocessar(false);

    props.saveListProspecsPF(
      {
        ...objParameters,
        nameList: nomeFiltro,
        idCluster: cluster.ID_CLUSTER_AUDIENCIA,
        namecluster: cluster.NOME,
      },
      filtroCompleto
    );
  }

  function updateInsights() {
    const audiencia = props.filtroSelecionado;

    alerts.confirm(`Deseja atualizar a audência ${audiencia.nameList} ?`, () => {
      const parameters = JSON.parse(audiencia.filtro),
        complete = JSON.parse(audiencia.filtro_completo);
      props.updateListProspecsPF(
        { ...parameters, idListProspectei: audiencia.idListProspectei },
        complete
      );
    });
  }

  function salvarCluster(cluster) {
    props.postClusterAudiencia(cluster);
  }

  function onExportCSV(idListaProspeccao) {
    setModalOpen(false);
    props.getListaFiltroCSV(idListaProspeccao);
  }
  ///

  const handleToggle = () => {
    setOpenButtonEnriquecimento(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButtonEnriquecimento(false);
  };

  return (
    <div className={classes.root}>
      {!props.showFiltro && (
        <div className={classes.onScreen}>
          {[props.filtroSegmentacao].map(filtro => (
            <Fragment>
              {filtro.selectedEstados &&
                filtro.selectedEstados.length > 0 &&
                filtro.selectedEstados.map(estado => (
                  <Chip
                    label={estado.sigla || estado.value}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedMunicipios &&
                filtro.selectedMunicipios.length > 0 &&
                filtro.selectedMunicipios.map(cidade => (
                  <Chip
                    label={cidade.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedBairros &&
                filtro.selectedBairros.length > 0 &&
                filtro.selectedBairros.map(bairro => (
                  <Chip
                    label={bairro.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedDDD &&
                filtro.selectedDDD.length > 0 &&
                filtro.selectedDDD.map(ddd => (
                  <Chip
                    label={ddd.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedMesNascimento &&
                filtro.selectedMesNascimento.length > 0 &&
                filtro.selectedMesNascimento.map(mes => (
                  <Chip
                    label={mes.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedProfissao &&
                filtro.selectedProfissao.length > 0 &&
                filtro.selectedProfissao.map(profissao => (
                  <Chip
                    label={profissao.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.faixaEtaria && filtro.faixaEtaria.length > 0 && (
                <Chip
                  label={`${filtro.faixaEtaria[0]} a ${filtro.faixaEtaria[1]} anos `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.dataCadastroInicio && filtro.dataCadastroFim && (
                <Chip
                  label={`${new MyDate(
                    filtro.dataCadastroInicio,
                    'DD/MM/YYYY'
                  ).getDate()} a ${new MyDate(filtro.dataCadastroFim, 'DD/MM/YYYY').getDate()}  `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.genre && (
                <Chip
                  label={filtro.genre.split(',')[0]}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.status && (
                <Chip
                  label={filtro.status.label || filtro.status ? 'Ativo' : 'Inativo'}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.termometro && (
                <Chip
                  label={filtro.termometro.label || setTermometro(filtro.termometro)}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.eCliente && (
                <Chip
                  label={`Cliente: ${filtro.eCliente.label || filtro.eCliente ? 'Sim' : 'Não'}`}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}

              {filtro.email && (
                <Chip
                  label={`Email: ${filtro.email.label || filtro.email ? 'Sim' : 'Não'}`}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.telefone && (
                <Chip
                  label={`Telefone: ${filtro.telefone.label || filtro.telefone ? 'Sim' : 'Não'}`}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.celular && (
                <Chip
                  label={`Celular: ${filtro.celular.label || filtro.celular ? 'Sim' : 'Não'}`}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.endereco && (
                <Chip
                  label={`Endereço: ${filtro.endereco.label || filtro.endereco ? 'Sim' : 'Não'}`}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}

              {filtro.dataCadastroInicio && filtro.dataCadastroFim && (
                <Chip
                  label={`${new MyDate(
                    filtro.dataCadastroInicio,
                    'DD/MM/YYYY'
                  ).getDate()} a ${new MyDate(filtro.dataCadastroFim, 'DD/MM/YYYY').getDate()}  `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.selectedStores &&
                filtro.selectedStores.length > 0 &&
                filtro.selectedStores.map(store => (
                  <Chip
                    label={store.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.qtdeCompras && filtro.qtdeCompras.length > 0 && (
                <Chip
                  label={`${filtro.qtdeCompras[0]} a ${filtro.qtdeCompras[1]} unidades `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.valorCompras && filtro.valorCompras.length > 0 && (
                <Chip
                  label={`${numberToReal(filtro.valorCompras[0])} a ${numberToReal(
                    filtro.valorCompras[1]
                  )} valor gasto `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.ticketMedio && filtro.ticketMedio.length > 0 && (
                <Chip
                  label={`${numberToReal(filtro.ticketMedio[0])} a ${numberToReal(
                    filtro.ticketMedio[1]
                  )} ticket médio `}
                  color="primary"
                  className={classes.chip}
                  variant="outlined"
                />
              )}
              {filtro.categoria_1 &&
                filtro.categoria_1.length > 0 &&
                filtro.categoria_1.map(grupo => (
                  <Chip
                    label={grupo.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.categoria_2 &&
                filtro.categoria_2.length > 0 &&
                filtro.categoria_2.map(familia => (
                  <Chip
                    label={familia.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedProdutos &&
                filtro.selectedProdutos.length > 0 &&
                filtro.selectedProdutos.map(produto => (
                  <Chip
                    label={produto.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}

              {filtro.categoria_6 &&
                filtro.categoria_6.length > 0 &&
                filtro.categoria_6.map(colecao => (
                  <Chip
                    label={colecao.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.selectedTamanhos &&
                filtro.selectedTamanhos.length > 0 &&
                filtro.selectedTamanhos.map(tamanho => (
                  <Chip
                    label={tamanho.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.categoria_4 &&
                filtro.categoria_4.length > 0 &&
                filtro.categoria_4.map(cor => (
                  <Chip
                    label={cor.label || cor.cor}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
              {filtro.categoria_5 &&
                filtro.categoria_5.length > 0 &&
                filtro.categoria_5.map(fornecedor => (
                  <Chip
                    label={fornecedor.label}
                    color="primary"
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}
            </Fragment>
          ))}
        </div>
      )}
      <Box display="flex" flexGrow="1" marginTop="16px" alignItems="center" marginBottom="24px">
        <Typography variant="h3" noWrap>
          {!props.filtroSelecionado.nameList ? 'Audiência' : props.filtroSelecionado.nameList}
        </Typography>

        {props.filtroSelecionado.nome_cluster && (
          <Typography variant="h3" noWrap>
            &nbsp;|&nbsp;{props.filtroSelecionado.nome_cluster}
          </Typography>
        )}
      </Box>
      {!props.showFiltro && (
        <Grid container>
          <Grid xs={5} item container>
            <Grid style={{ display: 'flex' }} item xs={3} justify="center">
              <Button
                color="primary"
                size="large"
                variant="outlined"
                className={key === 0 && classes.activeButton}
                onClick={() => setKey(0)}>
                Visão geral
              </Button>
            </Grid>
            <Grid style={{ display: 'flex' }} item xs={3} justify="center">
              <Button
                color="primary"
                size="large"
                variant="outlined"
                className={key === 1 && classes.activeButton}
                onClick={() => setKey(1)}>
                Visão analítica
              </Button>
            </Grid>

            {props.reprocessoAudiencia && props.reprocessoAudiencia.ID_REPROCESSO && (
              <Grid style={{ display: 'flex' }} item xs={3} justify="center">
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  className={key === 2 && classes.activeButton}
                  onClick={() => setKey(2)}>
                  Movimentação
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid xs={7} item container justify="flex-end">
            <Grid style={{ display: 'flex' }} item xs={3} justify="center">
              {/* <Button
                variant="outlined"
                size="large"
                color="primary"
                style={{ color: COLOR_PRIMARY }}
                onClick={() => setOpen(true)}>
                Audiências
              </Button> */}
              <ButtonGroup
                variant="outlined"
                color="primary"
                ref={anchorRef}
                aria-label="split button">
                <Button onClick={() => setOpen(true)}>Audiências</Button>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}>
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={openButtonEnriquecimento}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          <MenuItem onClick={() => history.push('/enriquecimento')}>
                            Enriquecimento
                          </MenuItem>
                          <MenuItem onClick={() => history.push('/importacao')}>
                            Importação
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Grid style={{ display: 'flex' }} item xs={2} justify="center">
              <Button
                onClick={() => setModalOpen(true)}
                size="large"
                variant="outlined"
                color="primary"
                style={{ color: COLOR_PRIMARY }}>
                Perfis
              </Button>
            </Grid>
            {props.filtroSelecionado.filtro_completo && (
              <Grid style={{ display: 'flex' }} item xs={2} justify="center">
                <SplitButton icon variant="outlined" text="Replicar" label="label">
                  <MenuItem onClick={() => setReplica(true)}>Copiar</MenuItem>
                  <MenuItem onClick={() => updateInsights()}>Atualizar</MenuItem>
                </SplitButton>
              </Grid>
            )}
            {props.filtroSelecionado.idListProspectei && (
              <Grid style={{ display: 'flex' }} item xs={3} justify="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY }}
                  onClick={() => setReprocessar(true)}>
                  Automatizar
                </Button>
              </Grid>
            )}
            {!props.showFiltro && (
              <Grid style={{ display: 'flex' }} item xs={2} justify="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY }}
                  onClick={() => props.showFilter()}>
                  {props.filtroSelecionado.nameList ? 'Editar' : 'Criar'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {Tabs.map((content, i) => key === content.id && content.component)}
      </div>
      {open && (
        <ModalSelecionarFiltro
          open={open}
          onClose={() => setOpen(false)}
          filtros={listaFiltro}
          filtro={props.filtroSelecionado}
          onSelect={filtro => {
            selecionaLista(filtro);
            setOpen(!open);
          }}
          onDelete={id => {
            props.excluirFiltro(id, () => props.setFiltro([]));
            props.clearFilter();
          }}
        />
      )}
      {modalPerfis && (
        <ModalPerfis
          open={modalPerfis}
          onExportCSV={idListaProspeccao => onExportCSV(idListaProspeccao)}
          onClose={() => setModalOpen(false)}
        />
      )}
      <ModalSalvarFiltro
        replica
        clusters={props.clustersAudiencia}
        open={replica}
        filter={nomeFiltro}
        onClose={() => setReplica(false)}
        onChange={(state, value) =>
          state === 'clusterName' ? setClusterFiltro(value) : setNomeFiltro(value)
        }
        onSave={() => salvarFiltro()}
        onSaveCluster={cluster => salvarCluster(cluster)}
      />

      {reprocessar && (
        <ModalReprocessaAudiencia
          open={reprocessar}
          value={reprocessaAudiencia}
          onClose={() => {
            setReprocessar(false);
          }}
          onSave={() => salvarReprocessoAudiencia()}
          onChange={state => setReprocessaAudiencia({ ...reprocessaAudiencia, ...state })}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },

  onScreen: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    height: 'auto',
    paddingTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  chip: {
    paddingTop: theme.spacing(4),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const mapStateToProps = ({ InsightsReducer }) => ({
  ...InsightsReducer,
});

const mapDispatchToProps = {
  showFilter,
  setFiltro,
  clearFilter,
  getListaFiltro,
  changeFiltroSpread,
  changeFiltro,
  saveListProspecsPF,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  savePromocao,
  changePerformance,
  filtroConsulta,
  excluirFiltro,
  postReprocessaAudiencia,
  getReprocessoAudiencia,
  putReprocessaAudiencia,
  postClusterAudiencia,
  updateListProspecsPF,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoInsights);
