import { useState } from 'react';
import { 
  Box,
  TextField, 
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Chip
} from '@mui/material';
import { Description } from '@mui/icons-material';
import { Dropzone } from 'utils/components';

interface propsProductsImport{
  open: boolean;
  onClose: () => void;
  onSave: (file: any) => void;
}

const identificadores = [
  'EAN',
  'CODIGO',
  'PRODUTO',
  'DE',
  'POR',
  'GRAMATURA',
  'CATEGORIA',
  'OBSERVAÇÃO'
];

export function ProductsImport({
  open,
  onClose,
  onSave
}: propsProductsImport) {
  const [textoImportacao, setTextoImportacao] = useState('');
  const [file,setFile] = useState(null);

  const onDropXlsx = (file: any) => {
    setFile(file);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
      aria-labelledby="product-import-dialog-title"
    >
      <DialogTitle
        fontStyle={'normal'}
        fontSize={'1rem'}
        lineHeight={'1.625rem'}
      >
        Importar produtos por planilha
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            marginY: '1rem'
          }}
        >
            <Box mb='0.5rem' gap={'0.5rem'} display='flex' flexDirection={'column'}>
              <Box>
                <Typography>Ordem das colunas do excel:</Typography>
              </Box>
              <Box display='flex' gap='0.5rem'>
                {identificadores.map(item => (
                  <Box>
                    <Chip label={item} clickable={false} />
                  </Box>
                ))}
              </Box>
            </Box>
          <Dropzone
            useChipsForPreview={true}
            maxFileSize={6000000}
            filesLimit={1}
            clearOnUnmount={false}
            style={{ backgroundColor: '#fff' }}
            dropzoneParagraphClass={`ds-text`}
            dropzoneText="Selecione ou arraste um arquivo xlsx"
            showFileNamesInPreview={true}
            showPreviewsInDropzone={true}
            previewChipProps={{ icon: <Description /> }}
            onDrop={onDropXlsx}

          />
          <Box 
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '2rem'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              sx={{
                background: '#FFFFFF',
                border: '1px solid #B0B0B0',
                color: '#1E1F21',
      
               
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSave(file);
              }}
            >
              Salvar Produtos
            </Button>
          </Box>
          
        </Box>
        </DialogContent>
      </Dialog>
  );
}
