import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DistributionTypeProps } from '../../../../hooks/api/distribution/useAdsDistribution';
import {
  finishLoadingAdsDistributionAdd,
  initLoadingAdsDistributionAdd,
  setAdsDistributionSelectedChannelsDataAdd
} from '../../action';

type FormObserverProps = {
  channel: DistributionTypeProps;
  values?: {};
};

const FormObserverContext: React.FC<FormObserverProps> = ({ channel }) => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initLoadingAdsDistributionAdd());
    const timer = setTimeout(() => {
      // console.log('*+*+* {## FormObserverContext component} = »»»»: ', channel, values);
      dispatch(setAdsDistributionSelectedChannelsDataAdd({ name: channel, data: values }));
      dispatch(finishLoadingAdsDistributionAdd());
    }, 1000);

    return () => {
      clearTimeout(timer);
      dispatch(finishLoadingAdsDistributionAdd());
    };
  }, [values]);

  return <React.Fragment></React.Fragment>;
};

/**
 * @returns A component
 * @description A component that can be used to catch automatically the Form values and add to adsDistribution redux form states
 */
const FormObserver: React.FC<FormObserverProps> = ({ channel, values }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initLoadingAdsDistributionAdd());
    const timer = setTimeout(() => {
      dispatch(setAdsDistributionSelectedChannelsDataAdd({ name: channel, data: values }));
      dispatch(finishLoadingAdsDistributionAdd());
    }, 1000);

    return () => {
      clearTimeout(timer);
      dispatch(finishLoadingAdsDistributionAdd());
    };
  }, [values]);

  return null;
};

export { FormObserverContext };
export default FormObserver;
