/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Typography,
  Divider,
  withStyles,
  Icon,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MyDate from '../../../utils/helpers/Date';
import { CustomTable, Snackbar, SplitButton } from '../../../utils/components';

import ModalCashback from './ModalCashback';
import ModalCashbackMercado from './ModalCashbackMercado';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

class CriarOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oferta: {},
      personalizada: null,
      tipoOferta: '',
      filtro: {},
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      key: 0,
      showModalCashback: false,
      showModalCashbackMercado: false,
      showSnackbar: false,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangeOferta(state, value) {
    this.setState({ oferta: { ...this.state.oferta, [state]: value } });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim } = this.state;
      const request = {
        complete: 0,
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
      };
      this.props.getTodasPerformanceMes(request);
    });
  }

  onKeyPressed(e) {
    const { nome_campanha } = this.state;

    if (e.keyCode === 13 && nome_campanha.length > 0) {
      this.props.getTodasPerformanceMes({ nome_campanha });
    } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
      this.onChangePeriodo();
    }
  }

  render() {
    const {
      classes,
      isLoadingOfertas,
      ofertas,
      isLoadingProdutosCategoria,
    } = this.props;
    const {
      key,
      showModalCashback,
      showModalCashbackMercado,
      showSnackbar,
    } = this.state;

    return (
      <div className={classes.root}>
        <Link to="/cashback" style={{ textDecoration: 'none' }}>
          <Button
            size="small"
            style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
            color="primary"
          >
            <Icon>arrow_back_ios</Icon>Voltar
          </Button>
        </Link>
        <Typography className={classes.label} variant="h3" noWrap>
          Criar Cashback
        </Typography>
        <div className={classes.actions}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <section>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    '&:hover': { borderRadius: 10 },
                  }}
                  color="inherit"
                  size="large"
                  className={key === 0 && classes.activeButton}
                  onClick={() => this.setState({ key: 0 })}
                >
                  Moda
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    '&:hover': { borderRadius: 10 },
                  }}
                  color="inherit"
                  size="large"
                  className={key === 1 && classes.activeButton}
                  onClick={() => this.setState({ key: 1 })}
                >
                  Supermercado
                </Button>
              </section>
              <div>
                <SplitButton
                  text="Criar Cashback"
                  label="label"
                  className={classes.margin}
                >
                  <MenuItem
                    onClick={() =>
                      this.setState({
                        showModalCashback: true,
                      })
                    }
                  >
                    Moda
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.setState({
                        showModalCashbackMercado: true,
                      })
                    }
                  >
                    Supermercado
                  </MenuItem>
                </SplitButton>
                <Button
                  style={{ color: COLOR_PRIMARY }}
                  color="primary"
                  size="large"
                  variant="text"
                  component={Link}
                  to="/filtros"
                >
                  Criar Filtro
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div
          className={classes.content}
          style={
            isLoadingOfertas
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
            }}
            columns={[
              { title: 'Nome', field: 'nome_oferta' },
              { title: 'Tipo Oferta', field: 'tipo_oferta' },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  moment(rowData.data_inicio).format('DD/MM/YYYY'),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  moment(rowData.data_fim).format('DD/MM/YYYY'),
              },

              {
                title: 'Ações',
                field: '',
                render: rowData => (
                  <Button
                    size="large"
                    color="primary"
                    onClick={() =>
                      this.setState(
                        {
                          oferta: rowData,
                          personalizada: false,
                        },
                        () => this.props.getEditOferta(rowData.id_oferta)
                      )
                    }
                  >
                    Iniciar Oferta
                  </Button>
                ),
              },
            ]}
            data={ofertas.naoCompletas.filter(
              oferta => oferta.id_lista_prospeccao === null
            )}
          />
        </div>
        {showModalCashback && (
          <ModalCashback
            open={showModalCashback}
            onChange={(state, value) => this.onChangeOferta(state, value)}
            onClose={() => this.setState({ showModalCashback: false })}
          />
        )}
        {showModalCashbackMercado && (
          <ModalCashbackMercado
            open={showModalCashbackMercado}
            onClose={() => this.setState({ showModalCashbackMercado: false })}
          />
        )}

        <Snackbar
          open={showSnackbar}
          onClose={() => this.setState({ showSnackbar: false })}
          variant="success"
          message="Oferta inteligênte criada com sucesso"
        />
        {isLoadingProdutosCategoria && (
          <Snackbar
            open={isLoadingProdutosCategoria}
            variant="info"
            message="Carregando produtos"
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ OfertasReducer, CampaignReducer }) => ({
  ...OfertasReducer,
  ...CampaignReducer,
});

const mapDispatchToProps = {};

const CriarOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CriarOferta);

export default withStyles(styles)(CriarOfertaConnect);
