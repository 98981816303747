import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DialogTitle from '../../../../utils/components/DialogTitle';
import TabPanel from '../../../../utils/components/TabPanel';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import AnalyticalVision from './components/AnalyticalVision';
import Offers from './components/Offers';
import Overview from './components/Overview';
import PowerfulCombinations from './components/PowerfulCombinations';
import Strategy from './components/Strategy';
import useStyles from './styles';

function EditProduct({ open, data, onClose }) {
  const classes = useStyles();

  const { ean, sku, name, filter_id } = data;

  const [value, setValue] = useState(0);
  const [product, setProduct] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProduct = async () => {
    const response = await api(
      `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/product/${sku}`,
      { filter_id }
    );

    setProduct(response);
  };

  useEffect(() => {
    getProduct();
  }, []);

  const handleSubmitStrategy = async values => {
    try {
      await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/product/edit/${values.id}`,
        {
          ...values,
          price:
            typeof values.price === 'string'
              ? values.price.replace(/[^0-9.-]+/g, '')
              : values.price,
        },
        'PUT'
      );
    } catch (err) {
      console.log(err);
    } finally {
      getProduct();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle onClose={onClose}>
        {name}, {ean}, {sku}
      </DialogTitle>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
      >
        <Tab className={classes.tab} label="Visão geral" />
        <Tab className={classes.tab} label="Visão Analitca" />
        <Tab className={classes.tab} label="Estratégia" disabled={!filter_id} />
        <Tab className={classes.tab} label="Ofertas" />
        <Tab className={classes.tab} label="Combinações Potentes" />
      </Tabs>

      <Divider />
      <DialogContent className={classes.content}>
        {!product ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={40} color="primary" />
          </div>
        ) : (
          <React.Fragment>
            <TabPanel value={value} index={0}>
              <Overview sku={product.sku} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AnalyticalVision product={product} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Strategy product={product} onSubmit={handleSubmitStrategy} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Offers product={product} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <PowerfulCombinations sku={product.sku} />
            </TabPanel>
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}

EditProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditProduct;
