import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawerPaper: {
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawerContainer: {
    overflowX: 'hidden',
    padding: theme.spacing(2),
    minHeight: '100vh',
  },
  content: { padding: theme.spacing(4, 8) },
  section: { paddingRight: theme.spacing(4), paddingLeft: theme.spacing(4) },
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    borderWidth: '1px 0 0',
    borderStyle: 'solid',
    borderColor: theme.palette.divider

  },
}));

export default useStyles;
