import { Box, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../utils/components/TabPanel';
import { AMBIENTE, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import api from '../../utils/helpers/Fetch';

import Banners from './components/Banners';
import General from './components/General';
import Pages from './components/Pages';
import Sections from './components/Sections';
import Scripts from './components/Scripts';
import Colors from './components/Colors';
import Domains from './components/Domains';
import Contacts from './components/Contacts';

import useStyles from './styles';

function Sites() {
  const classes = useStyles();

  const [data, setData] = useState();
  const [value, setValue] = useState(0);

  const getSite = async () => {
    try {
      const response = await api(`${DATASALES_CRM_API}sites-${AMBIENTE}/sites`);

      setData(response.content);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSite();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!data) {
    return null;
  }

  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box pt={4}>
            <Typography variant="h3" noWrap>
              Site
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box pt={4} pb={2}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={handleChange}>
              <Tab className={classes.tab} label="Geral" />
              <Tab className={classes.tab} label="Banners" />
              <Tab className={classes.tab} label="Páginas" />
              <Tab className={classes.tab} label="Faixas" />
              <Tab className={classes.tab} label="Domínios" />
              <Tab className={classes.tab} label="Cores" />
              <Tab className={classes.tab} label="Scripts" />
              <Tab className={classes.tab} label="Contatos" />
            </Tabs>
            <Divider />
          </Box>
        </Grid>
        <Grid item>
          <TabPanel value={value} index={0}>
            <General data={data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Banners siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Pages siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Sections siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Domains siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Colors siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Scripts siteId={data.id} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Contacts siteId={data.id} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Sites;
