// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA0TKqk1i5Hpxloek2B2D6153X-iUaduok',
  authDomain: 'app-dsmarketing.firebaseapp.com',
  projectId: 'app-dsmarketing',
  storageBucket: 'app-dsmarketing.appspot.com',
  messagingSenderId: '768260156515',
  appId: '1:768260156515:web:16f9ffcccb4f53d9c31c56',
  measurementId: 'G-F0TSH8LQJQ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const findToken = async (): Promise<string> => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BAZtdzHnTiShnS291vlLEU7cFO5XtPYe5SkIoEj3lk2JDaufMr_fxblDU839kyFeVwZPhlHrgEMq3LG1lL0Po5w'
    });

    return token;
  }
  return '';
};

