import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  AMBIENTE,
  DATASALES_CRM_API,
  CAMPANHAS,
} from '../../../utils/helpers/Constants';
import api from '../../../utils/helpers/Fetch';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  SpeakerNotesOffRounded as SpeakerNotesOffIcon,
  SearchRounded as SearchIcon,
} from '@material-ui/icons';
import { CustomTable, AutoCompleteLab } from '../../../utils/components';
import { CPF, Celular } from '../../../utils/helpers/Masks';
import useStyles from '../style';

export default function Blacklist() {
  const classes = useStyles(),
    [selectedType, setSelectedType] = useState(null),
    [typeCampaign, setTypeCampaign] = useState(null),
    [loadingBlacklist, setLoadingBlacklist] = useState(false),
    [to, setTo] = useState(null),
    [blacklist, setBlacklist] = useState([]);

  async function fetchBlacklist(type) {
    try {
      setLoadingBlacklist(true);
      setTypeCampaign(type);
      let content = [];
      switch (type) {
        case 'S':
          const sms = await api(
            `${DATASALES_CRM_API}report-${AMBIENTE}/blacklist/sms`,
            { to }
          );
          content = sms.content;
          break;
        case 'E':
          const email = await api(
            `${DATASALES_CRM_API}report-${AMBIENTE}/blacklist/email`,
            { to }
          );
          content = email.content;
          break;
      }

      setBlacklist(content);
    } catch (err) {
    } finally {
      setLoadingBlacklist(false);
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              pt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h4" noWrap>
                Blacklist
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtros
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <AutoCompleteLab
                  groupLabel="label"
                  label="Tipos de campanha"
                  placeholder="Selecione um tipo"
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  value={selectedType}
                  getOptionLabel={option => option.label}
                  onChange={(event, value) => {
                    if (value) {
                      fetchBlacklist(value.value);
                    } else {
                      setBlacklist([]);
                      setTypeCampaign(null);
                      setTo('');
                    }
                    setSelectedType(value);
                  }}
                  options={CAMPANHAS}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Destinatário"
                  placeholder="Digite um destinatário"
                  value={to}
                  disabled={!typeCampaign}
                  onChange={({ target: { value } }) => setTo(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!typeCampaign}
                          onClick={() => fetchBlacklist(selectedType.value)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <SpeakerNotesOffIcon />
                  </Avatar>
                }
                title="Blacklist"
              />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <CustomTable
                  showTitle={false}
                  toolbar={false}
                  data={blacklist}
                  pageSize={10}
                  isLoading={loadingBlacklist}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  columns={[
                    {
                      title: 'Nome',
                      field: 'nome',
                      editable: 'never',
                    },
                    {
                      title: 'Documento',
                      field: 'documento',
                      editable: 'never',
                      render: row => CPF(row.documento),
                    },
                    {
                      title: typeCampaign === 'S' ? 'Celular 1' : 'Email 1',
                      field: typeCampaign === 'S' ? 'celular1' : 'email1',
                      editable: 'never',
                      render: row =>
                        typeCampaign === 'S'
                          ? Celular(row.celular1)
                          : row.email1,
                    },
                    {
                      title: typeCampaign === 'S' ? 'Celular 2' : 'Email 2',
                      field: typeCampaign === 'S' ? 'celular2' : 'email2',
                      editable: 'never',
                      render: row =>
                        typeCampaign === 'S'
                          ? Celular(row.celular2)
                          : row.email2,
                    },
                    {
                      title: typeCampaign === 'S' ? 'Celular 3' : 'Email 3',
                      field: typeCampaign === 'S' ? 'celular3' : 'email3',
                      editable: 'never',
                      render: row =>
                        typeCampaign === 'S'
                          ? Celular(row.celular3)
                          : row.email3,
                    },
                    {
                      title: typeCampaign === 'S' ? 'Celular 4' : 'Email 4',
                      field: typeCampaign === 'S' ? 'celular4' : 'email4',
                      editable: 'never',
                      render: row =>
                        typeCampaign === 'S'
                          ? Celular(row.celular4)
                          : row.email4,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
