const INITIAL_STATE = {
  objPessoa: {},
  pessoas: [],
  loadingPessoas: false,
  vendas: [],
  loadingVendas: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PESSOAS":
      return { ...state, pessoas: action.payload, loadingPessoas: false };
    case "LOADING_PESSOAS":
      return { ...state, loadingPessoas: true };
    case "VENDAS":
      return { ...state, vendas: action.payload, loadingVendas: false };
    case "LOADING_VENDAS":
      return { ...state, loadingVendas: true };
    default:
      return state;
  }
};
