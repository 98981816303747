import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const _loadingMetabase = () => dispatch =>
  dispatch({ type: "LOADING_METABASE" });

export const getDashboardMetabase = id_dashboard => dispatch => {
  dispatch(_loadingMetabase());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getDashboardMetabase`,
    { id_dashboard }
  ).then(payload => dispatch({ type: "URL_IFRAME", payload }));
};
