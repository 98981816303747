import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { getHistoricoStatusPedido } from "../action/action";
import { Modal, CustomTable } from "../../../utils/components";
import { COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import MyDate from "../../../utils/helpers/Date";

const _myDate = new MyDate();

function ModalHistoricoPedido(props) {
  useEffect(() => {
    props.getHistoricoStatusPedido(props.pedido.guid_pedido);
  }, []);
  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={props.onClose}
      header={props.pedido.guid_pedido}
    >
      <section style={{ padding: "0 2rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Histórico de status
        </Typography>
        <CustomTable
          data={props.historicoPedidos || []}
          isLoading={props.loadingProdPedido}
          title=""
          columnsButton
          pageSize={5}
          paging
          search
          headerStyle={{ zIndex: 0 }}
          style={{
            border: "none",
            boxShadow: "none",
            width: "100%",
          }}
          columns={[
            {
              title: "Comentário",
              field: "comentario",
            },
            {
              title: "Status",
              field: "status_pedido",
              render: (rowData) => _statusPedido(rowData.status_pedido),
            },

            {
              title: "Data da atualização",
              field: "data_status",
              render: (rowData) =>
                _myDate.getDate(
                  "DD/MM/YYYY HH:mm",
                  rowData.data_status.replace("Z", "")
                ),
            },
          ]}
        />
      </section>
    </Modal>
  );
}

function _statusPedido(status) {
  switch (status) {
    case 0:
      return "Cancelado";
    case 1:
      return "Pedido realizado";
    case 2:
      return "Em separação";
    case 3:
      return "Saiu para entrega";
    case 4:
      return "Disponível para retirada";
    case 5:
      return "Pedido entregue/retirada";
    default:
      return "Pedido realizado";
  }
}

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer,
});

const mapDispatchToProps = { getHistoricoStatusPedido };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalHistoricoPedido);
