import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React from 'react';
import './dropzone.css';

const Dropzone = props => {
  const { readonly, initialFiles, dropzoneClass, variant, ...other } = props,
  filesToUrl = () =>
    initialFiles.map(file => typeof file === 'object' ? URL.createObjectURL(file) : file);


  return (
    <DropzoneArea
      dropzoneClass={`ds-dropzone-${variant} ${readonly ? 'readonly' : ''} ${dropzoneClass}`}
      initialFiles={filesToUrl()}
      {...other}
    />
  );
};

Dropzone.propTypes = {
  readonly: PropTypes.bool,
  initialFiles: PropTypes.array,
  dropzoneClass: PropTypes.string,
  variant: PropTypes.oneOf(['one', 'two']),
};

Dropzone.defaultProps = {
  readonly: false,
  variant: 'one',
  dropzoneClass: '',
initialFiles: [],
};

export default Dropzone;

