import Alert from '../../../utils/helpers/Alert';
import {
  AMBIENTE,
  API_CHATBOT_V2,
  API_WHATSAPP_V2,
  DATASALES_CRM_API
} from '../../../utils/helpers/Constants';
import DSAWS from '../../../utils/helpers/DSAWS';
import Fetch from '../../../utils/helpers/Fetch';
import FetchAlternative from '../../../utils/helpers/FetchAlternative';
import constants from '../contants';

const alerta = new Alert();

const loading = () => dispatch => dispatch({ type: 'LOADING_CHATBOT' });
const loadingGatilhos = () => dispatch => dispatch({ type: 'LOADING_GATILHOS' });

export const getInstancias = request => async dispatch => {
    dispatch(loading());
    return await FetchAlternative(`${API_WHATSAPP_V2}instances/client/list`, request).then(
      async payload => {
        await dispatch({
          type: constants.GET_INSTANCIAS,
          payload: payload.content
        });
        return payload;
      }
    );
  },
  putInstancia =
    ({
      id,
      nome,
      id_loja,
      mensagem_nao_entendi,
      mensagem_inicial,
      save_contacts,
      mensagem_final_chat,
      duracao_chat,
      resposta_automatica,
      minutos_sem_resposta,
      own_number,
      tipo_instancia,
      ...rest
    }) =>
    async dispatch => {
      try {
        dispatch(loading());
        const objPutChatbot = {
          id: id,
          name: nome,
          store: id_loja,
          message_dont_understand: mensagem_nao_entendi,
          opening_message: mensagem_inicial,
          save_contacts: save_contacts,
          final_message_chat: mensagem_final_chat,
          chat_duration: duracao_chat,
          automatic_answer: resposta_automatica,
          unanswered_minutes: minutos_sem_resposta,
          // active FALTA POR
          own_number: own_number,
          instance_type: tipo_instancia,
          ...rest
        };
        const payload = await Fetch(
          `${API_WHATSAPP_V2}instances/client/update`,
          objPutChatbot,
          'PUT'
        );
        dispatch(getInstancias());
        dispatch({
          type: constants.PUT_INSTANCIA,
          payload: payload
        });
      } catch (error) {
        dispatch({
          type: constants.PUT_INSTANCIA,
          payload: error
        });
      }
    },
  deleteInstancia =
    ({ id }) =>
    async dispatch => {
      // console.log({ id });
      // return;
      try {
        dispatch(loading());
        const payload = await Fetch(`${API_WHATSAPP_V2}instances/client/delete`, { id }, 'DELETE');
        dispatch(getInstancias());
      } catch (error) {
        dispatch(getInstancias());
      }
    },
  getLojas = request => dispatch => {
    dispatch(loading());
    Fetch(`${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`, request).then(
      payload => {
        dispatch({
          type: constants.GET_LOJAS,
          payload: payload
        });
      }
    );
  },
  getStatusInstancia = idInstancia => async dispatch => {
    return await FetchAlternative(
      `${API_WHATSAPP_V2}zapi/qrcode/image`,
      {
        client: idInstancia
      },
      'POST',
      null,
      null,
      false
    )
      .then(async payload => {
        console.log({ payload });
        await dispatch({
          type: constants.GET_STATUS_INSTANCIA,
          payload: { ...payload.content, idInstancia }
        });
        return payload;
      })
      .catch(async err => {
        console.log({ err });
        if (err.message === 'Unreserved instance.') {
          err = {
            ...err,
            content: {
              connected: false
            }
          };
        } else {
          console.log({ err });
          return err;
        }
        await dispatch({
          type: constants.GET_STATUS_INSTANCIA,
          payload: { ...err.content, idInstancia }
        });
        return err;
      });
  },
  getCalendarios = instance => async dispatch => {
    dispatch(loading());
    let calendarios = [];
    const payload = await Fetch(`${API_CHATBOT_V2}calendars/list`, {
      instance_id: instance.id
    }).then(payload => {
      if (payload.content) {
        payload.content = payload.content.map(calendario => ({
          ...calendario,
          instancia: instance,
          gatilhos: []
        }));
        calendarios = [...calendarios, ...payload.content];
      }
    });

    dispatch({
      type: constants.GET_CALENDARIOS,
      payload: { calendarios }
    });
    return payload;
  },
  getGatilhos = calendario => async dispatch => {
    dispatch(loadingGatilhos());
    return await Fetch(`${API_CHATBOT_V2}trigger/list`, {
      calendar_id: calendario.id
    }).then(payload => {
      if (payload.content) {
        dispatch({
          type: constants.SET_GATILHOS,
          payload: payload.content,
          calendar_id: calendario.id
        });
      }
      return payload.content;
    });
  },
  deleteGatilho = obj => async dispatch => {
    dispatch(loadingGatilhos());
    return await Fetch(`${API_CHATBOT_V2}trigger/delete/${obj.trigger_id}`, {}, 'DELETE');
  },
  salvarCalendario = (calendario, instancia) => async dispatch => {
    try {
      alerta.alertProcess(
        'Aguarde um momento.',
        'Estamos organizando os dados, esse processo pode demorar alguns segundos.',
        {
          timer: 120 * 1000
        }
      );
      dispatch(loading());
      let calendar = {},
        trigger = {};

      if (calendario.id) {
        calendar = {
          calendar_id: calendario.id,
          start_date: calendario.data_inicio,
          end_date: calendario.data_final,
          title: calendario.titulo,
          description: calendario.descricao,
          keyword: calendario.palavra_chave,
          instance_id: calendario.id_instancia,
          active: calendario.ativo,
          transhipment: calendario.transbordo,
          webhook: calendario.webhook,
          address_webhook: calendario.webhook_endereco
        };
        calendar = await Fetch(
          `${API_CHATBOT_V2}calendars/update`,
          calendar,
          'PUT',
          null,
          null,
          false
        );
      } else {
        calendar = {
          start_date: calendario.data_inicio,
          end_date: calendario.data_final,
          title: calendario.titulo,
          description: calendario.descricao,
          keyword: calendario.palavra_chave,
          instance_id: calendario.id_instancia,
          active: calendario.ativo,
          transhipment: calendario.transbordo,
          webhook: calendario.webhook,
          address_webhook: calendario.webhook_endereco
        };
        calendar = await Fetch(
          `${API_CHATBOT_V2}calendars/create`,
          calendar,
          'POST',
          null,
          null,
          false
        );
      }

      if (calendario.gatilhos.length) {
        for (const gatilho of calendario.gatilhos) {
          let s3Response = {};
          if (gatilho.url) {
            s3Response = await DSAWS.saveS3({
              folderName: 'chatbot',
              file: gatilho.url,
              type: gatilho.url.type,
              extension: gatilho.url?.name?.split('.')[1]
            });
          }

          if (gatilho.id) {
            trigger = {
              trigger_id: gatilho.id,
              url: s3Response.Location,
              message: gatilho.mensagem
            };
            await Fetch(`${API_CHATBOT_V2}trigger/update`, trigger, 'PUT', null, null, false);
          } else {
            trigger = {
              calendar_id: calendar.content.calendar_id || calendario.id,
              url: s3Response.Location,
              message: gatilho.mensagem
            };

            await Fetch(`${API_CHATBOT_V2}trigger/create`, trigger, 'POST', null, null, false);
          }
        }
      }

      dispatch(getCalendarios(instancia));
      dispatch({
        type: constants.POST_CALENDARIO,
        payload: calendar
      });

      alerta.alertSuccess('', 'Sucesso', '');

      return calendar;
    } catch (error) {
      console.log(error);
      alerta.alertWarning('', 'Houve algum problema ao tentar salvar os dados!');
    }
  },
  ativarInativarCalendario =
    ({ id, ativo, instancia }) =>
    dispatch => {
      dispatch(loading());
      Fetch(`${API_CHATBOT_V2}calendars/update`, { active: !ativo, calendar_id: id }, 'PUT').then(
        payload => {
          dispatch(getCalendarios(instancia));
          dispatch({
            type: constants.PUT_CALENDARIO,
            payload: payload
          });
        }
      );
    },
  novoGatilho =
    (calendarioId, { url, mensagem }) =>
    dispatch => {
      // dispatch(loading());
      // Fetch(
      //   `${API_WHATSAPP}crm/calendarios/${calendarioId}/gatilhos`,
      //   { url, mensagem },
      //   'POST'
      // ).then(payload => {
      //   dispatch(getCalendarios());
      //   dispatch({
      //     type: constants.POST_GATILHO,
      //     payload: payload,
      //   });
      // });
    },
  // deletarGatilho =
  //   ({ id }) =>
  //   dispatch => {
  //     dispatch(loading());
  //     Fetch(`${API_WHATSAPP}crm/gatilhos/${id}`, undefined, 'DEL').then(
  //       payload => {
  //         dispatch(getCalendarios());
  //         dispatch({
  //           type: constants.DEL_GATILHO,
  //           payload: payload,
  //         });
  //       }
  //     );
  //   },
  updateGatilho =
    ({ id, url, mensagem }) =>
    dispatch => {
      // dispatch(loading());
      // Fetch(
      //   `${API_WHATSAPP}crm/gatilhos/${id}`,
      //   { url, mensagem },
      //   'PUT'
      // ).then(payload => {
      //   dispatch(getCalendarios());
      //   dispatch({
      //     type: constants.PUT_GATILHO,
      //     payload: payload,
      //   });
      // });
    },
  getContatosInstancia = request => dispatch => {
    dispatch(loading());
    return Fetch(`${API_WHATSAPP_V2}contacts/list`, {
      id: request.instancia,
      name: request.nome_contato,
      cellphone: request.numero,
      status: request.status
    }).then(payload => {
      dispatch({
        type: constants.GET_CONTATOS_INSTANCIA,
        payload: payload.content
      });
      return payload.content;
    });
  },
  postContato = async request => {
    await Fetch(
      `${API_WHATSAPP_V2}contacts/create`,
      {
        cellphone: request.numero,
        instance_id: request.id_instancia,
        name: request.nome_contato,
        active: request.ativo_envio
      },
      'POST'
    ).then(payload => payload);
  },
  deleteContato = request => async dispatch => {
    await Fetch(
      `${API_WHATSAPP_V2}contacts/delete`,
      {
        cellphone: request.numero,
        instance_id: request.id_instancia
      },
      'DELETE'
    ).then(payload => payload);
  },
  postInstanciaZapi = request => async dispatch => {
    //dispatch(loading());
    return await Fetch(
      `${API_WHATSAPP_V2}instances/client/create`,
      {
        name: request.nome,
        store: request.id_loja,
        instance_type: 1
      },
      'POST'
    )
      .then(payload => {
        return payload;
      })
      .catch(err => {
        return err;
      });
  },
  convidarContatos = message => async dispatch => {
    try {
      await Fetch(
        `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postAtivarContato`,
        message,
        'POST'
      );
    } catch (err) {
      console.log(err);
    }
  },
  ativarContatos =
    (instance, batch = false) =>
    async () => {
      try {
        await Fetch(
          `${API_WHATSAPP_V2}contacts/active`,
          { client: instance, batch },
          'POST',
          null,
          null,
          true
        );
      } catch (err) {
        console.log(err);
      }
    },
  updateContact = contact => Fetch(`${API_WHATSAPP_V2}contacts/update`, contact, 'PUT'),
  disconnectInstancia = request => async () => {
    await FetchAlternative(
      `${API_WHATSAPP_V2}zapi/disconnect`,
      {
        client: request.id
      },
      'POST',
      null,
      null,
      false
    );
    await FetchAlternative(
      `${API_WHATSAPP_V2}instances/remove-reserve`,
      {
        client: request.id
      },
      'POST',
      null,
      null,
      false
    );
  },
  reloadQrCode = (instance, tipo_instancia, provider) => async dispatch => {
    let response;
    try {
      response = await FetchAlternative(
        `${API_WHATSAPP_V2}instances/reserve`,
        {
          client: instance,
          instance_type: tipo_instancia,
          provider: provider || 'WHATTOAPI'
        },
        'POST',
        null,
        null,
        false
      )
        .then(resp => {
          console.log({ resp });
        })
        .catch(err => {
          if (err.message === 'All instances are in use.') {
            return {
              error: true
            };
          }
        });
    } catch (err) {
      console.log({ err });
    }

    try {
      console.log({ instance });
      dispatch(getStatusInstancia(instance));
    } catch (err) {
      console.log({ err });
      return err;
    }

    return response;
  },
  listGroups = instancia => async () => {
    try {
      const response = await Fetch(`${API_WHATSAPP_V2}groups/list`, {
        client: instancia
      });
      return response.content;
    } catch (err) {
      return err;
    }
  },
  groupParticipants = id => async () => {
    try {
      const response = await Fetch(`${API_WHATSAPP_V2}groups/participants`, {
        id
      });
      return response.content;
    } catch (err) {
      return err;
    }
  },
  createGroup = group => async () => {
    try {
      const response = await Fetch(
        `${API_WHATSAPP_V2}/groups/create`,
        {
          client: group.instancia,
          ...group
        },
        'POST'
      );
      return response;
    } catch (err) {
      return err;
    }
  };

