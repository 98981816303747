import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    fastModels: {
      width: '100vw',
      position: 'relative'
    }
  })
);
