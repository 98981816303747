import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Container } from "../../../utils/components";
import { selectStore } from "../actions/actionsRelatorios";
import { Nav, NavItem, Tab } from "react-bootstrap";
import ArrayTabs from "./ArrayTabs";

const Relatorios = props => (
  <Container>
    <section className="wrapperDashboard">
      <Tab.Container id="tabs-with-dropdown" defaultActiveKey={1}>
        <Fragment>
          <Nav bsstyle="tabs">
            {ArrayTabs.map(({ key, title }, index) => (
              <NavItem key={index} eventKey={key}>
                {title}
              </NavItem>
            ))}
          </Nav>

          <Tab.Content animation>
            {ArrayTabs.map(({ component, key }, index) => (
              <Tab.Pane key={index} index={index} eventKey={key}>
                {component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Fragment>
      </Tab.Container>
    </section>
  </Container>
);

const mapStateToProps = ({ ReportsReducer }) => ({ ...ReportsReducer });
const mapDispatchToProps = { selectStore };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Relatorios);
