import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// import DialogTitle from '../../../../utils/components/DialogTitle';
import TabPanel from '../../../../utils/components/TabPanel';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
// import BackgroundPreview from './BackgroundPreview';
import Loader from './Loader';
// import SelectBackground from './SelectBackground';
import SelectTemplate from './SelectTemplate';

// import StoreLogoPreview from './StoreLogoPreview';

function CreateFlyer(props) {
  let { open, offerId, onClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { id: offer_id } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [stores, setStores] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectBackgroundIsOpen, setSelectBackgroundIsOpen] = useState(false);
  const [selectTemplateIsOpen, setSelectTemplateIsOpen] = useState(false);

  const [openDialogContinuar, setOpenDialogContinuar] = useState(false);

  const formik = useFormik({
    initialValues: {
      offer_id
    },
    // validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  async function handleSubmit(values) {
    try {
      if (!values.background) {
        throw new Error('Selecione uma imagem de fundo');
      }

      const { template, background, products, tag } = values;

      const company_data = {
        logo_url: values.store && values.store.logo_url ? values.store.logo_url : null,
        description: values.description ? values.description : null,
        address: values.address ? values.address : null,
        phone_number: values.phone_number ? values.phone_number : null
      };

      const result = await api(
        (await config_ds()).api_editor + '/flyers',
        {
          byGoogleCloud: true,
          templateId: template.id,
          formatId: template.formatId,
          tagId: tag ? tag.id : null,
          tagURL: tag ? tag.imageURL : null,
          designURL: template.designURL,
          productQuantity: template.productQuantity,
          products: products,
          backgroundURL: background ? background.imageURL : null,
          //
          company_data
        },
        'POST',
        undefined,
        undefined,
        false
      );

      await Promise.all(
        result.outArray.map(
          async r =>
            await api(
              `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`,
              {
                offer_id: offerId,
                name: template.name,
                format_id: template.formatId,
                product_quantity: template.productQuantity,
                type: background.type,
                image_url: r.image_url,
                json_url: r.design_url
              },
              'POST',
              undefined,
              undefined,
              false
            )
        )
      );

      enqueueSnackbar('Arte gerada com sucesso', { variant: 'success' });

      setOpenDialogContinuar(true);
    } catch (err) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  function handleSelectBackground(background) {
    formik.setFieldValue('background', background);
    setTabValue(prevState => prevState + 1);
  }

  function handleSelectTemplateClose() {
    setSelectTemplateIsOpen(false);
  }

  function handleSelectTemplate(template) {
    formik.setFieldValue('template', template);
    setTabValue(prevState => prevState + 1);
  }

  async function fetchStores() {
    try {
      await api(`${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`).then(
        response => {
          setStores(
            map(response, s => ({
              id: s.id_loja,
              name: s.nome_loja,
              zip_code: s.cep,
              street_address: s.endereco,
              phone_number: s.whatsapp_phone_number,
              logo_url: s.logo1
            }))
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchProducts() {
    try {
      await api(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        { id_tabloide: offerId }
      ).then(response => {
        formik.setFieldValue(
          'products',
          map(response[0].produtos, p => ({
            id: p.id_tabloide_produto,
            name: p.nome_produto,
            // price: currencyFormat(p.preco_original),
            price: p.preco_original,
            club_price: p.preco_dois,
            image_url: p.imagem_produto,
            is_active: p.ativo
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchTags() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/tags`).then(response => {
        setTags(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // fetchStores();
    // fetchTags();
    fetchProducts();
  }, []);

  if (!stores) {
    return null;
  }

  return (
    <Fragment>
      <Grid container>
        <Divider />

        <Grid item>
          {/* className={classes.content}> */}
          <form onSubmit={formik.handleSubmit}>
            <TabPanel value={tabValue} index={0}>
              <SelectTemplate
                onSelect={handleSelectTemplate}
                externFormat={formik.values?.background}
              />
            </TabPanel>
          </form>

          {/* <TabPanel value={tabValue} index={0}>
          <BackgroundsList />
        </TabPanel> */}
        </Grid>
      </Grid>
      {formik.isSubmitting && <Loader open={true} isSubmiting={formik.isSubmitting} />}
    </Fragment>
  );
}

export default CreateFlyer;

