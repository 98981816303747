/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Modal } from '../../../../utils/components';
import {} from '../../action';
import Alert from '../../../../utils/helpers/Alert';
import CloseIcon from '@material-ui/icons/Close';

const alerta = new Alert();

class PixelixeIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      panelLegendasOpen: false,
    };
  }

  header(props, state) {
    return <Fragment>Edição Avançada</Fragment>;
  }

  step1(props, state) {
    return (
      <iframe
        title={'iFrame2'}
        src={this.props.src}
        frameBorder="0"
        width="100%"
        height="800"
        allowtransparency="true"
      />
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    const { classes } = this.props;

    return (
      <Dialog open={this.props.open} fullScreen>
        <Button
          variant="contained"
          onClick={() =>
            alerta.alertConfirm(
              `Deseja realmente fechar a janela? Caso tenha salvado uma edição, não feche e aguarde a mensagem de confirmação!`,
              () => this.props.onClose()
            )
          }
          style={{
            position: 'absolute',
            right: 15,
            top: 11,
          }}
        >
          Sair
        </Button>
        {this.renderStep(activeStep, this.props, this.state)}
        {!this.state.panelLegendasOpen && (
          <Button
            variant="contained"
            onClick={() => this.setState({ panelLegendasOpen: true })}
            style={{
              position: 'absolute',
              right: 15,
              bottom: 11,
            }}
          >
            Legendas
          </Button>
        )}
        {this.state.panelLegendasOpen && (
          <div
            style={{
              position: 'absolute',
              right: 15,
              bottom: 11,
              padding: 20,
              borderRadius: 10,
              backgroundColor: 'white',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                color="inherit"
                onClick={() => {
                  this.setState({ panelLegendasOpen: false });
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ margin: 10 }}>imagem-fundo</div>
            <div style={{ margin: 10 }}>logo-empresa-01</div>
            <div style={{ margin: 10 }}>descritivo-texto-001</div>
            <div style={{ margin: 10 }}>produto-descricao-01</div>
            <div style={{ margin: 10 }}>produto-preco-de-01</div>
            <div style={{ margin: 10 }}>produto-preco-por-01</div>
            <div style={{ margin: 10 }}>produto-imagem-01</div>
            <div style={{ margin: 10 }}>produto-unidade-01</div>
            <div style={{ margin: 10 }}>endereco-01</div>
            <div style={{ margin: 10 }}>endereco-02</div>
          </div>
        )}
      </Dialog>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10,
  },
  appBar: {
    position: 'relative',
    border: 'none',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {};

const PixelixeIframeConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PixelixeIframe);

export default withStyles(styles)(PixelixeIframeConnect);
