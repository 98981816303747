import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import './styles.css';

SwiperCore.use([Navigation, Pagination]);

const carouselItemsStateData = [
  'https://storage.googleapis.com/datasalesio-imagens/marketing.png',
  'https://storage.googleapis.com/datasalesio-imagens/login_carousel_01.png',
  'https://storage.googleapis.com/datasalesio-imagens/login_carousel_02.png'
];

export function Carousel({ roundedBorders = false }) {
  const [indexSlide, setIndexSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const slideTo = index => swiper.slideTo(index);

  const [firstImageLink, setFirstImageLink] = useState(
    'https://api.whatsapp.com/send/?phone=551141187715&text=Oi%2C+quero+conhecer+mais+sobre+os+adicionais+que+potencializam+os+neg%C3%B3cios&type=phone_number&app_absent=0'
  );

  useEffect(() => {
    if (swiper) {
      slideTo(indexSlide);
    }
  }, [indexSlide, swiper]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndexSlide(prev => (prev >= 2 ? 0 : prev + 1));
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSwiperInit = swiper => {
    setSwiper(swiper);
    // Defina o slide inicial para 0 (a primeira imagem) quando o Swiper for inicializado
    swiper.slideTo(0);
  };

  return (
    <div
      style={{
        minWidth: '60%',
        width: '60%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Swiper
        onSwiper={onSwiperInit} // Use a função onSwiperInit para configurar o slide inicial
        slidesPerView={1}
        spaceBetween={0}
        loop={carouselItemsStateData.length > 1}
        pagination={{
          clickable: true
        }}
        navigation={true}
        className={`dsCampaignPreviewCarrossel${
          carouselItemsStateData.length < 2 ? ' onlyOneCard' : ''
        }`}
      >
        {carouselItemsStateData.map((carouselCard, index) => (
          <SwiperSlide
            key={index}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <img
              style={{
                maxWidth: '100%',
                borderRadius: roundedBorders ? '1.2rem' : 0,
                cursor: 'pointer'
              }}
              alt="post-preview"
              src={carouselCard}
              onClick={() => {
                if (index === 0) {
                  window.open(firstImageLink);
                } else {
                  window.open(
                    'https://wa.me/551931994796?text=Quero%20conhecer%20mais%20sobre%20Datasales%20Faz',
                    '_blank'
                  );
                }
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

