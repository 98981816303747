import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    background: 'rgb(241, 243, 244)',
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    flexDirection: 'column',
  },
  img: {
    width: 150,
    paddingBottom: theme.spacing(2),
  },
}));

const TagPreview = ({ src }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.img} alt="loja logo" src={src} />
    </div>
  );
};

export default TagPreview;
