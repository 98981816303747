import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Box,
  Grow,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import {
  Modal,
  MyButton,
  CountCard,
  CustomTable,
} from '../../../utils/components';

// Using Action
import { getAnswerForm } from '../action';

import useDate from '../../../hooks/date';

function ModalAnswerForm(props) {
  const form = props.form,
    [question, setQuestion] = useState(null),
    { getDate } = useDate();

  useEffect(() => {
    if (Boolean(form) && Boolean(form.id_formulario) && Boolean(question)) {
      const findQuestion = {
        idForm: form.id_formulario,
        idQuestion: question.id_form_campo,
      };
      props.getAnswerForm(findQuestion);
    }
  }, [form, question]);

  function Footer() {
    return (
      <Box>
        <MyButton
          onClick={() => {
            props.onClose();
            setQuestion(null);
          }}
          color="default"
        >
          Fechar
        </MyButton>
      </Box>
    );
  }

  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={() => {
        props.onClose();
        setQuestion(null);
      }}
      header={form.nameForm}
      footer={<Footer />}
    >
      <Box width="100%">
        <Grow in={!Boolean(question)}>
          <Box hidden={Boolean(question)}>
            <List dense>
              {Boolean(form.fields) ? (
                form.fields.map(field => (
                  <Fragment>
                    <ListItem>
                      <ListItemText primary={field.label} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => setQuestion(field)}
                          edge="end"
                          aria-label="next"
                        >
                          <NavigateNext />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))
              ) : (
                <span />
              )}
            </List>
          </Box>
        </Grow>
        <Grow in={Boolean(question)}>
          <Box width="100%" hidden={!Boolean(question)}>
            <Box display="flex" alignItems="center" justifyContent="start">
              <IconButton onClick={() => setQuestion(null)}>
                <NavigateBefore />
              </IconButton>
              {Boolean(question) && (
                <Typography variant="body1">{question.label}</Typography>
              )}
            </Box>
            {Boolean(props.loadAnswerForm) ? (
              <Box textAlign="center" marginY="2vh">
                <CircularProgress size={22} thickness={4} />
              </Box>
            ) : (
              <Grid
                style={{ marginTop: '2vh', marginBottom: '2vh' }}
                container
                spacing={3}
              >
                {props.answerForm.count.map(ct => (
                  <Grid item xs={3}>
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={ct.quantidade}
                      title={ct.resposta}
                      icon="question_answer"
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            <Box marginY="2vh">
              <CustomTable
                isLoading={Boolean(props.loadAnswerForm)}
                data={props.answerForm.answers}
                columns={[
                  { title: 'Documento', field: 'documento' },
                  { title: 'Resposta', field: 'resposta' },
                  {
                    title: 'Data',
                    field: 'data_resposta',
                    render: row => (
                      <span>
                        {getDate('DD/MM/YYYY HH:mm', row.data_resposta)}
                      </span>
                    ),
                  },
                  { title: 'Endereço IP', field: 'ip_resposta' },
                ]}
              />
            </Box>
          </Box>
        </Grow>
      </Box>
    </Modal>
  );
}

const mapStateToProps = ({ FormsReducer }) => ({
  ...FormsReducer,
});

const mapDispatchToProps = { getAnswerForm };

export default connect(mapStateToProps, mapDispatchToProps)(ModalAnswerForm);
