// LOADERS TAB CLIENTE
export const loaderClienteTotal = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_TOTAL' });
export const loaderClienteSexo = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_SEXO' });
export const loaderClienteStatus = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_STATUS' });
export const loaderClienteTermometro = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_TERMOMETRO' });
export const loaderClienteIdade = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_IDADE' });
export const loaderClienteMapa = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_MAPA' });
export const loaderClienteTotalizadores = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_TOTALIZADORES' });
export const loaderClienteProfissao = () => dispatch =>
  dispatch({ type: 'LOADER_CLIENTE_PROFISSAO' });

// LOADERS TAB OVERVIEW
export const loaderOverviewGenre = () => dispatch =>
  dispatch({ type: 'LOADER_OVERVIEW_GENRE' });
export const loaderOverviewDDD = () => dispatch =>
  dispatch({ type: 'LOADER_OVERVIEW_DDD' });
export const loaderOverviewAge = () => dispatch =>
  dispatch({ type: 'LOADER_OVERVIEW_AGE' });
export const loaderOverviewRegisterDay = () => dispatch =>
  dispatch({ type: 'LOADER_OVERVIEW_REGISTER_DAY' });
export const loaderOverviewRegisterMonth = () => dispatch =>
  dispatch({ type: 'LOADER_OVERVIEW_REGISTER_MONTH' });
