import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { SelectedContactType } from 'hooks/api/distribution/utils/distributeSMS';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { StringSchema } from 'yup';

import useAdsDistribution from '../../../../hooks/api/distribution/useAdsDistribution';
import { StateProps } from '../../../../store/types';
import Form from './components/Form';
import { useStyles } from './styles';

export const SMS_VALIDATION_SCHEMA = yup.object().shape({
  selectedInstance: yup
    .object()
    .shape({
      id: yup.string(),
      ativo: yup.string(),
      codigo: yup.string(),
      id_empresa: yup.number(),
      id_loja: yup.number(),
      nome: yup.string(),
      own_number: yup.string().nullable().notRequired(),
      shared_instancia: yup.number(),
      status: yup.object().shape({
        connected: yup.boolean().default(false)
      }),
      tipo_instancia: yup.number()
    })
    .nullable()
    .required('Selecione uma das instâncias listadas'),
  scheduleData: yup.string(),
  selectedContactType: yup
    .object()
    .shape({
      cod: yup.string(),
      label: yup.string()
    })
    .nullable()
    .required('Selecione uma das opções de tipo de contato disponíveis'),
  numbersInCopy: yup
    .string()
    .when('selectedContactType', {
      is: (contactType: SelectedContactType) => {
        return contactType ? contactType.cod === 'cop' : false;
      },
      then: (schema: StringSchema) =>
        schema.required(
          'Você precisa digitar os números para qual você deseja fazer os envios em cópia'
        )
    })
    .nullable(),
  text: yup
    .string()
    .max(139, 'SMS aceita um máximo de 139 caracteres')
    .required('O campo de texto é obrigatório para envios através de SMS')
});

const CLICK_TRACKER = ' {{clickTracker}}';

export type Instance = {
  id: number;
  ativo: string;
  codigo: string;
  id_empresa: number;
  id_loja: number;
  nome: string;
  own_number: string;
  shared_instancia: number;
  status: {
    connected: boolean;
  };
  tipo_instancia: number;
};

export type FormModel = {
  selectedInstance: Instance | object | null;
  selectedContactType: string | SelectedContactType;
  numbersInCopy?: string;
  scheduleDate: string;
  text: string;
  credits: number | string;
  post?: undefined;
};

type FormProps = {
  backgroundId: number;
  isFromSchedulePost?: boolean;
  open: boolean;
  isModal: boolean;
  data: {
    selectedItems: Array<object>;
    offerId: number;
    offerName: string;
  };
  onClose: () => void;
};

const contactTypes: SelectedContactType[] = [
  {
    cod: 'all',
    label: 'todos'
  },
  {
    cod: 'ca',
    label: 'contatos ativos'
  },
  {
    cod: 'ci',
    label: 'contatos inativos'
  },
  {
    cod: 'cop',
    label: 'somente número em cópia'
  }
];

const CreateSms = ({
  open,
  isModal = true,
  data,
  onClose,
  backgroundId,
  isFromSchedulePost
}: FormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const countItens = data.selectedItems.length;

  const {
    adsDistribution: { selectedChannelsFormData, validateForm, currentTab },
    auto_message
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);

  const savedData = selectedChannelsFormData?.filter(
    currentChannel => currentChannel?.name === 'sms'
  )[0]?.data;

  const [onClickAutoMessage, setOnClickAutoMessage] = useState(false);

  const handleChangeOnClickAutoMessage = () => {
    setOnClickAutoMessage(prev => !prev);
  };

  const { validateThisForm } = useAdsDistribution();

  const formik = useFormik({
    initialValues: {
      selectedInstance: savedData?.selectedInstance || null,
      scheduleDate:
        savedData?.scheduleDate ||
        moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
      selectedContactType: savedData?.selectedContactType || contactTypes[0],
      numbersInCopy: savedData?.numbersInCopy || '',
      text: auto_message?.message || '',
      credits: '...'
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: SMS_VALIDATION_SCHEMA,
    onSubmit: () => {}
  });

  useEffect(() => {
    history.push(history.location.pathname + '?DistributionOnSms');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  useEffect(() => {
    validateThisForm(formik, 'sms');
  }, [formik.values, validateForm]);

  useEffect(() => {
    if ((Number(currentTab) === 2 || onClickAutoMessage) && !isFromSchedulePost) {
      if (auto_message.sms) {
        formik.setFieldValue('text', auto_message.sms);
      } else {
        formik.setFieldValue('text', auto_message.all);
      }
      handleChangeOnClickAutoMessage();
    }
  }, [auto_message.sms]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className={classes.formContainer}>
            <Form
              backgroundId={backgroundId}
              formik={formik}
              datas={{ contactTypes, countItens }}
              handleChangeOnClickAutoMessage={handleChangeOnClickAutoMessage}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateSms;
