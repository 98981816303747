import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, MyButton, ShowMore } from '../../../utils/components';
import ModalAddLojas from './ModalAddLojas';
import PesquisaCEP from '../../../utils/helpers/CEP';
import { CEP } from '../../../utils/helpers/Masks';
import Dialog from '../../../utils/helpers/Alert';
import { saveStore, listStore, putStore, deleteStore } from '../actions/action';
import { faUserEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Lojas extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      editStore: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSaveStore = this.onSaveStore.bind(this);
    this.onEditStore = this.onEditStore.bind(this);
  }

  componentDidMount() {
    this.props.listStore();
  }

  onInputChange(state, value) {
    this.setState({ store: { ...this.state.store, [state]: value } });
  }

  onClose() {
    this.setState({
      showModal: false,
      editStore: false,
      store: {},
    });
  }

  onSaveStore() {
    delete this.state.showModal;
    this.props.saveStore(this.state.store);
    this.onClose();
  }

  onEditStore() {
    delete this.state.showModal;

    this.props.putStore(this.state.store);
    this.onClose();
  }

  render() {
    const { showModal, editStore } = this.state;
    return (
      <Fragment>
        <Container>
          <MyButton
            bsstyle="success"
            onClick={() => this.setState({ showModal: true })}
            style={{ marginBottom: '2vh' }}>
            Adicionar loja
          </MyButton>

          <ShowMore
            loading={this.props.isLoadingStore}
            data={this.props.listStores}
            header={[
              { name: 'id_loja', title: 'Nº' },
              { name: 'nome_loja', title: 'Nome' },
              { name: false, title: 'Endereço' },
              { name: false, title: 'Ações' },
            ]}
            headerStyle={{
              display: 'grid',
              gridTemplateColumns: '5% 45% 45% 5%',
            }}
            dataFormat={row => (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '5% 45% 45% 5%',
                }}>
                <Fragment>
                  <span>{row.id_loja}</span>
                  <span>{row.nome_loja}</span>

                  <span>{`${row.cidade || ''} - ${row.uf || ''}, ${CEP(row.cep)}`}</span>
                  <section
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '48% 48%',
                      columnGap: '2%',
                    }}>
                    <MyButton
                      bsSize="xs"
                      bsstyle="primary"
                      onClick={() =>
                        this.setState({
                          showModal: true,
                          store: row,
                          editStore: true,
                        })
                      }>
                      <FontAwesomeIcon icon={faUserEdit} />
                    </MyButton>
                    <MyButton
                      bsSize="xs"
                      bsstyle="danger"
                      onClick={() =>
                        new Dialog().confirm('Deseja excluir essa loja? ', () =>
                          this.props.deleteStore(row.id_loja)
                        )
                      }>
                      <FontAwesomeIcon icon={faTimes} />
                    </MyButton>
                  </section>
                </Fragment>
              </div>
            )}
          />
          {showModal && (
            <ModalAddLojas
              editStore={editStore}
              onSearchCEP={e => PesquisaCEP(e.target.value, this.onInputChange)}
              data={this.state.store}
              onChange={this.onInputChange}
              onSaveStore={this.onSaveStore}
              onEditStore={this.onEditStore}
              onClose={() => this.onClose()}
              show={showModal}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ LojasReducer }) => ({ ...LojasReducer });
const mapDispatchToProps = {
  saveStore,
  listStore,
  putStore,
  deleteStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lojas);
