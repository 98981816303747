import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useStyles from './styles';

const QRCodeDialog = ({ open, instancia, onClose, reloadQrCode }) => {
  const classes = useStyles();
  const [errorResponse, setErrorResponse] = useState(false),
    handleClose = () => onClose();

  const onOpen = async () => {
    if (instancia) {
      const response = await reloadQrCode(
        instancia.id,
        instancia.tipo_instancia,
        instancia.provider
      );
      console.log('Response ReloadQRCode');
      console.log(response);
      if (response?.error) {
        setErrorResponse(true);
      } else {
        setErrorResponse(false);
      }
    }
  };

  useEffect(() => {
    onOpen();
  }, []);

  let qrcodeResult = (
    <Grid item style={{ margin: 'auto', padding: 30 }}>
      {'Carregando...'}
    </Grid>
  );

  if (errorResponse) {
    qrcodeResult = (
      <Grid item style={{ margin: 'auto', padding: 30 }}>
        {'A instancia Selecionada ja está em uso!'}
      </Grid>
    );
  }

  if (instancia.status && instancia.status.qr) {
    qrcodeResult = (
      <Grid item style={{ left: '30%', position: 'relative' }}>
        <CardMedia
          image={instancia.status.qr}
          title="QR Code"
          style={{ height: 200, width: 200 }}
        />
      </Grid>
    );
  }

  return (
    <div onClose={handleClose} open={open} className={classes.dialogQRCodeBackground}>
      <div className={classes.dialogQRCode}>
        <DialogTitle onClose={handleClose}>
          <strong>QR Code</strong>
        </DialogTitle>
        <Typography>
          Nós usamos os seus contatos do WhatsApp para enviar mensagens de SMS e Whatsapp.
        </Typography>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid className={classes.gridInfoQRCode}>
              <Typography className={classes.typographyInfoQRCode}>
                1 - Abra o WhatsApp em seu celular
              </Typography>
              <Typography className={classes.typographyInfoQRCode}>
                2 - Toque em Menu ou Configurações e selecione <strong>WhatsApp Web</strong>
              </Typography>
              <Typography className={classes.typographyInfoQRCode}>
                3 - Aponte a câmera de seu celular para o código abaixo
              </Typography>
            </Grid>
            {qrcodeResult}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

QRCodeDialog.propTypes = {
  instancia: PropTypes.object,
  open: PropTypes.bool.isRequired
  //onSave: PropTypes.func.isRequired,
};

QRCodeDialog.defaultProps = {
  instancia: {},
  lojas: [],
  open: false,
  onClose: () => {}
};

export default QRCodeDialog;

