import React, { Fragment } from 'react';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownRounded';
import { COLOR_PRIMARY } from '../../helpers/Constants';

const useStyles = makeStyles((theme, props) => ({
  paper: {
    zIndex: 200,
    marginTop: theme.spacing(3),
    left: 0,
    right: 0,
    padding: '4px',
    width: '100%',
    gap: "10px",
    // borderRadius: "4px",
    // padding: '8px',
    // background: '#FFFFFF',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: "4px",

  },
}));

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const classes = useStyles();

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <Fragment>
      <Button
        {...props}
        variant={props.variant || 'text'}
        color="primary"
        style={{ color: COLOR_PRIMARY, ...props.style }}
        ref={anchorRef}
        aria-label="split button"
        size="large"
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={props.icon && <ArrowDropDownIcon />}
      >
        {props.text}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: '3333' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow" className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onClick={handleClose}>{props.children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
