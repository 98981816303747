import React, { useState } from 'react';

//Using Components
import { Box, StepButton, Stepper, Step } from '@material-ui/core';
import { Modal, MyButton } from '../../../utils/components';
import TabInfoForm from './Tabs/TabInfoForm';
import TabFieldsForm from './Tabs/TabFieldsForm';
import TabColorForm from './Tabs/TabColorForm';
import TabReviewForm from './Tabs/TabReviewForm';

// Styles
import useStyles from './styles';

const steps = ['Informações', 'Campos', 'Cores', 'Review'];

export default function ModalEditForm(props) {
  const classes = useStyles(),
    [activeStep, setActiveStep] = useState(0);

  function Footer() {
    return (
      <div className={classes.footerModal}>
        {activeStep > 0 ? (
          <MyButton
            onClick={() => setActiveStep(activeStep - 1)}
            color="default"
          >
            Voltar
          </MyButton>
        ) : (
          <span />
        )}
        {activeStep === 3 ? (
          <MyButton
            onClick={() => {
              props.onSave();
              props.onClose();
            }}
          >
            Atualizar formulário
          </MyButton>
        ) : (
          <MyButton onClick={() => setActiveStep(activeStep + 1)}>
            Próximo
          </MyButton>
        )}
      </div>
    );
  }

  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={props.onClose}
      header="Editar formulário"
      footer={<Footer />}
    >
      <Stepper disabled nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepButton onClick={() => setActiveStep(index)}>{step}</StepButton>
          </Step>
        ))}
      </Stepper>
      <Box display="grid" padding="1rem 5rem" gridGap="2rem">
        {activeStep === 0 && (
          <TabInfoForm form={props.form} onChange={props.onChange} />
        )}
        {activeStep === 1 && (
          <TabFieldsForm form={props.form} onChange={props.onChange} />
        )}
        {activeStep === 2 && (
          <TabColorForm form={props.form} onChange={props.onChange} />
        )}
        {activeStep === 3 && <TabReviewForm form={props.form} />}
      </Box>
    </Modal>
  );
}
