import { Box, Typography } from '@mui/material';
import AlienSvg from '../../../../../assets/images/alienSVG.svg';

export function NotFoundBackgrounds(){

  return (
    <Box
     sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 6,
    }}
    >
      <Box
        component={'img'}
        src={AlienSvg}
        style={{
          width: '200px',
          height: '200px'
        }}
      />
      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '26px',
          color: '#0C20B5'
        }}
      >
        Nenhum filtro encontrado
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          size: '14px',
          lineHeight: '24px',
          color: '#616161'
        }}
      >
        Não há fundos com a combinação de Segmento e Ocasião 
        <br/>que você selecionou, tente filtrar outras opções.
      </Typography>
    </Box>
  );
}


