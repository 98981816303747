import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTable, DialogAlert } from '../../../utils/components/index';
import {
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
} from '../actions/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

function ModalSelecionarFiltro(props) {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(false);
  const { listaFiltro } = props;

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth="xl"
      maxWidth="xl"
    >
      <MuiDialogTitle onClose={props.onClose}>Filtros</MuiDialogTitle>
      <DialogContent>
        <CustomTable
          paging
          search
          isLoading={props.loadingClienteMapa}
          onRowClick={(columns, data) => props.onSelect(data)}
          actions={[
            {
              icon: 'delete',
              iconProps: { color: 'secondary' },
              tooltip: 'Excluir filtro',
              onClick: (event, rowData) => {
                setId(rowData.idListProspectei);
                setOpen(true);
              },
            },
          ]}
          columns={[
            {
              title: 'Audiência',
              field: 'nameList',
              render: row => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {row.automacao && (
                    <FontAwesomeIcon
                      style={{ marginRight: '1vw' }}
                      icon={faRobot}
                    />
                  )}
                  {row.nameList}
                </span>
              ),
            },
            { title: 'Cluster', field: 'nome_cluster' },
            {
              title: 'Data',
              field: 'dataFilter',
              render: row => (
                <span>
                  {moment(
                    row.dateFilter
                      ? row.dateFilter.replace('Z', '')
                      : new Date()
                  ).format('DD/MM/YYYY HH:mm')}
                </span>
              ),
            },
          ]}
          data={listaFiltro}
        />
      </DialogContent>
      <DialogAlert
        open={open}
        title="Deseja excluir esta lista ?"
        onClose={() => setOpen(false)}
        onClick={() => {
          props.onDelete(id);
          setOpen(false);
        }}
      />
    </Dialog>
  );
}

const mapStateToProps = ({ InsightsReducer }) => ({
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSelecionarFiltro);
