import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setSelectedModule } from '../../modules/AppConfig/actions/actions';
import {
  setCurrentModule,
  setCurrentServices,
  setCurrentSetting
} from '../../modules/Login/actions/actionLogin';

interface SelectMenuProps {
  reload?: boolean;
}

// parametro reload = true apenas necessário em SingInAsUser
export function useSelectMenu(params: SelectMenuProps = { reload: false }) {
  const { reload } = params;
  const dispatch = useDispatch();
  const history = useHistory();

  const openDrawer = useCallback(
    (item: { path: LocationDescriptor<unknown>; title: any }) => {
      if (item.path) {
        history.push(item.path);
        document.title = `Datasales - ${item.title}`;
        if (reload) window.location.reload();
      }
    },
    [history]
  );

  const selectSettings = (
    services: string = '',
    path: string,
    title: string | undefined,
    selectedModule?: string | undefined
  ) => {
    const select = { services: services.split(','), path, title };

    dispatch(setSelectedModule(services === 'blog' ? null : selectedModule));
    dispatch(setCurrentModule(null));
    dispatch(setCurrentSetting(true));
    dispatch(setCurrentServices(select.services));
    if (services === 'blog') {
      localStorage.removeItem('DS@state_selectedModule');
    }
    openDrawer(select);
  };

  function selectService(
    mod: string,
    services: string = '',
    path: string,
    title: string,
    selectedModule: string = ''
  ) {
    dispatch(setSelectedModule(selectedModule));
    const select = { module: mod, services: services.split(','), path, title };
    dispatch(setCurrentSetting(false));
    dispatch(setCurrentModule(mod));
    dispatch(setCurrentServices(select.services));
    openDrawer(select);
  }

  function selectMenu(
    module: string,
    services: string,
    path: string,
    title: string,
    lineName?: string
  ) {
    module === 'config'
      ? selectSettings(services, path, title, lineName)
      : selectService(module, services, path, title, lineName);
  }

  const selectMenuShort = (param: string) => {
    switch (param) {
      case 'home':
        selectMenu('fastModels', 'propaganda_de_ofertas', '/home', 'Início');
        break;
      case 'projects':
        selectMenu('fastModels', 'propaganda_de_ofertas', '/projects', 'Início');
        break;
      case 'newStudio':
        selectMenu('fastModels', 'propaganda_de_ofertas', '/newStudio', 'Início');
        break;
      case 'professional':
        selectMenu('fastModels', 'propaganda_de_ofertas', '/professional', 'Início');
        break;
      case 'dashboard':
        selectMenu('dashboardV2', 'propaganda_de_ofertas', '/dashboard-v2', 'Dashboard');
        break;
      case 'financial':
        selectSettings('blog', '/financial', 'Financeiro');
        break;
      case 'minhaConta':
        selectSettings('account', '/account-settings', 'Minha Conta');
        break;
      case 'studio':
        selectMenu('criacao', 'templates,propaganda_de_ofertas', '/estudio', 'Estúdio');
        break;
      case 'criar_arte':
        selectMenu(
          'criacao',
          'templates,propaganda_de_ofertas',
          '/estudio?createNewTabloid=true',
          'Estúdio'
        );
        break;
      case 'allApps':
        selectMenu('allApps', 'propaganda_de_ofertas', '/apps/all', 'Todos os aplicativos');
        break;
      case 'chat':
        selectMenu('chat', 'chat', '/chat', 'Chat');
        break;
      case 'radios':
        selectMenu('radios', 'radios', '/radios', 'Radios');
        break;
      case 'campanhas':
        selectMenu(
          'campanhas',
          'criar_campanha,monitor_de_envios,resultados_de_campanhas,ads',
          '/monitor-campanhas',
          'Resultado de campanhas'
        );
        break;
      case 'site':
        selectMenu('sites', 'sites', '/sites', 'Site');
        break;

      case 'whatsapp':
        selectSettings('whatsapp', '/whatsapp', 'Whatsapp');
        break;
      case 'redes_sociais':
        selectSettings('redes_sociais', '/social-networks/accounts', 'Redes sociais');
        break;
        case 'without-access':
        selectSettings('without-access', '/without-access', 'Without Access');
        break;
      case 'form-delivery':
        selectSettings('blog', '/form-delivery', 'NewStore')
        break;
    }
  };

  return { selectMenu, selectService, selectSettings, selectMenuShort };
}

