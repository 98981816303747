import React from "react";
import { Slider } from "@material-ui/core";

export default props => (
  <Slider
    {...props}
    value={props.value}
    onChange={props.onChange}
    valueLabelDisplay="auto"
    aria-labelledby="range-slider"
    marks={props.marks}
    min={props.min}
    max={props.max}
  />
);
