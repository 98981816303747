import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { useDefaultStyles } from '../../style';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  avatar: { backgroundColor: theme.palette.primary.main },
  cardActions: {
    justifyContent: 'flex-end'
  },
  gridList: { width: '100%' },
  image: { cursor: 'pointer', objectFit: 'contain' },
  img: {
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`
  },
  imageList: {},
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius
  },
  content: {
    minHeight: '90vh'
  }
}));

let lastLength = 0;

const defaultTypeValue = value => {
  switch (value) {
    case 'Post':
      return [1, { id: 1, name: 'Post' }];
      break;
    case 'Post-video':
      return [1, { id: 1, name: 'Post (Video)' }];
      break;
    case 'Story':
      return [3, { id: 1, name: 'Story' }];
      break;
    case 'Story-video':
      return [3, { id: 1, name: 'Story (Video)' }];
      break;
    case 'Encarte':
      return [2, { id: 2, name: 'Encarte' }];
      break;
    case 'Cartaz-a4-retrato':
      return [14, { id: 14, name: 'Cartaz A4 - Retrato' }];
      break;
    case 'Cartaz-a4-paisagem':
      return [15, { id: 15, name: 'Cartaz A4 - Paisagem' }];
      break;
    case 'Tv-indoor':
      return [5, { id: 5, name: 'TV - Indoor' }];
      break;
    case 'Etiqueta':
      return [16, { id: 16, name: 'Etiqueta' }];
      break;
    default:
      return [2, { id: 2, name: 'Encarte' }];
  }
};

function SelectBackground({ onSelect, formatType }) {
  const classes = useStyles();
  const magnumClasses = useDefaultStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [backgrounds, setBackgrounds] = useState([]);
  const [formats, setFormats] = useState([]);
  const [segments, setSegments] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    formats: [defaultTypeValue(formatType)[0]],
    segments: [2],
    occasions: [],
    page: [1]
  });

  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v?.id) : value,
      page: [1]
    }));
  }

  async function fetchBackgrounds(params) {
    try {
      setLoading(true);

      const paramsString = params
        ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}`
        : '';

      const response = await api(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/backgrounds${paramsString}`
      );

      if (params.page[0] <= 1) {
        setBackgrounds(response.content);
      } else {
        setBackgrounds(prev => [...prev, ...response.content]);
      }

      lastLength = response.content.length;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSegments() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(response => {
        setSegments(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchOccasions(params) {
    try {
      const paramsString = params
        ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}`
        : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions${paramsString}`).then(
        response => {
          setOccasions(response.content);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchBackgrounds(query);
  }, [query]);

  const formatId = query?.formats[0];
  const segmentId = query?.segments[0];

  useEffect(() => {
    fetchOccasions({
      formatId,
      segmentId
    });
  }, [formatId, segmentId]);

  useEffect(() => {
    fetchFormats();
    fetchSegments();
  }, []);

  return (
    <InfiniteScroll
      dataLength={backgrounds.length}
      next={() => {
        setQuery(prev => ({
          ...prev,
          page: [prev.page[0] + 1]
        }));
      }}
      hasMore={lastLength === 50}
      height={'90vh'}
      loader={<h4>Carregando...</h4>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b></b>
        </p>
      }
      style={{ overflowAnchor: 'none' }}
    >
      <Grid container spacing={4}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px 0px 20px 0px'
              }}
            >
              <Avatar className={classes.avatar}>
                <InsertPhotoOutlinedIcon />
              </Avatar>
              <div className={magnumClasses.title2} style={{ marginLeft: 10 }}>
                Escolher o fundo da arte
              </div>
            </div>

            <div style={{ margin: '0px 10px' }}>
              <Divider style={{ margin: '0px 0px 20px 0px' }} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Autocomplete
                    // multiple
                    freeSolo
                    options={formats}
                    disabled={formats.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => handleQueryChange('formats', [value])}
                    defaultValue={defaultTypeValue(formatType)[1]}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Formatos"
                        placeholder="Selecione um ou mais formatos"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    // multiple
                    freeSolo
                    options={segments}
                    disabled={segments.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => handleQueryChange('segments', [value])}
                    defaultValue={{ id: 2, name: 'Supermercado' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Segmentos"
                        placeholder="Selecione um ou mais segmentos"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    // multiple
                    freeSolo
                    options={occasions}
                    disabled={occasions.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => handleQueryChange('occasions', [value])}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ocasioes"
                        placeholder="Selecione uma ou mais ocasioes"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GridList
            className={classes.gridList}
            cellHeight="auto"
            cols={matches ? 6 : 4}
            spacing={8}
          >
            {loading && query.page[0] === 1
              ? Array(20)
                  .fill(20)
                  .map((_, i) => (
                    <GridListTile>
                      <Skeleton
                        className={classes.skeleton}
                        variant="rect"
                        height={180}
                        width="100%"
                      />
                    </GridListTile>
                  ))
              : backgrounds
                  // .filter((el, index) => index < 500)
                  .map(item => (
                    // <GridListTile
                    //   key={item.id}
                    //   className={classes.image}
                    //   onClick={() => {
                    //     const { name } = formats.find(
                    //       f => f.id === item.formatId
                    //     );

                    //     onSelect({ ...item, type: name });
                    //   }}
                    // >
                    //   <img
                    //     className={classes.img}
                    //     src={item.thumbnail || item.imageURL}
                    //     alt={item.name + ' - ' + item.id}
                    //     title={item.name + ' - ' + item.id}
                    //     loading="lazy"
                    //   />

                    //   <GridListTileBar
                    //     title={item.name + ' - id: ' + item.id}
                    //     // classes={{
                    //     //   root: classes.titleBar,
                    //     //   title: classes.title,
                    //     // }}
                    //     // actionIcon={
                    //     //   <IconButton
                    //     //     className={classes.title}
                    //     //     onClick={e => {
                    //     //       this.setState({
                    //     //         anchorEl: e.currentTarget,
                    //     //         imgSelected: value,
                    //     //       });
                    //     //     }}
                    //     //   >
                    //     //     <MoreVertIcon />
                    //     //   </IconButton>
                    //     // }
                    //   />
                    // </GridListTile>
                    <div
                      key={item.id}
                      style={{ padding: '13px' }}
                      title={item.name + ' - id: ' + item.id}
                    >
                      <div
                        className={magnumClasses.gridCard}
                        onClick={() => {
                          const { name } = formats.find(f => f.id === item.formatId);

                          onSelect({ ...item, type: name });
                        }}
                      >
                        <div className={magnumClasses.gridMediaQueryHeight}>
                          <img
                            className={magnumClasses.gridImage}
                            src={item.thumbnail || item.imageURL}
                            alt={item.name + ' - ' + item.id}
                            loading="lazy"
                          />
                        </div>
                      </div>

                      <div style={{ margin: 15 }}>
                        <div className={magnumClasses.gridTitle}>
                          {item.name + ' - id: ' + item.id}
                        </div>
                      </div>
                    </div>
                  ))}
          </GridList>
        </Grid>
      </Grid>
    </InfiniteScroll>
  );
}

export default SelectBackground;
