import { CssBaseline } from '@material-ui/core/';
//import clsx from 'clsx';
import { useState } from 'react';
import { connect } from 'react-redux';

import { useTabloids } from '../../../../hooks/tabloids';
import Appbar from '../Appbar';
import NewDrawerMenu from '../NewDrawerMenu';
/* import NotificationEnable from './NotificationEnable'; */
import NotificationPayment from './NotificationPayment';
import useStyles from './styles';

function Layout({ children, showBtnMenu }) {
  const classes = useStyles(),
    //permissions = JSON.parse(localStorage.getItem('permissions')),
    name = JSON.parse(localStorage.getItem('name')),
    email = JSON.parse(localStorage.getItem('email')),
    [open, setOpen] = useState(true);

  const { setDraweSideOpened } = useTabloids();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  setDraweSideOpened(open);

  return (
    <div>
      <Appbar
        email={email}
        name={name}
      />

      <div className={classes.root}>

        <NewDrawerMenu
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />

        <div
          style={{
            width: '100%',
            paddingTop: '5rem',
            overflow: 'hidden'
          }}>
          {/* <NotificationEnable /> */}
          <NotificationPayment />

          {children}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ loginReducer }) => ({ ...loginReducer });

export default connect(mapStateToProps, null)(Layout);

