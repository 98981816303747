/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Modal, CustomTable, Countup } from '../../../utils/components';
import DescricaoPopover from './DescricaoPopover';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import '@webscopeio/react-textarea-autocomplete/style.css';

class DetalhesRecomendacao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header(props, state) {
    return <Fragment>Detalhes</Fragment>;
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        <div />
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={props.download}
        >
          Download
        </Button>
      </div>
    );
  }

  render() {
    const {
      open,
      onClose,
      recomendacao,
      recomendacaoDetalhes,
      loading_detalhes,
    } = this.props;

    return (
      <Fragment>
        <Modal
          size="xl"
          showModal={open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <section style={{ display: 'flex' }}>
            <CustomTable
              title=""
              columnsButton
              pageSize={5}
              paging
              search
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              isLoading={loading_detalhes}
              columns={
                recomendacao.personalizada === 1
                  ? [
                      {
                        title: 'ID do produto',
                        field: 'seq_produto',
                      },
                      {
                        title: 'Produto',
                        field: 'descompleta',
                      },
                      {
                        title: 'CPF',
                        field: 'documento_mestre',
                      },
                      {
                        title: 'Cluster',
                        field: 'cluster',
                      },
                      {
                        title: 'Faixa tempo ultima compra',
                        field: 'faixa_tempo_ultima_compra',
                      },
                      {
                        title: 'Maior preço',
                        field: 'maior_preco',
                        render: rowData => (
                          <span>R$ {MoedaBrasil(rowData.maior_preco)}</span>
                        ),
                      },
                      {
                        title: 'Menor preço',
                        field: 'menor_preco',
                        render: rowData => (
                          <span>R$ {MoedaBrasil(rowData.menor_preco)}</span>
                        ),
                      },
                      {
                        title: 'Preço médio',
                        field: 'preco_medio',
                        render: rowData => (
                          <span>R$ {MoedaBrasil(rowData.preco_medio)}</span>
                        ),
                      },
                    ]
                  : [
                      {
                        title: 'Desc.',
                        render: rowData => (
                          <DescricaoPopover
                            title={rowData.descompleta}
                            helpContent={
                              <section
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span>{rowData.descricao}</span>
                              </section>
                            }
                          />
                        ),
                      },
                      {
                        title: 'Categoria',
                        field: 'categoria_3',
                      },
                      {
                        title: 'Cod Produto',
                        field: 'seq_produto',
                      },
                      {
                        title: 'Produto',
                        field: 'descompleta',
                      },
                      {
                        title: 'Curva Quantidade de Vendas',
                        field: 'curva_abc_qtdevendas',
                      },
                      {
                        title: 'Curva Valor de Vendas',
                        field: 'curva_abc_vlrvendas',
                      },
                      {
                        title: 'Curva Contribuição',
                        field: 'curva_abc_vlrcontribvenda',
                      },
                      {
                        title: 'Maior Preço',
                        field: 'maior_preco',
                        render: rowData => (
                          <Countup
                            start={0}
                            end={rowData.maior_preco}
                            decimals={2}
                            prefix="R$ "
                          />
                        ),
                      },
                      {
                        title: 'Menor Preço',
                        field: 'menor_preco',
                        render: rowData => (
                          <Countup
                            start={0}
                            end={rowData.menor_preco}
                            decimals={2}
                            prefix="R$ "
                          />
                        ),
                      },
                      {
                        title: 'Faixa de Elasticidade',
                        field: 'curva_abc_vlrvendas',
                        render: rowData => (
                          <Countup
                            start={0}
                            end={rowData.faixa_elasticidade}
                            decimals={2}
                          />
                        ),
                      },
                      {
                        title: 'Cesta Média',
                        field: 'curva_abc_vlrvendas',
                        render: rowData => (
                          <Countup start={0} end={rowData.cesta_media} />
                        ),
                      },
                      {
                        title: 'Pessoas que sempre levam',
                        field: 'curva_abc_vlrvendas',
                        render: rowData => (
                          <Countup start={0} end={rowData.sempre_levam} />
                        ),
                      },
                      {
                        title: 'Pessoas que estão deixando de levar',
                        field: 'curva_abc_vlrvendas',
                        render: rowData => (
                          <Countup start={0} end={rowData.deixando_de_levar} />
                        ),
                      },
                      {
                        title: 'Pessoas que podem gostar',
                        field: 'curva_abc_vlrvendas',
                        render: rowData => (
                          <Countup start={0} end={rowData.podem_gostar} />
                        ),
                      },
                    ]
              }
              data={recomendacaoDetalhes}
            />
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ RecomendacaoReducer }) => ({
  ...RecomendacaoReducer,
});

const mapDispatchToProps = {};

const DetalhesRecomendacaoConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalhesRecomendacao);

export default withStyles(styles)(DetalhesRecomendacaoConnect);
