function getVars() {
  return Object.keys(localStorage).reduce(
    (vars, el) => {
      try {
        try {
          vars[el] = JSON.parse(localStorage.getItem(el));
        } catch {
          vars[el] = localStorage.getItem(el);
        }
      } catch (e) {
        console.error(`Erro com o elemento ${el}`, e);
      }
      return vars;
    },
    { isLoadingLogin: false }
  );
}

const varsFromLocalStorage = getVars();

const INITIAL_STATE = {
  ...varsFromLocalStorage,
  showBtnMenu: false,
  currentModule: JSON.parse(localStorage.getItem('currentModule')),
  currentSettings: JSON.parse(localStorage.getItem('currentSettings')) || true,
  currentServices: JSON.parse(localStorage.getItem('currentServices'))
    ? JSON.parse(localStorage.getItem('currentServices')).split(',')
    : ['blog', 'account']
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CURRENT_MODULE':
      localStorage.setItem('currentModule', JSON.stringify(action.payload.module));
      return { ...state, currentModule: action.payload.module };
    case 'SET_CURRENT_SETTINGS':
      localStorage.setItem('currentSettings', JSON.stringify(action.payload.isSettings));
      return { ...state, currentSettings: action.payload.isSettings };
    case 'SET_CURRENT_SERVICES':
      localStorage.setItem('currentServices', JSON.stringify(action.payload.services.toString()));
      return { ...state, currentServices: action.payload.services };
    case 'SHOW_BTN_MENU':
      return { ...state, showBtnMenu: action.payload };
    case 'SET_LOGGED':
      return {
        ...state,
        logged: action.payload.logged
      };
    case 'USER_LOGGED':
      localStorage.setItem('logged', true);
      Object.keys(action.payload).map(key =>
        localStorage.setItem(key, JSON.stringify(action.payload[key]))
      );

      if (action?.payload?.token?.length) {
        localStorage.setItem('token', JSON.stringify(action.payload.token));
      }
      return {
        ...state,
        ...getVars(),
        user: action.payload,
        logged: true,
        isLoadingLogin: false
      };
    case 'USER_LOGGED_ERROR':
      return {
        ...state,
        showError: true,
        mensagem: {
          status: action.payload.status,
          text: 'Usuário/senha inválida.'
        },
        isLoadingLogin: false
      };
    case 'LOG_OUT':
      localStorage.removeItem('email');
      localStorage.removeItem('name');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      localStorage.removeItem('logged');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('company');
      localStorage.removeItem('conpass.token');
      localStorage.removeItem('conpass.user.data');
      localStorage.removeItem('conpass.publicToken');
      localStorage.removeItem('companyId');
      localStorage.removeItem('currentModule');
      localStorage.removeItem('currentServices');
      localStorage.removeItem('currentSettings');
      localStorage.removeItem('segmentId');
      return {
        ...state,
        logged: false,
        currentModule: null,
        currentSettings: true,
        currentServices: ['blog', 'account']
      };
    case 'ON_CHANGE_LOGIN':
      return { ...state, [action.payload.key]: action.payload.string };
    case 'LOADING_LOGIN':
      return {
        ...state,
        isLoadingLogin: true
      };
    default:
      return state;
  }
};
