import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useDate from '../../../../../hooks/date';
import { COLOR_PRIMARY } from '../../../../../utils/helpers/Constants';
import { Celular2 } from '../../../../../utils/helpers/Masks';
import Markdown from '../helpers/Markdown';

function MessageImage({
  item,
  sticker,
  onClick,
  bgColorSelf = COLOR_PRIMARY,
  bgColorNoSelf = '#fff'
}) {
  const { getDate } = useDate();
  return (
    <Box
      flex="0 0 auto"
      display="flex"
      marginTop="1px"
      marginLeft={item.i_send ? '0px' : '10px'}
      marginBottom="1px"
      marginRight={item.i_send ? '10px' : '0px'}
      paddingLeft={item.i_send ? '20%' : '0px'}
      paddingRight={item.i_send ? '0px' : '20%'}
      justifyContent={item.i_send ? 'flex-end' : 'flex-start'}
      style={{
        opacity: 1,
        transform: 'none',
        transition: item.i_send
          ? 'opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          : 'opacity 257ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 171ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }}
    >
      <Box
        maxWidth={sticker ? '10vw' : '15vw'}
        color={item.i_send ? '#fff' : 'rgba(0, 0, 0, 0.45)'}
        minWidth="0%"
        boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
        padding="3px"
        borderRadius="7.5px"
        bgcolor={item.i_send ? bgColorSelf : bgColorNoSelf}
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {item.is_group && !item.i_send && (
          <Typography
            noWrap
            style={{
              fontSize: 11,
              padding: '3px 4px 5px 6px'
            }}
          >
            {`${Celular2(item.participant_phone)} ~${item.participant_name}`}
          </Typography>
        )}
        <img
          onClick={onClick}
          style={{ maxWidth: '100%', borderRadius: '7.5px', cursor: 'pointer' }}
          src={item.message}
          crossOrigin="anonymous"
          loading="lazy"
          alt="goku"
        />
        {Boolean(item.caption) && (
          <Typography
            style={{
              color: item.i_send ? '#fff' : '#303030',
              fontSize: 14.2,
              padding: '3px 4px 5px 6px'
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: Markdown(item.caption)
              }}
            />
          </Typography>
        )}
        <Box marginTop="1px" display="flex" justifyContent="flex-end" width="100%">
          <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
            {getDate('HH:mm', item.data_envio)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

MessageImage.propTypes = {
  onClick: PropTypes.func,
  sticker: PropTypes.bool,
  item: PropTypes.any.isRequired
};

export default MessageImage;
