import { Token, TokenOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dashboard from 'modules/Dashboard/components/Dashboard';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FacebookIcon from '../../../../../../assets/icons/marketing/FacebookOriginal.svg';
import InstagramIcon from '../../../../../../assets/icons/marketing/InstagramOriginal.svg';
import SmsIcon from '../../../../../../assets/icons/marketing/SMS.svg';
import TurbochargedActionIcon from '../../../../../../assets/icons/marketing/TurbochargedAction.svg';
import WhatsappIcon from '../../../../../../assets/icons/marketing/WhatsappOriginal.svg';
import UndrawMailSent from '../../../../../../assets/images/undrawMailSent.svg';
import { SendStatusProps } from '../../../../../../hooks/api/distribution/useAdsDistribution';
import { StateProps } from '../../../../../../store/types';
import { isLocalhost } from '../../../../../../utils/helpers/Constants';
import apps from '../../../../../../utils/routes/components/Appbar/components/AppsMenu/apps';
import {
  setCurrentModuleData,
  setCurrentServicesData,
  setCurrentSettingData
} from '../../../../../Login/actions/actionLogin';
import useStyles from './style';

type Props = {
  status: SendStatusProps;
  open: boolean;
  onClose: VoidFunction;
  isFromFastModels: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DistributionCompletedModal = ({ open, status, onClose, isFromFastModels }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem('token');

  const {
    adsDistribution: { selectedChannelsFormData }
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);
  const dispatch = useDispatch();

  const [selectedPlatform, setSelectedPlatform] = React.useState<[] | string[]>([]);

  /**
   * @method returnSocialNetworksPlatforms
   * @param channelTitle receive the channel title like (Redes Sociais, Impulsionamento)
   * @param channelName receive the channel name like "social-networks" or "sms".
   * @description return the Social Networks title to display on Status when Social Networks was distributed succefully.
   */
  function returnSocialNetworksPlatforms(channelTitle: string, channelName: string) {
    if (channelName !== 'social-networks') return channelTitle;

    const countPlatforms = selectedPlatform.length;

    return 'Post de ' + (countPlatforms === 1 ? selectedPlatform[0] : `${channelTitle}`);
  }

  const handleGoToMonitor = useCallback(() => {
    const findModuleCampaign = apps[0].modules.find(mod => mod.module === 'campanhas');
    const select = {
      module: 'campanhas',
      services: findModuleCampaign?.services.split(','),
      path: findModuleCampaign?.path,
      title: findModuleCampaign?.title
    };
    dispatch(setCurrentSettingData(false));
    dispatch(setCurrentModuleData(select.module));
    dispatch(setCurrentServicesData(select.services));
    history.push(select.path as string);
  }, [history]);

  const handleGoToDsDashboardV3 = useCallback(() => {
    const alias = isLocalhost
      ? 'https://market-rls.datasales.info'
      : 'https://market.datasales.info';
    const urlDsV2 = `${alias}/sign-as-user/${token?.replace(/"/g, '')}?shortToRedirect=dashboard`;
    window.location.assign(urlDsV2);
  }, [token]);

  const handleNameChannel = useCallback(
    (channel: string) => {
      if (channel.includes('social-networks')) {
        return returnSocialNetworksPlatforms('Redes sociais', 'social-networks');
      } else if (channel.includes('whatsapp')) {
        return returnSocialNetworksPlatforms('WhatsApp', 'whatsapp');
      } else if (channel.includes('turbocharged-action')) {
        return returnSocialNetworksPlatforms('Impulsionamento', 'turbocharged-action');
      } else if (channel.includes('sms')) {
        return returnSocialNetworksPlatforms('SMS', 'sms');
      }
    },
    [selectedPlatform]
  );

  const handleIconChannel = useCallback(
    (channel: string) => {
      if (channel.includes('social-networks')) {
        return (
          <Box>
            {selectedPlatform.map(platform => {
              if (platform === 'Facebook')
                return (
                  <img
                    className={classes.imgIcon}
                    src={FacebookIcon}
                    alt="icon-facebook-campaign"
                  />
                );
              if (platform === 'Instagram')
                return (
                  <img
                    className={classes.imgIcon}
                    src={InstagramIcon}
                    alt="icon-instagram-campaign"
                  />
                );
            })}
          </Box>
        );
      } else if (channel.includes('whatsapp')) {
        return <img className={classes.imgIcon} src={WhatsappIcon} alt="icon-whatsapp-campaign" />;
      } else if (channel.includes('turbocharged-action')) {
        return (
          <img
            className={classes.imgIcon}
            src={TurbochargedActionIcon}
            alt="icon-turbocharged-campaign"
          />
        );
      } else if (channel.includes('sms')) {
        return <img className={classes.imgIcon} src={SmsIcon} alt="icon-sms-campaign" />;
      }
    },
    [selectedPlatform]
  );

  React.useEffect(() => {
    if (
      selectedChannelsFormData.length > 0 &&
      selectedChannelsFormData.some(channelFormData => channelFormData.name === 'social-networks')
    ) {
      const socialNetworksFormData = selectedChannelsFormData.find(
        channelFormData => channelFormData.name === 'social-networks'
      )?.data;
      const socialNetworksPlatforms = socialNetworksFormData?.platforms?.map(
        platform => platform?.label
      );
      if (socialNetworksPlatforms) setSelectedPlatform(socialNetworksPlatforms);
    }
  }, [selectedChannelsFormData]);

  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={open}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
      tabIndex={-1}
      data-testid="dsMarketing-studio-distribution-completedModal"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
          <img src={UndrawMailSent} alt="icon-sent-campaign" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid className={classes.contentDisclaimmer} container item xs={12} spacing={2}>
          {status &&
            status.map((send, index) => (
              <Grid key={index.toString()} item xs={12}>
                <Box className={classes.boxDisclaimmer}>
                  {handleIconChannel(send.channel)}
                  {send.status === 'success' ? (
                    <>
                      <Typography color="#78BF77">Sucesso!</Typography>&nbsp;
                      <Typography>{handleNameChannel(send.channel)} enviado.</Typography>
                    </>
                  ) : (
                    <>
                      <div style={{ marginLeft: 10 }}>
                        <div style={{ display: 'flex' }}>
                          <Typography color="#D54D4D">Erro</Typography>&nbsp;
                          <Typography>
                            para distribuição de {handleNameChannel(send.channel)}. Tente novamente.
                          </Typography>
                        </div>
                        <div style={{ marginTop: 12 }}>
                          <Typography>{send.status || ''}</Typography>
                        </div>
                        {send?.item && (
                          <div>
                            &nbsp;
                            <Typography>
                              Artes com id:{' '}
                              {send.item?.length > 0 &&
                                send.item.map(itemData => <>{itemData.id}, </>)}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Box>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            {/* esse onClick deve fechar esse modal e o modal de configuracao da distribuicao - A FAZER */}
            <Button onClick={onClose} color="inherit">
              {isFromFastModels ? 'Voltar para tela inicial' : 'Voltar para estúdio'}
            </Button>
            {/* esse onClick redireciona para a tela de campanhas */}
            <Button onClick={handleGoToDsDashboardV3} variant="contained">
              Visualizar campanha
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default React.memo(DistributionCompletedModal);

function useAuth(): { token: any } {
  throw new Error('Function not implemented.');
}

