import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../utils/helpers/Functions';
import UploadBanner from './components/UploadBanner';
import useStyles from './styles';

function Banners({ siteId }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getBanners = async () => {
    try {
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/banners`,
        { site_id: siteId }
      );

      setData(response.content);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const onSubmit = async values => {
    try {
      setOpen(false);
      setLoading(true);
      const file = await toBase64(values.file['0']);

      await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/banners`,
        { site_id: siteId, ...file, device: values.device },
        'POST'
      );
    } catch (err) {
      console.log(err);
    } finally {
      getBanners();
    }
  };

  const handleDelete = async id => {
    try {
      await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/banners/${id}`,
        {},
        'DELETE'
      );
    } catch (err) {
      console.log(err);
    } finally {
      getBanners();
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <PermMediaIcon />
            </Avatar>
          }
          title="Banners"
          action={
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Adicionar Banner
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              {
                title: '',
                render: rowData => (
                  <img
                    className={classes.thumbnail}
                    alt={rowData.image}
                    src={rowData.url}
                  />
                ),
              },
              { title: 'Nome', field: 'name' },
              {
                title: 'Tipo',
                field: 'type',
                render: rowData => <span>{rowData.type.split('/')[1]}</span>,
              },
              {
                title: 'Tamanho',
                field: 'size',
                render: rowData => (
                  <span>{humanFileSize(rowData.size, true)}</span>
                ),
              },
              { title: 'URL', field: 'url' },
            ]}
            actions={[
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleDelete(rowData.id);
                },
              },
            ]}
            data={data || []}
            options={{ actionsColumnIndex: -1, search: false }}
            localization={localization}
          />
        </CardContent>
      </Card>

      <UploadBanner open={open} onSubmit={onSubmit} onClose={handleClose} />
    </React.Fragment>
  );
}

export default Banners;

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}
