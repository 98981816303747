import React from 'react';

//Using Components
import { Typography, CircularProgress, Tooltip } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Modal, Countup } from '../../../utils/components';

//Using Variables
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

// Usage Functions
import { setRuleImport } from '../../../utils/helpers/Functions';
import MyDate from '../../../utils/helpers/Date';

// Styles
import useStyles from './styles';

const _myDate = new MyDate();

export default function(props) {
  const classes = useStyles(),
    detail = props.detail;
  function Header() {
    return (
      <section style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '1rem' }}>
          #{detail.id_base} {detail.nome_prospeccao}
        </span>{' '}
        {Boolean(detail.processado) ? (
          <Tooltip title="Audiência enriquecida" placement="top">
            <CheckCircle style={{ fontSize: 25, color: '#00875a' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Enriquecimento em andamento" placement="top">
            <CircularProgress size={22} thickness={4} />
          </Tooltip>
        )}
      </section>
    );
  }
  return (
    <Modal
      size="sm"
      showModal={props.open}
      onClose={props.onClose}
      header={<Header />}
    >
      <section
        style={{ padding: '1rem 5rem 10rem', display: 'grid', gap: '2rem' }}
      >
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Detalhes
        </Typography>
        <div className={classes.containerColsImport}>
          <section>
            <strong>ID: &nbsp;</strong>
            <span>{detail.id_base}&nbsp;</span>
          </section>
          <section>
            <strong>ID Audiência: &nbsp;</strong>
            <span>{detail.id_prospeccao}&nbsp;</span>
          </section>
          <section>
            <strong>Audiência: &nbsp;</strong>
            <span>{detail.nome_prospeccao}&nbsp;</span>
          </section>
          <section>
            <strong>Status do enriquecimento: &nbsp;</strong>
            <span>
              {Boolean(detail.processado) ? 'Concluído' : 'Processando'}&nbsp;
            </span>
          </section>
          <section>
            <strong>Preço: &nbsp;</strong>
            <Countup decimals={2} prefix="R$ " end={detail.price_enrichment} />
          </section>
          <section>
            <strong>Quantidade: &nbsp;</strong>
            <Countup end={detail.quantity_enrichment} />
          </section>
          <section>
            <strong>Regra: &nbsp;</strong>
            <span>{setRuleImport(detail.rule_enrichment)}</span>
          </section>
          <section>
            <strong>Nome responsável: &nbsp;</strong>
            <span>{detail.user_empresa}&nbsp;</span>
          </section>
          <section>
            <strong>ID responsável: &nbsp;</strong>
            <span>{detail.id_user}&nbsp;</span>
          </section>
          <section>
            <strong>Data da solicitação: &nbsp;</strong>
            <span>
              {_myDate.getDate(
                'DD/MM/YYYY HH:mm',
                detail.data_insert.replace('Z', '')
              )}
            </span>
          </section>
        </div>
      </section>
    </Modal>
  );
}
