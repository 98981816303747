import Fetch from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';

export const isLoading = () => dispatch => dispatch({ type: 'LOADING_CUPOM' });

export const onChangeState = (state, value) => dispatch =>
  dispatch({
    type: 'onChange',
    payload: {
      state,
      value,
    },
  });

export const postOfertaCupom = cupom => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/postOfertaCupom`,
      cupom,
      'POST'
    );
  } catch (err) {
    console.log(err);
  }
};

export const getOfertaCupom = periodo => async dispatch => {
  dispatch(isLoading());
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getOfertaCupom`,
      periodo
    );
    dispatch({ type: 'GET_CUPONS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getInfoCupom = periodo => async dispatch => {
  dispatch(isLoading());
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getInfoCupom`,
      periodo
    );

    dispatch({ type: 'GET_INFO_CUPOM', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getLojas = () => async dispatch => {
  const data = await Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getLojas`
  );

  const payload = data.map(item => ({
    label: item.nome_loja,
    value: item.id_loja,
  }));
  dispatch({ type: 'GET_LOJAS', payload });
};

export const getEditOferta = id => async dispatch => {
  dispatch(isLoading());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getEditOferta`,
    { id_oferta: id }
  );
  dispatch({
    type: 'GET_EDIT_OFERTA',
    payload: {
      ofertaCabecalho: payload.ofertaCabecalho,
      ofertaDetalhe: payload.ofertaDetalhe,
    },
  });
};
