/* eslint-disable */
import { Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const styles = () => ({
  textMargin: { marginTop: '1vh' },
});

function WithoutInformation({ classes }) {
  return (
    <Box textAlign="center">
      <Typography variant="h6">
        Não encontramos nenhuma informação 😕
      </Typography>
      <Typography className={classes.textMargin} variant="subtitle2">
        Mas fique tranquilo, tente mudar o filtro ou atualize a página! 😉
      </Typography>
    </Box>
  );
}

export default withStyles(styles)(WithoutInformation);
