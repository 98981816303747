import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography, { TypographyProps } from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { styled, createStyles, makeStyles } from '@mui/styles';

export const TextFieldCustom = styled(TextField)<TextFieldProps>(() => ({
  padding: 0,
  '& .MuiInputBase-root': {
    fontFamily: 'Montserrat',
    color: '#616161',
    borderRadius: '5px',
    borderColor: '#red',
    padding: '12px 0',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.05em',
    backgroundColor: '#FFF'
  },

  '& .MuiOutlinedInput-root': {
    width: 315,
    maxHeight: 40,
    padding: '12px'
  },

  '& .MuiFormLabel-root.Mui-focused': {
    color: '#2813AD !important'
  },

  '& .MuiInputLabel-root': {
    color: '#2813AD',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.05em'
  }
}));

export const TypographyCategory = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#1E1F21'
  }
}));

export const TypographySubcategory = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#FFF'
  }
}));

export const IconButtonFooter = styled(IconButton)<IconButtonProps>(() => ({
  '&.MuiIconButton-root': {
    backgroundColor: '#FFF',
    padding: 0,
    '&:hover': {
      backgroundColor: '#FFF'
    }
  }
}));

export const useStyles = makeStyles(theme =>
  createStyles({
    categoriesSlider: {
      padding: '0 40px',
      width: '100%',
      '& .swiper-button-next': {
        zIndex: 99999,
        transform: 'scale(.62)',
        right: '-7px'
      },
      '& .swiper-button-prev': {
        zIndex: 99999,
        transform: 'scale(.62)',
        left: '-7px'
      }
    },
    categoriesSlide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      transition: 'all 0.3s ease'
    },
    subcategoriesSlide: {
      width: '184px',
      height: '184px'
    },
    subcategoriesSlideImage: {
      width: '100%',
      height: '100%',
      borderRadius: '4px',
      objectFit: 'cover'
    },
    subcategoriesSlider: {
      width: '100%',
      height: '184px',
      padding: '0 40px',
      '& .swiper-button-next': {
        right: '10px',
        zIndex: 99999
      },
      '& .swiper-button-prev': {
        left: '0px',
        zIndex: 99999
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '40px',
        height: '100%',
        background: '#F2F3F5',
        zIndex: 9999
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '0',
        right: '0',
        width: '55px',
        height: '100%',
        background: '#F2F3F5',
        zIndex: 9999
      }
    },
    subcategoriesSlideImageContainer: {
      width: '250px',
      height: '180px',
      overflow: 'hidden',
      borderRadius: '4px',
      border: '2px solid transparent',
      transition: 'all 0.3s ease',
      backgroundColor: '#FFF',
      padding: '1vh 3vw',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0, 0.75)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
      },
      '&:active': {
        transform: 'scale(.98)'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '2',
        left: '2',
        background: 'transparent'
      }
    },
    subcategoriesSlideImageContainerSelected: {
      borderColor: '#2813AD',
      width: '250px',
      height: '180px',
      overflow: 'hidden',
      borderRadius: '4px',
      border: '2px solid transparent',
      transition: 'all 0.3s ease',
      backgroundColor: '#FFF',
      padding: '1vh 3vw',
      '&:hover': {
        cursor: 'pointer'
      },
      '&:active': {
        transform: 'scale(.98)'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '2',
        left: '2',
        background: 'transparent'
      }
    },
    backgroundImageFooter: {
      borderRadius: '2px',
      height: '56px',
      width: '56px',
      backgroundSize: 'contain',
      padding: '3px'
    },
    containerEditBackgrounds: {
      backgroundColor: '#FFF',
      position: 'relative',
      top: '-5vh'
    }
  })
);

