import React from 'react';
import { useFormikContext } from "formik";
import {ChatBotFileData} from '../../../../../../types/chatbot'
import { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowForward } from "@mui/icons-material";
import { useStyles } from "./styles";
import { map } from "lodash";

export function Review() {
  const styles = useStyles();
  const formik = useFormikContext<ChatBotFileData>();

  const translateRule: any = useMemo(() => {
    return { update: "Substituir valores existentes" };
  }, []);

  const RenderColumns = (item: any) => {
    return (
      <Stack key={item.column} direction="row" spacing={2}>
        <div style={{ width: "30%" }}>
          <Typography variant="subtitle2">{item.label}</Typography>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <ArrowForward color="action" fontSize="small" />
        </div>
        <div style={{ width: "30%" }}>
          <Typography variant="subtitle2">{item.column}</Typography>
        </div>
      </Stack>
    );
  };
  return (
    <Stack spacing={4}>
      <Typography className={styles.titulo}>Resumo</Typography>
      <Typography className={styles.subTitulo}>Tipo de importação: <label htmlFor="" className={styles.text}>Dados cadastrais</label></Typography>
      <Typography className={styles.subTitulo}>Arquivo: <label htmlFor="" className={styles.text}>{formik.values.fileName}</label></Typography>
      <Typography className={styles.subTitulo}>Quantidade de linhas: <label htmlFor="" className={styles.text}>{formik.values.fileRows}</label></Typography>
      <Typography className={styles.subTitulo}>Regra: <label htmlFor="" className={styles.text}>{translateRule[formik.values.rule]}</label></Typography>
      <Typography className={styles.subTitulo}>
        Identificador: <label htmlFor="" className={styles.text}>{formik.values.identificator?.column}</label>
      </Typography>
      <Typography className={styles.subTitulo}>Colunas :</Typography>

      <div className={styles.rootOptions}>
        {map(formik.values.columns, (column) => RenderColumns(column))}
      </div>
    </Stack>
  );
}