/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button,
  CircularProgress
} from "@material-ui/core";
import {
  MyButton,
  DatePicker,
  Select,
  TextField,
  Modal,
  Chip,
  DialogAlert
} from "../../../utils/components";
import { onChangeState } from "../actions/index";
import { formataDinheiro } from "../../../utils/helpers/Functions";
import { MEDIA } from "../../../utils/helpers/Constants";
import "@webscopeio/react-textarea-autocomplete/style.css";

const steps = ["Informações de Oferta", "Produtos", "Iniciar"];
class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      nomeOferta: "",
      listaSelecionada: null,
      media: {},
      dataInicio: moment(),
      dataFim: moment(),
      familia: "",
      idFamilia: "",
      nomeProduto: "",
      idProduto: "",
      preco: "",
      porcentagem: "",
      precoDesconto: "",
      pontos: "",
      cashback: "",
      url: "",
      produtos: [],
      familias: [],
      codigoCupom: "",
      valorMinimo: "",
      limiteUso: "",
      valorDesconto: "",
      tipoCupom: "",
      showDialog: false,
      showDeleteProduto: false,
      produto: {}
    };
  }

  header(props, state) {
    const { tipo_oferta } = props.oferta;
    return (
      <Fragment>
        {tipo_oferta.toUpperCase()}
        <div
          style={{
            width: "100%",
            marginTop: 40,
            marginBottom: -40
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return "Salvar";
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return "Proximo";
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
            disabled={false}
          >
            Proximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const {
      nome_oferta,
      data_inicio,
      data_fim,
      media,
      filtro,
      id_lista_prospeccao
    } = props.oferta;

    return (
      <section>
        <section
          style={{ padding: "5rem 20rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Informações da Oferta
          </Typography>

          <TextField
            label="Nome"
            className="form-control"
            autoComplete="none"
            value={nome_oferta}
            onChange={event =>
              props.onChange("nome_oferta", event.target.value)
            }
          />
          {id_lista_prospeccao && (
            <Select
              disabled
              options={[filtro]}
              label="Filtro"
              onChange={value => props.onChange("filtro", value)}
              value={filtro}
            />
          )}
          <Select
            options={MEDIA}
            label="Media"
            onChange={value => props.onChange("media", value)}
            value={media}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <section>
              <DatePicker
                dateAndTime
                disableFuture
                label="Início da Oferta"
                onChange={date => props.onChange("data_inicio", date)}
                value={data_inicio}
              />
            </section>
            <section>
              <DatePicker
                dateAndTime
                disablePast
                label="Término da Oferta"
                onChange={date => this.props.onChange("data_fim", date)}
                value={data_fim}
              />
            </section>
          </div>
        </section>
      </section>
    );
  }
  step2(props, state) {
    const {
      familia,
      nomeProduto,
      preco,
      porcentagem,
      precoDesconto,
      pontos,
      cashback,
      codigoCupom,
      valorMinimo,
      limiteUso,
      valorDesconto,
      tipoCupom
    } = state;
    const { tipo_oferta } = props.oferta;
    const {
      isLoadingProdutosLista,
      isLoadingProdutosFamilia,
      ofertaDetalhe,
      isLoadingEdit
    } = props;

    <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
      <Typography style={{ fontWeight: "900" }} color="primary" variant="h5">
        Cupom de Desconto
      </Typography>
      <TextField
        label="Código do Cupom"
        className="form-control"
        onChange={({ target }) => this.setState({ codigoCupom: target.value })}
        value={codigoCupom}
      />
      <TextField
        type="number"
        label="Valor mínimo de uso"
        className="form-control"
        onChange={({ target }) => this.setState({ valorMinimo: target.value })}
        value={formataDinheiro(valorMinimo)}
      />
      <TextField
        type="number"
        label="Limite de Utilização do Cupom"
        className="form-control"
        onChange={({ target }) => this.setState({ limiteUso: target.value })}
        value={limiteUso}
      />
      <TextField
        type="number"
        label="Valor do Desconto"
        className="form-control"
        onChange={({ target }) =>
          this.setState({ valorDesconto: target.value })
        }
        value={formataDinheiro(valorDesconto)}
      />
      <section>
        <Chip
          active={tipoCupom === "R$"}
          label="Reais (R$)"
          clickable
          onClick={() => {
            this.setState({ tipoCupom: "R$" });
          }}
        />
        <Chip
          active={tipoCupom === "%"}
          label="Porcentagem (%)"
          clickable
          onClick={() => {
            this.setState({ tipoCupom: "%" });
          }}
        />
      </section>
    </section>;
  }
  step3(props, state) {
    const { produtos, familias } = state;
    const { nome_oferta, data_inicio, data_fim } = props.oferta;
    return (
      <section>
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre a Oferta
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome da Oferta</strong>
              </p>
              <span>{nome_oferta}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(data_inicio).format(
                "DD/MM/YYYY HH:mm"
              )} Até ${moment(data_fim).format("DD/MM/YYYY HH:mm")}`}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Produtos</strong>
              </p>
              <div
                style={{
                  display: "flex",

                  flexWrap: "wrap",
                  alignItems: "flex-start"
                }}
              >
                {produtos.map(prd => (
                  <Chip label={prd.nomeProduto}></Chip>
                ))}
                {familias.map(fam => (
                  <Chip label={fam.familia}></Chip>
                ))}
              </div>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={() => this.createDetalheOferta()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;

    const { activeStep, showDialog, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? "xl" : "lg"}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {showDialog && (
          <DialogAlert
            open={showDialog}
            title="Deseja dar desconto em todos produtos dessa familia?"
            onClick={() =>
              this.setState({ showDialog: false }, () => this.addFamilia())
            }
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
        {showDeleteProduto && (
          <DialogAlert
            open={showDeleteProduto}
            title="Deseja remover este produto?"
            onClick={() =>
              this.setState({ showDeleteProduto: false }, () =>
                this.props.deleteProduto(this.state.produto)
              )
            }
            onClose={() =>
              this.setState({ showDeleteProduto: false, produto: {} })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({
  OfertasReducer,
  CampaignReducer,
  InsightsReducer
}) => ({ ...OfertasReducer, ...CampaignReducer, ...InsightsReducer });
const mapDispatchToProps = {
  onChangeState
};

const NovaOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
