import React, { Fragment } from 'react';
import { ButtonGroup } from '@material-ui/core';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { MyButton } from '../../../../utils/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ configuracoesClube, putStatusConfigClube, onChange }) => (
  <Fragment>
    <ButtonGroup>
      <MyButton
        disabled={configuracoesClube.id_clube_configuracoes ? true : false}
        bsstyle="success"
        onClick={() => onChange('showModal', true)}
      >
        Add configurações
      </MyButton>
      <MyButton
        disabled={!configuracoesClube.id_clube_configuracoes ? true : false}
        bsstyle="primary"
        onClick={() => {
          onChange('showModal', true);
          onChange('edit', true);
        }}
      >
        Editar configurações
      </MyButton>
      <MyButton
        disabled={!configuracoesClube.id_clube_configuracoes}
        bsstyle="info"
        onClick={() =>
          putStatusConfigClube(configuracoesClube.id_clube_configuracoes)
        }
      >
        <FontAwesomeIcon
          icon={configuracoesClube.inativo ? faThumbsUp : faThumbsDown}
        />
      </MyButton>
    </ButtonGroup>
    <div className="containerInfo">
      <section style={{ gridColumn: '1/3' }} className="containerInfoCelula">
        <strong>Domínio:</strong> &nbsp;
        <span>{configuracoesClube.dominio}</span>
      </section>
      <section className="containerInfoCelula">
        <strong>Nome do clube:</strong> &nbsp;
        <span>{configuracoesClube.nome_clube}</span>
      </section>
      <section className="containerInfoCelula">
        <strong>Regulamento:</strong> <br />
        <MyButton
          disabled={!configuracoesClube.regulamento}
          onClick={() => window.open(configuracoesClube.regulamento, '_blank')}
          bsSize="xsmall"
          bsstyle="primary"
        >
          Visualizar
        </MyButton>
      </section>
      <section className="containerInfoCelula">
        <strong>Logo:</strong> <br />
        <img
          alt={configuracoesClube.logo}
          className="imgLogoClube"
          src={configuracoesClube.logo}
        />
      </section>
      <section className="containerInfoCelula">
        <strong>Cor:</strong> &nbsp;
        <div
          style={{ backgroundColor: configuracoesClube.cor }}
          className="containerCorClube"
        >
          {configuracoesClube.cor}
        </div>
      </section>

      <section
        onClick={() =>
          window.open(
            `https://www.facebook.com/${configuracoesClube.facebook}`,
            '_blank'
          )
        }
        className="containerInfoCelula"
      >
        <strong>Facebook:</strong> &nbsp;
        <div
          style={{ backgroundColor: '#4267b2' }}
          className="containerCorClube"
        >
          {configuracoesClube.facebook}
        </div>
      </section>
      <section
        onClick={() =>
          window.open(
            `https://www.instagram.com/${configuracoesClube.instagram}`,
            '_blank'
          )
        }
        className="containerInfoCelula"
      >
        <strong>Instagram:</strong> &nbsp;
        <div
          style={{
            color: '#fff',
            backgroundImage: 'linear-gradient(to right, #E1306C ,  #F77737)',
          }}
          className="containerCorClube"
        >
          {configuracoesClube.instagram}
        </div>
      </section>
      <section
        onClick={() =>
          window.open(
            `https://twitter.com/${configuracoesClube.twitter}`,
            '_blank'
          )
        }
        className="containerInfoCelula"
      >
        <strong>Twitter:</strong> &nbsp;
        <div
          style={{ backgroundColor: '#1da1f2' }}
          className="containerCorClube"
        >
          {configuracoesClube.twitter}
        </div>
      </section>
      <section
        onClick={() =>
          window.open(
            `https://www.youtube.com/${configuracoesClube.youtube}`,
            '_blank'
          )
        }
        className="containerInfoCelula"
      >
        <strong>Youtube:</strong> &nbsp;
        <div
          style={{ backgroundColor: '#ff0000' }}
          className="containerCorClube"
        >
          {configuracoesClube.youtube}
        </div>
      </section>
      <section className="containerInfoCelula">
        <strong>Whatsapp:</strong> &nbsp;
        <div
          style={{ backgroundColor: '#25D366' }}
          className="containerCorClube"
        >
          {configuracoesClube.whatsapp}
        </div>
      </section>
      <section className="containerInfoCelula">
        <section style={{ height: '6vh', overflow: 'auto' }}>
          {configuracoesClube.sobre}
        </section>
      </section>
    </div>
  </Fragment>
);
