import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../utils/components/TabPanel';
import { AMBIENTE, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import api from '../../utils/helpers/Fetch';
import Filter from './components/Filter';
import Filters from './components/Filters';
import Overview from './components/Overview';
import Products from './components/Products';
import CreateFilter from './components/SaveFilter';
import useStyles from './styles';

function PromotionalIntelligence() {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const [values, setValues] = useState({
    categories: [],
    products: [],
    seasonality_months: [],
    seasonality_weeks: [],
  });

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [numberOfCategories, setNumberOfCategories] = useState(0);

  const [filter, setFilter] = useState();

  const [open, setOpen] = useState({
    filter: false,
    filters: false,
    createFilter: false,
  });

  useEffect(() => {
    if (filter) {
      (async () => {
        setLoading(true);
        const response = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/products`,
          { filter_id: filter.id }
        );

        const {
          number_of_products,
          number_of_categories,
          categories,
        } = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/count`,
          { query: filter.json }
        );

        setProducts(response);
        setNumberOfProducts(number_of_products);
        setNumberOfCategories(number_of_categories);
        setCategories(categories);
        setLoading(false);
      })();
    }
  }, [filter]);

  const handleFilterOpen = () => {
    setOpen({ filter: true, filters: false, createFilter: false });
  };

  const handleFilterClose = () => {
    setOpen({ filter: false, filters: false, createFilter: false });
  };

  const handleFiltersOpen = () => {
    setOpen({ filter: false, filters: true, createFilter: false });
  };

  const handleFiltersClose = () => {
    setOpen({ filter: false, filters: false, createFilter: false });
  };

  const handleCreateFilterOpen = () => {
    setOpen({ filter: false, filters: false, createFilter: true });
  };

  const handleCreateFilterClose = () => {
    setOpen({ filter: false, filters: false, createFilter: false });
  };

  const handleFilterchange = data => {
    setFilter(data);
    setValues(JSON.parse(data.json));
    setOpen({ filter: false, filters: false });
  };

  async function handleSearch(data) {
    try {
      if (
        data ===
        {
          categories: [],
          products: [],
          seasonality_months: [],
          seasonality_weeks: [],
        }
      ) {
        alert('teste');
      }
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/search`,
        { query: JSON.stringify(data) }
      );

      const {
        number_of_products,
        number_of_categories,
        categories,
      } = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/count`,
        { query: JSON.stringify(data) }
      );

      setCategories(categories);
      setNumberOfProducts(number_of_products);
      setNumberOfCategories(number_of_categories);

      setProducts(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleSave(data) {
    try {
      await handleSearch(values);

      await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/new`,
        { ...data, json: JSON.stringify(values), products },
        'POST'
      );
    } catch (err) {
      console.log(err);
    } finally {
      handleCreateFilterClose();
    }
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open.filter,
        })}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box pt={4}>
              <Typography variant="h4" noWrap>
                {filter ? filter.name : 'Inteligência promocional'}
              </Typography>
            </Box>
          </Grid>
          <Grid item container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleFiltersOpen}
              >
                Escolher lista
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleFilterOpen}
              >
                Criar lista
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Button
                className={value === 0 && classes.active}
                color="primary"
                size="large"
                onClick={() => setValue(0)}
              >
                Filtro
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={value === 1 && classes.active}
                color="primary"
                size="large"
                onClick={() => setValue(1)}
              >
                Produtos
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={value} index={0}>
              <Overview
                numberOfProducts={numberOfProducts}
                numberOfCategories={numberOfCategories}
                categories={categories}
                data={products}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Products filter={filter} data={products} loading={loading} />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
      <Filter
        open={open.filter}
        values={values}
        onValuesChange={values => setValues(values)}
        onSubmit={handleSearch}
        onCreateFilterOpen={handleCreateFilterOpen}
        onClose={handleFilterClose}
      />
      {open.filters && (
        <Filters
          open={open.filters}
          onSelect={handleFilterchange}
          onClose={handleFiltersClose}
        />
      )}
      {open.createFilter && (
        <CreateFilter
          open={open.createFilter}
          onSubmit={handleSave}
          onClose={handleCreateFilterClose}
        />
      )}
    </div>
  );
}

export default PromotionalIntelligence;
