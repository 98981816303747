import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useState } from 'react';

import { TextField } from '../../../../utils/components';
import { postContato, updateContact } from '../../actions';

export default function ContactUpdate({ open, contact, instances, onClose, onChange, onSuccess }) {
  async function handleUpdate() {
    try {
      const _contact = {
        contact_id: contact.id,
        cellphone: contact.numero,
        name: contact.nome_contato,
        document: contact.documento,
        active: contact.ativo_envio,
        genre: contact.sexo,
        birth_date: contact.data_nascimento,
        instance_id: contact.id_instancia
      };
      if (contact.id) {
        await updateContact(_contact);
      } else {
        await postContato(contact);
      }
      onSuccess();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Dados</DialogTitle>
      <DialogContent>
        <TextField
          label="Nome"
          name="nome_contato"
          onChange={({ target: { value, name } }) => onChange(name, value)}
          value={contact.nome_contato}
          fullWidth
          style={{ marginBottom: 15 }}
        />
        <TextField
          label="Número"
          name="numero"
          onChange={({ target: { value, name } }) => onChange(name, value)}
          value={contact.numero}
          fullWidth
          style={{ marginBottom: 15 }}
        />
        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
          <InputLabel>Instância</InputLabel>
          <Select
            value={contact.id_instancia}
            name="id_instancia"
            onChange={({ target: { value, name } }) => onChange(name, value)}
            label="Instância"
          >
            {instances.map(data => (
              <MenuItem value={data.id}>{data.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            value={contact.ativo_envio}
            name="ativo_envio"
            onChange={({ target: { value, name } }) => onChange(name, value)}
            label="Status"
          >
            <MenuItem value={1}>Ativo</MenuItem>
            <MenuItem value={0}>Inativo</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

