const INITIAL_STATE = {
  urlIframe: "",
  loadingMetabase: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "URL_IFRAME":
      return {
        ...state,
        urlIframe: action.payload.iframeUrl,
        loadingMetabase: false
      };
    case "LOADING_METABASE":
      return { ...state, loadingMetabase: true };
    default:
      return { ...state };
  }
};
