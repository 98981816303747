import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

export const isLoadingStore = () => dispatch =>
  dispatch({ type: "IS_LOADING_STORE", payload: true });

export const listStore = () => dispatch => {
  dispatch(isLoadingStore());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
  ).then(payload => {
    dispatch({ type: "LIST_STORES", payload });
  });
};

export const saveStore = store => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/postLojas`,
    store,
    "POST"
  ).then(() => dispatch(listStore()));

export const putStore = store => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/putLoja`,
    store,
    "PUT"
  ).then(() => dispatch(listStore()));

export const deleteStore = idStore => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteLoja`,
    { idStore },
    "PUT"
  ).then(() => dispatch(listStore()));
