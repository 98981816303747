import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import DialogTitle from '../../../../utils/components/DialogTitle';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
});

function SaveFilter({ open, onSubmit, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        validate={makeValidate(schema)}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle onClose={onClose}>Salvar lista</DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                name="name"
                label="Nome"
                variant="outlined"
                margin="normal"
              />
              <TextField
                name="description"
                label="Descrição"
                variant="outlined"
                margin="normal"
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button type="submit" disabled={submitting}>
                Salvar
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

SaveFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SaveFilter;
