import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import {
  Button,
  Typography,
  Divider,
  withStyles,
  Icon,
} from '@material-ui/core';
import { getWhiteList, deleteWhiteListItem } from '../action/index';

import { Link } from 'react-router-dom';
import { MES } from '../../../utils/helpers/Constants';
import { CustomTable } from '../../../utils/components';
import ModalBlacklist from './ModalWhitelist';

class Configuracoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome_campanha: '',
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      filtroMes: MES[new Date().getMonth()],
      dataInicio: moment_tz(moment())
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      dataFim: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      showModalBlacklist: false,
      key: 0,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  componentDidMount() {
    this.props.getWhiteList();
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangePeriodo(state, data) {
    // this.setState({ [state]: data, filtroMes: null }, () => {
    //   const { filtroDataInicio, filtroDataFim } = this.state;
    //   const request = {
    //     filtroDataInicio: new MyDate(filtroDataInicio, "YYYY-MM-DD").getDate(),
    //     filtroDataFim: new MyDate(filtroDataFim, "YYYY-MM-DD").getDate()
    //   };
    //   this.props.getTodasPerformanceMes(request);
    // });
  }

  onKeyPressed(e) {
    // const { nome_campanha } = this.state;
    // if (e.keyCode === 13 && nome_campanha.length > 0) {
    //   this.props.getTodasPerformanceMes({ nome_campanha });
    // } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
    //   this.onChangePeriodo();
    // }
  }

  render() {
    const { classes, whiteList, loading_whiteList } = this.props;
    const { showModalBlacklist, key } = this.state;
    const data =
      key === 0 && whiteList ? whiteList.familias : whiteList.produtos;

    return (
      <div className={classes.root}>
        <Link to="/recomendacoes" style={{ textDecoration: 'none' }}>
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Recomendaçōes
          </Button>
        </Link>
        <Fragment>
          <Typography className={classes.label} variant="h3" noWrap>
            Configuraçōes
          </Typography>
          <div className={classes.actions}>
            <section
              style={{
                display: 'flex',
                marginTop: 20,
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography className={classes.label} variant="h6" noWrap>
                  Whitelist
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>
                    <Button
                      style={{
                        borderRadius: 10,
                        marginRight: 15,
                        '&:hover': { borderRadius: 10 },
                      }}
                      color="inherit"
                      size="large"
                      className={key === 0 && classes.activeButton}
                      onClick={() => this.setState({ key: 0 })}
                    >
                      Categorias
                    </Button>
                    <Button
                      style={{
                        borderRadius: 10,
                        '&:hover': { borderRadius: 10 },
                      }}
                      color="inherit"
                      size="large"
                      className={key === 1 && classes.activeButton}
                      onClick={() => this.setState({ key: 1 })}
                    >
                      Produtos
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      size="large"
                      variant="text"
                      onClick={() =>
                        this.setState({ showModalBlacklist: true })
                      }
                    >
                      Criar Whitelist
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Divider className={classes.divider} />
          <div
            className={classes.content}
            style={
              this.props.loadingPerformance
                ? {
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                : {}
            }
          >
            <CustomTable
              title=""
              columnsButton
              pageSize={5}
              paging
              search
              isLoading={loading_whiteList}
              headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
              actions={[
                // {
                //   icon: "arrow_downward",
                //   tooltip: "Download",
                //   onClick: (event, rowData) => {
                //     window.open(rowData.link_template, "_blank");
                //   }
                // },

                {
                  icon: () => <Icon color="error">delete</Icon>,
                  tooltip: 'Excluir',
                  onClick: (event, rowData) =>
                    this.props.deleteWhiteListItem(rowData.id_whitelist),
                },
              ]}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
                backgroundColor: '#eee',
              }}
              columns={[
                {
                  title: 'Nome do Produto',
                  field: 'nome_produto',
                  hidden: key === 1 ? false : true,
                },
                { title: 'Categoria', field: 'nome_familia' },
              ]}
              data={data}
            />
          </div>
        </Fragment>
        {showModalBlacklist && (
          <ModalBlacklist
            open={showModalBlacklist}
            type={key}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalBlacklist: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ RecomendacaoReducer }) => ({
  ...RecomendacaoReducer,
});

const mapDispatchToProps = { getWhiteList, deleteWhiteListItem };

const ConfiguracoesConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuracoes);

export default withStyles(styles)(ConfiguracoesConnect);
