/* eslint-disable */
import { Avatar, Button, Grid, Icon, makeStyles, Tooltip, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatePicker, Panel } from '../../utils/components';
import Alert from '../../utils/helpers/Alert';
import { AMBIENTE, COLOR_PRIMARY, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import Fetch from '../../utils/helpers/Fetch';
import { asyncForEach, toBase64 } from '../../utils/helpers/Functions';
import { Analytics, CreateTabloid, EditTabloid, PageWrapper, TabloidCard } from './components';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';

const _alert = new Alert();

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  remove: {
    marginLeft: 'auto',
    color: '#f44336',
  },
  edit: {
    marginLeft: 'auto',
  },
}));

function Tabloid(props) {
  const classes = useStyles();

  const [tabloids, setTabloids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabloid, setTabloid] = useState(null);
  const [open, setOpen] = useState({
    createTabloid: false,
    editTabloid: false,
    accessLog: false,
    completeEdit: false,
  });
  const [filtroDataInicio, setFiltroDataInicio] = useState(moment().subtract(1, 'months'));
  const [filtroDataFim, setFiltroDataFim] = useState(moment());

  const [page, setPage] = useState(1);

  const history = useHistory();

  const [openBannerEstudio, setOpenBannerEstudio] = useState(true);

  async function getTabloids(data) {
    try {
      setLoading(true);
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        data
      );

      setTabloids(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTabloids();
  }, []);

  useEffect(() => {
    getTabloids({
      dataInicio: moment(filtroDataInicio).format('YYYY-MM-DD'),
      dataFim: moment(filtroDataFim).add('1', 'days').format('YYYY-MM-DD'),
    });
  }, [filtroDataInicio, filtroDataFim]);

  async function handleSubmitBanner(tabloidId, banner) {
    try {
      const { position, device, href, file } = banner;

      const base64 = await toBase64(file);
      return await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/banner`,
        {
          tabloidId,
          position,
          device,
          href,
          ...base64,
        },
        'POST'
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function handleCreateTabloid(data, banners) {
    try {
      setOpen(prevState => ({ ...prevState, createTabloid: false }));
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
        data,
        'POST'
      );

      const { id } = response;

      if (banners && banners.length > 0) {
        await asyncForEach(banners, async banner => await handleSubmitBanner(id, banner));
      }
    } catch (err) {
      console.log(err);
    } finally {
      getTabloids();
    }
  }

  async function handleEditTabloid(data, banners) {
    try {
      setOpen(prevState => ({ ...prevState, editTabloid: false }));
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
        data,
        'PUT'
      );

      if (banners && banners.length > 0) {
        await Promise.all(banners.map(banner => handleSubmitBanner(data.uuid, banner)));
      }
    } catch (err) {
      console.log(err);
    } finally {
      getTabloids();
    }
  }

  function handleDeleteTabloid(item) {
    _alert.alertConfirm(`Deseja excluir a propaganda ${item.nome_tabloide_digital} ?`, async () => {
      try {
        await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/removeTabloide`,
          { idTabloide: item.id_tabloide_digital },
          'PUT'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getTabloids();
      }
    });
  }

  function formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  return (
    <PageWrapper
      title="Propaganda de Ofertas"
      loading={loading}
      header={
        <Grid container alignItems="center" direction="row" justify="space-between">
          <Grid item container xs={6} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" noWrap>
                Filtrar por período
              </Typography>
            </Grid>
            <Grid item>
              <DatePicker
                label="De"
                withKeyboard
                disableFuture
                format="DD/MM/YYYY"
                value={filtroDataInicio}
                onChange={date => setFiltroDataInicio(date)}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Até"
                withKeyboard
                disableFuture
                format="DD/MM/YYYY"
                value={filtroDataFim}
                onChange={date => setFiltroDataFim(date)}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => setOpen({ ...open, createTabloid: true })}>
              Criar Propaganda
            </Button>
          </Grid>
        </Grid>
      }
      banner={
        openBannerEstudio && (
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <CloseIcon fontSize="small" onClick={() => setOpenBannerEstudio(false)} />
            <img
              src={'https://datasalesio-imagens.s3.amazonaws.com/banner-estudio.png'}
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => props.history.push('/estudio')}
            />
          </Grid>
        )
      }>
      <div
        className={classes.root}
        style={{
          margin: 'auto',
          marginBottom: 20,
          display: loading ? 'none' : 'block',
        }}>
        <Pagination
          count={Math.ceil(tabloids.length / 10)}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </div>
      <Grid item container spacing={2}>
        {tabloids.map(
          (item, index) =>
            index < page * 10 &&
            index >= page * 10 - 10 && (
              <Panel
                key={index}
                avatar={
                  <Avatar className={classes.avatar}>
                    <Tooltip title="DoneAll">
                      <DoneAllIcon />
                    </Tooltip>
                  </Avatar>
                }
                title={item.nome_tabloide_digital}
                subheader={`De ${formatDate(item.data_inicio)} até ${formatDate(item.data_fim)}`}
                style={{ marginBottom: 20 }}
                action={
                  <Fragment>
                    <Button
                      size="large"
                      className={classes.button}
                      style={{ color: 'red' }}
                      variant="text"
                      onClick={() => handleDeleteTabloid(item)}>
                      Desativar
                    </Button>

                    <Button
                      size="large"
                      className={classes.button}
                      style={{ color: COLOR_PRIMARY }}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setTabloid(item);
                        setOpen(prevState => ({
                          ...prevState,
                          editTabloid: true,
                          completeEdit: false,
                        }));
                      }}>
                      Editar
                    </Button>

                    <Button
                      size="large"
                      className={classes.button}
                      style={{ color: COLOR_PRIMARY }}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        _alert.alertConfirm(
                          `Deseja duplicar a propaganda ${item.nome_tabloide_digital} ?`,
                          async () => {
                            try {
                              let tmp = { ...item };
                              delete tmp.colors.id;
                              handleCreateTabloid({
                                ...tmp,
                                nome_tabloide: tmp.nome_tabloide_digital,
                                storeId: tmp.loja,
                              });
                            } catch (err) {
                              console.log(err);
                            }
                          }
                        );
                      }}>
                      Duplicar
                    </Button>
                  </Fragment>
                }>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}>
                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Encarte"
                    icon="people"
                    style={{ marginRight: 20 }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/encarte/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_encarte__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Cartazeamento"
                    icon="people"
                    style={{ marginRight: 20, width: '120%' }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/cartaz/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_cartaz__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Etiquetas"
                    icon="people"
                    style={{ marginRight: 20 }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/etiqueta/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_etiqueta__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Redes Sociais"
                    icon="people"
                    style={{ marginRight: 20, width: '180%' }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <FacebookIcon />,
                        style: { marginRight: 20 },
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/facebook/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_facebook__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                      {
                        icon: <InstagramIcon />,
                        style: { marginRight: 20 },
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/instagram/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_instagram__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                      {
                        icon: <WhatsAppIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/whatsapp/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_whatsapp__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="E-mail Marketing"
                    icon="people"
                    disabled={false}
                    style={{ marginRight: 20 }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/email/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_email__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Mídia Indoor"
                    icon="people"
                    disabled={false}
                    style={{ marginRight: 20 }}
                    buttons={[
                      /* {
                        icon: <FileCopyIcon />,
                        onClick: () => {}
                      }, */
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/indoor/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_indoor__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Vídeos"
                    icon="people"
                    disabled={false}
                    style={{ marginRight: 20 }}
                    buttons={[
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/videos/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />

                  <TabloidCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    title="Link on-line"
                    icon="people"
                    //style={{ marginRight: 20 }}
                    buttons={[
                      {
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                          history.push(
                            '/lista-ofertas/link/' +
                              item.id_tabloide_digital +
                              '_' +
                              item.produtos.length +
                              '_link__' +
                              item.nome_tabloide_digital
                          );
                          setTabloid(item);
                        },
                      },
                    ]}
                  />
                </div>
              </Panel>
            )
        )}
      </Grid>
      <div
        className={classes.root}
        style={{
          margin: 'auto',
          marginTop: 20,
          display: loading ? 'none' : 'block',
        }}>
        <Pagination
          count={Math.ceil(tabloids.length / 10)}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </div>

      {open.createTabloid && (
        <CreateTabloid
          open={open.createTabloid}
          onSave={handleCreateTabloid}
          onClose={() => setOpen({ ...open, createTabloid: false })}
        />
      )}

      {open.editTabloid && (
        <EditTabloid
          open={open.editTabloid}
          tabloid={tabloid}
          onSave={handleEditTabloid}
          onClose={() => {
            setOpen({ ...open, editTabloid: false });
            getTabloids();
          }}
          onGetTabloids={() => props.getTabloides()}
          completeEdit={open.completeEdit}
        />
      )}
      {open.accessLog && (
        <Analytics
          open={open.accessLog}
          tabloid={tabloid}
          onClose={() => {
            setOpen({ ...open, accessLog: false });
            setTabloid({});
          }}
        />
      )}
    </PageWrapper>
  );
}

export default Tabloid;
