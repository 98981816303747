import { AMBIENTE } from '../utils/helpers/Constants';
//import api from './api';
import axios from 'axios';

export const setTokenCloudMessaging = async (token: string) => {
  /*const response = await api.post(`notification-${AMBIENTE}/datasales-client/store-token-web`, {
    token
  });*/
  const response = await axios.post(`https://api.datasales.info/notification-${AMBIENTE}/datasales-client/store-token-web`,
    { token },
  { 
    headers:{
      Authorization: Boolean(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')!) : '',
    }
  })
  return response.data;
};
