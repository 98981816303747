import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core/";
import { getConfigSubmenuPai } from "../action/action";
import { CountCard } from "../../../utils/components";
import "../style/comercial.css";

export class Sugestoes extends Component {
  componentDidMount() {
    this.props.getConfigSubmenuPai();
  }

  render() {
    return (
      <div style={{ paddingTop: "4rem" }}>
        <Typography style={{ paddingBottom: "1rem" }} variant="h3" noWrap>
          Inteligência
        </Typography>
        <section className="contCard">
          <Link
            to={{
              pathname: `/tabloides`,
              state: { title: "Tabloide" }
            }}
            style={{ textDecoration: "none" }}
          >
            <CountCard
              style={{ width: "100%", cursor: "pointer" }}
              title="Tabloide"
              icon="mood"
            />
          </Link>
          <Link
            to={{
              pathname: `/audiencia`,
              state: { title: "Audiências personalizadas" }
            }}
            style={{ textDecoration: "none" }}
          >
            <CountCard
              style={{ width: "100%", cursor: "pointer" }}
              title="Personalizado"
              icon="mood"
            />
          </Link>
          <Link
            to={{
              pathname: `/oneToOne`,
              state: { title: "Combinações" }
            }}
            style={{ textDecoration: "none" }}
          >
            <CountCard
              style={{ width: "100%", cursor: "pointer" }}
              title="One to One"
              icon="mood"
            />
          </Link>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ ComercialReducer }) => ({ ...ComercialReducer });

const mapDispatchToProps = { getConfigSubmenuPai };

export default connect(mapStateToProps, mapDispatchToProps)(Sugestoes);
