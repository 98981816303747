export default (string = '') => {
  let name = '';
  const newName = Boolean(string)
    ? string.split(' ')
    : String(JSON.parse(localStorage.getItem('name'))).split(' ');
  if (newName[0]) {
    name = name + newName[0].charAt(0);
  }
  if (newName[1]) {
    name = name + newName[1].charAt(0);
  }
  name = String(name).toUpperCase();

  return name;
};
