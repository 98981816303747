/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { contadorCelular } from "../../Insights/actions/actions";
import { getTemplates } from "../../Template/actions/actions";
import {
  Select,
  Chip,
  TextField,
  Modal,
  MyButton
} from "../../../utils/components";
import "@webscopeio/react-textarea-autocomplete/style.css";

const steps = ["Informações de Envio", "Mensagem", "Disparar"];
class NovaCampanhaSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      gatilho: [],
      tipo: [],
      mensagem: "",
      activeStep: 0,
      show: false,
      showAlert: false,
      showVar: false,
      showAlertValidate: false,
      showNumeros: false
    };
  }

  tituloModal(titulo) {
    switch (titulo) {
      case 1:
        return "Refazer campanha para pessoas que compraram";
      case 2:
        return "Refazer campanha para pessoas que não compraram";
      case 3:
        return "Refazer campanha para todas as pessoas";
      default:
        return "Nova Campanha";
    }
  }

  header(props, state) {
    return (
      <Fragment>
        <strong>Criar automação</strong>
        <div style={{ width: "100%" }}>
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep + 1
              })
            }
          >
            Próximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Informações da Automação
          </Typography>
          <TextField
            label="Nome da Automação"
            className="form-control"
            onChange={({ target }) => props.onChange("name", target.value)}
            value={props.name}
            autoComplete="none"
          />
          <Select
            label="Gatilho"
            labelKey="descricao_gatilho"
            valueKey="id_automacao_gatilho"
            options={props.gatilhos}
            onChange={value => props.onChange("gatilho", value)}
            value={props.gatilho}
          />
          <Select
            label="Tipo"
            options={[{ label: "SMS", value: "sms" }]}
            onChange={tipo => props.onChange("tipo", tipo)}
            value={props.tipo}
          />
          {props.edit && (
            <RadioGroup
              value={parseInt(props.ativo)}
              style={{ flexDirection: "row" }}
              aria-label="ativo"
              onChange={({ target: { value } }) =>
                props.onChange("ativo", value)
              }
            >
              <FormControlLabel
                value={1}
                label="Ativar"
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                value={0}
                label="Desativar"
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          )}
        </section>
      </section>
    );
  }
  step2(props, state) {
    return (
      <section style={{ padding: "2rem 15rem" }}>
        <Chip
          label="Primeiro nome"
          clickable
          onClick={() => {
            props.onChange("mensagem", `${props.mensagem} {{primeiroNome}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Nome completo"
          onClick={() => {
            props.onChange("mensagem", `${props.mensagem} {{nomeCompleto}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Cupom"
          onClick={() => {
            props.onChange("mensagem", `${props.mensagem} {{voucher}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Click tracker"
          onClick={() => {
            props.onChange("mensagem", `${props.mensagem} {{clickTracker}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="20"
          value={props.mensagem}
          margin="normal"
          variant="outlined"
          helperText={`${props.mensagem.length}/160`}
          onChange={({ target }) => props.onChange("mensagem", target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: "flex", flexDirection: "column" }}></div>
              </InputAdornment>
            )
          }}
        />
      </section>
    );
  }
  step3(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome: </strong>
                {props.name}
              </p>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Mensagem
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <span>{props.mensagem}</span>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={props.onSave} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, open } = this.props;

    const { activeStep } = this.state;

    return (
      <Modal
        size="lg"
        showModal={open}
        onClose={onClose}
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const mapStateToProps = ({
  CampaignReducer,
  InsightsReducer,
  TemplateReducer
}) => ({
  ...CampaignReducer,
  ...InsightsReducer,
  ...TemplateReducer
});

const mapDispatchToProps = { contadorCelular, getTemplates };

export default connect(mapStateToProps, mapDispatchToProps)(NovaCampanhaSMS);
