import React from "react";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RelatorioPeloDia from "./RelatorioPeloDia";
import RelatorioPeloDiaeMes from "./RelatorioPeloDiaeMes";
import RelatorioPorPerido from "./RelatorioPorPeriodo";

const titleTab = (icon, title) => (
  <section>
    <FontAwesomeIcon icon={icon} /> &nbsp;
    <strong>{title}</strong>
  </section>
);

export default [
  {
    key: 1,
    title: titleTab(faBirthdayCake, "Aniversariantes do mês"),
    component: <RelatorioPeloDia />
  },
  {
    key: 2,
    title: titleTab(faBirthdayCake, "Aniversariantes do dia/mês"),
    component: <RelatorioPeloDiaeMes />
  },
  {
    key: 3,
    title: titleTab(faBirthdayCake, "Aniversariantes por período"),
    component: <RelatorioPorPerido />
  }
];
