import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function OutlinedTextFields(props) {
  return (
    <TextField
      id={props.id}
      margin="none"
      autoComplete="none"
      label={props.label}
      onChange={props.onChange}
      placeholder={props.placeholder}
      helperText={props.helperText}
      fullWidth={props.fullWidth || true}
      variant={props.variant || 'outlined'}
      InputLabelProps={{ shrink: props.shrink }}
      {...props}
    />
  );
}
