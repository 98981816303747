import React from "react";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  },
  barColorPrimary: {
    backgroundColor: "rgba(0, 0, 0, 0.09)"
  }
})(LinearProgress);

export default props => <ColorLinearProgress style={{ width: "100%" }} />;
