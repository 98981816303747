import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';

export const DivTitle = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

export const ButtonStyled = styled(Button)<ButtonProps>({
  padding: '6px 8px !important',
  gap: '10px !important',
  background: '#0C20B5 !important',
  borderRadius: '4px !important',
  color: '#FFFFFF !important',
  fontStyle: 'normal !important',
  fontWeight: '500 !important',
  fontSize: '12px !important',
  lineHeight: '20px !important'
});

export const BoxFormat = styled(Box)<BoxProps>(() => ({
  width: '180px',
  marginTop: '28px',
  marginRight: '22px',
  cursor: 'pointer',
  borderRadius: '8px'
}));

export const Img = styled('img')({
  width: '180px',
  height: '180px'
});

export const TypographyTitle = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#1E1F21',
    display: 'inline'
  }
}));

export const TypographySubTitle = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#616161'
  }
}));

export const VideoLabel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 6px',
  marginLeft: 8,
  borderRadius: 200,
  border: 'solid 1px #0C20B5',
  background: '#FFFFFF',
  width: '48px',
  height: '14px'
}));

export const DivVideo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 0
});

export const TypographyVideo = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: 12,
    fontWeight: 500,
    color: '#0C20B5'
  }
}));

export const TypographyMenuMoreFormats = styled(Typography)<TypographyProps>(() => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#2A2A2B'
}));

