import { Theme } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: { padding: useTheme().spacing(1, 2) },
  channelContainer: {
    padding: '8px 6px',
    backgroundColor: '#f5f5f5',
    color: '#2c2c2c',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginTop: '0'
  },
  channelContainerWithMargin: {
    marginTop: '24px !important'
  },
  logoContainer: {
    height: '24px'
  },
  boxTitleAndLogo: {
    // backgroundColor: '#F5F5F5',
    // borderRadius: 2,
    // padding: useTheme().spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  imgIcon: {
    width: useTheme().spacing(3),
    marginRight: useTheme().spacing(1)
  },
  errorBox: {
    marginTop: '8px',
    fontWeight: 'normal',
    color: '#616161'
  }
}));

export default useStyles;
