import React from 'react';
import { useParams, Route, Switch, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TabloideDigital from './TabloideDigital';

function Tabloid(props) {
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const enviadoAlert = () => {
    enqueueSnackbar('Pedido enviado para processamento.', {
      variant: 'success',
    });
  };

  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/estudio/enviado"
          render={() => {
            enviadoAlert();
            return <Redirect to="/estudio" />;
          }}
          exact
        />
        <Route
          path="/estudio/enviadoEstudio"
          render={() => {
            enviadoAlert();
            return <Redirect to="/estudio" />;
          }}
          exact
        />
        <Route
          path="/estudio/:id/enviado"
          render={() => {
            enviadoAlert();
            return <Redirect to={`/estudio/${params?.id}`} />;
          }}
          exact
        />
        <Route path="/estudio" exact>
          <TabloideDigital />
        </Route>
        <Route path="/estudio/:id">
          <TabloideDigital />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Tabloid;
