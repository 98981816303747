import { Grid, Typography } from '@mui/material';
import ButtonChannel from 'modules/TabloideDigitalV2/components/AdsDistribution/components/WhereToDistribute/components/ButtonChannel';
import React, { useCallback, useState } from 'react';
import { ChannelsProps } from '../types/types'
import { ButtonChennels } from './ButtonChannels'
import { useStyles } from './styles'

type Props = {
  onChange: (idChannel: number, isSelected: boolean) => void;
  channels: ChannelsProps[];
};

export function ToDistribute(props: Props) {
  const styles = useStyles();
  const [, updateState] = useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const onSelectChannel = useCallback((isSelected: boolean, idChannel: number) => {
    props.onChange(idChannel, isSelected);
    forceUpdate();
  }, []);

  return (
    <Grid item xs={12}>
      <Grid className={styles.containerGlobal}>
        <Typography
          className={styles.title}
        >
          Canais de distribuição
        </Typography>
        <Typography
          className={styles.subTitle}
        >
          Selecione os canais que deseja distribuir as imagens.
        </Typography>
      </Grid>
      <Grid sx={{ flexDirection: 'column' }} className={styles.gridButtonChennels}>
        {props.channels.map((option, index)=>(
          <Grid>
            <ButtonChennels
              onSelected={checked => onSelectChannel(checked, index)}
              selected={option.selected}
              title={option.title}
              name={option.name}
              channels={option.channels}
              disabled={option.disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}