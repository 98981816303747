import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setAdsDistributionIsValidatingForm,
  setAdsDistributionSelectedChannels,
  setAdsDistributionValidateForm
} from '../../../modules/TabloideDigitalV2/action';
import { IDistributionPlatforms } from '../../../modules/TabloideDigitalV2/reducer/types';
import { StateProps } from '../../../store/types';
import { asyncForEach } from '../../../utils/helpers/Functions';
import distributeInstagramCarousel from './utils/distributeInstagramCarousel';
import { distributeSms } from './utils/distributeSMS';
import distributionSocialNetworkPost from './utils/distributeSocialNetworkPost';
import distributeTurbochargedAction from './utils/distributeTurbochargedAction';
import {
  distributeWhatsappContactOrCopy,
  distributeWhatsappGroup
} from './utils/distributeWhatsapp';

export type DistributionTypeProps =
  | 'social-networks'
  | 'turbocharged-action'
  | 'sms'
  | 'whatsapp'
  | 'site'
  | 'email'
  | 'google'
  | 'app';

export type DistributionStatusProps = 'initial' | 'fail' | 'success' | 'processing';
export type SendStatusProps = Array<{ channel: string; status: string }> | null;

type DistributionFunctionProps = (handleCloseDistribution?: () => void) => void;

type UseAdsDistributionReturnedProps = {
  sendStatus: SendStatusProps;
  distributionStatus: DistributionStatusProps;
  distribute: DistributionFunctionProps;
  validateThisForm: (formik: any, formChannel: DistributionTypeProps) => void;
};

function useAdsDistribution(offerId?: number) {
  const { selectedShareableItem, adsDistribution } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
  );

  const [selectedShareableItemDivided, setSelectedShareableItemDivided] = useState<any>([]);

  useEffect(() => {
    let lastIsScheduled = false;
    let arrayTmp: any = [];
    selectedShareableItem.forEach((element: any) => {
      if (element?.dateScheduled) {
        element?.dateScheduled?.forEach((eachDateScheduled: any) => {
          arrayTmp.push([{ ...element, dateScheduled: eachDateScheduled }]);
        });
        lastIsScheduled = true;
      } else {
        if (lastIsScheduled) {
          arrayTmp.push([element]);
        } else {
          if (arrayTmp.length > 0) {
            arrayTmp[arrayTmp.length - 1] = [...arrayTmp[arrayTmp.length - 1], element];
          } else {
            arrayTmp = [[element]];
          }
        }
        lastIsScheduled = false;
      }
    });
    setSelectedShareableItemDivided([...arrayTmp]);
  }, [selectedShareableItem]);

  const dispatch = useDispatch();

  const [distributionStatus, setDistributionStatus] = useState<DistributionStatusProps>('initial');
  const [sendStatus, setSendStatus] = useState<SendStatusProps>(null);

  const IsCarousel = () => selectedShareableItem.length > 1;
  const IsCarousel2 = (array: any) => array.length > 1;
  const IsPlatform = (platformName: string, platforms: IDistributionPlatforms[]) =>
    platforms.filter(platform => platform.value === platformName).length > 0;

  const distribute: DistributionFunctionProps = async () => {
    try {
      setDistributionStatus('processing');
      const requests: any = [];
      await asyncForEach(adsDistribution.selectedChannelsFormData, async (distribution: any) => {
        switch (distribution.name) {
          case 'whatsapp':
            try {
              const { selectedShippingType, selectedInstance, groupsToSend, numbersInCopy } =
                distribution.data;
              if (!selectedInstance || selectedInstance === null) {
                throw new Error('Selecione uma instância!');
              }

              for await (const itemShareable of selectedShareableItemDivided) {
                const distributionData = {
                  ...distribution.data,
                  scheduleDate: itemShareable[0].dateScheduled
                    ? moment(itemShareable[0].dateScheduled)
                    : distribution.data?.scheduleDate,
                  post: itemShareable[0].description || distribution.data?.post,
                  text: itemShareable[0].description || distribution.data?.text
                };

                switch (selectedShippingType.name) {
                  case 'CONTACTS':
                    const sendWhatsapp = await distributeWhatsappContactOrCopy({
                      carouselSelectedItems: itemShareable,
                      ...distributionData
                    });
                    requests.push(...sendWhatsapp);
                    break;
                  case 'ONLY_GROUPS':
                    if (groupsToSend.length > 0) {
                      if (numbersInCopy?.length > 0) {
                        const sendWhatsapp = await distributeWhatsappContactOrCopy({
                          carouselSelectedItems: itemShareable,
                          ...distributionData
                        });
                        requests.push(...sendWhatsapp);
                      }

                      const sendWhatsappGroup = await distributeWhatsappGroup({
                        carouselSelectedItems: itemShareable,
                        ...distributionData
                      });
                      requests.push(...sendWhatsappGroup);
                    }
                    break;
                  case 'CONTACTS_GROUPS':
                    if (groupsToSend.length > 0) {
                      const sendWhatsappContacts = await distributeWhatsappContactOrCopy({
                        carouselSelectedItems: itemShareable,
                        ...distributionData
                      });
                      requests.push(...sendWhatsappContacts);
                      const sendWhatsappGroups = await distributeWhatsappGroup({
                        carouselSelectedItems: itemShareable,
                        ...distributionData
                      });
                      requests.push(...sendWhatsappGroups);
                    }
                    break;
                  case 'ONLY_IN_COPY':
                    const sendWhatsappCopy = await distributeWhatsappContactOrCopy({
                      carouselSelectedItems: itemShareable,
                      ...distributionData
                    });
                    requests.push(...sendWhatsappCopy);
                    break;
                  case 'AUDIENCE':
                    const sendWhatsappAudience = await distributeWhatsappContactOrCopy({
                      carouselSelectedItems: itemShareable,
                      ...distributionData
                    });
                    requests.push(...sendWhatsappAudience);
                    break;
                }
              }
              // else if (
              //   selectedShippingType.name === 'CONTACTS' ||
              //   selectedShippingType.name === 'ONLY_IN_COPY'
              // ) {
              //   const sendWhatsapp = await distributeWhatsappContactOrCopy({
              //     carouselSelectedItems: selectedShareableItem,
              //     ...distributionData
              //   });
              //   requests.push(...sendWhatsapp);
              // } else if (selectedShippingType.name === 'ONLY_GROUPS' && groupsToSend.length > 0) {
              //   const sendWhatsappGroup = await distributeWhatsappGroup({
              //     carouselSelectedItems: selectedShareableItem,
              //     ...distributionData
              //   });
              //   requests.push(...sendWhatsappGroup);
              // }
            } catch (err: any) {
              console.log({ err });
              requests.push({
                _service: 'whatsapp',
                ...err
              });
            }
            break;
          case 'turbocharged-action':
            try {
              for await (const itemShareable of selectedShareableItemDivided) {
                const distributionData = {
                  ...distribution.data,
                  start_time: itemShareable[0].dateScheduled
                    ? moment(itemShareable[0].dateScheduled)
                    : distribution.data?.start_time,
                  end_time: itemShareable[0].dateScheduled
                    ? moment(itemShareable[0].dateScheduled).add(1, 'days')
                    : distribution.data?.end_time,
                  post: itemShareable[0].description || distribution.data?.post,
                  text: itemShareable[0].description || distribution.data?.text
                };

                let selectedShareableItemLocal: any;
                if (itemShareable && Array.isArray(itemShareable)) {
                  selectedShareableItemLocal = itemShareable.map(item => ({
                    ...item,
                    image_url: item.image_url ? item.image_url.split('?')[0] : null
                  }));
                }

                const _firstShareableItem = selectedShareableItemLocal[0];
                const { id, id_tabloide, name, link, image_url } = _firstShareableItem;

                const sendTurbocharged = await distributeTurbochargedAction({
                  data: {
                    offerId: id_tabloide,
                    offerName: name,
                    imageId: id,
                    imageName: name,
                    imageUrl: link ? link : image_url,
                    imageType: link ? 'video' : 'post',
                    selectedItems: selectedShareableItemLocal,
                    selectionType: link ? 'video' : 'post'
                  },
                  values: { ...distributionData }
                });
                requests.push(sendTurbocharged);
              }
            } catch (err: any) {
              requests.push({
                _service: 'turbocharged-action',
                ...err
              });
            }
            break;
          case 'social-networks':
            try {
              for await (const itemShareable of selectedShareableItemDivided) {
                if (IsCarousel2(itemShareable)) {
                  if (IsPlatform('instagram', distribution.data.platforms)) {
                    try {
                      const sendInstagranCarousel = await distributeInstagramCarousel({
                        title: distribution.data?.title,
                        post: itemShareable[0].description || distribution.data?.post,
                        profile: distribution.data?.profile,
                        offerId: offerId,
                        scheduleDate: itemShareable[0].dateScheduled
                          ? moment(itemShareable[0].dateScheduled)
                          : distribution.data?.scheduleDate,
                        carouselSelectedItems: itemShareable
                      });
                      requests.push(sendInstagranCarousel);
                    } catch (err: any) {
                      requests.push({
                        _service: 'social-networks',
                        itemShareable,
                        ...err
                      });
                    }
                  }
                } else {
                  try {
                    const sendSocialNetwork = await distributionSocialNetworkPost({
                      values: {
                        ...distribution?.data,
                        scheduleDate: itemShareable[0].dateScheduled
                          ? moment(itemShareable[0].dateScheduled)
                          : distribution.data?.scheduleDate,
                        post: itemShareable[0].description || distribution.data?.post,
                        text: itemShareable[0].description || distribution.data?.text
                      },
                      offerId: offerId,
                      carouselSelectedItems: itemShareable
                    });
                    requests.push(...sendSocialNetwork);
                  } catch (err: any) {
                    requests.push({
                      _service: 'social-networks',
                      itemShareable,
                      ...err
                    });
                  }
                }
              }
            } catch (err: any) {
              requests.push({
                _service: 'social-networks',
                ...err
              });
            }
            break;
          case 'sms':
            try {
              const sendSms = await distributeSms({
                selectedItems: selectedShareableItem,
                ...distribution.data
              });

              requests.push(...sendSms);
            } catch (err: any) {
              requests.push({
                _service: 'sms',
                ...err
              });
            }
            break;
        }
      });

      const responseRequests = await Promise.all(requests);
      const sendStatusByChannel = responseRequests.map(response => ({
        channel: response._service,
        status: response.message,
        item: response.itemShareable
      }));

      setDistributionStatus('success');
      setSendStatus(sendStatusByChannel);
    } catch (err) {
      console.log({ errCatch: err });
      setDistributionStatus('fail');
    }
  };

  /**
   * @method validateThisForm
   * @param formik the formik object returned by useFormik hook
   * @param formChannel the form channel name between the values ("social-networks" | "turbocharged-action" | "sms" | "whatsapp" | "site" | "app")
   * @description Validates if this form is valid with formik props got from useFormik and form channel name;
   */
  const validateThisForm = async (formik: any, formChannel: DistributionTypeProps) => {
    const { validateForm, currentTab, currentChannel, selectedChannels } = adsDistribution;

    dispatch(setAdsDistributionIsValidatingForm(true));

    if (validateForm) {
      await (async function () {
        if (currentChannel === formChannel) {
          let newSelectedChannels = selectedChannels;

          // Executing form validation method from referenced formik Form
          // that returns errors if have some
          const validationReturnedErrors = await formik.validateForm();

          // Verifying if this form validation has no errors or else,
          // it will be set as validation equals to false
          if (Object.keys(validationReturnedErrors).length < 1) {
            newSelectedChannels[Number(currentTab) - 2].validated = true;
            // Setts the current form validation to true
            dispatch(setAdsDistributionSelectedChannels(newSelectedChannels));
          } else {
            newSelectedChannels[Number(currentTab) - 2].validated = false;
            // Setts the current form validation to false
            // if there are some errors on the form
            dispatch(setAdsDistributionSelectedChannels(newSelectedChannels));
          }
        } else {
          alert('Ocorreu um erro de validação, por favor, contate o desenvolvedor!!');
        }
      })();

      // Setting validateForm state to false to avoid issues when the user
      // clicks on the next time to the validation Button
      dispatch(setAdsDistributionValidateForm(false));
    }

    await new Promise(r => setTimeout(r, 1000));
    dispatch(setAdsDistributionIsValidatingForm(false));
  };

  return {
    sendStatus,
    distributionStatus,
    distribute,
    validateThisForm
  } as UseAdsDistributionReturnedProps;
}

export default useAdsDistribution;

