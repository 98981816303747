/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Icon,
  Typography,
  withStyles,
} from '@material-ui/core';

import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomTable, Snackbar } from '../../../utils/components';
import { getCabecalhoProspects } from '../../Campanhas/action/actionCampanha';
import {
  createCabecalhoOferta,
  createDetalheOferta,
  getEditOferta,
  getOfertas,
  onChangeState,
} from '../actions/index';
import ModalContinuarOferta from './ModalContinuarOferta';
import ModalOferta from './ModalOferta';
import ModalOfertaInteligente from './ModalOfertaInteligente';

function CriarOferta(props) {
  const {
      classes,
      isLoadingOfertas,
      ofertas,
      isLoadingProdutosCategoria,
    } = props,
    [oferta, setOferta] = useState({}),
    [personalizada, setPersonalizada] = useState(false),
    [one2one, setOne2one] = useState(false),
    [tipoOferta, setTipoOferta] = useState('desconto'),
    [key, setKey] = useState(0),
    [showModalOferta, setShowModalOferta] = useState(false),
    [showModalContinuar, setShowModalContinuar] = useState(false),
    [showModalInteligente, setShowModalInteligente] = useState(false),
    [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const request = {
      complete: 0,
      dataInicio: moment()
        .subtract('1', 'month')
        .format('YYYY-MM-DD'),
      dataFim: moment()
        .add('1', 'days')
        .format('YYYY-MM-DD'),
    };
    props.getOfertas(request);
    props.getCabecalhoProspects();
  }, []);

  function onChangeOferta(state, value) {
    setOferta({ ...oferta, [state]: value });
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="h3" noWrap>
        Criar oferta
      </Typography>
      <div className={classes.actions}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography className={classes.label} variant="h6" noWrap>
            Sugestões de oferta
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 0 && classes.activeButton}
                onClick={() => setKey(0)}
              >
                Personalizada
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 1 && classes.activeButton}
                onClick={() => setKey(1)}
              >
                Preço Dois
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 2 && classes.activeButton}
                onClick={() => setKey(2)}
              >
                One To One
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                size="large"
                variant="text"
                onClick={() => {
                  setShowModalOferta(true);
                  setTipoOferta('desconto');
                  setPersonalizada(!Boolean(key));
                  setOne2one(key === 2 ? true : false);
                }}
              >
                Criar Oferta
              </Button>
              <Button
                color="primary"
                size="large"
                variant="text"
                component={Link}
                to="/filtros"
              >
                Criar Audiência
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div
        className={classes.content}
        style={
          isLoadingOfertas
            ? {
                display: 'flex',
                height: '100%',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }
            : {}
        }
      >
        {key === 0 && (
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            isloading={isLoadingOfertas}
            headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
            }}
            columns={[
              { title: 'Nome', field: 'nome_oferta' },
              { title: 'Loja', field: 'nome_loja' },
              { title: 'Tipo Oferta', field: 'tipo_oferta' },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  moment(rowData.data_inicio).format('DD/MM/YYYY'),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  moment(rowData.data_fim).format('DD/MM/YYYY'),
              },
              { title: 'Filtro', field: 'nome_lista' },
              {
                title: 'Ações',
                field: '',
                render: rowData => (
                  <Button
                    size="large"
                    color="primary"
                    onClick={() => {
                      setShowModalContinuar(true);
                      setOferta(rowData);
                      setPersonalizada(true);
                      setOne2one(false);
                      props.getEditOferta(rowData.id_oferta);
                    }}
                  >
                    Iniciar Oferta
                  </Button>
                ),
              },
            ]}
            data={ofertas.naoCompletas.filter(
              oferta =>
                oferta.id_lista_prospeccao !== null && oferta.one2one !== 1
            )}
          />
        )}

        {key === 1 && (
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
            }}
            columns={[
              { title: 'Nome', field: 'nome_oferta' },
              { title: 'Tipo Oferta', field: 'tipo_oferta' },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  moment(rowData.data_inicio).format('DD/MM/YYYY'),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  moment(rowData.data_fim).format('DD/MM/YYYY'),
              },

              {
                title: 'Ações',
                field: '',
                render: rowData => (
                  <Button
                    size="large"
                    color="primary"
                    onClick={() => {
                      setShowModalContinuar(true);
                      setOferta(rowData);
                      setPersonalizada(false);
                      setOne2one(false);
                      props.getEditOferta(rowData.id_oferta);
                    }}
                  >
                    Iniciar Oferta
                  </Button>
                ),
              },
            ]}
            data={ofertas.naoCompletas.filter(
              oferta =>
                oferta.id_lista_prospeccao === null && oferta.one2one !== 1
            )}
          />
        )}

        {key === 2 && (
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            isloading={isLoadingOfertas}
            headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
            }}
            columns={[
              { title: 'Nome', field: 'nome_oferta' },
              { title: 'Loja', field: 'nome_loja' },
              { title: 'Tipo Oferta', field: 'tipo_oferta' },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  moment(rowData.data_inicio).format('DD/MM/YYYY'),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  moment(rowData.data_fim).format('DD/MM/YYYY'),
              },
              {
                title: 'Ações',
                field: '',
                render: rowData => (
                  <Button
                    size="large"
                    color="primary"
                    onClick={() => {
                      setShowModalContinuar(true);
                      setOferta(rowData);
                      setPersonalizada(false);
                      setOne2one(true);
                      props.getEditOferta(rowData.id_oferta);
                    }}
                  >
                    Iniciar Oferta
                  </Button>
                ),
              },
            ]}
            data={ofertas.naoCompletas.filter(
              oferta =>
                oferta.id_lista_prospeccao === null && oferta.one2one === 1
            )}
          />
        )}
      </div>
      <ModalOferta
        open={showModalOferta}
        personalizada={personalizada}
        one2one={one2one}
        tipoOferta={tipoOferta}
        onChange={(state, value) => onChangeOferta(state, value)}
        onClose={() => {
          setShowModalOferta(false);
          props.onChangeState('idOferta', null);
        }}
      />

      <ModalContinuarOferta
        open={showModalContinuar}
        onChange={(state, value) => onChangeOferta(state, value)}
        onClose={() => {
          setShowModalContinuar(false);
          props.onChangeState('idOferta', null);
        }}
        tipoOferta={tipoOferta}
        oferta={oferta}
        personalizada={personalizada}
        one2one={one2one}
      />

      <ModalOfertaInteligente
        open={showModalInteligente}
        onChange={(state, value) => onChangeOferta(state, value)}
        onClose={() => {
          setShowModalInteligente(false);
          setShowSnackbar(false);
        }}
        onSave={() => {
          setShowModalInteligente(false);
          setShowSnackbar(true);
        }}
        tipoOferta={tipoOferta}
        oferta={oferta}
        personalizada={personalizada}
      />

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        variant="success"
        message="Oferta inteligênte criada com sucesso"
      />
      {isLoadingProdutosCategoria && (
        <Snackbar
          open={isLoadingProdutosCategoria}
          variant="info"
          message="Carregando produtos"
        />
      )}
    </div>
  );
}

// class CriarOferta extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {

//     };

//     this.onKeyPressed = this.onKeyPressed.bind(this);
//   }

// }

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ OfertasReducer, CampaignReducer }) => ({
  ...OfertasReducer,
  ...CampaignReducer,
});

const mapDispatchToProps = {
  getCabecalhoProspects,
  onChangeState,
  getOfertas,
  createCabecalhoOferta,
  createDetalheOferta,
  getEditOferta,
};

const CriarOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CriarOferta);

export default withStyles(styles)(CriarOfertaConnect);
