import { createGlobalStyle } from 'styled-components';

import { COLOR_PRIMARY } from '../utils/helpers/Constants';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

  }
 
  html, body, #root {
    width: 100%;
    font-family: 'Nunito', sans-serif !important;
    scroll-behavior: smooth;
  }

  body {
    background-color: #fff !important;
    -webkit-font-smoothing: antialiased !important;
  }
  
  ul {
    list-style: none;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${COLOR_PRIMARY};
    border-width: 2px;
  }
  
  .MuiFormLabel-root.Mui-focused {
    border-color: ${COLOR_PRIMARY};
  }

  .MuiAutocomplete-popper {
    margin-top: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12)
  }

  .ds-dropzone-one {
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 15px;
  border-width: 0.7px;
  background-color: white !important;
}

.ds-text {
  font-size: 20 !important;
  color: #727272;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 500;
}

.ds-dropzone-two {
  border-width: 0.7px;
  padding: 16px;
  background-color: #fff !important;
}

.ds-dropzone-two.readonly {
  pointer-events: none;
  background-color: transparent !important;
}

.ds-dropzone-two.readonly [class*="DropzoneArea-dropzoneTextStyle-"] {
  display: none;
}

.canvasjs-chart-credit {
  display: none;
}

.modal-90w {
  width: 90vw !important;
}

.alertify-logs {
  position: fixed;
  z-index: 999999 !important;
}

.MuiButton-label {
  text-transform: none;
}
.MuiMenu-paper {
  border-radius: 10px;
}

.ds-icon {
  position: relative;
  top: 5px;
}

#comTomTicketChatWidget,
#comTomTicketChatContainer {
  z-index: 9999 !important;
  right: 0 !important;
  left: 15px !important;
  width: 4vw;
  background-color: #312a5e !important;
}

#comTomTicketChatWidget {
  min-width: 3.5vw !important;
  width: 3.5vw;
  height: 3.5vw !important;
  border-radius: 100% !important;

}

#comTomTicketChatWidget > span.tomticketChat-status {
  display: none !important;
}

#comTomTicketChatWidget > span.tomticketChat-icon-status-widget {
  float: initial !important;
  position: initial !important;
  left: 0 !important;
  top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.swal2-container.swal2-center {
  -webkit-box-align: center;
  align-items: center;
  margin: auto;
  z-index: 9999999999;
  position: fixed;
}

span.call-to-action {
      display: none !important;
  }
  .plcht_btns_channels {
      left: 0 !important; 
  }
  .polichat_webchat_button div {
      left: 20px !important;
      right: unset !important;
  }
  #plcht-container #plcht-wdgt-close {
      left: 20px !important;
  }
  #plcht-container .other-channels {
      left: 22.5px !important;
  }
  @media    only screen and (min-width: 768px) {
      #plcht-container iframe#polichat_webchat_widget {
          left: 20px !important;
      }
  }

  .other-channels {
      display: none !important;
  }

  .emoji-picker-react .emoji-group:before {
      content: attr(data-display-name);
      color: #aaa;
      font-size: 13px;
      position: sticky;
      background: rgba(255, 255, 255, 0.95);
      width: 100%;
      z-index: 1;
      top: 0;
      text-transform: initial; 
      line-height: 45px;
      font-weight: normal; 
  }
`;

