import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  image:{
    borderRadius: '4px !important',
    height: '150px !important',
    width: '150px !important'
  },
  dateTimePicker: {
    maxWidth: '165px !important',

    '& .MuiOutlinedInput-root': {
      marginTop: '8px !important',
      height: '56px !important',
      background: '#FFF !important'
    },

    '&.MuiButton-outlined': {
      fontSize: '16px ',
      border: '1px solid #B0B0B0 !important'
    },

    '& img': {
      width: '30px !important'
    }
  },
  containerDateTime:{
    display: 'flex',
    flexDirection: 'row',
    gap: '24px !important'
  },
   dateTime: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    justifyContent: 'space-between !important'
  },
  fontTypography:{
    color: '#1E1F21 !important', 
    fontWeight: '600 !important',
    height: '20px !important',
    width: '35px !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontFamily: 'Montserrat !important',
    fontStyle: 'normal !important'
  }
}));