import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { onChange } from '../actions/action';
import {
  Stepper,
  Step,
  StepButton,
  Button,
  Typography,
  Icon,
  Divider,
} from '@material-ui/core';
import {
  Modal,
  TextField,
  Select,
  DatePicker,
  Chip,
  MyButton,
} from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

function ModalCupomCashback(props) {
  const [stepActive, setStep] = useState(0);

  function header() {
    const steps = ['Informações do Cashback', 'Iniciar'];
    return (
      <Fragment>
        Cupom cashback
        <div
          style={{
            width: '100%',
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={stepActive}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton disabled onClick={() => setStep(index)}>
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  function _disableNext() {
    if (
      props.cupomCashback.nomeCupomCashback === '' ||
      props.cupomCashback.dataInicio === null ||
      props.cupomCashback.dataFim === null ||
      props.cupomCashback.audienciaCupomCashback === null ||
      props.cupomCashback.valorCupomCashback === 0
    ) {
      return true;
    }
    return false;
  }

  function footer() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {stepActive !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => setStep(stepActive - 1)}
            disabled={stepActive === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {stepActive !== 1 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => setStep(stepActive + 1)}
            disabled={_disableNext()}
          >
            Próximo
          </Button>
        )}
      </div>
    );
  }

  function renderStep() {
    switch (stepActive) {
      case 0:
        return step1();
      case 1:
        return step2();
      default:
        return step1();
    }
  }

  function step1() {
    return (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Informações do cashback
        </Typography>
        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('nomeCupomCashback', value)
          }
          value={props.cupomCashback.nomeCupomCashback}
        />

        <Select
          onChange={value => props.onChange('audienciaCupomCashback', value)}
          value={props.cupomCashback.audienciaCupomCashback}
          options={props.listsProspectei}
          labelKey="nameList"
          valueKey="idListProspectei"
          label="Audiência participante"
        />

        <Select
          onChange={value => props.onChange('lojasCupomCashback', value)}
          value={props.cupomCashback.lojasCupomCashback}
          options={props.lojas}
          multiple
          label="Lojas participantes"
        />
        <TextField
          type="number"
          label="Valor do cashback"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('valorCupomCashback', value)
          }
          value={props.cupomCashback.valorCupomCashback}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DatePicker
            dateAndTime
            label="Começa a valer em"
            onChange={date =>
              props.onChange(
                'dataInicio',
                _myDate.getDate('YYYY-MM-DD HH:mm:ss', date)
              )
            }
            value={props.cupomCashback.dataInicio}
          />

          <DatePicker
            dateAndTime
            disablePast
            label="Termina de valer em"
            onChange={date =>
              props.onChange(
                'dataFim',
                _myDate.getDate('YYYY-MM-DD HH:mm:ss', date)
              )
            }
            value={props.cupomCashback.dataFim}
          />
        </div>
      </section>
    );
  }

  function step2() {
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Sobre o cupom de cashback
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome do cashback</strong>
              </p>
              <span>{props.cupomCashback.nomeCupomCashback}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Começa valer em</strong>
              </p>
              <span>
                {_myDate.getDate(
                  'DD/MM/YYYY HH:mm',
                  props.cupomCashback.dataInicio
                )}
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Termina de valer em</strong>
              </p>
              <span>
                {_myDate.getDate(
                  'DD/MM/YYYY HH:mm',
                  props.cupomCashback.dataFim
                )}
              </span>
            </section>

            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Valor do cashback</strong>
              </p>
              <span>R$ {props.cupomCashback.valorCupomCashback}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Lojas participantes</strong>
              </p>
              <span>
                {props.cupomCashback.lojasCupomCashback.map(loja => (
                  <Chip label={loja.label} />
                ))}
              </span>
            </section>
            {props.cupomCashback.audienciaCupomCashback && (
              <section style={{ paddingBottom: '2rem' }}>
                <p>
                  <strong>Audiencia participante</strong>
                </p>
                <span>
                  {props.cupomCashback.audienciaCupomCashback.nameList}
                </span>
              </section>
            )}
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              onClick={() => props.onSave(props.cupomCashback)}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  return (
    <Modal
      size="lg"
      showModal={props.open}
      onClose={props.onClose}
      header={header()}
      footer={footer()}
    >
      {renderStep()}
    </Modal>
  );
}

const mapStateToProps = ({
  CupomReducer,
  CampaignReducer,
  CupomCashbackReducer,
}) => ({
  ...CupomReducer,
  ...CampaignReducer,
  ...CupomCashbackReducer,
});

const mapDispatchToProps = { onChange };

export default connect(mapStateToProps, mapDispatchToProps)(ModalCupomCashback);
