import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Radios } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import FileField from '../../../FileField';
import useStyles from './styles';

function UploadBanner({ open, onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Adicionar banner</DialogTitle>
      <Divider />
      <DialogContent>
        <Form
          initialValues={{ device: 'mobile' }}
          onSubmit={onSubmit}
          render={({ values, handleSubmit }) => (
            <form id="upload-form" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Radios
                    label="Selecione"
                    name="device"
                    color="primary"
                    radioGroupProps={{ row: true }}
                    required={true}
                    data={[
                      { label: 'Mobile', value: 'mobile' },
                      { label: 'Desktop', value: 'desktop' },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <FileField name="file" accept="image/*" />
                </Grid>
                {values.file && values.file['0'] && (
                  <Grid item xs={12}>
                    <img
                      className={classes.preview}
                      alt={values.file['0'].name}
                      src={URL.createObjectURL(values.file['0'])}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            document
              .getElementById('upload-form')
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UploadBanner.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadBanner;
