const INITIAL_STATE = {
  cuponsCashback: null,
  cuponsCashbackDetalhe: [],
  isLoaderCupomCashback: false,
  cupomCashback: {
    nomeCupomCashback: "",
    lojasCupomCashback: [],
    valorCupomCashback: 0,
    audienciaCupomCashback: null,
    dataInicio: null,
    dataFim: null
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_CUPOM_CASHBACK":
      return {
        ...state,
        cuponsCashback: action.payload.cupons,
        cuponsCashbackDetalhe: action.payload.todosCupons,
        isLoaderCupomCashback: false
      };
    case "ON_CHANGE":
      return {
        ...state,
        cupomCashback: {
          ...state.cupomCashback,
          [action.payload.prop]: action.payload.value
        }
      };
    case "LOADER":
      return { ...state, isLoaderCupomCashback: true };
    default:
      return state;
  }
};
