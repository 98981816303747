import Fetch from '../../../utils/helpers/Fetch';
import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';

const loadListFileImport = () => dispatch =>
  dispatch({ type: 'LOAD_LIST_FILE' });

export const getFileImport = () => async dispatch => {
  try {
    dispatch(loadListFileImport());
    const payload = await Fetch(
      `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/getFileImport`
    );
    dispatch({ type: 'FILE_IMPORT', payload });
  } catch (err) {
    console.log(err);
  }
};

export const postFileImport = file => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileImport`,
      file,
      'POST'
    );
    dispatch(getFileImport());
  } catch (err) {
    console.log(err);
  }
};

export const postFileHeader = file => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const payload = await Fetch(
        `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileHeader`,
        file,
        'POST'
      );
      dispatch({ type: 'HEADER_FILE', payload: payload.data });
      resolve();
    } catch (err) {
      console.log(err);
      reject();
    }
  });
