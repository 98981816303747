import React from 'react';
import PropTypes from 'prop-types';
import useDate from '../../../../../hooks/date';
import { Box, Typography } from '@material-ui/core';
import Maps from './Maps';
import { Celular2 } from '../../../../../utils/helpers/Masks';
import { COLOR_PRIMARY } from '../../../../../utils/helpers/Constants';

function MessageLocation({
  item,
  onClick,
  bgColorSelf = COLOR_PRIMARY,
  bgColorNoSelf = '#fff',
}) {
  if (!item.message) {
    return <div />;
  }

  const cardinalPoints = item.message.split(','),
    { getDate } = useDate();
  return (
    <Box
      flex="0 0 auto"
      display="flex"
      marginTop="1px"
      marginLeft={item.i_send ? '0px' : '10px'}
      marginBottom="1px"
      marginRight={item.i_send ? '10px' : '0px'}
      paddingLeft={item.i_send ? '20%' : '0px'}
      paddingRight={item.i_send ? '0px' : '20%'}
      justifyContent={item.i_send ? 'flex-end' : 'flex-start'}
      style={{
        opacity: 1,
        transform: 'none',
        transition: item.i_send
          ? 'opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          : 'opacity 257ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 171ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
    >
      <Box
        width="20vw"
        color={item.i_send ? '#fff' : 'rgba(0, 0, 0, 0.45)'}
        minWidth="0%"
        boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
        padding="3px"
        borderRadius="7.5px"
        bgcolor={item.i_send ? bgColorSelf : bgColorNoSelf}
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {item.is_group && !item.i_send && (
          <Typography
            noWrap
            style={{
              fontSize: 11,
              padding: '3px 4px 5px 6px',
            }}
          >
            {`${Celular2(item.participant_phone)} ~${item.participant_name}`}
          </Typography>
        )}

        <Maps latitude={cardinalPoints[0]} longitude={cardinalPoints[1]} />

        <Box
          marginTop="1px"
          display="flex"
          justifyContent="flex-end"
          width="100%"
        >
          <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
            {getDate('HH:mm', item.data_envio)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

MessageLocation.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.any.isRequired,
};

export default MessageLocation;
