import { Box, Typography } from '@material-ui/core';
import React from 'react';

const TabPanel = ({ children, value, index, ...rest }) => (
  <Typography {...rest} component="div" hidden={value !== index}>
    {value === index && <Box>{children}</Box>}
  </Typography>
);

export default TabPanel;
