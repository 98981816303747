import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React from 'react';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div {...other} role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
