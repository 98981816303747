import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Using Components
import {
  Box,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Switch,
  Link,
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import { Autorenew } from '@material-ui/icons';
import { CustomTable } from '../../../utils/components';
import ModalAnswerForm from './ModalAnswerForm';
import ModalNumbersForms from './ModalNumbersForm';
import ModalEditForm from './ModalEditForm';
import ModalAddForm from './ModalAddForm';

// Using Action
import { getForms, postForm, putForm } from '../action';

// Using Helpers
import {
  COLOR_PRIMARY,
  FORM_TYPE,
  FORM_FIELDS_PERSON,
  FORM_INPUT_TYPE,
} from '../../../utils/helpers/Constants';
import DSAWS from '../../../utils/helpers/DSAWS';
import { parseSvgToBase64 } from '../../../utils/helpers/Functions';

// Using Styles
import useStyles from './styles';

const defaultForm = {
  typeForm: { label: '' },
  nameForm: '',
  fontColorForm: '#000',
  bgColorForm: '#FFF',
  fields: [
    {
      label: '',
      key: '',
      required: false,
      active: true,
      inputType: '',
      optionSelect: [''],
    },
  ],
};

function Forms(props) {
  const companyInfo = JSON.parse(localStorage.getItem('companyId')),

    classes = useStyles(),
    [answerFormModal, setAnswerFormModal] = useState(false),
    [numbersFormModal, setNumbersFormModal] = useState(false),
    [editModal, setEditModal] = useState(false),
    [openModal, setOpenModal] = useState(false),
    [activeForm, setActiveForm] = useState(false),
    [row, setRow] = useState({}),
    [form, setForm] = useState(defaultForm);

  useEffect(() => {
    props.getForms();
  }, []);

  useEffect(() => {
    if (Boolean(activeForm)) {
      onEdit();
      setActiveForm(false);
    }
  }, [activeForm]);

  function onChange(key, value) {
    setForm({ ...form, [key]: value });
    if (Boolean(row)) {
      setRow({ ...row, [key]: value });
    }
  }

  function onSave() {
    const objForm = {
      bgColorForm: form.bgColorForm,
      fontColorForm: form.fontColorForm,
      nameForm: form.nameForm,
      typeForm: form.typeForm.value,
      fields: form.fields.map(field => ({
        active: Boolean(field.active) ? 1 : 0,
        required: Boolean(field.required) ? 1 : 0,
        label: field.label,
        inputType: field.inputType.value,
        key: Boolean(field.key) ? field.key.value : null,
        optionSelect:
          Boolean(field.optionSelect) &&
          Boolean(field.optionSelect.length) &&
          Boolean(field.optionSelect[0])
            ? JSON.stringify(field.optionSelect)
            : null,
      })),
    };

    props.postForm(objForm);
  }

  function onEdit() {
    const objForm = {
      ativo: row.ativo,
      id_formulario: row.id_formulario,
      bgColorForm: row.bgColorForm,
      fontColorForm: row.fontColorForm,
      nameForm: row.nameForm,
      typeForm: row.typeForm.value,
      fields: row.fields.map(field => ({
        id_form_campo: field.id_form_campo,
        id_formulario: row.id_formulario,
        active: Boolean(field.active) ? 1 : 0,
        required: Boolean(field.required) ? 1 : 0,
        label: field.label,
        inputType: field.inputType.value,
        key: Boolean(field.key) ? field.key.value : null,
        optionSelect:
          Boolean(field.optionSelect) &&
          Boolean(field.optionSelect.length) &&
          Boolean(field.optionSelect[0])
            ? JSON.stringify(field.optionSelect)
            : null,
      })),
    };
    props.putForm(objForm);
    setRow({});
    setEditModal(false);
  }

  async function downloadQrCode(data) {
    const id = `qrcode-${data.guid_form}`,
      qrcode = await parseSvgToBase64(id),
      file = qrcode.split(';base64,')[1],
      attr = qrcode.split(';base64,')[0],
      uploadPhoto = {
        file: Buffer.from(file, 'base64'),
        type: attr.split(':')[1],
        bucketName: 'datasalesio-imagens',
        folderName: 'qrcode',
        subFolderName: `${companyInfo}/`,
        distinctName: false,
        name: data.guid_form,
      },
      { Location } = await DSAWS.saveS3(uploadPhoto),
      image = await fetch(Location),
      imageBlog = await image.blob(),
      imageURL = URL.createObjectURL(imageBlog),
      link = document.createElement('a');
    link.href = imageURL;
    link.download = data.guid_form;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.actions}>
        <Typography className={classes.label} variant="h3" noWrap>
          Formulários
        </Typography>
      </Box>
      <Box className={classes.actions} justifyContent="flex-end">
        <Tooltip title="Atualizar lista" placement="top">
          <IconButton onClick={() => props.getForms()}>
            <Autorenew />
          </IconButton>
        </Tooltip>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          style={{
            backgroundColor: COLOR_PRIMARY,
            marginRight: 15,
          }}
        >
          Novo formulário
        </Button>
      </Box>
      <Box className={classes.content}>
        <Paper className={classes.card}>
          <CustomTable
            toolbar={false}
            columns={[
              { title: 'Nome', field: 'nameForm' },
              {
                title: 'Tipo',
                field: 'typeForm',
                lookup: {
                  ADD_CLIENTS: 'Cadastro de clientes',
                  QUIZ: 'Questionário',
                },
              },
              {
                title: 'Link',
                field: 'linkForm',
                render: row => (
                  <Link
                    color="primary"
                    onClick={() => window.open(row.linkForm, '_blank')}
                  >
                    {row.linkForm}
                  </Link>
                ),
              },
              {
                title: 'QR Code',
                field: 'linkForm',
                render: row => (
                  <Tooltip title="Download" placement="top-start">
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() => downloadQrCode(row)}
                    >
                      <QRCode value={row.linkForm} size={64} />
                      <QRCode
                        style={{ display: 'none' }}
                        id={`qrcode-${row.guid_form}`}
                        value={row.linkForm}
                        size={1024}
                      />
                    </Box>
                  </Tooltip>
                ),
              },
              {
                title: 'Ativo',
                field: 'ativo',
                render: row => (
                  <Switch
                    onChange={({ target: { checked } }) => {
                      const typeForm = FORM_TYPE.find(
                          type => type.value === row.typeForm
                        ),
                        fields = row.fields.map(field => ({
                          ...field,
                          key: FORM_FIELDS_PERSON.find(
                            key => key.value === field.key
                          ),
                          inputType: FORM_INPUT_TYPE.find(
                            type => type.value === field.inputType
                          ),
                        })),
                        newRow = {
                          ...row,
                          fields,
                          typeForm,
                          ativo: Boolean(checked) ? 1 : 0,
                        };
                      setRow(newRow);
                      setActiveForm(true);
                    }}
                    checked={Boolean(row.ativo)}
                    color="primary"
                  />
                ),
              },
            ]}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (_, rowData) => {
                  const typeForm = FORM_TYPE.find(
                      type => type.value === rowData.typeForm
                    ),
                    fields = rowData.fields.map(field => ({
                      ...field,
                      key: FORM_FIELDS_PERSON.find(
                        key => key.value === field.key
                      ),
                      inputType: FORM_INPUT_TYPE.find(
                        type => type.value === field.inputType
                      ),
                    })),
                    newRow = { ...rowData, fields, typeForm };
                  setEditModal(true);
                  setRow(newRow);
                },
              },
              {
                icon: 'equalizer',
                tooltip: 'Números',
                onClick: (_, rowData) => {
                  setRow(rowData);
                  setNumbersFormModal(true);
                },
              },
              {
                icon: 'question_answer',
                tooltip: 'Respostas',
                onClick: (_, rowData) => {
                  setRow(rowData);
                  setAnswerFormModal(true);
                },
              },
            ]}
            isLoading={props.loadListForm}
            data={props.listFieldsFormClients}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              selectionProps: () => ({
                color: 'primary',
              }),
            }}
          />
        </Paper>
      </Box>
      <ModalAddForm
        open={openModal}
        form={form}
        onSave={() => onSave()}
        onChange={(key, value) => onChange(key, value)}
        onClose={() => {
          setOpenModal(false);
          setForm(defaultForm);
        }}
      />
      <ModalEditForm
        form={row}
        open={editModal}
        onSave={() => onEdit()}
        onChange={(key, value) => onChange(key, value)}
        onClose={() => {
          setEditModal(false);
          setRow({});
        }}
      />
      <ModalNumbersForms
        form={row}
        open={numbersFormModal}
        onClose={() => {
          setNumbersFormModal(false);
          setRow({});
        }}
      />
      <ModalAnswerForm
        form={row}
        open={answerFormModal}
        onClose={() => {
          setAnswerFormModal(false);
          setRow({});
        }}
      />
    </Box>
  );
}

const mapStateToProps = ({ FormsReducer }) => ({
  ...FormsReducer,
});

const mapDispatchToProps = { getForms, postForm, putForm };

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
