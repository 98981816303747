import { Autocomplete, TextField } from '@mui/material';

import { FetchQuery, Occasions, Segments } from '../../types';

function Filters({
  segments,
  segmentSelect,
  occasions,
  query,
  setQuery,
  setBackgrounds,
  setHandleMore
}: {
  segments: Segments[];
  segmentSelect: Segments[];
  occasions: Occasions[];
  query: FetchQuery;
  setQuery: (param: any) => void;
  setBackgrounds: (param: any) => void;
  setHandleMore: (param: any) => void;
}) {
  return (
    <>
      <Autocomplete
        id={'segments'}
        options={segments}
        value={segmentSelect[0] ? segmentSelect[0] : null}
        size={'medium'}
        sx={{ width: 470 }}
        renderInput={params => <TextField {...params} label="Segmentos" variant="outlined" />}
        onChange={(event: any, newValue: any | null) => {
          newValue
            ? (setBackgrounds({
                backgrounds: [],
                backgroundsFavorites: []
              }),
              setHandleMore(true),
              setQuery({ ...query, segments: [newValue.id], page: [1] }))
            : (setBackgrounds({
                backgrounds: [],
                backgroundsFavorites: []
              }),
              setHandleMore(true),
              setQuery({ ...query, segments: [], page: [1] }));
        }}
      />
      <Autocomplete
        id={'occasions'}
        options={occasions}
        size={'medium'}
        sx={{ width: 470 }}
        renderInput={params => <TextField {...params} label="Ocasião" variant="outlined" />}
        onChange={(event: any, newValue: any | null) => {
          newValue
            ? (setBackgrounds({
                backgrounds: [],
                backgroundsFavorites: []
              }),
              setHandleMore(true),
              setQuery({ ...query, occasions: [newValue.id], page: [1] }))
            : (setBackgrounds({
                backgrounds: [],
                backgroundsFavorites: []
              }),
              setHandleMore(true),
              setQuery({ ...query, occasions: [], page: [1] }));
        }}
      />
    </>
  );
}

export default Filters;

