import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { useDefaultStyles } from '../../style';
import TileTemplate from './TileTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  avatar: { backgroundColor: theme.palette.primary.main },
  cardActions: {
    justifyContent: 'flex-end'
  },
  gridList: { width: '100%' },
  image: { cursor: 'pointer', objectFit: 'contain' },
  img: {
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`
  },
  imageList: {},
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius
  },
  content: {
    minHeight: '90vh'
  }
}));

function SelectTemplate({ onSelect, externFormat, modelo_pronto, stores, setStore, formatType }) {
  const classes = useStyles();
  const magnumClasses = useDefaultStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [tabValue, setTabValue] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [templatesFull, setTemplatesFull] = useState([]);
  const [formats, setFormats] = useState([]);
  const [segments, setSegments] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storeSelected, setStoreSelected] = useState({});
  const [query, setQuery] = useState({
    formats: !modelo_pronto ? [externFormat.formatId] : [],
    segments: [],
    occasions: [],
    productQuantity: !modelo_pronto
      ? [externFormat.formatId === 2 ? 10 : externFormat.formatId === 16 ? 14 : 1]
      : [],
    tags: modelo_pronto ? ['modelo_pronto'] : [],
    video: formatType.includes('video') ? true : null
  });

  function handleStoreSelected(value) {
    setStoreSelected(value);
  }
  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v?.id) : value
    }));
  }

  const [countForFirstRender, setCountForFirstRender] = useState(0);

  async function fetchTemplates(params) {
    try {
      setCountForFirstRender(prev => prev + 1);

      setLoading(true);

      params = params ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}` : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/templates${params}`).then(response => {
        setTemplatesFull(response.content);
        setTemplates(response.content.slice(0, 24));
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSegments() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(response => {
        setSegments(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchOccasions() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions`).then(response => {
        setOccasions(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchTags() {
    try {
      await api(
        `https://1fqz7brbah.execute-api.us-east-1.amazonaws.com/prd/findAllTagsSelect`
      ).then(response => {
        setTags(response.content);
      });
      // await api(`https://api-backoffice.datasales.info/studio-${AMBIENTE}/findAllTagsSelect`).then(
      //   response => {
      //     setTags(response.content);
      //   }
      // );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchFormats();
    fetchTags();
    // fetchSegments();
    // fetchOccasions();

    if (formatType.includes('video')) {
      setTabValue(1);
    }
  }, []);

  useEffect(() => {
    fetchTemplates(query);
  }, [query.formats, query.segments, query.occasions, query.tags, query.video]);

  useEffect(() => {
    let timer1;
    if (countForFirstRender !== 0) {
      timer1 = setTimeout(() => fetchTemplates(query), 1100);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [query.productQuantity]);

  return (
    <InfiniteScroll
      dataLength={templates.length}
      next={() => {
        setTemplates(templatesFull.slice(0, templates.length + 12));
      }}
      hasMore={templates.length < templatesFull.length}
      height={'90vh'}
      loader={<h4>Carregando...</h4>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>.</b>
        </p>
      }
      style={{ overflowAnchor: 'none' }}
    >
      <Grid container spacing={4}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px 0px 20px 0px'
              }}
            >
              <Avatar className={classes.avatar}>
                <PhotoLibraryOutlinedIcon />
              </Avatar>
              <div className={magnumClasses.title2} style={{ marginLeft: 10 }}>
                Escolher a disposição do layout
              </div>
            </div>

            <div style={{ margin: '0px 10px' }}>
              <Divider style={{ margin: '0px 0px 20px 0px' }} />

              <Grid container spacing={2} className={classes.cardActions}>
                {modelo_pronto && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      width: '80px',
                      height: '80px',
                      marginTop: '-3px'
                    }}
                  >
                    <img
                      src={
                        storeSelected?.logo_url ||
                        'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/imagem_vazia_logo_empty.webp'
                      }
                      style={{
                        height: '100%',
                        width: '100%'
                      }}
                    ></img>
                  </Grid>
                )}
                {modelo_pronto && (
                  <Grid item xs={3}>
                    <Autocomplete
                      freeSolo
                      options={stores}
                      disabled={!modelo_pronto}
                      getOptionLabel={option => option.name}
                      onChange={(e, value) => {
                        handleStoreSelected(value);
                        setStore(value);
                      }}
                      renderInput={params => (
                        <>
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Logo da sua Loja"
                            placeholder="Selecione uma Loja"
                          />
                        </>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Autocomplete
                    // multiple
                    freeSolo
                    options={formats}
                    disabled={!modelo_pronto}
                    // disabled={formats.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => {
                      handleQueryChange('formats', [value]);
                    }}
                    defaultValue={
                      !modelo_pronto
                        ? {
                            id: externFormat.formatId,
                            name: externFormat.type,
                            disabled: true
                          }
                        : {}
                    }
                    // renderTags={(tagValue, getTagProps) =>
                    //   tagValue.map((option, index) => (
                    //     <Chip
                    //       label={option.name}
                    //       {...getTagProps({ index })}
                    //       disabled={option.disabled}
                    //     />
                    //   ))
                    // }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Formatos"
                        placeholder="Selecione um ou mais formatos"
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={segments}
                    disabled={segments.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) =>
                      handleQueryChange('segments', value)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Segmentos"
                        placeholder="Selecione um ou mais segmentos"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={occasions}
                    disabled={occasions.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) =>
                      handleQueryChange('occasions', value)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ocasioes"
                        placeholder="Selecione uma ou mais ocasioes"
                      />
                    )}
                  />
                </Grid> */}

                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Tags"
                    placeholder="Selecione as tags separadas por vírgula"
                    value={query.tags}
                    onChange={e => handleQueryChange('tags', e.target.value)}
                  /> */}
                  <Autocomplete
                    label="Tags"
                    name="tags"
                    options={tags}
                    getOptionValue={option => option}
                    getOptionLabel={option => option}
                    fullWidth
                    multiple
                    freeSolo
                    disabled={tags.length <= 0 || modelo_pronto}
                    onChange={(e, value) => {
                      handleQueryChange('tags', value.join(','));
                    }}
                    defaultValue={modelo_pronto ? ['modelo_pronto'] : []}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="Selecione uma ou mais tags"
                      />
                    )}
                  />
                </Grid>

                {!modelo_pronto && (
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex'
                    }}
                  >
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      label="Quantidade de produtos"
                      placeholder="Selecione a quantidade de produtos por pagina"
                      value={query.productQuantity}
                      onChange={e => {
                        let value = e.target.value;
                        if (value === '0') {
                          value = '';
                        } else {
                          Number(value);
                        }

                        handleQueryChange('productQuantity', value);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => {
              setTabValue(newValue);

              if (newValue === 1) {
                handleQueryChange('video', true);
              } else {
                handleQueryChange('video', null);
              }
            }}
            aria-label="image and video"
          >
            <Tab label="Imagem" />
            <Tab label="Vídeo (Beta)" />
          </Tabs>
        </Paper>

        <Grid item xs={12}>
          <GridList
            className={classes.gridList}
            cellHeight="auto"
            cols={matches ? 6 : 4}
            spacing={8}
          >
            {loading
              ? Array(20)
                  .fill(20)
                  .map((_, i) => (
                    <GridListTile>
                      <Skeleton
                        className={classes.skeleton}
                        variant="rect"
                        height={180}
                        width="100%"
                      />
                    </GridListTile>
                  ))
              : templates.map(item => (
                  // <GridListTile
                  //   key={item.id}
                  //   className={classes.image}
                  //   onClick={() => {
                  //     const { name } = formats.find(
                  //       f => f.id === item.formatId
                  //     );

                  //     onSelect({ ...item, type: name });
                  //   }}
                  // >
                  //   <TileTemplate item={item} />
                  // </GridListTile>
                  <div
                    key={item.id}
                    style={{ padding: '13px' }}
                    title={item.name + ' - id: ' + item.id}
                  >
                    <div
                      className={magnumClasses.gridCard}
                      onClick={() => {
                        const { name } = formats.find(f => f.id === item.formatId);

                        onSelect({ ...item, type: name });
                      }}
                    >
                      <div className={magnumClasses.gridMediaQueryHeight}>
                        <img
                          className={magnumClasses.gridImage}
                          src={item.imageURL}
                          alt={item.name + ' - id: ' + item.id}
                          title={item.name + ' - id: ' + item.id}
                          loading="lazy"
                        />
                      </div>
                    </div>

                    <div style={{ margin: 15 }}>
                      <div className={magnumClasses.gridTitle}>
                        {item.name + ' - id: ' + item.id}
                      </div>

                      <div style={{ width: '100%' }}>
                        {(item?.tags ? item.tags.split(',') : []).map(tag => (
                          <Chip size="small" label={tag} style={{ margin: 3 }} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
          </GridList>
        </Grid>
      </Grid>
    </InfiniteScroll>
  );
}

export default SelectTemplate;

