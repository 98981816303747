import React from 'react';
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { SyntheticEvent } from "react";

type AutocompleteProps = {
  selectOnFocus?: boolean;
  clearOnBlur?: boolean;
  handleHomeEndKeys?: boolean;
  freeSolo?: boolean;
  placeholder?: string;
  multiple?: boolean;
  label?: string;
  options?: any[];
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  disableClearable?: boolean;
  value?: any;
  defaultValue?: any;
  size?: "small" | "medium" | undefined;
  className?: string;
  error?: boolean;
  helperText?: string;
  renderOption?: any;
  getOptionLabel: (option: any) => string;
  onChange?: (_: SyntheticEvent<Element, Event>, option: any) => void;
  onInputChange?: (_: SyntheticEvent<Element, Event>, value: string) => void;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  noOptionsText?: React.ReactNode;
};

export function Autocomplete({
  disableClearable,
  selectOnFocus,
  clearOnBlur,
  handleHomeEndKeys,
  freeSolo,
  multiple,
  className,
  label,
  options = [],
  loading,
  disabled,
  fullWidth,
  value,
  defaultValue,
  size,
  placeholder,
  getOptionLabel,
  error,
  helperText,
  onChange,
  onInputChange,
  filterOptions,
  renderOption,
  noOptionsText,
}: AutocompleteProps) {
  return (
    <MuiAutocomplete
      disableClearable={disableClearable}
      selectOnFocus={selectOnFocus}
      clearOnBlur={clearOnBlur}
      handleHomeEndKeys={handleHomeEndKeys}
      freeSolo={freeSolo}
      onInputChange={onInputChange}
      noOptionsText={noOptionsText || "Nenhuma opção encontrada"}
      placeholder={placeholder}
      multiple={multiple}
      className={className}
      size={size}
      defaultValue={defaultValue}
      value={value}
      loadingText="Carregando..."
      loading={loading}
      options={options}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      filterOptions={filterOptions}
    />
  );
}
