import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import Table from 'material-table';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CreateSection from './components/CreateSection';
import useStyles from './styles';

function Sections({ siteId }) {
  const classes = useStyles();

  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createSectionIsOpen, setCreateSectionIsOpen] = useState(false);

  const getSections = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/sections`,
        { site_id: siteId }
      );

      setData(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSections();
  }, [getSections]);

  const handleCreateSectionOpen = () => {
    setCreateSectionIsOpen(true);
  };

  const handleCreateSectionClose = useCallback(() => {
    setCreateSectionIsOpen(false);
  }, [setCreateSectionIsOpen]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/sections`,
          { site_id: siteId, ...data },
          'POST'
        );
      } catch (err) {
        console.log(err);
      } finally {
        handleCreateSectionClose();
        getSections();
      }
    },
    [handleCreateSectionClose, getSections]
  );

  const handleEditSection = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/sections/${data.id}`,
          { active: !data.active },
          'PUT'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getSections();
      }
    },
    [getSections]
  );

  const handleRemoveSection = useCallback(
    async id => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/sections/${id}`,
          {},
          'DELETE'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getSections();
      }
    },
    [getSections]
  );

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <DescriptionIcon />
            </Avatar>
          }
          title="Faixas"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateSectionOpen}
            >
              Adicionar Faixa
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              { title: 'Titulo', field: 'title' },
              {
                title: 'Status',
                field: 'active',
                render: rowData => (
                  <Chip
                    className={
                      rowData.active ? classes.active : classes.inactive
                    }
                    label={rowData.active ? 'Ativo' : 'Inativo'}
                  />
                ),
              },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            onRowClick={(e, rowData) => {
              history.push(`sites/section/edit/${rowData.id}`);
            }}
            actions={[
              {
                icon: 'block',
                onClick: (e, rowData) => {
                  handleEditSection(rowData);
                },
              },
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleRemoveSection(rowData.id);
                },
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={localization}
          />
        </CardContent>
      </Card>
      {createSectionIsOpen && (
        <CreateSection
          open={createSectionIsOpen}
          onSubmit={handleSubmit}
          onClose={handleCreateSectionClose}
        />
      )}
    </Fragment>
  );
}

export default Sections;
