import { styled } from '@material-ui/styles';
import { Box, Typography } from '@mui/material';

const CustomTypography = styled(Typography)({
  letterSpacing: '0.05em',
  color: '#616161'
});

export const EmptyGallery = () => {
  return (
    <Box display="flex" flexDirection="column" gap={3.5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Box p={1.25} maxWidth={367} maxHeight={78} sx={{ background: '#fff' }}>
        <CustomTypography fontWeight={600} fontSize={15} lineHeight="22px" textAlign="center">
          Você ainda não criou nenhuma arte. Assista o vídeo e entenda como funciona 😉
        </CustomTypography>
      </Box>
      <Box>
        <iframe
          title="Como criar artes personalizadas"
          id="video-como-criar-artes-personalizadas"
          src="https://www.youtube.com/embed/5SY_LYZpPTU"
          width={585}
          height={386}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          // mozallowfullscreen
          webkitallowfullscreen
          allowfullscreen="1"
        />
      </Box>
    </Box>
  );
};

