import PropTypes from 'prop-types';
import React from 'react';
// important Swiper required modules
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
// core Swiper
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
// Styles must use direct files imports
import 'swiper/swiper.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

function DistributionPreview({ carouselItemsStateData, roundedBorders = false }) {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      loop={carouselItemsStateData.length < 1 ? true : false}
      pagination={{
        clickable: true
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className={
        `dsCampaignPreviewCarrossel` + (carouselItemsStateData.length < 2 ? ' onlyOneCard' : '')
      }
      style={{ width: '100%', right: '20px' }}
    >
      {carouselItemsStateData.map((carouselCard, index) => {
        return (
          <SwiperSlide
            key={index + carouselCard.id}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {carouselCard?.link ? (
              <video
                style={{
                  maxHeight: '500px',
                  maxWidth: '100%',
                  borderRadius: roundedBorders ? '1.2rem' : 0
                }}
                controls
                // loop
                autoPlay="autoplay"
              >
                <source src={carouselCard?.link} type="video/mp4" />
              </video>
            ) : (
              <img
                style={{
                  maxHeight: '500px',
                  maxWidth: '100%',
                  borderRadius: roundedBorders ? '1.2rem' : 0
                }}
                alt="post-preview"
                src={carouselCard?.image_url}
              />
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

DistributionPreview.propTypes = {
  carouselItemsStateData: PropTypes.object
};

export default DistributionPreview;
