import {
  API_URL_ACCOUTING,
  DATASALES_CRM_API,
  AMBIENTE,
  API_WHATSAPP,
} from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import Alert from '../../../utils/helpers/Alert';

export const isLoadingCampanha = () => dispatch =>
  dispatch({ type: 'LOADING_CAMPANHA' });

export const isLoadingExportCSV = status => dispatch =>
  dispatch({ type: 'LOADING_EXPORT_CSV', payload: status });

export const isLoadingWhatsApp = () => dispatch =>
  dispatch({ type: 'LOADING_WHATSAPP' });

export const getListaSMSLote = () => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getListaSMSLote`
  ).then(payload => dispatch({ type: 'LIST_SMS_LOTE', payload }));
};

export const getListaSMSLotePooling = () => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getListaSMSLote`
  ).then(payload => dispatch({ type: 'LIST_SMS_LOTE', payload }));
};

export const getListaSMSById = idListaCampanha => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getListaSMSById`,
    {
      idListaCampanha,
    }
  );
};

export const salvarTemplate = template => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postTemplate`,
    template,
    'POST'
  );
};

export const envioCampanhaSMS = campanha => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postSMSLote`,
    campanha,
    'POST'
  ).then(() => dispatch(getListaSMSLote()));
};

export const refazerCampanhaSMS = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postSMSRefazerLote`,
    campanha,
    'POST'
  ).then(() => dispatch(getListaSMSLote()));

export const envioCampanhaEmail = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/postEmail`,
    campanha,
    'POST'
  );

export const refazerCampanhaEmail = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/postEmailRefazerLote`,
    campanha,
    'POST'
  );

export const envioCampanhaCarta = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/postCampanhaCarta`,
    campanha,
    'POST'
  );
export const envioCampanhaPush = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/postCampanhaPush`,
    campanha,
    'POST'
  );

export const envioCampanhaRedeSocial = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/postCampanhaRedeSocial`,
    campanha,
    'POST'
  );

export const getPeopleProspecteiPF = objParameters => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/minhaBase`,
    objParameters
  ).then(payload => dispatch({ type: 'LIST_PROSPECTEI_PF', payload }));
};

export const getListProspecteiPFByID = idList => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getListaProspeccaoId`,
    {
      idList,
    }
  ).then(payload => dispatch({ type: 'LIST_PROSPECTEI_PF_BY_ID', payload }));
};

export const getBaseCNPJByID = idList => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getListProspectsByID`, {
    idList,
  }).then(payload => dispatch({ type: 'BASE_CNPJ_LIST', payload }));
};

export const getCoordinatesBaseCNPJ = idList => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getCoordinates`, {
    idList,
  }).then(payload => dispatch({ type: 'COORDINATES_CNPJ', payload }));
};

export const deleteListProspecteiPF = idList => dispatch =>
  new Alert().confirm('Deseja deletar mesmo essa lista ?', () => {
    dispatch(isLoadingCampanha());
    Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/deleteListaProspeccao`,
      { idList },
      'DELETE'
    ).then(() => dispatch(getCabecalhoProspects()));
  });

export const deleteListProspecteiPJ = idList => dispatch =>
  new Alert().confirm('Deseja deletar mesmo essa lista ?', () => {
    dispatch(isLoadingCampanha());
    Fetch(
      `${API_URL_ACCOUTING}vendaMais/deleteProspects`,
      { idList },
      'DELETE'
    ).then(() => dispatch(getCabecalhoProspects()));
  });

export const getCabecalhoProspects = () => async dispatch => {
  dispatch(isLoadingCampanha());
  const minhaBase = await Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getListaProspeccao`
  );
  dispatch({
    type: 'LIST_CABECALHO_PROSPECTS',
    payload: minhaBase,
  });
};

export const getListProspecteiPFCSVByID = idList => dispatch => {
  dispatch(isLoadingExportCSV(true));
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getCSVProspeccao`,
    {
      idList,
    }
  ).then(payload => {
    window.location.assign(payload.urlS3);
    dispatch(isLoadingExportCSV(false));
  });
};

export const getGraphicsProspecteiPFByID = idList => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getGraficoProspeccao`,
    {
      idList,
    }
  ).then(payload =>
    dispatch({ type: 'GRAPHICS_PROSPECTEI_PF_BY_ID', payload })
  );
};
export const getGraphicsBaseCNPJByID = idProspects => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getDashboardListProspects`, {
    idProspects,
  }).then(payload => dispatch({ type: 'GRAFICO_BASE_CNPJ', payload }));
};

export const sendSMSByIdList = List => dispatch => {
  dispatch(isLoadingCampanha());
  Fetch(`${API_URL_ACCOUTING}retail/sendSMSByIdList`, List, 'POST').then(() =>
    dispatch({ type: 'SEND_SMS' })
  );
};

export const pauseSendSMS = idListaCampanha => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postPausarLote`,
    idListaCampanha,
    'POST'
  ).then(() => dispatch(getListaSMSLotePooling()));
};

export const startSendSMS = idListaCampanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postIniciarLote`,
    idListaCampanha,
    'POST'
  ).then(() => dispatch(getListaSMSLotePooling()));

export const getInstancesWhatsApp = () => dispatch => {
  dispatch(isLoadingWhatsApp());
  Fetch(`${DATASALES_CRM_API}whatsapp-p1-${AMBIENTE}`, {}, 'POST').then(
    payload => {
      return dispatch({
        type: 'GET_INSTANCE_WHATSAPP',
        payload,
      });
    }
  );
};

export const getInstancesStatusWhatsApp = () => dispatch => {
  Fetch(`${DATASALES_CRM_API}whatsapp-p1-${AMBIENTE}/status`, {}).then(
    payload => {
      return dispatch({
        type: 'GET_INSTANCE_STATUS_WHATSAPP',
        payload,
      });
    }
  );
};

export const getInstancesStatusQRWhatsApp = () => dispatch => {
  Fetch(`${API_WHATSAPP}crm/qrcode`, {}).then(payload => {
    return dispatch({
      type: 'GET_INSTANCE_QRCODE_WHATSAPP',
      payload,
    });
  });
};

export const envioCampanhaWhatsApp = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create`,
    campanha,
    'POST'
  ).then(payload => dispatch({ type: 'ENVIAR_CAMPANHA_WHATSAPP', payload }));
