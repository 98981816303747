/* eslint-disable */
import {
  Avatar,
  Button,
  Icon,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';

import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FunnelChart,
  BarChart,
  CountCard,
  Countup,
  CustomTable,
  Panel,
  PieChart,
  SplitButton,
} from '../../../utils/components';
import {
  CELULARES,
  COLOR_PRIMARY,
  EMAILS,
  PARETO,
} from '../../../utils/helpers/Constants';
import { MoedaBrasil } from '../../../utils/helpers/Format';

import { getListaFiltro, modalPessoa } from '../../Insights/actions/actions';
import {
  getTaxaClique,
  getDesempenhoEnvio,
  getDetalhesCampanha,
  getPessoasByIDCampanha,
  getProdutosTabloideCampanha,
  getResgateCampanha,
  getRespostaCampanha,
} from '../action/action';
import '../style/performance.css';
import ListaResgateDetalhe from './ListaResgateDetalhe';
import ModalCestaProduto from './ModalCestaProduto';
import ModalDesempenho from './ModalDesempenho';
import ModalListaDetalhe from './ModalListaDetalhe';
import ModalPerfil from './ModalPerfil';
import ModalPerfis from './ModalPerfis';
import ModalRespostas from './ModalResposta';

class DetalhesCampanha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalDetalhe: false,
      showModal: false,
      showModalDesempenho: false,
      showModalSMS: false,
      showModalEmail: false,
      showModalRespostas: false,
      showModalCestaProduto: false,
      pra_quem: false,
      selectedProdutoTabloide: null,
      idCampanha: props.match.params.id,
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      dataInicioCampanha: moment().format('YYYY-MM-DD '),
      dataFimCampanha: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD '),
      pareto: PARETO[0],
      nomeCampanha: '',
      message: '',
      numero: '',
      nomeRemetente: '',
      remetente: '',
      email: '',
      subject: '',
      template: '',
      selectedCelular: CELULARES,
      numerosList: [],
      emailList: [],
      selectedEmail: EMAILS,
      keyVisao: 0,
      key: 0,
      tipo_usuario: '',
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params,
      { automacao, tipo_campanha } = this.props.campanha,
      id_campanha = id.split('_')[0],
      permissions = JSON.parse(localStorage.getItem('permissions')),
      has_campanha_simplificado = permissions.relacionamento
        ? permissions.relacionamento.find(
            ({ serviceName }) => serviceName === 'campanha_simplificado'
          )
        : false;

    if (has_campanha_simplificado) {
      this.setState({ tipo_usuario: 'simplificado' });
    }
    const pessoas_campanhas = {
      id_campanha: id_campanha,
      idCampanha: id_campanha,
      filtroDataInicio: this.state.dataInicioCampanha,
      filtroDataFim: this.state.dataFimCampanha,
      grupoCampanha: 'CONCLUIDO',
      automacao,
    };
    if (tipo_campanha === 'SMS') {
      this.props.getRespostaCampanha(id_campanha);
    }
    this.props.getListaFiltro();
    this.props.getProdutosTabloideCampanha(pessoas_campanhas);
    this.props.getDesempenhoEnvio(id_campanha);
    this.props.getTaxaClique(id_campanha);
    this.props.getResgateCampanha(pessoas_campanhas);
    this.props.getDetalhesCampanha(pessoas_campanhas);
    this.props.getPessoasByIDCampanha(pessoas_campanhas);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangeGrupoCampanha(grupo) {
    const id_campanha = this.props.match.params.id;
    const pessoas_campanhas = {
      idCampanha: id_campanha,
      id_campanha: id_campanha,
      grupoCampanha: grupo === 0 ? 'CONCLUIDO' : 'CONGELADO',
    };
    this.setState({ key: grupo });
    this.props.getDetalhesCampanha(pessoas_campanhas);
    this.props.getResgateCampanha(pessoas_campanhas);
    this.props.getProdutosTabloideCampanha(pessoas_campanhas);
  }

  render() {
    const {
        campanha,
        classes,
        resgateCampanha,
        statusEnvio,
        listaEnvio,
        pessoaModal,
        campanhaRespostas,
        produtosTabloideCampanha,
        taxaClique,
      } = this.props,
      {
        detalhesCampanha,
        vendasHora,
        vendasLoja,
        vendasDia,
        genero,
        faixaEtaria,
      } = this.props.detalhesCampanha,
      {
        showModalSMS,
        showModalEmail,
        showModalDetalhe,
        showModalDesempenho,
        showModalRespostas,
        showModalCestaProduto,
        selectedProdutoTabloide,
        idCampanha,
        keyVisao,
        key,
      } = this.state,
      detalheCampanha = detalhesCampanha[0] || {},
      grupoControle =
        detalheCampanha.pareto && detalheCampanha.pareto.split('/'),
      isContatosWhatsapp = lista =>
        lista.tipo_campanha === 'WHATSAPP' &&
        lista.id_lista_prospeccao === null;

    return (
      <div className={classes.root}>
        <Button
          size="small"
          style={{
            marginBottom: '1rem',
            color: COLOR_PRIMARY,
            width: 'max-content',
          }}
          color="primary"
          onClick={() => this.props.history.goBack()}
        >
          <Icon>arrow_back_ios</Icon>Voltar
        </Button>

        <Typography className={classes.label} variant="h3" noWrap>
          {listaEnvio[0] ? listaEnvio[0].nome_campanha : campanha.nome_campanha}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <div>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 0 && classes.activeButton}
              onClick={() => this.onChangeGrupoCampanha(0)}
            >
              {`Grupo de disparo - ${
                Boolean(grupoControle) ? grupoControle[0] : 0
              }%`}
            </Button>
            {Boolean(grupoControle) && parseInt(grupoControle[0]) !== 100 && (
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 1 && classes.activeButton}
                onClick={() => this.onChangeGrupoCampanha(1)}
              >
                {`Grupo de controle - ${grupoControle[1]}%`}
              </Button>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              width: '22%',
              justifyContent: 'space-between',
            }}
          >
            <SplitButton
              style={{ color: '#FFF' }}
              size="large"
              variant="contained"
              disabled
              noArrow
              text="Mais ações"
            >
              <MenuItem
                onClick={() => {
                  this.props.getPessoasByIDCampanha({
                    id_campanha: idCampanha,
                  });
                  this.setState({ showModal: true });
                }}
              >
                Lista de pessoas
              </MenuItem>
              {key === 0 && (
                <MenuItem
                  onClick={() => this.setState({ showModalRespostas: true })}
                >
                  Respostas
                </MenuItem>
              )}
            </SplitButton>
          </div>
        </div>
        <section>
          <Fragment>
            <Panel
              title="Visão Geral"
              subheader={`${moment(campanha.data_inicio_campanha).format(
                'DD/MM/YYYY'
              )} até ${moment(campanha.data_fim_campanha).format(
                'DD/MM/YYYY'
              )}`}
              style={{ marginBottom: 20 }}
            >
              <div
                style={{
                  display: 'flex',
                  width: isContatosWhatsapp(detalheCampanha) ? '30%' : '100%',
                  margin: 'auto',
                }}
              >
                <CountCard
                  count
                  loading={this.props.loadingDetalhes}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={
                    detalheCampanha.pessoas_alcancadas
                      ? parseFloat(detalheCampanha.pessoas_alcancadas)
                      : 0
                  }
                  title="Alcance"
                  icon="people"
                  style={{ marginRight: 20 }}
                />

                {!isContatosWhatsapp(detalheCampanha) && (
                  <Fragment>
                    <CountCard
                      count
                      helpButton
                      helpContent={
                        <section
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>
                            <strong>Campanha: </strong>
                            R$
                            {MoedaBrasil(detalheCampanha.gastos_campanha || 0)}
                          </span>
                          <span>
                            <strong>Desconto: </strong>
                            R$
                            {MoedaBrasil(
                              detalheCampanha.investimento_preco_2 || 0
                            )}
                          </span>
                        </section>
                      }
                      end={
                        detalheCampanha.gastos_campanha
                          ? parseFloat(detalheCampanha.gastos_campanha) +
                            parseFloat(detalheCampanha.investimento_preco_2)
                          : 0
                      }
                      prefix="R$ "
                      icon="build"
                      decimals={2}
                      titleTypographyProps={{ variant: 'h6' }}
                      title="Investimento"
                      style={{ marginRight: 20 }}
                      loading={this.props.loadingDetalhes}
                    />

                    {this.state.tipo_usuario != 'simplificado' && (
                      <Fragment>
                        <CountCard
                          count
                          helpButton
                          helpContent={
                            <section
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                <strong>Quantidade: </strong>
                                {MoedaBrasil(detalheCampanha.qtde_vendas || 0)}
                              </span>
                              <span>
                                <strong>Valor: </strong>
                                R${' '}
                                {MoedaBrasil(detalheCampanha.valor_vendas || 0)}
                              </span>
                            </section>
                          }
                          end={
                            detalheCampanha.qtde_vendas
                              ? parseFloat(detalheCampanha.qtde_vendas)
                              : 0
                          }
                          icon="score"
                          titleTypographyProps={{ variant: 'h6' }}
                          title="Vendas"
                          style={{ marginRight: 20 }}
                          loading={this.props.loadingDetalhes}
                        />

                        <CountCard
                          count
                          helpButton
                          helpContent={
                            <section
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                <strong>Faturamento: </strong>
                                R$
                                {MoedaBrasil(detalheCampanha.valor_vendas || 0)}
                              </span>
                              <span>
                                <strong>Ticket médio: </strong>
                                R$
                                {MoedaBrasil(detalheCampanha.ticket_medio || 0)}
                              </span>
                            </section>
                          }
                          end={
                            detalheCampanha.valor_vendas
                              ? parseFloat(detalheCampanha.valor_vendas)
                              : 0
                          }
                          prefix="R$ "
                          icon="add_shopping_cart"
                          titleTypographyProps={{ variant: 'h6' }}
                          title="Faturamento"
                          decimals={2}
                          style={{ marginRight: 20 }}
                          loading={this.props.loadingDetalhes}
                        />

                        <CountCard
                          count
                          helpButton
                          helpContent={
                            <section
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                R$ {MoedaBrasil(detalheCampanha.valor_roi || 0)}
                              </span>

                              <span>
                                {MoedaBrasil(detalheCampanha.roi || 0)}%
                              </span>
                            </section>
                          }
                          prefix="R$ "
                          end={
                            detalheCampanha.valor_roi
                              ? parseFloat(detalheCampanha.valor_roi)
                              : 0
                          }
                          titleTypographyProps={{ variant: 'h6' }}
                          title="ROI"
                          decimals={2}
                          icon="attach_money"
                          style={{ marginRight: 20 }}
                          loading={this.props.loadingDetalhes}
                        />

                        <CountCard
                          count
                          helpButton
                          helpContent={
                            <section
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                R$
                                {MoedaBrasil(detalheCampanha.margem || 0)}
                              </span>

                              <span>
                                {MoedaBrasil(
                                  detalheCampanha.margem_percentual || 0
                                )}
                                %
                              </span>
                            </section>
                          }
                          end={
                            detalheCampanha.margem_percentual
                              ? parseFloat(detalheCampanha.margem_percentual)
                              : 0
                          }
                          suffix="%"
                          icon="trending_up"
                          titleTypographyProps={{ variant: 'h6' }}
                          title="Margem"
                          decimals={3}
                          style={{ marginRight: 20 }}
                          loading={this.props.loadingDetalhes}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {this.state.tipo_usuario === 'simplificado' &&
                  statusEnvio.length > 0 && (
                    <Fragment>
                      <CountCard
                        count
                        loading={this.props.loadingDetalhes}
                        titleTypographyProps={{ variant: 'h6' }}
                        end={
                          statusEnvio[0].quantidade
                            ? parseFloat(statusEnvio[0].quantidade)
                            : 0
                        }
                        title={
                          statusEnvio[0].status_envio_externo.charAt(0) +
                          statusEnvio[0].status_envio_externo
                            .slice(1)
                            .toLowerCase()
                        }
                        icon="send"
                        style={{ marginRight: 20 }}
                      />

                      <CountCard
                        count
                        loading={this.props.loadingDetalhes}
                        titleTypographyProps={{ variant: 'h6' }}
                        end={
                          statusEnvio[1].quantidade
                            ? parseFloat(statusEnvio[1].quantidade)
                            : 0
                        }
                        title={
                          statusEnvio[1].status_envio_externo.charAt(0) +
                          statusEnvio[1].status_envio_externo
                            .slice(1)
                            .toLowerCase()
                        }
                        icon="pause"
                        style={{ marginRight: 20 }}
                      />

                      <CountCard
                        count
                        loading={this.props.loadingDetalhes}
                        titleTypographyProps={{ variant: 'h6' }}
                        end={
                          statusEnvio[2].quantidade
                            ? parseFloat(statusEnvio[2].quantidade)
                            : 0
                        }
                        title={
                          statusEnvio[2].status_envio_externo.charAt(0) +
                          statusEnvio[2].status_envio_externo
                            .slice(1)
                            .toLowerCase()
                        }
                        icon="close"
                        style={{ marginRight: 20 }}
                      />

                      {statusEnvio.length > 3 && (
                        <CountCard
                          count
                          loading={this.props.loadingDetalhes}
                          titleTypographyProps={{ variant: 'h6' }}
                          end={
                            statusEnvio[3].quantidade
                              ? parseFloat(statusEnvio[3].quantidade)
                              : 0
                          }
                          title={
                            statusEnvio[3].status_envio_externo.charAt(0) +
                            statusEnvio[3].status_envio_externo
                              .slice(1)
                              .toLowerCase()
                          }
                          icon="error-outline"
                          style={{ marginRight: 20 }}
                        />
                      )}
                    </Fragment>
                  )}
              </div>
            </Panel>
            <div
              style={{
                display:
                  this.state.tipo_usuario === 'simplificado' ||
                  isContatosWhatsapp(detalheCampanha)
                    ? 'none'
                    : 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 20,
              }}
            >
              <div>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    '&:hover': { borderRadius: 10 },
                  }}
                  color="inherit"
                  size="large"
                  className={keyVisao === 0 && classes.activeButton}
                  onClick={() => this.setState({ keyVisao: 0 })}
                >
                  Analítico
                </Button>

                {Boolean(produtosTabloideCampanha.length) && (
                  <Button
                    style={{
                      marginRight: 10,
                      borderRadius: 10,
                      '&:hover': { borderRadius: 10 },
                    }}
                    color="inherit"
                    size="large"
                    className={keyVisao === 1 && classes.activeButton}
                    onClick={() => this.setState({ keyVisao: 1 })}
                  >
                    Produtos
                  </Button>
                )}
              </div>
            </div>
            {keyVisao === 0 &&
              this.state.tipo_usuario != 'simplificado' &&
              !isContatosWhatsapp(detalheCampanha) && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Panel
                    width="49%"
                    action={
                      <Button
                        size="large"
                        className={classes.button}
                        variant="text"
                        color="primary"
                        onClick={() =>
                          this.setState({ showModalDetalhe: true })
                        }
                      >
                        Ver detalhe
                      </Button>
                    }
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Icon>shopping_cart</Icon>
                      </Avatar>
                    }
                    subheader={`${moment(campanha.data_inicio_campanha).format(
                      'DD/MM/YYYY'
                    )} até ${moment(campanha.data_fim_campanha).format(
                      'DD/MM/YYYY'
                    )}`}
                    title="Resgate de clientes"
                    style={{ marginBottom: 20 }}
                  >
                    <ListaResgateDetalhe lista={resgateCampanha} />
                  </Panel>
                  <Panel
                    width="49%"
                    title="Taxa de cliques"
                    style={{ marginBottom: 20 }}
                    action={
                      key === 0 &&
                      this.state.tipo_usuario != 'simplificado' &&
                      !isContatosWhatsapp(detalheCampanha) && (
                        <Button
                          size="large"
                          className={classes.button}
                          variant="text"
                          color="primary"
                          onClick={() =>
                            this.setState({ showModalDesempenho: true })
                          }
                        >
                          Ver detalhe
                        </Button>
                      )
                    }
                  >
                    {Boolean(taxaClique.length) ? (
                      Boolean(taxaClique[0].value) ? (
                        <FunnelChart data={taxaClique} />
                      ) : (
                        <Typography
                          className={classes.label}
                          variant="body2"
                          noWrap
                        >
                          Não foi possível recuperar a taxa de clique.
                        </Typography>
                      )
                    ) : (
                      <Typography
                        className={classes.label}
                        variant="body2"
                        noWrap
                      >
                        Recuperando a taxa de clique.
                      </Typography>
                    )}
                  </Panel>
                  <Panel
                    width="100%"
                    title="Por loja "
                    style={{ marginBottom: 20 }}
                  >
                    <BarChart
                      data={vendasLoja}
                      barDataKey="qtde_vendas"
                      xDataKey="nome_loja"
                      hide
                    />
                  </Panel>
                  <Panel
                    width="100%"
                    title="Por dia "
                    style={{ marginBottom: 20 }}
                  >
                    <BarChart
                      data={vendasDia}
                      barDataKey="qtde_vendas"
                      xDataKey="DATA_ANALISE"
                    />
                  </Panel>
                  <Panel
                    width="100%"
                    title="Vendas por hora "
                    style={{ marginBottom: 20 }}
                  >
                    <BarChart
                      data={vendasHora}
                      barDataKey="qtde_vendas"
                      xDataKey="HORA_ANALISE"
                    />
                  </Panel>
                  <Panel
                    width="49%"
                    title=" Genero"
                    style={{ marginBottom: 20 }}
                  >
                    <PieChart data={genero} />
                  </Panel>
                  <Panel
                    width="49%"
                    title="Faixa etaria"
                    style={{ marginBottom: 20 }}
                  >
                    <PieChart data={faixaEtaria} />
                  </Panel>
                </div>
              )}
            {keyVisao === 1 &&
              produtosTabloideCampanha.length > 0 &&
              this.state.tipo_usuario != 'simplificado' && (
                <CustomTable
                  isLoading={this.props.loadingDetalhes}
                  data={produtosTabloideCampanha}
                  title=""
                  paging
                  onRowClick={(e, rowData) =>
                    this.setState({
                      selectedProdutoTabloide: rowData,
                      showModalCestaProduto: true,
                    })
                  }
                  pageSize={5}
                  headerStyle={{ zIndex: 0 }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  columns={[
                    { title: 'Cod Produto', field: 'CODIGO' },
                    { title: 'Produto', field: 'NOME_PRODUTO' },
                    {
                      title: 'Preço venda',
                      field: 'PRECO_DOIS',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.PRECO_DOIS}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Percentual de Desconto',
                      field: 'DESCONTO_PERCENTUAL',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.DESCONTO_PERCENTUAL}
                          suffix="%"
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Faturamento Produto',
                      field: 'TOTAL_PRODUTO ',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.TOTAL_PRODUTO}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Produto R$',
                      field: 'MARGEM_PRODUTO',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM_PRODUTO}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Produto %',
                      field: 'MARGEM_PERCENTUAL_PRODUTO',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM_PERCENTUAL_PRODUTO}
                          suffix="%"
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Tamanho Cesta',
                      field: 'CESTA_MEDIA',
                      render: rowData => (
                        <Countup start={0} end={rowData.CESTA_MEDIA} />
                      ),
                    },
                    {
                      title: 'Faturamento Cesta',
                      field: 'TOTAL',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.TOTAL}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Cesta R$',
                      field: 'MARGEM',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Cesta %',
                      field: 'MARGEM_PERCENTUAL',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM_PERCENTUAL}
                          suffix="%"
                          decimals={2}
                        />
                      ),
                    },

                    {
                      title: 'Cupons com item',
                      field: 'TOTAL_CUPOM',
                      render: rowData => (
                        <Countup start={0} end={rowData.TOTAL_CUPOM} />
                      ),
                    },
                  ]}
                />
              )}
          </Fragment>
          {this.state.showModal && (
            <ModalPerfis
              open={this.state.showModal}
              pessoa={this.state}
              onClose={() => this.setState({ showModal: false })}
            />
          )}
          {pessoaModal.visible && (
            <ModalPerfil
              openModal={pessoaModal.visible}
              pessoa={pessoaModal.pessoa}
              onClose={() => this.props.modalPessoa(false, {})}
            />
          )}
          {showModalDetalhe && (
            <ModalListaDetalhe
              open={showModalDetalhe}
              onClose={() => this.setState({ showModalDetalhe: false })}
            />
          )}
          {showModalDesempenho && (
            <ModalDesempenho
              data={{ statusEnvio, listaEnvio }}
              open={showModalDesempenho}
              onClose={() => this.setState({ showModalDesempenho: false })}
            />
          )}
          {showModalRespostas && (
            <ModalRespostas
              showModal={showModalRespostas}
              respostas={campanhaRespostas}
              mensagem={listaEnvio.length > 0 ? listaEnvio[0].mensagem : ''}
              onClose={() => this.setState({ showModalRespostas: false })}
            />
          )}{' '}
          {showModalCestaProduto && (
            <ModalCestaProduto
              showModal={showModalCestaProduto}
              produto={selectedProdutoTabloide}
              onClose={() =>
                this.setState({
                  selectedProdutoTabloide: null,
                  showModalCestaProduto: false,
                })
              }
            />
          )}
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },

  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ PerformanceReducer, InsightsReducer }) => ({
  ...PerformanceReducer,
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getProdutosTabloideCampanha,
  getRespostaCampanha,
  getDesempenhoEnvio,
  getTaxaClique,
  getResgateCampanha,
  getDetalhesCampanha,
  getPessoasByIDCampanha,
  getListaFiltro,
  modalPessoa,
};

const DetalhesCampanhaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalhesCampanha);

export default withStyles(styles)(DetalhesCampanhaConnect);
