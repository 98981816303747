import { blue, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

import { COLOR_NEW_PRIMARY, COLOR_PRIMARY } from './../utils/helpers/Constants';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat']
  },
  shadows: ['none'],
  props: {
    MuiCheckbox: { color: 'primary' },
    MuiRadio: { color: 'primary' }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#312a5e !important'
        }
      },
      textPrimary: {
        color: COLOR_PRIMARY
      }
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: '#F5F5F5 !important'
        }
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      ...blue,
      500: COLOR_PRIMARY,
      main: '#2813AD',
      100: '#B336325e'
    },
    secondary: red,
    info: {
      main: '#2813AD'
    }
    // info: {
    //   main: COLOR_NEW_PRIMARY
    // }
  },

  status: {
    danger: 'orange'
  },
  shape: {
    borderRadius: 5
  },
  spacing: 8,
  zIndex: { snackbar: 999999999 }

  // zIndex: {
  //   appBar: 1200,
  //   drawer: 1100,
  // },
});

export default theme;

