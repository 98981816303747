/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Step,
  StepButton,
  Stepper,
  Typography,
  withStyles,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CompactPicker } from 'react-color';
import {
  ImageCard,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import {
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas,
  getTabloidsTemplatesFisico,
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import { json } from '../../../../utils/helpers/Functions';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

class GeneratePixelixeMenor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: 'Encarte-A4-12P-CPD-inicio_do_inverno-001',
      qtde_produtos: 12,
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: { r: 0, g: 0, b: 0, a: 1 },
      showDesativarModelo: false,
      etiquetas: [],
      font_size: null,
      sendData: null,
      loadingTemplates: false,
      listaTemplates: {},
    };
  }

  componentDidMount() {
    this.setState({ loadingTemplates: true });
    this.props
      .getTabloidsTemplatesFisico(
        {
          tipoPixelixe: 'encarteMenor',
        },
        true,
        false
      )
      .then(() => {
        let listaTemplatesTmp = {};

        this.props.tabloids_templates_fisico.reduce((acum, curr) => {
          listaTemplatesTmp[curr['nome'] + ' / id:' + curr['id']] = curr;
        }, {});

        this.setState({
          listaTemplates: listaTemplatesTmp,
          loadingTemplates: false,
        });
      });

    this.props.getTabloidsEtiquetas().then(payload => {
      for (var j = 0; j < this.props.tabloids_etiquetas.length; j++) {
        this.setState({
          etiquetas: [
            ...this.state.etiquetas,
            {
              ...this.props.tabloids_etiquetas[j],
              selected: false,
            },
          ],
        });
      }
    });
  }

  showPopover = current => {
    this.setState({ anchorEl: current });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeComplete = color => {
    this.setState(
      {
        colorText: color.rgb,
      },
      () => {
        let tmp = { ...this.state.json_parse };

        let indexOf = null;

        if (tmp.modifications && tmp.modifications['produto-descricao']) {
          tmp.modifications[
            'produto-descricao'
          ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }

        this.setState({ json_parse: { ...tmp } });
      }
    );
  };

  header(props, state) {
    return <Fragment>Gerar Etiqueta</Fragment>;
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            variant="contained"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.props.onGeneratePixelixeMenor({
                ...this.state.sendData,
                json: JSON.stringify(this.state.json_parse),
              });
              this.props.onClose();
            }}
            disabled={false}
          >
            Gerar
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { classes } = this.props;

    return (
      <section>
        <section style={{ padding: '2rem 4rem' }}>
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Template
          </Typography>
        </section>
        <Divider />
        <section
          style={{
            padding: '2rem 1rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Grid item xs={12}>
              <GridList
                cellHeight="auto"
                cols={8} //matches ? 8 : 6
                spacing={8}
                className={classes.gridList}
              >
                {this.state.loadingTemplates
                  ? Array(10)
                      .fill(10)
                      .map((_, i) => (
                        <GridListTile>
                          <Skeleton variant="rect" height={160} width="100%" />
                        </GridListTile>
                      ))
                  : Object.keys(this.state.listaTemplates).map(
                      (valueAtual, index) => (
                        <GridListTile className={classes.tile} key={valueAtual}>
                          <CardActionArea
                            onClick={() => {
                              this.setState({
                                activeStep: this.state.activeStep + 1,
                                json_parse: JSON.parse(
                                  this.state.listaTemplates[valueAtual]['json']
                                ),
                                sendData: {
                                  chave_template: this.state.listaTemplates[
                                    valueAtual
                                  ]['template'],
                                  id_template: this.state.listaTemplates[
                                    valueAtual
                                  ]['id'],
                                  json: this.state.listaTemplates[valueAtual][
                                    'json'
                                  ],
                                  nome_modelo: this.state.listaTemplates[
                                    valueAtual
                                  ]['nome'],
                                  produto_qtde: this.state.listaTemplates[
                                    valueAtual
                                  ]['qtde_produtos'],
                                  tipoPixelixe: this.state.listaTemplates[
                                    valueAtual
                                  ]['tipoPixelixe'],
                                  miniatura: this.state.listaTemplates[
                                    valueAtual
                                  ]['miniatura'],
                                },
                              });
                            }}
                          >
                            <img
                              className={classes.img}
                              src={
                                this.state.listaTemplates[valueAtual][
                                  'miniatura'
                                ]
                              }
                              alt={valueAtual}
                              title={valueAtual}
                              style={{ width: '100%', maxWidth: 400 }}
                            />
                          </CardActionArea>
                          <GridListTileBar
                            title={valueAtual}
                            classes={{
                              root: classes.titleBar,
                              title: classes.title,
                            }}
                          />
                        </GridListTile>
                      )
                    )}
              </GridList>
            </Grid>
          </div>
        </section>
      </section>
    );
  }

  step2(props, state) {
    const { classes } = this.props;
    const { variaveis, json_parse } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', width: '35%' }}>
          <Divider
            style={{
              margin: 'auto',
              marginTop: 20,
              width: '80%',
            }}
          />
          {!this.state.template.includes('http') && (
            <div style={{}}>
              <div>
                <div style={{ marginTop: 20, marginBottom: 12 }}>
                  Cor do texto
                </div>
                <div
                  style={{
                    width: '90%',
                    margin: 'auto',
                  }}
                >
                  <CompactPicker
                    color={this.state.colorText}
                    onChangeComplete={this.handleChangeComplete}
                    styles={{
                      default: {
                        picker: {
                          boxShadow: 'none',
                          margin: 'auto',
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                Tamanho do nome dos produtos:
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.font_size}
                  onChange={event => {
                    const tmp = { ...json_parse };
                    tmp.modifications['produto-descricao'] = {
                      ...tmp.modifications['produto-descricao'],
                      'font-size': event.target.value + 'px',
                    };
                    if (event.target.value === '') {
                      delete tmp.modifications['produto-descricao'][
                        'font-size'
                      ];
                    }
                    this.setState({ json_parse: { ...tmp } });
                    this.setState({ font_size: event.target.value });
                  }}
                  label="Tamanho do nome dos produtos"
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <MenuItem value="">
                    <em>Normal</em>
                  </MenuItem>
                  <MenuItem value={100}>Médio</MenuItem>
                  <MenuItem value={140}>Grande</MenuItem>
                  <MenuItem value={180}>Gigante</MenuItem>
                </Select>
              </div>
            </div>
          )}
          <div>
            <Divider
              style={{
                margin: 'auto',
                width: '80%',
                marginTop: 30,
              }}
            />
            <div style={{ marginTop: 30 }}>Etiqueta de preço</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '80%',
                margin: '10px auto 20px',
                flexWrap: 'wrap',
              }}
            >
              {this.state.etiquetas.map((value, index) => (
                <img
                  alt={value.etiqueta}
                  src={value.etiqueta}
                  style={{
                    width: 50,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7',
                    margin: 10,
                    border: value.selected ? '2px solid blue' : '',
                  }}
                  onClick={() => {
                    let tmp = this.state.etiquetas;
                    for (var j = 0; j < tmp.length; j++) {
                      tmp[j].selected = false;
                    }
                    tmp[index].selected = true;
                    this.setState({
                      etiquetas: tmp,
                      sendData: {
                        ...this.state.sendData,
                        etiqueta: value.etiqueta,
                      },
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20, width: '35%' }}>
          <img
            style={{ width: '100%', border: '1px solid' }}
            src={this.state.sendData.miniatura}
          ></img>
        </div>
      </div>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: '100%',
  },
  title: {
    color: theme.palette.common.white,
  },
  titleBar: {
    borderRadius: theme.shape.borderRadius,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
  },
  tile: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas,
  getTabloidsTemplatesFisico,
};

const GeneratePixelixeMenorConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePixelixeMenor);

export default withStyles(styles)(GeneratePixelixeMenorConnect);
