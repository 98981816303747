import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  accordion: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  accordionSummary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  accordionSummaryContent: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    padding: theme.spacing(2),
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
  },
  //------StylesQRCode-----
  dialogQRCodeBackground:{
    position: 'fixed !important',
    width: '100% !important',
    height: '100% !important',
    left: '0px !important',
    top: '0px !important',
    /*Opacidade*/
    background: 'rgba(0, 0, 0, 0.7);',
    zIndex: 9999,
    position: 'fixed !important'
  },
  dialogQRCode:{
    position: 'fixed',
    display: 'flex !important',
    width: '610px !important',
    height: '650px !important',
    border: '1px black solid',
    flexDirection: 'column !important',
    alignItems: 'flex-start !important',
    padding: '32px !important',
    gap: '24px !important',
    zIndex: 999999,
    left: '30%',
    top: '10%',
    background:  '#FFFFFF',
    borderRadius: '8px',
   },
   gridInfoQRCode:{
     marginLeft: '-15px'
   },
   typographyInfoQRCode:{
     fontSize: '15px',
     marginTop: '15px'
  }
  //------------------------
}));

export default useStyles;
