import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

//Using Actions
import {
  getListProspeccao,
  getPriceEnrichment,
  getEnrichment,
  postEnrichment,
} from '../action';

// Using Components
import {
  Box,
  Button,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CheckCircle, Visibility, Autorenew } from '@material-ui/icons';
import { CustomTable } from '../../../utils/components';
import ModalEnrichment from './ModalEnrichment';
import ModalDetailEnrichment from './ModalDetailEnrichment';

// Using Styles
import useStyles from './styles';

// Using Helpers
import MyDate from '../../../utils/helpers/Date';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

// Init Instances
const _myDate = new MyDate();

function Enriqucimento(props) {
  const classes = useStyles(),
    [openModal, setOpenModal] = useState(false),
    [openModalDetail, setOpenModalDetail] = useState(false),
    [enrichment, setEnrichment] = useState({}),
    [detail, setDetail] = useState({
      data_insert: '',
      id_base: 0,
      id_prospeccao: 0,
      nome_prospeccao: '',
      price_enrichment: '0',
      processado: 0,
      quantity_enrichment: 0,
      rule_enrichment: '',
    });

  useEffect(() => {
    props.getEnrichment();
    props.getListProspeccao();
  }, []);

  function onChange(key, value) {
    Object.assign(enrichment, { [key]: value });
    setEnrichment(enrichment);
  }

  function onSave() {
    const price = props.priceEnrichement,
      objEnrichment = {
        id_prospeccao: enrichment.audiencia.idListProspectei,
        nome_prospeccao: enrichment.audiencia.nameList,
        rule_enrichment: enrichment.ruleEnrichment,
        price_enrichment: price.total_price,
        quantity_enrichment: price.quantity,
      };
    props.postEnrichment(objEnrichment);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.actions}>
        <Typography className={classes.label} variant="h3" noWrap>
          Enriquecimento
        </Typography>
      </Box>
      <Box className={classes.actions} justifyContent="flex-end">
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          style={{
            backgroundColor: COLOR_PRIMARY,
            marginRight: 15,
          }}
        >
          Enriquecer
        </Button>
        <Tooltip title="Atualizar lista" placement="top">
          <IconButton onClick={() => props.getEnrichment()}>
            <Autorenew />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.content}>
        <Paper className={classes.card}>
          <CustomTable
            data={props.listEnrichment}
            isLoading={props.loadListEnrichment}
            title="Enriquecimentos"
            columns={[
              { title: '#', field: 'id_base' },
              { title: 'Audiência', field: 'nome_prospeccao' },
              {
                title: 'Data de enriquecimento',
                field: 'data_insert',
                render: row => (
                  <span>
                    {_myDate.getDate(
                      'DD/MM/YYYY HH:mm',
                      row.data_insert.replace('Z', '')
                    )}
                  </span>
                ),
              },
              {
                title: 'Status do enriquecimento',
                field: 'processado',
                render: row =>
                  Boolean(row.processado) ? (
                    <Tooltip title="Audiência enriquecida" placement="top">
                      <CheckCircle style={{ fontSize: 25, color: '#00875a' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Enriquecimento em andamento"
                      placement="top"
                    >
                      <CircularProgress size={22} thickness={4} />
                    </Tooltip>
                  ),
              },
              {
                title: 'Ações',
                render: row => (
                  <Tooltip title="Ver detalhes" placement="top">
                    <IconButton
                      onClick={() => {
                        setDetail(row);
                        setOpenModalDetail(true);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                ),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              selectionProps: () => ({
                color: 'primary',
              }),
            }}
          />
        </Paper>
      </Box>

      <ModalEnrichment
        onSelectAudiencia={idProspeccao =>
          props.getPriceEnrichment(idProspeccao)
        }
        onClose={() => setOpenModal(false)}
        onChange={(key, value) => onChange(key, value)}
        onSave={() => onSave()}
        price={props.priceEnrichement}
        prospeccao={props.listProspeccao}
        enrichment={enrichment}
        open={openModal}
      />

      <ModalDetailEnrichment
        detail={detail}
        open={openModalDetail}
        onClose={() => setOpenModalDetail(false)}
      />
    </Box>
  );
}

const mapStateToProps = ({ EnriquecimentoReducer }) => ({
  ...EnriquecimentoReducer,
});

const mapDispatchToProps = {
  getListProspeccao,
  getPriceEnrichment,
  postEnrichment,
  getEnrichment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enriqucimento);
