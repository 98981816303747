import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {ButtonFile} from "../../../../../../utils/components/ButtonFile"
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStyles } from "./styles";
import { Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useFormikContext } from "formik";
import{ChatBotFileData} from '../../../../../../types/chatbot'
import { useSnackbar } from 'notistack';
import { linkDownload } from '../../../../../../services/imports'

type fileList = {
  name: string, 
  size: number;
};

export function File(props: any) {
  const formik = useFormikContext<ChatBotFileData>();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function validation(file: fileList){
    let tamanho = (file.size / 1024)/1024
    const splitName = file.name.split(".");

    if(splitName[splitName.length - 1] === 'csv' 
    || splitName[splitName.length - 1] === 'CSV'
    || splitName[splitName.length - 1] === 'xlsx' 
    || splitName[splitName.length - 1] === 'XLSX' ){
      if(tamanho <= 10){
        formik.setFieldValue("file", file)
      }else{
        enqueueSnackbar('Arquivo maior do que 10MB!', { variant: 'error' });
      }
    }else{
      enqueueSnackbar('Arquivo diferente de .CSV ou .XLSX', { variant: 'error' });
    }
  }

  return (
    <Stack spacing={4}>
      <Typography className={styles.titulo}
      sx={{
        fontSize: '1.3rem',
        fontWeight: '500',
        padding: '0px',
        color: '#1D1D1D'
      }}
      >
        Importe seu arquivo de contatos
      </Typography>
      <Typography sx={{marginTop: '0.4rem !important'}}>
        <a href={linkDownload} style={{color: '#127FFF'}}>Faça o download do modelo de arquivo para importação</a>
      </Typography>

      <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
        <ButtonFile
          accept={[
            ".csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          icon={<CloudUploadIcon />}
          label={formik.values.file?.name}
          onChange={(file) => {
            validation(file)
            
          }}
        />
      </Box>

      <Box 
        sx={{
          display: 'flex', 
          border: '2px solid #69C0FF', 
          alignItems: 'center', 
          background: '#E0FFFF',
          paddingRight: '3.43rem'
          }}>
        <InfoRoundedIcon sx={{margin: '0.75rem', color: '#1890FF'}}/>
        <Typography sx={{fontSize:'0.75rem', fontWeight: '500'}} fontSize="small">
          O arquivo precisa ser do tipo .CSV ou .XLSX e não pode ter mais que 10MB.
        </Typography>
      </Box>

      <Box 
        sx={{
          display: 'flex', 
          border: '2px solid #FFCCC7', 
          alignItems: 'center', 
          background: '#ffecea',
          padding:'0.5rem',
          paddingRight: '3.43rem',
          paddingLeft: '0'
          }}>
        <CancelRoundedIcon sx={{margin: '0.75rem', color: '#EE2C2C'}}/>
        <Typography sx={{fontSize:'0.75rem', fontWeight: '500', letterSpacing: '5%', lineHeight: '1.375rem'}} fontSize="small">
          Atenção! Enviar mensagens de WhatsApp sem consetimento dos usuários viola as regras de política de privacidade do WhatsApp
          e pode acarretar em um possível banimento do seu número do WhatsApp.
        </Typography>
      </Box>
      
      <Typography 
        sx={{
          marginTop: '1.25rem !important', 
          fontSize: '0.75rem', 
          fontWeight: '500', 
          letterSpacing: '5%', 
          lineHeight: '1.375rem',
          color: '#616161'
          }}>
        Ao concordar com esta opção, você está de acordo com a <a href='https://www.whatsapp.com/legal/privacy-policy/?locale=pt_BR' 
        style={{color: '#127FFF'}} target="_blank">política de 
        privacidade do WhatsApp.</a> A Datasales não se resposabiliza por 
        quaisquer danos ocorridos por disparos que 
        violam a política de privacidade do WhatsApp.
      </Typography>

      <FormControlLabel sx={{marginTop: '0.71rem !important', color: '#616161', fontSize:'0.87rem !important', fontWeight: '500 !important'}}
            control={
              <Checkbox checked={!props.checked} name="gilad" color="info" sx={{paddingLeft: '0 !important'}} 
                onClick={()=>{
                  props.openCheck()
                }}/>
            }
            label="Estou ciente e concordo com a política de privacidade do Whatsapp."
          />
    </Stack>
  );
}