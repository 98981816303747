import React, { useState } from "react";
import Countup from "react-countup";
import { makeStyles } from "@material-ui/core/styles";
import { 
  Card, 
  CardHeader, 
  Avatar, 
  Icon,
  IconButton,
  Popover 
} from "@material-ui/core";
import { COLOR_PRIMARY } from "../../helpers/Constants";

export default function CountCard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function showPopover(current) {
    setAnchorEl(current);
  }

  function closePopover() {
    setAnchorEl(null);
  }

  return (
    <Card onClick={props.onClick} style={props.style} className={classes.card}>      
      <CardHeader
        {...props}
        avatar={
          <Avatar className={classes.avatar}>
            <Icon>{props.icon}</Icon>
          </Avatar>
        }
        title={
          isNaN(parseFloat(props.title)) ? (
            props.title
          ) : (
            <React.Fragment>
                <div>
                  <Countup
                    start={0}
                    end={parseFloat(props.title)}
                    duration={5}
                    separator="."
                    decimal=","
                  />                                        
                </div>      
                {props.subtitle && 
                  <div style={{fontSize: "0pt"}}>
                    <Countup
                      start={0}
                      end={parseFloat(props.subtitle)}
                      duration={5}
                      separator="."
                      decimal=","
                      decimals={props.decimals_sub}
                      prefix={props.prefix_sub}
                      style={{fontSize: "11pt"}}
                    />
                  </div>                  
                }           
            </React.Fragment>
          )
        }
        titleTypographyProps={props.titleTypographyProps || { variant: "h5" }}
        subheader={
          isNaN(parseFloat(props.subheader)) ? (                
            <div>
              {props.subheader}
              {props.helpButton && 
                <React.Fragment>
                  <IconButton
                    onClick={({ currentTarget }) => showPopover(currentTarget)}
                    style={{ 
                      padding: 0,
                      marginLeft: 5, 
                    }}
                  >
                    <Icon>help_outline</Icon>
                  </IconButton>
                  <Popover
                    id="simple-popover"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <section style={{ padding: '1rem' }}>                  
                      {props.helpContent}
                    </section>
                  </Popover>
                </React.Fragment>                
              }              
            </div> 
          ) : (
            <Countup
              start={0}
              end={parseFloat(props.subheader)}
              duration={5}
              separator="."
              decimal=","
              decimals={props.decimals}
              prefix={props.prefix}
            />
          )
        }
        subheaderTypographyProps={
          props.subheaderTypographyProps || { variant: "subtitle1" }
        }
      />         
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    borderRadius: 10,
    width: "100%"
  },
  cardContent: { display: "flex", flexDirection: "column" },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  icon: {
    display: "flex"
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
}));
