import axios from 'axios';

export const logError = async params => {
  try {
    await axios({
      // responseType: 'json',
      method: 'POST',
      url: 'https://api.datasales.info/editor-dev/log',
      data: {
        message: JSON.stringify({ title: 'DS.Marketing', ...params.dataError }, null, '\t')
      },
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem('token'))}`
      }
    });
  } catch (err) {
    console.log(err);
  }
};

