import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    content: {
      padding: 0,
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
      width: '100%',
    },
    emptyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
      width: '100%',
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export default styles;
