import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const BoxCheckBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  marginLeft: '-10px',
  marginTop: '1px',

  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.05rem',
  color: '#616161'
}));

export const SectionTwoText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px'
}));

export const SectionOne = styled(Box)(() => ({
  padding: '0px 0px 20px 0px',
  width: '100%'
}));

export const TypographyTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '26px',
  letterSpacing: '0.05rem',
  color: '#1D1D1D',
  marginBottom: '4px',
  height: '26px'
}));

export const TypographySubTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px',
  letterSpacing: '0.05rem',
  color: '#616161',
  marginBottom: '32px',
  height: '22px'
}));

export const FormControlFormPag = styled(FormControl)(() => ({
  marginBottom: '32px',
  marginTop: '32px',
  border: '0px'
}));

export const TextFieldForms = styled(TextField)(() => ({
  marginBottom: '24px',
  width: '100%',
  height: '52px'
}));

export const ButtonConfig = styled(Button)(() => ({
  height: '22px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: ' 0.05em',
  flex: 'none',
  order: '1',
  flexGrow: '0',
  marginTop: '24px',
  '&:hover': {
    background: '#fff',
    textDecorationLine: 'underline'
  }
}));
