/* eslint-disable */
import {
  Button,
  Divider,
  Typography,
  withStyles,
  Icon,
  IconButton,
  CircularProgress,
  Tooltip,
  Box,
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DialogAlert, CustomTable } from '../../../../utils/components';
import Alert from '../../../../utils/helpers/Alert';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import {
  getVideomatik, //
  postVideomatik,
} from '../../action/index';
import { GenerateVideomatik } from '../';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import { red } from '@material-ui/core/colors';

const alerta = new Alert();

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

class Videomatik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      qtde: null,
      loading: false,
      videomatikTable: [],
      templates: [],
      page: 1,
      nome_tabloide: '',
      generateVideomatik: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ nome_tabloide: id.split('__')[1] });

    this.setState(
      { id: id.split('_')[0], qtde: id.split('_')[1] },
      async () => {
        await this.onRefresh(true);

        this.interval = setInterval(() => {
          let array = this.state.videomatikTable.filter(value => !value.link);
          if (array.length > 0) {
            this.onRefresh(false);
          }
        }, 30000);
      }
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async onRefresh(loading) {
    this.setState({ loading: loading });
    const videomatikTable = await this.props.getVideomatik({
      id_tabloide: this.state.id,
    });

    this.setState({
      videomatikTable: videomatikTable.response,
      loading: false,
      templates: videomatikTable.templates,
    });
  }

  render() {
    const { classes } = this.props;
    const { page } = this.state;

    const data = this.state.videomatikTable.filter(value => value.link);

    return (
      <div className={classes.root} ref={this.myRef}>
        <Link
          to={`/propaganda-de-ofertas`}
          style={{ marginTop: 15, textDecoration: 'none' }}
        >
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Propaganda de Ofertas
          </Button>
        </Link>
        <div className={classes.actions}>
          <div className={classes.actions} style={{ maxWidth: '65vw' }}>
            <Typography className={classes.margin} variant="h3">
              {'Vídeos - ' + this.state.nome_tabloide}
            </Typography>
          </div>
          <div style={{ marginBottom: 20, display: 'flex' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: COLOR_PRIMARY,
                marginRight: 15,
                marginBottom: 10,
              }}
              onClick={() => this.setState({ generateVideomatik: true })}
            >
              Gerar novo
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: COLOR_PRIMARY,
                marginRight: 15,
                marginBottom: 10,
              }}
              onClick={() => this.onRefresh(true)}
            >
              <Icon>sync</Icon>
            </Button>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div
            style={{
              margin: 'auto',
              marginBottom: 20,
              display: this.state.loading ? 'none' : 'block',
            }}
          >
            <Pagination
              classes={{
                ul: classes.ul,
              }}
              count={Math.ceil(data.length / 1)}
              page={page}
              onChange={(e, v) => this.setState({ page: v })}
            />
          </div>
          <div
            style={{
              display: this.state.loading ? 'none' : 'flex',
              flexWrap: 'wrap',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {/* {data.length === 0 && 'Agora é só clicar em Gerar novo!'} */}
            {data.map(
              (value, index) =>
                index < page * 1 &&
                index >= page * 1 - 1 && (
                  <div
                    style={{
                      margin: 20,
                    }}
                  >
                    <video width="250" controls loop autoplay="autoplay">
                      <source src={value.link} type="video/mp4" />
                    </video>
                  </div>
                )
            )}
          </div>
          <div className={classes.content}>
            <CustomTable
              data={this.state.videomatikTable}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.state.loading}
              emptyDataSourceMessage={'Agora é só clicar em Gerar novo!'}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[
                {
                  title: 'Produto',
                  field: 'nome_produto',
                },
                {
                  title: 'Data',
                  field: 'data',
                  render: rowData =>
                    moment(rowData.data).format('DD/MM/YYYY hh:mm:ss'),
                },
                {
                  title: '',
                  field: '',
                  render: rowData => (
                    <div style={{ textAlign: 'center' }}>
                      {rowData.link ? (
                        <a href={rowData.link}>Link do vídeo</a>
                      ) : (
                        <Tooltip title="Processando" placement="top">
                          <CircularProgress size={22} thickness={4} />
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        {this.state.generateVideomatik && (
          <GenerateVideomatik
            open={this.state.generateVideomatik}
            tabloid={{
              id_tabloide_digital: this.state.id,
              qtde_produtos_lista: this.state.qtde,
            }}
            templates={this.state.templates}
            onClose={() => {
              this.setState({ generateVideomatik: false });
              this.onRefresh(true);
            }}
            onGenerate={(id_tabloide, id_template) =>
              this.props
                .postVideomatik({
                  id_tabloide,
                  id_template,
                })
                .then(() => {
                  this.onRefresh(true);
                })
            }
            tipoPixelixe={'videomatik'}
          ></GenerateVideomatik>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  ul: {
    width: 'fit-content',
    margin: 'auto',
  },

  rootCard: {
    width: 200,
  },
  media: {
    height: 0,
    paddingTop: '300px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});

const mapDispatchToProps = {
  getVideomatik,
  postVideomatik,
};

const VideomatikConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Videomatik);

export default withStyles(styles)(VideomatikConnect);
