/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  withStyles,
  Stepper,
  Step,
  StepButton,
  Typography,
  Divider,
  Icon,
  Button,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  DatePicker,
  Select,
  LoaderHTML,
  TextField,
  Modal,
  Chip,
  MyButton,
  Checkbox,
} from '../../../utils/components';
import {
  contadorEmail,
  addNumeroCampanha,
  removeNumeroCampanha,
} from '../../Insights/actions/actions';
import { getTemplateTipo } from '../../Template/actions/actions';
import { EmailValidator } from '../../../utils/helpers/Masks';
import { Countup } from '../../../utils/components';
import {
  PARETO,
  EMAILS,
  HOST_TRACKER,
  UNIDADE_TEMPO,
  DIAS,
  DIAS_MES,
} from '../../../utils/helpers/Constants';
import '../style/modal.css';

const steps = ['Campanha', 'Disparo', 'Email', 'Automação', 'Disparar'];

function textAutomation(state) {
  const unit = state.unitAutomacao ? `${state.unitAutomacao}` : '',
    time = state.unitTimeAutomacao
      ? state.unitTimeAutomacao.label.toLowerCase()
      : '',
    dia = state.diaDisparoAutomacao
      ? state.diaDisparoAutomacao.label.toLowerCase()
      : '',
    hora = state.horaDisparoAutomacao
      ? moment(state.horaDisparoAutomacao, 'HH:mm:ss').format('HH:mm:00')
      : moment().format('HH:mm:00'),
    text = `A cada ${unit} ${time}${
      dia ? `, todos os dias ${dia}` : ''
    }, às ${hora}`;
  return text;
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class NovaCampanhaEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      html: '',
      show: false,
      showAlert: false,
      showConfigTracker: false,
    };
  }
  componentDidMount() {
    const { email } = this.props;

    this.props.getTemplateTipo('email');

    if (email && email.optionList && email.optionList.value) {
      this.props.contadorEmail({
        emails: email.selectedEmail.map(opt => opt.value),
        value: email.optionList.value,
        pra_quem: email.pra_quem,
        origem_campanha: email.origem_campanha,
      });
    }
  }

  addEmail() {
    const { email } = this.props;

    if (EmailValidator(email.email)) {
      email.emailList.push({
        value: email.email,
        label: email.email,
      });
      this.props.addNumeroCampanha('contador_email');
      email.email = '';
      this.setState({ show: true, showAlert: false });
    } else {
      this.setState({ showAlert: true });
    }
  }

  removerEmail(index) {
    const { email } = this.props;
    email.emailList.splice(index, 1);
    this.props.removeNumeroCampanha('contador_email');
    this.setState({ show: true });
  }

  onChangeAdd(value) {
    this.props.onChange('email', value);
    if (/,|;/g.test(value)) {
      this.addEmail();
      this.props.onChange('email', '');
    }
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      case 4:
        return this.step5(props, state);
      default:
        return this.step1(props, state);
    }
  }

  step1(props) {
    return (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Informações da Campanha
        </Typography>
        <TextField
          label="Nome"
          className="form-control"
          onChange={({ target }) => {
            props.onChange('nomeCampanha', target.value);
          }}
          value={props.email.nomeCampanha}
          autoComplete="none"
        />
        <Select
          label={'Audiência'}
          disabled
          options={props.listsProspectei.map(list => ({
            label: list.nameList,
            value: list.idListProspectei || list.idPromocao,
          }))}
          placeholder="Selecione uma lista..."
          onChange={option => {
            props.contadorEmail({
              emails: props.email.selectedEmail.map(opt => opt.value),
              ...option,
            });
          }}
          value={props.email.optionList}
        />
        <Select
          label="Tabloide Digital"
          placeholder="Selecione um tabloide..."
          options={props.tabloides}
          labelKey="nome_tabloide_digital"
          valueKey="id_tabloide_digital"
          onChange={selectedTabloide =>
            props.onChange('selectedTabloide', selectedTabloide)
          }
        />

        <section>
          <DatePicker
            dateAndTime
            label="Início da campanha"
            onChange={date => props.onChange('dataInicioCampanha', date)}
            value={props.email.dataInicioCampanha}
          />
        </section>
        <section>
          <DatePicker
            dateAndTime
            label="Término da campanha"
            onChange={date => props.onChange('dataFimCampanha', date)}
            value={props.email.dataFimCampanha}
          />
        </section>
        <Select
          label="Grupo do controle"
          options={PARETO}
          valueKey="id"
          placeholder="Grupo do controle..."
          onChange={option => props.onChange('pareto', option)}
          value={props.email.pareto}
        />
      </section>
    );
  }

  step2(props, state) {
    return (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Informações de Disparo
        </Typography>
        <DatePicker
          dateAndTime
          label="Envio do email"
          onChange={date => props.onChange('dataInicio', date)}
          value={props.email.dataInicio}
        />

        <TextField
          label="Nome remetente"
          className="form-control"
          onChange={({ target }) => {
            props.onChange('nomeRemetente', target.value);
          }}
          value={props.email.nomeRemetente}
          autoComplete="none"
        />
        <TextField
          label="Email remetente"
          className="form-control"
          onChange={({ target }) => {
            props.onChange('remetente', target.value);
          }}
          value={props.email.remetente}
          autoComplete="none"
        />
        <section>
          <Chip
            clickable
            label="Nome completo"
            onClick={() =>
              props.onChange(
                'subject',
                `${props.email.subject} {{nomeCompleto}}`
              )
            }
          />
          <Chip
            label="Primeiro nome"
            clickable
            onClick={() =>
              props.onChange(
                'subject',
                `${props.email.subject} {{primeiroNome}}`
              )
            }
          />
          <Chip
            label="CPF"
            clickable
            onClick={() =>
              props.onChange('subject', `${props.email.subject} {{documento}}`)
            }
          />
        </section>
        <TextField
          label="Assunto"
          className="form-control"
          onChange={({ target }) => {
            props.onChange('subject', target.value);
          }}
          value={props.email.subject}
          autoComplete="none"
        />
        <Select
          label="Enviar somente para:"
          onChange={option => {
            props.onChange('selectedEmail', option);

            this.props.contadorEmail({
              emails: option.map(opt => opt.value),
              pra_quem: props.email.pra_quem,
              origem_campanha: props.email.origem_campanha,
              value: props.email.optionList
                ? props.email.optionList.value
                : props.email.idListProspectei,
            });
          }}
          value={props.email.selectedEmail}
          options={EMAILS}
          multiple
        />
        <section style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            label="E-mails copiados na campanha"
            className="form-control"
            onChange={({ target }) => this.onChangeAdd(target.value)}
            onKeyUp={e => e.keyCode === 13 && this.addEmail()}
            value={props.email.email}
            autoComplete="none"
          />
          {props.email.email && (
            <MyButton
              style={{ marginLeft: 5 }}
              bsstyle="success"
              onClick={() => this.addEmail()}
            >
              Adicionar
            </MyButton>
          )}
        </section>
        {state.show &&
          props.email.emailList.map((value, index) => (
            <section
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '1vh',
              }}
            >
              <strong>{value.value}</strong>

              <MyButton
                bsSize="xs"
                bsstyle="danger"
                onClick={() => this.removerEmail(index)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </MyButton>
            </section>
          ))}

        <TextField
          label="Link (URL)"
          className="form-control"
          onChange={({ target }) =>
            props.onChange('redirectTracker', target.value)
          }
          value={props.email.redirectTracker}
        />

        <Select
          options={HOST_TRACKER}
          valueKey="host"
          placeholder="Domínio..."
          onChange={option => props.onChange('hostTracker', option)}
          value={props.email.hostTracker}
        />
      </section>
    );
  }

  step3(props, state) {
    return (
      <section style={{ padding: '2rem 15rem', height: '40rem' }}>
        <Select
          label="Templates"
          onChange={option => {
            this.setState({ html: option });
            props.onChange('template', option && option.link_template);
          }}
          value={state.html}
          placeholder="Selecione um template..."
          options={props.listaTemplateTipo.map(template => ({
            ...template,
            label: template.nome_template,
            value: template.id_template,
          }))}
        />
        <section
          style={{
            marginTop: '2rem',
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <LoaderHTML
            textHTML={html =>
              this.setState({
                showConfigTracker: html.match(/{{clickTracker}}/)
                  ? true
                  : false,
              })
            }
            urlHTML={state.html}
            labelLink="link_template"
          />
        </section>
      </section>
    );
  }

  step4(props, state) {
    return (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Configurações automação
        </Typography>
        <Checkbox
          checked={Boolean(props.email.automacao_campanha_email)}
          onChange={({ target: { checked } }) =>
            props.onChange('automacao_campanha_email', Boolean(checked) ? 1 : 0)
          }
          label="Fazer disparos automáticos da campanha ?"
        />
        {Boolean(props.email.automacao_campanha_email) && (
          <Fragment>
            <TextField
              type="number"
              label="A cada"
              className="form-control"
              onChange={({ target: { value } }) =>
                props.onChange('unitAutomacao', value)
              }
              value={props.email.unitAutomacao}
            />
            <Select
              label="Unidade de tempo"
              options={UNIDADE_TEMPO}
              onChange={tipo => props.onChange('unitTimeAutomacao', tipo)}
              value={props.email.unitTimeAutomacao}
            />
            {props.email.unitTimeAutomacao &&
              props.email.unitTimeAutomacao.value !== 'days' &&
              props.email.unitTimeAutomacao.value !== 'hours' && (
                <Select
                  label="Todas às"
                  options={
                    props.email.unitTimeAutomacao.value !== 'months'
                      ? DIAS
                      : DIAS_MES
                  }
                  onChange={dia => props.onChange('diaDisparoAutomacao', dia)}
                  value={props.email.diaDisparoAutomacao}
                />
              )}
            {props.email.unitTimeAutomacao &&
              props.email.unitTimeAutomacao.value !== 'hours' && (
                <DatePicker
                  time
                  label="às"
                  format="HH:mm"
                  onChange={date =>
                    props.onChange('horaDisparoAutomacao', date)
                  }
                  value={props.email.horaDisparoAutomacao}
                />
              )}
          </Fragment>
        )}
      </section>
    );
  }

  step5(props, state) {
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Sobre a Campanha
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome</strong>
              </p>
              <span>{props.email.nomeCampanha}</span>
            </section>
            {Boolean(props.email.optionList) && (
              <section style={{ paddingBottom: '2rem' }}>
                <p>
                  <strong>Audiência</strong>
                </p>

                <span>{props.email.optionList.label}</span>
              </section>
            )}

            {Boolean(props.email.automacao_campanha_email) && (
              <section style={{ paddingBottom: '2rem' }}>
                <p>
                  <strong>Automação</strong>
                </p>

                <span>{textAutomation(props.email)}</span>
              </section>
            )}

            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data Disparo</strong>
              </p>
              <span>
                {moment(props.email.dataInicio).format('DD/MM/YYYY HH:mm')}
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Valor do Investimento</strong>
              </p>
              <span>
                <Countup
                  prefix="R$"
                  decimals={3}
                  isLoading={props.isLoadingCountEmail}
                  end={parseFloat(props.contador_campanha.valor_total || 0)}
                />
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Total de Envios</strong>
              </p>
              <span>
                <Countup
                  isLoading={this.props.isLoadingCountEmail}
                  end={this.props.contador_campanha.contador_email || 0}
                />
              </span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Mensagem
          </Typography>
          <LoaderHTML
            textHTML={html =>
              this.setState({
                showConfigTracker: html.match(/{{clickTracker}}/)
                  ? true
                  : false,
              })
            }
            urlHTML={state.html}
            labelLink="link_template"
          />
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              disabled={_disableButtonSave(props.email, state.showAlert)}
              onClick={props.onSave}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  tituloModal(titulo) {
    switch (titulo) {
      case 1:
        return 'Refazer campanha para pessoas que compraram';
      case 2:
        return 'Refazer campanha para pessoas que não compraram';
      case 3:
        return 'Refazer campanha para todas as pessoas';
      default:
        return 'Nova Campanha';
    }
  }

  header(props, state) {
    return (
      <Fragment>
        <strong>{this.tituloModal(props.email.pra_quem)}</strong>
        <section className="modal-header">
          <span className="modal-subtitle">
            <strong>Tipo de Campanha: </strong>Email
          </span>
          <span className="modal-subtitle">
            <strong>E-mails na lista: </strong>
            <Countup
              isLoading={this.props.isLoadingCountEmail}
              end={this.props.contador_campanha.contador_email || 0}
            />
          </span>
          <span className="modal-subtitle">
            <strong>Investimento: </strong>
            <Countup
              prefix="R$"
              decimals={3}
              isLoading={props.isLoadingCountEmail}
              end={parseFloat(props.contador_campanha.valor_total || 0)}
            />
          </span>
        </section>
        <Divider style={{ marginTop: '2rem' }} />
        <div style={{ width: '100%' }}>
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled={index === 3}
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            color="primary"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1,
              })
            }
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 4 && (
          <Button
            color="primary"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1,
              })
            }
          >
            Avançar
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { showModal, onClose } = this.props;
    const { activeStep } = this.state;

    return (
      <Modal
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
        size="lg"
        showModal={showModal}
        onClose={onClose}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const _disableButtonSave = (email, alert) => {
  if (
    email.remetente &&
    email.subject &&
    email.template &&
    (email.optionList || email.idListProspectei) &&
    !alert
  ) {
    return false;
  } else {
    return true;
  }
};

const mapStateToProps = ({
  CampaignReducer,
  InsightsReducer,
  TemplateReducer,
  TabloideDigitalReducer,
}) => ({
  ...CampaignReducer,
  ...InsightsReducer,
  ...TemplateReducer,
  ...TabloideDigitalReducer,
});

const mapDispatchToProps = {
  contadorEmail,
  getTemplateTipo,
  addNumeroCampanha,
  removeNumeroCampanha,
};

const NovaCampanhaEmailConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaCampanhaEmail);

export default withStyles(styles)(NovaCampanhaEmailConnect);
