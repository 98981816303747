import { useMediaQuery, useTheme } from '@mui/material';
import { ScheduledPosts } from 'modules/TabloideDigitalV2/components/ScheduledPosts';
import { DistributionChannels } from 'modules/TabloideDigitalV2/components/ScheduledPosts/DistributionChannels';
import { Fragment, useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';

import { findToken } from '../../firebase';
import SignInAsUser from '../../modules/SignInAsUser';
import { setTokenCloudMessaging } from '../../services/company';
import RoutePreload from './components/RoutePreload';
import ConstRotas from './ConstRotas';

function Rotas() {
  const tokenJWT = JSON.parse(localStorage.getItem('token'));
  const name = localStorage.getItem('name').replaceAll('"', '');
  const email = localStorage.getItem('email').replaceAll('"', '');
  const userId = localStorage.getItem('userId');
  const company = JSON.parse(localStorage.getItem('company'));

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSetToken = useMutation(setTokenCloudMessaging, { retry: 2 });

  const handleTokenCloudMessaging = useCallback(async () => {
    const token = await findToken();

    if (token) {
      await handleSetToken.mutateAsync(token);
    }
  }, [handleSetToken]);

  useEffect(() => {
    const device = isTablet ? 'mobile' : 'desktop';
    handleTokenCloudMessaging();
    window.Amplifiqueme = {};
    window.Amplifiqueme.onLoad = () => {
      window.Amplifiqueme.identify(
        {
          email, //Tipo: Email. Campo obrigatório. É o identificador chave do usuário dentro da plataforma
          created_at: new Date().getTime(), //Tipo: Timestamp. Opcional. (caso não utilize, remova a declaração ou defina como undefined). Campo de definição da Data de Criação do Usuário em sua Base.
          name, //Opcional. Nome do Usuário.
          company: company.name, //Opcional. Para definir o nome da Empresa do usuário.
          custom_fields: { device }
          // phone: '+5542999999999'//Tipo: Celular(Formatio E.164). Opcional. Para definir o celular do usuário.
        },
        true
      );
    };
    if (window.clarity) {
      window.clarity('set', 'userName', name);
      window.clarity('set', 'userEmail', email);
      window.clarity('set', 'companyId', company.id);
      window.clarity('set', 'companyName', company.name);
      window.clarity('set', 'cnpj', company.cnpj);
      window.clarity('identify', email, company.id, userId, company.name);
    }

    if (window.Median) {
      window.Median.identify(email);
    }

    if (company.platform_version) {
      window.location.replace(`https://app.dsmarketing.com.br/sign-as-user/${tokenJWT}?origin=old`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Route path="/" exact component={() => <Redirect to="/main" />} />
      <Route path="/projects" exact component={() => <Redirect to="/home?step=2" />} />
      <Route path="/newStudio" exact component={() => <Redirect to="/home?step=0" />} />
      <Route path="/professional" exact component={() => <Redirect to="/home?step=1" />} />
      <Route path="/login" exact component={() => <Redirect to="/" />} />
      <Route path="/sign-as-user/:token" component={SignInAsUser} />
      <Route path="/posts" component={ScheduledPosts} />
      <Route path="/distribution-channels" component={DistributionChannels} />
      {ConstRotas.map((rota, i) => (
        <Fragment key={i}>
          <RoutePreload path={rota.path} exact component={rota.component} />
          {rota.subRoutes &&
            rota.subRoutes.map((subRoute, j) => (
              <RoutePreload key={j} path={subRoute.path} component={subRoute.component} />
            ))}
        </Fragment>
      ))}
    </Router>
  );
}

const mapStateToProps = ({ RoutesReducer }) => ({ ...RoutesReducer });
export default connect(mapStateToProps, null)(Rotas);

