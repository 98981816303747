import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBirthReport } from '../actions/actionsRelatorios';
import {
  PagerMonths,
  ShowMore,
  ListReport,
  Panel,
} from '../../../utils/components';
class RelatorioPeloDia extends Component {
  constructor() {
    super();
    this.state = {
      key: false,
      month: new Date().getMonth(),
    };
  }
  componentDidMount() {
    this.props.getBirthReport(new Date().getMonth() + 1, 'month');
  }

  getDate = month =>
    this.props.getBirthReport(month + 1, 'month', this.props.selectedStores);

  render() {
    return (
      <Panel noHeader>
        <PagerMonths onSelect={this.getDate} />
        <ShowMore
          loading={this.props.isLoading}
          dataFormat={(row, data) => <ListReport row={row} data={data} />}
          data={this.props.listReportBirthDate}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ ReportsReducer }) => ({ ...ReportsReducer });

const mapDispatchToProps = {
  getBirthReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatorioPeloDia);
