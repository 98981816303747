import { Alert, Autocomplete } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ModalNewWhatsappInstance from 'modules/TabloideDigitalV2/components/AdsDistribution/components/ModalNewWhatsappInstance';
import FormObserver from 'modules/TabloideDigitalV2/components/FormObserver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { API_SMS_V2, API_WHATSAPP_V2 } from 'utils/helpers/Constants';
import { Instance } from '../..';
import Fetch from '../../../../../../utils/helpers/Fetch';
import SecurityIcon from '../../../../../../assets/icons/marketing/Security.svg';
import api from '../../../../../../utils/helpers/Fetch';
import * as actionsChatbot from '../../../../../Chatbot/actions';
import * as actionsAdsDistribution from '../../../../action';
import { useStyles } from '../../styles';
import { StateProps } from '../../../../../../store/types';
import { AutoFixHighOutlined } from '@mui/icons-material';

type ContactType = {
  cod: string;
  label: string;
};

type LojasType = {
  id_loja: number;
};

type FormReduxProps = {
  instancias: Instance[];
  lojas: LojasType[];
  getInstancias: () => any;
  getLojas: () => any;
  findAutoMessage: () => any;
};

type FormProps = {
  backgroundId: number;
  formik: any;
  datas: {
    contactTypes: Array<ContactType>;
    countItens: number;
  };
  handleChangeOnClickAutoMessage?: () => void;
};

type contactsInstance = {
  idInstance: number | null;
  contacts: Array<Object>;
  contactsAll: number;
  contactsActive: number;
  contactsDesactive: number;
};

function Form({ backgroundId, formik, datas,handleChangeOnClickAutoMessage, ...props }: FormProps) {
  const classes = useStyles();

  const {
    adsDistribution: { selectedChannelsFormData },
    loading_auto_message
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);

  const [isInstanceDialogOpen, setInstanceDialogOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [normalizedInstances, setNormalizedInstances] = useState<Array<Instance>>([]);
  const [usedCredits, setUsedCreditis] = useState<number>(0);
  const [contactsInstance, setcontactsInstance] = useState<contactsInstance>({
    idInstance: null,
    contacts: [{}],
    contactsAll: 0,
    contactsActive: 0,
    contactsDesactive: 0
  });

  const { instancias, lojas, getInstancias, getLojas } = props as FormReduxProps;

  async function loadContactsInstance(idInstance: Number) {
    return Fetch(`${API_WHATSAPP_V2}contacts/list`, {
      id: idInstance
    });
  }

  useEffect(() => {
    let idInstance = formik.values.selectedInstance?.id;
    if (idInstance) {
      loadContactsInstance(idInstance).then(payload => {
        let contactsActive: Array<Object> = payload?.content.filter(
          (value: { ativo_envio: number }) => {
            if (value.ativo_envio === 1) {
              return true;
            }
          }
        );
        let contactsDesactive: Array<Object> = payload?.content.filter(
          (value: { ativo_envio: number }) => {
            if (value.ativo_envio === 0) {
              return true;
            }
          }
        );
        setcontactsInstance({
          idInstance: idInstance,
          contacts: payload?.content,
          contactsAll: payload.content.length,
          contactsActive: contactsActive.length,
          contactsDesactive: contactsDesactive.length
        });
      });
    } else {
      setcontactsInstance({
        idInstance: null,
        contacts: [{}],
        contactsAll: 0,
        contactsActive: 0,
        contactsDesactive: 0
      });
    }
  }, [formik.values.selectedInstance]);

  useEffect(() => {
    let credits = 0;
    if (contactsInstance.idInstance) {
      let numbersCopyString = String(formik.values.numbersInCopy).split(',');
      let countNumbersCopy = 0;
      let countImg = datas.countItens;
      if (numbersCopyString[0] !== '') {
        countNumbersCopy = numbersCopyString.length;
      }

      if (formik.values.selectedContactType.cod === 'all') {
        credits = countImg * (contactsInstance.contactsAll + countNumbersCopy);
      } else if (formik.values.selectedContactType.cod === 'ca') {
        credits = countImg * (contactsInstance.contactsActive + countNumbersCopy);
      } else if (formik.values.selectedContactType.cod === 'ci') {
        credits = countImg * (contactsInstance.contactsDesactive + countNumbersCopy);
      } else if (formik.values.selectedContactType.cod === 'cop') {
        credits = countImg * countNumbersCopy;
      }
      setUsedCreditis(credits);
    } else {
      setUsedCreditis(credits);
    }
  }, [contactsInstance, formik.values.numbersInCopy, formik.values.selectedContactType]);

  useEffect(() => {
    if (!instancias.length && !lojas.length) {
      getInstancias();
      getLojas();
    }
  }, []);

  useEffect(() => {
    const compare = (field: { post?: string; text?: string }) =>
      'post' in field ? 'post' : 'text' in field ? 'text' : 'post';

    const valueFirst =
      selectedChannelsFormData[0]?.data[compare(selectedChannelsFormData[0]?.data)];
    if (valueFirst) {
      formik.setFieldValue('text', valueFirst);
    }
  }, []);

  /**
   * Normalize a instância para saber qual loja cada instância pertence.
   */
  useEffect(() => {
    if (instancias.length > 0 && lojas.length > 0) {
      const normalized_instances = instancias.map(instancia => {
        return {
          ...instancia,
          loja: lojas.find(loja => loja.id_loja === instancia.id_loja) || {}
        };
      });

      setNormalizedInstances(normalized_instances);
    }
  }, [instancias, lojas]);

  const findLastLimitHistory = async () => {
    try {
      let result = await api(`${API_SMS_V2}getLastLimitHistory`, {}, 'GET', null, null, false);

      // console.log({ result });
      formik.setFieldValue('credits', result.content.lastLimitHistory.balance / 1000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formik.setFieldValue('selectedInstance', normalizedInstances?.[0]);
  }, [normalizedInstances]);

  useEffect(() => {
    findLastLimitHistory();
  }, []);

  return (
    <>
      <form data-testid="dsMarketing-sms-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} spacing={3}>
              <Typography variant="h5" style={{ fontSize: '24px', fontWeight: '500' }}>
                SMS
              </Typography>
              <Typography
                style={{
                  padding: '8px 0 0 0',
                  fontSize: '14px'
                }}
              >
                Mensagens de SMS são enviadas para números que você selecionar. Cada mensagem
                enviada consome 1 crédito
              </Typography>
            </Grid>
            <Grid item className={classes.alertCredits}>
              <img src={SecurityIcon} alt="icon-sms-alert-credits" />
              Cada imagem enviada consome 1 crédito de SMS. <br />
              Exemplo: Se enviar 5 imagens para 10 pessoas, serão gastos 50 créditos.
            </Grid>

            <Grid item className={classes.creditInformational} xs={12}>
              <span className={classes.creditBalance}>
                Seu saldo de créditos: {formik.values.credits}
              </span>
              <span className={classes.creditBalance}>
                Esta Operação vai consumir {usedCredits}{' '}
                {usedCredits > 1 ? 'créditos.' : 'credito.'}
              </span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                padding: '0 !important',
                borderColor: '#CFCFCF'
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.boxLabelWhatsApp}>
              <Typography className={classes.typographySubtitleClickabled}>
                Qual número de WhatsApp
              </Typography>

              <Button
                color="primary"
                className={classes.typographySubtitleClickabledBtn}
                style={{ color: instancias.length !== 0 ? '#dfdfdf' : '#127FFF' }}
                onClick={() => setInstanceDialogOpen(true)}
                disabled={instancias.length !== 0 ? true : false}
              >
                Criar Nova Instância
              </Button>
            </Box>
            <Autocomplete
              data-testid="dsMarketing-distribution-select-instance-whatsapp-in-sms"
              autoHighlight
              fullWidth
              selectOnFocus
              autoComplete
              clearOnBlur
              options={normalizedInstances as []}
              getOptionLabel={option => option?.nome}
              loadingText="Carregando Instâncias..."
              /**
               * Value = selects formik data, if null, fetches existing instances of
               * user (in redux) and places the first vector object.
               * If there is no data in normalizedInstances, return null.
               */
              value={formik.values.selectedInstance}
              onChange={async (event, newValue) => {
                formik.setFieldValue('selectedInstance', newValue);
              }}
              classes={{
                popper: classes.popper,
                inputRoot: classes.typographyAutocomplete
              }}
              getOptionSelected={(option, value) =>
                option && value ? option.nome === value.nome : false
              }
              renderInput={params => (
                <TextField
                  {...params}
                  name="selectedInstance"
                  variant="outlined"
                  placeholder={
                    instancias && instancias.length <= 0
                      ? 'Você ainda não tem instâncias conectadas'
                      : 'Selecione uma instância'
                  }
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      notchedOutline: Boolean(formik.errors.selectedInstance)
                        ? classes.inputBorderError
                        : classes.inputNoBorder,
                      root: classes.inputBackgroundColor
                    }
                  }}
                  error={Boolean(formik.errors.selectedInstance)}
                  helperText={
                    Boolean(formik.errors.selectedInstance) ? (
                      <Typography variant="caption">{formik.errors?.selectedInstance}</Typography>
                    ) : (
                      '*obrigatório'
                    )
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.typographySubtitleClickabled}>
              Quando você deseja enviar a mensagem?
            </Typography>
            <DateTimePicker
              data-testid="dsMarketing-distribution-schedule-date-sms"
              name="scheduleDate"
              ampm={false}
              disablePast
              fullWidth
              inputVariant="outlined"
              format="DD/MM/YYYY HH:mm"
              value={formik.values.scheduleDate || new Date()}
              onChange={date => {
                formik.setFieldValue('scheduleDate', moment(date).toDate());
              }}
              // className={classes.typographySubtitleClickabled}
              error={Boolean(formik.errors.scheduleDate)}
              helperText={formik.errors.scheduleDate}
              InputProps={{
                readOnly: true,
                classes: {
                  notchedOutline: Boolean(formik.errors.scheduleDate)
                    ? classes.inputBorderError
                    : classes.inputNoBorder,
                  root: classes.inputBackgroundColor
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.typographySubtitleClickabled}>
              Para quais contatos deseja enviar?
            </Typography>
            <Autocomplete
              data-testid="dsMarketing-distribution-select-contact-type-sms"
              autoHighlight
              selectOnFocus
              fullWidth
              autoComplete
              clearOnBlur
              value={formik.values.selectedContactType}
              options={datas.contactTypes}
              getOptionLabel={option => option.label}
              getOptionSelected={(option, value) => option.label === value.label}
              onChange={(event, newValue) => {
                formik.setFieldValue('selectedContactType', newValue);
              }}
              classes={{
                popper: classes.popper,
                inputRoot: classes.typographyAutocomplete
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="selectedContactType"
                  value={formik.values.selectedContactType}
                  variant="outlined"
                  placeholder={'Selecione uma das opções'}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      notchedOutline: Boolean(formik.errors.selectedContactType)
                        ? classes.inputBorderError
                        : classes.inputNoBorder,
                      root: classes.inputBackgroundColor
                    }
                  }}
                  error={Boolean(formik.errors.selectedContactType)}
                  helperText={
                    Boolean(formik.errors.selectedContactType) ? (
                      <Typography variant="caption">
                        {formik.errors?.selectedContactType}
                      </Typography>
                    ) : (
                      '*obrigatório'
                    )
                  }
                />
              )}
            />
            <Typography
              style={{
                fontSize: '14px'
              }}
            >
              <strong>Ativos:</strong> Contatos com interação em 60 dais
              <br />
              <strong>Inativos:</strong> Contatos sem interação em 60 dias
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.typographySubtitleClickabled}>
              Adicionar números em cópia
            </Typography>
            <TextField
              data-testid="dsMarketing-distribution-numbers-in-copy-sms"
              name="numbersInCopy"
              //value={formik.values.numbersInCopy}
              type="text"
              variant="outlined"
              fullWidth
              rows={12}
              placeholder="99 99999 9999"
              onBlur={event => {
                formik.setFieldValue('numbersInCopy', event.target.value);
              }}
              error={Boolean(formik.errors.numbersInCopy)}
              helperText={
                Boolean(formik.errors.numbersInCopy)
                  ? formik.errors.numbersInCopy
                  : 'Adicione números manualmente que não estão na sua lista de contatos'
              }
              InputProps={{
                classes: {
                  notchedOutline: Boolean(formik.errors.numbersInCopy)
                    ? classes.inputBorderError
                    : classes.inputNoBorder,
                  root: classes.inputBackgroundColor
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box component="div" display="flex" alignItems="center" justifyContent="space-between">
              <Typography className={classes.typographySubtitleClickabled}>
                Qual texto da mensagem
              </Typography>
              <Button
                onClick={() => {
                  if(handleChangeOnClickAutoMessage){
                    handleChangeOnClickAutoMessage();
                  }
                  props?.findAutoMessage({ background_id: backgroundId, channel: 'sms' });
                }}
                disabled={loading_auto_message}
                color="primary"
                variant="text"
                size="small"
                startIcon={<AutoFixHighOutlined />}
              >
                {loading_auto_message ? 'Digitando ...' : 'Outra sugestão de mensagem'}
              </Button>
            </Box>
            <TextField
              data-testid="dsMarketing-distribution-text-sms"
              name="text"
              fullWidth
              type="text"
              variant="outlined"
              disabled={loading_auto_message}
              multiline
              rows={12}
              value={formik.values.text}
              placeholder="Aproveite essa oferta!"
              onChange={element => {
                formik.setFieldValue('text', element.target.value);
              }}
              error={Boolean(formik.errors.text)}
              helperText={
                Boolean(formik.errors.text)
                  ? formik.errors.text
                  : 'SMS aceitam um máximo de 139 caracteres'
              }
              InputProps={{
                classes: {
                  notchedOutline: Boolean(formik.errors.text)
                    ? classes.inputBorderError
                    : classes.inputNoBorder,
                  root: classes.inputBackgroundColor
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid
          style={{
            margin: '8px 0'
          }}
        >
          <Alert severity="info">
            Não é possível enviar emojis através de SMS. Eles serão apagados no processo de envio.
          </Alert>
        </Grid>

        {Boolean(Object.keys(formik.errors).length) && (
          <Grid
            style={{
              margin: '24px 0'
            }}
          >
            <Alert severity="error">Preencha todos os campos obrigatórios</Alert>
          </Grid>
        )}

        <FormObserver channel="sms" values={formik.values} />
      </form>

      {isInstanceDialogOpen && (
        <ModalNewWhatsappInstance
          isInstanceDialogOpen={isInstanceDialogOpen}
          setInstanceDialogOpen={setInstanceDialogOpen!}
          selectedStore={selectedStore!}
          setSelectedStore={setSelectedStore!}
          stores={lojas}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ ChatbotReducer }: any) => {
  return { ...ChatbotReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot, ...actionsAdsDistribution })(Form);

