const INITIAL_STATE = {
  listMinhaBase: [],
  listPromocoes: [],
  listPromocoesByID: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LISTS_PROSPECTEI_PF":
      return { ...state, listMinhaBase: action.payload };
    case "LIST_PROMOCOES":
      return { ...state, listPromocoes: action.payload };
    case "LIST_PROMOCOES_ID":
      return { ...state, listPromocoesByID: action.payload };
    default:
      return state;
  }
};
