import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Modal,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DATASALES_BACKOFFICE_API } from '../../utils/helpers/Constants';
import api from '../../utils/helpers/Fetch';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    padding: theme.spacing(4.4)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0)
  }
}));

export default function RecoverPassword() {
  const [email, setEmail] = useState('');
  const [origin, setOrigin] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!email) setDisabled(true);
    else {
      setDisabled(false);
    }
  }, [email]);

  useEffect(() => {
    const origin = window.location.origin;
    setOrigin(origin);
  }, [origin]);

  async function handleSubmit() {
    try {
      setLoading(true);
      setDisabled(true);
      await api(
        `${DATASALES_BACKOFFICE_API}p2-prd/session/forgot`,
        {
          email,
          redirect: `${origin}/reset/`
        },
        'POST'
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }

  const classes = useStyles();

  return (
    <Modal open={true}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Recuperar senha
          </Typography>
          <div className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Button
              fullWidth
              disabled={disabled}
              size="large"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              {loading ? <CircularProgress size={26} color="inherit" /> : 'Recuperar'}
            </Button>
            <Button
              fullWidth
              size="large"
              color="primary"
              onClick={() => {
                history.push('/');
              }}
            >
              voltar
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  );
}
