import Fetch from "../../../utils/helpers/Fetch";
import { ordenaArray } from "../../../utils/helpers/Functions";
import {
  API_GET_DDDs,
  API_GET_UFs,
  LIST_UFs,
  LIST_DDDs,
  LIST_MUNICIPIOS,
  LIST_BAIRROS,
  LIST_SEGMENTOS,
  LIST_CNPJ,
  LOADING_VENDA_MAIS,
  COUNT_CNPJ,
  LOADING_COUNT,
  LIST_CABECALHO_PROSPECTS,
  LIST_DETALHE_PROSPECTS,
  DOWNLOAD_CSV,
  GRAFICO_VENDA_MAIS
} from "../ducks/ducks";
import {
  API_URL_ACCOUTING,
  AMBIENTE,
  DATASALES_CRM_API
} from "../../../utils/helpers/Constants";

export const isLoadingVendaMais = () => dispatch =>
  dispatch({ type: LOADING_VENDA_MAIS, payload: true });

export const isLoadingCount = () => dispatch =>
  dispatch({ type: LOADING_COUNT, payload: true });

export const downloadCSV = (isDownload = true) => dispatch =>
  dispatch({ type: DOWNLOAD_CSV, payload: isDownload });

export const getCount = objParameters => dispatch => {
  dispatch(isLoadingCount());
  Fetch(`${API_URL_ACCOUTING}vendaMais/count`, objParameters, "POST").then(
    payload => dispatch({ type: COUNT_CNPJ, payload })
  );
};

export const getCabecalhoProspects = () => dispatch => {
  dispatch(isLoadingVendaMais());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getCabecalhoProspects`).then(payload =>
    dispatch({ type: LIST_CABECALHO_PROSPECTS, payload })
  );
};

export const createListWithProspects = prospects => dispatch =>
  Fetch(`${API_URL_ACCOUTING}vendaMais/createProspects`, prospects, "POST");

export const exportListProspectsToCSV = idProspects => dispatch => {
  dispatch(downloadCSV());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getCSVListProspects`, {
    idProspects
  }).then(resp => {
    window.location.assign(resp.urlS3);
    dispatch(downloadCSV(false));
  });
};

export const getDetailVendaMais = idProspects => dispatch => {
  dispatch(isLoadingVendaMais());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getListProspectsByID`, {
    idProspects
  }).then(payload => dispatch({ type: LIST_DETALHE_PROSPECTS, payload }));
};

export const getGraphVendaMais = idProspects => dispatch => {
  dispatch(isLoadingVendaMais());
  Fetch(`${API_URL_ACCOUTING}vendaMais/getDashboardListProspects`, {
    idProspects
  }).then(payload => dispatch({ type: GRAFICO_VENDA_MAIS, payload }));
};

export const getSegmentos = () => dispatch =>
  Fetch(`${API_URL_ACCOUTING}vendaMais/getSegmentos`).then(resp => {
    const payload = resp.map((res, index) => ({
      value: res.CNAEGRUPO,
      label: `${index <= 9 ? `0${index}` : index} - ${res.CNAEGRUPO}`
    }));
    dispatch({ type: LIST_SEGMENTOS, payload });
  });

export const getCompanys = objParameters => dispatch => {
  dispatch(isLoadingVendaMais());
  dispatch(getCount(objParameters));
  Fetch(`${API_URL_ACCOUTING}vendaMais/getPJs`, objParameters, "POST").then(
    payload => dispatch({ type: LIST_CNPJ, payload })
  );
};

export const getUFs = () => dispatch =>
  fetch(API_GET_UFs)
    .then(resp => resp.json())
    .then(payload =>
      dispatch({ type: LIST_UFs, payload: ordenaArray(payload, "sigla") })
    );

export const getDDDs = () => dispatch =>
  fetch(API_GET_DDDs)
    .then(resp => resp.json())
    .then(payload => dispatch({ type: LIST_DDDs, payload: payload.payload }));

export const getMunicipios = municipios => dispatch => {
  if (municipios) {
    const muni = municipios.map(municipio => municipio.id);
    const reqMuni = muni.toString().replace(/,/g, "|");
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${reqMuni}/municipios`
    )
      .then(resp => resp.json())
      .then(payload => {
        const municipio = payload.map(municipio => ({
          value: municipio.id,
          label: `${municipio.microrregiao.mesorregiao.UF.sigla} - ${municipio.nome}`,
          cidade: municipio.nome
        }));

        dispatch({
          type: LIST_MUNICIPIOS,
          payload: ordenaArray(municipio, "label")
        });
      });
  }
};

export const getBairros = objParameters => async dispatch => {
  try {
    const cidade = objParameters.map(params => params.cidade);
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/getBairrosByMunicipio`,
      { cidade }
    );
    const payload = response.map((bairro, value) => ({
      value,
      label: `${bairro.bairro} - ${bairro.cidade}`,
      bairro: bairro.bairro
    }));
    dispatch({ type: LIST_BAIRROS, payload });
  } catch (err) {
    console.log(err);
  }
};
