import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider as Material5ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastProvider } from 'contexts/Toast';
import { loadCSS } from 'fg-loadcss';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { queryClient } from 'services/queryClient';

import Error from './Error';
import { AdsProvider } from './hooks/ads';
import { HomeProvider } from './hooks/home';
import { TabloidsProvider } from './hooks/tabloids';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import GlobalStyle from './style/index';
import theme from './theme/index';
import themeMaterial5 from './theme/theme';

// theme css file

const ambient = localStorage.getItem('ambient');
if (!ambient) {
  localStorage.setItem('ambient', 'crm');
}

loadCSS(
  'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
  document.querySelector('#font-awesome-css')
);

const generateClassName = createGenerateClassName({
  productionPrefix: 'abc-jss-'
});

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <>
      <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => closeSnackbar(id)}>
        Close
      </CloseIcon>
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <Material5ThemeProvider theme={themeMaterial5}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <AdsProvider>
                  <TabloidsProvider>
                    <HomeProvider>
                      <SnackbarProvider
                        anchorOrigin={{
                          horizontal: 'center',
                          vertical: 'bottom'
                        }}
                        action={key => <DismissAction id={key} />}
                      >
                        <ConfirmProvider
                          defaultOptions={{
                            cancellationText: 'Cancelar'
                          }}
                        >
                          <ToastProvider>
                            <ToastContainer autoClose={9000} />
                            <GlobalStyle />
                            <Error />
                          </ToastProvider>
                        </ConfirmProvider>
                      </SnackbarProvider>
                    </HomeProvider>
                  </TabloidsProvider>
                </AdsProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </StylesProvider>
        </Material5ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root')
);

// initFacebookSdk().then(startApp);

registerServiceWorker();

