import { AnyAction, Dispatch } from 'redux';

import { AMBIENTE, API_DATA, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import Fetch from '../../utils/helpers/Fetch';
import { CategoryType, SegmentType } from './reducer';
import { ActionTypes } from './types';

export const setIsLoadingHome = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING, payload: data });

export const setIsLoadingSegments = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING_SEGMENTS, payload: data });

export const setIsLoadingPutSegmentCompany = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING_PUT_SEGMENT_COMPANY, payload: data });

export const setIsLoadingCompany = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING_COMPANY, payload: data });

export const setIsLoadingCategories = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING_CATEGORIES, payload: data });

export const setIsLoadingSubcategories = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_IS_LOADING_SUBCATEGORIES, payload: data });

export const setSelectedSegment = (data: SegmentType) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_SELECTED_SEGMENT, payload: data });

export const setSelectedCategory = (data: CategoryType) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_SELECTED_CATEGORY, payload: data });

export const setSelectedBackgrounds = (data: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_SELECTED_BACKGROUNDS, payload: data });

export const setPaginationProps = (offset: number) => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.SET_PAGINATION_PROPS, payload: offset });

export const putSegmentCompany = (id_segment: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_LOADING_PUT_SEGMENT_COMPANY,
    payload: true
  });

  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/company/update`,
      { ID_SEGMENT: id_segment },
      'PUT',
      null,
      null,
      false
    );

    dispatch({
      type: ActionTypes.PUT_SEGMENT_COMPANY,
      payload: payload.content
    });

    // localStorage.setItem('segmentID', id_segment);

    return payload;
  } catch (err) {
    console.log(err);
    throw new Error('ERROR');
  } finally {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_PUT_SEGMENT_COMPANY,
      payload: false
    });
  }
};

export const getSegments = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_LOADING_SEGMENTS,
    payload: true
  });

  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/segments/list`,
      null,
      'GET',
      null,
      null,
      false
    );

    dispatch({
      type: ActionTypes.GET_SEGMENTS,
      payload: payload.content
    });

    return payload;
  } catch (err) {
    console.log(err);
    throw new Error('ERROR');
  } finally {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_SEGMENTS,
      payload: false
    });
  }
};

export const getCompany = (user_id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_LOADING_COMPANY,
    payload: true
  });

  try {
    const { company } = await Fetch(`${API_DATA}users/${user_id}`, 'GET');

    dispatch({
      type: ActionTypes.GET_COMPANY,
      payload: company
    });

    return company;
  } catch (err) {
    console.log(err);
    throw new Error('ERROR');
  } finally {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_COMPANY,
      payload: false
    });
  }
};

export const getCategories = (categoryId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_LOADING_CATEGORIES,
    payload: true
  });

  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/categories/listBySegment/${categoryId}`,
      null,
      'GET',
      null,
      null,
      false
    );

    dispatch({
      type: ActionTypes.GET_CATEGORIES,
      payload: payload.content
    });

    return payload;
  } catch (err) {
    console.log(err);
    throw new Error('ERROR');
  } finally {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_CATEGORIES,
      payload: false
    });
  }
};

export const getSubcategories =
  (idCategory: number, limit?: number, offset?: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_SUBCATEGORIES,
      payload: true
    });

    try {
      const query = limit && offset ? `?limit=${limit}&offset=${offset}` : undefined;

      const payload = await Fetch(
        `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/templates/listByCategory/${idCategory}` +
          query,
        null,
        'GET',
        null,
        null,
        false
      );

      dispatch({
        type: ActionTypes.GET_SUBCATEGORIES,
        payload: payload.content
      });

      return payload;
    } catch (err) {
      console.log(err);
      throw new Error('ERROR');
    } finally {
      dispatch({
        type: ActionTypes.SET_IS_LOADING_SUBCATEGORIES,
        payload: false
      });
    }
  };

export const getSearchSuggestions =
  (CategoryId: number, searchText: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_IS_SEARCHING,
      payload: true
    });

    try {
      const payload = await Fetch(
        `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/subcategories/listByDescription/${CategoryId}?description=${searchText}`,
        null,
        'GET',
        null,
        null,
        false
      );

      dispatch({
        type: ActionTypes.GET_SEARCH_SUGGESTIONS,
        payload: payload.content
      });

      return payload;
    } catch (err) {
      console.log(err);
      throw new Error('ERROR');
    } finally {
      dispatch({
        type: ActionTypes.SET_IS_SEARCHING,
        payload: false
      });
    }
  };

export const getSearchSuggestionBackgrounds =
  (CategoryId: number, SubcategoryId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_IS_SEARCHING,
      payload: true
    });

    try {
      const payload = await Fetch(
        `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/templates/listByCategory/${CategoryId}?id_subcategory=${SubcategoryId}&searchInAllCategories=true`,
        null,
        'GET',
        null,
        null,
        false
      );

      dispatch({
        type: ActionTypes.GET_SEARCH_RESULTS,
        payload: payload.content
      });

      return payload;
    } catch (err) {
      console.log(err);
      throw new Error('ERROR');
    } finally {
      dispatch({
        type: ActionTypes.SET_IS_SEARCHING,
        payload: false
      });
    }
  };

export const clearSubcategories = () => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.CLEAR_SUBCATEGORIES });

export const clearSearchResults = () => (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.CLEAR_SEARCH_RESULTS });

