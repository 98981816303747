import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  ListSubheader,
  Menu,
  Typography
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { setSelectedModule } from '../../../../../../modules/AppConfig/actions/actions';
import {
  setCurrentModule,
  setCurrentServices,
  setCurrentSetting
} from '../../../../../../modules/Login/actions/actionLogin';
import { useSelectMenu } from '../../../../../../utils/helpers/Redirect';
import apps from './apps';
import useStyles from './styles';

function AppsMenu({
  onClose,
  anchor,
  setCurrentModule: _setCurrentModule,
  setCurrentSetting: _setCurrentSetting,
  setCurrentServices: _setCurrentServices,
  setSelectedModule: _setSelectedModule
}) {
  const classes = useStyles();

  const { selectService, selectSettings } = useSelectMenu();

  return (
    <Menu
      id="apps-menu"
      className={classes.menu}
      classes={{ paper: classes.paper }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Grid container spacing={1}>
        {apps.map((app, index) => (
          <>
            <Grid style={{ padding: 0 }} key={index} item xs={12}>
              {/* <ListSubheader disableGutters classes={{ root: classes.subheader }}>
                <img
                  src={app.breakline}
                  style={{ width: app.width }}
                  alt={`${app.lineName} breakline logo`}
                />
              </ListSubheader>
              <Divider /> */}
            </Grid>
            {app.modules.map((mod, id) => (
              <Grid key={id} style={{ display: 'flex' }} item xs={4} justify="center">
                <Button
                  className={classes.btnBase}
                  onClick={() =>
                    mod.module === 'config'
                      ? selectSettings(mod.services, mod.path, mod.title, app.lineName)
                      : selectService(mod.module, mod.services, mod.path, mod.title, app.lineName)
                  }
                >
                  <Box className={classes.btnBox}>
                    <Avatar alt="campaing" className={classes.avatar}>
                      {mod.icon}
                    </Avatar>
                    <Typography noWrap className={classes.typography}>
                      {mod.title}
                    </Typography>
                  </Box>
                </Button>
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    </Menu>
  );
}

const mapDispatchToProps = {
  setCurrentModule,
  setCurrentSetting,
  setCurrentServices,
  setSelectedModule
};

export default connect(null, mapDispatchToProps)(AppsMenu);
