import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(3),
    },
    root: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
    },
}));

export default useStyles;