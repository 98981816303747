/* eslint-disable */
//import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//import MenuIcon from '@mui/icons-material/MenuRounded';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
//import IconButton from '@mui/material/IconButton';
//import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
//import useTheme from '@mui/styles/useTheme';
import useWindowDimensions from 'hooks/app/ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SelectedModule, StateProps } from 'store/types';
import { API_URL_DEV_BACKOFFICE } from 'utils/helpers/Constants';
//import { isLocalhostWithoutRelese, isReleaseEnvironment } from 'utils/helpers/Constants';
import Fetch from 'utils/helpers/Fetch';
import { NewIconButtonMenu } from 'utils/routes/components/Appbar/components/NewIconButtonMenu';
import IconLogoDsChat from '../../../../assets/header/dsOmni.png';
import IconDeliveryVip from '../../../../assets/header/venderMais.jpg';
//import Iconfidelizar from '../../../../assets/header/fidelizar.png';
import NotificationIcon from '../../../../assets/header/notify.svg';
import PersonIcon from '../../../../assets/header/userIcon.svg';
import LogoDsMkt from '../../../../assets/preload/logo-marketing.png';
import { setSelectedModule } from '../../../../modules/AppConfig/actions/actions';
import {
  setCurrentModule,
  setCurrentServices,
  setCurrentSetting
} from '../../../../modules/Login/actions/actionLogin';
import { listNotification } from '../../../../services/notifications';
import { Notification as TNotification } from '../../../../types/notification';
import Alert from '../../../../utils/helpers/Alert';
import { useSelectMenu } from '../../../../utils/helpers/Redirect';
import AppsMenu from './components/AppsMenu';
//import apps1 from './components/AppsMenu/apps-1';
//import DefaultMenu from './components/DefaultMenu';
import NotificationMenu from './components/NotificationMenu';
import ProfileMenu from './components/ProfileMenu';
import SupportOptionsMenu from './components/SupportOptionsMenu';
import useStyles from './styles';
//import { ButtonMenu } from './stylesComponent';
import WppIcon from '../../../../assets/header/wppIcon.png';
import DropDown from '../../../../assets/header/dropDown.png';

type AppbarProps = {
  open: boolean;
  //showButtonOpen: boolean;

  name: string;
  selectedApp: SelectedModule | null;
  setCurrentModule: any;
  setCurrentSetting: any;
  setCurrentServices: any;
  setSelectedModule: any;
};

type Permission = {
  moduleId: number;
  moduleName: string;
  serviceId: number;
  serviceName: string;
};

const alerta = new Alert();

function Appbar({
  setCurrentModule: _setCurrentModule,
  setCurrentSetting: _setCurrentSetting,
  setCurrentServices: _setCurrentServices,
  setSelectedModule: _setSelectedModule
}: AppbarProps) {
  const classes = useStyles();
  const location = useLocation();
  //const theme: Theme = useTheme();
  const { selectMenu, selectSettings, selectMenuShort } = useSelectMenu();
  const { width } = useWindowDimensions();
  //const [currentTab, setCurrentTab] = useState<number>(0);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [appsMenuAnchorEl, setAppsMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [supportOptionsMenuAnchorEl, setSupportOptionsMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  /*const [defaultMenuAnchor, setDefaultMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [defaultMenuAnchor2, setDefaultMenuAnchor2] = React.useState<HTMLElement | null>(null);
  const [defaultMenuAnchor3, setDefaultMenuAnchor3] = React.useState<HTMLElement | null>(null);
  const [defaultMenuAnchor4, setDefaultMenuAnchor4] = React.useState<HTMLElement | null>(null);
  const [defaultMenuAnchor5, setDefaultMenuAnchor5] = React.useState<HTMLElement | null>(null);
  const [menuCreateNew, setMenuCreateNew] = React.useState<HTMLElement | null>(null);*/

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setProfileMenuAnchorEl(event.currentTarget);

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setNotificationMenuAnchorEl(event.currentTarget);

  const handleProfileMenuClose = () => setProfileMenuAnchorEl(null);
  const handleNotificationMenuClose = () => setNotificationMenuAnchorEl(null);

  const handleAppsMenuClose = () => setAppsMenuAnchorEl(null);
  const handleSupportOptionsMenuClose = () => setSupportOptionsMenuAnchorEl(null);
  const isSmallerScreen = width < 1120;

  const token = useMemo(() => {
    return JSON.parse(localStorage.getItem('token')!);
  }, [localStorage.getItem('token')]);

  const company = useMemo(() => {
    return JSON.parse(localStorage.getItem('company')!);
  }, [localStorage.getItem('company')]);

  const notifications = useQuery('notifications', listNotification, {
    refetchOnWindowFocus: 'always'
  });

  const unread: TNotification[] = useMemo(() => {
    return notifications.data?.filter(data => !Boolean(data.read)) || [];
  }, [notifications.data]);

  /*const handleGoToDsV3 = useCallback(
    (redirect: string) => {
      setCurrentTab(1);
      let hostNameV3 = '';
      isLocalhostWithoutRelese
        ? (hostNameV3 = 'market-dev')
        : isReleaseEnvironment
        ? (hostNameV3 = 'market-rls')
        : (hostNameV3 = 'market');
      const urlDsV3 = `https://${hostNameV3}.datasales.info/sign-as-user/${token}?shortToRedirect=${redirect}`;
      window.location.replace(urlDsV3);
    },
    [token]
  );*/

  const selectFormat = ({ formatString }: { formatString: string }) => {
    selectMenu(
      'criacao',
      'templates,propaganda_de_ofertas',
      `/estudio?createNewTabloid=true&createArtFormat=${formatString}`,
      'Estúdio'
    );
  };

  const menuOptionsCreateNew = [
    { title: 'Post', action: () => selectFormat({ formatString: 'Post' }) },
    { title: 'Post (Vídeo)', action: () => selectFormat({ formatString: 'Post-video' }) },
    { title: 'Story', action: () => selectFormat({ formatString: 'Story' }) },
    { title: 'Story (Vídeo)', action: () => selectFormat({ formatString: 'Story-video' }) },
    { title: 'Encarte', action: () => selectFormat({ formatString: 'Encarte' }) },
    { title: 'Cartaz retrato', action: () => selectFormat({ formatString: 'Cartaz-a4-retrato' }) },
    {
      title: 'Cartaz paisagem',
      action: () => selectFormat({ formatString: 'Cartaz-a4-paisagem' })
    },
    { title: 'Tv Indoor', action: () => selectFormat({ formatString: 'Tv-indoor' }) },
    { title: 'Etiqueta', action: () => selectFormat({ formatString: 'Etiqueta' }) },
    { title: 'Modelo Pronto', action: () => selectFormat({ formatString: 'Modelo Pronto' }) },
    { title: 'Importar', action: () => selectFormat({ formatString: 'upload' }) },
    { title: 'Manual (Em branco)', action: () => selectFormat({ formatString: 'Manual' }) }
  ];

  /*const handleGoToDsMktV3 = useCallback(
    (option: string) => {
      const alias = isLocalhostWithoutRelese
        ? 'https://market-dev.datasales.info'
        : isReleaseEnvironment
        ? 'https://market-rls.datasales.info'
        : 'https://market.datasales.info'; //qa.datasales.info";

      const urlDsV2 = ` ${alias}/sign-as-user/${token}?shortToRedirect=${option}`;

      window.location.assign(urlDsV2);
    },
    [token]
  );*/

  function handleGoToFidelize() {
    hasPermitions('crm', 'ds.fidelize')
      ? window.location.replace(`https://crm-develop.datasales.info/sign-as-user/${token}`)
      : selectMenuShort('without-access');
  }

  const handleRedirectToSuport = () => {
    const user = localStorage.getItem('company') ?? '';
    const parsedUser = JSON.parse(user);
    const companyName = parsedUser.name;
    const companyDocument = parsedUser.cnpj;
    const companyEmail = localStorage.getItem('email') ?? '';

    window.open(`https://api.whatsapp.com/send?text=Ol%C3%A1%2C+sou+da+empresa+${companyName}%2C+aqui+est%C3%A1+meu+CNPJ%3A+${companyDocument}+e+meu+EMAIL%3A+${companyEmail}+Estou+precisando+de+ajuda%21&phone=551141187715

  `);
  };

  const handleGoToDeliveryVip = useCallback(async () => {
    if (company.deliveryVip) {
      try {
        const loginVip = await Fetch(
          `${API_URL_DEV_BACKOFFICE}login/magicLink`,
          { memberId: company.deliveryVip.memberId, merchantId: company.deliveryVip.merchantId },
          'POST'
        );

        if (loginVip.magic_link) {
          window.open(`${loginVip.magic_link}`);
        }
      } catch (e) {
        alerta.alertWarning('', 'Credencias inválidas');
      }
    } else {
      alerta.alertWarning('', 'Você não possui acesso a essa funcionalidade');
      selectSettings('blog', '/form-delivery', 'NewStore');
    }
  }, [token]);

  useEffect(() => {
    const scriptHeadWayApp = document.createElement('script'),
      scriptConpass = document.createElement('script'),
      config = {
        selector: '.headwayapp',
        account: 'xD8aOx',
        trigger: '#btnHeadWayApp'
      };
    scriptHeadWayApp.async = true;
    scriptConpass.async = true;
    scriptHeadWayApp.src = 'https://cdn.headwayapp.co/widget.js';
    scriptConpass.src = 'https://fast.conpass.io/Nb6dGzMyhX5zl.js';
    document.head.appendChild(scriptHeadWayApp);
    document.head.appendChild(scriptConpass);
    scriptHeadWayApp.onload = function () {
      window?.Headway?.init(config);
    };
    scriptConpass.onload = () => {
      if (window?.Conpass && localStorage.getItem('email')) {
        window.Conpass.init({
          name: localStorage.getItem('name'),
          email: localStorage.getItem('email'),
          custom_fields: {
            id_usuario: localStorage.getItem('companyId'),
            id_empresa: localStorage.getItem('companyId')
          }
        });
      }
    };
  }, []);

  /*useEffect(() => {
    if (location.pathname === '/home') {
      setCurrentTab(0);
    } else if (location.pathname === '/financial') {
      setCurrentTab(4);
    }
  }, [location.pathname]);*/

  function hasPermitions(module: string, permit: string) {
    const userPermissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    const permission = userPermissions[module]?.find((permission: Permission) =>
      permission.serviceName.includes(permit)
    );
    return Boolean(permission);
  }

  return (
    <AppBar classes={{ root: classes.root }} color="inherit">
      <Grid container alignItems="center" wrap="nowrap" spacing={0}>
        <Grid
          item
          style={{ display: 'flex', alignItems: 'center', lineHeight: 0, marginLeft: '32px' }}
        >
          <NewIconButtonMenu
            color="default"
            aria-label="apps"
            size="small"
            onClick={() => selectSettings('blog', '/form-delivery', 'NewStore')}
            options={[
              {
                icon: IconLogoDsChat,
                label: 'Fidelizar',
                onClick: handleGoToFidelize
              },
              {
                icon: IconLogoDsChat,
                label: 'DS Omni',
                onClick: () => selectSettings('chat', '/chat', 'chat')
              },
              {
                icon: IconDeliveryVip,
                label: 'Vender mais',
                onClick: handleGoToDeliveryVip
              }
            ]}
          >
            <img
              id="logo-ds-marketing"
              className={classes.image}
              src={LogoDsMkt}
              alt="ds-marketing"
            />
          </NewIconButtonMenu>
          <img src={DropDown} alt="dropDown" />
        </Grid>

        <div className={classes.grow} />
        <Grid item sx={{ marginRight: '0.5rem' }}>
          <Button
            className={classes.btn}
            variant="contained"
            sx={{
              borderRadius: '4.75rem',
              backgroundColor: '#2A3364',
              color: '#FFFFFF',
              paddingLeft: '20px',
              paddingRight: '20px',
              marginRight: '8px',
              '&:hover': {
                backgroundColor: '#f5f5f5',
                color: 'black'
              }
            }}
            onClick={() => window.open('https://www.youtube.com/watch?v=v9PBKqKHP2s')}
          >
            Aprenda o novo menu
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            sx={{
              borderRadius: '4.75rem',
              backgroundColor: '#2A3364',
              color: '#FFFFFF',
              paddingLeft: '20px',
              paddingRight: '20px',
              '&:hover': {
                backgroundColor: '#f5f5f5',
                color: 'black'
              }
            }}
            onClick={handleRedirectToSuport}
          >
            <img src={WppIcon} style={{ marginRight: '8px' }} alt="falar com suporte" />
            Falar com suporte
          </Button>
        </Grid>
        <Grid item sx={{ marginRight: '1rem' }}>
          <Badge badgeContent={unread.length} color="primary">
            <Avatar
              onClick={handleNotificationMenuOpen}
              src={NotificationIcon}
              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
            />
          </Badge>
        </Grid>
        <Grid>
          <Avatar
            onClick={handleProfileMenuOpen}
            src={PersonIcon}
            sx={{ cursor: 'pointer', width: '24px', height: '24px', marginRight: '0.64rem' }}
          />
        </Grid>
      </Grid>

      <NotificationMenu
        anchorEl={notificationMenuAnchorEl}
        onClose={handleNotificationMenuClose}
        notifications={notifications.data}
      />
      <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={handleProfileMenuClose} />
      <NotificationMenu
        anchorEl={notificationMenuAnchorEl}
        onClose={handleNotificationMenuClose}
        notifications={notifications.data}
      />
      <AppsMenu anchor={appsMenuAnchorEl} onClose={handleAppsMenuClose} />

      <SupportOptionsMenu
        anchor={supportOptionsMenuAnchorEl}
        onClose={handleSupportOptionsMenuClose}
      />
    </AppBar>
  );
}

const mapStateToProps = (state: StateProps) => ({
  selectedApp: state.AppReducer.selectedModule
});

const mapDispatchToProps = {
  setCurrentModule,
  setCurrentSetting,
  setCurrentServices,
  setSelectedModule
};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
