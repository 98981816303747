import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  Modal,
  Panel,
  BarChart,
  PieChart
} from '../../../utils/components';
import {
  CircularProgress,
} from '@material-ui/core';

import { getProdutosCategoria } from '../actions/index';

function ModalCategoriasAnalitico(props) {
  const {
    vendasDia,
    vendasHora,
    genero,
    faixaEtaria,
    vendasLoja
  } = props.produtosCategoria;

  useEffect(() => {
    props.getProdutosCategoria({
      idOferta: props.data.id_oferta,
      idFamilia: props.data.id_categoria,
    });
  }, []);

  return (
    <Modal 
      onClose={props.onClose} 
      header={`${props.data.categoria}`} 
      showModal={props.open}
    >
      {props.isLoadingProdutosCategoria ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <CircularProgress></CircularProgress>
        </div> :
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Panel
            width="100%"
            title="Por loja "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <BarChart
              data={vendasLoja}
              barDataKey="vendas"
              xDataKey="nome_loja"
              hide
              // xDataKey="nome_loja"
            />
          </Panel>
          <Panel
            width="100%"
            title="Por dia "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <BarChart
              data={vendasDia}
              barDataKey="vendas"
              xDataKey="data_analise"
            />
          </Panel>
          <Panel
            width="100%"
            title="Por Hora "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <BarChart
              data={vendasHora}
              barDataKey="vendas"
              xDataKey="horario_analise"
            />
          </Panel>
          <Panel
            width="49%"
            title=" Genero"
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <PieChart data={genero} />
          </Panel>
          <Panel
            width="49%"
            title=" Faixa etaria"
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <PieChart data={faixaEtaria} />
          </Panel>
        </div>
      }  
    </Modal>
  );
}

const mapStateToProps = ({ OfertasReducer }) => ({
  ...OfertasReducer,
});

const mapDispatchToProps = {
  getProdutosCategoria,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCategoriasAnalitico);
