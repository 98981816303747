import React from 'react';
import CanvasJSReact from './scripts/canvasjs.react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const options = {
  animationEnabled: true,
  backgroundColor: 'transparent',
};

function _convertToHalfPie(chart) {
  let sum = 0;
  const dataPoints = chart.data[0].dataPoints;

  for (let i = 0; i < dataPoints.length; i++) {
    sum += dataPoints[i].y;
  }

  dataPoints.splice(0, 0, {
    y: sum,
    color: 'transparent',
    toolTipContent: null,
  });
}
export default ({ data, porcent, width, height, heightCanvas, semi }) => {
  const optionsRender = {
    ...options,
    height: heightCanvas || 400,
    width: width || 500,
    data: [
      {
        type: 'pie',
        dataPoints: data,
      },
    ],
  };
  semi && _convertToHalfPie(optionsRender);
  return data && data.length > 0 ? (
    <CanvasJSChart
      height={height}
      options={{
        ...optionsRender,
        data: !semi
          ? optionsRender.data.map(dt => ({
              ...dt,
              yValueFormatString: porcent ? '##0.00"%"' : null,
              indexLabel: '{label} {y}',
            }))
          : optionsRender.data,
      }}
    />
  ) : (
    <section className="semResultado">Nenhum resultado encontrado</section>
  );
};
