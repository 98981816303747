import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import './styles.css';

SwiperCore.use([Navigation, Pagination]);

const carouselItemsStateData = [
  {
    imageUrl: 'https://s3.datasales.info/datasalesio-imagens/banner01.png',
    destinationUrl: 'https://form.jotform.com/240094180910045'
  },
  {
    imageUrl: 'https://s3.datasales.info/datasalesio-imagens/banner02.png',
    destinationUrl: 'https://form.jotform.com/240093555455660'
  },
  {
    imageUrl: 'https://s3.datasales.info/datasalesio-imagens/banner03.png',
    destinationUrl: 'https://form.jotform.com/240093715990662'
  }
];

export function Swipe({ roundedBorders = false }) {
  const [indexSlide, setIndexSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const slideTo = index => swiper.slideTo(index);

  useEffect(() => {
    if (swiper) {
      slideTo(indexSlide);
    }
  }, [indexSlide, swiper]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndexSlide(prev => (prev >= carouselItemsStateData.length - 1 ? 0 : prev + 1));
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSwiperInit = swiper => {
    setSwiper(swiper);
    // Defina o slide inicial para 0 (a primeira imagem) quando o Swiper for inicializado
    swiper.slideTo(0);
  };

  return (
    <div
      style={{
        width: '79%', // Ajuste para 100% da largura
        display: 'flex',
        justifyContent: 'center', // Centraliza o Swiper na tela
        alignItems: 'center',
        height: '22vh'
      }}
    >
      <Swiper
        onSwiper={onSwiperInit} // Use a função onSwiperInit para configurar o slide inicial
        slidesPerView={1}
        spaceBetween={0}
        loop={carouselItemsStateData.length > 1}
        pagination={{
          clickable: true
        }}
        navigation={true}
        className={`dsCampaignPreviewCarrossel${
          carouselItemsStateData.length < 2 ? ' onlyOneCard' : ''
        }`}
      >
        {carouselItemsStateData.map((carouselItem, index) => (
          <SwiperSlide key={index} style={{ width: '100%', justifyContent: 'center' }}>
            <a href={carouselItem.destinationUrl} target="_blank" rel="noopener noreferrer">
              <img
                style={{
                  width: '100%',
                  borderRadius: roundedBorders ? '1.2rem' : 0,
                  height: '22vh',
                  cursor: 'pointer',
                  marginBottom: '2rem',
                  display: 'block'
                }}
                alt="post-preview"
                src={carouselItem.imageUrl}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

