import Search from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

// import { DateRange } from 'react-date-range';
// import * as locales from 'react-date-range/dist/locale';
// import { COLOR_PRIMARY } from 'utils/helpers/Constants';
// import FunilIcon from '../../../../assets/icons/marketing/Funil.svg';
import { useDefaultStyles } from '../../style';

type Range = {
  startDate: Date;
  endDate: Date;
  key: string;
};

interface IFilterAdvertisements {
  nameSearch: string;
  onChangeText: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  onClickOpen: React.MouseEventHandler<HTMLButtonElement>;
  onClickSerch: React.MouseEventHandler<HTMLButtonElement>;
  openFilter: boolean;
  onChangeDateRange: ({ selection }: { selection: any }) => void;
  ranges: Range[];
  button: any;
}

export const FilterAdvertisements = ({
  nameSearch,
  onChangeText,
  onKeyDown,

  button
}: IFilterAdvertisements) => {
  const classes = useDefaultStyles();
  const [searchId, setSearchId] = useState('');

  const theme = useTheme();

  return (
    <Box data-testid="tabloid-v2-filter-advertisements">
      <Grid container className={classes.rootCardSearch}>
        <Grid item className={classes.cardTextField}>
          <TextField
            className={classes.textField}
            id="input-with-icon-search"
            label={nameSearch && 'Pesquisar por nome'}
            placeholder={'Pesquisar propaganda por nome'}
            color="info"
            InputProps={{
              startAdornment: !nameSearch && (
                <InputAdornment position="start">
                  <Search className={classes.inputAdorment} />
                </InputAdornment>
              )
            }}
            value={nameSearch}
            onChange={onChangeText}
          />
        </Grid>
        <Grid item className={classes.cardTextField}>
          <TextField
            className={classes.textField}
            id="outlined-basic"
            type="number"
            label={searchId && 'Pesquisar propaganda por ID'}
            placeholder="Pesquisar propaganda por ID"
            color="info"
            InputProps={{
              startAdornment: !searchId && (
                <InputAdornment position="start">
                  <Search className={classes.inputAdorment} />
                </InputAdornment>
              )
            }}
            variant="outlined"
            onKeyDown={onKeyDown}
            value={searchId}
            onChange={e => setSearchId(e.target.value)}
          />
        </Grid>
        {/* <Grid item>
          <Button
            onClick={onClickOpen}
            startIcon={<img src={FunilIcon} />}
            color="primary"
            size="large"
          >
            Filtrar
          </Button>
          <Fade in={openFilter}>
            <Box position="absolute" zIndex="1" style={{ display: 'grid' }}>
              <DateRange
                rangeColors={[COLOR_PRIMARY]}
                locale={locales.ptBR}
                onChange={onChangeDateRange}
                showMonthAndYearPickers={false}
                moveRangeOnFirstSelection={false}
                editableDateInputs={false}
                showDateDisplay={false}
                ranges={ranges}
              />
              <Button size="large" variant="contained" color="primary" onClick={onClickSerch}>
                Filtrar
              </Button>
            </Box>
          </Fade>
        </Grid> */}
        <Grid item>{button}</Grid>
      </Grid>
    </Box>
  );
};

