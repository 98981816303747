import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles(theme => ({
//   autocompleteFilterFormats: {
//     '& .MuiTextField-root': {
//       padding: 0,
//       borderColor: 'none',
//       color: '#B0B0B0',
//       width: 250,
//       margin: '0 0 24px 0'
//     },

//     '& .MuiOutlinedInput-input': {
//       fontSize: '12px',
//       margin: '5px 0',
//       fontWeight: 500,
//       lineHeight: '15px',
//       letterSpacing: '0.05em'
//     },

//     '& .MuiButtonBase-root, .MuiChip-root': {
//       maxHeight: '25px'
//     },

//     '& .MuiChip-deleteIcon': {
//       maxHeight: '20px'
//     },

//     '& .Mui-focused': {
//       borderColor: 'none',
//       background: '#FFF',
//       marginBottom: '24px',
//       zIndex: 4,
//       maxHeight: 'auto'
//     },

//     '& .MuiFormLabel-root.Mui-focused': {
//       zIndex: 5
//     }
//   }
// }));

function FilterFormats({ formats, handleQueryChange, formatBackgroundSaved }) {
  // const classes = useStyles();

  const [actualFormat, setActualFormat] = useState([
    {
      id: 2,
      name: 'Encarte',
      disabled: true
    }
  ]);

  useEffect(() => {
    if (formatBackgroundSaved) {
      setActualFormat([formats.find(item => item.id === formatBackgroundSaved)]);
    }
  }, [formatBackgroundSaved]);

  return (
    <>
      <Autocomplete
        data-testid="tabloid-v2-filter-my-models"
        multiple
        filterSelectedOptions
        // freeSolo
        limitTags={1}
        options={formats}
        // className={classes.autocompleteFilterFormats}
        // disabled
        // disabled={formats.length <= 0}
        getOptionLabel={option => option.name}
        onChange={(e, value) => {
          handleQueryChange('formats', value);
          setActualFormat(value);
        }}
        value={actualFormat}
        defaultValue={actualFormat}
        // renderTags={(tagValue, getTagProps) =>
        //   tagValue.map((option, index) => (
        //     <Chip
        //       label={option.name}
        //       {...getTagProps({ index })}
        //       disabled={option.disabled}
        //     />
        //   ))
        // }
        renderInput={params => (
          <TextField
            {...params}
            size={'small'}
            color="info"
            variant="outlined"
            placeholder="Selecione os formatos"
            InputProps={{
              ...params.InputProps,
              classes: {},
              endAdornment: <>{params.InputProps.endAdornment}</>
            }}
          />
        )}
        sx={{
          '& .MuiTextField-root': {
            padding: 0,
            borderColor: 'none',
            color: '#B0B0B0',
            width: 250,
            margin: '0 0 24px 0'
          },

          '& .MuiOutlinedInput-input': {
            fontSize: '12px',
            margin: '5px 0',
            fontWeight: 500,
            lineHeight: '15px',
            letterSpacing: '0.05em'
          },

          '& .MuiButtonBase-root, .MuiChip-root': {
            maxHeight: '25px'
          },

          '& .MuiChip-deleteIcon': {
            maxHeight: '20px'
          },

          '& .Mui-focused': {
            borderColor: 'none',
            background: '#FFF',
            marginBottom: '24px',
            zIndex: 4,
            maxHeight: 'auto'
          },

          '& .MuiFormLabel-root.Mui-focused': {
            zIndex: 5
          }
        }}
      />
    </>
  );
}

export default FilterFormats;

