import React from 'react';
import { ReactNode } from "react";
import { PhotoCamera } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


type ButtonFileProps = {
  loading?: boolean;
  label?: string;
  onChange: (file: File ) => void;
  icon?: ReactNode;
  accept?: string[];
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  color?: string;
  erro?: boolean;
};

export function ButtonFile({
  onChange,
  label,
  icon,
  accept,
  error,
  helperText,
  disabled,
  loading,
  erro,
}: ButtonFileProps) {
  return (
    <>
    <Button sx={{
      display: 'flex', 
      flexDirection: 'column', 
      width: 'relative',
      padding: '0.93rem', 
      color: erro ? 'red' : '#616161',
      background: '#F5F5F5',
      border: erro ? '2px solid red' : '0px',
    }}
      component="label"
      //size="large"
      startIcon={icon || <PhotoCamera />}
      variant="contained"
      color={error ? "error" : "inherit"}
      disabled={disabled || loading}
    >
      {label ? `${label}` : loading ? "Subindo arquivo..." : "Escolher arquivo"}
      <input
        hidden
        accept={accept ? accept.join(",") : "image/*"}
        type="file"
        onChange={({ target: { files } }) => onChange(files[0])}
      />
    </Button>

    {error && (
      <Typography paddingLeft={2} variant="body2" color="error">
        {helperText}
      </Typography>
    )}
  </>
  );
}
