import React, { useEffect, useState } from 'react';
import api from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import Chat from './Chat';

function Instance() {
  const [instances, setInstances] = useState([]),
    [loadingInstances, setLoadingInstances] = useState(true);

  useEffect(() => {
    async function instances() {
      try {
        setLoadingInstances(true);
        const response = await api(
            `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/instances/client/list`
          ),
          data = response.content.filter(
            ({ tipo_instancia }) => tipo_instancia === 2
          );
        setInstances(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingInstances(false);
      }
    }

    instances();
  }, []);

  return <Chat instances={instances} loadingInstances={loadingInstances} />;
}

export default Instance;
