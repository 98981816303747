import React, { Component } from 'react';
import moment from 'moment';
import { Button, Icon, Typography, withStyles } from '@material-ui/core';

import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';
import { Card, CustomTable, DialogAlert } from '../../../utils/components';
import { set_editor } from '../../TabloideDigital/action/index';
import {
  close,
  deleteTemplate,
  editTemplate,
  exportSavedTemplate,
  exportTemplate,
  getTemplates,
  saveTemplate,
  setEditor,
  setTemplate,
} from '../actions/actions';
import ModalSaveTemplate from './ModalSaveTemplate';
import { VARIAVEL_CAMPANHA } from '../../../utils/helpers/Constants';

class Studio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idTemplate: undefined,
      templateName: undefined,
      templateLink: undefined,
      templateType: undefined,
      templateSelecionado: undefined,
      exportTemplate: {},
      jsonTemplate: null,
      showModalSave: false,
      showModalList: false,
      showModalEdit: false,
      showModalExport: false,
      showModalResize: false,
      showEditor: true,
      key: 0,
      showDialog: false,
    };
  }

  componentDidMount() {
    this.props.getTemplates();
    this.props.set_editor(this.editor);

    if (this.props.history.location.pathname === '/studio') {
      this.props.setTemplate(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key === 0 && this.state.key === 1) {
      this.props.getTemplates();
    }
    if (Boolean(this.editor)) {
      this.props.set_editor(this.editor);
    }
  }

  handleOnLoad = () => {
    this.props.editor &&
      this.props.editor.loadDesign(
        !this.props.templateSelecionado
          ? null
          : this.props.templateSelecionado.json
      );
  };

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  salvarTemplate(callback) {
    this.editor.exportHtml(data => {
      const { design, html } = data;

      const objTemplate = {
        htmlTemplate: html,
        jsonTemplate: design,
        nomeTemplate: this.state.templateName,
        tipoTemplate: this.state.templateType.label || 'EMAIL',
        arquivoTemplate: this.state.templateType.value || 'html',
      };

      this.props.saveTemplate(objTemplate);
      this.props.getTemplates();
      callback();
    });
  }

  editarTemplate() {
    this.editor.exportHtml(data => {
      const { design, html } = data;

      const objTemplate = {
        id_template: this.state.templateSelecionado.id_template,
        nomeTemplate: !this.state.templateName
          ? this.props.templateSelecionado.nome_template
          : this.state.templateName,
        htmlTemplate: html,
        jsonTemplate: design,
        tipoTemplate: this.state.templateType.label || 'EMAIL',
        arquivoTemplate: this.state.templateType.value || 'html',
      };

      this.props.editTemplate(objTemplate);
      this.setState({ showModalEdit: false });
    });
  }

  duplicarTemplate() {
    this.editor.exportHtml(data => {
      const { design, html } = data;

      const objTemplate = {
        htmlTemplate: html,
        jsonTemplate: design,
        nomeTemplate: `${this.props.templateSelecionado.nome_template}.copia`,
        tipoTemplate: this.props.templateSelecionado.tipo_template,
        arquivoTemplate:
          this.props.templateSelecionado.tipo_template === 'EMAIL'
            ? 'html'
            : this.props.templateSelecionado.tipo_template,
      };

      this.props.saveTemplate(objTemplate);
      this.props.getTemplates();
    });
  }

  exportTemplate() {
    this.editor.exportHtml(data => {
      const { design, html } = data;

      const objTemplate = {
        ...this.state.exportTemplate,
        type: 'export',
        htmlTemplate: html,
        jsonTemplate: design,
        nomeTemplate: 'test',
        tipoTemplate: 'PNG',
        arquivoTemplate: 'png',
      };

      this.props.exportTemplate(objTemplate);
    });
  }

  exportSavedTemplate() {
    const objExport = {
      ...this.state.exportTemplate,
    };

    this.props.exportSavedTemplate(objExport);
  }

  render() {
    const { classes, listaTemplate } = this.props;
    const {
      showEditor,
      // exportTemplate,
      showModalSave,
      key,
      showDialog,
    } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Templates de E-mail
        </Typography>

        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              marginTop: 10,
              marginBottom: 10,
              // justifyContent: "space-between",
              alignItems: 'flex-end',
            }}
          >
            <div style={{ flexGrow: 1, display: 'flex' }}>
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 0 && classes.activeButton}
                onClick={() => this.setState({ key: 0 })}
              >
                Criar template
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  '&:hover': { borderRadius: 10 },
                }}
                color="inherit"
                size="large"
                className={key === 1 && classes.activeButton}
                onClick={() => this.setState({ key: 1 })}
              >
                Lista de Templates
              </Button>
            </div>
            {key === 0 && (
              <div>
                <Button
                  color="primary"
                  disabled={!this.props.templateSelecionado}
                  size="large"
                  variant="outlined"
                  style={{ marginRight: 20 }}
                  onClick={() => this.setState({ showModalSave: true })}
                >
                  Salvar Cópia
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={() =>
                    this.props.templateSelecionado
                      ? this.editarTemplate()
                      : this.setState({ showModalSave: true })
                  }
                >
                  Salvar template
                </Button>
              </div>
            )}
          </section>
        </div>

        <div className={classes.content}>
          {showEditor && key === 0 && (
            <EmailEditor
              id="editor-id"
              minHeight="650px"
              style={{ border: 10 }}
              ref={editor => (this.editor = editor)}
              onLoad={this.handleOnLoad}
              options={{
                locale: 'pt-BR',
                mergeTags: VARIAVEL_CAMPANHA,
              }}
              appearance={{
                theme: 'light',
                panels: {
                  tools: {
                    dock: 'right',
                  },
                },
              }}
            />
          )}
          {key === 1 && (
            <Card title="Lista de templates" loading={false}>
              <CustomTable
                title=""
                columnsButton
                pageSize={7}
                paging
                search
                onRowClick={(columns, data) => {
                  this.setState({
                    key: 0,
                    idTemplate: data.id_template,
                    templateName: data.nome_template,
                    templateSelecionado: data,
                    templateType: {
                      label: data.tipo_template,
                      value: 'html',
                    },
                  });
                  this.props.setTemplate(data);
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                }}
                actions={[
                  {
                    icon: 'arrow_downward',
                    tooltip: 'Download',
                    onClick: (event, rowData) => {
                      window.open(rowData.link_template, '_blank');
                    },
                  },
                  {
                    icon: () => <Icon color="action">edit</Icon>,
                    tooltip: 'Editar',
                    onClick: (event, rowData) => {
                      this.setState({
                        key: 0,
                        idTemplate: rowData.id_template,
                        templateName: rowData.nome_template,
                        templateSelecionado: rowData,
                        templateType: {
                          label: rowData.tipo_template,
                          value: 'html',
                        },
                      });
                      this.props.setTemplate(rowData);
                    },
                  },
                  {
                    icon: () => <Icon color="error">delete</Icon>,
                    tooltip: 'Excluir',
                    onClick: (event, rowData) =>
                      this.setState({
                        showDialog: true,
                        id_template: rowData.id_template,
                      }),
                  },
                ]}
                // isLoading={loadingPerformance}
                // onRowClick={(columns, data) =>
                //   this.setState({ data: data }, () =>
                //     this.setState({ showModal: true })
                //   )
                // }

                columns={[
                  { title: 'Nome', field: 'nome_template' },
                  {
                    title: 'Data',
                    field: 'data_insert',
                    render: rowData =>
                      moment(rowData.data_insert).format('DD/MM/YYYY'),
                  },
                  { title: 'Tipo', field: 'tipo_template' },
                ]}
                data={listaTemplate.data ? listaTemplate.data : []}
              />
            </Card>
          )}
        </div>
        {showModalSave && (
          <ModalSaveTemplate
            showModal={showModalSave}
            template={this.state}
            onSave={() =>
              this.salvarTemplate(() => this.setState({ showModalSave: false }))
            }
            onEdit={() =>
              this.setState({ showModalSave: false }, () =>
                this.editarTemplate()
              )
            }
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalSave: false })}
          />
        )}

        {showDialog && (
          <DialogAlert
            open={this.state.showDialog}
            title="Deseja excluir este template ?"
            onClose={() => this.setState({ showDialog: false })}
            onClick={() =>
              this.setState({ showDialog: false }, () =>
                this.props.deleteTemplate({
                  id_template: this.state.id_template,
                })
              )
            }
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },

  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    width: '100%',
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapDispatchToProps = {
  saveTemplate,
  getTemplates,
  setEditor,
  setTemplate,
  editTemplate,
  exportTemplate,
  exportSavedTemplate,
  deleteTemplate,
  close,
  set_editor,
};

const mapStateToProps = ({ TemplateReducer, TabloideDigitalReducer }) => ({
  ...TemplateReducer,
  ...TabloideDigitalReducer,
});

const StudioConnect = connect(mapStateToProps, mapDispatchToProps)(Studio);

export default withStyles(styles)(StudioConnect);
