import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop': {
        background: 'rgba(29, 29, 29, 0.75)',
        backdropFilter: 'blur(6px)'
      }
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      gap: 16,
      letterSpacing: '0.05em',

      '& .MuiButton-root': {
        padding: '12px 16px',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.05em'
      },

      '& .MuiDialogTitle-root': {
        padding: 0
      },

      '& .MuiDialogContent-root': {
        padding: 0
      },

      '& .MuiDialogContentText-root': {
        marginBottom: 0
      },

      '& .MuiDivider-root': {
        borderColor: '#CFCFCF'
      }
    },
    title: {
      color: '#000000'
    },
    content: {
      color: '#616161',
      width: 564,

      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    cardButtons: {
      display: 'flex',
      flexDirection: 'row',
      letterSpacing: '0.05em'
    },
    button1: { alignItems: 'flex-start', color: '#616161' },
    button2: { marginLeft: 'auto' }
  })
);
