import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useStyles } from './styles';

type PropsSelectDays = {
  setFieldValue: any;
  values: any;
};

export function SelectDays(props: PropsSelectDays) {
  const styles = useStyles();
  const icon = (value: string) => {
    return <Box className={styles.icon}>{value}</Box>;
  };
  const iconChecked = (value: string) => {
    return <Box className={styles.iconChecked}>{value}</Box>;
  };
  return (
    <Grid className={styles.gridGlobal} marginLeft={'48px'}>
      <Typography className={styles.titulo}>Repetir Toda:</Typography>
      <FormGroup row>
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('D')}
              checkedIcon={iconChecked('D')}
              checked={props.values.weeklyDaysPublish?.sunday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  sunday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('S')}
              checkedIcon={iconChecked('S')}
              checked={props.values.weeklyDaysPublish?.monday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  monday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('T')}
              checkedIcon={iconChecked('T')}
              checked={props.values.weeklyDaysPublish?.tuesday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  tuesday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('Q')}
              checkedIcon={iconChecked('Q')}
              checked={props.values.weeklyDaysPublish?.wednesday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  wednesday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('Q')}
              checkedIcon={iconChecked('Q')}
              checked={props.values.weeklyDaysPublish?.thursday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  thursday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('S')}
              checkedIcon={iconChecked('S')}
              checked={props.values.weeklyDaysPublish?.friday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  friday: target.checked
                });
              }}
            />
          }
        />
        <FormControlLabel
          label={undefined}
          control={
            <Checkbox
              icon={icon('S')}
              checkedIcon={iconChecked('S')}
              checked={props.values.weeklyDaysPublish?.saturday}
              className={styles.checkBoxContainer}
              onChange={({ target }) => {
                props.setFieldValue('weeklyDaysPublish', {
                  ...props.values.weeklyDaysPublish,
                  saturday: target.checked
                });
              }}
            />
          }
        />
      </FormGroup>
    </Grid>
  );
}

