import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Checkbox, Typography } from '@mui/material';
import SwiperCore, { A11y, Navigation } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { COLOR_NEW_PRIMARY } from 'utils/helpers/Constants';

import { breakpoints as Breakpoint } from '.';
import { SubcategoriesBackgroundsType, SubcategoriesType } from '../../reducer';
import { useStyles } from './styles';

SwiperCore.use([Navigation, A11y]);

type ShowBackgroundsProps = {
  backgroundOrSearchResults: [] | SubcategoriesType[];
  breakpoints: typeof Breakpoint;
  isButtonToUpScrollVisible: boolean;
  isBackgroundSelected: (subcategoryId: number, backgroundId: number) => boolean;
  handleToggleSelectedBackground: (
    description: string,
    id: number,
    background: SubcategoriesBackgroundsType
  ) => void;
  onOpenBackground: (name: string, url: string) => void;
};

const label = { inputProps: { 'aria-label': 'Checkbox Subcategories' } };

export function ShowBackgrounds({
  backgroundOrSearchResults,
  breakpoints,
  isButtonToUpScrollVisible = false,
  handleToggleSelectedBackground,
  isBackgroundSelected,
  onOpenBackground
}: ShowBackgroundsProps) {
  const classes = useStyles();

  return (
    <>
      {backgroundOrSearchResults?.map((subcategory, index) => {
        if (
          subcategory.backgrounds.filter(item => item.tags.includes('dsartapp')).length !== 0 &&
          subcategory.backgrounds.length === 1
        ) {
          return null;
        } else {
          return (
            <div key={index} className={classes.subcategoriesContainer}>
              <Typography className={classes.subcategoriesTitle}>
                {subcategory.DESCRIPTION}
              </Typography>
              <Swiper
                spaceBetween={16}
                loop={false}
                pagination={false}
                navigation={true}
                // modules={[Navigation]}
                className={classes.subcategoriesSlider}
                breakpoints={breakpoints}
              >
                {subcategory?.backgrounds?.map((background, index) => {
                  if (!background.tags.includes('dsartapp'))
                    return (
                      <SwiperSlide
                        key={index}
                        className={classes.subcategoriesSlide}
                        data-testid="dsModule-fast-models-hero-component-subcategories-slide"
                      >
                        <div
                          className={[
                            classes.subcategoriesSlideImageContainer,
                            isBackgroundSelected(subcategory.ID, background.id)
                              ? classes.subcategoriesSlideImageContainerSelected
                              : ''
                          ].join(' ')}
                          data-background-selector={subcategory.ID}
                          onClick={e => {
                            const target = e.target as HTMLDivElement;

                            const isTheBackgroundPreviewElement = target?.getAttribute(
                              'data-background-selector'
                            );

                            if (isTheBackgroundPreviewElement)
                              onOpenBackground(background.name, background.imageURL);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Checkbox
                            className={classes.subcategoriesSlideCheckbox}
                            checked={isBackgroundSelected(subcategory.ID, background.id)}
                            onChange={() => {
                              handleToggleSelectedBackground(
                                subcategory.DESCRIPTION,
                                subcategory.ID,
                                background
                              );
                            }}
                            {...label}
                          />
                          <img
                            className={classes.subcategoriesSlideImage}
                            crossOrigin="anonymous"
                            src={background.thumbnail || background.imageURL}
                            alt={`Subcategory ${subcategory.DESCRIPTION} Image - ${background.name} (${subcategory.ID})`}
                          />
                          {background.auxiliarTextQtde && background.auxiliarTextQtde > 0 && (
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 8,
                                left: 8,
                                borderRadius: 8,
                                background: 'black',
                                color: 'white',
                                padding: '4px 12px'
                              }}
                            >
                              Texto editável
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    );
                })}
              </Swiper>

              {isButtonToUpScrollVisible ? (
                <Button
                  // className={classes.buttonUpScroll}
                  sx={{
                    zIndex: 10,
                    position: 'fixed',
                    right: 35,
                    bottom: 160,
                    background: COLOR_NEW_PRIMARY
                  }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                </Button>
              ) : null}
            </div>
          );
        }
      })}
    </>
  );
}
