/* eslint---disable */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Dialog, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

//import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropDownIcon from '../../../../../assets/icons/icons/downArrowIcon.svg';
import EditIcon from '../../../../../assets/icons/icons/editIcon.svg';
import TrashIcon from '../../../../../assets/icons/icons/trashIcon.svg';
import {
  CustomTable
  /*SplitButton*/
} from '../../../../../utils/components';
import { AlertDialogV2 } from '../../../../../utils/components/AlertDialogV2';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../../utils/helpers/Constants';
import { Fetch } from '../../../../../utils/helpers/Fetch';
import { formatPrice } from '../../../../../utils/helpers/Format';
//actions
import { postProdutoImagemTabloide } from '../../../../TabloideDigitalV2/action';
import AdicionarProduto from '../../../../TabloideDigitalV2/components/AdicionarProduto';

/*const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});*/
const TypographyInfoTable = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: '#1E1F21',
  display: 'inline'
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const style_button_1 = {
  width: 180,
  background: '#F5F5F5',
  color: '#616161',
  borderRadius: '4px',
  fontSize: 14,
  fontWeight: 400,
  '&:hover': {
    filter: 'brightness(0.8)',
    background: '#F5F5F5'
  }
};

const dinamica_comercial_array = {
  preco_unico: ['Preço De:', 'Preço Por:'],
  de_por: ['Preço De:', 'Preço Por:'],
  varejo_atacado: ['Preço Varejo:', 'Preço Atacado:'],
  pack_unitario: ['Preço Pack:', 'Preço Unitário:'],
  normal_clube: ['Preço Normal:', 'Preço Clube:'],
  limpo: ['Preço 1:', 'Preço 2:']
};

function SelecionarProdutos(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { tabloide } = props;

  const [produtos, setProdutos] = React.useState(null);

  const [openAdicionarProdutos, setOpenAdicionarProdutos] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [produtoFoco, setProdutoFoco] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState([false, () => {}]);
  const [countProductsSelect, setCountProductsSelect] = useState(0);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleOpenAdicionarProdutos = () => {
    setOpenAdicionarProdutos(true);
  };
  const handleCloseAdicionarProdutos = () => {
    setOpenAdicionarProdutos(false);
  };

  useEffect(() => {
    setProdutos(prev =>
      props.tabloideProdutos.map((element, index) => {
        let tmp = props.idsNaoIncluidos.find(element2 => element.id_tabloide_produto === element2);
        if (tmp) {
          tmp = false;
        } else {
          tmp = true;
        }
        return {
          ...element,
          selecionado: prev && prev[index] ? prev[index].selecionado : tmp
        };
      })
    );
  }, [props.tabloideProdutos]);

  useEffect(() => {
    props.onOpen();
  }, []);

  useEffect(() => {
    if (produtos?.length) {
      const initialCount = 0;
      const count = produtos.reduce((accumulator, currentProduct) => {
        if (currentProduct.selecionado) {
          return accumulator + 1;
        }
        return accumulator;
      }, initialCount);
      setCountProductsSelect(count);
    }
  }, [produtos]);

  const [selecionarTodos, setSelecionarTodos] = useState(false);

  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  async function postProdutoImagemTabloide(data, isBase64) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64
      });
      enqueueSnackbar('Upload de imagem com sucessoo!', {
        variant: 'success'
      });
      return image;
    } catch (err) {
      enqueueSnackbar(err.message || err.title, {
        variant: 'error'
      });
      return null;
    }
  }

  const toUpdateSequence = () => {
    let tmp = [...produtos];
    tmp.forEach((element, index) => {
      element.sequence = index;
    });
    let toUpdateSequence = null;
    toUpdateSequence = {
      products: [...tmp],
      categorias: []
    };

    return toUpdateSequence;
  };

  async function handleSubmitProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
        { produto: product },
        'POST',
        null,
        null,
        false
      );

      setProdutos(prevState => [...prevState, response.produto]);
      //alert('Produto adicionado ao final da lista!');

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });

      props.fetchProductsCreateArt();

      enqueueSnackbar('Produto adicionado ao final da lista!', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleEditProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
        { produto: product, toUpdateSequence: toUpdateSequence() },
        'PUT',
        null,
        null,
        false
      );

      setProdutos(prevState => {
        let index = prevState.findIndex(
          element => element.id_tabloide_produto === product.id_tabloide_produto
        );
        prevState[index] = product;
        return [...prevState];
      });

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      props.fetchProductsCreateArt();

      enqueueSnackbar('Atualização concluída', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleEditProducts(products) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putManyProducts`,
        { products, toUpdateSequence: toUpdateSequence() },
        'PUT',
        null,
        null,
        false
      );

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      props.fetchProductsCreateArt();

      enqueueSnackbar('Produtos deletados', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function updateProduto(newData, type, close) {
    try {
      setSubmiting(true);

      let new_image = '';
      let data = {
        ...newData,
        preco_original:
          typeof newData.preco_original === 'string'
            ? parseFloat(newData.preco_original.replace(',', '.'))
            : newData.preco_original,
        preco_dois:
          typeof newData.preco_dois === 'string'
            ? parseFloat(newData.preco_dois.replace(',', '.'))
            : newData.preco_dois
      };

      if (type === 'add') {
        data = {
          ...data,
          produto_ativo: 1
        };
      }

      let multProdutos =
        produtoFoco && produtoFoco.multProdutos ? [...produtoFoco.multProdutos] : [];
      data.forDelete = [];
      for (let i in multProdutos) {
        let atual = multProdutos[i];

        if (data.imagens) {
          let index = data.imagens.findIndex(
            element => element.id_tabloide_produto === atual.id_tabloide_produto
          );

          if (index < 0) {
            data.forDelete = [...data.forDelete, atual];
          }
        }
      }

      if (data.imagemPrincipal) {
        data = {
          ...data,
          ...data.imagemPrincipal[0]
        };

        data.imagens = [...data.imagemPrincipal, ...data.imagens];
      }

      if (data.imagens) {
        for (let i in data.imagens) {
          let image = data.imagens[i];

          if (image.new && image.imagem_produto) {
            if (Boolean(image.imagem_produto) && /;base64,/g.test(image.imagem_produto)) {
              new_image = await postProdutoImagemTabloide(image, true);
            } else {
              new_image = await postProdutoImagemTabloide(image, false);
            }
            //
            // throw 500;
            //
            let new_image_object = new_image;
            new_image = new_image.image;
            if (!new_image_object.findExistingImage) {
              let dateNow = new window.Date();
              dateNow = dateNow.getTime().toString();
              if (validURL(new_image)) new_image = new_image.split('?')[0] + '?' + dateNow;
            }

            let objTmp = {
              ...image, // pra receber o new
              imagem_produto: new_image,
              imagem_produto_original_link: new_image_object.imagem_produto_original_link,
              imagem_produto_original_s3: new_image_object.imagem_produto_original_s3,
              new: true
            };

            if (i === '0') {
              data = {
                ...data,
                ...objTmp
              };
            }

            let tmpImagens = data.imagens;
            tmpImagens[i] = {
              ...objTmp
            };

            data.imagens = [...tmpImagens];
          }
        }

        data.imagens.shift();
      }

      if (type === 'add') {
        await handleSubmitProduct({
          ...data,
          id_tabloide_digital: tabloide.id_tabloide_digital
        });
      } else {
        const index = produtos.indexOf(produtoFoco);

        produtos[index] = data;
        setProdutos(produtos);
        await handleEditProduct(data);
      }

      setSubmiting(false);

      close();
    } catch (e) {
      setSubmiting(false);

      console.log(e);

      enqueueSnackbar('Falha de Processamento! Tente novamente ou tente outra imagem.', {
        variant: 'error'
      });
    }
  }

  async function handleUpdateTabloid({ executeHandleSubmit } = { executeHandleSubmit: false }) {
    try {
      enqueueSnackbar('Atualizando informações...', {
        variant: 'info'
      });

      const data = {
        id_tabloide_digital: tabloide.id_tabloide_digital,
        uuid: tabloide.uuid,
        nome_tabloide_digital: tabloide.nome_tabloide_digital.replaceAll('/', '-'),
        descricao: tabloide.descricao,
        data_inicio: tabloide.data_inicio,
        data_fim: tabloide.data_fim,
        storeId: null,
        colors: tabloide.colors,
        googleId: '',
        facebookId: '',
        toUpdateSequence: toUpdateSequence()
      };

      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
        data,
        'PUT',
        null,
        null,
        false
      );

      enqueueSnackbar(response.title, {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    } finally {
      props.fetchProductsCreateArt({ executeHandleSubmit });
    }
  }

  return (
    <>
      <Dialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth
        disableBackdropClick
        maxWidth={'md'}
        PaperProps={{
          style: {
            backgroundColor: '#F2F3F5',
            padding: '24px'
          }
        }}
      >
        <DialogContent style={{ paddingTop: '1px' }}>
          {/*<SplitButton
          key={'list_produtos'}
          text="Adicionar produtos"
          label="label"
          style={{
            marginTop: 6,
            marginBotton: 6, 
            ...style_button_1
          }}
          icon
        >
          <MenuItem
            key="Adicionar produto único"
            onClick={() => handleOpenAdicionarProdutos()}
          >
            Adicionar produto único
          </MenuItem>

        </SplitButton>*/}

          {/*<Box display={'flex'} justifyContent={'space-between'}>
           
          </Box>*/}
          <CustomTable
            data={produtos}
            pageSize={countProductsSelect}
            search
            headerStyle={{
              backgroundColor: '#F2F3F5',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px'
            }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#F2F3F5',
              zIndex: 99
            }}
            options={{
              paging: false,
              sorting: false
            }}
            columns={[
              {
                field: 'imagem_produto',
                title: (
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '1.1rem' }}>
                    <Typography style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>
                      Foto
                    </Typography>
                    <FormControlLabel
                      style={{
                        fontSize: '12px',
                        fontWeight: 300,
                        lineHeight: '16px'
                      }}
                      control={
                        <Checkbox
                          checked={selecionarTodos}
                          onChange={event => {
                            let checked = event.target.checked;
                            setSelecionarTodos(checked);

                            let tmp = [...produtos];
                            tmp = tmp.map(element => ({
                              ...element,
                              selecionado: checked
                            }));

                            setProdutos([...tmp]);
                          }}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Selecionar Todos"
                    />
                  </Box>
                ),
                render: rowData => (
                  <img
                    alt={rowData.imagem_produto}
                    src={rowData.imagem_produto}
                    crossOrigin="anonymous"
                    style={{
                      width: 75,
                      height: 90,
                      cursor: 'pointer',
                      backgroundColor: '#e7e7e7'
                    }}
                    // onClick={() => props.onClickProduct(rowData)}
                  />
                )
              },
              {
                title: 'Informações',
                field: 'ean',
                render: rowData => (
                  <div
                    style={{ cursor: 'pointer', width: '180px' }}
                    // onClick={() => props.onClickProduct(rowData)}
                  >
                    {!!rowData.ean && (
                      <span>
                        <TypographyInfoTable>EAN: </TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {rowData.ean}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.id_produto && (
                      <span>
                        <TypographyInfoTable>Código: </TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {rowData.id_produto}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.nome_produto && (
                      <span>
                        <TypographyInfoTable>Nome:</TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {rowData.nome_produto}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.preco_original && (
                      <span>
                        <TypographyInfoTable>
                          {rowData.dinamica_comercial
                            ? dinamica_comercial_array[rowData.dinamica_comercial][0]
                            : dinamica_comercial_array['de_por'][0]}
                        </TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {formatPrice(rowData.preco_original)}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.preco_dois && (
                      <span>
                        <TypographyInfoTable>
                          {rowData.dinamica_comercial
                            ? dinamica_comercial_array[rowData.dinamica_comercial][1]
                            : dinamica_comercial_array['de_por'][1]}
                        </TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {formatPrice(rowData.preco_dois)}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.gramatura && (
                      <span>
                        <TypographyInfoTable>Unidade:</TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {rowData.gramatura}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    {!!rowData.observacoes && (
                      <span>
                        <TypographyInfoTable>Observações:</TypographyInfoTable>
                        <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                          {rowData.observacoes}
                        </TypographyInfoTable>
                        <br />
                      </span>
                    )}
                    <span>
                      <TypographyInfoTable>Sequência:</TypographyInfoTable>
                      <TypographyInfoTable sx={{ color: '#616161', marginLeft: '0.5rem' }}>
                        {rowData.sequence}
                      </TypographyInfoTable>
                      <br />
                    </span>
                  </div>
                )
              },
              {
                title: (
                  <Box sx={{ display: 'flex', gap: '0.7rem', alignItems: 'center' }}>
                    <Button
                      data-testid="delete-all-products"
                      variant="outlined"
                      disabled={!countProductsSelect}
                      onClick={() => {
                        setShowDeleteDialog([
                          true,
                          () => {
                            const allProducts = [...produtos];
                            const productsSelectedIndex = [];
                            const productsSelected = [];
                            allProducts.forEach((produto, index) => {
                              if (produto.selecionado) {
                                productsSelected.push({ ...produto, produto_ativo: 0 });
                                productsSelectedIndex.push(index);
                              }
                            });
                            productsSelectedIndex.reverse();
                            productsSelectedIndex.forEach(productIndex =>
                              allProducts.splice(productIndex, 1)
                            );
                            setProdutos(allProducts);
                            handleEditProducts(productsSelected);
                          }
                        ]);
                      }}
                      style={{
                        color: !countProductsSelect ? '#B0B0B0' : '#B02F2F',
                        width: '12.5rem'
                      }}
                    >
                      Apagar {countProductsSelect > 1 ? 'selecionados' : 'selecionado'}
                    </Button>
                    <Typography style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>
                      Ações
                    </Typography>
                  </Box>
                ),
                align: 'right',

                render: rowData => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.2rem' }}>
                    <Box component={'div'} sx={{ display: 'flex', gap: '1.5rem' }}>
                      {rowData.tableData.id !== 0 && (
                        <Box
                          component={'img'}
                          src={ArrowDropDownIcon}
                          style={{
                            cursor: 'pointer',
                            transform: 'rotate(180deg)',
                            width: '25px',
                            height: '25px'
                          }}
                          onClick={() => {
                            let productsFiltrado = produtos;
                            const index = produtos.findIndex(
                              element => rowData.id_tabloide_produto === element.id_tabloide_produto
                            );
                            const index2 = produtos.findIndex(
                              element =>
                                productsFiltrado[rowData.tableData.id - 1].id_tabloide_produto ===
                                element.id_tabloide_produto
                            );
                            let tmp1 = {
                              ...produtos[index],
                              sequence: produtos[index].sequence - 1
                            };
                            let tmp2 = {
                              ...produtos[index2],
                              sequence: produtos[index2].sequence + 1
                            };
                            let tmp3 = [...produtos];
                            tmp3[index] = { ...tmp2 };
                            tmp3[index2] = { ...tmp1 };
                            setProdutos(tmp3);
                            forceUpdate();
                          }}
                        />
                      )}
                      {rowData.tableData.id !== produtos.length - 1 && (
                        <Box
                          component={'img'}
                          src={ArrowDropDownIcon}
                          style={{
                            cursor: 'pointer',
                            width: '25px',
                            height: '25px'
                          }}
                          onClick={() => {
                            let productsFiltrado = produtos;
                            const index = produtos.findIndex(
                              element => rowData.id_tabloide_produto === element.id_tabloide_produto
                            );
                            const index2 = produtos.findIndex(
                              element =>
                                productsFiltrado[rowData.tableData.id + 1].id_tabloide_produto ===
                                element.id_tabloide_produto
                            );
                            let tmp1 = {
                              ...produtos[index],
                              sequence: produtos[index].sequence + 1
                            };
                            let tmp2 = {
                              ...produtos[index2],
                              sequence: produtos[index2].sequence - 1
                            };
                            let tmp3 = [...produtos];
                            tmp3[index] = { ...tmp2 };
                            tmp3[index2] = { ...tmp1 };
                            setProdutos(tmp3);
                            forceUpdate();
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      component={'img'}
                      src={EditIcon}
                      style={{
                        cursor: 'pointer',
                        fontSize: 30,
                        marginRight: 12
                      }}
                      onClick={() => {
                        setProdutoFoco(rowData);
                        handleOpenAdicionarProdutos();
                      }}
                    />
                    <Box
                      component={'img'}
                      src={TrashIcon}
                      style={{
                        cursor: 'pointer',
                        fontSize: 30,
                        marginRight: 12
                      }}
                      onClick={() => {
                        setShowDeleteDialog([
                          true,
                          () => {
                            const index = produtos.findIndex(
                              e => e.id_tabloide_produto === rowData.id_tabloide_produto
                            );
                            produtos.splice(index, 1);
                            setProdutos(produtos);
                            handleEditProduct({ ...rowData, produto_ativo: 0 });
                          }
                        ]);
                      }}
                    />
                    <Checkbox
                      checked={rowData.selecionado}
                      onChange={event => {
                        let tmp = [...produtos];
                        tmp[rowData.tableData.id] = {
                          ...tmp[rowData.tableData.id],
                          selecionado: event.target.checked
                        };
                        setProdutos([...tmp]);
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Box>
                )
              }
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={async () => {
              let tmp = produtos.filter(value => !value.selecionado);
              props.onSave(tmp.map(value => value.id_tabloide_produto));
              await handleUpdateTabloid({ executeHandleSubmit: true });
            }}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {openAdicionarProdutos && (
        <AdicionarProduto
          submiting={submiting}
          open={openAdicionarProdutos}
          produtoFoco={produtoFoco}
          categorias={[]}
          categoriaPadrao={null}
          onSave={data => {
            const close = () => {
              setProdutoFoco(null);
              handleCloseAdicionarProdutos();
              //setProdutoFoco(null);
            };
            if (produtoFoco && produtoFoco.lenght !== 0) {
              updateProduto(data, 'update', close);
            } else {
              updateProduto(data, 'add', close);
            }
          }}
          onClose={() => {
            setProdutoFoco(null);
            handleCloseAdicionarProdutos();
          }}
        />
      )}
      {showDeleteDialog[0] && (
        <AlertDialogV2
          open={showDeleteDialog[0]}
          title={`Deseja realmente excluir ${
            countProductsSelect > 1 ? 'esses itens?' : 'esse item?'
          }`}
          textAgree="Sim"
          handleAgree={() => {
            showDeleteDialog[1]();
            setShowDeleteDialog([false, () => {}]);
          }}
          handleClose={() => setShowDeleteDialog([false, () => {}])}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});
const mapDispatchToProps = {
  postProdutoImagemTabloide
};

export default connect(mapStateToProps, mapDispatchToProps)(SelecionarProdutos);
