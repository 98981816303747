import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Radio,
} from '@material-ui/core';
import DialogTitle from '../../../../utils/components/DialogTitle';
// import { AMBIENTE } from '../../../../utils/helpers/Constants';

const useStyles = makeStyles(theme => ({
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  listItemSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

function Distribute({ open, type, onSubmit, onClose, arrayAllowed,...props}) {

  const classes = useStyles();

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    console.log(`
      open: ${open}
      type: ${type}
      arrayAllowed: ${JSON.stringify(arrayAllowed)}
    `)
  }, [open, type, arrayAllowed]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        Selecione um tipo de distribuição
      </DialogTitle>
      <DialogContent dividers>
        <List disablePadding>
          {!!arrayAllowed[0] && (
            <ListItem
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              button
              selected={selectedValue === 'post'}
              onClick={() => {
                setSelectedValue('post');
              }}
            >
              <ListItemText
                primary="Meus Clientes"
                secondary="Publicação para os clientes que já nos seguem no Facebook, Instagram e Whatsapp."
              />
              <ListItemSecondaryAction>
                <Radio
                  checked={selectedValue === 'post'}
                  onChange={handleChange}
                  value="post"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {!!arrayAllowed[1] && (
            <ListItem
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              button
              selected={selectedValue === 'ads'}
              onClick={() => {
                setSelectedValue('ads');
              }}
            >
              <ListItemText
                primary="Novos Clientes"
                secondary="Publicação para possíveis novos clientes em um ou mais bairros da cidade."
              />
              <ListItemSecondaryAction>
                <Radio
                  checked={selectedValue === 'ads'}
                  onChange={handleChange}
                  value="ads"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {!!arrayAllowed[2] && type.toUpperCase() === 'encarte'.toUpperCase() && (
            <ListItem
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              button
              selected={selectedValue === 'app'}
              onClick={() => {
                setSelectedValue('app');
              }}
            >
              <ListItemText primary="Encartes no App" secondary="Publicação de encartes e tabloides no Sites." />
              <ListItemSecondaryAction>
                <Radio
                  checked={selectedValue === 'app'}
                  onChange={handleChange}
                  value="app"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {!!arrayAllowed[3] && type.toUpperCase() !== 'encarte'.toUpperCase() && (
            <ListItem
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              button
              selected={selectedValue === 'banner'}
              onClick={() => {
                setSelectedValue('banner');
              }}
            >
              <ListItemText
                primary="Site e Aplicativo"
                secondary="Publicação dos banners rotativos para atrair a atenção dos clientes."
              />
              <ListItemSecondaryAction>
                <Radio
                  checked={selectedValue === 'banner'}
                  onChange={handleChange}
                  value="banner"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {!!arrayAllowed[4] && (
            <ListItem
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              button
              selected={selectedValue === 'push'}
              onClick={() => {
                setSelectedValue('push');
              }}
            >
              <ListItemText
                primary="Aplicativo"
                secondary="Publicação de notificações para todos os dispositivos."
              />
              <ListItemSecondaryAction>
                <Radio
                  checked={selectedValue === 'push'}
                  onChange={handleChange}
                  value="push"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!selectedValue}
          onClick={() => {
            onSubmit(selectedValue);
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Distribute;

