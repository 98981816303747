import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    footer: {
      width: '100vw',
      height: '66px',
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 40px',
      bottom: 0,
      left: 0,
      backgroundColor: '#fff',
      // boxShadow: '0px -9px 25px rgba(0, 0, 0, 0.3)',
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.8)',
      zIndex: 1201
    },
    button: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.05em'
    },
    downloadButtonMenu: {
      // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.30))',
    },
    downloadButtonMenuRoot: {
      padding: 0
    },
    downloadButtonMenuPaper: {
      display: 'inline-block',
      overflow: 'visible !important',
      filter: 'drop-shadow(0px -4px 18px rgba(0,0,0,0.20))',
      padding: 0
    },
    downloadButtonMenuList: {
      borderRadius: '8px',
      padding: 0
    },
    wrapDownloadOptions: {
      fontSize: '14px',
      lineHeight: '22px'
    },
    titleBold: {
      fontWeight: 600,
      color: '#1D1D1D'
    },
    descriptionOption: {
      fontWeight: 500,
      color: '#616161'
    }
  })
);
