import axios from 'axios';

import {
  ITurbochargedActionFormData,
  SelectedShareableItemProps
} from '../../../../modules/TabloideDigitalV2/reducer/types';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { asyncMap } from '../../../../utils/helpers/Functions';
import makeId from '../../../../utils/helpers/makeId';

interface IAttachedVideo {
  image_hash: string;
  link: string;
  video_id?: string;
}

interface IAttachedCard {
  image_hash: string;
  link: string;
}

type AttachedFilesType = IAttachedCard | IAttachedVideo;

type DistributionTurbochargedActionProps = {
  offerId?: number | undefined;
  offerName?: string | undefined;
  imageId?: number | undefined;
  imageName?: string | undefined;
  data: {
    offerId: number | undefined;
    offerName: string;
    imageId: number;
    imageName: string;
    imageUrl: string;
    imageType: string;
    selectedItems: SelectedShareableItemProps;
    selectionType: string;
  };
  values: ITurbochargedActionFormData;
};

const distributeTurbochargedAction = async ({
  data,
  values,
  ...props
}: DistributionTurbochargedActionProps) => {
  return new Promise(async (resolve, reject) => {
    try {
      let attachedFiles: [] | AttachedFilesType[] = [];
      let imagesHashes: any;
      let hash;

      let pageAdsDistribution = {
        page_id: '105559578146515',
        instagram_actor_id: '3618289208274064'
      };
      const { offerId, offerName, imageId, imageUrl, imageName, selectedItems } = data;
      const hasCarousel = selectedItems.length > 1;

      const isVideo = imageUrl ? imageUrl.endsWith('.mp4') : false;

      const ads_media: any = [];
      selectedItems.forEach(item => {
        ads_media.push({
          id: item.id,
          isVideo: item.link?.endsWith('.mp4') || false,
          url: item.link || item.image_url,
          ...(item.link?.endsWith('.mp4')
            ? { preview: item.image_url }
            : { preview: item.preview_url })
        });
      });

      let previewHash = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
        {
          isVideo: false,
          image_url:
            'https://s3.amazonaws.com/datasalesio-imagens/produto-imagem/new/logo_veja_essa_oferta.png'
        },
        'POST'
      ).then(resp => resp.content.hash);

      if (hasCarousel) {
        imagesHashes = await asyncMap(
          data.selectedItems,
          async (currentCard: any) =>
            await api(
              `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
              {
                isVideo: currentCard?.link ? true : false,
                image_url: currentCard?.link ? currentCard.link : currentCard.image_url
              },
              'POST'
            ).then(resp => resp.content.hash)
        );
      } else {
        hash = await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
          {
            isVideo: isVideo,
            image_url: imageUrl
          },
          'POST'
        ).then(resp => resp.content.hash);
      }

      attachedFiles =
        hasCarousel && imagesHashes
          ? data?.selectedItems.map((card, index) => {
              const cardHash = imagesHashes[index];

              if (!card.link)
                return {
                  image_hash: cardHash,
                  link: values.link
                };
              else
                return {
                  video_id: cardHash,
                  link: values.link,
                  image_hash: previewHash
                };
            })
          : [];

      const genders = values.gender === 'male' ? [1] : values.gender === 'female' ? [2] : [];

      const targeting = JSON.stringify({
        age_min: values.age_min,
        age_max: values.age_max,
        genders,
        publisher_platforms: ['facebook', 'instagram'],
        ...(values.interests &&
          values.interests.length > 0 && {
            flexible_spec: [
              {
                interests: values?.interests.map(interest => ({
                  id: interest.id,
                  name: interest.name
                }))
              }
            ]
          }),
        geo_locations: {
          custom_locations: [
            {
              latitude: String(values?.location?.latitude),
              longitude: String(values?.location?.longitude),
              radius: String(values?.location?.radius),
              distance_unit: 'kilometer'
            }
          ]
        }
      });

      const companyId = localStorage?.getItem('companyId');
      const name = `${companyId}_${offerId ? offerId : props?.offerId}_${
        offerName ? offerName : props?.offerName
      }${imageId ? `_${imageId}` : ''}${imageName ? `_${imageName}` : ''}${
        data.selectionType ? `_${data.selectionType}` : ''
      }_CARROSSEL_IMPULSIONAMENTO_${makeId(8)}`.toUpperCase();

      const token = JSON.parse(localStorage.getItem('token') as string);
      let pageAdsCompany = null;

      await axios
        .get(`https://api.datasales.info/ads-manager-${AMBIENTE}/ads/findfacebookpage`, {
          headers: { authorization: `${token}` }
        })
        .then(response => {
          pageAdsCompany = response.data.content.facebook_page_id;
        })
        .catch(err => {
          console.log('Err_Get_Axios_findFacebookPageADS=>', err.response.data);
        });
      if (pageAdsCompany) {
        await axios
          .get(
            `https://api.datasales.info/social-networks-v2-${AMBIENTE}/pages/validatepageads/${pageAdsCompany}`,
            { headers: { authorization: `${token}` } }
          )
          .then(response => {
            pageAdsDistribution.page_id = response.data.content.page_id;
            if (response.data.content.instagram_accounts) {
              pageAdsDistribution.instagram_actor_id = response.data.content.instagram_accounts;
            }
          })
          .catch(err => {
            console.log('CatchValidatePageAds', err.response.data);
          });
      }

      const adsManagerData = {
        campaign: {
          status: 'ACTIVE',
          special_ad_categories: [],
          objective: !hasCarousel ? values.objective.value : 'OUTCOME_AWARENESS',
          name
        },
        adSet: {
          start_time: values.start_time,
          end_time: values.end_time,
          bid_amount: 1000,
          billing_event: 'IMPRESSIONS',
          targeting,
          status: 'ACTIVE',
          page_id: pageAdsDistribution.page_id,
          name,
          lifetime_budget: values.lifetime_budget === 1000 ? 900 : values.lifetime_budget,
          optimization_goal: !hasCarousel
            ? values.objective.value === 'OUTCOME_AWARENESS'
              ? 'REACH'
              : values.objective.value === 'OUTCOME_TRAFFIC'
              ? 'LINK_CLICKS'
              : 'IMPRESSIONS'
            : 'REACH'
        },
        adCreative: {
          name,
          degrees_of_freedom_spec: {
            creative_features_spec: {
              standard_enhancements: {
                enroll_status: 'OPT_OUT'
              }
            }
          },
          object_story_spec: {
            page_id: pageAdsDistribution.page_id,
            instagram_actor_id: pageAdsDistribution.instagram_actor_id,
            ...(hasCarousel
              ? {
                  link_data: {
                    child_attachments: attachedFiles,
                    link: values.link
                  }
                }
              : isVideo
              ? {
                  video_data: {
                    image_url:
                      'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/273579519_306486794833299_1766943318475173688_n.png',
                    video_id: hash,
                    call_to_action: {
                      type: 'LEARN_MORE'
                    }
                  }
                }
              : {
                  link_data: {
                    name: offerName ? offerName : props?.offerName,
                    description: values.text,
                    call_to_action: {
                      type: 'LEARN_MORE'
                    },
                    image_hash: hash,
                    link: values.link,
                    message: values.text
                  }
                })
          }
        },
        user_id: 2,
        offer_id: imageId ? imageId : offerId ? offerId : props?.offerId,
        ads_media: ads_media
      };

      await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
        adsManagerData,
        'POST',
        null,
        null,
        false
      );
      resolve({ _service: 'turbocharged-action', message: 'success' });
    } catch (err: any) {
      reject({ _service: 'turbocharged-action', message: err?.message || 'fail', err });
    }
  });
};

export default distributeTurbochargedAction;
