import Typography from '@material-ui/core/Typography';
import { DistributionTypeProps } from 'hooks/api/distribution/useAdsDistribution';

import { useStyles } from '../../../styles';

type ButtonChannelMessageProps = {
  name: DistributionTypeProps;
};

export default function ButtonChannelMessage({ name }: ButtonChannelMessageProps) {
  const classes = useStyles();

  function selectMessage(name: DistributionTypeProps) {
    switch (name) {
      case 'social-networks':
        return `As publicações do tipo Redes Sociais ficarão visíveis no seu perfil.`;
      case 'turbocharged-action':
        return `Defina um endereço próximo da sua loja que será distribuída a sua oferta. Este tipo de distribuição não ficará salvo no perfil das suas redes sociais.`;
      case 'whatsapp':
        return `Envie ofertas como mensagens de Whatsapp para seus contatos e grupos de Whatsapp.`;
      case 'sms':
        return `Envie mensagens de SMS para seus contatos.`;
      case 'email':
        return `Envie mensagens de Email para seus contatos.`;
      case 'google':
        return `Expanda sua empresa com Remarketing Google.`;
      default:
        return `Adicionar Descrição`;
    }
  }

  return (
    <Typography className={classes.boxText} role="typography" data-testid="ButtonChannelMessage-component">
      {selectMessage(name)}
    </Typography>
  );
}
