import React, { createContext, useState, useContext, useCallback } from 'react';
import _ from 'underscore';
import api from '../utils/helpers/Fetch';
import {
  translateStatusSocialNetworks,
  translateStatusPaidFacebook,
  translateStatusSends,
  MoedaBrasil,
} from '../utils/helpers/Format';
import { AMBIENTE, DATASALES_CRM_API, API_SOCIAL_NETWORKS_V2 } from '../utils/helpers/Constants';
import useDate from './date';
import moment from 'moment';

const HomeContext = createContext({});

const HomeProvider = ({ children }) => {
  const initialSend = {
    wait: [],
    sent: [],
    received: [],
    canceled: [],
    erro: [],
    read: [],
  };

  const [loadingPaidCampaigns, setLoadingPaidCampaigns] = useState(false);
  const [loadingDetailCampaign, setLoadingDetailCampaign] = useState(false);
  const [insights, setInsights] = useState([]);
  // const [comments, setComments] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [paidCampaigns, setPaidCampaigns] = useState([]);
  const [countCampaigns, setCountCampaigns] = useState([]);

  // Facebook
  const [loadingGraphsFacebookCommentsData, setLoadingGraphsFacebookCommentsData] = useState(false);
  const [graphsFacebookCommentsData, setGraphsFacebookCommentsData] = useState([]);
  const [loadingGraphsFacebookLikesData, setLoadingGraphsFacebookLikesData] = useState(false);
  const [graphsFacebookLikesData, setGraphsFacebookLikesData] = useState([]);
  const [loadingGraphsFacebookViewsData, setLoadingGraphsFacebookViewsData] = useState(false);
  const [graphsFacebookViewsData, setGraphsFacebookViewsData] = useState([]);
  const [loadingGraphsFacebookEngajamentData, setLoadingGraphsFacebookEngajamentData] =
    useState(false);
  const [graphsFacebookEngajamentData, setGraphsFacebookEngajamentData] = useState([]);
  const [loadingGraphsFacebookUniqueViewsData, setLoadingGraphsFacebookUniqueViewsData] =
    useState(false);
  const [graphsFacebookUniqueViewsData, setGraphsFacebookUniqueViewsData] = useState([]);
  const [loadingGraphsFacebookPaidViewsData, setLoadingGraphsFacebookPaidViewsData] =
    useState(false);
  const [graphsFacebookPaidViewsData, setGraphsFacebookPaidViewsData] = useState([]);

  //Instagram
  const [loadingGraphsInstagramCommentsData, setLoadingGraphsInstagramCommentsData] =
    useState(false);
  const [graphsInstagramCommentsData, setGraphsInstagramCommentsData] = useState([]);
  const [loadingGraphsInstagramEngajamentData, setLoadingGraphsInstagramEngajamentData] =
    useState(false);
  const [graphsInstagramEngajamentData, setGraphsInstagramEngajamentData] = useState([]);
  const [loadingGraphsInstagramImpressionsData, setLoadingGraphsInstagramImpressionsData] =
    useState(false);
  const [graphsInstagramImpressionsData, setGraphsInstagramImpressionsData] = useState([]);
  const [loadingGraphsInstagramLikesData, setLoadingGraphsInstagramLikesData] = useState(false);
  const [graphsInstagramLikesData, setGraphsInstagramLikesData] = useState([]);
  const [loadingGraphsInstagramViewsData, setLoadingGraphsInstagramViewsData] = useState(false);
  const [graphsInstagramViewsData, setGraphsInstagramViewsData] = useState([]);

  //Geral
  const [loadingGraphsGeralLikesData, setLoadingGraphsGeralLikesData] = useState(false);
  const [graphsGeralLikesData, setGraphsGeralLikesData] = useState([]);
  const [loadingGraphsGeralViewsData, setLoadingGraphsGeralViewsData] = useState(false);
  const [graphsGeralViewsData, setGraphsGeralViewsData] = useState([]);
  const [loadingGraphsGeralEngajamentsData, setLoadingGraphsGeralEngajamentsData] = useState(false);
  const [graphsGeralEngajamentsData, setGraphsGeralEngajamentsData] = useState([]);
  const [loadingGraphsGeralImpressionsData, setLoadingGraphsGeralImpressionsData] = useState(false);
  const [graphsGeralImpressionsData, setGraphsGeralImpressionsData] = useState([]);
  const [loadingGraphsGeralCommentsData, setLoadingGraphsGeralCommentsData] = useState(false);
  const [graphsGeralCommentsData, setGraphsGeralCommentsData] = useState([]);

  //Geral Counts
  const [loadingGeralLikesCounterData, setLoadingGeralLikesCounterData] = useState(false);
  const [geralLikesCounterData, setGeralLikesCounterData] = useState(0);
  const [loadingGeralViewsCounterData, setLoadingGeralViewsCounterData] = useState(false);
  const [geralViewsCounterData, setGeralViewsCounterData] = useState(0);
  const [loadingGeralEngajamentsCounterData, setLoadingGeralEngajamentsCounterData] =
    useState(false);
  const [geralEngajamentsCounterData, setGeralEngajamentsCounterData] = useState(0);
  const [loadingGeralImpressionsCounterData, setLoadingGeralImpressionsCounterData] =
    useState(false);
  const [geralImpressionsCounterData, setGeralImpressionsCounterData] = useState(0);

  //Facebook Counts
  const [facebookLikesCounterData, setFacebookLikesCounterData] = useState(0);
  const [facebookViewsCounterData, setFacebookViewsCounterData] = useState(0);
  const [facebookEngajamentsCounterData, setFacebookEngajamentsCounterData] = useState(0);
  const [facebookImpressionsCounterData, setFacebookImpressionsCounterData] = useState(0);

  //Instagram Counts
  const [instagramLikesCounterData, setInstagramLikesCounterData] = useState(0);
  const [instagramViewsCounterData, setInstagramViewsCounterData] = useState(0);
  const [instagramEngajamentsCounterData, setInstagramEngajamentsCounterData] = useState(0);
  const [instagramImpressionsCounterData, setInstagramImpressionsCounterData] = useState(0);

  const [loadingGraphsWhatsapp, setLoadingGraphsWhatsapp] = useState(false);
  const [graphsWhatsapp, setGraphsWhatsapp] = useState(initialSend);
  const [numberWhatsapp, setNumberWhatsapp] = useState({
    total: 0,
    sent: 0,
    read: 0,
    received: 0,
  });
  const [loadingGraphsSMS, setLoadingGraphsSMS] = useState(false);
  const [graphsSMS, setGraphsSMS] = useState(initialSend);
  const [numberSMS, setNumberSMS] = useState({
    total: 0,
    clicks: 0,
    read: 0,
    received: 0,
  });
  const { getMoment } = useDate();
  const startDate = getMoment().subtract(1, 'month').format('YYYY-MM-DD');
  const endDate = getMoment().weekday(7).format('YYYY-MM-DD');

  const getPaidCampaigns = useCallback(async () => {
    try {
      setLoadingPaidCampaigns(true);
      const payload = { start_date: startDate, end_date: endDate };
      const response = await api(`${DATASALES_CRM_API}ads-manager-${AMBIENTE}/ads`, payload);
      setPaidCampaigns(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPaidCampaigns(false);
    }
  }, []);

  const getCampaigns = useCallback(async p => {
    const params = {
      nome_campanha: null,
      filtroDataInicio: startDate,
      filtroDataFim: endDate,
      ...p,
    };
    const payload = {
      grupoCampanha: 'CONCLUIDO',
      automacao: 0,
      ...(params.nome_campanha ? { nome_campanha: params.nome_campanha } : {}),
      filtroDataInicio: moment(params.filtroDataInicio).format('YYYY-MM-DD'),
      filtroDataFim: moment(params.filtroDataFim).add('1', 'days').format('YYYY-MM-DD'),
    };
    const response = await api(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/getPerformanceMes`,
      payload
    );
    setCampaigns(response.campanhas);
  }, []);

  const getCountCampaigns = useCallback(async () => {
    const payload = {};
    const response = await api(
      `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/goals/count/campaigns-this-week`,
      payload
    );
    setCountCampaigns(response.content);
  }, []);

  const getInsightsFacebook = useCallback(async campaign_id => {
    try {
      setLoadingDetailCampaign(true);
      const { content } = await api(
        `${API_SOCIAL_NETWORKS_V2}insights/facebook`,
        { campaign_id },
        'POST',
        null,
        null,
        false
      );

      if (content.insights.comments_count > 0) {
        await api(
          `${API_SOCIAL_NETWORKS_V2}comments/facebook`,
          { campaign_id },
          'POST',
          null,
          null,
          false
        );
        // setComments(response.content.comments);
      }
      delete content.insights.fb_post_id;
      delete content.insights.page_post_engagements;

      const status = Object.entries(content.insights).map(value => ({
        quantidade: value[1],
        status_envio_externo: translateStatusSocialNetworks(value[0]),
      }));
      setInsights(status);
    } catch (err) {
      setInsights([]);
    } finally {
      setLoadingDetailCampaign(false);
    }
  }, []);

  const getInsightsInstagram = useCallback(async campaign_id => {
    try {
      setLoadingDetailCampaign(true);
      const { content } = await api(
        `${API_SOCIAL_NETWORKS_V2}insights/instagram`,
        { campaign_id },
        'POST',
        null,
        null,
        false
      );

      if (content.insights.comments_count > 0) {
        await api(
          `${API_SOCIAL_NETWORKS_V2}comments/instagram`,
          { campaign_id },
          'POST',
          null,
          null,
          false
        );
        // setComments(response.content.comments);
      }
      delete content.insights.post_id_fk;
      delete content.insights.impressions;
      const status = Object.entries(content.insights).map(value => ({
        quantidade: value[1],
        status_envio_externo: translateStatusSocialNetworks(value[0]),
      }));
      setInsights(status);
    } catch (err) {
      setInsights([]);
    } finally {
      setLoadingDetailCampaign(false);
    }
  }, []);

  const getInsightsPaidFacebook = useCallback(async (ads_id, creative_id) => {
    try {
      setLoadingDetailCampaign(true);
      const { content } = await api(
        `${DATASALES_CRM_API}ads-manager-${AMBIENTE}/ads/insights/${ads_id}/${creative_id}`,
        {},
        'GET',
        null,
        null,
        false
      );
      delete content.overview.account_currency;
      delete content.overview.cost_per_inline_post_engagement;
      delete content.overview.cost_per_unique_click;
      delete content.overview.cpc;
      delete content.overview.cpm;
      delete content.overview.cpp;
      delete content.overview.date_start;
      delete content.overview.date_stop;
      delete content.overview.spend;
      delete content.overview.cost_per_inline_link_click;
      let overview = content.overview;

      overview.frequency = MoedaBrasil(overview.frequency);
      // overview.cost_per_inline_link_click = `R$ ${MoedaBrasil(
      //   overview.cost_per_inline_link_click
      // )}`;
      let age = content.age;
      let device = content.device;
      let gender = content.gender;
      let platforms = content.platforms;

      const status = Object.entries(content.overview).map(value => ({
        quantidade: value[1],
        status_envio_externo: translateStatusPaidFacebook(value[0]),
        age,
        device,
        gender,
        platforms,
      }));
      setInsights(status);
    } catch (err) {
      setInsights([]);
    } finally {
      setLoadingDetailCampaign(false);
    }
  }, []);

  const getInsightsSends = useCallback(async campaign_id => {
    try {
      setLoadingDetailCampaign(true);
      const { status } = await api(
        `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/getDesempenhoEnvio`,
        { idCampanha: campaign_id },
        'GET',
        null,
        null,
        false
      );

      setInsights(status);
    } catch (err) {
      setInsights([]);
    } finally {
      setLoadingDetailCampaign(false);
    }
  }, []);

  const getGraphsFacebookCommentsData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookCommentsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-comments`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookCommentsData(content);
    } catch (err) {
      setGraphsFacebookCommentsData([]);
    } finally {
      setLoadingGraphsFacebookCommentsData(false);
    }
  }, []);

  const getGraphsFacebookLikesData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookLikesData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-likes`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookLikesData(content);
      setFacebookLikesCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      setGraphsFacebookLikesData([]);
    } finally {
      setLoadingGraphsFacebookLikesData(false);
    }
  }, []);

  const getGraphsFacebookViewsData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookViewsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookViewsData(content);
      setFacebookViewsCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsFacebookViewsData([]);
    } finally {
      setLoadingGraphsFacebookViewsData(false);
    }
  }, []);

  const getGraphsFacebookEngajamentData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookEngajamentData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-engajament`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookEngajamentData(content);
      setFacebookEngajamentsCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsFacebookEngajamentData([]);
    } finally {
      setLoadingGraphsFacebookEngajamentData(false);
    }
  }, []);

  const getGraphsFacebookUniqueViewsData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookUniqueViewsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-unique-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookUniqueViewsData(content);
      setFacebookImpressionsCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsFacebookUniqueViewsData([]);
    } finally {
      setLoadingGraphsFacebookUniqueViewsData(false);
    }
  }, []);

  const getGraphsFacebookPaidViewsData = useCallback(async () => {
    try {
      setLoadingGraphsFacebookPaidViewsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/facebook-paid-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsFacebookPaidViewsData(content);
    } catch (err) {
      console.log(err);
      setGraphsFacebookPaidViewsData([]);
    } finally {
      setLoadingGraphsFacebookPaidViewsData(false);
    }
  }, []);

  const getGraphsInstagramCommentsData = useCallback(async () => {
    try {
      setLoadingGraphsInstagramCommentsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instagram-comments`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsInstagramCommentsData(content);
    } catch (err) {
      console.log(err);
      setGraphsInstagramCommentsData([]);
    } finally {
      setLoadingGraphsInstagramCommentsData(false);
    }
  }, []);

  const getGraphsInstagramEngajamentData = useCallback(async () => {
    try {
      setLoadingGraphsInstagramEngajamentData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instagram-engajament`,
        {},
        'GET',
        null,
        null,
        false
      );
      const counter = content
        .map(_cont => _cont.total)
        .reduce((previousValue, currentValue) => previousValue + currentValue.total, 0);

      setGraphsInstagramEngajamentData(content);
      setInstagramEngajamentsCounterData(counter);
    } catch (err) {
      console.log(err);
      setGraphsInstagramEngajamentData([]);
    } finally {
      setLoadingGraphsInstagramEngajamentData(false);
    }
  }, []);

  const getGraphsInstagramImpressionsData = useCallback(async () => {
    try {
      setLoadingGraphsInstagramImpressionsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instagram-impressions`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsInstagramImpressionsData(content);
      setInstagramImpressionsCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsInstagramImpressionsData([]);
    } finally {
      setLoadingGraphsInstagramImpressionsData(false);
    }
  }, []);

  const getGraphsInstagramLikesData = useCallback(async () => {
    try {
      setLoadingGraphsInstagramLikesData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instagram-likes`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsInstagramLikesData(content);
      setInstagramLikesCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsInstagramLikesData([]);
    } finally {
      setLoadingGraphsInstagramLikesData(false);
    }
  }, []);

  const getGraphsInstagramViewsData = useCallback(async () => {
    try {
      setLoadingGraphsInstagramViewsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instagram-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsInstagramViewsData(content);
      setInstagramViewsCounterData(
        content.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    } catch (err) {
      console.log(err);
      setGraphsInstagramViewsData([]);
    } finally {
      setLoadingGraphsInstagramViewsData(false);
    }
  }, []);

  const getGraphsWhatsapp = useCallback(async () => {
    try {
      setLoadingGraphsWhatsapp(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/campaigns-status-by-semester`,
        { status_type: 'WHATSAPP' },
        'GET',
        null,
        null,
        false
      );

      const sendNumbers = content
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);
      const sentNumber = content
        .filter(_resp => _resp.status === 'ENVIADO SEM CONFIRMACAO')
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);
      const receivedNumber = content
        .filter(_resp => _resp.status === 'ENVIADO COM CONFIRMACAO')
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);

      const readNumber = content
        .filter(_resp => _resp.status === 'MENSAGEM ABERTA')
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);

      let response = content.map(_cont => ({
        label: _cont.date,
        value: _cont.total,
        status: translateStatusSends(_cont.status),
      }));
      response = _.groupBy(response, _resp => _resp.status);
      const numbers = {
        total: sendNumbers,
        sent: sentNumber,
        read: readNumber,
        received: receivedNumber,
      };
      setNumberWhatsapp(numbers);
      setGraphsWhatsapp({ ...graphsWhatsapp, ...response });
    } catch (err) {
      console.log(err);
      setGraphsWhatsapp(initialSend);
    } finally {
      setLoadingGraphsWhatsapp(false);
    }
  }, []);

  const getGraphsSMS = useCallback(async () => {
    try {
      setLoadingGraphsSMS(true);
      const sms = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/campaigns-status-by-semester`,
        { status_type: 'SMS' },
        'GET',
        null,
        null,
        false
      );
      const email = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/campaigns-status-by-semester`,
        { status_type: 'SMS' },
        'GET',
        null,
        null,
        false
      );

      const payload = {};
      let response = [...sms.content, ...email.content];
      const sendNumbers = response
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);
      const clicksNumber = response
        .filter(_resp => _resp.status === 'MENSAGEM CLICADA')
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);
      const receivedNumber = response
        .filter(
          _resp => _resp.status === 'ENVIADO COM CONFIRMACAO' || _resp.status === 'CONFIRMADO'
        )
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);

      const readNumber = response
        .filter(_resp => _resp.status === 'MENSAGEM ABERTA' || _resp.status === 'MENSAGEM ENTREGUE')
        .map(_resp => _resp.total)
        .reduce((previous, current) => previous + current, 0);

      response = response.map(_cont => ({
        label: _cont.date,
        value: _cont.total,
        status: translateStatusSends(_cont.status),
      }));
      response = _.groupBy(response, _resp => _resp.status);
      Object.entries(response).map(_resp => {
        let values = _.groupBy(_resp[1], _value => _value.label);
        values = Object.entries(values).map(_value => ({
          label: _value[0],
          value: _value[1]
            .map(({ value }) => value)
            .reduce((previous, current) => previous + current, 0),
        }));
        Object.assign(payload, { [_resp[0]]: values });
      });
      const numbers = {
        total: sendNumbers,
        clicks: clicksNumber,
        read: readNumber,
        received: receivedNumber,
      };
      setNumberSMS(numbers);
      setGraphsSMS({ ...graphsSMS, ...payload });
    } catch (err) {
      console.log(err);
      setGraphsSMS(initialSend);
    } finally {
      setLoadingGraphsSMS(false);
    }
  }, []);

  const getGraphsGeralLikesData = useCallback(async () => {
    try {
      setLoadingGraphsGeralLikesData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfacegraphicoverview-likes`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsGeralLikesData(content);
    } catch (err) {
      console.log(err);
      setGraphsGeralLikesData([]);
    } finally {
      setLoadingGraphsGeralLikesData(false);
    }
  }, []);

  const getGraphsGeralViewsData = useCallback(async () => {
    try {
      setLoadingGraphsGeralViewsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfacegraphicoverview-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsGeralViewsData(content);
    } catch (err) {
      console.log(err);
      setGraphsGeralViewsData([]);
    } finally {
      setLoadingGraphsGeralViewsData(false);
    }
  }, []);

  const getGraphsGeralEngajamentsData = useCallback(async () => {
    try {
      setLoadingGraphsGeralEngajamentsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfacegraphicoverview-engagaments`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsGeralEngajamentsData(content);
    } catch (err) {
      console.log(err);
      setGraphsGeralEngajamentsData([]);
    } finally {
      setLoadingGraphsGeralEngajamentsData(false);
    }
  }, []);

  const getGraphsGeralImpressionsData = useCallback(async () => {
    try {
      setLoadingGraphsGeralImpressionsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfacegraphicoverview-impressions`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsGeralImpressionsData(content);
    } catch (err) {
      console.log(err);
      setGraphsGeralImpressionsData([]);
    } finally {
      setLoadingGraphsGeralImpressionsData(false);
    }
  }, []);

  const getGraphsGeralCommentsData = useCallback(async () => {
    try {
      setLoadingGraphsGeralCommentsData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfacegraphicoverview-comments`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGraphsGeralCommentsData(content);
    } catch (err) {
      console.log(err);
      setGraphsGeralCommentsData([]);
    } finally {
      setLoadingGraphsGeralCommentsData(false);
    }
  }, []);

  const getGeralLikesCounterData = useCallback(async () => {
    try {
      setLoadingGeralLikesCounterData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfaceoverview-likes`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGeralLikesCounterData(content[0].total);
    } catch (err) {
      console.log(err);
      setGeralLikesCounterData([]);
    } finally {
      setLoadingGeralLikesCounterData(false);
    }
  }, []);

  const getGeralViewsCounterData = useCallback(async () => {
    try {
      setLoadingGeralViewsCounterData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfaceoverview-views`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGeralViewsCounterData(content[0].total);
    } catch (err) {
      console.log(err);
      setGeralViewsCounterData([]);
    } finally {
      setLoadingGeralViewsCounterData(false);
    }
  }, []);

  const getGeralEngajamentsCounterData = useCallback(async () => {
    try {
      setLoadingGeralEngajamentsCounterData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfaceoverview-engagaments`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGeralEngajamentsCounterData(content[0].total);
    } catch (err) {
      console.log(err);
      setGeralEngajamentsCounterData([]);
    } finally {
      setLoadingGeralEngajamentsCounterData(false);
    }
  }, []);

  const getGeralImpressionsCounterData = useCallback(async () => {
    try {
      setLoadingGeralImpressionsCounterData(true);
      const { content } = await api(
        `${DATASALES_CRM_API}advertising-dashboard-${AMBIENTE}/metrics/count/instfaceoverview-impressions`,
        {},
        'GET',
        null,
        null,
        false
      );

      setGeralImpressionsCounterData(content[0].total);
    } catch (err) {
      console.log(err);
      setGeralImpressionsCounterData([]);
    } finally {
      setLoadingGeralImpressionsCounterData(false);
    }
  }, []);

  return (
    <HomeContext.Provider
      value={{
        campaigns,
        paidCampaigns,
        countCampaigns,
        insights,
        graphsFacebookCommentsData,
        graphsFacebookLikesData,
        graphsFacebookViewsData,
        graphsFacebookEngajamentData,
        graphsFacebookUniqueViewsData,
        graphsFacebookPaidViewsData,
        graphsInstagramCommentsData,
        graphsInstagramEngajamentData,
        graphsInstagramImpressionsData,
        graphsInstagramLikesData,
        graphsInstagramViewsData,
        graphsWhatsapp,
        graphsSMS,
        graphsGeralLikesData,
        graphsGeralViewsData,
        graphsGeralEngajamentsData,
        graphsGeralImpressionsData,
        graphsGeralCommentsData,
        geralLikesCounterData,
        geralViewsCounterData,
        geralEngajamentsCounterData,
        geralImpressionsCounterData,
        numberSMS,
        numberWhatsapp,
        loadingPaidCampaigns,
        loadingDetailCampaign,
        loadingGraphsFacebookCommentsData,
        loadingGraphsFacebookLikesData,
        loadingGraphsFacebookViewsData,
        loadingGraphsFacebookEngajamentData,
        loadingGraphsFacebookUniqueViewsData,
        loadingGraphsFacebookPaidViewsData,
        loadingGraphsInstagramCommentsData,
        loadingGraphsInstagramEngajamentData,
        loadingGraphsInstagramImpressionsData,
        loadingGraphsInstagramLikesData,
        loadingGraphsInstagramViewsData,
        loadingGraphsWhatsapp,
        loadingGraphsSMS,
        loadingGraphsGeralLikesData,
        loadingGraphsGeralViewsData,
        loadingGraphsGeralEngajamentsData,
        loadingGraphsGeralImpressionsData,
        loadingGraphsGeralCommentsData,
        loadingGeralLikesCounterData,
        loadingGeralViewsCounterData,
        loadingGeralEngajamentsCounterData,
        loadingGeralImpressionsCounterData,
        facebookLikesCounterData,
        facebookViewsCounterData,
        facebookEngajamentsCounterData,
        facebookImpressionsCounterData,
        instagramLikesCounterData,
        instagramViewsCounterData,
        instagramEngajamentsCounterData,
        instagramImpressionsCounterData,
        getCampaigns,
        getPaidCampaigns,
        getCountCampaigns,
        getInsightsFacebook,
        getInsightsPaidFacebook,
        getInsightsInstagram,
        getInsightsSends,
        getGraphsFacebookCommentsData,
        getGraphsFacebookLikesData,
        getGraphsFacebookViewsData,
        getGraphsFacebookEngajamentData,
        getGraphsFacebookUniqueViewsData,
        getGraphsFacebookPaidViewsData,
        getGraphsInstagramCommentsData,
        getGraphsInstagramEngajamentData,
        getGraphsInstagramImpressionsData,
        getGraphsInstagramLikesData,
        getGraphsInstagramViewsData,
        getGraphsWhatsapp,
        getGraphsSMS,
        getGraphsGeralLikesData,
        getGraphsGeralViewsData,
        getGraphsGeralEngajamentsData,
        getGraphsGeralImpressionsData,
        getGraphsGeralCommentsData,
        getGeralLikesCounterData,
        getGeralViewsCounterData,
        getGeralEngajamentsCounterData,
        getGeralImpressionsCounterData,
      }}>
      {children}
    </HomeContext.Provider>
  );
};

function useHome() {
  const context = useContext(HomeContext);

  if (!context) {
    throw new Error('useHome must be used within an HomeProvider ');
  }
  return context;
}

export { HomeProvider, useHome };
