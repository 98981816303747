import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Typography,
  CircularProgress,
  Divider,
  withStyles,
  Avatar,
  // Tooltip
} from '@material-ui/core';
// import { Link } from "react-router-dom";
import { getSolicitacoes } from '../action/index';
import { Snackbar, Panel, DatePicker } from '../../../utils/components';

import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ModalDetalhes from './ModalDetahes';

class Solicitacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      performanceDetalhes: [],
      key: 0,
      dataInicio: moment().subtract('1', 'month'),
      dataFim: moment(),
      showSnackbar: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.getSolicitacoes();
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  render() {
    const {
      classes,
      performances,
      loading_recomendacoes,
      loading_xlsx,
    } = this.props;
    const { dataInicio, dataFim, showModal } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.label} variant="h4" noWrap>
          Performance
        </Typography>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  // onChange={date =>
                  //   this.onChangePeriodo("filtroDataInicio", date)
                  // }
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataFim}
                  format="DD/MM/YYYY"
                  // onChange={date => this.onChangePeriodo("filtroDataFim", date)}
                />
              </div>
            </div>
          </section>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.content}>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20
            }}
          >
            <Button
              onClick={() => this.setState({ showModal: true })}
              size="large"
            >
              Criar Tabloide Digital
            </Button>
          </div> */}
        </div>

        {!performances || loading_recomendacoes ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {performances &&
              performances.map((item, index) => (
                <Panel
                  key={index}
                  title={`${item.id_performance} - ${item.nome}`}
                  noDivider
                  // onClick={() =>
                  //   this.setState({ tabloide: item, showModalEdit: true })
                  // }
                  avatar={
                    <Avatar className={classes.avatar}>
                      <MultilineChartIcon />
                    </Avatar>
                  }
                  cardActions={
                    <Fragment>
                      <Button
                        size="small"
                        disabled={loading_xlsx}
                        onClick={() =>
                          this.setState({
                            showModal: true,
                            performanceDetalhes: item.detalhes,
                          })
                        }
                      >
                        Detalhes
                      </Button>
                      {/* <Button
                        size="small"
                        disabled={loading_xlsx}
                        onClick={() => window.open(item.link_pdf)}
                      >
                        Download PDF
                      </Button> */}
                    </Fragment>
                  }
                  width="49%"
                  subheader={`Criação ${this.formatDate(item.data_insert)} `}
                  style={{ marginBottom: 20 }}
                ></Panel>
              ))}
          </div>
        )}

        <Snackbar
          variant="success"
          message="Texto copiado"
          open={this.state.showSnackbar}
          onClose={() => this.setState({ showSnackbar: false })}
        />
        {showModal && (
          <ModalDetalhes
            open={showModal}
            performanceDetalhes={this.state.performanceDetalhes}
            // download={() => this.props.getXlsx(recomendacao)}
            onClose={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flex: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    display: 'flex',
    flexGrow: 1,
    flex: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(2),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  input: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderStyle: 'dashed',
    borderColor: '#727272',
    borderWidth: 0.8,
    borderRadius: 5,
    padding: 10,
    color: '#424242',
    fontSize: 14,
  },
});

const mapStateToProps = ({ SolicitacoesReducer }) => ({
  ...SolicitacoesReducer,
});

const mapDispatchToProps = {
  getSolicitacoes,
};

const SolicitacoesConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Solicitacoes);

export default withStyles(styles)(SolicitacoesConnect);
