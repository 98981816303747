import { createStyles, makeStyles } from '@material-ui/styles';


const color1 = '#616161';
const color2 = '#FFFFFF';
const color3 = '#2813AD';

export const useStyles = makeStyles(theme =>
  createStyles({
    versionOption: {
      height: 'auto',
      width: '100vw',
      position: 'absolute',
      background: '#F4F3FB',
      color: color1,
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '26px',
      letterSpacing: '0.05em',
      zIndex: 1000
    },
    elementImg: {
      position: 'absolute',
      width: 611,
      height: 574,
      left: 0,
      top: 438
    },
    cardHeader: {
      display: 'flex',
      gap: 8,
      flexDirection: 'column',
      alignItems: 'center',
      width: 796,
      textAlign: 'center'
    },
    cardInfo: {
      display: 'flex',
      justifyContent: 'center'
    },
    cardItem: {
      padding: 32,
      border: '1px solid #CFCFCF',
      borderRadius: '8px',
      width: 484,
      position: 'relative',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
    cardList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8
    },
    cardMainContent: {
      justifyContent: 'center',
      gap: 80,
      padding: '40px 0 141.44px'
    },
    oldPortal: {
      //height: 568,
      background: '#FFFFFF'
    },
    newPortal: {
      background: '#312A5E',
      color: color2,
      boxShadow: '0px 25px 45px rgba(40, 19, 173, 0.3)'
    },
    button: {
      width: '420px',
      height: '44px',
      borderRadius: '4px',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      padding: '12px 16px',

      '&:hover': {
        color: '#FFFFFF'
      }
    },
    btnOldPortal: {
      color: color3,
      border: '1px solid #2813AD',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.05em'
    },
    btnNewPortal: {
      color: '#2813AD',
      backgroundColor: '#FFFFFF',
      fontSize: '16px',
      lineHeight: '20px',

      letterSpacing: '0.05em'
    },
    cardRecomend: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 4,
      gap: 10,
      width: 138,
      height: 30,
      background: '#E79831'
    },
    cardIconLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16
    },
    title: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '32px',
      color: color1
    },
    cardWarning:{
      background: '#B22222',
      color: 'white',
      padding: '0.5rem',
      borderRadius: '5px'
    }
  })

);