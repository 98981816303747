import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  SmsRounded,
  EmailRounded,
  DraftsRounded,
  Facebook,
  Instagram,
  LinkedIn,
  WhatsApp,
  SendRounded,
  DonutLargeRounded
} from '@material-ui/icons';
import Details from './components/Details';
import useStyles from './styles';
import { SplitButtonIcon } from '../../../../utils/components';
import { BG_PRIMARY } from '../../../../utils/helpers/Constants';
import { MoreVert } from '@material-ui/icons';
import MyDate from '../../../../utils/helpers/Date';

const _myDate = new MyDate();

export default function Campaign({
  campaigns,
  loading,
  searchCampaign,
  searchInitDate,
  searchEndDate,
  searchAutomation,
  pauseCampanha,
  continueCampanha
}) {
  const classes = useStyles(),
    [details, setDetails] = useState(false),
    [page, setPage] = useState(1);

  function onPause(campanha) {
    const request = {
      filtroDataInicio: _myDate.getDate('YYYY-MM-DD', searchInitDate),
      filtroDataFim: _myDate.getDate('YYYY-MM-DD', searchEndDate),
      automacao: searchAutomation,
      nome_campanha: searchCampaign
    };

    pauseCampanha(campanha.ID_CAMPANHA, request);
  }

  function onContinue(campanha) {
    const request = {
      filtroDataInicio: _myDate.getDate('YYYY-MM-DD', searchInitDate),
      filtroDataFim: _myDate.getDate('YYYY-MM-DD', searchEndDate),
      automacao: searchAutomation,
      nome_campanha: searchCampaign
    };

    continueCampanha(campanha.ID_CAMPANHA, request);
  }

  function _subheader(status) {
    switch (status) {
      case 1:
      case 0:
        return 'Processando';
      case 2:
        return 'Processado';
      case 3:
        return 'Pausado';
      default:
        return '';
    }
  }

  function _avatar(type) {
    switch (String(type).toUpperCase()) {
      case 'EMAIL':
        return <EmailRounded />;
      case 'SMS':
        return <SmsRounded />;
      case 'CARTA':
        return <DraftsRounded />;
      case 'FACEBOOK':
        return <Facebook />;
      case 'INSTAGRAM':
      case 'INSTAGRAM_CAROUSEL':
        return <Instagram />;
      case 'LINKEDIN':
        return <LinkedIn />;
      case 'WHATSAPP':
        return <WhatsApp />;
      case 'WHATSAPP_STATUS':
        return <DonutLargeRounded />;
      default:
        return <SendRounded />;
    }
  }

  function panelActions(campanha) {
    return (
      <Box>
        {/* {(campanha.tipo_campanha === 'SMS' ||
          campanha.tipo_campanha === 'EMAIL') && (
          <Button
            size="large"
            className={classes.button}
            style={{ color: COLOR_PRIMARY }}
            variant="text"
            color="primary"
            onClick={() => setOpenClickRate(campanha.ID_CAMPANHA)}
          >
            Taxa de cliques
          </Button>
        )} */}

        <Tooltip title="Mais ações" placement="top">
          <SplitButtonIcon icon iconcomponent={<MoreVert />} style={{ color: BG_PRIMARY }}>
            {(campanha.processado === 0 || campanha.processado === 1) && (
              <MenuItem
                onClick={() => onPause(campanha)}
                style={{ borderRadius: 10, width: '100%' }}
              >
                Pausar campanha
              </MenuItem>
            )}
            {campanha.processado === 3 && (
              <MenuItem
                onClick={() => onContinue(campanha)}
                style={{ borderRadius: 10, width: '100%' }}
              >
                Continuar campanha
              </MenuItem>
            )}
            {campanha.processado === 2 && (
              <Typography align="center" variant="subtitle" noWrap>
                Campanha concluída, não é possível pausar o envio.
              </Typography>
            )}
          </SplitButtonIcon>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box className={classes.content}>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12} style={{ display: 'flex' }} justify="center" alignItems="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          Boolean(campaigns.length) &&
          campaigns.slice(0, page * 50).map(campaign => (
            <Grid key={campaign.ID_CAMPANHA} item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Tooltip title={campaign.tipo_campanha} placement="right">
                        {_avatar(campaign.tipo_campanha)}
                      </Tooltip>
                    </Avatar>
                  }
                  title={`#${campaign.ID_CAMPANHA} - ${campaign.nome_campanha}`}
                  subheader={`Status: ${_subheader(campaign.processado)}`}
                />
                <Divider />
                <CardActions className={classes.actions}>
                  {panelActions(campaign)}

                  <Button size="small" color="primary" onClick={() => setDetails(campaign)}>
                    Detalhes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <Grid style={{ textAlign: 'center', marginTop: 20 }}>
        <Button variant="text" color="primary" onClick={() => setPage(prev => prev + 1)}>
          Mostrar mais
        </Button>
      </Grid>
      <Details details={details} open={Boolean(details)} onClose={() => setDetails(false)} />
    </Box>
  );
}

