import { AppBar, Backdrop, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';

import DSAWS from '../../../../utils/helpers/DSAWS';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 250,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  },
  appBar: { background: 'transparent' },
  vid: {
    height: '80%'
  },
  title: {
    flex: 1
  }
}));

const DownloadVideo = ({ open, imageName, videoName, videoUrl, onClose }) => {
  const classes = useStyles();

  const handleDownloadImage = async () => {
    DSAWS.getObjectGeral({
      link: videoUrl
    })
      .then(response => {
        var link = document.createElement('a');
        link.href = response;
        link.download = `${videoName}.mp4`;

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch(error => {});
  };

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="body1">
            {videoName}
          </Typography>
          <IconButton color="inherit" onClick={handleDownloadImage}>
            <GetAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <img className={classes.img} src={imageUrl} alt={imageName} /> */}
      <video
        className={classes.vid}
        //width="250"
        controls
        loop
        autoplay="autoplay"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </Backdrop>
  );
};

export default DownloadVideo;

