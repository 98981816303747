import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import DialogTitle from '../../../../../../utils/components/DialogTitle';

const schema = Yup.object().shape({
  title: Yup.string().required('O nome e obrigatorio'),
});

function CreateAccount({ open, values, onSubmit, onClose }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Editar perfil</DialogTitle>
      <Divider />
      <DialogContent>
        <Form
          initialValues={values}
          validate={makeValidate(schema)}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form id="form" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField name="title" label="Nome" variant="outlined" />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            document
              .getElementById('form')
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateAccount;
