import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dashboardText: {
    width: '100%',
    padding: '2rem',
    fontSize: '1.5rem',
  },
  containerChat: {
    padding: theme.spacing(7, 3, 1),
  },
  informationTabContainer: {
    paddingTop: '16px',
    marginBottom: theme.spacing(3),
  },
  root: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(3),
  },
  container: {
    width: '100%',
    padding: '0',
    padding: theme.spacing(7, 3, 1),
  },
  card: { width: '100%', maxHeight: '86vh' },
  cardContent: { padding: 0, height: '100%' },
  header: {
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 75,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chatHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 75,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  chatsList: {
    maxHeight: 'calc(100vh - 256px)',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  chatBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '.5vw',
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  chatBarBorder: {
    borderRight: '.5px solid rgba(0, 0, 0, 0.12)',
  },
  avatarLgContact: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  avatarLg: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  avatarLgItem: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: '0 1vw 0 .8vw',
    backgroundColor: theme.palette.primary.main,
  },
  iconNewMessage: {
    fontSize: '1.3rem',
    color: '#919191',
  },
  messages: {
    backgroundColor: '#FFF',
  },
  backgrounsMessages: {
    height: '66vh',
    width: '100%',
    backgroundImage: `url(https://datasalesio-imagens.s3.amazonaws.com/bgChatDatasales.jpeg)`,
  },
  rootWriteMessage: {
    display: 'flex',
    padding: '.5vw 0',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  inputMessage: {
    backgroundColor: '#fff',
    padding: '1.5vh 2vw',
    margin: '1vh 0',
    borderRadius: '20px',
  },
  inputSearchChats: {
    backgroundColor: '#fff',
    padding: '.5vh .5vw',
    borderRadius: '20px',
    margin: '1vh 0',
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
    listStyleType: 'none',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  shortMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  loadMessages: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2vh',
    alignItems: 'center',
  },
  loadMessagesChats: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2vh 0',
    alignItems: 'center',
  },
  rootSearhContact: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  inputSearhContact: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  paper: { width: '100%' },
  imagePaper: {
    maxWidth: '100%',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginLeft: '1vw',
    marginRight: '1vw',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  headerContact: { color: theme.palette.primary.main, backgroundColor: '#FFF' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  appBar: { background: 'transparent' },
  avatarBackdrop: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  fab: {
    marginTop: '1vh',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '.05em',
  },
  tab: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    textTransform: 'none',
    opacity: 1,
  },
  tabActive: {
    backgroundColor: '#E8E8FD',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    textTransform: 'none',
    opacity: 1,
  },
  textAlert: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
  },
  menuInstance: { marginTop: theme.spacing(7), maxWidth: theme.spacing(50) },
  paperMenuInstance: {
    padding: theme.spacing(2, 4),
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.25)',
  },
  btnInstance: {
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 2),
  },
  containerNumbersAnalytics: {
    padding: theme.spacing(2.5),
    borderRadius: '5px',
    backgroundColor: '#F5F5F5',
  },
}));

export default useStyles;
