import Alert from '../../../utils/helpers/Alert';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import { toBase64 } from '../../../utils/helpers/Functions';
import makeId from '../../../utils/helpers/makeId';
import buildTemplate from '../helpers/BuildTemplate';

const _alert = new Alert();

const isLoadingPostTabloide = () => dispatch => dispatch({ type: 'LOAD_POST_TABLOIDE' });

const isLoadingPostTabloideLink = () => dispatch => dispatch({ type: 'LOAD_POST_TABLOIDE_LINK' });

const isLoadingPostFile = () => dispatch => dispatch({ type: 'LOAD_POST_FILE' });

const isLoadingTabloides = () => dispatch => dispatch({ type: 'LOAD_TABLOIDES' });

const isLoadingTabloideFisico = () => dispatch => dispatch({ type: 'LOAD_TABLOIDE_FISICO' });

const isLoadingTabloidsTemplatesFisico = () => dispatch =>
  dispatch({ type: 'LOAD_TABLOIDES_TEMPLATE_FISICO' });

const isLoadingTabloidsEtiquetas = () => dispatch => dispatch({ type: 'LOAD_TABLOIDES_ETIQUETAS' });

const isLoadingDetalhes = () => dispatch => dispatch({ type: 'LOAD_DETALHES' });

const isLoadingAutoMessage = () => dispatch => dispatch({ type: 'LOAD_AUTO_MESSAGE' });

export const setProdutosLength = data => dispatch =>
  dispatch({ type: 'SET_PRODUCTS_LENGTH', payload: data });

export const editProdutoTabloide = (payload, callback) => dispatch => {
  if (callback) {
    dispatch({
      type: 'POST_FILE',
      payload
    });
    callback();
  }
};

export const setTabloide = data => dispatch => {
  dispatch({ type: 'SET_TABLOIDE', payload: data });
};

export const setTemplate = data => dispatch =>
  dispatch({ type: 'SET_TEMPLATE', payload: buildTemplate(data) });

export const setJson = (id, json) => dispatch =>
  dispatch({ type: 'SET_JSON', payload: { json, id } });
export const set_editor = editor => ({
  type: 'SET_EDITOR',
  payload: editor
});

export const changeCategoriaIndex = payload => ({
  type: 'CHANGE_CATEGORIA_INDEX',
  payload: payload
});

export const changeCategoriaIndexSubtract = payload => ({
  type: 'CHANGE_CATEGORIA_INDEX_SUBTRACT',
  payload: payload
});

export const setSelectedShareableItem = payload => {
  if (payload && Array.isArray(payload)) {
    payload = payload.map(item => ({
      ...item,
      image_url: item.image_url ? item.image_url.split('?')[0] + '?' + makeId(6) : null
    }));
    // console.log({ 'action.payload: ': payload });
  }
  return {
    type: 'SET_SELECTED_SHAREABLE_ITEM',
    payload: payload
  };
};

export const clearSelectedShareableItem = () => ({
  type: 'CLEAR_SELECTED_SHAREABLE_ITEM'
});

export const setIsDistributionOpened = payload => ({
  type: 'SET_IS_DISTRIBUTION_OPENED',
  payload: payload
});

export const setAdsDistributionSelectedChannels = payload => ({
  type: 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS',
  payload: payload
});

export const setAdsDistributionSelectedChannelsData = payload => ({
  type: 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA',
  payload: payload
});

export const setAdsDistributionSelectedChannelsDataAdd = payload => ({
  type: 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA_ADD',
  payload: payload
});

export const setAdsDistributionSelectedChannelsDataRemove = payload => ({
  type: 'SET_ADS_DISTRIBUTION_SELECTED_CHANNELS_DATA_REMOVE',
  payload: payload
});

export const setAdsDistributionCurrentTab = payload => ({
  type: 'SET_ADS_DISTRIBUTION_CURRENT_TAB',
  payload: payload
});

export const setAdsDistributionCurrentChannel = payload => ({
  type: 'SET_ADS_DISTRIBUTION_CURRENT_CHANNEL',
  payload: payload
});

export const setAdsDistributionValidateForm = payload => ({
  type: 'SET_ADS_DISTRIBUTION_VALIDATE_FORM',
  payload: payload
});

export const setAdsDistributionIsValidatingForm = payload => ({
  type: 'SET_ADS_DISTRIBUTION_IS_VALIDATING_FORM',
  payload: payload
});

export const setFrequenceScheduledPosts = payload => ({
  type: 'SET_FREQUENCE_SCHEDULED_POSTS',
  payload: payload
});

export const clearFrequenceScheduledPosts = payload => ({
  type: 'CLEAR_FREQUENCE_SCHEDULED_POSTS',
  payload: payload
});

export const setFlyersToSelectWhenRedirectToOffer = payload => ({
  type: 'SET_FLYERS_TO_SELECT_WHEN_REDIRECT_TO_OFFER',
  payload: payload
});

// CHECKPOINT
export const setSelectedScheduledItems = payload => {
  return {
    type: 'SET_SELECTED_SCHEDULED_ITEMS',
    payload: payload
  };
};

export const setSelectedPostsRepeat = payload => {
  return {
    type: 'SET_SELECTED_POSTS_REPEAT',
    payload: payload
  };
};

export const clearSelectedPostsRepeat = () => {
  return {
    type: 'CLEAR_SELECTED_POSTS_REPEAT'
  };
};

export const clearSelectedScheduledItems = () => ({
  type: 'CLEAR_SELECTED_SCHEDULED_ITEMS'
});

export const clearAdsDistribution = () => ({
  type: 'CLEAR_ADS_DISTRIBUTION'
});

export const initLoadingAdsDistributionAdd = () => ({
  type: 'INIT_LOADING_ADS_DISTRIBUTION_ADD'
});

export const finishLoadingAdsDistributionAdd = () => ({
  type: 'FINISH_LOADING_ADS_DISTRIBUTION_ADD'
});

export const getTabloides = () => async dispatch => {
  dispatch(isLoadingTabloides());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`
  ).then(payload => dispatch({ type: 'GET_TABLOIDES', payload }));
};

export const getDetalhesTabloide = id => async dispatch => {
  dispatch(isLoadingDetalhes());

  Fetch(`${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getDetalhesTabloide`, {
    id
  }).then(payload => dispatch({ type: 'GET_DETALHES', payload }));
};

export const postFile = file => async dispatch => {
  dispatch(isLoadingPostFile());
  const xlsx = await toBase64(file);
  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecebeArquivo`,
    { ...xlsx },
    'POST'
  ).then(payload =>
    dispatch({
      type: 'POST_FILE',
      payload: {
        ...payload,
        lista_produtos: payload.lista_produtos.map(dt => ({
          ...dt,
          produto_ativo: 1
        }))
      }
    })
  );
};

export const postTabloideDigital = (data, callback) => async dispatch => {
  dispatch(isLoadingPostTabloide());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
    data,
    'POST'
  )
    .then(payload => dispatch({ type: 'POST_TABLOIDE', payload: payload.id }))
    .then(() => dispatch(getTabloides()));
  callback && callback();
};

export const postTabloideLink = data => async dispatch => {
  dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigitalLink`,
    data,
    'PUT'
  ).then(payload => {
    dispatch({ type: 'POST_TABLOIDE_LINK', payload: payload.link_tabloide });
  });
};

export const postProdutoImagemTabloide = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await Fetch(
        'https://thumbnail-flyers.azurewebsites.net/flyers/shot-bg',
        { ...data, notResize: true },
        'POST',
        null,
        null,
        false
      );
      dispatch({ type: 'PRODUTO_IMAGEM', payload: response });
      resolve(response);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const putEditTabloide = data => async dispatch => {
  // dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
    { tabloide: data },
    'PUT'
  )
    .then(payload => {
      dispatch({ type: 'POST_TABLOIDE_LINK', payload: payload.link_tabloide });
    })
    .then(() => dispatch(getTabloides()));
};

export const putProduto = data => async dispatch => {
  // dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
    { produto: data },
    'PUT'
  ).then(payload => {
    dispatch(getDetalhesTabloide(data.id_tabloide_digital));
  });
};

export const removeTabloide = tabloide => async dispatch =>
  _alert.alertConfirm(`Deseja excluir o tabloide ${tabloide.nome_tabloide_digital} ?`, async () => {
    try {
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/removeTabloide`,
        { idTabloide: tabloide.id_tabloide_digital },
        'PUT'
      );
      dispatch(getTabloides());
    } catch (err) {
      console.log(err);
    }
  });

export const putOfertaFisico = data => async dispatch => {
  try {
    /* dispatch(isLoadingTabloideFisico()); */
    dispatch({ type: 'CLEAN_PAGINAS' });
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postEncarteFisicoPixe`,
      data,
      'POST',
      null,
      null,
      false
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const getOfertaFisico = (data, times) => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getEncarteFisico`, data).then(
      payload => {
        let again = false;
        payload.forEach(e => {
          if (!((e.link && e.link_html) || e.error)) {
            again = true;
          }
        });
        dispatch({ type: 'GET_OFERTA_FISICO', payload: payload });
        if (again && times < 20) {
          setTimeout(function () {
            dispatch(getOfertaFisico(data, times + 1));
          }, 5 * 1000);
        } else {
          dispatch({ type: 'FINISH_TABLOIDE_FISICO' });
        }
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getOfertaFisicoAll =
  (data, loading = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloideFisico());
      const payload = await Fetch(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getEncarteFisico`,
        data,
        'GET',
        null,
        null,
        false
      );

      dispatch({ type: 'GET_OFERTA_FISICO_ALL', payload: payload });
      if (loading)
        dispatch({
          type: 'GET_OFERTA_FISICO_ALL_ZERA_CATEGORIA_INDEX',
          payload: payload
        });
      dispatch({ type: 'FINISH_TABLOIDE_FISICO' });
    } catch (err) {
      console.log(err);
    }
  };

export const getProductPhoto = data => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getProductPhoto`, data).then(
      payload => {
        dispatch({ type: 'GET_IMAGEM_PRODUTO', payload: payload });
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getProduct = data => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getProduct`, data).then(
      payload => {
        dispatch({ type: 'GET_PRODUCT_ADD', payload: payload.response });
        return payload;
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const cleanProductsForAdd = () => dispatch => dispatch({ type: 'CLEAN_PRODUCTS_FOR_ADD' });

export const getTabloidsTemplatesFisico =
  (data, loading = true, all = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloidsTemplatesFisico());
      if (all)
        data = {
          ...data,
          all: all
        };

      const payload = await Fetch(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getTabloidsTemplate`,
        data,
        'GET',
        null,
        null,
        false
      );

      dispatch({
        type: 'GET_TABLOIDS_TEMPLATES_FISICO',
        payload: payload.response
      });

      return payload;
    } catch (err) {
      console.log(err);
      throw new Error('ERROR');
    }
  };

export const getTabloidsTemplateById = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getTabloidsTemplate`,
      data
    ).then(payload => {
      return payload.response;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPesquisaSerpApi = data => async dispatch => {
  try {
    //dispatch(isLoadingTabloidsTemplatesFisico());
    return await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getSerpApi`, data).then(
      payload => {
        //dispatch({ type: 'GET_TABLOIDS_TEMPLATES_FISICO', payload: payload.response });
        return payload.images_results;
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const postTabloidsTemplate = data => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postTabloidsTemplate`,
      {
        ...data
      },
      'POST'
    );
  } catch (err) {
    console.log(err);
  }
};

export const deleteEncarteFisico = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/deleteEncarteFisico`,
      data,
      'DELETE',
      null,
      null,
      false
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const createTemplatePersonalizado = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postHerokuInsert`,
      data,
      'POST',
      null,
      null,
      false
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const addTabloidsTemplate = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/addTabloidsTemplate`,
      data,
      'POST'
    ).then(payload => {
      return 1;
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getTabloidsEtiquetas =
  (data, loading = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloidsEtiquetas());
      return await Fetch(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getTabloidsEtiquetas`,
        data
      ).then(payload => {
        dispatch({ type: 'GET_TABLOIDS_ETIQUETAS', payload: payload.response });
        return payload;
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export const addTabloidsEtiqueta = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/addTabloidsEtiqueta`,
      data,
      'POST'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getVideomatik = data => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getVideomatik`,
      data,
      'GET',
      null,
      null,
      false
    );

    return payload;
  } catch (err) {
    console.log(err);
    return {
      response: [],
      templates: []
    };
  }
};

export const postVideomatik = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postVideomatik`,
      data,
      'POST'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateSequence = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/updateSequence`,
      data,
      'PUT',
      null,
      null,
      false
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const v2_postEncarteFisicoPixeMenor = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/v2postEncarteFisicoPixeMenor`,
      data,
      'POST'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const v2_getEncarteFisicoPixeMenor = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/v2getEncarteFisicoMenor`,
      data,
      'GET'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const v2_putOfertaFisicoMaior = data => async dispatch => {
  try {
    /* dispatch(isLoadingTabloideFisico()); */
    dispatch({ type: 'CLEAN_PAGINAS' });
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/v2postEncarteFisicoPixeMaior`,
      {
        ...data
      },
      'POST'
    ).then(payload => {
      return 1;
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const postTabloideCategoria = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postTabloideCategoria`,
      data,
      'POST',
      null,
      null,
      true
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const putTabloideCategoria = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/putTabloideCategoria`,
      data,
      'PUT',
      null,
      null,
      true
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const deleteTabloideCategoria = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/deleteTabloideCategoria`,
      data,
      'DELETE',
      null,
      null,
      true
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    throw new Error('Falha de processamento');
  }
};

export const findAutoMessage = data => async dispatch => {
  dispatch(isLoadingAutoMessage());

  Fetch(
    'https://2jjyvz29v0.execute-api.us-east-1.amazonaws.com/prd/auto-message',
    {
      background_id: data.background_id
    },
    'POST',
    null,
    null,
    false
  )
    .then(payload =>
      dispatch({
        type: 'AUTO_MESSAGE',
        payload: { message: payload.message, channel: data.channel }
      })
    )
    .catch(err =>
      dispatch({ type: 'AUTO_MESSAGE', payload: { message: undefined, channel: data.channel } })
    );
};

export const RQFindAutoMessage = async data => {
  const response = await Fetch(
    'https://2jjyvz29v0.execute-api.us-east-1.amazonaws.com/prd/auto-message',
    { background_id: data.background_id },
    'POST',
    null,
    null,
    false
  );

  return response.message;
};

