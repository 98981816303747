import React, { useState } from "react";
import { Modal, TextField, MyButton } from "../../../utils/components";
import { COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import { Typography } from "@material-ui/core";
import { WhatsApp, Mail } from "@material-ui/icons";

export default function(props) {
  const [comentario, setComentario] = useState(null);
  function footer() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        <MyButton
          size="large"
          color="primary"
          variant="text"
          onClick={props.onClose}
        >
          Fechar
        </MyButton>

        <MyButton
          onClick={() => props.onSave(comentario)}
          size="large"
          color="primary"
          variant="text"
        >
          Salvar
        </MyButton>
      </div>
    );
  }

  function header() {
    return (
      <section>
        <div style={{ display: "flex" }}>
          <strong>{props.status.label}</strong>
          <div
            style={{
              marginLeft: "13vw",
              display: "flex",
              justifyContent: "space-between",
              width: "5vw",
              marginTop: "0.5vh"
            }}
          >
            {props.pedido.celular1 && (
              <WhatsApp
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${
                      props.pedido.celular1
                    }&text=${comentario || ""}`,
                    "_blank"
                  )
                }
                style={{ color: "#17990E", cursor: "pointer" }}
              />
            )}

            {props.pedido.email1 && (
              <Mail
                onClick={() =>
                  window.open(
                    `mailto:${props.pedido.email1}?subject=${props.status.label}&body=${comentario}`,
                    "_blank"
                  )
                }
                style={{ color: "#EA1D2C", cursor: "pointer" }}
              />
            )}
          </div>
        </div>
        <span>{props.pedido.guid_pedido}</span>
      </section>
    );
  }
  return (
    <Modal
      size="sm"
      showModal={props.open}
      onClose={props.onClose}
      header={header()}
      footer={footer()}
    >
      <section style={{ padding: "2rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Comentário sobre o pedido
        </Typography>
        <TextField
          multiline
          label="Comentário"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) => setComentario(value)}
          value={comentario}
        />
      </section>
    </Modal>
  );
}
