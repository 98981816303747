import { Divider, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Mail, Print, WhatsApp } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Countup, CustomTable, Modal } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import MyDate from '../../../utils/helpers/Date';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import { _statusPedido, _tipoPagamento, _tipoPedido } from '../../../utils/helpers/Functions';
import { Celular, CEP, CPF } from '../../../utils/helpers/Masks';
import { deleteProdutoPedido, getProdutosPedidoDelivery, putProdutoPedido } from '../action/action';
import '../style/ModalPedidoProduto.css';

const _myDate = new MyDate();

function ModalPedidoProduto(props) {
  const componentRef = useRef();
  const { classes } = props;
  const [eanCopiado, setEanCopiado] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    props.getProdutosPedidoDelivery(props.pedido.id_pedido);
  }, []);

  useEffect(() => {
    setKey(Math.random());
  }, [props.produtosPedido.produtos.length]);

  return (
    <Modal
      size="lg"
      showModal={props.open}
      onClose={props.onClose}
      header={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.pedido.guid_pedido} &nbsp;{' '}
          <ReactToPrint
            trigger={() => <Print style={{ cursor: 'pointer' }} />}
            content={() => componentRef.current}
          />
        </div>
      }>
      <section className="containerInfoPedido" ref={componentRef}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5">
          Informações do pedido
        </Typography>
        <section>
          <strong>ID do pedido: </strong>
          <span>{props.pedido.guid_pedido}</span>
        </section>
        <section>
          <strong>Cliente: </strong>
          <span>{props.pedido.nome}</span>
        </section>
        <section className="removePrint">
          <strong>Documento do cliente: </strong>
          <span>{CPF(props.pedido.documento)}</span>
        </section>
        <section>
          <strong>Endereço do cliente: </strong>
          <span>{`${props.pedido.endereco ? `${props.pedido.endereco}, ` : ''}${
            props.pedido.numero ? `${props.pedido.numero}, ` : ''
          }${props.pedido.bairro ? `${props.pedido.bairro}, ` : ''}${
            props.pedido.cidade ? `${props.pedido.cidade}, ` : ''
          }${props.pedido.uf ? `- ${props.pedido.uf}, ` : ''} ${
            props.pedido.cep ? `${CEP(props.pedido.cep)}, ` : ''
          }${props.pedido.complemento ? `${props.pedido.complemento}, ` : ''}`}</span>
        </section>
        {props.pedido.celular1 && (
          <section style={{ display: 'flex' }}>
            <strong>Celular do cliente: </strong>
            <section style={{ display: 'flex', alignItems: 'center' }}>
              <span>{Celular(props.pedido.celular1)}</span>
              <WhatsApp
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${props.pedido.celular1}`,
                    '_blank'
                  )
                }
                style={{ color: '#17990E', cursor: 'pointer' }}
                className="removePrint"
              />
            </section>
          </section>
        )}
        {props.pedido.email1 && (
          <section className="removePrint" style={{ display: 'flex' }}>
            <strong>Email do cliente: </strong>
            <section style={{ display: 'flex', alignItems: 'center' }}>
              <span>{props.pedido.email1}</span>
              <Mail
                onClick={() => window.open(`mailto:${props.pedido.email1}`, '_blank')}
                style={{ color: '#EA1D2C', cursor: 'pointer' }}
              />
            </section>
          </section>
        )}
        <section className="removePrint">
          <strong>Loja: </strong>
          <span>{props.pedido.nome_loja}</span>
        </section>
        <section>
          <strong>Data do pedido: </strong>
          <span>
            {_myDate.getDate('DD/MM/YYYY HH:mm', props.pedido.data_insert.replace('Z', ''))}
          </span>
        </section>
        <section className="removePrint">
          <strong>Status do pedido: </strong>
          <span>{_statusPedido(props.pedido.status_pedido)}</span>
        </section>
        <section className="removePrint">
          <strong>Tipo do pedido: </strong>
          <span>{_tipoPedido(props.pedido.tipo_pedido)}</span>
        </section>
        <section className="removePrint">
          <strong>Forma de pagamento: </strong>
          <span>{_tipoPagamento(props.pedido.tipo_pagamento)}</span>
        </section>
        {props.pedido.data_retirada && (
          <section className="removePrint">
            <strong>Data de retirada: </strong>
            <span>{_myDate.getDate('DD/MM/YYYY', props.pedido.data_retirada)}</span>
          </section>
        )}
        {props.pedido.hora_retirada && (
          <section className="removePrint">
            <strong>Hora de retirada: </strong>
            <span>{props.pedido.hora_retirada}</span>
          </section>
        )}
        <section className="showPrint">
          <strong>Taxa: </strong>
          <span>R$ {MoedaBrasil(props.produtosPedido.taxa)}</span>
        </section>
        <section className="showPrint">
          <strong>Total: </strong>
          <span>R$ {MoedaBrasil(props.produtosPedido.total)}</span>
        </section>
        <Divider className={classes.divider} />
        <Typography
          style={{
            fontWeight: '900',
            color: COLOR_PRIMARY,
            display: 'flex',
            justifyContent: 'space-between',
          }}
          color="primary"
          variant="h5">
          Produtos do pedido
        </Typography>

        <Typography
          className="removePrint"
          style={{
            fontWeight: '900',
            color: COLOR_PRIMARY,
            display: 'flex',
            justifyContent: 'space-between',
          }}
          color="primary">
          <Countup
            prefix="Total de R$"
            start={0}
            end={props.produtosPedido.total}
            duration={1}
            decimals={2}
          />
          <Countup
            prefix="Taxa de R$"
            start={0}
            end={props.produtosPedido.taxa}
            duration={1}
            decimals={2}
          />
        </Typography>
        <div className="showPrint">
          <section className="listItemPrint">
            <strong>Produto</strong>
            <strong>Quantidade</strong>
            <strong>Comentário</strong>
          </section>
          {props.produtosPedido.produtos &&
            props.produtosPedido.produtos.map(produto => (
              <section className="listItemPrint">
                <span>{produto.nome_produto}</span>
                <span>{produto.qtde_produto}</span>
                <span>{produto.comentario}</span>
              </section>
            ))}
        </div>
        <div className="removePrint">
          <CustomTable
            key={key}
            data={props.produtosPedido.produtos}
            isLoading={props.loadingProdPedido}
            title=""
            columnsButton
            pageSize={props.produtosPedido.produtos.length}
            paging
            search
            headerStyle={{ zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
            }}
            columns={[
              { title: 'Produto', field: 'nome_produto', editable: 'never' },
              {
                title: 'Codigo interno',
                field: 'id_produto',
                editable: 'never',
              },
              {
                title: 'Código de barras',
                field: 'ean',
                editable: 'never',
                render: rowData =>
                  Boolean(rowData.ean) ? (
                    <Tooltip placement="top" title={eanCopiado ? 'Copiado' : 'Copiar'}>
                      <section
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(rowData.ean);
                          setEanCopiado(true);
                        }}
                        onMouseLeave={() => setEanCopiado(false)}>
                        <Barcode
                          value={rowData.ean}
                          displayValue
                          format="EAN13"
                          width={1}
                          height={20}
                          textMargin={0}
                          fontSize={10}
                          margin={9}
                        />
                      </section>
                    </Tooltip>
                  ) : (
                    ''
                  ),
              },
              {
                title: 'Quantidade',
                field: 'qtde_produto',
                type: 'numeric',
                render: rowData => <Countup start={0} end={rowData.qtde_produto} duration={1} />,
              },
              {
                title: 'Total',
                field: 'preco_total',
                type: 'numeric',
                render: rowData => (
                  <Countup
                    prefix="R$"
                    start={0}
                    end={rowData.preco_total}
                    duration={1}
                    decimals={2}
                  />
                ),
              },
              { title: 'Comentário', field: 'comentario', editable: 'never' },
            ]}
            deleteText="Deseja tirar esse produto do pedido ?"
            editable={{
              onRowUpdate: newData =>
                new Promise(resolve => {
                  props.putProdutoPedido(newData);
                  resolve();
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  props.deleteProdutoPedido(oldData);
                  resolve();
                }),
            }}
          />
        </div>
      </section>
    </Modal>
  );
}

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const mapStateToProps = ({ CupomReducer, DeliveryReducer }) => ({
  ...CupomReducer,
  ...DeliveryReducer,
});

const mapDispatchToProps = {
  getProdutosPedidoDelivery,
  putProdutoPedido,
  deleteProdutoPedido,
};

const ModalPedidoProdutoConn = connect(mapStateToProps, mapDispatchToProps)(ModalPedidoProduto);

export default withStyles(styles)(ModalPedidoProdutoConn);
