/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  Button,
  MenuItem,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  contadorWhatsapp,
  addNumeroCampanha,
  removeNumeroCampanha,
} from '../../../Insights/actions/actions';
import {
  isLoadingCampanha,
  getInstancesWhatsApp,
  getInstancesStatusWhatsApp,
  getInstancesStatusQRWhatsApp,
  isLoadingWhatsApp,
  getCabecalhoProspects,
  envioCampanhaWhatsApp,
} from '../../../Campanhas/action/actionCampanha';
import { getInstancias } from '../../../Chatbot/actions';
import {
  MyButton,
  DatePicker,
  Select,
  Chip,
  Countup,
  TextField,
  Modal,
  CustomTable,
  SplitButton
} from '../../../../utils/components';
import DSAWS from '../../../../utils/helpers/DSAWS';
import {
  PARETO,
  HOST_TRACKER,
  ONE_MB_SIZE_IN_BYTES,
  COLOR_PRIMARY,
  BG_PRIMARY,
  CELULARES,
} from '../../../../utils/helpers/Constants';
import { Celular, CelularValidator } from '../../../../utils/helpers/Masks';
import { Dropzone } from '../../../../utils/components/Dropzone';
import '@webscopeio/react-textarea-autocomplete/style.css';

const steps = ['Primeiro', 'Informações de Envio', 'Mensagem', 'Disparar'];

class NovaCampanhaWhatsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      show: false,
      showAlert: false,
      showVar: false,
      showAlertValidate: false,
      showNumeros: false,
      mensagem: {
        message: "",
        files: [],
        selectedCelular: CELULARES,
        optionList: '',
        nomeCampanha: '',
        dataInicioCampanha: moment_tz(moment())
          .tz('America/Sao_Paulo')
          .format('YYYY-MM-DD HH:mm:ss'),
        dataFimCampanha: moment_tz(moment().add(7, 'days'))
          .tz('America/Sao_Paulo')
          .format('YYYY-MM-DD HH:mm:ss'),
        pareto: PARETO[0],
        key: 6,
        numero: "",
        numerosList: [],
        hostTracker: HOST_TRACKER[0],
      },
      phoneTest: '',
    };
  }

  componentDidMount() {
    this.props.getCabecalhoProspects();
    this.props.getInstancias();
  }

  onChange(state, value) {
    this.setState({ 
      mensagem: {
        ...this.state.mensagem,
        [state]: value 
      }
    });
  }

  async criarCampanhaWhatsApp() {
    
      let s3Response = {};

      if (this.state.mensagem.files.length) {
        this.setState({ uploadingWhatsApp: true });
        s3Response = await DSAWS.saveS3({
          folderName: 'whatsappcampanha',
          file: this.state.mensagem.files[0],
        });
        this.setState({ uploadingWhatsApp: false });
      }

      let objCampanha = {
        nomeCampanha: this.state.mensagem.nomeCampanha,
        numero: this.state.mensagem.numero,
        numerosList:
          this.state.mensagem.numerosList &&
          this.state.mensagem.numerosList.map(value => value.value),
        message: this.state.mensagem.message,
        dataInicio: moment(this.state.mensagem.dataInicio).format('DD/MM/YYYY HH:mm'),
        dataFim: moment(this.state.mensagem.dataFim).format('DD/MM/YYYY HH:mm'),
        dataInicioCampanha: moment(this.state.mensagem.dataInicioCampanha).format(
          'DD/MM/YYYY HH:mm'
        ),
        dataFimCampanha: moment(this.state.mensagem.dataFimCampanha).format(
          'DD/MM/YYYY HH:mm'
        ),
        nameList: this.state.mensagem.optionList.label,
        origin: 'Minha Base',
        pareto: this.state.mensagem.pareto.id,
        selectedCelular:
          this.state.mensagem.selectedCelular &&
          this.state.mensagem.selectedCelular.map(value => value.value),
        redirectTracker: this.state.mensagem.redirectTracker,
        custoCampanha: this.props.contador_celular
          ? this.props.contador_celular.valor_total
          : 0,
        cupom: this.state.mensagem.cupom,
        whatsapp_midia: 1,
        whatsapp_url: this.props.whatsapp_url,
        hostTracker: !this.state.mensagem.hostTracker ? '' : this.state.mensagem.hostTracker.host,        
      };
      if (this.state.mensagem.key === 6) {
        objCampanha = {
          ...objCampanha,
          idInstancia: this.state.mensagem.optionList.value,
        };
      } else {
        objCampanha = {
          ...objCampanha,
          idListProspectei: this.state.mensagem.optionList.value,
        };
      }
      this.props.envioCampanhaWhatsApp(objCampanha);
      this.props.onClose();
    
  }

  adicionarNumero(numero) {
    const { mensagem } = this.state;

    if (numero) { //CelularValidator(numero)
      mensagem.numerosList.push({
        value: numero,
        label: numero,
      });
      this.props.addNumeroCampanha('contador_celular');
      //mensagem.numero = '';
      this.setState({ showNumeros: true, phoneTest: '' });
    }
  }

  removerNumero(index) {
    const { mensagem } = this.state;
    mensagem.numerosList.splice(index, 1);
    this.props.removeNumeroCampanha('contador_celular');
    this.setState({ showNumeros: true });
  }

  onChangeAdd(value) {
    this.onChange('numero', Celular(value));
    if (/,|;/g.test(value)) {
      this.adicionarNumero();
      this.onChange('numero', '');
    }
  }

  onPasteAdd(value) {
    this.setState({ phoneTest: value });
    let celular,
      regex = /\d{2}\d{4,5}\d{4}/g,
      isPhone = false;
    if (/,/g.test(value)) {
      value.split(',').forEach(numero => {
        isPhone = regex.test(numero);
        if (Boolean(isPhone)) {
          celular = Celular(numero);
          this.adicionarNumero(numero);
        }
      });
    }
  }

  onAdd(key, value) {
    this.setState({ phoneTest: value });
    if (key === 13) {
      const regex = /\d{2}\d{4,5}\d{4}/g,
        isPhone = regex.test(value);
      if (Boolean(isPhone)) {
        this.adicionarNumero(value);
      }
    }
  }

  tituloModal(titulo) {
    switch (titulo) {
      case 1:
        return 'Refazer campanha para pessoas que compraram';
      case 2:
        return 'Refazer campanha para pessoas que não compraram';
      case 3:
        return 'Refazer campanha para todas as pessoas';
      default:
        return 'Nova Campanha';
    }
  }

  header(props, state) {
    return (
      <Fragment>
        <strong>{this.tituloModal(0)}</strong>
        <section className="modal-header">
          <span className="modal-subtitle">
            <strong>Tipo de Campanha: </strong>WHATSAPP
          </span>
          <span className="modal-subtitle">
            <strong>Mensagens na lista: </strong>
            <Countup
              isLoading={props.isLoadingCountPhone}
              end={props.contador_campanha.contador_celular || 0}
            />
          </span>
          <span className="modal-subtitle">
            <strong>Investimento: </strong>
            <Countup
              prefix="R$"
              decimals={2}
              isLoading={props.isLoadingCountPhone}
              end={parseFloat(props.contador_campanha.valor_total || 0)}
            />
          </span>
        </section>
        <Divider style={{ marginTop: '2rem' }} />
        <div style={{ width: '100%' }}>
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          style={{ color: COLOR_PRIMARY }}
          onClick={() =>
            this.setState({
              activeStep: state.activeStep === 0 ? 0 : state.activeStep - 1,
            })
          }
        >
          Voltar
        </Button>
        {(state.activeStep !== 0) &&
          <Button
            style={{ color: COLOR_PRIMARY }}
            onClick={() =>
              this.setState({
                activeStep: state.activeStep === 3 ? 3 : state.activeStep + 1,
              })
            }
          >
            Avançar
          </Button>
        }
      </div>
    );
  }

  dropzone(props, readonly = false) {
    const { files } = this.state.mensagem;

    return (
      <div>
        <img
          alt="whatsapp_url"
          src={
            this.props.whatsapp_url
          }
          style={{ width: '100%', cursor: 'pointer', backgroundColor: '#e7e7e7' }}
        />
      </div>
    );
  }

  step1(props, state) {
    const {
      listaInstancias
    } = this.props;

    return (
      <section style={{ padding: '2rem 2rem' }}>
        <CustomTable
          title=""
          //columnsButton
          pageSize={5}
          paging
          //search
          isLoading={listaInstancias.length<=0} // || this.props.listsProspectei<=0
          headerStyle={{
            zIndex: 0,
            color: BG_PRIMARY,
          }}
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
            color: BG_PRIMARY,
          }}
          columns={[
            { title: 'Nome', field: 'nome' },
            { title: 'Codigo', field: 'codigo' },
            {
              title: 'Ações',
              field: '',
              render: rowData => (
                <Button
                  onClick={() => {
                    this.setState({
                      mensagem: {
                        ...this.state.mensagem,
                        ofertas: false,
                        optionList: {
                          label: rowData.nome,
                          value: rowData.id,
                        },
                        listaSelecionada: false,
                      },
                      activeStep: this.state.activeStep + 1,
                    }, () => {
                      const { mensagem } = this.state;

                      if (mensagem && mensagem.optionList && mensagem.optionList.value) {
                        this.props.contadorWhatsapp({
                          celulares: mensagem.selectedCelular.map(opt => opt.value),
                          value: mensagem.optionList.value,
                          key: 6,
                          pra_quem: mensagem.pra_quem,
                          origem_campanha: mensagem.origem_campanha,
                        });
                      }
                    });
                  }}
                >
                  Iniciar Campanha
                </Button>
              ),
            },
          ]}
          data={listaInstancias}
        />
        
      </section>
    );
  }

  step2(props, state) {
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Informações da campanha
          </Typography>
          <TextField
            label="Nome"
            className="form-control"
            onChange={({ target }) => {
              this.onChange('nomeCampanha', target.value);
            }}
            value={this.state.mensagem.nomeCampanha}
            autoComplete="none"
          />
          {/* <Select
            label={this.state.mensagem.ofertas ? 'Oferta' : 'Audiência'}
            disabled
            options={props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao,
            }))}
            placeholder="Selecione uma lista..."
            onChange={option =>
              props.contadorWhatsapp({
                celulares: this.state.mensagem.selectedCelular.map(opt => opt.value),
                ...option,
              })
            }
            value={this.state.mensagem.optionList}
          /> */}
          {this.state.mensagem.ofertas && (
            <Select
              label="Audiência"
              options={props.listsProspectei.map(list => ({
                label: list.nameList,
                value: list.idListProspectei || list.idPromocao,
              }))}
              placeholder="Selecione uma audiência..."
              onChange={option => {
                this.onChange('listaSelecionada', option);
                props.contadorWhatsapp({
                  celulares: this.state.mensagem.selectedCelular.map(
                    opt => opt.value
                  ),
                  ...option,
                });
              }}
              value={this.state.mensagem.listaSelecionada}
            />
          )}

          {/* <section>
            <DatePicker
              dateAndTime
              label="Início da campanha"
              onChange={date => this.onChange('dataInicioCampanha', date)}
              value={this.state.mensagem.dataInicioCampanha}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              label="Término da campanha"
              onChange={date => this.onChange('dataFimCampanha', date)}
              value={this.state.mensagem.dataFimCampanha}
            />
          </section>
          <Select
            label="Grupo do controle"
            options={PARETO}
            valueKey="id"
            placeholder="Grupo do controle..."
            onChange={option => this.onChange('pareto', option)}
            value={this.state.mensagem.pareto}
          /> */}
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Informações do disparo
          </Typography>
          <DatePicker
            dateAndTime
            label="Envio de sms"
            onChange={date => this.onChange('dataInicio', date)}
            value={this.state.mensagem.dataInicio}
          />
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Números copiados na campanha"
              className="form-control"
              onChange={({ target }) => this.onPasteAdd(target.value)}
              onKeyUp={({ keyCode, target: { value } }) =>
                this.onAdd(keyCode, value)
              }
              value={state.phoneTest}
              autoComplete="none"
            />
            <Typography variant="caption">
              *Separe os números por <strong>vírgula</strong>, ou pressione{' '}
              <strong>enter</strong>.
            </Typography>
          </section>

          {state.showNumeros &&
            this.state.mensagem.numerosList.map((numero, index) => (
              <section
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "2vh"
                }}
              >
                <strong>{numero.label}</strong>
                <MyButton
                  bsSize="xs"
                  bsstyle="danger"
                  onClick={() => this.removerNumero(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </MyButton>
              </section>
          ))}

          {/* <Select
            up
            label="Celulares à enviar"
            onChange={option => {
              this.onChange('selectedCelular', option);
              props.contadorWhatsapp({
                celulares: option && option.map(opt => opt.value),
                pra_quem: this.state.mensagem.pra_quem,
                origem_campanha: this.state.mensagem.origem_campanha,
                value: this.state.mensagem.optionList
                  ? this.state.mensagem.optionList.value
                  : this.state.mensagem.idListProspectei,
              });
            }}
            value={this.state.mensagem.selectedCelular}
            options={CELULARES}
            multiple
          /> */}

          {this.state.mensagem.redirectTracker &&
            this.state.mensagem.redirectTracker.includes('oferta-personalizada') && (
              <Select
                up
                label="Template"
                onChange={option => this.onChange('selectedTemplate', option)}
                value={this.state.mensagem.selectedTemplate}
                placeholder="Template..."
                valueKey="id_template"
                labelKey="nome_template"
                options={props.listaTemplate.data}
              />
            )}

          {/* <section style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              label="Números para disparos de teste"
              className="form-control"
              onChange={({ target }) => this.onChangeAdd(target.value)}
              value={this.state.mensagem.numero}
              autoComplete="none"
            />
          </section> */}

          {/* <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Rastreio
          </Typography>

          <TextField
            label="Link (URL)"
            className="form-control"
            disabled={
              this.state.mensagem.redirectTracker &&
              this.state.mensagem.redirectTracker.includes('oferta-personalizada')
            }
            onChange={({ target }) =>
              this.onChange('redirectTracker', target.value)
            }
            value={this.state.mensagem.redirectTracker}
          />

          <Select
            label="Domínio"
            options={HOST_TRACKER}
            disabled
            valueKey="host"
            placeholder="Domínio..."
            onChange={option => this.onChange('hostTracker', option)}
            value={HOST_TRACKER[0]}
          /> */}
        </section>
      </section>
    );
  }
  step3(props, state) {
    return (
      <section style={{ padding: '2rem 15rem' }}>
        {/* <Chip
          label="Id Pessoa"
          clickable
          onClick={() => {
            this.onChange('message', `${this.state.mensagem.message} {{idPessoa}}`);
            this.setState({ showVar: false });
          }}
        />
        <Chip
          label="CPF"
          clickable
          onClick={() => {
            this.onChange(
              'message',
              `${this.state.mensagem.message} {{documento}}`
            );
            this.setState({ showVar: false });
          }}
        />
        <Chip
          label="Primeiro nome"
          clickable
          onClick={() => {
            this.onChange(
              'message',
              `${this.state.mensagem.message} {{primeiroNome}}`
            );
            this.setState({ showVar: false });
          }}
        />
        <Chip
          clickable
          label="Nome completo"
          onClick={() => {
            this.onChange(
              'message',
              `${this.state.mensagem.message} {{nomeCompleto}}`
            );
            this.setState({ showVar: false });
          }}
        />
        <Chip
          clickable
          label="Cupom"
          onClick={() => {
            this.onChange('message', `${this.state.mensagem.message} {{voucher}}`);
            this.setState({ showVar: false });
          }}
        />
        <Chip
          clickable
          label="Click tracker"
          onClick={() => {
            this.onChange(
              'message',
              `${this.state.mensagem.message} {{clickTracker}}`
            );
            this.setState({ showVar: false });
          }}
        />
        <Chip
          label="Identificador"
          clickable
          onClick={() => {
            this.onChange(
              'message',
              `${this.state.mensagem.message} {{identificador}}`
            );
            this.setState({ showVar: false });
          }}
        /> */}
        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="20"
          error={state.showAlert}
          value={this.state.mensagem.message}
          margin="normal"
          variant="outlined"
          helperText={`${this.state.mensagem.message.length}/160`}
          onChange={({ target }) => {
            this.onChange('message', target.value);
            this.setState({
              showAlert: target.value.length < 160 ? false : true,
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: 'flex', flexDirection: 'column' }}></div>
              </InputAdornment>
            ),
          }}
        />
        {this.dropzone(props)}
      </section>
    );
  }
  step4(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{
            padding: '2rem 15rem',
            display: 'grid',
            gap: '2rem',
            color: BG_PRIMARY,
          }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Sobre a Campanha
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome</strong>
              </p>
              <span>{this.state.mensagem.nomeCampanha}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data Disparo</strong>
              </p>
              <span>
                {moment(this.state.mensagem.dataInicio).format('DD/MM/YYYY HH:mm')}
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Valor do Investimento</strong>
              </p>
              <span>
                <Countup
                  prefix="R$"
                  decimals={2}
                  isLoading={props.isLoadingCountPhone}
                  end={parseFloat(props.contador_campanha.valor_total || 0)}
                />
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Total de Envios</strong>
              </p>
              <span>
                <Countup
                  isLoading={props.isLoadingCountPhone}
                  end={props.contador_campanha.contador_celular || 0}
                />
              </span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Mensagem
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <span>{this.state.mensagem.message}</span>
          </section>
          {this.state.mensagem.files.length > 0 && (
            <div style={{ paddingTop: '10px' }}>
              {this.dropzone(props, true)}
            </div>
          )}
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              disabled={
                _disableButtonSave(this.state.mensagem, state.showAlert) ||
                this.state.mensagem.uploadingWhatsApp
              }
              onClick={() => this.criarCampanhaWhatsApp()}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
            <div style={{ paddingTop: 30 }}>
              {this.state.mensagem.uploadingWhatsApp && <LinearProgress />}
            </div>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;
    const { activeStep, mensagem } = this.state;

    return (
      <Modal
        size="lg"
        showModal={this.props.showModal}
        onClose={() => onClose()}
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const _disableButtonSave = (mensagem, alert) => {
  if (
    mensagem.message &&
    !alert &&
    (mensagem.optionList || mensagem.idListProspectei)
  ) {
    return false;
  } else {
    return true;
  }
};

const mapStateToProps = ({ 
  CampaignReducer, 
  InsightsReducer,
  ChatbotReducer
}) => ({
  ...CampaignReducer,
  ...InsightsReducer,
  listaInstancias: ChatbotReducer.instancias
});

const mapDispatchToProps = {
  contadorWhatsapp,
  isLoadingCampanha,
  isLoadingWhatsApp,
  getInstancesWhatsApp,
  getInstancesStatusWhatsApp,
  getInstancesStatusQRWhatsApp,
  addNumeroCampanha,
  removeNumeroCampanha,
  getInstancias,
  getCabecalhoProspects,
  envioCampanhaWhatsApp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaCampanhaWhatsApp);
