import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { notificationChat } from '../../../../../utils/helpers/Format';
import { Celular2 } from '../../../../../utils/helpers/Masks';

function MessageNotification({ item }) {
  return (
    <Box
      flex="0 0 auto"
      display="flex"
      marginTop="8px"
      marginLeft="auto"
      marginBottom="8px"
      marginRight="auto"
      justifyContent="center"
    >
      <Box
        color="#000"
        minWidth="0%"
        boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
        padding="10px"
        borderRadius="7.5px"
        bgcolor="#FEF3BF"
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        <Typography style={{ fontSize: 11, fontWeight: 500 }}>
          {notificationChat(item.message, Celular2(item.participant_phone))}
        </Typography>
      </Box>
    </Box>
  );
}

MessageNotification.propTypes = {
  item: PropTypes.any.isRequired,
};

export default MessageNotification;
