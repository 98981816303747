const INITIAL_STATE = {
  totalizadores: {
    gastos_campanha: 0,
    margem_lucro: 0,
    qtde_campanha: 0,
    qtde_vendas: 0,
    roi: 0,
    ticket_medio: 0,
    valor_vendas: 0,
    novos_clientes: 0,
    valor_roi: 0,
  },
  detalhesCampanha: {
    detalhesCampanha: [],
    vendasHora: [],
    vendasLoja: [],
    vendasDia: [],
    faixaEtaria: [],
    genero: [],
  },
  graficoLojas: [],
  campanha: {},
  campanhas: {
    campanhas: [],
    visaoGeral: [],
    vendasHora: [],
    vendasLoja: [],
    vendasDia: [],
    faixaEtaria: [],
    genero: [],
  },
  visaoGeral: [],
  listaPessoas: [],
  resgateCampanha: [],
  listaResgate: [],
  statusEnvio: [],
  taxaClique: [],
  listaEnvio: [],
  campanhaRespostas: [],
  produtosTabloideCampanha: [],
  cestaProduto: [],
  loadingPerformance: false,
  loadingPessoas: false,
  loadingDetalhes: false,
  loadingTaxaClique: false,
  loadingResposta: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TODAS_PERFORMANCE':
      return {
        ...state,
        totalizadores: action.payload.total,
        campanhas: action.payload,
        loadingPerformance: false,
      };
    case 'LOADING_PERFORMANCE':
      return { ...state, loadingPerformance: true };
    case 'PESSOAS_CAMPANHA':
      return {
        ...state,
        listaPessoas: action.payload,
        loadingPessoas: false,
      };
    case 'LOADING_PESSOAS':
      return { ...state, loadingPessoas: true };
    case 'DETALHES_CAMPANHA':
      return {
        ...state,
        detalhesCampanha: action.payload,
        graficoLojas: action.payload.graph,
        loadingDetalhes: false,
      };

    case 'RESGATE_CAMPANHA':
      return {
        ...state,
        listaResgate: action.payload.lista,
        resgateCampanha: action.payload.detalhe,
        loadingPerformance: false,
      };
    case 'DESEMPENHO_ENVIO':
      return {
        ...state,
        statusEnvio: action.payload.status,
        listaEnvio: action.payload.lista,
        loadingDetalhes: false,
      };
    case 'TAXA_CLIQUE':
      return {
        ...state,
        loadingTaxaClique: false,
        taxaClique: action.payload,
      };
    case 'CAMPANHA_RESPOSTA':
      return {
        ...state,
        campanhaRespostas: action.payload,
        loadingResposta: false,
      };
    case 'PRODUTOS_TABLOIDE_CAMPANHA':
      return { ...state, produtosTabloideCampanha: action.payload };
    case 'CESTA_PRODUTO_TABLOIDE':
      return {
        ...state,
        cestaProduto: action.payload,
        loadingPerformance: false,
      };
    case 'LOADING_DETALHES':
      return { ...state, loadingDetalhes: true };
    case 'LOADING_TAXA_CLIQUE':
      return { ...state, loadingTaxaClique: true };
    case 'LOADING_RESPOSTA':
      return { ...state, loadingResposta: true };
    case 'setCampanha':
      return { ...state, campanha: action.payload };
    default:
      return state;
  }
};
