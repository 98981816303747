import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme =>({
  boxRoot:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },

  boxRootImg:{
    width:'40%',
    height:'30em',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },

  boxImg:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
    height:'100%',
  },

  buttonImg:{
    height: '38px',
    gap: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.05em',
      color: '#1D1D1D',
      marginTop: '16px',
      background: '#F5F5F5',
      border: 0,
      '&:hover': {
        border: 0
      }
  },

  checkboxRemoverFundo:{
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    padding: '0',
    marginLeft: '-10px',
    marginTop:'1px',

    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.05rem',
    color: '#616161',
  },

  boxContent:{
    width:'60%',
    marginLeft:'32px',
  },

  dialogWidth:{
    width:'70vw !important'
  },

  title:{
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '26px',
    letterSpacing: '0.05rem',
    color: '#1D1D1D',
    marginBottom: '4px',
    height: '26px',
  },

  subTitle:{
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    marginBottom: '3px',
    letterSpacing: '0.05rem',
    color: '#616161',
    marginBottom: '33px',
    height: '22px'
  },
  
  formFields:{
    marginBottom: '24px',
    width: '100%',
    height: '52px'
  },

  sectionsTwoTextfield:{
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },

  boxButtons:{
    display:'flex', 
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '16px',
  },

  buttonCancel:{
    color: '#616161',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '10px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.05em'
  },

  buttonSalve:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    background: '#2813AD',
    color: '#FFF',
    width: '80px',
    height: '38px',
    borderRadius: '4px',
    '&:hover': {
      background: '#2813AD',
    }
  },

  typographyMoreSettings:{
    height: '22px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing:' 0.05em',
    textDecorationLine: 'underline',
    color: '#127FFF',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    marginTop: '24px',
    '&:hover': {
      background: '#fff',
      textDecorationLine: 'underline'
    }
  },

  checkboxFormPag:{
    marginBottom: '32px',
    marginTop: '32px'
  },
  textFormTest:{
    '& label.Mui-focused': {
      color: '#2813AD !important',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2813AD !important',
      },
    },   
  },
  checkedIcon:{
    '&:before':{
      backround: '#2813AD !important'
    },
  },

}));