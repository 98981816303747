import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Countup from 'react-countup';
import api from '../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import MastercardIcon from '../../assets/icons/financial/mastercard.svg';
import BilletIcon from '../../assets/icons/financial/billet.svg';
import PixIcon from '../../assets/images/financial/pix.png';
import useDate from '../../hooks/date';
import useStyles from './styles';

export default function Financial() {
  const classes = useStyles(),
    { getDate } = useDate(),
    [loading, setLoading] = useState(true),
    [payments, setPayments] = useState([]),
    [paymentForm, setPaymentForm] = useState({});

  function _translateTypePayment(type = '') {
    switch (type.toUpperCase()) {
      case 'BOLETO':
        return BilletIcon;
      case 'CARTÃO DE CRÉDITO':
        return MastercardIcon;
      case 'CARTÃO DE DEBITO':
        return MastercardIcon;
      case 'PIX':
        return PixIcon;
      case 'OUTROS':
        return 4;
      default:
        return BilletIcon;
    }
  }

  useEffect(() => {
    (async function () {
      try {
        const { content } = await api(
          `${DATASALES_CRM_API}financial-${AMBIENTE}/payments/list`
        );

        if (content[0]) {
          setPaymentForm({
            type: _translateTypePayment(content[0].FORMA_PAGAMENTO),
            form: content[0].FORMA_PAGAMENTO,
            dueDate: content[0].DTA_VENCIMENTO,
            expirationDay: getDate('DD', content[0].DTA_VENCIMENTO),
            showBillet: content[0].FORMA_PAGAMENTO !== 'Cartão de crédito',
          });
        }

        setPayments(content);
      } catch (err) {
        console.log('ERRO PAYMENTS => ', err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container spacing={4} className={classes.root}>
      {loading ? (
        <Box
          width="100%"
          height="85vh"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : !paymentForm.type ? (
        <Grid container item xs={12} alignItems="center" justify="center">
          <Typography className={classes.titleTypography}>
            Não encontramos seus pagamentos recentes.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Typography className={classes.titleTypography}>
                Forma de pagamento
              </Typography>
              <Paper className={classes.paperPaymentForm} variant="outlined">
                <Box
                  marginBottom="16px"
                  display="flex"
                  width="100%"
                  alignItems="center"
                >
                  <img
                    alt="payment-form-icon"
                    src={paymentForm.type}
                    className={classes.iconPaymentForm}
                  />
                  <Typography className={classes.titleTypography}>
                    {paymentForm.form}
                  </Typography>
                </Box>
                <Typography className={classes.tableRowOdd}>
                  Vencimento todo dia {paymentForm.expirationDay}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography className={classes.titleTypography}>
                Pagamentos
              </Typography>
              <Paper className={classes.paperPayments} variant="outlined">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.titleTypography}
                        align="center"
                      >
                        Vencimento
                      </TableCell>
                      <TableCell
                        className={classes.titleTypography}
                        align="center"
                      >
                        Status
                      </TableCell>
                      {paymentForm.showBillet && (
                        <TableCell
                          className={classes.titleTypography}
                          align="center"
                        >
                          Boleto
                        </TableCell>
                      )}

                      <TableCell
                        className={classes.titleTypography}
                        align="center"
                      >
                        Valor
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.map((_row, index) => (
                      <TableRow key={index.toString()}>
                        <TableCell
                          className={
                            index % 2 === 0
                              ? classes.tableRowPair
                              : classes.tableRowOdd
                          }
                          align="center"
                        >
                          {getDate('DD/MM/YYYY', _row.DTA_VENCIMENTO)}
                        </TableCell>
                        <TableCell
                          className={
                            index % 2 === 0
                              ? classes.tableRowPair
                              : classes.tableRowOdd
                          }
                          align="center"
                        >
                          <Chip
                            label={
                              _row.STATUS_PAGAMENTO === 0
                                ? 'Aberto'
                                : _row.STATUS_PAGAMENTO === 1
                                ? 'Pago'
                                : 'Atrasado'
                            }
                            style={{
                              backgroundColor:
                                _row.STATUS_PAGAMENTO === 0
                                  ? '#EBDA44'
                                  : _row.STATUS_PAGAMENTO === 1
                                  ? '#78BF77'
                                  : '#D44D4D',
                            }}
                            color="primary"
                            size="small"
                          />
                        </TableCell>
                        {paymentForm.showBillet && (
                          <TableCell
                            className={
                              index % 2 === 0
                                ? classes.tableRowPair
                                : classes.tableRowOdd
                            }
                            align="center"
                          >
                            <a
                              style={{
                                color: '#0077E5',
                                textDecoration: 'underline',
                              }}
                              href={_row.URL_COBRANCA}
                              target="_blank"
                            >
                              Gerar boleto
                            </a>
                          </TableCell>
                        )}

                        <TableCell
                          className={
                            index % 2 === 0
                              ? classes.tableRowPair
                              : classes.tableRowOdd
                          }
                          align="center"
                        >
                          <Countup
                            start={0}
                            end={_row.VALOR}
                            separator="."
                            decimal=","
                            decimals={2}
                            prefix="R$ "
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
