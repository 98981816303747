const rgxItalic = /(_)([^_]+?)(_)/gs,
  rgxBold = /(\*)([^*]+?)(\*)/gs,
  rgxStrikethrough = /(\~)([^~]+?)(\~)/gs,
  rgxUrl = /(https?:\/\/[^\s]+)/g,
  htmlItalic = '<i>$2</i>',
  htmlBold = '<b>$2</b>',
  htmlStrikethrough = '<s>$2</s>',
  htmlUrl = url => '<a href="' + url + '" target="_blank">' + url + '</a>';

export default string =>
  string
    ? string
        .replace(rgxBold, htmlBold)
        .replace(rgxItalic, htmlItalic)
        .replace(rgxStrikethrough, htmlStrikethrough)
        .replace(rgxUrl, htmlUrl)
    : string;
