import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Typography, withStyles, Avatar, Icon, CircularProgress } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { listStore } from '../../Lojas/actions/action';
import { getOfertaCashback } from '../actions';
import { MES, COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { Panel, CountCard, DatePicker, Select } from '../../../utils/components';
import MyDate from '../../../utils/helpers/Date';
import ModalVerDetalhe from './ModalVerDetalhe';

const _myDate = new MyDate();

class Ofertas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_loja: null,
      nomeOferta: '',
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      filtroMes: MES[new Date().getMonth()],
      personalizada: null,

      modalVerDetalhe: false,
    };
  }

  componentDidMount() {
    const { filtroDataInicio, filtroDataFim, id_loja } = this.state;
    const periodo = {
      dataInicio: _myDate.getDate('YYYY-MM-DD', filtroDataInicio),
      dataFim: _myDate.getDate('YYYY-MM-DD', filtroDataFim),
      id_loja,
    };
    this.props.listStore();
    this.props.getOfertaCashback(periodo);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim, id_loja } = this.state;
      const periodo = {
        dataInicio: _myDate.getDate('YYYY-MM-DD', filtroDataInicio),
        dataFim: _myDate.getDate('YYYY-MM-DD', filtroDataFim),
        id_loja,
      };
      this.props.getOfertaCashback(periodo);
    });
  }

  render() {
    const { classes, cashback, isLoadingCashback } = this.props;
    const { filtroDataInicio, filtroDataFim, modalVerDetalhe } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Cashback
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por período
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date => this.onChangePeriodo('filtroDataInicio', date)}
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                marginLeft: 20,
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por lojas
              </Typography>

              <Select
                label="Lojas"
                options={this.props.listStores.map(loja => ({
                  value: loja.id_loja,
                  label: loja.nome_loja,
                }))}
                onChange={data => {
                  data
                    ? this.onChangePeriodo('id_loja', data.value)
                    : this.onChangePeriodo('id_loja', null);
                }}
              />
            </div>
          </section>
          <div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button2}
              component={Link}
              to="/criar-cashback">
              Criar cashback
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}>
          <Button
            style={{
              marginRight: 10,
              borderRadius: 10,
              '&:hover': { borderRadius: 10 },
            }}
            color="inherit"
            size="large"
            className={classes.activeButton}>
            Cashback
          </Button>
        </div>
        <div
          className={classes.content}
          style={
            isLoadingCashback
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }>
          {isLoadingCashback ? (
            <CircularProgress></CircularProgress>
          ) : (
            cashback.map(oferta => (
              <Fragment>
                <Panel
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Icon>shopping_cart</Icon>
                    </Avatar>
                  }
                  title={`${`${oferta.nome_loja} - `}${oferta.nome_cashback}`}
                  subheader={` ${moment(oferta.comeca_valer).format('DD/MM/YYYY')} até ${moment(
                    oferta.termina_valer
                  ).format('DD/MM/YYYY')}`}
                  style={{ marginBottom: 20 }}
                  action={
                    <Button
                      size="large"
                      className={classes.button}
                      variant="text"
                      color="primary"
                      component={Link}
                      onClick={() =>
                        this.setState({
                          ...oferta,
                          modalVerDetalhe: true,
                        })
                      }>
                      Ver detalhes
                    </Button>
                  }>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                      }}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.qtde_vendas}
                        title="Vendas"
                        icon="score"
                        style={{ marginRight: 20 }}
                      />

                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.qtde_pessoa}
                        title="Pessoas"
                        icon="group_add"
                        style={{ marginRight: 20 }}
                      />

                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.total_cashback}
                        decimals={2}
                        prefix="R$ "
                        title="Total cashback"
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </div>
                </Panel>
              </Fragment>
            ))
          )}
        </div>
        {modalVerDetalhe && (
          <ModalVerDetalhe
            {...this.state}
            open={modalVerDetalhe}
            onClose={() =>
              this.setState({
                modalVerDetalhe: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  button2: { margin: theme.spacing(1), backgroundColor: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ LojasReducer, CashbackReducer }) => ({
  ...LojasReducer,
  ...CashbackReducer,
});

const mapDispatchToProps = {
  listStore,
  getOfertaCashback,
};

const OfertasConnect = connect(mapStateToProps, mapDispatchToProps)(Ofertas);

export default withStyles(styles)(OfertasConnect);
