import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@mui/material/Button';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import localization from '../../../../localization/table';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

function Users() {
  const classes = useStyles();

  const history = useHistory();

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/users`
        );

        setData(response);
        //console.log(response)
      } catch (err) {}
    })();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container justify="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              history.push('/account-settings/user/new');
            }}
          >
            Adicionar usuario
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.card}>
          <Table
            title="Usuarios"
            columns={[
              { title: 'Nome', field: 'name' },
              { title: 'E-mail', field: 'email' }
            ]}
            isLoading={!data}
            data={data || []}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              selectionProps: () => ({
                color: 'primary'
              })
            }}
            localization={localization}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar usuário',
                onClick: (event, rowData) => {
                  history.push(`/account-settings/user/edit/${rowData.id}`);
                }
              },
              {
                icon: 'delete',
                tooltip: 'Editar usuário',
                onClick: (event, rowData) => {
                  history.push(`/account-settings/user/edit/:id`);
                }
              }
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Users;
