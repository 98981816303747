/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles, Button } from "@material-ui/core";
import { Modal, CustomTable } from "../../../utils/components";
import "@webscopeio/react-textarea-autocomplete/style.css";
// import Icon from "@mdi/react";
// import {
//   mdiFileExcelOutline,
//   mdiFilePdfOutline,
//   mdiFilePowerpointOutline
// } from "@mdi/js";

class DetalhesRecomendacao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  header(props, state) {
    return <Fragment>Detalhes</Fragment>;
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        <div />
      </div>
    );
  }

  // renderIcon(file_type) {
  //   switch (file_type) {
  //     case "xlsx":
  //       return (
  //         <Icon
  //           path={mdiFileExcelOutline}
  //           title="User Profile"
  //           size={2}
  //           color="#008000"
  //         />
  //       );
  //       case "pdf":
  //       return (
  //         <Icon
  //           path={mdiFilePdfOutline}
  //           title="User Profile"
  //           size={2}
  //           color="#ff0000"
  //         />
  //       );
  //       case "pptx":
  //       return (
  //         <Icon
  //           path={mdiFilePowerpointOutline}
  //           title="User Profile"
  //           size={2}
  //           color="#ff7c00"
  //         />
  //       );
  //   }
  // }

  render() {
    const { open, onClose, performanceDetalhes, loading_detalhes } = this.props;

    return (
      <Fragment>
        <Modal
          size="xl"
          showModal={open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <section style={{ display: "flex" }}>
            <CustomTable
              title=""
              columnsButton
              pageSize={5}
              paging
              search
              style={{
                border: "none",
                boxShadow: "none",
                width: "100%"
              }}
              actions={[
                {
                  icon: "arrow_downward",
                  tooltip: "Download",
                  onClick: (event, rowData) => {
                    window.open(rowData.download_link, "_blank");
                  }
                }
              ]}
              // isLoading={loading_detalhes}
              // onRowClick={(columns, data) =>
              //   this.setState({ data: data }, () =>
              //     this.setState({ showModal: true })
              //   )
              // }

              columns={[
                {
                  title: "Formato",
                  field: "formato_arquivo"
                  // render: rowData => this.renderIcon(rowData.formato_arquivo)
                },
                { title: "Download Link", field: "download_link" }
              ]}
              data={performanceDetalhes}
            />
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({ SolicitacoesReducer }) => ({
  ...SolicitacoesReducer
});

const mapDispatchToProps = {};

const DetalhesRecomendacaoConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalhesRecomendacao);

export default withStyles(styles)(DetalhesRecomendacaoConnect);
