import { createStyles, makeStyles } from '@material-ui/styles';
import { COLOR_NEW_PRIMARY } from 'utils/helpers/Constants';

export const useStyles = makeStyles(theme =>
  createStyles({
    fastModelsContent: {
      width: '100%',
      padding: '54px 0 100px'
    },
    // scrollContainer: {
    //   position: 'relative',
    //   overflow: 'auto',
    //   maxHeight: '650px',
    //   paddingBottom: '180px'
    // },
    subcategoriesContainer: {
      width: '100%',
      marginBottom: '30px'
    },
    subcategories: {
      width: '100%',
      display: 'flex',
      paddingLeft: '40px',
      '& .MuiSkeleton-root': {
        width: '180px',
        height: '180px',
        marginRight: '30px'
      }
    },
    subcategoriesTitle: {
      fontSize: '16px',
      fontWeight: 600,
      paddingLeft: '40px',
      marginBottom: '16px'
    },
    subcategoriesSlider: {
      width: '100%',
      height: '184px',
      padding: '0 40px',
      '& .swiper-button-next': {
        right: '10px',
        zIndex: 99999
      },
      '& .swiper-button-prev': {
        left: '0px',
        zIndex: 99999
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '40px',
        height: '100%',
        background: '#FFF',
        zIndex: 9999
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '0',
        right: '0',
        width: '40px',
        height: '100%',
        background: '#FFF',
        zIndex: 9999
      }
    },
    subcategoriesSlide: {
      width: '184px',
      height: '184px'
    },
    subcategoriesSlideImageContainer: {
      width: '184px',
      height: '184px',
      overflow: 'hidden',
      borderRadius: '5px',
      border: '2px solid transparent',
      transition: 'all 0.3s ease',
      '&:active': {
        transform: 'scale(.98)'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '2',
        left: '2',
        width: '180px',
        height: '180px',
        background: 'transparent'
      },
      '&:hover:before': {
        background: 'rgba(255, 255, 255, 0.7)'
      },
      '&:hover .MuiCheckbox-root': {
        opacity: 1
      }
    },
    subcategoriesSlideImageContainerSelected: {
      borderColor: '#2813AD',
      '&:before': {
        background: 'rgba(255, 255, 255, 0.5)'
      },
      '& .MuiCheckbox-root': {
        color: '#2813AD',
        opacity: 1,
        zIndex: 99
      },
      '& svg': {
        fill: '#2813AD'
      }
    },
    subcategoriesSlideCheckbox: {
      position: 'absolute !important',
      top: 0,
      left: 0,
      opacity: 0,
      cursor: 'pointer',
      transition: 'all 0.3s ease'
    },
    subcategoriesSlideImage: {
      width: '100%',
      height: '100%'
    }
    // buttonUpScroll: {
    //   zIndex: 10,
    //   position: 'fixed',
    //   right: 35,
    //   bottom: 160,
    //   background: COLOR_NEW_PRIMARY
    // }
  })
);

