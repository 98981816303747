import { Box, Button, styled, Typography } from '@mui/material';
import { clearFrequenceScheduledPosts } from 'modules/TabloideDigitalV2/action';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StateProps } from 'store/types';
import Layout from 'utils/routes/components/Layout';

import { AdsDistribution } from '../AdsDistribution';
import { FrequencePosts } from './FrequencePosts';
import { ListScheduleImages } from './ListScheduleImages';
import { RepeatPosts } from './RepeatPosts';

const CustomFooter = styled(Box)({
  width: '100%',
  height: '6.3vh',
  minHeight: '50px',
  position: 'fixed',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 40px',
  gap: '40px',
  bottom: 0,
  background: '#FFF',
  boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)'
});

export function ScheduledPosts() {
  const historys = useHistory();
  const dispatch = useDispatch();

  const [adsDistributionOpen, setAdsDistributionOpen] = useState(false);

  const { selectedScheduledItems, tabloide: tabloideSelected } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
  );

  return (
    <Layout showBtnMenu={false}>
      <Box>
        <Box minHeight="89.59vh" padding="40px 0 106px 0" sx={{ background: '#FFF' }}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box display="flex" flexDirection="column" gap={1} width="52.18rem">
              <Typography fontWeight={600} fontSize="1.25rem" color="#1D1D1D" lineHeight="32px">
                Post agendado
              </Typography>
              <Typography fontWeight={500} fontSize="1rem" color="#616161" lineHeight="26px">
                Escolha uma data para publicar as postagens individualmente.
                <br />
                Você ainda poderá selecionar mais de um canal de distribuição para cada post.
              </Typography>
            </Box>

            <FrequencePosts />

            <RepeatPosts />

            <ListScheduleImages />
          </Box>
        </Box>
        <CustomFooter>
          <Button
            color="info"
            onClick={() => {
              // history.back();
              dispatch(clearFrequenceScheduledPosts());
              historys.push('/estudio/' + tabloideSelected.id_tabloide_digital);
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={selectedScheduledItems.length <= 0}
            variant="contained"
            color="info"
            onClick={() => {
              // historys.push('/distribution-channels');
              // historys.replace('/distribution-channels');
              setAdsDistributionOpen(true);
            }}
          >
            Continuar
          </Button>

          {adsDistributionOpen && (
            <AdsDistribution
              isOpened={adsDistributionOpen}
              uuid={tabloideSelected.uuid}
              offerId={tabloideSelected.id_tabloide_digital}
              offerName={tabloideSelected.nome_tabloide_digital}
              onChangeDistributionState={changeState => setAdsDistributionOpen(changeState)}
              isFromSchedulePost={true}
            />
          )}
        </CustomFooter>
      </Box>
    </Layout>
  );
}
