import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
  withStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Chip,
  DatePicker,
  Select,
  Slider,
  TextField,
} from '../../../utils/components/index';
import {
  BG_PRIMARY,
  COLOR_PRIMARY,
  FAIXA_ETARIA,
  FAIXA_CASHBACK,
  MES,
  SN,
  TERMOMETRO,
} from '../../../utils/helpers/Constants';
import { RemoveAcentos } from '../../../utils/helpers/Format';
import {
  getBairros,
  getDDDs,
  getMunicipios,
  getUFs,
} from '../../VendaMais/action/action';
import {
  changeFiltro,
  changeFiltroSpread,
  changeTabs,
  changeTextSelect,
  clearFilter,
  filtroConsulta,
  getAllDocumentos,
  getListaCategoria1,
  getListaCategoria10,
  getListaCategoria11,
  getListaCategoria12,
  getListaCategoria2,
  getListaCategoria3,
  getListaCategoria4,
  getListaCategoria5,
  getListaCategoria6,
  getListaCategoria7,
  getListaCategoria8,
  getListaCategoria9,
  getListaFiltro,
  getPeopleProspecteiPF,
  getTagOrigem,
  listaLojas,
  listaProdutos,
  // listaTamanho,
  listaTodasProfissao,
  saveListProspecsPF,
  postClusterAudiencia,
  getClusterAudiencia,
  setFiltro,
  showFilter,
} from '../actions/actions';
import ModalSalvarFiltro from './ModalSalvarFiltro';

const drawerWidth = 600;

class Filtro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSalvarFiltro: false,
      isOpenSnackbar: false,
    };
  }

  componentDidMount() {
    this.props.listaLojas();
    this.props.getUFs();
    // this.props.getDDDs();
    this.props.getListaFiltro();
    this.props.filtroConsulta();
    this.props.listaTodasProfissao();
    this.props.listaProdutos();
    // this.props.listaTamanho();
    this.props.getListaCategoria1();
    this.props.getListaCategoria2();
    this.props.getListaCategoria3();
    this.props.getListaCategoria4();
    this.props.getListaCategoria5();
    this.props.getListaCategoria6();
    this.props.getListaCategoria7();
    this.props.getListaCategoria8();
    this.props.getListaCategoria9();
    this.props.getListaCategoria10();
    this.props.getListaCategoria11();
    this.props.getListaCategoria12();
    this.props.getTagOrigem();
    this.props.getClusterAudiencia();
  }

  componentWillUnmount() {
    this.props.clearFilter();
  }

  getMunicipios(options) {
    this.props.getMunicipios(options);
  }

  getBairros(options) {
    this.props.getBairros(options);
  }

  async searchPeople() {
    const { filtroSegmentacao } = this.props,
      objParameters = {
        nome: filtroSegmentacao.nomeConsumidor,
        cidades: filtroSegmentacao.selectedMunicipios
          ? filtroSegmentacao.selectedMunicipios.map(municipio =>
              RemoveAcentos(municipio.cidade)
            )
          : [],
        estados: filtroSegmentacao.selectedEstados
          ? filtroSegmentacao.selectedEstados.map(estado => estado.value)
          : [],
        bairros: filtroSegmentacao.selectedBairros
          ? filtroSegmentacao.selectedBairros.map(bairro => bairro.bairro)
          : [],
        ddd: filtroSegmentacao.selectedDDD
          ? filtroSegmentacao.selectedDDD.map(ddd => ddd.value)
          : [],

        store: filtroSegmentacao.selectedStores
          ? filtroSegmentacao.selectedStores.map(str => str.value)
          : [],
        faixaEtaria: filtroSegmentacao.faixaEtaria,
        percentualDesconto: filtroSegmentacao.percentualDesconto,
        genre: filtroSegmentacao.genre
          ? filtroSegmentacao.genre.split(',')
          : [],
        termometro: filtroSegmentacao.termometro
          ? filtroSegmentacao.termometro
          : [],
        tipoCampanha: filtroSegmentacao.tipoCampanha
          ? filtroSegmentacao.tipoCampanha
          : [],
        sensibilidade_sms: filtroSegmentacao.sensibilidade_sms
          ? filtroSegmentacao.sensibilidade_sms
          : [],
        sensibilidade_email: filtroSegmentacao.sensibilidade_email
          ? filtroSegmentacao.sensibilidade_email
          : [],
        sensibilidade_push: filtroSegmentacao.sensibilidade_push
          ? filtroSegmentacao.sensibilidade_push
          : [],
        sensibilidade_whatsapp: filtroSegmentacao.sensibilidade_whatsapp
          ? filtroSegmentacao.sensibilidade_whatsapp
          : [],
        status: filtroSegmentacao.status ? filtroSegmentacao.status : [],
        dataCadastroInicio: filtroSegmentacao.dataCadastroInicio,
        dataCadastroFim: filtroSegmentacao.dataCadastroFim,
        dataCompraInicio: filtroSegmentacao.dataCompraInicio,
        dataCompraFim: filtroSegmentacao.dataCompraFim,
        qtdeCompras: filtroSegmentacao.qtdeComprasInicio
          ? [
              filtroSegmentacao.qtdeComprasInicio,
              filtroSegmentacao.qtdeComprasFim,
            ]
          : [],
        valorCompras: filtroSegmentacao.valorComprasInicio
          ? [
              filtroSegmentacao.valorComprasInicio,
              filtroSegmentacao.valorComprasFim,
            ]
          : [],
        selectedProfissao: filtroSegmentacao.selectedProfissao
          ? filtroSegmentacao.selectedProfissao.map(pro => pro.value)
          : [],
        selectedTagOrigem: filtroSegmentacao.selectedTagOrigem
          ? filtroSegmentacao.selectedTagOrigem.map(tag => tag.origem_tag)
          : [],
        selectedDocumento: filtroSegmentacao.selectedDocumento
          ? filtroSegmentacao.selectedDocumento.map(doc => doc.documento)
          : [],
        selectedMesNascimento: filtroSegmentacao.selectedMesNascimento
          ? filtroSegmentacao.selectedMesNascimento.map(mes => mes.value)
          : [],
        celular:
          filtroSegmentacao.celular || filtroSegmentacao.celular === 0
            ? filtroSegmentacao.celular
            : [],
        telefone:
          filtroSegmentacao.telefone || filtroSegmentacao.telefone === 0
            ? filtroSegmentacao.telefone
            : [],
        endereco:
          filtroSegmentacao.endereco || filtroSegmentacao.endereco === 0
            ? filtroSegmentacao.endereco
            : [],

        canalPreferecia: filtroSegmentacao.canalPreferecia
          ? filtroSegmentacao.canalPreferecia
          : [],
        email:
          filtroSegmentacao.email || filtroSegmentacao.email === 0
            ? filtroSegmentacao.email
            : [],

        categoria_1: filtroSegmentacao.categoria_1
          ? filtroSegmentacao.categoria_1.map(grupo => grupo.value)
          : [],
        categoria_2: filtroSegmentacao.categoria_2
          ? filtroSegmentacao.categoria_2.map(familia => familia.value)
          : [],
        categoria_3: filtroSegmentacao.categoria_3
          ? filtroSegmentacao.categoria_3.map(condicao => condicao.value)
          : [],
        categoria_4: filtroSegmentacao.categoria_4
          ? filtroSegmentacao.categoria_4.map(cor => cor.value)
          : [],
        categoria_5: filtroSegmentacao.categoria_5
          ? filtroSegmentacao.categoria_5.map(fornecedor => fornecedor.value)
          : [],
        categoria_6: filtroSegmentacao.categoria_6
          ? filtroSegmentacao.categoria_6.map(colecao => colecao.value)
          : [],
        categoria_7: filtroSegmentacao.categoria_7
          ? filtroSegmentacao.categoria_7.map(fornecedor => fornecedor.value)
          : [],
        categoria_8: filtroSegmentacao.categoria_8
          ? filtroSegmentacao.categoria_8.map(colecao => colecao.value)
          : [],
        categoria_9: filtroSegmentacao.categoria_9
          ? filtroSegmentacao.categoria_9.map(fornecedor => fornecedor.value)
          : [],
        categoria_10: filtroSegmentacao.categoria_10
          ? filtroSegmentacao.categoria_10.map(colecao => colecao.value)
          : [],
        categoria_11: filtroSegmentacao.categoria_11
          ? filtroSegmentacao.categoria_11.map(colecao => colecao.value)
          : [],
        categoria_12: filtroSegmentacao.categoria_12
          ? filtroSegmentacao.categoria_12.map(colecao => colecao.value)
          : [],
        ticketMedio: filtroSegmentacao.ticketMedioInicio
          ? [
              filtroSegmentacao.ticketMedioInicio,
              filtroSegmentacao.ticketMedioFim,
            ]
          : [],
        diasCadastro: [
          filtroSegmentacao.diasCadastroInicio,
          filtroSegmentacao.diasCadastroFim,
        ],
        diasCompra: [
          filtroSegmentacao.diasCompraInicio,
          filtroSegmentacao.diasCompraFim,
        ],
        cashback: filtroSegmentacao.cashbackInicio
          ? [filtroSegmentacao.cashbackInicio, filtroSegmentacao.cashbackFim]
          : [],
        dataInicioVencimentoCashback:
          filtroSegmentacao.dataInicioVencimentoCashback,
        dataFimVencimentoCashback: filtroSegmentacao.dataFimVencimentoCashback,
        diasVencimentoCashback: filtroSegmentacao.diasVencimentoCashback,
        aniversarioHoje: filtroSegmentacao.aniversarioHoje,
        aniversarioMes: filtroSegmentacao.aniversarioMes,
      };

    this.props.filtroConsulta(objParameters);
  }

  salvarCluster(cluster) {
    this.props.postClusterAudiencia(cluster);
  }

  salvarFiltro() {
    const { filtroSegmentacao } = this.props;
    const objParameters = {
      nome: filtroSegmentacao.nomeConsumidor,
      documento: filtroSegmentacao.documento,
      cidades: filtroSegmentacao.selectedMunicipios
        ? filtroSegmentacao.selectedMunicipios.map(municipio =>
            RemoveAcentos(municipio.cidade)
          )
        : [],
      estados: filtroSegmentacao.selectedEstados
        ? filtroSegmentacao.selectedEstados.map(estado => estado.value)
        : [],
      bairros: filtroSegmentacao.selectedBairros
        ? filtroSegmentacao.selectedBairros.map(bairro => bairro.bairro)
        : [],
      ddd: filtroSegmentacao.selectedDDD
        ? filtroSegmentacao.selectedDDD.map(ddd => ddd.value)
        : [],

      store: filtroSegmentacao.selectedStores
        ? filtroSegmentacao.selectedStores.map(str => str.value)
        : [],
      faixaEtaria: filtroSegmentacao.faixaEtaria,
      percentualDesconto: filtroSegmentacao.percentualDesconto,
      genre: filtroSegmentacao.genre ? filtroSegmentacao.genre.split(',') : [],
      termometro: filtroSegmentacao.termometro
        ? filtroSegmentacao.termometro
        : [],
      tipoCampanha: filtroSegmentacao.tipoCampanha
        ? filtroSegmentacao.tipoCampanha
        : [],
      sensibilidade_sms: filtroSegmentacao.sensibilidade_sms
        ? filtroSegmentacao.sensibilidade_sms
        : [],
      sensibilidade_email: filtroSegmentacao.sensibilidade_email
        ? filtroSegmentacao.sensibilidade_email
        : [],
      sensibilidade_push: filtroSegmentacao.sensibilidade_push
        ? filtroSegmentacao.sensibilidade_push
        : [],
      sensibilidade_whatsapp: filtroSegmentacao.sensibilidade_whatsapp
        ? filtroSegmentacao.sensibilidade_whatsapp
        : [],
      status: filtroSegmentacao.status ? filtroSegmentacao.status : [],
      dataCadastroInicio: filtroSegmentacao.dataCadastroInicio,
      dataCadastroFim: filtroSegmentacao.dataCadastroFim,
      dataCompraInicio: filtroSegmentacao.dataCompraInicio,
      dataCompraFim: filtroSegmentacao.dataCompraFim,
      qtdeCompras: filtroSegmentacao.qtdeComprasInicio
        ? [
            filtroSegmentacao.qtdeComprasInicio,
            filtroSegmentacao.qtdeComprasFim,
          ]
        : [],
      valorCompras: filtroSegmentacao.valorComprasInicio
        ? [
            filtroSegmentacao.valorComprasInicio,
            filtroSegmentacao.valorComprasFim,
          ]
        : [],
      selectedProfissao: filtroSegmentacao.selectedProfissao
        ? filtroSegmentacao.selectedProfissao.map(pro => pro.value)
        : [],
      selectedTagOrigem: filtroSegmentacao.selectedTagOrigem
        ? filtroSegmentacao.selectedTagOrigem.map(tag => tag.origem_tag)
        : [],
      selectedDocumento: filtroSegmentacao.selectedDocumento
        ? filtroSegmentacao.selectedDocumento.map(doc => doc.documento)
        : [],
      selectedMesNascimento: filtroSegmentacao.selectedMesNascimento
        ? filtroSegmentacao.selectedMesNascimento.map(mes => mes.value)
        : [],
      celular:
        filtroSegmentacao.celular || filtroSegmentacao.celular === 0
          ? filtroSegmentacao.celular
          : [],
      telefone:
        filtroSegmentacao.telefone || filtroSegmentacao.telefone === 0
          ? filtroSegmentacao.telefone
          : [],
      endereco:
        filtroSegmentacao.endereco || filtroSegmentacao.endereco === 0
          ? filtroSegmentacao.endereco
          : [],
      email:
        filtroSegmentacao.email || filtroSegmentacao.email === 0
          ? filtroSegmentacao.email
          : [],

      categoria_1: filtroSegmentacao.categoria_1
        ? filtroSegmentacao.categoria_1.map(grupo => grupo.value)
        : [],
      categoria_2: filtroSegmentacao.categoria_2
        ? filtroSegmentacao.categoria_2.map(familia => familia.value)
        : [],
      categoria_3: filtroSegmentacao.categoria_3
        ? filtroSegmentacao.categoria_3.map(condicao => condicao.value)
        : [],
      categoria_4: filtroSegmentacao.categoria_4
        ? filtroSegmentacao.categoria_4.map(cor => cor.value)
        : [],
      categoria_5: filtroSegmentacao.categoria_5
        ? filtroSegmentacao.categoria_5.map(fornecedor => fornecedor.value)
        : [],
      categoria_6: filtroSegmentacao.categoria_6
        ? filtroSegmentacao.categoria_6.map(colecao => colecao.value)
        : [],
      categoria_7: filtroSegmentacao.categoria_7
        ? filtroSegmentacao.categoria_7.map(fornecedor => fornecedor.value)
        : [],
      categoria_8: filtroSegmentacao.categoria_8
        ? filtroSegmentacao.categoria_8.map(colecao => colecao.value)
        : [],
      categoria_9: filtroSegmentacao.categoria_9
        ? filtroSegmentacao.categoria_9.map(fornecedor => fornecedor.value)
        : [],
      categoria_10: filtroSegmentacao.categoria_10
        ? filtroSegmentacao.categoria_10.map(colecao => colecao.value)
        : [],
      categoria_11: filtroSegmentacao.categoria_11
        ? filtroSegmentacao.categoria_11.map(colecao => colecao.value)
        : [],
      categoria_12: filtroSegmentacao.categoria_12
        ? filtroSegmentacao.categoria_12.map(colecao => colecao.value)
        : [],
      ticketMedio: filtroSegmentacao.ticketMedioInicio
        ? [
            filtroSegmentacao.ticketMedioInicio,
            filtroSegmentacao.ticketMedioFim,
          ]
        : [],
      diasCadastro: [
        filtroSegmentacao.diasCadastroInicio,
        filtroSegmentacao.diasCadastroFim,
      ],
      diasCompra: [
        filtroSegmentacao.diasCompraInicio,
        filtroSegmentacao.diasCompraFim,
      ],
      cashback: filtroSegmentacao.cashbackInicio
        ? [filtroSegmentacao.cashbackInicio, filtroSegmentacao.cashbackFim]
        : [],
      dataInicioVencimentoCashback:
        filtroSegmentacao.dataInicioVencimentoCashback,
      dataFimVencimentoCashback: filtroSegmentacao.dataFimVencimentoCashback,
      diasVencimentoCashback: filtroSegmentacao.diasVencimentoCashback,
      aniversarioHoje: filtroSegmentacao.aniversarioHoje,
      aniversarioMes: filtroSegmentacao.aniversarioMes,
      nameList: this.state.filterName,
      idCluster: Boolean(this.state.clusterName)
        ? this.state.clusterName.ID_CLUSTER_AUDIENCIA
        : null,
      namecluster: Boolean(this.state.clusterName)
        ? this.state.clusterName.NOME
        : null,
    };
    this.setState({ modalSalvarFiltro: false });
    this.props.saveListProspecsPF(
      objParameters,
      filtroSegmentacao,
      response => {
        if (response) {
          this.selecionaLista(this.props.novaLista);
          this.setState({ isOpenSnackbar: true });
          this.props.showFilter(false);
        }
      }
    );
  }

  selecionaLista(lista) {
    if (lista) {
      const filtro_completo = JSON.parse(lista.filtro_completo);
      const filtro = JSON.parse(lista.filtro);
      this.props.filtroConsulta(filtro);
      this.props.changeFiltroSpread(filtro_completo);
      this.props.getPeopleProspecteiPF(filtro);
    } else {
      this.props.filtroConsulta({});
      this.props.getPeopleProspecteiPF();
      this.props.changeFiltroSpread();
    }

    this.props.setFiltro(lista);
  }

  numberToReal(numero) {
    let nmr = numero.toFixed(2).split('.');
    nmr[0] = 'R$ ' + nmr[0].split(/(?=(?:...)*$)/).join('.');
    return nmr[0];
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  render() {
    const { classes, children, showFiltro, listDDDs, clustersAudiencia } =
      this.props;
    const {
      selectedEstados,
      selectedMunicipios,
      selectedBairros,
      selectedDDD,
      faixaEtaria,
      percentualDesconto,
      genre,
      status,
      termometro,
      selectedDocumento,
      selectedTagOrigem,
      selectedProfissao,
      selectedMesNascimento,
      dataCompraInicio,
      dataCompraFim,
      dataCadastroInicio,
      dataCadastroFim,
      categoria_1,
      categoria_2,
      categoria_3,
      categoria_4,
      categoria_5,
      categoria_6,
      categoria_7,
      categoria_8,
      categoria_9,
      categoria_10,
      categoria_11,
      categoria_12,
      celular,
      telefone,
      endereco,
      aniversarioHoje,
      aniversarioMes,
      email,
      valorComprasInicio,
      valorComprasFim,
      qtdeComprasInicio,
      qtdeComprasFim,
      ticketMedioInicio,
      ticketMedioFim,
      diasCadastroInicio,
      diasCadastroFim,
      diasCompraInicio,
      diasCompraFim,
      cashbackInicio,
      cashbackFim,
      dataInicioVencimentoCashback,
      dataFimVencimentoCashback,
      diasVencimentoCashback,
      selectedStores,
      canalPreferecia,
    } = this.props.filtroSegmentacao;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: showFiltro,
          })}
        >
          <div className={classes.drawerHeader} />
          {children}
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={showFiltro}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List className={classes.margin}>
            <div className={classes.drawerHeader}>
              <div style={{ position: 'fixed' }}>
                <IconButton onClick={() => this.props.showFilter(false)}>
                  <ClearIcon color="primary" />
                </IconButton>
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Localização do Consumidor
              </Typography>
              <div className={classes.test}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Estado
                  </Typography>
                  <Select
                    onChange={value => {
                      this.props.changeFiltro('selectedEstados', value);
                      this.getMunicipios(value);
                    }}
                    value={selectedEstados}
                    options={this.props.listUFs.map(estado => ({
                      value: estado.sigla,
                      label: estado.nome,
                      id: estado.id,
                    }))}
                    multiple
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Cidade
                  </Typography>
                  <Select
                    value={selectedMunicipios}
                    onChange={value => {
                      this.props.changeFiltro('selectedMunicipios', value);
                      this.getBairros(value);
                    }}
                    disabled={selectedEstados ? !selectedEstados.length : true}
                    multiple
                    options={this.props.listMunicipios}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Bairro
                  </Typography>
                  <Select
                    value={selectedBairros}
                    onChange={value =>
                      this.props.changeFiltro('selectedBairros', value)
                    }
                    disabled={
                      selectedMunicipios ? !selectedMunicipios.length : true
                    }
                    multiple
                    options={this.props.listBairros}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    DDD
                  </Typography>
                  <Select
                    value={selectedDDD}
                    onChange={value =>
                      this.props.changeFiltro('selectedDDD', value)
                    }
                    multiple
                    options={listDDDs}
                  />
                </div>
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Características do Consumidor
              </Typography>
              <div className={classes.test}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Nome do consumidor
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    size="lg"
                    placeholder="Pesquisar consumidor..."
                    autoComplete="false"
                    onChange={({ target: { value } }) =>
                      this.props.changeFiltro('nomeConsumidor', value)
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    CPFs
                  </Typography>

                  <Select
                    placeholder="Digite o CPF..."
                    value={selectedDocumento}
                    labelKey="documento"
                    valueKey="documento"
                    onInputChange={text => this.props.getAllDocumentos(text)}
                    onChange={value =>
                      this.props.changeFiltro('selectedDocumento', value)
                    }
                    multiple
                    options={this.props.listDocumentos}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Mês de Aniversário
                  </Typography>
                  <Select
                    placeholder="Selecionar mês..."
                    value={selectedMesNascimento}
                    onChange={value =>
                      this.props.changeFiltro('selectedMesNascimento', value)
                    }
                    multiple
                    options={MES}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Profissão
                  </Typography>

                  <Select
                    value={selectedProfissao}
                    onChange={value =>
                      this.props.changeFiltro('selectedProfissao', value)
                    }
                    multiple
                    options={this.props.listTodasProfissao.map(profissao => ({
                      value: profissao.id_pessoa_profissao,
                      label: profissao.descricao,
                    }))}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Tag Origem
                  </Typography>

                  <Select
                    value={selectedTagOrigem}
                    labelKey="origem_tag"
                    valueKey="origem_tag"
                    onChange={value =>
                      this.props.changeFiltro('selectedTagOrigem', value)
                    }
                    multiple
                    options={this.props.listTagOrigem}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Faixa Etária
                  </Typography>

                  <Slider
                    style={{ color: COLOR_PRIMARY }}
                    value={!faixaEtaria.length > 0 ? [0, 100] : faixaEtaria}
                    onChange={(event, newValue) =>
                      this.props.changeFiltro('faixaEtaria', newValue)
                    }
                    marks={FAIXA_ETARIA}
                    min={0}
                    max={100}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Data de Cadastro
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DatePicker
                      className={classes.dataPicker}
                      label="De"
                      value={dataCadastroInicio}
                      format="DD/MM/YYYY"
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataCadastroInicio',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />
                    <DatePicker
                      className={classes.dataPicker}
                      label="Até"
                      value={dataCadastroFim}
                      format="DD/MM/YYYY"
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataCadastroFim',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Sexo
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Chip
                      active={genre === null}
                      label="Todos"
                      clickable
                      onClick={() => this.props.changeFiltro('genre', null)}
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                    <Chip
                      active={genre !== null && genre === 'Masculino,M'}
                      label="Masculino"
                      clickable
                      onClick={() =>
                        this.props.changeFiltro('genre', 'Masculino,M')
                      }
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                    <Chip
                      clickable
                      active={genre !== null && genre === 'Feminino,F'}
                      label="Feminino"
                      onClick={() =>
                        this.props.changeFiltro('genre', 'Feminino,F')
                      }
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Status
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Chip
                      clickable
                      active={status === null}
                      label="Todos"
                      onClick={() => this.props.changeFiltro('status', null)}
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                    <Chip
                      clickable
                      active={status !== null && status === 1}
                      label="Ativo"
                      onClick={() => this.props.changeFiltro('status', 1)}
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                    <Chip
                      clickable
                      active={status !== null && status === 2}
                      label="Inativo"
                      onClick={() => this.props.changeFiltro('status', 2)}
                      color="primary"
                      className={classes.chip}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Tem email
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.map((mail, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={email === mail.value}
                        label={mail.label}
                        onClick={() =>
                          this.props.changeFiltro('email', mail.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Tem telefone
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.map((tell, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={telefone === tell.value}
                        label={tell.label}
                        onClick={() =>
                          this.props.changeFiltro('telefone', tell.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Tem celular
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.map((cell, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={celular === cell.value}
                        label={cell.label}
                        onClick={() =>
                          this.props.changeFiltro('celular', cell.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Tem endereco
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.map((end, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={endereco === end.value}
                        label={end.label}
                        onClick={() =>
                          this.props.changeFiltro('endereco', end.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Canal de preferecia
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {[
                      { label: 'Todos', value: null },
                      { label: 'SMS', value: 'sms' },
                      { label: 'E-mail', value: 'email' },
                    ].map((end, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={canalPreferecia === end.value}
                        label={end.label}
                        onClick={() =>
                          this.props.changeFiltro('canalPreferecia', end.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Recência, Freqüência e Valor
              </Typography>
              <div className={classes.test}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 15,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Lojas
                  </Typography>
                  <Select
                    value={selectedStores}
                    onChange={value =>
                      this.props.changeFiltro('selectedStores', value)
                    }
                    multiple
                    options={this.props.listLojas}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',

                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Data da Última Compra
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DatePicker
                      className={classes.dataPicker}
                      label="De"
                      format="DD/MM/YYYY"
                      value={dataCompraInicio}
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataCompraInicio',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />

                    <DatePicker
                      className={classes.dataPicker}
                      label="Até"
                      format="DD/MM/YYYY"
                      value={dataCompraFim}
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataCompraFim',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Quantidade de Compras
                  </Typography>
                  <section className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'qtdeComprasInicio',
                          target.value
                        );
                      }}
                      value={qtdeComprasInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro('qtdeComprasFim', target.value);
                      }}
                      value={qtdeComprasFim}
                      autoComplete="none"
                    />
                  </section>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Valor Gasto
                  </Typography>
                  <section className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'valorComprasInicio',
                          target.value
                        );
                      }}
                      value={valorComprasInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'valorComprasFim',
                          target.value
                        );
                      }}
                      value={valorComprasFim}
                      autoComplete="none"
                    />
                  </section>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Ticket médio
                  </Typography>
                  <section className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'ticketMedioInicio',
                          target.value
                        );
                      }}
                      value={ticketMedioInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro('ticketMedioFim', target.value);
                      }}
                      value={ticketMedioFim}
                      autoComplete="none"
                    />
                  </section>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Percentual de desconto
                  </Typography>

                  <Slider
                    style={{ color: COLOR_PRIMARY }}
                    value={
                      !percentualDesconto || !percentualDesconto.length > 0
                        ? [0, 100]
                        : percentualDesconto
                    }
                    onChange={(event, newValue) =>
                      this.props.changeFiltro('percentualDesconto', newValue)
                    }
                    marks={FAIXA_ETARIA}
                    min={0}
                    max={100}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Termômetro
                  </Typography>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {TERMOMETRO.map((term, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={termometro === term.value}
                        label={term.label}
                        onClick={() =>
                          this.props.changeFiltro('termometro', term.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Automações
              </Typography>
              <div className={classes.test}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Aniversariantes de hoje
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.slice(1, 3).map((niver, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={aniversarioHoje === niver.value}
                        label={niver.label}
                        onClick={() =>
                          this.props.changeFiltro(
                            'aniversarioHoje',
                            niver.value
                          )
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Aniversariantes do mês
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {SN.slice(1, 3).map((niver, index) => (
                      <Chip
                        key={index}
                        clickable
                        active={aniversarioMes === niver.value}
                        label={niver.label}
                        onClick={() =>
                          this.props.changeFiltro('aniversarioMes', niver.value)
                        }
                        color="primary"
                        className={classes.chip}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Dias do cadastro
                  </Typography>
                  <section className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'diasCadastroInicio',
                          target.value
                        );
                      }}
                      value={diasCadastroInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'diasCadastroFim',
                          target.value
                        );
                      }}
                      value={diasCadastroFim}
                      autoComplete="none"
                    />
                  </section>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    width: '100%',
                  }}
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Dias da última compra
                  </Typography>
                  <section className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro(
                          'diasCompraInicio',
                          target.value
                        );
                      }}
                      value={diasCompraInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro('diasCompraFim', target.value);
                      }}
                      value={diasCompraFim}
                      autoComplete="none"
                    />
                  </section>
                </div>
              </div>
            </div>
            <Box className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Cashback
              </Typography>
              <Box className={classes.test}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Cashback
                  </Typography>
                  <Box className={classes.textField}>
                    <TextField
                      type="number"
                      label="De"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro('cashbackInicio', target.value);
                      }}
                      value={cashbackInicio}
                      autoComplete="none"
                    />
                    <TextField
                      type="number"
                      label="Até"
                      className="form-control"
                      onChange={({ target }) => {
                        this.props.changeFiltro('cashbackFim', target.value);
                      }}
                      value={cashbackFim}
                      autoComplete="none"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  marginTop="25px"
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Data de vencimento
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <DatePicker
                      className={classes.dataPicker}
                      label="De"
                      format="DD/MM/YYYY"
                      value={dataInicioVencimentoCashback}
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataInicioVencimentoCashback',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />
                    <DatePicker
                      className={classes.dataPicker}
                      label="Até"
                      format="DD/MM/YYYY"
                      value={dataFimVencimentoCashback}
                      onChange={date =>
                        this.props.changeFiltro(
                          'dataFimVencimentoCashback',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  marginTop="25px"
                >
                  <Typography
                    className={classes.label}
                    variant="subtitle1"
                    noWrap
                  >
                    Dias para vencimento
                  </Typography>
                  <Slider
                    style={{ color: COLOR_PRIMARY }}
                    value={
                      !diasVencimentoCashback || !diasVencimentoCashback.length
                        ? [1, 200]
                        : diasVencimentoCashback
                    }
                    onChange={(event, newValue) =>
                      this.props.changeFiltro(
                        'diasVencimentoCashback',
                        newValue
                      )
                    }
                    marks={FAIXA_CASHBACK}
                    min={1}
                    max={200}
                  />
                </Box>
              </Box>
            </Box>

            <div style={{ marginBottom: '10vh' }} className={classes.section}>
              <Typography className={classes.title} variant="h5" noWrap>
                Características do Produto
              </Typography>
              <div className={classes.test}>
                {this.props.listCategoria1.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',

                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 1
                    </Typography>

                    <Select
                      value={categoria_1}
                      onChange={value =>
                        this.props.changeFiltro('categoria_1', value)
                      }
                      multiple
                      options={this.props.listCategoria1.map(grupo => ({
                        value: grupo.categoria_1,
                        label: grupo.desc_categoria_1,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria2.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 20,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 2
                    </Typography>
                    <Select
                      value={categoria_2}
                      onChange={value =>
                        this.props.changeFiltro('categoria_2', value)
                      }
                      multiple
                      options={this.props.listCategoria2.map(familia => ({
                        value: familia.categoria_2,
                        label: familia.desc_categoria_2,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria3.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 20,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 3
                    </Typography>
                    <Select
                      value={categoria_3}
                      onChange={option =>
                        this.props.changeFiltro('categoria_3', option)
                      }
                      options={this.props.listCategoria3.map(condicao => ({
                        value: condicao.categoria_3,
                        label: condicao.desc_categoria_3,
                      }))}
                      multiple
                    />
                  </div>
                )}

                {this.props.listCategoria4.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 20,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 4
                    </Typography>
                    <Select
                      value={categoria_4}
                      onChange={value =>
                        this.props.changeFiltro('categoria_4', value)
                      }
                      multiple
                      options={this.props.listCategoria4.map(cor => ({
                        value: cor.categoria_4,
                        label: cor.desc_categoria_4,
                      }))}
                    />
                  </div>
                )}

                {this.props.listCategoria5.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 20,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 5
                    </Typography>
                    <Select
                      value={categoria_5}
                      onChange={value =>
                        this.props.changeFiltro('categoria_5', value)
                      }
                      multiple
                      options={this.props.listCategoria5.map(fornecedor => ({
                        value: fornecedor.categoria_5,
                        label: fornecedor.desc_categoria_5,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria6.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 6
                    </Typography>
                    <Select
                      value={categoria_6}
                      onChange={value =>
                        this.props.changeFiltro('categoria_6', value)
                      }
                      multiple
                      options={this.props.listCategoria6.map(colecao => ({
                        value: colecao.categoria_6,
                        label: colecao.desc_categoria_6,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria7.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 7
                    </Typography>

                    <Select
                      value={categoria_7}
                      onChange={value =>
                        this.props.changeFiltro('categoria_7', value)
                      }
                      multiple
                      options={this.props.listCategoria7.map(tamanho => ({
                        value: tamanho.categoria_7,
                        label: tamanho.desc_categoria_7,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria8.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 8
                    </Typography>

                    <Select
                      value={categoria_8}
                      onChange={value =>
                        this.props.changeFiltro('categoria_8', value)
                      }
                      multiple
                      options={this.props.listCategoria8.map(fornecedor => ({
                        value: fornecedor.categoria_8,
                        label: fornecedor.desc_categoria_8,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria9.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 9
                    </Typography>

                    <Select
                      value={categoria_9}
                      onChange={value =>
                        this.props.changeFiltro('categoria_9', value)
                      }
                      multiple
                      options={this.props.listCategoria9.map(fornecedor => ({
                        value: fornecedor.categoria_9,
                        label: fornecedor.desc_categoria_9,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria10.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,

                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 10
                    </Typography>

                    <Select
                      value={categoria_10}
                      onChange={value =>
                        this.props.changeFiltro('categoria_10', value)
                      }
                      multiple
                      options={this.props.listCategoria10.map(fornecedor => ({
                        value: fornecedor.categoria_10,
                        label: fornecedor.desc_categoria_10,
                      }))}
                    />
                  </div>
                )}

                {this.props.listCategoria11.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,

                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 11
                    </Typography>

                    <Select
                      value={categoria_11}
                      onChange={value =>
                        this.props.changeFiltro('categoria_11', value)
                      }
                      multiple
                      options={this.props.listCategoria11.map(fornecedor => ({
                        value: fornecedor.categoria_11,
                        label: fornecedor.desc_categoria_11,
                      }))}
                    />
                  </div>
                )}
                {this.props.listCategoria12.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                      marginBottom: 60,
                      width: '100%',
                    }}
                  >
                    <Typography
                      className={classes.label}
                      variant="subtitle1"
                      noWrap
                    >
                      Característica 12
                    </Typography>

                    <Select
                      value={categoria_12}
                      onChange={value =>
                        this.props.changeFiltro('categoria_12', value)
                      }
                      multiple
                      options={this.props.listCategoria12.map(fornecedor => ({
                        value: fornecedor.categoria_12,
                        label: fornecedor.desc_categoria_12,
                      }))}
                    />
                  </div>
                )}
              </div>
            </div>
          </List>
          <List style={{ zIndex: 1 }} className={classes.listActions}>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              paddingX="3vw"
              paddingY="1vh"
            >
              <Box display="flex" justifyContent="space-between" width="35%">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.props.clearFilter(() => this.searchPeople())
                  }
                >
                  Limpar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.searchPeople()}
                >
                  Aplicar
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ modalSalvarFiltro: true })}
              >
                Salvar
              </Button>
            </Box>
          </List>
        </Drawer>

        <ModalSalvarFiltro
          open={this.state.modalSalvarFiltro}
          onClose={() => this.setState({ modalSalvarFiltro: false })}
          filter={this.state.filterName}
          clusters={clustersAudiencia}
          onChange={(state, value) => this.onChange(state, value)}
          onSave={() => this.salvarFiltro()}
          onSaveCluster={cluster => this.salvarCluster(cluster)}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  listActions: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    width: 580,
  },

  margin: {
    marginTop: 90,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'flex-end',
  },
  test: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(7),
  },

  label: { fontWeight: 'bold', marginBottom: 10, color: BG_PRIMARY },
  dataPicker: { width: '49%' },
  textField: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,49%)',
    gap: '2%',
  },

  title: {
    fontWeight: 'bold',
    color: COLOR_PRIMARY,
  },
  section: {
    margin: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    top: 50,
    right: 50,
  },
  drawerPaper: {
    width: drawerWidth,
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  toolbar: theme.mixins.toolbar,
});

const mapStateToProps = ({
  VendaMaisReducer,
  CampaignReducer,
  PerfilConsumoReducer,
  InsightsReducer,
}) => ({
  ...VendaMaisReducer,
  ...CampaignReducer,
  ...InsightsReducer,
  ...PerfilConsumoReducer,
});

const mapDispatchToProps = {
  getDDDs,
  showFilter,
  getListaFiltro,
  saveListProspecsPF,
  clearFilter,
  filtroConsulta,
  getUFs,
  getMunicipios,
  getBairros,
  listaLojas,
  listaTodasProfissao,
  changeFiltro,
  changeTextSelect,
  changeTabs,
  listaProdutos,
  // listaTamanho,
  getListaCategoria1,
  getListaCategoria2,
  getListaCategoria3,
  getListaCategoria4,
  getListaCategoria5,
  getListaCategoria6,
  getListaCategoria7,
  getListaCategoria8,
  getListaCategoria9,
  getListaCategoria10,
  getListaCategoria11,
  getListaCategoria12,
  getAllDocumentos,
  getTagOrigem,
  changeFiltroSpread,
  getPeopleProspecteiPF,
  setFiltro,
  postClusterAudiencia,
  getClusterAudiencia,
};

const FiltroConnect = connect(mapStateToProps, mapDispatchToProps)(Filtro);

export default withStyles(styles)(FiltroConnect);
