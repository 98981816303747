const INITIAL_STATE = {
  listProspeccao: [],
  listEnrichment: [],
  priceEnrichement: { unit_price: 0, total_price: 0, quantity: 0 },
  loadListEnrichment: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_PROSPECCAO':
      return { ...state, listProspeccao: action.payload };

    case 'PRICE_ENRICHMENT':
      return {
        ...state,
        priceEnrichement: { ...state.priceEnrichement, ...action.payload },
      };

    case 'LIST_ENRICHMENT':
      return {
        ...state,
        listEnrichment: action.payload,
        loadListEnrichment: false,
      };

    case 'LOAD_LIST_ENRICHMENT':
      return {
        ...state,
        loadListEnrichment: true,
      };

    default:
      return state;
  }
};
