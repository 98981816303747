import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Autocomplete } from "../../../../../../utils/components/AutoComplete/AutoCompleteV2";
import {ChatBotFileData} from '../../../../../../types/chatbot'
import { useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import {MatchContatFile} from "../../../MathContatFile"
import { filter, isEmpty, map } from "lodash";
import Alert from "@mui/material/Alert";
import { useStyles } from "./styles";

type MatchProps = {
  colums?: { checked: boolean; column: string; label: string }[];
  headers?: string[];
};

export function Match({ colums, headers }: MatchProps) {
  const formik = useFormikContext<ChatBotFileData>();
  const styles = useStyles();

  const hasDocument = useMemo(() => {
    return headers?.some(
      (header) =>
        String(header).toUpperCase().includes("NUMERO")
    );
  }, [headers]);

  const handleChangeOptions = useCallback(
    (header: string, item: any) => {
      const tempColumns = formik.values.columns;
      const objectCustomData = { label: header, column: item.column };
      const tempColumn = tempColumns.findIndex(
        (column) => column.label === header
      );

      if (tempColumn >= 0 && !isEmpty(tempColumns)) {
        tempColumns[tempColumn] = objectCustomData;
      } else {
        tempColumns!.push(objectCustomData);
      }

      formik.setFieldValue(
        "columns",
        filter(tempColumns, (column) => Boolean(column.column))
      );
    },
    [formik]
  )

  const RenderOptions = useCallback(
    (header: string) => {
      const defaultValue = colums?.find(({ label }) => header === label);
      return (
        <MatchContatFile
          header={header}
          defaultValue={defaultValue}
          options={colums}
          onChange={(option) => handleChangeOptions(header, option)}
        />
      );
    },
    [colums, handleChangeOptions]
  );

  return (
    <Stack spacing={4}>
      <Typography className={styles.titulo}>
        Match
      </Typography>
      {!hasDocument ? (
        <Alert variant="filled" severity="warning">
          Não identificamos a coluna <b>Numero</b>.
        </Alert>
      ) : null}
      <Autocomplete
        size="small"
        label="Identificador"
        options={colums}
        getOptionLabel={(option) => option.column}
        onChange={(_, option) => {
          formik.setFieldValue("identificator", option);
        }}
        value={formik.values.identificator}
        className={styles.auto}
      />
      <Divider />
      {map(headers, (header) => RenderOptions(header))}
    </Stack>
  );
}