import moment, { Moment } from 'moment';
import moment_tz from 'moment-timezone';
import { OptionsObject, SnackbarMessage } from 'notistack';

import {
  ISocialNetworkFormData,
  SelectedShareableItemProps
} from '../../../../modules/TabloideDigitalV2/reducer/types';
import {
  AMBIENTE,
  CELULARES,
  DATASALES_CRM_API,
  HOST_TRACKER,
  PARETO
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';

export const socialNetworkState = {
  message: '',
  files: [],
  selectedCelular: CELULARES,
  optionList: '',
  nomeCampanha: '',
  dataInicioCampanha: moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
  dataFimCampanha: moment_tz(moment().add(7, 'days'))
    .tz('America/Sao_Paulo')
    .format('YYYY-MM-DD HH:mm:ss'),
  pareto: PARETO[0],
  key: 6,
  numero: '',
  numerosList: [],
  hostTracker: HOST_TRACKER[0]
};

type DistributionSocialNetworkPostProps = {
  values: ISocialNetworkFormData;
  offerId: number | undefined;
  carouselSelectedItems: SelectedShareableItemProps;
};

const distributionSocialNetworkPost = async ({
  values,
  offerId,
  carouselSelectedItems
}: DistributionSocialNetworkPostProps) => {
  const state = socialNetworkState;
  const imageId = carouselSelectedItems[0].id;
  const imageUrl = carouselSelectedItems[0].link
    ? carouselSelectedItems[0].link
    : carouselSelectedItems[0].image_url;
  const preview_url = carouselSelectedItems[0].preview_url;
  const platforms = values.platforms.map(item => item.value);
  const requestSends = platforms.map((platform: string) => {
    if (platform === 'facebook') {
      return api(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/facebook`,
        {
          name: 'Post Facebook',
          message: values.post,
          send_date: values.scheduleDate
            ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
            : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
          start_date_campaign: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
          end_date_campaign: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
          is_midia: !!imageUrl,
          url: imageUrl,
          ...( preview_url && {preview_url: preview_url} ),
          advertising_id: offerId,
          image_advertisement_id: imageId,
          page_id: values.profile.page_id,
          fb_id: values.profile.fb_user_id
        },
        'POST',
        null,
        null,
        false
      );
    } else if (platform === 'instagram') {
      return api(
        `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/instagram`,
        {
          name: 'Post Instagram',
          message: values.post,
          send_date: values.scheduleDate
            ? moment(values.scheduleDate).format('DD/MM/YYYY HH:mm')
            : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
          start_date_campaign: moment(state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
          end_date_campaign: moment(state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
          is_midia: !!imageUrl,
          url: imageUrl,
          ...( preview_url && {preview_url: preview_url} ),
          advertising_id: offerId,
          image_advertisement_id: imageId,
          page_id: values.profile.page_id,
          fb_id: values.profile.fb_user_id
        },
        'POST',
        null,
        null,
        false
      );
    }
  });

  return requestSends;
};

export default distributionSocialNetworkPost;
