import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  wrapDialog: {
    borderRadius: '8px',
    fontFamily: 'Montserrat, sans-serif',
    letterSpacing: '0.065em',
  },
  dialogTitle: {
    paddingBottom: '12px',
    '& > h2': {
      fontWeight: 800,
      fontSize: '24px !important',
    },
  },
  wrapContent: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 500,

    color: '#616161',
    padding: '12px 32px 24px 32px',
  },
  content01: {
    padding: '0 !important',
  },
  content02: {
    padding: '24px 0 0 0',
  },
  row: {
    margin: '0 22px',
  },
  wrapActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
  },
  cancel: {
    fontSize: '16px',
    padding: '12px',
    background: 'none',
    color: '#616161',
    letterSpacing: '0.065em',
  },
  confirm: {
    fontSize: '16px',
    padding: '12px',
    letterSpacing: '0.065em',
  },
});
