/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
} from '@material-ui/core';
import {
  Modal,
} from '../../../../utils/components';
import { 
} from '../../action';
import Alert from '../../../../utils/helpers/Alert';

const alerta = new Alert();

class PixelixeIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
    }
  }

  header(props, state) {
    return (
      <Fragment>
        Edição Avançada
      </Fragment>
    );
  }

  step1(props, state) {
    return (
      <iframe
        title={"iFrame2"}
        src={this.props.src}
        frameBorder="0"
        width="100%"
        height="800"
        allowtransparency="true"
      />
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const {
      activeStep
    } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'xl'}
          onClose={() => alerta.alertConfirm(
            `Deseja realmente fechar a janela? Caso tenha salvado uma edição, não feche e aguarde a mensagem de confirmação!`,
            () => this.props.onClose()
          )}
          header={this.header(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10,
  },
});

const mapStateToProps = ({
  TabloideDigitalReducer
}) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  
};

const PixelixeIframeConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PixelixeIframe);

export default withStyles(styles)(PixelixeIframeConnect);
