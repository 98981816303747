/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Typography,
  withStyles,
  Button,
  Icon,
  IconButton,
} from '@material-ui/core';
import {
  getFamilias,
  getProdutosByFamilia,
  postWhiteListItem,
} from '../action/index';
import {
  Modal,
  AutoCompleteLab,
  DialogAlert,
  CustomTable,
} from '../../../utils/components';

import '@webscopeio/react-textarea-autocomplete/style.css';

class Blacklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whitelist: { familia: {}, produtos: [] },
      showSnackbar: false,
    };

    // this.addProdutos = this.addProdutos.bind(this);
    // this.addFamilia = this.addFamilia.bind(this);
  }

  componentDidMount() {
    // this.props.getBlacklist();
    this.props.getFamilias();

    // this.props.listStore();
  }

  header(props, state) {
    return <Fragment>Whitelist</Fragment>;
  }

  onChange = (state, value, callback) => {
    const { whitelist } = this.state;
    this.setState({ whitelist: { ...whitelist, [state]: value } }, callback);
  };

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        <div />
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() =>
            this.setState({ showSnackbar: true }, () => props.onClose())
          }
        >
          Salvar
        </Button>
      </div>
    );
  }

  handleSubmit = () => {
    const { produtos, familia } = this.state.whitelist;

    if (familia && produtos.length === 0) {
      const data = {
        familia: { ...familia, nome_familia: familia.label },
      };
      this.props.postWhiteListItem(data);
    } else if (familia && produtos.length > 0) {
      const data = produtos.map(item => ({
        ...item,
        nome_produto: item.label,
        nome_familia: item.familia,
      }));
      this.props.postWhiteListItem({ produtos: data });
    }

    this.setState({ whitelist: { familia: {}, produtos: [] } });
  };

  render() {
    const {
      onClose,
      familias,
      produtos,
      load_familias,
      load_produtos,
      blacklist,
      load_blacklist,
      type,
    } = this.props;
    const { familia, showDialog, whitelist } = this.state;

    return (
      <Fragment>
        <Modal
          size="md"
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <section
            style={{
              // padding: "2rem 20rem",
              display: 'flex',
              flex: 1,
              gap: '2rem',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <AutoCompleteLab
                  disabled={load_familias}
                  groupLabel="label"
                  label="Categorias"
                  placeholder="Pesquisar categorias"
                  loading={load_familias}
                  value={this.state.familia}
                  getOptionSelected={(option, value) =>
                    option.id_familia === value.id_familia
                  }
                  getOptionLabel={option => option.label}
                  onChange={(event, value) =>
                    this.onChange(
                      'familia',
                      value,
                      () =>
                        value && this.props.getProdutosByFamilia(value.label)
                    )
                  }
                  options={familias}
                  style={{ marginRight: 15 }}
                />
                <AutoCompleteLab
                  label="Produtos"
                  multiple
                  disableCloseOnSelect
                  placeholder="Pesquisar produtos"
                  loading={load_produtos}
                  disabled={load_produtos || !familias}
                  value={this.state.produto}
                  getOptionSelected={(option, value) =>
                    option.id_produto === value.id_produto
                  }
                  getOptionLabel={option => option.label}
                  // onChange={(event, value) =>
                  //   this.setState({ produto: value })
                  // }
                  // onInputChange={(event, value, reason) =>
                  //   this.props.getPessoas({ cpf: value })
                  // }
                  options={produtos}
                  onChange={(event, value) => this.onChange('produtos', value)}
                />
              </div>
              <div style={{ width: '10%' }}>
                <Button
                  variant="text"
                  color="primary"
                  // disabled={
                  //   this.props.type === 0
                  //     ? !familia
                  //       ? true
                  //       : false
                  //     : this.props.type === 1
                  //     ? !nomeProduto
                  //       ? true
                  //       : false
                  //     : false
                  // }
                  onClick={this.handleSubmit}
                >
                  Adicionar
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <CustomTable
                title=""
                columnsButton
                pageSize={5}
                paging
                // search
                actions={[
                  // {
                  //   icon: "arrow_downward",
                  //   tooltip: "Download",
                  //   onClick: (event, rowData) => {
                  //     window.open(rowData.link_template, "_blank");
                  //   }
                  // },
                  {
                    icon: () => <Icon color="action">edit</Icon>,
                    tooltip: 'Editar',
                  },
                  {
                    icon: () => <Icon color="error">delete</Icon>,
                    tooltip: 'Excluir',
                  },
                ]}
                isLoading={load_blacklist}
                headerStyle={{ backgroundColor: '#fff', zIndex: 0 }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  backgroundColor: '#fff',
                }}
                columns={[
                  {
                    title: 'Nome do Produto',
                    field: 'nome_produto',
                    hidden: this.props.type === 0 ? true : false,
                  },
                  { title: 'Categoria', field: 'nome_familia' },
                ]}
                data={[]}
              />
            </div>
          </section>
        </Modal>
        {showDialog && (
          <DialogAlert
            open={showDialog}
            title="Deseja dar desconto em todos produtos dessa familia?"
            onClick={() =>
              this.setState({ showDialog: false }, () => this.addFamilia())
            }
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ OneToOneReducer }) => ({
  ...OneToOneReducer,
});
const mapDispatchToProps = {
  getFamilias,
  getProdutosByFamilia,
  postWhiteListItem,
};

const BlacklistConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Blacklist);

export default withStyles(styles)(BlacklistConnect);
