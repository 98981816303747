import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    content: {
      padding: 0,
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    radioGroup: {
      display: 'flex',
      maxHeight: '300px',
      flexWrap: 'wrap',
      backgroundColor: 'purple',
    },
    margin: { marginBottom: theme.spacing(2) },
  })
);

export default styles;
