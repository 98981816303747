import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Table from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import localization from '../../../../localization/table';
import DialogTitle from '../../../../utils/components/DialogTitle';
import TabPanel from '../../../../utils/components/TabPanel';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

function Filters({ open, onSelect, onClose }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [value, setValue] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filters`
        );

        setData(response);
      } catch (err) {}
    })();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>Escolher lista</DialogTitle>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
      >
        <Tab className={classes.tab} label="Meus filtros" />
        <Tab className={classes.tab} label="Recomendações Inteligêntes" />
      </Tabs>
      <Divider />

      <DialogContent className={classes.content}>
        <TabPanel value={value} index={0}>
          <Table
            title=""
            isLoading={!data}
            columns={[
              { title: 'Nome', field: 'name' },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            data={
              (data &&
                data.filter(({ recommendation }) => recommendation === false)) ||
              []
            }
            localization={localization}
            onRowClick={(event, rowData) => {
              onSelect(rowData);
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(async (resolve, reject) => {
                  await api(
                    `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/delete/${oldData.id}`,
                    {},
                    'DELETE'
                  );

                  const response = await api(
                    `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filters`
                  );

                  setData(response);

                  resolve();
                }),
            }}
            options={{ actionsColumnIndex: -1 }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            title=""
            isLoading={!data}
            columns={[
              { title: 'Nome', field: 'name' },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            data={
              (data &&
                data.filter(({ recommendation }) => recommendation === true)) ||
              []
            }
            localization={localization}
            onRowClick={(event, rowData) => {
              onSelect(rowData);
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(async (resolve, reject) => {
                  await api(
                    `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/delete/${oldData.id}`,
                    {},
                    'DELETE'
                  );

                  const response = await api(
                    `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filters`
                  );

                  setData(response);

                  resolve();
                }),
            }}
            options={{ actionsColumnIndex: -1 }}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}

Filters.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Filters;
