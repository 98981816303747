import React, { Fragment } from 'react';

// Usage Components
import { Typography } from '@material-ui/core';
import { Countup } from '../../../../utils/components';

// Usage Constants
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

// Usage Functions
import { setRuleImport } from '../../../../utils/helpers/Functions';

// Styles
import useStyles from '../styles';

function TabReview(props) {
  const classes = useStyles();
  const attr = { ...props.enrichment, ...props.price };

  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Review
      </Typography>
      <div className={classes.containerColsImport}>
        <section>
          <strong>Audiência: &nbsp;</strong>
          <span>{attr.audiencia.nameList}&nbsp;</span>
        </section>
        <section>
          <strong>Preço: &nbsp;</strong>
          <Countup decimals={2} prefix="R$ " end={attr.total_price} />
        </section>
        <section>
          <strong>Regra: &nbsp;</strong>
          <span>{setRuleImport(attr.ruleEnrichment)}</span>
        </section>
      </div>
    </Fragment>
  );
}

export default TabReview;
