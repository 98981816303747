import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, MyButton, Select, TextField } from '../../../utils/components';
import BuscaCEP from '../../../utils/helpers/CEP';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { inputMoney } from '../../../utils/helpers/Functions';
import '../style/ModalAddLoja.css';

function ModalAddLoja(props) {
  const [salvaLoja, setAttrSalvaLoja] = useState({
    nome_loja: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    tipo_pedido: null,
    forma_pagamento: [],
    frete: '0',
    taxa_servico: '0',
    maximo_item: '0',
    minimo_compra: '0',
    descricao: '',
    whatsapp_phone_number: '',
    aceita_dinheiro: false,
    aceita_debito: false,
    aceita_credito: false,
    aceita_boleto: false,
    aceita_vr: false,
    aceita_pix: false,
    chave_pix: '',
  });

  const handleChange = event => {
    setAttrSalvaLoja(prevState => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  function footer() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        <MyButton
          size="large"
          color="primary"
          variant="text"
          onClick={props.onClose}
        >
          Fechar
        </MyButton>

        <MyButton
          onClick={() => props.onSave(salvaLoja)}
          size="large"
          color="primary"
          variant="text"
        >
          Salvar
        </MyButton>
      </div>
    );
  }

  function buscaCEP(cep) {
    setAttrSalvaLoja({ ...salvaLoja, cep });
    if (cep.length === 8) {
      BuscaCEP(cep, data =>
        setAttrSalvaLoja({
          ...salvaLoja,
          cep: data.cep,
          endereco: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          uf: data.uf,
        })
      );
    }
  }

  return (
    <Modal
      showModal={props.showModal}
      onClose={props.onClose}
      header="Adicionar loja"
      footer={footer()}
    >
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Informações da loja
        </Typography>
        <TextField
          value={salvaLoja.nome_loja}
          onChange={({ target: { value } }) =>
            setAttrSalvaLoja({ ...salvaLoja, nome_loja: value })
          }
          label="Nome da loja"
          className="form-control"
          autoComplete="none"
        />
        <TextField
          value={salvaLoja.whatsapp}
          onChange={({ target: { value } }) =>
            setAttrSalvaLoja({
              ...salvaLoja,
              whatsapp_phone_number: value,
            })
          }
          label="Whatsapp"
          className="form-control"
          autoComplete="none"
        />
        <section className="addEnderecoLoja">
          <TextField
            value={salvaLoja.cep}
            onChange={({ target: { value } }) =>
              buscaCEP(value.replace(/\D+/g, ''))
            }
            label="CEP"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.endereco}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, endereco: value })
            }
            label="Endereço"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.bairro}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, bairro: value })
            }
            label="Bairro"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.cidade}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, cidade: value })
            }
            label="Cidade"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.uf}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, uf: value })
            }
            label="UF"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.numero}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, numero: value })
            }
            label="Número"
            className="form-control"
            autoComplete="none"
          />
        </section>

        <Select
          options={[
            { label: 'Delivery', value: 1 },
            { label: 'Drive', value: 2 },
            { label: 'Delivery/Drive', value: 3 },
          ]}
          onChange={tipo_pedido =>
            setAttrSalvaLoja({
              ...salvaLoja,
              tipo_pedido: tipo_pedido.value,
            })
          }
          label="Tipo pedido"
        />
        <FormControl color="primary" component="fieldset">
          <FormLabel component="legend">Formas de pagamento</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_dinheiro}
                  onChange={handleChange}
                  name="aceita_dinheiro"
                />
              }
              label="Dinheiro"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_debito}
                  onChange={handleChange}
                  name="aceita_debito"
                />
              }
              label="Debito"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_credito}
                  onChange={handleChange}
                  name="aceita_credito"
                />
              }
              label="Credito"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_boleto}
                  onChange={handleChange}
                  name="aceita_boleto"
                />
              }
              label="Boleto"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_vr}
                  onChange={handleChange}
                  name="aceita_vr"
                />
              }
              label="VR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={salvaLoja.aceita_pix}
                  onChange={handleChange}
                  name="aceita_pix"
                />
              }
              label="PIX"
            />
          </FormGroup>
        </FormControl>
        {/* <Autocomplete
          multiple
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionLabel={option => option.label}
          options={[
            { label: 'Dinheiro', value: 1 },
            { label: 'Débito', value: 2 },
            { label: 'Crédito', value: 3 },
            { label: 'Boleto', value: 4 },
          ]}
          value={salvaLoja.forma_pagamento}
          onChange={(event, newValue) =>
            setAttrSalvaLoja({ ...salvaLoja, forma_pagamento: newValue })
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Formas de pagamento"
              variant="outlined"
              fullWidth
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <React.Fragment>
              //       {stores.length === 0 ? (
              //         <CircularProgress color="inherit" size={20} />
              //       ) : null}
              //       {params.InputProps.endAdornment}
              //     </React.Fragment>
              //   ),
              // }}
            />
          )}
        /> */}

        <section className="addValoresLoja">
          <TextField
            value={salvaLoja.frete}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({ ...salvaLoja, frete: inputMoney(value) })
            }
            label="Frete"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.taxa_servico}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({
                ...salvaLoja,
                taxa_servico: inputMoney(value),
              })
            }
            label="Taxa de serviço"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.minimo_compra}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({
                ...salvaLoja,
                minimo_compra: inputMoney(value),
              })
            }
            label="Valor mínimo de compra"
            className="form-control"
            autoComplete="none"
          />
          <TextField
            value={salvaLoja.maximo_item}
            onChange={({ target: { value } }) =>
              setAttrSalvaLoja({
                ...salvaLoja,
                maximo_item: value.replace(/\D+/g, ''),
              })
            }
            label="Máximo de itens por pedido"
            className="form-control"
            autoComplete="none"
          />
        </section>
        <TextField
          value={salvaLoja.chave_pix}
          onChange={({ target: { value } }) =>
            setAttrSalvaLoja({
              ...salvaLoja,
              chave_pix: value,
            })
          }
          label="Chave PIX"
          className="form-control"
          autoComplete="none"
        />
        <TextField
          value={salvaLoja.descricao}
          onChange={({ target: { value } }) =>
            setAttrSalvaLoja({
              ...salvaLoja,
              descricao: value,
            })
          }
          label="Descrição"
          className="form-control"
          autoComplete="none"
          multiline
        />
      </section>
    </Modal>
  );
}

export default connect(null, null)(ModalAddLoja);
