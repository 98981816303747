import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { useHistory, useParams } from 'react-router-dom';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

function CreatePage() {
  const emailEditorRef = useRef();

  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();

  const [, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [path, setPath] = useState('');
  const [design, setDesign] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/pages/${id}`
        );

        setTitle(response.content.title);
        setPath(response.content.path);
        setDesign(response.content.design);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const onLoad = () => {
    if (!emailEditorRef.current) window.location.reload();
    emailEditorRef.current && emailEditorRef.current.loadDesign(design);
  };

  const handleTitleChange = useCallback(
    event => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const handlePathChange = useCallback(
    event => {
      setPath(event.target.value);
    },
    [setPath]
  );

  const handleSubmit = useCallback(() => {
    emailEditorRef.current &&
      emailEditorRef.current.exportHtml(async data => {
        try {
          const { design, html } = data;

          await api(
            `${DATASALES_CRM_API}sites-${AMBIENTE}/pages/${id}`,
            { title, path, design, html },
            'PUT'
          );
        } catch (err) {
          console.log(err);
        } finally {
          history.goBack();
        }
      });
  }, [title, path, history, id]);

  if (!design) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.push(`/sites`);
            }}
          >
            voltar
          </Button>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Título"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Caminho"
              placeholder="Ex: quem-somos, duvidas-frequentes, formas-de-pagamento"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              value={path}
              onChange={handlePathChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              style={{ height: 56 }}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            options={{
              locale: 'pt-BR',
            }}
            appearance={{
              theme: 'light',
              panels: {
                tools: {
                  dock: 'right',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

CreatePage.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreatePage;
