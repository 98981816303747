import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex' },
    hasSelectedItems: {
      bottom: '0 !important',
      transition: 'all .5s ease'
    },
    distributionModalWrapper: {
      margin: '0',
      height: '100%'
    },
    wrapperBoxContainer: {
      width: '100%'
      // marginTop: 32
    },
    tabContainer: {
      borderBottom: '1px solid #CFCFCF',
      display: 'flex',
      justifyContent: 'center'
    },
    disclaimerError: {
      marginTop: '8px',
      marginBottom: '24px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 0px 8px 8px',
      gap: '8px',
      width: '100%',
      height: '40px',
      border: '1px solid #D54D4D',
      borderRadius: '4px',
      flex: 'none',
      order: 6,
      flexGrow: 0,
      background: 'rgba(213, 77, 77, 0.05)'
    },
    popper: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius
    },
    typographyAutocomplete: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '.05em',
      color: '#616161',
      backgroundColor: '#F5F5F5',
      borderRadius: '4px',
      border: 'none'
    },
    typographyBigTitle: {
      fontWeight: 600,
      fontSize: '32px !important',
      lineHeight: '39px !important',
      letterSpacing: '.05em',
      padding: '0 10px'
    },
    typographyTitle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '.05em',
      alignItems: 'center',
      display: 'flex',
      fontStyle: 'normal'
    },
    gridContentBtn: {
      marginTop: theme.spacing(1)
    },
    boxLabelWhatsApp: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px !important',
      justifyContent: 'space-between'
    },
    typographySubtitleClickabled: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.05em',
      display: 'flex',
      marginBottom: '0px !important',
      alignItems: 'center',
      fontStyle: 'normal'
    },
    typographySubtitleClickabledBtn: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.05em',
      display: 'flex',
      alignItems: 'center'
    },
    gridContent: {
      marginBottom: '10px'
    },
    typographySubtitle: {
      margin: '8px 0 0 8px',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.025em'
    },
    typographySubtitleIMG: {
      fontWeight: 500,
      fontSize: '15px !important',
      lineHeight: '22px !important',
      letterSpacing: '0.05em !important',
      marginBottom: '16px !important'
    },
    typographyWarning: {
      margin: 0,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.025em'
    },
    boxContent: {
      flexGrow: 1,
      Height: '100%'
    },
    icon: {
      width: theme.spacing(6),
      marginRight: '16px'
    },
    iconDisabled: {
      width: theme.spacing(6),
      marginRight: '16px',
      filter: 'grayscale(100%)',
      opacity: 0.5
    },
    buttonIconCentralizer: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    boxContentIcon2: {
      display: 'flex',
      position: 'absolute',
      left: '16px',
      top: '43px'
    },
    boxContentIcon3: {
      display: 'flex',
      position: 'absolute',
      left: '46px',
      top: '66px'
    },
    boxContentIcon: {
      display: 'flex',
      position: 'absolute',
      left: '48px',
      top: '46px'
    },
    boxContentText: {
      display: 'inline-block',
      position: 'absolute',
      top: '29px',
      left: '150px'
    },
    boxText: {
      display: 'inline',
      width: '60%',
      textAling: 'center',
      position: 'relative',
      top: '8px'
    },
    creditsCounterBox: {
      color: '#fff',
      fontWeight: 500,
      fontSize: '12px',
      backgroundColor: '#B0B0B0',
      padding: '4px',
      display: 'inline-block',
      marginTop: '10px',
      borderRadius: '2px'
    },
    boxContentBtn: {
      position: 'relative',
      width: '711px',
      height: '144px',
      borderRadius: theme.spacing(1),
      backgroundColor: '#F5F5F5',
      display: 'flex',
      cursor: 'pointer',
      border: '2px solid transparent',
      '&:hover': {
        '& $checkbox': {
          visibility: 'visible'
        }
      }
    },
    boxContentBtnSelected: {
      border: '2px solid #2813AD'
    },
    boxContentBtnWithoutCredits: {
      border: '2px solid #B02F2F'
    },
    boxContentBtnDisabled: {
      position: 'relative',
      width: '711px',
      height: '144px',
      borderRadius: theme.spacing(1),
      backgroundColor: '#F5F5F5',
      display: 'flex',
      cursor: 'pointer',
      border: '2px solid transparent',
      '&:hover': {
        '& $checkbox': {
          visibility: 'visible'
        }
      },
      pointerEvents: 'none',
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      flexDirection: 'column'
    },
    zeroCreditsContainer: {
      width: 'fit-content',
      display: 'inline-block',
      marginTop: '8px'
    },
    zeroCreditsMessageBox: {
      color: '#B02F2F',
      backgroundColor: '#FFEDED',
      padding: '8px 16px',
      borderRadius: '8px',
      marginRight: '24px'
    },
    zeroCreditsBuyButton: { color: '#127FFF', fontWeight: 600 },
    checkbox: {
      padding: 0,
      color: '#2813AD',
      position: 'absolute',
      visibility: 'hidden'
    },
    checkboxSelected: {
      padding: 0,
      color: '#2813AD !important',
      position: 'absolute',
      visibility: 'visible'
    },
    typographyBtn: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '.05em',
      color: '#616161'
    },
    tabsRoot: {
      color: '#36325e'
    },
    tabsIndicator: {
      color: '#36325e'
    },
    inputNoBorder: {
      border: 'none'
    },
    inputBorderError: {
      border: '1px solid #f44336 !important'
    },
    inputBackgroundColor: {
      backgroundColor: '#F5F5F5'
    },
    checkLabel: {
      fontWeight: 'bold',
      color: `${theme.palette.primary.main} !important`,
      fontSize: '11pt'
    },
    disabledLabel: {
      fontWeight: 'normal',
      color: `##00000042 !important`,
      fontSize: '11pt'
    },
    modalActionButtonsWrapper: {
      height: '66px',
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.8)',
      padding: '8px 40px',
      zIndex: 1201
    },
    buttonReturnSectionAndSelectionWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    buttonReturnSectionAndSelection: {
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.05em'
    },
    loadingButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: 29
    },
    loadingButton: {
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.05em',
      color: '#FFFFFF !important'
    },
    smsMessage2: { position: 'relative', top: '-11px', paddingRight: '5px' }
  })
);

