import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";
import Fetch from "../../../utils/helpers/Fetch";

export const saveTemplate = template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/postTemplate`,
    template,
    "POST"
  ).then(payload => dispatch({ type: "URL", payload }));

export const getTemplateTipo = tipo_template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/getTemplateType`,
    { tipo_template }
  ).then(payload => dispatch({ type: "TEMPLATE_TIPO", payload }));

export const getTemplates = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/getTemplates`
  ).then(payload => dispatch({ type: "LISTA_TEMPLATE", payload }));

export const editTemplate = template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/putTemplate`,
    template,
    "PUT"
  );

export const deleteTemplate = template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteTemplate`,
    template,
    "PUT"
  ).then(() => dispatch(getTemplates()));

export const exportTemplate = template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/postExportTemplate`,
    template,
    "POST"
  ).then(payload => dispatch({ type: "URL", payload }));

export const exportSavedTemplate = template => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/putExportTemplate`,
    template,
    "PUT"
  ).then(payload => dispatch({ type: "URL", payload }));

export const setTemplate = template => {
  return {
    type: "SET_TEMPLATE",
    payload: template
  };
};

export const close = () => {
  return {
    type: "ON_CLOSE"
  };
};

export const setEditor = editor => {
  return {
    type: "setEditor",
    payload: editor
  };
};
