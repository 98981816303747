/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  withStyles,
  CircularProgress,
  Button,
  IconButton,
  Popover,
  Icon,
  Select,
  MenuItem
} from '@material-ui/core';
import { CompactPicker } from 'react-color';
import {
  ImageCard,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import { 
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

class GeneratePixelixe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: "Encarte-A4-12P-CPD-inicio_do_inverno-001",
      qtde_produtos: 12,
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: {r: 0, g: 0, b: 0, a: 1},
      showDesativarModelo: false,
      etiquetas: [],
      etiqueta: null,
      font_size: null,
    };
  }    

  async componentDidMount() {  
    this.props.getTabloidsEtiquetas()
      .then((payload) => {
        for (var j = 0; j < this.props.tabloids_etiquetas.length; j++) {
          this.setState({
            etiquetas: [
              ...this.state.etiquetas,
              {
                ...this.props.tabloids_etiquetas[j],
                selected: false
              }
            ]
          });
        }
      });
    try {
      const response = await Fetch(
        `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
      );

      this.setState({ stores: response });
    } catch (err) {
      console.error(err);
    }
  }

  showPopover = (current) => {
    this.setState({ anchorEl: current });
  }

  closePopover = () => {
    this.setState({ anchorEl: null });
  }

  handleChangeComplete = (color) => {
    this.setState({ 
      colorText: color.rgb,
    }, () => {
      let tmp = { ...this.state.json_parse };

      let indexOf = null;

      if (tmp.extras) {
        indexOf = tmp.extras.findIndex(i => i.name === "endereco-01");
        if (indexOf > -1) {
          tmp.extras[indexOf].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;              
        }

        indexOf = tmp.extras.findIndex(i => i.name === "endereco-02");
        if (indexOf > -1) {
          tmp.extras[indexOf].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }

        indexOf = tmp.extras.findIndex(i => i.name === "telefone-01");
        if (indexOf > -1) {
          tmp.extras[indexOf].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }

        indexOf = tmp.extras.findIndex(i => i.name === "descritivo-texto-001");
        if (indexOf > -1) {
          tmp.extras[indexOf].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }
      }

      if (tmp.modifications && tmp.modifications['produto-descricao']) {
        tmp.modifications['produto-descricao'].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
      }

      this.setState({ json_parse: { ...tmp } }); 
    });       
  };  

  header(props, state) {
    const steps = [
      'Formato', 'Qtde Produtos', 'Ocasião', 'Template', 'Variáveis', 'Páginas'
    ];

    return (
      <Fragment>
        Gerar Arte - {this.props.tabloid.qtde_produtos_lista} Produtos no Total
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  footer(props, state) {
    const {
      activeStep
    } = this.state

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep === 4 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              (this.state.activeStep === 4) && this.props.putOfertaFisico({ 
                id: this.props.tabloid.id_tabloide_digital,
                id_template: this.state.id_template,
                nome_modelo: this.state.nome_modelo,
                chave_template: this.state.template,
                produto_qtde: this.state.qtde_produtos,
                tipoPixelixe: this.props.tipoPixelixe,
                json: JSON.stringify(this.state.json_parse),
                etiqueta: this.state.etiqueta,
              }).then(() => this.props.onClose());
            }}
            disabled={false}
          >
            Próximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    let formato = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      formato[curr["formato"]] = 1;
    }, {});

    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Formato
          </Typography>
        </section>
        <Divider />
        <section
          style={{ 
            padding: '2rem 4rem',
            display: 'flex',
            justifyContent: 'center'
          }}
        >   
          {Object.keys(formato).map((value, index) => 
            <ImageCard 
              descricao={value}
              style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  formato: value
                });
              }}
            />
          )}
        </section>
      </section>
    );
  }

  step2(props, state) {
    let qtde_produtos = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      (curr["formato"] === this.state.formato) 
      && (qtde_produtos[curr["qtde_produtos"]] = 1);
    }, {});

    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Modelo (Qtde de Produtos)
          </Typography>
        </section>
        <Divider />
        <section
          style={{ 
            padding: '2rem 4rem',
            display: 'flex',
            justifyContent: 'center'
          }}
        >   
          {Object.keys(qtde_produtos).map((value, index) => 
            <ImageCard 
              descricao={value}
              style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  qtde_produtos: value
                });
              }}
            />
          )}
        </section>
      </section>
    );
  }

  step3(props, state) {
    let ocasiao = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      (curr["formato"] === this.state.formato)
      && (curr["qtde_produtos"].toString() === this.state.qtde_produtos.toString())
      && (ocasiao[curr["ocasiao"]] = curr["miniatura"]);
    }, {});

    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha uma Ocasião
          </Typography>
        </section>
        <Divider />
        <section
          style={{ 
            padding: '2rem 4rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >   
          {Object.keys(ocasiao).map((value, index) => 
            <ImageCard 
              descricao={value}
              height={
                ['facebook', 'instagram', 'whatsapp', 'cartaz'].includes(this.props.tipoPixelixe) 
                && ['Post'].includes(this.state.formato)
                && 150
              }
              style={{ backgroundColor: '#E0E0E0', marginRight: 20, marginBottom: 20 }}
              image={ocasiao[value]}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  ocasiao: value
                });
              }}
            />
          )}
        </section>
      </section>
    );
  }

  step4(props, state) {
    let template = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      (curr["formato"] === this.state.formato)
      && (curr["qtde_produtos"].toString() === this.state.qtde_produtos.toString())
      && (curr["ocasiao"] === this.state.ocasiao)
      && (template[curr["nome"]] = [curr["miniatura"], curr["template"], curr["id"]]);
    }, {});

    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Template
          </Typography>
        </section>
        <Divider />
        <section
          style={{ 
            padding: '2rem 4rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >   
          {Object.keys(template).map((value, index) => 
            <ImageCard 
              descricao={value}
              height={
                ['facebook', 'instagram', 'whatsapp', 'cartaz'].includes(this.props.tipoPixelixe)
                && ['Post'].includes(this.state.formato)
                && 150
              }
              style={{ backgroundColor: '#E0E0E0', marginRight: 20, marginBottom: 20 }}
              image={template[value][0]}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  template: template[value][1]
                });
                this.props.tabloids_templates_fisico.reduce((acum, curr) => {
                  if ((curr["formato"] === this.state.formato)
                  && (curr["qtde_produtos"].toString() === this.state.qtde_produtos.toString())
                  && (curr["ocasiao"] === this.state.ocasiao)
                  && (curr["id"] === template[value][2]))
                  {
                    this.setState({
                      id_template: curr["id"],
                      nome_modelo: curr["nome"],
                      json: curr["json"],
                      json_parse: JSON.parse(curr["json"])
                    }, () => {
                      this.setState({ store: this.state.stores[0] || null }, () => {
                        const tmp = { ...this.state.json_parse };

                        if (tmp.extras && this.state.store) {
                          let indexOf = tmp.extras.findIndex(i => i.name === "logo-empresa-01");
                          if (indexOf > -1) {
                            tmp.extras[indexOf].image_url = this.state.store.logo1 ? this.state.store.logo1.split("?")[0] : " ";
                          }

                          indexOf = tmp.extras.findIndex(i => i.name === "endereco-01");
                          if (indexOf > -1) {
                            tmp.extras[indexOf].text = this.state.store.endereco || " ";                
                          }

                          indexOf = tmp.extras.findIndex(i => i.name === "endereco-02");
                          if (indexOf > -1) {
                            tmp.extras[indexOf].text = this.state.store.whatsapp_phone_number || " ";                
                          }

                          indexOf = tmp.extras.findIndex(i => i.name === "telefone-01");
                          if (indexOf > -1) {
                            tmp.extras[indexOf].text = this.state.store.whatsapp_phone_number || " ";                
                          }

                          this.setState({ json_parse: { ...tmp } });
                        }
                      });                      
                    });
                  }
                }, {});
              }}
            />
          )}
        </section>
      </section>
    );
  }  

  step5(props, state) {
    const { classes } = this.props;
    const { variaveis, json_parse } = this.state;

    return (
      <div style={{ textAlign: 'center' }}>
        <Autocomplete
          // open={open}
          // onOpen={() => {
          //   setOpen(true);
          // }}
          // onClose={() => {
          //   setOpen(false);
          // }}

          style={{ width: '90%', margin: '30px auto 30px' }}
          getOptionSelected={(option, value) =>
            option.id_loja === value.id_loja
          }
          getOptionLabel={option => option.nome_loja}
          options={this.state.stores}
          loading={this.state.stores.length === 0}
          value={this.state.store}
          onChange={(event, newValue) => {
              this.setState({ store: newValue });
              const tmp = { ...json_parse };

              if (tmp.extras && this.state.store) {
                let indexOf = tmp.extras.findIndex(i => i.name === "logo-empresa-01");
                if (indexOf > -1) {
                  tmp.extras[indexOf].image_url = newValue.logo1 ? newValue.logo1.split("?")[0] : "";
                }

                indexOf = tmp.extras.findIndex(i => i.name === "endereco-01");
                if (indexOf > -1) {
                  tmp.extras[indexOf].text = newValue.endereco || " ";                
                }

                indexOf = tmp.extras.findIndex(i => i.name === "endereco-02");
                if (indexOf > -1) {
                  tmp.extras[indexOf].text = newValue.whatsapp_phone_number || " ";                
                }

                indexOf = tmp.extras.findIndex(i => i.name === "telefone-01");
                if (indexOf > -1) {
                  tmp.extras[indexOf].text = newValue.whatsapp_phone_number || " ";                
                }

                this.setState({ json_parse: { ...tmp } });
              }
            }
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Selecionar loja"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.stores.length === 0 ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {json_parse.extras && json_parse.extras.map((value, i) => 
          (value.type === "text")
          ? <TextField
              type="string"
              label={value.name}
              onChange={({ target }) => {
                const tmp = { ...json_parse };
                tmp.extras[i].text = target.value;
                this.setState({ json_parse: { ...tmp } });
              }}
              value={value.text}
              style={{ margin: 20, width: '90%' }}
            />
          : (value.type === "image") &&        
              <img
                alt={value.image_url}
                src={validURL(value.image_url) ? value.image_url : 'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'}
                style={{ height: 120, cursor: 'pointer', backgroundColor: '#e7e7e7' }}
              />
        )}
        <Divider 
          style={{
            margin: 'auto',
            marginTop: 20,
            width: '80%'
          }}
        />
        {(!this.state.template.includes("http")) && (
          <div
            style={{
            }}
          >
            <div>
              <div style={{ marginTop: 20, marginBottom: 12 }}>Cor do texto</div>
              <div style={{
                width: '90%',
                margin: 'auto',
              }}>
                <CompactPicker
                  color={this.state.colorText}
                  onChangeComplete={this.handleChangeComplete}
                  styles={{
                    default: {
                      picker: {
                        boxShadow: 'none',
                        margin: 'auto'
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 20
              }}
            >
              {/* <TextField
                type="string"
                label={"Tamanho da Fonte"}
                onChange={({ target }) => {
                  const tmp = { ...json_parse };
                  tmp.modifications["produto-descricao"] = {
                    ...tmp.modifications["produto-descricao"],
                    "font-size": target.value + "px"
                  };
                  if (target.value === '') {
                    delete tmp.modifications["produto-descricao"]["font-size"];
                  }
                  this.setState({ json_parse: { ...tmp } });
                  this.setState({ font_size: target.value });
                }}
                value={this.state.font_size}
                style={{ margin: 20, width: '90%' }}
              /> */}
              Tamanho do nome dos produtos:
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.font_size}
                onChange={(event) => {
                  const tmp = { ...json_parse };
                  tmp.modifications["produto-descricao"] = {
                    ...tmp.modifications["produto-descricao"],
                    "font-size": event.target.value + "px"
                  };
                  if (event.target.value === '') {
                    delete tmp.modifications["produto-descricao"]["font-size"];
                  }
                  this.setState({ json_parse: { ...tmp } });
                  this.setState({ font_size: event.target.value });
                }}
                label="Tamanho do nome dos produtos"
                style={{
                  marginLeft: 20
                }}
              >
                <MenuItem value="">
                  <em>Normal</em>
                </MenuItem>
                <MenuItem value={100}>Médio</MenuItem>
                <MenuItem value={140}>Grande</MenuItem>
                <MenuItem value={180}>Gigante</MenuItem>
              </Select>
            </div>
          </div>
        )}
        {(['encarte','facebook','instagram','whatsapp','email','indoor'].includes(this.props.tipoPixelixe)) && (
          <div>
            <Divider 
              style={{
                margin: 'auto',
                width: '80%',
                marginTop: 30
              }}
            />
            <div style={{ marginTop: 30 }}>Etiqueta de preço</div>
            <div style={{ 
              display: 'flex',
              justifyContent: 'center',
              width: '80%',
              margin: '10px auto 20px',
              flexWrap: 'wrap'
            }}>
              {this.state.etiquetas.map((value, index) => (
                <img
                  alt={value.etiqueta}
                  src={value.etiqueta}
                  style={{ 
                    width: 50, 
                    cursor: 'pointer', 
                    backgroundColor: '#e7e7e7',
                    margin: 10,
                    border: value.selected ? '2px solid blue' : ''
                  }}
                  onClick={() => {
                    let tmp = this.state.etiquetas;
                    for (var j = 0; j < tmp.length; j++ ) {
                      tmp[j].selected = false;                      
                    }
                    tmp[index].selected = true;
                    this.setState({
                      etiquetas: tmp,
                      etiqueta: value.etiqueta
                    });
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {(this.state.ocasiao === 'Meus Modelos') && (
          <div>
            <Button
              size="large"
              color="secondary"
              variant="text"
              onClick={() => this.setState({ showDesativarModelo: true })}
              style={{ marginTop: 40 }}
            >
              Desativar Template
            </Button>
          </div>
        )}
      </div>
    );
  }

  step6(props, state) {
    const { classes } = this.props;
    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Páginas
          </Typography>          
        </section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {this.props.paginas_fisico.map((e, i) => (              
              (!((e.link && e.link_html) || e.error))
                ? <CircularProgress style={{ margin: 60 }}></CircularProgress>
                : e.error
                  ? <React.Fragment>
                      <IconButton
                        onClick={({ currentTarget }) => this.showPopover(currentTarget)}
                        style={{ padding: 0, margin: 10 }}
                      >
                        <Icon style={{ fontSize: 50 }}>error</Icon>
                      </IconButton>
                      <Popover
                        id="simple-popover"
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.closePopover}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <section style={{ padding: '1rem' }}>
                          {e.error}
                        </section>
                      </Popover>
                    </React.Fragment>
                  : <a href={e.link} target={"_blank"}>
                      <img src={e.link} alt={"image_grid_"+i} 
                        style={{ 
                          width: 130, 
                          margin: 10,
                          borderRadius: '5%'
                        }}
                      />
                    </a>  
            ))}
          </div>
        </section>
      </section>    
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      case 4:
        return this.step5(props, state);
      case 5:
        return this.step6(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const {
      activeStep
    } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {this.state.showDesativarModelo && (
          <DialogAlert
            open={this.state.showDesativarModelo}
            title="Deseja desativar este modelo?"
            onClick={() =>
              this.setState({ showDesativarModelo: false }, () =>
                {
                  this.props.createTemplatePersonalizado({
                    id: this.state.id_template,
                    deletar: true
                  }).then(() => this.props.onClose());
                }
              )
            }
            onClose={() =>
              this.setState({ showDesativarModelo: false })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  }
});

const mapStateToProps = ({
  TabloideDigitalReducer
}) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas
};

const GeneratePixelixeConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePixelixe);

export default withStyles(styles)(GeneratePixelixeConnect);
