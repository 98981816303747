import Fetch from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';

const loadAutomacao = () => dispatch => dispatch({ type: 'LOADER_AUTOMACAO' });

export const getGatilhos = () => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/getGatilhos`
    );
    dispatch({ type: 'GET_GATILHOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getAutomacao = () => async dispatch => {
  try {
    dispatch(loadAutomacao());
    const payload = await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/getAutomacao`
    );
    dispatch({ type: 'GET_AUTOMACAO', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getAutomacaoPersonalizada = () => async dispatch => {
  try {
    dispatch(loadAutomacao());
    const payload = await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/getAutomacaoPersonalizada`
    );

    dispatch({ type: 'GET_AUTOMACAO_PERSONALIZADA', payload });
  } catch (err) {
    console.log(err);
  }
};

export const saveAutomacao = automacao => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/postAutomacao`,
      automacao,
      'POST'
    );
    dispatch(getAutomacao());
  } catch (err) {
    console.log(err);
  }
};

export const saveAutomacaoPersonalizada = automacao => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/postAutomacaoPersonalizada`,
      automacao,
      'POST'
    );
    // dispatch(getAutomacao());
  } catch (err) {
    console.log(err);
  }
};

export const putAutomacao = automacao => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}auto-p1-${AMBIENTE}/${AMBIENTE}/crm/putAutomacao`,
      automacao,
      'PUT'
    );
    dispatch(getAutomacao());
  } catch (err) {
    console.log(err);
  }
};
