import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import Table from 'material-table';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CreatePage from './components/CreatePage';
import useStyles from './styles';

function Pages({ siteId }) {
  const classes = useStyles();

  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createPageIsOpen, setCreatePageIsOpen] = useState(false);

  const getPages = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/pages`,
        { site_id: siteId }
      );

      setData(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getPages();
  }, [getPages]);

  const handleCreatePageOpen = () => {
    setCreatePageIsOpen(true);
  };

  const handleCreatePageClose = useCallback(() => {
    setCreatePageIsOpen(false);
  }, [setCreatePageIsOpen]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/pages`,
          { site_id: siteId, ...data },
          'POST'
        );
      } catch (err) {
        console.log(err);
      } finally {
        handleCreatePageClose();
        getPages();
      }
    },
    [handleCreatePageClose, getPages]
  );

  const handleEditPage = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/pages/${data.id}`,
          { active: !data.active },
          'PUT'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getPages();
      }
    },
    [getPages]
  );

  const handleRemovePage = useCallback(
    async id => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/pages/${id}`,
          {},
          'DELETE'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getPages();
      }
    },
    [getPages]
  );

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <DescriptionIcon />
            </Avatar>
          }
          title="Páginas"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreatePageOpen}
            >
              Adicionar Página
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              { title: 'Titulo', field: 'title' },
              { title: 'Caminho', field: 'path' },
              {
                title: 'Status',
                field: 'active',
                render: rowData => (
                  <Chip
                    className={
                      rowData.active ? classes.active : classes.inactive
                    }
                    label={rowData.active ? 'Ativo' : 'Inativo'}
                  />
                ),
              },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            onRowClick={(e, rowData) => {
              history.push(`sites/page/edit/${rowData.id}`);
            }}
            actions={[
              {
                icon: 'block',
                onClick: (e, rowData) => {
                  handleEditPage(rowData);
                },
              },
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleRemovePage(rowData.id);
                },
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={localization}
          />
        </CardContent>
      </Card>
      {createPageIsOpen && (
        <CreatePage
          open={createPageIsOpen}
          onSubmit={handleSubmit}
          onClose={handleCreatePageClose}
        />
      )}
      {/* <EditPage
        open={editPageIsOpen}
        pageId={pageId}
        onSubmit={handleEditPageSubmit}
        onClose={handleEditPageClose}
      /> */}
    </Fragment>
  );
}

export default Pages;
