import React, { Component } from "react";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCrop } from "@fortawesome/free-solid-svg-icons";
import {
  setEditor,
  setTemplate,
  salvarPvd,
  deletePvd,
  getPvd,
  editPvd
} from "../actions/actions";
import { MyButton, Container } from "../../../utils/components";

class PoliticaPrivacidade extends Component {
  constructor(props) {
    super(props);
    this.state = { showEditor: false };
  }

  componentDidMount() {
    this.props.setEditor(this.editor);
    this.props.getPvd();
    this.showEditor();
  }

  showEditor() {
    setTimeout(() => this.setState({ showEditor: true }), 2000);
  }

  handleOnLoad = () => {
    this.editor.loadDesign(
      !this.props.template ? null : this.props.template.jsonTemplate
    );
  };

  salvarPvd() {
    this.editor.exportHtml(data => {
      const { design, html } = data;

      const objTemplate = {
        html: html,
        jsonTemplate: design
      };

      if (!this.props.template) {
        this.props.salvarPvd(objTemplate);
      } else {
        this.props.editPvd(objTemplate);
      }
    });
  }

  render() {
    const { showEditor } = this.state;

    return (
      <Container>
        <section style={{ marginLeft: "1%" }}>
          <MyButton
            bsSize="xg"
            bsstyle="primary"
            onClick={() => this.salvarPvd()}
          >
            Salvar
          </MyButton>
        </section>
        {showEditor && (
          <EmailEditor
            minHeight="750px"
            ref={editor => (this.editor = editor)}
            onLoad={this.handleOnLoad}
            onDesignLoad={this.onDesignLoad}
            options={{
              locale: "pt-BR"
            }}
          />
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = {
  setEditor,
  editPvd,
  getPvd,
  deletePvd,
  setTemplate,
  salvarPvd
};

const mapStateToProps = ({ AppReducer }) => ({ ...AppReducer });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PoliticaPrivacidade);
