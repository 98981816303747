import React from 'react';
import AnalyticsView from './AnalyticsView';
import Overview from './Overview';
import MovimentacaoReprocesso from './MovimentacaoReprocesso';

export default [
  {
    id: 0,
    component: <Overview />,
  },
  {
    id: 1,
    component: <AnalyticsView />,
  },
  {
    id: 2,
    component: <MovimentacaoReprocesso />,
  },
];
