export default class Socket {
  constructor(ws = '') {
    const token = localStorage.getItem('token');
    this._token = JSON.parse(token);
    this._socket = new WebSocket(ws, ['http', this._token]);
  }

  ws() {
    return this._socket;
  }
}
