import React from 'react';
import { Box, Chip } from '@material-ui/core';
import DateCustom from '../../helpers/Date';

export default props => (
  <Box onSelect={props.onSelect}>
    {new DateCustom().arrayDays().map((day, index) => (
      <Chip key={index}>{day}</Chip>
    ))}
  </Box>
);
