import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Typography,
  //Divider,
  withStyles,
  Avatar,
  Icon,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MES } from '../../../utils/helpers/Constants';
import {
  Panel,
  CountCard,
  DatePicker,
  Snackbar,
} from '../../../utils/components';

const data = {
  tabloide: [
    {
      id: 63,
      name: 'Dia das mães',
      dataInicio: '01/11/2019',
      dataFim: '03/11/2019',
      produtos: 12,
      categoria: 'Cerveja e Refrigerantes',
      faturamento: 47363.72,
    },
    {
      id: 87,
      name: 'Semanal',
      dataInicio: '01/12/2019',
      dataFim: '07/12/2019',
      produtos: 310,
      categoria: 'Alimentos',
      faturamento: 103232.64,
    },
    {
      id: 23,
      name: 'Clube',
      dataInicio: '29/11/2019',
      dataFim: '01/12/2019',
      produtos: 15,
      categoria: 'Açougue e Cerveja e Refrigerantes',
      faturamento: 7363.72,
    },
  ],
  combinacoes: [
    {
      id: 142,
      name: 'Clube Vinho',
      dataInicio: '07/11/2019',
      dataFim: '11/11/2019',
      produtos: 22,
      categoria: 'Liquidos e Bebidas',
      faturamento: 1242.72,
    },
    {
      id: 45,
      name: 'Clube Sobremesas',
      dataInicio: '03/11/2019',
      dataFim: '23/11/2019',
      produtos: 53,
      categoria: 'Alimentos e Laticinios',
      faturamento: 12363.72,
    },
  ],
  crossSeling: [
    {
      id: 147,
      name: 'Cluster cervejeiros',
      dataInicio: '12/11/2019',
      dataFim: '15/11/2019',
      produtos: 10,
      categoria: 'Liquidos e Bebidas',
      faturamento: 1092.72,
    },
    {
      id: 27,
      name: 'Cluster casa e limpeza',
      dataInicio: '15/11/2019',
      dataFim: '20/11/2019',
      produtos: 40,
      categoria: 'Perfumaria',
      faturamento: 3042.72,
    },
  ],
};

class Tabloides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      nomeOferta: '',
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      filtroMes: MES[new Date().getMonth()],
      personalizada: null,
      key: 0,
      name: '',
      data: [],
      loading: false,
      showModalInteligente: false,
      showSnackbar: false,
    };
  }

  componentDidMount() {
    // this.onChange("title", this.props.location.state.title);
    this.switchName(this.props.match.params.id);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  switchName(id) {
    if (id === '0') {
      this.setState({ name: 'Tabloide', data: data.tabloide });
    } else if (id === '1') {
      this.setState({ name: 'Combinações', data: data.combinacoes });
    } else if (id === '2') {
      this.setState({ name: 'Cross Selling', data: data.crossSeling });
    } else if (id === '3') {
      this.setState({ name: 'Pricing', data: data.crossSeling });
    } else if (id === '4') {
      this.setState({
        name: 'Audiências personalizadas',
        data: data.crossSeling,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      filtroDataInicio,
      filtroDataFim,

      showSnackbar,
    } = this.state;

    return (
      <div className={classes.root}>
        {this.props.match.params.id !== 4 && (
          <Link to="/sugestoes" style={{ textDecoration: 'none' }}>
            <Button
              size="small"
              style={{ marginBottom: '1rem' }}
              color="primary"
            >
              <Icon>arrow_back_ios</Icon>Voltar para Sugestões
            </Button>
          </Link>
        )}

        <Typography className={classes.label} variant="h3" noWrap>
          Audiência
        </Typography>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date =>
                    this.onChangePeriodo('filtroDataInicio', date)
                  }
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
              </div>
            </div>
          </section>
          <div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              to="/configuracoes-audiencia"
            >
              Configuraçōes
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          {this.state.loading ? (
            <CircularProgress></CircularProgress>
          ) : (
            data.tabloide.map(value => (
              <Fragment>
                <Panel
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Icon>shopping_cart</Icon>
                    </Avatar>
                  }
                  title={`#${value.id} - ${value.name}`}
                  subheader={` ${value.dataInicio} até ${value.dataFim}`}
                  style={{ marginBottom: 20 }}
                  action={
                    <Button
                      // onClick={() =>
                      //   window.location.assign(
                      //     "https://datasalesio-imagens.s3.amazonaws.com/20191128_recomendacao_aplicativo_cluster_churrasco__0612_1212.xlsx"
                      //   )
                      // }
                      component={Link}
                      to="/oneToOne-detalhe/1"
                      size="large"
                      className={classes.button}
                      variant="text"
                      color="primary"
                    >
                      Detalhes
                    </Button>
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                      }}
                    >
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={value.produtos}
                        title=" Produtos"
                        icon="shop"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        titleTypographyProps={{ variant: 'h6' }}
                        title=" Categoria"
                        icon="score"
                        style={{ marginRight: 20 }}
                      >
                        <Typography variant="inherit">
                          {value.categoria}
                        </Typography>
                      </CountCard>

                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={value.faturamento}
                        decimals={2}
                        prefix="R$ "
                        title="Potencial Faturamento"
                        icon="add_shopping_cart"
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </div>
                </Panel>
              </Fragment>
            ))
          )}
        </div>
        <Snackbar
          open={showSnackbar}
          onClose={() => this.setState({ showSnackbar: false })}
          variant="success"
          message="Sugestão inteligênte criada com sucesso"
        ></Snackbar>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ OneToOneReducer }) => ({ ...OneToOneReducer });

const mapDispatchToProps = {};

const TabloidesConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Tabloides);

export default withStyles(styles)(TabloidesConnect);
