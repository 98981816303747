import Box from '@material-ui/core/Box';

import logoPrimary from '../../../../assets/images/logoPrimary.png';
import { useStyles } from '../../styles';

export default function HeaderInfo() {
  const classes = useStyles();

  return (
    <Box className={classes.cardHeader}>
      <img src={logoPrimary} alt="Logo Datasales" />
      <Box className={classes.title}>Nosso portal está com novas funcionalidades!</Box>
      <Box>
        Você pode escolher usar o portal antigo ou explorar a nova maneira de usar a Datasales.
      </Box>
    </Box>
  );
}

