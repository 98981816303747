import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import DialogTitle from '../../../utils/components/DialogTitle';

const NumberFormatCustom = React.forwardRef(function NumberFormatCusto(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      getInputRef={ref}
      format={'(##) ## #####-####'}
      placeholder={'(99) 99 99999-9999'}
      type="tel"
      isNumericString
      onValueChange={values =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      {...other}
    />
  );
});

const EditarContactDialog = ({
  open,
  edit,
  contact,
  onClose,
  onSave,
  onEdit,
}) => {
  const [error, setMessageError] = useState({ isError: true }),
    [contactOut, setContactOut] = useState({});

  useEffect(() => {
    if (edit) {
      setContactOut({
        nome_contato: contact.name_chat,
        numero: contact.number_chat,
      });
    } else {
      setContactOut({});
    }
  }, [open, contact, edit]);

  const clearErrors = nome => {
    if (nome) {
      if (error[nome]) {
        delete error[nome];
        setMessageError({ ...error });
      }
    } else {
      setMessageError({});
    }
  };

  const handleSetContact = (attr, value) =>
    setContactOut({ ...contactOut, [attr]: value });

  const validate = () => {
    clearErrors();
    let messages = {};
    if (!contactOut.nome_contato) {
      messages['nome_contato'] = 'Informe o nome';
    }
    if (!contactOut.numero) {
      messages['numero'] = 'Informe o numero';
    }

    setMessageError({
      ...error,
      ...messages,
      isError: Boolean(Object.keys(messages).length),
    });
    return !Object.keys(messages).length;
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {edit ? contactOut.nome_contato : 'Adicionar contato'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="nome_contato"
              label="Nome"
              variant="outlined"
              value={contactOut.nome_contato}
              onChange={({ target: { name, value } }) =>
                handleSetContact(name, value)
              }
              error={!!error['nome_contato']}
              helperText={error['nome_contato']}
              onFocus={() => clearErrors('nome_contato')}
              onBlur={() => validate()}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              name="numero"
              label={edit ? null : 'Número'}
              variant="outlined"
              disabled={edit}
              defaultValue={contactOut.numero}
              value={contactOut.numero}
              onChange={({ target: { name, value } }) => {
                handleSetContact(name, value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!!error['numero']}
              helperText={error['numero']}
              onFocus={() => clearErrors('numero')}
              onBlur={() => validate()}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="documento"
              label="Documento"
              variant="outlined"
              value={contactOut.documento}
              onChange={({ target: { name, value } }) =>
                handleSetContact(name, value)
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancelar
        </Button>

        <Button
          disabled={error.isError}
          onClick={() => (edit ? onEdit(contactOut) : onSave(contactOut))}
          color="primary"
        >
          {edit ? 'Editar' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditarContactDialog.propTypes = {
  contact: PropTypes.object,
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

EditarContactDialog.defaultProps = {
  contact: {
    name_chat: '',
    number_chat: '',
    documento: '',
  },
  open: false,
  edit: false,
  onClose: () => {},
};

NumberFormatCustom.propTypes = {
  // inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditarContactDialog;
