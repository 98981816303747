const INITIAL_STATE = {
  lojas: null,
  cupons: [],
  infoCupom: [],
  isLoadingCupom: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "onChange":
      return { ...state, [action.payload.state]: action.payload.value };
    case "GET_INFO_CUPOM":
      return { ...state, infoCupom: action.payload, isLoadingCupom: false };
    case "GET_CUPONS":
      return {
        ...state,
        cupons: action.payload,
        isLoadingCupom: false
      };
    case "GET_LOJAS":
      return {
        ...state,
        lojas: action.payload
      };
    case "LOADING_CUPOM":
      return { ...state, isLoadingCupom: true };

    default:
      return state;
  }
};
