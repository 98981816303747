/* eslint-disable */
import { Dialog, Divider, IconButton, Slide, Typography, withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { loadCSS } from 'fg-loadcss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Countup, CustomTable } from '../../../utils/components/index';
import { modalPessoa } from '../../Insights/actions/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

function ModalPerfis(props) {
  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, []);

  const { isLoadingProdutosOferta, produtosCategoria } = props;

  async function renderImg(url) {
    return <img src={url} alt="" width={80} height={80} style={{ borderRadius: 10 }} />;
    // try {
    //   await fetch(url);
    //   return (
    //     <img
    //       src={url}
    //       alt=""
    //       width={80}
    //       height={80}
    //       style={{ borderRadius: 10 }}
    //     />
    //   );
    // } catch(err) {
    //   return (
    //     <img
    //       src={
    //         "https://trali.com.br/wp-content/uploads/2018/04/Produto-sem-imagem.jpg"
    //       }
    //       alt=""
    //       width={80}
    //       height={80}
    //       style={{ borderRadius: 10 }}
    //     />
    //   );
    // }
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth="xl"
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
        Produtos
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <CustomTable
          title=""
          isLoading={isLoadingProdutosOferta}
          paging
          search
          columnsButton
          pagesSize={7}
          columns={[
            {
              field: 'url',
              Title: 'Produto',
              render: rowData => (
                <img
                  src={rowData.url}
                  crossOrigin="anonymous"
                  alt=""
                  width={80}
                  height={80}
                  style={{ borderRadius: 10 }}
                />
              )
            },
            { title: 'Nome do Produto', field: 'produto' },
            { title: 'Familia', field: 'familia' },
            {
              title: 'Preço original',
              field: 'preco_original',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.preco_original}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: 'Preço dois',
              field: 'preco_dois',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.preco_dois}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: 'Desconto',
              field: 'desconto',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.desconto}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: 'Investimento',
              field: 'investimento',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.investimento}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: 'Faturamento',
              field: 'faturamento',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.faturamento}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: 'ROI',
              field: 'roi',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.roi}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            { title: 'Vendas', field: 'qtde_vendas' }
          ]}
          data={produtosCategoria}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ OfertasReducer }) => ({ ...OfertasReducer });

const mapDispatchToProps = { modalPessoa };

const ModalPerfisConnect = connect(mapStateToProps, mapDispatchToProps)(ModalPerfis);

export default withStyles(styles)(ModalPerfisConnect);
