import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { map } from 'lodash';
import { useCallback, useState } from 'react';
//import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

type IconButtonMenuProps = IconButtonProps & {
  onClick: () => void;
  options: {
    icon?: string;
    label: string;
    title?: string;
    description?: string;
    onClick?: () => void;
  }[];
};

export function NewIconButtonMenu({ options, onClick, ...rest }: IconButtonMenuProps) {
  const [company] = useState(JSON.parse(localStorage.getItem('company')!));
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOption = useCallback((_: any) => {
    _?.onClick();
    handleClose();
  }, []);

  const handleClickForm = useCallback(() => {
    onClick();
    handleClose();
  }, []);

  return (
    <>
      <IconButton
        {...rest}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {rest.children}
      </IconButton>
      <Menu
        keepMounted
        classes={{ root: styles.root, paper: styles.paper }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {map(options, (option, index) => (
          <>
            <MenuItem
              style={{ padding: 12 }}
              key={index.toString()}
              onClick={() => {
                handleClickOption(option);
              }}
            >
              <div>
                {option?.title && (
                  <ListItemText primary={option.title} classes={{ primary: styles.font }} />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '5px'
                  }}
                >
                  {option?.icon && (
                    <Avatar
                      id="avatar"
                      className={styles.icon}
                      src={option.icon}
                      sx={{ width: 30, height: 30 }}
                      variant="rounded"
                    />
                  )}
                  <ListItemText primary={option.label} classes={{ primary: styles.font }} />
                </div>
              </div>
            </MenuItem>
            {option?.label === 'delivery Vip' && !company?.deliveryVip && (
              <Button
                color="secondary"
                style={{
                  width: '90%',
                  height: '36px',
                  backgroundColor: '#2813AD',
                  marginLeft: '5%',
                  marginBottom: '20px',
                  marginTop: '3px',
                  color: '#fff'
                }}
                onClick={handleClickForm}
              >
                cadastrar loja online
              </Button>
            )}
          </>
        ))}
      </Menu>
    </>
  );
}

