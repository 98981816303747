import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography, Divider } from '@material-ui/core';

import { Select, CustomTable, MyButton } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import {
  postCategoriasDelivery,
  getCategoriasDelivery,
  onChange,
  putStatusCategorias,
  getLojas,
  putCategoriasDelivery,
} from '../action/action';
import MyAlert from '../../../utils/helpers/Alert';
import ModalCriarCategoria from '../components/ModalCriarCategoria';

const _myAlert = new MyAlert();

function Categorias(props) {
  const { classes } = props;
  const [modalCriarCategoria, setModalCategoria] = useState(false);

  useEffect(() => {
    props.getCategoriasDelivery();
    props.getLojas();
  }, []);

  function onSave() {
    const objCategoria = {
      nome_categoria: props.categoriaDelivery.nome_categoria,
      lojas:
        props.categoriaDelivery.lojas_categoria &&
        props.categoriaDelivery.lojas_categoria.map(loja => loja.id_loja),
    };
    props.postCategoriasDelivery(objCategoria);
    setModalCategoria(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Categorias do pedido
        </Typography>
      </div>

      <Typography className={classes.label} variant="h6" noWrap>
        Filtrar por loja
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '25vw' }}>
          <Select
            onChange={loja =>
              props.getCategoriasDelivery(loja ? loja.id_loja : null)
            }
            label="Lojas"
            labelKey="nome_loja"
            valueKey="id_loja"
            options={
              props.lojas ? props.lojas.filter(loja => loja.tipo_pedido) : []
            }
          />
        </div>
        <MyButton
          onClick={() => setModalCategoria(true)}
          size="large"
          className={classes.button}
          variant="text"
          color="primary"
        >
          Criar categoria
        </MyButton>
      </div>
      <Divider className={classes.divider} />
      <div
        className={classes.content}
        style={{
          display: 'flex',
          height: '100%',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomTable
          data={props.categorias}
          isLoading={props.loadingDelivery}
          title=""
          columnsButton
          pageSize={5}
          paging
          search
          headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
          options={{
            rowStyle: rowData =>
              rowData.ativo ? {} : { backgroundColor: '#ffcdd2' },
          }}
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
            backgroundColor: '#eee',
          }}
          columns={[
            { title: 'Categoria', field: 'nome_categoria' },
            {
              title: 'Loja',
              field: 'nome_loja',
              editable: 'never',
            },
          ]}
          editable={{
            onRowUpdate: newData =>
              new Promise(resolve => {
                props.putCategoriasDelivery(newData);
                resolve();
              }),
          }}
          actions={[
            rowData => ({
              icon: rowData.ativo ? 'remove' : 'add',
              tooltip: rowData.ativo
                ? 'Inativar categoria'
                : 'Ativar categoria',
              onClick: (event, rowData) =>
                _myAlert.alertConfirm(
                  `Deseja ${
                    rowData.ativo ? 'inativar categoria' : 'ativar categoria'
                  } ?`,
                  () => props.putStatusCategorias(rowData)
                ),
            }),
          ]}
        />
      </div>
      {modalCriarCategoria && (
        <ModalCriarCategoria
          open={modalCriarCategoria}
          onClose={() => setModalCategoria(false)}
          onChange={props.onChange}
          onSave={() => onSave()}
        />
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer,
});

const mapDispatchToProps = {
  getLojas,
  getCategoriasDelivery,
  onChange,
  postCategoriasDelivery,
  putStatusCategorias,
  putCategoriasDelivery,
};

const CategoriasConn = connect(mapStateToProps, mapDispatchToProps)(Categorias);

export default withStyles(styles)(CategoriasConn);
