/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';
import { TextField, MyButton } from '../../../utils/components';
import '../style/modal.css';

class ModalPerfil extends Component {
  constructor(props) {
    super(props);
    this.state = { activeStep: 0, motivo: '', documento: '' };
  }

  render() {
    const { onClose, showModal, onSave } = this.props;
    const { activeStep, motivo, documento } = this.state;
    return (
      <Dialog open={showModal} fullWidth="md">
        <DialogTitle className="modal-title" onClose={onClose}>
          Justificativa
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
              <Step>
                <StepButton onClick={() => this.setState({ activeStep: 0 })}>
                  Motivo
                </StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => this.setState({ activeStep: 1 })}>
                  Documento
                </StepButton>
              </Step>
            </Stepper>
          </div>
          {activeStep === 0 && (
            <Fragment>
              <center>
                <strong>
                  Deixe um breve comentário sobre o motivo dessa ação.
                </strong>
              </center>
              <TextField
                label="Comentário"
                fullWidth
                multiline
                rows="10"
                margin="normal"
                variant="outlined"
                onChange={({ target: { value } }) =>
                  this.setState({ motivo: value })
                }
              />
            </Fragment>
          )}
          {activeStep === 1 && (
            <Fragment>
              <center>
                <strong>Informe seu CPF para registrarmos sua ação</strong>
              </center>
              <TextField
                label="CPF"
                className="form-control"
                autoComplete="none"
                onChange={({ target: { value } }) =>
                  this.setState({ documento: value })
                }
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <MyButton onClick={onClose}>Cancelar</MyButton>
          {activeStep === 0 ? (
            <MyButton
              onClick={() => this.setState({ activeStep: 1 })}
              color="primary"
              autoFocus
            >
              Próximo
            </MyButton>
          ) : (
            <MyButton
              onClick={() => {
                onSave({ documento, motivo });
                onClose();
              }}
              color="primary"
              autoFocus
            >
              OK
            </MyButton>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPerfil);
