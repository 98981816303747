import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { Form } from 'react-final-form';
import CurrencyTextfield from '../../../CurrencyTextfield';
import useStyles from './styles';

function Strategy({ product, onSubmit }) {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Form
            initialValues={product}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <CardHeader title="Editar preço" />

                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <CurrencyTextfield label="Preço" name="price" />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={!product.filter_id}
                    startIcon={<SaveIcon />}
                  >
                    Salvar
                  </Button>
                </CardActions>
              </form>
            )}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default Strategy;
