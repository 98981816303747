import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  preview: {
    width: '150px',
    borderRadius: theme.shape.borderRadius,
  },
  adCreativePreview: {
    height: 96,
    width: 96,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  button: {
    height: 56,
  },
}));

export default useStyles;
