import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  stepTitle: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    boxShadow: 'none',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: theme.palette.divider,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3, 2),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  loader: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10,
  },
  scrool: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  colorBox: {
    height: 50,
    width: 50,
    borderRadius: 25,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
}));

export default useStyles;
