import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Field } from 'react-final-form';
import useStyles from './styles';

const FileField = ({ name, ...props }) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => {
        const handleChange = ({ target }) => {
          onChange(target.files);
        };
        return (
          <React.Fragment>
            <input
              {...input}
              id="contained-button-file"
              className={classes.fileIput}
              type="file"
              onChange={handleChange}
              {...props}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                color="default"
                size="large"
                startIcon={<AddIcon />}
              >
                Upload
              </Button>
            </label>
          </React.Fragment>
        );
      }}
    </Field>
  );
};

export default FileField;
