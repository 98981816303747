import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  platformsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    marginBottom: theme.spacing(2)
  },
  mediaContainer: {
    height: 250,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  media: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
