import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Typography, withStyles, Avatar, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getCupomSorteio, getCupomSorteioById } from '../actions/actions';
import { Panel, CountCard, DatePicker, Select } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import ModalPerfil from './ModalPerfil';

class CupomSorteio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeCupom: '',
      showModal: false,
      id_loja: null,
      filtroDataInicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      filtroDataFim: moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const cupons = {
      dataInicio: this.state.filtroDataInicio,
      dataFim: moment(this.state.filtroDataFim).add('1', 'days').format('YYYY-MM-DD'),
    };
    this.props.getCupomSorteio(cupons);
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data }, () => {
      const cupons = {
        dataInicio: this.state.filtroDataInicio,
        dataFim: moment(this.state.filtroDataFim).add('1', 'days').format('YYYY-MM-DD'),
      };
      this.props.getCupomSorteio(cupons);
    });
  }

  verPerfil(cupom) {
    this.props.getCupomSorteioById(cupom.id_cupom_sorteio);
    this.setState({ showModal: true, nomeCupom: cupom.nome_cupom });
  }

  render() {
    const { classes, cuponsSorteio } = this.props;
    const { filtroDataInicio, filtroDataFim, showModal } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Cupom de sorteio
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date => this.onChangePeriodo('filtroDataInicio', date)}
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
              </div>
            </div>
            <div
              style={{
                display: 'none', //flex
                flexDirection: 'column',
                width: '50%',
                marginLeft: 20,
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por Lojas
              </Typography>

              <Select
                label="Lojas"
                onChange={e => this.onChangePeriodo('id_loja', e ? e.value : null)}
                options={this.props.lojas}
              />
            </div>
          </section>
          <Button
            size="large"
            variant="contained"
            style={{ backgroundColor: COLOR_PRIMARY }}
            color="primary"
            component={Link}
            to="/criar-cupom-sorteio">
            Criar cupom de sorteio
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}>
          <Button
            style={{
              marginRight: 10,
              borderRadius: 10,
              '&:hover': { borderRadius: 10 },
            }}
            color="inherit"
            size="large"
            className={classes.activeButton}>
            Cupons de sorteio
          </Button>
        </div>
        <div
          className={classes.content}
          style={
            this.props.isLoadingCupom === true
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }>
          {this.props.isLoadingCupom === true ? (
            <div>Carregando</div>
          ) : (
            cuponsSorteio.map(cupom => (
              <Panel
                avatar={
                  <Avatar className={classes.avatar}>
                    <Icon>shopping_cart</Icon>
                  </Avatar>
                }
                subheader={`${moment(cupom.data_inicio).format('DD/MM/YYYY')} - ${moment(
                  cupom.data_fim
                ).format('DD/MM/YYYY')} `}
                title={cupom.nome_cupom}
                style={{ marginBottom: 20 }}
                action={
                  <Button
                    size="large"
                    className={classes.button}
                    onClick={() => this.verPerfil(cupom)}
                    variant="text"
                    color="primary">
                    Ver Perfis
                  </Button>
                }>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginBottom: 20,
                    }}>
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.minimo_gasto}
                      decimals={2}
                      prefix="R$ "
                      title="Valor do cupom"
                      icon="attach_money"
                      style={{ marginRight: 20 }}
                    />

                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.qtde_cupons}
                      title="Quantidade de cupons"
                      icon="confirmation_number"
                      style={{ marginRight: 20 }}
                    />
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.qtde_pessoa}
                      title="Quantidade de pessoas"
                      icon="person"
                      style={{ marginRight: 20 }}
                    />
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.impressos}
                      title="Cupons impressos"
                      icon="print"
                      style={{ marginRight: 20 }}
                    />
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.n_impressos}
                      title="Cupons não impressos"
                      icon="print_disabled"
                      style={{ marginRight: 20 }}
                    />
                  </div>
                </div>
              </Panel>
            ))
          )}
        </div>
        {showModal && (
          <ModalPerfil
            {...this.state}
            open={showModal}
            onClose={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ CupomSorteioReducer }) => ({
  ...CupomSorteioReducer,
});

const mapDispatchToProps = { getCupomSorteio, getCupomSorteioById };

const CupomSorteioConnect = connect(mapStateToProps, mapDispatchToProps)(CupomSorteio);

export default withStyles(styles)(CupomSorteioConnect);
