const INITIAL_STATE = {
  idOferta: null,
  ofertaCabecalho: {},
  lojas: [],
  ofertaDetalhe: [],
  ofertas: {
    visaoGeral: [],
    completas: [],
    naoCompletas: [],
    vendasDia: [],
    vendasHora: [],
    vendasLoja: [],
    faixaEtaria: [],
    genero: [],
  },
  familias: [],
  produtos: [],
  produtosCategoria: {
    produtos: [],
    visaoGeral: [],
    vendasDia: [],
    vendasHora: [],
    genero: [],
    faixaEtaria: [],
    vendasLoja: [],
  },
  produto: {
    detalhesProduto: [],
    vendasHora: [],
    vendasDia: [],
    faixaEtaria: [],
    genero: [],
    vendasLoja: [],
  },
  detalhesOferta: {
    categorias: [],
    visaoGeral: [],
    vendasDia: [],
    vendasHora: [],
    genero: [],
    faixaEtaria: [],
    vendasLoja: [],
  },
  categorias: [],
  visaoGeral: {},
  pessoas: [],
  pessoa: null,
  produto_search: [],
  produtosImagens: [],

  isLoadingOfertaCab: false,
  isLoadingOfertas: false,
  isLoadingPerformanceOfertas: false,
  isLoadingVendasOfertaHora: false,
  isLoadingVendasOfertaDia: false,
  isLoadingVendasOfertaLoja: false,
  isLoadingProdutosFamilia: false,
  isLoadingProdutosCategoria: false,
  isLoadingCategorias: false,
  isLoadingPessoas: false,
  isLoadingEdit: false,
  isLoadingDetalhesProduto: false,
  isLoadingProdutosLista: false,
  isLoadingPessoa: false,
  isLoadingProdutoSearch: false,
  isLoadingProdutosImagens: false,
  isloadingPhoto: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'onChange':
      return { ...state, [action.payload.state]: action.payload.value };
    case 'onChangeOfertaCabecalho':
      return {
        ...state,
        ofertaCabecalho: {
          ...state.ofertaCabecalho,
          [action.payload.state]: action.payload.value,
        },
      };
    case 'onChangeOfertaDetalhe':
      return {
        ...state,
        ofertaDetalhe: {
          ...state.ofertaDetalhe,
          [action.payload.state]: action.payload.value,
        },
      };
    case 'POST_CABECALHO':
      return { 
        ...state, 
        idOferta: action.payload, 
        ofertaDetalhe: [],
        isLoadingOfertaCab: false 
      };
    case 'GET_OFERTAS':
      return { ...state, ofertas: action.payload, isLoadingOfertas: false };
    case 'GET_OFERTAS_COMPLETAS':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          completas: action.payload.completas,
        },
        isLoadingOfertas: false,
      };
    case 'GET_PERFORMANCE_OFERTAS':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          visaoGeral: action.payload.visaoGeral,
          faixaEtaria: action.payload.faixaEtaria,
          genero: action.payload.genero,
        },
        isLoadingPerformanceOfertas: false,
      };
    case 'GET_OFERTAS_NAO_COMPLETAS':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          naoCompletas: action.payload.naoCompletas,
        },
        //isLoadingOfertas: false
      };
    case 'GET_VENDAS_OFERTA_HORA':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          vendasHora: action.payload.vendasHora,
        },
        isLoadingVendasOfertaHora: false,
      };
    case 'GET_VENDAS_OFERTA_DIA':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          vendasDia: action.payload.vendasDia,
        },
        isLoadingVendasOfertaDia: false,
      };
    case 'GET_VENDAS_OFERTA_LOJA':
      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          vendasLoja: action.payload.vendasLoja,
        },
        isLoadingVendasOfertaLoja: false,
      };
    case 'GET_FAMILIAS':
      return { ...state, familias: action.payload };
    case 'GET_PRODUTOS':
      return {
        ...state,
        produtos: action.payload,
        isLoadingProdutosFamilia: false,
      };
    case 'GET_DETALHES_PRODUTO':
      return {
        ...state,
        produto: action.payload,
        isLoadingDetalhesProduto: false,
      };
    case 'GET_LOJAS':
      return {
        ...state,
        lojas: action.payload,
      };
    case 'GET_DETALHES_OFERTA':
      return {
        ...state,
        // produtosCategoria: action.payload.produtos,
        detalhesOferta: action.payload,
        // categorias: action.payload.categorias,
        // visaoGeral: action.payload.visaoGeral
        //   ? action.payload.visaoGeral[0]
        //   : {},

        isLoadingCategorias: false,
      };
    case 'GET_PRODUTOS_CATEGORIA':
      return {
        ...state,
        // produtosCategoria: action.payload.produtos,
        produtosCategoria: action.payload,
        isLoadingProdutosCategoria: false,
      };
    case 'GET_PESSOAS_OFERTA':
      return {
        ...state,
        pessoas: action.payload,
        isLoadingPessoas: false,
      };
    case 'GET_PRODUTO_SEARCH':
      return {
        ...state,
        produto_search: action.payload,
        isLoadingProdutoSearch: false,
      };
    case 'GET_PESSOA':
      return {
        ...state,
        pessoa: Object.keys(action.payload).length > 0 
                  ? action.payload
                  : null,
        isLoadingPessoa: false,
      };  
    case 'CLEAR_PESSOA':
      return {
        ...state,
        pessoa: null,
        produto_search: [],
      }; 
    case 'GET_EDIT_OFERTA':
      return {
        ...state,
        ofertaCabecalho: action.payload.ofertaCabecalho,
        ofertaDetalhe: action.payload.ofertaDetalhe,
        isLoadingEdit: false,
      };
    case 'GET_PRODUTOS_IMAGENS':
      return {
        ...state,
        produtosImagens: action.payload,
        isLoadingProdutosImagens: false,
      };
    case 'POST_PRODUTOS':
      return {
        ...state,
        ofertaDetalhe: action.payload,
        isLoadingProdutosLista: false,
      };
    case 'LOADING_PRODUTOS':
      return { ...state, isLoadingProdutosFamilia: true };
    case 'LOADING_PRODUTOS_LISTA':
      return { ...state, isLoadingProdutosLista: true };
    case 'LOADING_EDIT_OFERTA':
      return { ...state, isLoadingEdit: true };
    case 'LOADING_PESSOAS':
      return { ...state, isLoadingPessoas: true };
    case 'LOADING_OFERTAS':
      return { ...state, isLoadingOfertas: true };
    case 'LOADING_PERFORMANCE_OFERTAS':
      return { ...state, isLoadingPerformanceOfertas: true };
    case 'LOADING_VENDAS_OFERTA_HORA':
      return { ...state, isLoadingVendasOfertaHora: true };
    case 'LOADING_VENDAS_OFERTA_DIA':
      return { ...state, isLoadingVendasOfertaDia: true };
    case 'LOADING_VENDAS_OFERTA_LOJA':
      return { ...state, isLoadingVendasOfertaLoja: true };
    case 'LOADING_CATEGORIAS_OFERTA':
      return {
        ...state,
        isLoadingCategorias: true,
      };
    case 'LOADING_PRODUTOS_OFERTA':
      return {
        ...state,
        isLoadingProdutosCategoria: true,
      };
    case 'LOADING_OFERTA_CABECALHO':
      return { ...state, isLoadingOfertaCab: true };
    case 'LOADING_DETALHES_PRODUTO':
      return { ...state, isLoadingDetalhesProduto: true };
    case 'LOADING_PESSOA':
      return { ...state, isLoadingPessoa: true };  
    case 'LOADING_PRODUTO_SEARCH':
      return { ...state, isLoadingProdutoSearch: true };
    case 'LOADING_PRODUTOS_IMAGENS':
      return { ...state, isLoadingProdutosImagens: true };
    case "LOADING_PHOTO":
      return { ...state, isloadingPhoto: true };
    default:
      return state;
  }
};
