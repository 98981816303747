import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../utils/helpers/Constants';

const AdsContext = createContext({});

const AdsProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = JSON.parse(localStorage.getItem('token'));
    const user = localStorage.getItem(
      '@datasales:facebookCurrentAuthenticatedUser'
    );

    if (user) {
      return { token, user: JSON.parse(user) };
    }
    return {};
  });

  const signIn = useCallback(async data => {
    const response = await api(
      `${DATASALES_CRM_API}ads-manager-${AMBIENTE}/users`,
      {
        user_id: data.userID,
        name: data.name,
        email: data.email,
        picture_url: data.picture.data.url,
        access_token: data.accessToken,
        expires_in: data.expiresIn,
        access_expiration_time: data.data_access_expiration_time,
        is_connected: true,
      },
      'POST'
    );

    const { content } = response;

    localStorage.setItem(
      '@datasales:facebookCurrentAuthenticatedUser',
      JSON.stringify(content)
    );

    setData({ user: content });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@datasales:facebookCurrentAuthenticatedUser');

    setData({});
  }, []);

  const selectAccount = useCallback(async (userId, adAccountId) => {
    await api(
      `${DATASALES_CRM_API}ads-manager-${AMBIENTE}/users/${userId}`,
      {
        ad_account_id: adAccountId,
      },
      'PUT'
    );

    localStorage.setItem(
      '@datasales:facebookCurrentAuthenticatedUser',
      JSON.stringify({ ...data.user, ad_account_id: adAccountId })
    );

    setData(prevState => ({
      user: { ...prevState.user, ad_account_id: adAccountId },
    }));
  });

  return (
    <AdsContext.Provider
      value={{ user: data.user, signIn, signOut, selectAccount }}
    >
      {children}
    </AdsContext.Provider>
  );
};

function useAds() {
  const context = useContext(AdsContext);

  if (!context) {
    throw new Error(' useAuth must be used within an adsProvider ');
  }
  return context;
}

export { AdsProvider, useAds };
