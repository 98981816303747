import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { COLOR_PRIMARY, BG_PRIMARY } from "../../helpers/Constants";

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const { title, subheader, children, loading } = props;

  return (
    <Card className={classes.card} style={props.style}>
      <CardHeader
        action={
          props.showMore && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )
        }
        style={{ color: BG_PRIMARY }}
        title={title}
        titleTypographyProps={{ variant: "h5" }}
        subheader={subheader}
      />
      {props.showMore ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.content}>
            {loading ? (
              <CircularProgress className={classes.progress} size={40} />
            ) : (
              children
            )}
          </CardContent>
        </Collapse>
      ) : (
        <CardContent className={classes.content}>
          {loading ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={40}
            />
          ) : (
            children
          )}
        </CardContent>
      )}
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    marginTop: theme.spacing(3),
    borderRadius: 10
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },

  progress: {
    color: COLOR_PRIMARY,
    margin: theme.spacing(2)
  }
}));
