import React from "react";
import "./Container.css";
export default props => (
  <section
    style={props.style}
    className={`containerStyleComponent ${props.className}`}
  >
    {props.loading ? (
      <div className="loader-example">
        <div className="loader-bar" />
      </div>
    ) : (
      props.children
    )}
  </section>
);
