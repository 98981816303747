const INITIAL_STATE = {
  data: {
    contadores: {},
    percentualVendas: {},
    visaoGeralCampanhas: {},
    visaoGeralOfertas: {}
  },
  loadingPerformance: false,
  loadingRankingLojas: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PERFORMANCE":
      return {
        ...state,
        data: action.payload,
        loadingPerformance: false
      };
    case "LOADING_PERFORMANCE":
      return { ...state, loadingPerformance: true };

    default:
      return state;
  }
};
