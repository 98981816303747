import moment from "moment";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";
import Fetch from "../../../utils/helpers/Fetch";

export const isLoadingCountPhone = () => dispatch =>
  dispatch({ type: "LOADING_COUNT_PHONE" });
export const isLoadingCountEmail = () => dispatch =>
  dispatch({ type: "LOADING_COUNT_EMAIL" });

export const getTipoRecomedacoes = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/getTipoRecomendacoes`
  ).then(payload => dispatch({ type: "TIPO_RECOMENDACOES", payload }));

export const getRecomedacoes = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/getTipoRecomendacoes`
  ).then(res => {
    const data = res.map(rec => rec);

    Fetch(
      `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/getRecomendacoes`,
      { id_recomendacao_tipo: data[0].id_recomendacao_tipo }
    ).then(({ lista, ofertas }) => {
      const date = moment().format("MM/YYYY");
      const recomendacoes = lista.map(rec => ({
        id_recomendacao: rec.id_recomendacao,
        id_recomendacao_tipo: rec.id_recomendacao_tipo,
        nome_recomendacao: rec.nome_recomendacao,
        data: moment(rec.data_insert).format("MM/YYYY")
      }));
      const recomendacoesAtivas = recomendacoes.filter(
        rec => rec.data === date
      );
      const recomendacoesInativas = recomendacoes.filter(
        rec => rec.data !== date
      );
      dispatch({
        type: "RECOMENDACOES",
        payload: {
          recomendacoesAtivas,
          recomendacoesInativas,
          ofertas
        }
      });
    });
  });

export const contadorCelular = celular => dispatch => {
  const request = {
    idListProspectei: celular.idListProspectei,
    celular: celular.celulares
  };

  dispatch(isLoadingCountPhone());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorCelular`,
    request
  ).then(payload => dispatch({ type: "CONTADOR_CELULAR", payload }));
};

export const contadorEmail = email => dispatch => {
  const request = {
    idListProspectei: email.idListProspectei,
    email: email.emails
  };

  dispatch(isLoadingCountEmail());
  Fetch(
    `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorEmail`,
    request
  ).then(payload => dispatch({ type: "CONTADOR_EMAIL", payload }));
};

export const envioCampanhaEmail = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/postEmailRecomendacao`,
    campanha,
    "POST"
  );

export const envioCampanhaSMS = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/postSMSRecomendacao`,
    campanha,
    "POST"
  );
export const envioCampanhaCarta = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecomendacaoCarta`,
    campanha,
    "POST"
  );

export const envioCampanhaRedeSocial = campanha => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}recomendacao-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecomendacaoRedeSocial`,
    campanha,
    "POST"
  );
