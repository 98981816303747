import React, { Fragment } from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import ShowMore from "@tedconf/react-show-more";
import { MyButton } from "./../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
export default ({
  data,
  title,
  subtitle,
  bubbleStyle,
  dataFormat,
  icon,
  limitPage,
  noButton
}) => (
  <ShowMore by={limitPage || 3} items={data}>
    {({ current, onMore }) => (
      <section style={{ display: "grid", width: "100%" }}>
        {current.length > 0 ? (
          <Fragment>
            <Timeline>
              {current.map(dt => (
                <TimelineEvent
                  title={title(dt)}
                  createdAt={subtitle(dt)}
                  icon={icon}
                  bubbleStyle={bubbleStyle}
                >
                  {dataFormat(dt)}
                </TimelineEvent>
              ))}
            </Timeline>
            {!noButton && (
              <MyButton
                bsstyle="primary"
                disabled={!onMore}
                bsSize="xs"
                onClick={() => {
                  if (!!onMore) onMore();
                }}
              >
                <span style={{ fontSize: "1.2em" }}>
                  <FontAwesomeIcon icon={faSearchPlus} /> &nbsp; Ver Mais
                </span>
              </MyButton>
            )}
          </Fragment>
        ) : (
          <section className="semResultado">
            Nenhum resultado encontrado
          </section>
        )}
      </section>
    )}
  </ShowMore>
);
