import { Box, Dialog, DialogContent, Icon, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const CustomDialog = styled(Dialog)({
  marginTop: 71,
  '& .MuiDialog-paper': {
    borderRadius: '20px 20px 0 0',
    overflow: 'hidden'
  }
});

const CustomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '24px 40px',
  width: '100%',
  background: '#FFF',
  borderRadius: '20px 20px 0 0',
  position: 'fixed'
});

interface INewEmptyModalProps {
  open: boolean;
  handleClose: () => void;
  filter: ReactNode;
  children: ReactNode;
}

export const NewEmptyModal = ({ open, handleClose, children, filter }: INewEmptyModalProps) => {
  return (
    <CustomDialog fullScreen open={open} onClose={handleClose}>
      <CustomBox>
        <Box display="flex" flexDirection="row" gap={1}>
          {filter}
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <Icon>clear</Icon>
          </IconButton>
        </Box>
      </CustomBox>
      <Box
        p="90px 40px"
        sx={{
          overflow: 'hidden'
        }}
      >
        {' '}
        {children}
      </Box>
    </CustomDialog>
  );
};

