import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';

export const salvarPvd = obj => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/postPoliticaPrivacidade`,
    obj,
    'POST'
  ).catch(err => console.log(err));
};

export const editPvd = () => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/putPvd`,
    'PUT'
  ).catch(err => console.log(err));
};

export const deletePvd = obj => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/deletePvd`,
    obj,
    'PUT'
  ).catch(err => console.log(err));
};

export const getPvd = () => dispatch => {
  Fetch(`${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getPvd`)
    .then(resp => dispatch({ type: 'GET_PVD', payload: resp }))
    .catch(err => console.log(err));
};

export const setTemplate = template => {
  return {
    type: 'SET_TEMPLATE',
    payload: template,
  };
};

export const setEditor = editor => {
  return {
    type: 'setEditor',
    payload: editor,
  };
};

export const setSelectedModule = selectedModule => {
  return {
    type: 'SET_SELECTED_MODULE',
    payload: selectedModule,
  };
};
