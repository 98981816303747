import React from 'react';
import {
  Slide,
  withStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Dialog } from '@mui/material'

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Modal = props => (
  <Dialog
    TransitionComponent={Transition}
    aria-labelledby="form-dialog-title"
    fullWidth={props.size || 'xl'}
    maxWidth={props.size || 'xl'}
    fullScreen={props.fullScreen}
    open={props.showModal}
    onClose={props.onClose}
    disableBackdropClick={props.disableBackdropClick || false}
  >
    <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
      {props.header}
    </DialogTitle>

    <DialogContent style={props.styleContent} dividers={!!props.dividers}>
      {props.children}
    </DialogContent>
    <DialogActions>{props.footer}</DialogActions>
  </Dialog>
);

export default withStyles(styles)(Modal);
