import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import LoopIcon from '@material-ui/icons/Loop';
import PeopleIcon from '@material-ui/icons/People';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DialogTitle from '../../../../utils/components/DialogTitle';
import TabPanel from '../../../../utils/components/TabPanel';
import api from '../../../../utils/helpers/Fetch';
import Ads from './components/Ads';
import useStyles from './styles';

// import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import ScoreIcon from '@material-ui/icons/Score';

function Statistics({ open, imageId, onClose }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const isEmpty = !loading && statistics.length === 0;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await api(
          `https://yrwyq4jw43.execute-api.us-east-1.amazonaws.com/dev/statistic`,
          { id: imageId },
          'GET',
          null,
          null,
          false
        );

        setStatistics(response);
      } catch (err) {
        enqueueSnackbar('Erro ao carregar estatistias', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" fullScreen onClose={onClose}>
      <DialogTitle onClose={onClose}>Estatísticas</DialogTitle>
      {!loading && (
        <Tabs
          indicatorColor="primary"
          onChange={handleTabChange}
          value={tabValue}
        >
          <Tab className={classes.tab} label="Padrão" />
          <Tab className={classes.tab} label="Carro de som digital" />
        </Tabs>
      )}
      <DialogContent dividers>
        <TabPanel index={0} value={tabValue}>
          {loading && !isEmpty && (
            <div className={classes.loadingContainer}>
              <CircularProgress color="primary" />{' '}
            </div>
          )}
          {isEmpty && !loading && (
            <div className={classes.emptyContainer}>
              <Typography variant="h6">Nenhum resultado</Typography>
            </div>
          )}
          {!loading && !isEmpty && (
            <Grid container spacing={2}>
              {statistics &&
                statistics.map(item => (
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        title={item.nome_campanha}
                        subheader=""
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <WhatsAppIcon />
                          </Avatar>
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Card variant="outlined">
                              <CardHeader
                                avatar={
                                  <Avatar
                                    className={classes.avatar}
                                    variant="rounded"
                                  >
                                    <PeopleIcon />
                                  </Avatar>
                                }
                                title={item.pessoas_alcancadas}
                                subheader="Alcance"
                              />
                            </Card>
                          </Grid>

                          <Grid item xs={3}>
                            <Card variant="outlined">
                              <CardHeader
                                avatar={
                                  <Avatar
                                    className={classes.avatar}
                                    variant="rounded"
                                  >
                                    <CheckIcon />
                                  </Avatar>
                                }
                                title={
                                  item.envios ? item.envios.CONCLUIDO.total : 0
                                }
                                subheader="Enviados"
                              />
                            </Card>
                          </Grid>
                          <Grid item xs={3}>
                            <Card variant="outlined">
                              <CardHeader
                                avatar={
                                  <Avatar
                                    className={classes.avatar}
                                    variant="rounded"
                                  >
                                    <LoopIcon />
                                  </Avatar>
                                }
                                title={
                                  item.envios
                                    ? item.envios.PROCESSANDO.total
                                    : 0
                                }
                                subheader="Processando"
                              />
                            </Card>
                          </Grid>
                          <Grid item xs={3}>
                            <Card variant="outlined">
                              <CardHeader
                                avatar={
                                  <Avatar
                                    className={classes.avatar}
                                    variant="rounded"
                                  >
                                    <ErrorIcon />
                                  </Avatar>
                                }
                                title={item.envios ? item.envios.ERRO.total : 0}
                                subheader="Erros"
                              />
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )}
        </TabPanel>
        <TabPanel index={1} value={tabValue}>
          <Ads offerId={imageId} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}

export default Statistics;
