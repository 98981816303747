import React, { useEffect } from 'react';
import GraphComponent from '../GraphComponent';
import { Grid } from '@material-ui/core';
import { useHome } from '../../../../hooks/home';

export default function PostGraphFacebook() {
  const {
    graphsFacebookCommentsData,
    loadingGraphsFacebookCommentsData,
    getGraphsFacebookCommentsData,

    graphsFacebookLikesData,
    loadingGraphsFacebookLikesData,
    getGraphsFacebookLikesData,

    graphsFacebookViewsData,
    loadingGraphsFacebookViewsData,
    getGraphsFacebookViewsData,

    graphsFacebookEngajamentData,
    loadingGraphsFacebookEngajamentData,
    getGraphsFacebookEngajamentData,

    graphsFacebookUniqueViewsData,
    loadingGraphsFacebookUniqueViewsData,
    getGraphsFacebookUniqueViewsData,

    graphsFacebookPaidViewsData,
    loadingGraphsFacebookPaidViewsData,
    getGraphsFacebookPaidViewsData,
  } = useHome();

  useEffect(() => {
    getGraphsFacebookCommentsData();
    getGraphsFacebookLikesData();
    getGraphsFacebookViewsData();
    getGraphsFacebookEngajamentData();
    getGraphsFacebookUniqueViewsData();
    getGraphsFacebookPaidViewsData();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}></Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1={'Comentários'}
          text2={'Quantas pessoas seguem sua página'}
          array={graphsFacebookCommentsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookCommentsData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Curtidas'}
          text2={'Quantas curtidas suas publicaçoes tiveram'}
          array={graphsFacebookLikesData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookLikesData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1="Contas alcançadas"
          text2={'Quantas vezes sua publicação apareceu para alguém'}
          array={graphsFacebookViewsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookUniqueViewsData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Visualizações de impulsionamentos'}
          text2={
            'A média de vezes que seu anúncio foi visualizado por uma mesma pessoa'
          }
          array={graphsFacebookPaidViewsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookPaidViewsData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Engajamento'}
          text2={'Quantas pessoas clicaram nas suas propagandas impulsionadas'}
          array={graphsFacebookEngajamentData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookEngajamentData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Visualizações'}
          text2={'Quantidade de pessoas que viram sua publicação'}
          array={graphsFacebookUniqueViewsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsFacebookViewsData}
        />
      </Grid>
    </Grid>
  );
}
