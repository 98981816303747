import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    hero: {
      width: '100%',
      height: '273px',
      background: 'linear-gradient(180deg, #e9e9e9 0%, rgba(245, 245, 245, 0) 100%)',
      borderBottom: '1px solid #E2E2E2'
    },
    heroItems: {},
    heroAdornmentImage: {
      position: 'absolute',
      width: '320px',
      height: '100%',
      objectFit: 'cover'
    },
    heroAdornmentImageLeft: {
      left: '0px'
    },
    heroAdornmentImageRight: {
      right: '0px'
    },
    categoriesContainer: {
      maxWidth: '680px'
    },
    categoriesSlider: {
      // overflow: 'hidden',
      width: '100%',
      // padding: '0 40px',
      '& .swiper-button-next': {
        top: '35%',
        right: '-7px',
        zIndex: 99999,
        transform: 'scale(.62)'
      },
      '& .swiper-button-prev': {
        top: '35%',
        left: '-7px',
        zIndex: 99999,
        transform: 'scale(.62)'
      }
    },
    categoriesSlide: {
      minWidth: '120px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      transition: 'all 0.3s ease',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.05)'
      },
      '&:active': {
        transition: 'all 0.2s ease',
        transform: 'scale(.95)'
      }
    },
    categoriesSlideImage: {
      width: '80px',
      height: '80px',
      overflow: 'hidden',
      borderRadius: '50%',
      marginBottom: '8px',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgb(0, 0, 0, 12%)'
    },
    categoriesSlideName: {
      color: '#B0B0B0'
    },
    categoriesSlideNameSelected: {
      fontWeight: 'bold',
      color: '#000'
    },
    categoriesSliderSkeleton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    },
    inputNoBorder: {
      border: 'none',
      borderColor: 'transparent !important'
    },
    inputBackgroundColor: {
      height: '48px',
      backgroundColor: '#FFF',
      boxShadow: '0px 4px 12px rgb(0, 0, 0, 12%)'
    }
  })
);

