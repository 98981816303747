import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Chip } from '../../../../utils/components';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

export default function ConvidarContatos({ onClose, onSave, open, instances }) {
  const [message, setMessage] = useState(''),
    [instance, setInstance] = useState(null),
    [showAlert, setShowAlert] = useState(false);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Convidar contatos</DialogTitle>
      <DialogContent>
        <Box padding="2rem 5rem">
          <Box marginBottom="2vh">
            <Typography
              style={{
                fontWeight: '900',
                color: COLOR_PRIMARY,
              }}
              variant="h5"
            >
              Instâncias
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              onChange={({ target: { value } }) => setInstance(value)}
              value={instance}
              label="Instância"
            >
              {instances.map(instan => (
                <MenuItem value={instan.id}>{instan.nome}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box marginBottom="2vh">
            <Typography
              style={{
                fontWeight: '900',
                color: COLOR_PRIMARY,
              }}
              variant="h5"
            >
              Mensagem
            </Typography>
            <Chip
              label="Primeiro nome"
              clickable
              onClick={() => setMessage(`${message} {{primeiroNome}}`)}
            />
            <Chip
              clickable
              label="Nome completo"
              onClick={() => setMessage(`${message} {{nomeCompleto}}`)}
            />
            <Chip
              clickable
              label="Click tracker"
              onClick={() => setMessage(`${message} {{clickTracker}}`)}
            />
            <TextField
              id="outlined-multiline-static"
              label="Digite a mensagem desejada..."
              fullWidth
              multiline
              rows="10"
              error={showAlert}
              value={message}
              helperText={`${message.length}/160`}
              onChange={({ target: { value } }) => {
                setMessage(value.length > 160 ? message : value);
                setShowAlert(value.length > 160 ? true : false);
              }}
              margin="normal"
              variant="outlined"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={!Boolean(message) || !Boolean(instance)}
          onClick={() => {
            onSave({ message, instance });
            onClose();
          }}
          color="primary"
        >
          Convidar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
