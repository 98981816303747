const INITIAL_STATE = {
  submenuPai: [],
  submenuFilho: [],
  loaderSubmenuFilho: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SUBMENU_PAI":
      return {
        ...state,
        submenuPai: action.payload,
        loaderSubmenuFilho: false
      };
    case "SUBMENU_FILHO":
      return {
        ...state,
        submenuFilho: action.payload,
        loaderSubmenuFilho: false
      };
    case "LOAD_SUBMENU_FILHO":
      return { ...state, loaderSubmenuFilho: true };
    default:
      return state;
  }
};
