import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core/';
import { CountCard } from '../../../utils/components';
import { withStyles } from '@material-ui/core';

export class Database extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography style={{ paddingBottom: '1rem' }} variant="h3" noWrap>
          Configuraçōes
        </Typography>

        <section className="contCard">
          <Link to="/banco-de-imagens" style={{ textDecoration: 'none' }}>
            <CountCard
              style={{ width: '100%', cursor: 'pointer' }}
              title="Banco de Imagens"
              icon="peoples"
            />
          </Link>
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    height: '100%',
  },
});

const mapStateToProps = ({ DatabaseReducer }) => ({ ...DatabaseReducer });

const mapDispatchToProps = {};

const DatabaseConnect = connect(mapStateToProps, mapDispatchToProps)(Database);

export default withStyles(styles)(DatabaseConnect);
