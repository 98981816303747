import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 152,
    height: 152,
    fontSize: '50pt',
    padding: theme.spacing(3),
  },
  typography: {
    fontWeight: 300,
    fontSize: '2.125rem',
    color: '#605a5a',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  indicatorTab: {
    height: 2,
    top: 0,
  },
  buttonTab: {
    fontSize: '9pt',
  },
  wrapperTab: {
    flexDirection: 'row',
    alignItems: 'end',
  },
  labelIconTab: {
    minHeight: 50,
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  icon: { fontSize: '14pt', marginRight: '1vh' },
  alert: { marginBottom: 20 },
  iconTable: { textAlign: 'center', width: '38%', color: '#605a5a' },
  color: { color: '#605a5a' },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
