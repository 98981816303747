import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { makeValidate } from 'mui-rff';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { useAds } from '../../../../../../hooks/ads';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import { AMBIENTE } from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import AdCreative from './components/AdCreative';
import AdSet from './components/AdSet';
import Campaign from './components/Campaign';
import Review from './components/Review';
import useStyles from './styles';

const newCampaignScheme = Yup.object().shape({
  campaign: Yup.object().shape({
    objective: Yup.string().required(''),
    name: Yup.string().required('Nome  é obrigatório'),
  }),
});

const adSetScheme = Yup.object().shape({
  adSet: Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    page_id: Yup.string().required('Pagina é obrigatório'),
    daily_budget: Yup.string().required('Orçamento diário e obrigatório'),
    // lifetime_budget: Yup.string(),
    start_time: Yup.date().required(''),
    end_time: Yup.date(),
    targeting: Yup.object().shape({
      // gender: Yup.string().required(),
      age_min: Yup.number().required(),
      age_max: Yup.number().required(),
      geo_locations: Yup.object()
        .shape({
          cities: Yup.array().of(
            Yup.object().shape({
              key: Yup.string('').required(''),
              radius: Yup.string().required(''),
              distance_unit: Yup.string().required(''),
            })
          ),
        })
        .required(''),
    }),
  }),
});

//eslint-disable-next-line
const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const adCreativeScheme = Yup.object().shape({
  adCreative: Yup.object().shape({
    name: Yup.string().required(''),
    message: Yup.string().required(''),
    link: Yup.string().matches(URL, 'Insira um link valido').required(''),
    image: Yup.object().shape({
      hash: Yup.string().required(''),
    }),
  }),
});

const schema = {
  0: newCampaignScheme,
  1: adSetScheme,
  2: adCreativeScheme,
  3: undefined,
};

function getSteps() {
  return [
    'Nova campanha',
    'Novo conjunto de anúncios',
    'Novo anúncio',
    'Revisar',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Campaign />;
    case 1:
      return <AdSet />;
    case 2:
      return <AdCreative />;
    case 3:
      return <Review />;
    default:
      return 'Unknown step';
  }
}

function StartCampaign({ open, onSubmit, refreshAds, onClose }) {
  const classes = useStyles();

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const { user } = useAds();

  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  const [initialValues, setInitialValues] = useState(
    // {
    //   campaign: {
    //     status: 'ACTIVE',
    //     special_ad_categories: [],
    //     objective: 'REACH',
    //     name: 'teste',
    //   },
    //   adSet: {
    //     start_time: '2021-04-26T17:23:55.129Z',
    //     bid_amount: 20,
    //     billing_event: 'IMPRESSIONS',
    //     targeting: {
    //       age_min: 13,
    //       age_max: 65,
    //       geo_locations: {
    //         cities: [
    //           { key: '255192', distance_unit: 'kilometer', radius: '5' },
    //         ],
    //       },
    //     },
    //     status: 'ACTIVE',
    //     page_id: '103245814902741',
    //     daily_budget: '600',
    //     name: 'teste',
    //   },
    //   adCreative: {
    //     image: {
    //       id: '2190352007750439:dbd1690b41fd99eb2b033b60750eadfa',
    //       hash: 'dbd1690b41fd99eb2b033b60750eadfa',
    //       url:
    //         'https://scontent-lga3-1.xx.fbcdn.net/v/t45.1600-4/177575575_23847143117130574_5116698557089510265_n.jpg?_nc_cat=111&ccb=1-3&_nc_sid=2aac32&_nc_ohc=7yBMe3b3OF8AX8mRYOC&_nc_ht=scontent-lga3-1.xx&oh=0191ca1719a63539d807924e2f866971&oe=60AC4344',
    //       height: 1920,
    //       width: 1080,
    //     },
    //     name: 'TESTE',
    //     link: 'https://www.datasales.io/',
    //     message: 'teste',
    //   },
    // }
    {
      campaign: {
        status: 'ACTIVE',
        special_ad_categories: [],
      },
      adSet: {
        start_time: new Date(),
        bid_amount: 20,
        billing_event: 'IMPRESSIONS',
        targeting: {
          age_min: 13,
          age_max: 65,
          geo_locations: {
            cities: [
              { key: undefined, radius: '20', distance_unit: 'kilometer' },
            ],
          },
        },
        status: 'ACTIVE',
      },
      adCreative: {
        image: {},
      },
    }
  );

  const steps = getSteps();

  const handleCreateAds = useCallback(
    async values => {
      try {
        const data = {
          ...values,
          user_id: user.id,
          ad_account_id: user.ad_account_id,
          access_token: user.access_token,
          adSet: {
            ...values.adSet,
            targeting: JSON.stringify(values.adSet.targeting),
            optimization_goal: values.campaign.objective,
          },
          adCreative: {
            name: values.adCreative.name,
            object_story_spec: {
              page_id: values.adSet.page_id,
              link_data: {
                image_hash: values.adCreative.image.hash,
                link: values.adCreative.link,
                message: values.adCreative.message,
              },
            },
          },
        };

        await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
          data,
          'POST',
          undefined,
          undefined,
          false
        );

        refreshAds();

        enqueueSnackbar('Campanha criada com sucesso', { variant: 'success' });
        onClose();
      } catch (err) {
        const message = err.message;

        enqueueSnackbar(message, { variant: 'error' });

        if (message.includes('raio')) {
          confirm({
            title: 'Deseja voltar para edição de publico?',
            description: `O raio geográfico selecionado por você não está dentro dos limites especificados`,
            confirmationText: 'Voltar',
          }).then(() => {
            setActiveStep(1);
          });
        }
      }
    },
    [user, enqueueSnackbar]
  );

  const handleFormSubmit = async values => {
    if (activeStep !== steps.length - 1) {
      handleNext(values);
    } else {
      return handleCreateAds(values);
    }
  };

  const handleNext = values => {
    setInitialValues(values);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <Form
        initialValues={initialValues}
        validate={
          activeStep !== 3 ? makeValidate(schema[activeStep]) : undefined
        }
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle onClose={onClose}></DialogTitle>
            <Stepper activeStep={activeStep}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <DialogContent style={{ maxHeight: '500px' }} dividers>
              {getStepContent(activeStep)}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button onClick={onClose}>Cancelar</Button>
              <div className={classes.grow} />
              {activeStep !== 0 && !values.campaign.id && (
                <Button onClick={handleBack}>voltar</Button>
              )}
              <Button type="submit" disabled={submitting}>
                {submitting ? (
                  <CircularProgress
                    size={theme.typography.subtitle1.fontSize}
                  />
                ) : activeStep !== steps.length - 1 ? (
                  'Avançar'
                ) : (
                  'Salvar'
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
}

StartCampaign.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StartCampaign;
