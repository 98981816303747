const INITIAL_STATE = {
  currentCashback: [],
  expiredCashback: [],
  listPeople: [],
  listPeopleDefault: [],
  listTypePeople: [],
  listMaritalStatus: [],
  listSchooling: [],
  listStatusPeople: [],
  listTypeArrival: [],
  listaProfissao: [],
  personProfile: [],
  listRetailPerson: [],
  savePerson: {
    data_nascimento: new Date(),
  },
  listRetailFamilyPerson: [],
  listRetailConditionPerson: [],
  listRetailGroupPerson: [],
  listCampanhaSMSById: [],
  retencaoPeople: {
    retencao: '...',
    date: '...',
  },
  statusPeople: {
    status: '...',
  },
  dadoGastos: {
    gastosTotal: '...',
    qtdeTotal: 0,
    ultimaData: '...',
  },
  storesId: [],
  isLoadingPeople: false,
  pessoaNota: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_PEOPLE':
      return {
        ...state,
        listPeople: action.payload,
        listPeopleDefault: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_TYPE_PEOPLE':
      return {
        ...state,
        listTypePeople: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_MARITAL_STATUS':
      return {
        ...state,
        listMaritalStatus: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_SCHOOLING':
      return {
        ...state,
        listSchooling: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_STATUS_PEOPLE':
      return {
        ...state,
        listStatusPeople: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_TYPE_ARRIVAL':
      return {
        ...state,
        listTypeArrival: action.payload,
        isLoadingPeople: false,
      };
    case 'GET_PROFISSAO':
      return { ...state, listaProfissao: action.payload };
    case 'RETAIL_PERSON':
      const filter = action.payload.timeline.map(sale => ({
        ...sale,
        desc_categoria_1: sale.desc_categoria_1 || sale.nome_produto,
        categoria_1: sale.categoria_1 || sale.codigo,
      }));
      return {
        ...state,
        listRetailPerson: filter,
        dadoGastos: action.payload.dadoVendas,
      };
    case 'FILTER_PEOPLE':
      const listPeopleDefault = [...state.listPeopleDefault];
      return {
        ...state,
        listPeople: Object.keys(action.payload).includes('warning')
          ? listPeopleDefault
          : action.payload,
        isLoadingPeople: false,
      };

    case 'RETAIL_FAMILY_PERSON':
      return {
        ...state,
        listRetailFamilyPerson: _newGraphPie(action.payload, 'familia'),
      };

    case 'RETAIL_CONDITION_PERSON':
      return {
        ...state,
        listRetailConditionPerson: _newGraphPie(action.payload, 'condicao'),
      };

    case 'RETAIL_GROUP_PERSON':
      return {
        ...state,
        listRetailGroupPerson: _newGraphPie(action.payload, 'grupo'),
      };
    case 'CAMPANHA_SMS':
      return {
        ...state,
        listCampanhaSMSById: action.payload,
      };
    case 'RETENCAO_PERSON':
      return {
        ...state,
        retencaoPeople: action.payload,
      };
    case 'STATUS_PERSON':
      return {
        ...state,
        statusPeople: action.payload,
      };
    case 'PERSON_PROFILE':
      return {
        ...state,
        personProfile: action.payload[0],
        isLoadingPeople: false,
      };
    case 'INPUT_CHANGE':
      return {
        ...state,
        savePerson: {
          ...state.savePerson,
          [action.payload.string]: action.payload.data,
        },
      };
    case 'SET_SAVE_PERSON':
      return {
        ...state,
        savePerson: { ...action.payload },
      };
    case 'EXTRATO_CASHBACK':
      return {
        ...state,
        currentCashback: action.payload.current,
        expiredCashback: action.payload.expired,
        isLoadingPeople: false,
      };
    case 'GET_PESSOA_NOTA':
      return {
        ...state,
        pessoaNota: action.payload,
      };
    case 'POST_PESSOA_NOTA':
      return {
        ...state,
        pessoaNota: action.payload,
      };
    case 'PUT_PESSOA_NOTA':
      return {
        ...state,
        pessoaNota: action.payload,
      };
    case 'DELETE_PESSOA_NOTA':
      return {
        ...state,
        pessoaNota: action.payload,
      };
    case 'CHANGE_STORE':
      return { ...state, storesId: action.payload };
    case 'LOADING_PEOPLE':
      return { ...state, isLoadingPeople: true };
    default:
      return state;
  }
};

const _newGraphPie = (data, type) =>
  data &&
  data.map(dt => ({
    label: dt[type],
    y: dt.porcentagem,
  }));
