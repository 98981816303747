import React, { useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  NotesRounded,
  ExpandMoreRounded,
  ExpandLessRounded,
} from '@material-ui/icons';
import { BG_PRIMARY } from '../../../../../utils/helpers/Constants';
import { formatPrice, urlify } from '../../../../../utils/helpers/Format';
import useStyles from '../styles';
import useDate from '../../../../../hooks/date';

export default function Resume({ data, details }) {
  const classes = useStyles(),
    { getDate } = useDate(),
    [expanded, setExpanded] = useState(true);

  const expandedClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item style={{ marginBottom: '2vh' }}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} variant="rounded">
              <NotesRounded />
            </Avatar>
          }
          action={
            <IconButton
              size="small"
              onClick={expandedClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </IconButton>
          }
          title="Resumo"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={2} style={{ color: BG_PRIMARY }}>
              <Grid item>
                <b>Nome:</b> {data.nome_campanha}
              </Grid>
              <Grid item>
                <b>Data de criação:</b>{' '}
                {data.data_insert &&
                  getDate(
                    'DD/MM/YYYY HH:mm',
                    data.data_insert.replace('Z', '')
                  )}
              </Grid>
              <Grid item>
                <b>Data de agendamento:</b>{' '}
                {data.data_envio &&
                  getDate('DD/MM/YYYY HH:mm', data.data_envio.replace('Z', ''))}
              </Grid>
              <Grid item>
                <b>Término do processo:</b>{' '}
                {data.data_processado &&
                  getDate(
                    'DD/MM/YYYY HH:mm',
                    data.data_processado.replace('Z', '')
                  )}
              </Grid>
              <Grid item>
                <b>Custos:</b>{' '}
                {data.custo_campanha && formatPrice(data.custo_campanha)}
              </Grid>
              {data.redirect_tracker && (
                <Grid item>
                  <b>Redirecionamento:</b>{' '}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: urlify(data.redirect_tracker),
                    }}
                  />
                </Grid>
              )}
              <Grid item container spacing={2} xs={12}>
                <Grid item>
                  <Chip
                    label={<b>#{details.ID_CAMPANHA}</b>}
                    variant="outlined"
                    color="primary"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label={details.tipo_campanha}
                    variant="outlined"
                    color="primary"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
