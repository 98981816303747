import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
  Modal, 
  Panel,
  PieChart,
  Recharts
} from '../../../utils/components';
import {
  CircularProgress,
} from '@material-ui/core';

import { getDetahesProduto } from '../actions/index';

function ModalProdutosAnalitico(props) {
  const [produto, setProduto] = useState({
    detalhesProduto: [],
    vendasHora: [],
    vendasDia: [],
    faixaEtaria: [],
    genero: [],
    vendasLoja: [],
  });

  const {
    vendasDia,
    vendasHora,
    genero,
    faixaEtaria,
    vendasLoja
  } = produto;

  useEffect(() => {
    props.getDetahesProduto({
      idOferta: props.data.id_oferta,
      idProduto: props.data.id_produto,
    });
    return setProduto({
      detalhesProduto: [],
      vendasHora: [],
      vendasDia: [],
      faixaEtaria: [],
      genero: [],
      vendasLoja: [],
    });
  }, []);

  useEffect(() => {
    setProduto(props.produto);
  }, [props.produto]);

  return (
    <Modal 
      onClose={props.onClose} 
      header={`${props.data.produto}`} 
      showModal={props.open}
    >
      {props.isLoadingDetalhesProduto ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <CircularProgress></CircularProgress>
        </div> :
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Panel
            width="100%"
            title="Por loja "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <Recharts
              data={vendasLoja}
              barDataKey="qtde_vendas"
              xDataKey="nome_loja"
              hide
              // xDataKey="nome_loja"
            />
          </Panel>
          <Panel
            width="100%"
            title="Por dia "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <Recharts
              data={vendasDia}
              barDataKey="qtde_vendas"
              xDataKey="data_analise"
            />
          </Panel>
          <Panel
            width="100%"
            title="Por Hora "
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <Recharts
              data={vendasHora}
              barDataKey="qtde_vendas"
              xDataKey="horario_analise"
            />
          </Panel>
          <Panel
            width="49%"
            title=" Genero"
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <PieChart data={genero} />
          </Panel>
          <Panel
            width="49%"
            title=" Faixa etaria"
            //  subheader={detalhesProduto.familia}
            style={{ marginBottom: 20 }}
          >
            <PieChart data={faixaEtaria} />
          </Panel>
        </div>
      }      
    </Modal>
  );
}

const mapStateToProps = ({ OfertasReducer }) => ({
  ...OfertasReducer,
});

const mapDispatchToProps = {
  getDetahesProduto,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProdutosAnalitico);
