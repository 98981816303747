import { Dialog, DialogTitle, Grid } from '@mui/material';
import { useState } from 'react';

const ImgEtiqueta = ({ tag, onChange, onClose }: { tag: any; onChange: any; onClose: any }) => {
  const [mouseImg, setMouseImg] = useState(false);

  return (
    <img
      src={tag.imageURL}
      alt=""
      style={{
        width: '100%',
        cursor: 'pointer',
        ...(!!mouseImg
          ? {
              filter: 'brightness(0.5)',
              backgroundColor: 'white',
              borderRadius: 10
            }
          : {})
      }}
      onClick={() => {
        onChange(tag);
        onClose();
      }}
      onMouseEnter={(e: any) => {
        setMouseImg(true);
      }}
      onMouseLeave={(e: any) => {
        setMouseImg(false);
      }}
    />
  );
};

function TagDialog({ onClose, open, tags, onChange }: any) {
  return (
    // <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
    <div
      style={{
        height: 500,
        overflowY: 'scroll'
      }}
    >
      <div style={{ marginBottom: 24 }}>Selecione uma Etiqueta</div>
      <Grid container spacing={2}>
        {tags.map((tag: any) => (
          <Grid key={tag.id} item xs={3}>
            <ImgEtiqueta tag={tag} onChange={onChange} onClose={onClose} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TagDialog;

