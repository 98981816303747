import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core/';
import { Dashboard } from '@material-ui/icons';
import { getConfigSubmenuPai } from '../action/action';
import { BG_PRIMARY, COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import '../style/comercial.css';

export class Comercial extends Component {
  componentDidMount() {
    this.props.getConfigSubmenuPai();
  }

  render() {
    const { classes } = this.props;

    return this.props.loaderSubmenuFilho ? (
      <section style={{ marginTop: '20vh', textAlign: 'center' }}>
        <CircularProgress color="primary" size={40} />
      </section>
    ) : (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography style={{ paddingBottom: '1rem' }} variant="h3" noWrap>
            Performance
          </Typography>
        </div>

        <section className="contCard">
          {this.props.submenuPai &&
            this.props.submenuPai.map(pai => (
              <Link
                to={{ pathname: `/analise-id/${pai.id_submenu_pai}`, pai }}
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <ListItem
                  button
                  style={{ backgroundColor: '#FFF', borderRadius: '5px' }}
                >
                  <ListItemIcon style={{ color: COLOR_PRIMARY }}>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: BG_PRIMARY }}
                    primary={pai.nome_submenu_pai}
                  />
                </ListItem>
              </Link>
            ))}
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3),
    paddingTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

const mapStateToProps = ({ ComercialReducer }) => ({ ...ComercialReducer });

const mapDispatchToProps = { getConfigSubmenuPai };

const ComercialConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comercial);

export default withStyles(styles)(ComercialConnect);
