import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  withStyles,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { DatePicker, Checkbox } from '../../../utils/components';
import {
  getResgateCampanha,
  getTodasPerformanceMes,
  getTodasPerformancePeriodo,
  setCampanha,
  pauseCampanha,
  continueCampanha
} from '../action/action';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import useDate from '../../../hooks/date';
import AdsStatistics from './Ads';
import Campaign from './Campaign';

function MonitorCampaigns(props) {
  const { classes } = props,
    permissions = JSON.parse(localStorage.getItem('permissions')),
    hasCreateCampaign = permissions.relacionamento
      ? permissions.relacionamento.find(({ serviceName }) => serviceName === 'criar_campanha')
      : false,
    hasCampaignSimplied = permissions.relacionamento
      ? permissions.relacionamento.find(
          ({ serviceName }) => serviceName === 'campanha_simplificado'
        )
      : false,
    location = useLocation(),
    history = useHistory(),
    { subtractDate, getMoment, getDate } = useDate(),
    [searchCampaign, setSearchCampaign] = useState(''),
    [searchInitDate, setSearchInitDate] = useState(subtractDate(1, 'month')),
    [searchEndDate, setSearchEndDate] = useState(getMoment()),
    [searchAutomation, setSearchAutomation] = useState(0),
    [openClickRate, setOpenClickRate] = useState(false),
    [actualTab, setActualTab] = useState(0);

  useEffect(() => {
    let request = {
      filtroDataInicio: getDate('YYYY-MM-DD', searchInitDate),
      filtroDataFim: getDate('YYYY-MM-DD', searchEndDate),
      grupoCampanha: 'CONCLUIDO',
      automacao: searchAutomation
    };
    if (Boolean(location.state)) {
      request = { ...request, id_campanha: location.state.ID_CAMPANHA };
      const initDate = location.state.data_inicio_campanha.replace('Z', ''),
        endDate = location.state.data_fim_campanha.replace('Z', '');
      setSearchCampaign(location.state.nome_campanha);
      setSearchAutomation(location.state.automacao);
      setSearchInitDate(getDate('YYYY-MM-DD', initDate));
      setSearchEndDate(getDate('YYYY-MM-DD', endDate));
    }
    props.getTodasPerformanceMes(request);
    props.getResgateCampanha(request);
    history.replace();
  }, []);

  function onChangePeriodo() {
    const request = {
      filtroDataInicio: getDate('YYYY-MM-DD', searchInitDate),
      filtroDataFim: getDate('YYYY-MM-DD', searchEndDate),
      grupoCampanha: 'CONCLUIDO',
      automacao: searchAutomation
    };

    props.getTodasPerformanceMes(request);
    if (!Boolean(searchAutomation)) {
      props.getResgateCampanha(request);
    }
  }

  function onKeyPressed(e) {
    if (e.keyCode === 13 && searchCampaign.length > 0) {
      const request = {
        filtroDataInicio: getDate('YYYY-MM-DD', searchInitDate),
        filtroDataFim: getDate('YYYY-MM-DD', searchEndDate),
        automacao: searchAutomation,
        nome_campanha: searchCampaign
      };
      props.getTodasPerformanceMes(request);
    } else if (e.keyCode === 13 && !searchCampaign.length > 0) {
      onChangePeriodo();
    }
  }

  function onSearchByName() {
    if (searchCampaign.length > 0) {
      const request = {
        filtroDataInicio: getDate('YYYY-MM-DD', searchInitDate),
        filtroDataFim: getDate('YYYY-MM-DD', searchEndDate),
        automacao: searchAutomation,
        nome_campanha: searchCampaign
      };
      props.getTodasPerformanceMes(request);
    } else {
      onChangePeriodo();
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.actions}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Monitor de Campanhas
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          size="lg"
          placeholder="Pesquisar campanha..."
          autoComplete="false"
          onChange={({ target: { value } }) => setSearchCampaign(value)}
          value={searchCampaign}
          onKeyDown={onKeyPressed}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => onSearchByName()}>
                  <Search />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>

      <Box className={classes.actions}>
        <Box
          display="flex"
          marginTop="20px"
          marginBottom="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" width="70%" marginRight="20px">
            <Typography className={classes.label} variant="h6" noWrap>
              Filtrar por período
            </Typography>
            <Box display="flex" flexDirection="row">
              <DatePicker
                label="De"
                style={{ marginRight: 15 }}
                withKeyboard
                className={classes.dataPicker}
                value={searchInitDate}
                format="DD/MM/YYYY"
                disableFuture
                onChange={date => {
                  setSearchInitDate(date);
                  onChangePeriodo();
                }}
              />

              <DatePicker
                label="Até"
                style={{ marginRight: 15 }}
                withKeyboard
                className={classes.dataPicker}
                value={searchEndDate}
                format="DD/MM/YYYY"
                onChange={date => {
                  setSearchEndDate(date);
                  onChangePeriodo();
                }}
              />
              <Checkbox
                value={Boolean(searchAutomation)}
                label="Automações"
                onChange={({ target: { checked } }) => {
                  setSearchAutomation(Boolean(checked) ? 1 : 0);
                  onChangePeriodo();
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ cursor: !hasCreateCampaign ? 'not-allowed' : 'auto' }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            component={Link}
            disabled={!hasCreateCampaign}
            to={`/criar-campanha${hasCampaignSimplied ? '/simplificado' : ''}`}
            style={{
              backgroundColor: !hasCreateCampaign ? null : COLOR_PRIMARY,
              marginRight: 15
            }}
          >
            Criar Campanha
          </Button>
        </Box>
      </Box>

      <Tabs
        value={actualTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, newValue) => setActualTab(newValue)}
        aria-label="tabs"
        style={{
          marginBottom: 20
        }}
      >
        <Tab label="Campanhas Diretas" />
        <Tab label="Campanhas Impulsionadas" />
      </Tabs>
      {actualTab === 0 && (
        <Campaign
          campaigns={props.campanhas.campanhas}
          loading={props.loadingPerformance}
          loadingDetalhes={props.loadingDetalhes}
          pauseCampanha={props.pauseCampanha}
          continueCampanha={props.continueCampanha}
          searchCampaign={searchCampaign}
          searchInitDate={searchInitDate}
          searchEndDate={searchEndDate}
          searchAutomation={searchAutomation}
        />
      )}
      {actualTab === 1 && <AdsStatistics />}
    </Box>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2) },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  margin: {
    marginRight: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#FFF'
  }
});

const mapStateToProps = ({ PerformanceReducer }) => ({
  ...PerformanceReducer
});

const mapDispatchToProps = {
  getTodasPerformanceMes,
  getTodasPerformancePeriodo,
  getResgateCampanha,
  setCampanha,
  pauseCampanha,
  continueCampanha
};

const MonitorCampaignsReducers = connect(mapStateToProps, mapDispatchToProps)(MonitorCampaigns);

export default withStyles(styles)(MonitorCampaignsReducers);

