import { LinearProgress, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { useState } from 'react';

import { TabPanelV2 } from './components/TabPanelV2';

const CustomTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  '& .MuiTab-root.Mui-selected': { color: theme.palette.info.main },
  '& .MuiTabs-indicator': { backgroundColor: theme.palette.info.main }
}));

type Tab = {
  label: string;
  index: number;
  handleClick?: () => Promise<void>;
  children: React.ReactNode;
};

interface ITimelineProps {
  tabs: Tab[];
  loadingBackgrounds?: boolean;
  defaultValue?: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const TimelineV2 = ({ defaultValue = 0, tabs, loadingBackgrounds }: ITimelineProps) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', p: '24px 0px 32px 0px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map(tab => (
            <Tab
              label={tab.label}
              onClick={tab.handleClick}
              sx={{
                minWidth: `${tab.label.length}px`,
                p: '8px 0px',
                marginRight: 4
              }}
              {...a11yProps(value)}
            />
          ))}
        </CustomTabs>
      </Box>

      {loadingBackgrounds && (
        <Stack spacing={2} justifyContent="center" alignItems="center" width="100%" height="62vh">
          <Typography variant="subtitle1">Carregando...</Typography>
          <LinearProgress variant="indeterminate" sx={{ width: '50%' }} />
        </Stack>
      )}

      {tabs.map(tab => (
        <Box marginTop={4}>
          <TabPanelV2 index={tab.index} value={value}>
            {tab.children}
          </TabPanelV2>
        </Box>
      ))}
    </Box>
  );
};

