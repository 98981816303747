import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { faThumbsUp, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  saveConfigClube,
  getConfigClube,
  putStatusConfigClube,
  updateConfigClube,
  saveRecomendacaoClube,
  getRecomendacoes,
  deleteRecomendacoes,
} from '../action/action';
import { Container } from '../../../utils/components';
import ModalConfigClube from './ModalConfigClube';
import ModalAddRecomendacoes from './ModalAddRecomendacoes';
import TagConfigClube from './Tabs/TabConfigClube';
import TabRecomendacoes from './Tabs/TabRecomendacoes';
import '../style/ConfigClubeStyle.css';
export class ConfigClube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalRecomendacoes: false,
      edit: false,
    };
  }

  componentDidMount() {
    this.props.getConfigClube();
    this.props.getRecomendacoes();
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onSend() {
    delete this.state.showModal;
    if (this.state.edit) {
      delete this.state.edit;
      this.props.updateConfigClube({
        ...this.props.configuracoesClube,
        ...this.state,
      });
    } else {
      this.props.saveConfigClube({ ...this.state });
    }
    this.setState({ showModal: false, edit: false });
  }

  onSaveRecomendacao() {
    const saveRecomendacao = {
      descricaoRecomendacao: this.state.descricaoRecomendacao,
      imagensRecomendacao: this.state.imagensRecomendacao,
      tituloRecomendacao: this.state.tituloRecomendacao,
      dataInicioRecomendacao: this.state.dataInicioRecomendacao,
      dataFimRecomendacao: this.state.dataFimRecomendacao,
    };
    this.props.saveRecomendacaoClube(saveRecomendacao);
    this.setState({ showModalRecomendacoes: false });
  }

  render() {
    const { showModal, showModalRecomendacoes } = this.state;

    return (
      <section style={{ marginLeft: '1vw' }}>
        <Tabs
          animation={false}
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="home"
            label={
              <span>
                <FontAwesomeIcon icon={faCogs} /> Configuração
              </span>
            }
          >
            <Container
              loading={this.props.isLoadingClube}
              style={{ marginLeft: 0, border: 'none', borderRadius: 0 }}
            >
              <TagConfigClube
                {...this.props}
                onChange={(state, value) => this.onChange(state, value)}
              />
            </Container>
          </Tab>
          <Tab
            eventKey="profile"
            label={
              <span>
                <FontAwesomeIcon icon={faThumbsUp} /> Recomendação
              </span>
            }
          >
            <Container
              style={{ marginLeft: 0, border: 'none', borderRadius: 0 }}
            >
              <TabRecomendacoes
                {...this.props}
                onChange={(state, value) => this.onChange(state, value)}
              />
            </Container>
          </Tab>
        </Tabs>

        <ModalConfigClube
          {...this.props.configuracoesClube}
          configuracoes={this.state}
          showModal={showModal}
          onClose={() =>
            this.setState({
              showModal: false,
              edit: false,
              tituloRecomendacao: null,
              descricaoRecomendacao: null,
            })
          }
          onChange={(state, value) => this.onChange(state, value)}
          onSend={() => this.onSend()}
        />
        <ModalAddRecomendacoes
          recomendacoes={this.state}
          onClose={() => this.setState({ showModalRecomendacoes: false })}
          onChange={(state, value) => this.onChange(state, value)}
          onSend={() => this.onSaveRecomendacao()}
          showModal={showModalRecomendacoes}
        />
      </section>
    );
  }
}

const mapStateToProps = ({ ConfigClubeReducer }) => ({ ...ConfigClubeReducer });

const mapDispatchToProps = {
  saveConfigClube,
  getConfigClube,
  putStatusConfigClube,
  updateConfigClube,
  saveRecomendacaoClube,
  getRecomendacoes,
  deleteRecomendacoes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigClube);
