import Brightness1Icon from '@mui/icons-material/Brightness1';
import React from 'react';

export type LabelDotProps = {
  labelNumber: number;
  numberColor?: string;
  dot?: boolean;
};

export function LabelDot({ labelNumber, numberColor, dot, ...props }: LabelDotProps) {
  const isTheFirstLabel = labelNumber <= 1;
  return (
    <div
      {...props}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {dot && !isTheFirstLabel && (
        <span style={{ fontWeight: 'bold', paddingRight: '16px' }}>{`—`}</span>
      )}
      <Brightness1Icon />
      <span
        style={{
          position: 'absolute',
          left: dot && !isTheFirstLabel ? '41px' : '9px',
          color: numberColor ? numberColor : 'white',
          fontSize: '0.85rem'
        }}
      >
        {labelNumber}
      </span>
    </div>
  );
}
