import { Divider, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Modal, MyButton, Select, TextField } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { toBase64 } from '../../../utils/helpers/Functions';

function ModalEditaImagem(props) {
  const [valueTab, setValueTab] = useState(0);
  const [produto, setProduto] = useState({
    ...props.row,
    base64: props.row.imagem_produto,
    name: props.row.nome_produto
  });

  function footer() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <MyButton size="large" color="primary" variant="text" onClick={props.onClose}>
          Fechar
        </MyButton>

        <MyButton onClick={() => props.onSave(produto)} size="large" color="primary" variant="text">
          Salvar
        </MyButton>
      </div>
    );
  }

  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={props.onClose}
      header={`Atualizar imagem ${produto.nome_produto}`}
      footer={footer()}
    >
      <Divider />
      <section style={{ padding: '2rem 10rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Imagem do produto
        </Typography>
        <Tabs
          value={valueTab}
          onChange={(e, value) => setValueTab(value)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Pesquisar imagem" />
          <Tab label="Link da imagem" />
          <Tab label="Upload" />
        </Tabs>
        {_renderContentTab(valueTab, props, setProduto, produto)}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            alt={produto.name}
            crossOrigin="anonymous"
            src={produto.base64}
            style={{ width: 300, height: 300 }}
          />
        </div>
      </section>
    </Modal>
  );
}

function _renderContentTab(tab, props, setProduto, produto) {
  switch (tab) {
    case 0:
      return (
        <Select
          label="Pesquisar imagem"
          placeholder="EAN..."
          options={props.imagemProduto}
          labelKey="EAN"
          valueKey="LINK_S3"
          onChange={value =>
            setProduto({
              ...produto,
              base64: value ? value.LINK_S3 : null,
              name: value ? value.EAN : null
            })
          }
        />
      );
    case 1:
      return (
        <TextField
          label="Link da imagem"
          className="form-control"
          autoComplete="none"
          onChange={async ({ target: { value } }) => {
            if (value.length > 0) {
              const imagem = await toBase64(value);
              setProduto({
                ...produto,
                ...imagem
              });
            }
          }}
        />
      );

    case 2:
      return (
        <section style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={async e => {
              if (typeof e.target.files[0] !== 'undefined') {
                const imagem = await toBase64(e.target.files[0]);
                setProduto({
                  ...produto,
                  ...imagem
                });
              }
            }}
          />
          <label htmlFor="contained-button-file">
            <MyButton size="large" component="span" color="primary" variant="text">
              Upload da imagem
            </MyButton>
          </label>
        </section>
      );
    default:
      return <div />;
  }
}

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditaImagem);
