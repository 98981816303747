import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

import { TimelineV2 } from '../../utils/components/TimelineV2';
import Company from './components/Company';
import Notifications from './components/Notifications';
import Security from './components/Security';
import Stores from './components/Store';
import Users from './components/Users';

function AccountSettings() {
  const location = useLocation();
  const tabs = [
    {
      label: 'Empresa',
      index: 0,
      children: <Company />
    },
    {
      label: 'Usuarios',
      index: 1,
      children: <Users />
    },
    {
      label: 'Lojas',
      index: 2,
      children: <Stores />
    },
    {
      label: 'Segurança',
      index: 3,
      children: <Security />
    },
    {
      label: 'Notificações',
      index: 4,
      children: <Notifications />
    }
  ];

  //console.log();

  return (
      <Grid container direction="column" 
        style={{ 
          width: '100%',
          maxWidth: '100%',
        }}>
        <Grid item style={{ paddingBottom: '0px' }}>
          <Box pt={2} pl={3} style={{ paddingBottom: '0px' }}>
            <Typography
              variant="h4"
              noWrap
              style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '32px'
              }}
            >
              Configurações da conta
            </Typography>
            <Typography
              variant="p"
              noWrap
              style={{
                fontSize: '14px',
                color: '#616161',
                lineHeight: '22px',
                fontWeight: '500'
              }}
            >
              Configure a sua empresa, usuários e lojas.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          style={{
            paddingTop: '0px',
            paddingRight: '3%'
          }}
        >
          <Box pl={3}>
            <TimelineV2 tabs={tabs} defaultValue={location.state?.index} />
          </Box>
        </Grid>
      </Grid>
  );
}

export default AccountSettings;

