import React, { Component } from 'react';
import Badge from '../Badge/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faArrowRight,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Sidebar.css';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmenuPai: false,
      showSubmenuFilho: false,
      new_rotas: props.rotas,
    };
  }

  openToggleMenu(index) {
    this.setState({
      [`showSubmenuPai${index}`]: !this.state[`showSubmenuPai${index}`],
    });
  }

  openToggleSubmenu(data, index, subindex) {
    this.setState(
      {
        [`showSubmenuFilho${index}_${subindex}`]:
          !this.state[`showSubmenuFilho${index}_${subindex}`],
        id_submenu_pai: data.id_submenu_pai,
      },
      () => this.props.onSelectSubmenu(data, subindex)
    );
  }

  render() {
    const { loaderSubmenuFilho } = this.props;
    const { new_rotas } = this.state;
    return (
      <div style={{ width: '15vw' }} className="div-container-sidebar">
        {new_rotas
          .filter(rota => !rota.hidden)
          .map((rota, index) => (
            <ul key={index} className="ul-sidebar">
              <li
                className="linha-sidebar"
                onClick={() => this.openToggleMenu(index)}
              >
                <span className="span-sidebar">
                  <i>
                    <FontAwesomeIcon icon={rota.icon} />
                  </i>

                  {rota.path ? (
                    <Link to={rota.path} className="link">
                      {rota.name}
                    </Link>
                  ) : (
                    <span>{rota.name}</span>
                  )}

                  {rota.beta && (
                    <Badge backgroundColor="#e85764" style={{ width: '3vw' }}>
                      Beta
                    </Badge>
                  )}
                </span>
              </li>
              {this.state[`showSubmenuPai${index}`] &&
                rota.subRoutes &&
                rota.subRoutes.map((subrota, subindex) => (
                  <ul key={subindex} className="ul-sidebar-nivel2">
                    <li
                      className="linha-sidebar-sub"
                      onClick={() =>
                        this.openToggleSubmenu(subrota, index, subindex)
                      }
                    >
                      <span>
                        <i>
                          <FontAwesomeIcon
                            icon={
                              this.state[
                                `showSubmenuFilho${index}_${subindex}`
                              ] && subrota.subFilho
                                ? faArrowDown
                                : faArrowRight
                            }
                          />
                        </i>
                        &nbsp;
                        {subrota.path ? (
                          <Link to={subrota.path} className="link">
                            {subrota.name}
                          </Link>
                        ) : (
                          <span style={{ marginRight: '15px' }}>
                            {subrota.name}
                          </span>
                        )}
                        {loaderSubmenuFilho && (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        )}
                      </span>
                    </li>
                    {this.state[`showSubmenuFilho${index}_${subindex}`] &&
                      subrota.subFilho &&
                      subrota.subFilho.map((filho, key) => (
                        <ul key={key} className="ul-sidebar-nivel3">
                          <li className="linha-sidebar-sub">
                            <span>
                              <i>
                                <FontAwesomeIcon icon={faArrowRight} />
                              </i>
                              &nbsp;
                              <Link
                                to={`/dashboard/${filho.id_dashboard}`}
                                className="link"
                              >
                                {filho.name}
                              </Link>
                            </span>
                          </li>
                        </ul>
                      ))}
                  </ul>
                ))}
            </ul>
          ))}
      </div>
    );
  }
}
