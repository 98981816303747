import React, { Fragment } from 'react';

//Using Components
import { Typography, Divider, Box } from '@material-ui/core';

//Using Variables
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

// Using Styles
import useStyles from '../styles';

export default function(props) {
  const form = props.form,
    classes = useStyles();
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Review
      </Typography>
      <Box className={classes.containerColsImport}>
        <Box>
          <strong>Tipo do formulário: &nbsp;</strong>
          <span>{Boolean(form.typeForm) && form.typeForm.label}</span>
        </Box>
        <Box>
          <strong>Nome do formulário: &nbsp;</strong>
          <span>{form.nameForm}</span>
        </Box>
        <Box>
          <strong>Cor da fonte: &nbsp;</strong>
          <span>{form.fontColorForm}</span>
        </Box>
        <Box>
          <strong>Cor do fundo: &nbsp;</strong>
          <span>{form.bgColorForm}</span>
        </Box>
        <Box>
          <strong>Campos do formulário: &nbsp;</strong>
          {Boolean(form.fields) &&
            form.fields.map((field, index) => (
              <Box key={index} marginLeft="2rem" paddingBottom=".5rem">
                <Box>
                  <strong>Campo:&nbsp;</strong>
                  <span>{field.label}</span>
                </Box>
                <Box>
                  <strong>Entrada:&nbsp;</strong>
                  <span>{field.inputType.label}</span>
                </Box>

                {field.inputType.value === 'select' &&
                  Boolean(field.optionSelect.length) &&
                  field.optionSelect.map(option => (
                    <Fragment>
                      <span style={{ marginLeft: '4rem' }}>- {option}</span>
                      <br />
                    </Fragment>
                  ))}

                {Boolean(field.key) && (
                  <Box>
                    <strong>Chave:&nbsp;</strong>
                    <span>{field.key.label}</span>
                  </Box>
                )}
                <Box>
                  <strong>Ativo:&nbsp;</strong>
                  <span>{Boolean(field.active) ? 'Sim' : 'Não'}</span>
                </Box>
                <Box>
                  <strong>Obrigatório:&nbsp;</strong>
                  <span>{Boolean(field.required) ? 'Sim' : 'Não'}</span>
                </Box>
                <Divider />
              </Box>
            ))}
        </Box>
      </Box>
    </Fragment>
  );
}
