import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'react-final-form';

function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format={currencyFormatter}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="R$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs({ name, ...rest }) {
  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <TextField
          {...input}
          value={value}
          onChange={e => onChange(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          {...rest}
        />
      )}
    </Field>
  );
}
