import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  content: {padding:theme.spacing(4)},
  popper: {
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default useStyles;
