import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import WifiTetheringOutlinedIcon from '@material-ui/icons/WifiTetheringOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAds } from '../../../../../../hooks/ads';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import api from '../../../../../../utils/helpers/Fetch';
import { formatPrice } from '../../../../../../utils/helpers/Format';
import { AMBIENTE } from '../../../../../../utils/helpers/Constants';

const adFormats = [
  'AUDIENCE_NETWORK_INSTREAM_VIDEO',
  'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE',
  'AUDIENCE_NETWORK_OUTSTREAM_VIDEO',
  'AUDIENCE_NETWORK_REWARDED_VIDEO',
  'DESKTOP_FEED_STANDARD',
  'FACEBOOK_STORY_MOBILE',
  'FACEBOOK_STORY_STICKER_MOBILE',
  'INSTAGRAM_EXPLORE_CONTEXTUAL',
  'INSTAGRAM_EXPLORE_IMMERSIVE',
  'INSTAGRAM_REELS',
  'INSTAGRAM_STANDARD',
  'INSTAGRAM_STORY',
  'INSTANT_ARTICLE_RECIRCULATION_AD',
  'INSTANT_ARTICLE_STANDARD',
  'INSTREAM_BANNER_DESKTOP',
  'INSTREAM_BANNER_MOBILE',
  'INSTREAM_VIDEO_DESKTOP',
  'INSTREAM_VIDEO_IMAGE',
  'INSTREAM_VIDEO_MOBILE',
  'JOB_BROWSER_DESKTOP',
  'JOB_BROWSER_MOBILE',
  'MARKETPLACE_MOBILE',
  'MESSENGER_MOBILE_INBOX_MEDIA',
  'MESSENGER_MOBILE_STORY_MEDIA',
  'MOBILE_BANNER',
  'MOBILE_FEED_BASIC',
  'MOBILE_FEED_STANDARD',
  'MOBILE_FULLWIDTH',
  'MOBILE_INTERSTITIAL',
  'MOBILE_MEDIUM_RECTANGLE',
  'MOBILE_NATIVE',
  'RIGHT_COLUMN_STANDARD',
  'SUGGESTED_VIDEO_DESKTOP',
  'SUGGESTED_VIDEO_MOBILE',
  'WATCH_FEED_HOME',
  'WATCH_FEED_MOBILE',
];

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 600,
  },
  previewCardContent: {
    minHeight: 600,
  },
}));

function AdsDetails({ open, adsId, onClose }) {
  const classes = useStyles();

  const { user } = useAds();

  const [insights, setInsights] = useState();
  const [adPreview, setAdPreview] = useState();
  const [insightsIsLoading, setInsightsIsLoading] = useState(false);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);

  const findInsights = async () => {
    try {
      setInsightsIsLoading(true);
      const response = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/ads/insights/${adsId}`,
        { access_token: user.access_token, ad_account_id: user.ad_account_id }
      );

      const { content } = response;

      setInsights(content[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setInsightsIsLoading(false);
    }
  };

  const findAdPreview = useCallback(
    async (ad_format = 'MOBILE_FEED_STANDARD') => {
      try {
        setPreviewIsLoading(true);
        const response = await api(
          ' https://graph.facebook.com/v10.0/23846690871630574/previews',
          { access_token: user.access_token, ad_format }
        );

        const { data } = response;

        setAdPreview(data[0] || []);
      } catch (err) {
        console.log(err);
      } finally {
        setPreviewIsLoading(false);
      }
    },
    [user]
  );

  const handlePreviewChange = (_, value) => {
    findAdPreview(value);
  };

  useEffect(() => {
    findInsights();
    findAdPreview();
  }, []);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Detalhes</DialogTitle>
      <DialogContent
        className={clsx({
          [classes.loadingContainer]:
            insightsIsLoading || previewIsLoading || !insights,
        })}
        dividers
      >
        {insightsIsLoading || previewIsLoading ? (
          <CircularProgress color="primary" />
        ) : !insights ? (
          <Typography variant="h6">Nenhum registro para exibir</Typography>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar}>
                      <BarChartIcon />
                    </Avatar>
                  }
                  title="Desempenho"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar className={classes.avatar}>
                              <PeopleAltOutlinedIcon />
                            </Avatar>
                          }
                          title={insights.reach}
                          subheader="Pessoas alcançadas"
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar className={classes.avatar}>
                              <AttachMoneyIcon />
                            </Avatar>
                          }
                          title={formatPrice(insights.spend)}
                          subheader="Valor gasto"
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar className={classes.avatar}>
                              <WifiTetheringOutlinedIcon />
                            </Avatar>
                          }
                          title={insights.impressions}
                          subheader="Impressões"
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar className={classes.avatar}>
                              <TouchAppIcon />
                            </Avatar>
                          }
                          title={insights.clicks}
                          subheader="Clicks"
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {adPreview && adPreview.body && (
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar className={classes.avatar}>
                        <ImageOutlinedIcon />
                      </Avatar>
                    }
                    title="Prévia do anúncio"
                  />
                  <Divider />

                  <CardContent className={classes.previewCardContent}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Autocomplete
                          options={adFormats}
                          defaultValue="MOBILE_FEED_STANDARD"
                          getOptionLabel={option => option}
                          getOptionValue={option => option}
                          onChange={handlePreviewChange}
                          renderInput={params => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item container justify="center" xs={12}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: adPreview.body,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

AdsDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdsDetails;
