import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tab: {
    textTransform: 'none',
  },
  content: {
    minHeight: 500,
    padding: theme.spacing(4),
  },
  loadingContainer: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
