import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import React from 'react';
import { Field } from 'react-final-form';

const FileField = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => {
        return (
          <CurrencyTextField
            {...input}
            {...props}
            variant="outlined"
            value={value}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="number"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={onChange}
          />
        );
      }}
    </Field>
  );
};

export default FileField;
