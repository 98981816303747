const INITIAL_STATE = {
  configuracoesClube: {
    cor: "",
    dominio: "",
    logo: "",
    nomeClube: "",
    regulamento: ""
  },
  listRecomendacoes: [],
  isLoadingRecomendacao: false,
  isLoadingClube: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CONFIG_CLUBE":
      return {
        ...state,
        configuracoesClube: action.payload,
        isLoadingClube: false
      };
    case "LIST_RECOMENDACOES":
      return {
        ...state,
        listRecomendacoes: action.payload,
        isLoadingRecomendacao: false
      };
    case "LOADING_CLUBE":
      return { ...state, isLoadingClube: true };
    case "LOADING_RECOMENDACAO":
      return { ...state, isLoadingRecomendacao: true };
    default:
      return state;
  }
};
