import { Chip, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import { Format, Query } from '../..';

const AutocompleteStylized = styled(Autocomplete)(({ theme }) => ({
  '& .MuiTextField-root': {
    padding: 0,
    borderColor: 'none',
    color: '#B0B0B0',
    width: 250,
    margin: '0 0 24px 0'
  },

  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    margin: '5px 0',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.05em'
  },

  '& .MuiButtonBase-root, .MuiChip-root': {
    maxHeight: '25px'
  },

  '& .MuiChip-deleteIcon': {
    maxHeight: '20px'
  },

  '& .Mui-focused': {
    borderColor: 'none',
    background: '#FFF',
    marginBottom: '24px',
    zIndex: 4,
    maxHeight: 'auto'
  },

  '& .MuiFormLabel-root.Mui-focused': {
    zIndex: 5
  }
}));

const TextFieldStylized = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root': {
    padding: 0,
    borderColor: 'none',
    color: '#B0B0B0',
    minWidth: 250,
    margin: '0 0 24px 0'
  },

  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    margin: '5px 0',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.05em',
    minWidth: 250
  },

  '& .Mui-focused': {
    borderColor: 'none',
    background: '#FFF',
    marginBottom: '24px',
    zIndex: 4,
    maxHeight: 'auto'
  },

  '& .MuiFormLabel-root.Mui-focused': {
    zIndex: 5
  }
}));

interface FilterModelsProps {
  query?: Query;
  tags: Array<string> | null;
  formats: Array<Format>;
  currentTab: Number;
  modeloPronto: boolean;
  handleQueryChange: (state: string, value: Array<any> | number | string) => void;
  handleTagChange: (state: string, value: Array<any> | number | string) => void;
}

export default function FilterModels({
  query,
  tags,
  formats,
  currentTab,
  modeloPronto,
  handleQueryChange,
  handleTagChange
}: FilterModelsProps): JSX.Element {
  const [searchTerm, setSearchTerm] = useState(query?.productQuantity);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let delayDebounceFn: any;
    if (count !== 0) {
      delayDebounceFn = setTimeout(() => {
        handleQueryChange('productQuantity', searchTerm!);
      }, 2000);
    }

    setCount(prev => prev + 1);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <>
      {modeloPronto ? (
        <AutocompleteStylized
          data-testid="tabloid-v3-filter-my-models"
          multiple
          filterSelectedOptions={true}
          limitTags={1}
          options={formats}
          getOptionLabel={option => option.name}
          onChange={(e, value) => {
            console.log('formats', formats);

            handleQueryChange('formats', value);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Formatos"
              size={'small'}
              color="info"
              variant="outlined"
              placeholder="Selecione os formatos"
              InputProps={{
                ...params.InputProps,
                classes: {},
                endAdornment: <>{params.InputProps.endAdornment}</>
              }}
            />
          )}
        />
      ) : (
        <>
          <AutocompleteStylized
            value={
              // currentTab === 0 ? ['recomendado'] : currentTab === 1 ? query?.tags :
              query?.tags
            }
            options={
              // currentTab !== 0 ? tags.filter(tag => tag !== 'recomendado') :
              tags
            }
            getOptionValue={option => option}
            getOptionLabel={option => option}
            filterSelectedOptions={true}
            limitTags={1}
            fullWidth
            multiple
            freeSolo
            // disabled={tags.length <= 0 || currentTab === 0 ? true : false} //|| modelo_pronto
            onChange={(e, values: any) => {
              if (currentTab === 1) {
                if (values.indexOf('Meu Modelo') === -1) {
                  values.push('Meu Modelo');
                }
              }

              handleTagChange('tags', values);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  disabled={
                    // currentTab === 0
                    //   ? option === 'recomendado'
                    //   :
                    currentTab === 1 ? option === 'Meu Modelo' : false
                  }
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                size={'small'}
                color="info"
                variant={'outlined'}
                label="Tags"
                placeholder="Selecione uma ou mais tags"
                InputProps={{
                  ...params.InputProps,
                  classes: {},
                  endAdornment: <>{params.InputProps.endAdornment}</>
                }}
              />
            )}
            // sx={{
            //   '& :hover': {
            //     cursor: currentTab === 0 ? 'not-allowed' : 'auto'
            //   },

            //   '& .MuiChip :hover': {
            //     cursor: currentTab === 0 ? 'not-allowed' : 'auto'
            //   }
            // }}
          />

          <TextFieldStylized
            fullWidth
            size="small"
            color="info"
            type="number"
            variant="outlined"
            label="Quantidade de produtos"
            placeholder="Selecione a quantidade de produtos"
            value={searchTerm}
            onChange={e => {
              let value = e.target.value;
              if (value === '0') {
                value = '';
              } else {
                Number(value);
              }

              setSearchTerm(value);
            }}
          />
        </>
      )}
    </>
  );
}

