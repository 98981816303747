import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { DistributionTypeProps } from 'hooks/api/distribution/useAdsDistribution';
import React from 'react';

import { API_SMS_V2 } from '../../../../../../../utils/helpers/Constants';
import api from '../../../../../../../utils/helpers/Fetch';
import { useStyles } from '../../../styles';
import ButtonChannelMessage from './ButtonChannelMessage';
import SMSWithoutCreditsMessage from './SMSWithoutCreditsMessage';

type ChannelsProps = {
  icon: string;
};

type Props = {
  name: DistributionTypeProps;
  title: string;
  disabled?: boolean;
  selected?: boolean;
  onSelected?: (isSelected: boolean) => void;
  channels: Array<ChannelsProps>;
};

const ButtonChannel: React.FC<Props> = ({
  name,
  title,
  channels,
  onSelected = () => {},
  disabled = false,
  selected = false
}) => {
  const classes = useStyles();

  const [credits, setCredits] = React.useState<number>(0);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);

  /**
   * @description returns true if the credits are greater than 0, otherwise returns false
   */
  const hasZeroCredits = credits === 0;

  const getCreditsBalance = async () => {
    try {
      let result = await api(
        `${API_SMS_V2}getLastLimitHistory`,
        {},
        'GET',
        undefined,
        undefined,
        false
      );
      const resultCredits = result.content.lastLimitHistory.balance / 1000 || 0;

      setCredits(resultCredits);
    } catch (err) {
      console.log(err);
    }
  };

  function handleSelect() {
    if (hasZeroCredits) setShowMessage(true);
    if (!hasZeroCredits && name === 'sms') onSelected(!selected);
    if (name !== 'sms') onSelected(!selected);
  }

  React.useEffect(() => {
    if (name === 'sms') getCreditsBalance();
  }, []);

  return (
    <>
      <Box
        className={[classes.boxContentBtn + (selected && !disabled ? ` ${classes.boxContentBtnSelected}` : ''),
          hasZeroCredits && !selected && name === 'sms' ? classes.boxContentBtnWithoutCredits : ''
        ].join(' ')}
        onClick={() => { !disabled ? handleSelect() : console.log('Em breve')}}
        data-testid="adsDistribution-WhereToDistribute-ButtonChannel-component"
      >
        <Box width="100%" style={{ position: 'absolute', top: '3px', left: '3px' }}>
            <Checkbox
              checked={selected}
              className={selected && !disabled ? classes.checkboxSelected : classes.checkbox}
              disabled={disabled}
              aria-label="Global Card Checkbox"
              //onChange={(_, checked) => onSelected(checked)} Disabled because of card selection
              data-testid="dsMarketing-TabloideDigitalV2-AdsDistribution-WhereToDistribute-Checkbox"
            />
        </Box>
        {channels.length > 1 ? (
          <>
            {' '}
            <Box className={classes.boxContentIcon2}>
              {channels.slice(0, 2).map((channel, index) => (
                <img
                  key={index.toString()}
                  className={disabled ? classes.iconDisabled : classes.icon}
                  src={channel.icon}
                  alt={`icon-${index}`}
                  data-testid="ButtonChannel-channel-icon"
                />
              ))}
            </Box>
            {/*<Box className={classes.boxContentIcon3}>
              {channels.slice(2).map((channel, index) => (
                <img
                  key={index.toString()}
                  className={classes.iconDisabled}
                  src={channel.icon}
                  alt={`icon-2`}
                  data-testid="ButtonChannel-channel-icon"
                />
              ))}
              </Box>*/}
          </>
        ) : (
          <Box className={classes.boxContentIcon}>
            <img
              className={disabled ? classes.iconDisabled : classes.icon}
              src={channels[0].icon}
              alt="facebook-icon"
              data-testid="ButtonChannel-channel-icon"
            />
          </Box>
        )}
        <Box className={classes.boxContentText}>
          <Typography
            style={{ opacity: !disabled ? 1 : 0.5 }}
            className={classes.typographyBtn}
            data-testid="title-component"
          >
            {title}
          </Typography>
          <ButtonChannelMessage name={name} />
          <br />
          {name == 'sms' ? (
            <Typography className={classes.creditsCounterBox} component="span">
              Seu saldo de créditos: {credits}
            </Typography>
          ) : null}
        </Box>
      </Box>
      {showMessage && name === 'sms' ? <SMSWithoutCreditsMessage /> : null}
    </>
  );
};

export default ButtonChannel;

