import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import DialogTitle from '../../../../utils/components/DialogTitle';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../utils/helpers/Functions';

function ImportImage({ open, offerId, importType, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  function handleChange(files) {
    setFiles(files);
  }

  async function handleSubmit() {
    try {
      setSubmitting(true);
      enqueueSnackbar('Importando...', {
        variant: 'info',
      });

      await Promise.all(
        files.map(async file => {
          const fileData = await toBase64(file);

          delete fileData.blob;

          if (fileData.name.includes('.png.jpeg')) {
            fileData.name = fileData.name.replace('.png', '');
          }

          return await api(
            `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/import-image`,
            { offerId, importType, file: fileData },
            'POST',
            null,
            null,
            false
          );
        })
      );

      enqueueSnackbar('Imagens importadas com sucesso', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      enqueueSnackbar('Erro ao importar imagens', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle onClose={onClose}>Importar imagem</DialogTitle>
      <Divider />
      <DialogContent>
        <DropzoneArea
          acceptedFiles={['image/jpeg', 'image/png']}
          showPreviews
          useChipsForPreview
          showPreviewsInDropzone={false}
          maxFileSize={5000000}
          dropzoneText="Arraste e solte um arquivo aqui ou clique"
          showAlerts={false}
          onChange={handleChange}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={files.length === 0 || submitting}
          onClick={handleSubmit}
        >
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportImage;
