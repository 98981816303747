import axios from 'axios';
import React, { Component } from 'react';
import './LoaderHTML.css';
export default class LoaderHTML extends Component {
  state = { html: '', loading: false };
  async componentDidMount() {
    try {
      const { urlHTML, labelLink } = this.props;

      if (urlHTML) {
        this.setState({ loading: true });
        const html = await axios.get(urlHTML[labelLink]);
        this.props.textHTML(html.data);
        this.setState({ html: html.data, loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.urlHTML !== this.props.urlHTML && this.props.urlHTML) {
      try {
        const { urlHTML, labelLink } = this.props;
        if (urlHTML) {
          this.setState({ loading: true });
          const html = await axios.get(urlHTML[labelLink]);
          this.props.textHTML(html.data);
          this.setState({ html: html.data, loading: false });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  render() {
    const { html, loading } = this.state;
    const { urlHTML } = this.props;

    return !loading ? (
      urlHTML ? (
        <div className="comTemplate">
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      ) : (
        <div className="semTemplate">Preview template</div>
      )
    ) : (
      <div className="loader-example">
        <div className="loader-bar" />
      </div>
    );
  }
}
