import React, { useState, useCallback } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Link,
} from '@material-ui/core';
import {
  ChatBubbleOutline,
  ExpandMoreRounded,
  ExpandLessRounded,
  PersonRounded,
} from '@material-ui/icons';
import { mention } from '../../../../../utils/helpers/Format';
import useDate from '../../../../../hooks/date';
import useStyles from '../styles';

export default function Comments({ data = [], type = '' }) {
  const classes = useStyles(),
    { differenceFormated } = useDate(),
    [expanded, setExpanded] = useState(true),
    [expandedReply, setExpandedReply] = useState('');

  const expandedClick = () => {
    setExpanded(!expanded);
  };

  const _redirect = useCallback(
    comment => {
      let newLink = '';
      if (type === 'FACEBOOK') {
        newLink = `https://www.facebook.com/${comment.user_id}/`;
      } else if (type === 'INSTAGRAM') {
        newLink = `https://www.instagram.com/${comment.username}/`;
      }

      window.open(newLink, '_blank');
    },
    [type]
  );

  return (
    <Grid item>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} variant="rounded">
              <ChatBubbleOutline />
            </Avatar>
          }
          action={
            <IconButton
              size="small"
              onClick={expandedClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </IconButton>
          }
          title="Comentários"
        />
        <Collapse
          className={classes.collapseComment}
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            {data &&
              data.length &&
              data
                .filter(comment =>
                  comment.type_attachment &&
                  comment.type_attachment !== 'sticker'
                    ? false
                    : true
                )
                .map(comment => (
                  <Box key={comment.comment_id} pt={0.5} pl={2} display="flex">
                    <Box mr={1} mt="2px" alignSelf="flex-start">
                      <Avatar className={classes.avatarMobile}>
                        <PersonRounded style={{ fontSize: '12pt' }} />
                      </Avatar>
                    </Box>
                    <Box pr={2} overflow="hidden">
                      <Box className={classes.rootComment}>
                        <Box py="8px" px="12px">
                          <Link
                            className={classes.nameComment}
                            onClick={() => _redirect(comment)}
                          >
                            {comment.username}
                          </Link>

                          <Box py="4px">
                            {comment.has_attachment ? (
                              <Box>
                                <img
                                  style={{
                                    borderRadius: 18,
                                    width:
                                      comment.type_attachment === 'sticker'
                                        ? 80
                                        : '50%',
                                    height:
                                      comment.type_attachment === 'sticker'
                                        ? 80
                                        : 'auto',
                                  }}
                                  src={comment.url_attachment}
                                />
                              </Box>
                            ) : (
                              <span className={classes.comment}>
                                {comment.message}
                              </span>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <ul className={classes.ulComment}>
                        <li className={classes.liComment}>
                          <b>
                            {differenceFormated(
                              comment.created_comment,
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                          </b>
                        </li>
                        {comment.like_count ? (
                          <li className={classes.liComment}>
                            <b>{`${comment.like_count} ${
                              comment.like_count > 1 ? 'curtidas' : 'curtida'
                            }`}</b>
                          </li>
                        ) : (
                          <div />
                        )}
                      </ul>
                      {comment.has_replies ? (
                        <ul
                          className={classes.ulComment}
                          style={{ marginLeft: '2vw', display: 'block' }}
                        >
                          <li
                            className={classes.liComment}
                            style={{ marginTop: '1vh', marginBottom: 16 }}
                          >
                            <Link
                              style={{ color: '#36325e' }}
                              onClick={() =>
                                setExpandedReply(
                                  expandedReply === comment.comment_id
                                    ? ''
                                    : comment.comment_id
                                )
                              }
                            >
                              <b>
                                {expandedReply === comment.comment_id
                                  ? 'Ocultar respostas'
                                  : `Ver respostas (${comment.replies_count})`}
                              </b>
                            </Link>
                          </li>
                          <li>
                            <Collapse
                              in={expandedReply === comment.comment_id}
                              timeout="auto"
                              unmountOnExit
                            >
                              {comment.replies.map(reply => (
                                <Box
                                  key={reply.reply_id}
                                  pr={2}
                                  mb={2}
                                  overflow="hidden"
                                >
                                  <Box className={classes.rootComment}>
                                    <Box py="8px" px="12px">
                                      <Link
                                        className={classes.nameComment}
                                        onClick={() => _redirect(reply)}
                                      >
                                        {reply.username}
                                      </Link>
                                      <Box py="4px">
                                        <div
                                          className={classes.comment}
                                          dangerouslySetInnerHTML={{
                                            __html: mention(reply.message),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <ul className={classes.ulComment}>
                                    <li className={classes.liComment}>
                                      <b>
                                        {differenceFormated(
                                          reply.created_reply,
                                          'YYYY-MM-DD HH:mm:ss'
                                        )}
                                      </b>
                                    </li>
                                    {reply.like_count ? (
                                      <li className={classes.liComment}>
                                        <b>{`${reply.like_count} ${
                                          reply.like_count > 1
                                            ? 'curtidas'
                                            : 'curtida'
                                        }`}</b>
                                      </li>
                                    ) : (
                                      <div />
                                    )}
                                  </ul>
                                </Box>
                              ))}
                            </Collapse>
                          </li>
                        </ul>
                      ) : (
                        <div />
                      )}
                    </Box>
                  </Box>
                ))}
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
