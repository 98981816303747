import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandMoreRounded as ExpandMoreIcon } from '@material-ui/icons';
import useStyles from '../styles';

export default function InstanceSelect(props) {
  const classes = useStyles(),
    [anchor, setAnchor] = useState(null),
    handleClick = event => setAnchor(event.currentTarget),
    handleClose = () => setAnchor(null);

  return (
    <>
      <Button
        id="select-instance"
        disabled={props.loadingInstances}
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        className={classes.btnInstance}
      >
        {props.instance.name || 'Instância'}
      </Button>
      <Menu
        keepMounted
        id="instance-menu"
        className={classes.menuInstance}
        classes={{ paper: classes.paperMenuInstance }}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.textAlert} color="primary">
              Selecione a instância
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Box marginTop="1vh">
          {props.instances.map((instance, index) => (
            <Box display="flex" alignItems="center">
              <Tooltip
                title={instance.shared_instance ? 'Online' : 'Offline'}
                placement="top"
              >
                <Box
                  marginRight=".5vw"
                  width="10px"
                  height="10px"
                  borderRadius="10px"
                  bgcolor={instance.shared_instance ? '#43a047' : '#e53935'}
                />
              </Tooltip>
              <MenuItem
                onClick={() => {
                  props.onClick(instance);
                  handleClose();
                }}
              >
                <Typography
                  noWrap
                  key={index.toString()}
                  className={classes.textAlert}
                  style={{ color: '#616161' }}
                >
                  {instance.nome}
                </Typography>
              </MenuItem>
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  );
}
