import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
   auto: {
   '& label.Mui-focused': {
      color: '#2813AD !important',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2813AD !important',
      },
    },   
  },
}));