import React, { useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Fab,
  Grow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  AttachFileRounded as AttachFileIcon,
  ImageRounded as ImageIcon,
} from '@material-ui/icons';
import useStyles from '../styles';

const _files = [
  {
    key: 1,
    tooltipTitle: 'Fotos e vídeos',
    accept: 'image/*, video/mp4',
    id: 'file-midia',
    icon: <ImageIcon />,
  },
];

export default function MenuFiles(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(prev => !prev);
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <Box>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            position="absolute"
            bottom="12vh"
            display="flex"
            flexDirection="column"
          >
            {_files.map(_file => (
              <Grow key={_file.id} in={open} timeout={_file.key * 300}>
                <Tooltip title={_file.tooltipTitle} placement="right">
                  <Fab
                    classes={{ root: classes.fab }}
                    size="medium"
                    color="primary"
                  >
                    <input
                      style={{ display: 'none' }}
                      accept={_file.accept}
                      id={_file.id}
                      multiple
                      type="file"
                      onChange={({ target: { files } }) => {
                        props.onPreview(files);
                        setOpen(null);
                      }}
                    />

                    <label
                      style={{ display: 'flex', cursor: 'pointer' }}
                      htmlFor={_file.id}
                    >
                      {_file.icon}
                    </label>
                  </Fab>
                </Tooltip>
              </Grow>
            ))}
          </Box>
          <IconButton
            title="Anexar"
            onClick={handleClick}
            style={{ transform: 'rotate(-45deg)' }}
          >
            <AttachFileIcon />
          </IconButton>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
