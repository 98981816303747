export default {
  header: {
    actions: 'Ações',
  },
  body: {
    addTooltip: 'Adicionar',
    emptyDataSourceMessage: 'Nenhum registro para exibir',
    editRow: {
      saveTooltip: 'Salvar',
      cancelTooltip: 'Cancelar',
      deleteText: 'Você deseja remover esse item?',
    },
  },
  toolbar: {
    showColumnsTitle: 'Exibir colunas',
    showColumnsAriaLabel: 'Exibir colunas',
    searchPlaceholder: 'Pesquisar',
    exportTitle: 'Exportar',
    exportName: 'Exportar CSV',
  },
  pagination: {
    labelRowsSelect: 'linhas',
    labelDisplayedRows: '{count} de {from}-{to}',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
  },
};
