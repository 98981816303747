import queryString from 'query-string';
import { Fragment, useLayoutEffect, useState } from 'react';

import { TimelineV2 } from '../../../../../utils/components/TimelineV2';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../../utils/helpers/Constants';
import api from '../../../../../utils/helpers/Fetch';
import { NewEmptyModal } from '../../NewEmptyModal';
import { Backgrounds } from './components/Backgrounds';
import Filters from './components/filters';
import {
  Background,
  BackgroundsWithFavorites,
  CurrentTab,
  FetchQuery,
  Format,
  Occasions,
  Segments
} from './types';

export function FindBackgrounds({
  open,
  handleClose,
  formatType,
  setBackgroundSelected,
  segments
}: {
  open: boolean;
  handleClose: () => void;
  formatType: string;
  setBackgroundSelected: (param: any) => void;
  segments: Segments[];
}) {
  const [loadingBackgrounds, setLoadingBackgrounds] = useState(false);
  const [backgrounds, setBackgrounds] = useState<BackgroundsWithFavorites>({
    backgrounds: [],
    backgroundsFavorites: []
  });
  const [segmentSelect, setSegmentSelect] = useState<Segments[]>([]);
  const [occasions, setOccasions] = useState<Occasions[]>([]);
  const [query, setQuery] = useState<FetchQuery>({
    formats: [],
    segments: [],
    occasions: [],
    justCompanyId: [],
    page: []
  });
  const [handleMore, setHandleMore] = useState<boolean>(true);
  const tabs = [
    {
      label: 'Geral',
      index: 0,
      children: (
        <Backgrounds
          setCurrentTab={() => {
            setBackgrounds({
              backgrounds: [],
              backgroundsFavorites: []
            }),
              setCurrentTab({ index: 0 });
          }}
          indexTab={0}
          backgrounds={backgrounds}
          setBackgroundSelected={setBackgroundSelected}
          funFavoriteBackground={favoriteBackground}
          handleClose={handleClose}
          handleNextPage={handleNextPage}
          handleMore={handleMore}
          loadingBackgrounds={loadingBackgrounds}
        />
      )
    },
    {
      label: 'Meus Fundos',
      index: 1,
      children: (
        <Backgrounds
          setCurrentTab={() => {
            setBackgrounds({
              backgrounds: [],
              backgroundsFavorites: []
            }),
              setCurrentTab({ index: 1 });
          }}
          indexTab={1}
          backgrounds={backgrounds}
          setBackgroundSelected={setBackgroundSelected}
          funFavoriteBackground={favoriteBackground}
          handleClose={handleClose}
          handleNextPage={handleNextPage}
          handleMore={handleMore}
          loadingBackgrounds={loadingBackgrounds}
        />
      )
    }
  ];
  const [currentTab, setCurrentTab] = useState<CurrentTab>({});

  function handleNextPage() {
    setQuery({ ...query, page: [query.page[0] + 1] });
  }

  async function fetchOccasions() {
    try {
      let listOccasions: Occasions[] = [];
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions`).then(response => {
        response.content.map((value: { id: any; name: any }) => {
          listOccasions.push({ id: value.id, label: value.name });
        });
        setOccasions(listOccasions);
      });
    } catch (err) {
      console.log('ErrFetchOccasion=>', err);
    }
  }

  async function fetchFormats() {
    try {
      let format = await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        formatType == 'Story-video' ? (formatType = 'Story') : '';
        formatType == 'Post-video' ? (formatType = 'Post') : '';
        let format: Array<Format> = response.content.filter((value: { name: string }) => {
          return (
            value.name.toString().replace(/\s/g, '').replace(/-/g, '').toLowerCase() ===
            formatType.toString().replace(/\s/g, '').replace(/-/g, '').toLowerCase()
          );
        });
        if (format.length) {
          setBackgrounds({
            backgrounds: [],
            backgroundsFavorites: []
          });
          setHandleMore(true);
          if (currentTab.index === 0) {
            setQuery({
              ...query,
              occasions: [],
              segments: [2],
              justCompanyId: [],
              formats: [format[0].id],
              page: [1]
            });
          } else if (currentTab.index === 1) {
            const companyId = localStorage.getItem('companyId');
            setQuery({
              ...query,
              occasions: [],
              justCompanyId: [companyId],
              segments: [],
              formats: [format[0].id],
              page: [1]
            });
          }
        }
      });
      return format;
    } catch (err) {
      console.log('ErrFetchFormats=>', err);
    }
  }

  async function fetchBackgrounds(params: any, funFavorite?: number) {
    try {
      setLoadingBackgrounds(true);
      setSegmentSelect(
        segments.filter(value => {
          return value.id == params.segments[0];
        })
      );
      if (funFavorite) {
        params = { ...params, funFavorite: [1] };
      }
      params = params ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}` : '';
      //console.log('PARAMETROS_FETCH_BACKGROUNDS=>',params);
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/favoriteBackgrounds${params}`).then(
        response => {
          if (response.content.length === 0 || response.content.length < 50) {
            setHandleMore(false);
          }
          if (funFavorite) {
            setBackgrounds({
              backgrounds: [...response.content.backgrounds],
              backgroundsFavorites: [...response.content.backgroundsFavorites]
            });
          } else {
            setBackgrounds({
              backgrounds: [...backgrounds.backgrounds, ...response.content.backgrounds],
              backgroundsFavorites: [...response.content.backgroundsFavorites]
            });
          }
        }
      );
    } catch (err) {
      console.log('ErrFetchBackground=>', err);
    } finally {
      setLoadingBackgrounds(false);
    }
  }

  async function favoriteBackground(background: Background) {
    try {
      setLoadingBackgrounds(true);
      if (!background.favorite) {
        await api(
          `${DATASALES_CRM_API}editor-${AMBIENTE}/favoriteBackground`,
          { background_id: background.id },
          'POST'
        )
          .then(() => {
            fetchBackgrounds(query, 1);
          })
          .catch(err => {
            console.log('ErrCREATEFavoriteBackground=>', err);
          });
      }
      if (background.favorite) {
        await api(
          `${DATASALES_CRM_API}editor-${AMBIENTE}/favoriteBackground/${background.id}`,
          null,
          'DELETE'
        )
          .then(() => {
            fetchBackgrounds(query, 1);
          })
          .catch(err => {
            console.log('ErrDELETEFavoriteBackground=>', err);
          });
      }
    } catch (err) {
      console.log('ErrFetchBackground=>', err);
    } finally {
      setLoadingBackgrounds(false);
    }
  }

  useLayoutEffect(() => {
    setLoadingBackgrounds(true);
    fetchOccasions();
    fetchFormats();
  }, [currentTab]);

  const [count, setCount] = useState(0);

  useLayoutEffect(() => {
    count > 0 && fetchBackgrounds(query);
    setCount(prev => prev + 1);
  }, [query]);

  return (
    <NewEmptyModal
      open={open}
      handleClose={() => handleClose()}
      filter={
        <Filters
          segments={segments}
          segmentSelect={segmentSelect}
          occasions={occasions}
          query={query}
          setQuery={setQuery}
          setBackgrounds={setBackgrounds}
          setHandleMore={setHandleMore}
        />
      }
    >
      <>
        <TimelineV2 tabs={tabs} loadingBackgrounds={loadingBackgrounds} />
      </>
    </NewEmptyModal>
  );
}

