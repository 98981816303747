import { Autocomplete, Button, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import addDays from 'date-fns/addDays';
import formatDate from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { OutArray, Store, UpdateImageHandleSubmit } from 'modules/FastModels/types';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { asyncForEach } from 'utils/helpers/Functions';
import { useSelectMenu } from 'utils/helpers/Redirect';

import { StateProps } from '../../../../store/types';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import makeId from '../../../../utils/helpers/makeId';
import {
  clearSelectedShareableItem,
  setFlyersToSelectWhenRedirectToOffer,
  setIsDistributionOpened,
  setSelectedShareableItem
} from '../../../TabloideDigitalV2/action';
import { AdsDistribution } from '../../../TabloideDigitalV2/components/AdsDistribution';
import { setSelectedBackgrounds } from '../../actions';
import {
  SelectedBackgroundsType,
  SubcategoriesBackgroundsType,
  SubcategoriesType
} from '../../reducer';
import Footer from './Footer';
import './style.css';
import { useStyles } from './styles';

type ContentProps = {
  onChangePage: (page: number) => void;
  onOpenBackground: (name: string, url: string) => void;
};

Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

function ButtonRefreshArt({ formik, index, isSubmitting, isSubmittingLogo }: any) {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    let myTimeout: any;

    if (isSubmitting) {
      setEnabled(false);
      myTimeout = setTimeout(() => {
        setEnabled(true);
        console.log('Executing Time out');
      }, 45 * 1000);
    } else {
      setEnabled(true);
    }

    return () => clearTimeout(myTimeout);
  }, [isSubmitting]);

  return (
    <Button
      variant="contained"
      onClick={() => {
        formik.setFieldValue('currentBackground', index);
        formik.handleSubmit();
      }}
      disabled={!enabled || isSubmittingLogo}
      sx={{ marginTop: '16px' }}
    >
      Atualizar Arte
    </Button>
  );
}

export default function PricePage({ onChangePage, onOpenBackground, ...props }: ContentProps) {
  const classes = useStyles();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      currentBackground: null,
      currentPrice: null,
      store: {
        logo_url: null
      }
    },
    onSubmit: handleSubmit
  });

  const permissions = JSON.parse(localStorage?.getItem('permissions') || '{}');
  const noDistribution = permissions?.criacao?.find((item: any) => item?.serviceId === 66);

  const [stores, setStores] = useState<any>([]);
  const [loadingStores, setLoadingStores] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [openAdsDistribution, setOpenAdsDistribution] = useState<boolean>(false);
  const [savingFlyers, setSavingFlyers] = useState<boolean>(false);
  const [isSubmittingLogo, setIsSubmittingLogo] = useState<boolean>(false);
  const [loadingDistribution, setLoadingDistribution] = useState<boolean>(false);

  const [colorText, setColorText] = useState<any>(null);

  const dispatch = useDispatch();
  const { selectedBackgrounds } = useSelector((state: StateProps) => state.FastModelsReducer);

  const backgroundsCount = selectedBackgrounds.length;

  const [defaultJSONAuxiliar, setDefaultJSONAuxiliar] = useState(null);

  const { selectMenu } = useSelectMenu();

  useEffect(() => {
    setDefaultJSONAuxiliar(
      selectedBackgrounds.map(item => JSON.parse(item.backgroundData?.jsonAuxiliar))
    );
  }, []);

  /**
   * @method handleInputKeyDown
   * @param e the the element KeyboardEvent
   * @param i the element iterator number
   * @description select the next input when Enter Key event is fired
   */
  function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>, i: number) {
    const countInputs = document.querySelectorAll(
      `input[id^="prices-page-background-element"]`
    ).length;
    const nextInput = document.querySelector(`#prices-page-background-element-${i + 1}`);

    if (e.key === 'Enter' && i < countInputs - 1) {
      // Example Focus
      nextInput.focus();
    }
  }

  const [anchorEl, setAnchorEl] = useState({});
  const handleClickGenerate = (event, property, index) => {
    setAnchorEl(prev => ({
      ...prev,
      [index]: {
        ...prev?.[index],
        [property]: event.currentTarget
      }
    }));
  };
  const handleCloseGenerate = (property, index) => {
    let tmpObject = anchorEl?.[index];
    if (tmpObject) delete tmpObject[property];
    setAnchorEl(prev => ({
      ...prev,
      [index]: {
        ...tmpObject
      }
    }));
  };

  const onChangeStore = (value: Store) => {
    const storeComplete = (storeVar: any) => {
      let enderecoComplete = '';
      if (storeVar) {
        if (storeVar.endereco) {
          enderecoComplete = enderecoComplete + storeVar.endereco;
        }
        if (storeVar.numero) {
          enderecoComplete = enderecoComplete + ', ' + storeVar.numero;
        }
        if (storeVar.bairro) {
          enderecoComplete = enderecoComplete + ', ' + storeVar.bairro;
        }
        if (storeVar.cep) {
          enderecoComplete = enderecoComplete + ', ' + storeVar.cep;
        }
        if (storeVar.cidade) {
          enderecoComplete = enderecoComplete + ', ' + storeVar.cidade;
        }
        if (storeVar.uf) {
          enderecoComplete = enderecoComplete + ', ' + storeVar.uf;
        }
      }

      return enderecoComplete;
    };

    formik.setFieldValue('store', value);
    formik.setFieldValue('address', storeComplete(value?.complete));
    formik.setFieldValue('phone_number', value?.phone_number);

    setSelectedStore(value);
  };

  async function fetchStores() {
    try {
      setLoadingStores(true);
      await api(`${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`).then(
        response => {
          const tmp = map(response, s => ({
            id: s.id_loja,
            name: s.nome_loja,
            zip_code: s.cep,
            street_address: s.endereco,
            phone_number: s.whatsapp_phone_number,
            logo_url: s.logo1,
            complete: {
              ...s
            }
          }));
          setStores(tmp);
          onChangeStore(tmp[0]);

          setIsSubmittingLogo(true);
          formik.handleSubmit();
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingStores(false);
    }
  }

  async function handleSubmit(values: UpdateImageHandleSubmit) {
    try {
      if (isSubmittingLogo) {
        await handleSubmitStore(values);
      } else {
        await handleSubmitPrices(values);
      }

      // enqueueSnackbar('Arte gerada com sucesso', { variant: 'success' });
    } catch (err) {
      console.log(err);
      // const message = err.message || 'Erro ao gerar arte';
      // enqueueSnackbar(message, { variant: 'error' });
    }
  }

  async function handleSubmitPrices(values: UpdateImageHandleSubmit) {
    const changePriceSelectedBrackgrounds = selectedBackgrounds;

    try {
      changePriceSelectedBrackgrounds[values.currentBackground!] = {
        ...changePriceSelectedBrackgrounds[values.currentBackground!],
        isSubmitting: true
      };

      dispatch(setSelectedBackgrounds(changePriceSelectedBrackgrounds));
      // console.log('changePriceSelectedBrackgrounds', changePriceSelectedBrackgrounds);

      let { tag } = values;

      const currentPrice = parseFloat(
        values?.currentPrice?.[
          values.currentBackground!
        ] as string /*.replace(',', '.').replace(' ', '')*/
      );

      const company_data = {
        logo_url: values.store && values.store.logo_url ? values.store.logo_url : null,
        description: values.description ? values.description : null,
        address: values.address ? values.address : null,
        phone_number: values.phone_number ? values.phone_number : null
      };

      const result: any = {
        outArray: []
      };

      let productsHere = [
        {
          club_price: currentPrice
        }
      ];

      const template = changePriceSelectedBrackgrounds[values.currentBackground!].backgroundData;

      const tmp = await api(
        (await config_ds()).api_editor + '/flyers',
        {
          byGoogleCloud: true,
          templateId: template.id,
          formatId: template.formatId,
          tagId: tag ? tag.id : null,
          tagURL: tag ? tag.imageURL : null,
          designURL: template.designURL,
          productQuantity: template.productQuantity,
          products: productsHere,
          backgroundURL: null,
          company_data,
          auxiliar_texts: values.auxiliar_texts?.[values.currentBackground!],
          auxiliar_texts_colors: values.auxiliar_texts_colors?.[values.currentBackground!]
          // colors:
          //   expandedAccordion === 'panelCores'
          //     ? {
          //         colorText,
          //         colorPrice
          //       }
          //     : {}
        },
        'POST',
        undefined,
        undefined,
        false
      );

      result.outArray.push(...tmp.outArray);

      changePriceSelectedBrackgrounds[values.currentBackground!] = {
        ...changePriceSelectedBrackgrounds[values.currentBackground!],
        generatedImage: result.outArray[0].image_url,
        generatedImagePreview: result.outArray[0].preview_url,
        design_url: result.outArray[0].design_url,
        price: currentPrice,
        isSubmitting: false
      };
      dispatch(setSelectedBackgrounds(changePriceSelectedBrackgrounds));
    } catch (err) {
      console.log({ err });

      changePriceSelectedBrackgrounds[values.currentBackground!] = {
        ...changePriceSelectedBrackgrounds[values.currentBackground!],
        isSubmitting: false
      };
    }
  }

  async function handleSubmitStore(values: UpdateImageHandleSubmit) {
    try {
      const copySelectedBackgrounds = selectedBackgrounds;
      copySelectedBackgrounds.map(background => {
        return {
          ...background,
          isSubmitting: true
        };
      });
      dispatch(setSelectedBackgrounds(copySelectedBackgrounds));
      // console.log('copySelectedBackgrounds', copySelectedBackgrounds);

      const { tag } = values;

      const company_data = {
        logo_url: values.store && values.store.logo_url ? values.store.logo_url : null,
        description: values.description ? values.description : null,
        address: values.address ? values.address : null,
        phone_number: values.phone_number ? values.phone_number : null
      };

      let createFlyer: Array<OutArray> = [];
      await asyncForEach(
        copySelectedBackgrounds,
        async (currentBackground: SelectedBackgroundsType, indexCurrentBackground: number) => {
          const {
            id: templateId,
            formatId,
            designURL,
            productQuantity
          } = currentBackground.backgroundData;

          const response = await api(
            (await config_ds()).api_editor + '/flyers',
            {
              // isBase64: 'json',
              // pixelRatio: 0.2,
              byGoogleCloud: true,
              templateId: templateId,
              formatId: formatId,
              tagId: tag ? tag.id : null,
              tagURL: tag ? tag.imageURL : null,
              designURL: designURL,
              productQuantity: productQuantity,
              products: [
                {
                  club_price: currentBackground.price ? currentBackground.price : 99.99
                }
              ],
              backgroundURL: null,
              company_data,
              auxiliar_texts: values.auxiliar_texts?.[indexCurrentBackground],
              auxiliar_texts_colors: values.auxiliar_texts_colors?.[indexCurrentBackground]
            },
            'POST',
            undefined,
            undefined,
            false
          );

          createFlyer.push(response.outArray[0]);
        }
      );

      // console.log('createFlyer', createFlyer);

      const changeLogoSelectedBackgrounds = copySelectedBackgrounds.map((background, index) => {
        return {
          ...background,
          generatedImage: createFlyer[index].image_url,
          generatedImagePreview: createFlyer[index].preview_url,
          design_url: createFlyer[index].design_url,
          isSubmitting: false
        };
      });

      // console.log('changeLogoSelectedBackgrounds', changeLogoSelectedBackgrounds);

      dispatch(setSelectedBackgrounds(changeLogoSelectedBackgrounds));
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingLogo(false);
    }
  }

  const saveFlyersOnTabloid = async () => {
    let id_tabloide_digital: any = null;
    let idsFlyersSaved: any = null;

    try {
      setSavingFlyers(true);

      const responseGet = await api(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        {
          nome: 'Modelos Rápidos - ' + formatDate(new Date(), 'dd-MM-yyyy'),
          dataInicio: formatDate(subDays(new Date(), 1), 'yyyy-MM-dd'),
          dataFim: formatDate(addDays(new Date(), 8), 'yyyy-MM-dd')
        }
      );

      if (responseGet?.length === 0) {
        const responseCreate = await api(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
          {
            nome_tabloide: 'Modelos Rápidos - ' + formatDate(new Date(), 'dd-MM-yyyy'),
            descricao: '',
            data_inicio: formatDate(new Date(), 'yyyy-MM-dd'),
            data_fim: formatDate(addDays(new Date(), 7), 'yyyy-MM-dd'),
            arquivo_tabloide_digital: null,
            googleId: '',
            facebookId: '',
            storeId: null,
            colors: {
              background: '#fff',
              description: 'rgba(0, 0, 0, 0.87)',
              card: '#fff',
              percent: 'rgba(0, 0, 0, 0.87)',
              productName: 'rgba(0, 0, 0, 0.87)',
              productPrice: 'rgba(0, 0, 0, 0.87)',
              productDiscountPrice: 'rgba(0, 0, 0, 0.87)'
            },
            produtos: [],
            copia: false,
            id_tabloide_digital: null
          },
          'POST',
          null,
          null,
          false
        );

        id_tabloide_digital = responseCreate.tabloide.id_tabloide_digital;
      } else {
        id_tabloide_digital = responseGet[0].id_tabloide_digital;
      }

      idsFlyersSaved = await Promise.all(
        selectedBackgrounds.map(async flyer => {
          const template = flyer.backgroundData;

          return await api(
            `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`,
            {
              offer_id: id_tabloide_digital,
              name: template.name,
              format_id: template.formatId,
              template_id: template.id,
              product_quantity: template.productQuantity,
              type: template.formatId === 1 ? 'Post' : 'Undefined',
              image_url: flyer.generatedImage,
              json_url: flyer.design_url,
              preview_url: flyer.generatedImagePreview
            },
            'POST',
            undefined,
            undefined,
            false
          );
        })
      );

      idsFlyersSaved = idsFlyersSaved?.map((flyerSaved: any) => flyerSaved?.content?.id);
    } catch (err) {
      console.log(err);
    } finally {
      setSavingFlyers(false);
    }
    return { id_tabloide_digital, idsFlyersSaved };
  };

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    dispatch(setIsDistributionOpened(openAdsDistribution));
  }, [openAdsDistribution]);

  const textoAuxiliarProperty = (index: number) => 'texto-auxiliar-' + (index + 1).pad(2);

  const havePrice = (index: number) => defaultJSONAuxiliar?.[index]?.qtdePriceElement !== 0;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            letterSpacing: '0.05em'
          }}
          data-testid="dsModule-fast-models-prices"
        >
          <div
            style={{
              width: 803,
              padding: 32,
              margin: '40px auto auto',
              backgroundColor: '#F5F5F5'
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 32, textAlign: 'center' }}>
                <img
                  src={
                    formik.values?.store?.logo_url ||
                    'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/imagem_vazia_logo_empty.webp'
                  }
                  style={{ width: 164 }}
                ></img>
              </div>
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '32px'
                  }}
                >
                  Selecione a loja
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '26px',

                    marginTop: 8
                  }}
                >
                  As informações da loja selecionada aparecerão nas imagens abaixo. Por exempo:
                  logo, endereço e número de telefone.
                </div>
                <div style={{ marginTop: 32 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    sx={{
                      bgcolor: 'white'
                    }}
                    options={stores}
                    getOptionLabel={(option: any) => option?.name}
                    style={{ width: '100%' }}
                    value={selectedStore}
                    onChange={(e, value: Store) => {
                      onChangeStore(value);

                      setIsSubmittingLogo(true);
                      value && formik.handleSubmit();
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={loadingStores ? 'Carregando...' : 'Selecione a loja'}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '15px',

                    color: '#616161',

                    marginTop: 8,
                    marginLeft: 16
                  }}
                >
                  As informações da loja selecionada aparecerá nas suas imagens
                </div>
              </div>
            </div>

            {selectedBackgrounds.map((item, index) => (
              <>
                <hr style={{ borderTop: '2px solid #fff', margin: '24px 0px 24px 0px' }} />

                <div key={`div-background-container-${index}`} style={{ display: 'flex' }}>
                  <div style={{ marginRight: 32, textAlign: 'center' }}>
                    <img
                      src={
                        formik.isSubmitting && (isSubmittingLogo || item.isSubmitting)
                          ? 'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loading-buffering.gif'
                          : item.generatedImagePreview ||
                            item.generatedImage ||
                            item.backgroundData.thumbnail ||
                            item.backgroundData.imageURL
                      }
                      style={{
                        width: 120,
                        marginLeft: 22,
                        marginRight: 22,
                        opacity: item.generatedImage && (item.price || !havePrice(index)) ? 1 : 0.3
                      }}
                    ></img>
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '26px',

                        color: '#127FFF',
                        cursor: 'pointer',

                        marginTop: 8
                      }}
                      onClick={e => {
                        // const isTheBackgroundPreviewElement = e.target?.getAttribute(
                        //   'data-background-selector'
                        // );

                        // if (isTheBackgroundPreviewElement)
                        onOpenBackground(
                          item.backgroundData.name,
                          item.generatedImage || item.backgroundData.imageURL
                        );
                      }}
                    >
                      Visualizar imagem
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '32px'
                      }}
                    >
                      {item.subcategoryDescription}
                    </div>
                    {havePrice(index) && (
                      <>
                        <div style={{ marginTop: 24 }}>
                          <TextField
                            id={`prices-page-background-element-${index}`}
                            disabled={formik.isSubmitting && isSubmittingLogo ? true : false}
                            label={
                              formik.isSubmitting && isSubmittingLogo
                                ? 'Aguarde o carregamento'
                                : 'Preço do produto'
                            }
                            placeholder="R$ 00.00 "
                            variant="outlined"
                            type="number"
                            sx={{
                              bgcolor: 'white',
                              width: '100%'
                            }}
                            inputProps={{ min: 0 }}
                            onBlur={event => {
                              if (event.target.value) {
                                formik.setFieldValue('currentBackground', index);
                                formik.setFieldValue('currentPrice', {
                                  ...formik?.values?.currentPrice,
                                  [index]: Math.abs(parseFloat(event.target.value))
                                });
                                formik.handleSubmit();
                              }
                            }}
                            onKeyDown={function (e) {
                              handleInputKeyDown(e, index);
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '15px',

                            color: '#616161',

                            marginTop: 8,
                            marginLeft: 16
                          }}
                        >
                          Este é o preço que irá nesta imagem
                        </div>
                      </>
                    )}
                    {Array.from(Array(item.backgroundData.auxiliarTextQtde || 0).keys()).map(
                      (value_auxiliar_text, index_auxiliar_text) => (
                        <div style={{ marginTop: 24 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                              id={`auxiliar-text-index-${index_auxiliar_text}-element-${index}`}
                              disabled={formik.isSubmitting && isSubmittingLogo ? true : false}
                              label={
                                formik.isSubmitting && isSubmittingLogo
                                  ? 'Aguarde o carregamento'
                                  : 'Texto ' + (index_auxiliar_text + 1)
                              }
                              placeholder={'Texto ' + (index_auxiliar_text + 1)}
                              variant="outlined"
                              type="text"
                              multiline
                              sx={{
                                bgcolor: 'white',
                                width: '100%'
                              }}
                              defaultValue={
                                defaultJSONAuxiliar?.[index]?.auxiliarTexts?.[
                                  textoAuxiliarProperty(index_auxiliar_text)
                                ]
                              }
                              onBlur={event => {
                                formik.setFieldValue('currentBackground', index);
                                formik.setFieldValue('auxiliar_texts', {
                                  ...formik.values.auxiliar_texts,
                                  [index]: {
                                    ...formik.values.auxiliar_texts?.[index],
                                    [textoAuxiliarProperty(index_auxiliar_text)]: event.target.value
                                  }
                                });
                              }}
                              onKeyDown={function (e) {
                                handleInputKeyDown(e, index);
                              }}
                            />

                            <Button
                              sx={{
                                marginLeft: '8px'
                              }}
                            >
                              <img
                                src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/new-create-art/icon-colors-5.png"
                                alt=""
                                onClick={event =>
                                  handleClickGenerate(
                                    event,
                                    textoAuxiliarProperty(index_auxiliar_text),
                                    index
                                  )
                                }
                                style={{
                                  width: 40,
                                  height: 40,
                                  ...(!!colorText?.[index]?.[
                                    textoAuxiliarProperty(index_auxiliar_text)
                                  ]
                                    ? {}
                                    : { filter: 'opacity(0.3)' })
                                }}
                              />
                            </Button>
                          </div>

                          <Menu
                            id="basic-menu"
                            anchorEl={
                              anchorEl?.[index]?.[textoAuxiliarProperty(index_auxiliar_text)]
                            }
                            open={Boolean(
                              anchorEl?.[index]?.[textoAuxiliarProperty(index_auxiliar_text)]
                            )}
                            onClose={() =>
                              handleCloseGenerate(textoAuxiliarProperty(index_auxiliar_text), index)
                            }
                            MenuListProps={{
                              'aria-labelledby': 'basic-button'
                            }}
                            sx={{
                              '& .MuiPaper-root': {
                                borderRadius: 1,
                                minWidth: 260,
                                boxShadow:
                                  'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                                '& .MuiMenu-list': {
                                  padding: '4px 0'
                                }
                              }
                            }}
                          >
                            <MenuItem onClick={() => {}}>
                              <div style={{ fontSize: 14, margin: '8px 0px' }}>
                                <SketchPicker
                                  color={
                                    colorText?.[index]?.[textoAuxiliarProperty(index_auxiliar_text)]
                                  }
                                  onChangeComplete={(color: any) => {
                                    setColorText((prev: any) => ({
                                      ...prev,
                                      [index]: {
                                        ...prev?.[index],
                                        [textoAuxiliarProperty(index_auxiliar_text)]: color.rgb
                                      }
                                    }));
                                    formik.setFieldValue('auxiliar_texts_colors', {
                                      ...colorText,
                                      [index]: {
                                        ...colorText?.[index],
                                        [textoAuxiliarProperty(index_auxiliar_text)]: color.rgb
                                      }
                                    });

                                    if (
                                      !formik.values.auxiliar_texts?.[index]?.[
                                        textoAuxiliarProperty(index_auxiliar_text)
                                      ]
                                    ) {
                                      formik.setFieldValue('auxiliar_texts', {
                                        ...formik.values.auxiliar_texts,
                                        [index]: {
                                          ...formik.values.auxiliar_texts?.[index],
                                          [textoAuxiliarProperty(index_auxiliar_text)]: ''
                                        }
                                      });
                                    }
                                  }}
                                  styles={{
                                    default: {
                                      picker: {
                                        boxShadow: 'none',
                                        margin: 'auto'
                                      }
                                    }
                                  }}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: 8
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      let tmpObject = colorText[index];
                                      delete tmpObject[textoAuxiliarProperty(index_auxiliar_text)];
                                      setColorText((prev: any) => ({
                                        ...prev,
                                        [index]: {
                                          ...tmpObject
                                        }
                                      }));
                                      formik.setFieldValue('auxiliar_texts_colors', {
                                        ...colorText,
                                        [index]: {
                                          ...tmpObject
                                        }
                                      });
                                      handleCloseGenerate(
                                        textoAuxiliarProperty(index_auxiliar_text),
                                        index
                                      );
                                    }}
                                  >
                                    Limpar
                                  </Button>

                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleCloseGenerate(
                                        textoAuxiliarProperty(index_auxiliar_text),
                                        index
                                      );
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </div>
                              </div>
                            </MenuItem>
                          </Menu>
                        </div>
                      )
                    )}

                    <ButtonRefreshArt
                      index={index}
                      formik={formik}
                      isSubmitting={selectedBackgrounds[index].isSubmitting}
                      isSubmittingLogo={isSubmittingLogo}
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </form>

      <div style={{ height: 80 }}></div>

      <Footer
        disabledDistribute={
          savingFlyers ||
          selectedBackgrounds.filter(i => i.isSubmitting).length > 0 ||
          formik.isSubmitting ||
          selectedBackgrounds.filter(i => !i.generatedImage).length > 0 ||
          selectedBackgrounds.filter((i, index) => havePrice(index) && i.price === undefined)
            .length > 0
        }
        goBack={() => onChangePage(1)}
        onDistribute={async () => {
          try {
            const { id_tabloide } = await saveFlyersOnTabloid();
            if (noDistribution) {
              document.getElementById('#EstudioDeCriacaoButton').click();
              // await new Promise(r => setTimeout(r, 2000));
              window.location.replace(window.location.href + '/' + id_tabloide);
              return;
            }
            dispatch(
              setSelectedShareableItem([
                ...selectedBackgrounds.map(item => ({
                  // ...item.backgroundData,
                  // ...item
                  id: item.backgroundData.id,
                  image_url: item.generatedImage || item.backgroundData.imageURL,
                  preview_url:
                    item.generatedImagePreview ||
                    item.backgroundData.thumbnail ||
                    item.backgroundData.imageURL,
                  data: new Date().getTime()
                }))
              ])
            );
            setOpenAdsDistribution(true);
          } catch (err) {
            console.log(err);
          }
        }}
        loadingDistribution={loadingDistribution}
        onCreateFolder={async (typeToDistribute: any) => {
          try {
            setLoadingDistribution(true);
            const { id_tabloide_digital: id_tabloide, idsFlyersSaved } =
              await saveFlyersOnTabloid();
            dispatch(
              setFlyersToSelectWhenRedirectToOffer({
                flyersIds: [...idsFlyersSaved],
                readyToDistribute: false,
                typeToDistribute: typeToDistribute
              })
            );
            setLoadingDistribution(false);
            selectMenu(
              'criacao',
              'templates,propaganda_de_ofertas',
              '/estudio/' + id_tabloide,
              'Estúdio'
            );
          } catch (err) {
            console.log(err);
          }
        }}
      />

      {openAdsDistribution && (
        <AdsDistribution
          isFromFastModels={true}
          onCloseFromFastModels={(
            { finishProcess }: { finishProcess: boolean } = { finishProcess: false }
          ) => {
            dispatch(clearSelectedShareableItem());
            setOpenAdsDistribution(false);
            if (finishProcess) {
              dispatch(setIsDistributionOpened(false));
              dispatch(setSelectedBackgrounds([]));
              onChangePage(1);
            }
          }}
          isOpened={openAdsDistribution}
          uuid={'uuid'}
          offerId={0}
          offerName={'OfferName'}
          onChangeDistributionState={null}
        />
      )}
    </>
  );
}

