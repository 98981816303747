import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import DialogTitle from '../../../../utils/components/DialogTitle';
import TabPanel from '../../../../utils/components/TabPanel';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { useDefaultStyles } from '../../style';
import SelecionarProdutos from '../SelecionarProdutos';
import BackgroundPreview from './BackgroundPreview';
import Loader from './Loader';
import SelectBackground from './SelectBackground';
import SelectTemplate from './SelectTemplate';
import StoreLogoPreview from './StoreLogoPreview';

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const useStyles = makeStyles(theme => ({
  avatar: { backgroundColor: theme.palette.primary.main },
  title: {
    flexGrow: 1
  },
  appBar: {
    border: 'none !important'
  },
  tab: {
    textTransform: 'none'
  },
  tagImg: {
    height: 50
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    minHeight: '70vh'
  }
}));

function TagDialog(props) {
  const classes = useStyles();
  const { onClose, open, tags, onChange } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
      <DialogTitle id="simple-dialog-title">Selecione uma Etiqueta</DialogTitle>
      <Grid container spacing={2}>
        {tags.map(tag => (
          <Grid key={tag.id} item xs={3}>
            <img
              src={tag.imageURL}
              alt=""
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => {
                onChange(tag);
                onClose();
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
}

function CreateFlyer(props) {
  let { open, offerId, onClose, onCloseReturn, formatType } = props;

  const classes = useStyles();

  const magnumClasses = useDefaultStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { id: offer_id } = useParams();

  const [tabValue, setTabValue] = useState(!props.modelo_pronto ? 0 : 1);
  const [stores, setStores] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectBackgroundIsOpen, setSelectBackgroundIsOpen] = useState(false);
  const [selectTemplateIsOpen, setSelectTemplateIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openDialogContinuar, setOpenDialogContinuar] = useState(false);

  const [openSelecionarProdutos, setOpenSelecionarProdutos] = useState(false);
  const [idsNaoIncluidos, setIdsNaoIncluidos] = useState([]);

  const [openTagDialog, setOpenTagDialog] = useState(false);

  const history = useHistory();

  useEffect(() => {
    history.push(history.location.pathname + '?CreateFlyer');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  const [colorText, setColorText] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1
  });
  const [colorText2, setColorText2] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1
  });
  const [colorPrice, setColorPrice] = useState({
    r: 255,
    g: 255,
    b: 255,
    a: 1
  });

  const [expandedAccordion, setExpandedAcoordion] = useState(false);
  const onClickAccordion = panel => (event, isExpanded) => {
    setExpandedAcoordion(isExpanded ? panel : false);
  };

  const formik = useFormik({
    initialValues: {
      offer_id
    },
    // validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  async function handleSubmitVideo(values) {
    try {
      const { template, background, products, tag } = values;

      const result = await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postVideomatik`,
        {
          seeRequests: false,
          id_tabloide: offer_id,
          id_template: template.video_template_id,
          tipo: template.formatId,
          idsNaoIncluidos,
          composition: template.video_composition,
          logo: values.store && values.store.logo_url ? values.store.logo_url : null,
          fundo: background ? background.imageURL : null,
          etiqueta: tag ? tag.imageURL : null,
          texto: values.description ? values.description : null,
          ...(expandedAccordion === 'panelCores'
            ? {
                corTexto1: rgbToHex(colorText.r, colorText.g, colorText.b),
                corTexto2: rgbToHex(colorText2.r, colorText2.g, colorText2.b),
                corPreco1: rgbToHex(colorPrice.r, colorPrice.g, colorPrice.b)
              }
            : {})
        },
        'POST',
        undefined,
        undefined,
        false
      );

      enqueueSnackbar('Arte enviada para processamento', {
        variant: 'success'
      });

      setOpenDialogContinuar(true);
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Erro ao gerar arte', { variant: 'error' });
    }
  }

  const executeThisFunction = async () => {
    if (props.isManual) {
      try {
        setTabValue(2);
        const result = await handleSubmit({});
        onClose(result[0]?.content);
      } catch (err) {}
    }
  };

  useEffect(() => {
    executeThisFunction();
  }, [props.isManual]);

  async function handleSubmit(values) {
    let lastResult = [];

    try {
      if (props.isManual) {
        setLoading(true);
      }

      if (!values.background && !props.modelo_pronto && !props.isManual) {
        throw new Error('Selecione uma imagem de fundo');
      }

      let { template, background, products, tag } = values;

      if (props.isManual) {
        products = [];
        background = '';
        template = {
          id: 1505,
          formatId: 18,
          name: 'Manual (Em Branco)',
          designURL:
            'https://s3.amazonaws.com/datasalesio-imagens/ManualEmBranco20220406T175119298Z-8c24607b-aa42-41ff-888a-9291356ee6eb.json',
          productQuantity: 0,
          type: 'Manual'
        };
      }

      if (template?.video_template_id) {
        await handleSubmitVideo(values);
        return;
      }

      const company_data = {
        logo_url: values.store && values.store.logo_url ? values.store.logo_url : null,
        description: values.description ? values.description : null,
        address: values.address ? values.address : null,
        phone_number: values.phone_number ? values.phone_number : null
      };

      const result = {
        outArray: []
      };

      let productsHere = products.filter(k => !idsNaoIncluidos.find(n => n === k.id));

      let totalQuantity = template.productQuantity
        ? productsHere.length / template.productQuantity
        : 1;

      for (let k = 0; k < totalQuantity / 10; k++) {
        const payload = {
          byGoogleCloud: true,
          offer_id: offerId,
          templateId: template.id,
          formatId: template.formatId,
          tagId: tag ? tag.id : null,
          tagURL: tag ? tag.imageURL : null,
          designURL: template.designURL,
          productQuantity: template.productQuantity,
          products: productsHere
            ?.slice(0, 10 * template.productQuantity)
            .map(({ fullData, ...rest }) => ({
              ...rest,
              isOfertaSecreta: Boolean(fullData.isOfertaSecreta)
            })),
          backgroundURL: background ? background.imageURL : null,
          //
          company_data,
          colors:
            expandedAccordion === 'panelCores'
              ? {
                  colorText,
                  colorPrice
                }
              : {}
        };
        const tmp = await api(
          (await config_ds()).api_editor + '/flyers',
          payload,
          'POST',
          undefined,
          undefined,
          false
        );

        result.outArray.push(...tmp.outArray);

        productsHere.splice(0, 10 * template.productQuantity);
      }

      await Promise.all(
        result.outArray.map(async r =>
          lastResult.push(
            await api(
              `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`,
              {
                offer_id: offerId,
                name: template.name,
                format_id: template.formatId,
                template_id: template.id,
                product_quantity: template.productQuantity,
                type: template.type,
                image_url: r.image_url,
                json_url: r.design_url,
                preview_url: r.preview_url
              },
              'POST',
              undefined,
              undefined,
              false
            )
          )
        )
      );

      enqueueSnackbar('Arte gerada com sucesso', { variant: 'success' });

      setOpenDialogContinuar(true);
    } catch (err) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });
    }

    if (props.isManual) {
      setLoading(false);
      return lastResult;
    }
  }

  function handleSelectBackground(background) {
    formik.setFieldValue('background', background);
    setTabValue(prevState => prevState + 1);
  }

  function handleSelectTemplate(template) {
    formik.setFieldValue('template', template);

    if (!props.modelo_pronto) {
      setTabValue(prevState => prevState + 1);
    }
  }

  function handleSetStore(value) {
    formik.setFieldValue('store', value);
  }
  async function fetchStores() {
    try {
      await api(`${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`).then(
        response => {
          setStores(
            map(response, s => ({
              id: s.id_loja,
              name: s.nome_loja,
              zip_code: s.cep,
              street_address: s.endereco,
              phone_number: s.whatsapp_phone_number,
              logo_url: s.logo1,
              complete: {
                ...s
              }
            }))
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchProducts() {
    try {
      await api(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        { id_tabloide: offerId }
      ).then(response => {
        formik.setFieldValue(
          'products',
          map(response[0].produtos, p => ({
            id: p.id_tabloide_produto,
            name: p.nome_produto,
            // price: currencyFormat(p.preco_original),
            price: p.preco_original,
            club_price: p.preco_dois,
            dynamics: p.dinamica_comercial,
            image_url: p.imagem_produto,
            is_active: p.ativo,
            var1: p.var1,
            var2: p.var2,
            var3: p.var3,
            var4: p.var4,
            var5: p.var5,
            var6: p.var6,
            fullData: { ...p }
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchTags() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/tags`).then(response => {
        setTags(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchStores();
    fetchTags();
    fetchProducts();
  }, []);

  if (!stores) {
    return null;
  }
  return (
    <Fragment>
      <Dialog open={open} maxWidth="lg" fullWidth fullScreen onClose={onCloseReturn}>
        <DialogTitle onClose={onCloseReturn}>
          {tabValue > 0 && !props.modelo_pronto && (
            <Button
              onClick={() => {
                setTabValue(prevState => prevState - 1);
              }}
              style={{ marginRight: 40 }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          )}
        </DialogTitle>

        {/* <AppBar className={classes.appBar} position="relative" color="inherit">
          <Toolbar>
            <Typography className={classes.title}>Novo editor</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
            >
              Salvar
            </Button>
          </Toolbar>
        </AppBar> */}

        <div style={{ margin: '0px 30px' }}>
          <div
            className={magnumClasses.title1}
            style={{
              marginBottom: 20
            }}
          >
            {!props.modelo_pronto
              ? `Gerar Arte - ${props.tabloid.qtde_produtos_lista} Produtos no Total`
              : `Modelos Prontos Novo Editor`}
          </div>

          <Paper square>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              // onChange={(event, newValue) => {
              //   setTabValue(newValue);
              // }}
            >
              <Tab label="1. Arte" className={magnumClasses.tab} />
              <Tab label="2. Diagramação" className={magnumClasses.tab} />
              <Tab label="3. Descrição" className={magnumClasses.tab} />
            </Tabs>
          </Paper>
        </div>

        {/* <Divider /> */}
        {/* <Toolbar /> */}

        <DialogContent className={classes.content}>
          <form onSubmit={formik.handleSubmit}>
            <div style={tabValue === 0 ? {} : { display: 'none' }}>
              <SelectBackground onSelect={handleSelectBackground} formatType={formatType} />
            </div>
            {tabValue === 1 && (
              <SelectTemplate
                onSelect={params => {
                  handleSelectTemplate(params);

                  if (props.modelo_pronto) {
                    formik.handleSubmit();
                  }
                }}
                externFormat={formik.values?.background}
                modelo_pronto={props.modelo_pronto}
                stores={stores}
                setStore={handleSetStore}
                formatType={formatType}
              />
            )}
            <div style={tabValue === 2 ? {} : { display: 'none' }}>
              <Container maxWidth="md">
                <Grid container spacing={4} style={{ marginTop: 10 }}>
                  <Grid item xs={8}>
                    <Card variant="outlined">
                      <CardHeader
                        title="Loja"
                        avatar={
                          <Avatar className={classes.avatar}>
                            <StoreOutlinedIcon />
                          </Avatar>
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          {formik.values.store && formik.values.store.logo_url && (
                            <Grid item xs={12}>
                              <StoreLogoPreview src={formik.values.store.logo_url} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Autocomplete
                              options={stores}
                              getOptionLabel={option => option.name}
                              value={formik.values.store}
                              onChange={(e, value) => {
                                const storeComplete = storeVar => {
                                  let enderecoComplete = '';
                                  if (storeVar) {
                                    if (storeVar.endereco) {
                                      enderecoComplete = enderecoComplete + storeVar.endereco;
                                    }
                                    if (storeVar.numero) {
                                      enderecoComplete = enderecoComplete + ', ' + storeVar.numero;
                                    }
                                    if (storeVar.bairro) {
                                      enderecoComplete = enderecoComplete + ', ' + storeVar.bairro;
                                    }
                                    if (storeVar.cep) {
                                      enderecoComplete = enderecoComplete + ', ' + storeVar.cep;
                                    }
                                    if (storeVar.cidade) {
                                      enderecoComplete = enderecoComplete + ', ' + storeVar.cidade;
                                    }
                                    if (storeVar.uf) {
                                      enderecoComplete = enderecoComplete + ', ' + storeVar.uf;
                                    }
                                  }

                                  return enderecoComplete;
                                };

                                formik.setFieldValue('store', value);
                                formik.setFieldValue('address', storeComplete(value?.complete));
                                formik.setFieldValue('phone_number', value?.phone_number);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Selecione uma loja"
                                  error={formik.touched.store && Boolean(formik.errors.store)}
                                  helperText={formik.touched.store && formik.errors.store}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="description"
                              name="description"
                              placeholder="Descrição"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.description && Boolean(formik.errors.description)
                              }
                              helperText={formik.touched.description && formik.errors.description}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="address"
                              name="address"
                              placeholder="Endereço"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                              error={formik.touched.address && Boolean(formik.errors.address)}
                              helperText={formik.touched.address && formik.errors.address}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="phone_number"
                              name="phone_number"
                              placeholder="Celular"
                              value={formik.values.phone_number}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.phone_number && Boolean(formik.errors.phone_number)
                              }
                              helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Card variant="outlined" style={{ marginTop: 20 }}>
                      <CardHeader
                        title="Etiquetas"
                        avatar={
                          <Avatar className={classes.avatar}>
                            <LocalOfferOutlinedIcon />
                          </Avatar>
                        }
                      />
                      <Divider />

                      <CardContent style={{ 'text-align': 'center' }}>
                        {!formik.values?.tag?.imageURL && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenTagDialog(true)}
                            style={{
                              marginLeft: 15,
                              backgroundColor: 'rgba(0, 0, 0, 0.03)',
                              color: '#000000DE',
                              fontWeight: 'regular',
                              border: '0.1px solid  rgba(0, 0, 0, 0.125)'
                            }}
                          >
                            Selecionar Etiquetas
                          </Button>
                        )}

                        <img
                          src={formik.values?.tag?.imageURL}
                          alt=""
                          onClick={() => setOpenTagDialog(true)}
                          style={{ cursor: 'pointer', width: 230 }}
                        />
                        {/* <Autocomplete
                          options={tags}
                          getOptionLabel={option => option.name}
                          value={formik.values.tag}
                          onChange={(e, value) => {
                            formik.setFieldValue('tag', value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Selecione uma etiqueta"
                              error={formik.touched.store && Boolean(formik.errors.tag)}
                              helperText={formik.touched.tag && formik.errors.tag}
                            />
                          )}
                           renderOption={option => {
                            return (
                              <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                  <Avatar
                                    // className={classes.tagImg}
                                    src={option.imageURL}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body1" color="textSecondary">
                                    {option.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }}
                        />*/}
                      </CardContent>
                    </Card>

                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 65 }}
                        onClick={() => setOpenSelecionarProdutos(true)}
                      >
                        Selecionar Produtos
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <img
                      src={formik.values?.background?.imageURL + '?' + makeid(5) || ''}
                      alt={'Preview do Fundo'}
                      style={{ width: '100%', borderRadius: 15 }}
                    />

                    <Accordion
                      expanded={expandedAccordion === 'panelCores'}
                      onChange={onClickAccordion('panelCores')}
                      style={{ marginTop: 20 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, .03)',
                          borderBottom: '1px solid rgba(0, 0, 0, .125)'
                        }}
                      >
                        <Typography>
                          Cores {expandedAccordion === 'panelCores' ? '(Enviar)' : ''}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 12,
                                textAlign: 'center'
                              }}
                            >
                              Cor do Texto
                            </div>
                            <div
                              style={{
                                width: '90%',
                                margin: 'auto'
                              }}
                            >
                              <CompactPicker
                                color={colorText}
                                onChangeComplete={color => setColorText(color.rgb)}
                                styles={{
                                  default: {
                                    picker: {
                                      boxShadow: 'none',
                                      margin: 'auto'
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                marginTop: 20,
                                marginBottom: 12,
                                textAlign: 'center'
                              }}
                            >
                              Cor do Preço
                            </div>
                            <div
                              style={{
                                width: '90%',
                                margin: 'auto'
                              }}
                            >
                              <CompactPicker
                                color={colorPrice}
                                onChangeComplete={color => setColorPrice(color.rgb)}
                                styles={{
                                  default: {
                                    picker: {
                                      boxShadow: 'none',
                                      margin: 'auto'
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>

                          {formik?.values?.template?.video_template_id && (
                            <div>
                              <div
                                style={{
                                  marginTop: 20,
                                  marginBottom: 12,
                                  textAlign: 'center'
                                }}
                              >
                                Cor da Mensagem
                              </div>
                              <div
                                style={{
                                  width: '90%',
                                  margin: 'auto'
                                }}
                              >
                                <CompactPicker
                                  color={colorText2}
                                  onChangeComplete={color => setColorText2(color.rgb)}
                                  styles={{
                                    default: {
                                      picker: {
                                        boxShadow: 'none',
                                        margin: 'auto'
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Container>

              {openSelecionarProdutos && (
                <SelecionarProdutos
                  open={openSelecionarProdutos}
                  onOpen={() => {
                    //this.setState({ idsNaoIncluidos: [] });
                  }}
                  onClose={() => {
                    setOpenSelecionarProdutos(false);
                  }}
                  tabloideProdutos={formik.values.products.map(item => item.fullData)}
                  idsNaoIncluidos={idsNaoIncluidos}
                  onSave={value => {
                    setIdsNaoIncluidos([...value]);
                    setOpenSelecionarProdutos(false);
                  }}
                />
              )}
            </div>
          </form>

          {/* <TabPanel value={tabValue} index={0}>
          <BackgroundsList />
        </TabPanel> */}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          {tabValue === 2 && (
            <Button
              id={'GerarArteButton'}
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
            >
              Gerar
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {(formik.isSubmitting || loading) && <Loader open={true} isSubmiting={formik.isSubmitting} />}

      {openDialogContinuar && (
        <Dialog
          open={openDialogContinuar}
          onClose={() => setOpenDialogContinuar(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Deseja gerar mais artes?'}</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);
                onClose();
              }}
              color="primary"
            >
              Não
            </Button>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);

                if (!props.modelo_pronto) {
                  setTabValue(0);
                }
              }}
              color="primary"
              autoFocus
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openTagDialog && (
        <TagDialog
          open={openTagDialog}
          tags={tags}
          onClose={() => {
            setOpenTagDialog(false);
          }}
          onChange={value => {
            formik.setFieldValue('tag', value);
          }}
          selectedTag={formik.values?.tag}
        />
      )}
    </Fragment>
  );
}

export default CreateFlyer;

