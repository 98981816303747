import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Table from '../../../../utils/components/CustomTable';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import TabPanel from '../../components/TabPanel';
import useStyles from './styles';

function ProviderDetails() {
  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();

  const [provider, setProvider] = useState({});
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getProvider() {
    try {
      setLoading(true);
      const response = await Fetch(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/provider/${id}`
      );

      setProvider(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProvider();
  }, []);

  if (!provider) {
    return null;
  }

  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.push('/trade/providers');
            }}
          >
            voltar
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h3" noWrap>
            {provider.name}
          </Typography>
        </Grid>
        {value !== 0 && (
          <Grid item container justify="flex-end">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                history.push(
                  `/trade/provider/${id}/${
                    value === 1 ? 'user' : 'product'
                  }/new`
                );
              }}
            >
              {value === 1 ? 'Adicionar Usuário' : 'Adicionar Produto'}
            </Button>
          </Grid>
        )}

        <Grid item>
          <Box pt={4} pb={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid item>
          <Card className={classes.card}>
            <Tabs
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
            >
              <Tab label="Performance" />
              <Tab label="Usuarios" />
              <Tab label="Produtos" />
              <Tab label="Campanhas" />
            </Tabs>
            <Divider />
            <CardContent className={classes.cardContent}>
              <TabPanel value={value} index={0}>
                <Table
                  search
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar Fornecedor',
                      onClick: (event, rowData) => {
                        history.push(
                          `/trade/provider/${id}/user/edit/${rowData.id}`
                        );
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Remover Empresa',
                      onClick: (event, rowData) => {},
                    },
                  ]}
                  columns={[
                    { field: 'id', title: 'ID' },
                    { field: 'name', title: 'Nome' },
                  ]}
                  isLoading={loading}
                  data={[]}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Table
                  search
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar Fornecedor',
                      onClick: (event, rowData) => {
                        history.push(
                          `/trade/provider/${id}/user/edit/${rowData.id}`
                        );
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Remover Empresa',
                      onClick: (event, rowData) => {},
                    },
                  ]}
                  columns={[
                    { field: 'id', title: 'ID' },
                    { field: 'name', title: 'Nome' },
                    { field: 'email', title: 'Email' },
                  ]}
                  isLoading={loading}
                  data={provider.users}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Table
                  search
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Editar Fornecedor',
                      onClick: (event, rowData) => {
                        history.push(
                          `/trade/provider/${id}/product/edit/${rowData.id}`
                        );
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Remover Produto',
                      onClick: (event, rowData) => {},
                    },
                  ]}
                  columns={[
                    // {
                    //   title: 'thumbnail',
                    //   render: rowData => (
                    //     <img
                    //       className={classes.thumbnail}
                    //       alt={rowData.name}
                    //       src={rowData.thumbnail}
                    //     />
                    //   ),
                    // },
                    { field: 'id', title: 'ID' },
                    { field: 'ean', title: 'EAN' },
                    { field: 'sku', title: 'Codigo Interno' },
                    { field: 'name', title: 'Nome' },
                    { field: 'category', title: 'Categoria' },
                    { field: 'price', title: 'Preço' },
                  ]}
                  isLoading={false}
                  data={provider.products}
                />
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProviderDetails;
