/* eslint-disable */
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { connect } from 'react-redux';
import { CustomTable, DatePicker } from '../../../../utils/components';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import MyDate from '../../../../utils/helpers/Date';
import Fetch from '../../../../utils/helpers/Fetch';
import {
  formatPrice,
  legendDeviceBanner,
  legendPositionBanner,
} from '../../../../utils/helpers/Format';
import { toBase64 } from '../../../../utils/helpers/Functions';
import { postProdutoImagemTabloide, cleanProductsForAdd } from '../../action';
import Preview from '../Preview';
import UploadBanner from '../UploadBanner';
import AdicionarProduto from '../AdicionarProduto';
import useStyles from './styles';
import ModalSerpApi from '../AdicionarProduto/ModalSerpApi/index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && children}
    </div>
  );
}

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const Date = new MyDate();

function CreateTabloid(props) {
  const { tabloid } = props;
  const classes = useStyles();

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);

  const [uploadBannerIsOpen, setOpen] = useState(false);
  const [openModalSerpApi, setOpenModalSerpApi] = useState(false);
  const [dadosModalSerpApi, setDadosModalSerpApi] = useState({});
  const [adicionarProdutoIsOpen, setOpenAdd] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(Date.getDate('YYYY-MM-DD HH:mm'));
  const [endDate, setEndDate] = useState(Date.getDate('YYYY-MM-DD HH:mm'));

  const [googleId, setGoogleId] = useState('');
  const [facebookId, setFacebookId] = useState('');

  const [banners, setBanners] = useState([]);

  // const [headerBanners, setHeaderBanners] = useState([]);
  // const [footerBanners, setFooterBanners] = useState([]);

  const [colors, setColors] = useState({
    background: '',
    description: '',
    card: '',
    percent: '',
    productName: '',
    productPrice: '',
    productDiscountPrice: '',
  });

  const [products, setProducts] = useState([]);
  const [productsOrder, setProductsOrder] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [value, setValue] = React.useState(0);

  const steps = props.completeEdit
    ? [
        'Informações da Propaganda',
        'Banners',
        'Cores',
        'Integração',
        // 'Produtos',
      ]
    : [
        'Informações da Propaganda',
        // 'Integração',
        // 'Banners',
        // 'Cores',
        'Produtos',
      ];

  useEffect(() => {
    setName(tabloid.nome_tabloide_digital);
    setDescription(tabloid.descricao);

    setStartDate(tabloid.data_inicio.replace('Z', ''));
    setEndDate(tabloid.data_fim.replace('Z', ''));
    setProducts(tabloid.produtos);
    setGoogleId(tabloid.googleId);
    setFacebookId(tabloid.facebookId);
    setBanners(tabloid.banners);
    if (tabloid.store) {
      setStore(tabloid.store);
    }
    if (tabloid.colors) {
      setColors({
        background: Boolean(tabloid.colors.background)
          ? tabloid.colors.background
          : '',
        card: Boolean(tabloid.colors.card) ? tabloid.colors.card : '',
        description: Boolean(tabloid.colors.description)
          ? tabloid.colors.description
          : '',
        percent: Boolean(tabloid.colors.percent) ? tabloid.colors.percent : '',
        productDiscountPrice: Boolean(tabloid.colors.productDiscountPrice)
          ? tabloid.colors.productDiscountPrice
          : '',
        productName: Boolean(tabloid.colors.productName)
          ? tabloid.colors.productName
          : '',
        productPrice: Boolean(tabloid.colors.productPrice)
          ? tabloid.colors.productPrice
          : '',
      });
    }
  }, []);

  useEffect(() => {
    let tmp = [...products];
    setProductsOrder(
      tmp.sort(function(a, b) {
        let i = a.sequence;
        let j = b.sequence;
        if (!i && i !== 0) i = a.id_tabloide_produto;
        if (!j && j !== 0) j = b.id_tabloide_produto;
        if (i > j) {
          return 1;
        }
        if (i < j) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
    );
  }, [JSON.stringify(products)]);

  useEffect(() => {
    async function getStores() {
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
        );

        setStores(response);
      } catch (err) {
        console.error(err);
      }
    }

    getStores();
  }, []);

  async function handleSubmit() {
    const data = {
      id_tabloide_digital: tabloid.id_tabloide_digital,
      uuid: tabloid.uuid,
      nome_tabloide_digital: name.replaceAll('/', '-'),
      descricao: description,
      data_inicio: startDate,
      data_fim: endDate,
      storeId: store ? store.id_loja : null,
      colors,
      googleId,
      facebookId,
    };

    props.onSave(
      data,
      banners.filter(banner => banner.id === undefined)
    );
  }

  async function handleSubmitProduct(product) {
    try {
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
        { produto: product },
        'POST'
      );

      setProducts(prevState => [response.produto, ...prevState]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleEditProduct(product) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
        { produto: product },
        'PUT'
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDeleteBanner(id) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/tabloid/banner/${id}`,
        {},
        'DELETE'
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function addProduto(dataProduct) {
    let new_image = '';
    let data = {
      ...dataProduct,
      preco_original:
        typeof dataProduct.preco_original === 'string'
          ? parseFloat(dataProduct.preco_original.replace(',', '.'))
          : dataProduct.preco_original,
      preco_dois:
        typeof dataProduct.preco_dois === 'string'
          ? parseFloat(dataProduct.preco_dois.replace(',', '.'))
          : dataProduct.preco_dois,
      produto_ativo: 1,
    };
    if (Boolean(data.imagem_produto) && /;base64,/g.test(data.imagem_produto)) {
      new_image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64: true,
      });
    } else {
      new_image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64: false,
      });
    }
    let new_image_object = new_image;
    new_image = new_image.image;
    let dateNow = new window.Date();
    dateNow = dateNow.getTime().toString();
    if (validURL(new_image))
      new_image = new_image.split('?')[0] + '?' + dateNow;
    handleSubmitProduct({
      ...data,
      id_tabloide_digital: tabloid.id_tabloide_digital,
      imagem_produto: new_image,
      imagem_produto_original_link:
        new_image_object.imagem_produto_original_link,
      imagem_produto_original_s3: new_image_object.imagem_produto_original_s3,
    });
    forceUpdate();
  }

  const handleChangeColor = state => color => {
    setColors(prevState => ({ ...prevState, [state]: color.hex }));
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function stepOne() {
    return (
      <React.Fragment>
        <Typography className={classes.stepTitle} variant="h6">
          Informações da Propaganda
        </Typography>
        <Grid container spacing={2}>
          {!props.completeEdit && (
            <Grid item xs={12}>
              <TextField
                label="Nome"
                fullWidth
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Grid>
          )}
          {props.completeEdit && (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  // open={open}
                  // onOpen={() => {
                  //   setOpen(true);
                  // }}
                  // onClose={() => {
                  //   setOpen(false);
                  // }}

                  getOptionSelected={(option, value) =>
                    option.id_loja === value.id_loja
                  }
                  getOptionLabel={option => option.nome_loja}
                  options={stores}
                  loading={stores.length === 0}
                  value={store}
                  onChange={(event, newValue) => setStore(newValue)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecionar loja"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {stores.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          )}
          {!props.completeEdit && (
            <React.Fragment>
              <Grid item xs={6}>
                <DatePicker
                  label="De"
                  dateAndTime
                  value={startDate}
                  onChange={date =>
                    setStartDate(Date.getDate('YYYY-MM-DD HH:mm', date))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Até"
                  dateAndTime
                  value={endDate}
                  onChange={date =>
                    setEndDate(Date.getDate('YYYY-MM-DD HH:mm', date))
                  }
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  function stepTwo() {
    return (
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <TextField
            label="Google"
            fullWidth
            variant="outlined"
            value={googleId}
            onChange={event => setGoogleId(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Facebook"
            fullWidth
            variant="outlined"
            value={facebookId}
            onChange={event => setFacebookId(event.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  function stepThree() {
    return (
      <React.Fragment>
        <Typography variant="h6">Banners</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTable
              data={banners}
              pageSize={10}
              paging
              search
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Adicionar Banner',
                  isFreeAction: true,
                  onClick: () => {
                    setOpen(true);
                  },
                },
              ]}
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    handleDeleteBanner(oldData.id);
                    const data = banners;
                    const index = banners.indexOf(oldData);
                    data.splice(index, 1);
                    setProducts(data);
                    forceUpdate();
                    resolve();
                  }),
              }}
              columns={[
                {
                  title: 'Preview',
                  render: rowData => (
                    <img
                      className={classes.bannerPreview}
                      alt={rowData.name}
                      src={rowData.url || URL.createObjectURL(rowData.file)}
                    />
                  ),
                },
                {
                  title: 'Nome',
                  field: 'name',
                  render: rowData => (
                    <span>
                      {rowData.name ? rowData.name : rowData.file.name}
                    </span>
                  ),
                },
                {
                  title: 'Posição',
                  field: 'position',
                  render: rowData => (
                    <span>{legendPositionBanner(rowData.position)}</span>
                  ),
                },
                {
                  title: 'Dispositivo',
                  field: 'device',
                  render: rowData => (
                    <span>{legendDeviceBanner(rowData.device)}</span>
                  ),
                },
                {
                  title: 'Tipo',
                  field: 'type',
                  render: rowData => (
                    <span>
                      {rowData.type
                        ? rowData.type.split('/')[1]
                        : rowData.file.type.split('/')[1]}
                    </span>
                  ),
                },
              ]}
              deleteText="Deseja remover esse banner?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function stepFour() {
    return (
      <Box display="flex" flexDirection="row" width="100%" overflow="hidden">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="60%"
          paddingX={10}
        >
          <Preview colors={colors} />
        </Box>
        <Box height="100%" width="40%" borderLeft="solid 1px rgba(0,0,0,0.12)">
          <Box height="50%" width="100%">
            <TabPanel value={value} index={0}>
              <ChromePicker
                color={colors.background}
                onChangeComplete={handleChangeColor('background')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChromePicker
                color={colors.description}
                onChangeComplete={handleChangeColor('description')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ChromePicker
                color={colors.card}
                onChangeComplete={handleChangeColor('card')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ChromePicker
                color={colors.percent}
                onChangeComplete={handleChangeColor('percent')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ChromePicker
                color={colors.productName}
                onChangeComplete={handleChangeColor('productName')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ChromePicker
                color={colors.productPrice}
                onChangeComplete={handleChangeColor('productPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ChromePicker
                color={colors.productDiscountPrice}
                onChangeComplete={handleChangeColor('productDiscountPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
          </Box>

          <Box
            className={classes.scrool}
            height="50%"
            width="100%"
            marginTop={6}
            paddingBottom={6}
          >
            <Divider />
            <List>
              <ListItem button divider onClick={() => setValue(0)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.background}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Cor de fundo"
                  secondary={colors.background}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(1)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.description}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Descrição"
                  secondary={colors.description}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(2)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.card} />
                </ListItemAvatar>
                <ListItemText primary="Card" secondary={colors.card} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(3)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.percent} />
                </ListItemAvatar>
                <ListItemText primary="Desconto" secondary={colors.percent} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(4)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productName}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Nome do produto"
                  secondary={colors.productName}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(5)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productPrice}
                  />
                </ListItemAvatar>
                <ListItemText primary="De" secondary={colors.productPrice} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(6)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productDiscountPrice}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Por"
                  secondary={colors.productDiscountPrice}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  }

  function stepFive() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAdd(true)}
        >
          Adicionar Produto
        </Button>
        <CustomTable
          data={products}
          pageSize={10}
          paging
          search
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
          columns={[
            {
              field: 'imagem_produto',
              title: 'Foto do produto',
              editComponent: props => (
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#e7e7e7',
                  }}
                >
                  <img
                    alt="product"
                    src={
                      props.value ||
                      'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{ width: 100, cursor: 'pointer' }}
                  />
                  <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      type="file"
                      onChange={async e => {
                        const imageEdit = await toBase64(e.target.files[0]);
                        props.onChange(imageEdit.base64);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button size="small" component="span">
                        Escolher foto
                      </Button>
                    </label>
                    <Button
                      size="small"
                      component="span"
                      onClick={() => {
                        setDadosModalSerpApi({
                          index: props.rowData.tableData.id,
                          nome_produto: props.rowData.nome_produto,
                          onChangeImage: image => props.onChange(image),
                        });
                        setOpenModalSerpApi(true);
                      }}
                    >
                      Buscar Imagem
                    </Button>
                  </div>
                </section>
              ),
              render: rowData => (
                <img
                  alt={rowData.imagem_produto}
                  src={rowData.imagem_produto}
                  style={{
                    width: 75,
                    height: 90,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7',
                  }}
                />
              ),
            },
            { title: 'EAN', field: 'ean' },
            { title: 'Código', field: 'id_produto' },
            { title: 'Produto', field: 'nome_produto' },
            {
              title: 'De',
              field: 'preco_original',
              render: rowData => (
                <span>{formatPrice(rowData.preco_original)}</span>
              ),
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  type="numeric"
                  size="small"
                  InputProps={{
                    style: { fontSize: 12 },
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              title: 'Por',
              field: 'preco_dois',
              render: rowData => (
                <span> {formatPrice(rowData.preco_dois)}</span>
              ),
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  type="numeric"
                  size="small"
                  InputProps={{
                    style: { fontSize: 12 },
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            { title: 'Gramatura', field: 'gramatura' },
            { title: 'Categoria', field: 'categoria' },
            { title: 'Observação', field: 'observacoes' },
            // {
            //   title: 'Mover',
            //   field: 'observacoes',
            //   render: rowData => (
            //     <div>
            //       <ArrowDropUpIcon
            //         style={{
            //           cursor: 'pointer'
            //         }}
            //       />
            //       <ArrowDropDownIcon
            //         style={{
            //           cursor: 'pointer'
            //         }}
            //       />
            //     </div>
            //   ),
            //   editComponent: props => (
            //     <div>
            //     </div>
            //   ),
            // },
          ]}
          deleteText="Deseja desativar esse produto para o tabloide ?"
          editable={{
            // onRowAdd: newData =>
            //   new Promise(resolve => {
            //     handleSubmitProduct({
            //       ...newData,
            //       id_tabloide_digital: tabloid.id_tabloide_digital,
            //     });
            //     forceUpdate();
            //     resolve();
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async resolve => {
                let new_image = '';
                let data = {
                  ...newData,
                  preco_original:
                    typeof newData.preco_original === 'string'
                      ? parseFloat(newData.preco_original.replace(',', '.'))
                      : newData.preco_original,
                  preco_dois:
                    typeof newData.preco_dois === 'string'
                      ? parseFloat(newData.preco_dois.replace(',', '.'))
                      : newData.preco_dois,
                };
                if (
                  Boolean(data.imagem_produto) &&
                  /;base64,/g.test(data.imagem_produto)
                ) {
                  try {
                    new_image = await props.postProdutoImagemTabloide({
                      ...data,
                      isBase64: true,
                    });
                  } catch (err) {
                    resolve();
                    return null;
                  }
                } else {
                  try {
                    new_image = await props.postProdutoImagemTabloide({
                      ...data,
                      isBase64: false,
                    });
                  } catch (err) {
                    resolve();
                    return null;
                  }
                }
                let new_image_object = new_image;
                new_image = new_image.image;
                let dateNow = new window.Date();
                dateNow = dateNow.getTime().toString();
                if (validURL(new_image))
                  new_image = new_image.split('?')[0] + '?' + dateNow;
                data = {
                  ...data,
                  imagem_produto: new_image,
                  imagem_produto_original_link:
                    new_image_object.imagem_produto_original_link,
                  imagem_produto_original_s3:
                    new_image_object.imagem_produto_original_s3,
                };
                const index = products.indexOf(oldData);
                products[index] = data;
                setProducts(products);
                handleEditProduct(data);
                forceUpdate();
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const index = products.indexOf(oldData);
                products.splice(index, 1);
                setProducts(products);
                handleEditProduct({ ...oldData, produto_ativo: 0 });
                forceUpdate();
                resolve();
              }),
          }}
        />
      </div>
    );
  }

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return stepOne();
      // case 1:
      //   return stepTwo();
      // case 2:
      //   return stepThree();
      // case 3:
      //   return stepFour();
      case 1:
        return stepFive();
      default:
        return stepOne();
    }
  }

  function getStepContentComplete() {
    switch (activeStep) {
      case 0:
        return stepOne();
      case 1:
        return stepThree();
      case 2:
        return stepFour();
      case 3:
        return stepTwo();
      default:
        return stepOne();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick
      >
        <AppBar className={classes.appBar} color="unset">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {name}
            </Typography>
            <IconButton
              edge="start"
              color="unset"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg">
          <Paper className={classes.paper}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {props.completeEdit ? getStepContentComplete() : getStepContent()}

            <div className={classes.buttons}>
              {activeStep !== 0 ? (
                <Button onClick={handleBack} className={classes.button}>
                  Anterior
                </Button>
              ) : (
                <div />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={
                  activeStep === steps.length - 1 ? handleSubmit : handleNext
                }
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
              </Button>
            </div>
          </Paper>
        </Container>
      </Dialog>
      {uploadBannerIsOpen && (
        <UploadBanner
          open={uploadBannerIsOpen}
          onSave={data => {
            setBanners(prevState => [...data, ...prevState]);
            setOpen(false);
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {adicionarProdutoIsOpen && (
        <AdicionarProduto
          open={adicionarProdutoIsOpen}
          onSave={data => {
            addProduto(data);
            setOpenAdd(false);
          }}
          onClose={() => {
            setOpenAdd(false);
            props.cleanProductsForAdd();
          }}
        />
      )}
      {openModalSerpApi && (
        <ModalSerpApi
          open={openModalSerpApi}
          onClose={() => {
            setOpenModalSerpApi(false);
          }}
          nome_produto={dadosModalSerpApi.nome_produto}
          onClickSelecionar={obj => {
            dadosModalSerpApi.onChangeImage(obj);
            setOpenModalSerpApi(false);
          }}
        ></ModalSerpApi>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  postProdutoImagemTabloide,
  cleanProductsForAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTabloid);
