import React, { useEffect } from 'react';
import { Divider, Typography, Grid } from '@material-ui/core';
import GraphComponent from '../GraphComponent';
import useStyles from '../../styles';
import { useHome } from '../../../../hooks/home';
import OverviewPost from './OverviewPost';
import { ReactComponent as HeartIcon } from '../../../../assets/icons/marketing/HeartIcon.svg';
import { ReactComponent as EyesIcon } from '../../../../assets/icons/marketing/EyesIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/marketing/PeopleIcon.svg';
import { ReactComponent as VectorIcon } from '../../../../assets/icons/marketing/VectorIcon.svg';

export default function Overview() {
  const classes = useStyles(),
    {
      graphsGeralLikesData,
      loadingGraphsGeralLikesData,
      getGraphsGeralLikesData,

      graphsGeralViewsData,
      loadingGraphsGeralViewsData,
      getGraphsGeralViewsData,

      graphsGeralEngajamentsData,
      loadingGraphsGeralEngajamentsData,
      getGraphsGeralEngajamentsData,

      graphsGeralImpressionsData,
      loadingGraphsGeralImpressionsData,
      getGraphsGeralImpressionsData,

      graphsGeralCommentsData,
      loadingGraphsGeralCommentsData,
      getGraphsGeralCommentsData,

      geralLikesCounterData,

      getGeralLikesCounterData,

      geralViewsCounterData,

      getGeralViewsCounterData,

      geralEngajamentsCounterData,

      getGeralEngajamentsCounterData,

      geralImpressionsCounterData,

      getGeralImpressionsCounterData,
    } = useHome();

  const getGeral = () => {
    getGeralLikesCounterData();
    getGeralViewsCounterData();
    getGeralEngajamentsCounterData();
    getGeralImpressionsCounterData();

    getGraphsGeralLikesData();
    getGraphsGeralViewsData();
    getGraphsGeralEngajamentsData();
    getGraphsGeralImpressionsData();
    getGraphsGeralCommentsData();
  };

  useEffect(() => {
    getGeral();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ padding: 10 }}>
          <Typography color="primary" className={classes.subtitle}>
            Visão Geral
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <OverviewPost
            icon={<PeopleIcon />}
            value={geralImpressionsCounterData}
            text1="Contas alcançadas"
          />
        </Grid>
        <Grid item xs={3}>
          <OverviewPost
            icon={<HeartIcon />}
            value={geralLikesCounterData}
            text1="Curtidas"
          />
        </Grid>
        <Grid item xs={3}>
          <OverviewPost
            icon={<EyesIcon />}
            value={geralViewsCounterData}
            text1="Visualizações"
          />
        </Grid>
        <Grid item xs={3}>
          <OverviewPost
            icon={<VectorIcon />}
            value={geralEngajamentsCounterData}
            text1="Engajamento"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="primary" className={classes.subtitle}>
            Métricas Gerais
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <GraphComponent
            text1={'Comentários'}
            text2={'Quantas pessoas seguem sua página'}
            array={graphsGeralCommentsData.map(v => ({
              label: v.month,
              value: v.total,
            }))}
            loading={loadingGraphsGeralCommentsData}
          />
        </Grid>

        <Grid item xs={4}>
          <GraphComponent
            text1={'Curtidas'}
            text2={'Quantas curtidas suas publicaçoes tiveram'}
            array={graphsGeralLikesData.map(v => ({
              label: v.month,
              value: v.total,
            }))}
            loading={loadingGraphsGeralLikesData}
          />
        </Grid>

        <Grid item xs={4}>
          <GraphComponent
            text1={'Contas alcançadas'}
            text2={'Quantas vezes sua publicação apareceu para alguém'}
            array={graphsGeralImpressionsData.map(v => ({
              label: v.month,
              value: v.total,
            }))}
            loading={loadingGraphsGeralImpressionsData}
          />
        </Grid>

        <Grid item xs={4}>
          <GraphComponent
            text1={'Engajamento'}
            text2={
              'Quantas pessoas clicaram nas suas propagandas impulsionadas'
            }
            array={graphsGeralEngajamentsData.map(v => ({
              label: v.month,
              value: v.total,
            }))}
            loading={loadingGraphsGeralEngajamentsData}
          />
        </Grid>

        <Grid item xs={4}>
          <GraphComponent
            text1={'Visualizações'}
            text2={'Quantidade de pessoas que viram suas publicações'}
            array={graphsGeralViewsData.map(v => ({
              label: v.month,
              value: v.total,
            }))}
            loading={loadingGraphsGeralViewsData}
          />
        </Grid>
      </Grid>
    </>
  );
}
