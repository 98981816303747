import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  footerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 1rem',
  },
  listColsImport: {
    display: 'grid',
    gridTemplateColumns: '30% 15% 30%',
    columnGap: '4%',
    justifyContent: 'center',
  },
  containerColsImport: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    height: '40vh',
  },
}));

export default useStyles;
