import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Typography,
  withStyles,
  Drawer,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
} from '@material-ui/core';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  HighlightOff as HighlightOffIcon,
  RefreshRounded as RefreshIcon,
} from '@material-ui/icons';
import {
  Chip,
  DatePicker,
  MyButton,
  Panel,
  Select,
  SplitButton,
} from '../../../utils/components';
import {
  COLOR_PRIMARY,
  STATUS_PEDIDO,
  TIPO_PEDIDO,
} from '../../../utils/helpers/Constants';
import MyDate from '../../../utils/helpers/Date';
import {
  _corStatusPedido,
  _statusPedido,
  _tipoPagamento,
  _tipoPedido,
} from '../../../utils/helpers/Functions';
import { CEP, CPF } from '../../../utils/helpers/Masks';
import {
  getLojas,
  getPedidosDelivery,
  getPedidosDeliveryPooling,
  putStatusPedido,
} from '../action/action';
import ModalAtualizaPedido from './ModalAtualizaPedido';
import ModalHistoricoPedido from './ModalHistoricoPedido';
import ModalPedidoProduto from './ModalPedidoProduto';
import FilterListIcon from '@material-ui/icons/FilterList';

const _myDate = new MyDate();

function Delivery(props) {
  const { classes } = props,
    [open, setOpen] = useState(false),
    [completed, setCompleted] = useState(0),
    [modalPedidoProduto, setModalPedido] = useState(false),
    [modalAtualizaPedido, setModalAtualizaPedido] = useState(false),
    [modalHistorico, setModalHistorico] = useState(false),
    [pedido, setPedido] = useState(null),
    [status, setStatusPedido] = useState(null),
    [tipo, setTipoPedido] = useState(null),
    [startDate, setStartDate] = useState(null),
    [endDate, setEndDate] = useState(null),
    [time, setTime] = useState(),
    [takeoutDate, setTakeoutDate] = useState(null),
    [loja, setLojaPedido] = useState(null),
    [tipo_status, setTipoStatus] = useState(null);

  useEffect(() => {
    props.getPedidosDelivery({ tabloid_id: props.offerId });
    props.getLojas();
  }, []);

  useEffect(() => {
    if (completed > 100) {
      deliveryPooling();
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCompleted(completed => completed + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function deliveryPooling() {
    setCompleted(0);
    const filtroPedido = {
      status_pedido: status,
      id_loja: loja,
      tipo_pedido: tipo,
      tabloid_id: props.offerId,
      data_inicio: startDate ? _myDate.getDate('YYYY-MM-DD', startDate) : null,
      data_fim: endDate ? _myDate.getDate('YYYY-MM-DD', endDate) : null,
      hora: time,
      data_retirada: takeoutDate
        ? _myDate.getDate('YYYY-MM-DD', takeoutDate)
        : null,
    };
    props.getPedidosDeliveryPooling(filtroPedido);
  }

  function filtroPedidos(
    id_loja,
    status_pedido,
    tipo_pedido,
    data_inicio,
    data_fim,
    time,
    data_retirada
  ) {
    const filtroPedido = {
      tabloid_id: props.offerId,
      status_pedido,
      id_loja,
      tipo_pedido,
      data_inicio: data_inicio
        ? _myDate.getDate('YYYY-MM-DD', data_inicio)
        : null,
      data_fim: data_fim ? _myDate.getDate('YYYY-MM-DD', data_fim) : null,
      hora: time,
      data_retirada: data_retirada
        ? _myDate.getDate('YYYY-MM-DD', data_retirada)
        : null,
    };

    props.getPedidosDelivery(filtroPedido);
  }

  function atualizaPedido(comentario) {
    const objPutPedido = {
      status_pedido: tipo_status.value,
      guid_pedido: pedido.guid_pedido,
      id_pedido: pedido.id_pedido,
      nome: pedido.nome,
      documento: pedido.documento,
      id_loja: pedido.id_loja,
      comentario,
    };
    props.putStatusPedido(objPutPedido);
    setModalAtualizaPedido(false);
  }

  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={classes.drawerContainer}>
          <Toolbar />
          <Grid container spacing={4}>
            <Grid item container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Filtro</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={12}>
                <Select
                  label="Lojas"
                  labelKey="nome_loja"
                  valueKey="id_loja"
                  options={
                    props.lojas
                      ? props.lojas.filter(loja => loja.tipo_pedido)
                      : []
                  }
                  onChange={loja => {
                    setLojaPedido(loja ? loja.id_loja : null);
                    filtroPedidos(
                      loja ? loja.id_loja : null,
                      status,
                      tipo,
                      startDate,
                      endDate,
                      time,
                      takeoutDate
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Status do pedido"
                  options={STATUS_PEDIDO}
                  onChange={status => {
                    setStatusPedido(status ? status.value : null);
                    filtroPedidos(
                      loja,
                      status ? status.value : null,
                      tipo,
                      startDate,
                      endDate,
                      time,
                      takeoutDate
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  label="Tipo do pedido"
                  options={TIPO_PEDIDO}
                  onChange={tipo => {
                    setTipoPedido(tipo ? tipo.value : null);
                    filtroPedidos(
                      loja,
                      status,
                      tipo ? tipo.value : null,
                      startDate,
                      endDate,
                      time,
                      takeoutDate
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="De"
                  format="DD/MM/YYYY"
                  disableFuture
                  withKeyboard
                  className={classes.dataPicker}
                  value={startDate}
                  onChange={date => {
                    setStartDate(date);
                    filtroPedidos(
                      loja,
                      status,
                      tipo,
                      date || null,
                      endDate,
                      time,
                      takeoutDate
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="Ate"
                  format="DD/MM/YYYY"
                  disableFuture
                  withKeyboard
                  className={classes.dataPicker}
                  value={endDate}
                  onChange={date => {
                    setEndDate(date);
                    filtroPedidos(
                      loja,
                      status,
                      tipo,
                      startDate,
                      date || null,
                      time,
                      takeoutDate
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="Data de retirada"
                  format="DD/MM/YYYY"
                  disableFuture
                  withKeyboard
                  className={classes.dataPicker}
                  value={takeoutDate}
                  onChange={date => {
                    setTakeoutDate(date);
                    filtroPedidos(
                      loja,
                      status,
                      tipo,
                      startDate,
                      endDate,
                      time,
                      date || null
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  menuPlacement="top"
                  label="Hora"
                  options={[
                    { label: '9:00', value: '9:00' },
                    { label: '10:00', value: '10:00' },
                    { label: '11:00', value: '11:00' },
                    { label: '12:00', value: '12:00' },
                    { label: '13:00', value: '13:00' },
                    { label: '14:00', value: '14:00' },
                    { label: '15:00', value: '15:00' },
                    { label: '16:00', value: '16:00' },
                    { label: '17:00', value: '17:00' },
                    { label: '18:00', value: '18:00' },
                    { label: '19:00', value: '19:00' },
                    { label: '20:00', value: '20:00' },
                    { label: '21:00', value: '21:00' },
                  ]}
                  onChange={hora => {
                    setTime(hora ? hora.value : null);
                    filtroPedidos(
                      loja,
                      status,
                      tipo,
                      startDate,
                      endDate,
                      hora ? hora.value : null,
                      takeoutDate
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Drawer>
      <Box height="30px" />
      <LinearProgress variant="determinate" value={completed} />
      <Box px={3} pb={3}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item container justify="space-between">
            <Grid item>
              <Box pt={4}>
                <Typography variant="h3" noWrap>
                  Pedidos
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box pt={4}>
                <Typography className={classes.label} variant="h6" noWrap>
                  Total de {props.pedidos ? props.pedidos.length : 0} pedidos
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end" spacing={2}>
            <Grid item>
              <Tooltip title="Atualizar">
                <IconButton onClick={() => deliveryPooling()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<FilterListIcon />}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Filtro
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          {props.loadingDelivery ? (
            <Grid item container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : props.pedidos && props.pedidos.length > 0 ? (
            <Grid item container spacing={2}>
              {props.pedidos.map(pedido => (
                <Grid item xs={12}>
                  <Panel
                    style={{ marginBottom: '2vh' }}
                    title={`${pedido.nome || 'Nome não informado'} - ${
                      pedido.documento
                        ? CPF(pedido.documento)
                        : 'Documento não informado'
                    }`}
                    subheader={
                      <span>
                        <strong>ID Pedido: {pedido.guid_pedido}</strong>
                        <br />
                        {pedido.nome_loja} -
                        {_myDate.getDate(
                          'DD/MM/YYYY HH:mm',
                          pedido.data_insert.replace('Z', '')
                        )}
                      </span>
                    }
                    action={
                      <Fragment>
                        <MyButton
                          size="large"
                          className={classes.button}
                          style={{
                            color: _corStatusPedido(pedido.status_pedido),
                          }}
                          variant="text"
                          color="primary"
                          disabled={!pedido.guid_pedido ? true : false}
                          onClick={() => {
                            setModalHistorico(true);
                            setPedido(pedido);
                          }}
                        >
                          Histórico
                        </MyButton>
                        <SplitButton
                          style={{
                            color: _corStatusPedido(pedido.status_pedido),
                          }}
                          text="Atualizar pedido"
                          label="label"
                        >
                          {STATUS_PEDIDO.map(tipo => (
                            <MenuItem
                              key={tipo.value}
                              style={{ borderRadius: 10, width: '100%' }}
                              onClick={() => {
                                setTipoStatus(tipo);
                                setPedido(pedido);
                                setModalAtualizaPedido(true);
                              }}
                            >
                              {tipo.label}
                            </MenuItem>
                          ))}
                        </SplitButton>
                        <MyButton
                          size="large"
                          className={classes.button}
                          style={{
                            color: _corStatusPedido(pedido.status_pedido),
                          }}
                          variant="text"
                          color="primary"
                          disabled={!pedido.guid_pedido ? true : false}
                          onClick={() => {
                            setModalPedido(true);
                            setPedido(pedido);
                          }}
                        >
                          Ver pedido
                        </MyButton>
                      </Fragment>
                    }
                  >
                    <Chip
                      color={_corStatusPedido(pedido.status_pedido)}
                      active
                      label={_statusPedido(pedido.status_pedido)}
                    />
                    &nbsp;
                    <Chip
                      color={_corStatusPedido(pedido.status_pedido)}
                      label={_tipoPedido(pedido.tipo_pedido)}
                    />
                    &nbsp;
                    <Chip
                      color={_corStatusPedido(pedido.status_pedido)}
                      label={_tipoPagamento(pedido.tipo_pagamento)}
                    />
                    {pedido.tipo_pedido === 1
                      ? `Endereço de entrega: ${
                          pedido.endereco ? `${pedido.endereco}, ` : ''
                        }${pedido.numero ? `${pedido.numero}, ` : ''}${
                          pedido.bairro ? `${pedido.bairro}, ` : ''
                        }${pedido.cidade ? `${pedido.cidade}, ` : ''}${
                          pedido.uf ? `- ${pedido.uf}, ` : ''
                        } ${pedido.cep ? `${CEP(pedido.cep)}, ` : ''}${
                          pedido.complemento ? `${pedido.complemento}, ` : ''
                        }`
                      : ''}
                  </Panel>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item>
              <center>
                <Typography className={classes.label} variant="h6" noWrap>
                  Nenhum pedido encontrado
                </Typography>
              </center>
            </Grid>
          )}
        </Grid>
      </Box>
      {modalHistorico && (
        <ModalHistoricoPedido
          open={modalHistorico}
          onClose={() => setModalHistorico(false)}
          pedido={pedido}
        />
      )}
      {modalAtualizaPedido && (
        <ModalAtualizaPedido
          open={modalAtualizaPedido}
          onClose={() => setModalAtualizaPedido(false)}
          status={tipo_status}
          pedido={pedido}
          onSave={comentario => atualizaPedido(comentario)}
        />
      )}
      {modalPedidoProduto && (
        <ModalPedidoProduto
          open={modalPedidoProduto}
          onClose={() => setModalPedido(false)}
          pedido={pedido}
        />
      )}
    </React.Fragment>
  );
}

const styles = theme => ({
  drawerContainer: {
    overflowX: 'hidden',
    padding: theme.spacing(2),
    minHeight: '100vh',
  },
  drawer: {
    flexShrink: 0,
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawerPaper: {
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer,
});

const mapDispatchToProps = {
  getLojas,
  getPedidosDelivery,
  putStatusPedido,
  getPedidosDeliveryPooling,
};

const DeliveryConn = connect(mapStateToProps, mapDispatchToProps)(Delivery);

export default withStyles(styles)(DeliveryConn);
