const INITIAL_STATE = {
  listFileImport: [],
  listHeaders: [],
  listDefaultHeaders: [],
  listTempCustomHeaders: [],
  attrFile: {
    link: '',
    file_name: '',
    type_import: '',
  },
  loadListFileImport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HEADER_FILE':
      const tempCustomList = action.payload.cols
        .filter(list => Boolean(list.checked))
        .map(list => ({
          label: list.label,
          column: list.column,
        }));
      return {
        ...state,
        attrFile: action.payload.attrFile,
        listHeaders: action.payload.cols,
        listDefaultHeaders: action.payload.fileCols,
        listTempCustomHeaders: tempCustomList,
      };

    case 'FILE_IMPORT':
      return {
        ...state,
        listFileImport: action.payload,
        loadListFileImport: false,
      };

    case 'LOAD_LIST_FILE':
      return {
        ...state,
        loadListFileImport: true,
      };

    default:
      return state;
  }
};
