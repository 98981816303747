/* eslint-disable */
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import '@webscopeio/react-textarea-autocomplete/style.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { CustomTable, Modal } from '../../../../../utils/components';
import { cleanProductsForAdd, getProduct, getProductPhoto } from '../../../action/index';

class ModalSearchProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      produto: {
        imagem_produto: null,
        ean: null,
        id_produto: null,
        nome_produto: null,
        preco_original: null,
        preco_dois: null,
        gramatura: null,
        categoria: null,
        observação: null
      },
      search_photo: false,
      search_product_add: false
    };
  }

  header(props, state) {
    return <Fragment>Pesquisa de Produtos</Fragment>;
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <div></div>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section
        style={{
          height: '100%'
        }}
      >
        <div
          style={{
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 700,
              justifyContent: 'center',
              margin: 'auto',
              flexWrap: 'wrap'
            }}
          >
            {(this.props.dataSearchProdutos.length > 0 || this.props.isLoadingSearchProdutos) && (
              <CustomTable
                data={this.props.dataSearchProdutos}
                title=""
                pageSize={10}
                paging
                isLoading={this.props.isLoadingSearchProdutos}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%'
                }}
                columns={[
                  {
                    field: 'LINK_S3',
                    Title: 'Foto',
                    render: rowData => (
                      <img
                        alt={rowData.LINK_S3}
                        crossOrigin="anonymous"
                        src={rowData.LINK_S3}
                        style={{
                          width: 75,
                          height: 75,
                          cursor: 'pointer',
                          backgroundColor: '#e7e7e7'
                        }}
                      />
                    )
                  },
                  {
                    title: 'EAN',
                    field: 'EAN'
                  },
                  {
                    title: 'PRODUTO',
                    field: 'produto'
                  },
                  {
                    title: '',
                    field: '',
                    render: rowData => (
                      <Button
                        size="small"
                        component="span"
                        onClick={() =>
                          this.props.onClickSelecionar({
                            ...rowData
                          })
                        }
                      >
                        Selecionar
                      </Button>
                    )
                  }
                ]}
              />
            )}
          </div>
        </div>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'md'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  gridList: {
    width: 500,
    height: 450
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  getProductPhoto,
  getProduct,
  cleanProductsForAdd
};

const ModalSearchProdutosConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSearchProdutos);

export default withStyles(styles)(ModalSearchProdutosConnect);
