import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    marginTop: theme.spacing(7)
  },
  paper: { borderRadius: '1rem', boxShadow: '0px 0px 10px 0px #0000001A !important' },
  unread: {
    backgroundColor: '#edefff !important',
    '&:hover': {
      backgroundColor: '#e1e4fc !important'
    }
  }
}));

export default useStyles;
