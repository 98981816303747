import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import FabookIcon from '@material-ui/icons/Facebook';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DialogTitle from '../../../../../../utils/components/DialogTitle';
import { AMBIENTE } from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';

const useStyles = makeStyles(theme => ({
  facebookButton: {
    backgroundColor: '#3b5998',
    color: '#fff'
  },
  logoutButton: {
    marginLeft: 15,
    backgroundColor: '#98332d',
    color: '#fff'
  }
}));

function CreateAccount({ open, onSubmit, onClose, initiateWithoutModal = false }) {
  const classes = useStyles();

  const [pages, setPages] = useState(null);
  const [userId, setUserId] = useState(null);
  const [withoutModal, setWithoutModal] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const hasPages = () => (pages && pages.length > 0 ? true : false);

  const checkIfThisIsNOTAnInstagramBusinessAccount = pageInfo =>
    pageInfo && pageInfo.isInstagramBusiness === 0;

  const isInstagramBusiness = pageInfo => {
    return checkIfThisIsNOTAnInstagramBusinessAccount(pageInfo) ? (
      <div
        style={{
          fontSize: '14px',
          backgroundColor: '#FFEDED',
          padding: '6px',
          color: '#B02F2F'
        }}
      >
        Este perfil de Instagram não é do tipo comercial
      </div>
    ) : null;
  };

  const createUser = () => {
    setLoadingFacebook(true);
    FB.login(
      function (resLogin) {
        console.log({ resLogin });
        FB.getLoginStatus(async resStatus => {
          console.log({ resStatus });
          if (resStatus.status === 'connected') {
            FB.api('/me', { fields: 'name, email, picture' }, async resMe => {
              console.log({ resMe });
              let createUserResponse;
              try {
                enqueueSnackbar('Enviando...', {
                  variant: 'info'
                });
                if (resStatus?.authResponse) {
                  createUserResponse = await api(
                    `https://api.datasales.info/social-networks-v2-${AMBIENTE}/users`,
                    {
                      fb_user_id: resStatus.authResponse.userID,
                      name: resMe.name,
                      email: resMe.email,
                      picture_url: resMe.picture.data.url,
                      access_token: resStatus.authResponse.accessToken,
                      expires_in: resStatus.authResponse.expiresIn,
                      data_access_expiration_time:
                        resStatus.authResponse.data_access_expiration_time,
                      is_connected: resStatus.status === 'connected'
                    },
                    'POST',
                    null,
                    null,
                    false
                  );
                }
                setUserId(resStatus.authResponse.userID);

                if (createUserResponse?.content?.pages) {
                  setPages(createUserResponse?.content?.pages);
                } else {
                  enqueueSnackbar('Nenhuma página encontrada!', {
                    variant: 'error'
                  });
                }
              } catch (err) {
                enqueueSnackbar(err.message, { variant: 'error' });
              } finally {
                setWithoutModal(false);
              }
            });
          } else if (resStatus.status === 'unknown') {
            enqueueSnackbar(
              'Por favor, verifique as configurações de segurança da sua conta no Facebook, incluindo a autenticação de dois fatores, e certifique-se de que não existam pendências na sua conta através do Meta Business Suite.',
              {
                variant: 'warning'
              }
            );
          }

          setLoadingFacebook(false);
        });
      },
      {
        config_id: '1034400440955581'
      }
    );
  };

  const updateUser = async () => {
    pages.forEach(async page => {
      if (page.checked) {
        try {
          if (page && userId) {
            enqueueSnackbar('Enviando...', {
              variant: 'info'
            });

            const response = await api(
              `https://api.datasales.info/social-networks-v2-${AMBIENTE}/pages`,
              {
                fb_user_id: userId,
                page_id: page.page_id,
                active: 1
              },
              'PUT',
              null,
              null,
              false
            );

            console.log({ response });

            onSubmit();
            enqueueSnackbar('Página salva com sucesso!', {
              variant: 'success'
            });
          } else {
            alert('Nenhum usuário ou página selecionado(a).');
          }
        } catch (err) {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
      }
    });
  };

  useEffect(() => {
    if (open && initiateWithoutModal) {
      createUser();
    }
  }, [open]);

  useEffect(() => {
    window.FB.getLoginStatus(function (response) {});
    setWithoutModal(initiateWithoutModal);
  }, []);

  return (
    <Dialog open={open && !withoutModal} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Adicionar perfil</DialogTitle>
      <Divider />
      <DialogContent>
        <div style={{ padding: 20 }}>
          {pages && pages.length > 0 && (
            <div style={{ marginTop: 20, marginBottom: 40 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ marginBottom: 20 }}>
                  Selecione a página para o perfil:
                </FormLabel>
                <FormGroup>
                  {pages.map((page, index) => (
                    <>
                      <FormControlLabel
                        // disabled={checkIfThisIsNOTAnInstagramBusinessAccount(page)}
                        control={
                          <Checkbox
                            // disabled={checkIfThisIsNOTAnInstagramBusinessAccount(page)}
                            checked={page.checked}
                            onChange={event => {
                              const tmp = pages;
                              tmp[index] = {
                                ...page,
                                checked: event.target.checked
                              };
                              setPages(tmp);
                            }}
                            name={page.page_name}
                          />
                        }
                        label={
                          page.page_name +
                          (page.instagram_username
                            ? ' (Instagram: ' + page.instagram_username + ')'
                            : '')
                        }
                      />

                      {isInstagramBusiness(page)}
                    </>
                  ))}
                </FormGroup>
              </FormControl>

              {pages.some(currentPage =>
                checkIfThisIsNOTAnInstagramBusinessAccount(currentPage)
              ) ? (
                <Alert severity="info" style={{ color: '#004EA9', marginTop: '24px' }}>
                  Sua conta do Instagram não é do tipo <strong>empresarial</strong>. Para fazer a
                  distribuição no Instagram, você precisa ter uma conta do tipo{' '}
                  <strong>empresarial</strong>.
                  <a href="https://www.youtube.com/watch?v=mPwXxoAkfsY"> Clique aqui</a> para
                  descobrir como.
                  <br />
                  Após isso, <strong>reconecte</strong> a sua conta do instagram a sua pagina do
                  facebook e logo em seguida refaça a sua distribuição, em caso de dúvidas acione o
                  suporte da Datasales.
                </Alert>
              ) : null}
            </div>
          )}

          {pages && pages.length === 0 && (
            <div style={{ marginBottom: 40, marginTop: 30 }}>Nenhuma página encontrada</div>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              className={classes.facebookButton}
              variant="contained"
              startIcon={<FabookIcon />}
              onClick={createUser}
            >
              {loadingFacebook
                ? 'Carregando...'
                : hasPages()
                ? 'Reconectar com o Facebook'
                : 'Conectar com o Facebook'}
            </Button>

            {pages && pages.length > 0 && (
              <Button variant="contained" color="primary" onClick={updateUser}>
                Salvar
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

CreateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default React.memo(CreateAccount);
