import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import ShowMore from '@tedconf/react-show-more';
import {
  Box,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  InputBase,
  CircularProgress,
} from '@material-ui/core';
import { Modal, MyButton } from '../../../utils/components';

import getInitials from '../../../utils/helpers/getInitials';

// Using Styles
import useStyles from '../styles';

export default function ModalContacts(props) {
  const classes = useStyles(),
    [contacts, setContacts] = useState(props.contacts);

  useEffect(() => {
    setContacts(props.contacts);
  }, [props.contacts]);

  function _filter({ target: { value } }) {
    if (Array.isArray(props.contacts) && props.contacts.length) {
      const filterContacts = props.contacts.filter(contact => {
        // const name = contact[props.labelKey] || contact.numero;
        return (
          contact[props.labelKey]?.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
          contact?.numero?.toUpperCase().indexOf(value.toUpperCase()) > -1
        );
      });
      if (props.onChange) {
        props.onChange(value, filterContacts);
      }
      setContacts(filterContacts);
    }
  }

  return (
    <Modal
      size="sm"
      header="Nova conversa"
      showModal={props.open}
      onClose={props.onClose}
      styleContent={{ paddingTop: 0 }}>
      <Paper elevation={3} className={classes.rootSearhContact}>
        <InputBase
          onChange={_filter}
          className={classes.inputSearhContact}
          placeholder="Pesquisar contatos"
        />
      </Paper>

      {Boolean(props.loading) ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <ShowMore by={5} items={_arrayListContacts(contacts, props.labelKey)}>
          {({ current, onMore }) => (
            <>
              {current.map((letter, keyLi) => (
                <li key={keyLi} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader className={classes.headerContact}>{letter[0]}</ListSubheader>
                    {letter[1].map((name, keyItem) => (
                      <ListItem button onClick={() => props.onSelect(name)} key={keyItem}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            {getInitials(name.nome_contato)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={name?.nome_contato} secondary={name?.numero} />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
              <MyButton
                fullWidth
                color="primary"
                variant={null}
                disabled={!onMore}
                onClick={() => {
                  if (!!onMore) onMore();
                }}>
                Mais
              </MyButton>
            </>
          )}
        </ShowMore>
      )}
    </Modal>
  );
}

function _arrayListContacts(contacts, key) {
  if (!contacts.length) return [];

  let newContacts = contacts.map(contact => ({
    ...contact,
    [key]: !Boolean(contact[key]) ? contact.numero : contact[key],
  }));
  newContacts = _.sortBy(newContacts, key);
  newContacts = _.groupBy(newContacts, contact => String(contact[key]).toUpperCase().charAt(0));
  newContacts = Object.entries(newContacts);
  return newContacts;
}
