/* eslint---disable */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fetch from '../../../../utils/helpers/Fetch';
import {
  AMBIENTE,
  DATASALES_CRM_API,
  COLOR_PRIMARY,
} from '../../../../utils/helpers/Constants';
import { KeyboardDatePicker } from '@material-ui/pickers';

function CalendarCreateProgrammed(props) {
  const [diasSemana, setDiasSemana] = useState({
    seg: {
      active: false,
      id_tabloide_digital: null,
      label: 'S',
    },
    ter: {
      active: false,
      id_tabloide_digital: null,
      label: 'T',
    },
    qua: {
      active: false,
      id_tabloide_digital: null,
      label: 'Q',
    },
    qui: {
      active: false,
      id_tabloide_digital: null,
      label: 'Q',
    },
    sex: {
      active: false,
      id_tabloide_digital: null,
      label: 'S',
    },
    sab: {
      active: false,
      id_tabloide_digital: null,
      label: 'S',
    },
    dom: {
      active: false,
      id_tabloide_digital: null,
      label: 'D',
    },
  });

  const [tabloidsBasic, setTabloidsBasic] = useState([]);

  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );

  useEffect(() => {
    async function getTabloidsBasic() {
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais?all=true`
        );
        setTabloidsBasic(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    getTabloidsBasic();
  }, []);

  const changeDiaSemanaActive = dia =>
    setDiasSemana({
      ...diasSemana,
      [dia]: {
        ...diasSemana[dia],
        active: !diasSemana[dia].active,
      },
    });

  return (
    <Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Planejamento</DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12}>
              {/* <TextField
                variant="outlined"
                fullWidth
                placeholder="Propaganda sem nome"
                value={this.state.nome}
                onChange={e => this.setState({ nome: e.target.value })}
              /> */}

              {Object.keys(diasSemana).map(
                value =>
                  diasSemana[value].active && (
                    <div style={{ margin: 10, marginBottom: 20 }}>
                      <div style={{ textTransform: 'capitalize' }}>{value}</div>
                      <Autocomplete
                        getOptionSelected={(option, value) =>
                          option.id_tabloide_digital ===
                          value.id_tabloide_digital
                        }
                        getOptionLabel={option =>
                          //(!option.id_empresa ? <b>{'Pública - '}</b> : '') +
                          option.nome_tabloide_digital
                        }
                        options={tabloidsBasic}
                        loading={tabloidsBasic.length === 0}
                        value={diasSemana[value]}
                        onChange={(event, newValue) =>
                          setDiasSemana({
                            ...diasSemana,
                            [value]: {
                              ...diasSemana[value],
                              ...newValue,
                            },
                          })
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Selecione modelo pronto para importação"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {tabloidsBasic.length === 0 ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  )
              )}

              <div style={{ margin: 10 }}>
                {Object.keys(diasSemana).map(value => (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{
                      margin: 3,
                      padding: '10px 20px 10px 20px',
                      ...(() =>
                        diasSemana[value].active
                          ? {
                              backgroundColor: COLOR_PRIMARY,
                              color: 'white',
                            }
                          : {})(),
                    }}
                    onClick={() => changeDiaSemanaActive(value)}
                  >
                    {diasSemana[value].label}
                  </IconButton>
                ))}
              </div>

              <div style={{ margin: 10 }}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="De"
                  format="DD/MM/YYYY"
                  value={dataInicio}
                  onChange={date => setDataInicio(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
              <div style={{ margin: 10 }}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Até"
                  format="DD/MM/YYYY"
                  value={dataFinal}
                  onChange={date => setDataFinal(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancelar</Button>
          <Button
            color="primary"
            onClick={() => {
              let diasSemana_tmp = {};
              for (var x = 0; x < Object.keys(diasSemana).length; x++) {
                let tmp = Object.keys(diasSemana)[x];
                if (
                  diasSemana[tmp].active &&
                  diasSemana[tmp].id_tabloide_digital
                ) {
                  diasSemana_tmp[tmp] = { ...diasSemana[tmp] };
                }
              }

              props.onSave({
                dataInicio,
                dataFinal,
                diasSemana: diasSemana_tmp,
              });
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarCreateProgrammed);
