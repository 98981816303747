import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  active: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  inactive: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

export default useStyles;
