import {
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Tab,
  Tabs,
  Typography,
  withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Modal, MyButton, Select, TextField } from '../../../utils/components';
import TabPanel from '../../../utils/components/TabPanel';
import { AMBIENTE, COLOR_PRIMARY, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import api from '../../../utils/helpers/Fetch';
import { inputMoney, toBase64 } from '../../../utils/helpers/Functions';
import { getCategoriasDelivery } from '../action/action';

function ModalCriarProduto(props) {
  const { classes } = props;
  const [disableCategoria, setDisableCategoria] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [query, setQuery] = useState('');
  const [images, setImages] = useState();

  const search = async q => {
    try {
      const response = await api(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getSerpApi`, {
        query: q
      });

      const { images_results } = response;

      setImages(images_results);
    } catch (err) {
      console.log(err);
    }
  };

  function _disableButton() {
    const {
      nome_produto,
      loja_produto,
      categoria_produto,
      unid_medida_produto,
      medida_produto,
      preco_produto,
      limite_produto,
      ean_produto,
      imagem_produto
    } = props.produtoDelivery;
    if (
      nome_produto &&
      loja_produto &&
      categoria_produto &&
      unid_medida_produto &&
      medida_produto &&
      preco_produto &&
      limite_produto &&
      ean_produto &&
      imagem_produto
    ) {
      return false;
    }
    return true;
  }

  function footer() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <MyButton size="large" color="primary" variant="text" onClick={props.onClose}>
          Fechar
        </MyButton>

        <MyButton
          disabled={_disableButton()}
          onClick={props.onSave}
          size="large"
          color="primary"
          variant="text"
        >
          Salvar
        </MyButton>
      </div>
    );
  }

  return (
    <Modal
      size="lg"
      showModal={props.open}
      onClose={props.onClose}
      header="Criar produto"
      footer={footer()}
    >
      <Divider className={classes.divider} />
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Informações do produto
        </Typography>
        <TextField
          label="Nome do produto"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('produtoDelivery', 'nome_produto', value)
          }
          value={props.produtoDelivery.nome_produto}
        />
        <Select
          onChange={loja => {
            if (loja) {
              props.onChange('produtoDelivery', 'loja_produto', loja);
              props.getCategoriasDelivery(loja.id_loja);
              setDisableCategoria(false);
            } else {
              setDisableCategoria(true);
            }
          }}
          value={props.produtoDelivery.loja_produto}
          options={props.lojas ? props.lojas.filter(loja => loja.tipo_pedido) : []}
          labelKey="nome_loja"
          valueKey="id_loja"
          label="Lojas"
        />
        <Select
          onChange={value => props.onChange('produtoDelivery', 'categoria_produto', value)}
          value={props.produtoDelivery.categoria_produto}
          label="Categorias"
          labelKey="nome_categoria"
          valueKey="guid_categoria"
          disabled={disableCategoria}
          options={props.categorias}
        />
        <Select
          onChange={value => props.onChange('produtoDelivery', 'unid_medida_produto', value)}
          value={props.produtoDelivery.unid_medida_produto}
          label="Unidade de medida"
          options={[
            { label: 'Unidade', value: 'UN' },
            { label: 'Kilo', value: 'KG' }
          ]}
        />
        <TextField
          label="Gramatura"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('produtoDelivery', 'medida_produto', value)
          }
          value={props.produtoDelivery.medida_produto}
        />

        <TextField
          label="Preço do produto"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('produtoDelivery', 'preco_produto', inputMoney(value))
          }
          value={props.produtoDelivery.preco_produto}
        />
        <TextField
          type="number"
          label="Limite de compra"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('produtoDelivery', 'limite_produto', value)
          }
          value={props.produtoDelivery.limite_produto}
        />
        <TextField
          label="EAN"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange('produtoDelivery', 'ean_produto', value)
          }
          value={props.produtoDelivery.ean_produto}
        />
        <Divider className={classes.divider} />
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Imagem do produto
        </Typography>

        <Tabs
          value={valueTab}
          onChange={(e, value) => setValueTab(value)}
          className={classes.root}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Pesquisar imagem" />
          <Tab label="Pesquisar google imagens" />
          <Tab label="Link da imagem" />
          <Tab label="Upload" />
        </Tabs>
        <TabPanel value={valueTab} index={0}>
          <Select
            label="Pesquisar imagem"
            placeholder="EAN..."
            options={props.imagemProduto}
            labelKey="EAN"
            valueKey="LINK_S3"
            onChange={value => props.onChange('produtoDelivery', 'imagem_produto', value.LINK_S3)}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          s
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiTextField
                label="Pesquisar"
                fullWidth
                variant="outlined"
                onChange={event => {
                  setQuery(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          search(query);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item container spacing={2}>
              {images &&
                images.map(image => (
                  <Grid item xs={4}>
                    <ButtonBase
                      onClick={async () => {
                        props.onChange('produtoDelivery', 'imagem_produto', image.thumbnail);
                      }}
                    >
                      <img
                        className={classes.preview}
                        crossOrigin="anonymous"
                        alt={image.title}
                        src={image.thumbnail}
                      />
                    </ButtonBase>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <TextField
            label="Link da imagem"
            className="form-control"
            autoComplete="none"
            onChange={async ({ target: { value } }) => {
              if (value.length > 0) {
                const imagem = await toBase64(value);
                props.onChange('produtoDelivery', 'imagem_produto', imagem);
              }
            }}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={3}>
          <section style={{ display: 'flex', justifyContent: 'center' }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={async e => {
                if (typeof e.target.files[0] !== 'undefined') {
                  const imagem = await toBase64(e.target.files[0]);
                  props.onChange('produtoDelivery', 'imagem_produto', imagem);
                }
              }}
            />
            <label htmlFor="contained-button-file">
              <MyButton size="large" component="span" color="primary" variant="text">
                Upload da imagem
              </MyButton>
            </label>
          </section>
        </TabPanel>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            alt={props.produtoDelivery.imagem_produto.name}
            src={
              props.produtoDelivery.imagem_produto.base64 || props.produtoDelivery.imagem_produto
            }
            crossOrigin="anonymous"
            style={{ width: 300, height: 300 }}
          />
        </div>
      </section>
    </Modal>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2)
  },
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3)
  },

  preview: {
    height: 200,
    width: 200,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider
  }
});

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer
});

const mapDispatchToProps = { getCategoriasDelivery };

const ConnModalCriarProduto = connect(mapStateToProps, mapDispatchToProps)(ModalCriarProduto);

export default withStyles(styles)(ConnModalCriarProduto);
