import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import COLORS from './colors';
import './style.css';

export default function MyBarChart({
  data,
  xDataKey,
  hide,
  payload,
  tooltipFormatter,
  barDataKey,
  stacked,
  width,
  barLegend,
  noTooltip
}) {
  const [newData, setNewData] = useState([]),
    [chartData, setChartData] = useState(data),
    [activeLegend, setActiveLegend] = useState([]),
    [disabledLegend, setDisabledLegend] = useState([]);

  useEffect(() => {
    if (barLegend) {
      let tempData = [];

      tempData = data?.map((dt, index) => ({
        ...dt,
        id: index,
        [xDataKey]: dt[xDataKey],
        [barDataKey]: dt[barDataKey],
        color: COLORS[index % COLORS.length]
      }));

      setNewData(tempData);
      setActiveLegend(tempData);
      setChartData(tempData);
    }
  }, [data]);

  function _filterChart(id) {
    let _disabled = [];
    if (disabledLegend.includes(id)) {
      _disabled = disabledLegend.filter(idDisabled => idDisabled !== id);
    } else {
      _disabled = disabledLegend.concat(id);
    }
    const newChartData = newData.filter(dt => !_disabled.includes(dt.id));
    setChartData(newChartData);
    setDisabledLegend(_disabled);
  }

  function LegendComponent({ payload }) {
    return (
      <div className="containerLegend">
        {payload.map(entry => {
          const { id, color } = entry,
            active = disabledLegend.includes(id),
            style = { color: active ? '#AAA' : '#000' };

          return (
            <span className="textLegend" style={style} onClick={() => _filterChart(id)}>
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && <Symbols cx={5} cy={5} type="circle" size={25} fill={'#FFF'} />}
              </Surface>
              <span>{entry[xDataKey]}</span>
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div className="container">
      <ResponsiveContainer height="100%" width={width}>
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} hide={hide} />
          <YAxis />
          {!noTooltip && (
            <Tooltip
              payload={payload}
              contentStyle={{ borderRadius: 15 }}
              formatter={(value, name) => [value, tooltipFormatter ? tooltipFormatter : name]}
            />
          )}
          <Legend verticalAlign="bottom" payload={activeLegend} content={LegendComponent} />

          {stacked ? (
            stacked.map((stack, index) => (
              <Bar
                key={index}
                radius={index === stacked.length - 1 ? [5, 5, 0, 0] : false}
                stackId="a"
                dataKey={stack}
              >
                {chartData?.map((dt, index) => (
                  <Cell key={index} fill={dt.color} />
                ))}
              </Bar>
            ))
          ) : (
            <Bar dataKey={barDataKey} radius={[5, 5, 0, 0]}>
              {chartData?.map((dt, index) => (
                <Cell key={index} fill={dt.color} />
              ))}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
