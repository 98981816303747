import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fade,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LaunchIcon from '@material-ui/icons/Launch';
//import SettingsIcon from '@material-ui/icons/Settings';
//import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
//import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from 'axios';
//import axios from 'axios';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
//import { toast } from 'react-toastify';
import _ from 'underscore';
import { AlertDialogV2 } from 'utils/components/AlertDialogV2/index';
import { toBase64 } from 'utils/helpers/Functions';

import { GeneratePixelixe, PixelixeIframe } from '../';
import { TabloidsContext } from '../../../../hooks/tabloids';
import { DatePicker, DialogAlert, SplitButton } from '../../../../utils/components';
import DialogTitle from '../../../../utils/components/DialogTitle';
import _Alert from '../../../../utils/helpers/Alert';
//import Alert from '../../../../utils/helpers/Alert';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import DSAWS from '../../../../utils/helpers/DSAWS';
import api from '../../../../utils/helpers/Fetch';
import Fetch from '../../../../utils/helpers/Fetch';
import makeId from '../../../../utils/helpers/makeId';
import CreateArt from '../../../TabloideDigitalV3/components/CreateArt';
import { ReadyModels } from '../../../TabloideDigitalV3/components/ReadyModels/';
import {
  changeCategoriaIndex,
  changeCategoriaIndexSubtract,
  clearSelectedScheduledItems,
  clearSelectedShareableItem,
  createTemplatePersonalizado,
  deleteEncarteFisico,
  getOfertaFisicoAll,
  getVideomatik,
  putOfertaFisico,
  setFlyersToSelectWhenRedirectToOffer,
  setSelectedShareableItem,
  setTabloide
} from '../../action/index';
import { AdsDistribution } from '../AdsDistribution';
import AppDistribution from '../AppDistribution';
import BannerDistribution from '../BannerDistribution';
import Canva from '../Canva';
import CreateAds from '../CreateAds';
import CreateFlyer from '../CreateFlyer';
import FormatPage from '../CreateFlyer/FormatPage/index';
import CreateVideo from '../CreateVideo';
import Distribute from '../Distribute';
import DownloadImage from '../DownloadImage';
import DownloadVideo from '../DownloadVideo';
import EditFlyer from '../EditFlyer';
import GenerateMenu from '../Generate/menu.jsx';
import ModalCriar from '../GeneratePixelixeDetalhe/modalCriar';
import ImportImage from '../ImportImage';
import PolotnoEditor from '../PolotnoEditor';
import PushNotificationDistribution from '../PushNotificationDistribution';
import Sales from '../Sales';
import Statistics from '../Statistics';
import DialogWarningVideoInPdf from './components/DialogWarningVideoInPdf';
import MagnumGrid from './components/MagnumGrid';
import MeuModeloNameDialog from './components/MeuModeloNameDialog';
import ProductsCarousel from './components/ProductsCarousel';
import ProductsFooter from './components/ProductsFooter';

const _alert = new _Alert();

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?', // query string
    // '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

function capitalizeFirstLetter(string) {
  if (typeof string === 'string' || string instanceof String) {
    return string.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  }
  return string;
}

function getImageDimensions(file) {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
}

function SelectAll({ selectedShareableItem, setSelectedShareableItem, flyers_key }) {
  const [selected, setSelected] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          if (!selected) {
            const tmp = [
              ...selectedShareableItem,
              ...flyers_key.filter(itemFlyer => !itemFlyer.disabled)
            ];

            const arrayUniqueByKey = [...new Map(tmp.map(item => [item['id'], item])).values()];

            setSelectedShareableItem(arrayUniqueByKey);
          } else {
            var tmp = selectedShareableItem.filter(function (objFromA) {
              return !flyers_key.find(function (objFromB) {
                return objFromA.id === objFromB.id;
              });
            });

            const arrayUniqueByKey = [...new Map(tmp.map(item => [item['id'], item])).values()];

            setSelectedShareableItem(arrayUniqueByKey);
          }

          setSelected(prev => !prev);
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: 6,
            margin: '0px 4px 0px 16px',
            ...(selected
              ? { backgroundColor: 'rgb(40, 19, 173)' }
              : { border: '2.5px solid rgb(40, 19, 173)' }),
            fontFamily: "'Montserrat', sans-serif",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CheckIcon style={{ color: 'white' }} />
        </div>
        Selecionar todos
      </div>
    </>
  );
}

class ListaOfertasEncarte extends Component {
  static contextType = TabloidsContext;

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      routeHistory: null,
      tipoPixelixe: null,
      generatePixelixe: false,
      pixelixeIframe: false,
      openDetalhe: false,
      openEditJson: false,
      itemSelected: [],
      nome_tabloide: null,
      download: false,
      expanded: {},
      showDialog: false,
      deleteId: null,
      progressDownload: null,
      editTabloidIsOpen: false,
      productListIsOpen: false,
      editIsComplete: false,
      anchorEl: null,
      downloadAnchorEl: null,
      open: this.props.downloadAnchorEl,
      linkAnchorEl: null,
      videoAnchorEl: null,
      imgSelected: undefined,
      keySelected: null,
      carouselSelection: {
        selectionType: 'post',
        selectedItems: []
      },
      pixelixeIframe_open: false,
      downloadImgIsOpen: false,
      nome: this.props.tabloid.nome_tabloide_digital,
      data_inicio: moment(this.props.tabloid.data_inicio),
      data_fim: moment(this.props.tabloid.data_fim),
      stores: [],
      createPostIsOpen: false,
      createAdsIsOpen: false,
      distributeIsOpen: false,
      multipleDistributionIsOpen: false,
      generateIsOpen: false,
      onCreateNovoModelo: null,
      //REFERNTE A CADASTRO DE MEUS MODELOS
      nomeModelo: null,
      ocasiaoModelo: 'Meus Modelos',
      qtdeModelo: null,
      //
      openCriarTemplate: false,
      tabValue: 0,
      statisticsIsOpen: false,
      salesIsOpen: false,
      canvaIsOpen: false,
      openCanvaFromStudio: false,
      designCanvaFromStudio: null,
      importImageIsOpen: false,
      importType: null,
      appDistribution: false,
      bannerDistribution: false,
      pushNotificationDistribution: false,
      polotnoIsOpen: false,
      videosIsOpen: false,
      createFlyerIsOpen: false,
      modelo_pronto: false,
      newReadyModels: false,
      editFlyerIsOpen: false,
      //
      videomatikTable: [],
      templatesVideomatik: [],
      loadingVideomatik: false,
      downloadVidIsOpen: false,
      vidSelected: undefined,
      flyers: [],
      loadingFlyers: false,
      openDialogMeuModeloName: false,
      //
      showDialogDesativarNovoEstudio: false,
      //
      productSelected: {},
      commandToAddProduct: '',
      dialogPDF: false,
      //
      openCreateFlyer: false,
      duplicateTabloid: false
    };
    this.myRef = React.createRef();
  }

  hasCarouselCardsSelected = () =>
    !!this.props.selectedShareableItem && this.props.selectedShareableItem.length > 0
      ? true
      : false;

  handleTabChange = (_, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleExpandClick = j => {
    let tmp = this.state.expanded;
    tmp[j] = !tmp[j];
    this.setState({ expanded: tmp });
  };

  async getStores() {
    try {
      const response = await Fetch(
        `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
      );

      if (this.props.tabloid.storeId) {
        const indexStore = response.findIndex(element => {
          return element.id_loja === this.props.tabloid.storeId;
        });
        if (indexStore >= 0) this.setState({ store: response[indexStore] });
      }

      this.setState({ stores: response });
    } catch (err) {
      console.error(err);
    }
  }
  async getEncartesNovoEstudio(id) {
    try {
      this.setState({
        loadingFlyers: true
      });

      const response = await Fetch(`${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`, {
        offer_id: id
      });
      const content = response.content.map(flyer => ({
        ...flyer,
        disabled: false
      }));

      this.setState({
        flyers: _.groupBy(content, flyer => capitalizeFirstLetter(flyer.type))
      });

      this.select_flyersToSelectWhenRedirectToOffer();
    } catch (err) {
      console.log(err);
    }

    this.setState({
      loadingFlyers: false
    });
  }

  async saveCopyImgAws(data) {
    if (!data) return;

    const newData = await toBase64(data, { noHeaders: true });
    const splitName = newData.name.split('.'),
      objFileTemp = {
        file: newData.blob,
        name: `${splitName[0]}-${new Date().getTime()}`,
        type: newData.type,
        bucketName: 'datasalesio-imagens',
        folderName: ``,
        subFolderName: ``,
        extension: splitName[1],
        distinctName: false
      };

    const { Location } = await DSAWS.saveS3(objFileTemp);
    return Location;
  }

  async handleDuplicateEncarte(data) {
    try {
      let imgUrl = await this.saveCopyImgAws(data.image_url);
      let jsonUrl = await this.saveCopyImgAws(data.json_url);
      let previewUrl = await this.saveCopyImgAws(data.preview_url);

      const response = await api(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`,
        {
          offer_id: data.offer_id,
          name: data.name,
          format_id: data.format_id,
          template_id: data.template_id,
          product_quantity: data.product_quantity,
          type: data.type,
          image_url: imgUrl,
          json_url: jsonUrl,
          preview_url: previewUrl,
          is_active: data.is_active,
          disabled: data.disabled
        },
        'POST',
        undefined,
        undefined,
        false
      );

      this.props.enqueueSnackbar('Arte duplicada com sucesso', {
        variant: 'success'
      });

      return response;
    } catch (err) {
      console.log(err);
      const message = err.message || 'Erro ao duplicar arte';
      this.props.enqueueSnackbar(message, { variant: 'error' });
    }
  }
  async updateEncartesNovoEstudio(data) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers/${data.id}`,
        {
          is_active: 0
        },
        'PUT',
        null,
        null,
        false
      );

      this.props.enqueueSnackbar('Arte deletada!', {
        variant: 'success'
      });

      this.getEncartesNovoEstudio(this.state.id);
    } catch (err) {
      console.log(err);
    }
  }

  async onDisableManyImages(flyers) {
    try {
      const flyers_ids = flyers.map(flyer => flyer.id);

      await Fetch(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers/disable`,
        {
          flyers: flyers_ids
        },
        'PUT',
        null,
        null,
        false
      );

      this.getEncartesNovoEstudio(this.state.id);
    } catch (err) {
      console.log(err);
    }
  }

  async onDisableManyVideos(videos) {
    try {
      const videos_ids = videos.map(video => video.id);

      await Fetch(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/videos/disable`,
        {
          videos: videos_ids
        },
        'PUT',
        null,
        null,
        false
      );

      await this.onRefreshVideomatik(this.state.id, true);
      this.setState({ loadingVideomatik: false });
    } catch (err) {
      console.log(err);
    }
  }

  select_flyersToSelectWhenRedirectToOffer() {
    const flyersToSelectWhenRedirectToOffer = this.props.flyersToSelectWhenRedirectToOffer;
    let continueAfterSelect = false;

    for (let categoryTypeFlyer in this.state.flyers) {
      const tmpFlyers = this.state.flyers[categoryTypeFlyer].filter(item =>
        flyersToSelectWhenRedirectToOffer?.flyersIds?.includes(item.id)
      );
      if (tmpFlyers?.length > 0) {
        continueAfterSelect = true;
      }
      this.props.setSelectedShareableItem([...this.props.selectedShareableItem, ...tmpFlyers]);
    }

    if (continueAfterSelect) {
      this.props.setFlyersToSelectWhenRedirectToOffer({
        ...this.props.flyersToSelectWhenRedirectToOffer,
        readyToDistribute: true
      });
    }
  }

  componentDidMount() {
    // this.props.onOpen();
    const id = this.props.tabloid.id_tabloide_digital;
    this.setState({ id: id });

    this.getEncartesNovoEstudio(id);

    this.props.getOfertaFisicoAll({
      id: id,
      all: true
    });

    this.getStores();

    this.setNewInterval(id);

    this.props.setTabloide(this.props.tabloid);

    //Videomatik
    const initVideomatik = async () => {
      await this.onRefreshVideomatik(id, true);

      this.intervalVideomatik = setInterval(() => {
        let array = this.state.videomatikTable.filter(value => !value.link);
        if (array.length > 0) {
          this.onRefreshVideomatik(id, false);
        }
      }, 30000);
    };
    initVideomatik();

    const createArtFormat = queryString.parse(location?.search)?.createArtFormat;
    if (createArtFormat) {
      if (createArtFormat === 'Modelo Pronto') {
        this.setState({
          modelo_pronto: true,
          createFlyerIsOpen: {
            modelo_pronto: true,
            type: 'Modelo Pronto',
            open: false
          }
        });
      }
      if (createArtFormat === 'upload') {
        this.setState({
          importImageIsOpen: true,
          importType: 'upload'
        });
      }
      if (createArtFormat === 'Manual') {
        this.setState({
          createFlyerIsOpen: { open: true, isManual: true, type: 'Manual' }
        });
      }
      if (createArtFormat !== 'upload') {
        this.setState({ createFlyerIsOpen: { open: true, type: createArtFormat } });
      }
      this.props.history.replace({
        search: null
      });
    }
    const createFormatCanva = queryString.parse(location?.search)?.createFormatCanva;
    if (createFormatCanva) {
      this.setState({
        canvaIsOpen: true,
        openCanvaFromStudio: true,
        designCanvaFromStudio: createFormatCanva
      });
    }
  }

  async onRefreshVideomatik(id, loading) {
    this.setState({ loadingVideomatik: loading });
    const videomatikTable = await this.props.getVideomatik({ id_tabloide: id });

    this.setState({
      videomatikTable: videomatikTable.response,
      loadingVideomatik: false,
      templatesVideomatik: videomatikTable.templates
    });
  }

  async handleDisableItems(items) {
    const countSelectedItems = this.props.selectedShareableItem.length;

    if (countSelectedItems > 0) {
      const message = `Deseja realmente desativar a(s) arte(s) selecionada(s)?`;
      const flyers = [];
      const videos = [];

      _alert.alertConfirm(message, async () => {
        this.props.enqueueSnackbar('Processando...', {
          variant: 'info'
        });

        items.forEach(item => {
          if (item.link) {
            videos.push(item);
          } else {
            flyers.push(item);
          }
        });

        console.log('videos', videos);
        console.log('images', flyers);

        console.log(videos.length > 0);
        console.log(flyers.length > 0);

        if (videos.length > 0) await this.onDisableManyVideos(videos);
        if (flyers.length > 0) await this.onDisableManyImages(flyers);

        this.props.enqueueSnackbar('Arte(s) deletada(s)!', {
          variant: 'success'
        });
      });
    }
  }

  setNewInterval(id) {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      let array = this.props.paginas_fisico_all.response.filter(
        value => (!value.link && !value.error) || (!value.link_html && !value.error_html)
      );
      if (array.length > 0) {
        this.props.getOfertaFisicoAll(
          {
            id: id,
            all: true
          },
          false
        );
      }
    }, 10000);
  }

  componentWillMount() {
    this.props.clearSelectedShareableItem();
    this.props.clearSelectedScheduledItems();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalVideomatik);
  }

  onRefresh() {
    this.props.getOfertaFisicoAll({
      id: this.state.id,
      all: true
    });
  }

  onRefreshSilence() {
    this.props.getOfertaFisicoAll(
      {
        id: this.state.id,
        all: true
      },
      false
    );
  }

  handleDownloadImage = async (imageUrl, imageName) => {
    this.setState({ anchorEl: null });

    let imageBlob = await toBase64(imageUrl, { noHeaders: true });
    // imageBlob.base64

    // DSAWS.getObjectGeral({
    //   link: imageUrl
    // }).then(imageBlob => {});

    try {
      var link = document.createElement('a');
      link.href = imageBlob.base64;
      link.download = `${imageName}.jpeg`;

      document.body.appendChild(link);

      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(link);
      }, 200);
    } catch (error) {
      console.error('--»» Deu Pau no Download da Imagem: ', error);
    }
  };

  handleDownloadZip = async filesDownload => {
    let key = '';
    if (filesDownload) {
      key = 'ZIP';
    } else {
      key = this.state.keySelected;
      filesDownload = this.state.flyers[key];
    }
    this.setState({
      [`download_${key}`]: true,
      progressDownload: 1
    });

    const imageFile = [];
    for (let i = 0; i < filesDownload.length; i++) {
      let link = filesDownload[i].image_url;
      if (validURL(link)) {
        //let imageBlob = await toBase64(link);
        // let imageBlob = await DSAWS.getObjectGeral({
        //   link
        // });

        let imageBlob = await toBase64(link, { noHeaders: true });
        // imageBlob.base64

        imageFile.push({
          name: i + '.' + 'jpg', //link.split('.')[link.split('.').length - 1]
          base64: imageBlob.base64
        });
      }

      this.setState({
        progressDownload: ((i + 1) / filesDownload.length) * 100
      });
    }

    let zip = new JSZip();
    let folder = zip.folder('collection');
    imageFile.forEach((image, index) =>
      folder.file(image.name, image.base64.split(',')[1], {
        base64: true
      })
    );
    folder.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'files'));

    this.setState({
      [`download_${key}`]: false,
      progressDownload: null
    });
  };

  toggleDialogPDF = () => {
    console.log('dialogPDF', this.state.dialogPDF);
    this.setState({ dialogPDF: !this.state.dialogPDF });
  };

  /**
   *
   * @param {*} isDownloadSelected
   *
   * Se TRUE, a função entende que deve baixar todas as artes selecionadas e
   * puxa as artes da variável 'this.props.selectedShareableItem'
   *
   * Se FALSE, a função entende que deve baixar todas as artes de um
   * determinado tipo e puxa as artes da variável 'this.state.flyers[key]'
   */
  normalizeArrayPDF = (isDownloadSelected, hasTrimArea, isPrinter) => {
    let key = this.state.keySelected;

    const arrayToPDF =
      isDownloadSelected && !!this.props.selectedShareableItem.length
        ? this.props.selectedShareableItem
        : this.state.flyers[key];

    const hasVideo = arrayToPDF.find(item => item.id_videomatik);
    const numberVideos = arrayToPDF.filter(item => item.id_videomatik);

    if (!!hasVideo) {
      if (arrayToPDF.length === numberVideos.length) {
        const message = 'Para baixar vídeos, selecione apenas 1 por vez e clique na opção ".mp4".';

        _alert.alertWarning('Atenção!', message);
      } else {
        this.toggleDialogPDF();
      }
    } else {
      this.handleDownloadPdf(arrayToPDF, hasTrimArea, isPrinter);
    }
  };

  handleDownloadPdf = async (arrayToPDF, hasTrimArea, isPrinter = false) => {
    let key = this.state.keySelected;

    this.setState({
      [`download_${key}`]: true,
      progressDownload: 1
    });

    const imageFile = [];
    const HAS_FARID_CLIENT = Number(localStorage.getItem('companyId')) === 9628;
    console.log('HAS_FARID_CLIENT::>', HAS_FARID_CLIENT);
    //console.log('COMPANY_ID', localStorage.getItem('companyId'));

    const IS_ENCARTE_A4 = (arrayToPDF[0].type.includes('Encarte') || arrayToPDF[0].type.includes('A4'));
    console.log('IS_ENCARTE_A4', IS_ENCARTE_A4);

    for (let i = 0; i < arrayToPDF.length; i++) {
      const link = arrayToPDF[i].image_url ? arrayToPDF[i].image_url.split('?')[0] : null;
      const is_video = !!arrayToPDF[i].id_videomatik;

      if (!is_video) {
        if (validURL(link)) {
          // let imageBlob = await DSAWS.getObjectGeral({
          //   link
          // });

          let imageBlob = await toBase64(link, { noHeaders: true });
          // imageBlob.base64

          var dimensions = await getImageDimensions(imageBlob.base64);

          // 1 milimeter = 3.78 px
          // converting pixels to millimeters is required for easy conversion to PDF
          imageFile.push({
            base64: imageBlob.base64,
            w:
              HAS_FARID_CLIENT ?
                IS_ENCARTE_A4 ? 210 : dimensions.w * (25.4 / 300)
                : dimensions.w / 3.78,
            h:
              HAS_FARID_CLIENT ?
                IS_ENCARTE_A4 ? 297 : dimensions.h * (25.4 / 300)
                : dimensions.h / 3.78
          });
        }
      }
    }

    // const pageOrientation = dimensions.h >= dimensions.w ? 'p' : 'landscape';

    // let doc = new jsPDF(pageOrientation, 'mm', 'a4');

    // imageFile.map((image, index) => {
    //   let ratio = image.w / image.h > 210 / 297;
    //   let w, h;
    //   const marginPdf = 15;
    //   switch (pageOrientation) {
    //     case 'p':
    //       w = ratio ? 210 - marginPdf : 0;
    //       h = ratio ? 0 : 297 - marginPdf;

    //       break;
    //     case 'landscape':
    //       w = ratio ? 297 - marginPdf : 0;
    //       h = ratio ? 0 : 210 - marginPdf;

    //       break;
    //     default:
    //       console.log('Erorr');
    //   }

    //   doc.addImage(image.base64, 'JPEG', 5, 5, w, h);
    //   if (index < imageFile.length - 1) {
    //     doc.addPage();
    //   }
    // });

    /**
     * The explanation of this code is in the article:
     * https://datasales.workplace.com/work/knowledge/1496255014227911
     */
    const imageSample = imageFile[0];
    const imageMargin = {
      axis_x: hasTrimArea ?
        HAS_FARID_CLIENT ? 5 : 10
        : 0,
      axis_y: hasTrimArea ?
        HAS_FARID_CLIENT ? 5 : 10
        : 0
    };
    const defaultCropSize = HAS_FARID_CLIENT ? 2.5 : 10; //in milimeter
    const defaultDimension = {
      width:
        HAS_FARID_CLIENT ?
          imageSample.w
          : hasTrimArea ?
            imageSample.w + (imageMargin.axis_x * 2)
            : imageSample.w,
      height:
        HAS_FARID_CLIENT ?
          imageSample.h
          : hasTrimArea ?
            imageSample.h + (imageMargin.axis_y * 2)
            : imageSample.h
    };

    const trimArea = {
      cornerTopLeft: {
        axis_x: {
          position_x: defaultCropSize,
          position_y: defaultCropSize * 2,
          radius_x: defaultCropSize,
          radius_y: 0
        },
        axis_y: {
          position_x: defaultCropSize * 2,
          position_y: defaultCropSize,
          radius_x: 0,
          radius_y: defaultCropSize
        }
      },
      cornerBottomLeft: {
        axis_x: {
          position_x: defaultCropSize,
          position_y: defaultDimension.height - defaultCropSize * 2,
          radius_x: defaultCropSize,
          radius_y: 0
        },
        axis_y: {
          position_x: defaultCropSize * 2,
          position_y: defaultDimension.height - defaultCropSize,
          radius_x: 0,
          radius_y: defaultCropSize
        }
      },
      cornerTopRight: {
        axis_x: {
          position_x: defaultDimension.width - defaultCropSize,
          position_y: defaultCropSize * 2,
          radius_x: defaultCropSize,
          radius_y: 0
        },
        axis_y: {
          position_x: defaultDimension.width - defaultCropSize * 2,
          position_y: defaultCropSize,
          radius_x: 0,
          radius_y: defaultCropSize
        }
      },
      cornerBottomRight: {
        axis_x: {
          position_x: defaultDimension.width - defaultCropSize,
          position_y: defaultDimension.height - defaultCropSize * 2,
          radius_x: defaultCropSize,
          radius_y: 0
        },
        axis_y: {
          position_x: defaultDimension.width - defaultCropSize * 2,
          position_y: defaultDimension.height - defaultCropSize,
          radius_x: 0,
          radius_y: defaultCropSize
        }
      }
    };

    const doc = new jsPDF({
      orientation: defaultDimension.width > defaultDimension.height ? 'landscape' : 'portrait',
      unit: 'mm',
      format: [defaultDimension.width, defaultDimension.height]
    });

    const { cornerTopLeft, cornerBottomLeft, cornerTopRight, cornerBottomRight } = trimArea;

    /*
    console.log('Dimensions::>', dimensions);
    console.log('hasTrimArea', hasTrimArea);
    console.log('imageSample', imageSample);
    console.log('imageMargin', imageMargin);
    console.log('defaultCropSize', defaultCropSize);
    console.log('defaultDimension', defaultDimension);
    console.log('trimArea', trimArea);
    console.log('doc', doc);*/

    for (let index = 0; index < imageFile.length; index++) {
      this.setState({
        progressDownload: ((index + 1) / arrayToPDF.length) * 100
      });
      //console.log('Propriedade_da_imagem_1',doc.getImageProperties(imageFile[index].base64));
      if (isPrinter) {
        try {
          await axios.post(
            `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/convert-image-cmyk`,
            { base64: imageFile[index].base64 }
          ).then(async (response) => {
            if (response?.data?.imageConvert?.link) {
              const linkImageCmyk = response?.data?.imageConvert?.link;
              const imageBlob = await toBase64(linkImageCmyk, { noHeaders: true });
              imageFile[index].base64 = imageBlob.base64;
            }
          });
        } catch {
          (err) => {
            console.log('err_convert_to_cmyk', err);
          }
        };
      }
      //console.log('Propriedade_da_imagem_2',doc.getImageProperties(imageFile[index].base64))
      doc.addImage(
        imageFile[index].base64,
        'JPEG',
        imageMargin.axis_x,
        imageMargin.axis_y,
        HAS_FARID_CLIENT ? defaultDimension.width - (imageMargin.axis_x * 2) : null,
        HAS_FARID_CLIENT ? defaultDimension.height - (imageMargin.axis_y * 2) : null);
      doc.setTextColor(0.00, 0.00, 0.00, 1.00);
      doc.setDrawColor(0.00, 0.00, 0.00, 1.00);
      doc.setFillColor(0.00, 0.00, 0.00, 1.00);
      if (hasTrimArea) {
        //console.log('trimArea', trimArea);

        //corner top left
        doc.ellipse(
          cornerTopLeft.axis_x.position_x,
          cornerTopLeft.axis_x.position_y,
          cornerTopLeft.axis_x.radius_x,
          cornerTopLeft.axis_x.radius_y
        );
        doc.ellipse(
          cornerTopLeft.axis_y.position_x,
          cornerTopLeft.axis_y.position_y,
          cornerTopLeft.axis_y.radius_x,
          cornerTopLeft.axis_y.radius_y
        );

        //corner bottom left
        doc.ellipse(
          cornerBottomLeft.axis_x.position_x,
          cornerBottomLeft.axis_x.position_y,
          cornerBottomLeft.axis_x.radius_x,
          cornerBottomLeft.axis_x.radius_y
        );
        doc.ellipse(
          cornerBottomLeft.axis_y.position_x,
          cornerBottomLeft.axis_y.position_y,
          cornerBottomLeft.axis_y.radius_x,
          cornerBottomLeft.axis_y.radius_y
        );

        //corner top right
        doc.ellipse(
          cornerTopRight.axis_x.position_x,
          cornerTopRight.axis_x.position_y,
          cornerTopRight.axis_x.radius_x,
          cornerTopRight.axis_x.radius_y
        );
        doc.ellipse(
          cornerTopRight.axis_y.position_x,
          cornerTopRight.axis_y.position_y,
          cornerTopRight.axis_y.radius_x,
          cornerTopRight.axis_y.radius_y
        );

        //corner bottom right
        doc.ellipse(
          cornerBottomRight.axis_x.position_x,
          cornerBottomRight.axis_x.position_y,
          cornerBottomRight.axis_x.radius_x,
          cornerBottomRight.axis_x.radius_y
        );
        doc.ellipse(
          cornerBottomRight.axis_y.position_x,
          cornerBottomRight.axis_y.position_y,
          cornerBottomRight.axis_y.radius_x,
          cornerBottomRight.axis_y.radius_y
        );
      }

      if (index < imageFile.length - 1) {
        doc.addPage();
      }
    }

    doc.save();

    this.setState({
      [`download_${key}`]: false,
      progressDownload: null
    });
  };

  handleDownloadCard = async (cardLink, cardName, isVideo = false) => {
    if (isVideo) {
      window.open(cardLink, '_blank');
    } else {
      this.handleDownloadImage(cardLink, cardName);
    }
  };

  handleSubmit = async (dataParam = {}) => {
    const data = {
      ...this.props.tabloid,
      ...dataParam,
      nome_tabloide_digital: this.state.nome,
      data_inicio: this.state.data_inicio,
      data_fim: this.state.data_fim,
      storeId: (this.state.store && this.state.store.id_loja) || null,
      loja: (this.state.store && this.state.store.id_loja) || null
    };

    this.setState({ editTabloidIsOpen: false });

    await this.props.onSave(data, []);
  };

  editarNovoMenuClick = () => {
    const flyerId = this.state.imgSelected && this.state.imgSelected.id;
    const offerId = this.props.tabloid.id_tabloide_digital;
    const access_token = JSON.parse(localStorage.getItem('token'));

    const params = queryString.stringify({
      offer_id: offerId,
      name: this.state.imgSelected.fullItem.name,
      // format_id:
      access_token,
      redirect_url: window.location.href
    });

    const url = `${AMBIENTE === 'dev'
      ? 'http://studio-web-development.s3-website-us-east-1.amazonaws.com'
      : 'https://editor.datasales.info'
      }/flyer/${flyerId}/fundos?${params}`;
    window.open(url, '_blank');
  };

  updateOnChangeOfShareableItems(shareableItems) {
    let carouselSelectionOptions = {};
    let hasVideo = false;

    carouselSelectionOptions = {
      ...this.state.carouselSelection,
      selectedItems: shareableItems
    };

    if (shareableItems.length > 0) {
      for (let i = 0; i < shareableItems.length; i++) {
        const selectedCard = shareableItems[i];

        if (hasVideo) break;
        if (selectedCard.link && selectedCard.hasOwnProperty('link')) hasVideo = true;
      }
    }

    if (hasVideo) carouselSelectionOptions.selectionType = 'video_and_post';
    else carouselSelectionOptions.selectionType = 'post';

    this.setState({
      carouselSelection: carouselSelectionOptions
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedShareableItem &&
      this.props.selectedShareableItem !== prevProps.selectedShareableItem
    ) {
      let flyers = Object.values(this.state.flyers).flat();
      if (Boolean(this.props.selectedShareableItem.length)) {
        const typesSelected = [
          ...new Set(
            this.props.selectedShareableItem.map(item => item.type).filter(type => Boolean(type))
          )
        ];

        if (Boolean(typesSelected.length)) {
          flyers = flyers.map(flyer => ({
            ...flyer,
            disabled: !flyer.type.includes(typesSelected[0])
          }));
        } else {
          flyers = flyers.map(flyer => ({
            ...flyer,
            disabled: false
          }));
        }
        this.setState({
          flyers: _.groupBy(flyers, flyer => capitalizeFirstLetter(flyer.type))
        });
      } else {
        const hasFlyerDisabled = flyers.find(flyer => flyer.disabled);
        if (hasFlyerDisabled) {
          flyers = flyers.map(flyer => ({
            ...flyer,
            disabled: false
          }));
          this.setState({
            flyers: _.groupBy(flyers, flyer => capitalizeFirstLetter(flyer.type))
          });
        }
      }

      this.updateOnChangeOfShareableItems(this.props.selectedShareableItem);
    }
  }

  render() {
    const { classes, getTabloids } = this.props;

    const fator = 8;
    const dataCategoria = {};
    const responseCategoria = this.props.paginas_fisico_all.responseCategoria;
    const responseCategoria_index = this.props.responseCategoria_index;
    const ObjectResponseCategoria = Object.keys(responseCategoria);
    for (let y = 0; y < ObjectResponseCategoria.length; y++) {
      const keyTmp = ObjectResponseCategoria[y];
      dataCategoria[keyTmp] = responseCategoria[keyTmp].slice(
        0,
        responseCategoria_index[keyTmp] * fator
      );
    }

    const loading = this.props.loading_tabloide_fisico;

    if (
      this.props.flyersToSelectWhenRedirectToOffer?.flyersIds?.length > 0 &&
      !this.props.flyersToSelectWhenRedirectToOffer?.readyToDistribute
    ) {
      return (
        <div style={{ marginTop: '20vh', textAlign: 'center' }}>
          <CircularProgress color="primary" />{' '}
        </div>
      );
    }

    if (queryString.parse(location?.search)?.createArtFormat) {
      return (
        <div style={{ marginTop: '20vh', textAlign: 'center' }}>
          <CircularProgress color="primary" />{' '}
        </div>
      );
    }

    if (this.state.createFlyerIsOpen.open || this.state.openCreateFlyer) {
      return (
        <>
          {this.state.openCreateFlyer && (
            <FormatPage
              returnPage={() => this.setState({ openCreateFlyer: false })}
              dataSelected={value => {
                this.setState({ ...value });
              }}
              qtdeProdutos={this.props.tabloid.produtos.length}
            />
          )}

          {(this.state.createFlyerIsOpen.open || this.state.openCreateFlyer) && (
            <CreateArt
              open={this.state.createFlyerIsOpen.open}
              onCloseReturn={async () => {
                getTabloids();
                this.setState({ createFlyerIsOpen: { open: false }, openCreateFlyer: true });
              }}
              handleClose={async flyerToOpen => {
                getTabloids();
                this.setState({ createFlyerIsOpen: { open: false }, openCreateFlyer: false });
                this.getEncartesNovoEstudio(this.state.id);
                this.onRefreshVideomatik(this.state.id, false);

                if (flyerToOpen) {
                  const item = flyerToOpen;
                  this.setState(
                    {
                      imgSelected: {
                        id: item.id,
                        fullItem: item
                      }
                    },
                    () => {
                      _alert.alertConfirm('Arte criada com sucesso! Abrir?', () =>
                        this.editarNovoMenuClick()
                      );
                    }
                  );
                }
              }}
              offerId={this.props.tabloid.id_tabloide_digital}
              formatType={this.state.createFlyerIsOpen.type}
              qtdeProductsInList={this.props.tabloid.produtos.length}
              modelo_pronto={this.state.createFlyerIsOpen.modelo_pronto || this.state.modelo_pronto}
              isManual={!!this.state.createFlyerIsOpen?.isManual}
              onClickProduct={(value, posExec) => {
                this.setState({
                  productListIsOpen: true,
                  editIsComplete: false,
                  commandToAddProduct: 'AddProduct',
                  productSelected: value,
                  createArtPosExec: posExec
                });
              }}
            />
          )}

          {false && (
            <CreateFlyer
              open={
                !!this.state.createFlyerIsOpen?.isManual ? false : this.state.createFlyerIsOpen.open
              }
              offerId={this.props.tabloid.id_tabloide_digital}
              tabloid={{
                qtde_produtos_lista: this.props.tabloid.produtos.length
              }}
              modelo_pronto={this.state.createFlyerIsOpen?.modelo_pronto}
              onCloseReturn={() => {
                this.setState({ createFlyerIsOpen: { open: false } });
              }}
              onClose={async flyerToOpen => {
                this.setState({ createFlyerIsOpen: { open: false }, openCreateFlyer: false });
                await this.getEncartesNovoEstudio(this.state.id);

                if (flyerToOpen) {
                  const item = flyerToOpen;
                  this.setState(
                    {
                      imgSelected: {
                        id: item.id,
                        fullItem: item
                      }
                    },
                    () => {
                      _alert.alertConfirm('Arte criada com sucesso! Abrir?', () =>
                        this.editarNovoMenuClick()
                      );
                    }
                  );
                }
                this.onRefreshVideomatik(this.state.id, false);
              }}
              isManual={!!this.state.createFlyerIsOpen?.isManual}
              formatType={this.state.createFlyerIsOpen.type}
            />
          )}
        </>
      );
    }

    return (
      <>
        {!this.state.openCreateFlyer && (
          <div className={classes.root} ref={this.myRef}>
            {/* <div>
            <Button
              component={Link}
              to="/estudio"
              size="small"
              style={{ margin: '15px 0 1rem 0' }}
              color="primary"
            >
              <Icon>arrow_back_ios</Icon>Voltar
            </Button>
          </div> */}
            {this.props.loadingGetTabloids ? (
              <div style={{ textAlign: 'center', margin: 30 }}>
                <CircularProgress />
              </div>
            ) : (
              // PRIMEIRO BOX DE CIMA
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '8px',
                  marginBottom: '27px'
                }}
              >
                <div>
                  <Button
                    component={Link}
                    to="/home?step=2"
                    size="small"
                    style={{ marginRight: '27px' }}
                    //     style={{ margin: '15px 0 1rem 0' }}
                    // color="primary"
                    color="primary"
                    className={classes.title4}
                  >
                    {/* <img src={BackIcon} width={20} height={16} style={{ marginRight: '12px' }} /> */}
                    <Icon style={{ marginRight: 10 }}>arrow_back_ios</Icon>
                    Voltar
                  </Button>
                </div>

                <div className={classes.actions} style={{ alignItems: 'center' }}>
                  <span
                    className={classes.title1}
                    style={{
                      maxWidth: '65vw'
                      // alignItems: 'center'
                    }}
                  >
                    {this.props.tabloid.nome_tabloide_digital}

                    <IconButton
                      onClick={() =>
                        this.setState({
                          editTabloidIsOpen: true
                        })
                      }
                      style={{ marginLeft: '13.5px' }}
                    >
                      <Icon>border_color</Icon>
                      {/* <img src={EditPencilLine} width={25.33} height={25.33} /> */}
                    </IconButton>
                  </span>
                  <div style={{ display: 'flex' }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      style={{
                        marginLeft: 15,
                        backgroundColor: '#2813AD',
                        whiteSpace: 'nowrap',
                        letterSpacing: '0.05em'
                      }}
                      onClick={e => {
                        this.setState({
                          generateMenuAnchorEl: e.currentTarget
                        });
                        // this.setState({
                        //   openCreateFlyer: true
                        // });
                      }}
                    >
                      <Icon style={{ marginRight: 10 }}>add_circle_outline_icon</Icon>
                      Criar nova arte
                    </Button>

                    {this.state.generateMenuAnchorEl && (
                      <GenerateMenu
                        loading={this.state.openCreateFlyer}
                        qtdProducts={this.props.tabloid.produtos.length}
                        anchorEl={this.state.generateMenuAnchorEl}
                        onClose={() => {
                          this.setState({
                            generateMenuAnchorEl: null
                          });
                        }}
                        open={Boolean(this.state.generateMenuAnchorEl)}
                        onSubmit={value => {
                          this.setState({
                            generateMenuAnchorEl: null
                          });

                          if (value === 'automatic') {
                            this.setState({ generatePixelixe: true });
                          } else if (value === 'automaticNewEditor') {
                            this.setState({ openCreateFlyer: true });
                            // this.setState({ createFlyerIsOpen: { open: true } });
                          } else if (value === 'readyMadeTemplates') {
                            this.setState({
                              createFlyerIsOpen: {
                                open: true,
                                modelo_pronto: true,
                                type: 'Modelo Pronto'
                              }
                            });
                          } else if (value === 'newReadyModels') {
                            this.setState({
                              createFlyerIsOpen: {
                                open: true,
                                modelo_pronto: true,
                                type: 'Modelo Pronto'
                              }
                            });
                            console.log('State', this.state);
                          } else if (value === 'canva') {
                            this.setState({ canvaIsOpen: true });
                          } else if (value === 'manual') {
                            this.setState({
                              createFlyerIsOpen: { open: true, isManual: true, type: 'Manual' }
                            });
                          } else if (value === 'videos') {
                            this.setState({ videosIsOpen: true });
                          } else if (value === 'upload') {
                            this.setState({
                              importImageIsOpen: true,
                              importType: value
                            });
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <Divider className={classes.divider} />

            <Fragment>
              <div style={{ margin: '0px 0px 30px 0px' }}>
                <div className={classes.title1}>Lista de produtos</div>
                {this.props.tabloid.produtos <= 0 && (
                  <div style={{ display: 'flex', marginTop: 8 }}>
                    <div className={classes.title3}>Sua lista de produtos está vazia.</div>
                  </div>
                )}

                {this.props.tabloid.produtos <= 0 && (
                  <SplitButton
                    text="Adicionar produtos"
                    label="label"
                    className={classes.title2}
                    style={{
                      marginTop: 32,
                      color: '#1D1D1D',
                      backgroundColor: '#F5F5F5',
                      padding: '12px 16px'
                    }}
                    icon
                  >
                    <MenuItem
                      key="Adicionar produto único"
                      className={(classes.menuItem, classes.title5)}
                      onClick={() => {
                        this.setState({
                          productListIsOpen: true,
                          editIsComplete: false,
                          commandToAddProduct: 'AddProduct'
                        });
                      }}
                    >
                      Adicionar produto único
                    </MenuItem>

                    <MenuItem
                      key="Adicionar por texto"
                      className={(classes.menuItem, classes.title5)}
                      onClick={() => {
                        this.setState({
                          productListIsOpen: true,
                          editIsComplete: false,
                          commandToAddProduct: 'TextImport'
                        });
                      }}
                    >
                      Adicionar por texto
                    </MenuItem>
                    <MenuItem
                      key="Importar arquivo"
                      className={(classes.menuItem, classes.title5)}
                      onClick={() => {
                        this.setState({
                          productListIsOpen: true,
                          editIsComplete: false,
                          commandToAddProduct: 'FilesImport'
                        });
                      }}
                    >
                      Importar Arquivo
                    </MenuItem>

                    <MenuItem
                      key="Adicionar produtos de outra propaganda"
                      className={(classes.menuItem, classes.title5)}
                      onClick={() => {
                        this.setState({
                          productListIsOpen: true,
                          editIsComplete: false,
                          commandToAddProduct: 'Models'
                        });
                      }}
                    >
                      Adicionar produtos de outra propaganda
                    </MenuItem>

                    <MenuItem
                      key="Ver lista de produtos"
                      className={(classes.menuItem, classes.title5)}
                      onClick={() => {
                        this.setState({
                          productListIsOpen: true,
                          commandToAddProduct: 'ListProducts'
                        });
                      }}
                    >
                      Ver lista de produtos
                    </MenuItem>
                  </SplitButton>
                )}

                <ProductsCarousel
                  array={this.props.tabloid.produtos}
                  tabloid={this.props.tabloid}
                  handleSubmit={this.handleSubmit}
                  loadingGetTabloids={this.props.loadingGetTabloids}
                  onClick={value => {
                    this.setState({
                      productListIsOpen: true,
                      editIsComplete: false,
                      commandToAddProduct: 'AddProduct',
                      productSelected: value
                    });
                  }}
                  splitButtonAdd={
                    <SplitButton
                      text="Adicionar produtos"
                      label="label"
                      className={classes.title2}
                      style={{
                        // marginTop: 32,
                        color: '#1D1D1D',
                        backgroundColor: '#F5F5F5',
                        padding: '12px 16px',
                        marginRight: 16
                      }}
                      icon
                    >
                      <MenuItem
                        key="Adicionar produto único"
                        className={(classes.menuItem, classes.title5)}
                        onClick={() => {
                          this.setState({
                            productListIsOpen: true,
                            editIsComplete: false,
                            commandToAddProduct: 'AddProduct'
                          });
                        }}
                      >
                        Adicionar produto único
                      </MenuItem>

                      <MenuItem
                        key="Adicionar por texto"
                        className={(classes.menuItem, classes.title5)}
                        onClick={() => {
                          this.setState({
                            productListIsOpen: true,
                            editIsComplete: false,
                            commandToAddProduct: 'TextImport'
                          });
                        }}
                      >
                        Adicionar por texto
                      </MenuItem>
                      <MenuItem
                        key="Importar arquivo"
                        className={(classes.menuItem, classes.title5)}
                        onClick={() => {
                          this.setState({
                            productListIsOpen: true,
                            editIsComplete: false,
                            commandToAddProduct: 'FilesImport'
                          });
                        }}
                      >
                        Importar Arquivo
                      </MenuItem>

                      <MenuItem
                        key="Adicionar produtos de outra propaganda"
                        className={(classes.menuItem, classes.title5)}
                        onClick={() => {
                          this.setState({
                            productListIsOpen: true,
                            editIsComplete: false,
                            commandToAddProduct: 'Models'
                          });
                        }}
                      >
                        Adicionar produtos de outra propaganda
                      </MenuItem>

                      <MenuItem
                        key="Ver lista de produtos"
                        className={(classes.menuItem, classes.title5)}
                        onClick={() => {
                          this.setState({
                            productListIsOpen: true,
                            commandToAddProduct: 'ListProducts'
                          });
                        }}
                      >
                        Ver lista de produtos
                      </MenuItem>
                    </SplitButton>
                  }
                />
              </div>
              {/* <Divider className={classes.divider} /> */}

              {/* EL GRANDE COMPONENTE CENTRAL */}
              <Grid item container>
                {loading && (
                  <MagnumGrid
                    loading={loading}
                    array={[]}
                    type={1}
                    onClickMenu={() => { }}
                    onClickImage={() => { }}
                  />
                )}

                {dataCategoria &&
                  !loading &&
                  Object.keys(dataCategoria).map(key => (
                    <Grid item container xs={12} spacing={1} key={key}>
                      <div
                        className={classes['title1-2']}
                        style={{
                          textTransform: 'capitalize',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {key}
                      </div>

                      <Grid item>
                        {this.state[`download_${key}`] ? (
                          <div style={{ marginRight: 30, marginBottom: 10 }}>
                            <Box position="relative" display="inline-flex">
                              <CircularProgress />
                              <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >{`${Math.round(this.state.progressDownload)}%`}</Typography>
                              </Box>
                            </Box>
                          </div>
                        ) : (
                          <IconButton
                            onClick={async () => {
                              this.setState({
                                [`download_${key}`]: true,
                                progressDownload: 0
                              });
                              const imageFile = [];
                              for (let i = 0; i < dataCategoria[key].length; i++) {
                                let link = dataCategoria[key][i].link;
                                if (validURL(link)) {
                                  //let imageBlob = await toBase64(link);
                                  let imageBlob = await DSAWS.getObjectGeral({
                                    link
                                  });
                                  imageFile.push({
                                    name: i + '.' + 'jpg', //link.split('.')[link.split('.').length - 1]
                                    base64: imageBlob
                                  });
                                }
                                this.setState({
                                  progressDownload:
                                    (i / this.props.paginas_fisico_all.response.length) * 100
                                });
                              }
                              let zip = new JSZip();
                              let folder = zip.folder('collection');
                              imageFile.forEach((image, index) =>
                                folder.file(image.name, image.base64.split(',')[1], {
                                  base64: true
                                })
                              );
                              folder
                                .generateAsync({ type: 'blob' })
                                .then(content => saveAs(content, 'files'));
                              this.setState({ [`download_${key}`]: false });
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <MagnumGrid
                            array={dataCategoria[key].map(value => ({
                              ...value,
                              link: null,
                              id: value.id,
                              preview_url: value.miniatura ? value.miniatura : null,
                              name: value.nome_modelo,
                              image_url: value.miniatura
                                ? value.miniatura
                                : value.link ||
                                'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loading-buffering.gif'
                            }))}
                            isGeracao1={true}
                            type={key}
                            onClickMenu={(e, item) => {
                              this.setState({
                                anchorEl: e.currentTarget,
                                imgSelected: {
                                  ...item,
                                  key
                                }
                              });
                            }}
                            onClickImage={item => {
                              this.setState({
                                imgSelected: {
                                  ...item,
                                  link: item.image_url ? item.image_url : null
                                },
                                downloadImgIsOpen: true
                              });
                            }}
                            onReprocessar={id => {
                              this.props.enqueueSnackbar('Processando...', {
                                variant: 'info'
                              });
                              this.props
                                .putOfertaFisico({
                                  isReload: {
                                    id_arte: id
                                  }
                                })
                                .then(resp => {
                                  this.props.enqueueSnackbar(resp.message, {
                                    variant: 'success'
                                  });

                                  this.onRefreshSilence();
                                })
                                .catch(err => {
                                  this.props.enqueueSnackbar('Falha de processamento', {
                                    variant: 'error'
                                  });
                                });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          top: 0,
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {responseCategoria_index[key] > 2 && (
                          <Button
                            style={{
                              margin: 5,
                              cursor: 'pointer'
                            }}
                            variant="contained"
                            color="primary"
                            size="medium"
                            disableElevation
                            onClick={() => this.props.changeCategoriaIndexSubtract(key)}
                          >
                            <KeyboardArrowUpIcon fontSize="default" />
                          </Button>
                        )}
                        {responseCategoria_index[key] * fator < responseCategoria[key].length && (
                          <Button
                            style={{
                              margin: 1,
                              cursor: 'pointer'
                            }}
                            variant="contained"
                            color="primary"
                            size="medium"
                            disableElevation
                            onClick={() => this.props.changeCategoriaIndex(key)}
                          >
                            <KeyboardArrowDownIcon fontSize="default" />
                          </Button>
                        )}
                      </div>
                    </Grid>
                  ))}

                {this.state.videomatikTable?.length > 0 && (
                  <Grid item container xs={12}>
                    <>
                      <div style={{ display: 'flex' }}>
                        <div
                          className={classes.title1}
                          style={{
                            textTransform: 'capitalize',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          Vídeo
                        </div>
                      </div>
                      <Grid item xs={12}>
                        {this.state.loadingVideomatik && (
                          <MagnumGrid
                            loading={this.state.loadingVideomatik}
                            array={[]}
                            type={1}
                            onClickMenu={() => { }}
                            onClickImage={() => { }}
                          />
                        )}

                        {this.state.videomatikTable?.length > 0 && (
                          <MagnumGrid
                            array={this.state.videomatikTable.map(value => ({
                              ...value,
                              id: value.id,
                              name: value.nome_produto,
                              image_url: value.link
                                ? 'https://datasalesio-imagens.s3.amazonaws.com/playvideo_videomatik.png'
                                : 'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loading-buffering.gif'
                            }))}
                            type={'Video'}
                            onClickMenu={(e, item) => {
                              item.link &&
                                this.setState({
                                  videoAnchorEl: e.currentTarget,
                                  vidSelected: item,
                                  imgSelected: {
                                    vidSelected: item
                                  }
                                });
                            }}
                            onClickImage={item => {
                              item.link &&
                                this.setState({
                                  vidSelected: item,
                                  downloadVidIsOpen: true
                                });
                            }}
                          />
                        )}
                      </Grid>
                    </>
                  </Grid>
                )}

                {/* NOVO ESTUDIO */}

                {loading && this.state.loadingFlyers && (
                  <MagnumGrid
                    loading={this.state.loadingFlyers}
                    array={[]}
                    type={1}
                    onClickMenu={() => { }}
                    onClickImage={() => { }}
                  />
                )}

                {/* {Object.keys(this.state.flyers).length <= 0 && (
                <>
                  <div
                    style={{
                      width: '100%',
                      padding: '15px',
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 600,
                      fontSize: 20,
                      color: '#312A5E'
                    }}
                  >
                    Suas artes
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      padding: '15px',
                      marginBottom: 20
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#312A5E',
                        backgroundColor: '#F5F5F5',
                        padding: 10
                      }}
                    >
                      {this.props.tabloid.produtos.length > 0
                        ? 'Você ainda não possui artes.'
                        : 'Crie uma lista de produtos para criar uma arte.'}
                    </div>
                    <div></div>
                  </div>
                </>
              )} */}

                {this.state.flyers && (
                  // Post, Cartaz A4 - Retrato, Encarte
                  <Grid item container xs={12}>
                    {Object.keys(this.state.flyers).map((key, index) => (
                      <Fragment key={index}>
                        <div className={classes.boxTitles} key={index}>
                          <div
                            className={classes.title1}
                            style={{
                              textTransform: 'capitalize',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {key}
                          </div>

                          {this.state[`download_${key}`] ? (
                            <div style={{ marginRight: 30, marginLeft: 20, marginBottom: 10 }}>
                              <Box position="relative" display="inline-flex">
                                <CircularProgress style={{ color: '#2813AD' }} />
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                    style={{ color: '#2813AD' }}
                                  >{`${Math.round(this.state.progressDownload)}%`}</Typography>
                                </Box>
                              </Box>
                            </div>
                          ) : (
                            <>
                              {/* <Button
                                className={classes.btnMenu}
                                aria-controls={this.state.open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={this.state.open ? 'true' : null}
                                onClick={e => {
                                  this.setState({
                                    downloadAnchorEl: e.currentTarget,
                                    keySelected: key
                                  });
                                }}
                              >
                                {this.state.downloadAnchorEl === null && (
                                  <Box
                                    className={[classes.title4, classes.boxMenu1]}
                                    style={{ color: '#2813AD' }}
                                  >
                                    <Box className={classes.boxMenu2}>
                                      <GetAppIcon />
                                    </Box>
                                    <Box className={classes.boxMenu3}>Baixar todos</Box>
                                  </Box>
                                )}
                              </Button> */}
                              <SelectAll
                                selectedShareableItem={this.props.selectedShareableItem}
                                setSelectedShareableItem={this.props.setSelectedShareableItem}
                                flyers_key={this.state.flyers[key]}
                                key={key}
                              />
                            </>
                          )}
                        </div>

                        <Grid item xs={12}>
                          <MagnumGrid
                            array={this.state.flyers[key]}
                            type={key}
                            onClickMenu={(e, item) => {
                              this.setState({
                                anchorEl: e.currentTarget,
                                imgSelected: {
                                  ativo: item.is_active,
                                  code: null,
                                  data: item.created_at,
                                  error: null,
                                  error_html: null,
                                  from: null,
                                  id: item.id,
                                  id_empresa: item.company_id,
                                  id_tabloide: item.offer_id,
                                  link: item.image_url,
                                  link_html: null,
                                  miniatura: item.image_url,
                                  modelo: null,
                                  nome_modelo: item.name,
                                  pagina: null,
                                  tipoPixelixe: item.type,
                                  is_new_studio: true,
                                  fullItem: item,
                                  key
                                }
                              });
                            }}
                            onClickImage={item => {
                              this.setState({
                                imgSelected: {
                                  ativo: item.is_active,
                                  code: null,
                                  data: item.created_at,
                                  error: null,
                                  error_html: null,
                                  from: null,
                                  id: item.id,
                                  id_empresa: item.company_id,
                                  id_tabloide: item.offer_id,
                                  link: item.image_url,
                                  link_html: null,
                                  miniatura: item.image_url,
                                  modelo: null,
                                  nome_modelo: item.name,
                                  pagina: null,
                                  tipoPixelixe: item.type,
                                  is_new_studio: true
                                },
                                downloadImgIsOpen: true
                              });
                            }}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                )}
              </Grid>

              {/* <Divider
              style={{ width: '100%', marginBottom: 20, marginTop: 20 }}
              className={classes.divider}
            /> */}
              {/* ANTIGO LINK ONLINE */}
              {/* {this.props.tabloid && (
              <Grid item container xs={12} spacing={1}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes['title1-2']}
                    style={{
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {'Link Online'}
                  </div>
                </div>

                <MagnumGrid
                  array={[
                    {
                      selected: false,
                      image_url:
                        'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loja+online+(1).png',
                    },
                  ]}
                  onClickMenu={(e, item) => {
                    this.setState({
                      linkAnchorEl: e.currentTarget,
                    });
                  }}
                  onClickImage={item => {
                    window.open(
                      `https://ofertas.meumercado.co/${localStorage.getItem('companyId')}/${
                        this.props.tabloid.uuid
                      }`,
                      '_blank',
                    );
                  }}
                  menuOut={[
                    {
                      text: 'Vendas',
                      icon: props => <TrendingUpIcon fontSize="small" {...props} />,
                      onClick: () => {
                        this.setState({
                          linkAnchorEl: null,
                          salesIsOpen: true,
                        });
                      },
                    },
                    {
                      text: 'Configurações',
                      icon: props => <SettingsIcon fontSize="small" {...props} />,
                      onClick: () => {
                        this.setState({
                          linkAnchorEl: null,
                          productListIsOpen: true,
                          editIsComplete: true,
                        });
                      },
                    },
                  ]}
                />
              </Grid>
            )} */}

              <div className={classes.content}>
                <div className={classes.content}>
                  <div
                    style={{
                      margin: 'auto',
                      marginBottom: 20,
                      display: loading ? 'none' : 'block'
                    }}
                  ></div>

                  <div
                    style={{
                      display: loading ? 'block' : 'none',
                      textAlign: 'center'
                    }}
                  >
                    <CircularProgress></CircularProgress>
                  </div>
                </div>
              </div>

              <div style={{ height: 200 }}></div>
            </Fragment>
          </div>
        )}

        {/* FRAGMENT QUE ESTAVA DENTRO DO DIV MAIOR ALI EM CIMA */}
        <>
          {/* EL GRANDE E IMPORTANTE EDIT TABLOID */}
          {this.state.productListIsOpen &&
            this.props.EditTabloid({
              closeBox: () => {
                this.setState({
                  productListIsOpen: false,
                  productSelected: {}
                });
              },
              posExec: () => {
                if (this.state.createArtPosExec) {
                  this.state.createArtPosExec();
                  this.setState({
                    createArtPosExec: null
                  });
                }
              },
              completeEdit: this.state.editIsComplete,
              commandToAddProduct: this.state.commandToAddProduct,
              productSelected: this.state.productSelected
            })}

          {this.state.editTabloidIsOpen && (
            <Dialog
              open={this.state.editTabloidIsOpen}
              onClose={() => {
                this.setState({ editTabloidIsOpen: false });
              }}
            >
              <DialogTitle
                onClose={() => {
                  this.setState({ editTabloidIsOpen: false });
                }}
              >
                Informações da propaganda
              </DialogTitle>

              <DialogContent dividers>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Propaganda sem nome"
                      value={this.state.nome}
                      onChange={e => this.setState({ nome: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      getOptionSelected={(option, value) => option.id_loja === value.id_loja}
                      getOptionLabel={option => option.nome_loja}
                      options={this.state.stores}
                      loading={this.state.stores.length === 0}
                      value={this.state.store}
                      onChange={(event, newValue) => this.setState({ store: newValue })}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Selecionar loja"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.stores.length === 0 ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="De"
                      dateAndTime
                      value={this.state.data_inicio}
                      onChange={date => this.setState({ data_inicio: date })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Ate"
                      dateAndTime
                      value={this.state.data_fim}
                      onChange={date => this.setState({ data_fim: date })}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ editTabloidIsOpen: false })}>
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {this.state.downloadImgIsOpen && this.state.imgSelected.link && (
            <DownloadImage
              open={this.state.downloadImgIsOpen}
              imageUrl={this.state.imgSelected.link + '?' + makeId(6)}
              imageDataTime={this.state.imgSelected.data}
              imageUrlToDownload={this.state.imgSelected.link}
              imageName={this.state.imgSelected.nome_modelo}
              onClose={() => {
                this.setState({ downloadImgIsOpen: false });
              }}
            />
          )}

          {this.state.downloadVidIsOpen && this.state.vidSelected.link && (
            <DownloadVideo
              open={this.state.downloadVidIsOpen}
              videoUrl={this.state.vidSelected.link}
              videoName={this.state.vidSelected.nome_produto}
              onClose={() => {
                this.setState({ downloadVidIsOpen: false });
              }}
            />
          )}

          {this.state.generatePixelixe && (
            <GeneratePixelixe
              open={this.state.generatePixelixe}
              tabloid={{
                id_tabloide_digital: this.state.id,
                qtde_produtos_lista: this.props.tabloid.produtos.length
              }}
              tabloideProdutos={this.props.tabloid.produtos}
              templatesVideomatik={this.state.templatesVideomatik}
              onClose={() => {
                this.setState({ generatePixelixe: false });
                this.onRefresh();
                this.onRefreshVideomatik(this.state.id, false);
              }}
            ></GeneratePixelixe>
          )}

          {this.state.pixelixeIframe && (
            <PixelixeIframe
              open={this.state.pixelixeIframe}
              onClose={() => {
                this.setState({ pixelixeIframe: false });
              }}
            ></PixelixeIframe>
          )}

          {this.state.showDialog && (
            <DialogAlert
              open={this.state.showDialog}
              title="Deseja realmente desativar o arquivo?"
              onClick={() => {
                this.props.enqueueSnackbar('Processando...', {
                  variant: 'info'
                });
                this.setState({ showDialog: false });
                this.props
                  .deleteEncarteFisico({
                    id: this.state.imgSelected.id
                  })
                  .then(resp => {
                    this.props.enqueueSnackbar(resp.message, {
                      variant: 'success'
                    });
                    this.props.getOfertaFisicoAll(
                      {
                        id: this.state.id,
                        all: true
                      },
                      false
                    );
                  })
                  .catch(err => {
                    this.props.enqueueSnackbar('Falha de processamento', {
                      variant: 'error'
                    });
                  });
              }}
              onClose={() => this.setState({ showDialog: false })}
            />
          )}

          {this.state.showDialogDesativarNovoEstudio && (
            <DialogAlert
              open={this.state.showDialogDesativarNovoEstudio}
              title="Deseja realmente desativar a arte?"
              onClick={() => {
                this.props.enqueueSnackbar('Processando...', {
                  variant: 'info'
                });
                this.setState({ showDialogDesativarNovoEstudio: false });

                try {
                  this.updateEncartesNovoEstudio(this.state.imgSelected);
                } catch (err) {
                  console.log({ err });
                  this.props.enqueueSnackbar('Falha de processamento', {
                    variant: 'error'
                  });
                }
              }}
              onClose={() => this.setState({ showDialogDesativarNovoEstudio: false })}
            />
          )}

          {this.state.openCriarTemplate && (
            <ModalCriar
              open={this.state.openCriarTemplate}
              onClose={() => this.setState({ openCriarTemplate: false })}
              value={{
                nomeModelo: this.state.nomeModelo,
                ocasiaoModelo: this.state.ocasiaoModelo,
                qtdeModelo: this.state.qtdeModelo
              }}
              onChange={(e, campo) =>
                this.setState({
                  [campo]: e.target.value
                })
              }
              onClick={() => {
                if (!this.state.nomeModelo) {
                  alert('Preencha o campo Nome do Modelo!');
                } else {
                  this.state.onCreateNovoModelo();
                }
              }}
            ></ModalCriar>
          )}
        </>

        {/* OUTROS DIALOGS */}
        <>
          {this.state.openDialogMeuModeloName && (
            <MeuModeloNameDialog
              open={this.state.openDialogMeuModeloName}
              onClose={() => {
                this.setState({ openDialogMeuModeloName: false });
              }}
              onSave={async valueName => {
                let imgSelected = this.state.imgSelected;
                let fullItem = imgSelected.fullItem;

                await Fetch(
                  `https://api-backoffice.datasales.io/studio-${AMBIENTE}/templates`,
                  {
                    companyId: localStorage.getItem('companyId'),
                    formatId: fullItem.format_id,
                    occasionId: null,
                    segmentId: null,
                    name: valueName,
                    imageURL: fullItem.image_url,
                    designURL: fullItem.json_url,
                    productQuantity: fullItem.product_quantity,
                    tags: 'Meu Modelo'
                  },
                  'POST'
                  // null,
                  // null,
                  // false
                );

                this.setState({ openDialogMeuModeloName: false });
              }}
            ></MeuModeloNameDialog>
          )}

          {this.state.pixelixeIframe_open && this.state.imgSelected && (
            <PixelixeIframe
              open={this.state.pixelixeIframe_open}
              onClose={() => {
                this.setState({ pixelixeIframe_open: false });
              }}
              src={
                'https://studio.pixelixe.com/#api?apiKey=EzWaSycXeWchL44HPUtcnLpZes63&graphicUrl=' +
                (this.state.imgSelected.link_html
                  ? this.state.imgSelected.link_html.split('?')[0]
                  : null) +
                `&graphicName=${this.state.imgSelected.nome}&uid=${this.state.imgSelected.id}_${this.state.imgSelected.id_tabloide
                }_${this.state.imgSelected.modelo}_${this.state.imgSelected.pagina}_${this.state.imgSelected.code
                }_${this.state.imgSelected.id_empresa}_${this.state.imgSelected.tipoPixelixe}_${this.state.imgSelected.from || 'null'
                }_link_${window.location.href.split('/enviado')[0]}/enviado_link_&lang=po`
              }
            ></PixelixeIframe>
          )}

          {this.state.salesIsOpen && (
            <Sales
              open={this.state.salesIsOpen}
              onClose={() => {
                this.setState({
                  salesIsOpen: false
                });
              }}
            />
          )}

          {this.state.distributeIsOpen && (
            <Distribute
              open={this.state.distributeIsOpen}
              type={
                this.state.imgSelected?.tipoPixelixe
                  ? this.state.imgSelected.tipoPixelixe
                  : this.state.carouselSelection.selectionType
              }
              arrayAllowed={
                this.state.carouselSelection.selectedItems &&
                  this.state.carouselSelection.selectedItems.length > 0
                  ? this.state.carouselSelection.selectionType === 'post'
                    ? [1, 1, 1, 1, 1]
                    : [1, 1, 0, 0, 0]
                  : this.state.imgSelected?.vidSelected
                    ? [1, 1, 0, 0, 0]
                    : [1, 1, 1, 1, 1]
              }
              onSubmit={value => {
                this.setState({ distributeIsOpen: false });

                if (value === 'post') {
                  this.setState({ createPostIsOpen: true });
                } else if (value === 'ads') {
                  if (
                    this.state.imgSelected?.key.toUpperCase() === 'Post'.toUpperCase() ||
                    this.state.imgSelected?.vidSelected?.tipo === 1 ||
                    this.hasCarouselCardsSelected()
                  ) {
                    this.setState({ createAdsIsOpen: true });
                  } else {
                    _alert.alertWarning(
                      'Ops!',
                      'Só é permitido impulsionar artes do tipo Post ou Selecionando Posts & Vídeos.'
                    );
                  }
                } else if (value === 'app') {
                  this.setState({ appDistribution: true });
                } else if (value === 'banner') {
                  this.setState({ bannerDistribution: true });
                } else if (value === 'push') {
                  this.setState({ pushNotificationDistribution: true });
                }
              }}
              onClose={() => {
                this.setState({ distributeIsOpen: false });
              }}
            />
          )}

          {((this.state.createAdsIsOpen && this.state.imgSelected) ||
            (this.state.createAdsIsOpen && this.hasCarouselCardsSelected())) && (
              <CreateAds
                open={this.state.createAdsIsOpen}
                hasCarousel={this.hasCarouselCardsSelected() || false}
                data={
                  this.hasCarouselCardsSelected()
                    ? {
                      ...this.state.carouselSelection,
                      uuid: this.props.tabloid.uuid,
                      offerId: this.props.tabloid.id_tabloide_digital,
                      offerName: this.props.tabloid.nome_tabloide_digital
                    }
                    : !!this.state.imgSelected?.vidSelected
                      ? {
                        uuid: this.props.tabloid.uuid,
                        offerId: this.props.tabloid.id_tabloide_digital,
                        offerName: this.props.tabloid.nome_tabloide_digital,
                        imageId: this.state.imgSelected?.vidSelected?.id,
                        imageName: this.state.imgSelected?.vidSelected?.nome_produto,
                        imageUrl: this.state.imgSelected?.vidSelected?.link,
                        imageType: 'video'
                      }
                      : {
                        uuid: this.props.tabloid.uuid,
                        offerId: this.props.tabloid.id_tabloide_digital,
                        offerName: this.props.tabloid.nome_tabloide_digital,
                        imageId: this.state.imgSelected.id,
                        imageName: this.state.imgSelected.nome_modelo,
                        imageUrl: this.state.imgSelected.link,
                        imageType: this.state.imgSelected.tipoPixelixe
                      }
                }
                onClose={() => {
                  this.setState({ createAdsIsOpen: false });
                }}
              />
            )}

          {this.state.appDistribution && this.state.imgSelected && (
            <AppDistribution
              open={this.state.appDistribution}
              data={{
                uuid: this.props.tabloid.uuid,
                offerId: this.props.tabloid.id_tabloide_digital,
                offerName: this.props.tabloid.nome_tabloide_digital,
                imageId: this.state.imgSelected.id,
                imageName: this.state.imgSelected.nome_modelo,
                imageUrl: this.state.imgSelected.link,
                imageType: this.state.imgSelected.tipoPixelixe
              }}
              onClose={() => {
                this.setState({ appDistribution: false });
              }}
            />
          )}

          {this.state.pushNotificationDistribution && this.state.imgSelected && (
            <PushNotificationDistribution
              open={this.state.pushNotificationDistribution}
              data={{
                uuid: this.props.tabloid.uuid,
                offerId: this.props.tabloid.id_tabloide_digital,
                offerName: this.props.tabloid.nome_tabloide_digital,
                imageId: this.state.imgSelected.id,
                imageName: this.state.imgSelected.nome_modelo,
                imageUrl: this.state.imgSelected.link,
                imageType: this.state.imgSelected.tipoPixelixe
              }}
              onClose={() => {
                this.setState({ pushNotificationDistribution: false });
              }}
            />
          )}

          {this.state.bannerDistribution && this.state.imgSelected && (
            <BannerDistribution
              open={this.state.bannerDistribution}
              data={{
                uuid: this.props.tabloid.uuid,
                offerId: this.props.tabloid.id_tabloide_digital,
                offerName: this.props.tabloid.nome_tabloide_digital,
                imageId: this.state.imgSelected.id,
                imageName: this.state.imgSelected.nome_modelo,
                imageUrl: this.state.imgSelected.link,
                imageType: this.state.imgSelected.tipoPixelixe
              }}
              onClose={() => {
                this.setState({ bannerDistribution: false });
              }}
            />
          )}

          {this.state.statisticsIsOpen && this.state.imgSelected && (
            <Statistics
              open={this.state.statisticsIsOpen}
              offerId={this.props.tabloid.id_tabloide_digital}
              imageId={this.state.imgSelected.id}
              onClose={() => {
                this.setState({ statisticsIsOpen: false });
              }}
            />
          )}

          {this.state.canvaIsOpen && (
            <Canva
              open={this.state.canvaIsOpen}
              offerId={this.props.tabloid.id_tabloide_digital}
              openCanvaFromStudio={this.state.openCanvaFromStudio}
              designCanvaFromStudio={this.state.designCanvaFromStudio}
              onClose={() => {
                this.onRefresh();
                this.setState({ canvaIsOpen: false, openCanvaFromStudio: false });
              }}
            />
          )}

          {this.state.importImageIsOpen && (
            <ImportImage
              open={this.state.importImageIsOpen}
              offerId={this.props.tabloid.id_tabloide_digital}
              importType={this.state.importType}
              onClose={() => {
                this.onRefresh();
                this.setState({ importImageIsOpen: false });
              }}
            />
          )}

          {this.state.polotnoIsOpen && (
            <PolotnoEditor
              open={this.state.polotnoIsOpen}
              flyerId={this.state.imgSelected && this.state.imgSelected.id}
              offerId={this.props.tabloid.id_tabloide_digital}
              onClose={() => {
                this.setState({ polotnoIsOpen: false });
              }}
            />
          )}

          {this.state.videosIsOpen && (
            <CreateVideo
              open={this.state.videosIsOpen}
              tabloid={{
                qtde_produtos_lista: this.props.tabloid.produtos.length
              }}
              onClose={() => {
                this.setState({ videosIsOpen: false });
                this.onRefreshVideomatik(this.state.id, false);
              }}
            />
          )}

          {this.state.newReadyModels && (
            <ReadyModels
            //open={ this.state.newReadyModels }
            //onClose={() => {
            //  this.setState({ newReadyModels: false });
            // }}
            />
          )}

          {this.state.editFlyerIsOpen && (
            <EditFlyer
              open={this.state.editFlyerIsOpen}
              flyerId={this.state.imgSelected && this.state.imgSelected.id}
              offerId={this.props.tabloid.id_tabloide_digital}
              onClose={() => {
                this.setState({ editFlyerIsOpen: false });
              }}
            />
          )}

          {this.state.multipleDistributionIsOpen && (
            <AdsDistribution
              isOpened={this.state.multipleDistributionIsOpen}
              uuid={this.props.tabloid.uuid}
              offerId={this.props.tabloid.id_tabloide_digital}
              offerName={this.props.tabloid.nome_tabloide_digital}
              onChangeDistributionState={changeState => {
                this.setState({ multipleDistributionIsOpen: changeState });
              }}
            />
          )}
        </>

        {/* OS MENUS EXISTENTES */}
        <>
          <Menu
            classes={{ paper: classes.menuPaper }}
            anchorEl={this.state.videoAnchorEl}
            TransitionComponent={Fade}
            open={Boolean(this.state.videoAnchorEl)}
            onClose={() => {
              this.setState({ videoAnchorEl: null });
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  this.setState({
                    videoAnchorEl: null,
                    downloadVidIsOpen: true
                  });
                }}
              >
                <ListItemIcon>
                  <LaunchIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Abrir</Typography>
              </MenuItem>

              {/*!sem_distribuição && (
                <MenuItem
                  onClick={() => {
                    this.setState({
                      videoAnchorEl: null,
                      distributeIsOpen: true,
                    });
                  }}>
                  <ListItemIcon>
                    <ShareOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Distribuir</Typography>
                </MenuItem>
                )*/}
            </MenuList>
          </Menu>

          <Menu
            id={'flyerMenu'}
            classes={{ paper: classes.menuPaper }}
            anchorEl={this.state.anchorEl}
            TransitionComponent={Fade}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null });
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  this.setState({ anchorEl: null, downloadImgIsOpen: true });
                }}
              >
                <ListItemIcon>
                  <LaunchIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Abrir</Typography>
              </MenuItem>

              {/* NOVO ESTUDIO */}
              {this.state.imgSelected &&
                [
                  'cartaz',
                  'email',
                  'encarte',
                  'encarteMenor',
                  'etiqueta',
                  'facebook',
                  'instagram',
                  'whatsapp',
                  'redes sociais',
                  'indoor'
                ].includes(this.state.imgSelected.tipoPixelixe) && (
                  <MenuItem
                    onClick={() => {
                      this.setState({ anchorEl: null });
                      if (this.state.imgSelected.tipoPixelixe.includes('canva')) {
                        window.canvaApi.editDesign({
                          design: {
                            id: this.state.imgSelected.nome_modelo.split(' ')[0]
                          },
                          onDesignPublish: async ({ designId, designTitle, exportUrl }) => {
                            await Fetch(
                              `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/canva-design/${this.state.imgSelected.id}`,
                              {
                                ...this.state.imgSelected,
                                designTitle,
                                nome_modelo: `${designId} - ${designTitle}`,
                                exportUrl
                              },
                              'PUT',
                              null,
                              null,
                              false
                            ).then(() => {
                              this.onRefresh();
                            });
                          }
                        });
                      } else {
                        if (!this.state.imgSelected.link_html) {
                          alert('Não é possível editar no momento. Tente reprocessar.');
                          return null;
                        }

                        this.setState({
                          pixelixeIframe_open: true
                        });
                      }
                    }}
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Editar</Typography>
                  </MenuItem>
                )}

              {this.state.imgSelected && this.state.imgSelected.is_new_studio && (
                <MenuItem
                  id={'menuEditarNovo-id-' + this.state.imgSelected.id}
                  onClick={() => this.editarNovoMenuClick()}
                >
                  <ListItemIcon>
                    <EditOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Editar (Novo)</Typography>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  this.setState({ duplicateTabloid: true });
                }}
              >
                <ListItemIcon>
                  <FileCopyOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Duplicar arte</Typography>
              </MenuItem>

              {this.state.duplicateTabloid &&
                (console.log('Abrir modal'),
                  (
                    <AlertDialogV2
                      open={this.state.duplicateTabloid}
                      loading={false}
                      contentText="Deseja duplicar essa arte?"
                      handleAgree={async () => {
                        this.setState({ anchorEl: null });
                        this.props.enqueueSnackbar('Processando...', {
                          variant: 'info'
                        });
                        this.handleDuplicateEncarte(this.state.imgSelected?.fullItem)
                          .then(resp => {
                            if (resp.message == 'success') {
                              this.getEncartesNovoEstudio(this.state.id);
                            }
                          })
                          .catch(err => {
                            this.props.enqueueSnackbar(
                              'Falha de processamento',
                              {
                                variant: 'error'
                              },
                              console.log(err)
                            );
                          });
                        this.setState({ duplicateTabloid: false });
                      }}
                      handleClose={() => {
                        this.setState({ duplicateTabloid: false });
                      }}
                      textAgree="Sim"
                      textClose="Não"
                    />
                  ))}

              {/*<MenuItem
                onClick={() => {
                  this.state.imgSelected &&
                    this.handleDownloadImage(
                      this.state.imgSelected.link,
                      this.state.imgSelected.nome_modelo
                    );
                }}>
                <ListItemIcon>
                  <GetAppIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Baixar</Typography>
              </MenuItem>*/}

              {this.state.imgSelected && this.state.imgSelected.is_new_studio && (
                <MenuItem
                  onClick={() => {
                    this.setState({
                      anchorEl: null,
                      openDialogMeuModeloName: true
                    });
                  }}
                >
                  <ListItemIcon>
                    <ControlPointDuplicateIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Criar Meu Modelo (Novo)</Typography>
                </MenuItem>
              )}
              {/* <MenuItem
                onClick={() => {
                  this.setState({ anchorEl: null });
                }}
              >
                <ListItemIcon>
                  <ShareOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Compartilhar</Typography>
              </MenuItem> */}

              {/*!sem_distribuição && (
                <MenuItem
                  onClick={() => {
                    this.setState({ anchorEl: null, distributeIsOpen: true });
                  }}>
                  <ListItemIcon>
                    <ShareOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Distribuir</Typography>
                </MenuItem>
                )*/}

              {this.state.imgSelected &&
                [
                  'cartaz',
                  'email',
                  'encarte',
                  'encarteMenor',
                  'etiqueta',
                  'facebook',
                  'indoor',
                  'redes sociais',
                  'whatsapp'
                ].includes(this.state.imgSelected.tipoPixelixe) && (
                  <MenuItem
                    onClick={() =>
                      this.setState({
                        anchorEl: null,
                        openCriarTemplate: true,
                        onCreateNovoModelo: () => {
                          this.props.enqueueSnackbar('Processando...', {
                            variant: 'info'
                          });
                          this.props
                            .createTemplatePersonalizado({
                              id: this.state.imgSelected.id,
                              id_tabloide: this.state.imgSelected.id_tabloide,
                              modelo: this.state.imgSelected.modelo,
                              pagina: this.state.imgSelected.pagina,
                              link: this.state.imgSelected.link,
                              link_html: this.state.imgSelected.link_html,
                              code: this.state.imgSelected.code,
                              id_empresa: this.state.imgSelected.id_empresa,
                              tipoPixelixe: this.state.imgSelected.tipoPixelixe,
                              from: this.state.imgSelected.from || null,
                              data: moment().format('YYYY-MM-DD hh:mm:ss'),
                              nome_modelo: this.state.nomeModelo || null,
                              ocasiaoModelo: this.state.ocasiaoModelo || null,
                              qtdeModelo: this.state.qtdeModelo || null,
                              add_template: true
                            })
                            .then(resp => {
                              this.props.enqueueSnackbar(resp.message, {
                                variant: 'success'
                              });
                              this.onRefresh();
                              this.setState({
                                onCreateNovoModelo: null,
                                nomeModelo: null,
                                ocasiaoModelo: 'Meus Modelos',
                                qtdeModelo: null,
                                openCriarTemplate: false
                              });
                            })
                            .catch(err => {
                              this.props.enqueueSnackbar('Falha de processamento', {
                                variant: 'error'
                              });
                            });
                        }
                      })
                    }
                  >
                    <ListItemIcon>
                      <ControlPointDuplicateIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Criar Meu Modelo</Typography>
                  </MenuItem>
                )}

              {this.state.imgSelected && !this.state.imgSelected.is_new_studio && (
                <MenuItem
                  onClick={() => {
                    this.setState({ anchorEl: null, showDialog: true });
                  }}
                >
                  <ListItemIcon>
                    <BlockOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Desativar</Typography>
                </MenuItem>
              )}

              {/*this.state.imgSelected && this.state.imgSelected.is_new_studio && (
                <MenuItem
                  onClick={() => {
                    this.setState({
                      anchorEl: null,
                      showDialogDesativarNovoEstudio: true,
                    });
                  }}>
                  <ListItemIcon>
                    <BlockOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Desativar</Typography>
                </MenuItem>
                )*/}
            </MenuList>
          </Menu>

          <Menu
            id="basic-menu"
            anchorEl={this.state.downloadAnchorEl}
            open={Boolean(this.state.downloadAnchorEl)}
            onClose={() => {
              this.setState({ downloadAnchorEl: null });
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  this.setState({ downloadAnchorEl: null });
                  this.handleDownloadZip();
                }}
              >
                <span className={classes.spanMenu}>
                  <Box className={classes.boxMenu1} style={{ color: '#2813AD' }}>
                    <Box className={classes.boxMenu2}>
                      <FolderZipIcon />
                    </Box>
                    <Box
                      className={[classes.boxMenu3, classes.title4]}
                      style={{ color: '#2813AD' }}
                    >
                      zip
                    </Box>
                  </Box>
                </span>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  this.setState({ downloadAnchorEl: null });
                  this.normalizeArrayPDF(false);
                }}
              >
                <span className={classes.spanMenu}>
                  <Box className={classes.boxMenu1} style={{ color: '#2813AD' }}>
                    <Box className={classes.boxMenu2}>
                      <PictureAsPdfIcon />
                    </Box>
                    <Box
                      className={[classes.boxMenu3, classes.title4]}
                      style={{ color: '#2813AD' }}
                    >
                      pdf
                    </Box>
                  </Box>
                </span>
              </MenuItem>
            </MenuList>
          </Menu>
        </>

        <ProductsFooter
          show={true}
          isDistributionOpen={this.state.multipleDistributionIsOpen}
          onDistributeCarousel={() => {
            this.setState({ multipleDistributionIsOpen: true });
          }}
          onClearDistribution={() => {
            this.setState({ multipleDistributionIsOpen: false });
          }}
          onDisableManyItems={items => {
            this.handleDisableItems(items);
          }}
          onDownloadImage={() => {
            const countSelectedItems = this.props.selectedShareableItem.length;

            if (countSelectedItems > 0) {
              let message = `Deseja Baixar ${countSelectedItems} Item${countSelectedItems > 1 ? 's' : ''
                }`;

              _alert.alertConfirm(message, () => {
                this.props.selectedShareableItem.map(
                  ({ id, name, type, image_url, link }, index) => {
                    const cardName = `${name}-${type}-${id}-Datasales-crm`.toUpperCase();
                    const cardURL = link ? link : image_url ? image_url.split('?')[0] : null;
                    const isVideo = link ? true : false;

                    this.handleDownloadCard(cardURL, cardName, isVideo);
                  }
                );
              });
            }
          }}
          onDownloadZipFooter={() => {
            for (i = 0; i < this.props.selectedShareableItem.length; i++) {
              if (this.props.selectedShareableItem[i].tipo === 1) {
                return _alert.alertError(
                  'Atenção!!!',
                  'Arquivos do tipo video não podem ser zipados, baixe-os separadamente.'
                );
              }
            }
            let message = `Deseja Baixar ${this.props.selectedShareableItem.length} Item${this.props.selectedShareableItem.length > 1 ? 's' : ''
              }`;
            _alert.alertConfirm(message, () => {
              return this.handleDownloadZip(
                this.props.selectedShareableItem.map(item => ({
                  ...item,
                  image_url: item.image_url ? item.image_url.split('?')[0] : null
                }))
              );
            });
          }}
          onDownloadPdf={() => {
            this.normalizeArrayPDF(true, false);
          }}
          onDownloadPdfTrimArea={() => {
            this.normalizeArrayPDF(true, true);
          }}
          progressDownload={this.state.progressDownload}
        />

        <DialogWarningVideoInPdf
          isOpen={this.state.dialogPDF}
          onClose={() => { } /*this.setState({ dialogPDF: false })*/}
          onDownloadPDF={() => {
            this.handleDownloadPdf(this.props.selectedShareableItem);
          }}
        />
      </>
    );
  }
}

const styles = theme => ({
  categoryTitle: {
    fontWeight: '600',
    fontSize: 26
  },
  root: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    letterSpacing: '0.05em'
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end'
    // marginBottom: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#312a5e !important'
    }
  },

  divider: {
    marginBottom: theme.spacing(4)
  },
  ul: {
    width: 'fit-content',
    margin: 'auto'
  },

  rootCard: {
    width: 200
  },
  media: {
    height: 0,
    paddingTop: '300px'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  },
  gridList: {
    width: '100%'
    // flexWrap: 'nowrap',
    // // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.common.white
  },
  titleBar: {
    borderRadius: theme.shape.borderRadius,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)'
  },
  img: {
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    objectPosition: 'top'
  },
  menuPaper: {
    border: '1px solid #d3d4d5',
    width: 300
  },
  menuList: {
    padding: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    marginLeft: '0px'
  },
  menuItem: {
    borderRadius: 4,
    padding: '12px',
    width: '100%'
  },
  boxTitles: {
    display: 'flex'
    // padding: '32px 0 0'
  },
  // -------------------------------
  // -------------------------------
  // -------------------------------
  title1: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '32px',
    color: '#1D1D1D',
    letterSpacing: '0.05em'
  },
  'title1-2': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D',
    letterSpacing: '0.05em'
  },
  title2: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D',
    letterSpacing: '0.05em'
  },
  title3: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#616161',
    letterSpacing: '0.05em'
  },
  title4: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    color: '#127FFF',
    letterSpacing: '0.05em'
  },
  title5: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D',
    letterSpacing: '0.05em'
  },
  spanMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 2,
    marginRight: 15,
    color: color1
  },
  boxMenu1: {
    display: 'flex',
    alignContent: 'space-around',
    cursor: 'pointer'
  },
  boxMenu2: {
    display: 'flex',
    alignItems: 'center'
  },
  boxMenu3: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  btnMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '12px 16px',
    marginLeft: 15
  }
});

const color1 = '#312A5E';
const color2 = '#127FFF';

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  getOfertaFisicoAll,
  deleteEncarteFisico,
  changeCategoriaIndex,
  changeCategoriaIndexSubtract,
  createTemplatePersonalizado,
  getVideomatik,
  putOfertaFisico,
  clearSelectedShareableItem,
  clearSelectedScheduledItems,
  setTabloide,
  setSelectedShareableItem,
  setFlyersToSelectWhenRedirectToOffer
};

const ListaOfertasEncarteConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaOfertasEncarte);

export default withRouter(withSnackbar(withStyles(styles)(ListaOfertasEncarteConnect)));

