import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Tab, Typography, Divider } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import Overview from '../../components/Overview';
import Facebook from '../../components/Facebook';
import Instagram from '../../components/Instagram';
import Whatsapp from '../../components/Whatsapp';
import Others from '../../components/Others';
import useStyles from '../../styles';
import { useHome } from '../../../../hooks/home';
import useDate from '../../../../hooks/date';
import ActionsTurboCharged from "../../components/ActionsTurboCharged";


const tabs = [
  { label: 'Visão Geral', key: 0 },
  { label: 'Facebook', key: 1 },
  { label: 'Instagram', key: 2 },
  { label: 'Whatsapp', key: 3 },
  { label: 'Outros', key: 4 },
  { label: 'Impulsionamento', key: 5 },
];

export default function () {
  const classes = useStyles(),
    [currentTab, setCurrentTab] = useState(0),
    { getCampaigns, getCountCampaigns } = useHome();

  const { getMoment } = useDate(),
    [campaignNameSearch, setCampaignNameSearch] = useState(null),
    [startDate, setStartDate] = useState(
      getMoment().subtract(1, 'month').format('YYYY-MM-DD')
    ),
    [endDate, setEndDate] = useState(
      getMoment().weekday(7).format('YYYY-MM-DD')
    );

  const getCampaignsWithParams = () => {
    getCampaigns({
      nome_campanha: campaignNameSearch,
      filtroDataInicio: startDate,
      filtroDataFim: endDate,
    });
  };

  useEffect(() => {
    getCampaignsWithParams();
    getCountCampaigns();
  }, []);

  function handleChangeTab(_, newValue) {
    setCurrentTab(newValue);
  }

  const params = {
    startDate: startDate,
    endDate: endDate,
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    campaignNameSearch: campaignNameSearch,
    setCampaignNameSearch: setCampaignNameSearch,
    getCampaigns: getCampaignsWithParams,
  };

  return (
    <Box className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography color="primary" className={classes.title}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Tabs
            value={currentTab}
            indicatorColor="transparent"
            onChange={handleChangeTab}
          >
            {tabs.map(tab => (
              <Tab
                key={tab.key}
                style={{ paddingLeft: tab.key === 0 ? 0 : null }}
                label={tab.label}
                disableTouchRipple
                className={
                  currentTab === tab.key ? classes.tabActive : classes.tab
                }
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TabPanel tab={currentTab} value={0}>
            <Overview />
          </TabPanel>
          <TabPanel tab={currentTab} value={1}>
            <Facebook {...params} />
          </TabPanel>
          <TabPanel tab={currentTab} value={2}>
            <Instagram {...params} />
          </TabPanel>
          <TabPanel tab={currentTab} value={3}>
            <Whatsapp {...params} />
          </TabPanel>
          <TabPanel tab={currentTab} value={4}>
            <Others {...params} />
          </TabPanel>
          <TabPanel tab={currentTab} value={5}>
            <ActionsTurboCharged {...params} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}
