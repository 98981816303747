import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox } from '@mui/material';
//import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLayoutEffect, useState } from 'react';

import EmptyImage from '../../../../../../assets/icons/marketing/EmptyImage.svg';
import EmptyImageRed from '../../../../../../assets/icons/marketing/EmptyImageRed.svg';
import { inputMoney } from '../../../../../../utils/helpers/Functions';
import { Celular3, CEP, removeLetters } from '../../../../../../utils/helpers/Masks';
import { ImageCropper } from '../ImageCropper';
import { useStyles } from './styles';

export function FormEditLoja(props) {
  const classes = useStyles();
  const [moreSettings, setMoreSettings] = useState(false);
  const [dataStore, setDataStore] = useState({});
  const [checkboxRemoverFundo, setCheckboxRemoverFundo] = useState(false);

  const [imageToCrop, setImageToCrop] = useState('');
  const [imageToCropOriginal, setImageToCropOriginal] = useState('');
  const [croppedImage, setCroppedImage] = useState('');

  const options = [
    { label: 'Delivery', value: 1 },
    { label: 'Drive', value: 2 },
    { label: 'Delivery/Drive', value: 3 }
  ];
  /*const VALIDATION = Yup.object().shape({
    nome_loja: Yup.string().required('*Campo obrigatório')
  })*/

  const handleSubmit = () => {
    setCheckboxRemoverFundo(false);
    setMoreSettings(false);
    props.onSave(dataStore);
  };
  
  let formik = useFormik({
    /*initialValues: {
      id_loja: '',
      nome_loja: '',
      cep:  props.data.cep || '',
      endereco:  props.data.endereco || '',
      bairro:  props.data.bairro || '',
      cidade:  props.data.cidade || '',
      uf:  props.data.uf || '',
      numero:  props.data.numero || '',
      tipo_pedido:  props.data.tipo_pedido || null,
      forma_pagamento:  props.data.forma_pagamento || [],
      frete:  props.data.frete || '0',
      taxa_servico:  props.data.taxa_servico || '0',
      maximo_item: props.data.maximo_item || '0',
      minimo_compra: props.data.minimo_compra || '0',
      descricao:  props.data.descricao || '',
      whatsapp_phone_number:  props.data.whatsapp_phone_number || '',
      aceita_dinheiro:  props.data.aceita_dinheiro ||false,
      aceita_debito:  props.data.aceita_debito || false,
      aceita_credito:  props.data.aceita_credito || false,
      aceita_boleto: props.data.aceita_boleto || false,
      aceita_vr:  props.data.aceita_vr || false,
      aceita_pix:  props.data.aceita_pix || false,
      chave_pix:  props.data.chave_pix || '',
      base64: null
    },
    validationSchema: VALIDATION,*/
    onSubmit: handleSubmit
  });

  const handleClose = () => {
    setMoreSettings(false);
    props.onClose();
  };
  useLayoutEffect(() => {
    setDataStore({ ...props.data, naoRemoverFundo: true });
  }, [props.open]);

  const onUploadFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImageToCrop(reader.result);
        setImageToCropOriginal(reader.result);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Dialog
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          fullWidth
          classes={{ paperFullWidth: classes.dialogWidth }}
        >
          <DialogContent>
            <Box className={classes.boxRoot}>
              <Box className={classes.boxRootImg}>
                <Box
                  className={classes.boxImg}
                  bgcolor={Boolean(formik.errors.base64) ? '#D54D4D0D' : '#F5f5f5'}
                  border={Boolean(formik.errors.base64) ? '1px solid #D54D4D' : null}
                >
                  {imageToCrop && (
                    <ImageCropper
                      imageToCrop={imageToCrop}
                      onImageCropped={croppedImage => setCroppedImage(croppedImage)}
                    />
                  )}

                  {!imageToCrop && (
                    <img
                      src={
                        dataStore.logo1
                          ? dataStore.logo1
                          : Boolean(formik.errors.base64)
                          ? EmptyImageRed
                          : EmptyImage
                      }
                      alt={'image to up'}
                      style={{
                        width: dataStore.logo1 ? '100%' : null,
                        maxHeight: dataStore.logo1 ? '100%' : null,
                        objectFit: 'contain'
                      }}
                    />
                  )}
                </Box>
                <Box alignSelf="flex-start">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={onUploadFile}
                    // onChange={async e => {
                    //   console.log(e.target.files[0]);
                    //   const imageEdit = await toBase64(e.target.files[0]);
                    //   setDataStore({...dataStore, logo1: imageEdit.base64});
                    //   console.log({imageEdit})
                    //   setCheckboxRemoverFundo(true);
                    //   //formik.setFieldValue('base64', imageEdit.base64);
                    // }}
                  />

                  {!imageToCrop && (
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" component="span" className={classes.buttonImg}>
                        Adicionar logo
                        {/*base64 && <DoneAllIcon style={{ marginLeft: 10 }} />*/}
                      </Button>
                    </label>
                  )}

                  {imageToCrop && (
                    <Button
                      variant="outlined"
                      className={classes.buttonImg}
                      onClick={() => {
                        setDataStore({ ...dataStore, logo1: croppedImage });
                        setCheckboxRemoverFundo(true);
                        setImageToCrop(undefined);
                        // setCroppedImage(undefined);
                      }}
                    >
                      Cortar
                    </Button>
                  )}

                  {!!croppedImage && !imageToCrop && (
                    <Button
                      variant="outlined"
                      className={classes.buttonImg}
                      onClick={() => {
                        setDataStore({ ...dataStore, logo1: imageToCropOriginal });
                        setCroppedImage(imageToCropOriginal);
                        setImageToCrop(imageToCropOriginal);
                      }}
                    >
                      Cortar Novamente
                    </Button>
                  )}
                </Box>
                {checkboxRemoverFundo && (
                  <Box alignSelf="flex-start" className={classes.checkboxRemoverFundo}>
                    <Checkbox
                      onChange={({ target }) => {
                        setDataStore({ ...dataStore, naoRemoverFundo: Boolean(!target.checked) });
                      }}
                      name="Remover fundo da imagem."
                    />{' '}
                    Remover fundo da imagem.
                  </Box>
                )}
              </Box>
              <Box className={classes.boxContent} disabled={!!imageToCrop}>
                <Typography className={classes.title}>Configurações da loja</Typography>

                <Typography className={classes.subTitle}>
                  Edite as informações da sua loja.
                </Typography>

                <TextField
                  className={classes.textFormTest}
                  id="nome_loja"
                  label="Nome"
                  variant="outlined"
                  defaultValue={dataStore.nome_loja}
                  error={Boolean(formik.errors.nome_loja)}
                  helperText={Boolean(formik.errors.nome_loja) && formik.errors.nome_loja}
                  onChange={({ target: { value } }) => {
                    setDataStore({ ...dataStore, nome_loja: value });
                    //formik.setFieldValue('nome_loja', value);
                  }}
                  classes={{ root: classes.formFields }}
                />

                <TextField
                  className={classes.textFormTest}
                  id="outlined-basic"
                  label="Whatsapp"
                  variant="outlined"
                  value={dataStore.whatsapp_phone_number}
                  error={Boolean(formik.errors.whatsapp_phone_number)}
                  helperText={
                    Boolean(formik.errors.whatsapp_phone_number) &&
                    formik.errors.whatsapp_phone_number
                  }
                  onChange={({ target: { value } }) => {
                    setDataStore({ ...dataStore, whatsapp_phone_number: Celular3(value) });
                    //formik.setFieldValue('whatsapp_phone_number', value);
                  }}
                  classes={{ root: classes.formFields }}
                />

                <section className={classes.sectionsTwoTextfield}>
                  <TextField
                    className={classes.textFormTest}
                    id="outlined-basic"
                    label="CEP"
                    variant="outlined"
                    value={dataStore.cep}
                    error={Boolean(formik.errors.name)}
                    helperText={Boolean(formik.errors.name) && formik.errors.name}
                    onChange={({ target: { value } }) => {
                      setDataStore({ ...dataStore, cep: CEP(value) });
                      //formik.setFieldValue('cep', value);
                    }}
                    classes={{ root: classes.formFields }}
                  />

                  <TextField
                    className={classes.textFormTest}
                    id="outlined-basic"
                    label="UF"
                    variant="outlined"
                    value={dataStore.uf}
                    error={Boolean(formik.errors.name)}
                    helperText={Boolean(formik.errors.name) && formik.errors.name}
                    onChange={({ target: { value } }) => {
                      setDataStore({ ...dataStore, uf: value });
                      //formik.setFieldValue('uf', value);
                    }}
                    classes={{ root: classes.formFields }}
                  />
                </section>

                <TextField
                  className={classes.textFormTest}
                  id="outlined-basic"
                  label="Endereço"
                  variant="outlined"
                  defaultValue={dataStore.endereco}
                  error={Boolean(formik.errors.endereco)}
                  helperText={Boolean(formik.errors.endereco) && formik.errors.endereco}
                  onChange={({ target: { value } }) => {
                    setDataStore({ ...dataStore, endereco: value });
                    //formik.setFieldValue('endereco', value);
                  }}
                  classes={{ root: classes.formFields }}
                />

                <TextField
                  className={classes.textFormTest}
                  id="outlined-basic"
                  label="Bairro"
                  variant="outlined"
                  defaultValue={dataStore.bairro}
                  error={Boolean(formik.errors.bairro)}
                  helperText={Boolean(formik.errors.bairro) && formik.errors.bairro}
                  onChange={({ target: { value } }) => {
                    setDataStore({ ...dataStore, bairro: value });
                    //formik.setFieldValue('bairro', value);
                  }}
                  classes={{ root: classes.formFields }}
                />

                <TextField
                  className={classes.textFormTest}
                  id="outlined-basic"
                  label="Cidade"
                  variant="outlined"
                  defaultValue={dataStore.cidade}
                  error={Boolean(formik.errors.cidade)}
                  helperText={Boolean(formik.errors.cidade) && formik.errors.cidade}
                  onChange={({ target: { value } }) => {
                    setDataStore({ ...dataStore, cidade: value });
                    //formik.setFieldValue('cidade', value);
                  }}
                  classes={{ root: classes.formFields }}
                />

                {moreSettings && (
                  <section>
                    <Divider style={{ marginBottom: '24px', color: '#CFCFCF' }} />
                    <Typography className={classes.title}>Configurações de site</Typography>

                    <Typography className={classes.subTitle}>
                      Configure as informações de site
                    </Typography>

                    <FormControl
                      color="primary"
                      component="fieldset"
                      className={classes.checkboxFormPag}
                    >
                      <FormLabel component="legend">Formas de pagamento</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_dinheiro}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_dinheiro: target.checked });
                              }}
                              name="aceita_dinheiro"
                            />
                          }
                          label="Dinheiro"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_debito}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_debito: target.checked });
                              }}
                              name="aceita_debito"
                            />
                          }
                          label="Debito"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_credito}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_credito: target.checked });
                              }}
                              name="aceita_credito"
                            />
                          }
                          label="Credito"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_boleto}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_boleto: target.checked });
                              }}
                              name="aceita_boleto"
                            />
                          }
                          label="Boleto"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_vr}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_vr: target.checked });
                              }}
                              name="aceita_vr"
                            />
                          }
                          label="VR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="info"
                              defaultChecked={dataStore.aceita_pix}
                              onChange={({ target }) => {
                                setDataStore({ ...dataStore, aceita_pix: target.checked });
                              }}
                              name="aceita_pix"
                            />
                          }
                          label="PIX"
                        />
                      </FormGroup>
                    </FormControl>
                    <Autocomplete
                      freeSolo={true}
                      options={options}
                      getOptionLabel={option => option.label}
                      onChange={(e, value) => {
                        setDataStore({ ...dataStore, tipo_pedido: value.value });
                        //formik.setFieldValue('tipo_pedido', value);
                      }}
                      defaultValue={options[parseInt(dataStore.tipo_pedido) - 1]}
                      renderInput={params => (
                        <TextField
                          className={classes.textFormTest}
                          {...params}
                          id="outlined-basic"
                          label="Tipo de pedido"
                          variant="outlined"
                          //defaultValue={dataStore.tipo_pedido}
                          error={Boolean(formik.errors.tipo_pedido)}
                          helperText={
                            Boolean(formik.errors.tipo_pedido) && formik.errors.tipo_pedido
                          }
                          classes={{ root: classes.formFields }}
                        />
                      )}
                    />

                    <section className={classes.sectionsTwoTextfield}>
                      <TextField
                        className={classes.textFormTest}
                        id="outlined-basic"
                        label="Taxa de Frete"
                        variant="outlined"
                        value={dataStore.frete}
                        error={Boolean(formik.errors.frete)}
                        helperText={Boolean(formik.errors.frete) && formik.errors.frete}
                        onChange={({ target: { value } }) => {
                          setDataStore({ ...dataStore, frete: inputMoney(value) });
                          //formik.setFieldValue('frete', value);
                        }}
                        classes={{ root: classes.formFields }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>
                        }}
                      />

                      <TextField
                        className={classes.textFormTest}
                        id="outlined-basic"
                        label="Taxa de serviço"
                        variant="outlined"
                        value={dataStore.taxa_servico}
                        error={Boolean(formik.errors.taxa_servico)}
                        helperText={
                          Boolean(formik.errors.taxa_servico) && formik.errors.taxa_servico
                        }
                        onChange={({ target: { value } }) => {
                          setDataStore({ ...dataStore, taxa_servico: inputMoney(value) });
                          //formik.setFieldValue('taxa_servico', value);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>
                        }}
                        classes={{ root: classes.formFields }}
                      />
                    </section>

                    <section className={classes.sectionsTwoTextfield}>
                      <TextField
                        className={classes.textFormTest}
                        id="outlined-basic"
                        label="Quantidade máxima de itens"
                        variant="outlined"
                        value={dataStore.maximo_item}
                        error={Boolean(formik.errors.maximo_item)}
                        helperText={Boolean(formik.errors.maximo_item) && formik.errors.maximo_item}
                        onChange={({ target: { value } }) => {
                          setDataStore({ ...dataStore, maximo_item: removeLetters(value) });
                          //formik.setFieldValue('maximo_item', value);
                        }}
                        classes={{ root: classes.formFields }}
                      />

                      <TextField
                        className={classes.textFormTest}
                        id="outlined-basic"
                        label="Valor mínimo de compra"
                        variant="outlined"
                        value={dataStore.minimo_compra}
                        error={Boolean(formik.errors.minimo_compra)}
                        helperText={
                          Boolean(formik.errors.minimo_compra) && formik.errors.minimo_compra
                        }
                        onChange={({ target: { value } }) => {
                          setDataStore({ ...dataStore, minimo_compra: inputMoney(value) });
                          //formik.setFieldValue('minimo_compra', value);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>
                        }}
                        classes={{ root: classes.formFields }}
                      />
                    </section>

                    <TextField
                      className={classes.textFormTest}
                      id="outlined-basic"
                      label="Chave PIX"
                      variant="outlined"
                      defaultValue={dataStore.chave_pix}
                      error={Boolean(formik.errors.chave_pix)}
                      helperText={Boolean(formik.errors.chave_pix) && formik.errors.chave_pix}
                      onChange={({ target: { value } }) => {
                        setDataStore({ ...dataStore, chave_pix: value });
                        //formik.setFieldValue('chave_pix', value);
                      }}
                      style={{ width: '49%' }}
                      classes={{ root: classes.formFields }}
                    />
                  </section>
                )}

                <Box className={classes.boxButtons}>
                  <Button onClick={handleClose} className={classes.buttonCancel}>
                    Cancelar
                  </Button>
                  <Button onClick={handleSubmit} className={classes.buttonSalve}>
                    Salvar
                  </Button>
                </Box>
                {!moreSettings && (
                  <Button
                    onClick={() => {
                      setMoreSettings(!moreSettings);
                    }}
                    className={classes.typographyMoreSettings}
                  >
                    Ver mais configurações
                  </Button>
                )}
                {moreSettings && (
                  <Button
                    onClick={() => {
                      setMoreSettings(!moreSettings);
                    }}
                    className={classes.typographyMoreSettings}
                  >
                    Ver menos
                  </Button>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </form>
  );
}

