/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Stepper,
  Step,
  StepButton,
  Divider,
  Typography,
  Icon,
  Button
} from "@material-ui/core";
import moment from "moment";
import {
  DatePicker,
  Select,
  TextField,
  Modal,
  MyButton
} from "../../../utils/components";

const steps = ["Informações de Envio", "Disparar"];

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

class NovaCampanhaCarta extends Component {
  state = { activeStep: 0 };

  header(props, state) {
    return (
      <Fragment>
        <strong>Nova Campanha</strong>
        <section className="modal-header">
          <span className="modal-subtitle">
            <strong>Tipo de Campanha: </strong>Rede Social
          </span>
        </section>
        <Divider style={{ marginTop: "2rem" }} />
        <div style={{ width: "100%" }}>
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          color="primary"
          onClick={() =>
            this.setState({
              activeStep: state.activeStep === 0 ? 0 : state.activeStep - 1
            })
          }
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!props.carta.nomeCampanha ? true : false}
          onClick={() =>
            this.setState({
              activeStep: state.activeStep === 1 ? 1 : state.activeStep + 1
            })
          }
        >
          Avançar
        </Button>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Informações da Campanha
          </Typography>
          <TextField
            label="Nome"
            className="form-control"
            onChange={({ target }) => {
              props.onChange("nomeCampanha", target.value);
            }}
            value={props.carta.nomeCampanha}
            autoComplete="none"
          />
          <Select
            label={"Audiência"}
            disabled
            options={props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao
            }))}
            placeholder="Selecione uma lista..."
            // onChange={option => {
            //   // props.onChange("optionList", option);
            //   // props.onChange("listaSelecionada", false);
            //   props.contadorCelular({
            //     celulares: props.email.selectedEmail.map(opt => opt.value),
            //     ...option
            //   });
            // }}
            value={props.carta.optionList}
          />
          <DatePicker
            dateAndTime
            label="Início da campanha"
            onChange={date => props.onChange("dataInicioCampanha", date)}
            value={props.carta.dataInicioCampanha}
          />

          <DatePicker
            dateAndTime
            label="Término da campanha"
            onChange={date => props.onChange("dataFimCampanha", date)}
            value={props.carta.dataFimCampanha}
          />
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Informações de Disparo
          </Typography>

          <DatePicker
            dateAndTime
            label="Envio Carta"
            onChange={date => props.onChange("dataInicio", date)}
            value={props.carta.dataInicio}
          />

          <TextField
            label="Custo campanha"
            onChange={({ target: { value } }) =>
              props.onChange("custoCampanha", value)
            }
            type="number"
            className="form-control"
          />
        </section>
      </section>
    );
  }

  step2(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre a Campanha
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome</strong>
              </p>
              <span>{props.carta.nomeCampanha}</span>
            </section>

            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Data Disparo</strong>
              </p>
              <span>
                {moment(props.carta.dataInicio).format("DD/MM/YYYY HH:mm")}
              </span>
            </section>
          </section>
        </section>

        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={props.onSave} size="large">
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, showModal } = this.props;
    const { activeStep } = this.state;
    return (
      <Modal
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
        size="lg"
        onClose={onClose}
        showModal={showModal}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const mapStateToProps = ({ CampaignReducer, InsightsReducer }) => ({
  ...CampaignReducer,
  ...InsightsReducer
});

const mapDispatchToProps = {};

const NovaCampanhaCartaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaCampanhaCarta);

export default withStyles(styles)(NovaCampanhaCartaConnect);
