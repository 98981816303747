/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  withStyles,
  CircularProgress,
  Button,
  IconButton,
  Popover,
  Icon,
  Select,
  MenuItem
} from '@material-ui/core';
import { CompactPicker } from 'react-color';
import {
  ImageCard,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import { 
  getTabloidsEtiquetas
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

class GenerateVideomatik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: "Encarte-A4-12P-CPD-inicio_do_inverno-001",
      qtde_produtos: 12,
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: {r: 0, g: 0, b: 0, a: 1},
      showDesativarModelo: false,
      etiquetas: [],
      etiqueta: null,
      font_size: null,
    };
  }    

  async componentDidMount() {  
    
  }

  header(props, state) {
    const steps = [
      'Formato'
    ];

    return (
      <Fragment>
        Gerar Arte - {this.props.tabloid.qtde_produtos_lista} Produtos no Total
        {/* <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div> */}
      </Fragment>
    );
  }

  footer(props, state) {
    const {
      activeStep
    } = this.state

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {/* {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep === 4 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              (this.state.activeStep === 4) && this.props.putOfertaFisico({ 
                id: this.props.tabloid.id_tabloide_digital,
                id_template: this.state.id_template,
                nome_modelo: this.state.nome_modelo,
                chave_template: this.state.template,
                produto_qtde: this.state.qtde_produtos,
                tipoPixelixe: this.props.tipoPixelixe,
                json: JSON.stringify(this.state.json_parse),
                etiqueta: this.state.etiqueta,
              }).then(() => this.props.onClose());
            }}
            disabled={false}
          >
            Próximo
          </Button>
        )} */}
      </div>
    );
  }

  step1(props, state) {
    let formato = {};

    formato = this.props.templates;

    return (
      <section>
        <section
          style={{ padding: '2rem 4rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Formato
          </Typography>
        </section>
        <Divider />
        <section
          style={{ 
            padding: '2rem 4rem',
            display: 'flex',
            justifyContent: 'center'
          }}
        >   
          {formato.map((value, index) => 
            <ImageCard 
              descricao={value.nome}
              style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
              image={value.miniatura}
              onClick={() => {
                this.setState({
                  //activeStep: this.state.activeStep + 1,
                  formato: value.id
                });
                this.props.onGenerate(this.props.tabloid.id_tabloide_digital, value.id);
                this.props.onClose();
              }}
            />
          )}
        </section>
      </section>
    );
  }  

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const {
      activeStep
    } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {this.state.showDesativarModelo && (
          <DialogAlert
            open={this.state.showDesativarModelo}
            title="Deseja desativar este modelo?"
            onClick={() =>
              this.setState({ showDesativarModelo: false }, () =>
                {
                  this.props.createTemplatePersonalizado({
                    id: this.state.id_template,
                    deletar: true
                  }).then(() => this.props.onClose());
                }
              )
            }
            onClose={() =>
              this.setState({ showDesativarModelo: false })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  }
});

const mapStateToProps = ({
  TabloideDigitalReducer
}) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  getTabloidsEtiquetas
};

const GenerateVideomatikConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateVideomatik);

export default withStyles(styles)(GenerateVideomatikConnect);
