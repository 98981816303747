import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Table from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import localization from '../../../../../../localization/table';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import { formatPrice } from '../../../../../../utils/helpers/Format';
import useStyles from './styles';

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

function renderChip(approved, classes) {
  switch (approved) {
    case null:
      return (
        <Chip
          className={classes.warning}
          icon={<HourglassEmptyIcon color="inherit" />}
          label="Aguardando aprovacao"
        />
      );

    case true:
      return (
        <Chip
          className={classes.success}
          icon={<CheckIcon color="inherit" />}
          label="Aprovado"
        />
      );

    case false:
      return (
        <Chip
          className={classes.error}
          icon={<CloseIcon color="inherit" />}
          label="Recusado"
        />
      );

    default:
      return null;
  }
}

function CampaignDetails({ open, campaignId, onClose }) {
  const classes = useStyles();

  const [campaign, setCampaign] = useState();
  const [loading, setLoading] = useState(false);

  // const getCampaign = ;

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/${campaignId}`
        );

        setCampaign(response);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const handleApproveCampaign = async () => {
    try {
      setLoading(true);
      await await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/edit/${campaignId}`,
        { approved: 1 },
        'PUT'
      );

      const response = await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/${campaignId}`
      );

      setCampaign(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeclineCampaign = async () => {
    try {
      setLoading(true);

      await await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/edit/${campaignId}`,
        { approved: 0 },
        'PUT'
      );

      const response = await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/${campaignId}`
      );

      setCampaign(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveProduct = async id => {
    try {
      setLoading(true);

      await await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/product/remove/${id}`,
        {},
        'DELETE'
      );

      const response = await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaign/${campaignId}`
      );

      setCampaign(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Detalhes da campanha</DialogTitle>
      <Divider />
      <DialogContent>
        {loading || !campaign ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={40} color="primary" />
          </div>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6">Informaçōes do envio</Typography>
              <List disablePadding>
                <ListItem>
                  <ListItemText primary={`Nome: ${campaign.name}`} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography>Status: &nbsp; </Typography>
                        {renderChip(campaign.approved, classes)}
                      </Box>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Data de início: ${formatDate(
                      campaign.start_date
                    )}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Data de término: ${formatDate(
                      campaign.end_date
                    )}`}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Disparos</Typography>
              <List disablePadding>
                {campaign.shots &&
                  campaign.shots.map(shot => (
                    <ListItem>
                      <ListItemText primary={shot.type} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Produtos</Typography>
              <Table
                title=""
                columns={[
                  { title: 'EAN', field: 'ean' },
                  { title: 'Codigo interno', field: 'sku' },
                  { title: 'Nome', field: 'name' },
                  {
                    title: 'Preço',
                    field: 'price',
                    render: rowData => (
                      <span>{formatPrice(rowData.price)}</span>
                    ),
                  },
                  {
                    title: 'Sell Out',
                    field: 'sell_out_price',
                    render: rowData => (
                      <span>{formatPrice(rowData.sell_out_price)}</span>
                    ),
                  },
                ]}
                isLoading={!campaign.products}
                data={campaign.products || []}
                actions={[
                  {
                    icon: 'delete',
                    tooltip: 'Remover produto',
                    onClick: (event, rowData) => {
                      handleRemoveProduct(rowData.id);
                    },
                  },
                ]}
                localization={localization}
                options={{ actionsColumnIndex: -1 }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDeclineCampaign}>Recusar</Button>
        <Button onClick={handleApproveCampaign}>Aprovar</Button>
      </DialogActions>
    </Dialog>
  );
}

CampaignDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  campaignId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CampaignDetails;
