import React from 'react';
import {
  Marker,
  GoogleMap,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const style = {
    height: `200px`,
    width: '100%',
    borderRadius: '7.5px',
  },
  Maps = compose(
    withProps({
      googleMapURL:
        'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAFnRCI6kHw0ICofnhMoI9zI2B0HexETeU&libraries=geometry,drawing,places',
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={style} />,
      mapElement: <div style={style} />,
    }),
    withScriptjs,
    withGoogleMap
  )(({ latitude, longitude }) => (
    <GoogleMap
      onClick={() =>
        window.open(
          `http://maps.google.com/maps?q=${latitude},${longitude}&ll=${latitude},${longitude}&z=18`,
          '_blank'
        )
      }
      defaultZoom={15.2}
      defaultClickableIcons={false}
      options={{
        streetViewControl: false,
        disableDefaultUI: true,
        gestureHandling: 'none',
      }}
      center={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }}
    >
      <Marker
        defaultClickable={false}
        position={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
      />
    </GoogleMap>
  ));

export default Maps;
