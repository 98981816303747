import Dialog from '@material-ui/core/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

type DialogEditProps = {
  onClose: () => void;
  open: boolean;
};

const DialogSelectVersion: React.FC<DialogEditProps> = ({ onClose, open }) => {
  const token = JSON.parse(localStorage.getItem('token') as string);

  const texts = [
    '1. Edite várias artes ao mesmo tempo',
    '2. Layouts exclusivos da nova versão',
    '3. Impulsione com quantos créditos quiser',
    '4. Etiquetas com preços dinâmicos',
    '5. Altere unidade e medida'
  ];

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose} title="">
      <form>
        <Stack spacing={2} margin={2}>
          <Stack alignItems="center">
            <img
              src="https://s3.datasales.info/datasalesio-imagens/newVersion.png"
              alt="Bem Vindo"
              style={{ width: '100%' }}
            />
          </Stack>
          <Stack>
            <Typography variant="h5" sx={{ marginTop: '5px' }}>
              Nova plataforma!
            </Typography>

            <Typography fontWeight={600} sx={{ marginTop: '6px', marginBottom: '1rem' }}>
              Todas as funcionalidades da versão atual, só que ainda melhores!
            </Typography>

            <Stack spacing={1} sx={{ marginTop: '5px' }}>
              {texts.map((text, index) => (
                <Typography key={index} variant="button" noWrap fontWeight={400} color="#2A3364">
                  {text}
                </Typography>
              ))}
            </Stack>

            <Stack mt={5}>
              <Button
                variant="contained"
                sx={{ width: '100%', height: '50px', borderRadius: '2rem' }}
                onClick={() =>
                  window.location.replace(
                    `https://app.dsmarketing.com.br/sign-as-user/${token}?origin=old`
                  )
                }
              >
                Acessar nova plataforma
              </Button>
            </Stack>
            <Stack>
              <Button
                variant="text"
                sx={{
                  color: 'rgba(52, 62, 117, 1)',
                  marginBottom: '10px',
                  borderRadius: '2rem',
                  marginTop: '1rem'
                }}
                onClick={onClose}
              >
                Ficar nessa versão
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default DialogSelectVersion;

