import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import checkbox from '../../../../assets/icons/marketing/checkbox.svg';
import newPortalImg from '../../../../assets/images/newPortalImg.png';
import { useStyles } from '../../styles';

interface NewPortalProps {
  handleClick: () => void;
}

export default function NewPortal({ handleClick }: NewPortalProps) {
  const classes = useStyles();

  return (
    <Box className={[classes.cardItem, classes.newPortal].join(' ')}>
      <Box className={classes.cardRecomend}>Recomendado</Box>
      <Box className={classes.title} style={{ color: '#FFFFFF', margin: '8px 0 8px' }}>
        Novo portal com novidades
      </Box>
      <Box className={classes.cardContent} style={{ color: '#FFFFFF' }}>
        <Box>
          Este é o novo portal da Datasales. Contém todas as funcionalidades que você já conhece e
          também novas funcionalidades como:{' '}
        </Box>
        <Box className={classes.cardList}>
          <Box className={classes.cardIconLabel}>
            <img src={checkbox} /> Envie ofertas de SMS
          </Box>
          <Box className={classes.cardIconLabel}>
            <img src={checkbox} />
            Distribua sem precisar criar artes
          </Box>
          <Box className={classes.cardIconLabel}>
            <img src={checkbox} />
            Faça distribuição ainda mais rápido
          </Box>
        </Box>
        <img src={newPortalImg} alt="Portal Tradicional" />
        <Button
          className={classes.button}
          style={{
            color: '#2813AD',
            backgroundColor: '#FFFFFF',
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.05em',
            textDecoration: 'none'
          }}
          onClick={() => handleClick()}
        >
          Acessar Novo portal com novidades
        </Button>
      </Box>
    </Box>
  );
}
