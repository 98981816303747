import { faChartBar, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

import AccountSettings from '../../modules/AccountSettings';
import AddUser from '../../modules/AccountSettings/components/Users/pages/AddUser';
import EditUser from '../../modules/AccountSettings/components/Users/pages/EditUser';
import Audiences from '../../modules/Ads/pages/Audiences';
import AdsCampaigns from '../../modules/Ads/pages/Campaigns';
// import TemplateList from "../../modules/Template/components/TemplateList";
import PoliticaPrivacidade from '../../modules/AppConfig/components/PoliticaPrivacidade';
import AllApps from '../../modules/AllApps';
import Apps from '../../modules/Apps';
import Audiencia from '../../modules/AudienciaPersonalizada/components/Audiencia';
import AudienciaDetalhes from '../../modules/AudienciaPersonalizada/components/AudienciaDetalhes';
import ConfiguracoesAudiencia from '../../modules/AudienciaPersonalizada/components/Configuracoes';
// import SemPermissoes from "../../modules/SemPermissoes/index";
import Automacoes from '../../modules/Automacoes/components/Index';
import { Blog } from '../../modules/Blog/pages';
import Cashback from '../../modules/Cashback/components/Cashback';
import CriarCashback from '../../modules/Cashback/components/CriarCashback';
import Chat from '../../modules/Chat/components/Chat';
import ChatAnalytic from '../../modules/Chat/components/index';
import Instance from '../../modules/Chat/components/Instance';
import Calendario from '../../modules/Chatbot/component/Calendario';
import Contatos from '../../modules/Chatbot/component/Contacts';
import Grupos from '../../modules/Chatbot/component/Grupos';
import { default as Chatbot, default as Instancias } from '../../modules/Chatbot/component/Instancias';
import RespostasAutomaticas from '../../modules/Chatbot/component/RespostasAutomaticas';
import Cluster from '../../modules/Cluster/components/Cluster';
import ClusterDetalhe from '../../modules/Cluster/components/ClusterDetalhe';
import SubCluster from '../../modules/Cluster/components/SubCluster';
// import Metabase from "../../modules/Metabase/components/Metabase";
import Comercial from '../../modules/Comercial/components/Comercial';
import ComercialId from '../../modules/Comercial/components/ComercialId';
import Metabase from '../../modules/Comercial/components/Metabase';
import ConfigCashback from '../../modules/ConfigCashback/components/ConfigCashback';
// import SMSAutomatico from "../../modules/SMS/components/SMSAutomatico";
// import Agendamento from "../../modules/Agendamento/components/Agenda/Agendamento";
// import Tags from "../../modules/Agendamento/components/Tags/Tags";
// import Recomendacoes from "../../modules/Recomendacoes/components/Tabs";
import ConfigClube from '../../modules/ConfigClube/components/ConfigClube';
import BancoDeImagens from '../../modules/Configuracoes/components/BancoDeImagens';
import Configuracoes from '../../modules/Configuracoes/components/index';
import CriarCupom from '../../modules/Cupom/components/CriarCupom';
import Cupom from '../../modules/Cupom/components/Cupom';
import CriarCupomCashback from '../../modules/CupomCashback/components/CriarCupomCashback';
import CupomCashback from '../../modules/CupomCashback/components/CupomCashback';
import CriarCupomSorteio from '../../modules/CupomSorteio/components/CriarCupomSorteio';
import CupomSorteio from '../../modules/CupomSorteio/components/CupomSorteio';
import Dashboard from '../../modules/Dashboard/components/Dashboard';
import Database from '../../modules/Database/components/Database';
import DatabasePessoas from '../../modules/Database/components/Pessoas';
import DatabaseVendas from '../../modules/Database/components/Vendas';
import CategoriasDelivery from '../../modules/Delivery/components/Categorias';
import Delivery from '../../modules/Delivery/components/Delivery';
import LojasDelivery from '../../modules/Delivery/components/Lojas';
import ProdutosDelivery from '../../modules/Delivery/components/Produtos';
import Enriquecimento from '../../modules/Enriquecimento/components';
import FastModels from '../../modules/FastModels';
import { Home } from '../../modules/Home'
import { NewStudio } from '../../modules/Home/Studio';
import Financial from '../../modules/Financial';
import Forms from '../../modules/Forms/components';
import { HomeDashboard } from '../../modules/HomeDashboard/pages';
import Importacao from '../../modules/Importacao/components';
import Insights from '../../modules/Insights/components/Insights';
import Sugestoes from '../../modules/Inteligencia/components/Sugestoes';
import SugestoesList from '../../modules/Inteligencia/components/SugestoesList';
import Lojas from '../../modules/Lojas/components/Lojas';
import CriarOferta from '../../modules/Ofertas/components/CriarOferta';
import DetalhesOferta from '../../modules/Ofertas/components/DetalhesOferta';
import DetalhesProduto from '../../modules/Ofertas/components/DetalhesProduto';
import MonitorOfertas from '../../modules/Ofertas/components/MonitorOfertas';
import Ofertas from '../../modules/Ofertas/components/Ofertas';
import ProdutosCategoria from '../../modules/Ofertas/components/ProdutosCategoria';
import OneToOneConfiguracoes from '../../modules/OneToOne/components/Configuracoes';
import OneToOne from '../../modules/OneToOne/components/OneToOne';
import OneToOneDetalhes from '../../modules/OneToOne/components/OneToOneDetalhes';
import Answers from '../../modules/Performance/components/Answers';
import Blacklist from '../../modules/Performance/components/Blacklist';
import CriarCampanha from '../../modules/Performance/components/CriarCampanha';
import DetalhesCampanha from '../../modules/Performance/components/DetalhesCampanha';
import MonitorCampaigns from '../../modules/Performance/components/MonitorCampaigns';
import Performance from '../../modules/Performance/components/Performance';
import Perfil from '../../modules/Pessoas/components/Perfil/Perfil';
import Pessoa from '../../modules/Pessoas/components/Pessoa';
import PromotionalIntelligence from '../../modules/PromotionalIntelligence';
import Radios from '../../modules/Radios';
import RecomendacoesConfiguracao from '../../modules/Recomendacao/components/Configuracoes';
import Recomendacoes from '../../modules/Recomendacao/components/index';
import Relatorios from '../../modules/Relatorios/components/Relatorios';
import Sites from '../../modules/Sites';
import EditPage from '../../modules/Sites/pages/EditPage';
import EditSection from '../../modules/Sites/pages/EditSection';
import SocialNetworks from '../../modules/SocialNetworks';
import Accounts from '../../modules/SocialNetworks/pages/Accounts';
import Solicitacoes from '../../modules/Solicitacoes/components/index';
import ConfiguracoesTabloide from '../../modules/Tabloide/components/Configuracoes';
import Tabloide from '../../modules/Tabloide/components/Tabloide';
import TabloideDetalhes from '../../modules/Tabloide/components/TabloideDetalhes';
import EditEtiquetas from '../../modules/TabloideDigital/components/EditEtiquetas';
import LinkOnlinePage from '../../modules/TabloideDigital/components/LinkOnlinePage';
import ListaOfertasEncarte from '../../modules/TabloideDigital/components/ListaOfertasEncarte';
import Videomatik from '../../modules/TabloideDigital/components/Videomatik';
// import CriarTabloide from "../../modules/TabloideDigital/components/CriarTabloide";
import TabloideDigital from '../../modules/TabloideDigital/index';
import EditTemplates from '../../modules/TabloideDigitalV2/components/EditTemplates';
//import TabloidDetails from '../../modules/TabloideDigitalV2/pages/TabloidDetails';
import ListaOfertasEncarteV2 from '../../modules/TabloideDigitalV2/components/ListaOfertasEncarte';
// import TabloideStudio from "../../modules/TabloideDigital/components/TabloideStudio";
import TabloideDigitalV2 from '../../modules/TabloideDigitalV2/index';
import Studio from '../../modules/Template/components/Studio';
import { Campaigns, EditProduct, EditProvider, NewProduct, NewProvider, NewUser, ProviderDetails, Providers } from '../../modules/Trade/pages/index';
import VersionOption from '../../modules/VersionOption';
import WithoutAccess from './components/WithoutAccess';
import NewStore from '../../modules/DeliveryVip/pages/NewStore';
import Professional from 'modules/Home/Professional';
import Projects from 'modules/Home/Projects';

const Rotas = [{
        name: 'NewStore',
        hidden: false,
        component: NewStore,
        path: '/form-delivery',
        service: 'blog'
    }, {
        name: 'Blog',
        hidden: false,
        component: Blog,
        path: '/blog',
        service: 'blog'
    },
    {
        name: 'Financeiro',
        hidden: true,
        component: Financial,
        path: '/financial',
        service: 'blog'
    },
    {
        name: 'Dashboard',
        hidden: false,
        component: Dashboard,
        path: '/dashboard',
        service: 'analise'
    },
    {
        name: 'DashboardV2',
        hidden: false,
        component: HomeDashboard,
        path: '/dashboard-v2',
        service: 'analise'
    },
    {
        component: AdsCampaigns,
        path: '/ads',
        service: 'ads'
    },
    {
        component: Audiences,
        path: '/ads/publicos',
        service: 'ads'
    },
    {
        component: Recomendacoes,
        path: '/recomendacoes',
        service: 'recomendacoes_inteligentes'
    },
    {
        component: Providers,
        path: '/trade/providers',
        service: 'trade'
    },
    {
        component: Campaigns,
        path: '/trade/campaigns',
        service: 'trade'
    },
    {
        component: Sites,
        path: '/sites',
        service: 'sites'
    },
    {
        component: Apps,
        path: '/aplicativos',
        service: 'aplicativos'
    },
    {
        component: AllApps,
        path: '/apps/all',
        service: 'apps/all'
    },
    {
        component: EditPage,
        path: '/sites/page/edit/:id',
        service: 'sites'
    },
    {
        component: EditSection,
        path: '/sites/section/edit/:id',
        service: 'sites'
    },
    {
        component: SocialNetworks,
        path: '/social-networks',
        service: 'redes_sociais'
    },
    {
        component: Accounts,
        path: '/social-networks/accounts',
        service: 'whatsapp'
    },
    {
        component: Radios,
        path: '/radios',
        service: 'radios'
    },
    {
        component: NewProvider,
        path: '/trade/provider/new',
        service: 'trade'
    },
    {
        component: EditProvider,
        path: '/trade/provider/edit/:id',
        service: 'trade'
    },
    {
        component: ProviderDetails,
        path: '/trade/provider/:id',
        service: 'trade'
    },
    {
        component: NewUser,
        path: '/trade/provider/:id/user/new',
        service: 'trade'
    },
    {
        component: EditUser,
        path: '/trade/provider/:id/user/edit/:userId',
        service: 'trade'
    },
    {
        component: NewProduct,
        path: '/trade/provider/:id/product/new',
        service: 'trade'
    },
    {
        component: EditProduct,
        path: '/trade/provider/:id/product/edit/:productId',
        service: 'trade'
    },
    {
        component: Configuracoes,
        path: '/configuracoes'
    },
    {
        component: BancoDeImagens,
        path: '/banco-de-imagens',
        service: 'banco_de_dados'
    },
    {
        component: Cluster,
        path: '/cluster',
        service: 'cluster'
    },
    {
        component: SubCluster,
        path: '/subcluster/:id',
        service: 'cluster'
    },
    {
        component: ClusterDetalhe,
        path: '/clusterdetalhe/:id',
        service: 'cluster'
    },
    {
        component: Solicitacoes,
        path: '/performance',
        service: 'performance'
    },
    {
        component: RecomendacoesConfiguracao,
        path: '/recomendacoes-configuracao',
        service: 'recomendacoes_inteligentes'
    },
    {
        component: TabloideDigital,
        path: '/propaganda-de-ofertas',
        service: 'propaganda_de_ofertas'
    },
    {
        component: VersionOption,
        path: '/main',
        service: 'propaganda_de_ofertas'
    },
    {
        component: Home,
        path: '/home',
        service: 'propaganda_de_ofertas'
    },
    {
        component: TabloideDigitalV2,
        path: '/estudio',
        service: 'propaganda_de_ofertas'
    },
    {
        component: TabloideDigitalV2,
        path: '/estudio/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: TabloideDigitalV2,
        path: '/estudio/:id/enviado',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarteV2,
        path: '/lista-ofertas-v2/encarte/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/encarte/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/facebook/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/instagram/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/whatsapp/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/cartaz/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/etiqueta/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: Videomatik,
        path: '/lista-ofertas/videos/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: LinkOnlinePage,
        path: '/lista-ofertas/link/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/email/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: ListaOfertasEncarte,
        path: '/lista-ofertas/indoor/:id',
        service: 'propaganda_de_ofertas'
    },
    {
        component: EditTemplates,
        path: '/edit-templates',
        service: 'propaganda_de_ofertas'
    },
    {
        component: EditEtiquetas,
        path: '/edit-etiquetas',
        service: 'propaganda_de_ofertas'
    },
    {
        component: AccountSettings,
        path: '/account-settings',
        service: 'account_settings'
    },
    {
        component: AddUser,
        path: '/account-settings/user/new',
        service: 'account_settings'
    },
    {
        component: EditUser,
        path: '/account-settings/user/edit/:id',
        service: 'account_settings'
    },
    {
        component: Comercial,
        path: '/analise',
        service: 'bi'
    },
    {
        component: Audiencia,
        path: '/audiencia',
        service: 'audiencia'
    },
    {
        component: ConfiguracoesAudiencia,
        path: '/configuracoes-audiencia',
        service: 'audiencia'
    },
    {
        component: AudienciaDetalhes,
        path: '/audiencia-detalhe/:id',
        service: 'audiencia'
    },
    {
        component: Tabloide,
        path: '/tabloides',
        service: 'tabloide_digital'
    },
    {
        component: ConfiguracoesTabloide,
        path: '/configuracoes-tabloides',
        service: 'tabloide_digital'
    },
    {
        component: TabloideDetalhes,
        path: '/tabloide-detalhe/:id',
        service: 'tabloide_digital'
    },

    {
        component: ComercialId,
        path: '/analise-id/:id',
        service: 'bi'
    },
    {
        hidden: true,
        component: OneToOneDetalhes,
        path: '/oneToOne-detalhe/:id'
    },
    {
        hidden: true,
        component: OneToOne,
        path: '/oneToOne'
    },
    {
        hidden: true,
        component: OneToOneConfiguracoes,
        path: '/configuracoes-oneToOne'
    },
    {
        hidden: true,
        component: Metabase,
        path: '/metabase/:id',
        service: 'bi'
    },
    {
        hidden: true,
        component: Sugestoes,
        path: '/sugestoes'
    },
    {
        hidden: true,
        component: Automacoes,
        path: '/automacoes',
        service: 'automacoes'
    },
    {
        hidden: true,
        component: SugestoesList,
        path: '/sugestoes-list/:id'
    },

    {
        name: 'Ofertas',
        hidden: false,
        component: Ofertas,
        path: '/ofertas',
        service: 'ofertas'
    },
    {
        name: 'Monitor de ofertas',
        path: '/monitor-ofertas',
        hidden: false,
        service: 'monitor_de_ofertas',
        component: MonitorOfertas
    },
    {
        name: 'Inteligência promocional',
        hidden: false,
        component: PromotionalIntelligence,
        path: '/inteligência-promocional',
        service: 'inteligencia_promocional'
    },
    {
        name: 'Cashback',
        hidden: false,
        component: Cashback,
        path: '/cashback',
        service: 'cashback'
    },
    {
        name: 'Criar cashback',
        hidden: false,
        component: CriarCashback,
        path: '/criar-cashback',
        service: 'cashback'
    },
    {
        name: 'Cupom',
        hidden: false,
        component: Cupom,
        path: '/cupom'
    },
    {
        name: 'Cupom de sorte',
        hidden: false,
        component: CupomSorteio,
        path: '/cupom-sorteio',
        service: 'cupom_de_sorteio'
    },

    {
        name: 'Criar cupom de sorte',
        hidden: false,
        component: CriarCupomSorteio,
        path: '/criar-cupom-sorteio',
        service: 'cupom_de_sorteio'
    },
    {
        name: 'Cupom de cashback',
        hidden: false,
        component: CupomCashback,
        path: '/cupom-cashback',
        service: 'cupom_de_cashback'
    },
    {
        name: 'Criar cupom de cashback',
        hidden: false,
        component: CriarCupomCashback,
        path: '/criar-cupom-cashback',
        service: 'cupom_de_cashback'
    },
    {
        name: 'Criar cashback',
        hidden: false,
        component: CriarCupom,
        path: '/criar-cupom',
        service: 'cashback'
    },
    {
        name: 'Analytics',
        icon: faChartBar,
        hidden: false,
        component: Insights,
        path: '/filtros',
        service: 'audiencia'
    },
    {
        component: Pessoa,
        path: '/pessoas/:id',
        service: 'audiencia',
        hidden: false
    },
    {
        name: 'Respostas Automáticas',
        icon: faChartBar,
        hidden: false,
        component: RespostasAutomaticas,
        path: '/whatsapp/chatbot',
        service: 'whatsapp'
    },
    {
        name: 'Instâncias',
        icon: faChartBar,
        hidden: false,
        component: Instancias,
        path: '/whatsapp',
        service: 'whatsapp'
    },
    {
        name: 'Grupos',
        icon: faChartBar,
        hidden: false,
        component: Grupos,
        path: '/whatsapp/grupos/:id',
        service: 'whatsapp'
    },
    {
        name: 'Contatos Whatsapp',
        icon: faChartBar,
        hidden: false,
        component: Contatos,
        path: '/whatsapp/contatos',
        service: 'whatsapp'
    },
    {
        name: 'Chatbot',
        icon: faChartBar,
        hidden: false,
        component: Chatbot,
        path: '/chatbot',
        service: 'whatsapp'
    },
    {
        name: 'Calend�rio',
        icon: faChartBar,
        hidden: false,
        component: Calendario,
        path: '/chatbot/calendario',
        service: 'chatbot'
    },
    {
        name: 'Campanha',
        hidden: false,
        component: Performance,
        path: '/campanhas',
        service: 'campanhas'
    },
    {
        name: 'Campanha',
        hidden: false,
        component: Performance,
        path: '/campanhas/:id',
        service: 'campanhas'
    },
    {
        name: 'Delivery',
        // hidden: false,
        component: Delivery,
        path: '/delivery',
        service: 'pedidos'
    },
    {
        name: 'Produtos delivery',
        // hidden: false,
        component: ProdutosDelivery,
        path: '/produtos-delivery',
        service: 'pedidos'
    },
    {
        name: 'Categorias delivery',
        // hidden: false,
        component: CategoriasDelivery,
        path: '/categorias-delivery',
        service: 'pedidos'
    },
    {
        name: 'Lojas delivery',
        // hidden: false,
        component: LojasDelivery,
        path: '/lojas-delivery',
        service: 'pedidos'
    },
    {
        name: 'DetalhesCampanha',
        hidden: true,
        component: DetalhesCampanha,
        path: '/detalhes-campanha/:id',
        service: 'campanhas'
    },
    {
        name: 'CriarCampanha',
        hidden: true,
        component: CriarCampanha,
        path: '/criar-campanha',
        service: 'criar_campanha'
    },
    {
        name: 'CriarCampanha',
        hidden: true,
        component: CriarCampanha,
        path: '/criar-campanha/:id',
        service: 'criar_campanha'
    },
    {
        name: 'criarOferta',
        hidden: true,
        component: CriarOferta,
        path: '/criar-oferta',
        service: 'criar_oferta'
    },
    {
        name: 'DetalhesOferta',
        hidden: true,
        component: DetalhesOferta,
        path: '/detalhes-oferta/:id',
        service: 'ofertas'
    },
    {
        name: 'ProdutosCategoria',
        hidden: true,
        component: ProdutosCategoria,
        path: '/produtos-categoria/:id',
        service: 'ofertas'
    },
    {
        name: 'DetalhesProduto',
        hidden: true,
        component: DetalhesProduto,
        path: '/detalhes-produto/:id',
        service: 'ofertas'
    },
    {
        name: 'Database',
        hidden: true,
        component: Database,
        path: '/database',
        service: 'banco_de_dados'
    },
    {
        name: 'DatabasePessoas',
        hidden: true,
        component: DatabasePessoas,
        path: '/database-pessoas',
        service: 'banco_de_dados'
    },
    {
        name: 'DatabaseVendas',
        hidden: true,
        component: DatabaseVendas,
        path: '/database-vendas',
        service: 'banco_de_dados'
    },
    {
        component: Studio,
        name: 'Criar template',
        path: '/studio',
        service: 'templates'
    },
    {
        name: 'App',
        beta: true,
        icon: faMobileAlt,
        hidden: false,
        subRoutes: [{
            component: PoliticaPrivacidade,
            name: 'Pol�tica de privacidade',
            path: '/politica-privacidade'
        }]
    },
    {
        component: Enriquecimento,
        name: 'Enriquecimento',
        path: '/enriquecimento',
        hidden: false,
        service: 'audiencia'
    },
    {
        component: Importacao,
        name: 'Importacao',
        path: '/importacao',
        hidden: false,
        service: 'audiencia'
    },
    {
        component: Forms,
        name: 'Formulários',
        path: '/forms',
        hidden: false,
        service: 'audiencia'
    },
    {
        component: Chat,
        name: 'Chat',
        path: '/chat/instance/:instance',
        hidden: false,
        service: 'chat'
    },
    {
        component: Instance,
        name: 'Instance',
        path: '/chat',
        hidden: false,
        service: 'chat'
    },
    {
        component: ChatAnalytic,
        name: 'Analítico',
        path: '/chat-analytic',
        hidden: false,
        service: 'chat'
    },
    {
        component: MonitorCampaigns,
        name: 'Monitor de campanhas',
        path: '/monitor-campanhas',
        hidden: false,
        service: 'monitor_de_envios'
    },
    {
        component: Answers,
        name: 'Respostas',
        path: '/respostas',
        hidden: false,
        service: 'respostas'
    },
    {
        component: Blacklist,
        name: 'Blacklist',
        path: '/blacklist',
        hidden: false,
        service: 'blacklist'
    },
    {
        component: Studio,
        name: 'Criar template',
        path: '/studio-edit',
        hidden: true,
        service: 'templates'
    },
    {
        component: Perfil,
        path: '/perfil/:id',
        hidden: true,
        service: 'audiencia'
    },
    {
        component: Lojas,
        path: '/lojas',
        hidden: true
    },
    {
        component: Relatorios,
        path: '/relatorios',
        hidden: true
    },
    {
        component: ConfigClube,
        path: '/configClube',
        hidden: true
    },
    {
        component: ConfigCashback,
        path: '/configCashback',
        hidden: true
    },
    {
        component: WithoutAccess,
        path: '/without-access',
        service: 'without-access'
    }
];

export default Rotas;