import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';

import { useStyles } from '../styles/dialogs';

type DialogAlertVideoProps = {
  show: boolean;
  onClose: VoidFunction;
};

const DialogAlertVideo = ({ show, onClose }: DialogAlertVideoProps) => {
  const classes = useStyles();
  return (
    <Dialog open={show} onClose={onClose} className={classes.wrapDialog}>
      <DialogTitle className={classes.dialogTitle}>Atenção!</DialogTitle>
      <DialogContent className={classes.wrapContent}>
        <DialogContent className={classes.content01}>
          Apenas é possível baixar 1 vídeo por vez. Selecione 1 e tente novamente.
        </DialogContent>
      </DialogContent>
      <Divider className={classes.row} />

      <DialogActions
        style={{ justifyContent: 'flex-end' }}
        disableSpacing
        className={classes.wrapActions}
      >
        <Button className={classes.confirm} variant="contained" color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlertVideo;

