import { useState } from "react";
import  { Button, Box, Dialog, DialogContent, Typography, TextField, }  from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useStyles } from "./styles";
import ImagePreview from "modules/FastModels/components/ImagePreview";
import { ScheduleItem } from "../ListScheduleImages";
import { styled } from "@mui/styles";

interface PropsModalPublicationDates{
  showModal: boolean;
  item: ScheduleItem;
  onClose: () => void;
  onUpdateDate: (newDate: Date,indexDate: number) => void;
}

const Img = styled('img')({});

export function ModalPublicationDates({
  showModal,
  item,
  onClose,
  onUpdateDate
}: PropsModalPublicationDates) {
  const styles = useStyles();
  const [imagePreview,setImagePreview] = useState(false);

  return (
    <Dialog
      open={showModal}
    > 
      <DialogContent>  
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'2.5rem'}
      >
        <Box>
          <Img 
            src={item.preview_url}
            className={styles.image}
            onClick={() => setImagePreview(true)}
          />
        </Box>
        <Box 
          display={'flex'}
          flexDirection={'column'}
          gap={'24px'}
        >
          
          <Typography
            fontStyle={'normal'}
            fontWeight={500}
            fontSize={'14px'}
            lineHeight={'20px'}
            color={'#616161'}
          >
            Este poste será publicado repetidamente até a data de término selecionada. 
            Se não deseja que essa publicação se repita, desmarque a opção <strong>”Repetir publicações"</strong>
          </Typography>
          {item.dateScheduled.map((date,index)=>(
            <Box className={styles.containerDateTime}>
              <Box className={styles.dateTime}>
                <Typography className={styles.fontTypography}>Data</Typography>
                <DatePicker
                  value={date}
                  disablePast={true}
                  inputFormat="dd/MM/yyyy"
                  mask="__/__/____"
                  onChange={(date, keyboardInput) => {
                    if (date) {
                      onUpdateDate(date,index);
                    }
                  }}
                  className={styles.dateTimePicker}
                  renderInput={params => <TextField {...params} />}
                  InputAdornmentProps={{ position: 'start' }}
                />
              </Box>

              <Box className={styles.dateTime}>
                <Typography className={styles.fontTypography}>Hora</Typography>
                <TimePicker
                  value={date}
                  className={styles.dateTimePicker}
                  ampm={false}
                  onChange={(date, keyboardInput) => {
                    if (date) {
                      onUpdateDate(date,index);
                    }
                  }}
                  renderInput={params => <TextField {...params} />}
                  InputAdornmentProps={{ position: 'start' }}
                />
              </Box>
            </Box>
          ))}
          <Box
            display={'flex'}
            justifyContent={"flex-end"}
          >
            <Button 
              onClick={() => onClose()}
              sx={{
                width: '81px',
                heigth: '44px',
                background: '#2813AD',
                borderRadius: '4px',
                padding: '12px 16px',
                '&:hover':{
                  background: '#2813AD'
                }
              }}
            >
              <Typography
                color={'#FFFFFF'}
                fontFamily={'Montserrat'}
                fontStyle={'normal'}
                fontWeight={500}
                fontSize={'14px'}
                lineHeight={'20px'}
              >
                Fechar
              </Typography></Button>
          </Box>            
        </Box>
      </Box>
      </DialogContent>
      <ImagePreview
        open={imagePreview}
        imageName={item.name}
        imageUrl={item.image_url}
        onClose={() => setImagePreview(false)}
      />
    </Dialog>
  )

}

