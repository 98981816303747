import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContent: {
      flexGrow: 1,
      Height: '100%'
    },
    icon: {
      width: '1.75rem',
      height: '1.75rem'
    },
    iconDisabled: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
      width: '1.75rem',
      height: '1.75rem'
    },
    boxContentIcon2: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5625rem',
      padding: '0.4375rem',
      width: '80px'
    },
    boxContentIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5625rem',
      padding: '0.4375rem',
      width: '96px'
    },
    boxContentText: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      marginLeft: '0.5rem !important'
    },
    boxContentText2: {
      display: 'flex !important',
      gap: '0.5rem !important'
    },
    boxContentCheckbox: {
      display: 'flex !important',
      justifyContent: 'space-evenly !important',
      alignItems: 'center !important'
    },
    boxContentBtn: {
      position: 'relative',
      width: '43.43rem',
      height: '7rem',
      borderRadius: theme.spacing(1),
      backgroundColor: '#F5F5F5',
      display: 'flex',
      padding: '1rem !important',
      justifyContent: 'space-between',
      cursor: 'pointer',
      border: '2px solid transparent',
      '&:hover': {
        '& $checkbox': {
          visibility: 'visible'
        }
      }
    },
    boxContentBtnSelected: {
      border: '2px solid #2813AD'
    },
    boxContentBtnDisabled: {
      pointerEvents: 'none',
      padding: '1rem !important',
      borderRadius: theme.spacing(1),
      backgroundColor: '#F5F5F5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
      width: '43.43rem',
      height: '7rem'
      //flexDirection: 'column'
    },
    checkbox: {
      padding: '0px !important',
      color: '#2813AD',
      position: 'absolute',
      visibility: 'hidden'
    },
    checkboxSelected: {
      padding: '0px !important',
      color: '#2813AD !important',
      position: 'absolute',
      visibility: 'visible'
    },
    typographyBtn: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      letterSpacing: '.05em',
      color: 'black',
      fontWeight: '600 !important'
    }
  })
);

