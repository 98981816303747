import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { COLOR_PRIMARY, BG_PRIMARY } from '../../../utils/helpers/Constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }),
  filter = createFilterOptions();

export default props => {
  const [cluster, setCluster] = useState(null);
  function onChangeCluster(newValue) {
    if (typeof newValue === 'string') {
      // CRIA UM CLUSTER
      props.onSaveCluster(newValue);
      setCluster({
        NOME: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // CRIA UM CLUSTER
      props.onSaveCluster(newValue.inputValue);
      setCluster({
        NOME: newValue.inputValue,
      });
    } else {
      // SELECIONA UM CLUSTER JÁ EXISTENTE
      props.onChange('clusterName', newValue);
      setCluster(newValue);
    }
  }

  function filterCluster(options, params) {
    const filtered = filter(options, params);
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        NOME: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  function optionLabelCluster(option) {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.NOME;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle style={{ color: BG_PRIMARY }} id="form-dialog-title">
        {props.replica ? 'Replicar audiência' : 'Salvar audiência'}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          onChange={(event, newValue) => onChangeCluster(newValue)}
          filterOptions={(options, params) => filterCluster(options, params)}
          getOptionLabel={option => optionLabelCluster(option)}
          renderOption={option => option.NOME}
          value={cluster}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          id="free-solo-with-text-demo"
          options={props.clusters}
          renderInput={params => (
            <TextField
              {...params}
              autoFocus
              fullWidth
              id="clusters"
              margin="dense"
              label="Clusters"
              variant="outlined"
            />
          )}
        />
        <TextField
          margin="dense"
          id="name"
          variant="outlined"
          label="Nome da audiência"
          fullWidth
          onChange={({ target: { value } }) =>
            props.onChange('filterName', value)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: BG_PRIMARY }}
          onClick={props.onClose}
          color="default"
        >
          Cancelar
        </Button>
        <Button
          disabled={
            !(Boolean(cluster) && Boolean(cluster.ID_CLUSTER_AUDIENCIA)) ||
            !Boolean(props.filter)
          }
          style={{
            color:
              !(Boolean(cluster) && Boolean(cluster.ID_CLUSTER_AUDIENCIA)) ||
              !Boolean(props.filter)
                ? BG_PRIMARY
                : COLOR_PRIMARY,
          }}
          onClick={props.onSave}
          color={
            !(Boolean(cluster) && Boolean(cluster.ID_CLUSTER_AUDIENCIA)) ||
            !Boolean(props.filter)
              ? 'default'
              : 'primary'
          }
        >
          {props.replica ? 'Replicar' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
