import { asyncMap } from 'utils/helpers/Functions';
import api from '../utils/helpers/Fetch';
import { AMBIENTE } from 'utils/helpers/Constants';
import { Hash } from 'types/ads';

export const postAdImages = async (data: Object) => {
  return api(
    `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
    { ...data },
    'POST',
    null,
    null,
    false
  ).then(resp => resp.content.hash)
};

export const generateHash = async (selectedShareableItem: []) => {
  try{
    let imagesHashes = [];
    let previewHash: string | null = null;
    
    imagesHashes = await asyncMap(
      selectedShareableItem,
      async (currentItem: any) => {
        let previewHashVideo: string | null = null;
        if(currentItem.link && !previewHashVideo){
          previewHashVideo = await postAdImages({
            isVideo: false,
            image_url: 'https://s3.amazonaws.com/datasalesio-imagens/produto-imagem/new/logo_veja_essa_oferta.png'
          });
          previewHash = previewHashVideo;
        }
        const hash = await postAdImages({
          isVideo: currentItem?.link ? true : false,
          image_url: currentItem?.link ? currentItem.link : currentItem.image_url
        });
        if(hash){
          return {
            isVideo: currentItem?.link ? true : false,
            hash: hash,
            ...(currentItem?.link && { previewHash: previewHash ?? previewHashVideo })
          }
        }
      }
    );
    return imagesHashes;
  }catch(error: any){
    console.log('Catch_generateHash', error);
    throw error;
  }
};

export const getStatusUploadVideo = async (data: Object) => {
  return api(
    `https://api.datasales.info/ads-manager-${AMBIENTE}/ads/getstatusuploadvideos`,
    { ...data },
    'POST',
    null,
    null,
    false
  ).then(resp => resp.content.status);
};

export const generatePreviews = async (arrayHash: Hash[]) => {
  return api(`https://api.datasales.info/ads-manager-${AMBIENTE}/ads/generatepreviewads`,
    {
      hashes: arrayHash,
      link: 'https://www.google.com/'
    },
    'POST',
    null,
    null,
    false
  ).then(resp => resp.content.iframes);
}

export const deleteMediaAdAccount = async (hashes: Hash[]) => {
  return api(`https://api.datasales.info/ads-manager-${AMBIENTE}/ads/deletemedia`,
    { hashArray: hashes },
    'DELETE',
    null,
    null,
    false
  ).catch((error)=>{
    console.log('ErrorDeleteMidia>::>', error);
  });
}