import React from 'react';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  MyButton,
  TextField,
  Select,
  DatePicker,
} from '../../../../utils/components';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export default function ModalDebitoCashback(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth="sm"
      maxWidth="sm"
    >
      <MuiDialogTitle onClose={props.onClose}>
        Débito de cashback
      </MuiDialogTitle>
      <DialogContent>
        <section
          style={{
            padding: '0 1rem',
            display: 'grid',
            gap: '2rem',
          }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Configurações do cashback
          </Typography>
          <Select
            label="Lojas"
            options={props.lojas}
            placeholder="Selecione a loja..."
            onChange={loja => props.onChange('loja', loja)}
          />
          <Select
            label="Receita"
            options={[
              { value: 'ENTRADA', label: 'Crédito' },
              { value: 'SAIDA', label: 'Débito' },
            ]}
            placeholder="Selecione o tipo de cashback..."
            value={props.value.tipo_cashback}
            onChange={cashback => props.onChange('tipo_cashback', cashback)}
          />
          <TextField
            label="Valor"
            className="form-control"
            type="number"
            autoComplete="none"
            value={props.value.valor_cashback}
            onChange={({ target: { value } }) =>
              props.onChange('valor_cashback', value)
            }
          />
          <DatePicker
            dateAndTime
            label="Começa valer em"
            value={props.value.comeca_valer}
            onChange={date => props.onChange('comeca_valer', date)}
          />
          <DatePicker
            dateAndTime
            label="Termina valer em"
            value={props.value.termina_valer}
            onChange={date => props.onChange('termina_valer', date)}
          />
        </section>
      </DialogContent>
      <DialogActions>
        <MyButton onClick={props.onClose} color="default">
          Cancelar
        </MyButton>
        <MyButton onClick={props.onSave}>Salvar</MyButton>
      </DialogActions>
    </Dialog>
  );
}
