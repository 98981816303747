const INITIAL_STATE = {
  categoriaDelivery: {
    nome_categoria: null,
    lojas_categoria: null,
  },
  produtoDelivery: {
    nome_produto: null,
    loja_produto: null,
    categoria_produto: null,
    unid_medida_produto: null,
    medida_produto: null,
    preco_produto: null,
    limite_produto: null,
    ean_produto: null,
    imagem_produto: {
      base64: null,
      name: null,
      type: null,
    },
  },
  categorias: [],
  pedidos: [],
  produtos: [],
  arquivos: [],
  imagemProduto: null,
  produtosPedido: {
    unidade: 0,
    total: 0,
    taxa: 0,
    qtde_produto: 0,
    produtos: [],
  },
  lojas: [],
  historicoPedidos: null,
  loadingDelivery: false,
  loadingProdPedido: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOJAS":
      return { ...state, lojas: action.payload, loadingDelivery: false };
    case "HISTORICO_PEDIDO":
      return {
        ...state,
        historicoPedidos: action.payload,
        loadingProdPedido: false,
      };
    case "CATEGORIAS":
      return { ...state, categorias: action.payload, loadingDelivery: false };
    case "PEDIDOS":
      return { ...state, pedidos: action.payload, loadingDelivery: false };
    case "PRODUTOS":
      return { ...state, produtos: action.payload, loadingDelivery: false };
    case "ARQUIVOS":
      const newPayload = [];
      action.payload.forEach((file, index) => {
        let newArquivo = state.arquivos[index];
        if (Boolean(newArquivo)) {
          if (
            file.guid_arquivo === newArquivo.guid_arquivo &&
            file.status !== newArquivo.status
          ) {
            newPayload.push({ ...file, hidden: false });
          } else {
            newPayload.push({
              ...file,
              hidden: file.status === 0 || file.status === 2 ? false : true,
            });
          }
        } else {
          newPayload.push({
            ...file,
            hidden: file.status === 0 || file.status === 2 ? false : true,
          });
        }
      });
      return { ...state, arquivos: newPayload, loadingDelivery: false };
    case "PRODUTOS_PEDIDOS":
      return {
        ...state,
        produtosPedido: action.payload,
        loadingProdPedido: false,
      };
    case "IMAGEM_PRODUTO":
      return {
        ...state,
        imagemProduto: action.payload,
        loadingProdPedido: false,
      };
    case "ON_CHANGE":
      return {
        ...state,
        [action.payload.object]: {
          ...state[action.payload.object],
          [action.payload.state]: action.payload.value,
        },
      };
    case "IS_LOADING":
      return { ...state, loadingDelivery: true };
    case "LOAD_PRODUTO_PEDIDO":
      return { ...state, loadingProdPedido: true };
    default:
      return state;
  }
};
