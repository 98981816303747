/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Button,
  Divider,
  Icon,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@material-ui/core';
import '@webscopeio/react-textarea-autocomplete/style.css';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Chip,
  Countup,
  DatePicker,
  Modal,
  MyButton,
  Select,
  TextField,
  Checkbox,
} from '../../../utils/components';
import {
  BG_PRIMARY,
  CELULARES,
  COLOR_PRIMARY,
  HOST_TRACKER,
  HOST_LINK,
  PARETO,
  TIPO_ENVIO_CAMPANHA,
  UNIDADE_TEMPO,
  DIAS,
  DIAS_MES,
  VARIAVEL_CAMPANHA,
} from '../../../utils/helpers/Constants';
import { Celular, CelularValidator } from '../../../utils/helpers/Masks';
import {
  addNumeroCampanha,
  contadorCelular,
  removeNumeroCampanha,
} from '../../Insights/actions/actions';
import { getTemplates } from '../../Template/actions/actions';

const steps = [
  'Tipo da campanha',
  'Informações do envio',
  'Rastreio',
  'Mensagem',
  'Automação',
  'Disparar',
];

function textAutomation(state) {
  const unit = state.unitAutomacao ? `${state.unitAutomacao}` : '',
    time = state.unitTimeAutomacao
      ? state.unitTimeAutomacao.label.toLowerCase()
      : '',
    dia = state.diaDisparoAutomacao
      ? state.diaDisparoAutomacao.label.toLowerCase()
      : '',
    hora = state.horaDisparoAutomacao
      ? moment(state.horaDisparoAutomacao, 'HH:mm:ss').format('HH:mm:00')
      : moment().format('HH:mm:00'),
    text = `A cada ${unit} ${time}${
      dia ? `, todos os dias ${dia}` : ''
    }, às ${hora}`;
  return text;
}
class NovaCampanhaSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      show: false,
      showAlert: false,
      showAlertValidate: false,
      showNumeros: false,
      phoneTest: '',
      linkHost: { ...HOST_LINK[0] },
    };
  }

  componentDidMount() {
    const { sms } = this.props;
    this.props.getTemplates();

    if (sms && sms.optionList && sms.optionList.value) {
      this.props.contadorCelular({
        celulares: sms.selectedCelular.map(opt => opt.value),
        value: sms.optionList.value,
        pra_quem: sms.pra_quem,
        origem_campanha: sms.origem_campanha,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (Boolean(this.props.sms.selectedTabloide)) {
      const prev_props = Object.keys(prevProps.sms.selectedTabloide).length;
      const newProps = Object.keys(this.props.sms.selectedTabloide).length;
      if (Boolean(newProps) && newProps !== prev_props) {
        this.setLinkTabloidPerson(this.props, this.state.linkHost);
      }
    }
  }

  getBasePathCompany() {
    const empresa = JSON.parse(localStorage.getItem('company'));
    if (Boolean(empresa)) {
      const path = empresa.companyBasePath;
      return path;
    }
    return '';
  }

  setLinkTabloidPerson(props, host) {
    const mensurar_campanha = props.sms.mensurar_campanha,
      newHost = host.host;
    let linkOfertaPersonalizada = `${newHost}${this.getBasePathCompany()}/`;
    this.setState({ linkHost: host });
    if (Boolean(mensurar_campanha) && mensurar_campanha.id === 4) {
      if (Boolean(props.sms.selectedTabloide)) {
        const idTabloide = props.sms.selectedTabloide.uuid;
        linkOfertaPersonalizada = `${linkOfertaPersonalizada}${idTabloide}/{{idPessoa}}`;
        props.onChange('redirectTracker', linkOfertaPersonalizada);
      }
    }
  }

  adicionarNumero(numero) {
    const { sms } = this.props;
    if (CelularValidator(numero)) {
      sms.numerosList.push({
        value: numero,
        label: numero,
      });
      this.props.addNumeroCampanha('contador_celular');
      this.setState({ showNumeros: true, phoneTest: '' });
    }
  }

  removerNumero(index) {
    const { sms } = this.props;
    sms.numerosList.splice(index, 1);
    this.props.removeNumeroCampanha('contador_celular');
    this.setState({ showNumeros: true });
  }

  onPasteAdd(value) {
    this.setState({ phoneTest: value });
    let celular,
      regex = /\d{2}\d{4,5}\d{4}/g,
      isPhone = false;
    if (/,/g.test(value)) {
      value.split(',').forEach(numero => {
        isPhone = regex.test(numero);
        if (Boolean(isPhone)) {
          celular = Celular(numero);
          this.adicionarNumero(celular);
        }
      });
    }
  }

  onAdd(key, value) {
    this.setState({ phoneTest: value });
    if (key === 13) {
      const regex = /\d{2}\d{4,5}\d{4}/g,
        isPhone = regex.test(value);
      if (Boolean(isPhone)) {
        this.adicionarNumero(Celular(value));
      }
    }
  }

  tituloModal(titulo) {
    switch (titulo) {
      case 1:
        return 'Refazer campanha para pessoas que compraram';
      case 2:
        return 'Refazer campanha para pessoas que não compraram';
      case 3:
        return 'Refazer campanha para todas as pessoas';
      default:
        return 'Nova Campanha';
    }
  }

  header(props, state) {
    return (
      <Fragment>
        <strong>{this.tituloModal(props.sms.pra_quem)}</strong>
        <section className="modal-header">
          <span className="modal-subtitle">
            <strong>Tipo de Campanha: </strong>SMS
          </span>
          <span className="modal-subtitle">
            <strong>SMS's na lista: </strong>
            <Countup
              isLoading={props.isLoadingCountPhone}
              end={props.contador_campanha.contador_celular || 0}
            />
          </span>
          <span className="modal-subtitle">
            <strong>Investimento: </strong>
            <Countup
              prefix="R$"
              decimals={3}
              isLoading={props.isLoadingCountPhone}
              end={parseFloat(props.contador_campanha.valor_total || 0)}
            />
          </span>
        </section>
        <Divider style={{ marginTop: '2rem' }} />
        <div style={{ width: '100%' }}>
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled={index === 4}
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            style={{ color: COLOR_PRIMARY }}
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1,
              })
            }
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 5 && (
          <Button
            style={{ color: COLOR_PRIMARY }}
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1,
              })
            }
          >
            Avançar
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const tipo_envio_campanha = props.sms.tipo_envio_campanha;
    const mensurar_campanha = props.sms.mensurar_campanha;
    return (
      <section style={{ minHeight: '50vh' }}>
        <section
          style={{
            padding: '2rem 15rem',
            display: 'grid',
            gap: '2rem',
          }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Tipo da campanha
          </Typography>
          <Select
            label="Tipo da campanha"
            options={
              this.props.tipo_usuario != 'simplificado'
                ? TIPO_ENVIO_CAMPANHA.slice(0, 2)
                : TIPO_ENVIO_CAMPANHA.slice(0, 1)
            }
            placeholder="Selecione o tipo da campanha..."
            value={tipo_envio_campanha}
            onChange={tipo_campanha =>
              props.onChange('tipo_envio_campanha', tipo_campanha)
            }
          />
          {Boolean(tipo_envio_campanha) && tipo_envio_campanha.id === 2.1 && (
            <Select
              label="O que mensurar ?"
              options={TIPO_ENVIO_CAMPANHA.slice(2)}
              placeholder="Selecione o que deseja mensurar..."
              onChange={mensurar_campanha =>
                props.onChange('mensurar_campanha', mensurar_campanha)
              }
              value={mensurar_campanha}
            />
          )}
          {Boolean(mensurar_campanha) &&
            (mensurar_campanha.id === 3 || mensurar_campanha.id === 4) && (
              <Select
                label="Tabloide Digital"
                placeholder="Selecione um tabloide..."
                options={props.tabloides}
                labelKey="nome_tabloide_digital"
                valueKey="id_tabloide_digital"
                onChange={selectedTabloide =>
                  props.onChange('selectedTabloide', selectedTabloide)
                }
              />
            )}
        </section>
      </section>
    );
  }

  step2(props, state) {
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Informações da campanha
          </Typography>
          <TextField
            label="Nome"
            className="form-control"
            onChange={({ target }) => {
              props.onChange('nomeCampanha', target.value);
            }}
            value={props.sms.nomeCampanha}
            autoComplete="none"
          />
          <Select
            label={props.sms.ofertas ? 'Oferta' : 'Audiência'}
            disabled
            options={props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao,
            }))}
            placeholder="Selecione uma lista..."
            onChange={option =>
              props.contadorCelular({
                celulares: props.sms.selectedCelular.map(opt => opt.value),
                ...option,
              })
            }
            value={props.sms.optionList}
          />

          {props.sms.ofertas && (
            <Select
              label="Audiência"
              options={props.listsProspectei.map(list => ({
                label: list.nameList,
                value: list.idListProspectei || list.idPromocao,
              }))}
              placeholder="Selecione uma audiência..."
              onChange={option => {
                props.onChange('listaSelecionada', option);
                props.contadorCelular({
                  celulares: props.sms.selectedCelular.map(opt => opt.value),
                  ...option,
                });
              }}
              value={props.sms.listaSelecionada}
            />
          )}

          <section>
            <DatePicker
              dateAndTime
              label="Início da campanha"
              onChange={date => props.onChange('dataInicioCampanha', date)}
              value={props.sms.dataInicioCampanha}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              label="Término da campanha"
              onChange={date => props.onChange('dataFimCampanha', date)}
              value={props.sms.dataFimCampanha}
            />
          </section>

          <Select
            disabled={
              props.sms.tipo_envio_campanha &&
              props.sms.tipo_envio_campanha.id === 1
                ? true
                : false
            }
            label="Grupo do controle"
            options={PARETO}
            valueKey="id"
            placeholder="Grupo do controle..."
            onChange={option => props.onChange('pareto', option)}
            value={props.sms.pareto}
          />
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Informações do disparo
          </Typography>
          <DatePicker
            dateAndTime
            label="Envio de sms"
            onChange={date => props.onChange('dataInicio', date)}
            value={props.sms.dataInicio}
          />
          <Select
            up
            label="Celulares à enviar"
            onChange={option => {
              props.onChange('selectedCelular', option);
              props.contadorCelular({
                celulares: option && option.map(opt => opt.value),
                pra_quem: props.sms.pra_quem,
                origem_campanha: props.sms.origem_campanha,
                value: props.sms.optionList
                  ? props.sms.optionList.value
                  : props.sms.idListProspectei,
              });
            }}
            value={props.sms.selectedCelular}
            options={CELULARES}
            multiple
          />
          {props.sms.redirectTracker &&
            props.sms.redirectTracker.includes('ofertasdoclube') && (
              <Select
                up
                label="Template"
                onChange={option => props.onChange('selectedTemplate', option)}
                value={props.sms.selectedTemplate}
                placeholder="Template..."
                valueKey="id_template"
                labelKey="nome_template"
                options={props.listaTemplate.data}
              />
            )}

          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Números copiados na campanha"
              className="form-control"
              onChange={({ target }) => this.onPasteAdd(target.value)}
              onKeyUp={({ keyCode, target: { value } }) =>
                this.onAdd(keyCode, value)
              }
              value={state.phoneTest}
              autoComplete="none"
            />
            <Typography variant="caption">
              *Separe os números por <strong>vírgula</strong>, ou pressione{' '}
              <strong>enter</strong>.
            </Typography>
          </section>
          {state.showNumeros &&
            props.sms.numerosList.map((numero, index) => (
              <section
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginTop: '2vh',
                }}
              >
                <strong>{numero.label}</strong>
                <MyButton
                  bsSize="xs"
                  bsstyle="danger"
                  onClick={() => this.removerNumero(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </MyButton>
              </section>
            ))}
        </section>
      </section>
    );
  }

  step3(props) {
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Formulários
          </Typography>
          <Select
            label="Formulários"
            options={props.listFieldsFormClients}
            valueKey="id_formulario"
            labelKey="nameForm"
            placeholder="Formulário..."
            onChange={form =>
              Boolean(form)
                ? props.onChange('redirectTracker', `${form.linkForm}?h=:hash`)
                : props.onChange('redirectTracker', '')
            }
          />
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            variant="h5"
          >
            Rastreio
          </Typography>
          {props.sms.redirectTracker &&
            (props.sms.redirectTracker.includes('ofertasdoclube') ||
              props.sms.redirectTracker.includes('cobratudo')) && (
              <Select
                label="Domínio link"
                options={HOST_LINK}
                valueKey="host"
                placeholder="Domínio..."
                onChange={option =>
                  this.setLinkTabloidPerson(this.props, option)
                }
                defaultValue={[this.state.linkHost]}
              />
            )}
          <TextField
            label="Link (URL)"
            className="form-control"
            disabled={
              props.sms.redirectTracker &&
              (props.sms.redirectTracker.includes('ofertasdoclube') ||
                props.sms.redirectTracker.includes('cobratudo') ||
                props.sms.redirectTracker.includes('?h=:hash'))
            }
            onChange={({ target }) =>
              props.onChange('redirectTracker', target.value)
            }
            value={props.sms.redirectTracker}
          />

          <Select
            label="Domínio"
            options={HOST_TRACKER}
            disabled
            valueKey="host"
            placeholder="Domínio..."
            onChange={option => props.onChange('hostTracker', option)}
            value={HOST_TRACKER[0]}
          />
          <TextField
            label="Facebook Pixel"
            className="form-control"
            autoComplete="none"
            value={props.sms.facebook_id}
            onChange={({ target: { value } }) =>
              props.onChange('facebook_id', value)
            }
          />
          <TextField
            id="outlined-multiline-static"
            label="Google Tag"
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            rows="10"
            value={props.sms.google_tag}
            onChange={({ target: { value } }) =>
              props.onChange('google_tag', value)
            }
          />
        </section>
      </section>
    );
  }

  step4(props, state) {
    return (
      <section style={{ padding: '2rem 15rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          variant="h5"
        >
          Mensagem
        </Typography>
        <Grid container spacing={1}>
          {VARIAVEL_CAMPANHA.map(variavel => (
            <Grid item xs>
              <Chip
                label={variavel.name}
                clickable
                onClick={() =>
                  props.onChange(
                    'message',
                    `${props.sms.message} ${variavel.value}`
                  )
                }
              />
            </Grid>
          ))}
        </Grid>

        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="10"
          error={state.showAlert}
          value={props.sms.message}
          margin="normal"
          variant="outlined"
          helperText={`${props.sms.message.length}/160`}
          onChange={({ target }) => {
            props.onChange(
              'message',
              target.value.length > 160 ? props.sms.message : target.value
            );
            this.setState({
              showAlert: target.value.length > 160 ? true : false,
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: 'flex', flexDirection: 'column' }} />
              </InputAdornment>
            ),
          }}
        />
      </section>
    );
  }

  step5(props, state) {
    return (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          variant="h5"
        >
          Configurações automação
        </Typography>
        <Checkbox
          checked={Boolean(props.sms.automacao_campanha_sms)}
          onChange={({ target: { checked } }) =>
            props.onChange('automacao_campanha_sms', Boolean(checked) ? 1 : 0)
          }
          label="Fazer disparos automáticos da campanha ?"
        />
        {Boolean(props.sms.automacao_campanha_sms) && (
          <Fragment>
            <TextField
              type="number"
              label="A cada"
              className="form-control"
              onChange={({ target: { value } }) =>
                props.onChange('unitAutomacao', value)
              }
              value={props.sms.unitAutomacao}
            />
            <Select
              label="Unidade de tempo"
              options={UNIDADE_TEMPO}
              onChange={tipo => props.onChange('unitTimeAutomacao', tipo)}
              value={props.sms.unitTimeAutomacao}
            />
            {props.sms.unitTimeAutomacao &&
              props.sms.unitTimeAutomacao.value !== 'days' &&
              props.sms.unitTimeAutomacao.value !== 'hours' && (
                <Select
                  label="Todas às"
                  options={
                    props.sms.unitTimeAutomacao.value !== 'months'
                      ? DIAS
                      : DIAS_MES
                  }
                  onChange={dia => props.onChange('diaDisparoAutomacao', dia)}
                  value={props.sms.diaDisparoAutomacao}
                />
              )}
            {props.sms.unitTimeAutomacao &&
              props.sms.unitTimeAutomacao.value !== 'hours' && (
                <DatePicker
                  time
                  label="às"
                  format="HH:mm"
                  onChange={date =>
                    props.onChange('horaDisparoAutomacao', date)
                  }
                  value={props.sms.horaDisparoAutomacao}
                />
              )}
          </Fragment>
        )}
      </section>
    );
  }

  step6(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{
            padding: '2rem 15rem',
            display: 'grid',
            gap: '2rem',
            color: BG_PRIMARY,
          }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Sobre a Campanha
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome</strong>
              </p>
              <span>{props.sms.nomeCampanha}</span>
            </section>

            {Boolean(props.sms.optionList) && (
              <section style={{ paddingBottom: '2rem' }}>
                <p>
                  <strong>Audiência</strong>
                </p>
                <span>{props.sms.optionList.label}</span>
              </section>
            )}
            {Boolean(props.sms.automacao_campanha_sms) && (
              <section style={{ paddingBottom: '2rem' }}>
                <p>
                  <strong>Automação</strong>
                </p>

                <span>{textAutomation(props.sms)}</span>
              </section>
            )}
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data Disparo</strong>
              </p>
              <span>
                {moment(props.sms.dataInicio).format('DD/MM/YYYY HH:mm')}
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Valor do Investimento</strong>
              </p>
              <span>
                <Countup
                  prefix="R$"
                  decimals={3}
                  isLoading={props.isLoadingCountPhone}
                  end={parseFloat(props.contador_campanha.valor_total || 0)}
                />
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Total de Envios</strong>
              </p>
              <span>
                <Countup
                  isLoading={props.isLoadingCountPhone}
                  end={props.contador_campanha.contador_celular || 0}
                />
              </span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Mensagem
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <span>{props.sms.message}</span>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900', color: COLOR_PRIMARY }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            {props.sms.redirectTracker &&
              !props.sms.message.includes('{clickTracker}}') && (
                <p>
                  Não achamos na sua mensagem a variável do link que voce criou.
                  <br />
                  Deseja prosseguir com o disparo ?
                </p>
              )}

            <br />

            <MyButton
              disabled={_disableButtonSave(props.sms, state.showAlert)}
              onClick={props.onSave}
              style={{
                backgroundColor: !_disableButtonSave(props.sms, state.showAlert)
                  ? COLOR_PRIMARY
                  : 'rgba(0, 0, 0, 0.26)',
              }}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      case 4:
        return this.step5(props, state);
      case 5:
        return this.step6(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, sms } = this.props;

    const { activeStep } = this.state;

    return (
      <Modal
        showModal={sms.showModalSMS}
        onClose={onClose}
        size="lg"
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const _disableButtonSave = (sms, alert) => {
  if (sms.message && !alert && (sms.optionList || sms.idListProspectei)) {
    if (sms.ofertas && Object.keys(sms.selectedTemplate).length === 0) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

const mapStateToProps = ({
  CampaignReducer,
  InsightsReducer,
  TemplateReducer,
  TabloideDigitalReducer,
  AccountSettingsReducer,
}) => ({
  ...CampaignReducer,
  ...InsightsReducer,
  ...TemplateReducer,
  ...TabloideDigitalReducer,
  ...AccountSettingsReducer,
});

const mapDispatchToProps = {
  contadorCelular,
  getTemplates,
  addNumeroCampanha,
  removeNumeroCampanha,
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaCampanhaSMS);
