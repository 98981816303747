const INITIAL_STATE = {
  isLoadingCupom: false,
  cuponsSorteio: [],
  cuponsSorteioPessoas: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_CUPOM_SORTEIO":
      return { ...state, cuponsSorteio: action.payload, isLoadingCupom: false };
    case "GET_CUPOM_SORTEIO_PESSOA":
      return {
        ...state,
        cuponsSorteioPessoas: action.payload,
        isLoadingCupom: false
      };
    case "LOADING_CUPOM_SORTEIO":
      return { ...state, isLoadingCupom: true };
    default:
      return state;
  }
};
