import React from 'react';
import { Avatar, Box, Grow } from '@material-ui/core';
import { SmsRounded, WhatsApp } from '@material-ui/icons';
import {
  Message,
  MessageImage,
} from '../../../../Chat/components/chat-elements/components';
import useStyles from '../styles';
import bgChat from '../../../../../assets/images/backChat.jpg';

export default function Phone({ isWhatsapp, isStatusWhatsapp, data }) {
  const classes = useStyles(),
    chat = {
      i_send: 1,
      message: data.whatsapp_midia ? data.whatsapp_url : data.mensagem,
      caption: data.mensagem,
      data_envio: data.data_envio && data.data_envio.replace('Z', ''),
    };

  return (
    <Grow in>
      <Box className={classes.root}>
        <Box
          className={classes.contentIphone}
          style={{
            backgroundColor: isStatusWhatsapp ? '#000' : '#FFF',
            backgroundSize: isStatusWhatsapp ? 'contain' : 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: isStatusWhatsapp
              ? `url(${data.whatsapp_url})`
              : isWhatsapp
              ? `url(${bgChat})`
              : 'transparent',
          }}
        >
          <ul>
            <li
              style={{
                backgroundColor: isStatusWhatsapp ? 'transparent' : '#F6F6F6',
              }}
              className={classes.btnVolune}
            >
              <Box display="flex" alignItems="center" padding="2vh 1vw 0">
                <Avatar className={classes.avatarMobile}>
                  {isWhatsapp || isStatusWhatsapp ? (
                    <WhatsApp style={{ fontSize: '12pt' }} />
                  ) : (
                    <SmsRounded style={{ fontSize: '12pt' }} />
                  )}
                </Avatar>
                &nbsp;
                <b
                  style={{
                    fontSize: '9pt',
                    color: isStatusWhatsapp ? '#FFF' : '#000',
                  }}
                >
                  {isStatusWhatsapp
                    ? 'Meus status'
                    : isWhatsapp
                    ? 'Whatsapp'
                    : 'SMS'}
                </b>
              </Box>
            </li>
          </ul>

          <Box className={classes.topbar}>
            <Box className={classes.contentTopbar} />
          </Box>

          <Box
            onClick={() => isStatusWhatsapp && window.open(data.whatsapp_url)}
            style={{ cursor: isStatusWhatsapp ? 'pointer' : '' }}
            display={isStatusWhatsapp ? 'flex' : 'initial'}
            alignItems="flex-end"
            justifyContent="center"
            height={isStatusWhatsapp ? '85%' : 'auto'}
            className={classes.contentMobile}
          >
            {isStatusWhatsapp ? (
              <Box color="#FFF" textAlign="center">
                <span>{data.mensagem}</span>
              </Box>
            ) : data.whatsapp_midia ? (
              <MessageImage
                item={chat}
                onClick={() => window.open(data.whatsapp_url)}
              />
            ) : (
              <Message item={chat} />
            )}
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}
