import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Card from '../CardCampaign';
import DialogViewMore from '../DialogViewMore';
import { useHome } from '../../../../hooks/home';
import useDate from '../../../../hooks/date';
import useStyles from '../../styles';

export default function Private() {
  const classes = useStyles(),
    [openViewMore, setOpenViewMore] = useState(false),
    [posts, setPosts] = useState([]),
    [dataViewMore, setDataViewMore] = useState({}),
    { getDate } = useDate(),
    { campaigns } = useHome();

  useEffect(() => {
    const _posts = campaigns.filter(
      campaign => campaign.tipo_campanha === 'WHATSAPP'
    );
    setPosts(_posts);
  }, [campaigns]);

  return (
    <Grid container spacing={3}>
      {Boolean(posts.length) ? (
        posts.map(post => (
          <Grid xs={4} key={post.ID_CAMPANHA} item>
            <Card
              title={post.nome_campanha}
              startDate={getDate('DD/MM/YYYY', post.data_inicio_campanha)}
              endDate={getDate('DD/MM/YYYY', post.data_fim_campanha)}
              image={post.whatsapp_url}
              onClickMore={() => {
                setOpenViewMore(true);
                setDataViewMore({
                  id: post.ID_CAMPANHA,
                  name: post.nome_campanha,
                  type: 'WHATSAPP',
                  send_date: getDate('DD/MM/YYYY', post.data_envio),
                  start_date: getDate('DD/MM/YYYY', post.data_inicio_campanha),
                  end_date: getDate('DD/MM/YYYY', post.data_fim_campanha),
                });
              }}
            />
          </Grid>
        ))
      ) : (
        <Grid xs={12} item>
          <Typography
            style={{ color: '#616161' }}
            className={classes.cardTitle}
          >
            Não encontramos envios no privado
          </Typography>
        </Grid>
      )}
      <DialogViewMore
        open={openViewMore}
        onClose={() => setOpenViewMore(false)}
        data={dataViewMore}
      />
    </Grid>
  );
}
