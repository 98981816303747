import styled from '@emotion/styled';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
  Button,
  Fade,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { EmptyGallery } from 'modules/TabloideDigitalV3/pages/EmptyGallery';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
// import { NavItem } from 'react-bootstrap';
// import { DateRange } from 'react-date-range';
// import * as locales from 'react-date-range/dist/locale';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { AlertDialogV2 } from 'utils/components/AlertDialogV2/index';
import { TimelineV2 } from 'utils/components/TimelineV2';
import { useSelectMenu } from 'utils/helpers/Redirect';

import { Analytics, CreateTabloid } from '../../../modules/TabloideDigitalV2/components';
import {
  EditMeusFundosNew,
  EditMeusModelos,
  EditMeusModelosNew
} from '../../../modules/TabloideDigitalV2/components';
import CreateOptions from '../../../modules/TabloideDigitalV2/components/CreateOptions/index';
import EditModelosOptions from '../../../modules/TabloideDigitalV2/components/EditModelosOptions/index';
import { FilterAdvertisements } from '../../../modules/TabloideDigitalV2/components/FilterAdvertisements';
import StudioImages from '../../../modules/TabloideDigitalV2/components/StudioImages';
import { useDefaultStyles } from '../../../modules/TabloideDigitalV2/style';
import _Alert from '../../../utils/helpers/Alert';
import {
  AMBIENTE,
  COLOR_LIGHT_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_NEW_PRIMARY,
  COLOR_SECONDARY,
  DATASALES_CRM_API
} from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import { toBase64 } from '../../../utils/helpers/Functions';
import { DivTitle, TypographySubTitle, TypographyTitle } from '../Studio/styles';

const _alert = new _Alert();

export const StyleWrapper = styled.div`
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 8px;
  }

  .fc-event-title {
    white-space: normal;
    cursor: pointer;
  }
  .fc-toolbar-title {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 26px;
    font-family: montserrat;
  }

  .fc-prev-button {
    background-color: ${COLOR_NEW_PRIMARY} !important;
    border-color: ${COLOR_NEW_PRIMARY} !important;
    color: white !important;
  }

  .fc-next-button {
    background-color: ${COLOR_NEW_PRIMARY} !important;
    border-color: ${COLOR_NEW_PRIMARY} !important;
    color: white !important;
  }

  .fc-view-harness {
    background-color: white;
  }

  .fc-button-primary {
    background-color: ${COLOR_LIGHT_GRAY};
    border-color: ${COLOR_LIGHT_GRAY};
    color: ${COLOR_LIGHT_BLACK};
    outline: none !important;
  }

  .fc-button-secondary {
    background-color: ${COLOR_SECONDARY};
  }

  .fc-button-primary:hover {
    background-color: ${COLOR_NEW_PRIMARY};
    border-color: ${COLOR_NEW_PRIMARY};
  }

  .fc-button-primary:focus {
    background-color: ${COLOR_NEW_PRIMARY} !important;
    border-color: ${COLOR_NEW_PRIMARY} !important;
  }

  .fc-button-primary:disabled,
  .fc-button-primary:disabled:hover {
    opacity: 0.95;
    background-color: ${COLOR_LIGHT_GRAY};
    border-color: ${COLOR_LIGHT_GRAY};
    cursor: not-allowed;
  }

  .fc-button-secondary:hover {
    background-color: #e8e8fd;
    border-color: #e8e8fd;
  }

  .fc-h-event {
    background-color: ${COLOR_NEW_PRIMARY};
    border-color: ${COLOR_NEW_PRIMARY};
  }

  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: ${COLOR_NEW_PRIMARY} !important;
    border-color: ${COLOR_NEW_PRIMARY} !important;
  }
`;

const formatDate = (date: moment.MomentInput) => moment(date).format('DD/MM/YYYY');

const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));

function Projects(props: any) {
  const magnumClasses = useDefaultStyles();

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { selectMenu } = useSelectMenu();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [tabloids, setTabloids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [tabloid, setTabloid] = useState(null);
  const [tabloidSelected, setTabloidSelected] = useState(null);
  const tabloidIsEmpty =
    tabloid &&
    Object.keys(tabloid).length === 0 &&
    Object.getPrototypeOf(tabloid) === Object.prototype;

  const [open, setOpen] = useState({
    createTabloid: false,
    createPlanejamento: false,
    createOptions: false,
    editTabloid: false,
    accessLog: false,
    editModelosOptions: false,
    deleteTabloid: false,
    duplicateTabloid: false
  });
  const [filtroDataInicio, setFiltroDataInicio] = useState(moment().subtract(1, 'month').toDate());
  const [filtroDataFim, setFiltroDataFim] = useState(moment().toDate());

  const [openStudio, setOpenStudio] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [nameSearch, setNameSearch] = useState('');

  const [abaEmFoco, setAbaEmFoco] = useState(0);

  const [editMeusModelosOpen, setEditMeusModelosOpen] = useState(false);
  const [editMeusModelosNewOpen, setEditMeusModelosNewOpen] = useState(false);
  const [editMeusFundosNewOpen, setEditMeusFundosNewOpen] = useState(false);

  useEffect(() => {
    if (queryString.parse(location?.search)?.createNewTabloid) {
      setOpen({ ...open, createOptions: false, createTabloid: true });
      history.push({
        search: ''
      });
    }
  });

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTabloid(null);
  };

  async function getTabloids(data: { dataInicio: any; dataFim: any } | undefined) {
    // this.props.clearSelectedShareableItem();

    data = {
      ...data,
      dataInicio: (data && data.dataInicio) || moment(filtroDataInicio).format('YYYY-MM-DD'),
      dataFim: (data && data.dataFim) || moment(filtroDataFim).add('1', 'days').format('YYYY-MM-DD')
    };
    if (params?.id) {
      data = {
        ...data,
        id_tabloide: params?.id
      };
    }
    if (nameSearch) {
      data = {
        ...data,
        nome: nameSearch
      };
    }
    try {
      setLoading(true);

      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        data
      );

      setTabloids(response);

      setLoading(false);

      if (params?.id) {
        //testar retorno nulo

        if (response.length > 0) {
          setTabloid(response[0]);
          setOpenStudio(true);
        } else {
          setTabloid({});
        }
      }
    } catch (err) {
      setLoading(false);
    }
  }

  const [countSearch, setCountSearch] = useState(0);

  useEffect(() => {
    let timer1: NodeJS.Timeout | undefined;
    setLoading(true);
    if (countSearch === 0) {
      if (queryString.parse(location?.search)?.createNewTabloid) {
      } else {
        getTabloids();
      }
    } else {
      timer1 = setTimeout(() => getTabloids(), 1500);
    }
    setCountSearch(prev => prev + 1);
    return () => {
      clearTimeout(timer1);
    };
  }, [nameSearch]);

  async function handleSubmitBanner(
    tabloidId: any,
    banner: { position: any; device: any; href: any; file: any }
  ) {
    try {
      const { position, device, href, file } = banner;

      const base64 = await toBase64(file);
      return await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/banner`,
        {
          tabloidId,
          position,
          device,
          href,
          ...base64
        },
        'POST'
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function handleCreateTabloid(data: any, banners: undefined) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
        data,
        'POST',
        null,
        null,
        false
      );

      const { tabloide } = response;

      // if (banners && banners.length > 0) {
      //   await asyncForEach(
      //     banners,
      //     async banner => await handleSubmitBanner(id, banner)
      //   );
      // }

      //getTabloids();

      enqueueSnackbar(response.title, {
        variant: 'success'
      });

      history.push('/estudio/' + tabloide.id_tabloide_digital);
    } catch (err) {
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
      console.log(err);
      //getTabloids();
    }
  }

  const [handleEditTabloidCount, setHandleEditTabloidCount] = useState(0);

  useEffect(() => {
    if (handleEditTabloidCount > 0) {
      params?.id && getTabloids();
    }
  }, [handleEditTabloidCount]);

  function closeSelectedTabloid() {
    setTabloidSelected(null);
    setOpen({ ...open, deleteTabloid: false, duplicateTabloid: false });
    setLoadingAction(false);
  }

  async function handleDeleteTabloid(item: { id_tabloide_digital: any } | null) {
    setLoadingAction(true);
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/removeTabloide`,
        { idTabloide: item.id_tabloide_digital },
        'PUT',
        null,
        null,
        false
      );
      closeSelectedTabloid();
      enqueueSnackbar(response.title, {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
      closeSelectedTabloid();
    } finally {
      closeSelectedTabloid();
      getTabloids();
    }
  }

  async function handleDuplicateTabloid(item: null) {
    setLoadingAction(true);
    try {
      let tmp = { ...item };
      if (tmp?.colors?.id) delete tmp.colors.id; // descobrir porquê delete.colors

      handleCreateTabloid({
        ...tmp,
        nome_tabloide: tmp.nome_tabloide_digital,
        storeId: tmp.loja,
        copia: true
      });
      closeSelectedTabloid();
    } catch (err) {
      closeSelectedTabloid();
      console.log(err);
    }
  }

  const [indexGaleria, setIndexGaleria] = useState(2);

  const fator = 10;
  const totalImagens = indexGaleria * fator;
  // const galeriaTabloides = tabloids.slice(0, totalImagens);

  const loadData = async () => {
    await sleep(300).then(() => setIndexGaleria(indexGaleria + 1));
  };

  const clickOnTabloid = (item: { id_tabloide_digital: { toString: () => string } }) => {
    history.push('/estudio/' + item.id_tabloide_digital.toString());
  };

  const is396 = JSON.parse(localStorage.getItem('companyId')) === 396 && AMBIENTE === 'dev';

  const galleryDatas = {
    tabloids: tabloids,
    matches: matches,
    indexGaleria: indexGaleria,
    loading: loading,
    onClick: onClick,
    loadData: loadData
  };

  function onClick(op: any, item: React.SetStateAction<null>) {
    console.log({ op }, { item });
    switch (op) {
      case 'abrir':
        clickOnTabloid(item);
        break;
      case 'deletar':
        setOpen({ ...open, deleteTabloid: true });
        setTabloidSelected(item);
        break;
      case 'duplicar':
        setOpen({ ...open, duplicateTabloid: true });
        setTabloidSelected(item);
        break;
    }
  }

  const tabs = [
    {
      label: 'Galeria',
      index: 0,
      handleClick: () => getTabloids(),
      children: (
        <Grid container>
          <Grid item>
            <FilterAdvertisements
              nameSearch={nameSearch}
              onChangeText={event => {
                setNameSearch(event.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  const id = e.target.value.toString();
                  selectMenu(
                    'criacao',
                    'templates,propaganda_de_ofertas',
                    `/estudio/${id}`,
                    'Estúdio'
                  );
                  //history.push('/estudio/' + e.target.value.toString());
                }
              }}
              onClickOpen={() => setOpenFilter(prev => !prev)}
              openFilter={openFilter}
              onChangeDateRange={({ selection }) => {
                console.log(selection);
                setFiltroDataInicio(selection.startDate);
                setFiltroDataFim(selection.endDate);
              }}
              ranges={[
                {
                  startDate: filtroDataInicio,
                  endDate: filtroDataFim,
                  key: 'selection'
                }
              ]}
              onClickSerch={() => getTabloids()}
              button={
                <Button
                  id="create-new-advertising"
                  data-cy={'CriarNovoButton'}
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ background: '#2813AD' }}
                  onClick={() => {
                    selectMenu('criacao', 'templates,propaganda_de_ofertas', null, 'Estúdio');
                    setOpen({ ...open, createTabloid: true });
                  }}
                >
                  Nova propaganda
                </Button>
              }
            />
          </Grid>
          <Grid item xs={12}>
            {!loading && tabloids.length === 0 && <EmptyGallery />}
            <StudioImages tab="gallery" galleryDatas={galleryDatas} />

            {/* <InfiniteScroll
              dataLength={galeriaTabloides.length}
              next={loadData}
              hasMore={indexGaleria * fator < tabloids.length}
            >
              <ImageList
                cellHeight={'auto'}
                cols={matches ? 6 : 4}
                gap={3}
                sx={{
                  padding: theme => theme.spacing(0, 0, 0),
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {loading
                  ? Array(12)
                    .fill(12)
                    .map((_, i) => (
                      <div key={i} style={{ padding: '13px' }}>
                        <Skeleton
                          className={`${magnumClasses.gridMediaQueryHeight} ${magnumClasses.gridCard}}`}
                          style={{ height: 290, width: 180, borderRadius: 5 }}
                          variant="rect"
                        />
                      </div>
                    ))
                  : galeriaTabloides.map((item, index) => (
                    <div
                      key={item.id_tabloide_digital.toString()}
                      className={magnumClasses.cardTabloids}
                      style={{
                        width: 180
                      }}
                    >
                      <div
                        key={item.id_tabloide_digital.toString()}
                        style={{
                          marginRight: 24,
                          marginBottom: 32,
                          width: 180,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}
                        className={magnumClasses.gridCard}
                        title={item.nome_tabloide_digital + ' - id: ' + item.id_tabloide_digital}
                      >
                        <div
                          key={item.id_tabloide_digital.toString()}
                          style={{
                            marginRight: 24,
                            marginBottom: 32,
                            width: 180,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}
                          className={magnumClasses.gridCard}
                          title={item.nome_tabloide_digital + ' - id: ' + item.id_tabloide_digital}
                        >
                          <div className={magnumClasses.gridMediaQueryHeight}>
                            <div className={magnumClasses.cardOptions}>
                              <div
                                className={magnumClasses.redirectEditor}
                                onClick={() => clickOnTabloid(item)}
                              ></div>
                              <div
                                className={magnumClasses.wrapTrashText}
                                onClick={() => {
                                  setOpen({ ...open, duplicateTabloid: true });
                                  setTabloidSelected(item);
                                }}
                              >
                                Duplicar
                              </div>
                              <div
                                className={magnumClasses.wrapTrashIcon}
                                onClick={() => {
                                  setOpen({ ...open, deleteTabloid: true });
                                  setTabloidSelected(item);
                                }}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </div>
                          </div>

                          <img
                            className={magnumClasses.gridImageEstudio2}
                            src={
                              item.artes && item.artes[0]
                                ? item.artes[0]?.miniatura || item.artes[0]?.link
                                : 'https://datasalesio-imagens.s3.amazonaws.com/empty_image_propaganda_02.png'
                            }
                            alt={item.nome_tabloide_digital}
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: 8 }}>
                        <div
                          className={magnumClasses.gridTitle2}
                          title={
                            item.nome_tabloide_digital + ' - id: ' + item.id_tabloide_digital
                          }
                        >
                          {item.nome_tabloide_digital}
                        </div>

                        {/* <div
                            className={magnumClasses.gridMoreOptions}
                            style={{ marginTop: 4 }}
                            onClick={e => handleMenuOpen(e, item)}
                          >
                            <AddCircleOutlineIcon
                              style={{
                                marginRight: '5px'
                              }}
                            />{' '}
                            Mais Opções
                          </div> */}
            {/* </div>
                    </div>
                  ))}
              </ImageList>
            </InfiniteScroll> */}
          </Grid>
        </Grid>
      )
    },
    {
      label: 'Meus modelos',
      index: 1,
      children: <EditMeusModelosNew />
    },
    {
      label: 'Meus fundos',
      index: 2,
      children: <EditMeusFundosNew />
    }
  ];

  return (
    <>
      <Switch>
        <Route path="/home" exact>
          <div
            id="#rootTabloidDiv"
            style={{
              padding: '1px 42px',
              overflow: 'hidden',
              maxWidth: '100%'
            }}
          >
            <Grid container direction="column" style={{ marginTop: 0}} 
              sx={{ backgroundColor: '#F2F3F5', paddingTop: '5vh' }}
            >
              <Grid item xs={12} maxHeight={54} letterSpacing="0.05em">
                <DivTitle>
                  <TypographyTitle
                    sx={{
                      '&.MuiTypography-root': {
                        fontSize: '1.25rem',
                        lineHeight: '2rem'
                      }
                    }}
                  >
                    Meus projetos
                  </TypographyTitle>
                </DivTitle>
                <TypographySubTitle marginBottom={'2rem'}>
                  Veja todas as artes de propaganda que você encontrou aqui
                </TypographySubTitle>
              </Grid>
              <Grid item xs={12}>
                <TimelineV2 tabs={tabs} />
              </Grid>
            </Grid>
          </div>
        </Route>
      </Switch>

      <Menu
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuList>
          {tabloid && (
            <React.Fragment>
              <MenuItem>
                <Typography component="h3" variant="body1">
                  {tabloid.nome_tabloide_digital}
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  De {formatDate(tabloid.data_inicio)} Ate
                  {formatDate(tabloid.data_fim)}
                </Typography>
              </MenuItem>
            </React.Fragment>
          )}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              _alert.alertConfirm(
                `Deseja duplicar a propaganda ${tabloid.nome_tabloide_digital} ?`,
                async () => {
                  try {
                    let tmp = { ...tabloid };
                    if (tmp?.colors?.id) delete tmp.colors.id; // descobrir porquê delete.colors

                    handleCreateTabloid({
                      ...tmp,
                      nome_tabloide: tmp.nome_tabloide_digital,
                      storeId: tmp.loja,
                      copia: true
                    });
                  } catch (err) {
                    console.log(err);
                  }
                }
              );
            }}
          >
            <ListItemIcon>
              <FileCopyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Fazer uma copia</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteTabloid(tabloid);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Remover</Typography>
          </MenuItem>
        </MenuList>
      </Menu>

      {open.createTabloid && (
        <CreateTabloid
          open={false} //open.createTabloid
          onSave={handleCreateTabloid}
          onClose={() => setOpen({ ...open, createTabloid: false })}
        />
      )}

      {open.createOptions && (
        <CreateOptions
          open={open.createOptions}
          onSubmit={(value: any) => {
            let tmp = {};
            switch (value) {
              case 'agora':
                tmp = { createTabloid: true };
                break;
              case 'planejamento':
                tmp = { createPlanejamento: true };
                break;
            }
            setOpen({ ...open, createOptions: false, ...tmp });
          }}
          onClose={() => setOpen({ ...open, createOptions: false })}
        />
      )}

      {open.editModelosOptions && (
        <EditModelosOptions
          open={open.editModelosOptions}
          onSubmit={(value: any) => {
            switch (value) {
              case 'atual':
                setEditMeusModelosOpen(true);
                break;
              case 'templates':
                setEditMeusModelosNewOpen(true);
                break;
              case 'backgrounds':
                setEditMeusFundosNewOpen(true);
                break;
            }
            setOpen({ ...open, editModelosOptions: false });
          }}
          onClose={() => setOpen({ ...open, editModelosOptions: false })}
        />
      )}

      {open.accessLog && (
        <Analytics
          open={open.accessLog}
          tabloid={tabloid}
          onClose={() => {
            setOpen({ ...open, accessLog: false });
            setTabloid({});
          }}
        />
      )}

      {editMeusModelosOpen && (
        <EditMeusModelos
          open={editMeusModelosOpen}
          onClose={() => {
            setEditMeusModelosOpen(false);
          }}
        ></EditMeusModelos>
      )}

      {open.deleteTabloid && (
        <AlertDialogV2
          open={open.deleteTabloid}
          loading={loadingAction}
          contentText="Tem certeza que deseja excluir esta pasta de propagandas?"
          handleAgree={() => handleDeleteTabloid(tabloidSelected)}
          handleClose={closeSelectedTabloid}
          textAgree="Excluir"
          textClose="Cancelar"
        />
      )}

      {open.duplicateTabloid && (
        <AlertDialogV2
          open={open.duplicateTabloid}
          loading={loadingAction}
          contentText="Deseja duplicar esta pasta de propagandas?"
          handleAgree={() => handleDuplicateTabloid(tabloidSelected)}
          handleClose={closeSelectedTabloid}
          textAgree="Sim"
          textClose="Não"
        />
      )}
    </>
  );
}

export default Projects;

