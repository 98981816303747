import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Icon,
} from "@material-ui/core";
import {
  Modal,
  MyButton,
  TextField,
  Select,
  Chip,
} from "../../../utils/components";

const steps = ["Informações de Envio", "Mensagem", "Disparar"];

function EditarAutomacaoWifi(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [automacao, setNewAutomacao] = useState({
    ativo: props.automacaoEdit.ativo,
    gatilho: {
      id_automacao_gatilho: props.automacaoEdit.id_automacao_gatilho,
      tipo_gatilho: props.automacaoEdit.tipo_gatilho,
      descricao_gatilho: props.automacaoEdit.descricao_gatilho,
    },
    id_automacao_mensagem: props.automacaoEdit.id_automacao_mensagem,
    mensagem: props.automacaoEdit.mensagem,
    nome_automacao: props.automacaoEdit.nome_automacao,
    tipo: {
      label: props.automacaoEdit.tipo_automacao,
      value: String(props.automacaoEdit.tipo_automacao).toLowerCase(),
    },
  });
  function header() {
    return (
      <Fragment>
        <strong>Editar automação</strong>
        <div style={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index} onClick={() => setActiveStep(index)}>
                <StepButton>{step}</StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  function footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%",
        }}
      >
        {activeStep !== 0 ? (
          <MyButton
            size="large"
            color="primary"
            variant="text"
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0 ? true : false}
          >
            Voltar
          </MyButton>
        ) : (
          <div />
        )}
        {activeStep !== 2 && (
          <MyButton
            size="large"
            color="primary"
            variant="text"
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Próximo
          </MyButton>
        )}
      </div>
    );
  }

  function step1() {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Informações da Automação
          </Typography>
          <TextField
            label="Nome da Automação"
            className="form-control"
            onChange={({ target: { value } }) =>
              setNewAutomacao({ ...automacao, nome_automacao: value })
            }
            value={automacao.nome_automacao}
            autoComplete="none"
          />
          <Select
            label="Gatilho"
            labelKey="descricao_gatilho"
            valueKey="id_automacao_gatilho"
            options={props.gatilhos}
            onChange={(value) =>
              setNewAutomacao({ ...automacao, gatilho: value })
            }
            value={automacao.gatilho}
          />
          <Select
            label="Tipo"
            options={[{ label: "SMS", value: "sms" }]}
            onChange={(tipo) => setNewAutomacao({ ...automacao, tipo })}
            value={automacao.tipo}
          />

          <RadioGroup
            value={parseInt(automacao.ativo, 10)}
            style={{ flexDirection: "row" }}
            aria-label="ativo"
            onChange={({ target: { value } }) =>
              setNewAutomacao({ ...automacao, ativo: parseInt(value, 10) })
            }
          >
            <FormControlLabel
              value={1}
              label="Ativado"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={0}
              label="Desativado"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </section>
      </section>
    );
  }

  function step2() {
    return (
      <section style={{ padding: "2rem 15rem" }}>
        <Chip
          label="Primeiro nome"
          clickable
          onClick={() =>
            setNewAutomacao({
              ...automacao,
              mensagem: `${automacao.mensagem} {{primeiroNome}}`,
            })
          }
        />
        <Chip
          clickable
          label="Nome completo"
          onClick={() =>
            setNewAutomacao({
              ...automacao,
              mensagem: `${automacao.mensagem} {{nomeCompleto}}`,
            })
          }
        />
        <Chip
          clickable
          label="Cupom"
          onClick={() =>
            setNewAutomacao({
              ...automacao,
              mensagem: `${automacao.mensagem} {{voucher}}`,
            })
          }
        />
        <Chip
          clickable
          label="Click tracker"
          onClick={() =>
            setNewAutomacao({
              ...automacao,
              mensagem: `${automacao.mensagem} {{clickTracker}}`,
            })
          }
        />
        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="20"
          value={automacao.mensagem}
          margin="normal"
          variant="outlined"
          helperText={`${automacao.mensagem.length}/160`}
          onChange={({ target: { value } }) =>
            setNewAutomacao({ ...automacao, mensagem: value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: "flex", flexDirection: "column" }}></div>
              </InputAdornment>
            ),
          }}
        />
      </section>
    );
  }

  function step3() {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre à automação
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome da automação: </strong>
                {automacao.nome_automacao}
              </p>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Gatilho: </strong>
                {automacao.gatilho.descricao_gatilho}
              </p>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Tipo da automação: </strong>
                {automacao.tipo.label}
              </p>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Status da automação: </strong>
                {automacao.ativo ? "Ativado" : "Desativado"}
              </p>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Mensagem
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <span>{automacao.mensagem}</span>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={() => props.onEdit(automacao)} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  function renderStep() {
    switch (activeStep) {
      case 0:
        return step1();
      case 1:
        return step2();
      case 2:
        return step3();
      default:
        return step1();
    }
  }

  return (
    <Modal
      size="lg"
      showModal={props.showModal}
      onClose={props.onClose}
      header={header()}
      footer={footer()}
    >
      {renderStep()}
    </Modal>
  );
}

const mapStateToProps = ({ AutomacaoReducer }) => ({
  ...AutomacaoReducer,
});

export default connect(mapStateToProps, null)(EditarAutomacaoWifi);
