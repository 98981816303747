import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { GroupAdd as GroupAddIcon, SentimentSatisfiedRounded } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';
import { AutoFixHighOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import PickerEmoji from 'emoji-picker-react';
import { useFormik } from 'formik';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Audience, AudienceOptions } from 'types/audience';
import * as yup from 'yup';
import { ObjectSchema, StringSchema } from 'yup';

import useAdsDistribution from '../../../../../../hooks/api/distribution/useAdsDistribution';
import { StateProps } from '../../../../../../store/types';
import { Checkbox } from '../../../../../../utils/components';
import {
  AMBIENTE,
  API_WHATSAPP_V2,
  CELULARES,
  DATASALES_CRM_API,
  HOST_TRACKER,
  PARETO
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import * as actionsChatbot from '../../../../../Chatbot/actions';
import QRCodeDialog from '../../../../../Chatbot/component/Dialogs/QRCodeDialog';
import useInterval from '../../../../../Chatbot/utils/UseInterval';
import * as actionsAdsDistribution from '../../../../action';
import FormObserver from '../../../FormObserver';
import { useStyles } from '../../styles';
import ModalNewWhatsappInstance from '../ModalNewWhatsappInstance';

type Props = {
  isStatus?: boolean;
  offerId: number;
  offerName: string;
  backgroundId: number;
  isFromSchedulePost?: boolean;
};

const shippingTypes = [
  {
    name: 'CONTACTS',
    value: 'contacts',
    label: 'Meus contatos'
  },
  {
    name: 'ONLY_GROUPS',
    value: 'onlyGroups',
    label: 'Meus grupos'
  },
  {
    name: 'CONTACTS_GROUPS',
    value: 'contacts_groups',
    label: 'Todos os contatos e grupos'
  },
  {
    name: 'ONLY_IN_COPY',
    value: 'onlyInCopy',
    label: 'Apenas números em cópia'
  },
  {
    name: 'AUDIENCE',
    value: 'audience',
    label: 'Audiência '
  }

  // {
  //   name: 'CONTACTS',
  //   value: 'contacts',
  //   label: 'Individual para cada contato'
  // },
  // {
  //   name: 'ONLY_IN_COPY',
  //   value: 'onlyInCopy',
  //   label: 'Para os números em cópia'
  // },
  // {
  //   name: 'ONLY_GROUPS',
  //   value: 'onlyGroups',
  //   label: 'Para grupos'
  // }
];

export const WHATSAPP_VALIDATION_SCHEMA = yup.object().shape({
  selectedInstance: yup
    .object()
    .shape({
      id: yup.string(),
      ativo: yup.string(),
      codigo: yup.string(),
      id_empresa: yup.number(),
      id_loja: yup.number(),
      nome: yup.string(),
      own_number: yup.string().nullable().notRequired(),
      shared_instancia: yup.number(),
      status: yup.object().shape({
        connected: yup.boolean().default(false)
      }),
      tipo_instancia: yup.number()
    })
    .nullable(true)
    .required('Selecione uma das instâncias listadas'),
  selectedShippingType: yup
    .object()
    .shape({
      label: yup.string(),
      name: yup.string(),
      value: yup.string()
    })
    .nullable(true)
    .required('Selecione uma das opções de envio disponíveis'),
  scheduleData: yup.string(),
  post: yup.string().nullable(true),
  numbersInCopy: yup
    .string()
    .when('selectedShippingType', {
      is: shippingType => {
        return shippingType ? shippingType.name === 'ONLY_IN_COPY' : false;
      },
      then: (schema: StringSchema) => {
        return schema.required(
          'Você precisa digitar os números para qual você deseja fazer os envios em cópia'
        );
      }
    })
    .nullable(),
  groupsToSend: yup.array(yup.string()).when('selectedShippingType', {
    is: shippingType => {
      return shippingType
        ? shippingType.name === 'ONLY_GROUPS' || shippingType.name === 'CONTACTS_GROUPS'
        : false;
    },
    then: (schema: ObjectSchema) =>
      schema.required(
        'Para fazer disparos em grupos de whatsapp, você precisa selecionar os grupos para fazer envios'
      )
  }),
  agreeWhatsappTerms: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
  audience: yup
    .object()
    .nullable()
    .when('selectedShippingType', {
      is: shippingType => {
        return shippingType ? shippingType.name === 'AUDIENCE' : false;
      },
      then: (schema: ObjectSchema) => schema.required('Selecione uma audiencia de destino')
    })
});

const TextField_numbersInCopy: React.FC<any> = ({ formik, classes, ...props }) => {
  const [localNumbers, setLocalNumbers] = useState('');

  useEffect(() => {
    let timer1 = setTimeout(() => formik.setFieldValue('numbersInCopy', localNumbers), 2 * 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [localNumbers]);

  return (
    <TextField
      data-testid="dsMarketing-distribution-numbers-in-copy-whatsapp"
      name="numbersInCopy"
      variant="outlined"
      type="text"
      value={localNumbers}
      onChange={event => {
        setLocalNumbers(event.target.value);
      }}
      minRows={12}
      fullWidth
      disabled={false}
      placeholder="99 99999 9999"
      error={Boolean(formik.errors.numbersInCopy)}
      InputProps={{
        classes: {
          notchedOutline: Boolean(formik.errors.numbersInCopy)
            ? classes.inputBorderError
            : classes.inputNoBorder,
          root: classes.inputBackgroundColor
        }
      }}
    />
  );
};

const WhatsApp: React.FC<Props> = ({
  isStatus,
  offerId,
  offerName,
  backgroundId,
  isFromSchedulePost,
  ...props
}) => {
  const [isQRCodeDialogOpen, setQRCodeDialogOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    adsDistribution: { selectedChannelsFormData, validateForm },
    selectedShareableItem
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);

  const savedData = useMemo(
    () =>
      selectedChannelsFormData?.filter(currentChannel => currentChannel.name === 'whatsapp')[0]
        ?.data,
    []
  );

  const inputRef = useRef();
  const [onClickAutoMessage, setOnClickAutoMessage] = useState(false);
  const [isInstanceDialogOpen, setInstanceDialogOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState({});
  // const [selectedShippingType, setSelectedShippingType] = useState({});
  const [normalizedInstances, setNormalizedInstances] = useState([]);
  const [postText, setPostText] = useState(
    selectedChannelsFormData[0]?.data?.post || selectedChannelsFormData[0]?.data?.text || ''
  );
  const [anchorEmoji, setAnchorEmoji] = useState(false || null);
  const [selectionText, setSelectionText] = useState({
    selectionStart: 0,
    selectionEnd: 0
  });

  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsToSend, setGroupsToSend] = useState([] as Array<string>);
  const [groupsCheckedState, setGroupsCheckedState] = useState([] as Array<boolean>);
  // To control the "SEND TO ALL" checkbox
  const [isAllGroupsSelected, setAllGroupsSelected] = useState<boolean>(false);
  const [openCopyNumbers, setOpenCopyNumbers] = useState<boolean>(false);
  const [audiences, setAudiences] = useState<Array<Audience>>([]);
  const [audienceOptions, setAudienceOptions] = useState([] as Array<AudienceOptions>);
  const [audienceOptionSelected, setAudienceOptionSelected] = useState({} as AudienceOptions);
  const { instancias, lojas }: any = props;

  const { validateThisForm } = useAdsDistribution();

  const history = useHistory();
  const classes = useStyles();

  useInterval(() => {
    if (isQRCodeDialogOpen) {
      var result = props.getStatusInstancia(selectedInstance.id);
      result.then((resultado: { content: { connected: any } }) => {
        if (resultado.content.connected) {
          setQRCodeDialogOpen(false);
        }
      });
    }
  }, [45 * 1000]);

  useEffect(() => {
    if (isQRCodeDialogOpen && props.statusInstancias[selectedInstance.id]) {
      setSelectedInstance({
        ...selectedInstance,
        status: { ...selectedInstance.status, qr: props.statusInstancias[selectedInstance.id].qr }
      });
    }
  }, [isQRCodeDialogOpen, props.statusInstancias]);

  useEffect(() => {
    history.push(history.location.pathname + '?DistributionOnWhatsapp');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  useEffect(() => {
    if (!instancias.length && !lojas.length) {
      props.getInstancias();
      props.getLojas();
    }
    if (!audiences.length) {
      getProspeccaoList();
    }
  }, []);

  /**
   * Normalize an instance to know which store each instance belongs to
   */
  useEffect(() => {
    if (instancias.length > 0 && lojas.length > 0) {
      const normalized_instances = instancias.map(instancia => {
        return {
          ...instancia,
          loja: lojas.find(loja => loja.id_loja === instancia.id_loja) || {}
        };
      });

      console.log(normalized_instances);

      setNormalizedInstances(normalized_instances);
    }
  }, [instancias, lojas]);

  // It starts groupsCheckedState when all of groups are loaded
  useEffect(() => {
    if (isAllGroupsSelected) {
      return;
    }

    setGroupsCheckedState(new Array(groups.length).fill(false) as Array<boolean>);
  }, [groups, isAllGroupsSelected]);

  useEffect(() => {
    formik.setFieldValue('groupsToSend', groupsToSend);
  }, [groupsToSend]);

  useEffect(() => {
    if (isAllGroupsSelected) {
      setGroupsToSend(groups.map(group => group.ID));
    } else {
      setGroupsToSend([]);
    }
  }, [isAllGroupsSelected]);

  useEffect(() => {
    if (
      (Number(props.adsDistribution.currentTab) === 2 || onClickAutoMessage) &&
      !isFromSchedulePost
    ) {
      if (props.auto_message.whatsapp) {
        setPostText(props.auto_message.whatsapp);
      } else {
        setPostText(props.auto_message.all);
      }
      setOnClickAutoMessage(false);
    }
  }, [props.auto_message.whatsapp]);

  useEffect(() => {
    setGroupsCheckedState(new Array(groups.length).fill(false) as Array<boolean>);
    formik.setFieldValue('groupsToSend', []);
    setAllGroupsSelected(false);
  }, [selectedInstance]);

  const loadGroups = useCallback(
    async (idClient: number) => {
      setIsLoadingGroups(true);

      try {
        const response = await api(
          `${API_WHATSAPP_V2}groups/list`,
          {
            client: idClient
          },
          'GET',
          null,
          null,
          false
        );

        setGroups(response.content);
      } catch (err) {
        enqueueSnackbar('Erro ao carregar grupos', { variant: 'error' });
      } finally {
        setIsLoadingGroups(false);
      }
    },
    [setGroups]
  );

  const getProspeccaoList = useCallback(async () => {
    try {
      let response: Array<Audience> = await api(
        `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getListaProspeccao`,
        null,
        'GET',
        null,
        null,
        false
      );
      response = response.filter(audience => audience.not_show !== 1);
      let audienceOptions: Array<AudienceOptions> = response.map((audience, index) => {
        return {
          name: audience.nameList,
          value: audience.idListProspectei,
          label: audience.nameList,
          index: index
        };
      });
      setAudienceOptions(audienceOptions);
      setAudiences(response);
    } catch (err) {
      enqueueSnackbar('Erro ao carregar as audiências', { variant: 'error' });
    }
  }, [setAudiences, setAudienceOptions]);

  const handleGroupsCheckboxOnChange = useCallback(
    (position: number) => {
      const updatedCheckedStatus = groupsCheckedState.map((item, index): boolean => {
        return index === position ? !item : item;
      });

      setGroupsCheckedState(updatedCheckedStatus);

      // Where is true, it starts a new array with the groups checked
      const fromBooleanToGroups = updatedCheckedStatus
        .map((currentState, index) => currentState && groups[index].ID)
        .filter(group => !!group);

      setGroupsToSend(fromBooleanToGroups);
    },
    [groupsCheckedState, setGroupsCheckedState, groups, setGroupsToSend]
  );

  function updateSelectionText() {
    const newPosition = {
      selectionStart: inputRef.current.selectionStart,
      selectionEnd: inputRef.current.selectionEnd
    };
    setSelectionText(newPosition);
  }

  function updateSelectionTextAfterEmoji(n: any) {
    setSelectionText(p => ({
      selectionStart: p.selectionStart + n,
      selectionEnd: p.selectionEnd + n
    }));
  }

  function onEmojiText(emoji: any) {
    if (postText) {
      const newText = `${postText.slice(0, selectionText.selectionStart)}${emoji}${postText.slice(
        selectionText.selectionStart
      )}`;

      setPostText(newText);
    } else {
      setPostText(emoji);
    }
    updateSelectionTextAfterEmoji(emoji.length);
  }

  const formik = useFormik({
    initialValues: {
      selectedCelular: CELULARES,
      dataInicioCampanha: moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
      dataFimCampanha: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      pareto: PARETO[0],
      key: 6,
      hostTracker: HOST_TRACKER[0],
      selectedInstance: savedData?.selectedInstance || null,
      selectedShippingType: savedData?.selectedShippingType || shippingTypes[0],
      numbersInCopy: savedData?.numbersInCopy || '',
      scheduleDate:
        savedData?.scheduleDate ||
        moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
      post: '',
      offerId: offerId,
      offerName: offerName,
      groupsToSend: savedData?.groupsToSend || [],
      agreeWhatsappTerms: JSON.parse(localStorage.getItem('agreeWhatsappTerms')) || false,
      audience: null
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: WHATSAPP_VALIDATION_SCHEMA,
    onSubmit: () => {}
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formik) {
        formik.setFieldValue('post', postText);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [postText]);

  useEffect(() => {
    validateThisForm(formik, 'whatsapp');
  }, [formik.values, validateForm]);

  const isScheduled = selectedShareableItem?.[0]?.dateScheduled;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container className={classes.container} maxWidth="md">
          <div style={{ width: '90%', marginRight: 32 }}>
            <Grid item xs={12}>
              <Typography classes={{ root: classes.typographyTitle }}>
                Informações de distribuição WhatsApp
              </Typography>
              <Grid className={classes.gridContentBtn} container spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} className={classes.gridContent}>
                  <Box className={classes.boxLabelWhatsApp}>
                    <Typography className={classes.typographySubtitleClickabled}>
                      Qual número de WhatsApp
                    </Typography>

                    <Button
                      color="primary"
                      className={classes.typographySubtitleClickabledBtn}
                      style={{ color: instancias.length !== 0 ? '#dfdfdf' : '#127FFF' }}
                      onClick={() => setInstanceDialogOpen(true)}
                      disabled={instancias.length !== 0 ? true : false}
                    >
                      Criar Nova Instância
                    </Button>
                  </Box>
                  <Autocomplete
                    id="id_autocomplete_instance_wpp"
                    data-testid="dsMarketing-distribution-select-instance-whatsapp"
                    autoHighlight
                    fullWidth
                    selectOnFocus
                    autoComplete
                    clearOnBlur
                    options={normalizedInstances}
                    getOptionLabel={option => option.nome}
                    loadingText="Carregando Instâncias..."
                    value={
                      formik.values.selectedInstance
                        ? {
                            id: formik.values.selectedInstance.id,
                            nome: formik.values.selectedInstance.nome
                          }
                        : null
                    }
                    onChange={async (event, newValue) => {
                      setSelectedInstance(newValue);
                      formik.setFieldValue('selectedInstance', newValue);

                      let isConnected;

                      if (newValue?.id) {
                        isConnected = await api(
                          `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/zapi/is-connected`,
                          {
                            client: newValue?.id
                          }
                        );

                        if (!isConnected?.content?.connected) {
                          setQRCodeDialogOpen(true);
                        }

                        await loadGroups(newValue.id);
                      }
                    }}
                    classes={{
                      popper: classes.popper,
                      inputRoot: classes.typographyAutocomplete
                    }}
                    getOptionSelected={(option, value) => option.nome === value.nome}
                    renderOption={option => {
                      return (
                        <>
                          <Typography>{option.nome}</Typography>
                          <Tooltip
                            title={option.shared_instance ? 'Online' : 'Offline'}
                            placement="top"
                          >
                            <Box
                              marginLeft="1vw"
                              width="10px"
                              height="10px"
                              borderRadius="10px"
                              bgcolor={option.shared_instance ? '#43a047' : '#e53935'}
                            />
                          </Tooltip>
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginLeft: '8px',
                              letterSpacing: '5%'
                            }}
                          >
                            {option.shared_instance ? 'Conectado' : 'Desconectado'}
                          </Typography>
                        </>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="selectedInstance"
                        variant="outlined"
                        placeholder={
                          !instancias
                            ? 'Você ainda não tem instâncias conectadas'
                            : 'Selecione uma instância'
                        }
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            notchedOutline: Boolean(formik.errors.selectedInstance)
                              ? classes.inputBorderError
                              : classes.inputNoBorder,
                            root: classes.inputBackgroundColor
                          }
                        }}
                        error={Boolean(formik.errors.selectedInstance)}
                        helperText={
                          Boolean(formik.errors.selectedInstance) ? (
                            <Typography variant="caption">
                              {formik.errors?.selectedInstance}
                            </Typography>
                          ) : (
                            '*obrigatório'
                          )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} className={classes.gridContent}>
                  <Box className={classes.boxLabelWhatsApp}>
                    <Typography className={classes.typographySubtitleClickabled}>
                      Tipo de disparo
                    </Typography>
                  </Box>
                  <Autocomplete
                    id="id_autocomplete_instance_shipping_type"
                    data-testid="dsMarketing-distribution-select-shipping-type-whatsapp"
                    autoHighlight
                    fullWidth
                    selectOnFocus
                    autoComplete
                    clearOnBlur
                    value={formik.values.selectedShippingType}
                    options={shippingTypes}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('selectedShippingType', newValue);
                    }}
                    renderOption={props => (
                      <Box component="li" {...props}>
                        {props.label}{' '}
                        {props.value === 'audience' && (
                          <img
                            loading="lazy"
                            width="95"
                            src={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                            srcSet={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                            alt=""
                          />
                        )}
                      </Box>
                    )}
                    classes={{
                      popper: classes.popper,
                      inputRoot: classes.typographyAutocomplete
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="selectedShippingType"
                        variant="outlined"
                        placeholder="Selecione o tipo de envio"
                        value={formik.values.selectedShippingType}
                        error={Boolean(formik.errors.selectedShippingType)}
                        helperText={
                          Boolean(formik.errors.selectedShippingType) ? (
                            <Typography variant="caption">
                              {formik.errors?.selectedShippingType}
                            </Typography>
                          ) : (
                            '*obrigatório'
                          )
                        }
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            notchedOutline: Boolean(formik.errors.selectedShippingType)
                              ? classes.inputBorderError
                              : classes.inputNoBorder,
                            root: classes.inputBackgroundColor
                          }
                        }}
                      />
                    )}
                  />
                  <Typography className={classes.typographySubtitle}>
                    <strong>Meus contatos:</strong> Dispara para seus contatos ativos do whatsapp.{' '}
                    <br />
                    <strong>Meus grupos:</strong> Disparo para seus grupos do Wathsapp.
                    <br />
                    <strong>Meus contatos e grupos:</strong> Disparo para seus contatos e grupos do
                    Whatsapp.
                    <br />
                    <strong>Audiência:</strong> Disparo realizado para um grupo de pessoas em
                    especifico(Audiência) criado na sua conta do{' '}
                    <img
                      loading="lazy"
                      width="60"
                      src={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                      srcSet={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                      alt=""
                    />
                  </Typography>
                </Grid>

                {formik.values.selectedShippingType &&
                  formik.values.selectedShippingType?.value === 'audience' && (
                    <Grid
                      item
                      container
                      spacing={1}
                      xs={12}
                      style={{ margin: '0px 0px' }}
                      data-testid="dsMarketing-distribution-select-audiences-to-send-whatsapp"
                      className={classes.gridContent}
                    >
                      {!audiences.length ? (
                        <Alert severity="warning">
                          <Typography>
                            <strong>
                              Sem Audiências!!!
                              <br />
                              Se você ainda não tem o nosso produto <></>
                              <img
                                loading="lazy"
                                width="80"
                                src={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                                srcSet={`https://plus.datasales.info/static/media/logo-crm.6186407bac9db10e00cf.png`}
                                alt=""
                              />{' '}
                              em sua conta, entre em contato com o nosso time e saiba mais.
                            </strong>
                          </Typography>
                        </Alert>
                      ) : (
                        <>
                          <Grid container item alignItems="center" spacing={2}>
                            <Grid item>
                              <Typography className={classes.typographySubtitleClickabled}>
                                Audiência
                              </Typography>
                            </Grid>
                          </Grid>
                          <Autocomplete
                            id={`id_autocomplete_audience`}
                            data-testid={'dsMarketing-distribution-audience'}
                            autoHighlight
                            fullWidth
                            selectOnFocus
                            autoComplete
                            clearOnBlur
                            value={audienceOptionSelected}
                            options={audienceOptions}
                            getOptionLabel={(option: AudienceOptions) => option.label}
                            getOptionSelected={(
                              option: AudienceOptions,
                              value: AudienceOptions
                            ) => {
                              return option.value === value.value;
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setAudienceOptionSelected(newValue);
                                formik.setFieldValue('audience', audiences[newValue.index]);
                              } else {
                                setAudienceOptionSelected({} as AudienceOptions);
                                formik.setFieldValue('audience', null);
                              }
                            }}
                            classes={{
                              popper: classes.popper,
                              inputRoot: classes.typographyAutocomplete
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                name="selectedAudience"
                                variant="outlined"
                                placeholder="Selecione uma Audiência"
                                value={formik.values.audience}
                                error={Boolean(formik.errors.audience)}
                                InputProps={{
                                  ...params.InputProps,
                                  classes: {
                                    notchedOutline: Boolean(formik.errors.audience)
                                      ? classes.inputBorderError
                                      : classes.inputNoBorder,
                                    root: classes.inputBackgroundColor
                                  }
                                }}
                                helperText={
                                  Boolean(formik.errors.audience) ? (
                                    <Typography variant="caption">
                                      Selecione uma audiência.
                                    </Typography>
                                  ) : (
                                    '*obrigatório'
                                  )
                                }
                              />
                            )}
                          />
                        </>
                      )}
                    </Grid>
                  )}
                {formik.values?.selectedShippingType?.name !== 'ONLY_GROUPS' && (
                  <>
                    <Grid item xs={12} className={classes.gridContent}>
                      <Checkbox
                        label={'Adicionar números em cópia'}
                        checked={openCopyNumbers}
                        onChange={() => {
                          setOpenCopyNumbers(prev => !prev);
                        }}
                        value={openCopyNumbers}
                      />
                    </Grid>

                    {openCopyNumbers && (
                      <>
                        <Grid item xs={12} className={classes.gridContent}>
                          <Box className={classes.boxLabelWhatsApp}>
                            <Typography className={classes.typographySubtitleClickabled}>
                              Números em cópia
                            </Typography>
                            <Typography
                              className={classes.typographySubtitle}
                              style={{ margin: 0, marginLeft: 8 }}
                            >
                              *limitado a 15 números
                            </Typography>
                          </Box>

                          <TextField_numbersInCopy formik={formik} classes={classes} />
                          <Typography
                            className={classes.typographyWarning}
                            style={{ marginTop: 8 }}
                          >
                            <strong>Separe os números com ponto-e-vírgula ;</strong>
                            <br />
                            Ao adicionar números em cópia, você concorda com a{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.whatsapp.com/legal/privacy-policy/?locale=pt_BR"
                            >
                              política de privacidade do WhatsApp.
                            </a>
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {groups.length > 0 &&
                  formik.values.selectedShippingType &&
                  (formik.values.selectedShippingType?.value === 'contacts_groups' ||
                    formik.values.selectedShippingType?.value === 'onlyGroups') && (
                    <Grid item container spacing={1} xs={12} style={{ margin: '10px 0px' }}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: 'lightgrey',
                          height: 1
                        }}
                      />
                      <Grid
                        data-testid="dsMarketing-distribution-select-groups-to-send-whatsapp"
                        item
                        xs={12}
                        style={{ margin: '10px 0px' }}
                      >
                        <Grid container item alignItems="center" spacing={2}>
                          <Grid item>
                            <Typography>Enviar para os grupos</Typography>
                          </Grid>
                          <Grid item>
                            {isLoadingGroups ? <CircularProgress size={25} /> : null}
                          </Grid>
                        </Grid>

                        {isLoadingGroups ? (
                          <></>
                        ) : (
                          <>
                            <Grid item xs={12} style={{ marginLeft: 20 }}>
                              <Checkbox
                                classNameLabel={classes.checkLabel}
                                checked={isAllGroupsSelected}
                                onChange={() => {
                                  setAllGroupsSelected(!isAllGroupsSelected);
                                }}
                                value={isAllGroupsSelected}
                                label={'SELECIONAR TODOS'}
                                disabled={false}
                              />
                            </Grid>

                            {groups.map((group, groupIndex) => (
                              <Grid
                                key={`grid-group-item-${groupIndex}`}
                                item
                                xs={12}
                                style={{ marginLeft: 20 }}
                              >
                                <Checkbox
                                  key={`checkbox-group-item-${groupIndex}`}
                                  value={isAllGroupsSelected}
                                  name={group.NAME}
                                  disabled={isAllGroupsSelected}
                                  checked={
                                    isAllGroupsSelected
                                      ? isAllGroupsSelected
                                      : groupsCheckedState[groupIndex]
                                  }
                                  classNameLabel={
                                    isAllGroupsSelected ? classes.disabledLabel : classes.checkLabel
                                  }
                                  label={group.NAME}
                                  onChange={() => {
                                    handleGroupsCheckboxOnChange(groupIndex);
                                  }}
                                />

                                <IconButton
                                  edge="start"
                                  disabled={isAllGroupsSelected}
                                  color="primary"
                                  onClick={() => {
                                    navigator.clipboard.writeText(group.INVITE);
                                    alert('Link de convite copiado para área de trabalho');
                                  }}
                                  aria-label="close"
                                >
                                  <GroupAddIcon />
                                </IconButton>
                              </Grid>
                            ))}
                          </>
                        )}
                        {Boolean(formik.errors.groupsToSend) && (
                          <Grid>
                            <Alert severity="error">{formik.errors.groupsToSend as string}</Alert>
                          </Grid>
                        )}
                      </Grid>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: 'lightgrey',
                          height: 1
                        }}
                      />
                    </Grid>
                  )}

                {groups.length <= 0 &&
                  formik.values.selectedShippingType &&
                  formik.values.selectedInstance &&
                  formik.values.selectedShippingType?.value === 'onlyGroups' && (
                    <>
                      <Grid item xs={12}>
                        <Alert severity="warning">
                          Este número de Whatsapp não tem grupos disponíveis. Por favor, selecione
                          outro tipo de disparo.
                        </Alert>
                      </Grid>
                    </>
                  )}

                {!isScheduled && (
                  <Grid item xs={12} className={classes.gridContent}>
                    <Box className={classes.boxLabelWhatsApp}>
                      <Typography className={classes.typographySubtitleClickabled}>Data</Typography>
                    </Box>
                    <DateTimePicker
                      data-testid="dsMarketing-distribution-schedule-date-whatsapp"
                      name="scheduleDate"
                      ampm={false}
                      disablePast
                      fullWidth
                      inputVariant="outlined"
                      format="DD/MM/YYYY HH:mm"
                      value={formik.values.scheduleDate || new Date()}
                      onChange={date => {
                        formik.setFieldValue('scheduleDate', moment(date).toDate());
                      }}
                      className={classes.typographySubtitleClickabled}
                      error={Boolean(formik.errors.scheduleDate)}
                      helperText={formik.errors.scheduleDate}
                      InputProps={{
                        readOnly: true,
                        classes: {
                          notchedOutline: Boolean(formik.errors.scheduleDate)
                            ? classes.inputBorderError
                            : classes.inputNoBorder,
                          root: classes.inputBackgroundColor
                        }
                      }}
                    />
                  </Grid>
                )}

                {!isScheduled && (
                  <Grid item xs={12} className={classes.gridContent}>
                    <Box className={classes.boxLabelWhatsApp}>
                      <Typography className={classes.typographySubtitleClickabled}>
                        Qual texto da mensagem
                      </Typography>
                      <Button
                        onClick={() => {
                          setOnClickAutoMessage(true);
                          props.findAutoMessage({
                            background_id: backgroundId,
                            channel: 'whatsapp'
                          });
                        }}
                        disabled={props?.loading_auto_message}
                        color="primary"
                        variant="text"
                        size="small"
                        startIcon={<AutoFixHighOutlined />}
                      >
                        {props?.loading_auto_message
                          ? 'Digitando ...'
                          : 'Outra sugestão de mensagem'}
                      </Button>
                    </Box>
                    <Popover
                      disableAutoFocus
                      disableEnforceFocus
                      disableRestoreFocus
                      anchorEl={anchorEmoji}
                      open={Boolean(anchorEmoji)}
                      onClose={() => setAnchorEmoji(null)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                    >
                      <PickerEmoji
                        onEmojiClick={(_, { emoji }) => onEmojiText(emoji)}
                        disableAutoFocus
                        disableSearchBar
                        disableSkinTonePicker
                        groupNames={{
                          smileys_people: 'Smileys e pessoas',
                          animals_nature: 'Animais e natureza',
                          food_drink: 'Comidas e bebidas',
                          travel_places: 'Viagens e lugares',
                          activities: 'Atividades',
                          objects: 'Objetos',
                          symbols: 'Símbolos',
                          flags: 'Bandeiras',
                          recently_used: 'Recentes'
                        }}
                        pickerStyle={{ boxShadow: '' }}
                      />
                    </Popover>

                    <TextField
                      data-testid="dsMarketing-distribution-post-text-whatsapp"
                      fullWidth
                      name="post"
                      variant="outlined"
                      multiline
                      placeholder="Digite o texto..."
                      minRows={12}
                      disabled={props?.loading_auto_message}
                      value={postText}
                      onChange={element => {
                        setPostText(element.target.value);
                      }}
                      onSelect={updateSelectionText}
                      inputRef={inputRef}
                      error={Boolean(formik.errors.post)}
                      helperText={Boolean(formik.errors.post) ? formik.errors.post : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              size="small"
                              onClick={({ currentTarget }) => setAnchorEmoji(currentTarget)}
                            >
                              <SentimentSatisfiedRounded />
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          notchedOutline: Boolean(formik.errors.post)
                            ? classes.inputBorderError
                            : classes.inputNoBorder,
                          root: classes.inputBackgroundColor
                        }
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} className={classes.gridContent} style={{ marginBottom: 0 }}>
                  <Checkbox
                    styleControl={{
                      ...(formik.errors.agreeWhatsappTerms
                        ? { color: 'red', fontWeight: 'bold' }
                        : {})
                    }}
                    label={'Concordo com os termos do Whatsapp'}
                    checked={formik.values.agreeWhatsappTerms}
                    onChange={(event: any) => {
                      formik.setFieldValue('agreeWhatsappTerms', event.target.checked);

                      localStorage.setItem(
                        'agreeWhatsappTerms',
                        JSON.stringify(event.target.checked)
                      );
                    }}
                    value={formik.values.agreeWhatsappTerms}
                  />
                </Grid>

                <Grid item xs={12} className={classes.gridContent}>
                  <Typography className={classes.typographyWarning}>
                    Ao concordar com esta opção, você está de acordo com a{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.whatsapp.com/legal/privacy-policy/?locale=pt_BR"
                    >
                      política de privacidade do Whatsapp.
                    </a>{' '}
                    A Datasales não se resposabiliza por quaisquer danos ocorridos por disparos que
                    violam a política de privacidade do Whatsapp.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {Boolean(Object.keys(formik.errors).length) && (
              <Grid
                style={{
                  marginBottom: '24px'
                }}
              >
                <Alert className={classes.disclaimerError} severity="error">
                  Preencha todos os campos obrigatórios
                </Alert>
              </Grid>
            )}
          </div>
        </Container>

        <FormObserver channel="whatsapp" values={formik.values} />
      </form>

      {isInstanceDialogOpen && (
        <ModalNewWhatsappInstance
          isInstanceDialogOpen={isInstanceDialogOpen}
          setInstanceDialogOpen={setInstanceDialogOpen!}
          selectedStore={selectedStore!}
          setSelectedStore={setSelectedStore!}
          stores={lojas}
        />
      )}
      {isQRCodeDialogOpen && (
        <QRCodeDialog
          open={isQRCodeDialogOpen}
          instancia={selectedInstance}
          onClose={() => {
            setQRCodeDialogOpen(false);
            formik.setFieldValue('selectedInstance', null);
          }}
          reloadQrCode={props.reloadQrCode}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ ChatbotReducer, TabloideDigitalReducer }: any) => {
  return { ...ChatbotReducer, ...TabloideDigitalReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot, ...actionsAdsDistribution })(WhatsApp);

