import moment from 'moment';
import 'moment/locale/pt-br';

//import { capitalize } from '../utils/helpers/Format';

export default function HooksDate() {
  function getMoment(format = 'DD/MM/YYYY', date) {
    if (Boolean(date)) {
      return moment(date, format);
    }
    return moment();
  }

  function getDate(format, date) {
    let tempDate = date;
    if (typeof date === 'string') {
      tempDate = date && date.includes('Z') ? date.replace('Z', '') : date;
    }
    const newDate = moment(tempDate).format(format);
    if (newDate === 'Data inválida') {
      return null;
    }
    return newDate;
  }

  function getMonth(month, format) {
    return moment().month(month).format(format);
  }

  function arrayMonth() {
    return moment.monthsShort();
  }

  function arrayDays(date) {
    const daysInMonth = moment(date).daysInMonth(),
      daysMonth = [];
    for (let index = 1; index <= daysInMonth; index++) {
      daysMonth.push(index);
    }
    return daysMonth;
  }

  function addDate(amount, unit, date = moment()) {
    return moment(date).add(unit, amount);
  }

  function subtractDate(amount, unit) {
    const date = getMoment();
    return moment(date).subtract(unit, amount);
  }

  function duration(current = 0) {
    const ms = current * 1000;
    let seconds = moment.duration(ms).seconds();
    seconds = String(seconds);
    seconds = seconds.length === 1 ? `0${seconds}` : seconds;
    let minutes = moment.duration(ms).minutes();
    minutes = String(minutes);
    minutes = minutes.length === 1 ? `0${minutes}` : minutes;
    let hours = moment.duration(ms).hours();
    hours = String(hours);
    hours = hours.length === 1 ? `0${hours}` : hours;
    const durations = { seconds, minutes, hours };
    return durations;
  }

  function differenceFormated(date = moment(), format = '') {
    const now = moment(),
      createdDate = moment(date).format(format),
      createdDateFront = moment(date);
    let diff = now.diff(createdDate, 'minutes');

    if (diff === 0) {
      return 'Agora';
    } else if (diff > 0 && diff < 60) {
      return `${diff} min`;
    } else {
      diff = now.diff(createdDate, 'hours');
      if (diff < 24) {
        return `${diff} h`;
      } else {
        diff = now.diff(createdDate, 'days');
        if (diff < 7) {
          return `${diff} d`;
        } else {
          return `${createdDateFront.format('D [de] MMMM [de] YYYY [às] HH:mm')}`;
        }
      }
    }
  }

  function difference(date = moment(), format = '') {
    const now = moment();
    const createdDate = moment(date);
    let diff = now.diff(date, 'minutes');
    if (diff === 0) {
      return 'Agora';
    } else if (diff > 0 && diff < 60) {
      return `${diff} min`;
    } else {
      diff = now.diff(createdDate, 'hours');
      if (diff < 24) {
        return `${diff} h`;
      } else {
        diff = now.diff(createdDate, 'days');
        if (diff < 7) {
          return `${diff} d`;
        } else {
          return createdDate.format('D [de] MMMM[,] yyyy');
        }
      }
    }
  }

  function differenceNumber(date = moment(), format = '', unitOfTime = 'days') {
    const now = moment(),
      createdDate = moment(date, format).format('YYYY-MM-DD');
    return now.diff(createdDate, unitOfTime);
  }

  return {
    getMoment,
    getDate,
    getMonth,
    arrayMonth,
    arrayDays,
    addDate,
    subtractDate,
    duration,
    difference,
    differenceFormated,
    differenceNumber
  };
}

