import React from "react";
import { Avatar } from "@material-ui/core";
import { Panel, MyButton } from "../../../utils/components";

export default ({ classes, data, onUpdate }) =>
  data &&
  data.length > 0 &&
  data.map((automacao) => (
    <Panel
      avatar={
        <Avatar className={classes.avatar}>{automacao.tipo_automacao}</Avatar>
      }
      title={`${`#${automacao.id_automacao_mensagem} - `}${
        automacao.nome_automacao
      }`}
      subheader={automacao.mensagem}
      style={{ marginBottom: 20 }}
      action={
        <MyButton
          size="large"
          className={classes.button}
          variant="text"
          color="primary"
          onClick={() => onUpdate(automacao)}
        >
          Editar
        </MyButton>
      }
    />
  ));
