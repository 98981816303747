import React, { Fragment, Component } from 'react';
import FileBase64 from 'react-file-base64';
import { Typography } from '@material-ui/core';
import './file.css';

export default class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayFiles: [],
    };
  }

  // componentWillUnmount() {
  //   this.setState({ arrayFiles: [] });
  // }

  removeFile = file => {
    const { arrayFiles } = this.state;
    const index = arrayFiles.indexOf(file);
    arrayFiles.splice(index, 1);
    this.setState({ arrayFiles });
    this.props.files(arrayFiles);
  };

  listFiles = file => (
    <Typography
      key={file.name}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      bsstyle="primary">
      <strong>{file.name}</strong>
    </Typography>
  );

  getFiles = files => {
    const { arrayFiles } = this.state;
    files.map(function (file, i) {
      switch (file.name.split('.')[1].toLowerCase()) {
        case 'jpg':
          return arrayFiles.push({
            file: file.base64,
            base64: file.base64.split(',')[1],
            size: file.size,
            type: file.type,
            name: file.name,
          });

        case 'png':
          return arrayFiles.push({
            file: file.base64,
            base64: file.base64.split(',')[1],
            size: file.size,
            type: file.type,
            name: file.name,
          });
        case 'gif':
        case 'bmp':
        case 'tiff':
        case 'pdf':
        case 'docx':
        case 'txt':
        case 'xlsx':
        case 'csv':
        case 'xlt':
        case 'jpeg':
          return arrayFiles.push({
            file: file.base64,
            base64: file.base64.split(',')[1],
            size: file.size,
            type: file.type,
            name: file.name,
          });

        default:
          return [];
      }
    });
    this.setState({ arrayFiles });
    this.props.files(arrayFiles);
  };
  render() {
    const { arrayFiles } = this.state;
    const { listFiles, filesFormat, hiddenFiles } = this.props;

    return (
      <Fragment>
        <div className="btn btn-default btn-file">
          <i className="fa fa-paperclip" />
          &nbsp;
          <span className="nameFile">
            {arrayFiles[0] && hiddenFiles ? arrayFiles[0].name : 'Importar arquivos'}
          </span>
          <FileBase64 disabled multiple onDone={files => this.getFiles(files)} />
        </div>
        <section className="listFilesImages">
          {filesFormat &&
            !hiddenFiles &&
            arrayFiles.map(file => (
              <section className="imgSection">
                <img className="img" src={file.file} alt={file.file} />
                <section
                  style={{
                    display: 'grid',
                    gridTemplateRows: '49% 49%',
                    rowGap: '2%',
                  }}>
                  <strong style={{ marginLeft: '7px', fontSize: 18 }}>{file.name}</strong>
                  <text
                    style={{
                      marginLeft: '7px',
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: '#616161',
                    }}>
                    {file.size}
                  </text>
                </section>
              </section>
            ))}
        </section>
        {listFiles && !hiddenFiles && (
          <section className="listFiles">{arrayFiles.map(file => this.listFiles(file))}</section>
        )}
      </Fragment>
    );
  }
}
