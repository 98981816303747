const INITIAL_STATE = {
  blacklist: {
    data: [],
    familias: [],
    produtos: []
  },
  familias: [],
  produtos: [],
  load_blacklist: false,
  load_familias: false,
  load_produtos: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_BLACKLIST":
      return {
        ...state,
        blacklist: action.payload,
        load_blacklist: false
      };
    case "POST_BLACKLIST":
      return {
        ...state,
        blacklist: action.payload,
        load_blacklist: false
      };
    case "DELETE_BLACKLIST":
      return {
        ...state,
        load_blacklist: false
      };
    case "GET_FAMILIAS":
      return {
        ...state,
        familias: action.payload,
        load_familias: false
      };
    case "GET_PRODUTOS":
      return {
        ...state,
        produtos: action.payload,
        load_produtos: false
      };
    case "LOAD_BLACKLIST":
      return { ...state, load_blacklist: true };
    case "LOAD_FAMILIAS":
      return { ...state, load_familias: true };
    case "LOAD_PRODUTOS":
      return { ...state, load_produtos: true };
    default:
      return state;
  }
};
