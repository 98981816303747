const STATIC_FILTER = {
    listaSelecionada: [],
    selectedEstados: [],
    selectedMunicipios: [],
    selectedBairros: [],
    selectedDDD: [],
    selectedDocumento: [],
    selectedTagOrigem: [],
    nomeConsumidor: null,
    selectedMesNascimento: [],
    selectedProfissao: [],
    listCategoria1: [],
    listCategoria2: [],
    listCategoria3: [],
    listCategoria4: [],
    listCategoria5: [],
    listCategoria6: [],
    listCategoria7: [],
    listCategoria8: [],
    listCategoria9: [],
    listCategoria10: [],
    listCategoria11: [],
    listCategoria12: [],
    faixaEtaria: [],
    percentualDesconto: [],
    qtdeComprasInicio: null,
    qtdeComprasFim: null,
    valorComprasInicio: null,
    valorComprasFim: null,
    ticketMedioInicio: null,
    ticketMedioFim: null,
    diasCadastroInicio: '',
    diasCadastroFim: '',
    diasCompraInicio: '',
    diasCompraFim: '',
    cashbackInicio: null,
    cashbackFim: null,
    dataInicioVencimentoCashback: null,
    dataFimVencimentoCashback: null,
    diasVencimentoCashback: [],
    selectedStores: [],
    dataCadastroInicio: null,
    dataCadastroFim: null,
    dataCompraInicio: null,
    dataCompraFim: null,
    telefone: null,
    email: null,
    endereco: null,
    aniversarioHoje: 0,
    aniversarioMes: 0,
    canalPreferecia: null,
    celular: null,
    genre: null,
    status: null,
    eCliente: null,
    termometro: null,
    potenciaisConsumidores_mesAtual: 1,
    potenciaisConsumidores_mesAnterior: 1,
    tipoCampanha: null,
    sensibilidade_sms: null,
    sensibilidade_email: null,
    sensibilidade_push: null,
    sensibilidade_whatsapp: null,
  },
  INITIAL_STATE = {
    showFiltro: false,
    filtroSelecionado: [],
    reprocessoAudiencia: null,
    reprocessoAudienciaSaida: [],
    reprocessoAudienciaEntrada: [],
    clustersAudiencia: [],
    overviewDDD: [],
    overviewGenre: [],
    overviewAge: [],
    overviewAgeBars: [],
    overviewRegisterDay: [],
    overviewRegisterMonth: [],
    perfilCliente: {
      totalClientes: [],
      sexo: [],
      status: null,
      stats: {},
      termometro: [],
      idade: [],
      mapa: [],
      totalizadores: [
        {
          celular: 0,
          email: 0,
          endereco: 0,
          ativos: 0,
        },
      ],
      valores: {
        celular: 0,
        email: 0,
      },
      clientes: { clientes: [], n_compraram: {}, total: 0 },
      profissoes: [],
      pessoa: '',
      show: false,
    },
    filtroSegmentacao: STATIC_FILTER,
    novaLista: {
      selectedEstados: [],
      selectedMunicipios: [],
      selectedBairros: [],
    },
    filtroConsulta: {},
    performanceListaId: [],
    performanceLista: {},
    listaFiltro: [],
    listLojas: [],
    listProfissao: [],
    listTodasProfissao: [],
    listProdutos: [],
    listAllCategoria1: [],
    listCategoria1: [],
    listCategoria2: [],
    listCategoria3: [],
    listCategoria4: [],
    listCategoria5: [],
    listCategoria6: [],
    listCategoria7: [],
    listCategoria8: [],
    listCategoria9: [],
    listCategoria10: [],
    listCategoria11: [],
    listCategoria12: [],
    listTamanho: [],
    listTagOrigem: [],
    listDocumentos: [],
    listHeaders: [],
    listDefaultHeaders: [],
    listTempCustomHeaders: [],
    existDocumento: false,
    attrFile: {
      link: '',
      file_name: '',
      type_import: '',
      file_length: 0,
    },
    pessoaModal: {
      visible: false,
      pessoa: {},
    },
    contador_campanha: 0,
    openModalPessoa: false,
    isEnablePerformance: false,
    isLoadingCountPhone: false,
    isLoadingCountEmail: false,
    isLoadingInsights: false,
    loadingClienteTotal: false,
    loadingClienteSexo: false,
    loadingClienteStatus: false,
    loadingClienteTermometro: false,
    loadingClienteMapa: false,
    loadingClienteIdade: false,
    loadingClienteTotalizadores: false,
    loadingClienteProfissao: false,
    isLoadingCampaignCSV: false,
    loadingClienteSensibilidade: false,
    isLoadingOverviewGenre: false,
    isLoadingOverviewDDD: false,
    isLoadingOverviewAge: false,
    isLoadingOverviewRegisterDay: false,
    isLoadingOverviewRegisterMonth: false,
  };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'NOVA_LISTA':
      return { ...state, novaLista: action.payload };
    case 'showFiltro':
      return { ...state, showFiltro: !state.showFiltro };
    case 'CLEAR_FILTRO':
      return INITIAL_STATE;
    case 'OPEN_MODAL_EMAIL':
      return { ...state, modalEmail: action.payload };
    case 'setFiltro': {
      return {
        ...state,
        filtroSelecionado: action.payload,
      };
    }
    case 'setEstado':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          selectedEstados: action.payload,
        },
      };

    case 'setNome':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          nome: action.payload,
        },
      };
    case 'setCpf':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          documento: action.payload,
        },
      };
    case 'SET_FAIXA_ETARIA':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          faixaEtaria: action.payload,
        },
      };
    case 'SET_QTDE_COMPRAS':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          qtdeCompras: action.payload,
        },
      };
    case 'SET_VALOR_GASTO':
      return {
        ...state,
        filtroSegmentacao: {
          ...state.filtroSegmentacao,
          valorGasto: action.payload,
        },
      };

    case 'RESULTADO_FILTRO':
      return {
        ...state,
        ...action.payload,
        isLoadingInsights: false,
      };
    case 'CLEAR_FILTER':
      return {
        ...state,
        filtroSelecionado: [],
        filtroSegmentacao: STATIC_FILTER,
      };
    case 'GET_CLIENTE_TOTAL':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          totalClientes: action.payload.total,
          clientes: action.payload.response,
        },
        loadingClienteTotal: false,
      };
    case 'GET_CLIENTE_SEXO':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          sexo: action.payload,
        },
        loadingClienteSexo: false,
      };
    case 'GET_CLIENTE_STATUS':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          status: action.payload,
          stats: action.payload,
        },
        loadingClienteStatus: false,
      };
    case 'GET_CLIENTE_TERMOMETRO':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          termometro: action.payload,
        },
        loadingClienteTermometro: false,
      };

    case 'GET_LISTA_CATEGORIA1':
      return {
        ...state,
        listAllCategoria1: action.payload,
        listCategoria1: action.payload,
      };
    case 'GET_LISTA_CATEGORIA2':
      return {
        ...state,
        listCategoria2: action.payload,
      };
    case 'GET_LISTA_CATEGORIA3':
      return {
        ...state,
        listCategoria3: action.payload,
      };
    case 'GET_LISTA_CATEGORIA4':
      return {
        ...state,
        listCategoria4: action.payload,
      };
    case 'GET_LISTA_CATEGORIA5':
      return {
        ...state,
        listCategoria5: action.payload,
      };
    case 'GET_LISTA_CATEGORIA6':
      return {
        ...state,
        listCategoria6: action.payload,
      };
    case 'GET_LISTA_CATEGORIA7':
      return {
        ...state,
        listCategoria7: action.payload,
      };
    case 'GET_LISTA_CATEGORIA8':
      return {
        ...state,
        listCategoria8: action.payload,
      };
    case 'GET_LISTA_CATEGORIA9':
      return {
        ...state,
        listCategoria9: action.payload,
      };
    case 'GET_LISTA_CATEGORIA10':
      return {
        ...state,
        listCategoria10: action.payload,
      };

    case 'GET_LISTA_CATEGORIA11':
      return {
        ...state,
        listCategoria11: action.payload,
      };

    case 'GET_LISTA_CATEGORIA12':
      return {
        ...state,
        listCategoria12: action.payload,
      };

    case 'GET_CLIENTE_IDADE':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          idade: action.payload,
        },
        loadingClienteIdade: false,
      };
    case 'GET_CLIENTE_MAPA':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          mapa: action.payload,
        },
        loadingClienteMapa: false,
      };
    case 'GET_CLIENTE_TOTALIZADORES':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          totalizadores: action.payload,
        },
        loadingClienteTotalizadores: false,
      };
    case 'GET_CLIENTE_TOTALIZADORES_CELULAR_VALOR':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          valores: {
            ...state.perfilCliente.valores,
            celular: action.payload,
          },
        },
      };
    case 'GET_CLIENTE_TOTALIZADORES_EMAIL_VALOR':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          valores: {
            ...state.perfilCliente.valores,
            email: action.payload,
          },
        },
      };
    case 'GET_CLIENTE_PROFISSAO':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          profissoes: action.payload,
        },
        loadingClienteProfissao: false,
      };

    case 'LIST_PROSPECTEI_PF':
      return {
        ...state,
        listProspectei: action.payload.data,
        lengthData: action.payload.length,
        isLoadingInsights: false,
      };
    case 'LISTA_FILTRO':
      return {
        ...state,
        listaFiltro: action.payload,
        isLoadingInsights: false,
      };
    case 'LIST_PROMOCOES':
      return { ...state, listPromocoes: action.payload };
    case 'LISTA_PERFORMANCE':
      return {
        ...state,
        performanceListaId: action.payload,
        isLoadingInsights: false,
      };
    case 'LISTA_LOJAS':
      const options = action.payload.map(opt => ({
        value: opt.id_loja,
        label: `${opt.nome_loja} ${opt.cidade} - ${opt.uf}`,
      }));
      return { ...state, listLojas: options };
    case 'LISTA_PROFISSAO':
      return {
        ...state,
        listProfissao: action.payload,
      };

    case 'LISTA_TODAS_PROFISSAO':
      return {
        ...state,
        listTodasProfissao: action.payload,
      };

    case 'LISTA_PRODUTOS':
      return {
        ...state,
        listProdutos: action.payload,
      };

    case 'LISTA_TAMANHO':
      return {
        ...state,
        listTamanho: action.payload,
      };

    case 'FILTRO_CONSULTA':
      return {
        ...state,
        filtroConsulta: { ...action.payload },
      };

    case 'REMOVE_NUMERO_CAMPANHA':
      const remove_contador_celular =
        state.contador_campanha[action.payload] - 1;
      const remove_payload = {
        ...state.contador_campanha,
        [action.payload]: remove_contador_celular,
        valor_total:
          parseFloat(state.contador_campanha.valor_unitario) *
          remove_contador_celular,
      };

      return {
        ...state,
        contador_campanha: remove_payload,
      };
    case 'ADD_NUMERO_CAMPANHA':
      const { valor_unitario } = state.contador_campanha;
      const new_contador_celular = state.contador_campanha[action.payload] + 1;
      const new_payload = {
        ...state.contador_campanha,
        [action.payload]: new_contador_celular,
        valor_total: parseFloat(valor_unitario) * new_contador_celular,
      };

      return {
        ...state,
        contador_campanha: new_payload,
      };
    case 'CONTADOR_PUSH':
      return {
        ...state,
        contador_campanha: action.payload,
        isLoadingCountPhone: false,
      };
    case 'CONTADOR_WHATSAPP':
      return {
        ...state,
        contador_campanha: action.payload,
        isLoadingCountPhone: false,
      };
    case 'CONTADOR_CELULAR':
      return {
        ...state,
        contador_campanha: action.payload,
        isLoadingCountPhone: false,
      };
    case 'CONTADOR_EMAIL':
      return {
        ...state,
        contador_campanha: action.payload,
        isLoadingCountEmail: false,
      };

    case 'CHANGE_FILTRO':
      const { key, value } = action.payload;
      return {
        ...state,
        filtroSegmentacao: { ...state.filtroSegmentacao, [key]: value },
      };

    case 'CHANGE_FILTRO_SPREAD':
      return {
        ...state,
        newList: action.payload,
        filtroSegmentacao: action.payload
          ? { ...action.payload }
          : state.filtroSegmentacao,
      };
    case 'CHANGE_PERFORMANCE':
      return {
        ...state,
        isEnablePerformance: action.payload.visible,
        performanceLista: action.payload.lista,
      };

    case 'LIST_TAG_ORIGEM':
      return { ...state, listTagOrigem: action.payload };

    case 'LIST_DOCUMENTOS':
      return { ...state, listDocumentos: action.payload };

    case 'REPROCESSO_AUDIENCIA':
      return { ...state, reprocessoAudiencia: action.payload };

    case 'REPROCESSO_AUDIENCIA_SAIDA':
      return { ...state, reprocessoAudienciaSaida: action.payload };

    case 'REPROCESSO_AUDIENCIA_ENTRADA':
      return { ...state, reprocessoAudienciaEntrada: action.payload };
    case 'CLUSTER_AUDIENCIA':
      return { ...state, clustersAudiencia: action.payload };

    case 'HEADER_FILE_INSIGHTS':
      const tempCustomList = action.payload.cols
          .filter(list => Boolean(list.checked))
          .map(list => ({
            label: list.label,
            column: list.column,
          })),
        haveDocument = action.payload.fileCols.some(
          header =>
            String(header)
              .toUpperCase()
              .includes('DOC') ||
            String(header)
              .toUpperCase()
              .includes('CPF') ||
            String(header)
              .toUpperCase()
              .includes('CNPJ')
        );
      return {
        ...state,
        attrFile: action.payload.attrFile,
        listHeaders: action.payload.cols,
        listDefaultHeaders: action.payload.fileCols,
        existDocumento: Boolean(haveDocument),
        listTempCustomHeaders: tempCustomList,
      };

    case 'MODAL_PESSOA':
      return {
        ...state,
        pessoaModal: {
          ...state.pessoaModal,
          pessoa: action.payload.pessoa,
          visible: action.payload.visible,
        },
      };
    case 'OVERVIEW_DDD':
      return {
        ...state,
        overviewDDD: action.payload,
        isLoadingOverviewDDD: false,
      };
    case 'OVERVIEW_GENRE':
      return {
        ...state,
        overviewGenre: action.payload,
        isLoadingOverviewGenre: false,
      };
    case 'OVERVIEW_AGE':
      return {
        ...state,
        overviewAge: action.payload.data,
        overviewAgeBars: action.payload.bars,
        isLoadingOverviewAge: false,
      };
    case 'OVERVIEW_REGISTER_DAY':
      return {
        ...state,
        overviewRegisterDay: action.payload,
        isLoadingOverviewRegisterDay: false,
      };
    case 'OVERVIEW_REGISTER_MONTH':
      return {
        ...state,
        overviewRegisterMonth: action.payload,
        isLoadingOverviewRegisterMonth: false,
      };
    case 'GET_TOTAL_CADASTROS':
      return {
        ...state,
        perfilCliente: {
          ...state.perfilCliente,
          clientes: {
            ...state.perfilCliente.clientes,
            total: action.payload,
          },
        },
        loadingClienteTotal: false,
      };

    case 'LOADER_OVERVIEW_GENRE':
      return { ...state, isLoadingOverviewGenre: true };
    case 'LOADER_OVERVIEW_DDD':
      return { ...state, isLoadingOverviewDDD: true };
    case 'LOADER_OVERVIEW_AGE':
      return { ...state, isLoadingOverviewAge: true };
    case 'LOADER_OVERVIEW_REGISTER_DAY':
      return { ...state, isLoadingOverviewRegisterDay: true };
    case 'LOADER_OVERVIEW_REGISTER_MONTH':
      return { ...state, isLoadingOverviewRegisterMonth: true };
    case 'LOADING_COUNT_PHONE':
      return { ...state, isLoadingCountPhone: true };
    case 'LOADING_COUNT_EMAIL':
      return { ...state, isLoadingCountEmail: true };
    case 'LOADING_INSIGHTS':
      return { ...state, isLoadingInsights: true };
    case 'LOADER_CLIENTE_TOTAL':
      return { ...state, loadingClienteTotal: true };
    case 'LOADER_CLIENTE_SEXO':
      return { ...state, loadingClienteSexo: true };
    case 'LOADER_CLIENTE_STATUS':
      return { ...state, loadingClienteStatus: true };
    case 'LOADER_CLIENTE_TERMOMETRO':
      return { ...state, loadingClienteTermometro: true };
    case 'LOADER_CLIENTE_MAPA':
      return { ...state, loadingClienteMapa: true };
    case 'LOADER_CLIENTE_IDADE':
      return { ...state, loadingClienteIdade: true };
    case 'LOADER_CLIENTE_TOTALIZADORES':
      return { ...state, loadingClienteTotalizadores: true };
    case 'LOADER_CLIENTE_PROFISSAO':
      return { ...state, loadingClienteProfissao: true };
    case 'LOADING_CAMPANHA_CSV':
      return { ...state, isLoadingCampaignCSV: action.payload };
    case 'openModal':
      return {
        ...state,
        show: action.payload.show,
        pessoa: action.payload.pessoa,
      };

    default:
      return state;
  }
};
