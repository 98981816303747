import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Avatar, Box, Grow, Paper } from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import Phone from './Phone';
import useStyles from '../styles';

// important Swiper required modules
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

import '../styles.css';

export default function Preview({ campaign, type }) {
  const classes = useStyles(),
    [email, setEmail] = useState('');
  useEffect(() => {
    if (type === 'EMAIL') {
      (async () => {
        const { data } = await axios.get(campaign.mensagem);
        setEmail(data);
      })();
    }
  }, [type]);

  if (type && type.includes('FACEBOOK')) {
    return (
      <Grow in>
        <Paper variant="outlined">
          <Box px="1vw" py="2vh" display="flex">
            <Avatar className={classes.avatar}>
              <Facebook />
            </Avatar>
            <b style={{ marginLeft: '1vw', fontSize: '12pt' }}>Facebook</b>
          </Box>
          <Box px="1vw" pb="2vh">
            <span>{campaign.mensagem}</span>
          </Box>

          <Box>
            {Array.isArray(campaign.whatsapp_url) ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="dsCampaignPreviewCarrossel"
              >
                {campaign.whatsapp_url.map((media, index) => (
                  <SwiperSlide key={index.toString()}>
                    {media.url.endsWith('mp4') ? (
                      <video className={classes.image} controls autoPlay>
                        <source src={media.url} type="video/mp4" />
                      </video>
                    ) : (
                      <img className={classes.image} src={media.url} />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : campaign.whatsapp_url.endsWith('mp4') ? (
              <video className={classes.image} controls autoPlay>
                <source src={campaign.whatsapp_url} type="video/mp4" />
              </video>
            ) : (
              <img className={classes.image} src={campaign.whatsapp_url} />
            )}
          </Box>
        </Paper>
      </Grow>
    );
  } else if (type && type.includes('INSTAGRAM')) {
    return (
      <Grow in>
        <Paper variant="outlined">
          <Box px="1vw" py="2vh" display="flex" alignItems="center">
            <Avatar className={classes.avatar}>
              <Instagram />
            </Avatar>
            <b style={{ marginLeft: '1vw', fontSize: '12pt' }}>Instagram</b>
          </Box>

          <Box>
            {Array.isArray(campaign.whatsapp_url) ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="dsCampaignPreviewCarrossel"
              >
                {campaign.whatsapp_url.map((media, index) => (
                  <SwiperSlide key={index.toString()}>
                    {media.url.endsWith('mp4') ? (
                      <video className={classes.image} controls autoPlay>
                        <source src={media.url} type="video/mp4" />
                      </video>
                    ) : (
                      <img className={classes.image} src={media.url} />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : campaign.whatsapp_url &&
              campaign.whatsapp_url.endsWith('mp4') ? (
              <video className={classes.image} controls autoPlay>
                <source src={campaign.whatsapp_url} type="video/mp4" />
              </video>
            ) : (
              <img className={classes.image} src={campaign.whatsapp_url} />
            )}
          </Box>

          <Box px="1vw" py="1vh">
            <b>Instagram</b>&nbsp;
            <span style={{ marginLeft: '.5vw', fontSize: '11pt' }}>
              {campaign.mensagem}
            </span>
          </Box>
        </Paper>
      </Grow>
    );
  } else if (type === 'WHATSAPP') {
    return <Phone isWhatsapp data={campaign} />;
  } else if (type === 'WHATSAPP_STATUS') {
    return <Phone isStatusWhatsapp data={campaign} />;
  } else if (type === 'SMS') {
    return <Phone data={campaign} />;
  } else if (type === 'EMAIL') {
    return (
      <Grow in>
        <div
          style={{ width: '100%', overflow: 'auto', height: '95vh' }}
          dangerouslySetInnerHTML={{
            __html: email,
          }}
        />
      </Grow>
    );
  }
  return <div id="empty" />;
}
