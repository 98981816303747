import React from 'react';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

export default props => (
  <HeatmapLayer
    data={props.data.map(
      data => new window.google.maps.LatLng(data.lat, data.lng),
    )}
  />
);
