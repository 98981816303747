/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  Modal,
  TextField,
} from '../../../../utils/components';
import '@webscopeio/react-textarea-autocomplete/style.css';
import { 
  getTabloidsTemplatesFisico,
  postTabloidsTemplate
} from '../../action/index';
import {
  COLOR_PRIMARY,
} from '../../../../utils/helpers/Constants';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";

class EditJson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      templateIndex: null,
      json_parse: {},
      json: null,
      template_nome: null
    };
  }

  componentDidMount() {
    this.props.getTabloidsTemplatesFisico({
      tipoPixelixe: this.props.tipoPixelixe,
      order: true
    });
  }

  header(props, state) {
    return (
      <Fragment>
        Edição de Json - {this.state.template_nome}
      </Fragment>
    );
  }

  footer(props, state) {
    const {
      activeStep
    } = this.state

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        <div></div>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section
        style={{
          height: '100%',
        }}
      >        
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 800,
              justifyContent: 'center',
              margin: 'auto',
              flexWrap: 'wrap',
            }}
          >

            {
              this.props.loadind_tabloides_template_fisico && 
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 30
                  }}
                >
                  Carregando...
                </div>                  
            }

            { 
              (this.state.templateIndex === null) && 
              (!this.props.loadind_tabloides_template_fisico) &&
              this.props.tabloids_templates_fisico.map((value, i) => 
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY, margin: 10 }}
                  onClick={() => this.setState({ 
                    templateIndex: value.id,
                    template_nome: value.nome,
                    json_parse: JSON.parse(value.json)
                  })}
                >
                  {value.template} / {value.nome}
                </Button>
            )}

            {(this.state.templateIndex !== null) &&
              <React.Fragment>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY, margin: 10 }}
                  onClick={() => {
                    this.setState({ 
                      templateIndex: null,
                      json_parse: {},
                      json: null,
                      template_nome: null
                    });
                    this.props.getTabloidsTemplatesFisico({
                      tipoPixelixe: this.props.tipoPixelixe,
                      order: true
                    });
                  }}
                >
                  Voltar
                </Button>
                <JSONInput
                  placeholder={ this.state.json_parse }
                  locale={locale}
                  height="550px"
                  width="700px"
                  onChange={(e) => this.setState({ json: e.json })}
                />
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY, margin: 10 }}
                  onClick={() => this.props.postTabloidsTemplate({
                    id_template: this.state.templateIndex,
                    json: this.state.json
                  })}
                >
                  Salvar
                </Button>
              </React.Fragment>
            }
          </div>
        </div>        
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const {
      activeStep
    } = this.state;    

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10,
  },
});

const mapStateToProps = ({
  TabloideDigitalReducer
}) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  getTabloidsTemplatesFisico,
  postTabloidsTemplate
};

const EditJsonConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditJson);

export default withStyles(styles)(EditJsonConnect);
