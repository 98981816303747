import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  useTheme,
} from '@material-ui/core';

import axios from 'axios';
import Card from '../../components/Card';
import TabPanel from '../../components/TabPanel';

import useStyles from './styles';

import banner1 from '../../../../assets/images/banner01_treinamento.png';
import banner2 from '../../../../assets/images/banner02_youtube.png';

function Home() {
  const theme = useTheme(),
    classes = useStyles(),
    [value, setValue] = useState(0),
    [loadingArticles, setLoadingArticles] = useState(false),
    [articles, setArticles] = useState([]);

  useEffect(() => {
    async function loadBlog() {
      try {
        setLoadingArticles(true);
        const { data } = await axios.get(
          'https://3jyvx7lnsf.execute-api.us-east-1.amazonaws.com/s/listBlogs'
        );
        setArticles(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingArticles(false);
      }
    }

    loadBlog();
  }, []);

  const items = {
    banner01: {
      path: 'https://calendly.com/datasales',
      class: classes.imgCarousel,
      target: '_blank',
      source: banner1,
    },
    banner02: {
      path: 'https://www.youtube.com/channel/UCACeto460xMzcTcRtVd4kCQ/featured',
      class: classes.imgCarousel,
      target: '_blank',
      source: banner2,
    },
  };

  return (
    <Box p={3}>
      <Grid container direction="column">
        <Grid container direction="row" className={classes.containerBanners}>
          <a href={items.banner01.path} target={items.banner01.target}>
            <img
              className={items.banner01.class}
              src={items.banner01.source}
              alt="banner com link que redireciona ao treinamento"
            />
          </a>

          <a href={items.banner02.path} target={items.banner02.target}>
            <img
              className={items.banner02.class}
              src={items.banner02.source}
              alt="banner com link que redireciona ao canal no you tube"
            />
          </a>
        </Grid>
        {/* <Grid item xs={12} className={classes.gridCarousel}>
          <Carousel
            animation="slide"
            autoPlay={true}
            interval={5000}
            timeout={{
              enter: 1500,
            }}
            fullHeightHover={false}
            indicatorIconButtonProps={{
              className: classes.indicatorIconButtonProps
            }}
            activeIndicatorIconButtonProps={{
              className: classes.activeIndicatorIconButtonProps
            }}
            navButtonsProps={{
              className: classes.navButtonsProps,
              style: {
                background: '#73C1C2',
              }
            }}
          >
            {items.map((item, i) =>
              <a href={item.path} target='_blank' key={i}>
                <img class={item.class} src={item.source} />
              </a>
            )
            }

          </Carousel>
        </Grid> */}

        {/*
          Listing Blog's Articles with:

          - Thumb
          - Title
          - and Description
        */}
        <Grid item>
          <Box pt={4} pb={2}>
            <Button
              className={value === 0 ? classes.active : ''}
              color="primary"
              size="large"
              onClick={() => setValue(0)}
            >
              Blog
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              {loadingArticles ? (
                <Grid
                  style={{ display: 'flex' }}
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  justify="center"
                >
                  <CircularProgress size={theme.typography.h3.fontSize} />
                </Grid>
              ) : (
                articles.map(art => (
                  <Grid key={art.id} item xs={12} lg={4} md={6}>
                    <Card
                      title={art.titulo}
                      image={art.link_imagem}
                      description={art.resumo}
                      onClick={() => window.open(art.url, '_BLANK')}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
