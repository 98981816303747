import React, { useEffect } from 'react';
import GraphComponent from '../GraphComponent';
import { Grid } from '@material-ui/core';
import { useHome } from '../../../../hooks/home';

export default function PostGraphInstagram() {
  const {
    graphsInstagramCommentsData,
    loadingGraphsInstagramCommentsData,
    getGraphsInstagramCommentsData,

    graphsInstagramEngajamentData,
    loadingGraphsInstagramEngajamentData,
    getGraphsInstagramEngajamentData,

    graphsInstagramImpressionsData,
    loadingGraphsInstagramImpressionsData,
    getGraphsInstagramImpressionsData,

    graphsInstagramLikesData,
    loadingGraphsInstagramLikesData,
    getGraphsInstagramLikesData,

    graphsInstagramViewsData,
    loadingGraphsInstagramViewsData,
    getGraphsInstagramViewsData,
  } = useHome();

  useEffect(() => {
    getGraphsInstagramCommentsData();
    getGraphsInstagramEngajamentData();
    getGraphsInstagramImpressionsData();
    getGraphsInstagramLikesData();
    getGraphsInstagramViewsData();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}></Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1="Comentários"
          text2="Quantas pessoas seguem sua página"
          array={graphsInstagramCommentsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsInstagramCommentsData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1="Curtidas"
          text2="Quantas curtidas suas publicaçoes tiveram"
          array={graphsInstagramLikesData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsInstagramLikesData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1="Contas alcançadas"
          text2={'Quantas vezes sua publicação apareceu para alguém'}
          array={graphsInstagramImpressionsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsInstagramImpressionsData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Engajamento'}
          text2={'Quantas pessoas clicaram nas suas propagandas impulsionadas'}
          array={graphsInstagramEngajamentData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsInstagramEngajamentData}
        />
      </Grid>

      <Grid item xs={4}>
        <GraphComponent
          text1={'Visualizações'}
          text2={'Quantidade de pessoas que viram sua publicação'}
          array={graphsInstagramViewsData.map(v => ({
            label: v.date,
            value: v.total,
          }))}
          loading={loadingGraphsInstagramViewsData}
        />
      </Grid>
    </Grid>
  );
}
