import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ModalBody from './BodyModal';
import { MyButton } from '../../../../utils/components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

function ModalAddPessoa(props) {
  const requiredPessoa = {
    nome: '',
    documento: '',
    rua: Boolean(props.defaultPessoa) ? props.defaultPessoa.endereco : '',
    ...props.defaultPessoa,
  };
  const [pessoa, setAttrPessoa] = useState(requiredPessoa);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth="xl"
      maxWidth="xl"
    >
      <MuiDialogTitle onClose={props.onClose}>
        {props.editable ? pessoa.nome : 'Adicionar consumidor'}
      </MuiDialogTitle>
      <DialogContent>
        <ModalBody
          attrPessoa={pessoa}
          onChange={(key, value) => {
            setAttrPessoa(Object.assign(pessoa, { [key]: value }));
            if (
              key === 'uf' ||
              key === 'data_nascimento' ||
              key === 'blacklist_email' ||
              key === 'blacklist_sms'
            ) {
              forceUpdate();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <MyButton onClick={props.onClose} color="default">
          Cancelar
        </MyButton>
        <MyButton
          onClick={() => {
            props.onSave(pessoa);
            props.onClose();
          }}
        >
          Salvar
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ PeopleReducer }) => ({ ...PeopleReducer });

export default connect(mapStateToProps, null)(ModalAddPessoa);
