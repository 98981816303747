import React, { useState } from 'react';
import TextField from './TextField';

function TextInput(props) {
  const [modeInput, setModeInput] = useState(false);
  const [valueTemp, setValueTemp] = useState('');

  function SaveChange() {
    setModeInput(false);
    props.onChange &&
      props.onChange({
        edit: !Boolean(valueTemp) ? false : valueTemp !== props.value,
        newValue: valueTemp,
      });
  }

  return (
    <div
      style={{ cursor: props.editable ? 'pointer' : 'cursor' }}
      onClick={() => (props.editable ? setModeInput(true) : null)}
      onBlur={() => (props.editable ? SaveChange() : null)}
    >
      <p style={{ display: 'flex', alignItems: 'center' }}>
        {props.title && <strong style={{ fontSize: 18 }}>{props.title}</strong>}
        &nbsp;
        {!modeInput ? (
          props.loading ? (
            'Carregando...'
          ) : (
            props.value
          )
        ) : props.editable ? (
          <TextField
            autoFocus
            onChange={({ target: { value } }) => {
              setValueTemp(value);
              props.onChangeInput && props.onChangeInput(value);
            }}
            onKeyPress={event => event.key === 'Enter' && SaveChange()}
            size="small"
            className="form-control"
            defaultValue={props.value}
          />
        ) : null}
      </p>
    </div>
  );
}

export default TextInput;
