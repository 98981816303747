import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  gridGlobal: {
    display: 'flex !important',
    gap: '2rem !important',
    padding: '24px !important',
    width: '52.19rem !important',
    borderRadius: '0.5rem !important',
    background: '#F2F3F5'
  },
  gridTypography: {
    display: 'flex !important',
    gap: '0.5rem !important'
  },
  gridTextFields: {
    display: 'flex !important',
    justifyContent: 'space-evenly !important',
    alignItems: 'center !important',
    gap: '1rem !important'
  },
  gridDateTime: {
    display: 'flex !important',
    alignItems: 'center !important',
    gap: '2.5rem !important'
  },
  PickerDateTime: {
    width: '11rem !important',
    display: 'flex',
    alignContent: 'center',
    '& .MuiOutlinedInput-root': {
      background: '#FFF'
    }
  },
  gridScheduleEvery:{
    display: 'flex !important',
    flexDirection: 'column'
  },
  boxScheduleEvery:{
    display: 'flex',
  },
  boxTypeRepeat:{
    display: 'flex',
    gap: '16px',
    flexDirection: 'row'
  },
  gridContent: {
    display: 'flex !important',
    gap: '2rem !important'
  },
  gridRepeatMonth: {
    display: 'flex !important',
    justifyContent: 'start !important',
    gap: '1rem !important',
    alignItems: 'center !important',
    marginLeft: '48px !important'
  },
  title: {
    fontSize: '1rem !important',
    fontWeight: '600 !important',
    lineHeight: '1.218rem !important',
    color: '#1D1D1D !important'
  },
  subTitle: {
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    lineHeight: '1.218rem !important',
    color: '#616161 !important'
  },
  labels: {
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    lineHeight: '1.218rem !important',
    color: '#1E1F21 !important',
  },
  autoCompleteRepeat: {
    width: '10.9375rem !important',
    '& .MuiOutlinedInput-root': {
      background: '#FFF'
    }
  }
}));
