import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  avatar: {
    color: '#2813AD',
    backgroundColor: '#F4F3FB',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '22px',
    width: '77px',
    height: '77px',
  },
  avatarPreview: {
    width: 150,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
  },
  titulo: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px'
  },
  subTitulo: {
    fontSize: '14px',
    color: '#616161',
    lineHeight: '22px',
    fontWeight: '500'
  },
  nome: {
    margin: '0px', 
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '26px'
  },
  email: {
    margin: '0px', 
    fontSize: '14px', 
    color: '#B0B0B0',
    fontWeight: '500',
    lineHeight: '22px'
  },
  textForm:{
    '& .Mui-focused':{
      //border: 'solid 1px green'
    }
  },
  textFormTest:{
    '& label.Mui-focused': {
      color: '#2813AD !important',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2813AD !important',
      },
    },   
  }


}));

export default useStyles;
