import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelectMenu } from 'utils/helpers/Redirect';

import { useDefaultStyles } from '../../style';

type TypePixelixe =
  | 'Post'
  | 'Encarte'
  | 'Story'
  | 'TV - indoor'
  | 'Customizado'
  | 'Cartaz A3 - Retrato'
  | 'Cartaz A3 - Paisagem'
  | 'Cartaz A4 - Retrato'
  | 'Cartaz A4 - Paisagem'
  | 'Etiqueta'
  | 'Splash 22x22'
  | 'Manual'
  | 'Banner - E-commerce';

type Picture = {
  id: number;
  id_tabloide: number;
  link: string;
  miniatura: string;
  tipoPixelixe: TypePixelixe;
};

type Tabloid = {
  artes: Picture[];
  id_tabloide_digital: number;
  id_empresa: number;
  nome_tabloide_digital: string;
  data_inicio: Date;
  data_fim: Date;
  deletado: number;
  uuid: string;
  data_insert: Date;
  descricao: string;
  // logo_url?: any;
  // loja?: any;
  // link_tabloide_digital?: any;
  // link_json?: any;
  // arquivo_tabloide_digital?: any;
  // link_html?: any;
  // data_deletado?: any;
  // googleId: string;
  // facebookId: string;
  // storeId?: any;
  // data_calendario?: any;
  // id_tabloide_digital_pai?: any;
  // uuid_calendario?: any;
  // identificacaoFormulario?: any;
  // identificacaoObrigatoria?: any;
  // identificacaoNome?: any;
  // identificacaoTelefone?: any;
  // identificacaoEmail?: any;
  // ofertaVendasOnline?: any;
};

type Template = {
  id: number;
  companyId: number;
  formatId: number;
  occasionId?: any;
  segmentId?: any;
  name: string;
  imageURL: string;
  designURL: string;
  productQuantity: number;
  tags: string;
  video_template_id?: any;
  video_composition?: any;
  FAST_MODELS?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
};

type Background = {
  id: number;
  companyId: number;
  segmentId: number;
  occasionId?: any;
  formatId: number;
  name: string;
  imageURL: string;
  thumbnail: string;
  tags?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
};

type Image = {
  id: number;
  imageName: string;
  image?: Picture[] | string;
};

type GalleryDatas = {
  tabloids: Array<Tabloid>;
  loading: boolean;
  indexGaleria: number;
  onClick: (op: string, item: Image | Tabloid) => {};
  loadData: () => {};
};

type MyModelDatas = {
  templates: Array<Template>;
  indexModels: number;
  onClick: (op: string, item: Template) => {};
  loading: boolean;
  loadData: () => {};
};

type MyBackgroundDatas = {
  backgrounds: Array<Background>;
  indexBackgrounds: number;
  onClick: (op: string, item: Template) => {};
  loading: boolean;
  loadData: () => {};
};

type DatasTransform = {
  imageList: Array<Image>;
  loading: boolean;
  indexFrame: number;
  onClick?: (op: string, item: Image | Tabloid | any) => {};
  loadingDatas: () => {};
  productQuantity?: number;
};

function datasTransform(
  tab: string,
  galleryDatas?: GalleryDatas,
  myModelsDatas?: MyModelDatas,
  myBackgroundsDatas?: MyBackgroundDatas
) {
  const datas = {};
  // console.log('datasTransform - galleryDatas', galleryDatas);
  switch (tab) {
    case 'gallery':
      Object.defineProperties(datas, {
        imageList: {
          writable: true,
          value: galleryDatas!.tabloids.map(tabloid => {
            return {
              id: tabloid.id_tabloide_digital,
              imageName: tabloid.nome_tabloide_digital,
              image: tabloid.artes
            };
          })
        },
        loading: {
          writable: true,
          value: galleryDatas!.loading
        },
        indexFrame: {
          writable: true,
          value: galleryDatas!.indexGaleria
        },
        onClick: {
          writable: true,
          value: galleryDatas!.onClick
        },
        loadingDatas: {
          writable: true,
          value: galleryDatas!.loadData
        }
      });

      break;

    case 'myModels':
      Object.defineProperties(datas, {
        imageList: {
          writable: true,
          value: myModelsDatas!.templates.map(template => {
            return {
              id: template.id,
              imageName: template.name,
              image: template.imageURL,
              productQuantity: template.productQuantity
            };
          })
        },
        loading: {
          writable: true,
          value: myModelsDatas!.loading
        },
        indexFrame: {
          writable: true,
          value: myModelsDatas!.indexModels
        },
        onClick: {
          writable: true,
          value: myModelsDatas!.onClick
        },
        loadingDatas: {
          writable: true,
          value: myModelsDatas!.loadData
        }
      });

      break;

    case 'myBackgrounds':
      Object.defineProperties(datas, {
        imageList: {
          writable: true,
          value: myBackgroundsDatas!.backgrounds.map(background => {
            return {
              id: background.id,
              imageName: background.name,
              image: background.imageURL
            };
          })
        },
        loading: {
          writable: true,
          value: myBackgroundsDatas!.loading
        },
        indexFrame: {
          writable: true,
          value: myBackgroundsDatas!.indexBackgrounds
        },
        onClick: {
          writable: true,
          value: myBackgroundsDatas!.onClick
        },
        loadingDatas: {
          writable: true,
          value: myBackgroundsDatas!.loadData
        }
      });

      break;
  }

  // console.log('datasTransform - datas', datas);
  return datas;
}

interface StudioImagesProps {
  tab: 'gallery' | 'myModels' | 'myBackgrounds';
  galleryDatas?: GalleryDatas;
  myModelsDatas?: MyModelDatas;
  myBackgroundsDatas?: MyBackgroundDatas;
}

export default function StudioImages({
  tab,
  galleryDatas,
  myModelsDatas,
  myBackgroundsDatas
}: StudioImagesProps): JSX.Element {
  const { selectMenu, selectSettings } = useSelectMenu();
  const magnumClasses = useDefaultStyles();
  const [convertedDatas, setConvertedDatas] = useState<DatasTransform>({
    imageList: [],
    indexFrame: 0,
    loading: false,
    loadingDatas: () => {
      return {};
    },
    onClick: (op, item) => {
      return {};
    }
  });

  useEffect(() => {
    // console.log('useEffect - galleryDatas', galleryDatas);
    // console.log('useEffect - myModelsDatas', myModelsDatas);
    // console.log('useEffect - myBackgroundDatas', myBackgroundsDatas);

    const datas = datasTransform(
      tab,
      galleryDatas,
      myModelsDatas,
      myBackgroundsDatas
    ) as DatasTransform;
    // console.log('useEffect - datas', datas);

    setConvertedDatas(datas);
  }, [galleryDatas, myModelsDatas, myBackgroundsDatas]);

  const factor = 10;
  const totalImages = convertedDatas.indexFrame * factor;
  const imagesFrame = convertedDatas.imageList.slice(0, totalImages);

  // console.log('convertedDatas', convertedDatas);

  return (
    <>
      {/* {console.log('imagesFrame', imagesFrame)} */}
      <InfiniteScroll
        dataLength={imagesFrame.length}
        next={convertedDatas.loadingDatas}
        hasMore={convertedDatas.indexFrame * factor < convertedDatas.imageList.length}
        loader={undefined}
      >
        <ImageList
          rowHeight={'auto'}
          gap={3}
          sx={{
            padding: theme => theme.spacing(0, 0, 0),
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {convertedDatas.loading
            ? Array(20)
                .fill(20)
                .map((_, i) => (
                  <div key={i} style={{ padding: '13px' }}>
                    <Skeleton
                      className={`${magnumClasses.gridMediaQueryHeight} ${magnumClasses.gridCard}}`}
                      style={{ height: 290, width: 180, borderRadius: 5 }}
                      variant="rectangular"
                    />
                  </div>
                ))
            : imagesFrame.map((item, index) => (
                <>
                  {/* {index < 5 && console.log('item', item)} */}
                  <div
                    key={item.id.toString()}
                    className={magnumClasses.cardTabloids}
                    // style={{
                    //   width: 180
                    // }}
                  >
                    <div
                      key={item.id.toString()}
                      style={{
                        marginRight: 24,
                        marginBottom: 8,
                        width: 180,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        cursor: 'pointer'
                      }}
                      // className={magnumClasses.gridCard}
                      title={item.imageName + ' - id: ' + item.id}
                    >
                      <div className={magnumClasses.gridMediaQueryHeight}>
                        <div className={magnumClasses.cardOptions}>
                          <div
                            className={magnumClasses.redirectEditor}
                            onClick={() => {
                              if (tab === 'gallery') {
                                selectMenu(
                                  'criacao',
                                  'templates,propaganda_de_ofertas',
                                  null,
                                  'Estúdio'
                                );
                                convertedDatas.onClick!('abrir', galleryDatas!.tabloids[index]);
                              } else if (tab === 'myModels') {
                                convertedDatas.onClick!('open', myModelsDatas!.templates[index]);
                              }
                            }}
                          ></div>
                          {tab === 'gallery' && (
                            <div
                              className={magnumClasses.wrapTrashText}
                              onClick={() =>
                                convertedDatas.onClick!('duplicar', galleryDatas!.tabloids[index])
                              }
                            >
                              Duplicar
                            </div>
                          )}
                          <div
                            className={magnumClasses.wrapTrashIcon}
                            onClick={() => {
                              if (tab === 'gallery') {
                                convertedDatas.onClick!('deletar', galleryDatas!.tabloids[index]);
                              } else if (tab === 'myModels') {
                                convertedDatas.onClick!(
                                  'delete',
                                  myModelsDatas!.templates[index].id
                                );
                              } else if (tab === 'myBackgrounds') {
                                convertedDatas.onClick!(
                                  'delete',
                                  myBackgroundsDatas!.backgrounds[index].id
                                );
                              }
                            }}
                          >
                            <IconButton
                              style={{
                                color: 'red',
                                padding: 0
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </div>
                        </div>

                        <Box
                          sx={{
                            'max-height': '250px',
                            'max-width': '100%',
                            width: 180,
                            margin: 'auto',
                            borderRadius: '8px',
                            padding: '12px 8px'
                          }}
                        >
                          <img
                            className={magnumClasses.gridImageEstudio2}
                            src={
                              tab === 'gallery'
                                ? item.image && item.image[0]
                                  ? item.image[0].thumbnail ||
                                    item.image[0].miniatura ||
                                    item.image[0]?.link
                                  : 'https://datasalesio-imagens.s3.amazonaws.com/empty_image_propaganda_02.png'
                                : item.image
                            }
                            alt={item.imageName}
                          />
                        </Box>
                      </div>
                    </div>

                    <div style={{ marginTop: 8 }}>
                      <div
                        className={magnumClasses.gridTitle2}
                        title={item.imageName + ' - id: ' + item.id}
                      >
                        {item.imageName}
                      </div>
                    </div>
                  </div>
                </>
              ))}
        </ImageList>
      </InfiniteScroll>
    </>
  );
}

