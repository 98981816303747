import { connect } from 'react-redux';
import { useSelectMenu } from '../../../../utils/helpers/Redirect';
import { Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjectIcon from "../../../../assets/header/projects.svg";
import EstudioIcon from "../../../../assets/header/studio.svg";
//import ChatIcon from "../../../../assets/header/chat.svg";
import ModeloProntoIcon from "../../../../assets/header/modelosProntos.svg";
//import CampanhasIcon from "../../../../assets/header/campanhas.svg";
import MyAccountIcon from "../../../../assets/header/account.svg";
import WhatsAppIcon from "../../../../assets/header/whatsapp.svg";
import SociaisIcon from "../../../../assets/header/socialAccount.svg";
import RadioIcon from "../../../../assets/header/radio.svg";
import AulasIcon from "../../../../assets/header/aulas.svg";
import VisaoGeral from "../../../../assets/header/visaoGeral.svg";
import TreinamentoIcon from "../../../../assets/header/treinamento.svg";
//import SuportIcon from "../../../../assets/header/support.svg";
import FinanceiroIcon from "../../../../assets/header/financeiro.svg";


import { useStyles, Drawer } from "./styles";
import { isLocalhostWithoutRelese, isReleaseEnvironment } from 'utils/helpers/Constants';
import { useCallback } from 'react';

/*type Permission = {
  moduleId: number;
  moduleName: string;
  serviceId: number;
  serviceName: string;
};*/

interface PropsDrawer {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

function NewDrawerMenu({ open, handleDrawerOpen, handleDrawerClose }: PropsDrawer) {
  const { selectMenu, selectMenuShort } = useSelectMenu();
  const token = JSON.parse(localStorage.getItem('token')!);
  const companyId: number | null = Number(localStorage.getItem('companyId'));
  const styles = useStyles();

  const handleGoToDsMktV3 = useCallback(
    (option: string) => {
      const alias = isLocalhostWithoutRelese
        ? 'https://market-dev.datasales.info'
        : isReleaseEnvironment
        ? 'https://market-rls.datasales.info'
        : 'https://market.datasales.info'; //qa.datasales.info";

      const urlDsV2 = ` ${alias}/sign-as-user/${token}?shortToRedirect=${option}`;

      window.location.assign(urlDsV2);
    },
    [token]
  );

  const handleGoToDsV3 = useCallback(
    (redirect: string) => {
      let hostNameV3 = '';
      isLocalhostWithoutRelese
        ? (hostNameV3 = 'market-dev')
        : isReleaseEnvironment
        ? (hostNameV3 = 'market-rls')
        : (hostNameV3 = 'market');
      const urlDsV3 = `https://${hostNameV3}.datasales.info/sign-as-user/${token}?shortToRedirect=${redirect}`;
      window.location.replace(urlDsV3);
    },
    [token]
  );

  /*const handleGoToFidelize = useCallback(
    (option: string) => {
      const alias = isLocalhostWithoutRelese
        ? 'https://crm-develop.datasales.info'
        : 'https://plus.datasales.info'; //qa.datasales.info";

      const urlDsV2 = ` ${alias}/sign-as-user/${token}?shortToRedirect=${option}`;

      window.location.assign(urlDsV2);
    },
    [token]
  );

  function hasPermitions(module: string, permit: string) {
    const userPermissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    const permission = userPermissions[module]?.find((permission: Permission) =>
      permission.serviceName.includes(permit)
    );
    return Boolean(permission);
  }*/

  const MENU = [
    [
      {
        title: 'Visão Geral',
        onClick: () => handleGoToDsMktV3('dashboard'),
        icon: VisaoGeral,
      }
    ],
    [
      {
        title: 'Meus Projetos',
        onClick: () => selectMenu('fastModels', 'propaganda_de_ofertas', '/projects', 'Início'),
        icon: ProjectIcon,
      },
      {
        title: 'Estudio de Criação',
        icon: EstudioIcon,
        onClick: () => selectMenu('fastModels', 'propaganda_de_ofertas', '/newStudio', 'Início'),
      },
      {
        title: 'Artes Prontas',
        icon: ModeloProntoIcon,
        onClick: () => selectMenu('fastModels', 'propaganda_de_ofertas', '/professional', 'Início'),
      }
    ],
    [
      {
        title: 'Minha conta',
        onClick: () => selectMenu('config', 'account', '/account-settings', 'Minha Conta'),
        icon: MyAccountIcon,
      },
      {
        title: 'WhatsApp',
        onClick: () => handleGoToDsV3('whatsapp'),
        icon: WhatsAppIcon,
      },
      {
        title: 'Redes Sociais',
        onClick: () => selectMenu('redes_sociais', 'redes_sociais', '/social-networks/accounts', 'Redes sociais'),
        icon: SociaisIcon,
      },
      {
        title: 'Rádios',
        onClick: () => selectMenu('radios', 'radios', '/radios', 'Rádios'),
        icon: RadioIcon,
      },
      ...(companyId === 396 ? [{
        title: 'Site',
        onClick: () => selectMenu('sites', 'sites', '/sites', 'Site'),
        icon: RadioIcon,
      }] : [])
    ],
    [
      {
        title: 'Vídeo aulas',
        onClick: () => window.open("https://www.youtube.com/playlist?list=PLr7dYaCP_s-xDLMHwir93qh1USr9Mdfyx"),
        icon: AulasIcon,
      },
      {
        title: 'Treinamento',
        onClick: () => window.open("https://calendly.com/datasales"),
        icon: TreinamentoIcon,
      },
      /*{
        title: 'Atendimento',
        onClick: () => console.log('atendimento'),
        icon: SuportIcon,
      },*/
      {
        title: 'Financeiro',
        onClick: () => selectMenuShort('financial'),
        icon: FinanceiroIcon,
      },
    ]
  ];

  return (
    <Box className={styles.sidebar}>
      <Drawer
        open={open}
        id={`drawerSide`}
        variant="permanent"
        anchor='left'
      >
          <List disablePadding dense sx={{px: 2}}>
            <ListItemButton
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              sx={{
                p: 2,
                borderRadius: "4px",
                color: "#778799",
                justifyContent: open ? 'initial' : 'center',
                '&:hover':{
                  background: '#F3F4FB',
                  color: '#0C20B5',
                  '& .MuiTypography-root':{
                    color: '#0C20B5'
                  }
                }
              }}
            >
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                  {open ?
                    <>
                      <ChevronLeftIcon />
                        <Typography
                          sx={{
                            color: '#778799',
                            fontSize: '0.75rem',
                            fontWeight: 400,
                            lineHeight: '1rem'
                          }}
                        >
                          Fechar Menu
                        </Typography>
                    </> :
                    <ChevronRightIcon /> }
              </Stack>
            </ListItemButton>
          </List>

        {MENU.map((menu, indexArrayMaster) => (
          <>
            <List
              sx={{
                justifyContent: open ? 'initial' : 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
                px: 2,
              }}
            >
              {menu.map((menu) => (
                <ListItem title={`${menu.title}`} disablePadding
                  sx={{
                    display: 'block',
                    ...(!open &&{ px: 0 })
                  }}>
                  <ListItemButton
                    title={`${menu.title}`}
                    sx={{
                      height: '2.4rem',
                      justifyContent: open ? 'initial' : 'center',
                      py: 1.5,
                      '&:hover':{
                        background: '#F3F4FB',
                        '& .MuiListItemText-primary':{
                          color: '#0C20B5'
                        }
                      }
                    }}
                    onClick={menu.onClick}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={menu.icon} alt="logout" />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${menu.title}`}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiListItemText-primary':{
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          letterSpacing: '0.02px',
                          lineHeight: '1.25rem',
                          color: '#374957'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {(MENU.length !== indexArrayMaster + 1) &&  <Divider />}
            </List>
          </>
        ))}
      </Drawer>
    </Box>
  );
}

const mapStateToProps = ({ loginReducer }: { loginReducer: any}) => ({ ...loginReducer });

export default connect(mapStateToProps, null)(NewDrawerMenu);

