import { Dialog, DialogContent, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4, 8)
  },
  typography: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));

function CreateDesignDialog({ open }) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogContent className={classes.content}>
        <Typography className={classes.typography} variant="h5">
          Preparando sua arte...
        </Typography>
        <BorderLinearProgress variant="indeterminate" />
      </DialogContent>
    </Dialog>
  );
}

export default CreateDesignDialog;

