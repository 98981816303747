import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import * as yup from 'yup';

import FacebookIcon from '../../../../../../assets/icons/marketing/FacebookOriginal.svg';
import InstagramIcon from '../../../../../../assets/icons/marketing/InstagramOriginal.svg';
import SmsIcon from '../../../../../../assets/icons/marketing/SMS.svg';
import TurbochargedActionIcon from '../../../../../../assets/icons/marketing/TurbochargedAction.svg';
import WhatsappIcon from '../../../../../../assets/icons/marketing/WhatsappOriginal.svg';
import useAdsDistribution from '../../../../../../hooks/api/distribution/useAdsDistribution';
import {
  DistributionTypeProps,
  SendStatusProps
} from '../../../../../../hooks/api/distribution/useAdsDistribution';
import { StateProps } from '../../../../../../store/types';
import {
  setAdsDistributionSelectedChannels,
  setAdsDistributionValidateForm
} from '../../../../action';
import { WHATSAPP_VALIDATION_SCHEMA } from '../../../AdsDistribution/components/WhatsApp';
import { TURBOCHARGED_ACTION_VALIDATION_SCHEMA } from '../../../CreateAds';
import { SOCIAL_NETWORKS_VALIDATION_SCHEMA } from '../../../CreatePost';
import { SMS_VALIDATION_SCHEMA } from '../../../CreateSms';
import useStyles from './style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type DisplayErrorsProps = {
  channelName: string;
  errors: string[];
};

type DistributionConfirmationModalProps = {
  isFromSchedulePost?: boolean;
  offerId: number;
  openModal: boolean;
  onCloseModal: (value: boolean) => void;
  onOpenModalCompleted: (value: SendStatusProps) => void;
};

function DistributionConfirmationModal({
  isFromSchedulePost,
  offerId,
  openModal,
  onCloseModal,
  onOpenModalCompleted
}: DistributionConfirmationModalProps) {
  const classes = useStyles();
  const {
    adsDistribution: { selectedChannelsFormData, selectedChannels }
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = React.useState<DisplayErrorsProps[]>([]);
  const [selectedPlatform, setSelectedPlatform] = React.useState<[] | string[]>([]);
  const { distribute, distributionStatus, sendStatus } = useAdsDistribution(offerId);
  const paddingInPixels = '32px';

  const hasSchema = (channel: DistributionTypeProps) => {
    if (
      channel === 'social-networks' ||
      channel === 'turbocharged-action' ||
      channel === 'whatsapp' ||
      channel === 'sms'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const selectSchema = (channel: DistributionTypeProps) => {
    console.log(' --- Log do Logadas: ', channel);
    if (isFromSchedulePost) {
      if (channel === 'turbocharged-action') {
        return TURBOCHARGED_ACTION_VALIDATION_SCHEMA.shape({
          interests: yup
            .array()
            .min(
              1,
              'Adicione um interesse para direcionar a divulgação. Se não adicionar, seu post será enviado mesmo assim!'
            )
        });
      }
    } else {
      if (channel === 'social-networks') {
        return SOCIAL_NETWORKS_VALIDATION_SCHEMA.shape({
          post: yup
            .string()
            .required(
              'Você não preencheu o campo de texto. Se não preencher, seus posts serão enviados sem texto.'
            )
            .nullable()
        });
      }
      if (channel === 'turbocharged-action') {
        return TURBOCHARGED_ACTION_VALIDATION_SCHEMA.shape({
          interests: yup
            .array()
            .min(
              1,
              'Adicione um interesse para direcionar a divulgação. Se não adicionar, seu post será enviado mesmo assim!'
            ),
          text: yup
            .string()
            .required(
              'Insira uma mensagem de texto para sua publicação. Se não preencher, seu post turbinado será enviado sem texto.'
            )
            .nullable()
        });
      }

      if (channel === 'whatsapp') {
        return WHATSAPP_VALIDATION_SCHEMA.shape({
          post: yup
            .string()
            .required(
              'Insira uma mensagem de texto para sua campanha. Se não preencher, o seu compartilhamento será enviado sem texto.'
            )
            .nullable()
        });
      }

      if (channel === 'sms') {
        return SMS_VALIDATION_SCHEMA.shape({
          text: yup
            .string()
            .max(139, 'SMS aceita um máximo de 139 caracteres')
            .required('O campo de texto é obrigatório para envios através de SMS')
        });
      }
    }
  };

  function returnSocialNetworksPlatforms(channelTitle: string, channelName: string) {
    if (channelName !== 'social-networks') return channelTitle;

    const countPlatforms = selectedPlatform.length;
    let platforms = '';

    for (let i = 0; i < countPlatforms; i++) {
      platforms += selectedPlatform[i] + (i === countPlatforms - 1 ? '' : ', ');
    }

    return countPlatforms === 1 ? selectedPlatform[0] : `${channelTitle} (${platforms})`;
  }

  function setAllChannelFormValidationsToFalse() {
    const newSelectedChannels = selectedChannels.map((channel, index) => {
      if (channel.validated) {
        return {
          ...channel,
          validated: false
        };
      } else {
        return channel;
      }
    });

    dispatch(setAdsDistributionSelectedChannels(newSelectedChannels));
    dispatch(setAdsDistributionValidateForm(false));
  }

  const handleConfirm = () => {
    // distribute(onCloseDistribution());
    distribute();
  };

  const handleClickOpen = () => {
    setOpen(true);
    onCloseModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCloseModal(false);

    setAllChannelFormValidationsToFalse();
  };

  const renderChannelIcon = (channel: DistributionTypeProps) => {
    if (channel === 'social-networks') {
      return (
        <span className={classes.logoContainer}>
          {selectedPlatform.map(platform => {
            if (platform === 'Facebook')
              return (
                <img className={classes.imgIcon} src={FacebookIcon} alt="icon-facebook-campaign" />
              );
            if (platform === 'Instagram')
              return (
                <img
                  className={classes.imgIcon}
                  src={InstagramIcon}
                  alt="icon-instagram-campaign"
                />
              );
          })}
        </span>
      );
    }
    if (channel === 'whatsapp') {
      return <img className={classes.imgIcon} src={WhatsappIcon} alt="icon-whatsapp-campaign" />;
    }
    if (channel === 'turbocharged-action') {
      return (
        <img className={classes.imgIcon} src={TurbochargedActionIcon} alt="icon-car-campaign" />
      );
    }

    if (channel === 'sms') {
      return <img className={classes.imgIcon} src={SmsIcon} alt="icon-sms-campaign" />;
    }
  };

  React.useEffect(() => {
    if (distributionStatus === 'success' && Boolean(sendStatus)) {
      setOpen(false);
      onCloseModal(false);
      if (sendStatus !== null) onOpenModalCompleted(_.uniq(sendStatus, 'channel'));
    }
  }, [distributionStatus, sendStatus]);

  React.useEffect(() => {
    if (openModal) {
      handleClickOpen();
    }
  }, [openModal]);

  React.useEffect(() => {
    if (open) {
      setDisplayErrors([]);
      selectedChannelsFormData.forEach(channel => {
        if (hasSchema(channel.name)) {
          console.log(` %%=> channel.data [${channel.name}]: `, channel.data);
          selectSchema(channel.name)
            ?.validate(channel.data, {
              abortEarly: false,
              recursive: true
            })
            .catch(function (err: any) {
              console.log({ err });

              setDisplayErrors(oldDisplayErrors => [
                ...oldDisplayErrors,
                {
                  channelName: channel.name,
                  errors: err.errors
                }
              ]);
            });
        }
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (
      selectedChannelsFormData.length > 0 &&
      selectedChannelsFormData.some(channelFormData => channelFormData.name === 'social-networks')
    ) {
      const socialNetworksFormData = selectedChannelsFormData.find(
        channelFormData => channelFormData.name === 'social-networks'
      )?.data;
      const socialNetworksPlatforms = socialNetworksFormData?.platforms?.map(
        platform => platform?.label
      );
      if (socialNetworksPlatforms) setSelectedPlatform(socialNetworksPlatforms);
    }
  }, [selectedChannelsFormData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: '602px',
          padding: paddingInPixels,
          borderRadius: '8px'
        },
        classes: {
          root: 'dsMarketing-studio-distribution-confirmationModal'
        }
      }}
      tabIndex={-1}
    >
      <DialogTitle sx={{ padding: '0 0 24px 0', fontSize: '24px' }}>
        Confirmar distribuição
      </DialogTitle>
      <DialogContent sx={{ padding: '0 0 24px 0' }}>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ color: '#616161', fontSize: '16px' }}
        >
          Você selecionou os seguintes tipos de distribuição: <br />
          <br />
          {selectedChannels.map((channel, index) => (
            <div
              key={index}
              className={[
                classes.channelContainer,
                index > 0 ? classes.channelContainerWithMargin : ''
              ].join(' ')}
            >
              <div className={classes.boxTitleAndLogo}>
                {renderChannelIcon(channel.name)}
                {returnSocialNetworksPlatforms(channel?.title, channel?.name)}
              </div>
              {displayErrors
                .filter(currentError => currentError.channelName === channel.name)
                .map(channelError =>
                  channelError.errors.map((error, errorIndex) => (
                    <div key={errorIndex} className={classes.errorBox}>
                      {error}
                    </div>
                  ))
                )}
              {Array.isArray(displayErrors) &&
                displayErrors?.filter(currentError => currentError.channelName === channel.name)
                  .length <= 0 && <div className={classes.errorBox}>Tudo ok!</div>}
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 0 0 0',
          borderTop: '1px solid #CFCFCF'
        }}
      >
        <Button
          disabled={distributionStatus === 'processing'}
          sx={{ color: '#191919' }}
          onClick={handleClose}
        >
          Voltar para edição
        </Button>
        <Button
          disabled={distributionStatus === 'processing'}
          variant="contained"
          onClick={handleConfirm}
        >
          {distributionStatus === 'processing'
            ? 'Distribuindo ...'
            : displayErrors.length > 0
            ? 'Confirmar mesmo assim'
            : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(DistributionConfirmationModal);
