export default {
  LOADING_CHATBOT: 'LOADING_CHATBOT',
  LOADING_GATILHOS: 'LOADING_GATILHOS',
  GET_INSTANCIAS: 'GET_INSTANCIAS',
  GET_CONTATOS_INSTANCIA: 'GET_CONTATOS_INSTANCIA',
  PUT_INSTANCIA: 'PUT_INSTANCIA',
  GET_LOJAS: 'GET_LOJAS',
  GET_STATUS_INSTANCIA: 'GET_STATUS_INSTANCIA',
  GET_CALENDARIOS: 'GET_CALENDARIOS',
  POST_CALENDARIO: 'POST_CALENDARIO',
  PUT_CALENDARIO: 'PUT_CALENDARIO',
  POST_GATILHO: 'POST_GATILHO',
  DEL_GATILHO: 'DEL_GATILHO',
  PUT_GATILHO: 'PUT_GATILHO',
  SET_GATILHOS: 'SET_GATILHOS',
};
