import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TabPanel from '../../utils/components/TabPanel';
import Analytics from './components/Analytics';
import Posts from './components/Posts';
import useStyles from './styles';

function SocialNetworks() {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const history = useHistory();

  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box pt={4}>
            <Typography variant="h3" noWrap>
              Redes Sociais
            </Typography>
          </Box>
        </Grid>

        <Grid item container justify="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              history.push('/social-networks/accounts');
            }}
            endIcon={<PeopleAltIcon />}
          >
            Contas
          </Button>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Button
              className={value === 0 ? classes.active : ''}
              color="inherit"
              size="large"
              onClick={() => {
                setValue(0);
              }}
            >
              Posts
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={value === 1 ? classes.active : ''}
              color="inherit"
              size="large"
              onClick={() => {
                setValue(1);
              }}
            >
              Analytics
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <Posts />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Analytics />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SocialNetworks;
