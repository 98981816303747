import { Box, Menu, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config_ds } from 'utils/helpers/Constants';

import { useStyles } from './styles';

type FooterProps = {
  goBack: () => void;
  onDistribute: () => void;
  onCreateFolder: (type: string) => void;
  disabledDistribute: boolean;
  loadingDistribution: boolean;
};

export default function Footer({
  goBack,
  onDistribute,
  disabledDistribute,
  onCreateFolder,
  loadingDistribution
}: FooterProps) {
  const classes = useStyles();

  const permissions = JSON.parse(localStorage?.getItem('permissions') || '{}');
  const noDistribution = permissions?.criacao?.find((item: any) => item?.serviceId === 66);

  const [anchorElTypePublication, setAnchorElTypePublication] = useState(null);

  const toggleTypePublicationMenu = (event: any) => {
    if (anchorElTypePublication !== event.currentTarget) {
      setAnchorElTypePublication(event.currentTarget);
    }
  };

  function handleCloseTypePublicationMenu() {
    setAnchorElTypePublication(null);
  }

  const publicacao_agendada_ativada = true;

  const click_direct = () => {
    handleCloseTypePublicationMenu();
    onCreateFolder('default');
  };

  return (
    <div className={classes.footer}>
      <div>
        <Button
          className={classes.button}
          variant="text"
          onClick={() => goBack()}
          style={{
            marginRight: 40,
            color: '#2813AD',
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.05em'
          }}
        >
          Voltar
        </Button>
      </div>
      <div>
        <Button
          className={classes.button}
          disabled={disabledDistribute}
          onClick={e =>
            publicacao_agendada_ativada ? toggleTypePublicationMenu(e) : click_direct()
          }
          onMouseOver={handleCloseTypePublicationMenu}
          style={{
            marginRight: 40,
            color: '#2813AD',
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.05em',
            ...(disabledDistribute ? { opacity: 0.5 } : {})
          }}
        >
          {loadingDistribution ? 'Carregando...' : 'Distribuir'}
        </Button>

        <Menu
          id="simple-menu-type-publication"
          variant="menu"
          anchorEl={anchorElTypePublication}
          open={Boolean(anchorElTypePublication)}
          onClose={handleCloseTypePublicationMenu}
          className={classes.downloadButtonMenu}
          classes={{
            root: classes.downloadButtonMenuRoot,
            paper: classes.downloadButtonMenuPaper,
            list: classes.downloadButtonMenuList
            // item: classes.downloadButtonMenuItem
          }}
          style={{ zIndex: 99999, top: '-48px' }}
          color="primary"
          MenuListProps={{
            onMouseLeave: handleCloseTypePublicationMenu,
            style: {
              padding: 0,
              color: '#000'
            }
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <MenuItem
            // disabled={selectedShareableItem.length > 10 ? true : false}
            onClick={() => {
              handleCloseTypePublicationMenu();
              onCreateFolder('default');
            }}
          >
            <Box className={classes.wrapDownloadOptions}>
              <Typography variant="inherit" noWrap className={classes.titleBold}>
                Publicação comum
              </Typography>
              <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                Publique suas artes agora
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseTypePublicationMenu();
              onCreateFolder('scheduled');
            }}
          >
            <Box className={classes.wrapDownloadOptions}>
              <Typography variant="inherit" noWrap className={classes.titleBold}>
                Publicação agendada
              </Typography>
              <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                Programe seus posts para o ano todo
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </div>
      {noDistribution && (
        // desnecessário após criação acima do botão distribuir
        <div>
          <Button
            className={classes.button}
            disabled={disabledDistribute}
            style={{ backgroundColor: !disabledDistribute ? '#2813AD' : '#CFCFCF' }}
            variant="contained"
            onClick={() => onDistribute()}
          >
            Salvar artes na pasta
          </Button>
        </div>
      )}
    </div>
  );
}

