import { GridListTile, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import MenuItem from '@material-ui/core/MenuItem';
// "/home/thales/Documentos/ds/front/front-crm/src/modules/TabloideDigitalV2/components/index"
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import { Skeleton } from '@material-ui/lab';
import { useState } from 'react';

import { useTabloids } from '../../../../../hooks/tabloids';
// import { AMBIENTE } from '../../../../../utils/helpers/Constants';
import { useDefaultStyles } from '../../../style';

// const IOSSwitch = withStyles(theme => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: theme.spacing(1)
//   },
//   switchBase: {
//     padding: 1,
//     '&$checked': {
//       transform: 'translateX(16px)',
//       color: theme.palette.common.white,
//       '& + $track': {
//         backgroundColor: '#52d869',
//         opacity: 1,
//         border: 'none'
//       }
//     },
//     '&$focusVisible $thumb': {
//       color: '#52d869',
//       border: '6px solid #fff'
//     }
//   },
//   thumb: {
//     width: 24,
//     height: 24
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(['background-color', 'border'])
//   },
//   checked: {},
//   focusVisible: {}
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked
//       }}
//       {...props}
//     />
//   );
// });

const useStyles = makeStyles(theme => ({
  gridList: { width: '100%' },
  skeleton: {
    borderRadius: theme.shape.borderRadius
  },
  divider: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5)
  },
  menuList: {
    padding: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    marginLeft: '0px'
  },
  menuItem: {
    borderRadius: 4,
    padding: '12px',
    width: '100%'
  },
  // -------------------------------
  // -------------------------------
  // -------------------------------
  title1: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '32px',
    color: '#1D1D1D'
  },
  'title1-2': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D'
  },
  title2: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D'
  },
  title3: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#616161'
  },
  title4: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    color: '#127FFF'
  },
  title5: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: '#1D1D1D'
  }
}));

function ProductsCarousel({
  onClick,
  array,
  loading,
  tabloid,
  handleSubmit,
  loadingGetTabloids,
  splitButtonAdd
}) {
  const classes = useStyles();
  const magnumClasses = useDefaultStyles();

  const [scrollCount, setScrollCount] = useState(0);
  const [loadingTabloid, setLoadingTabloid] = useState(false);

  const { draweSideOpened } = useTabloids();

  const companyBasePath = localStorage.getItem('companyBasePath');

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {array.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '16px 0px',
              // backgroundColor: '#F5F5F5',
              borderRadius: 8
              // marginLeft: 24,
            }}
          >
            <div>{splitButtonAdd}</div>
            {/* <div
              style={{
                letterSpacing: '0.05em',
                textAlign: 'left',
                color: '#000000'
              }}
              className={classes.title2}
            >
              Deseja publicar esses itens no seu site?
            </div>
            <div>
              {loadingTabloid || loadingGetTabloids ? (
                <img
                  src="https://datasalesio-imagens.s3.amazonaws.com/tmp/loading-loading-forever.gif"
                  alt=""
                  style={{ width: 24, marginLeft: 12, marginRight: 12 }}
                />
              ) : (
                <IOSSwitch
                  checked={!!tabloid.ofertaVendasOnline}
                  onChange={async event => {
                    setLoadingTabloid(true);
                    await handleSubmit({ ofertaVendasOnline: event.target.checked ? 1 : null });
                    setLoadingTabloid(false);
                  }}
                  name="publicarNoSite"
                />
              )}
            </div> */}
            {/* <a
              href={
                AMBIENTE === 'dev'
                  ? `https://delivery-datasales-dev.s3.amazonaws.com${companyBasePath}`
                  : `https://meumercado.co${companyBasePath}`
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                marginLeft: 32,
                textDecoration: 'none',
                alignItems: 'center'
              }}
              className={classes.title4}
            >
              <VisibilityIcon style={{ marginRight: 12, letterSpacing: '0.05em' }} />
              Ver no site
            </a> */}
          </div>
        )}
      </div>

      {array.length > 0 && (
        <div
          style={{
            display: 'flex',
            // justifyContent: 'left',
            flexDirection: 'column'
            // marginBottom: 30,
          }}
        >
          <div
            style={{
              width: `calc(95vw - ${draweSideOpened ? '240px' : '0px'})`
            }}
          >
            <div
              style={{
                display: 'flex',
                marginLeft: scrollCount,
                overflow: 'hidden'
              }}
            >
              {loading
                ? Array(12)
                    .fill(12)
                    .map((_, i) => (
                      <GridListTile key={i}>
                        <Skeleton
                          className={classes.skeleton}
                          variant="rect"
                          height={180}
                          width="100%"
                        />
                      </GridListTile>
                    ))
                : array.map((item, indexKey) => (
                    // <div
                    //   key={item.id}
                    //   style={{
                    //     width: 150,
                    //     backgroundColor: 'lightgrey',
                    //     marginLeft: 18,
                    //   }}
                    //   title={item.name + ' - id: ' + item.id}>
                    //   <div style={{ width: 150 }} onClick={() => onClickImage(item)}>
                    //     <img
                    //       style={{ width: '100%' }}
                    //       src={item.imagem_produto}
                    //       title={item.nome_produto + ' - id: ' + item.id_tabloide_produto}
                    //       loading="lazy"
                    //     />
                    //   </div>

                    //   <div
                    //     className={magnumClasses.productText}
                    //     style={{ margin: '0px 10px 5px 10px' }}>
                    //     {item.nome_produto}
                    //   </div>

                    //   <div
                    //     className={magnumClasses.productText}
                    //     style={{ margin: '0px 10px 5px 10px' }}>
                    //     R$ {item.preco_dois}
                    //   </div>
                    // </div>
                    <div
                      key={item.id_tabloide_digital.toString() + `${indexKey}`}
                      style={{ marginRight: 16 }}
                      onClick={() => onClick(item)}
                    >
                      <div
                        className={magnumClasses.gridCard2}
                        // title={item.nome_tabloide_digital + ' - id: ' + item.id_tabloide_digital}
                        // onClick={() => clickOnTabloid(item)}
                        // style={{ padding: 0}}
                      >
                        <div
                          className={magnumClasses.gridMediaQueryHeight2}
                          style={{ width: 125, height: '100%' }}
                        >
                          <img
                            className={magnumClasses.gridImage}
                            crossOrigin="anonymous"
                            src={item.imagem_produto}
                            title={item.nome_produto + ' - id: ' + item.id_tabloide_produto}
                            alt={item.nome_produto + ' - id: ' + item.id_tabloide_produto}
                            loading="lazy"
                          />
                        </div>

                        <div>
                          <div
                            className={magnumClasses.gridTitle}
                            title={item.nome_produto + ' - id: ' + item.id_tabloide_produto}
                            style={{ marginTop: 8, width: 125 }}
                          >
                            <div className={magnumClasses.productText2} style={{ height: 66 }}>
                              {item.nome_produto}
                            </div>

                            <div className={magnumClasses.productPrice} style={{ marginTop: 8 }}>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2
                              }).format(item.preco_dois)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <IconButton
                style={{ backgroundColor: 'white', opacity: scrollCount + 200 <= 0 ? 1 : 0.2 }}
                onClick={() => setScrollCount(prev => (prev + 200 <= 0 ? prev + 200 : prev))}
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <IconButton
                onClick={() => setScrollCount(prev => (prev - 200 <= 0 ? prev - 200 : prev))}
              >
                <ArrowForwardIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductsCarousel;
