import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxText: {
      fontSize: '0.875rem !important',
      fontWeight: '500 !important',
      lineHeight: '1.25rem !important',
      color: '#616161 !important'
    }
  })
);
