import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { MoedaBrasil } from "../../../utils/helpers/Format";
import { BG_PRIMARY } from "../../../utils/helpers/Constants";

export default ({ lista }) => (
  <Fragment>
    <Table
      style={{ maxHeight: "30vh", color: BG_PRIMARY }}
      size="small"
      aria-label="a dense table"
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ color: BG_PRIMARY }}>
            Período sem compras
          </TableCell>
          <TableCell style={{ color: BG_PRIMARY }} align="center">
            Clientes
          </TableCell>
          <TableCell style={{ color: BG_PRIMARY }} align="center">
            Valor em vendas
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lista
          ? lista.map((linha, key) => (
              <TableRow key={key}>
                <TableCell style={{ color: BG_PRIMARY }}>
                  {linha.faixas}
                </TableCell>
                <TableCell style={{ color: BG_PRIMARY }} align="center">
                  {linha.total}
                </TableCell>
                <TableCell style={{ color: BG_PRIMARY }} align="center">
                  R$ {MoedaBrasil(linha.VL_VENDAS)}
                </TableCell>
              </TableRow>
            ))
          : ""}
      </TableBody>
    </Table>
  </Fragment>
);
