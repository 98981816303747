import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Backdrop,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { CloseRounded, GetAppRounded, GroupRounded } from '@material-ui/icons';
import Markdown from './chat-elements/helpers/Markdown';
import useDate from '../../../hooks/date';
import getInitials from '../../../utils/helpers/getInitials';
import useStyles from '../styles/index';

export default function DialogMedia({ open, midia, chat, onClose }) {
  const classes = useStyles(),
    { differenceFormated } = useDate();

  function _view() {
    if (Boolean(midia)) {
      switch (midia.type) {
        case 'VIDEO':
          return (
            <video
              controls
              autoPlay
              alt={midia.message_id}
              style={{ borderRadius: '7.5px', maxWidth: '20vw' }}
            >
              <source src={midia.message} type="video/mp4" />
            </video>
          );
        case 'IMAGE':
          return (
            <img
              style={{ borderRadius: '7.5px', maxWidth: '24vw' }}
              alt={midia.message_id}
              src={midia.message}
            />
          );
        default:
          return null;
      }
    }
  }

  async function _onDownload() {
    const image = await fetch(midia.message);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = midia.message_id;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flex={1} display="flex" alignItems="center">
            <Avatar alt={chat.NAME} className={classes.avatarBackdrop}>
              {Boolean(midia) && Boolean(midia.i_send) ? (
                getInitials()
              ) : chat.PROFILE_THUMBNAIL ? (
                <img
                  src={chat.PROFILE_THUMBNAIL}
                  className={classes.avatarBackdrop}
                />
              ) : chat.IS_GROUP ? (
                <GroupRounded />
              ) : null}
            </Avatar>
            <Box>
              <Typography
                style={{ color: '#FFF', marginLeft: '1vw' }}
                variant="body1"
              >
                {Boolean(midia) && Boolean(midia.i_send) ? 'Você' : chat.NAME}
              </Typography>
              {Boolean(midia) && Boolean(midia.data_envio) ? (
                <Typography
                  style={{ color: '#FFF', marginLeft: '1vw' }}
                  variant="subtitle1"
                >
                  {differenceFormated(
                    midia.data_envio.replace('Z', ''),
                    'YYYY-MM-DDTHH:mm:ss'
                  )}
                </Typography>
              ) : null}
            </Box>
          </Box>

          <IconButton
            title="Baixar"
            color="inherit"
            onClick={() => _onDownload()}
          >
            <GetAppRounded />
          </IconButton>
          <IconButton title="Fechar" color="inherit" onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box display="flex" flexDirection="column" alignItems="center">
        {_view()}
        <Typography style={{ color: '#FFF', marginTop: '2vh' }} variant="body1">
          {midia && midia.caption ? (
            <span
              dangerouslySetInnerHTML={{
                __html: Markdown(midia.caption),
              }}
            />
          ) : null}
        </Typography>
      </Box>
    </Backdrop>
  );
}
