import moment from 'moment';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { Audience } from 'types/audience';

import { SelectedShareableItemProps } from '../../../../modules/TabloideDigitalV2/reducer/types';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { asyncForEach } from '../../../../utils/helpers/Functions';

type ShippingType = {
  name: string;
  value: string;
  label: string;
};

type Instance = {
  id: string;
  ativo: string;
  codigo: string;
  id_empresa: number;
  id_loja: number;
  nome: string;
  own_number: string;
  shared_instancia: number;
  status: {
    connected: boolean;
  };
  tipo_instancia: number;
};

type DistributionWhatsappProps = {
  selectedCelular: Array<{
    value: string;
    label: string;
  }>;
  dataInicioCampanha: string;
  dataFimCampanha: string;
  key: number;
  selectedInstance: Instance; //object,
  selectedShippingType: ShippingType; //object,
  numbersInCopy: string;
  scheduleDate: string;
  post: string;
  hostTracker: {
    host: string;
    label: string;
  };
  pareto: {
    id: string;
    label: string;
  };
  groupsToSend?: Array<{
    ID: string;
    INVITE: string;
    NAME: string;
  }>;

  offerId: number;
  offerName: string;
  carouselSelectedItems: SelectedShareableItemProps;
  audience?: Audience;
  // setLoading: (loading: boolean) => void;
  // setError: (error: string | null | unknown ) => void;
};

const distributeWhatsappContactOrCopy = async ({
  dataInicioCampanha,
  dataFimCampanha,
  hostTracker,
  key,
  numbersInCopy,
  pareto,
  selectedCelular,
  selectedInstance,
  selectedShippingType,
  post,
  offerId,
  offerName,
  scheduleDate,
  carouselSelectedItems,
  audience
}: DistributionWhatsappProps) => {
  return carouselSelectedItems.map((item: any, index: number) => {
    const isVideo = item.link ? item.link.endsWith('.mp4') : false;

    return api(
      `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create`,
      {
        onlyInCopy: selectedShippingType.value === 'onlyInCopy',
        idInstancia: selectedInstance.id,
        nomeCampanha: offerName,
        numerosList:
          numbersInCopy &&
          numbersInCopy.split(';').map((numb: any) => numb.trim().replace(/\s/g, '')),
        message: index === 0 ? post : '',
        numero: '',
        dataInicio: scheduleDate
          ? moment(scheduleDate).format('DD/MM/YYYY HH:mm')
          : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
        dataFim: moment(scheduleDate).add(7, 'days').format('DD/MM/YYYY HH:mm'),
        dataInicioCampanha: moment(dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
        dataFimCampanha: moment(dataFimCampanha).format('DD/MM/YYYY HH:mm'),
        origin: 'Minha Base',
        pareto: pareto.id,
        selectedCelular: selectedCelular && selectedCelular.map(value => value.value),
        custoCampanha: 0,
        whatsapp_midia: true,
        whatsapp_url: isVideo ? item.link : item.image_url,
        ...(item.preview_url && { preview_url: item.preview_url }),
        hostTracker: !hostTracker ? '' : hostTracker.host,
        id_propaganda: offerId,
        id_propaganda_imagem: item.id,
        ...(isVideo ? { messageType: 'VIDEO' } : {}),
        ...(audience &&
          selectedShippingType.value === 'audience' && {
            idListProspectei: audience.idListProspectei
          }),
        ...(audience && selectedShippingType.value === 'audience' && { tipo_envio_campanha: 2.1 }),
        ...(audience &&
          selectedShippingType.value === 'audience' && { nameList: audience.nameList })
      },
      'POST',
      null,
      null,
      false
    );
  });
};

const distributeWhatsappGroup = async ({
  dataInicioCampanha,
  dataFimCampanha,
  selectedInstance,
  post,
  offerId,
  offerName,
  groupsToSend,
  scheduleDate,
  carouselSelectedItems
}: DistributionWhatsappProps) => {
  const requests: any[] = [];
  for (let k = 0; k < groupsToSend!.length; k++) {
    const group = groupsToSend![k];
    const requestSends = carouselSelectedItems.map((item: any, index: number) => {
      const isVideo = item.link ? item.link.endsWith('.mp4') : false;

      return api(
        `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/campaign/create-group`,
        {
          idInstancia: selectedInstance.id,
          groupId: group,
          id_propaganda: offerId,
          nomeCampanha: offerName,
          message: index === 0 ? post : '',
          dataInicio: scheduleDate
            ? moment(scheduleDate).format('DD/MM/YYYY HH:mm')
            : moment().add(1, 'minute').format('DD/MM/YYYY HH:mm'),
          dataFim: moment(scheduleDate).add(7, 'days').format('DD/MM/YYYY HH:mm'),
          dataInicioCampanha: moment(dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
          dataFimCampanha: moment(dataFimCampanha).format('DD/MM/YYYY HH:mm'),
          whatsapp_midia: true,
          whatsapp_url: isVideo ? item.link : item.image_url,
          ...(item.preview_url && { preview_url: item.preview_url }),
          ...(isVideo ? { messageType: 'VIDEO' } : {})
        },
        'POST',
        null,
        null,
        false
      );
    });
    requests.push(...requestSends);
  }

  return requests;
};

export { distributeWhatsappContactOrCopy, distributeWhatsappGroup };

