import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Using Actions
import { getFileImport, postFileImport } from '../action';
import { postArquivoAudienciaCompleto } from '../../Insights/actions/actions';

// Using Components
import {
  Box,
  MenuItem,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CheckCircle, Visibility, Autorenew } from '@material-ui/icons';
import { CustomTable, SplitButton } from '../../../utils/components';
import ModalViewDetail from './ModalViewDetail';
import ImportFileSales from './ImportFileSales';
import ImportaArquivoAudiencia from '../../Insights/components/ImportaArquivoAudiencia';

// Using Styles
import useStyles from './styles';

// Using Helpers
import MyDate from '../../../utils/helpers/Date';

// Init Instances
const _myDate = new MyDate();

function Importacao(props) {
  const classes = useStyles(),
    [openModal, setOpenModal] = useState(false),
    [openModalSales, setOpenModalSales] = useState(false),
    [openViewDetail, setOpenViewDetail] = useState(false),
    [rowViewDetail, setRowViewDetail] = useState({}),
    permissions = JSON.parse(localStorage.getItem('permissions')),
    hasAud = Boolean(
      permissions.relacionamento &&
        permissions.relacionamento.find(
          item => item.serviceName === 'audiencia'
        )
    );

  useEffect(() => {
    props.getFileImport();
  }, []);

  function onSave(arquivo) {
    props.postArquivoAudienciaCompleto(arquivo, () => props.getFileImport());
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.actions}>
        <Typography className={classes.label} variant="h3" noWrap>
          Importação
        </Typography>
      </Box>
      <Box className={classes.actions} justifyContent="flex-end">
        <Tooltip title="Atualizar" placement="top">
          <IconButton onClick={() => props.getFileImport()}>
            <Autorenew />
          </IconButton>
        </Tooltip>

        <SplitButton icon text="Importar arquivo" label="label">
          {hasAud && (
            <MenuItem
              style={{ borderRadius: 10, width: '100%' }}
              onClick={() => setOpenModal(true)}
            >
              Cadastros
            </MenuItem>
          )}
          <MenuItem
            style={{ borderRadius: 10, width: '100%' }}
            onClick={() => setOpenModalSales(true)}
          >
            Vendas
          </MenuItem>
        </SplitButton>
      </Box>
      <Box className={classes.content}>
        <Paper className={classes.card}>
          <CustomTable
            toolbar={false}
            columns={[
              { title: 'Nome do arquivo', field: 'file_name' },
              {
                title: 'Data de importação',
                field: 'data_insert',
                render: row =>
                  Boolean(row.data_insert) && (
                    <span>
                      {_myDate.getDate(
                        'DD/MM/YYYY HH:mm',
                        row.data_insert.replace('Z', '')
                      )}
                    </span>
                  ),
              },
              {
                title: 'Status da importação',
                field: 'processado',
                render: row =>
                  Boolean(row.queue_processed) ? (
                    <Tooltip title="Arquivo importado" placement="top">
                      <CheckCircle style={{ fontSize: 25, color: '#00875a' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Importação em andamento" placement="top">
                      <CircularProgress size={22} thickness={4} />
                    </Tooltip>
                  ),
              },
              {
                title: 'Ações',
                render: row => (
                  <Tooltip title="Ver detalhes" placement="top">
                    <IconButton
                      onClick={() => {
                        setOpenViewDetail(true);
                        setRowViewDetail(row);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                ),
              },
            ]}
            isLoading={props.loadListFileImport}
            data={props.listFileImport}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              selectionProps: () => ({
                color: 'primary',
              }),
            }}
          />
        </Paper>
      </Box>
      <ModalViewDetail
        data={rowViewDetail}
        open={openViewDetail}
        onClose={() => {
          setOpenViewDetail(false);
          setRowViewDetail({});
        }}
      />
      <ImportaArquivoAudiencia
        open={openModal}
        type="PESSOA"
        onClose={() => setOpenModal(false)}
        onSave={arquivo => onSave(arquivo)}
      />
      <ImportFileSales
        open={openModalSales}
        type="VENDAS"
        onClose={() => setOpenModalSales(false)}
        onSave={arquivo => props.postFileImport(arquivo)}
      />
    </Box>
  );
}

const mapStateToProps = ({ ImportacaoReducer }) => ({
  ...ImportacaoReducer,
});

const mapDispatchToProps = {
  getFileImport,
  postArquivoAudienciaCompleto,
  postFileImport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Importacao);
