import Button from '@mui/material/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  currentPassword: Yup.string().required(),
  password: Yup.string()
    .min(6, 'A senha tem que ter mais que 6 caractéres.')
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas estão diferentes.')
    .required(),
});

function Security() {
  const classes = useStyles();

  async function onSubmit(data) {
    try {
      await api(
        `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/user/password`,
        data,
        'PUT'
      );
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Card className={classes.card}>
      <Form
        initialValues={{}}
        validate={makeValidate(schema)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <CardHeader title="Senha" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField className={classes.textFormTest}
                    name="currentPassword"
                    fullWidth
                    variant="outlined"
                    label="Senha atual"
                    security
                    type="password"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField className={classes.textFormTest}
                    name="password"
                    fullWidth
                    variant="outlined"
                    label="Senha"
                    security
                    type="password"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField className={classes.textFormTest}
                    name="confirmPassword"
                    fullWidth
                    variant="outlined"
                    label="Confirme sua Senha"
                    security
                    type="password"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <Button
                type="submit"
                color="info"
                variant="contained"
                disabled={submitting}
              >
                Salvar
              </Button>
            </CardActions>
          </form>
        )}
      </Form>
    </Card>
  );
}

export default Security;
