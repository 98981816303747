/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Divider,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button,
  CircularProgress
} from "@material-ui/core";
import {
  MyButton,
  DatePicker,
  Select,
  Countup,
  TextField,
  Modal,
  AutoComplete,
  Chip,
  DialogAlert,
  CustomTable
} from "../../../utils/components";
import { listStore } from "../../Lojas/actions/action";
import {
  getFamalias,
  getProdutosByFamilia,
  postOfertaCashbackMercado
} from "../actions";
import { MoedaBrasil } from "../../../utils/helpers/Format";
import { inputMoney } from "../../../utils/helpers/Functions";
import { MEDIA, COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import MyDate from "../../../utils/helpers/Date";
import "@webscopeio/react-textarea-autocomplete/style.css";

const _myDate = new MyDate();

class ModalCashbackMercado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      nomeOferta: "",
      listaSelecionada: null,
      media: null,
      lojas: null,
      dataInicio: _myDate.getDate(
        "YYYY-MM-DD HH:mm:ss",
        moment().add(3, "days")
      ),
      dataFim: _myDate.getDate("YYYY-MM-DD HH:mm:ss", moment().add(30, "days")),
      familia: "",
      idFamilia: "",
      nomeProduto: "",
      idProduto: "",
      preco: 0,
      porcentagem: 0,
      cashback: 0,
      produtos: [],
      familiasResumo: [],
      showDeleteProduto: false,
      produto: {}
    };
  }

  componentDidMount() {
    this.props.listStore();
    this.props.getFamalias();
  }

  removeProduto() {
    const { produtos, produto } = this.state;
    const index = produtos.findIndex(
      prd => prd.idProduto === produto.id_produto
    );
    produtos.splice(index, 1);
    this.setState({ produtos });
  }

  onSave() {
    const {
      produtos,
      nomeOferta,
      dataInicio,
      dataFim,
      lojas,
      media
    } = this.state;
    const objCashback = {
      nome_cashback: nomeOferta,
      lojas: lojas && lojas.length > 0 ? lojas.map(loja => loja.value) : null,
      media_cashback: media ? media.value : null,
      comeca_valer: dataInicio,
      termina_valer: dataFim,
      produtos
    };
    this.props.postOfertaCashbackMercado(objCashback);
    this.props.onClose();
  }

  addProdutos() {
    const {
      familia,
      nomeProduto,
      idFamilia,
      idProduto,
      preco,
      porcentagem,
      cashback,
      produtos
    } = this.state;
    const produto = this.props.produtos.find(
      prd => prd.id_produto === idProduto
    );
    produtos.unshift({
      url: produto ? produto.url : null,
      idFamilia,
      familia,
      idProduto,
      nomeProduto,
      preco,
      porcentagem,
      cashback
    });
    this.setState({
      produtos,
      familia: "",
      nomeProduto: "",
      preco: 0,
      porcentagem: 0,
      cashback: 0
    });
  }
  addFamilia(familia) {
    const { produtos } = this.props;
    const arrProdutos = produtos.map(prd => ({
      url: prd ? prd.url : null,
      idFamilia: prd.id_familia,
      idProduto: prd.id_produto,
      nomeProduto: prd.label,
      familia: prd.familia,
      preco: prd.preco,
      porcentagem: this.state.porcentagem,
      cashback: this.calculaCashbackWithParams(
        this.state.porcentagem,
        prd.preco
      )
    }));
    this.state.produtos.unshift(...arrProdutos);
    this.state.familiasResumo.unshift(familia);
    this.setState({
      produtos: this.state.produtos,
      familiasResumo: this.state.familiasResumo,
      familia: ""
    });
  }

  calculaCashback() {
    const { porcentagem, preco } = this.state;

    if (porcentagem && preco) {
      const preco1 = parseFloat(preco);

      const porcentagem1 = parseFloat(porcentagem);

      const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));

      this.setState({ cashback: vdesconto });
    }
  }

  calculaCashbackWithParams(porcentagem, preco) {
    const preco1 = parseFloat(preco);

    const porcentagem1 = parseFloat(porcentagem);

    return parseFloat(preco1 * (porcentagem1 / 100));
  }

  header(props, state) {
    const steps = ["Informações do Cashback", "Produtos", "Iniciar"];

    return (
      <Fragment>
        Cashback supermercado
        <div
          style={{
            width: "100%",
            marginTop: 40,
            marginBottom: -40
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return "Salvar";
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return "Proximo";
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
            disabled={
              !state.nomeOferta ? true : state.activeStep === 2 ? true : false
            }
          >
            {this.renderText()}
          </Button>
        )}
      </div>
    );
  }

  selectFamilia(value) {
    const familia = this.props.familias.find(
      familia => familia.label === value
    );
    this.setState(
      {
        familia: value,
        idFamilia: familia.id_familia
      },
      () => this.props.getProdutosByFamilia(value)
    );
  }

  selectProduto(value) {
    const produto = this.props.produtos.find(prd => prd.label === value);
    this.setState({
      nomeProduto: produto.label,
      preco: produto.preco,
      idProduto: produto.id_produto
    });
  }

  step1(props, state) {
    const { nomeOferta, dataInicio, dataFim, media, lojas } = state;
    return (
      <section style={{ padding: "5rem 20rem", display: "grid", gap: "2rem" }}>
        <Typography style={{ fontWeight: "900" }} color="primary" variant="h5">
          Informações do cashback
        </Typography>

        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          value={nomeOferta}
          onChange={event => this.setState({ nomeOferta: event.target.value })}
        />

        <Select
          options={props.listStores.map(loja => ({
            value: loja.id_loja,
            label: loja.nome_loja
          }))}
          multiple
          label="Lojas participantes"
          onChange={value => this.setState({ lojas: value })}
          value={lojas}
        />

        {/* <Select
          options={MEDIA}
          label="Media"
          onChange={value => this.setState({ media: value })}
          value={media}
        /> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <section>
            <DatePicker
              dateAndTime
              label="Começa a valer em"
              onChange={date => this.setState({ dataInicio: date })}
              value={dataInicio}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              disablePast
              label="Termina de valer em"
              onChange={date => this.setState({ dataFim: date })}
              value={dataFim}
            />
          </section>
        </div>
      </section>
    );
  }
  step2(props, state) {
    const {
      familia,
      nomeProduto,
      preco,
      porcentagem,
      cashback,
      produtos
    } = state;
    const { familias, isLoadingCashback } = props;

    return (
      <section
        style={{
          padding: "2rem 1rem 5rem 1rem",
          height: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginTop: 24,
            marginBottom: 24
          }}
        >
          <div style={{ width: "25%" }}>
            <AutoComplete
              label="Familia"
              value={familia}
              suggestions={familias}
              onChange={value => this.setState({ familia: value })}
              onSelect={value => this.selectFamilia(value)}
            />
          </div>
          <div style={{ width: "25%" }}>
            <AutoComplete
              label="Produto"
              value={isLoadingCashback ? "Carregando..." : nomeProduto}
              disabled={isLoadingCashback === true || !familia ? true : false}
              onChange={value => this.setState({ nomeProduto: value })}
              suggestions={props.produtos}
              onSelect={value => this.selectProduto(value)}
            />
          </div>

          <div style={{ width: "11%" }}>
            <TextField
              label="Porcentagem"
              value={porcentagem}
              disabled={!familia}
              shrink={true}
              onChange={e =>
                this.setState({ porcentagem: e.target.value }, () =>
                  this.calculaCashback()
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div>
                      <strong style={{ fontSize: 18 }}>%</strong>
                    </div>
                  </InputAdornment>
                )
              }}
            />
          </div>

          <div style={{ width: "11%" }}>
            <Button
              size="large"
              variant="text"
              color="primary"
              disabled={!familia}
              onClick={() =>
                nomeProduto ? this.addProdutos() : this.addFamilia(familia)
              }
            >
              Adicionar
            </Button>
          </div>
        </div>
        <CustomTable
          data={produtos}
          title=""
          paging
          pagesSize={5}
          actions={[
            {
              icon: () => <Icon color="error">delete</Icon>,
              tooltip: "Excluir",
              onClick: (event, rowData) =>
                this.setState({
                  showDeleteProduto: true,
                  produto: {
                    id_oferta: rowData.id_oferta,
                    id_oferta_detalhe: rowData.id_oferta_detalhe,
                    id_produto: rowData.idProduto
                  }
                })
            }
          ]}
          columns={[
            {
              field: "url",
              Title: "Produto",
              render: rowData =>
                rowData.url && (
                  <img
                    onerror="this.onerror=null;this.src='https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png';"
                    src={rowData.url}
                    alt=""
                    width={50}
                    height={50}
                  />
                )
            },
            { title: "Nome do Produto", field: "nomeProduto" },
            { title: "Familia", field: "familia" },
            {
              title: "Preço",
              field: "preco",
              render: rowData => (
                <Countup
                  start={0}
                  end={parseFloat(rowData.preco)}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: "Porcentagem",
              field: "porcentagem",
              render: rowData => `${rowData.porcentagem}%`
            },
            {
              title: "Cashback",
              field: "cashback",
              render: rowData => (
                <Countup
                  start={0}
                  end={parseFloat(rowData.cashback)}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            }
          ]}
        />
      </section>
    );
  }
  step3(props, state) {
    const { nomeOferta, dataInicio, dataFim, produtos, familiasResumo } = state;
    return (
      <section>
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre o cashback
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome do cashback</strong>
              </p>
              <span>{nomeOferta}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Começa valer em</strong>
              </p>
              <span>{_myDate.getDate("DD/MM/YYYY HH:mm", dataInicio)}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Termina de valer em</strong>
              </p>
              <span>{_myDate.getDate("DD/MM/YYYY HH:mm", dataFim)}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Famílias</strong>
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start"
                }}
              >
                {familiasResumo.map(familia => (
                  <Chip label={familia}></Chip>
                ))}
              </div>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Produtos</strong>
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start"
                }}
              >
                {produtos.slice(0, 10).map(prd => (
                  <Chip label={prd.nomeProduto} />
                ))}
                <Chip label={`+ ${produtos.length - 10} produtos`} />
              </div>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={() => this.onSave()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, open } = this.props;
    const { activeStep, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? "xl" : "lg"}
          showModal={open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {showDeleteProduto && (
          <DialogAlert
            open={showDeleteProduto}
            title="Deseja remover este produto?"
            onClick={() =>
              this.setState({ showDeleteProduto: false }, () =>
                this.removeProduto()
              )
            }
            onClose={() =>
              this.setState({ showDeleteProduto: false, produto: {} })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({ LojasReducer, CashbackReducer }) => ({
  ...LojasReducer,
  ...CashbackReducer
});
const mapDispatchToProps = {
  listStore,
  getFamalias,
  getProdutosByFamilia,
  postOfertaCashbackMercado
};

const ModalCashbackMercadoConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCashbackMercado);

export default withStyles(styles)(ModalCashbackMercadoConnect);
