export default {
  automacoes: [
    {
      title: 'Automações',
      path: '/automacoes',
      moduleName: 'relacionamento',
      serviceName: 'automacoes'
    }
  ],
  banco_de_dados: [
    {
      title: 'Audiência',
      path: '/filtros',
      moduleName: 'relacionamento',
      serviceName: 'audiencia'
    },
    {
      title: 'Enriquecimento',
      path: '/enriquecimento',
      moduleName: 'relacionamento',
      serviceName: 'enriquecimento'
    },
    {
      title: 'Importação',
      path: '/importacao',
      moduleName: 'relacionamento',
      serviceName: 'importacao'
    },
    {
      title: 'Banco de imagens',
      path: '/banco-de-imagens',
      moduleName: 'criacao',
      serviceName: 'banco_de_dados'
    }
  ],
  cashback: [
    {
      title: 'Cashback',
      path: '/cashback',
      moduleName: 'relacionamento',
      serviceName: 'cashback'
    },
    {
      title: 'Cupom de cashback',
      path: '/cupom-cashback',
      moduleName: 'relacionamento',
      serviceName: 'cupom_de_cashback'
    }
  ],
  campanhas: [
    {
      titleCampanhas: 'Campanhas:',
      moduleName: 'relacionamento',
      serviceName: 'monitor_de_envios'
    },
    {
      title: 'Monitor de campanhas',
      path: '/monitor-campanhas',
      moduleName: 'relacionamento',
      serviceName: 'monitor_de_envios'
    },
    {
      title: 'Criar campanha',
      path: '/criar-campanha',
      moduleName: 'relacionamento',
      serviceName: 'criar_campanha'
    },
    {
      title: 'Resultados',
      path: '/campanhas',
      moduleName: 'relacionamento',
      serviceName: 'resultados_de_campanhas'
    },
    {
      title: 'Anúncios',
      path: '/ads',
      moduleName: 'relacionamento',
      serviceName: 'ads'
    },
    {
      title: 'Respostas',
      path: '/respostas',
      moduleName: 'relacionamento',
      serviceName: 'respostas'
    },
    {
      title: 'Blacklist',
      path: '/blacklist',
      moduleName: 'relacionamento',
      serviceName: 'blacklist'
    },
    {
      title: 'Formulários',
      path: '/forms',
      moduleName: 'relacionamento',
      serviceName: 'formularios'
    },
    {
      title: 'Templates de E-mail',
      path: '/studio',
      moduleName: 'criacao',
      serviceName: 'templates'
    },
    {
      titleSegmentacao: 'Segmentação:',
      moduleName: 'relacionamento',
      serviceName: 'monitor_de_envios'
    },
    {
      title: 'Audiências',
      path: '/filtros',
      moduleName: 'relacionamento',
      serviceName: 'audiencia'
    },
    {
      title: 'Enriquecimento',
      path: '/enriquecimento',
      moduleName: 'relacionamento',
      serviceName: 'enriquecimento'
    },

    {
      title: 'Importação',
      path: '/importacao',
      moduleName: 'relacionamento',
      serviceName: 'importacao'
    }
  ],
  cluster: [
    {
      title: 'Cluster',
      path: '/cluster',
      moduleName: 'relacionamento',
      serviceName: 'cluster'
    }
  ],
  criacao: [
    {
      title: 'Propaganda de ofertas',
      path: '/estudio',
      moduleName: 'criacao',
      serviceName: 'propaganda_de_ofertas'
    }
    // {
    //   title: 'Templates de E-mail',
    //   path: '/studio',
    //   moduleName: 'criacao',
    //   serviceName: 'templates',
    // },
  ],
  dashboardV2: [
    {
      title: 'Dashboard',
      path: '/dashboard-v2',
      moduleName: 'criacao',
      serviceName: 'propaganda_de_ofertas'
    }
  ],
  fastModels: [
    {
      title: 'Modelos Prontos',
      path: '/home',
      moduleName: 'criacao',
      serviceName: 'propaganda_de_ofertas'
    }
  ],
  allApps: [
    {
      title: 'Todos os aplicativos',
      path: '/apps/all',
      moduleName: 'allApps',
      serviceName: 'propaganda_de_ofertas'
    }
  ],
  versionOption: [
    {
      title: 'Versões',
      path: '/versions',
      moduleName: 'criacao',
      serviceName: 'propaganda_de_ofertas'
    }
  ],
  e_commerce: [
    {
      title: 'Pedidos',
      path: '/delivery',
      moduleName: 'vendas',
      serviceName: 'pedidos'
    },
    {
      title: 'Categorias',
      path: '/categorias-delivery',
      moduleName: 'vendas',
      serviceName: 'categorias'
    },
    {
      title: 'Produtos',
      path: '/produtos-delivery',
      moduleName: 'vendas',
      serviceName: 'produtos'
    }
  ],
  ofertas: [
    {
      title: 'Resultados',
      path: '/ofertas',
      moduleName: 'relacionamento',
      serviceName: 'resultados_de_ofertas'
    },
    {
      title: 'Criar oferta',
      path: '/criar-oferta',
      moduleName: 'relacionamento',
      serviceName: 'criar_oferta'
    },
    {
      title: 'Monitor de ofertas',
      path: '/monitor-ofertas',
      moduleName: 'relacionamento',
      serviceName: 'monitor_de_ofertas'
    },
    {
      title: 'Inteligência promocional',
      path: '/inteligência-promocional',
      moduleName: 'relacionamento',
      serviceName: 'inteligencia_promocional'
    }
  ],
  performance: [
    {
      title: 'Visão Geral',
      path: '/dashboard',
      moduleName: 'performance',
      serviceName: 'visao_geral'
    },
    {
      title: 'BI',
      path: '/analise',
      moduleName: 'performance',
      serviceName: 'bi'
    },
    {
      title: 'Sob Demanda',
      path: '/performance',
      moduleName: 'performance',
      serviceName: 'sob_demanda'
    }
  ],

  promocoes: [
    {
      title: 'Recomendações Inteligêntes',
      path: '/recomendacoes',
      moduleName: 'promocoes',
      serviceName: 'recomendacoes_inteligentes'
    },
    {
      title: 'Cupom de Desconto',
      path: '/cupom-desconto',
      moduleName: 'promocoes',
      serviceName: 'cupom_de_desconto'
    }
  ],
  sorteio: [
    {
      title: 'Cupom de Sorteio',
      path: '/cupom-sorteio',
      moduleName: 'relacionamento',
      serviceName: 'cupom_de_sorteio'
    }
  ],
  trade_marketing: [
    {
      title: 'Trade',
      path: '/trade/providers',
      moduleName: 'trade_marketing',
      serviceName: 'trade'
    },
    {
      title: 'Campanhas',
      path: '/trade/campaigns',
      moduleName: 'trade_marketing',
      serviceName: 'trade'
    }
  ],
  whatsapp: [
    {
      title: 'Whatsapp',
      path: '/whatsapp',
      moduleName: 'relacionamento',
      serviceName: 'whatsapp'
    },
    {
      title: 'Contatos',
      path: '/whatsapp/contatos',
      moduleName: 'relacionamento',
      serviceName: 'whatsapp'
    },
    {
      title: 'Chatbot',
      path: '/whatsapp/chatbot',
      moduleName: 'relacionamento',
      serviceName: 'whatsapp'
    }
  ],
  redes_sociais: [
    {
      title: 'Redes sociais',
      path: '/social-networks/accounts',
      moduleName: 'relacionamento',
      serviceName: 'redes_sociais'
    }
  ],
  sites: [
    {
      title: 'Site',
      path: '/sites',
      moduleName: 'relacionamento',
      serviceName: 'sites'
    },
    {
      title: 'Pedidos',
      path: '/delivery',
      moduleName: 'vendas',
      serviceName: 'pedidos'
    },
    {
      title: 'Categorias',
      path: '/categorias-delivery',
      moduleName: 'vendas',
      serviceName: 'categorias'
    },
    {
      title: 'Produtos',
      path: '/produtos-delivery',
      moduleName: 'vendas',
      serviceName: 'produtos'
    }
  ],
  radios: [
    {
      title: 'Radios',
      path: '/radios',
      moduleName: 'relacionamento',
      serviceName: 'radios'
    }
  ],
  chat: [
    {
      title: 'Chat',
      path: '/chat',
      moduleName: 'relacionamento',
      serviceName: 'chat'
    },
    {
      title: 'Visão geral',
      path: '/chat-analytic',
      moduleName: 'relacionamento',
      serviceName: 'chat'
    }
  ],
  intelligence: [
    {
      title: 'performance',
      path: '/performance',
      moduleName: 'inteligencia',
      serviceName: 'performance'
    },
    {
      title: 'analise',
      path: '/analise',
      moduleName: 'inteligencia',
      serviceName: 'analise'
    },
    {
      title: 'recomendacoes',
      path: '/recomendacoes',
      moduleName: 'inteligencia',
      serviceName: 'recomendacoes'
    },
    {
      title: 'trade',
      path: '/trade/providers',
      moduleName: 'crm',
      serviceName: 'trade'
    }
  ]
};
