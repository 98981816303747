import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root:{
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  },
  textFormTest:{
    '& label.Mui-focused': {
      color: '#2813AD !important',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2813AD !important',
      },
    },   
  },
}));

export default useStyles;
