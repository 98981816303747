import React, { Component } from 'react';
import './toggle.css';
export default class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
    };
  }
  changeState = () => {
    const { verifiry } = this.props;
    verifiry
      ? verifiry &&
        this.setState(
          {
            status: !this.state.status,
          },
          () => {
            this.props.fn(this.state.status);
          },
        )
      : this.setState(
          {
            status: !this.state.status,
          },
          () => {
            this.props.fn(this.state.status);
          },
        );
  };
  render() {
    return (
      <label class="switch">
        <input
          type="checkbox"
          onChange={() => this.changeState()}
          checked={this.state.status}
        />
        <span class="slider round" />
      </label>
    );
  }
}
