import React, { Fragment } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import DateCustom from '../../../utils/helpers/Date';

export default ({ data, row }) => (
  <Fragment>
    <ListGroupItem>
      <p>
        <strong>{row.nome}</strong> &nbsp;
        <span style={{ color: 'tomato' }}>{row.nome_loja}</span>
      </p>
      <p>
        <strong>Data de Nascimento: </strong>
        <span>
          {new DateCustom(row.data_nascimento, 'DD/MM/YYYY').getDate()}
        </span>
      </p>
      <p>
        <strong>Idade: </strong>
        <span>{row.idade}</span>
      </p>
    </ListGroupItem>

    {data.length === 0 && (
      <ListGroupItem>
        <section style={{ display: 'flex', justifyContent: 'center' }}>
          <strong>Não possui aniversariantes nesta data.</strong>
        </section>
      </ListGroupItem>
    )}
  </Fragment>
);
