import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const loadingPessoas = () => dispatch => dispatch({ type: "LOADING_PESSOAS" });
const loadingVendas = () => dispatch => dispatch({ type: "LOADING_VENDAS" });

export const getPessoas = data => dispatch => {
  dispatch(loadingPessoas());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getPessoas`,
    data
  ).then(payload => dispatch({ type: "PESSOAS", payload: payload }));
};

export const getVendas = (
  documento = "",
  dataInicio = "",
  dataFim = ""
) => dispatch => {
  dispatch(loadingVendas());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getVendas`,
    { documento, dataInicio, dataFim }
  ).then(payload => dispatch({ type: "VENDAS", payload: payload }));
};

export const savePessoa = pessoa => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/postPessoa`,
    pessoa,
    "POST"
  ).then(() => dispatch(getPessoas()));
};

export const editPessoa = pessoa => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/putPessoa`,
    pessoa,
    "PUT"
  ).then(() => dispatch(getPessoas()));
};

export const setState = (state, value) => dispatch =>
  dispatch({
    type: "setState",
    payload: {
      state,
      value
    }
  });

// export const getConfigSubmenuFilho = idSubmenuPai => dispatch => {
//   dispatch(loadSubmenuFilho());
//   Fetch(
//     `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getConfigSubmenuFilho`,
//     { idSubmenuPai }
//   ).then(payload => {
//     dispatch({ type: "SUBMENU_FILHO", payload });
//   });
// };
