import {
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  avatar: { backgroundColor: theme.palette.primary.main },
  cardActions: {
    justifyContent: 'flex-end',
  },
  gridList: { width: '100%' },
  image: { cursor: 'pointer', objectFit: 'contain' },
  img: {
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
  },
  imageList: {},
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    minHeight: '90vh',
  },
}));

function SelectTemplate(props) {
  const classes = useStyles();

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    id_tabloide: props.id_tabloide,
  });

  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v?.id) : value,
    }));
  }

  async function fetchTemplates(params) {
    try {
      setLoading(true);

      params = params
        ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}`
        : '';

      await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getVideomatik${params}`
      ).then(response => {
        setTemplates(response.templates);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTemplates(query);
  }, [query]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <GridList
          className={classes.gridList}
          cellHeight="auto"
          cols={8} //matches ? 8 : 6
          spacing={8}
        >
          {loading
            ? Array(20)
                .fill(20)
                .map((_, i) => (
                  <GridListTile>
                    <Skeleton
                      className={classes.skeleton}
                      variant="rect"
                      height={180}
                      width="100%"
                    />
                  </GridListTile>
                ))
            : templates.map(item => (
                <GridListTile
                  key={item.id}
                  className={classes.image}
                  onClick={() => {
                    props.onSelect(item);
                  }}
                  cols={item.colunas || 1}
                >
                  <img
                    className={classes.img}
                    src={item.miniatura}
                    alt={item.nome + ' - ' + item.id}
                    title={item.nome + ' - ' + item.id}
                    loading="lazy"
                  />

                  <GridListTileBar title={item.nome + ' - ' + item.id} />
                </GridListTile>
              ))}
        </GridList>
      </Grid>
    </Grid>
  );
}

export default SelectTemplate;
