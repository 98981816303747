import React, { Fragment, useState } from 'react';
import {
  Icon,
  IconButton,
  Popover,
} from '@material-ui/core';

export default function CountCard(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  function showPopover(current) {
    setAnchorEl(current);
  }

  function closePopover() {
    setAnchorEl(null);
  }

  return (    
    <Fragment>
        <IconButton
            onClick={({ currentTarget }) => showPopover(currentTarget)}
            style={{ padding: 0 }}
        >
            <Icon>info_outline</Icon>
        </IconButton>
        <Popover
            id="simple-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
            <section style={{ padding: '1rem', width: '30vw' }}>
            <section
                style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '2vh',
                }}
            >
                <strong>{props.title}</strong>
            </section>
            {props.helpContent}
            </section>
        </Popover>
    </Fragment>
  );
}

