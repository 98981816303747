import React, { Component } from "react";
import { connect } from "react-redux";
import { modalPessoa } from "../actions/actions";
import Filtro from "./Filtro";
import ResultadoInsights from "./ResultadoInsights";

class Insights extends Component {
  render() {
    return (
      <div style={{ flexGrow: 1, backgroundColor: '#eee' }}>
        <Filtro>
          <ResultadoInsights />
        </Filtro>
      </div>
    );
  }
}

const mapStateToProps = ({ InsightsReducer }) => ({
  ...InsightsReducer
});

const mapDispatchToProps = { modalPessoa };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insights);
