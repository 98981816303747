import React from 'react';
import {
  Avatar,
  Box,
  Badge,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { GroupRounded } from '@material-ui/icons';
import { notificationChat } from '../../../utils/helpers/Format';
import { Celular2 } from '../../../utils/helpers/Masks';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(1, 2),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  shortMessage: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '500',
  },
  nameChat: {
    fontSize: '13pt',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '700',
  },
  dateChat: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.45)',
  },
  dateRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  badgeContactInactive: { backgroundColor: '#e53935' },
  badgeContactActive: { backgroundColor: '#43a047' },
  badgeDot: {
    height: 15,
    minWidth: 15,
    borderRadius: 15,
    border: '3px #FFF solid',
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    height: 20,
    minWidth: 20,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fontBadge: { color: '#FFF', fontSize: 11 },
}));

export default function ItemChat({
  index,
  style,
  data,
  chatActive,
  onClick,
  disabled,
}) {
  const classes = useStyles();
  function shortMessage(data) {
    switch (data.TYPE) {
      case 'VIDEO':
        return '📽 Vídeo';
      case 'AUDIO':
        return '🎤 Mensagem de voz';
      case 'IMAGE':
        return '📷 Imagem';
      case 'DOCUMENT':
        return '📄 Documento';
      case 'LOCATION':
        return '📌 Localização';
      case 'STICKER':
        return '💟 Figurinha';
      default:
        return !data.IS_GROUP
          ? data.MESSAGE
          : data.MESSAGE
          ? notificationChat(data.MESSAGE)
          : '';
    }
  }
  return (
    <ListItem
      disabled={disabled}
      className={classes.root}
      onClick={onClick}
      button
      alignItems="flex-start"
      style={{
        backgroundColor: chatActive.NUMBER === data.NUMBER && '#f0f0f0',
      }}
    >
      <ListItemAvatar>
        <Tooltip
          title={Boolean(data.ACTIVE) ? 'Contato ativo' : 'Contato inativo'}
        >
          <Badge
            classes={{
              badge:
                Boolean(data.ACTIVE) || Boolean(data.IS_GROUP)
                  ? classes.badgeContactActive
                  : classes.badgeContactInactive,
              dot: classes.badgeDot,
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            overlap="circle"
            color="primary"
          >
            <Avatar className={classes.avatar}>
              {data.PROFILE_THUMBNAIL ? (
                <img
                  src={data.PROFILE_THUMBNAIL}
                  alt={
                    data.IS_GROUP && !data.NAME
                      ? 'Grupo desconhecido'
                      : data.NAME || Celular2(data.NUMBER)
                  }
                  className={classes.avatar}
                />
              ) : data.IS_GROUP ? (
                <GroupRounded />
              ) : null}
            </Avatar>
          </Badge>
        </Tooltip>
      </ListItemAvatar>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" width="100%">
          <ListItemText
            classes={{ primary: classes.nameChat }}
            primary={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Typography style={{ fontWeight: 700 }}>
                  {data.IS_GROUP && !data.NAME
                    ? 'Grupo desconhecido'
                    : data.NAME || Celular2(data.NUMBER)}
                </Typography>
              </Box>
            }
            secondary={
              <Typography className={classes.message} variant="body2">
                <span className={classes.shortMessage}>
                  {shortMessage(data)}
                </span>
              </Typography>
            }
          />

          <ListItemText
            classes={{
              primary: classes.dateChat,
              root: classes.dateRoot,
            }}
            primary={_myDate.difference(
              data.MESSAGE_DATE,
              'YYYY-MM-DDTHH:mm:ss'
            )}
            secondary={
              parseInt(data.UNREAD, 10) ? (
                <Box className={classes.badge}>
                  <b className={classes.fontBadge}>
                    {parseInt(data.UNREAD, 10)}
                  </b>
                </Box>
              ) : null
            }
          />
        </Box>
        <Box display="flex" width="100%">
          {data.USERNAME && (
            <Box style={{ marginRight: '.5vw' }}>
              <Chip
                label={<b>{data.USERNAME}</b>}
                color="primary"
                size="small"
              />
            </Box>
          )}
          {data.SHORT_TICKET && (
            <Chip
              label={<b>{data.SHORT_TICKET}</b>}
              color="primary"
              size="small"
            />
          )}
        </Box>
      </Box>
    </ListItem>
  );
}

export function ItemChatEmpty({ textEmpty }) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemText
        secondary={
          <Typography align="center" noWrap variant="body2">
            {textEmpty}
          </Typography>
        }
      />
    </ListItem>
  );
}
