import React, { FC, useEffect, useCallback, useState, Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import * as actionsChatbot from '../../../../../Chatbot/actions';

interface Props {
  isInstanceDialogOpen: boolean;
  setInstanceDialogOpen: (isSelected: boolean) => void;
  selectedStore: object | string | null;
  setSelectedStore: Dispatch<SetStateAction<null>>;
  stores: Array<any>;
}

const NumberFormatCustom = React.forwardRef(
  function NumberFormatCusto(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        format={'(##) ## #####-####'}
        placeholder={'(99) 99 99999-9999'}
        type="tel"
        // allowEmptyFormatting
        isNumericString
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  });

export const ModalNewWhatsappInstance: FC<Props> = ({
  isInstanceDialogOpen,
  setInstanceDialogOpen,
  selectedStore,
  setSelectedStore,
  stores,
  ...props
}) => {
  const [storeNumber, setStoreNumber] = useState(null);

  const handleSaveNewInstance = (store) => {
    if (!store) {
      alert('Selecione uma Loja, por favor!');
    } else {
      const objStore = JSON.parse(store);
      console.log(stores);

      props.postInstanciaZapi(objStore)
        .then((response) => {
          // Como retorno da criação de uma instância
          // vem o seu ID
          props.putInstancia({
            id: response.content.instance_id,
            own_number: storeNumber,//objInstance.own_number, //|| storeNumber,
            automatically_activate_contacts: 1,
            ativo: 1
          })
          props.getInstancias()
        });

      setInstanceDialogOpen(false);
      setSelectedStore({});
    }
  }

  return (
    <Dialog
      open={isInstanceDialogOpen}
      onClose={() => {
        setInstanceDialogOpen(false);
        setSelectedStore(null);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Nova Instância'}</DialogTitle>
      <DialogContent
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <FormControl style={{ width: 300 }}>
          <InputLabel id="demo-simple-select-label">Loja</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedStore}
            onChange={event => {
              setSelectedStore(event.target.value);
            }}>
            {stores.map(value => (
              <MenuItem
                value={JSON.stringify({
                  id_loja: value.id_loja,
                  nome: value.nome_loja,
                })}>
                {value.id_loja} - {value.nome_loja}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ marginTop: '5px' }}>
          <TextField
            required
            fullWidth
            name="own_number"
            label="Número de Whatsapp"
            variant="standard"
            onChange={({ target: { value } }) => {
              setStoreNumber(value);
            }}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setInstanceDialogOpen(false);
            setSelectedStore(null);
          }}
          color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            handleSaveNewInstance(selectedStore);
          }}
          color="primary"
          autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const mapStateToProps = ({ ChatbotReducer }) => {
  return { ...ChatbotReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot })(ModalNewWhatsappInstance);

