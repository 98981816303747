import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Button,
  Icon,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import { postCupomCashback } from '../actions/action';
import { getLojas } from '../../Cupom/actions';
import { getCabecalhoProspects } from '../../Campanhas/action/actionCampanha';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { CustomTable, Countup } from '../../../utils/components';
import MyDate from '../../../utils/helpers/Date';
import ModalCupomCashback from './ModalCupomCashback';

const _myDate = new MyDate();

function CriaCupomCashback(props) {
  const { classes } = props;
  const [modalCupomCashback, setCupomCashback] = useState(false);

  useEffect(() => {
    props.getLojas();
    props.getCabecalhoProspects();
  }, []);

  function saveCupomCashback(cash) {
    const objCupomCash = {
      nomeCupomCashback: cash.nomeCupomCashback,
      valorCupomCashback: cash.valorCupomCashback,
      dataInicio: cash.dataInicio,
      dataFim: cash.dataFim,
      lojasCupomCashback: cash.lojasCupomCashback,
      audienciaCupomCashback:
        cash.audienciaCupomCashback &&
        cash.audienciaCupomCashback.idListProspectei,
    };
    props.postCupomCashback(objCupomCash);
    setCupomCashback(false);
  }

  return (
    <div className={classes.root}>
      <Link to="/cupom-cashback" style={{ textDecoration: 'none' }}>
        <Button
          size="small"
          style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
          color="primary"
        >
          <Icon>arrow_back_ios</Icon>Voltar para cupom cashback
        </Button>
      </Link>
      <Typography className={classes.label} variant="h3" noWrap>
        Criar cupom de cashback
      </Typography>
      <div className={classes.actions}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div>
              <Button
                style={{ color: COLOR_PRIMARY }}
                color="primary"
                size="large"
                variant="text"
                onClick={() => setCupomCashback(true)}
              >
                Criar cupom cashback
              </Button>

              <Button
                style={{ color: COLOR_PRIMARY }}
                color="primary"
                size="large"
                variant="text"
                component={Link}
                to="/filtros"
              >
                Criar Filtro
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div
        className={classes.content}
        style={{
          display: 'flex',
          height: '100%',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.isLoaderCupomCashback ? (
          <CircularProgress />
        ) : (
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
            }}
            columns={[
              { title: 'Nome', field: 'nome_cupom_cashback' },
              {
                title: 'Valor do cashback',
                field: 'valor_cupom_cashback',
                render: rowData => (
                  <Countup
                    prefix="R$"
                    start={0}
                    end={rowData.valor_cupom_cashback}
                    duration={1}
                    decimals={2}
                  />
                ),
              },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  _myDate.getDate('DD/MM/YYYY', rowData.data_inicio),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  _myDate.getDate('DD/MM/YYYY', rowData.data_fim),
              },
            ]}
            data={props.cuponsCashbackDetalhe}
          />
        )}
      </div>
      {modalCupomCashback && (
        <ModalCupomCashback
          open={modalCupomCashback}
          onClose={() => setCupomCashback(false)}
          onSave={saveCupomCashback}
        />
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ CupomCashbackReducer }) => ({
  ...CupomCashbackReducer,
});

const mapDispatchToProps = {
  postCupomCashback,
  getLojas,
  getCabecalhoProspects,
};

const CriaCashbackConn = connect(
  mapStateToProps,
  mapDispatchToProps
)(CriaCupomCashback);

export default withStyles(styles)(CriaCashbackConn);
