import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  styled,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from 'utils/helpers/Constants';

import api from '../../../../../utils/helpers/Fetch';

const AutocompleteStylized = styled(Autocomplete)(({ theme }) => ({
  '& .MuiTextField-root': {
    padding: 0,
    borderColor: 'none',
    color: '#B0B0B0',
    width: '100%',
    margin: '0 0 24px 0'
  },

  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    margin: '5px 0',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.05em'
  },

  '& .MuiButtonBase-root, .MuiChip-root': {
    maxHeight: '25px'
  },

  '& .MuiChip-deleteIcon': {
    maxHeight: '20px'
  },

  '& .Mui-focused': {
    borderColor: 'none',
    background: '#FFF',
    marginBottom: '24px',
    zIndex: 4,
    maxHeight: 'auto'
  },

  '& .MuiFormLabel-root.Mui-focused': {
    zIndex: 5
  }
}));

const FilterTags = ({ tagsSelected, setTagsSelected, fetchTemplates }) => {
  const [tags, setTags] = useState([]);

  async function fetchTags() {
    try {
      await api(
        `https://1fqz7brbah.execute-api.us-east-1.amazonaws.com/prd/findAllTagsSelect?formatId=30`
      ).then(response => {
        setTags(response.content);
      });
      // await api(
      //   `https://api-backoffice.datasales.info/studio-${AMBIENTE}/findAllTagsSelect?formatId=30`
      // ).then(response => {
      //   setTags(response.content);
      // });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      <AutocompleteStylized
        value={tagsSelected}
        options={tags}
        getOptionValue={option => option}
        getOptionLabel={option => option}
        filterSelectedOptions={true}
        // limitTags={1}
        fullWidth
        multiple
        freeSolo
        onChange={(e, values: any) => {
          setTagsSelected(values);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
        }
        renderInput={params => (
          <TextField
            {...params}
            size={'small'}
            color="info"
            variant={'outlined'}
            label="Tags"
            placeholder="Selecione uma ou mais tags"
            InputProps={{
              ...params.InputProps,
              classes: {},
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => fetchTemplates()}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              )
            }}
          />
        )}
      />
    </>
  );
};

const TemplateCard = ({
  template,
  setTemplateSelected
}: {
  template: any;
  setTemplateSelected: any;
}) => {
  const [templateMouseOver, setTemplateMouseOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <VisibilityIcon
        sx={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer', color: '#2813AD' }}
        onMouseEnter={(e: any) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <img
        src={template.thumbnail || template.imageURL}
        alt=""
        style={{
          width: '100%',
          cursor: 'pointer',
          padding: 8,
          ...(!!templateMouseOver
            ? {
                filter: 'brightness(0.5)',
                backgroundColor: 'white',
                borderRadius: 10
              }
            : {})
        }}
        onClick={() => {
          setTemplateSelected(template);
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <img
          src={template.thumbnail || template.imageURL}
          alt=""
          style={{
            width: '100%',
            cursor: 'pointer',
            borderRadius: 10,
            border: '2px solid #2813AD',
            padding: 12
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
          onClick={() => {
            setTemplateSelected(template);
          }}
        />
      </Menu>
    </div>
  );
};

export function RodapePersonalizedDialog({ setRodapeSelected, storeSelected }: any) {
  const { enqueueSnackbar } = useSnackbar();

  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);

  async function fetchTemplates(params: any) {
    try {
      setLoadingTemplates(true);

      params = params
        ? `?${queryString.stringify({ ...params, tags: tagsSelected }, { arrayFormat: 'comma' })}`
        : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/templates${params}`).then(response => {
        setTemplates(response?.content);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTemplates(false);
    }
  }

  useEffect(() => {
    fetchTemplates({ formats: [30] });
  }, []);

  async function handleSubmit() {
    try {
      setIsSubmitting(true);

      let template = templateSelected;

      const company_data = {
        logo_url: storeSelected?.logo_url ? storeSelected.logo_url : null,
        description: storeSelected?.description ? storeSelected.description : null,
        address: storeSelected?.street_address ? storeSelected.street_address : null,
        phone_number: storeSelected?.phone_number ? storeSelected.phone_number : null
      };

      const tmp: { outArray: [] } = await api(
        'https://api.datasales.info/flyers-lambda-prd/processor',
        {
          byGoogleCloud: true,
          gerarSomenteJson: 'gerarJsonPuro',
          designURL: template.designURL,
          company_data
          // colors: appliedColor
          //   ? {
          //       colorText,
          //       colorPrice
          //     }
          //   : {}
        },
        'POST',
        undefined,
        undefined,
        false
      );

      setIsSubmitting(false);
      setTemplateSelected(null);

      setRodapeSelected(
        tmp.outArray.map(el => ({
          ...el,
          design_url: {
            ...el.design_url,
            fonts: []
          }
        }))
      );
    } catch (err: any) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });

      setIsSubmitting(false);
      setTemplateSelected(null);
    }
  }

  useEffect(() => {
    if (templateSelected) {
      handleSubmit();
    }
  }, [templateSelected]);

  return (
    <>
      <div>
        <>
          {loadingTemplates || isSubmitting ? (
            <>carregando...</>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 24,
                  marginBottom: 24
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                  <div>Selecione um template</div>
                </div>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    setRodapeSelected(null);
                  }}
                >
                  Limpar
                </Button>
              </div>

              <div>
                <FilterTags
                  tagsSelected={tagsSelected}
                  setTagsSelected={setTagsSelected}
                  fetchTemplates={() => fetchTemplates({ formats: [30] })}
                />
              </div>

              <Grid container spacing={2}>
                {templates?.map((template: any) => (
                  <Grid key={template.id} item xs={12}>
                    <TemplateCard template={template} setTemplateSelected={setTemplateSelected} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      </div>
    </>
  );
}

