import { Fade, LinearProgress } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import LogoDsMkt from '../../../../assets/preload/logo-marketing.png';
import { setShowBtnMenu } from '../../../../modules/Login/actions/actionLogin';
import api from '../../../../utils/helpers/Fetch';
import routes from '../Drawer/routes';
import useStyles from './styles';

function Preload(props) {
  const user = jwt_decode(`${JSON.parse(localStorage.getItem('token'))}`),
    currentSettings = JSON.parse(localStorage.getItem('currentSettings')),
    currentModule = JSON.parse(localStorage.getItem('currentModule')),
    classes = useStyles(),
    location = useLocation(),
    params = useParams(),
    history = useHistory(),
    [accessModule, setAccessModule] = useState(true),
    [permissionsDefault] = useState([{ serviceName: 'blog' }, { serviceName: 'account' }]);

  const image = useMemo(() => {
    if (window.location.hostname.includes('redeminipreco')) {
      return 'https://datasalesio-imagens.s3.amazonaws.com/LogoMiniPreco.png';
    } else {
      return LogoDsMkt;
    }
  }, []);

  const userById = useQuery('user', async () => {
    const response = await api(
      `https://kldlktujc6.execute-api.us-east-1.amazonaws.com/prd/users/${user.user_id}`
    );

    localStorage.setItem('permissions', JSON.stringify(response.permissions));
    localStorage.setItem('userId', JSON.stringify(user.user_id));
    localStorage.setItem('companyBasePath', response?.company?.companyBasePath || '');
    localStorage.setItem('segmentId', response?.company?.ID_SEGMENT || '');
    findService(response.permissions);
    return response;
  });

  useEffect(() => {
    if (!Boolean(accessModule)) {
      history.replace('/without-access');
    }
  }, [accessModule]);

  useEffect(() => {
    if (!Boolean(currentSettings) && !Boolean(currentModule)) {
      props.path === 'main' ? history.replace('/main') : history.replace('/home');
    }
  }, [currentSettings, currentModule]);

  function redirectPathPermission(flatPermissions) {
    if (Boolean(props.currentModule)) {
      const currentRoutes = routes[props.currentModule].filter(route =>
        flatPermissions.find(permission => permission.serviceName === route.serviceName)
      );

      if (Boolean(currentRoutes.length)) {
        props.setShowBtnMenu(currentRoutes.length > 1);
        let redirectPath = '';

        const findRoute = currentRoutes.find(
          currentRoute => currentRoute.path === location.pathname
        );

        if (!location.search) {
          if (Boolean(findRoute)) {
            redirectPath = findRoute.path;
          } else {
            redirectPath = currentRoutes[0].path;
          }

          history.replace(redirectPath);
        }
      } else {
        setAccessModule(false);
      }
    } else if (Boolean(props.currentSettings)) {
      props.setShowBtnMenu(false);
    }
  }

  function findService(permissions = {}) {
    const isInterno =
      location.pathname.includes('edit-templates') || location.pathname.includes('edit-etiquetas');

    if (!isInterno) {
      const lengthParams = Object.keys(params).length,
        flatPermissions = permissionsDefault.concat(Object.values(permissions).flat()),
        services = flatPermissions.filter(
          service => service.serviceName && props.currentServices.includes(service.serviceName)
        );

      setAccessModule(Boolean(services.length));
      if (!Boolean(lengthParams)) {
        redirectPathPermission(flatPermissions);
      }
    }
  }

  if (Boolean(userById.isFetching)) {
    return (
      <Fade in={userById.isFetching}>
        <div className={classes.root}>
          <img className={classes.logo} src={image} alt="logo" />
          <LinearProgress className={classes.progress} />
        </div>
      </Fade>
    );
  } else {
    return props.children;
  }
}

const mapStateToProps = ({ loginReducer }) => ({ ...loginReducer }),
  mapDispatchToProps = { setShowBtnMenu };

export default connect(mapStateToProps, mapDispatchToProps)(Preload);

