/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  getProdutos, 
  putProduto, 
  getFamalias, 
  postProdutoImagemTabloide 
} from '../actions/index';
import {
  Button,
  Icon,
  withStyles,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Divider
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CustomTable, AutoCompleteLab, Checkbox } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { CPF } from '../../../utils/helpers/Masks';
import { toBase64 } from '../../../utils/helpers/Functions';
import moment from "moment";

const base64_default = 'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png';

class BancoImagens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      familia: {},
      query: {
        familia: null,
        codProduto: null,
        nomeProduto: null,
        emOferta: '',
        principal: null,
        data: null,
        noPhoto: null
      },
      imageEdit: {
        base64: base64_default,
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
  }
  componentDidMount() {
    this.props.getProdutos(this.state.query);
    this.props.getFamalias();
  }

  onInputChange(state, value) {
    this.setState({ query: { ...this.state.query, [state]: value } });
  }

  filterData(type) {
    const { nome, documento } = this.state;
    if (type === 'cpf' && CPF.length > 0) {
      this.props.getPessoas({ documento });
    } else if (type === 'nome' && nome.length > 0) {
      this.props.getPessoas({ nome });
    } else {
      this.props.getPessoas();
    }
  }

  render() {
    const { classes, loadingFamilias } = this.props;
    const { familia, query } = this.state;

    return (
      <div className={classes.root}>
        <div 
          className={classes.actions}
          style={
            this.props.fromOfertas && 
            {display: 'none'}
          }
        >
          <Typography className={classes.margin} variant="h3" noWrap>
            Banco de Imagens
          </Typography>
        </div>
        <div 
          style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            marginBottom: 40,
            marginTop: 50,
          }}
        >                  
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              variant="outlined"
              margin="none"
              style={{ marginRight: 20 }}
              size="md"
              placeholder="Nome do produto..."
              autoComplete="false"
              value={query.nomeProduto}
              onChange={event =>
                this.onInputChange('nomeProduto', event.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.props.getProdutos(query)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="none"
              style={{
                marginRight: 20,
                '&:focus': { borderColor: COLOR_PRIMARY },
              }}
              size="md"
              placeholder="Código do produto..."
              autoComplete="false"
              value={query.codProduto}
              onChange={event =>
                this.onInputChange('codProduto', event.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.props.getProdutos(query)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <AutoCompleteLab
              disabled={loadingFamilias}
              groupLabel="label"
              label="Categorias"
              placeholder="Selecionar categoria..."
              loading={loadingFamilias}
              value={familia}
              getOptionSelected={(option, value) =>
                option.id_familia === value.id_familia
              }
              getOptionLabel={option => option.label}
              onChange={(event, value) => {
                this.props.getProdutos({
                  ...query,
                  familia: value ? value.id_familia : null,
                });
                this.setState({
                  familia: value,
                  query: { ...query, 
                    familia: value ? value.id_familia : null },
                });
              }}
              options={this.props.familias}
            />
            <div style={{ marginLeft: 16 }}>
              <Checkbox                
                onChange={() => {
                  this.props.getProdutos({
                    ...query,
                    noPhoto: event.target.checked ? 1 : null,
                    data: moment().format('YYYY-MM-DD HH:mm:ss')
                  });
                  this.setState({
                    query: {
                      ...query,
                      noPhoto: event.target.checked ? 1 : null,
                      data: moment().format('YYYY-MM-DD HH:mm:ss')
                    }                  
                  });
                }}
                label="Sem Foto"
              />
            </div>
            <Checkbox                
              onChange={() => {
                this.props.getProdutos({
                  ...query,
                  emOferta: event.target.checked ? 1 : null,
                  data: moment().format('YYYY-MM-DD HH:mm:ss')
                });
                this.setState({
                  query: {
                    ...query,
                    emOferta: event.target.checked ? 1 : null,
                    data: moment().format('YYYY-MM-DD HH:mm:ss')
                  }                  
                });
              }}
              label="Em Oferta"
            />
            <Checkbox
              onChange={() => {
                this.props.getProdutos({
                  ...query,
                  principal: event.target.checked ? 1 : null,
                  data: moment().format('YYYY-MM-DD HH:mm:ss')
                });
                this.setState({
                  query: {
                    ...query,
                    principal: event.target.checked ? 1 : null,
                    data: moment().format('YYYY-MM-DD HH:mm:ss')
                  } 
                });
              }}
              label="Principal"
              disabled={!this.state.query.emOferta}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          <CustomTable
            data={this.props.produtos}
            title=""
            pageSize={10}
            paging
            isLoading={this.props.loadingProdutos}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
            }}
            columns={[
              {
                field: 'imagem_produto',
                Title: 'Foto do produto',

                editComponent: props => (
                  <section
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        props.rowData.imagem_produto
                        ? props.rowData.imagem_produto
                        : props.rowData.LINK_S3 ||
                          props.rowData.imagem_produto ||
                          this.state.imageEdit.base64                        
                      }
                      style={{ width: 100, cursor: 'pointer' }}
                    />
                    <div style={{ marginTop: '2vh' }}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        disabled={false}
                        onChange={async e => {
                          const imageEdit = await toBase64(e.target.files[0]);
                          props.onChange(imageEdit.base64);
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          size="small"
                          component="span"
                          disabled={false}
                        >
                          Escolher foto
                        </Button>
                      </label>
                    </div>
                  </section>
                ),
                render: rowData => (
                  <img
                    src={rowData.LINK_S3 || this.state.imageEdit.base64}
                    style={{ width: 75, height: 75, cursor: 'pointer' }}
                  />
                ),
              },
              { title: 'EAN', field: 'SEQEAN', editable: 'never' },
              { title: 'Codigo', field: 'SEQPRODUTO', editable: 'never' },
              { title: 'Produto', field: 'PRODUTO', editable: 'never'  },
              { title: 'Familia', field: 'FAMILIA', editable: 'never' },
              {
                title: 'Gramatura',
                field: 'PROD_PESOLIQ',
                editable: 'never',
                render: rowData =>
                  `${rowData.PROD_PESOLIQ} ${rowData.PROD_EMB}`,
              },
            ]}
            deleteText="Deseja desativar esse produto para o tabloide ?"
            editable={{
              onRowUpdate: (newData, oldData, index) =>
                new Promise((resolve, reject) => {
                  this.props.postProdutoImagemTabloide({
                    imagem_produto: newData.imagem_produto,
                    id_produto: newData.SEQPRODUTO,
                    ean: newData.SEQEAN
                  });
                  resolve();
                  this.forceUpdate();
                }),
            }}
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    paddingTop: '1rem',
    height: '100%',
  },
  subList: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  section: { display: 'flex' },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({ ConfigReducer }) => ({
  ...ConfigReducer,
});

const mapDispatchToProps = {
  getProdutos,
  getFamalias,
  putProduto,
  postProdutoImagemTabloide
};

const BancoImagensConnect = connect(mapStateToProps, mapDispatchToProps)(BancoImagens);

export default withStyles(styles)(BancoImagensConnect);
