import { LOAD_USERS } from '../types';

const INITIAL_STATE = {
  statusMessage: 'waiting',
  filters: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, users: action.payload };
    default:
      return state;
  }
};
