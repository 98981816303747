import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";
import Fetch from "../../../utils/helpers/Fetch";

const loadingCashback = () => dispatch =>
  dispatch({ type: "LOADING_CASHBACK", payload: true });

export const getConfigCashback = () => async dispatch => {
  dispatch(loadingCashback());
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getConfigCashback`
    );
    dispatch({ type: "CONFIG_CASHBACK", payload });
  } catch (err) {
    console.log(err);
  }
};

export const salvaConfigCashback = cashback => async dispatch => {
  dispatch(loadingCashback());
  try {
    await Fetch(
      `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/postConfigCashback`,
      cashback,
      "POST"
    );
    dispatch(getConfigCashback());
  } catch (err) {
    console.log(err);
  }
};

export const changeCashback = (key, value) => dispatch =>
  dispatch({ type: "CHANGE_CASHBACK", payload: { key, value } });
