import React, { Component } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  // Cell,
  XAxis,
  YAxis,
} from 'recharts';
import './style.css';

export default class App extends Component {
  render() {
    return (
      <div className="container">
        <ResponsiveContainer>
          <BarChart
            data={this.props.data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={this.props.xDataKey}
              type="category"
              axisLine={true}
              tickLine={true}
              hide={this.props.hide}
            />
            <YAxis />
            <Tooltip
              payload={this.props.payload}
              contentStyle={{ borderRadius: 15 }}
              formatter={(value, name, props) => [
                value,
                this.props.legend ? this.props.legend : 'Quantidade de vendas',
              ]}
            />
            {!this.props.noLegend && (
              <Legend
                iconSize={20}
                iconType="circle"
                margin={{ top: 20 }}
                formatter={() => <span>{this.props.legend}</span>}
              />
            )}
            <Bar
              dataKey={this.props.barDataKey}
              stackId="a"
              fill="#42a5f5"
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
