import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CampaignDetails from './components/CampaignDetails';
import useStyles from './styles';

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

function Campaigns() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [providers, setProviders] = useState([]);
  const [providerId, setProviderId] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(21);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment());

  async function getProviders() {
    try {
      const response = await api(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/providers`
      );

      setProviders(response);
    } catch (err) {}
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api(
          `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaigns`,
          {
            providerId,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
          }
        );

        setCampaigns(response);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [providerId, startDate, endDate]);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleOpen = id => {
    setCampaignId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setCampaignId(undefined);
    setOpen(false);
  };

  const handleChangeProviderId = id => {
    setProviderId(id);
  };

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <React.Fragment>
      <Box p={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box paddingY={4}>
              <Typography variant="h3" noWrap>
                Campanhas
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Autocomplete
                options={providers}
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  handleChangeProviderId(value ? value.id : undefined);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Forncedores"
                    placeholder="Selecione um ou mais fornecedores"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="De"
                format="DD/MM/YYYY"
                animateYearScrolling
                inputVariant="outlined"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Até"
                format="DD/MM/YYYY"
                animateYearScrolling
                inputVariant="outlined"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Box pt={4} pb={2}>
              <Divider />
            </Box>
          </Grid>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={40} color="primary" />
            </div>
          )}
          {campaigns.map(campaign => (
            <Grid item>
              <Card className={classes.card}>
                <CardHeader
                  title={campaign.name}
                  subheader={`${formatDate(
                    campaign.start_date
                  )} ate ${formatDate(campaign.end_date)}`}
                />
                <CardContent></CardContent>
                <Divider />
                <CardActions className={classes.cardActions}>
                  <Button
                    onClick={() => {
                      handleOpen(campaign.id);
                    }}
                  >
                    detalhes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {open && (
        <CampaignDetails
          open={open}
          campaignId={campaignId}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  );
}

export default Campaigns;
