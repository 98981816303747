import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ViewQuilt as ViewQuiltIcon,
  Store as StoreIcon,
  Today as TodayIcon,
  QueryBuilder as QueryBuilderIcon,
  Wc as WcIcon,
  Group as GroupIcon,
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BarChart, CountCard, DatePicker, PieChart, Select } from '../../../utils/components';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import { getCabecalhoProspects } from '../../Campanhas/action/actionCampanha';
import {
  getEditOferta,
  getLojas,
  getOfertas,
  getOfertasCompletas,
  getOfertasNaoCompletas,
  getPerformanceOfertas,
  getPessoasByOferta,
  getVendasOfertaDia,
  getVendasOfertaHora,
  getVendasOfertaLoja,
} from '../actions/index';
import ModalContinuarOferta from './ModalContinuarOferta';
import useStyles from '../styles';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

function Overview(props) {
  const classes = props.classes,
    overview = props.overview || {};

  return (
    <Grid item container spacing={2} direction="column">
      <Grid item xs>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <ViewQuiltIcon />
              </Avatar>
            }
            title="Visão geral"
            subheader={`${_myDate.getDate('DD/MM/YYYY', props.initDate)} até ${_myDate.getDate(
              'DD/MM/YYYY',
              props.endDate
            )}`}
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <CountCard
                  count
                  loading={props.loading}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={overview.qtde_vendas}
                  title="Vendas"
                  icon="score"
                />
              </Grid>
              <Grid item xs={2}>
                <CountCard
                  count
                  loading={props.loading}
                  titleTypographyProps={{ variant: 'h6' }}
                  decimals={2}
                  end={overview.faturamento}
                  prefix="R$ "
                  title="Faturamento"
                  icon="add_shopping_cart"
                />
              </Grid>
              <Grid item xs={2}>
                <CountCard
                  count
                  loading={props.loading}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={overview.investimento}
                  decimals={2}
                  prefix="R$ "
                  title="Investimento"
                  icon="build"
                />
              </Grid>
              <Grid item xs={2}>
                <CountCard
                  count
                  helpButton
                  helpContent={
                    <Box display="flex" flexDirection="column">
                      <Typography>R$ {MoedaBrasil(overview.roi || 0)}</Typography>
                      <Typography>{MoedaBrasil(overview.roi_perc)}%</Typography>
                    </Box>
                  }
                  prefix="R$ "
                  end={overview.roi ? parseFloat(overview.roi) : 0}
                  loading={props.loading}
                  titleTypographyProps={{ variant: 'h6' }}
                  title="ROI"
                  decimals={2}
                  icon="attach_money"
                />
              </Grid>
              <Grid item xs={2}>
                <CountCard
                  count
                  loading={props.loading}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={overview.ticket_medio}
                  decimals={2}
                  prefix="R$ "
                  title="Ticket médio"
                  icon="attach_money"
                />
              </Grid>
              <Grid item xs={2}>
                <CountCard
                  count
                  loading={props.loading}
                  helpButton
                  helpContent={
                    <Box display="flex" flexDirection="column">
                      <Typography>R$ {MoedaBrasil(overview.margem || 0)}</Typography>
                      <Typography>{MoedaBrasil(overview.margem_percentual || 0)}%</Typography>
                    </Box>
                  }
                  end={overview.margem_percentual ? parseFloat(overview.margem_percentual) : 0}
                  suffix="%"
                  icon="trending_up"
                  titleTypographyProps={{ variant: 'h6' }}
                  title="Margem"
                  decimals={2}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <StoreIcon />
              </Avatar>
            }
            title="Lojas"
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <BarChart data={props.stores} barDataKey="vendas" xDataKey="nome_loja" barLegend />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <TodayIcon />
              </Avatar>
            }
            title="Dia"
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <BarChart data={props.days} barDataKey="vendas" xDataKey="DATA_ANALISE" barLegend />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <QueryBuilderIcon />
              </Avatar>
            }
            title="Hora"
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <BarChart data={props.hours} barDataKey="vendas" xDataKey="horario_analise" barLegend />
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="rounded">
                  <WcIcon />
                </Avatar>
              }
              title="Gênero"
            />
            <Divider className={classes.divider} />
            <CardContent className={classes.content}>
              <PieChart data={props.genre} nameKey="name" dataKey="value" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="rounded">
                  <GroupIcon />
                </Avatar>
              }
              title="Faixa etária"
            />
            <Divider className={classes.divider} />
            <CardContent className={classes.content}>
              <PieChart data={[]} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Offers(props) {
  const classes = props.classes,
    [page, setPage] = useState(1);
  return (
    <Grid item container spacing={2} direction="column">
      <Grid style={{ display: 'flex' }} item xs justify="center">
        <Pagination
          count={Math.ceil(props.offers.length / 10)}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </Grid>
      {props.offers.map(
        (offer, i) =>
          i < page * 10 &&
          i >= page * 10 - 10 && (
            <Grid key={i} item xs>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar} variant="rounded">
                      <ShoppingCartIcon />
                    </Avatar>
                  }
                  title={`${offer.id_oferta} - ${offer.nome_oferta}`}
                  subheader={`${_myDate.getDate(
                    'DD/MM/YYYY',
                    offer.data_inicio
                  )} até ${_myDate.getDate('DD/MM/YYYY', offer.data_fim)}`}
                  action={
                    <Box>
                      <Button
                        size="large"
                        className={classes.button}
                        variant="text"
                        color="primary"
                        disabled={
                          offer.tipo_oferta === 'cupom'
                            ? true
                            : offer.nome_oferta !== null
                            ? offer.nome_oferta.includes('Processando')
                              ? true
                              : false
                            : true
                        }
                        component={Link}
                        to={`/detalhes-oferta/${offer.id_oferta}_${offer.nome_oferta}`}>
                        Detalhes
                      </Button>
                      <Button
                        size="large"
                        className={classes.button}
                        variant="text"
                        color="primary"
                        component={Link}
                        disabled={offer.status === 0 || !offer.id_oferta ? true : false}
                        onClick={() => props.onEdit(offer)}>
                        Editar
                      </Button>
                    </Box>
                  }
                />
                <Divider className={classes.divider} />
                <CardContent className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={offer.qtde_vendas || 0}
                        title="Vendas"
                        icon="score"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        decimals={2}
                        end={offer.faturamento}
                        prefix="R$ "
                        title="Faturamento"
                        icon="add_shopping_cart"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={offer.investimento}
                        decimals={2}
                        prefix="R$ "
                        title="Investimento"
                        icon="build"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        helpButton
                        helpContent={
                          <Box display="flex" flexDirection="column">
                            <Typography>R$ {MoedaBrasil(offer.roi || 0)}</Typography>
                            <Typography>{MoedaBrasil(offer.roi_perc)}%</Typography>
                          </Box>
                        }
                        prefix="R$ "
                        end={offer.roi ? parseFloat(offer.roi) : 0}
                        titleTypographyProps={{ variant: 'h6' }}
                        title="ROI"
                        decimals={2}
                        icon="attach_money"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={offer.ticket_medio}
                        decimals={2}
                        prefix="R$ "
                        title="Ticket médio"
                        icon="attach_money"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CountCard
                        count
                        helpButton
                        helpContent={
                          <Box display="flex" flexDirection="column">
                            <Typography>R$ {MoedaBrasil(offer.margem || 0)}</Typography>
                            <Typography>{MoedaBrasil(offer.margem_percentual || 0)}%</Typography>
                          </Box>
                        }
                        end={offer.margem_percentual ? parseFloat(offer.margem_percentual) : 0}
                        suffix="%"
                        icon="trending_up"
                        titleTypographyProps={{ variant: 'h6' }}
                        title="Margem"
                        decimals={2}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
      )}
      <Grid style={{ display: 'flex' }} item xs justify="center">
        <Pagination
          count={Math.ceil(props.offers.length / 10)}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </Grid>
    </Grid>
  );
}

function Ofertas(props) {
  const classes = useStyles(),
    [key, setKey] = useState(0),
    [offerName, setOfferName] = useState(''),
    [initDate, setInitDate] = useState(_myDate.subtractDate('month', 1)),
    [endDate, setEndDate] = useState(_myDate.addDate('day', 1)),
    [store, setStore] = useState(null),
    // [personalize, setPersonalize] = useState(null),
    [modalEdit, setModalEdit] = useState(false);

  function getOfertasDados(attr = {}) {
    const request = {
      status: 1,
      complete: 1,
      dataInicio: _myDate.getDate('YYYY-MM-DD', initDate),
      dataFim: _myDate.getDate('YYYY-MM-DD', endDate),
      idLoja: typeof store === 'object' ? 0 : store.value,
      ...attr,
    };

    props.getOfertasCompletas(request);
    props.getPerformanceOfertas(request);
    props.getOfertasNaoCompletas(request);
    props.getVendasOfertaHora(request);
    props.getVendasOfertaDia(request);
    props.getVendasOfertaLoja(request);
  }

  useEffect(() => {
    props.getLojas();
    props.getCabecalhoProspects();
    getOfertasDados();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box pt={2} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" noWrap>
                Ofertas
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                size="lg"
                placeholder="Pesquisar oferta..."
                autoComplete="false"
                value={offerName}
                onChange={({ target: { value } }) => setOfferName(value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          getOfertasDados({
                            nomeOferta: offerName,
                          })
                        }>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por período
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <DatePicker
                    label="De"
                    disableFuture
                    withKeyboard
                    value={initDate}
                    format="DD/MM/YYYY"
                    onChange={date => {
                      setInitDate(date);
                      getOfertasDados({
                        dataInicio: _myDate.getDate('YYYY-MM-DD', date),
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    value={endDate}
                    label="Até"
                    withKeyboard
                    format="DD/MM/YYYY"
                    onChange={date => {
                      setEndDate(date);
                      getOfertasDados({
                        dataFim: _myDate.getDate('YYYY-MM-DD', date),
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por lojas
              </Typography>
              <Select
                label="Lojas"
                options={props.lojas}
                value={store}
                onChange={data => {
                  setStore(data);
                  getOfertasDados({
                    idLoja: !data ? 0 : data.value,
                  });
                }}
              />
            </Grid>
            <Grid style={{ display: 'flex' }} alignItems="flex-end" justify="flex-end" item xs>
              <Button
                size="large"
                variant="contained"
                color="primary"
                component={Link}
                to="/criar-oferta">
                Criar oferta
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                color="inherit"
                size="large"
                className={key === 0 && classes.activeButton}
                onClick={() => setKey(0)}>
                Visão geral
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                size="large"
                className={key === 1 && classes.activeButton}
                onClick={() => setKey(1)}>
                Ofertas
              </Button>
            </Grid>
          </Grid>
          {key === 0 && (
            <Overview
              classes={classes}
              overview={props.ofertas.visaoGeral[0]}
              days={props.ofertas.vendasDia}
              hours={props.ofertas.vendasHora}
              stores={props.ofertas.vendasLoja}
              genre={props.ofertas.genero}
              loading={props.isLoadingPerformanceOfertas}
              initDate={initDate}
              endDate={endDate}
            />
          )}
          {key === 1 && (
            <Offers
              classes={classes}
              offers={props.ofertas.completas}
              onEdit={offer => {
                props.getEditOferta(offer.id_oferta);
                // setPersonalize(Boolean(offer.id_lista_prospeccao));
                setModalEdit(true);
              }}
            />
          )}
        </Grid>
      </div>
      <ModalContinuarOferta
        open={modalEdit}
        //  onChange={(state, value) => this.onChangeOferta(state, value)}
        onClose={() => setModalEdit(false)}
        tipoOferta={props.ofertaCabecalho.tipo_oferta}
        oferta={props.ofertaCabecalho}
        personalizada={!!props.ofertaCabecalho.id_lista_prospeccao}
        one2one={!!props.ofertaCabecalho.one2one}
      />
    </div>
  );
}

const mapStateToProps = ({ OfertasReducer }) => ({ ...OfertasReducer }),
  mapDispatchToProps = {
    getOfertas,
    getOfertasCompletas,
    getPerformanceOfertas,
    getOfertasNaoCompletas,
    getVendasOfertaHora,
    getVendasOfertaDia,
    getVendasOfertaLoja,
    getLojas,
    getPessoasByOferta,
    getEditOferta,
    getCabecalhoProspects,
  };

export default connect(mapStateToProps, mapDispatchToProps)(Ofertas);
