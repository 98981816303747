import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, CustomTable, Countup } from '../../../utils/components';
import { getCestaProduto } from '../action/action';

function ModalCestaProduto(props) {
  useEffect(() => {
    const objCestaProduto = {
      id_campanha: props.produto.ID_CAMPANHA,
      id_tabloide: props.produto.id_tabloide_digital,
      status_envio: props.produto.STATUS_ENVIO,
      seq_produto: props.produto.CODIGO,
    };
    props.getCestaProduto(objCestaProduto);
  }, []);
  return (
    <Modal
      onClose={props.onClose}
      header={`Cesta do produto ${props.produto.NOME_PRODUTO}`}
      showModal={props.showModal}
    >
      <CustomTable
        data={props.cestaProduto}
        isLoading={props.loadingPerformance}
        title=""
        paging
        headerStyle={{ zIndex: 0 }}
        style={{
          border: 'none',
          boxShadow: 'none',
          width: '100%',
        }}
        columns={[
          { title: 'Cod Produto', field: 'CODIGO' },
          { title: 'Produto', field: 'NOME_PRODUTO' },
          {
            title: 'Quantidade de Vendas',
            field: 'QUANTIDADE',
            render: rowData => <Countup start={0} end={rowData.QUANTIDADE} />,
          },
          {
            title: 'Valor Médio',
            field: 'VALOR_UNITARIO_MEDIO',
            render: rowData => (
              <Countup
                start={0}
                end={rowData.VALOR_UNITARIO_MEDIO}
                decimals={2}
                prefix="R$ "
              />
            ),
          },
          {
            title: 'Faturamento',
            field: 'TOTAL',
            render: rowData => (
              <Countup
                start={0}
                end={rowData.TOTAL}
                prefix="R$ "
                decimals={2}
              />
            ),
          },

          {
            title: 'Margem R$',
            field: 'MARGEM',
            render: rowData => (
              <Countup
                start={0}
                end={rowData.MARGEM}
                prefix="R$ "
                decimals={2}
              />
            ),
          },
          {
            title: 'Margem %',
            field: 'MARGEM_PERCENTUAL',
            render: rowData => (
              <Countup
                start={0}
                end={rowData.MARGEM_PERCENTUAL}
                suffix="%"
                decimals={2}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}

const mapStateToProps = ({ PerformanceReducer }) => ({ ...PerformanceReducer });

const mapDispatchToProps = { getCestaProduto };

export default connect(mapStateToProps, mapDispatchToProps)(ModalCestaProduto);
