/* eslint-disable */
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CPF, Celular, CNPJ } from '../../../utils/helpers/Masks';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import {
  Divider,
  Dialog,
  withStyles,
  IconButton,
  Typography,
  Slide,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import {
  CountCard,
  CustomTable,
  TextInput,
  MyButton,
} from '../../../utils/components';
import {
  getPersonProfile,
  getRetailByIdPerson,
  getRetailFamilyByIdPerson,
  getRetailConditionByIdPerson,
  getRetailGroupByIdPerson,
  getCamapanhaSMSByIdPerson,
  getRetencaoByIdPerson,
  getStatusByIdPerson,
  onSetSavePerson,
  putPerson,
  deletePeople,
} from '../../Pessoas/actions/actionsPeople';
import ModalJustificativa from './ModalJustificativa';
import ModalEditPessoa from '../../Pessoas/components/Pessoas/ModalAddPessoa';
import '../style/modal.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dadosPessoais: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'center',
  },
});

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, onDelete, onEdit }) => (
    <MuiDialogTitle
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      disableTypography
      className={classes.root}
    >
      <Typography variant="h6">{children}</Typography>
      <section>
        <IconButton color="primary" aria-label="close" onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" aria-label="close" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </section>
    </MuiDialogTitle>
  )
);

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ModalPerfil(props) {
  const history = useHistory();
  const [modalJustificativa, setModalJustificativa] = useState(false);
  const [modalEditPessoa, setModalEditPessoa] = useState(false);
  const [justificativa, setJustificativa] = useState('');
  const [newValues, setNewValues] = useState({});
  const [empresa, setEmpresa] = useState({});

  useEffect(() => {
    props.getPersonProfile(props.pessoa.id_pessoa);
    props.getRetailByIdPerson(props.pessoa.id_pessoa);
    props.getRetailFamilyByIdPerson(props.pessoa.id_pessoa);
    props.getRetailConditionByIdPerson(props.pessoa.id_pessoa);
    props.getRetailGroupByIdPerson(props.pessoa.id_pessoa);
    props.getCamapanhaSMSByIdPerson(props.pessoa.id_pessoa);
    props.getRetencaoByIdPerson(props.pessoa.id_pessoa);
    props.getStatusByIdPerson(props.pessoa.id_pessoa);
    setEmpresa(JSON.parse(localStorage.getItem('company')));
  }, []);

  function executeAction(result) {
    switch (justificativa) {
      case 'del':
        props.deletePeople(
          { ...result, pessoa: props.pessoa.id_pessoa },
          props.filtroConsulta
        );
        props.onClose();
        break;

      case 'edit':
        props.putPerson(
          {
            ...newValues,
            motivo: result.motivo,
            documentoMotivo: result.documento,
          },
          props.filtroConsulta
        );
        break;

      default:
        return;
    }
  }

  return (
    <Dialog
      open={props.openModal}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth="lg"
      maxWidth="lg"
    >
      <DialogTitle
        onDelete={() => {
          setModalJustificativa(true);
          setJustificativa('del');
        }}
        onEdit={() => setModalEditPessoa(true)}
        className="modal-title"
        onClose={props.onClose}
      >
        {props.personProfile.nome}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Alert variant="filled" severity="warning">
          <b>ESTA É UMA INFORMAÇÃO CONFIDENCIAL </b>
          <br />
          Seu uso deve ser exclusivo da empresa{' '}
          {String(empresa.name).toUpperCase()}, inscrita no CNPJ sob o nº &nbsp;
          {CNPJ(empresa.cnpj)}, e apenas para auxílio no processo de
          relacionamento. A divulgação de quaisquer informações à terceiros
          poderá ser punida de acordo com o previsto por lei.
        </Alert>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            padding: '10px 25px 10px 25px',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '2vh',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" color="primary">
              Dados pessoais
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                textAlign: 'start',
              }}
            >
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Nome: "
                  loading={props.isLoadingPeople}
                  value={props.personProfile.nome}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="CPF: "
                  loading={props.isLoadingPeople}
                  value={CPF(props.personProfile.documento)}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <p>
                  <strong style={{ fontSize: 18 }}>Idade: </strong>
                  {props.isLoadingPeople
                    ? 'Carregando...'
                    : props.personProfile.idade}
                </p>
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Cidade: "
                  loading={props.isLoadingPeople}
                  value={props.personProfile.cidade}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Endereço: "
                  loading={props.isLoadingPeople}
                  value={props.personProfile.endereco}
                />
              </div>
            </div>
            <div
              style={{
                textAlign: 'start',
              }}
            >
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Data nascimento: "
                  loading={props.isLoadingPeople}
                  value={props.personProfile.data_nascimento}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Celular: "
                  loading={props.isLoadingPeople}
                  value={Celular(props.personProfile.celular1)}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <TextInput
                  title="Email: "
                  loading={props.isLoadingPeople}
                  value={props.personProfile.email1}
                />
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <p>
                  <strong style={{ fontSize: 18 }}>Status: </strong>
                  {props.isLoadingPeople
                    ? 'Carregando...'
                    : props.personProfile.status}
                </p>
              </div>
              <div style={{ padding: '10px 0px 10px 0px' }}>
                <p>
                  <strong style={{ fontSize: 18 }}>Loja Preferida: </strong>
                  {props.isLoadingPeople
                    ? 'Carregando...'
                    : props.personProfile.nome_loja}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <CountCard
              loading={props.isLoadingPeople}
              titleTypographyProps={{ variant: 'h6' }}
              title="Última compra"
              icon="today"
              style={{ marginRight: 20 }}
            >
              {props.dadoGastos.ultimaData
                ? moment(props.dadoGastos.ultimaData.replace('Z', '')).format(
                    'DD/MM/YYYY'
                  )
                : 'Não encontrado'}
            </CountCard>
            <CountCard
              count
              loading={props.isLoadingPeople}
              titleTypographyProps={{ variant: 'h6' }}
              end={props.pessoa.qt_compras}
              title="Quantidade de compras"
              icon="add_shopping_cart"
              style={{ marginRight: 20 }}
            />

            <CountCard
              count
              loading={props.isLoadingPeople}
              titleTypographyProps={{ variant: 'h6' }}
              end={props.pessoa.ticket_medio}
              decimals={2}
              prefix="R$ "
              title="Ticket médio"
              icon="check"
              style={{ marginRight: 20 }}
            />
            <CountCard
              count
              loading={props.isLoadingPeople}
              titleTypographyProps={{ variant: 'h6' }}
              end={props.pessoa.valor_total_gasto}
              decimals={2}
              prefix="R$ "
              title="Gasto total"
              icon="attach_money"
              style={{ marginRight: 20 }}
            />
          </div>
          <Divider></Divider>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <Typography variant="h4" color="primary">
              Histórico de vendas
            </Typography>
            <CustomTable
              title=""
              search={false}
              pageSize={5}
              paging
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              isLoading={props.isLoadingPeople}
              columns={[
                { title: 'Produto', field: 'nome_produto' },
                { title: 'Familia', field: 'desc_categoria_2' },
                {
                  title: 'Departamento',
                  field: 'desc_categoria_3',
                },
                { title: 'Grupo', field: 'desc_categoria_4' },
                {
                  title: 'Valor',
                  field: 'valor_unitario',
                  render: rowData => (
                    <span>
                      R$ {MoedaBrasil(rowData.total || rowData.valor_unitario)}
                    </span>
                  ),
                },
              ]}
              data={props.listRetailPerson}
            />
          </div>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <Typography variant="h4" color="primary">
              Timeline de campanhas
            </Typography>
            <CustomTable
              title=""
              search={false}
              pageSize={5}
              paging
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              isLoading={props.isLoadingPeople}
              columns={[
                { title: 'Campanha', field: 'nome_campanha' },
                {
                  title: 'Data do disparo',
                  field: 'data_agendamento',
                },
                {
                  title: 'Ações',
                  field: 'data_agendamento',
                  render: row => (
                    <MyButton
                      onClick={() =>
                        history.push(
                          `/detalhes-campanha/${row.id_campanha_lista}_`
                        )
                      }
                    >
                      Visualizar campanha
                    </MyButton>
                  ),
                },
              ]}
              data={props.listCampanhaSMSById.map(campanha => ({
                ...campanha,
                data_agendamento: campanha.data_agendamento
                  ? moment(campanha.data_agendamento.replace('Z', '')).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  : 'Nenhum resultado ',
              }))}
            />
          </div>
        </div>
      </DialogContent>
      {modalJustificativa && (
        <ModalJustificativa
          showModal={modalJustificativa}
          onClose={() => setModalJustificativa(false)}
          onSave={result => executeAction(result)}
        />
      )}
      {modalEditPessoa && (
        <ModalEditPessoa
          editable
          defaultPessoa={props.personProfile}
          open={modalEditPessoa}
          onSave={newPessoa => {
            setModalJustificativa(true);
            setJustificativa('edit');
            setNewValues(newPessoa);
          }}
          onClose={() => setModalEditPessoa(false)}
        />
      )}
    </Dialog>
  );
}

const mapStateToProps = ({ PeopleReducer, InsightsReducer }) => ({
  ...PeopleReducer,
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getPersonProfile,
  getRetailByIdPerson,
  getRetailFamilyByIdPerson,
  getRetailConditionByIdPerson,
  getRetailGroupByIdPerson,
  getCamapanhaSMSByIdPerson,
  getRetencaoByIdPerson,
  getStatusByIdPerson,
  onSetSavePerson,
  putPerson,
  deletePeople,
};

const ModalPerfilConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPerfil);

export default withStyles(styles)(ModalPerfilConnect);
