import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    appBar: { position: 'relative', border: 'none' },
    container: { display: 'flex' },
    contentTitle: { display: 'flex', alignItems: 'center', flex: 1 },
    preview: { width: '100%' },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    content: { padding: theme.spacing(2, 4) },
    previewContainer: {
      maxWidth: '100%',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    popper: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    checkLabel: {
      fontWeight: 'bold',
      color: `${theme.palette.primary.main} !important`,
      fontSize: '11pt',
    },
    collapseSms: { width: '100%', marginTop: theme.spacing(3.5) },

    contentInfoSms: {
      padding: theme.spacing(2),
      backgroundColor: '#F5F5F5',
    },
    contentInfoSmsAlert: {
      backgroundColor: '#EFEFEF',
      marginTop: theme.spacing(3),
    },
    typographyTitleSms: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '.05em',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    typographyAutocompleteSms: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '.05em',
      color: '#616161',
    },
    iconAlertSms: { marginRight: theme.spacing(1) },
    sliderThumb: {
      width: `${theme.spacing(2)}px !important`,
      height: `${theme.spacing(2)}px !important`,
      marginTop: '-6px !important',
    },
    sliderRail: { height: 6 },
    chipLimits: { marginRight: theme.spacing(2) },
    inputNoBorder: {
      border: 'none',
    },
    inputBackgroundColor: {
      backgroundColor: '#F5F5F5',
    },
    inputBorderError: {
      border: '1px solid #f44336 !important',
    },
    socialNetworksError: {
      marginTop: '8px',
      marginBottom: '24px',
      // boxSizing: 'border-box',
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // padding: '8px 0px 8px 8px',
      // gap: '8px',
      // width: '100%',
      // height: '40px',
      // border: '1px solid #D54D4D',
      // borderRadius: '4px',
      // flex: 'none',
      // order: '6',
      // flexGrow: '0',
      // background: 'rgba(213, 77, 77, 0.05)'
    },
    disclaimerError: {
      marginTop: '8px',
      marginBottom: '24px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 0px 8px 8px',
      gap: '8px',
      width: '100%',
      height: '40px',
      border: '1px solid #D54D4D',
      borderRadius: '4px',
      flex: 'none',
      order: '6',
      flexGrow: '0',
      background: 'rgba(213, 77, 77, 0.05)',
    },
  }),
);

export default styles;
