import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PersonIcon from '../../../../../../assets/header/user.png';
import { logout } from '../../../../../../modules/Login/actions/actionLogin';
import useStyles from './styles';

const ProfileMenu = ({ anchorEl, onClose, ...rest }) => {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const token = JSON.parse(localStorage.getItem('token'));
  const { logout } = rest;
  const history = useHistory();

  function handleSignOut() {
    logout();
    history.push('/');
  }

  React.useEffect(() => {
    const storedName = localStorage.getItem('name').split('"')[1];
    const storedEmail = localStorage.getItem('email').split('"')[1];
    setName(storedName);
    setEmail(storedEmail);
  }, []);

  return (
    <Menu
      id="profile-menu"
      className={classes.menu}
      classes={{ paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Box paddingX={2} paddingY={1}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="column" alignItems="center" xs={12} spacing={1}>
            <Grid item xs={12}>
              <Avatar alt={name} src={PersonIcon} />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="body1">
                {email}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Button
                className={classes.exit}
                fullWidth
                variant="outlined"
                color="default"
                onClick={() =>
                  window.location.replace(
                    `https://app.dsmarketing.com.br/sign-as-user/${token}?origin=old`
                  )
                }
              >
                Acessar nova versão
              </Button>
              <Button
                className={classes.exit}
                fullWidth
                variant="outlined"
                color="default"
                onClick={() =>
                  window.location.replace(`https://www.dsmarketing.com.br/termos-de-uso/`)
                }
              >
                Termos de uso
              </Button>
              <Button
                className={classes.exit}
                fullWidth
                variant="outlined"
                color="default"
                onClick={handleSignOut}
              >
                Sair
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Menu>
  );
};

const mapDispatchToProps = { logout };

export default connect(null, mapDispatchToProps)(ProfileMenu);

