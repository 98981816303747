import React, { useEffect } from 'react';

import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import CalendarActiveIcon from '../../../../assets/icons/marketing/CalendarActive.svg';
import StarIcon from '../../../../assets/icons/marketing/Star.svg';
import { useHome } from '../../../../hooks/home';
import useStyles from '../../styles';
import DevicesIcon from '@material-ui/icons/Devices';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import WcIcon from '@material-ui/icons/Wc';
import { BarChart } from '../../../../utils/components';

import DateRangeIcon from '@material-ui/icons/DateRange';

export default function ({ open, adsId, creativeId, data, onClose }) {
  const classes = useStyles(),
    {
      insights,
      loadingDetailCampaign,
      getInsightsFacebook,
      getInsightsPaidFacebook,
      getInsightsInstagram,
      getInsightsSends,
    } = useHome();

  useEffect(() => {
    if (data && data.id && data.type) {
      switch (data.type) {
        case 'FACEBOOK':
          getInsightsFacebook(data.id);
          break;
        case 'FACEBOOK_PAID':
          getInsightsPaidFacebook(data.id, data.creative_id);
          break;
        case 'INSTAGRAM':
          getInsightsInstagram(data.id);
          break;
        case 'WHATSAPP':
          getInsightsSends(data.id);
          break;
      }
    }
  }, [data]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogContent className={classes.dialog}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="primary" className={classes.subtitle}>
              {data && data.name}
            </Typography>
          </Grid>
          <Grid container spacing={4} item xs={12}>
            <Grid item xs={6} alignItems="center" style={{ display: 'flex' }}>
              <Box className={classes.dialogInfoContainer}>
                <Avatar
                  variant="rounded"
                  src={CalendarActiveIcon}
                  className={classes.dialogIcon}
                />
                <Box paddingLeft="8px">
                  {data && data.start_date ? (
                    <Typography
                      style={{ color: '#616161' }}
                      className={classes.cardTitle}
                    >
                      Data de criação: {data.start_date}
                    </Typography>
                  ) : null}
                  {data && data.send_date ? (
                    <Typography
                      style={{ color: '#616161' }}
                      className={classes.cardTitle}
                    >
                      Data de agendamento: {data.send_date}
                    </Typography>
                  ) : null}
                  {data && data.end_date ? (
                    <Typography
                      style={{ color: '#616161' }}
                      className={classes.cardTitle}
                    >
                      Data de término: {data.end_date}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Grid>
            {data && data.lifetime_budget ? (
              <>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  style={{ display: 'flex' }}
                >
                  <Box className={classes.dialogInfoContainer}>
                    <Avatar
                      variant="rounded"
                      src={StarIcon}
                      className={classes.dialogIcon}
                      style={{ padding: '12px' }}
                    />
                    <Box paddingLeft="8px">
                      <Typography
                        style={{ color: '#616161' }}
                        className={classes.cardTitle}
                      >
                        Créditos gastos: {data.lifetime_budget}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {loadingDetailCampaign ? (
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              style={{ display: 'flex' }}
            >
              <CircularProgress size="40px" />
            </Grid>
          ) : (
            <Grid item container xs={12} spacing={4}>
              {Boolean(insights.length) ? (
                <>
                  {insights.map(insight => (
                    <Grid item xs={4}>
                      <Box className={classes.dialogInfoContainer}>
                        <Avatar
                          variant="rounded"
                          src={CalendarActiveIcon}
                          className={classes.dialogIcon}
                        />
                        <Box paddingLeft="8px">
                          <Typography
                            style={{ color: '#B4B4B4' }}
                            className={classes.textDescription}
                          >
                            {insight.status_envio_externo}
                          </Typography>
                          <Typography
                            color="primary"
                            className={classes.cardTitle}
                          >
                            {insight.quantidade}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}

                  <Grid item container xs={12} spacing={4}>
                    <Grid item xs={6}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar
                              className={classes.avatar}
                              variant="rounded"
                            >
                              <DevicesIcon />
                            </Avatar>
                          }
                          title="Plataformas"
                        />
                        <Divider />
                        <CardContent>
                          <BarChart
                            data={insights[0].platforms}
                            barLegend
                            randomFill
                            barDataKey="reach"
                            xDataKey="publisher_platform"
                            tooltipFormatter="Alcance"
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar
                              className={classes.avatar}
                              variant="rounded"
                            >
                              <DevicesOtherIcon />
                            </Avatar>
                          }
                          title="Dispositivo"
                        />
                        <Divider />
                        <CardContent>
                          <BarChart
                            data={insights[0].device}
                            barLegend
                            randomFill
                            barDataKey="reach"
                            xDataKey="device_platform"
                            tooltipFormatter="Alcance"
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar
                              className={classes.avatar}
                              variant="rounded"
                            >
                              <WcIcon />
                            </Avatar>
                          }
                          title="Gênero"
                        />
                        <Divider />
                        <CardContent>
                          <BarChart
                            data={insights[0].gender}
                            barLegend
                            randomFill
                            barDataKey="impressions"
                            xDataKey="gender"
                            tooltipFormatter="Impressões: "
                            // noTooltip
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card variant="outlined">
                        <CardHeader
                          avatar={
                            <Avatar
                              className={classes.avatar}
                              variant="rounded"
                            >
                              <DateRangeIcon />
                            </Avatar>
                          }
                          title="Idade"
                        />
                        <Divider />
                        <CardContent>
                          <BarChart
                            data={insights[0].age}
                            barLegend
                            randomFill
                            barDataKey="impressions"
                            xDataKey="age"
                            tooltipFormatter="Impressões: "
                            // noTooltip
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography
                    style={{ color: '#616161' }}
                    className={classes.cardTitle}
                  >
                    Números indisponíveis no momento
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
