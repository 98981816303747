import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button, { ButtonProps } from "@mui/material/Button";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useStyles } from "./styles";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { map } from "lodash";

type DialogProps = MuiDialogProps & {
  title?: React.ReactNode;
  children: React.ReactNode;
  actions?: ButtonProps[];
  onClose?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Dialog({
  title,
  children,
  actions,
  onClose,
  ...rest
}: DialogProps) {
  const styles = useStyles();

  return (
    <MuiDialog
      {...rest}
      onClose={() => {}}
      aria-labelledby="responsive-dialog-title"
      id="responsive-dialog"
      TransitionComponent={Transition}
    >
        <MuiDialogTitle
          className={styles.rootHeader}
          id="responsive-dialog-title"
        >
        {title && (
          <div>{title}</div>
        )}
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        
      <MuiDialogContent id="responsive-dialog-content">
        {children}
      </MuiDialogContent>
      {actions && Array.isArray(actions) && (
        <MuiDialogActions
          className={styles.rootActions}
          id="responsive-dialog-actions"
        >
          {map(actions, (action, index) => (
            <Button key={index.toString()} {...action} />
          ))}
        </MuiDialogActions>
      )}
    </MuiDialog>
  );
}
