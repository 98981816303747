import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from 'material-table';
import React, { useState } from 'react';
import location from '../../../../localization/table';
import { formatPrice } from '../../../../utils/helpers/Format';
import ProductDetails from '../ProductDetails';
import useStyles from './styles';

function Products({ filter, data, loading }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState();

  const handleOpenProduct = rowData => {
    setOpen(true);
    setProduct(rowData);
  };

  const handleCloseProduct = () => {
    setOpen(false);
    setProduct(undefined);
  };

  // const handleSubmit = async values => {
  //   await api(
  //     `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/filter/product/edit/${values.id}`,
  //     values,
  //     'PUT'
  //   );
  // };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader title="Produtos" />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            onRowClick={(e, rowData) => {
              handleOpenProduct(rowData);
            }}
            actions={[
              {
                icon: 'exit_to_app',
                tooltip: 'Exibir Produto',
                onClick: (e, rowData) => {
                  handleOpenProduct(rowData);
                },
              },
            ]}
            columns={[
              { title: 'ean', field: 'ean' },
              { title: 'Codigo interno', field: 'sku' },
              { title: 'Nome', field: 'name' },
              { title: 'Familia', field: 'category' },
              {
                title: 'Preço',
                field: 'price',
                render: rowData => <span>{formatPrice(rowData.price)}</span>,
              },
              {
                title: 'Maior preço',
                field: 'highest_price',
                render: rowData => (
                  <span>{formatPrice(rowData.highest_price)}</span>
                ),
              },
              {
                title: 'Menor preço',
                field: 'lowest_price',
                render: rowData => (
                  <span>{formatPrice(rowData.lowest_price)}</span>
                ),
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={location}
          />
        </CardContent>
      </Card>
      {open && (
        <ProductDetails
          open={open}
          data={product}
          onClose={handleCloseProduct}
        />
      )}
    </React.Fragment>
  );
}

export default Products;
