/* eslint-disable */
import { Button, Divider, Typography, withStyles, Icon } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../../utils/components';
import Alert from '../../../../utils/helpers/Alert';
import {
  getOfertaFisicoAll,
  getTabloidsTemplatesFisico,
  deleteEncarteFisico,
} from '../../action/index';
import { AMBIENTE, COLOR_PRIMARY, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import { Analytics, EditTabloid } from '../index';
import { toBase64 } from '../../../../utils/helpers/Functions';

const alerta = new Alert();

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

class LinkOnlinePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      qtde_produtos_lista: null,
      tipoPixelixe: null,
      generatePixelixe: false,
      pixelixeIframe: false,
      openDetalhe: false,
      openEditJson: false,
      itemSelected: [],
      nome_tabloide: null,
      download: false,
      linkOnlineTable: [
        {
          nome: null,
        },
      ],
      loading: false,
      tabloids: [],
      tabloid: null,
      editTabloideOpen: false,
      accessLogOpen: false,
    };
  }

  getTabloids() {
    try {
      this.setState({ loading: true });
      Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`
      ).then(response => {
        this.setState({ tabloids: response });
        this.setState({ loading: false });
        let temp = response.filter(obj => {
          return obj.id_tabloide_digital === parseInt(this.state.id, 10);
        });
        if (temp.length > 0) this.setState({ tabloid: temp[0] });
      });
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  }

  handleSubmitBanner(tabloidId, banner) {
    try {
      const { position, device, href, file } = banner;

      return toBase64(file).then(base64 => {
        return Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/banner`,
          {
            tabloidId,
            position,
            device,
            href,
            ...base64,
          },
          'POST'
        ).then(response => {
          return response;
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleEditTabloid(data, banners = []) {
    this.setState({ loading: true });
    let banners_total = banners.length;
    try {
      this.setState({ editTabloideOpen: false });
      Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
        data,
        'PUT'
      ).then(() => {
        if (banners && banners.length > 0) {
          banners.map(banner =>
            this.handleSubmitBanner(data.uuid, banner).then(() => {
              banners_total--;
              if (banners_total === 0) this.getTabloids();
            })
          );
        }
        if (banners_total === 0) this.getTabloids();
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id: id.split('_')[0] });
    this.setState({ qtde_produtos_lista: id.split('_')[1] });
    this.setState({ tipoPixelixe: id.split('_')[2] });
    this.setState({ nome_tabloide: id.split('__')[1] });
    this.setState({
      linkOnlineTable: [
        {
          nome: id.split('__')[1],
        },
      ],
    });

    this.getTabloids();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Link to={`/propaganda-de-ofertas`} style={{ marginTop: 15, textDecoration: 'none' }}>
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Propaganda de Ofertas
          </Button>
        </Link>
        <div className={classes.actions}>
          <div className={classes.actions} style={{ maxWidth: '65vw' }}>
            <Typography className={classes.margin} variant="h3">
              Link On-line - {this.state.nome_tabloide}
            </Typography>
          </div>
          <div style={{ marginBottom: 20 }}></div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div className={classes.content}>
            <CustomTable
              data={this.state.linkOnlineTable}
              title=""
              //pageSize={10}
              //paging
              //search
              isLoading={this.state.loading}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[
                {
                  title: 'Nome',
                  field: 'nome',
                },
                {
                  title: '',
                  field: '',
                  render: rowData => (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            this.state.tabloid.uuid !== null
                              ? `https://tabloide.ofertasdoclube.com.br/${localStorage.getItem(
                                  'companyId'
                                )}/${this.state.tabloid.uuid}`
                              : this.state.tabloid.link_tabloide_digital
                          );
                          alerta.alertSuccess('Link Copiado!');
                        }}>
                        Copiar Link
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
                        onClick={() => this.setState({ editTabloideOpen: true })}>
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ backgroundColor: COLOR_PRIMARY }}
                        onClick={() => this.setState({ accessLogOpen: true })}>
                        Analítico
                      </Button>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </div>
        </div>
        {this.state.editTabloideOpen && (
          <EditTabloid
            open={this.state.editTabloideOpen}
            tabloid={this.state.tabloid}
            onSave={(data, banners) => this.handleEditTabloid(data, banners)}
            onClose={() => this.setState({ editTabloideOpen: false })}
            //onGetTabloids={() => props.getTabloides()}
            completeEdit={true}
          />
        )}
        {this.state.accessLogOpen && (
          <Analytics
            open={this.state.accessLogOpen}
            tabloid={this.state.tabloid}
            onClose={() => this.setState({ accessLogOpen: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});

const mapDispatchToProps = {
  getOfertaFisicoAll,
  getTabloidsTemplatesFisico,
  deleteEncarteFisico,
};

const LinkOnlinePageConnect = connect(mapStateToProps, mapDispatchToProps)(LinkOnlinePage);

export default withStyles(styles)(LinkOnlinePageConnect);
