import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Typography, withStyles, Avatar, Icon, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getEditOferta, getLojas, getOfertaCupom } from '../actions/index';
import { Panel, CountCard, DatePicker, Select } from '../../../utils/components';
import ModalPerfis from './ModalPerfis';
import ModalProdutos from './ModalProdutos';
import ModalEditarOferta from './ModalEditarOferta';
import MyDate from '../../../utils/helpers/Date';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

const _myDate = new MyDate();

class Ofertas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_loja: null,
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      personalizada: null,
      key: 0,
      modalVerPerfil: false,
      modalProdutos: false,
      modalEditar: false,
    };
  }

  componentDidMount() {
    const { filtroDataInicio, filtroDataFim, id_loja } = this.state;
    const periodo = {
      dataInicio: _myDate.getDate('YYYY-MM-DD', filtroDataInicio),
      dataFim: _myDate.getDate('YYYY-MM-DD', filtroDataFim),
      id_loja,
    };
    this.props.getLojas();
    this.props.getOfertaCupom(periodo);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data }, () => {
      const { filtroDataInicio, filtroDataFim, id_loja } = this.state;
      const periodo = {
        dataInicio: _myDate.getDate('YYYY-MM-DD', filtroDataInicio),
        dataFim: _myDate.getDate('YYYY-MM-DD', filtroDataFim),
        id_loja,
      };
      this.props.getOfertaCupom(periodo);
    });
  }

  render() {
    const { classes, cupons } = this.props;
    const { key, filtroDataInicio, filtroDataFim, modalVerPerfil, modalProdutos, modalEditar } =
      this.state;

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Cupom
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date => this.onChangePeriodo('filtroDataInicio', date)}
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                marginLeft: 20,
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por Lojas
              </Typography>

              <Select
                label="Lojas"
                onChange={e => this.onChangePeriodo('id_loja', e ? e.value : null)}
                options={this.props.lojas}
              />
            </div>
          </section>
          <div>
            <Button
              size="large"
              variant="contained"
              style={{ backgroundColor: COLOR_PRIMARY }}
              color="primary"
              component={Link}
              to="/criar-cupom">
              Criar Cupom
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}>
          <Button
            style={{
              marginRight: 10,
              borderRadius: 10,
              '&:hover': { borderRadius: 10 },
            }}
            color="inherit"
            size="large"
            className={key === 0 && classes.activeButton}
            onClick={() => this.setState({ key: 0 })}>
            Cupons
          </Button>
        </div>
        <div
          className={classes.content}
          style={
            this.props.isLoadingCupom
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }>
          {this.props.isLoadingCupom ? (
            <CircularProgress style={{ color: COLOR_PRIMARY }} />
          ) : (
            cupons.map(cupom => (
              <Fragment>
                <Panel
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Icon>shopping_cart</Icon>
                    </Avatar>
                  }
                  title={`${cupom.id_oferta_cupom ? `#${cupom.id_oferta_cupom} - ` : ''}${
                    cupom.nome_cupom
                  }`}
                  subheader={` ${moment(cupom.data_inicio).format('DD/MM/YYYY')} até ${moment(
                    cupom.data_fim
                  ).format('DD/MM/YYYY')}`}
                  style={{ marginBottom: 20 }}
                  action={
                    <Button
                      size="large"
                      className={classes.button}
                      variant="text"
                      color="primary"
                      disabled={!cupom.id_oferta_cupom ? true : false}
                      onClick={() =>
                        this.setState({
                          ...cupom,
                          modalVerPerfil: true,
                        })
                      }>
                      Ver Perfis
                    </Button>
                  }>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                      }}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={cupom.valor_cupom}
                        decimals={2}
                        prefix="R$ "
                        title="Valor do cupom"
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />

                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={cupom.limite_uso}
                        title="Limite de uso"
                        icon="confirmation_number"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={cupom.pessoa_uso}
                        title="Pessoas que usaram"
                        icon="thumb_up"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={cupom.pessoa_n_uso}
                        title="Pessoas que não usaram"
                        icon="thumb_down"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={cupom.qtde_uso}
                        title="Quantidade total de uso"
                        icon="plus_one"
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </div>
                </Panel>
              </Fragment>
            ))
          )}
        </div>
        {modalEditar && (
          <ModalEditarOferta
            open={modalEditar}
            onClose={() => this.setState({ modalEditar: false })}
            personalizada={this.state.personalizada}></ModalEditarOferta>
        )}
        {modalVerPerfil && (
          <ModalPerfis
            {...this.state}
            open={modalVerPerfil}
            onClose={() => this.setState({ modalVerPerfil: false })}
          />
        )}
        {ModalProdutos && (
          <ModalProdutos
            open={modalProdutos}
            onClose={() => this.setState({ modalProdutos: false })}></ModalProdutos>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ OfertasReducer, CupomReducer }) => ({
  ...OfertasReducer,
  ...CupomReducer,
});

const mapDispatchToProps = {
  getLojas,
  getEditOferta,
  getOfertaCupom,
};

const OfertasConnect = connect(mapStateToProps, mapDispatchToProps)(Ofertas);

export default withStyles(styles)(OfertasConnect);
