import React from 'react';
import {
  ForumRounded as ForumIcon,
  StoreRounded as StoreIcon,
  WebRounded as WebIcon,
  RadioRounded as RadioIcon,
  LanguageRounded as LanguageIcon,
  DevicesRounded as DevicesIcon,
  // MonetizationOnRounded as MonetizationIcon,
  CreateRounded as CreateIcon,
  EqualizerRounded as EqualizerIcon,
  // SearchRounded as SearchIcon,
  WhatsApp as WhatsappIcon,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faDatabase, faPercentage } from '@fortawesome/free-solid-svg-icons';
import DSMarketing from '../../../../../../assets/images/menu/marketing.svg';
import DSCommerce from '../../../../../../assets/images/menu/commerce.png';
import DSOmni from '../../../../../../assets/images/menu/omni.png';
import DSLoyalty from '../../../../../../assets/images/menu/loyalty.png';
import DSConfig from '../../../../../../assets/images/menu/config.png';

export default [
  {
    // lineName: 'marketing',
    // breakline: DSMarketing,
    // width: '10vw',
    modules: [
      {
        module: 'campanhas',
        services: 'criar_campanha,monitor_de_envios,resultados_de_campanhas,ads',
        path: '/monitor-campanhas',
        title: 'Campanhas',
        icon: <DevicesIcon />,
      },
      {
        module: 'criacao',
        services: 'templates,propaganda_de_ofertas',
        path: '/estudio',
        title: 'Estúdio',
        icon: <CreateIcon />,
      },
      {
        title: 'Whatsapp',
        icon: <WhatsappIcon />,
        path: '/whatsapp',
        module: 'whatsapp',
        services: 'whatsapp',
      },
      {
        title: 'Redes sociais',
        icon: <LanguageIcon />,
        path: '/social-networks/accounts',
        module: 'redes_sociais',
        services: 'redes_sociais',
      },
      {
        title: 'Radios',
        path: '/radios',
        icon: <RadioIcon />,
        module: 'radios',
        services: 'radios',
      },
      {
        title: 'Site',
        path: '/sites',
        icon: <WebIcon />,
        module: 'sites',
        services: 'sites',
      },
      {
        title: 'DSOmni',
        path: '/chat',
        icon: <ForumIcon />,
        module: 'chat',
        services: 'chat',
      },
      {
        module: 'config',
        services: 'account',
        path: '/account-settings',
        title: 'Minha Conta',
        icon: <FontAwesomeIcon icon={faAward} />,
      },
    ],
  },

  // {
  //   lineName: 'ecommerce',
  //   breakline: DSCommerce,
  //   width: '10vw',
  //   modules: [
  //     {
  //       title: 'Site e APP',
  //       path: '/sites',
  //       icon: <WebIcon />,
  //       module: 'sites',
  //       services: 'sites',
  //     },
  //     {
  //       title: 'Venda On-line',
  //       path: '/delivery',
  //       icon: <StoreIcon />,
  //       module: 'e_commerce',
  //       services: 'pedidos,categorias,produtos',
  //     },
  //   ],
  // },

  // {
  //   lineName: 'omni',
  //   breakline: DSOmni,
  //   width: '5vw',
  //   modules: [
  //     {
  //       title: 'Chat',
  //       path: '/chat',
  //       icon: <ForumIcon />,
  //       module: 'chat',
  //       services: 'chat',
  //     },
  //   ],
  // },

  // {
  //   lineName: 'loyalty',
  //   breakline: DSLoyalty,
  //   width: '7vw',
  //   modules: [
  //     {
  //       module: 'banco_de_dados',
  //       services: 'audiencia,enriquecimento,importacao,banco_de_dados',
  //       path: '/filtros',
  //       title: 'Meus Clientes',
  //       icon: <FontAwesomeIcon icon={faDatabase} />,
  //     },
  //     {
  //       module: 'ofertas',
  //       services:
  //         'ofertas,inteligencia_promocional,criar_oferta,monitor_de_ofertas,resultados_de_ofertas,recomendacoes_inteligentes,cupom_de_desconto',
  //       path: '/ofertas',
  //       title: 'Ofertas',
  //       icon: <FontAwesomeIcon icon={faPercentage} />,
  //     },
  //     {
  //       module: 'performance',
  //       services: 'visao_geral,bi,sob_demanda',
  //       path: '/analise',
  //       title: 'BI',
  //       icon: <EqualizerIcon />,
  //     },
  //     // {
  //     //   module: 'cashback',
  //     //   services: 'cashback,cupom_de_cashback',
  //     //   path: '/cashback',
  //     //   title: 'Cashback',
  //     //   icon: <MonetizationIcon />,
  //     // },
  //     // {
  //     //   module: 'sorteio',
  //     //   services: 'cupom_de_sorteio',
  //     //   path: '/cupom-sorteio',
  //     //   title: 'Sorteios',
  //     //   icon: <FontAwesomeIcon icon={faAward} />,
  //     // },
  //     // {
  //     //   module: 'pesquisas',
  //     //   services: 'formularios',
  //     //   path: '/forms',
  //     //   title: 'Pesquisas',
  //     //   icon: <SearchIcon />,
  //     // },
  //   ],
  // },

  //   {
  //     lineName: 'config',
  //     breakline: DSConfig,
  //     width: '6vw',
  //     modules: [
  //       {
  //         module: 'config',
  //         services: 'account',
  //         path: '/account-settings',
  //         title: 'Minha Conta',
  //         icon: <FontAwesomeIcon icon={faAward} />,
  //       },
  //     ],
  //   },
];
