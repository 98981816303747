import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    formContainer: {
      margin: 0,
      maxWidth: '540px',
      // padding: '0 20px',
      overflowY: 'hidden',
      overflowX: 'hidden',
      fontFamily: 'Montserrat, sans-serif',
      letter: '5%',
      letterSpacing: '0.05em',
      lineHeight: '22px',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '16px',
      '&:hover': {
        // overflowY: 'scroll',
      },
      '& > strong': {
        fontSize: 700
      }
    },
    alertCredits: {
      background: '#F4F3FB',
      color: '#2813AD',
      fontSize: '12px !important',
      lineHeight: '22px',
      letter: '5%',
      fontWeight: 500,

      marginTop: '16px !important',
      padding: '8px 16px !important',

      display: 'flex',
      justifyContent: 'start',

      '& > img': {
        marginRight: '8px'
      }
    },
    boxLabelWhatsApp: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px !important'
    },
    creditInformational: {
      margin: '24px 0 0 0 !important',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      gap: '8px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '25px',
        height: '100%',
        border: 'none',
        borderRadius: '2px',
        padding: '4px',
        gap: '10px',
        fontSize: '12px'
      }
    },
    creditBalance: {
      background: '#F0F0F0',
      color: '#616161'
    },
    campaignConsume: {
      background: '#F4F3FB',
      color: '#2813AD'
    },
    buyCredits: {
      margin: '24px 0 0 0 !important',
      padding: '12px 16px !important',
      maxWidth: '233px',
      width: '100%',
      color: '#2813AD !important',
      border: '1px solid #2813AD !important',
      fontSize: '16px !important',
      lineHeight: '26px',
      letter: '5%'
    },
    popper: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius
    },
    typographyAutocomplete: {
      fontWeight: 400,
      fontSize: '14px !important',
      lineHeight: '17px',
      letterSpacing: '.05em',
      color: '#616161',
      backgroundColor: '#F5F5F5',
      borderRadius: '4px',
      border: 'none',
      width: '500px',
      padding: 0
    },
    typographySubtitleClickabled: {
      fontSize: '16px',
      lineHeight: '20px',
      display: 'flex',
      marginBottom: '0px !important',
      alignItems: 'center',
      color: 'black !important',
      fontWeight: '500 !important'
    },
    typographySubtitleClickabledBtn: {
      fontSize: '14px',
      lineHeight: '17px',
      display: 'flex',
      alignItems: 'center'
    },
    inputNoBorder: {
      border: 'none'
    },
    inputBorderError: {
      border: '1px solid #f44336 !important'
    },
    inputBackgroundColor: {
      backgroundColor: '#F5F5F5'
    }
  })
);
