import {
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  BoxFormat,
  DivVideo,
  Img,
  TypographyMenuMoreFormats,
  TypographySubTitle,
  TypographyTitle,
  TypographyVideo,
  VideoLabel
} from './styles';
import formatCartazPaisagem from '../../../../../assets/newStudio/formatCartazPaisagem.png';
import formatCartazRetrato from '../../../../../assets/newStudio/formatCartazRetrato.png';
import formatEncarte from '../../../../../assets/newStudio/formatEncarte.png';
import formatEtiquetas from '../../../../../assets/newStudio/formatEtiquetas.png';
import formatPost from '../../../../../assets/newStudio/formatPost.png';
import formatPostVideo from '../../../../../assets/newStudio/formatPostVideo.png';
import formatStory from '../../../../../assets/newStudio/formatStory.png';
import formatStoryVideo from '../../../../../assets/newStudio/formatStoryVideo.png';
import formatTvIndoor from '../../../../../assets/newStudio/formatTvIndoor.png';

import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { AMBIENTE, DATASALES_CRM_API } from 'utils/helpers/Constants';

import api from '../../../../../utils/helpers/Fetch';

const ImgPure = styled('img')();

const Div = styled('div')();

const MasterBox = ({ children, disabled, ...props }: any) => {
  return (
    <BoxFormat
      {...props}
      sx={{
        ...props.sx,
        ...(disabled ? { opacity: [0.3] } : {})
      }}
      {...(disabled ? { onClick: () => {} } : {})}
    >
      <div title={disabled ? 'Adicione produtos à lista para gerar artes!' : ''}>{children}</div>
    </BoxFormat>
  );
};

type FormartType = {
  companyId: number;
  createdAt: string;
  height: number;
  id: number;
  name: string;
  updatedAt: string;
  width: number;
};

const FormatPage = ({ returnPage, dataSelected }: any) => {
  const [myFormats, setMyFormats] = useState<Array<FormartType>>([]);
  const [anchorElMoreFormats, setAnchorElMoreFormats] = useState<null | HTMLElement>(null);
  const openMoreFormats = Boolean(anchorElMoreFormats);
  const MY_COMPANY_ID = parseInt(localStorage.getItem('companyId')!);

  const handleClickMoreFormats = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMoreFormats(event.currentTarget);
  };
  const handleCloseMoreFormats = () => {
    setAnchorElMoreFormats(null);
  };

  useEffect(() => {
    api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
      const myFormats = response.content.filter(
        (format: FormartType) => format.companyId === MY_COMPANY_ID
      );
      setMyFormats(myFormats);
    });
  }, []);

  return (
    <Box sx={{backgroundColor: '#F2F3F5'}} overflow={'hidden'}>
      {returnPage && (
        <Div onClick={returnPage} style={{ cursor: 'pointer', margin: 16 }}>
          <TypographySubTitle sx={{ color: '#2813AD' }}>{'< '}voltar</TypographySubTitle>
        </Div>
      )}
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} mt={2} pl={10.5} pb={7}>
        <TypographyTitle
          sx={{
            width: '100%',
            lineHeight: '32px !important',
            fontSize: '20px !important',
            fontWeight: 600
          }}
        >
          Qual formato deseja criar?
        </TypographyTitle>

        <MasterBox onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Post' } })}>
          <Img src={formatPost} />
          <TypographyTitle>Post</TypographyTitle>
          <TypographySubTitle>Formato quadrado, ideal para Redes Sociais</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Post-video' } })}
        >
          <Img src={formatPostVideo} />
          <DivVideo>
            <TypographyTitle>Post</TypographyTitle>
            <VideoLabel>
              <TypographyVideo>Vídeo</TypographyVideo>
            </VideoLabel>
          </DivVideo>
          <TypographySubTitle>Vídeo em formato em quadrado</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Story' } })}
        >
          <Img src={formatStory} />
          <TypographyTitle>Story</TypographyTitle>
          <TypographySubTitle>Formato ideal para stories e status</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Story-video' } })}
        >
          <Img src={formatStoryVideo} />
          <DivVideo>
            <TypographyTitle>Story</TypographyTitle>
            <VideoLabel>
              <TypographyVideo>Vídeo</TypographyVideo>
            </VideoLabel>
          </DivVideo>
          <TypographySubTitle>Vídeo no formato para stories e status</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Encarte' } })}
        >
          <Img src={formatEncarte} />
          <TypographyTitle>Encarte</TypographyTitle>
          <TypographySubTitle>Ideal para criar artes com até 30 produtos</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() =>
            dataSelected({ createFlyerIsOpen: { open: true, type: 'Cartaz-a4-retrato' } })
          }
        >
          <Img src={formatCartazRetrato} />
          <TypographyTitle>Cartaz retrato</TypographyTitle>
          <TypographySubTitle>Encartes retrato, ideal para impressão</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() =>
            dataSelected({ createFlyerIsOpen: { open: true, type: 'Cartaz-a4-paisagem' } })
          }
        >
          <Img src={formatCartazPaisagem} />
          <TypographyTitle>Cartaz paisagem</TypographyTitle>
          <TypographySubTitle>Encarte deitado, ideal para impressão</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Tv-indoor' } })}
        >
          <Img src={formatTvIndoor} />
          <TypographyTitle>Tv (indoor)</TypographyTitle>
          <TypographySubTitle>Faça ofertas nas TVs do seu estabelecimento</TypographySubTitle>
        </MasterBox>

        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Etiqueta' } })}
        >
          <Img src={formatEtiquetas} />
          <TypographyTitle>Etiqueta</TypographyTitle>
          <TypographySubTitle>Ideal para impressão A4</TypographySubTitle>
        </MasterBox>

        {parseInt(localStorage.getItem('companyId') || '') === 2996 && (
          <MasterBox
            onClick={() => dataSelected({ createFlyerIsOpen: { open: true, type: 'Banner 9x3m' } })}
          >
            <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/tv-indoor.png" />
            <TypographyTitle>Banner 9x3m</TypographyTitle>
            <TypographySubTitle>Formato de banner personalizado</TypographySubTitle>
          </MasterBox>
        )}
        {parseInt(localStorage.getItem('companyId') || '') === 1115 && (
          <MasterBox
            onClick={() =>
              dataSelected({ createFlyerIsOpen: { open: true, type: 'Encarte - PET CAMP' } })
            }
          >
            <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
            <TypographyTitle>Encarte - PET CAMP</TypographyTitle>
            <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
          </MasterBox>
        )}
        {parseInt(localStorage.getItem('companyId') || '') === 1115 && (
          <MasterBox
            onClick={() =>
              dataSelected({ createFlyerIsOpen: { open: true, type: 'Encarte Aberto - PET CAMP' } })
            }
          >
            <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
            <TypographyTitle>Encarte Aberto - PET CAMP</TypographyTitle>
            <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
          </MasterBox>
        )}

        {parseInt(localStorage.getItem('companyId') || '') === 37814 && (
          <MasterBox
            onClick={() =>
              dataSelected({ createFlyerIsOpen: { open: true, type: 'Encarte-personalizado-Ibraim' } })
            }
          >
            <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
            <TypographyTitle>Encarte personalizado</TypographyTitle>
            <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
          </MasterBox>
        )}
        {Boolean(myFormats.length) && (
          <MasterBox>
            <IconButton
              onClick={handleClickMoreFormats}
              aria-controls={openMoreFormats ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMoreFormats ? 'true' : undefined}
              sx={{
                height: '180px',
                width: '180px',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#F2F3F5'
              }}
            >
              <ImgPure src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/icons/iconMaisFormatos.png" />
            </IconButton>
            <Menu
              anchorEl={anchorElMoreFormats}
              id={'more-formats'}
              open={openMoreFormats}
              onClose={handleCloseMoreFormats}
              onClick={handleCloseMoreFormats}
              transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              PaperProps={{
                sx: {
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  width: '210px'
                }
              }}
            >
              {myFormats.map(format => (
                <MenuItem
                  style={{
                    height: '40px'
                  }}
                  onClick={() =>
                    dataSelected({ createFlyerIsOpen: { open: true, type: format.name } })
                  }
                >
                  <TypographyMenuMoreFormats>{format.name}</TypographyMenuMoreFormats>
                </MenuItem>
              ))}
            </Menu>
            <TypographyTitle>Todos os formatos</TypographyTitle>
            <TypographySubTitle>Escolha o formato que mais te atende</TypographySubTitle>
          </MasterBox>
        )}
        {/* <MasterBox onClick={() => dataSelected({ canvaIsOpen: true })}>
          <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/canva.png" />
          <TypographyTitle>Editor do Canva</TypographyTitle>
          <TypographySubTitle>Use o editor do Canva para criar suas artes</TypographySubTitle>
        </MasterBox>
        <MasterBox
          onClick={() => dataSelected({ createFlyerIsOpen: { open: true, isManual: true } })}
        >
          <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/branco.png" />
          <TypographyTitle>Em branco</TypographyTitle>
          <TypographySubTitle>
            Uma página em branco para você editar do seu jeito
          </TypographySubTitle>
        </MasterBox> */}
      </Box>
    </Box>
  );
};

export default FormatPage;

