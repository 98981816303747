import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Using Actions
import { postFileHeader } from '../action';

// Using Components
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepButton,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Description as DescriptionIcon,
  ArrowForward,
} from '@material-ui/icons';
import { DropzoneArea as Dropzone } from 'material-ui-dropzone';
import { Modal, MyButton, MyRadio } from '../../../utils/components';

// Helpers Functions
import { toBase64, setRuleImport } from '../../../utils/helpers/Functions';
import DSAWS from '../../../utils/helpers/DSAWS';

// Usage Constants
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

const steps = ['Selecione o arquivo', 'Match das colunas', 'Regras', 'Review'];

function ImportFileSales(props) {
  const companyInfo = JSON.parse(localStorage.getItem('companyId')),
    [activeStep, setActiveStep] = useState(0),
    [loadFile, setLoadFile] = useState(false),
    [listHeader, setListHeader] = useState([]),
    [keyImport, setKeyImport] = useState('cupom'),
    [ruleImport, setRule] = useState('update'),
    [fileLink, setFileLink] = useState(''),
    [customHeaders, setCustomHeaders] = useState([]),
    [file, setFile] = useState({
      file: null,
      base64: '',
      name: '',
      size: 0,
      type: '',
    });

  useEffect(() => {
    const headers = props.listHeaders.map(header => ({
      column: header.column,
      label: header.label,
    }));
    setListHeader(headers);
  }, [props.listHeaders]);

  useEffect(() => {
    setCustomHeaders(props.listTempCustomHeaders);
  }, [props.listTempCustomHeaders]);

  function _onClose() {
    props.onClose();
    setActiveStep(0);
    setLoadFile(false);
    setListHeader([]);
    setKeyImport('cupom');
    setRule('update');
    setCustomHeaders([]);
    setFileLink('');
    setFile({
      file: null,
      base64: '',
      name: '',
      size: 0,
      type: '',
    });
  }

  function mergeHeaders(label, column) {
    const objCustom = { label, column },
      tempCustom = props.listTempCustomHeaders.findIndex(
        customList => customList.label === label
      );

    if (tempCustom >= 0) {
      customHeaders[tempCustom] = objCustom;
    } else {
      customHeaders.push(objCustom);
    }
  }

  async function onChangeFile(file = []) {
    if (Boolean(file.length)) {
      const base64 = await toBase64(file[0]),
        newFile = {
          file: file[0],
          base64: base64.base64,
          name: base64.name,
          size: base64.size,
          type: base64.type,
        };
      setFile(newFile);
    }
  }

  async function uploadFile() {
    setLoadFile(true);
    const splitName = file.name.split('.'),
      objFileTemp = {
        file: file.file,
        name: `${file.file.lastModified}-${splitName[0]}`,
        type: file.type,
        bucketName: 'log-crm',
        folderName: 'arquivos',
        subFolderName: `${companyInfo}/importacao/`,
        extension: splitName[1],
        distinctName: false,
      },
      { Location } = await DSAWS.saveS3(objFileTemp),
      objRequest = {
        url: Location,
        type: objFileTemp.extension,
        name: objFileTemp.name,
      };

    await props.postFileHeader(objRequest);
    setFileLink(Location);
    setLoadFile(false);
    setActiveStep(1);
  }

  async function onImport() {
    setLoadFile(true);
    const splitName = file.name.split('.'),
      objFileTemp = {
        file: Buffer.from(JSON.stringify(customHeaders)),
        name: `${file.file.lastModified}-${splitName[0]}`,
        type: 'json',
        bucketName: 'log-crm',
        folderName: 'cabecalho',
        subFolderName: `${companyInfo}/importacao/`,
        extension: 'json',
        distinctName: false,
      },
      { Location } = await DSAWS.saveS3(objFileTemp),
      objImport = {
        typeImport: props.type,
        headerLink: Location,
        fileLink,
        fileName: objFileTemp.name,
        fileLength: props.attrFile.file_length,
        keyImport,
        ruleImport,
      };
    props.onSave(objImport);
    _onClose();
  }

  function Footer() {
    return (
      <Box width="100%" display="flex" justifyContent="space-between">
        {activeStep > 0 ? (
          <MyButton
            disabled={loadFile}
            onClick={() => setActiveStep(activeStep - 1)}
            color="default"
          >
            Voltar
          </MyButton>
        ) : (
          <span />
        )}
        {activeStep === 0 ? (
          <MyButton
            disabled={!file.file || loadFile}
            onClick={() => uploadFile()}
          >
            {loadFile ? (
              <CircularProgress size="1.5rem" />
            ) : (
              'Selecionar arquivo'
            )}
          </MyButton>
        ) : activeStep === 3 ? (
          <MyButton disabled={loadFile} onClick={() => onImport()}>
            {loadFile ? 'Processando...' : 'Importar'}
          </MyButton>
        ) : (
          activeStep !== 0 && (
            <MyButton onClick={() => setActiveStep(activeStep + 1)}>
              Próximo
            </MyButton>
          )
        )}
      </Box>
    );
  }

  function ContentCheckbox(attr, key) {
    let defaultSelect = listHeader.find(({ label }) => attr === label);
    defaultSelect = Boolean(defaultSelect) && defaultSelect.column;
    return (
      <Fragment key={key}>
        <Grid item xs={4}>
          <Typography
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            variant="subtitle2"
          >
            Arquivo
          </Typography>
          <Typography variant="body1" gutterBottom>
            {attr}
          </Typography>
        </Grid>
        <Grid item xs={4} alignItems="center" style={{ display: 'flex' }}>
          <ArrowForward style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            variant="subtitle2"
          >
            Coluna
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select
              displayEmpty
              autoWidth
              labelId="id-label"
              defaultValue={defaultSelect}
              options={listHeader}
              onChange={({ target: { value } }) => mergeHeaders(attr, value)}
            >
              {listHeader.map(({ column }, index) => (
                <MenuItem key={index} value={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Fragment>
    );
  }

  return (
    <Modal
      showModal={props.open}
      onClose={() => _onClose()}
      footer={Footer()}
      header="Importador arquivo"
      size="md"
    >
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepButton disabled onClick={() => setActiveStep(index)}>
              {step}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid style={{ padding: '1rem 5rem' }} container spacing={3}>
        {activeStep === 0 && (
          <Fragment>
            <Grid item xs={12}>
              <Alert variant="filled" severity="info">
                O arquivo não pode conter mais que 10MB
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Dropzone
                onChange={files => onChangeFile(files)}
                acceptedFiles={[
                  '.csv',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ]}
                useChipsForPreview
                filesLimit={1}
                clearOnUnmount={false}
                style={{ backgroundColor: '#fff' }}
                dropzoneParagraphClass={`ds-text`}
                dropzoneText="Selecione ou arraste um arquivo"
                previewText="Arquivo"
                showAlerts={false}
                showPreviews
                showFileNamesInPreview
                showPreviewsInDropzone={false}
                previewChipProps={{ icon: <DescriptionIcon /> }}
                maxFileSize={30000000}
              />
            </Grid>
          </Fragment>
        )}

        {activeStep === 1 && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                color="primary"
                variant="h5"
              >
                Identificador de atualização
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small">
                <InputLabel id="id-label">Identificador</InputLabel>
                <Select
                  displayEmpty
                  autoWidth
                  labelId="id-label"
                  defaultValue="documento"
                  options={listHeader.map(header => header.column)}
                  onChange={({ target: { value } }) => setKeyImport(value)}
                >
                  {listHeader.map((defHeader, index) => (
                    <MenuItem key={index} value={defHeader.column}>
                      {defHeader.column}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                color="primary"
                variant="h5"
              >
                Match das colunas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {props.listDefaultHeaders.map((header, key) =>
                  ContentCheckbox(header, key)
                )}
              </Grid>
            </Grid>
          </Fragment>
        )}

        {activeStep === 2 && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                color="primary"
                variant="h5"
              >
                Regras
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MyRadio.ContentRadio
                name="rules1"
                defaultValue="update"
                onChange={({ target: { value } }) => setRule(value)}
              >
                <MyRadio.Radio
                  value="update"
                  label="Substituir valores existentes"
                />
              </MyRadio.ContentRadio>
            </Grid>
          </Fragment>
        )}

        {activeStep === 3 && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                color="primary"
                variant="h5"
              >
                Review
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                justifyContent="space-between"
                height="40vh"
              >
                <Box>
                  <strong>Tipo de importação: &nbsp;</strong>
                  <span>Dados cadastrais</span>
                </Box>
                <Box>
                  <strong>Arquivo: &nbsp;</strong>
                  <span
                    onClick={() => window.open(props.attrFile.link, '_blank')}
                    style={{
                      fontSize: '11pt',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {props.attrFile.file_name}&nbsp;
                  </span>
                </Box>
                <Box>
                  <strong>Identificador: &nbsp;</strong>
                  <span>{keyImport}</span>
                </Box>
                <Box>
                  <strong>Regra: &nbsp;</strong>
                  <span>{setRuleImport(ruleImport)}</span>
                </Box>
                <Box>
                  <strong>Quantidade de linhas: &nbsp;</strong>
                  <span>{props.attrFile.file_length}</span>
                </Box>
                <Box>
                  <strong>Colunas que serão importadas: &nbsp;</strong>
                  <Grid container spacing={3}>
                    {customHeaders.map((header, key) => (
                      <Fragment key={key}>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                          <span style={{ fontSize: '12pt' }}>
                            {header.label}
                          </span>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                          <ArrowForward
                            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                          />
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                          <span style={{ fontSize: '12pt' }}>
                            {header.column}
                          </span>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Modal>
  );
}

const mapStateToProps = ({ ImportacaoReducer }) => ({
    ...ImportacaoReducer,
  }),
  mapDispatchToProps = { postFileHeader };

export default connect(mapStateToProps, mapDispatchToProps)(ImportFileSales);
