import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';

import { useStyles } from '../styles/dialogs';

export default function DialogWarningVideoInPdf({ isOpen, onClose, onDownloadPDF }) {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} dividers={true} className={classes.wrapDialog}>
      <DialogTitle className={classes.dialogTitle}>Atenção!</DialogTitle>
      <DialogContent className={classes.wrapContent}>
        <DialogContent className={classes.content01}>
          O formato PDF não aceita vídeos, apenas imagens. Seu download será feito apenas com as
          imagens selecionadas.
        </DialogContent>

        <DialogContent className={classes.content02}>
          Para baixar vídeos, selecione a opção ".mp4".
        </DialogContent>
      </DialogContent>

      <Divider className={classes.row} />

      <DialogActions disableSpacing className={classes.wrapActions}>
        {/* <div > */}
        <Button className={classes.cancel} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className={classes.confirm}
          variant="contained"
          color="primary"
          onClick={() => {
            onDownloadPDF();
            onClose();
          }}
        >
          Ok, baixar PDF
        </Button>
        {/* // </div> */}
      </DialogActions>
    </Dialog>
  );
}

