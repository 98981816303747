import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import General from './components/General';

function Sites() {
  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box pt={4}>
            <Typography variant="h3" noWrap>
              Aplicativos
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <General />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Sites;
