const loadState = stateName => {
  try {
    const returnedValue = localStorage.getItem(`DS@state_${stateName}`);
    return returnedValue || undefined;
  } catch (err) {
    return undefined;
  }
};

const saveState = (stateName, value) => {
  try {
    localStorage.setItem(`DS@state_${stateName}`, value);
  } catch {
    // ignore write
  }
};

const getSelectedState = loadState('selectedModule');

const INITIAL_STATE = {
  pvd: null,
  template: null,
  editor: null,
  selectedModule: getSelectedState || null,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_PVD':
      return { ...state, template: action.payload };
    case 'SET_TEMPLATE':
      return { ...state, template: action.payload };
    case 'setEditor':
      return { ...state, editor: action.payload };
    case 'SET_SELECTED_MODULE':
      saveState('selectedModule', action.payload);
      return { ...state, selectedModule: action.payload };
    default:
      return state;
  }
};
