import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

export default function MediaCard(props) {
  const useStyles = makeStyles({
    root: {
      width: props.width || 150,
    },
    media: {
      height: props.height || 220,
    },
  });

  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={props.onClick} style={props.style}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image || "https://datasalesio-imagens.s3.amazonaws.com/rsz_gradient.jpg"}
          title="ImageCard"
        />
        <CardContent>
        <div style={{ textAlign: 'center'}}>
            <span>
                {props.descricao}
            </span>
        </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
