import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { useState, useEffect } from 'react';
import api from '../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../utils/helpers/Functions';
import {
  DATASALES_CRM_API,
  AMBIENTE,
} from '../../../../utils/helpers/Constants';
import { useSnackbar } from 'notistack';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
  fileIput: {
    display: 'none',
  },
  avatar: { background: theme.palette.primary.main },
  actions: {
    justifyContent: 'flex-end',
  },
  preview: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));

function General() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationSettings, setApplicationSettings] = useState({
    about: null,
    privacy_policy: null,
    logo_url: null,
    site_url: null,
    facebook_url: null,
    instagram_url: null,
    twitter_url: null,
    youtube_url: null,
    sales_is_active: false,
    banners_is_active: false,
    booklets_is_active: false,
    offers_is_active: false,
    fav_icon_url: null,
  });

  function handleChange(state, value) {
    setApplicationSettings(prevState => ({ ...prevState, [state]: value }));
  }

  async function handleLogoChange(event, imageType = 'logo') {
    try {
      enqueueSnackbar('Salvando alterações', {
        variant: 'info',
      });

      const file = await toBase64(event.target.files[0]);

      if (applicationSettings.id) {
        const response = await api(
          `${DATASALES_CRM_API}application-management-${AMBIENTE}/application-settings/${applicationSettings.id}`,
          { file: { ...file, imageType } },
          'PUT',
          null,
          null,
          null
        );

        const { logo_url } = response.content;

        setApplicationSettings(prevState => ({ ...prevState, logo_url }));

        enqueueSnackbar('Alterações salvas com sucesso', {
          variant: 'success',
        });

        return;
      }

      const response = await api(
        `${DATASALES_CRM_API}application-management-${AMBIENTE}/application-settings`,
        { file: { ...file, imageType } },
        'POST',
        null,
        null,
        null
      );

      const { logo_url } = response.content;

      setApplicationSettings(prevState => ({ ...prevState, logo_url }));

      enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' });
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit() {
    try {
      setIsSubmitting(true);

      enqueueSnackbar('Salvando alterações', {
        variant: 'info',
      });

      if (applicationSettings.privacy_policy) {
        await api(
          `${DATASALES_CRM_API}application-management-${AMBIENTE}/privacy-policies`,
          { privacyPolicy: applicationSettings.privacy_policy },
          'PUT',
          null,
          null,
          null
        );
      }

      if (applicationSettings.id) {
        await api(
          `${DATASALES_CRM_API}application-management-${AMBIENTE}/application-settings/${applicationSettings.id}`,
          applicationSettings,
          'PUT',
          null,
          null,
          null
        );

        enqueueSnackbar('Alterações salvas com sucesso', {
          variant: 'success',
        });

        return;
      }

      const response = await api(
        `${DATASALES_CRM_API}application-management-${AMBIENTE}/application-settings`,
        applicationSettings,
        'POST',
        null,
        null,
        null
      );

      const { content } = response;

      setApplicationSettings(content);

      enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Erro', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function fetchApplicationSettings() {
      try {
        const response = await api(
          `${DATASALES_CRM_API}application-management-${AMBIENTE}/application-settings`
        );

        const privacy_policy = await api(
          `${DATASALES_CRM_API}application-management-${AMBIENTE}/privacy-policies`
        ).then(resp => resp.content.privacyPolicy);

        setApplicationSettings({ ...response.content, privacy_policy });
      } catch (err) {
        console.log(err);
      }
    }

    fetchApplicationSettings();
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title="Sobre"
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <DescriptionIcon />
              </Avatar>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              {applicationSettings.logo_url && (
                <Grid item xs={2}>
                  <div>
                    <img
                      alt="logo"
                      className={classes.preview}
                      src={applicationSettings.logo_url}
                    />
                  </div>
                </Grid>
              )}
              <Grid item>
                <Typography gutterBottom>Logo</Typography>
                <input
                  id="contained-button-file"
                  className={classes.fileIput}
                  type="file"
                  onChange={handleLogoChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    color="default"
                    size="large"
                    startIcon={<Icon>file_upload</Icon>}
                  >
                    {applicationSettings.logo_url ? 'Atualizar logo' : 'Upload'}
                  </Button>
                </label>
              </Grid>

              {/* {applicationSettings.fav_icon_url && (
                <Grid item xs={2}>
                  <div>
                    <img
                      alt="logo"
                      className={classes.preview}
                      src={applicationSettings.fav_icon_url}
                    />
                  </div>
                </Grid>
              )}
              <Grid item>
                <Typography gutterBottom>Ícone</Typography>
                <input
                  id="contained-button-file"
                  className={classes.fileIput}
                  type="file"
                  onChange={e => handleLogoChange(e, 'icon')}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    color="default"
                    size="large"
                    startIcon={<Icon>file_upload</Icon>}
                  >
                    {applicationSettings.fav_icon_url
                      ? 'Atualizar ícone'
                      : 'Upload'}
                  </Button>
                </label>
              </Grid> */}

              <Grid item xs={12}>
                <Typography gutterBottom>Venda Online</Typography>
                <Switch
                  checked={applicationSettings.sales_is_active}
                  onChange={event => {
                    handleChange('sales_is_active', event.target.checked);
                  }}
                  color="primary"
                  name="sales_is_active"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom>Banners</Typography>
                <Switch
                  checked={applicationSettings.banners_is_active}
                  onChange={event => {
                    handleChange('banners_is_active', event.target.checked);
                  }}
                  color="primary"
                  name="banners_is_active"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom>Encartes</Typography>
                <Switch
                  checked={applicationSettings.booklets_is_active}
                  onChange={event => {
                    handleChange('booklets_is_active', event.target.checked);
                  }}
                  color="primary"
                  name="booklets_is_active"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom>Ofertas</Typography>
                <Switch
                  checked={applicationSettings.offers_is_active}
                  onChange={event => {
                    handleChange('offers_is_active', event.target.checked);
                  }}
                  color="primary"
                  name="offers_is_active"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="description"
                  label="Texto"
                  variant="outlined"
                  multiline
                  rows={8}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={applicationSettings.about}
                  onChange={event => {
                    handleChange('about', event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="privacy_policy"
                  label="Politica de Privacidade"
                  variant="outlined"
                  multiline
                  value={applicationSettings.privacy_policy}
                  rows={8}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={event => {
                    handleChange('privacy_policy', event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title="Redes Sociais"
            avatar={
              <Avatar className={classes.avatar} variant="rounded">
                <LanguageIcon />
              </Avatar>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="website"
                  label="Site"
                  variant="outlined"
                  fullWidth
                  value={applicationSettings.site_url}
                  onChange={event => {
                    handleChange('site_url', event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="facebook"
                  label="Facebook"
                  variant="outlined"
                  fullWidth
                  value={applicationSettings.facebook_url}
                  onChange={event => {
                    handleChange('facebook_url', event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="instagram"
                  label="Instagram"
                  variant="outlined"
                  fullWidth
                  value={applicationSettings.instagram_url}
                  onChange={event => {
                    handleChange('instagram_url', event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="twitter"
                  label="Twitter"
                  variant="outlined"
                  fullWidth
                  value={applicationSettings.twitter_url}
                  onChange={event => {
                    handleChange('twitter_url', event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="youtube"
                  label="YouTbe"
                  variant="outlined"
                  fullWidth
                  value={applicationSettings.youtube_url}
                  onChange={event => {
                    handleChange('youtube_url', event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <YouTubeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default General;
