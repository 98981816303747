import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { sendSMS } from '../../actions/actionsPeople';
import ModalEnvioSMS from './ModalEnvioSMS';
import { MyButton } from '../../../../utils/components';
import './Perfil.css';

class EnvioSMS extends Component {
  constructor() {
    super();
    this.state = { showModal: false };
  }
  render() {
    const { number, idPerson } = this.props;
    const { showModal } = this.state;
    return (
      <Fragment>
        <MyButton
          bsstyle="primary"
          onClick={() => this.setState({ showModal: true })}
          bsSize="xs"
          disabled={!number}
        >
          SMS
        </MyButton>
        <ModalEnvioSMS
          idPerson={idPerson}
          number={number}
          onClose={() => this.setState({ showModal: false })}
          onSendSMS={this.props.sendSMS}
          open={showModal}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  sendSMS,
};

export default connect(
  null,
  mapDispatchToProps,
)(EnvioSMS);
