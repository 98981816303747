import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { inputMoney, toBase64 } from 'utils/helpers/Functions';
import { Celular3, CEP, removeLetters } from 'utils/helpers/Masks';
import * as Yup from 'yup';

import EmptyImage from '../../../../../../assets/icons/marketing/EmptyImage.svg';
import EmptyImageRed from '../../../../../../assets/icons/marketing/EmptyImageRed.svg';
import {
  BoxCheckBox,
  ButtonConfig,
  FormControlFormPag,
  SectionOne,
  SectionTwoText,
  TextFieldForms,
  TypographySubTitle,
  TypographyTitle
} from './style';

interface NewStoreProps {
  showModal: boolean;
  onSave: () => {};
  onClose: () => {};
}

export default function ModalAddNewStore({
  showModal,
  onSave,
  onClose
}: NewStoreProps): JSX.Element {
  const [base64, setBase64] = useState(null);
  const [moreSettings, setMoreSettings] = useState(false);
  const options = [
    { label: 'Delivery', value: 1 },
    { label: 'Drive', value: 2 },
    { label: 'Delivery/Drive', value: 3 }
  ];

  const [salvaLoja, setAttrSalvaLoja] = useState({
    id_loja: null,
    nome_loja: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    tipo_pedido: null,
    forma0_pagamento: [],
    frete: '0',
    taxa_servico: '0',
    maximo_item: '0',
    minimo_compra: '0',
    descricao: '',
    whatsapp_phone_number: '',
    aceita_dinheiro: false,
    aceita_debito: false,
    aceita_credito: false,
    aceita_boleto: false,
    aceita_vr: false,
    aceita_pix: false,
    chave_pix: '',
    logo1: null,
    naoRemoverFundo: true
  });

  function limpar() {
    setAttrSalvaLoja({
      id_loja: null,
      nome_loja: '',
      cep: '',
      endereco: '',
      bairro: '',
      cidade: '',
      uf: '',
      numero: '',
      tipo_pedido: null,
      forma0_pagamento: [],
      frete: '0',
      taxa_servico: '0',
      maximo_item: '0',
      minimo_compra: '0',
      descricao: '',
      whatsapp_phone_number: '',
      aceita_dinheiro: false,
      aceita_debito: false,
      aceita_credito: false,
      aceita_boleto: false,
      aceita_vr: false,
      aceita_pix: false,
      chave_pix: '',
      logo1: null,
      naoRemoverFundo: true
    });
  }

  const handleChange = event => {
    setAttrSalvaLoja(prevState => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  };

  const NEW_STORE_VALIDATION = Yup.object().shape({
    name: Yup.string().nullable().required('*Campo obrigatório'),
    whatsapp: Yup.string().nullable().required('*Campo obrigatório'),
    cep: Yup.string().nullable().required('*Campo obrigatório'),
    uf: Yup.string().nullable().required('*Campo obrigatório'),
    address: Yup.string().nullable().required('*Campo obrigatório'),
    district: Yup.string().nullable().required('*Campo obrigatório'),
    city: Yup.string().nullable().required('*Campo obrigatório'),
    base64: Yup.mixed().nullable().required('Insira uma imagem')
  });

  const handleSubmit = () => {
    onSave(salvaLoja);
    // formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      base64: null,
      name: null,
      whatsapp: null,
      cep: null,
      uf: null,
      address: null,
      district: null,
      city: null
    },
    validationSchema: NEW_STORE_VALIDATION,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSubmit
  });

  const handleClose = () => {
    onClose();
    setBase64(null);
    limpar();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Dialog
          open={showModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          fullWidth
        >
          <DialogContent sx={{}}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                width={'50%'}
                height={'100%'}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={Boolean(formik.errors.base64) ? '#D54D4D0D' : '#F5f5f5'}
                  //border={Boolean(formik.errors.base64) ? '1px solid #D54D4D' : null}
                  width="100%"
                  height="380px"
                >
                  <img
                    src={
                      base64 ? base64 : Boolean(formik.errors.base64) ? EmptyImageRed : EmptyImage
                    }
                    alt={'image to up'}
                    style={{
                      width: base64 ? '100%' : null,
                      maxHeight: base64 ? '100%' : null,
                      objectFit: 'contain'
                    }}
                  />
                </Box>
                <Box alignSelf="flex-start">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={async e => {
                      const imageEdit = await toBase64(e.target.files![0]);
                      setBase64(imageEdit.base64);
                      setAttrSalvaLoja({ ...salvaLoja, logo1: imageEdit.base64 });
                      formik.setFieldValue('base64', imageEdit.base64);
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        marginTop: '16px',
                        background: '#F5F5F5',
                        color: '#2813AD',
                        border: 0,

                        '&:hover': {
                          border: 0
                        }
                      }}
                    >
                      Importar imagem
                      {base64 && <DoneAllIcon style={{ marginLeft: 10 }} />}
                    </Button>
                  </label>
                  {base64 && (
                    <BoxCheckBox alignSelf="flex-start">
                      <Checkbox
                        onChange={({ target }) => {
                          setAttrSalvaLoja({
                            ...salvaLoja,
                            naoRemoverFundo: Boolean(!target.checked)
                          });
                        }}
                        name="Remover fundo da imagem."
                      />{' '}
                      Remover fundo da imagem.
                    </BoxCheckBox>
                  )}
                </Box>
              </Box>
              <Box width={'60%'} marginLeft="32px">
                <div>
                  <Typography fontSize={16} fontWeight={500} lineHeight={3} marginBottom={0}>
                    Criação da loja
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={1.4}
                    marginBottom={3}
                    color="#616161"
                  >
                    Preencha as informações da sua loja.
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    color="info"
                    error={Boolean(formik.errors.name)}
                    helperText={Boolean(formik.errors.name) && formik.errors.name}
                    onChange={({ target: { value } }) => {
                      // setName(value);
                      setAttrSalvaLoja({ ...salvaLoja, nome_loja: value });
                      formik.setFieldValue('name', value);
                    }}
                    sx={{
                      padding: '0px 0px 20px 0px',
                      width: '100%'
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Whatsapp"
                    placeholder="Whatsapp"
                    variant="outlined"
                    color="info"
                    error={Boolean(formik.errors.whatsapp)}
                    helperText={Boolean(formik.errors.whatsapp) && formik.errors.whatsapp}
                    onChange={({ target: { value } }) => {
                      // setName(value);
                      setAttrSalvaLoja({
                        ...salvaLoja,
                        whatsapp_phone_number: value
                      });
                      formik.setFieldValue('whatsapp', value);
                    }}
                    sx={{
                      padding: '0px 0px 20px 0px',
                      width: '100%'
                    }}
                  />

                  <Box
                    sx={{
                      padding: '0px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="CEP"
                      placeholder="CEP"
                      variant="outlined"
                      color="info"
                      error={Boolean(formik.errors.cep)}
                      helperText={Boolean(formik.errors.cep) && formik.errors.cep}
                      onChange={({ target: { value } }) => {
                        // setName(value);
                        setAttrSalvaLoja({ ...salvaLoja, cep: CEP(value) });
                        formik.setFieldValue('cep', value);
                      }}
                      sx={{
                        // padding: '0px 0px 20px 0px',
                        width: '48%'
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="UF"
                      placeholder="UF"
                      variant="outlined"
                      color="info"
                      error={Boolean(formik.errors.uf)}
                      helperText={Boolean(formik.errors.uf) && formik.errors.uf}
                      onChange={({ target: { value } }) => {
                        // setName(value);
                        setAttrSalvaLoja({ ...salvaLoja, uf: value });
                        formik.setFieldValue('uf', value);
                      }}
                      sx={{
                        // padding: '0px 0px 20px 0px',
                        width: '48%'
                      }}
                    />
                  </Box>

                  <TextField
                    id="outlined-basic"
                    label="Endereço"
                    placeholder="Endereço"
                    variant="outlined"
                    color="info"
                    error={Boolean(formik.errors.address)}
                    helperText={Boolean(formik.errors.address) && formik.errors.address}
                    onChange={({ target: { value } }) => {
                      // setName(value);
                      setAttrSalvaLoja({ ...salvaLoja, endereco: value });
                      formik.setFieldValue('address', value);
                    }}
                    sx={{
                      padding: '0px 0px 20px 0px',
                      width: '100%'
                    }}
                  />
                  <SectionTwoText>
                    <TextField
                      id="outlined-basic"
                      label="Bairro"
                      placeholder="Bairro"
                      variant="outlined"
                      color="info"
                      error={Boolean(formik.errors.district)}
                      helperText={Boolean(formik.errors.district) && formik.errors.district}
                      onChange={({ target: { value } }) => {
                        // setName(value);
                        setAttrSalvaLoja({ ...salvaLoja, bairro: value });
                        formik.setFieldValue('district', value);
                      }}
                    />
                    <TextField
                      onChange={({ target: { value } }) =>
                        setAttrSalvaLoja({ ...salvaLoja, numero: value })
                      }
                      label="Número"
                      className="form-control"
                      autoComplete="none"
                      color="info"
                    />
                  </SectionTwoText>
                  <TextField
                    id="outlined-basic"
                    label="Cidade"
                    placeholder="Cidade"
                    variant="outlined"
                    color="info"
                    error={Boolean(formik.errors.city)}
                    helperText={Boolean(formik.errors.city) && formik.errors.city}
                    onChange={({ target: { value } }) => {
                      // setName(value);
                      setAttrSalvaLoja({ ...salvaLoja, cidade: value });
                      formik.setFieldValue('city', value);
                    }}
                    sx={{
                      padding: '0px 0px 20px 0px',
                      width: '100%',
                      marginTop: '20px'
                    }}
                  />
                  {moreSettings && (
                    <SectionOne>
                      <Divider style={{ marginBottom: '24px', color: '#CFCFCF' }} />
                      <TypographyTitle sx={{ fontWeight: '500' }}>
                        Configurações de site
                      </TypographyTitle>

                      <TypographySubTitle>Configure as informações de site</TypographySubTitle>

                      <FormControlFormPag color="primary" component="fieldset">
                        <FormLabel component="legend">Formas de pagamento</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_dinheiro}
                                onChange={handleChange}
                                name="aceita_dinheiro"
                                color="info"
                              />
                            }
                            label="Dinheiro"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_debito}
                                onChange={handleChange}
                                name="aceita_debito"
                                color="info"
                              />
                            }
                            label="Debito"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_credito}
                                onChange={handleChange}
                                name="aceita_credito"
                                color="info"
                              />
                            }
                            label="Credito"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_boleto}
                                onChange={handleChange}
                                name="aceita_boleto"
                                color="info"
                              />
                            }
                            label="Boleto"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_vr}
                                onChange={handleChange}
                                name="aceita_vr"
                                color="info"
                              />
                            }
                            label="VR"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={salvaLoja.aceita_pix}
                                onChange={handleChange}
                                name="aceita_pix"
                                color="info"
                              />
                            }
                            label="PIX"
                          />
                        </FormGroup>
                      </FormControlFormPag>

                      <Autocomplete
                        freeSolo={true}
                        options={options}
                        getOptionLabel={option => option.label}
                        onChange={(e, value) => {
                          setAttrSalvaLoja({
                            ...salvaLoja,
                            tipo_pedido: value.value
                          });
                          //formik.setFieldValue('tipo_pedido', value);
                        }}
                        defaultValue={options[parseInt(salvaLoja.tipo_pedido) - 1]}
                        renderInput={params => (
                          <TextFieldForms
                            {...params}
                            id="outlined-basic"
                            label="Tipo de pedido"
                            variant="outlined"
                            color="info"
                            //defaultValue={dataStore.tipo_pedido}
                            //classes={{root:classes.formFields}}
                          />
                        )}
                      />
                      <SectionTwoText sx={{ marginTop: '32px' }}>
                        <TextFieldForms
                          id="outlined-basic"
                          label="Taxa de Frete"
                          variant="outlined"
                          value={salvaLoja.frete}
                          color="info"
                          onChange={({ target: { value } }) => {
                            setAttrSalvaLoja({ ...salvaLoja, frete: inputMoney(value) });
                            //formik.setFieldValue('frete', value);
                          }}
                          //classes={{root:classes.formFields}}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}
                        />
                        <TextFieldForms
                          id="outlined-basic"
                          label="Taxa de serviço"
                          variant="outlined"
                          color="info"
                          value={salvaLoja.taxa_servico}
                          onChange={({ target: { value } }) => {
                            setAttrSalvaLoja({
                              ...salvaLoja,
                              taxa_servico: inputMoney(value)
                            });
                            //formik.setFieldValue('taxa_servico', value);
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}
                          //classes={{root:classes.formFields}}
                        />
                      </SectionTwoText>

                      <SectionTwoText sx={{ marginTop: '32px' }}>
                        <TextFieldForms
                          id="outlined-basic"
                          label="Quantidade máxima de itens"
                          variant="outlined"
                          color="info"
                          value={salvaLoja.maximo_item}
                          onChange={({ target: { value } }) => {
                            setAttrSalvaLoja({
                              ...salvaLoja,
                              maximo_item: removeLetters(value)
                            });
                            //formik.setFieldValue('maximo_item', value);
                          }}
                          //classes={{root:classes.formFields}}
                        />

                        <TextFieldForms
                          id="outlined-basic"
                          label="Valor mínimo de compra"
                          variant="outlined"
                          value={salvaLoja.minimo_compra}
                          color="info"
                          onChange={({ target: { value } }) => {
                            setAttrSalvaLoja({
                              ...salvaLoja,
                              minimo_compra: inputMoney(value)
                            });

                            //formik.setFieldValue('minimo_compra', value);
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}
                          //classes={{root:classes.formFields}}
                        />
                      </SectionTwoText>
                      <TextFieldForms
                        id="outlined-basic"
                        label="Chave PIX"
                        variant="outlined"
                        color="info"
                        defaultValue={salvaLoja.chave_pix}
                        sx={{ marginTop: '32px' }}
                        onChange={({ target: { value } }) => {
                          setAttrSalvaLoja({
                            ...salvaLoja,
                            chave_pix: value
                          });

                          //formik.setFieldValue('chave_pix', value);
                        }}

                        //classes={{root:classes.formFields}}
                      />

                      <TextField
                        value={salvaLoja.descricao}
                        color="info"
                        sx={{ marginTop: '32px', width: '100%' }}
                        onChange={({ target: { value } }) =>
                          setAttrSalvaLoja({
                            ...salvaLoja,
                            descricao: value
                          })
                        }
                        label="Descrição"
                        autoComplete="none"
                        multiline
                      />
                    </SectionOne>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 0px 20px 0px'
                    }}
                  >
                    <Button onClick={handleClose} sx={{ color: '#616161' }}>
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => {
                        onSave(salvaLoja);
                        setBase64(null);
                        limpar();
                      }}
                      sx={{
                        marginLeft: '16px',
                        background: '#2813AD',
                        color: '#FFF',
                        border: 0,
                        width: '80px',
                        height: '38px',

                        '&:hover': {
                          border: 0,
                          background: '#2813AD'
                        }
                      }}
                    >
                      Salvar
                    </Button>
                    {/* <Button type='submit' onClick={handleSubmit}> Arrocha</Button> */}
                  </Box>

                  {!moreSettings && (
                    <ButtonConfig
                      onClick={() => {
                        setMoreSettings(!moreSettings);
                      }}
                      sx={{
                        textDecorationLine: 'underline',
                        color: '#127FFF',
                        '&:hover': {
                          background: '#fff',
                          textDecorationLine: 'underline'
                        }
                      }}
                    >
                      Ver mais configurações
                    </ButtonConfig>
                  )}
                  {moreSettings && (
                    <ButtonConfig
                      onClick={() => {
                        setMoreSettings(!moreSettings);
                      }}
                      sx={{
                        textDecorationLine: 'underline',
                        color: '#127FFF',
                        '&:hover': {
                          background: '#fff',
                          textDecorationLine: 'underline'
                        }
                      }}
                    >
                      Ver menos
                    </ButtonConfig>
                  )}
                </div>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </form>
  );
}

