import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const loadingProdutos = () => dispatch =>
  dispatch({ type: "LOADING_PRODUTOS" });
const loadingFamilias = () => dispatch =>
  dispatch({ type: "LOADING_FAMILIAS" });
const loadingPhoto = () => dispatch =>
  dispatch({ type: "LOADING_PHOTO" });  

export const getProdutos = data => dispatch => {
  dispatch(loadingProdutos());
  Fetch(
    // `https://dr935ng0xk.execute-api.us-east-1.amazonaws.com/dev/crm/getProdutos`,
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getProdutos`,
    data
  ).then(payload => dispatch({ type: "GET_PRODUTOS", payload: payload }));
};

export const getFamalias = () => async dispatch => {
  dispatch(loadingFamilias());
  const payload = await Fetch(
    `https://4hx27tk197.execute-api.us-east-1.amazonaws.com/dev/crm/produto/getFamilias`
    // `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // {
    //   idStores
    // }
  );
  dispatch({ type: "GET_FAMILIAS", payload });
};

export const putProduto = data => dispatch => {
  dispatch(getProdutos());
  Fetch(
    // `https://dr935ng0xk.execute-api.us-east-1.amazonaws.com/dev/crm/getProdutos`,
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/putProduto`,
    { produto: data },
    "PUT"
  ).then(payload => dispatch({ type: "PUT_PRODUTO", payload: payload }));
};

export const postProdutoImagemTabloide = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch(loadingPhoto());
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postProdutoImagem`,
        data,
        'POST'
      );
      dispatch({ type: 'PRODUTO_IMAGEM_', payload: response.image, data: data });
      resolve(response.image);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
