import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as TrendingUpIcon } from './Trending_Up.svg';
import { ReactComponent as TrendingDownIcon } from './Trending_Down.svg';
import React from 'react';

const defaultFont = {
  fontFamily: "'Montserrat', sans-serif",
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  divRoot2: {
    width: '100%',
  },
  border: {
    border: '1px solid #B4B4B4',
  },
  borderTop: {
    borderTop: '1px solid #B4B4B4',
  },
  title1: {
    ...defaultFont,
    margin: '20px 10px 10px 8px',
    fontWeight: 500,
    fontSize: 16,
    color: '#312A5E',
  },
  title2: {
    ...defaultFont,
    margin: '8px 10px 16px 8px',
    fontWeight: 500,
    fontSize: 14,
    color: '#616161',
  },
  monthTitle: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: 14,
    color: '#B4B4B4',
  },
  bar: {
    width: 40,
    backgroundColor: '#312A5E',
  },
  divBar: {
    display: 'flex',
    height: 220,
    justifyContent: 'space-around',
    alignItems: 'end',
    paddingTop: 20,
  },
  divMonthLabels: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  divCard: {
    position: 'relative',
    borderRadius: 4,
  },
  trendingIcon: {
    position: 'absolute ',
    right: 13,
    top: 13,
  },
  circular: {
    'align-self': 'center',
    width: 40,
  },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    'box-shadow': '5px 5px 22px -3px rgba(0,0,0,0.78)',
  },
}));

const bar = (value, max) => ({
  height: (value / max) * 100 + '%',
});

export default ({ example, ...props }) => {
  const classes = useStyles();

  // PopOver init
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [textPop, setTextPop] = React.useState([]);

  const handlePopoverOpen = (event, value) => {
    setAnchorEl(event.currentTarget);
    setTextPop([value.label, value.value]);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTextPop([]);
  };

  const open = Boolean(anchorEl);
  // PopOver end

  const months = props.array;

  const maxMonth = months.length
    ? Math.max(...months.map(value => value.value))
    : [];

  let trendingUp = <TrendingUpIcon class={classes.trendingIcon} />;
  if (months && months.length > 1) {
    trendingUp =
      months[months.length - 1].value > months[months.length - 2].value;

    trendingUp = trendingUp ? (
      <TrendingUpIcon class={classes.trendingIcon} />
    ) : (
      <TrendingDownIcon class={classes.trendingIcon} />
    );
  }

  const popover = (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      style={{ textAlign: 'center' }}
    >
      <Typography>{textPop[0]}</Typography>
      <Typography>{textPop[1]}</Typography>
    </Popover>
  );

  const barComponent = value => (
    <div
      class={classes.bar}
      style={bar(value.value, maxMonth)}
      onMouseEnter={e => handlePopoverOpen(e, value)}
      onMouseLeave={handlePopoverClose}
    ></div>
  );

  return (
    <div class={classes.root}>
      <div class={classes.divRoot2}>
        <div class={classes.border + ' ' + classes.divCard}>
          {trendingUp}
          <div class={classes.title1}>{props.text1}</div>
          <div class={classes.title2}>{props.text2}</div>

          <div class={classes.borderTop + ' ' + classes.divBar}>
            {!props.loading && months && months.length > 0 && (
              <>
                {months.map(value => barComponent(value))}
                {popover}
              </>
            )}
            {!props.loading && months && months.length <= 0 && (
              <Typography>Nenhum dado encontrado.</Typography>
            )}
            {props.loading && (
              <img
                src={
                  'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loading-buffering.gif'
                }
                class={classes.circular}
              />
            )}
          </div>
        </div>

        <div class={classes.divMonthLabels}>
          {months.map(value => (
            <div class={classes.monthTitle}>{value.label}</div>
          ))}
        </div>
      </div>
    </div>
  );
};
