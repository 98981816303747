const INITIAL_STATE = {
  familias: [],
  produtos: [],
  cashback: [],
  info_cashback: [],
  isLoadingCashback: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_OFERTA_CASHBACK":
      return { ...state, cashback: action.payload, isLoadingCashback: false };
    case "GET_INFO_CASHBACK":
      return {
        ...state,
        info_cashback: action.payload,
        isLoadingCashback: false
      };
    case "GET_FAMILIAS":
      return { ...state, familias: action.payload, isLoadingCashback: false };
    case "GET_PRODUTOS":
      return { ...state, produtos: action.payload, isLoadingCashback: false };
    case "LOADING_CASHBACK":
      return { ...state, isLoadingCashback: true };
    default:
      return state;
  }
};
