import api from '../../../services/api';
import _Alert from '../../../utils/helpers/Alert';
import { API_URL_BACKOFFICE } from '../../../utils/helpers/Constants';
import { API_URL_DEV_BACKOFFICE } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import history from '../../../utils/routes/history';

const isLoadingLogin = () => dispatch => dispatch({ type: 'LOADING_LOGIN' });

const _alert = new _Alert();

export const onChangeLogin = (key, string) => dispatch =>
    dispatch({ type: 'ON_CHANGE_LOGIN', payload: { key, string } }),
  setLogged = logged => dispatch => dispatch({ type: 'SET_LOGGED', payload: { logged } }),
  setShowBtnMenu = payload => dispatch => dispatch({ type: 'SHOW_BTN_MENU', payload }),
  setCurrentModule = module => dispatch =>
    dispatch({ type: 'SET_CURRENT_MODULE', payload: { module } }),
  setCurrentSetting = isSettings => dispatch =>
    dispatch({ type: 'SET_CURRENT_SETTINGS', payload: { isSettings } }),
  setCurrentServices = services => dispatch =>
    dispatch({ type: 'SET_CURRENT_SERVICES', payload: { services } }),
  login = user => dispatch => {
    dispatch(isLoadingLogin());
    // localStorage.clear();
    // eslint-disable-next-line no-undef
    return new Promise(async resolve => {
      const email = user.user;
      const password = user.pass;
      const requestInfo = { email, password, webapp: true };
      try {
        // const payload = await Fetch(`${API_URL_BACKOFFICE}login`, requestInfo, 'POST');
        const payload = await Fetch(`${API_URL_DEV_BACKOFFICE}login`, requestInfo, 'POST');
        //console.log('LOGIN => ', payload);
        if (payload.token) {
          dispatch({
            type: 'USER_LOGGED',
            payload
          });

          if (email === 'bi@rondelli.com.br') {
            localStorage.setItem('ambient', 'inteligencia');
            history.push('/recomendacoes');
          }

          const newUsers = new Date('2024-04-01T00:00:00.000Z');

          if (payload.company.createdAt >= newUsers) {
            window.location.replace(`https://app.dsmarketing.com.br/sign-as-user/${token}?origin=old`);
          }
          //   api.defaults.headers.common.Authorization = payload.token;
          resolve();
        } else {
          dispatch({
            type: 'USER_LOGGED_ERROR',
            payload
          });
        }
      } catch (err) {
        console.log(err, 'ERR');

        dispatch({
          type: 'USER_LOGGED_ERROR',
          payload: { status: 400 }
        });
      }
    });
  },
  logout = () => ({ type: 'LOG_OUT' }),
  setCurrentModuleData = module => ({ type: 'SET_CURRENT_MODULE', payload: { module } }),
  setCurrentSettingData = isSettings => ({ type: 'SET_CURRENT_SETTINGS', payload: { isSettings } }),
  setCurrentServicesData = services => ({ type: 'SET_CURRENT_SERVICES', payload: { services } });

