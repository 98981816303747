import constants from '../contants';

const INITIAL_STATE = {
  instancias: [],
  lojas: [],
  loadingChatbot: false,
  loadingGatilhos: false,
  statusInstancias: [],
  calendarios: [],
  totaisCalendarios: {},
  contatos: [],
  totaisContatos: 0,
  totaisContatosAtivos: 0,
  totaisContatosInativos: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.LOADING_CHATBOT:
      return { ...state, loadingChatbot: true };
    case constants.LOADING_GATILHOS:
      return { ...state, loadingGatilhos: true };
    case constants.GET_INSTANCIAS:
      return {
        ...state,
        instancias: action.payload.map(instancia => ({
          ...instancia,
          status: { connected: false },
          loja: {},
        })),
        loadingChatbot: false,
      };
    case constants.GET_CONTATOS_INSTANCIA:
      const totaisContatosAtivos = action.payload.filter(contato =>
          Boolean(contato.ativo_envio)
        ).length,
        totaisContatosInativos = action.payload.filter(
          contato => !Boolean(contato.ativo_envio)
        ).length;
      return {
        ...state,
        contatos: action.payload,
        totaisContatos: action.payload.length,
        totaisContatosAtivos,
        totaisContatosInativos,
        loadingChatbot: false,
      };
    case constants.GET_LOJAS:
      return {
        ...state,
        lojas: action.payload,
        loadingChatbot: false,
      };
    case constants.PUT_INSTANCIA:
      return {
        ...state,
        loadingChatbot: false,
      };
    case constants.GET_STATUS_INSTANCIA:
      let newStatusInstancias = { ...state.statusInstancias };
      newStatusInstancias[action.payload.idInstancia] = { ...action.payload };
      return {
        ...state,
        statusInstancias: newStatusInstancias,
      };
    case constants.GET_CALENDARIOS:
      return {
        ...state,
        loadingChatbot: false,
        calendarios: action.payload.calendarios,
        totaisCalendarios: action.payload.totais,
      };
    case constants.SET_GATILHOS:
      let tmp = [...state.calendarios];
      let index = tmp.findIndex(
        calendario => calendario.id === action.calendar_id
      );
      tmp[index] = {
        ...tmp[index],
        gatilhos: [...action.payload],
      };
      return {
        ...state,
        calendarios: [...tmp],
        loadingGatilhos: false,
      };
    case constants.POST_CALENDARIO:
      return {
        ...state,
        loadingChatbot: false,
      };
    case constants.PUT_CALENDARIO:
      return {
        ...state,
        loadingChatbot: false,
      };
    case constants.POST_GATILHO:
      return {
        ...state,
        loadingChatbot: false,
      };
    case constants.DEL_GATILHO:
      return {
        ...state,
        loadingChatbot: false,
      };
    case constants.PUT_GATILHO:
      return {
        ...state,
        loadingChatbot: false,
      };
    default:
      return { ...state, loadingChatbot: false };
  }
};
