import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerGlobal: {
      marginTop: '2.125rem',
      marginBottom: '1.5rem'
    },
    title: {
      fontSize: '1rem !important',
      fontWeight: '600 !important',
      lineHeight: '1.625rem !important',
      color: 'black !important'
    },
    subTitle: {
      fontSize: '0.875rem !important',
      fontWeight: '500 !important',
      lineHeight: '1.25rem !important',
      color: '#616161 !important'
    },
    gridButtonChennels: {
      display: 'flex !important',
      gap: '1.5rem !important'
    }
  })
);
