import { Box, Grid, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  BarChart,
  Card,
  Count,
  MapsComponent,
  MultiBarChart,
  PieChart,
} from '../../../utils/components';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import {
  getClienteMapa,
  getClienteTotalizadores,
  getOverviewAge,
  getOverviewDDD,
  getOverviewGenre,
  getOverviewRegisterDay,
  getOverviewRegisterMonth,
  getTotalCadastros,
  modalPessoa,
} from '../actions/actions';
import WithoutInformation from './WithoutInformation';

function Overview(props) {
  const totalizadores = props.perfilCliente.totalizadores[0],
    clientes = props.perfilCliente.clientes,
    valores = props.perfilCliente.valores;

  useEffect(() => {
    filtroCliente(props.filtroConsulta);
  }, []);

  useEffect(() => {
    const lengthFilter = Object.values(props.filtroConsulta).length;
    if (lengthFilter > 0) {
      filtroCliente(props.filtroConsulta);
    }
  }, [props.filtroConsulta]);

  function filtroCliente(filtro) {
    props.getClienteMapa(filtro);
    props.getClienteTotalizadores(filtro);
    props.getTotalCadastros(filtro);
    props.getOverviewDDD(filtro);
    props.getOverviewGenre(filtro);
    props.getOverviewRegisterDay(filtro);
    props.getOverviewRegisterMonth(filtro);
    props.getOverviewAge(filtro);
  }

  return (
    <Box className={props.classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Count
            title={clientes.total}
            subheader="Cadastros"
            icon="people"
            style={{ marginRight: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Count
            title={totalizadores.celular}
            helpButton
            helpContent={<span>R$ {MoedaBrasil(valores.celular)}</span>}
            subheader="Celular"
            icon="smartphone"
            style={{ marginRight: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Count
            title={totalizadores.email}
            helpButton
            helpContent={<span>R$ {MoedaBrasil(valores.email)}</span>}
            subheader="Email"
            icon="email"
            style={{ marginRight: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Count
            title={totalizadores.endereco}
            subheader="Endereço"
            icon="location_on"
            style={{ marginRight: 20 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Card loading={props.isLoadingOverviewGenre} title="Gêneros" showMore>
            {Boolean(props.overviewGenre.length) ? (
              <PieChart
                nameKey="SEXO"
                dataKey="QUANTIDADE"
                data={props.overviewGenre}
              />
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card loading={props.isLoadingOverviewDDD} title="DDD" showMore>
            {Boolean(props.overviewDDD.length) ? (
              <PieChart
                nameKey="DDD"
                dataKey="QUANTIDADE"
                data={props.overviewDDD}
              />
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card loading={props.isLoadingOverviewAge} title="Idade" showMore>
            {Boolean(props.overviewAge.length) ? (
              <MultiBarChart
                bars={props.overviewAgeBars}
                data={props.overviewAge}
                nameKey="FAIXA"
              />
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            loading={props.isLoadingOverviewRegisterMonth}
            title="Cadastros/mês"
            showMore
          >
            {Boolean(props.overviewRegisterMonth.length) ? (
              <BarChart
                barLegend
                xDataKey="DATE"
                barDataKey="QUANTIDADE"
                data={props.overviewRegisterMonth}
              />
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            loading={props.isLoadingOverviewRegisterDay}
            title="Cadastros/dia"
            showMore
          >
            {Boolean(props.overviewRegisterDay.length) ? (
              <BarChart
                barLegend
                xDataKey="DATE"
                barDataKey="QUANTIDADE"
                data={props.overviewRegisterDay}
              />
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card loading={props.loadingClienteMapa} title="Mapa" showMore>
            {Boolean(props.perfilCliente.mapa.length) ? (
              <Box width="100%" heigh="100%">
                <MapsComponent
                  isMarkerShown
                  data={props.perfilCliente.mapa}
                  typeMap="CLUSTER"
                  onModalPerson={props.modalPessoa}
                />
              </Box>
            ) : (
              <WithoutInformation />
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  mapStateToProps = ({ RoutesReducer, InsightsReducer }) => ({
    ...InsightsReducer,
    ...RoutesReducer,
  }),
  mapDispatchToProps = {
    getOverviewRegisterDay,
    getOverviewRegisterMonth,
    getOverviewDDD,
    getOverviewGenre,
    getOverviewAge,
    getClienteMapa,
    getClienteTotalizadores,
    getTotalCadastros,
    modalPessoa,
  },
  OverviewRedux = connect(mapStateToProps, mapDispatchToProps)(Overview);

export default withStyles(styles)(OverviewRedux);
