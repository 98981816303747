import React, { useState, useEffect } from 'react';
import { Box, Button, Fade, Menu, MenuItem } from '@material-ui/core';
import { ExpandMoreRounded as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import useStyles from '../styles';
import * as locales from 'react-date-range/dist/locale';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

export default function ChatInformationFilter({
  name,
  list,
  isDate = false,
  setSelectedValue = null,
  onChangeDate,
  clear,
  ...props
}) {
  const classes = useStyles(),
    [anchor, setAnchor] = useState(null),
    [filter, setFilter] = useState(null),
    handleClick = event => setAnchor(event.currentTarget),
    handleClose = () => setAnchor(null),
    handleChangeFilter = value => setFilter(value);

  useEffect(() => {
    if (clear) setFilter(null);
  }, [clear]);

  useEffect(() => {
    if (setSelectedValue) setSelectedValue(filter);
  }, [filter]);

  return (
    <>
      <Button
        id={`select-${name}`}
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        className={classes.btnInstance}
        style={{ marginRight: '1vw' }}
      >
        {filter ? filter.name : name}
      </Button>

      <Menu
        keepMounted
        id={`${name}-menu`}
        className={classes.menuInstance}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {isDate ? (
          <DateRange
            rangeColors={[COLOR_PRIMARY]}
            locale={locales.ptBR}
            onChange={({ selection }) => {
              const payload = {
                name: `${moment(selection.startDate).format(
                  'DD/MM/YYYY'
                )} ~ ${moment(selection.endDate).format('DD/MM/YYYY')}`,
                start: moment(selection.startDate).format('YYYY-MM-DD'),
                end: moment(selection.endDate).format('YYYY-MM-DD'),
              };

              setFilter(payload);
              onChangeDate(payload);
            }}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            editableDateInputs={false}
            showDateDisplay={false}
            ranges={[
              {
                startDate: moment(props.startDate).toDate(),
                endDate: moment(props.endDate).toDate(),
                key: 'selection',
              },
            ]}
          />
        ) : (
          list.map(({ id, name }) => (
            <MenuItem
              key={id.toString()}
              onClick={() => {
                handleChangeFilter({ id, name });
                handleClose();
              }}
            >
              {name}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
}
