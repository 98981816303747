import React from 'react';
import {
  Box,
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Typography,
  ListSubheader
} from '@material-ui/core';

import useStyles from './styles';

function SupportOptionsMenu({ onClose, anchor }) {
  const classes = useStyles();

  return (
    <Menu
      id="support-menu"
      // className={classes.menu}
      // classes={{ paper: classes.paper }}
      open={anchor}
      anchorEl={anchor}
      onClose={onClose}
      // TransitionComponent={Fade}
      // anchorOrigin={{
      //   vertical: 'center',
      //   horizontal: 'center'
      // }}
      // transformOrigin={{
      //   vertical: 'center',
      //   horizontal: 'center'
      // }}

      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuItem onClick={() => window.open('https://datasales.movidesk.com/kb/')}>
        Aprenda com Vídeos
      </MenuItem>
      <MenuItem onClick={() => window.open('https://calendly.com/datasales')}>
        Agendar Treinamento
      </MenuItem>
      <MenuItem
        onClick={() => window.open('https://datasales.stonly.com/kb/pt/entre-em-contato-121583')}
      >
        Fale Conosco
      </MenuItem>
    </Menu>
  );
}

export default SupportOptionsMenu;
