import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import AgendaReducer from '../modules/Agendamento/reducer/reducer';
import AppReducer from '../modules/AppConfig/reducer/reducer';
import AutomacaoReducer from '../modules/Automacoes/reducer';
import CampaignReducer from '../modules/Campanhas/reducer/reducer';
import CashbackReducer from '../modules/Cashback/reducer/index';
import ChatbotReducer from '../modules/Chatbot/reducer';
import ClusterReducer from '../modules/Cluster/reducer/reducer';
import ComercialReducer from '../modules/Comercial/reducer/reducer';
import ConfigCashbackReducer from '../modules/ConfigCashback/reducer/reducer';
import ConfigClubeReducer from '../modules/ConfigClube/reducer/reducer';
import ConfigReducer from '../modules/Configuracoes/reducer/index';
import CupomReducer from '../modules/Cupom/reducer/index';
import CupomCashbackReducer from '../modules/CupomCashback/reducer/reducer';
import CupomSorteioReducer from '../modules/CupomSorteio/reducer/reducer';
import DashboardReducer from '../modules/Dashboard/reducer/index';
import DatabaseReducer from '../modules/Database/reducer/index';
import DeliveryReducer from '../modules/Delivery/reducer/reducer';
import FastModelsReducer from '../modules/FastModels/reducer';
import InsightsReducer from '../modules/Insights/reducer/reducer';
// import RoutesReducer from "../utils/routes/reducer/reducer";
import loginReducer from '../modules/Login/reducer/reducerLogin';
import LojasReducer from '../modules/Lojas/reducer/reducer';
import MetabaseReducer from '../modules/Metabase/reducer/reducer';
import OfertasReducer from '../modules/Ofertas/reducer/index';
import OneToOneReducer from '../modules/OneToOne/reducer/reducer';
import PerformanceReducer from '../modules/Performance/reducer/reducer';
import PeopleReducer from '../modules/Pessoas/reducer/reducerPeople';
import PromocaoReducer from '../modules/Promocoes/reducer/reducer';
import RecomendacaoReducer from '../modules/Recomendacao/reducer/index';
import RecomendacoesReducer from '../modules/Recomendacoes/reducer/reducer';
import registerUserReducer from '../modules/RegisterUsers/reducers';
import ReportsReducer from '../modules/Relatorios/reducer/reducerRelatorio';
import SMSAutomaticoReducer from '../modules/SMS/reducer/reducer';
import SolicitacoesReducer from '../modules/Solicitacoes/reducer/index';
import TabloideReducer from '../modules/Tabloide/reducer/reducer';
import TabloideDigitalReducer from '../modules/TabloideDigitalV2/reducer/index';
import TemplateReducer from '../modules/Template/reducer/reducer';
import VendaMaisReducer from '../modules/VendaMais/reducer/reducer';
import AccountSettingsReducer from '../modules/AccountSettings/reducer/reducer';
import ChatReducer from '../modules/Chat/reducer';
import ImportacaoReducer from '../modules/Importacao/reducer';
import EnriquecimentoReducer from '../modules/Enriquecimento/reducer';
import FormsReducer from '../modules/Forms/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  ConfigReducer,
  TabloideDigitalReducer,
  TabloideReducer,
  OneToOneReducer,
  DashboardReducer,
  FastModelsReducer,
  AppReducer,
  DatabaseReducer,
  // RoutesReducer,
  loginReducer,
  PeopleReducer,
  ReportsReducer,
  CampaignReducer,
  VendaMaisReducer,
  LojasReducer,
  SMSAutomaticoReducer,
  AgendaReducer,
  PromocaoReducer,
  ConfigClubeReducer,
  InsightsReducer,
  ConfigCashbackReducer,
  TemplateReducer,
  RecomendacoesReducer,
  PerformanceReducer,
  MetabaseReducer,
  ComercialReducer,
  registerUserReducer,
  OfertasReducer,
  AutomacaoReducer,
  ChatbotReducer,
  CupomReducer,
  CashbackReducer,
  RecomendacaoReducer,
  SolicitacoesReducer,
  CupomSorteioReducer,
  CupomCashbackReducer,
  DeliveryReducer,
  ClusterReducer,
  AccountSettingsReducer,
  ChatReducer,
  ImportacaoReducer,
  EnriquecimentoReducer,
  FormsReducer,
});

export const authInterceptor = () => next => action => {
  if (action && action.payload && action.payload.statusCode === 401) {
    action.type = 'LOG_OUT';
  }
  return next(action);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk, authInterceptor)));

export default store;
