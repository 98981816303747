import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Countup from 'react-countup';
import useStyles from '../styles';

export function GeneralNumbersMetricsBox({ number = 0, title, ...props }) {
  const classes = useStyles();
  return (
    <Box className={classes.containerNumbersAnalytics} {...props}>
      <Typography className={classes.title} color="primary">
        <Countup
          start={0}
          end={number || 0}
          duration={5}
          separator="."
          decimal=","
          decimals={0}
        />
      </Typography>
      <Typography
        style={{ marginTop: 8 }}
        className={classes.textAlert}
        color="primary"
      >
        {title}
      </Typography>
    </Box>
  );
}
