import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Typography,
  Divider,
  withStyles,
  Icon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../utils/components';

import { COLOR_PRIMARY, BG_PRIMARY } from '../../../utils/helpers/Constants';
import ModalCriarCupomSorteio from './ModalCriarCupomSorteio';

export class CriarCupomSorteio extends Component {
  state = { showModalCupom: false };
  render() {
    const { classes } = this.props;
    const { showModalCupom } = this.state;

    return (
      <div className={classes.root}>
        <Link to="/cupom-sorteio" style={{ textDecoration: 'none' }}>
          <Button
            size="small"
            style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
            color="primary"
          >
            <Icon>arrow_back_ios</Icon>Voltar para cupom de desconto
          </Button>
        </Link>
        <Typography className={classes.label} variant="h3" noWrap>
          Criar cupom de sorteio
        </Typography>
        <div className={classes.actions}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Typography className={classes.label} variant="h6" noWrap>
              Sugestões de cupom
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    '&:hover': { borderRadius: 10 },
                  }}
                  color="inherit"
                  size="large"
                  className={classes.activeButton}
                >
                  Padrão
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  size="large"
                  variant="text"
                  style={{ color: COLOR_PRIMARY }}
                  onClick={() =>
                    this.setState({
                      showModalCupom: true,
                    })
                  }
                >
                  Criar cupom
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div
          className={classes.content}
          style={{
            display: 'flex',
            height: '100%',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomTable
            title=""
            columnsButton
            pageSize={5}
            paging
            search
            headerStyle={{
              backgroundColor: '#eee',
              zIndex: 0,
              color: BG_PRIMARY,
            }}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
              backgroundColor: '#eee',
              color: BG_PRIMARY,
            }}
            columns={[
              { title: 'Nome', field: 'nome_oferta' },
              { title: 'Tipo Oferta', field: 'tipo_oferta' },
              {
                title: 'Data inicio',
                field: 'data_inicio',
                render: rowData =>
                  moment(rowData.data_inicio).format('DD/MM/YYYY'),
              },
              {
                title: 'Data fim',
                field: 'data_fim',
                render: rowData =>
                  moment(rowData.data_fim).format('DD/MM/YYYY'),
              },
              { title: 'Filtro', field: 'nome_lista' },
              {
                title: 'Ações',
                field: '',
                render: rowData => (
                  <Button
                    size="large"
                    color="primary"
                    style={{ color: COLOR_PRIMARY }}
                    onClick={() =>
                      this.setState({
                        showModalContinuar: true,
                        oferta: rowData,
                        personalizada: true,
                      })
                    }
                  >
                    Iniciar Oferta
                  </Button>
                ),
              },
            ]}
            data={[]}
          />
        </div>
        {showModalCupom && (
          <ModalCriarCupomSorteio
            open={showModalCupom}
            onClose={() =>
              this.setState({
                showModalCupom: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

const CriarCupomSorteioConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CriarCupomSorteio);

export default withStyles(styles)(CriarCupomSorteioConnect);
