import { DeleteOutlined } from '@mui/icons-material';
import { Grid, IconButton, ImageList, Skeleton, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AlertDialogV2 } from 'utils/components/AlertDialogV2';

import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { useDefaultStyles } from '../../style';
import StudioImages from '../StudioImages';
import FilterFormats from './FilterFormats';

// const useStyles = makeStyles(theme => ({
//   containerAutocomplete: {
//     '& .MuiTextField-root': {
//       maxHeight: '42px !important',
//     },
//   },
//   imageList: {
//     padding: theme.spacing(4, 0, 0),
//     width: '100%',
//     display: 'flex !important',
//     flexWrap: 'wrap !important',
//   },
//   image: {
//     cursor: 'pointer',
//     objectFit: 'contain',
//     maxHeight: '400px',
//     maxWidth: '220px',
//   },
//   layerDelete: {
//     height: '100%',
//     width: '100%',
//     position: 'absolute',
//     '&:hover': {
//       background: 'rgba(0,0,0,.3)',
//       borderRadius: theme.shape.borderRadius,
//       border: `1px solid ${theme.palette.divider}`,

//       '& $wrapTrashIcon': {
//         display: 'block'
//       }
//     },
//   },
//   redirectEditor: {
//     height: '100%',
//     width: '100%',
//   },
//   wrapTrashIcon: {
//     display: 'none',
//     width: '25px',
//     height: '25px',
//     right: 5,
//     top: 5,
//     position: 'absolute',
//     borderRadius: '2px',
//   },
//   img: {
//     maxHeight: '250px',
//     maxWidth: '100%',
//     objectFit: 'cover',
//     width: '100%',
//     borderRadius: theme.shape.borderRadius,
//     border: `1px solid ${theme.palette.divider}`,
//   },
//   skeleton: {
//     borderRadius: theme.shape.borderRadius,
//   },
// }));

function SelectTemplate({ open, onSelect, onClose, externFormat }) {
  // const classes = useStyles();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('lg'));
  // const magnumClasses = useDefaultStyles();

  const [indexModels, setIndexModels] = useState(2);
  const [templates, setTemplates] = useState([]);
  const [formats, setFormats] = useState([]);
  // const [segments, setSegments] = useState([]);
  // const [occasions, setOccasions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState({});
  const [query, setQuery] = useState({
    formats: [2],
    segments: [],
    occasions: [],
    productQuantity: [],
    tags: ''
  });

  const factor = 10;
  // const totalImagens = indexModels * factor;
  const { enqueueSnackbar } = useSnackbar();

  const loadData = async () => {
    await sleep(300).then(() => setIndexModels(indexModels + 1));
  };

  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v.id) : value
    }));
  }

  async function fetchTemplates(params) {
    try {
      setLoading(true);

      params = {
        ...query,
        tags: 'Meu Modelo'
      };

      params = params ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}` : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/templates${params}`).then(response => {
        setTemplates(response.content);
        console.log('templates', response.content);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // async function fetchSegments() {
  //   try {
  //     await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(
  //       response => {
  //         setSegments(response.content);
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function fetchOccasions() {
  //   try {
  //     await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions`).then(
  //       response => {
  //         setOccasions(response.content);
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  function handleClose() {
    setIdSelected(null);
    setLoadingAction(false);
  }

  async function deleteTemplate(id) {
    setLoadingAction(true);
    try {
      enqueueSnackbar('Deletando...', {
        variant: 'info'
      });

      await api(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/template/${id}`,
        null,
        'DELETE',
        null,
        null,
        false
      );

      handleClose();

      fetchTemplates(query);

      enqueueSnackbar('Sucesso!', {
        variant: 'success'
      });
    } catch (err) {
      handleClose();
      console.log(err);
    }
  }

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  function openItemEditor(item) {
    const access_token = JSON.parse(localStorage.getItem('token'));

    const params = queryString.stringify({
      access_token,
      redirect_url: window.location.href,

      format_id: item.formatId,
      product_quantity: item.productQuantity,
      template_id: item.id,
      template_name: item.name
    });

    const url = `https://editor.datasales.info/template/fundos?${params}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    // fetchTemplates(query);
    fetchFormats();
    // fetchSegments();
    // fetchOccasions();
  }, []);

  useEffect(() => {
    fetchTemplates(query);
  }, [query]);

  const onClick = (op, item) => {
    if (item?.name === null) return null;
    switch (op) {
      case 'open':
        openItemEditor(item);
        break;
      case 'delete':
        setIdSelected(item);
        // deleteTemplate(item);
        break;
    }
  };

  const myModelsDatas = {
    templates: templates,
    indexModels: indexModels,
    onClick: onClick,
    loading: loading,
    loadData: loadData
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sx={{
            '& .MuiTextField-root': {
              maxHeight: '42px !important'
            }
          }}
        >
          <FilterFormats formats={formats} handleQueryChange={handleQueryChange} />
        </Grid>
        <Grid item xs={12} sx={{ margin: '24px 0' }}>
          {/* <InfiniteScroll
          dataLength={templates.length}
          next={loadData}
          hasMore={indexModels * factor < templates.length}
        >
          <ImageList
            data-testid={`tabloid-v2-list-all-cards-my-models`}
            cellHeight={'auto'}
            gap={3}
            className={classes.imageList}
          >
            <ImageList
              data-testid={`tabloid-v2-list-all-cards-my-models`}
              cellHeight={'auto'}
              gap={3}
              className={classes.imageList}
            >
              {loading
                ? Array(20)
                    .fill(20)
                    .map((_, i) => (
                      <div key={i} style={{ padding: '13px' }}>
                        <Skeleton
                          className={`${classes.skeleton} ${magnumClasses.gridMediaQueryHeight} ${magnumClasses.gridCard}}`}
                          style={{ height: 290, width: 180 }}
                          variant="rect"
                        />
                      </div>
                    ))
                : templates.map(item => (
                    <div
                      key={item.id.toString()}
                      style={{
                        marginRight: 24,
                        marginBottom: 24,
                        width: 180,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      {item.name}
                      {/* asdadsa Casdasd ASDASDAS dasdadas asdasdsada asdadsada asdadsadasdasda */}
          {/* </div>
                  </div>
                </div>
              ))}
          </ImageList>
        </InfiniteScroll>
 */}
          <StudioImages tab="myModels" myModelsDatas={myModelsDatas} />
        </Grid>
      </Grid>
      {idSelected && (
        <AlertDialogV2
          open={idSelected ? true : false}
          loading={loadingAction}
          contentText="Tem certeza que deseja excluir este modelo?"
          handleAgree={() => deleteTemplate(idSelected)}
          handleClose={handleClose}
          textAgree="Excluir"
          textClose="Cancelar"
        />
      )}
    </>
  );
}

export default SelectTemplate;

