import React, { Fragment } from 'react';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme, props) => ({
  paper: {
    zIndex: 200,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    padding: '10px 20px 10px 20px',
    width: '100%',
    borderRadius: 10,
  },
}));

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const classes = useStyles();

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <Fragment>
      <IconButton
        {...props}
        variant={props.variant || 'text'}
        color="primary"
        style={{ color: 'black', ...props.style }}
        ref={anchorRef}
        aria-label="split button"
        size="large"
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {props.icon ? (
          props.iconcomponent ? (
            props.iconcomponent
          ) : (
            <ArrowDropDownIcon />
          )
        ) : (
          <span />
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: '3333' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow" className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>{props.children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
