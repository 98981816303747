import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core/";
import { CountCard } from "../../../utils/components";
import { withStyles } from "@material-ui/core";

export class Database extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography style={{ paddingBottom: "1rem" }} variant="h3" noWrap>
          Banco de dados
        </Typography>

        <section className="contCard">
          <Link to="/database-pessoas" style={{ textDecoration: "none" }}>
            <CountCard
              style={{ width: "100%", cursor: "pointer" }}
              title="Pessoas"
              icon="peoples"
            />
          </Link>
          {/* <Link to="/database-vendas" style={{ textDecoration: "none" }}>
            <CountCard
              style={{ width: "100%", cursor: "pointer" }}
              title="Vendas"
              icon="add_shopping_cart"
            />
          </Link> */}
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    paddingTop: "3rem",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flexGrow: 1,
    height: "100%"
  }
});

const mapStateToProps = ({ DatabaseReducer }) => ({ ...DatabaseReducer });

const mapDispatchToProps = {};

const DatabaseConnect = connect(mapStateToProps, mapDispatchToProps)(Database);

export default withStyles(styles)(DatabaseConnect);
