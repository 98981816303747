/* eslint-disable */
import {
  Button,
  withStyles,
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, TextField } from '../../../../utils/components';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import {
  deleteEncarteFisico,
  createTemplatePersonalizado
} from '../../action/index';
import { red } from '@material-ui/core/colors';

class ModalCriar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.myRef = React.createRef() 
  }

  header(props, state) {
    const { classes } = this.props;

    return (
      <Fragment>
      </Fragment>
    );
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {/* <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() =>
            {}
          }
        >
          Salvar
        </Button> */}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'xs'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <div className={classes.content} ref={this.myRef}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <TextField
                  label="Nome do Modelo"
                  value={this.props.nomeModelo}
                  shrink={true}
                  disabled={false}
                  onChange={e => this.props.onChange(e)}
                  style={{ width: 300, margin: 10 }}
                ></TextField>
              </div>
              <div>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
                  onClick={() => this.props.onClick()}
                >
                  Criar
                </Button>
              </div>
            </div>
          </div>
        </Modal>        
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },


  rootCard: {
    width: 200,
    backgroundColor: '#F6F6F6',
  },
  media: {
    height: 0,
    paddingTop: '300px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  deleteEncarteFisico,
  createTemplatePersonalizado
};

const ModalCriarConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCriar);

export default withStyles(styles)(ModalCriarConnect);
