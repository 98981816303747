// Material Icons
import HighlightOff from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Images
import FacebookIcon from '../../../../assets/icons/marketing/FacebookOriginal.svg';
import GmailIcon from '../../../../assets/icons/marketing/Gmail.svg';
import GoogleIcon from '../../../../assets/icons/marketing/google.svg';
import InstagramIcon from '../../../../assets/icons/marketing/InstagramOriginal.svg';
import SmsIcon from '../../../../assets/icons/marketing/SMS.svg';
import TiktokIcon from '../../../../assets/icons/marketing/tiktok.svg';
import TurbochargedActionIcon from '../../../../assets/icons/marketing/TurbochargedAction.svg';
import WhatsappIcon from '../../../../assets/icons/marketing/WhatsappOriginal.svg';
import { SendStatusProps } from '../../../../hooks/api/distribution/useAdsDistribution';
import useUser from '../../../../hooks/api/users/useUser';
// Redux
import { StateProps } from '../../../../store/types';
import {
  clearAdsDistribution,
  clearSelectedShareableItem,
  findAutoMessage,
  setAdsDistributionCurrentChannel,
  setAdsDistributionCurrentTab,
  setAdsDistributionSelectedChannels,
  setAdsDistributionSelectedChannelsDataRemove,
  setAdsDistributionValidateForm,
  setIsDistributionOpened
} from '../../action';
import { ISelectedChannels } from '../../reducer/types';
// Individual Components
import DistributionPreview from '../Carousel/DistributionPreview';
import CreateAds from '../CreateAds';
import CreatePost from '../CreatePost';
import CreateSms from '../CreateSms';
// Self Components
import { WhatsApp, WhereToDistribute } from './components';
import DistributionCompletedModal from './components/DistributionCompletedModal';
import DistributionConfirmationModal from './components/DistributionConfirmationModal';
import ExitConfirmationModal from './components/ExitConfirmationModal';
import { LabelDot } from './components/LabelDot';
// Styles
import { useStyles } from './styles';
import theme from './theme';
import { ChannelsProps } from './utils/types';

type AdsDistributionProps = {
  isOpened: boolean;
  uuid: string;
  offerId: number;
  offerName: string;
  onChangeDistributionState: ((toggleState: boolean) => {}) | null;
  isFromFastModels?: boolean;
  isFromSchedulePost?: boolean;
  onCloseFromFastModels?: any;
};

export const AdsDistribution: FC<AdsDistributionProps> = ({
  isOpened,
  isFromSchedulePost,
  uuid,
  offerId,
  offerName,
  onChangeDistributionState,
  isFromFastModels = false,
  onCloseFromFastModels = () => {}
}) => {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { selectedShareableItem, adsDistribution, isLoadingAdsDistributionAdd } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
  );

  const smallerChannels = !!selectedShareableItem?.[0]?.dateScheduled;

  const _channels: ChannelsProps[] = !smallerChannels
    ? [
        {
          grid: 4,
          title: 'Redes sociais',
          name: 'social-networks',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: FacebookIcon }, { icon: InstagramIcon }, { icon: TiktokIcon }]
        },
        {
          grid: 4,
          title: 'Mensagem WhatsApp',
          name: 'whatsapp',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: WhatsappIcon }]
        },
        {
          grid: 4,
          title: 'Impulsionamento',
          name: 'turbocharged-action',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: TurbochargedActionIcon }]
        },
        {
          grid: 4,
          title: 'Remarketing Google',
          name: 'google',
          selected: false,
          validated: false,
          disabled: true,
          distributed: false,
          channels: [{ icon: GoogleIcon }]
        },
        {
          grid: 4,
          title: 'Email',
          name: 'email',
          selected: false,
          validated: false,
          disabled: true,
          distributed: false,
          channels: [{ icon: GmailIcon }]
        },
        {
          grid: 4,
          title: 'SMS',
          name: 'sms',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: SmsIcon }]
        }
      ]
    : [
        {
          grid: 4,
          title: 'Redes sociais',
          name: 'social-networks',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: FacebookIcon }, { icon: InstagramIcon }, { icon: TiktokIcon }]
        },
        {
          grid: 4,
          title: 'Impulsionamento',
          name: 'turbocharged-action',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: TurbochargedActionIcon }]
        },
        {
          grid: 4,
          title: 'Mensagem WhatsApp',
          name: 'whatsapp',
          selected: false,
          validated: false,
          disabled: false,
          distributed: false,
          channels: [{ icon: WhatsappIcon }]
        },
        {
          grid: 4,
          title: 'REMARKETING GOOGLE',
          name: 'google',
          selected: false,
          validated: false,
          disabled: true,
          distributed: false,
          channels: [{ icon: GoogleIcon }]
        },
        {
          grid: 4,
          title: 'EMAIL',
          name: 'email',
          selected: false,
          validated: false,
          disabled: true,
          distributed: false,
          channels: [{ icon: GmailIcon }]
        }
      ];

  const [, updateState] = useState<object>();
  const [value, setValue] = useState<string>('1');
  const [channels, setChannels] = useState<ChannelsProps[]>([]);
  const [tabs, setTabs] = useState<Array<string>>(['Onde distribuir']);
  const [enabledTabs, setEnabledTabs] = useState<string[] | []>(['1', '2']);
  const [modalCompletedIsOpen, setModalCompletedIsOpen] = useState(false);
  const [sendStatus, setSendStatus] = useState<SendStatusProps>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [isLoadingSleep, setIsLoadingSleep] = useState<boolean>(false);
  const [currentTabNumber, setCurrentTabNumber] = useState(1);

  const forceUpdate = useCallback(() => updateState({}), []);

  const { data: userProfilesData, loading: loadingProfilesData, getUserProfiles } = useUser();

  const onSelectedChannel = useCallback(
    (idChannel: number, isSelected: boolean) => {
      const newTabs = tabs;
      const tempChannel = channels;
      if (isSelected) {
        newTabs.push(tempChannel[idChannel].title);
      } else {
        const index = newTabs.indexOf(tempChannel[idChannel].title);
        newTabs.splice(index, 1);
      }

      tempChannel[idChannel] = { ...tempChannel[idChannel], selected: isSelected };

      setTabs(newTabs);
      setChannels(() => [...tempChannel]);
      forceUpdate();
    },
    [tabs, channels]
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    return;
  };

  const handleClose = () => {
    if (onChangeDistributionState) {
      onChangeDistributionState(false);
      setIsDistributionOpened(false);
    }
  };

  const isOnWhereToDistribute = () => (parseInt(adsDistribution.currentTab) === 1 ? true : false);
  const isOnTheLastChannel = () =>
    parseInt(adsDistribution.currentTab) >= adsDistribution.selectedChannels.length + 1 &&
    !isOnWhereToDistribute()
      ? true
      : false;

  function validateBeforeDistribute() {
    if (adsDistribution.currentChannel !== null && !isOnWhereToDistribute())
      dispatch(setAdsDistributionValidateForm(true));
  }

  const handleSetCurrentChannel = (currentTab: number) => {
    if (currentTab > 1) {
      const selectedTabIndexForChannel = currentTab - 2;
      const selectedChannel = adsDistribution.selectedChannels[selectedTabIndexForChannel].name;
      dispatch(setAdsDistributionCurrentChannel(selectedChannel));
    } else {
      dispatch(setAdsDistributionCurrentChannel(null));
    }
  };

  const handleDisableNextButton = () => {
    return adsDistribution.selectedChannels.length === 0 ? true : false;
  };

  const handlePrevTab = () => {
    const currentTab = parseInt(adsDistribution.currentTab);
    const prevTab = currentTab - 1;
    if (currentTab > 1) {
      dispatch(setAdsDistributionCurrentTab(prevTab.toString()));
      handleSetCurrentChannel(prevTab);
    }
  };

  const handleEnabledTabs = async () => {
    const currentTab = parseInt(adsDistribution.currentTab);

    if (!isOnWhereToDistribute() && !isOnTheLastChannel()) {
      adsDistribution.selectedChannels.map((channel, index) => {
        const channelTab = index + 2;

        if (channel.validated && channelTab === currentTab) {
          setEnabledTabs(oldTabs => [...oldTabs, `${channelTab + 1}`]);
        }
      });
    }
  };

  const handleNextTab = () => {
    const currentTab = parseInt(adsDistribution.currentTab);
    const nextTab = currentTab + 1;

    if (currentTab < currentTabNumber) {
      dispatch(setAdsDistributionCurrentTab(nextTab.toString()));
      handleSetCurrentChannel(nextTab);
    }

    handleEnabledTabs();
  };

  async function handleDistributeOnValidated(isButtonDistribute = true) {
    setIsLoadingSleep(true);

    validateBeforeDistribute();

    await new Promise(r => setTimeout(r, 2000));

    setIsLoadingSleep(false);

    const currentChannelData = adsDistribution.selectedChannels.filter(
      channel => channel.name === adsDistribution.currentChannel
    )[0];

    if (isOnTheLastChannel()) {
      if (currentChannelData?.validated && isButtonDistribute) {
        setIsModalOpen(true);
      }
    } else {
      if (currentChannelData?.validated === true) {
        handleNextTab();
      } else if (isOnWhereToDistribute()) {
        if (isOnWhereToDistribute()) handleNextTab();
      }
    }
  }

  const handleChannels = () => {
    const _selectedChannels = channels.filter(
      channel =>
        channel.selected === true &&
        adsDistribution.selectedChannels.every(
          selectedChannel => selectedChannel.name !== channel.name
        )
    );
    let addMoreChannels: ISelectedChannels[] = [];
    addMoreChannels.push(
      ...adsDistribution.selectedChannels.filter(selectedChannel =>
        channels.some(channel => channel.name === selectedChannel.name && channel.selected === true)
      )
    );

    addMoreChannels.push(..._selectedChannels);

    adsDistribution.selectedChannelsFormData.forEach(currentChannelFormData => {
      if (
        !addMoreChannels.some(
          currentSelectedChannel => currentSelectedChannel.name === currentChannelFormData.name
        )
      ) {
        dispatch(setAdsDistributionSelectedChannelsDataRemove(currentChannelFormData.name));
      }
    });

    dispatch(setAdsDistributionSelectedChannels(addMoreChannels));
  };

  const renderTab = (tab: string) => {
    switch (tab.toUpperCase()) {
      case 'REDES SOCIAIS':
        return (
          <CreatePost
            backgroundId={selectedShareableItem?.[0].background_id}
            offerId={offerId}
            offerName={offerName}
            selectedItems={selectedShareableItem}
            isPost
            onClose={() => {}}
            showOnlyFacebookAndInstagram
            preLoadedProfiles={userProfilesData}
            isFromSchedulePost={isFromSchedulePost}
          />
        );
      case 'ONDE DISTRIBUIR':
        return <WhereToDistribute channels={channels} onChange={onSelectedChannel} />;
      // case 'WHATSAPP STATUS':
      //   return <WhatsApp isStatus imageId imageUrl offerId={offerId} offerName={offerName} />;
      case 'MENSAGEM WHATSAPP':
        return (
          <WhatsApp
            backgroundId={selectedShareableItem?.[0].background_id}
            offerId={offerId}
            offerName={offerName}
            isFromSchedulePost={isFromSchedulePost}
          />
        );
      case 'IMPULSIONAMENTO':
        return (
          <CreateAds
            backgroundId={selectedShareableItem?.[0].background_id}
            open={true}
            isModal={false}
            hasCarousel={true}
            data={{
              selectedItems: selectedShareableItem,
              uuid: uuid,
              offerId: offerId,
              offerName: offerName
            }}
            onClose={() => {}}
            isFromSchedulePost={isFromSchedulePost}
          />
        );
      case 'SMS':
        return (
          <CreateSms
            backgroundId={selectedShareableItem?.[0]?.background_id}
            open={true}
            isModal={false}
            data={{
              selectedItems: selectedShareableItem,
              offerId: offerId,
              offerName: offerName
            }}
            onClose={() => {}}
          />
        );
    }
  };

  useEffect(() => {
    setCurrentTabNumber(adsDistribution.selectedChannels.length + 1);

    if (adsDistribution.selectedChannels.length > 0) {
      const isAllSelectedChannelsOpen = adsDistribution.selectedChannels.every(
        channel => channel.validated
      );

      if (isAllSelectedChannelsOpen && !isModalOpen && !isOnWhereToDistribute()) {
        handleDistributeOnValidated();
      }
    }

    const timeoutEnabledTabs = setTimeout(() => {
      handleEnabledTabs();
    }, 3000);

    return () => {
      clearTimeout(timeoutEnabledTabs);
    };
  }, [JSON.stringify(adsDistribution.selectedChannels)]);

  useEffect(() => {
    setValue(adsDistribution.currentTab);
  }, [adsDistribution.currentTab]);

  useEffect(() => {
    handleChannels();
  }, [channels]);

  useEffect(() => {
    setChannels(_channels);

    getUserProfiles();
    dispatch(clearAdsDistribution());
    // It resets Tabs and Channels when this component was unmounted
    return () => {
      setTabs(['Onde distribuir']);
      dispatch(clearAdsDistribution());
    };
  }, []);

  useEffect(() => {
    dispatch(setIsDistributionOpened(isOpened));
  }, []);

  useEffect(() => {
    const payload = { background_id: selectedShareableItem?.[0].background_id, channel: 'all' };
    dispatch(findAutoMessage(payload));
  }, [selectedShareableItem?.[0].background_id, dispatch]);

  return (
    <ThemeProvider theme={theme(classes)}>
      <Dialog fullScreen open={isOpened} onClose={handleClose}>
        <DialogContent>
          <Box
            className={classes.boxContent}
            data-testid="modules-TabloidDigitalV2-components-AdsDistribution"
          >
            <TabContext value={value}>
              <Grid container spacing={0} className={classes.distributionModalWrapper}>
                <Grid item xs={12} md={12}>
                  <Button
                    startIcon={<HighlightOff />}
                    onClick={() => setOpenExitModal(true)}
                    variant="text"
                    style={{ fontSize: 16, padding: '12px 16px', lineHeight: '24px' }}
                  >
                    Sair de distribuição
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.wrapperBoxContainer}>
                    <Box className={classes.tabContainer}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {tabs.map((tab, index) => (
                          <Tab
                            key={index.toString()}
                            disabled={
                              index === 0
                                ? false
                                : loadingProfilesData
                                ? true
                                : !enabledTabs.some(_tab => Number(_tab) === index + 1)
                            }
                            icon={<LabelDot labelNumber={Number(index + 1)} dot={true} />}
                            label={tab}
                            iconPosition={'start'}
                            sx={{
                              cursor: !enabledTabs.some(_tab => Number(_tab) === index + 1)
                                ? 'not-allowed !important'
                                : 'pointer',
                              pointerEvents: 'all !important',
                              minHeight: '48px !important',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '26px',
                              letterSpacing: '0.05em',
                              // paddingLeft: index === 0 ? '42px' : '8px',
                              paddingLeft: index === 0 ? '0px' : '0px',
                              marginRight: '16px',
                              paddingRight: '0',
                              color:
                                index + 1 < parseInt(adsDistribution.currentTab)
                                  ? '#2813AD'
                                  : `#CFCFCF${
                                      !enabledTabs.some(_tab => Number(_tab) === index + 1)
                                        ? ' !important'
                                        : ''
                                    }`,
                              '&:before': {
                                content: '""',
                                height: '0px',
                                width: '130%',
                                position: 'absolute',
                                bottom: 0,
                                background:
                                  index + 1 < parseInt(adsDistribution.currentTab)
                                    ? '#2813AD'
                                    : 'transparent'
                                // paddingBottom: '1px solid #127FFF'
                              }
                            }}
                            value={Number(index + 1).toString()}
                          />
                        ))}
                      </TabList>
                    </Box>
                  </Box>
                </Grid>
                <Grid container item xs={12} style={{ padding: '48px 48px 0px' }}>
                  {!smallerChannels && (
                    <Grid item xs={5.5}>
                      <DistributionPreview
                        carouselItemsStateData={selectedShareableItem}
                        roundedBorders={true}
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    xs={6.5}
                    sx={
                      smallerChannels
                        ? {
                            margin: 'auto'
                          }
                        : {}
                    }
                  >
                    {tabs.map((tab, index) => (
                      <TabPanel
                        key={index.toString()}
                        value={Number(index + 1).toString()}
                        style={{ padding: '0px 0px 0px' }}
                      >
                        {renderTab(tab)}
                      </TabPanel>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </TabContext>
          </Box>
          <DistributionConfirmationModal
            isFromSchedulePost={isFromSchedulePost}
            offerId={offerId}
            openModal={isModalOpen}
            onOpenModalCompleted={status => {
              setModalCompletedIsOpen(true);
              setSendStatus(status);
            }}
            onCloseModal={value => setIsModalOpen(value)}
          />

          <DistributionCompletedModal
            open={modalCompletedIsOpen}
            status={sendStatus}
            onClose={() => {
              if (isFromFastModels) {
                onCloseFromFastModels({ finishProcess: true });
              } else {
                dispatch(clearSelectedShareableItem());
                setModalCompletedIsOpen(false);
                handleClose();
                if (smallerChannels) {
                  history.push('/estudio/' + offerId);
                }
              }
            }}
            isFromFastModels={isFromFastModels}
          />

          <ExitConfirmationModal
            openModal={openExitModal}
            handleClose={() => setOpenExitModal(false)}
            confirmate={() => {
              if (isFromFastModels) {
                onCloseFromFastModels({ finishProcess: true });
              } else {
                dispatch(clearSelectedShareableItem());
                handleClose();
              }
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalActionButtonsWrapper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6} className={classes.buttonReturnSectionAndSelectionWrapper}>
              <Button
                size="large"
                variant="text"
                className={classes.buttonReturnSectionAndSelection}
                onClick={() => {
                  if (isOnWhereToDistribute()) {
                    isFromFastModels ? onCloseFromFastModels() : handleClose();
                  } else {
                    handlePrevTab();
                  }
                }}
              >
                {isOnWhereToDistribute()
                  ? isFromFastModels
                    ? 'Voltar para tela de preços'
                    : 'Voltar para seleção'
                  : 'Voltar para aba anterior'}
              </Button>
            </Grid>

            <Grid item xs={6} className={classes.loadingButtonWrapper}>
              <LoadingButton
                loading={loadingProfilesData}
                variant="contained"
                color="primary"
                className={classes.loadingButton}
                style={{
                  backgroundColor:
                    !handleDisableNextButton() &&
                    !loadingProfilesData &&
                    !adsDistribution.isValidatingForm &&
                    !isLoadingAdsDistributionAdd &&
                    !isLoadingSleep
                      ? '#2813AD'
                      : '#CFCFCF'
                }}
                disabled={
                  handleDisableNextButton() ||
                  loadingProfilesData ||
                  adsDistribution.isValidatingForm ||
                  isLoadingAdsDistributionAdd ||
                  isLoadingSleep
                }
                onClick={() => {
                  handleDistributeOnValidated(true);
                }}
              >
                {adsDistribution.isValidatingForm
                  ? 'Avaliando Formulário...'
                  : isLoadingSleep
                  ? 'Carregando...'
                  : isOnTheLastChannel()
                  ? 'Distribuir'
                  : 'Continuar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

