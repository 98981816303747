import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import localization from '../../../../../../localization/table';
import { BarChart } from '../../../../../../utils/components';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import { formatPrice } from '../../../../../../utils/helpers/Format';
import useStyles from './styles';

const last_days = [
  { label: '5 dias', value: 5 },
  { label: '10 dias', value: 10 },
  { label: '15 dias', value: 15 },
  { label: '20 dias', value: 20 },
  { label: '30 dias', value: 30 },
  { label: '60 dias', value: 60 },
  { label: '90 dias', value: 90 },
];

function AnalyticalVision({ product }) {
  const classes = useStyles();

  // const [product, setProduct] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState();
  const [companyStores, setCompanyStores] = useState();
  const [lastDay, setLastDay] = useState();

  useEffect(() => {
    // (async () => {
    //   const response = await api(
    //     `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/product/${sku}`
    //   );

    //   setProduct(response.content);
    // })();

    (async () => {
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/stores`
      );

      setCompanyStores(response);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/product/analytical/${product.sku}`,
        {
          stores: stores
            ? stores.map(item => item.id_loja).toString()
            : undefined,
          lastDay: lastDay ? lastDay.value : 30,
        }
      );

      setData(response.content);
      setLoading(false);
    })();
  }, [stores, lastDay]);

  if (!companyStores || !data) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" size={40} />
      </div>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Filtro" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Autocomplete
                  options={last_days}
                  getOptionLabel={option => option.label}
                  onChange={(e, value) => {
                    setLastDay(value);
                  }}
                  defaultValue={{ label: '30 dias', value: 30 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Últimos dias"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={companyStores}
                  getOptionLabel={option => option.nome_loja}
                  onChange={(e, value) => {
                    setStores(value);
                  }}
                  multiple
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Lojas"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<SearchIcon />}
            >
              filtrar
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.large}
                variant="rounded"
                src="https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483097.jpg"
              />
            }
            title={product.name}
            titleTypographyProps={{ variant: 'h6', component: 'strong' }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{`Produto: ${product.sku}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`Categoria: ${product.category}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`EAN: ${product.ean}`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.card}>
                  <CardHeader
                    title={formatPrice(product.price || 0)}
                    subheader="preço"
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.card}>
                  <CardHeader
                    title={formatPrice(product.highest_price || 0)}
                    subheader="maior preço"
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.card}>
                  <CardHeader
                    title={formatPrice(product.lowest_price || 0)}
                    subheader="menor preço"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.card}>
                  <CardHeader
                    title={product.medium_basket || 0}
                    subheader="Cesta média "
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.card}>
                  <CardHeader
                    title={product.always_lead || 0}
                    subheader="Pessoas que sempre Levam"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.card}>
                  <CardHeader
                    title={product.not_taking || 0}
                    subheader="Pessoas que estão deixando de levar"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.card}>
                  <CardHeader
                    title={product.may_like || 0}
                    subheader="Pessoas que podem gostar"
                  />
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card className={classes.card}>
          <CardHeader title="Loja - Faturamento" />
          <Divider />
          <CardContent>
            <BarChart
              barLegend
              randomFill
              xDataKey="NOME_LOJA"
              width="99%"
              barDataKey='VLRVENDA'
              // stacked={[
              //   'PERIODO',
              //   'SEQPRODUTO',
              //   'NROEMPRESA',
              //   'MAIOR_PRECO',
              //   'MENOR_PRECO',
              //   'CESTA_MEDIA',
              //   'SEMPRE_LEVAM',
              //   'DEIXANDO_LEVAR',
              //   'PODEM_GOSTAR',
              //   'TOTAL_CUPOM',
              //   'QTDVENDA',
              //   'VLRVENDA',
              //   'VLRCONTRIBVENDA',
              // ]}
              data={data}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Table
          title=""
          isLoading={loading}
          columns={[
            { title: 'LOJA', field: 'NOME_LOJA' },
            { title: 'PERIODO', field: 'PERIODO' },
            { title: 'NROEMPRESA', field: 'NROEMPRESA' },
            { title: 'MAIOR_PRECO', field: 'MAIOR_PRECO' },
            { title: 'MENOR_PRECO', field: 'MENOR_PRECO' },
            { title: 'CESTA_MEDIA', field: 'CESTA_MEDIA' },
            { title: 'SEMPRE_LEVAM', field: 'SEMPRE_LEVAM' },
            { title: 'DEIXANDO_LEVAR', field: 'DEIXANDO_LEVAR' },
            { title: 'PODEM_GOSTAR', field: 'PODEM_GOSTAR' },
            { title: 'TOTAL_CUPOM', field: 'TOTAL_CUPOM' },
            { title: 'VLRVENDA', field: 'VLRVENDA' },
          ]}
          data={data || []}
          localization={localization}
          options={{ search: false }}
        />
      </Grid>
    </Grid>
  );
}

export default AnalyticalVision;
