import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import location from '../../../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const months = [
  { label: 'Janeiro', value: '2020-01-01' },
  { label: 'Fevereiro', value: '2020-02-01' },
  { label: 'Março', value: '2020-03-01' },
  { label: 'Abril', value: '2020-04-01' },
  { label: 'Maio', value: '2020-05-01' },
  { label: 'Junho', value: '2020-06-01' },
  { label: 'Julho', value: '2020-07-01' },
  { label: 'Agosto', value: '2020-08-01' },
  { label: 'Setembro', value: '2020-09-01' },
  { label: 'Outubro', value: '2020-10-01' },
  { label: 'Novembro', value: '2020-11-01' },
  { label: 'Dezembro', value: '2020-12-01' },
];

const weeks = [
  { label: 'Mês completo', value: 0 },
  { label: 'Semana 1', value: 1 },
  { label: 'Semana 2', value: 2 },
  { label: 'Semana 3', value: 3 },
  { label: 'semana 4', value: 4 },
];

function PowerfulCombinations({ sku }) {
  const classes = useStyles();

  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [week, setWeek] = useState(0);
  const [month, setMonth] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/product/combination/${sku}`,
        {
          week,
          month,
          category,
        }
      );

      setData(response.content);
      setLoading(false);
    })();
  }, [week, month, category]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/categories`
        );

        setCategories(response);
      } catch (err) {}
    })();
  }, []);

  if (!categories) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Filtro" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Autocomplete
                  options={categories}
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => {
                    setCategory(value && value.name);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Categorias"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={months}
                  getOptionLabel={option => option.label}
                  onChange={(e, value) => {
                    setMonth(value && value.value);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Mês"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={weeks}
                  defaultValue={weeks[0]}
                  getOptionLabel={option => option.label}
                  onChange={(e, value) => {
                    setWeek(value && value.value);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Semana"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Produtos" />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Table
              title=""
              isLoading={loading}
              columns={[
                { title: 'Nome', field: 'NOME_COMBINACAO' },
                { title: 'Mes', field: 'MES' },
                { title: 'Ano', field: 'ANO' },
                { title: 'Semana mes', field: 'SEMANA_MES' },
                { title: 'Anomes', field: 'ANOMES' },
                { title: 'TOTAL_CUPOM', field: 'TOTAL_CUPOM' },
                { title: 'VLRVENDA', field: 'VLRVENDA' },
                { title: 'RANKING', field: 'RANKING' },
                { title: 'FAMILIA', field: 'FAMILIA' },
              ]}
              data={data || []}
              localization={location}
              options={{ search: false }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PowerfulCombinations;
