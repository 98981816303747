import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api from '../../../../utils/helpers/Fetch';
import getInitials from '../../../../utils/helpers/getInitials';
import useStyles from './styles';

function Posts() {
  const classes = useStyles();

  const [posts, setPosts] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          'https://yrwyq4jw43.execute-api.us-east-1.amazonaws.com/dev/post'
        );

        setPosts(response);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!posts) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      {posts.map(post => (
        <Grid item xs={12} lg={4} md={6}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>
                  {getInitials(post.profile_title)}
                </Avatar>
              }
              title={post.profile_title}
              subheader={moment
                .unix(post.create_date._seconds)
                .format('DD/MM/YYYY HH:mm')}
              action={
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <div className={classes.platformsContainer}>
                {post.platforms.map(platform => (
                  <Chip label={platform} color="primary" />
                ))}
              </div>
              <Typography>{post.post}</Typography>
              {post.media_urls.length > 0 && (
                <div className={classes.mediaContainer}>
                  <img
                    className={classes.media}
                    alt={post.post}
                    src={post.media_urls[0]}
                  />
                </div>
              )}
            </CardContent>

            {/* {post.media_urls.length > 0 && (
              <CardMedia
                className={classes.media}
                image={post.media_urls[0]}
                title="Paella dish"
              />
            )} */}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Posts;
