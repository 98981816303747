/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Typography,
  withStyles,
  Button,
  Icon,
  IconButton,
} from '@material-ui/core';
import {
  getFamalias,
  getProdutosByFamilia,
  postBlacklist,
  getBlacklist,
  getPessoas,
} from '../action/action';
import {
  MyButton,
  DatePicker,
  Select,
  Modal,
  AutoComplete,
  AutoCompleteLab,
  Chip,
  DialogAlert,
  Snackbar,
  CustomTable,
} from '../../../utils/components';
import AddIcon from '@material-ui/icons/Add';
// import {} from "../actions/index";
// import { listStore } from "../../Lojas/actions/action";
// import { MoedaBrasil } from "../../../utils/helpers/Format";
// import { MEDIA } from "../../../utils/helpers/Constants";
import '@webscopeio/react-textarea-autocomplete/style.css';

class Blacklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      familia: {},
      produto: {},
      pessoa: {},
      showSnackbar: false,
      dataInicio: moment(),
      dataFim: moment(),
      familia: '',
      idFamilia: '',
      nomeProduto: '',
      idProduto: '',
    };

    this.addProdutos = this.addProdutos.bind(this);
    this.addFamilia = this.addFamilia.bind(this);
  }

  componentDidMount() {
    this.props.getBlacklist();
    this.props.getFamalias();

    // this.props.listStore();
  }

  header(props, state) {
    return <Fragment>Whitelist</Fragment>;
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        <div />
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() =>
            this.setState({ showSnackbar: true }, () => props.onClose())
          }
          // onClick={() =>
          //   this.setState({
          //     activeStep: this.state.activeStep - 1
          //   })
          // }
          // disabled={this.state.activeStep === 0 ? true : false}
        >
          Salvar
        </Button>
      </div>
    );
  }

  addFamilia() {
    const { familia, pessoa } = this.state;

    const data = {
      familia: { ...familia, nome_familia: familia.label },
    };
    this.props.postBlacklist(data);
    this.setState({ familia: '', idFamilia: '' });
    // const data = this.state.familias;

    // data.push({
    //   idFamilia,
    //   familia
    // });
    // this.setState(
    //   {
    //     familia: data,
    //     familia: ""
    //   }
    // () => {
    //   const objOferta = {
    //     idOferta: this.props.idOferta,
    //     produtos: data,
    //     tipoOferta
    //   };
    //   this.props.addProdutosOferta(objOferta);
    // }
    // );
  }

  addProdutos() {
    const { familia, produto, pessoa } = this.state;

    const data = {
      produto: {
        ...produto,
        nome_produto: produto.label,
      },
      tipo: 'onetoone',
    };
    this.props.postBlacklist(data);
    this.setState({ nomeProduto: '', idProduto: '' });

    // {
    //   if (familia && !nomeProduto) {
    //     this.setState({ showDialog: true });
    //   } else {
    //     const data = this.state.produtos;
    //     data.push({
    //       idFamilia,
    //       familia,
    //       idProduto,
    //       nomeProduto,
    //       preco,
    //       porcentagem,
    //       precoDesconto
    //     });
    //     this.setState(
    //       {
    //         produtos: data,
    //         familia: "",
    //         nomeProduto: ""
    //       }
    //       // () => {
    //       //   const objOferta = {
    //       //     idOferta: this.props.idOferta,
    //       //     produtos: data,
    //       //     tipoOferta
    //       //   };
    //       //   this.props.addProdutosOferta(objOferta);
    //       // }
    //     );
    //   }
    // }
  }

  render() {
    const {
      onClose,
      load_familias,
      load_produtos,
      load_pessoas,
      pessoas,
      blacklist,
      load_blacklist,
      type,
    } = this.props;
    const { familia, produto, nomeProduto, showDialog, pessoa } = this.state;

    const data =
      this.props.type === 0 ? blacklist.familias : blacklist.produtos;

    return (
      <Fragment>
        <Modal
          size="md"
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <section
            style={{
              // padding: "2rem 20rem",
              display: 'flex',
              flex: 1,
              gap: '2rem',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            {/* <Typography
              style={{ fontWeight: "900" }}
              color="primary"
              variant="h5"
            >
              Informações da Oferta
            </Typography> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <section>
                <DatePicker
                  dateAndTime
                  disableFuture
                  label="Início da Oferta"
                  onChange={date => this.setState({ datainicio: date })}
                  value={datainicio}
                />
              </section>
              <section>
                <DatePicker
                  dateAndTime
                  disablePast
                  label="Término da Oferta"
                  onChange={date => this.setState({ dataFim: date })}
                  value={dataFim}
                />
              </section>
            </div> */}

            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ width: '40%' }}>
                  <AutoCompleteLab
                    disabled={load_familias}
                    groupLabel="label"
                    label="Categorias"
                    placeholder="Pesquisar categorias"
                    loading={load_familias}
                    value={this.state.familia}
                    getOptionSelected={(option, value) =>
                      option.id_familia === value.id_familia
                    }
                    getOptionLabel={option => option.label}
                    onChange={(event, value) =>
                      this.setState(
                        { familia: value },
                        () =>
                          value && this.props.getProdutosByFamilia(value.label)
                      )
                    }
                    // onInputChange={(event, value, reason) =>
                    //   this.props.getPessoas({ cpf: value })
                    // }
                    options={this.props.familias}
                    // onSelect={value =>
                    //
                    // }
                  />
                </div>
                <div style={{ width: '40%' }}>
                  <AutoCompleteLab
                    label="Produtos"
                    placeholder="Pesquisar produtos"
                    loading={load_produtos}
                    disabled={load_produtos === true || !familia ? true : false}
                    value={this.state.produto}
                    getOptionSelected={(option, value) =>
                      option.id_produto === value.id_produto
                    }
                    getOptionLabel={option => option.label}
                    // onChange={(event, value) =>
                    //   this.setState({ produto: value })
                    // }
                    // onInputChange={(event, value, reason) =>
                    //   this.props.getPessoas({ cpf: value })
                    // }
                    options={this.props.produtos}
                    onChange={(event, value) =>
                      this.setState({ produto: value })
                    }
                  />

                  {/* <AutoComplete
                    label="Produto"
                    loading={load_produtos}
                    value={nomeProduto}
                    disabled={load_produtos === true || !familia ? true : false}
                    onChange={value => this.setState({ nomeProduto: value })}
                    suggestions={this.props.produtos}
                    onSelect={value =>
                      this.setState({
                        nomeProduto: this.props.produtos.filter(
                          prd => prd.label === value
                        )[0].label,
                        preco: this.props.produtos.filter(
                          prd => prd.label === value
                        )[0].preco,
                        idProduto: this.props.produtos.filter(
                          prd => prd.label === value
                        )[0].id_produto
                      })
                    }
                  ></AutoComplete> */}
                </div>
                {/* <div style={{ width: "25%" }}>
                  <AutoCompleteLab
                    label="Pessoa"
                    placeholder="Pesquisar pessoa pelo cpf"
                    loading={load_pessoas}
                    value={this.state.pessoa}
                    getOptionSelected={(option, value) =>
                      option.documento === value.documento
                    }
                    getOptionLabel={option => option.nome}
                    onChange={(event, value) =>
                      this.setState({ pessoa: value })
                    }
                    onInputChange={(event, value, reason) =>
                      this.props.getPessoas({ cpf: value })
                    }
                    options={this.props.pessoas}
                    onSelect={value => this.setState({ pessoa: value })}
                  />
                </div> */}
                <div style={{ width: '10%' }}>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={
                      this.props.type === 0
                        ? !familia
                          ? true
                          : false
                        : this.props.type === 1
                        ? !nomeProduto
                          ? true
                          : false
                        : false
                    }
                    onClick={this.addProdutos}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <CustomTable
                title=""
                columnsButton
                pageSize={5}
                paging
                // search
                actions={[
                  // {
                  //   icon: "arrow_downward",
                  //   tooltip: "Download",
                  //   onClick: (event, rowData) => {
                  //     window.open(rowData.link_template, "_blank");
                  //   }
                  // },
                  {
                    icon: () => <Icon color="action">edit</Icon>,
                    tooltip: 'Editar',
                  },
                  {
                    icon: () => <Icon color="error">delete</Icon>,
                    tooltip: 'Excluir',
                  },
                ]}
                isLoading={load_blacklist}
                headerStyle={{ backgroundColor: '#fff', zIndex: 0 }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  backgroundColor: '#fff',
                }}
                columns={[
                  {
                    title: 'Nome do Produto',
                    field: 'nome_produto',
                    hidden: this.props.type === 0 ? true : false,
                  },
                  { title: 'Categoria', field: 'nome_familia' },
                ]}
                data={data}
              />
            </div>
          </section>
        </Modal>
        {showDialog && (
          <DialogAlert
            open={showDialog}
            title="Deseja dar desconto em todos produtos dessa familia?"
            onClick={() =>
              this.setState({ showDialog: false }, () => this.addFamilia())
            }
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ OneToOneReducer }) => ({
  ...OneToOneReducer,
});
const mapDispatchToProps = {
  postBlacklist,
  getBlacklist,
  getFamalias,
  getProdutosByFamilia,
  getPessoas,
};

const BlacklistConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Blacklist);

export default withStyles(styles)(BlacklistConnect);
