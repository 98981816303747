import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { 
  Button, 
  Icon, 
  CircularProgress,
  withStyles
 } from "@material-ui/core";
import { getConfigSubmenuFilho } from "../action/action";
import { getDashboardMetabase } from "../../Metabase/action/action";
import "../style/comercial.css";

export class ComercialId extends Component {
  state = { checked: false };
  // componentDidMount() {
  //   this.props.getConfigSubmenuFilho(this.props.match.params.id);
  // }

  // changeDashboard(filho) {
  //   this.props.getDashboardMetabase(filho.id_dashboard);
  //   this.setState({ checked: filho.id_submenu_filho });
  // }

  render() {
    const { classes } = this.props;

    return this.props.loaderSubmenuFilho ? (
      <section style={{ marginTop: "20vh", textAlign: "center" }}>
        <CircularProgress color="primary" size={40} />
      </section>
    ) : (
      <div className={classes.root}>
        <Link
          to={`/analise-id/${this.props.match.params.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button size="small" style={{ marginBottom: "1rem" }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar
          </Button>
        </Link>

        <section style={{ paddingTop: "1rem", textAlign: "center" }}>
          {this.props.loadingMetabase ? (
            <CircularProgress color="primary" size={40} />
          ) : (
            <iframe
              title={this.props.urlIframe}
              src={this.props.urlIframe}
              frameBorder="0"
              width="100%"
              height="3500"
              allowtransparency="true"
            />
          )}
        </section>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    paddingTop: "3rem",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  }
});

const mapStateToProps = ({ ComercialReducer, MetabaseReducer }) => ({
  ...ComercialReducer,
  ...MetabaseReducer
});

const mapDispatchToProps = { getConfigSubmenuFilho, getDashboardMetabase };

const ComercialIdConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComercialId);

export default withStyles(styles)(ComercialIdConnect);
