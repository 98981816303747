import React, { Fragment } from 'react';
import {
  DatePicker,
  TextField,
  Checkbox,
  Select,
} from '../../../../utils/components';
import DateCustom from '../../../../utils/helpers/Date';
import PesquisaCEP from '../../../../utils/helpers/CEP';

const genero = [
  { label: 'Feminino', value: 'F' },
  { label: 'Masculinos', value: 'M' },
];

const _myDate = new DateCustom();

const BodyModal = props => (
  <Fragment>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto',
        gridGap: '1vh 1vw',
      }}
    >
      <section style={{ gridColumn: '1/3' }}>
        <TextField
          required
          defaultValue={props.attrPessoa.nome}
          onChange={({ target: { value } }) => props.onChange('nome', value)}
          label="Nome"
          className="form-control"
        />
      </section>
      <TextField
        required
        defaultValue={props.attrPessoa.documento}
        onChange={({ target: { value } }) => props.onChange('documento', value)}
        label="CPF"
        className="form-control"
      />
      <DatePicker
        label="Data de Nascimento"
        format="DD/MM/YYYY"
        value={props.attrPessoa.data_nascimento}
        onChange={date =>
          props.onChange('data_nascimento', _myDate.getDate('YYYY-MM-DD', date))
        }
      />

      <section
        style={{
          gridColumn: '1 / 5',
          display: 'grid',
          gridTemplateColumns: '10vw 15vw auto auto 5vw auto',
          alignItems: 'center',
          gridGap: '1vw',
        }}
      >
        <TextField
          defaultValue={props.attrPessoa.cep}
          onChange={({ target: { value } }) => {
            let lenValue = value.replace(/\D/g, '');
            props.onChange('cep', lenValue);
            lenValue = lenValue.length;
            if (Boolean(lenValue) && lenValue === 8) {
              PesquisaCEP(props.attrPessoa.cep, (key, value) =>
                props.onChange(key, value)
              );
            }
          }}
          onKeyPress={event =>
            event.key === 'Enter' &&
            PesquisaCEP(props.attrPessoa.cep, (key, value) =>
              props.onChange(key, value)
            )
          }
          label="CEP"
          className="form-control"
        />
        <TextField
          onChange={({ target: { value } }) => props.onChange('rua', value)}
          value={props.attrPessoa.rua}
          label="Logradouro"
          className="form-control"
        />
        <TextField
          onChange={({ target: { value } }) => props.onChange('bairro', value)}
          value={props.attrPessoa.bairro}
          label="Bairro"
          className="form-control"
        />
        <TextField
          onChange={({ target: { value } }) => props.onChange('cidade', value)}
          value={props.attrPessoa.cidade}
          label="Cidade"
          className="form-control"
        />
        <TextField
          onChange={({ target: { value } }) => props.onChange('uf', value)}
          value={props.attrPessoa.uf}
          label="UF"
          className="form-control"
        />
        <TextField
          onChange={({ target: { value } }) =>
            props.onChange('complemento', value)
          }
          label="Complemento"
          className="form-control"
        />
      </section>
      <Select
        label="Gênero"
        className="form-control"
        options={genero}
        value={
          props.attrPessoa.sexo
            ? genero.find(({ value }) => value === props.attrPessoa.sexo)
            : null
        }
        onChange={select =>
          props.onChange('sexo', Boolean(select) ? select.value : null)
        }
      />

      <TextField
        defaultValue={props.attrPessoa.celular1}
        onChange={({ target: { value } }) => props.onChange('celular1', value)}
        label="Celular"
        className="form-control"
      />
      <TextField
        defaultValue={props.attrPessoa.telefone1}
        onChange={({ target: { value } }) => props.onChange('telefone1', value)}
        label="Telefone"
        className="form-control"
      />
      <TextField
        defaultValue={props.attrPessoa.email1}
        onChange={({ target: { value } }) => props.onChange('email1', value)}
        label="Email"
        className="form-control"
      />
      <Checkbox
        checked={!Boolean(props.attrPessoa.blacklist_email)}
        onChange={({ target: { checked } }) =>
          props.onChange('blacklist_email', !Boolean(checked) ? 1 : 0)
        }
        label="Deseja receber oferta via email ?"
      />
      <Checkbox
        checked={!Boolean(props.attrPessoa.blacklist_sms)}
        onChange={({ target: { checked } }) =>
          props.onChange('blacklist_sms', !Boolean(checked) ? 1 : 0)
        }
        label="Deseja receber oferta via sms ?"
      />
    </div>
  </Fragment>
);

export default BodyModal;
