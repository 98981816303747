import React from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

export default props => (
  <DateTime
    onChange={props.onChange}
    timeFormat={false}
    closeOnSelect
    locale="pt-br"
    dateFormat="DD/MM/YYYY"
    inputProps={{ ...props.inputProps, placeholder: props.placeholder }}
    isValidDate={props.isValidDate}
  />
);
