import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actionsChatbot from '../../actions';
import useInterval from '../../utils/UseInterval';
import Utils from '../../utils/Utils';
import {ListContat} from '../Dialogs/ContactList'
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import {
  CropFree as CropFreeIcon,
  SettingsApplications as SettingsApplicationsIcon,
  ListAlt as ListAltIcon,
  Info as InfoIcon,
  FileCopy as FileCopyIcon,
  ExitToApp as ExitToAppIcon,
  PeopleAltRounded as GroupIcon,
} from '@material-ui/icons';

import DetalhesInstanciaDialog from '../Dialogs/DetalhesInstanciaDialog';
import EditarInstanciaDialog from '../Dialogs/EditarInstanciaDialog';
import PageWrapper from '../PageWrapper';
import QRCodeDialog from '../Dialogs/QRCodeDialog';
import InfoDeviceDialog from '../Dialogs/InfoDeviceDialog';

const ChatBot = props => {
  const history = useHistory(),
    [instanciaOnDialog, setInstanciaOnDialog] = useState({}),
    [detalhesDialogOpen, setDetalhesDialogOpen] = useState(false),
    [editDialogOpen, setEditDialogOpen] = useState(false),
    [QRCodeDialogOpen, setQRCodeDialogOpen] = useState(false),
    [infoDeviceDialogOpen, setInfoDeviceDialogOpen] = useState(false),
    [instancias, setInstancias] = useState([]),
    [desconectando, setDesconectando] = useState(false),
    [confirmQrcodeReload, setConfirmQrcodeReload] = useState(false),
    [openNovaInstancia, setOpenNovaInstancia] = useState(false),
    [lojaSelecionada, setLojaSelecionada] = useState(null),
    { lojas, loadingChatbot, statusInstancias } = props;

    const [openModalContats, setModalContats] = useState(false)

    function closeListContat(){
      setModalContats(false)
    }

  /* Carrega a lista de instâncias e as lojas */
  useEffect(() => {
    props.getInstancias();
    props.getLojas();
  }, []);

  useEffect(() => {
    /* Normalize a instância para que a mesma tenha o status */
    const instancias = props.instancias.map(instancia => ({
      ...instancia,
      loja: lojas.find(loja => loja.id_loja === instancia.id_loja) || {},
    }));
    setInstancias(instancias);
  }, [props.instancias, lojas]);

  useEffect(() => {
    loadStatusInstancias();
  }, [instancias]);

  /*
    Atualiza o status das instâncias
   */
  useEffect(() => {
    const keys = Object.keys(statusInstancias);
    keys.forEach(key => {
      let status = statusInstancias[key];
      let instancia = instancias.find(
        instancia => instancia.id === status.idInstancia
      );
      if (instancia) {
        instancia.status = status;
        // Fecha o modal do QR Code, caso o mesmo esteja aberto e a instância esteja conectada
        if (QRCodeDialogOpen && instanciaOnDialog.id === status.idInstancia) {
          if (status.connected) {
            setQRCodeDialogOpen(false);
          } else {
            setInstanciaOnDialog({ ...instancia });
          }
        }
      }
    });
  }, [statusInstancias]);

  /*
    Polling para pegar o status das instâncias a cada x segundos
  */
  useInterval(() => {
    loadStatusInstancias();
  }, [10 * 1000]);

  const loadStatusInstancias = () => {
      instancias.forEach(instancia => {
        setTimeout(() => {
          props.getStatusInstancia(instancia.id);
        }, 200);
      });
    },
    handleInstanciaOnDialog = instancia => {
      if (instancia) {
        setInstanciaOnDialog({ ...instancia });
      } else {
        setInstanciaOnDialog({});
      }
    },
    toggleDetalhesDialogOpen = instancia => {
      handleInstanciaOnDialog(instancia);
      setDetalhesDialogOpen(!detalhesDialogOpen);
    },
    toggleEditDialogOpen = instancia => {
      handleInstanciaOnDialog(instancia);
      setEditDialogOpen(!editDialogOpen);
    },
    toggleQRCodeDialog = instancia => {
      handleInstanciaOnDialog(instancia);
      setQRCodeDialogOpen(!QRCodeDialogOpen);
    },
    toggleInfoDeviceDialogOpen = instancia => {
      if (instancia) {
        handleInstanciaOnDialog(instancia);
      }
      setInfoDeviceDialogOpen(!infoDeviceDialogOpen);
    },
    saveInstancia = instancia => {
      props.putInstancia(instancia);
      toggleEditDialogOpen();
    },
    deleteInstancia = instancia => {
      props.deleteInstancia(instancia);
      toggleEditDialogOpen();
    };

  return (
    <Fragment>
      <PageWrapper
        {...props}
        title="Instâncias"
        buttonBack
        loading={loadingChatbot}
        header={
          <div style={{ textAlign: 'right'}}>
             <Button
              variant="contained"
              //color="primary"
              style={{ marginRight: 15, 
                background: '#F5F5F5',
                color: '#616161'
              }}
              onClick={() => setModalContats(true)}
            >
              Importar lista de contatos
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 15 }}
              onClick={() => history.push('/whatsapp/chatbot')}
            >
              Chatbot
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 15 }}
              onClick={() => history.push('/whatsapp/contatos')}
            >
              Contatos
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenNovaInstancia(true)}
            >
              Criar Nova Instância
            </Button>
          </div>
        }
      >
        <Grid container direction="column" spacing={2}>
          {!loadingChatbot &&
            instancias.map((instancia, index) => (
              <Grid item key={index}>
                <Card classes={{ root: 'card-instancias' }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item container>
                        <Typography component="h5" variant="h5" gutterBottom>
                          {`${instancia.id} - ${instancia.nome}`}
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Divider />
                      </Grid>
                      <Grid item container direction="row">
                        <Grid item md={3}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Loja</b>
                          </Typography>
                          <Typography variant="body1">
                            {instancia.loja
                              ? `${instancia.loja.id_loja || ''} - ${instancia
                                  .loja.nome_loja || ''}`
                              : '-'}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              marginTop: 10,
                              display: 'flex',
                            }}
                          >
                            <FileCopyIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  'https://whatsapp.meumercado.co/' +
                                    instancia.token || '?'
                                );
                                alert(
                                  'Link copiado para Área de Transferência!'
                                );
                              }}
                            />
                            <a
                              href={
                                'https://whatsapp.meumercado.co/' +
                                  instancia.token || '?'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: 'black',
                                textDecoration: 'none',
                                marginLeft: 6,
                              }}
                            >
                              whatsapp.meumercado.co...
                            </a>
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Mensagens na Fila</b>
                          </Typography>
                          <Typography variant="body1">
                            {instancia.status.qtdeFila
                              ? `${instancia.status.qtdeFila}`
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Status</b>
                          </Typography>
                          <Chip
                            size="small"
                            variant="outlined"
                            color={
                              instancia.status.connected
                                ? 'primary'
                                : 'secondary'
                            }
                            label={
                              instancia.status.connected
                                ? 'Conectado'
                                : instancia.status.session
                                ? 'Sem Conexão'
                                : 'Desconectado'
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Data da criação</b>
                          </Typography>
                          <Typography variant="body1">
                            {Utils.formatData(instancia.createdAt)}
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Data da criação</b>
                          </Typography>
                          <Typography variant="body1">
                            {Utils.formatData(instancia.updatedAt)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Box p={0.5}>
                          <Button
                            onClick={() => toggleDetalhesDialogOpen(instancia)}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<ListAltIcon />}
                          >
                            Detalhes
                          </Button>
                        </Box>
                        <Box p={0.5}>
                          <Button
                            onClick={() => toggleEditDialogOpen(instancia)}
                            size="medium"
                            variant="outlined"
                            color="primary"
                            startIcon={<SettingsApplicationsIcon />}
                          >
                            Configurar
                          </Button>
                        </Box>
                        {instancia.status.connected && (
                          <Box p={0.5}>
                            <Button
                              onClick={() =>
                                history.push(`/whatsapp/grupos/${instancia.id}`)
                              }
                              size="medium"
                              variant="outlined"
                              color="primary"
                              startIcon={<GroupIcon />}
                            >
                              Grupos
                            </Button>
                          </Box>
                        )}
                        {instancia.status.connected && (
                          <Box p={0.5}>
                            <Button
                              onClick={() => {
                                setDesconectando(true);
                                props
                                  .disconnectInstancia({ id: instancia.id })
                                  .then(() => {
                                    props.getStatusInstancia(instancia.id);
                                    setTimeout(function() {
                                      setDesconectando(false);
                                    }, 5000);
                                  })
                                  .catch(() => setDesconectando(false));
                              }}
                              size="medium"
                              variant="outlined"
                              color="secondary"
                              startIcon={<ExitToAppIcon />}
                            >
                              {desconectando
                                ? 'Desconectando...'
                                : 'Desconectar'}
                            </Button>
                          </Box>
                        )}
                        <Box p={1} flexGrow={1} />
                        <Box p={0.5}>
                          {!instancia.status.connected ? (
                            <Button
                              variant="contained"
                              size="medium"
                              color="primary"
                              onClick={() => toggleQRCodeDialog(instancia)}
                              startIcon={<CropFreeIcon />}
                            >
                              QR Code
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              size="medium"
                              color="primary"
                              onClick={() =>
                                toggleInfoDeviceDialogOpen(instancia)
                              }
                              startIcon={<InfoIcon />}
                            >
                              Dispositivo
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <DetalhesInstanciaDialog
          open={detalhesDialogOpen}
          instancia={instanciaOnDialog}
          onClose={() => toggleDetalhesDialogOpen()}
        />
        {editDialogOpen && (
          <EditarInstanciaDialog
            open={editDialogOpen}
            instancia={instanciaOnDialog}
            lojas={lojas}
            onClose={() => toggleEditDialogOpen()}
            onSave={instancia => saveInstancia(instancia)}
            onDelete={instancia => deleteInstancia(instancia)}
          />
        )}
        {QRCodeDialogOpen && (
          <QRCodeDialog
            open={QRCodeDialogOpen}
            reloadQrCode={props.reloadQrCode}
            instancia={instanciaOnDialog}
            confirmQrcodeReload={confirmQrcodeReload}
            onClose={() => toggleQRCodeDialog()}
            onCloseAlert={() => setConfirmQrcodeReload(false)}
          />
        )}
        <InfoDeviceDialog
          open={infoDeviceDialogOpen}
          instancia={instanciaOnDialog}
          onClose={() => toggleInfoDeviceDialogOpen()}
        />
        {openNovaInstancia && (
          <Dialog
            open={openNovaInstancia}
            onClose={() => {
              setOpenNovaInstancia(false);
              setLojaSelecionada(null);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Nova Instância Cliente'}
            </DialogTitle>
            <DialogContent>
              <FormControl style={{ width: 300 }}>
                <InputLabel id="demo-simple-select-label">Loja</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lojaSelecionada}
                  onChange={event => {
                    setLojaSelecionada(event.target.value);
                  }}
                >
                  {lojas.map(value => (
                    <MenuItem
                      value={JSON.stringify({
                        id_loja: value.id_loja,
                        nome: value.nome_loja,
                      })}
                    >
                      {value.id_loja} - {value.nome_loja}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenNovaInstancia(false);
                  setLojaSelecionada(null);
                }}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if (!lojaSelecionada) {
                    alert('Selecione uma Loja, por favor!');
                  } else {
                    props
                      .postInstanciaZapi(JSON.parse(lojaSelecionada))
                      .then(() => props.getInstancias());
                    setOpenNovaInstancia(false);
                    setLojaSelecionada(null);
                  }
                }}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </PageWrapper>
      <ListContat
        open={openModalContats}
        onClose={closeListContat}
        identificator='numero'
      />
    </Fragment>
  );
};

const mapStateToProps = ({ ChatbotReducer }) => {
  return { ...ChatbotReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot })(ChatBot);

