import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Icon } from '@material-ui/core';

import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

const PageWrapper = ({
  title,
  header,
  loading,
  children,
  history,
  buttonBack,
}) => {
  return (
    <Box p={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box pt={4}>
            {buttonBack && (
              <Button
                onClick={() => history.goBack()}
                size="medium"
                style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
                color="primary"
              >
                <Icon>arrow_back_ios</Icon>Voltar
              </Button>
            )}
            <Typography variant="h3" noWrap>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item>{header}</Grid>
        <Grid item>
          <Box pt={4} pb={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>{loading && <CircularProgress color="primary" />}</Grid>
        </Grid>
        {children}
      </Grid>
    </Box>
  );
};

PageWrapper.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.any,
  children: PropTypes.any,
};

PageWrapper.defaultProps = {
  title: '',
  loading: false,
};

export default PageWrapper;
