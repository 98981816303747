/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { Celular } from '../../../utils/helpers/Masks';
import { CustomTable } from '../../../utils/components/index';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ModalPerfis(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth="xl"
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
        Lista de pessoas
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <CustomTable
          title=""
          isLoading={props.loadingPessoas}
          paging
          search
          columnsButton
          pageSize={7}
          columns={[
            { title: 'Nome', field: 'nome' },
            { title: 'Destinatário', field: 'destinatario' },
            {
              title: 'Status',
              field: 'status_envio_externo',
            },
          ]}
          data={props.listaPessoas.map(data => ({
            ...data,
            destinatario: Celular(data.destinatario),
          }))}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ InsightsReducer, PerformanceReducer }) => ({
  ...InsightsReducer,
  ...PerformanceReducer,
});

const mapDispatchToProps = {};

const ModalPerfisConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPerfis);

export default withStyles(styles)(ModalPerfisConnect);
