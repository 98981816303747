import { Celular2 } from './Masks';

export const MoedaBrasil = (value = 0, fraction = 2) => {
    const _newValue = Boolean(value) ? value : 0,
      _newFraction = Boolean(fraction) ? fraction : 2;

    return parseFloat(_newValue).toLocaleString('pt-br', {
      minimumFractionDigits: _newFraction,
      maximumFractionDigits: _newFraction,
    });
  },
  DivisaoUnidade = value => parseInt(value, 10).toLocaleString('pt-bt'),
  { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }),
  RemoveAcentos = value => {
    const letrasAcentuadas = {
      â: 'a',
      Â: 'A',
      à: 'a',
      À: 'A',
      á: 'a',
      Á: 'A',
      ã: 'a',
      Ã: 'A',
      ê: 'e',
      Ê: 'E',
      è: 'e',
      È: 'E',
      é: 'e',
      É: 'E',
      î: 'i',
      Î: 'I',
      ì: 'i',
      Ì: 'I',
      í: 'i',
      Í: 'I',
      õ: 'o',
      Õ: 'O',
      ô: 'o',
      Ô: 'O',
      ò: 'o',
      Ò: 'O',
      ó: 'o',
      Ó: 'O',
      ü: 'u',
      Ü: 'U',
      û: 'u',
      Û: 'U',
      ú: 'u',
      Ú: 'U',
      ù: 'u',
      Ù: 'U',
      ç: 'c',
      Ç: 'C',
    };

    return value.replace(/[\W]/g, letra => letrasAcentuadas[letra] || letra);
  },
  validateEmail = email => {
    const regexEmail = /^[a-z0-9.]+@[a-z0-9]+\.([a-z]+)?$/i;
    return regexEmail.test(email);
  },
  legendPositionBanner = position => {
    switch (String(position).toLowerCase()) {
      case 'header':
        return 'Cabeçalho';
      case 'footer':
        return 'Rodapé';
      default:
        return '';
    }
  },
  legendDeviceBanner = device => {
    switch (String(device).toLowerCase()) {
      case 'both':
        return 'Ambos';
      case 'smartphone':
        return 'Celular';
      case 'desktop':
        return 'Computador';
      default:
        return '';
    }
  };

function _manipulateNotification(params = '') {
  let newParams = params.split(' e ').map(phone => Celular2(phone));
  newParams = `${newParams.slice(0, -1).join(',')}${
    newParams.length > 1 ? ' e ' : ' '
  }${newParams.slice(-1)}`;

  return newParams;
}

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}

export function mention(text) {
  const urlRegex = /\B@[a-z0-9_-]+/gi;
  return text.replace(urlRegex, function (url) {
    return `<a href="https://www.instagram.com/${url.replace(
      '@',
      ''
    )}/" target="_blank" style="color: #FFF !important;font-size: .8125rem;word-wrap: break-word;word-break: break-word;font-weight: 600;line-height: 1.23080;">${url}</a>`;
  });
}

export function capitalize(string = '') {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function notificationChat(notification = '', cellphone = '') {
  const action = notification.split('-')[0],
    params = notification.split('-')[1];
  let message = '';
  if (action.includes('E2E_ENCRYPTED')) {
    return `Seu código de segurança mudou.`;
  } else if (action.includes('GROUP_CREATE')) {
    return `${cellphone} criou o grupo "${params}"`;
  } else if (action.includes('GROUP_PARTICIPANT_ADD')) {
    return `${cellphone} adicionou ${_manipulateNotification(params)}`;
  } else if (action.includes('GROUP_PARTICIPANT_LEAVE')) {
    return `${_manipulateNotification(params)} saiu`;
  } else if (action.includes('GROUP_CHANGE_RESTRICT')) {
    message =
      params === 'on'
        ? `${cellphone} mudou as configurações do grupo para permitir que apenas admins possam editar os dados do grupo`
        : `${cellphone} mudou as configurações desse grupo para permitir que todos participantes possam editar os dados do grupo`;
    return message;
  } else if (action.includes('GROUP_CHANGE_ANNOUNCE')) {
    message =
      params === 'on'
        ? `📢 ${cellphone} definiu que somente admins podem enviar mensagens a este grupo`
        : `📢 ${cellphone} mudou as configurações deste grupo para permitir que todos participantes possam enviar mensagens ao grupo`;
    return message;
  }
}

export function translateStatusSocialNetworks(status = '') {
  switch (status) {
    case 'post_reactions_by_type_total':
    case 'likes_count':
      return 'Reações';
    case 'post_impressions':
    case 'impressions':
      return 'Visualizações';
    case 'post_impressions_paid':
      return 'Visualizações pagas';
    case 'post_engaged_users':
    case 'engagament':
      return 'Engajamento';
    case 'post_impressions_unique':
    case 'reach':
      return 'Contas atingidas';
    case 'saved':
      return 'Salvos';
    case 'comments_count':
      return 'Comentários';
    default:
      return '';
  }
}

export function translateStatusPaidFacebook(status = '') {
  switch (status) {
    case 'clicks':
      return 'Cliques';
    case 'impressions':
      return 'Visualizações';
    case 'frequency':
      return 'Frequência (Por pessoa)';
    case 'reach':
      return 'Contas atingidas';
    case 'cost_per_inline_link_click':
      return 'Custo por resultado (Cliques)';
    default:
      return '';
  }
}

export function translateStatusSends(status) {
  switch (status.toUpperCase()) {
    case 'AGUARDANDO':
      return 'wait';
    case 'ENVIADO SEM CONFIRMACAO':
    case 'ENVIADO':
    case 'CONFIRMACAO INDISPONIVEL':
    case 'MENSAGEM EM ANALISE':
      return 'sent';
    case 'ENVIADO COM CONFIRMACAO':
    case 'CONFIRMADO':
      return 'received';
    case 'MENSAGEM ABERTA':
    case 'MENSAGEM ENTREGUE':
      return 'read';
    case 'CANCELADO':
    case 'CANCELADA':
    case 'CANCELADO TEMPORARIAMENTE':
      return 'canceled';
    case 'ERRO':
    case 'NAO ENTREGUE':
    case 'MENSAGEM INVALIDA':
    case 'DESTINATARIO INVALIDO':
    case 'TENTATIVA ENVIO DUPLICADO':
    case 'ENVIO INVALIDO':
    case 'ENVIO BLOQUEADO':
      return 'erro';
    case 'MENSAGEM CLICADA':
      return 'clicks';
    case 'SPAM':
      return 'spam';
    default:
      return 'wait';
  }
}

