import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Avatar,
  ListItemAvatar,
  List,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import getInitials from '../../../../../../utils/helpers/getInitials';
import PropTypes from 'prop-types';
import api from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

function Accounts({ open, onClose }) {
  const classes = useStyles();

  const [accounts, setAccounts] = useState();

  useEffect(() => {
    (async () => {
      const response = await api(
        'https://api.datasales.info/ads-manager-dev/accounts'
      );

      const { content } = response;

      setAccounts(content);
    })();
  }, []);

  if (!accounts) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Contas</DialogTitle>
      <DialogContent className={classes.content} dividers>
        <List>
          {accounts.map(account => (
            <ListItem key={account.id} button divider>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  {getInitials(account.name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={account.name} secondary={account.type} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

Accounts.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Accounts;
