import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Box, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { AlertDialogV2 } from 'utils/components/AlertDialogV2';

import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import FilterFormats from '../EditMeusModelosNew/FilterFormats';
import StudioImages from '../StudioImages';
import FinalDialog from './FinalDialog';
import { sleep } from 'utils/helpers/Functions';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     overflow: 'hidden',
//     backgroundColor: theme.palette.background.paper
//   },
//   avatar: { backgroundColor: theme.palette.primary.main },
//   cardActions: {
//     justifyContent: 'flex-end'
//   },
//   // gridList: { width: '100vw' },
//   image: { cursor: 'pointer', objectFit: 'contain' },
//   img: {
//     maxHeight: 255,
//     // height: '100%',
//     objectFit: 'contain',
//     borderRadius: 4,
//     // borderRadius: theme.shape.borderRadius,
//     maxWidth: 180,
//     width: '100%',
//     border: `0.5px solid ${theme.palette.divider}`
//   },
//   imageList: {},
//   icon: {
//     color: 'rgba(255, 255, 255, 0.54)'
//   },
//   skeleton: {
//     borderRadius: theme.shape.borderRadius
//   },
//   content: {
//     minHeight: '100vh'
//   },
//   cardContent: {
//     marginBottom: 8,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between'
//   },
//   gridCard: {
//     cursor: 'pointer',
//     borderRadius: 8,
//     width: '100%',
//     height: 'auto',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   title: {
//     marginTop: 8,
//     color: '#000000',
//     fontWeight: 500,
//     fontSize: 14,
//     lineHeight: '22px',
//     'text-overflow': 'ellipsis',
//     letterSpacing: '0.05em',
//     'white-space': 'break-spaces',
//     display: '-webkit-box',
//     maxWidth: '180px',
//     '-webkit-line-clamp': '2',
//     '-webkit-box-orient': 'vertical',
//     overflow: 'hidden',
//     'text-overflow': 'ellipsis'
//   },
//   layerDelete: {
//     // minHeight: 106,
//     maxHeight: 255,
//     height: '100%',
//     width: '100%',
//     position: 'absolute',
//     '&:hover': {
//       background: 'rgba(0,0,0,.3)',
//       borderRadius: theme.shape.borderRadius,
//       border: `1px solid ${theme.palette.divider}`,

//       '& $wrapTrashIcon': {
//         display: 'block'
//       }
//     }
//   },
//   redirectEditor: {
//     maxHeight: 255,
//     height: '100%',
//     width: '100%'
//   },
//   wrapTrashIcon: {
//     display: 'none',
//     width: '25px',
//     height: '25px',
//     right: 5,
//     top: 5,
//     position: 'absolute',
//     borderRadius: '2px'
//   },
//   gridContainer: { display: 'flex', flexDirection: 'column' },
//   gridItem: {
//     display: 'flex',
//     flexDirection: 'row',
//     gap: 16,

//     '& .MuiTextField-root': {
//       maxHeight: '42px !important',
//     },
//   },
//   gridItemContainer: { display: 'flex', flexDirection: 'row', gap: 24, flexWrap: 'wrap' }
// }));

function SelectBackground({ open, onSelect, onClose }) {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();

  const [backgrounds, setBackgrounds] = useState([]);
  const [indexBackgrounds, setIndexBackgrounds] = useState(2);

  const [formats, setFormats] = useState([]);
  const [segments, setSegments] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState({});
  const [query, setQuery] = useState({
    formats: [2],
    segments: [],
    occasions: [],
    justCompanyId: 1
  });

  const [formatBackgroundSaved, setFormatBackgroundSaved] = useState(null);
  const [openDialogUpBackground, setOpenDialogUpBackground] = useState(false);

  const loadData = async () => {
    await sleep(300).then(() => setIndexBackgrounds(indexBackgrounds + 1));
  };

  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v.id) : value
    }));
  }

  async function fetchBackgrounds(params) {
    try {
      setLoading(true);

      params = params ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}` : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/backgrounds${params}`).then(response => {
        setBackgrounds(response.content);

        console.log('background', response.content);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setIdSelected(null);
    setLoadingAction(false);

    setQuery(prev => ({ ...prev, randomToExecute: Math.random() }));
  }

  async function deleteBackground(id) {
    setLoadingAction(true);
    try {
      enqueueSnackbar('Deletando...', {
        variant: 'info'
      });

      await api(
        `${DATASALES_CRM_API}editor-${AMBIENTE}/backgrounds/${id}`,
        null,
        'DELETE',
        null,
        null,
        false
      );

      handleClose();

      enqueueSnackbar('Sucesso!', {
        variant: 'success'
      });
    } catch (err) {
      handleClose();
      console.log(err);
    }
  }

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSegments() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(response => {
        setSegments(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchOccasions() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions`).then(response => {
        setOccasions(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //   fetchBackgrounds(query); //
  //   fetchFormats();
  //   fetchSegments();
  //   fetchOccasions();
  // }, [query]);

  useEffect(() => {
    // fetchTemplates(query);
    fetchFormats();
    // fetchSegments();
    // fetchOccasions();
  }, []);

  useEffect(() => {
    if (formatBackgroundSaved) {
      setQuery({ ...query, formats: [formatBackgroundSaved] });
    }
  }, [formatBackgroundSaved]);

  useEffect(() => {
    fetchBackgrounds(query);
  }, [query]);

  const onClick = (op, item) => {
    if (item?.name === null) return null;
    switch (op) {
      // case 'open':
      //   openItemEditor(item);
      //   break;
      case 'delete':
        setIdSelected(item);
        // deleteBackground(item);
        break;
    }
  };

  const myBackgroundsDatas = {
    backgrounds: backgrounds,
    indexBackgrounds: indexBackgrounds,
    onClick: onClick,
    // deleteTemplate: deleteTemplate,
    // openItemEditor: openItemEditor,
    loading: loading,
    loadData: loadData
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          // className={classes.gridItem}

          sx={{
            display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            gap: 2,
            // maxWidth: '441px',

            '& .MuiTextField-root': {
              maxHeight: '42px !important'
            }
          }}
        >
          <FilterFormats
            formats={formats}
            handleQueryChange={handleQueryChange}
            formatBackgroundSaved={formatBackgroundSaved}
          />
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogUpBackground(true)}
              style={{ padding: '8px 16px', height: 44, background: theme.palette.info.main }}
            >
              Importar fundo
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ margin: '24px 0' }}>
          {/* <Grid item xs={12} className={classes.gridItemContainer}>
          {loading
            ? Array(20)
              .fill(20)
              .map((_, i) => (
                <Skeleton className={classes.skeleton} variant="rect" height={307} width={180} />
              ))
            : backgrounds
              // .filter((el, index) => index < 500)
              .map(item => (
                <Box key={item.id.toString()} className={classes.cardContent}>
                  <Box style={{ position: 'relative' }} className={classes.gridCard}>
                    <Box className={classes.layerDelete}> */}
          {/* <Box className={classes.redirectEditor} onClick={() => {}}></Box>
        {/* <Box
                        className={classes.wrapTrashIcon}
                        style={{
                          background: confirmDelete[item.id] ? 'red' : '#FFF'
                        }}
                      >
                        <IconButton
                          style={{
                            color: confirmDelete[item.id] ? '#FFF' : 'red',
                            padding: 0
                          }}
                          onClick={e => {
                            if (confirmDelete[item.id]) {
                              deleteBackground(item.id);
                              setConfirmDelete(() => ({
                                ...confirmDelete,
                                [item.id]: false
                              }));
                            } else {
                              setConfirmDelete(() => ({
                                ...confirmDelete,
                                [item.id]: true
                              }));
                            }
                          }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Box>
                    </Box>

                    <img
                      className={classes.img}
                      src={item.thumbnail || item.imageURL}
                      alt={item.name}
                      title={item.name}
                      loading="lazy"
                    />
                  ))
              : backgrounds
                  // .filter((el, index) => index < 500)
                  .map(item => (
                    <Box key={item.id.toString()} className={classes.cardContent}>
                      <Box style={{ position: 'relative' }} className={classes.gridCard}>
                        <Box className={classes.layerDelete}>
                          {/* <Box className={classes.redirectEditor} onClick={() => {}}></Box> 
                          <Box
                            className={classes.wrapTrashIcon}
                            style={{
                              background: '#FFF'
                            }}
                          >
                            <IconButton
                              style={{
                                color: 'red',
                                padding: 0
                              }}
                              onClick={e => {
                                setIdSelected(item.id);
                                // if (confirmDelete[item.id]) {
                                //   deleteBackground(item.id);
                                //   setConfirmDelete(() => ({
                                //     ...confirmDelete,
                                //     [item.id]: false
                                //   }));
                                // } else {
                                //   setConfirmDelete(() => ({
                                //     ...confirmDelete,
                                //     [item.id]: true
                                //   }));
                                // }
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Box>
                        </Box>

                        <img
                          className={classes.img}
                          src={item.thumbnail || item.imageURL}
                          alt={item.name}
                          title={item.name}
                          loading="lazy"
                        />
                      </Box>

                  <Box className={classes.title}>{item.name + ' - id: ' + item.id}</Box>
                </Box>
              ))}
        </Grid> */}

          <StudioImages tab="myBackgrounds" myBackgroundsDatas={myBackgroundsDatas} />
        </Grid>
      </Grid>

      {openDialogUpBackground && (
        <FinalDialog
          open={openDialogUpBackground}
          onClose={() => {
            setOpenDialogUpBackground(false);
            handleClose();
          }}
          formatBackgroundSaved={value => setFormatBackgroundSaved(value)}
        />
      )}

      {idSelected && (
        <AlertDialogV2
          open={idSelected ? true : false}
          loading={loadingAction}
          contentText="Tem certeza que deseja excluir este fundo?"
          handleAgree={() => deleteBackground(idSelected)}
          handleClose={handleClose}
          textAgree="Excluir"
          textClose="Cancelar"
        />
      )}
    </>
  );
}

export default SelectBackground;

