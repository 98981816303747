import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Divider,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  MyButton,
  DatePicker,
  Countup,
  TextField,
  Modal,
  AutoComplete,
  Chip,
  DialogAlert,
  CustomTable,
} from '../../../utils/components';
import { onChangeState } from '../actions/index';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import '@webscopeio/react-textarea-autocomplete/style.css';
class EditarOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      nomeOferta: '',
      listaSelecionada: null,
      dataInicio: moment(),
      dataFim: moment(),
      produtos: [],
      showDialog: false,
      showDeleteProduto: false,
      produto: {},
    };
  }

  header(props, state) {
    const { nome_oferta, tipo_oferta } = props.ofertaCabecalho;

    const steps = ['Informações de Oferta', 'Informações do Cupom', 'Iniciar'];

    return (
      <Fragment>
        {`${nome_oferta} - ${tipo_oferta}`}
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return 'Salvar';
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return 'Proximo';
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep + 1,
              })
            }
            disabled={this.props.isLoadingEdit}
          >
            Proximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { nome_oferta, data_inicio, data_fim } = props.ofertaCabecalho;
    const { isLoadingEdit } = props;

    return (
      <section>
        {isLoadingEdit ? (
          <div
            style={{
              display: 'flex',
              padding: '5rem 20rem',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <section
            style={{ padding: '5rem 20rem', display: 'grid', gap: '2rem' }}
          >
            <Typography
              style={{ fontWeight: '900' }}
              color="primary"
              variant="h5"
            >
              Informações da Oferta
            </Typography>
            <TextField
              label="Nome"
              className="form-control"
              autoComplete="none"
              value={nome_oferta}
              shrink
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <section>
                <DatePicker
                  dateAndTime
                  disableFuture
                  label="Início da Oferta"
                  value={data_inicio}
                />
              </section>
              <section>
                <DatePicker
                  dateAndTime
                  disablePast
                  label="Término da Oferta"
                  value={data_fim}
                />
              </section>
            </div>
          </section>
        )}
      </section>
    );
  }
  step2(props, state) {
    const {
      familia,
      nomeProduto,
      preco,
      porcentagem,
      precoDesconto,
      pontos,
      cashback,
      produtos,
    } = state;
    const { tipo_oferta } = props.ofertaCabecalho;
    const {
      isLoadingProdutosFamilia,

      ofertaDetalhe,
    } = props;

    return tipo_oferta === 'cupom' ? (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Cupom de Desconto
        </Typography>
        <TextField
          label="Código do Cupom"
          shrink
          className="form-control"
          value={ofertaDetalhe.codigo_cupom}
        />
        <TextField
          type="number"
          shrink
          label="Valor mínimo de uso"
          className="form-control"
          value={ofertaDetalhe.valor_minimo}
        />
        <TextField
          type="number"
          shrink
          label="Limite de Utilização do Cupom"
          className="form-control"
          value={ofertaDetalhe.limite_uso}
        />
        <TextField
          type="number"
          shrink
          label="Valor do Desconto"
          className="form-control"
          value={ofertaDetalhe.valor_desconto}
        />
        <section>
          <Chip
            active={ofertaDetalhe.tipo_desconto === 'R$'}
            label="Reais (R$)"
            clickable
          />
          <Chip
            active={ofertaDetalhe.tipo_desconto === '%'}
            label="Porcentagem (%)"
            clickable
          />
        </section>
      </section>
    ) : (
      <section
        style={{
          padding: '2rem 1rem 5rem 1rem',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <div style={{ width: '25%' }}>
            <AutoComplete
              label="Familia"
              value={familia}
              suggestions={this.props.familias}
              onChange={value => this.setState({ familia: value })}
              onSelect={value =>
                this.setState({
                  familia: value,
                  idFamilia: this.props.familias.filter(
                    familia => familia.label === value
                  )[0].id_familia,
                })
              }
            ></AutoComplete>
          </div>
          <div style={{ width: '25%' }}>
            <AutoComplete
              label="Produto"
              value={isLoadingProdutosFamilia ? 'Carregando...' : nomeProduto}
              disabled={
                isLoadingProdutosFamilia === true || !familia ? true : false
              }
              onChange={value => this.setState({ nomeProduto: value })}
              suggestions={this.props.produtos}
              onSelect={value =>
                this.setState({
                  nomeProduto: this.props.produtos.filter(
                    prd => prd.label === value
                  )[0].label,
                  preco: this.props.produtos.filter(
                    prd => prd.label === value
                  )[0].preco,
                  idProduto: this.props.produtos.filter(
                    prd => prd.label === value
                  )[0].id_produto,
                })
              }
            ></AutoComplete>
          </div>
          {tipo_oferta === 'pontos' ? (
            <div style={{ width: '11%' }}>
              <TextField
                label="Pontos"
                value={pontos}
                disabled={!familia}
                shrink={true}
                onChange={e =>
                  this.setState({ pontos: e.target.value }, () => {
                    this.calculaDesconto();
                  })
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <div>
                //         <strong style={{ fontSize: 18 }}>%</strong>
                //       </div>
                //     </InputAdornment>
                //   )
                // }}
              ></TextField>
            </div>
          ) : (
            <Fragment>
              <div style={{ width: '11%' }}>
                <TextField
                  label="Preço"
                  value={preco ? MoedaBrasil(preco) : preco}
                  disabled
                  shrink={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div>
                          <Icon>attach_money</Icon>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </div>
              <div style={{ width: '11%' }}>
                <TextField
                  label="Porcentagem"
                  value={porcentagem}
                  disabled={!familia}
                  shrink={true}
                  onChange={e =>
                    this.setState({ porcentagem: e.target.value }, () =>
                      tipo_oferta === 'cashback'
                        ? this.calculaCashback()
                        : this.calculaDesconto()
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div>
                          <strong style={{ fontSize: 18 }}>%</strong>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </div>
              <div style={{ width: '11%' }}>
                {tipo_oferta === 'cashback' ? (
                  <TextField
                    label="Cashback"
                    value={cashback ? MoedaBrasil(cashback) : cashback}
                    shrink={true}
                    disabled
                    // onChange={e =>
                    //   this.setState({ cashback: e.target.value }, () =>

                    //   )
                    // }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>
                            <Icon>attach_money</Icon>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                ) : (
                  <TextField
                    label="Preço com desconto"
                    value={
                      precoDesconto ? MoedaBrasil(precoDesconto) : precoDesconto
                    }
                    shrink={true}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>
                            <Icon>attach_money</Icon>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}
              </div>
            </Fragment>
          )}

          <div style={{ width: '11%' }}>
            <Button
              size="large"
              variant="text"
              color="primary"
              disabled={!familia}
              onClick={() => this.addProdutos()}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <CustomTable
          title=""
          isLoading={this.props.isLoadingEdit}
          paging
          pagesSize={5}
          actions={[
            {
              icon: () => <Icon color="error">delete</Icon>,
              tooltip: 'Excluir',
              onClick: (event, rowData) =>
                this.setState({
                  showDeleteProduto: true,
                  produto: {
                    id_oferta: rowData.id_oferta,
                    id_oferta_detalhe: rowData.id_oferta_detalhe,
                    id_produto: rowData.idProduto,
                  },
                }),
            },
          ]}
          columns={[
            {
              field: 'url',
              Title: 'Produto',

              render: rowData =>
                rowData.url && (
                  <img
                    src={
                      rowData.url ||
                      'https://trali.com.br/wp-content/uploads/2018/04/Produto-sem-imagem.jpg'
                    }
                    alt=""
                    width={50}
                    height={50}
                    style={{ borderRadius: 10 }}
                  />
                ),
            },
            { title: 'Nome do Produto', field: 'nomeProduto' },
            { title: 'Familia', field: 'familia' },
            {
              title: 'Preço',
              field: 'preco',
              hidden: tipo_oferta === 'pontos' ? true : false,
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.preco}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              ),
            },
            {
              title: 'Porcentagem',
              field: 'porcentagem',
              hidden: tipo_oferta === 'pontos' ? true : false,
              render: rowData =>
                `${rowData.porcentagem ? rowData.porcentagem : 0}%`,
            },
            {
              title: 'Preço dois',
              field: 'precoDesconto',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.precoDesconto}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              ),
              hidden:
                tipo_oferta === 'cashback' || tipo_oferta === 'pontos'
                  ? true
                  : false,
            },
            {
              title: 'Cashback',
              field: 'cashback',
              render: rowData => (
                <Countup
                  start={0}
                  end={rowData.cashback}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              ),
              hidden:
                tipo_oferta === 'desconto' ||
                tipo_oferta === 'pontos' ||
                !tipo_oferta
                  ? true
                  : false,
            },
            {
              title: 'Pontos',
              field: 'pontos',
              hidden:
                tipo_oferta === 'desconto' ||
                tipo_oferta === 'cashback' ||
                !tipo_oferta
                  ? true
                  : false,
            },
          ]}
          data={[...ofertaDetalhe, ...produtos]}
        />
      </section>
    );
  }
  step3(props, state) {
    const { nome_oferta, data_inicio, data_fim } = this.props.ofertaCabecalho;
    const { ofertaDetalhe } = this.props;
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Sobre a Oferta
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome da Oferta</strong>
              </p>
              <span>{nome_oferta}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(data_inicio).format(
                'DD/MM/YYYY HH:mm'
              )} Até ${moment(data_fim).format('DD/MM/YYYY HH:mm')}`}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Produtos</strong>
              </p>
              <div
                style={{
                  display: 'flex',

                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                {ofertaDetalhe.map(prd => (
                  <Chip label={prd.nomeProduto}></Chip>
                ))}
              </div>
            </section>
            {/* <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Total de Envios</strong>
              </p>
              <span>
                <Countup
                // isLoading={props.isLoadingCountPhone}
                // end={props.contador_celular.contador_celular || 0}
                />
              </span>
            </section> */}
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              onClick={() => {
                this.props.onClose();
              }}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;

    const { activeStep, showDialog, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? 'xl' : 'lg'}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {showDialog && (
          <DialogAlert
            open={showDialog}
            title="Deseja dar desconto em todos produtos dessa familia?"
            onClick={() =>
              this.setState({ showDialog: false }, () => this.addFamilia())
            }
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
        {showDeleteProduto && (
          <DialogAlert
            open={showDeleteProduto}
            title="Deseja remover este produto?"
            onClick={() => this.setState({ showDeleteProduto: false })}
            onClose={() =>
              this.setState({ showDeleteProduto: false, produto: {} })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

// const _disableButtonSave = (sms, alert) => {
//   // if (message && !alert && optionList) {
//   //   return false;
//   // } else {
//   //   return true;
//   // }
// };

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({
  OfertasReducer,
  CampaignReducer,
  InsightsReducer,
}) => ({ ...OfertasReducer, ...CampaignReducer, ...InsightsReducer });
const mapDispatchToProps = {
  onChangeState,
};

const EditarOfertaaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditarOferta);

export default withStyles(styles)(EditarOfertaaConnect);
