const INITIAL_STATE = {
  tipoRecomendacoes: [],
  recomendacoesAtivas: [],
  recomendacoesInativas: [],
  listaOfertas: [],
  contador_celular: 0,
  contador_email: 0,

  isLoadingCountPhone: false,
  isLoadingCountEmail: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CONTADOR_WHATSAPP":
      return {
        ...state,
        contador_celular: action.payload,
      };
    case "CONTADOR_CELULAR":
      return {
        ...state,
        contador_celular: action.payload,
        isLoadingCountPhone: false
      };
    case "CONTADOR_EMAIL":
      return {
        ...state,
        contador_email: action.payload.contador_email,
        isLoadingCountEmail: false
      };
    case "TIPO_RECOMENDACOES":
      return { ...state, tipoRecomendacoes: action.payload };
    case "RECOMENDACOES":
      return {
        ...state,
        recomendacoesAtivas: action.payload.recomendacoesAtivas,
        recomendacoesInativas: action.payload.recomendacoesInativas,
        listaOfertas: action.payload.ofertas
      };
    default:
      return state;
  }
};
