import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Countup, CustomTable, MyButton } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { toBase64 } from '../../../utils/helpers/Functions';
import { postProdutoImagemTabloide } from '../../TabloideDigital/action/index';
import { getLojas, postLoja, putInfoLoja } from '../action/action';
import ModalAddLoja from './ModalAddLoja';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

function Lojas(props) {
  const { classes } = props,
    [newData, setNewData] = useState(null),
    [modalAddLoja, setModalAddLoja] = useState(false),
    [modalRemoverFundo, setModalRemoverFundo] = useState(false),
    [naoRemoverFundo, setNaoRemoverFundo] = useState(false);

  useEffect(() => {
    props.getLojas();
  }, []);

  function onSaveLoja(loja) {
    const objSaveLoja = {
      bairro: loja.bairro,
      cep: loja.cep,
      cidade: loja.cidade,
      descricao: loja.descricao,
      endereco: loja.endereco,
      frete: parseFloat(loja.frete.replace(',', '.')),
      maximo_item: loja.maximo_item,
      minimo_compra: parseFloat(loja.minimo_compra.replace(',', '.')),
      nome_loja: loja.nome_loja,
      numero: loja.numero,
      tipo_pedido: loja.tipo_pedido,
      taxa_servico: parseFloat(loja.taxa_servico.replace(',', '.')),
      forma_pagamento: loja.forma_pagamento,
      uf: loja.uf,
      whatsapp_phone_number: loja.whatsapp_phone_number,
      aceita_dinheiro: loja.aceita_dinheiro,
      aceita_debito: loja.aceita_debito,
      aceita_credito: loja.aceita_credito,
      aceita_boleto: loja.aceita_boleto,
      aceita_vr: loja.aceita_vr,
      chave_pix: loja.chave_pix
    };
    setModalAddLoja(false);
    props.postLoja(objSaveLoja);
  }

  const [count, setCount] = useState(0);

  async function onUpdateLoja() {
    let new_image = '',
      data = { ...newData };

    if (Boolean(data.logo1) && /;base64,/g.test(data.logo1)) {
      new_image = await props.postProdutoImagemTabloide({
        imagem_produto: data.logo1,
        isBase64: true,
        naoRemoverFundo,
        isLogo: true
      });
    }
    let dateNow = new window.Date();
    dateNow = dateNow.getTime().toString();
    if (validURL(new_image)) new_image = new_image.split('?')[0] + '?' + dateNow;
    data = { ...data, logo1: new_image };

    props.putInfoLoja(data);
  }

  useEffect(() => {
    if (count > 0) {
      onUpdateLoja();
    }
  }, [count]);

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Lojas
        </Typography>
        <MyButton
          onClick={() => setModalAddLoja(true)}
          size="large"
          className={classes.button}
          variant="text"
          color="primary"
        >
          Adicionar loja
        </MyButton>
      </div>
      <Divider className={classes.divider} />
      <div
        className={classes.content}
        style={{
          display: 'flex',
          height: '100%',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CustomTable
          data={props.lojas || []}
          isLoading={props.loadingDelivery}
          title=""
          columnsButton
          pageSize={10}
          paging
          search
          headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
            backgroundColor: '#eee'
          }}
          columns={[
            {
              field: 'logo1',
              title: 'Logo',
              editComponent: props => (
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#e7e7e7'
                  }}
                >
                  <img
                    alt="logo"
                    crossOrigin="anonymous"
                    src={
                      props.value
                        ? props.value.includes('data:')
                          ? props.value
                          : props.value.split('?')[0] + '?' + Math.random().toString()
                        : null ||
                          'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{ width: 70 }}
                  />
                  <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="change-image"
                      type="file"
                      onChange={async ({ target: { files } }) => {
                        const imageEdit = await toBase64(files[0]);
                        props.onChange(imageEdit.base64);
                      }}
                    />
                    <label htmlFor="change-image">
                      <Button size="small" component="span">
                        Escolher foto
                      </Button>
                    </label>
                  </div>
                </section>
              ),
              render: rowData => (
                <img
                  alt={rowData.logo1}
                  crossOrigin="anonymous"
                  src={
                    rowData.logo1
                      ? rowData.logo1.split('?')[0] + '?' + Math.random().toString()
                      : null
                  }
                  style={{
                    width: 70,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7'
                  }}
                />
              )
            },
            {
              title: 'Loja',
              field: 'nome_loja',
              editable: 'never'
            },
            {
              title: 'Whatsapp',
              field: 'whatsapp_phone_number'
            },
            {
              title: 'Tipo pedido',
              field: 'tipo_pedido',
              lookup: { 1: 'Delivery', 2: 'Drive', 3: 'Delivery/Drive' }
            },
            {
              title: 'Taxa de serviço',
              field: 'taxa_servico',
              type: 'numeric',
              render: rowData => (
                <Countup
                  prefix="R$ "
                  start={0}
                  end={rowData.taxa_servico}
                  duration={1}
                  decimals={2}
                />
              )
            },
            {
              title: 'Valor do frete',
              field: 'frete',
              type: 'numeric',
              render: rowData => (
                <Countup prefix="R$ " start={0} end={rowData.frete} duration={1} decimals={2} />
              )
            },
            {
              title: 'Quantidade máxima de itens',
              field: 'maximo_item',
              type: 'numeric',
              render: rowData => (
                <Countup
                  start={parseInt(rowData.maximo_item, 10)}
                  end={parseInt(rowData.maximo_item, 10)}
                  duration={1}
                />
              )
            },
            {
              title: 'Valor mínimo de compra',
              field: 'minimo_compra',
              type: 'numeric',
              render: rowData => (
                <Countup
                  prefix="R$ "
                  start={0}
                  end={rowData.minimo_compra}
                  duration={1}
                  decimals={2}
                />
              )
            },
            {
              title: 'Chave PIX',
              field: 'chave_pix'
            },
            {
              title: 'Descrição',
              field: 'descricao'
            },
            {
              title: 'CEP',
              field: 'cep'
            },

            {
              title: 'Endereco',
              field: 'endereco'
            },
            {
              title: 'Numero',
              field: 'numero'
            },

            {
              title: 'Bairro',
              field: 'bairro'
            },

            {
              title: 'Cidade',
              field: 'cidade'
            },

            {
              title: 'UF',
              field: 'uf'
            }
          ]}
          editable={{
            onRowUpdate: newData =>
              new Promise(async resolve => {
                setNewData(newData);
                setModalRemoverFundo(true);
                resolve();
              })
          }}
        />
      </div>

      <ModalAddLoja
        showModal={modalAddLoja}
        onSave={loja => onSaveLoja(loja)}
        onClose={() => setModalAddLoja(false)}
      />

      <Dialog open={modalRemoverFundo} onClose={() => setModalRemoverFundo(false)}>
        <DialogTitle>Deseja remover o fundo da imagem?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setModalRemoverFundo(false);
              setNaoRemoverFundo(true);
              setCount(prev => prev + 1);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              setModalRemoverFundo(false);
              setNaoRemoverFundo(false);
              setCount(prev => prev + 1);
            }}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2)
  },
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({ DeliveryReducer }) => ({ ...DeliveryReducer });

const mapDispatchToProps = {
  getLojas,
  putInfoLoja,
  postLoja,
  postProdutoImagemTabloide
};

const LojasConn = connect(mapStateToProps, mapDispatchToProps)(Lojas);

export default withStyles(styles)(LojasConn);
