import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import '../styles/alert.css';
import { COLOR_PRIMARY } from './Constants';

const MySwal = withReactContent(Swal);

export function useAlerts() {
  async function dialogAndStep() {
    await MySwal.mixin({
      input: 'textarea',
      confirmButtonText: 'Next &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2'],
      confirmButtonColor: COLOR_PRIMARY
    }).queue([
      {
        title: 'Justificativa',
        text: 'Escreva uma justificativa...'
      },
      {
        title: 'CPF',
        text: 'Digite o seu CPF...'
      }
    ]);
  }

  async function process(title, message = '') {
    await MySwal.fire({
      title: <p>{title}</p>,
      text: message,
      timer: 35000,
      timerProgressBar: true,
      onBeforeOpen: () => MySwal.showLoading(),
      confirmButtonColor: COLOR_PRIMARY
    });
  }

  async function error(title, message = '', callback) {
    try {
      const response = await MySwal.fire({
        icon: 'error',
        title,
        text: message
      });
      if (callback) {
        callback(response);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function warning(title, message = '') {
    await MySwal.fire({
      icon: 'warning',
      title,
      text: message
    });
  }

  async function success(title, message = '', footer = '') {
    await MySwal.fire({
      icon: 'success',
      title,
      footer,
      text: message
    });
  }

  async function confirm(message = '', callback) {
    await MySwal.fire({
      text: message,
      showCancelButton: true,
      confirmButtonColor: COLOR_PRIMARY,
      confirmButtonText: 'Sim',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  }

  return {
    dialogAndStep,
    process,
    error,
    warning,
    success,
    confirm
  };
}
export default class Alert {
  constructor(message, wait = 5000) {
    this._message = message;
  }

  async alertDialogAndStep(callback) {
    await MySwal.mixin({
      input: 'textarea',
      confirmButtonText: 'Next &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2'],
      confirmButtonColor: COLOR_PRIMARY
    }).queue([
      {
        title: 'Justificativa',
        text: 'Escreva uma justificativa...'
      },
      {
        title: 'CPF',
        text: 'Digite o seu CPF...'
      }
    ]);
  }

  async alertProcess(title, message = this._message, { timer } = {}) {
    await MySwal.fire({
      title: <p>{title}</p>,
      text: message,
      timer: timer || 35 * 1000,
      timerProgressBar: true,
      onBeforeOpen: () => MySwal.showLoading(),
      confirmButtonColor: COLOR_PRIMARY
    });
  }

  async alertError(
    title,
    message = this._message,
    cancelButtonText = null,
    confirmButtonText = null,
    confirmButtonOpenLinkOnNewTab = null,
    callback = null
  ) {
    const alertOptions = {
      icon: 'error',
      title,
      text: message,
      color: '#36325e',
      confirmButtonColor: '#36325e'
    };

    if (cancelButtonText) {
      alertOptions.cancelButtonText = cancelButtonText;
      alertOptions.showCancelButton = true;
    }
    if (confirmButtonText) alertOptions.confirmButtonText = confirmButtonText;

    try {
      const response = await MySwal.fire(alertOptions).then(result => {
        if (confirmButtonOpenLinkOnNewTab) {
          if (result.isConfirmed) {
            window.open(confirmButtonOpenLinkOnNewTab, '_blank');
          } else if (result.isDenied || result.isDismissed) {
          }
        }
      });
      if (callback) {
        callback(response);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async alertWarning(title, message = this._message) {
    await MySwal.fire({
      icon: 'warning',
      title,
      text: message
    });
  }

  async alertSuccess(title, message = this._message, footer = '') {
    await MySwal.fire({
      icon: 'success',
      title,
      footer,
      text: message
    });
  }

  async alertConfirm(message = this._message, callback) {
    await MySwal.fire({
      text: message,
      showCancelButton: true,
      confirmButtonColor: COLOR_PRIMARY,
      confirmButtonText: 'Sim',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  }
}

