import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    footer: {
      width: '100vw',
      height: '80px',
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 40px',
      bottom: 0,
      left: 0,
      backgroundColor: '#fff',
      boxShadow: '0px -9px 25px rgba(0, 0, 0, 0.3)',
      zIndex: 1201
    },
    subcategoriesBackground: {
      position: 'relative',
      width: '64px',
      height: '64px',
      display: 'inline-block',
      marginRight: '8px',
      '&:before': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        backgroundColor: 'rgba(250, 250, 250, 0.5)',
      }
    },
    subcategoriesBackgroundCheckbox: {
      position: 'absolute !important',
      top: '-6px',
      left: '-6px',
      transform: 'scale(.8)',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '& svg': {
        fill: '#2813AD'
      }
    }
  })
);
