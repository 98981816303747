const INITIAL_STATE = {
  configCashback: {
    dias_inicio: "0",
    dias_periodo: "0"
  },
  isLoadingCashback: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CONFIG_CASHBACK":
      return {
        ...state,
        configCashback: action.payload,
        isLoadingCashback: false
      };
    case "CHANGE_CASHBACK":
      const { key, value } = action.payload;
      return {
        ...state,
        configCashback: { ...state.configCashback, [key]: value }
      };
    case "LOADING_CASHBACK":
      return { ...state, isLoadingCashback: true };
    default:
      return { ...state };
  }
};
