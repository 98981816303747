import { makeStyles, createStyles } from '@material-ui/core/styles';
import imagePhone from '../../../../assets/images/phone.png';

const styles = makeStyles(theme =>
  createStyles({
    content: { flexGrow: 1, height: '100%' },
    button: { margin: theme.spacing(1) },
    avatar: { backgroundColor: theme.palette.primary.main },
    actions: { justifyContent: 'space-between' },
    image: { width: '100%' },
    phone: {
      width: '100%',
      '&:after': {
        backgroundImage: `url(${imagePhone})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
      },
    },
    reachTitle: {
      fontSize: '10pt',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20vh',
      width: '100%',
    },
    root: { display: 'flex', justifyContent: 'center' },
    contentIphone: {
      height: '65vh',
      width: '15vw',
      display: 'inline-block',
      border: `5px solid ${theme.palette.primary.main}`,
      borderRadius: 25,
      boxShadow: '0 19px 38px rgb(0 0 0 / 31%), 0 15px 12px rgb(0 0 0 / 22%)',
      position: 'relative',
      overflowY: 'overlay',
    },
    topbar: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      left: 'calc(22%)',
      top: -5,
      width: '56%',
      backgroundColor: theme.palette.primary.main,
      height: 12,
      borderRadius: '0px 0px 15px 15px',
    },
    contentTopbar: {
      backgroundColor: '#000',
      height: 3,
      marginTop: 5,
      width: 30,
      borderRadius: '20%',
    },
    btnVolune: {
      height: '6vh',
      listStyle: 'none',
      borderRadius: '20px 20px 0 0',
    },
    contentMobile: {
      color: theme.palette.primary.main,
      letterSpacing: -0.8,
      padding: '1vh 0vw',
    },
    avatarMobile: {
      backgroundColor: theme.palette.primary.main,
      width: 25,
      height: 25,
    },
    ulComment: {
      marginLeft: 12,
      paddingTop: 3,
      minHeight: 15,
      fontSize: 12,
      lineHeight: '12px',
      color: theme.palette.primary.main,
      display: 'flex',
    },
    liComment: {
      marginRight: 12,
    },
    collapseComment: {
      maxHeight: '40vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    rootComment: {
      boxSizing: 'border-box',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      borderRadius: 18,
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      width: 'fit-content',
    },
    nameComment: {
      color: '#FFF !important',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      fontWeight: 600,
      lineHeight: '1.23080',
      fontSize: '.8125rem',
    },
    comment: {
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      maxWidth: '100%',
      fontWeight: 400,
      lineHeight: '1.3333',
      fontSize: '.9375rem',
    },
  })
);

export default styles;
