import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { Autocomplete, makeValidate, TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  role: Yup.number().required('O cargo é obrigatório'),
  permissions: Yup.array().of(
    Yup.object().shape({
      moduleId: Yup.number().required(),
      serviceId: Yup.number().required(),
    })
  ),
  password: Yup.string().min(6, 'A senha tem que ter mais que 6 caractéres.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas estão diferentes.'
  ),
});

function EditUser() {
  const classes = useStyles();

  const [companyPermissions, setCompanyPermissions] = useState();
  const [roles, setRoles] = useState();

  const history = useHistory();

  const companyId = localStorage.getItem('companyId');

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/permissions`
        );

        setCompanyPermissions(
          response.map(item => ({
            moduleId: item.module.id,
            moduleName: item.module.name,
            serviceId: item.service.id,
            serviceName: item.service.name,
          }))
        );
      } catch (err) {
        console.error(err);
      }
    })();
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/roles`
        );

        setRoles(response);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  async function onSubmit(data) {
    try {
      await api(
        `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/user/new`,
        data,
        'POST'
      );
      history.push('/account-settings');
    } catch (err) {
      console.error(err);
    }
  }

  if (!companyPermissions || !roles) {
    return null;
  }

  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item>
          <Grid item>
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                history.push('/account-settings');
              }}
            >
              voltar
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={4}>
              <Grid item container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6">Adicionar Usuário</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Form
                  initialValues={{ companyId, permissions: [] }}
                  onSubmit={onSubmit}
                  validate={makeValidate(schema)}
                  render={({values, handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField className={classes.textFormTest}
                            name="name"
                            fullWidth
                            variant="outlined"
                            label="Nome"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField className={classes.textFormTest}
                            name="email"
                            fullWidth
                            variant="outlined"
                            label="Email"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField className={classes.textFormTest}
                            name="password"
                            fullWidth
                            variant="outlined"
                            label="Senha"
                            security
                            type="password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField className={classes.textFormTest}
                            name="confirmPassword"
                            fullWidth
                            variant="outlined"
                            label="Confirme sua Senha"
                            security
                            type="password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete className={classes.textFormTest}
                            label="Cargos"
                            name="role"
                            textFieldProps={{ variant: 'outlined' }}
                            options={roles}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete className={classes.textFormTest}
                            label="Permissōes"
                            name="permissions"
                            required
                            textFieldProps={{ variant: 'outlined' }}
                            options={companyPermissions}
                            groupBy={option => option.moduleName}
                            getOptionLabel={option => option.serviceName}
                            disableCloseOnSelect
                            multiple
                          />
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="info"
                              size="large"
                              disabled={submitting}
                              startIcon={<SaveIcon />}
                            >
                              Salvar
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="large"
                              disabled={submitting}
                              startIcon={<UndoIcon />}
                            >
                              Limpar
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="large"
                              onClick={() => {
                                history.push('/modules');
                              }}
                              startIcon={<CloseIcon />}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditUser;
