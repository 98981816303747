export const CPF = cpf =>
  cpf && cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
export const CNPJ = cnpj =>
  cnpj &&
  cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
export const RG = rg =>
  rg && rg.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
export const CEP = cep => cep && cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
export const Telefone = telefone =>
  telefone && telefone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
export const Celular = telefone =>
  telefone &&
  telefone
    .replace(/ /g, '')
    .replace('-', '')
    .replace(/^(\d{2})(\d{4})|(\d{5})(\d{4})/, '($1) $2-$3');
export const Celular2 = celular =>
  celular &&
  celular.replace(/^(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})/gm, '+$1 $2 $3-$4');

export const Celular3 = (celular) => {
  celular=celular.replace(/\D/g,"");
  celular=celular.replace(/^(\d{2})(\d)/g,"($1) $2");
  celular=celular.replace(/(\d)(\d{4})$/,"$1-$2");
  return celular;
}

export const removeLetters = (value) => {
  value = value.replace(/\D/g,"");
  return value;
}

export const nmrValidator = nmr => {
  const re = /^[0-9]{0,11}$/;
  return re.test(nmr);
};
export const EmailValidator = email => {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const CelularValidator = celular =>
  /\(\d{2,}\) \d{4,5}-\d{4}/g.test(celular.trim());
