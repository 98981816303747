/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Step,
  StepButton,
  Stepper,
  Typography,
  withStyles,
} from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { CompactPicker } from 'react-color';
import {
  ImageCard,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import {
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas,
  v2_putOfertaFisicoMaior,
  getTabloidsTemplatesFisico,
  getTabloidsTemplateById,
  postVideomatik,
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AMBIENTE,
  DATASALES_CRM_API,
  COLOR_PRIMARY,
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import { TextField as TextFieldAC } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import SelecionarProdutos from '../SelecionarProdutos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { withSnackbar } from 'notistack';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const fator = 8;

//APAGAR ESSE JSON
//var jsonTeste = `{\"modifications\":{\"imagem-fundo\":{\"name\":\"imagem-fundo\",\"type\":\"image\",\"image_url\":\""},\"produto-imagem\":{\"name\":\"produto-imagem-&numb&\",\"type\":\"image\",\"image_url\":\"\"},\"produto-descricao\":{\"name\":\"produto-descricao-&numb&\",\"type\":\"text\",\"text\":\"produto\"},\"produto-preco-por\":{\"name\":\"produto-preco-por-&numb&\",\"type\":\"text\",\"text\":\"R$ 10,90\"}},\"extras\":[{\"name\":\"logo-empresa-01\",\"type\":\"image\",\"image_url\":\"https://s3.amazonaws.com/datasalesio-imagens/6cdb6708-d38b-4140-065e-5b6c674f9bc9.png\"},{\"name\":\"descritivo-texto-001\",\"type\":\"text\",\"text\":\"Ofertas válidas até XX/XX ou enquanto durarem os estoques\"},{\"name\":\"endereco-01\",\"type\":\"text\",\"text\":\"Av. Santos Drumont, 990\"},{\"name\":\"endereco-02\",\"type\":\"text\",\"text\":\"19999081150\"}]}`;

class GeneratePixelixe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: 'Encarte-A4-12P-CPD-inicio_do_inverno-001',
      qtde_produtos: 12,
      tipoPixelixe: '',
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: { r: 0, g: 0, b: 0, a: 1 },
      colorPrice: { r: 255, g: 255, b: 255, a: 1 },
      showDesativarModelo: false,
      etiquetas: [],
      etiqueta: null,
      font_size: null,
      listaSegmentos: {},
      segmentoSelected: null,
      inputSegmentoSelected: '',
      listaTiposPixelixe: {},
      tipoSelected: null,
      inputTipoSelected: '',
      listaFormatos: {},
      formatoSelected: null,
      inputFormatoSelected: '',
      listaQtdes: {},
      qtdeSelected: null,
      inputQtdeSelected: '',
      listaOcasioes: {},
      ocasiaoSelected: null,
      inputOcasiaoSelected: '',
      listaTemplates: {},
      templateSelected: null,
      loadingTemplates: false,
      page: 8,
      paginaDe: null,
      paginaAte: null,
      miniatura: null,
      gerarEtiquetaPersonalizada: false,
      //
      categoriaEmFoco: 0,
      //
      selecionarProdutosOpen: false,
      //
      moreEtiqueta: 1,
      //
      idsNaoIncluidos: [],
      //
      auxExtra: {}, //objeto extra pra auxiliar a priore FG FARMA com template personalizado
      //
      antigoChecked: false,
      //
      multImagens: null,
    };
  }

  componentDidMount() {
    (async () => {
      this.props.getTabloidsEtiquetas().then(payload => {
        for (var j = 0; j < this.props.tabloids_etiquetas.length; j++) {
          this.setState({
            etiquetas: [
              ...this.state.etiquetas,
              {
                ...this.props.tabloids_etiquetas[j],
                selected: false,
              },
            ],
          });
        }
      });
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
        );

        this.setState({ stores: response });
      } catch (err) {
        console.error(err);
      }
    })();
    this.carregaTemplates();
  }

  carregaTemplates = () => {
    try {
      this.setState({ loadingTemplates: true });
      this.props
        .getTabloidsTemplatesFisico({
          pagina: 1,
          segmento: this.state.segmentoSelected,
          tipoPixelixe: this.state.tipoSelected,
          formato: this.state.formatoSelected,
          qtde_produtos: this.state.qtdeSelected,
          ocasiao: this.state.ocasiaoSelected,
        })
        .then(payload => {
          this.setState({
            listaSegmentos: payload.listaSegmentos,
            listaTiposPixelixe: payload.listaTiposPixelixe,
            listaFormatos: payload.listaFormatos,
            listaQtdes: payload.listaQtdes,
            listaOcasioes: payload.listaOcasioes,
            //
            segmentoSelected:
              this.state.segmentoSelected || payload.listaSegmentos[0],
            tipoSelected:
              this.state.tipoSelected || payload.listaTiposPixelixe[0],
            formatoSelected:
              this.state.formatoSelected || payload.listaFormatos[0],
            qtdeSelected: this.state.qtdeSelected || payload.listaQtdes[0],
            //
            page: 8,
            loadingTemplates: false,
          });

          let listaTemplates = {};

          payload.response.reduce((acum, curr, index) => {
            listaTemplates[curr['nome'] + ' / id:' + curr['id']] = [
              curr['miniatura'],
              curr['template'],
              curr['id'],
              index,
            ];
          }, {});

          this.setState({ listaTemplates: listaTemplates });
        })
        .catch(erro => {
          this.setState({ loadingTemplates: false });
        });
    } catch {}
  };

  showPopover = current => {
    this.setState({ anchorEl: current });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeCompleteOthers = color => {
    this.setState(
      {
        colorText: color.rgb,
      },
      () => {
        let tmp = { ...this.state.json_parse };

        if (tmp.modifications && tmp.modifications['produto-descricao']) {
          tmp.modifications[
            'produto-descricao'
          ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }

        this.setState({ json_parse: { ...tmp } });
      }
    );
  };

  handleChangeCompletePrice = color => {
    this.setState(
      {
        colorPrice: color.rgb,
      },
      () => {
        let tmp = { ...this.state.json_parse };

        if (tmp.modifications) {
          tmp.modifications['price-color'] = {
            color: `rgb(${this.state.colorPrice.r}, ${this.state.colorPrice.g}, ${this.state.colorPrice.b})`,
          };
        }

        this.setState({ json_parse: { ...tmp } });
      }
    );
  };

  header(props, state) {
    const steps = ['Filtros', 'Variáveis'];

    return (
      <Fragment>
        Gerar Arte - {this.props.tabloid.qtde_produtos_lista} Produtos no Total
        {/* <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div> */}
      </Fragment>
    );
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: 5,
          width: '100%',
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            variant="contained"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
            style={{ marginRight: 20 }}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep === 1 && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!this.state.gerarEtiquetaPersonalizada) {
                  if (this.state.activeStep === 1) {
                    this.props.enqueueSnackbar('Processando...', {
                      variant: 'info',
                    });
                    this.props
                      .putOfertaFisico({
                        id: this.props.tabloid.id_tabloide_digital,
                        id_template: this.state.id_template,
                        nome_modelo: this.state.nome_modelo,
                        chave_template: this.state.template,
                        produto_qtde: this.state.qtde_produtos,
                        tipoPixelixe: this.state.tipoPixelixe,
                        json: JSON.stringify(this.state.json_parse),
                        etiqueta: this.state.etiqueta,
                        paginaDe: parseFloat(this.state.paginaDe) - 1,
                        paginaAte: parseFloat(this.state.paginaAte),
                        idsNaoIncluidos: this.state.idsNaoIncluidos,
                        antigoChecked: this.state.antigoChecked,
                        multImagens: this.state.multImagens,
                        ...this.state.auxExtra,
                      })
                      .then(resp => {
                        this.setState({ activeStep: 0 });
                        this.setState({ auxExtra: {} });

                        this.props.enqueueSnackbar(resp.message, {
                          variant: 'success',
                        });
                      })
                      .catch(err => {
                        this.setState({ auxExtra: {} });

                        this.props.enqueueSnackbar('Falha de processamento', {
                          variant: 'error',
                        });
                      });
                  }
                } else {
                  this.state.activeStep === 1 &&
                    this.props
                      .v2_putOfertaFisicoMaior({
                        id: this.props.tabloid.id_tabloide_digital,
                        id_template: this.state.id_template,
                        nome_modelo: '#testeV2: ' + this.state.nome_modelo,
                        chave_template: this.state.template,
                        produto_qtde: this.state.qtde_produtos,
                        tipoPixelixe: this.state.tipoPixelixe,
                        json: JSON.stringify(this.state.json_parse),
                        etiqueta: this.state.etiqueta,
                        paginaDe: parseFloat(this.state.paginaDe) - 1,
                        paginaAte: parseFloat(this.state.paginaAte),
                        idsNaoIncluidos: this.state.idsNaoIncluidos,
                        ...this.state.auxExtra,
                      })
                      .then(() => {
                        this.setState({ activeStep: 0 });
                        this.setState({ auxExtra: {} });
                      });
                }
              }}
              disabled={false}
            >
              Gerar
            </Button>
          </div>
        )}
      </div>
    );
  }

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  loadData = async () => {
    await this.sleep(300).then(() => {
      this.setState({ page: this.state.page + 1 });
    });
  };

  selectOnChangeSegmento = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          segmentoSelected: newValue,
          listaTiposPixelixe: {},
          tipoSelected: null,
          listaFormatos: {},
          formatoSelected: null,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeTipo = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          tipoSelected: newValue,
          listaFormatos: {},
          formatoSelected: null,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeFormato = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          formatoSelected: newValue,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeQtde = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          qtdeSelected: newValue,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeOcasiao = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          ocasiaoSelected: newValue,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  storeComplete = storeVar => {
    let enderecoComplete = '';
    if (storeVar.endereco) {
      enderecoComplete = enderecoComplete + storeVar.endereco;
    }
    if (storeVar.numero) {
      enderecoComplete = enderecoComplete + ', ' + storeVar.numero;
    }
    if (storeVar.bairro) {
      enderecoComplete = enderecoComplete + ', ' + storeVar.bairro;
    }
    if (storeVar.cep) {
      enderecoComplete = enderecoComplete + ', ' + storeVar.cep;
    }
    if (storeVar.cidade) {
      enderecoComplete = enderecoComplete + ', ' + storeVar.cidade;
    }
    if (storeVar.uf) {
      enderecoComplete = enderecoComplete + ', ' + storeVar.uf;
    }

    return enderecoComplete;
  };

  step1(props, state) {
    const is396 = JSON.parse(localStorage.getItem('companyId')) === 396;

    const { classes } = this.props;

    const ObjectKeysTemplates = Object.keys(this.state.listaTemplates).slice(
      0,
      this.state.page * fator
    );

    return (
      <Fragment>
        {is396 && (
          <div>
            <Button
              color="primary"
              size="large"
              style={{
                ...(() =>
                  this.state.categoriaEmFoco === 0
                    ? {
                        backgroundColor: COLOR_PRIMARY,
                        color: 'white',
                      }
                    : {})(),
              }}
              onClick={() => this.setState({ categoriaEmFoco: 0 })}
            >
              Imagens
            </Button>
            <Button
              style={{
                ...(() =>
                  this.state.categoriaEmFoco === 1
                    ? {
                        backgroundColor: COLOR_PRIMARY,
                        color: 'white',
                      }
                    : {})(),
              }}
              color="inherit"
              size="large"
              onClick={() => this.setState({ categoriaEmFoco: 1 })}
            >
              Vídeos
            </Button>
          </div>
        )}

        {this.state.categoriaEmFoco === 0 && (
          <InfiniteScroll
            dataLength={ObjectKeysTemplates.length}
            next={this.loadData}
            loader={<h4>Loading...</h4>}
            hasMore={this.state.page * fator <= ObjectKeysTemplates.length}
            height={950}
            style={{ overflowAnchor: 'none' }}
            //scrollableTarget="scrollableDiv"
          >
            <section>
              {/* <section style={{ padding: '2rem 4rem' }}>
                    <Typography
                      style={{ fontWeight: '900' }}
                      color="primary"
                      variant="h5"
                    >
                      Filtros
                    </Typography>
                  </section> */}
              <section>
                <div style={{ display: 'flex', margin: '20px 30px 30px' }}>
                  <Autocomplete
                    value={this.state.segmentoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeSegmento(null, newValue)
                    }
                    inputValue={this.state.inputSegmentoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputSegmentoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaSegmentos}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Segmento"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.tipoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeTipo(null, newValue)
                    }
                    inputValue={this.state.inputTipoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputTipoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaTiposPixelixe}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Tipo"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.formatoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeFormato(null, newValue)
                    }
                    inputValue={this.state.inputFormatoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputFormatoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaFormatos}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Formato"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.qtdeSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeQtde(null, newValue)
                    }
                    inputValue={this.state.inputQtdeSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputQtdeSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaQtdes}
                    style={{ width: 200, marginRight: 15 }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Produtos por página"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.ocasiaoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeOcasiao(null, newValue)
                    }
                    inputValue={this.state.inputOcasiaoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputOcasiaoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaOcasioes}
                    style={{ width: 200, marginRight: 15 }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Ocasião"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  {is396 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.gerarEtiquetaPersonalizada}
                          onChange={event => {
                            this.setState({
                              gerarEtiquetaPersonalizada: event.target.checked,
                            });
                          }}
                          name="Etiqueta Personalizada"
                          color="primary"
                        />
                      }
                      label="Etiqueta Personalizada"
                    />
                  )}
                </div>
              </section>
              <Divider />
              <section
                style={{
                  padding: '2rem 1rem',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <GridList
                      cellHeight="auto"
                      cols={8} //matches ? 8 : 6
                      spacing={8}
                      className={classes.gridList}
                    >
                      {this.state.loadingTemplates
                        ? Array(10)
                            .fill(10)
                            .map((_, i) => (
                              <GridListTile>
                                <Skeleton
                                  variant="rect"
                                  height={160}
                                  width="100%"
                                />
                              </GridListTile>
                            ))
                        : ObjectKeysTemplates.map((valueAtual, index) => (
                            <GridListTile
                              className={classes.tile}
                              key={valueAtual}
                            >
                              <CardActionArea
                                onClick={() => {
                                  var templateSelected =
                                    this.props.tabloids_templates_fisico[
                                      this.state.listaTemplates[valueAtual][3]
                                    ];
                                  this.setState({ loadingTemplates: true });
                                  this.props
                                    .getTabloidsTemplateById({
                                      id: templateSelected.id,
                                    })
                                    .then(payload => {
                                      if (payload[0])
                                        this.setState(
                                          {
                                            loadingTemplates: false,
                                            tipoPixelixe:
                                              templateSelected.tipoPixelixe,
                                            formato: templateSelected.formato,
                                            qtde_produtos:
                                              templateSelected.qtde_produtos,
                                            ocasiao: templateSelected.ocasiao,
                                            id_template: templateSelected.id,
                                            nome_modelo: templateSelected.nome,
                                            miniatura:
                                              templateSelected.miniatura,
                                            json: payload[0].json,
                                            json_parse: JSON.parse(
                                              payload[0].json
                                            ),
                                            template: templateSelected.template,
                                            store: this.state.stores[0] || null,
                                            activeStep:
                                              this.state.activeStep + 1,
                                            paginaDe: 1,
                                            paginaAte: Math.ceil(
                                              this.props.tabloid
                                                .qtde_produtos_lista /
                                                templateSelected.qtde_produtos
                                            ),
                                          },
                                          () => {
                                            const tmp = {
                                              ...this.state.json_parse,
                                            };

                                            if (
                                              tmp.extras &&
                                              this.state.store
                                            ) {
                                              let indexOf =
                                                tmp.extras.findIndex(
                                                  i =>
                                                    i.name === 'logo-empresa-01'
                                                );
                                              if (indexOf > -1) {
                                                tmp.extras[indexOf].image_url =
                                                  this.state.store.logo1
                                                    ? this.state.store.logo1.split(
                                                        '?'
                                                      )[0]
                                                    : ' ';
                                              }

                                              indexOf = tmp.extras.findIndex(
                                                i => i.name === 'endereco-01'
                                              );
                                              if (indexOf > -1) {
                                                tmp.extras[indexOf].text =
                                                  this.storeComplete(
                                                    this.state.store
                                                  ) || ' ';
                                              }

                                              indexOf = tmp.extras.findIndex(
                                                i => i.name === 'endereco-02'
                                              );
                                              if (indexOf > -1) {
                                                tmp.extras[indexOf].text =
                                                  this.state.store
                                                    .whatsapp_phone_number ||
                                                  ' ';
                                              }

                                              indexOf = tmp.extras.findIndex(
                                                i => i.name === 'telefone-01'
                                              );
                                              if (indexOf > -1) {
                                                tmp.extras[indexOf].text =
                                                  this.state.store
                                                    .whatsapp_phone_number ||
                                                  ' ';
                                              }

                                              this.setState({
                                                json_parse: { ...tmp },
                                              });
                                            }
                                          }
                                        );
                                    });
                                }}
                              >
                                <img
                                  className={classes.img}
                                  src={this.state.listaTemplates[valueAtual][0]}
                                  alt={valueAtual}
                                  title={valueAtual}
                                  style={{ width: '100%', maxWidth: 400 }}
                                />
                              </CardActionArea>
                              <GridListTileBar
                                title={valueAtual}
                                classes={{
                                  root: classes.titleBar,
                                  title: classes.title,
                                }}
                              />
                            </GridListTile>
                          ))}
                    </GridList>
                  </Grid>
                </div>
              </section>
            </section>
          </InfiniteScroll>
        )}
        {this.state.categoriaEmFoco === 1 && (
          <div style={{ display: 'flex', marginTop: 20 }}>
            {this.props.templatesVideomatik.map((value, index) => (
              <ImageCard
                descricao={value.nome}
                style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
                image={value.miniatura}
                onClick={async () => {
                  await this.props.postVideomatik({
                    id_tabloide: this.props.tabloid.id_tabloide_digital,
                    id_template: value.id,
                  });
                  //this.props.onClose();
                }}
              />
            ))}
          </div>
        )}
      </Fragment>
    );
  }

  step2(props, state) {
    //FG FARMA personalização
    const is928 =
      JSON.parse(localStorage.getItem('companyId')) === 928 ||
      JSON.parse(localStorage.getItem('companyId')) === 1079;

    const is396 = JSON.parse(localStorage.getItem('companyId')) === 396;

    const { classes } = this.props;
    const { variaveis, json_parse } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', width: '35%' }}>
          {!(
            json_parse &&
            json_parse.options &&
            json_parse.options.noNeedLogo
          ) && (
            <Autocomplete
              // open={open}
              // onOpen={() => {
              //   setOpen(true);
              // }}
              // onClose={() => {
              //   setOpen(false);
              // }}

              style={{ width: '90%', margin: '30px auto 30px' }}
              getOptionSelected={(option, value) =>
                option.id_loja === value.id_loja
              }
              getOptionLabel={option => option.nome_loja}
              options={this.state.stores}
              loading={this.state.stores.length === 0}
              value={this.state.store}
              onChange={(event, newValue) => {
                this.setState({ store: newValue });
                const tmp = { ...json_parse };

                if (tmp.extras && this.state.store) {
                  let indexOf = tmp.extras.findIndex(
                    i => i.name === 'logo-empresa-01'
                  );
                  if (indexOf > -1) {
                    tmp.extras[indexOf].image_url = newValue.logo1
                      ? newValue.logo1.split('?')[0]
                      : '';
                  }

                  indexOf = tmp.extras.findIndex(i => i.name === 'endereco-01');
                  if (indexOf > -1) {
                    tmp.extras[indexOf].text =
                      this.storeComplete(newValue) || ' ';
                  }

                  indexOf = tmp.extras.findIndex(i => i.name === 'endereco-02');
                  if (indexOf > -1) {
                    tmp.extras[indexOf].text =
                      newValue.whatsapp_phone_number || ' ';
                  }

                  indexOf = tmp.extras.findIndex(i => i.name === 'telefone-01');
                  if (indexOf > -1) {
                    tmp.extras[indexOf].text =
                      newValue.whatsapp_phone_number || ' ';
                  }

                  this.setState({ json_parse: { ...tmp } });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Selecionar loja"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.stores.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          )}
          {json_parse.extras &&
            json_parse.extras.map((value, i) =>
              value.type === 'text' ? (
                <TextField
                  type="string"
                  label={value.name}
                  onChange={({ target }) => {
                    const tmp = { ...json_parse };
                    tmp.extras[i].text = target.value;
                    this.setState({ json_parse: { ...tmp } });
                  }}
                  value={value.text}
                  style={{ margin: 20, width: '90%' }}
                />
              ) : (
                value.type === 'image' && (
                  <img
                    alt={value.image_url}
                    src={
                      validURL(value.image_url)
                        ? value.image_url
                        : 'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{
                      height: 120,
                      cursor: 'pointer',
                      backgroundColor: '#e7e7e7',
                    }}
                  />
                )
              )
            )}

          {!(
            json_parse &&
            json_parse.options &&
            json_parse.options.noNeedColors &&
            json_parse.options.noNeedTextSize
          ) && (
            <Fragment>
              <Divider
                style={{
                  margin: 'auto',
                  marginTop: 20,
                  width: '80%',
                }}
              />
              {true && ( // ANTES ERA ISSO: !this.state.template.includes('http')
                <div style={{}}>
                  <div>
                    <div style={{ marginTop: 20, marginBottom: 12 }}>
                      Cor do Texto
                    </div>
                    <div
                      style={{
                        width: '90%',
                        margin: 'auto',
                      }}
                    >
                      <CompactPicker
                        color={this.state.colorText}
                        onChangeComplete={this.handleChangeCompleteOthers}
                        styles={{
                          default: {
                            picker: {
                              boxShadow: 'none',
                              margin: 'auto',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div style={{ marginTop: 20, marginBottom: 12 }}>
                      Cor do Preço
                    </div>
                    <div
                      style={{
                        width: '90%',
                        margin: 'auto',
                      }}
                    >
                      <CompactPicker
                        color={this.state.colorPrice}
                        onChangeComplete={this.handleChangeCompletePrice}
                        styles={{
                          default: {
                            picker: {
                              boxShadow: 'none',
                              margin: 'auto',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 20,
                    }}
                  >
                    {/* <TextField
                            type="string"
                            label={"Tamanho da Fonte"}
                            onChange={({ target }) => {
                              const tmp = { ...json_parse };
                              tmp.modifications["produto-descricao"] = {
                                ...tmp.modifications["produto-descricao"],
                                "font-size": target.value + "px"
                              };
                              if (target.value === '') {
                                delete tmp.modifications["produto-descricao"]["font-size"];
                              }
                              this.setState({ json_parse: { ...tmp } });
                              this.setState({ font_size: target.value });
                            }}
                            value={this.state.font_size}
                            style={{ margin: 20, width: '90%' }}
                          /> */}
                    Tamanho do nome dos produtos:
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.font_size}
                      onChange={event => {
                        const tmp = { ...json_parse };
                        tmp.modifications['produto-descricao'] = {
                          ...tmp.modifications['produto-descricao'],
                          'font-size': event.target.value + 'px',
                        };
                        if (event.target.value === '') {
                          delete tmp.modifications['produto-descricao'][
                            'font-size'
                          ];
                        }
                        this.setState({ json_parse: { ...tmp } });
                        this.setState({ font_size: event.target.value });
                      }}
                      label="Tamanho do nome dos produtos"
                      style={{
                        marginLeft: 20,
                      }}
                    >
                      <MenuItem value="">
                        <em>Normal</em>
                      </MenuItem>
                      <MenuItem value={100}>Médio</MenuItem>
                      <MenuItem value={140}>Grande</MenuItem>
                      <MenuItem value={180}>Gigante</MenuItem>
                    </Select>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {!(
            json_parse &&
            json_parse.options &&
            json_parse.options.noNeedEtiquetas
          ) &&
            [
              'encarte',
              'facebook',
              'instagram',
              'whatsapp',
              'redes sociais',
              'email',
              'indoor',
            ].includes(this.state.tipoPixelixe) && (
              <div>
                <Divider
                  style={{
                    margin: 'auto',
                    width: '80%',
                    marginTop: 30,
                  }}
                />
                <div style={{ marginTop: 30 }}>Etiqueta de preço</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '80%',
                    margin: '10px auto 20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <div>
                    {this.state.etiquetas.map(
                      (value, index) =>
                        index < this.state.moreEtiqueta * 24 && (
                          <img
                            alt={value.etiqueta}
                            src={value.etiqueta}
                            style={{
                              width: 50,
                              cursor: 'pointer',
                              backgroundColor: '#e7e7e7',
                              margin: 10,
                              border: value.selected ? '2px solid blue' : '',
                            }}
                            onClick={() => {
                              let tmp = this.state.etiquetas;
                              for (var j = 0; j < tmp.length; j++) {
                                tmp[j].selected = false;
                              }
                              tmp[index].selected = true;
                              this.setState({
                                etiquetas: tmp,
                                etiqueta: value.etiqueta,
                              });
                            }}
                          />
                        )
                    )}
                  </div>
                  <div>
                    {this.state.moreEtiqueta * 24 <=
                      this.state.etiquetas.length && (
                      <Button
                        style={{
                          margin: 5,
                          cursor: 'pointer',
                        }}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => {
                          this.setState({
                            moreEtiqueta: this.state.moreEtiqueta + 1,
                          });
                        }}
                      >
                        <KeyboardArrowDownIcon fontSize="default" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          <Divider
            style={{
              margin: 'auto',
              marginTop: 20,
              width: '80%',
            }}
          />
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3>Imprimir página</h3>
            <TextField
              id="outlined-basic"
              label="de"
              variant="outlined"
              style={{ width: 50, marginRight: 10, marginLeft: 10 }}
              value={this.state.paginaDe}
              onChange={({ target }) => {
                this.setState({ paginaDe: target.value });
              }}
            />
            <h3>até</h3>
            <TextField
              id="outlined-basic"
              label="até"
              variant="outlined"
              style={{ width: 50, marginRight: 10, marginLeft: 10 }}
              value={this.state.paginaAte}
              onChange={({ target }) => {
                this.setState({ paginaAte: target.value });
              }}
            />
          </div>
          <Divider
            style={{
              margin: 'auto',
              marginTop: 20,
              width: '80%',
            }}
          />
          <div
            style={{
              marginTop: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ selecionarProdutosOpen: true });
              }}
              disabled={false}
            >
              Selecionar Produtos
            </Button>
          </div>
          {this.state.ocasiao === 'Meus Modelos' && (
            <div>
              <Button
                size="large"
                color="secondary"
                variant="text"
                onClick={() => this.setState({ showDesativarModelo: true })}
                style={{ marginTop: 40 }}
              >
                Desativar Template
              </Button>
            </div>
          )}

          <div style={{ margin: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.antigoChecked}
                  onChange={event =>
                    this.setState({ antigoChecked: event.target.checked })
                  }
                  name="antigoChecked"
                  color="primary"
                />
              }
              label="Preço no modelo antigo"
            />
          </div>

          {is396 && (
            <div
              style={{
                width: '100%',
                margin: 30,
              }}
            >
              <Accordion
                style={{
                  backgroundColor: '#eee',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Múltiplas Imagens
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="no"
                      onClick={() => this.setState({ multImagens: null })}
                      style={
                        !this.state.multImagens
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      <NotInterestedIcon />
                    </IconButton>

                    <IconButton
                      color="primary"
                      aria-label="m2"
                      onClick={() => this.setState({ multImagens: '2' })}
                      style={
                        this.state.multImagens === '2'
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      2
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="m2"
                      onClick={() => this.setState({ multImagens: '3' })}
                      style={
                        this.state.multImagens === '3'
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      3
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="m2"
                      onClick={() => this.setState({ multImagens: '4' })}
                      style={
                        this.state.multImagens === '4'
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      4
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="m2"
                      onClick={() => this.setState({ multImagens: '5' })}
                      style={
                        this.state.multImagens === '5'
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      5
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="m2"
                      onClick={() => this.setState({ multImagens: '6' })}
                      style={
                        this.state.multImagens === '6'
                          ? {
                              backgroundColor: '#aaa',
                            }
                          : {}
                      }
                    >
                      6
                    </IconButton>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
        <div style={{ marginTop: 20, width: '35%' }}>
          <div>
            <img
              style={{ width: '100%', border: '1px solid' }}
              src={
                (json_parse &&
                  json_parse.modifications &&
                  json_parse.modifications['imagem-fundo'] &&
                  json_parse.modifications['imagem-fundo'].image_url) ||
                this.state.miniatura
              }
            ></img>
          </div>

          <div style={{ margin: 30 }}>
            {is928 && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!(
                          this.state.auxExtra &&
                          this.state.auxExtra.fgFarmaSpecial &&
                          this.state.auxExtra.fgFarmaSpecial.isPost20
                        )
                      }
                      onChange={event => {
                        if (event.target.checked) {
                          let qtde = 20;
                          this.setState({
                            paginaAte: Math.ceil(
                              this.props.tabloid.qtde_produtos_lista / qtde
                            ),
                            auxExtra: {
                              produto_qtde: qtde,
                              fgFarmaSpecial: {
                                isPost20: true,
                              },
                            },
                          });
                        } else {
                          this.setState({
                            paginaAte: Math.ceil(
                              this.props.tabloid.qtde_produtos_lista /
                                this.state.qtde_produtos
                            ),
                            auxExtra: {},
                          });
                        }
                      }}
                      name="isPost20"
                      color="primary"
                    />
                  }
                  label="É post personalizado 20 produtos"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!(
                          this.state.auxExtra &&
                          this.state.auxExtra.fgFarmaSpecial &&
                          this.state.auxExtra.fgFarmaSpecial.isEncarte18
                        )
                      }
                      onChange={event => {
                        if (event.target.checked) {
                          let qtde = 18;
                          this.setState({
                            paginaAte: Math.ceil(
                              this.props.tabloid.qtde_produtos_lista / qtde
                            ),
                            auxExtra: {
                              produto_qtde: qtde,
                              fgFarmaSpecial: {
                                isEncarte18: true,
                              },
                            },
                          });
                        } else {
                          this.setState({
                            paginaAte: Math.ceil(
                              this.props.tabloid.qtde_produtos_lista /
                                this.state.qtde_produtos
                            ),
                            auxExtra: {},
                          });
                        }
                      }}
                      name="isEncarte18"
                      color="primary"
                    />
                  }
                  label="É encarte personalizado 18 produtos"
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          fullScreen
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          dividers
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {this.state.showDesativarModelo && (
          <DialogAlert
            open={this.state.showDesativarModelo}
            title="Deseja desativar este modelo?"
            onClick={() =>
              this.setState({ showDesativarModelo: false }, () => {
                this.props.enqueueSnackbar('Processando...', {
                  variant: 'info',
                });
                this.props
                  .createTemplatePersonalizado({
                    id: this.state.id_template,
                    deletar: true,
                  })
                  .then(resp => {
                    this.props.enqueueSnackbar(resp.message, {
                      variant: 'success',
                    });
                    this.props.onClose();
                  })
                  .catch(err => {
                    this.props.enqueueSnackbar('Falha de processamento', {
                      variant: 'error',
                    });
                  });
              })
            }
            onClose={() => this.setState({ showDesativarModelo: false })}
          ></DialogAlert>
        )}

        {this.state.selecionarProdutosOpen && (
          <SelecionarProdutos
            open={this.state.selecionarProdutosOpen}
            onOpen={() => {
              //this.setState({ idsNaoIncluidos: [] });
            }}
            onClose={() => {
              this.setState({ selecionarProdutosOpen: false });
            }}
            tabloideProdutos={this.props.tabloideProdutos}
            idsNaoIncluidos={this.state.idsNaoIncluidos}
            onSave={value => {
              this.setState({ idsNaoIncluidos: [...value] });
              this.setState({ selecionarProdutosOpen: false });
            }}
          ></SelecionarProdutos>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  tile: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    color: theme.palette.common.white,
  },
  titleBar: {
    borderRadius: theme.shape.borderRadius,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
  },
  gridList: {
    width: '100%',
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  putOfertaFisico,
  createTemplatePersonalizado,
  getTabloidsEtiquetas,
  v2_putOfertaFisicoMaior,
  getTabloidsTemplatesFisico,
  getTabloidsTemplateById,
  postVideomatik,
};

const GeneratePixelixeConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePixelixe);

export default withSnackbar(withStyles(styles)(GeneratePixelixeConnect));
