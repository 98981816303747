import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";
import Fetch from "../../../utils/helpers/Fetch";

const isLoadingSolicitacoes = () => dispatch =>
  dispatch({ type: "LOAD_SOLICITACOES" });



export const getSolicitacoes = () => async dispatch => {
  dispatch(isLoadingSolicitacoes());

  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getPerformance
    `
  ).then(payload => dispatch({ type: "GET_PERFORMANCES", payload }));
};

