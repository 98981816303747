/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTable } from '../../../utils/components/index';
import { MoedaBrasil } from '../../../utils/helpers/Format';

const columns = [
  { title: 'Nome', field: 'nome_pessoa' },
  { title: 'Quantidade de compras', field: 'TOTAL_CUPOM' },
  {
    title: 'Valor total',
    field: 'TOTAL',
    render: rowData => <span>R$ {MoedaBrasil(rowData.TOTAL)}</span>,
  },
  {
    title: 'Data da última compra',
    field: 'DT_ULTIMA_COMPRA',
    render: rowData => (
      <span>
        {moment(rowData.DT_ULTIMA_COMPRA ? rowData.DT_ULTIMA_COMPRA.replace('Z', '') : null).format(
          'DD/MM/YYYY HH:mm'
        )}
      </span>
    ),
  },
  {
    title: 'Data da recuperação',
    field: 'DT_RECUPERACAO',
    render: rowData => (
      <span>
        {rowData.DT_RECUPERACAO
          ? moment(rowData.DT_RECUPERACAO ? rowData.DT_RECUPERACAO.replace('Z', '') : null).format(
              'DD/MM/YYYY HH:mm'
            )
          : 'Data não encontrada.'}
      </span>
    ),
  },
  {
    title: 'Período sem compras',
    field: 'dias_ultima_compra',
    render: rowData => <span>{rowData.dias_ultima_compra} dias</span>,
  },
  // {
  //   title: "Valor da compra",
  //   field: "COMPRAS_CAMPANHA",
  //   render: rowData => <span>R$ {MoedaBrasil(rowData.COMPRAS_CAMPANHA)}</span>
  // }
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const MuiDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

function ModalListaDetalhe(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth="xl"
      maxWidth="lg"
    >
      <MuiDialogTitle onClose={props.onClose}>
        Lista de pessoas que compraram durante a campanha
      </MuiDialogTitle>
      <Divider />
      <MuiDialogContent>
        <CustomTable
          paging
          search
          columnsButton
          pageSize={5}
          columns={columns}
          data={props.listaResgate}
        />
      </MuiDialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ PerformanceReducer }) => ({
  ...PerformanceReducer,
});

const mapDispatchToProps = {};

const ModalListaDetalheConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalListaDetalhe);

export default withStyles(styles)(ModalListaDetalheConnect);
