import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AppsIcon from '@material-ui/icons/Apps';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Countup from 'react-countup';
import { BarChart } from '../../../../utils/components';
import useStyles from './styles';

function Overview({
  categories,
  numberOfProducts,
  numberOfCategories,
  loading,
}) {
  const classes = useStyles();

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="rounded">
                  <AppsIcon />
                </Avatar>
              }
              title={
                loading ? (
                  <Skeleton variant="text" width={40} />
                ) : (
                  <Countup
                    start={0}
                    end={numberOfProducts || 0}
                    duration={5}
                    separator="."
                  />
                )
              }
              subheader={
                loading ? <Skeleton variant="text" width={65} /> : 'Produtos'
              }
            />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="rounded">
                  <AppsIcon />
                </Avatar>
              }
              title={
                loading ? (
                  <Skeleton variant="text" width={40} />
                ) : (
                  <Countup
                    start={0}
                    end={numberOfCategories || 0}
                    duration={5}
                    separator="."
                  />
                )
              }
              subheader={
                loading ? <Skeleton variant="text" width={65} /> : 'Categorias'
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="rounded">
                  <AppsIcon />
                </Avatar>
              }
              title="Total por categoria"
            />
            <Divider />
            <CardContent className={classes.content}>
              {loading ? (
                <div className={classes.loadingChart}>
                  <CircularProgress size={40} color="primary" />
                </div>
              ) : (
                <BarChart
                  barLegend
                  randomFill
                  barDataKey="total"
                  xDataKey="name"
                  width="99%"
                  // tooltipFormatter="Total por categprias"
                  data={categories || []}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview;
