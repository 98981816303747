import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { Modal, CustomTable } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { Celular } from '../../../utils/helpers/Masks';
import MyDate from '../../../utils/helpers/Date';

import { getRespostaCampanha } from '../action/action';

const _myDate = new MyDate();

function ModalResposta(props) {
  useEffect(() => {
    if (Boolean(props.idCampanha)) {
      props.getRespostaCampanha(props.idCampanha);
    }
  }, [props.idCampanha]);
  return (
    <Modal
      onClose={props.onClose}
      header="Respostas"
      size="lg"
      showModal={props.open}
    >
      {props.loadingResposta ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress style={{ color: COLOR_PRIMARY }} />
        </Box>
      ) : (
        <CustomTable
          data={props.campanhaRespostas}
          title=""
          pageSize={5}
          paging
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
          headerStyle={{ zIndex: 0 }}
          columns={[
            {
              title: 'Destinatário',
              field: 'destinatario',
              render: rowData => <span>{Celular(rowData.destinatario)}</span>,
            },
            { title: 'Resposta', field: 'resposta' },
            {
              title: 'Data de recebimento',
              field: 'data_recebimento',
              render: rowData => (
                <span>
                  {_myDate.getDate(
                    'DD/MM/YYYY HH:mm',
                    rowData.data_recebimento.replace('Z', '')
                  )}
                </span>
              ),
            },
          ]}
        />
      )}
    </Modal>
  );
}

const mapStateToProps = ({ PerformanceReducer }) => ({
  ...PerformanceReducer,
});

const mapDispatchToProps = { getRespostaCampanha };

export default connect(mapStateToProps, mapDispatchToProps)(ModalResposta);
