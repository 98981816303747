import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Table from 'material-table';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localization from '../../../../localization/table';
import api from '../../../../utils/helpers/Fetch';
import CreateAudience from './components/CreateAudience';
import useStyles from './styles';

function Campaigns() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [audiences, setAudiences] = useState();

  const [loading, setLoading] = useState(false);

  const [createAudienceIsOpen, setCreateAudienceIsOpen] = useState(false);

  const findAudiences = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api(
        'https://api.datasales.info/ads-manager-dev/audiences'
      );

      const { content } = response;

      setAudiences(content);
    } catch (err) {
      enqueueSnackbar('Erro ao carregar públicos', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const handleDeleteAudience = useCallback(async id => {
    try {
      setLoading(true);
      await api(
        `https://api.datasales.info/ads-manager-dev/audiences/${id}`,
        {},
        'DELETE'
      );
      enqueueSnackbar('Público removido com sucesso', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Erro ao remover público', { variant: 'error' });
    } finally {
      findAudiences();
    }
  }, []);

  useEffect(() => {
    findAudiences();
  }, [findAudiences]);

  const handleCreateAudienceOpen = () => {
    setCreateAudienceIsOpen(true);
  };

  const handleCreateAudienceClose = useCallback(() => {
    setCreateAudienceIsOpen(false);
  }, []);

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                history.go(-1);
              }}
            >
              voltar
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h3" noWrap>
              Públicos
            </Typography>
          </Grid>
          <Grid item container spacing={2} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={handleCreateAudienceOpen}
              >
                Criar
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Card variant="outlined">
              <CardContent className={classes.content}>
                <Table
                  title=""
                  isLoading={loading}
                  actions={[
                    {
                      icon: 'delete',
                      label: 'excluir',
                      onClick: (_, rowData) => {
                        handleDeleteAudience(rowData.id);
                      },
                    },
                  ]}
                  columns={[
                    { field: 'id', title: 'id' },
                    { field: 'name', title: 'Nome' },
                    {
                      field: 'createdAt',
                      title: 'Criado em',
                      render: rowData => (
                        <span>
                          {moment(rowData.createdAt).format('DD/MM/YYYY')}
                        </span>
                      ),
                    },
                  ]}
                  data={audiences}
                  options={{ actionsColumnIndex: -1 }}
                  localization={localization}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <CreateAudience
        open={createAudienceIsOpen}
        refreshAudiences={findAudiences}
        onClose={handleCreateAudienceClose}
      />
    </React.Fragment>
  );
}

export default Campaigns;
