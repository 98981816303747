import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BarChart,
  Card,
  Count,
  MapsComponent,
} from '../../../utils/components';
import WithoutInformation from './WithoutInformation';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import {
  getClienteIdade,
  getClienteMapa,
  getClienteProfissao,
  getClienteSexo,
  getClienteStatus,
  getClienteTermometro,
  getClienteTotal,
  getClienteTotalizadores,
  modalPessoa,
} from '../actions/actions';

class AnalyticsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtroConsulta: props.filtroConsulta,
    };
  }

  filtroCliente(filtro) {
    this.props.getClienteTotal(filtro);
    this.props.getClienteSexo(filtro);
    this.props.getClienteStatus(filtro);
    this.props.getClienteTermometro(filtro);
    this.props.getClienteIdade(filtro);
    this.props.getClienteMapa(filtro);
    this.props.getClienteProfissao(filtro);
    this.props.getClienteTotalizadores(filtro);
  }

  componentDidMount() {
    this.filtroCliente(this.state.filtroConsulta);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filtroConsulta !== prevState.filtroConsulta)
      return { filtroConsulta: nextProps.filtroConsulta };
    else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.filtroConsulta !== this.state.filtroConsulta) {
      this.filtroCliente(this.state.filtroConsulta);
    }
  }

  getTotal(type) {
    return this.props.perfilCliente.mapa
      .filter(pessoa => pessoa[type] !== null)
      .length.toString();
  }

  render() {
    const {
      classes,
      perfilCliente,
      loadingClienteTotal,
      loadingClienteSexo,
      loadingClienteTermometro,
      loadingClienteIdade,
      loadingClienteMapa,
      loadingClienteProfissao,
    } = this.props;
    return (
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Count
            title={perfilCliente.clientes ? perfilCliente.clientes.total : 0}
            subheader="Cadastros"
            icon="people"
            style={{ marginRight: 20 }}
          />
          <Count
            title={
              !perfilCliente.totalizadores.length > 0
                ? 'Carregando...'
                : perfilCliente.totalizadores[0].celular
            }
            helpButton
            helpContent={
              <span>R$ {MoedaBrasil(perfilCliente.valores.celular)}</span>
            }
            subheader="Celular"
            icon="smartphone"
            style={{ marginRight: 20 }}
          />
          <Count
            title={`${
              !perfilCliente.totalizadores.length > 0
                ? 'Carregando...'
                : perfilCliente.totalizadores[0].email
            }`}
            helpButton
            helpContent={
              <span>R$ {MoedaBrasil(perfilCliente.valores.email)}</span>
            }
            subheader="Email"
            icon="email"
            style={{ marginRight: 20 }}
          />
          <Count
            title={`${
              !perfilCliente.totalizadores.length > 0
                ? 'Carregando...'
                : perfilCliente.totalizadores[0].endereco
            }`}
            subheader="Endereço"
            icon="location_on"
            style={{ marginRight: 20 }}
          />
          <Count
            title={`${
              !perfilCliente.totalizadores.length > 0
                ? 'Carregando...'
                : perfilCliente.totalizadores[0].ativos
            }`}
            subheader="Ativos"
            icon="check_circle_outline"
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ width: '49%' }}>
            <Card loading={loadingClienteTotal} title="Total clientes" showMore>
              {Boolean(perfilCliente.totalClientes.length) ? (
                <BarChart
                  barLegend
                  barDataKey="total_loja"
                  xDataKey="nome_loja"
                  tooltipFormatter="Qtde. clientes"
                  data={perfilCliente.totalClientes}
                />
              ) : (
                <WithoutInformation />
              )}
            </Card>
          </div>
          <div style={{ width: '49%' }}>
            <Card loading={loadingClienteSexo} title="Sexo" showMore>
              {Boolean(perfilCliente.sexo.length) ? (
                <BarChart
                  stacked={['Não informado', 'Masculino', 'Feminino', ' ']}
                  barLegend
                  xDataKey="nome_loja"
                  data={perfilCliente.sexo}
                />
              ) : (
                <WithoutInformation />
              )}
            </Card>
          </div>
          <div style={{ width: '49%' }}>
            <Card loading={loadingClienteIdade} title="Idade" showMore>
              {Boolean(perfilCliente.idade.length) ? (
                <BarChart
                  stacked={[
                    '0 a 12 anos',
                    '13 a 18 anos',
                    '19 a 25 anos',
                    '26 a 35 anos',
                    '36 a 45 anos',
                    '46 a 65 anos',
                    'acima de 66 anos',
                  ]}
                  barLegend
                  xDataKey="nome_loja"
                  data={perfilCliente.idade}
                />
              ) : (
                <WithoutInformation />
              )}
            </Card>
          </div>

          <div style={{ width: '49%' }}>
            <Card
              loading={loadingClienteTermometro}
              title="Termômetro"
              showMore
            >
              {Boolean(perfilCliente.termometro.length) ? (
                <BarChart
                  stacked={['Congelado', 'Congelando', 'Morno', 'Quente']}
                  barLegend
                  xDataKey="nome_loja"
                  data={perfilCliente.termometro}
                />
              ) : (
                <WithoutInformation />
              )}
            </Card>
          </div>
        </div>
        <Card loading={loadingClienteProfissao} title="Profissoes" showMore>
          {Boolean(perfilCliente.profissoes.length) ? (
            <BarChart
              barLegend
              barDataKey="qtde"
              xDataKey="descricao"
              tooltipFormatter="Qtde. clientes"
              data={perfilCliente.profissoes}
            />
          ) : (
            <WithoutInformation />
          )}
        </Card>
        <Card loading={loadingClienteMapa} title="Mapa" showMore>
          {Boolean(perfilCliente.mapa.length) ? (
            <div style={{ width: '100%', heigh: '100%' }}>
              <MapsComponent
                isMarkerShown
                data={perfilCliente.mapa}
                typeMap="CLUSTER"
              />
            </div>
          ) : (
            <WithoutInformation />
          )}
        </Card>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const mapStateToProps = ({ RoutesReducer, InsightsReducer }) => ({
  ...InsightsReducer,
  ...RoutesReducer,
});
const mapDispatchToProps = {
  getClienteTotal,
  getClienteSexo,
  getClienteStatus,
  getClienteTermometro,
  getClienteIdade,
  getClienteMapa,
  getClienteProfissao,
  getClienteTotalizadores,
  modalPessoa,
};
const AnalyticsViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsView);

export default withStyles(styles)(AnalyticsViewConnect);
