import Fetch from "../../../utils/helpers/Fetch";
import { AMBIENTE, DATASALES_CRM_API } from "../../../utils/helpers/Constants";

export const isLoadingCashback = () => dispatch =>
  dispatch({ type: "LOADING_CASHBACK" });

export const getFamalias = () => async dispatch => {
  dispatch(isLoadingCashback());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
  );
  dispatch({ type: "GET_FAMILIAS", payload });
};

export const getProdutosByFamilia = familia => async dispatch => {
  dispatch(isLoadingCashback());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getProdutosByfamilia`,
    {
      familia
    }
  );
  dispatch({ type: "GET_PRODUTOS", payload });
};

export const getOfertaCashback = cashback => async dispatch => {
  try {
    dispatch(isLoadingCashback());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getOfertaCashback`,
      cashback
    );
    dispatch({ type: "GET_OFERTA_CASHBACK", payload });
  } catch (err) {
    console.log(err);
  }
};

export const getInfoCashback = cashback => async dispatch => {
  try {
    dispatch(isLoadingCashback());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getInfoCashback`,
      cashback
    );
    dispatch({ type: "GET_INFO_CASHBACK", payload });
  } catch (err) {
    console.log(err);
  }
};

export const postOfertaCashback = cashback => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/postOfertaCashback`,
      cashback,
      "POST"
    );
    dispatch(getOfertaCashback());
  } catch (err) {
    console.log(err);
  }
};

export const postOfertaCashbackMercado = cashback => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/postOfertaCashbackMercado`,
      cashback,
      "POST"
    );
    dispatch(getOfertaCashback());
  } catch (err) {
    console.log(err);
  }
};
