import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Stepper,
  Step,
  StepButton,
  Divider,
  Typography,
  Icon,
  Tooltip,
  InputAdornment
} from "@material-ui/core";
import { RemoveAcentos } from "../../../utils/helpers/Format";
import MyDate from "../../../utils/helpers/Date";
import {
  getDDDs,
  getUFs,
  getMunicipios,
  getBairros
} from "../../VendaMais/action/action";
import {
  getClienteTotalizadores,
  listaTodasProfissao,
  listaLojas,
  getListaCategoria1,
  getListaCategoria2,
  getListaCategoria3,
  getListaCategoria4,
  getListaCategoria5,
  getListaCategoria6,
  getListaCategoria7,
  getListaCategoria8,
  getListaCategoria9,
  getListaCategoria10,
  getListaCategoria11,
  getListaCategoria12
} from "../../Insights/actions/actions";
import {
  Modal,
  MyButton,
  Select,
  TextField,
  Countup,
  Slider,
  DatePicker,
  Chip
} from "../../../utils/components";
import {
  COLOR_PRIMARY,
  UNIDADE_TEMPO,
  FAIXA_ETARIA,
  GENRE,
  STATUS,
  SN,
  TERMOMETRO,
  DIAS,
  DIAS_MES
} from "../../../utils/helpers/Constants";

const _myDate = new MyDate();

function textAutomation(state) {
  return `A cada ${state.unitAutomacao} ${
    state.unitTimeAutomacao ? state.unitTimeAutomacao.label.toLowerCase() : ""
  }  
  
  ${
    state.diaDisparoAutomacao
      ? `todas as ${state.diaDisparoAutomacao.label.toLowerCase()}`
      : state.unitTimeAutomacao.value === "days" ||
        state.unitTimeAutomacao.value === "hours"
      ? ""
      : "todos os dias"
  } ${
    state.unitTimeAutomacao.value === "hours"
      ? ""
      : ` às ${_myDate.getDate("HH:mm", state.horaDisparoAutomacao)}`
  } `;
}
class CriarAutomacaoPersonalizado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeAutomacao: "",
      mensagem: "",
      tipo: null,
      unitTimeAutomacao: null,
      unitAutomacao: 0,
      activeStep: 0
    };
  }

  componentDidMount() {
    this.props.listaLojas();
    this.props.getUFs();
    this.props.getDDDs();
    this.props.listaTodasProfissao();
    this.props.getListaCategoria1();
    this.props.getListaCategoria2();
    this.props.getListaCategoria3();
    this.props.getListaCategoria4();
    this.props.getListaCategoria5();
    this.props.getListaCategoria6();
    this.props.getListaCategoria7();
    this.props.getListaCategoria8();
    this.props.getListaCategoria9();
    this.props.getListaCategoria10();
    this.props.getListaCategoria11();
    this.props.getListaCategoria12();
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  searchPeople(state, value) {
    this.setState({ [state]: value }, () => {
      const objParameters = {
        cidades: this.state.selectedMunicipios
          ? this.state.selectedMunicipios.map(municipio =>
              RemoveAcentos(municipio.cidade)
            )
          : [],
        estados: this.state.selectedEstados
          ? this.state.selectedEstados.map(estado => estado.sigla)
          : [],
        bairros: this.state.selectedBairros
          ? this.state.selectedBairros.map(bairro => bairro.bairro)
          : [],
        ddd: this.state.selectedDDD
          ? this.state.selectedDDD.map(ddd => ddd.value)
          : [],

        store: this.state.selectedStores
          ? this.state.selectedStores.map(str => str.value)
          : [],
        faixaEtaria: this.state.faixaEtaria,
        genre: this.state.genre ? this.state.genre.id.split(",") : [],
        termometro: this.state.termometro ? this.state.termometro.value : [],
        status: this.state.status ? this.state.status.value : [],
        dataCadastroInicio: this.state.dataCadastroInicio,
        dataCadastroFim: this.state.dataCadastroFim,
        dataCompraInicio: this.state.dataCompraInicio,
        dataCompraFim: this.state.dataCompraFim,
        qtdeCompras: this.state.qtdeComprasInicio
          ? [this.state.qtdeComprasInicio, this.state.qtdeComprasFim]
          : [],
        valorCompras: this.state.valorComprasInicio
          ? [this.state.valorComprasInicio, this.state.valorComprasFim]
          : [],
        selectedProfissao: this.state.selectedProfissao
          ? this.state.selectedProfissao.map(pro => pro.id_pessoa_profissao)
          : [],
        selectedMesNascimento: this.state.selectedMesNascimento
          ? this.state.selectedMesNascimento.value
          : [],
        celular: this.state.celular ? this.state.celular.value : [],
        email: this.state.email ? this.state.email.value : [],
        ticketMedio: this.state.ticketMedioInicio
          ? [this.state.ticketMedioInicio, this.state.ticketMedioFim]
          : [],
        categoria_1: this.state.categoria_1
          ? this.state.categoria_1.map(grupo => grupo.categoria_1)
          : [],
        categoria_2: this.state.categoria_2
          ? this.state.categoria_2.map(familia => familia.categoria_2)
          : [],
        categoria_3: this.state.categoria_3
          ? this.state.categoria_3.map(condicao => condicao.categoria_3)
          : [],
        categoria_4: this.state.categoria_4
          ? this.state.categoria_4.map(cor => cor.categoria_4)
          : [],
        categoria_5: this.state.categoria_5
          ? this.state.categoria_5.map(fornecedor => fornecedor.categoria_5)
          : [],
        categoria_6: this.state.categoria_6
          ? this.state.categoria_6.map(colecao => colecao.categoria_6)
          : [],
        categoria_7: this.state.categoria_7
          ? this.state.categoria_7.map(fornecedor => fornecedor.categoria_7)
          : [],
        categoria_8: this.state.categoria_8
          ? this.state.categoria_8.map(colecao => colecao.categoria_8)
          : [],
        categoria_9: this.state.categoria_9
          ? this.state.categoria_9.map(fornecedor => fornecedor.categoria_9)
          : [],
        categoria_10: this.state.categoria_10
          ? this.state.categoria_10.map(colecao => colecao.categoria_10)
          : [],
        categoria_11: this.state.categoria_11
          ? this.state.categoria_11.map(colecao => colecao.categoria_11)
          : [],
        categoria_12: this.state.categoria_12
          ? this.state.categoria_12.map(colecao => colecao.categoria_12)
          : []
      };

      this.props.getClienteTotalizadores(objParameters);
    });
  }

  header(props, state) {
    const steps = [
      "Informações da automação",
      "Mensagem",
      "Localização do consumidor",
      "Características do consumidor",
      "Recência, Freqüência e Valor",
      "Características do Produto",
      "Iniciar"
    ];

    return (
      <Fragment>
        <span>Automação personalizada</span>
        <br />
        {props.loadingClienteTotalizadores ? (
          <span
            style={{
              fontSize: "11pt",
              fontWeight: "bold",
              color: COLOR_PRIMARY
            }}
          >
            Carregando...
          </span>
        ) : (
          <Fragment>
            <span
              style={{
                fontSize: "11pt",
                fontWeight: "bold",
                color: COLOR_PRIMARY
              }}
            >
              Celular:{" "}
              <Countup
                start={0}
                end={parseInt(props.perfilCliente.totalizadores[0].celular, 10)}
                duration={5}
              />
            </span>
            &nbsp;
            <span
              style={{
                fontSize: "11pt",
                fontWeight: "bold",
                color: COLOR_PRIMARY
              }}
            >
              Email:{" "}
              <Countup
                start={0}
                end={parseInt(props.perfilCliente.totalizadores[0].email, 10)}
                duration={5}
              />
            </span>
          </Fragment>
        )}
        <div
          style={{
            width: "100%"
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {state.activeStep !== 0 ? (
          <MyButton
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </MyButton>
        ) : (
          <div />
        )}

        {state.activeStep !== 6 && (
          <MyButton
            size="large"
            color="primary"
            variant="text"
            disabled={
              !state.nomeAutomacao ||
              !state.tipo ||
              !state.unitAutomacao ||
              !state.unitTimeAutomacao
            }
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
          >
            Próximo
          </MyButton>
        )}
      </div>
    );
  }

  step1(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Informações da automação
        </Typography>
        <TextField
          label="Nome da automação"
          className="form-control"
          onChange={({ target }) =>
            this.onChange("nomeAutomacao", target.value)
          }
          value={state.nomeAutomacao}
        />
        <Select
          label="Tipo"
          options={[{ label: "SMS", value: "sms" }]}
          onChange={tipo => this.onChange("tipo", tipo)}
          value={state.tipo}
        />
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "13% 25% 30% 20% 4%",
            gridGap: "2%",
            alignItems: "center"
          }}
        >
          <TextField
            type="number"
            label="A cada"
            className="form-control"
            onChange={({ target }) =>
              this.onChange("unitAutomacao", target.value)
            }
            value={state.unitAutomacao}
          />
          <Select
            label="Unidade de tempo"
            options={UNIDADE_TEMPO}
            onChange={tipo => this.onChange("unitTimeAutomacao", tipo)}
            value={state.unitTimeAutomacao}
          />

          {state.unitTimeAutomacao &&
            state.unitTimeAutomacao.value !== "days" &&
            state.unitTimeAutomacao.value !== "hours" && (
              <Select
                label="Todas às"
                options={
                  state.unitTimeAutomacao.value !== "months" ? DIAS : DIAS_MES
                }
                onChange={dia => this.onChange("diaDisparoAutomacao", dia)}
                value={state.diaDisparoAutomacao}
              />
            )}
          {state.unitTimeAutomacao &&
            state.unitTimeAutomacao.value !== "hours" && (
              <DatePicker
                time
                label="às"
                format="HH:mm"
                onChange={date => this.onChange("horaDisparoAutomacao", date)}
                value={state.horaDisparoAutomacao}
              />
            )}
          <Tooltip
            title="Informe o intervalo de tempo para o gatilho ser acionado. Exemplo: A cada 7 horas, o gatilho será acionado a cada 7 horas todos os dias da semana."
            placement="right"
            arrow
            disableFocusListener
            interactive
          >
            <center>
              <Icon style={{ color: COLOR_PRIMARY }}>info</Icon>
            </center>
          </Tooltip>
        </section>
      </section>
    );
  }

  step2(props, state) {
    return (
      <section style={{ padding: "2rem 15rem" }}>
        <Chip
          label="Id Pessoa"
          clickable
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{idPessoa}}`)
          }
        />
        <Chip
          label="CPF"
          clickable
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{documento}}`)
          }
        />
        <Chip
          label="Primeiro nome"
          clickable
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{primeiroNome}}`)
          }
        />
        <Chip
          clickable
          label="Nome completo"
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{nomeCompleto}}`)
          }
        />
        <Chip
          clickable
          label="Cupom"
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{voucher}}`)
          }
        />
        <Chip
          clickable
          label="Click tracker"
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{clickTracker}}`)
          }
        />
        <Chip
          label="Identificador"
          clickable
          onClick={() =>
            this.onChange("mensagem", `${state.mensagem} {{identificador}}`)
          }
        />
        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="20"
          error={state.showAlert}
          value={state.mensagem}
          margin="normal"
          variant="outlined"
          helperText={`${state.mensagem.length}/160`}
          onChange={({ target }) => {
            this.onChange("mensagem", target.value);
            this.onChange({
              showAlert: target.value.length >= 160 ? true : false
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: "flex", flexDirection: "column" }} />
              </InputAdornment>
            )
          }}
        />
      </section>
    );
  }

  step3(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Localização do consumidor
        </Typography>

        <Select
          label="Estado"
          multiple
          options={props.listUFs}
          onChange={value => {
            this.searchPeople("selectedEstados", value);
            this.props.getMunicipios(value);
          }}
          value={state.selectedEstados}
          labelKey="nome"
          valueKey="sigla"
        />
        <Select
          label="Cidade"
          options={props.listMunicipios}
          disabled={props.listMunicipios.length <= 0}
          valueKey="cidade"
          multiple
          onChange={value => {
            this.searchPeople("selectedMunicipios", value);
            this.props.getBairros(value);
          }}
          value={state.selectedMunicipios}
        />
        <Select
          label="Bairro"
          multiple
          options={props.listBairros}
          disabled={props.listBairros.length <= 0}
          onChange={value => this.searchPeople("selectedBairros", value)}
          value={state.selectedBairros}
        />
        <Select
          label="DDD"
          multiple
          options={props.listDDDs}
          onChange={value => this.searchPeople("selectedDDD", value)}
          value={state.selectedDDD}
        />
      </section>
    );
  }

  step4(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Características do consumidor
        </Typography>
        <Select
          label="Aniversariante"
          options={SN}
          onChange={value => this.onChange("selectedMesNascimento", value)}
          value={state.selectedMesNascimento}
        />
        <Select
          label="Profissão"
          multiple
          options={props.listTodasProfissao}
          labelKey="descricao"
          valueKey="id_pessoa_profissao"
          onChange={value => this.searchPeople("selectedProfissao", value)}
          value={state.selectedProfissao}
        />
        <section>
          <Typography
            style={{
              fontSize: "10pt",
              color: "rgba(0, 0, 0, 0.54)",
              paddingLeft: "20px"
            }}
            variant="subtitle1"
            noWrap
          >
            Faixa etária
          </Typography>
          <Slider
            style={{ color: COLOR_PRIMARY }}
            value={!this.state.faixaEtaria ? [0, 100] : this.state.faixaEtaria}
            onChange={(event, newValue) =>
              this.searchPeople("faixaEtaria", newValue)
            }
            marks={FAIXA_ETARIA}
            min={0}
            max={100}
          />
        </section>
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            gridGap: "2%"
          }}
        >
          <TextField
            type="number"
            label="Inicio do perído do cadastro (dias)"
            className="form-control"
            onChange={({ target }) => {
              this.onChange("dataCadastroInicio", target.value);
            }}
            value={state.dataCadastroInicio}
          />

          <TextField
            type="number"
            label="Fim do perído do cadastro (dias)"
            className="form-control"
            onChange={({ target }) => {
              this.onChange("dataCadastroFim", target.value);
            }}
            value={state.dataCadastroFim}
          />
        </section>
        <Select
          label="Gênero"
          options={GENRE}
          valueKey="id"
          onChange={value => this.searchPeople("genre", value)}
          value={state.genre}
        />
        <Select
          label="Status"
          options={STATUS}
          onChange={value => this.searchPeople("status", value)}
          value={state.status}
        />
        <Select
          label="Email"
          options={SN}
          onChange={value => this.searchPeople("email", value)}
          value={state.email}
        />
        <Select
          label="Celular"
          options={SN}
          onChange={value => this.searchPeople("celular", value)}
          value={state.celular}
        />
      </section>
    );
  }

  step5(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Recência, Freqüência e Valor
        </Typography>
        <Select
          multiple
          label="Lojas"
          options={props.listLojas}
          onChange={value => this.searchPeople("selectedStores", value)}
          value={state.selectedStores}
        />
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            gridGap: "2%"
          }}
        >
          <TextField
            type="number"
            label="Inicio do perído da última compra (dias)"
            className="form-control"
            onChange={({ target }) => {
              this.onChange("dataCompraInicio", target.value);
            }}
            value={state.dataCompraInicio}
          />

          <TextField
            type="number"
            label="Fim do perído da última compra (dias)"
            className="form-control"
            onChange={({ target }) => {
              this.onChange("dataCompraFim", target.value);
            }}
            value={state.dataCompraFim}
          />
        </section>
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            gridGap: "2%"
          }}
        >
          <TextField
            type="number"
            label="Quantidade de compras de"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("qtdeComprasInicio", target.value);
            }}
            value={state.qtdeComprasInicio}
          />
          <TextField
            type="number"
            label="Quantidade de compras até"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("qtdeComprasFim", target.value);
            }}
            value={state.qtdeComprasFim}
          />
        </section>
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            gridGap: "2%"
          }}
        >
          <TextField
            type="number"
            label="Valor gasto de"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("valorComprasInicio", target.value);
            }}
            value={state.valorComprasInicio}
          />
          <TextField
            type="number"
            label="Valor gasto até"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("valorComprasFim", target.value);
            }}
            value={state.valorComprasFim}
          />
        </section>
        <section
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 49%)",
            gridGap: "2%"
          }}
        >
          <TextField
            type="number"
            label="Ticket médio de"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("ticketMedioInicio", target.value);
            }}
            value={state.ticketMedioInicio}
          />
          <TextField
            type="number"
            label="Ticket médio até"
            className="form-control"
            onChange={({ target }) => {
              this.searchPeople("ticketMedioFim", target.value);
            }}
            value={state.ticketMedioFim}
          />
        </section>
        <Select
          label="Termômetro"
          options={TERMOMETRO}
          onChange={value => this.searchPeople("termometro", value)}
          value={state.termometro}
        />
      </section>
    );
  }

  step6(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Características do Produto
        </Typography>
        {props.listCategoria1.length > 0 && (
          <Select
            multiple
            label="Característica 1"
            options={props.listCategoria1}
            labelKey="desc_categoria_1"
            valueKey="categoria_1"
            onChange={value => this.searchPeople("categoria_1", value)}
            value={state.categoria_1}
          />
        )}
        {props.listCategoria2.length > 0 && (
          <Select
            multiple
            label="Característica 2"
            options={props.listCategoria2}
            labelKey="desc_categoria_2"
            valueKey="categoria_2"
            onChange={value => this.searchPeople("categoria_2", value)}
            value={state.categoria_2}
          />
        )}
        {props.listCategoria3.length > 0 && (
          <Select
            multiple
            label="Característica 3"
            options={props.listCategoria3}
            labelKey="desc_categoria_3"
            valueKey="categoria_3"
            onChange={value => this.searchPeople("categoria_3", value)}
            value={state.categoria_3}
          />
        )}
        {props.listCategoria4.length > 0 && (
          <Select
            multiple
            label="Característica 4"
            options={props.listCategoria4}
            labelKey="desc_categoria_4"
            valueKey="categoria_4"
            onChange={value => this.searchPeople("categoria_4", value)}
            value={state.categoria_4}
          />
        )}
        {props.listCategoria5.length > 0 && (
          <Select
            multiple
            label="Característica 5"
            options={props.listCategoria5}
            labelKey="desc_categoria_5"
            valueKey="categoria_5"
            onChange={value => this.searchPeople("categoria_5", value)}
            value={state.categoria_5}
          />
        )}
        {props.listCategoria6.length > 0 && (
          <Select
            multiple
            label="Característica 6"
            options={props.listCategoria6}
            labelKey="desc_categoria_6"
            valueKey="categoria_6"
            onChange={value => this.searchPeople("categoria_6", value)}
            value={state.categoria_6}
          />
        )}
        {props.listCategoria7.length > 0 && (
          <Select
            multiple
            label="Característica 7"
            options={props.listCategoria7}
            labelKey="desc_categoria_7"
            valueKey="categoria_7"
            onChange={value => this.searchPeople("categoria_7", value)}
            value={state.categoria_7}
          />
        )}
        {props.listCategoria8.length > 0 && (
          <Select
            multiple
            label="Característica 8"
            options={props.listCategoria8}
            labelKey="desc_categoria_8"
            valueKey="categoria_8"
            onChange={value => this.searchPeople("categoria_8", value)}
            value={state.categoria_8}
          />
        )}
        {props.listCategoria9.length > 0 && (
          <Select
            multiple
            label="Característica 9"
            options={props.listCategoria9}
            labelKey="desc_categoria_9"
            valueKey="categoria_9"
            onChange={value => this.searchPeople("categoria_9", value)}
            value={state.categoria_9}
          />
        )}
        {props.listCategoria10.length > 0 && (
          <Select
            multiple
            label="Característica 10"
            options={props.listCategoria10}
            labelKey="desc_categoria_10"
            valueKey="categoria_10"
            onChange={value => this.searchPeople("categoria_10", value)}
            value={state.categoria_10}
          />
        )}
        {props.listCategoria11.length > 0 && (
          <Select
            multiple
            label="Característica 11"
            options={props.listCategoria11}
            labelKey="desc_categoria_11"
            valueKey="categoria_11"
            onChange={value => this.searchPeople("categoria_11", value)}
            value={state.categoria_11}
          />
        )}
        {props.listCategoria12.length > 0 && (
          <Select
            multiple
            label="Característica 12"
            options={props.listCategoria12}
            labelKey="desc_categoria_12"
            valueKey="categoria_12"
            onChange={value => this.searchPeople("categoria_12", value)}
            value={state.categoria_12}
          />
        )}
      </section>
    );
  }

  step7(props, state) {
    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Iniciar
        </Typography>
        <section style={{ padding: "0 7rem" }}>
          <section style={{ paddingBottom: "2rem" }}>
            <p>
              <strong>Nome</strong>
            </p>
            <span>{state.nomeAutomacao}</span>
          </section>
          <section style={{ paddingBottom: "2rem" }}>
            <p>
              <strong>Tipo de automação</strong>
            </p>
            <span>{state.tipo && state.tipo.label}</span>
          </section>
          <section style={{ paddingBottom: "2rem" }}>
            <p>
              <strong>Horário da automação</strong>
            </p>
            <span>{textAutomation(state)}</span>
          </section>
          {state.selectedEstados && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Estados</strong>
              </p>
              {state.selectedEstados.length > 0 &&
                state.selectedEstados.map(uf => (
                  <span>
                    {uf.sigla}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.selectedMunicipios && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Cidades</strong>
              </p>
              {state.selectedMunicipios.length > 0 &&
                state.selectedMunicipios.map(mun => (
                  <span>
                    {mun.cidade}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.selectedBairros && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Bairros</strong>
              </p>
              {state.selectedBairros.length > 0 &&
                state.selectedBairros.map(bai => (
                  <span>
                    {bai.bairro}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.selectedDDD && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>DDD</strong>
              </p>
              {state.selectedDDD.length > 0 &&
                state.selectedDDD.map(ddd => (
                  <span>
                    {ddd.label}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.selectedMesNascimento && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Aniversariante</strong>
              </p>
              <span>{state.selectedMesNascimento ? "Sim" : "Não"}</span>
            </section>
          )}
          {state.selectedProfissao && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Profissões</strong>
              </p>
              {state.selectedProfissao.length > 0 &&
                state.selectedProfissao.map(pro => (
                  <span>
                    {pro.label}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.faixaEtaria && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Faixa etária</strong>
              </p>
              {state.faixaEtaria.length > 0 && (
                <span>{`Entre ${state.faixaEtaria[0]} e ${state.faixaEtaria[1]} anos.`}</span>
              )}
            </section>
          )}
          {state.dataCadastroInicio && state.dataCadastroFim && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Perído de dias de cadastro</strong>
              </p>
              <span>{`Entre ${state.dataCadastroInicio} e ${state.dataCadastroFim} dias.`}</span>
            </section>
          )}
          {state.dataCompraInicio && state.dataCompraFim && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Perído de dias da última compra</strong>
              </p>
              <span>{`Entre ${state.dataCompraInicio} e ${state.dataCompraFim} dias.`}</span>
            </section>
          )}
          {state.genre && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Gênero</strong>
              </p>
              <span>{state.genre.label}</span>
            </section>
          )}
          {state.status && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Status</strong>
              </p>
              <span>{state.status.label}</span>
            </section>
          )}
          {state.email && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Email</strong>
              </p>
              <span>{state.email.label}</span>
            </section>
          )}
          {state.celular && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Celular</strong>
              </p>
              <span>{state.celular.label}</span>
            </section>
          )}
          {state.selectedStores && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Lojas</strong>
              </p>
              {state.selectedStores.length > 0 &&
                state.selectedStores.map(loja => (
                  <span>
                    {loja.label}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.qtdeComprasInicio && state.qtdeComprasFim && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Perído de quantidade de compras</strong>
              </p>
              <span>{`Entre ${state.qtdeComprasInicio} e ${state.qtdeComprasFim} compras.`}</span>
            </section>
          )}
          {state.valorComprasInicio && state.valorComprasFim && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Perído de valores de compras</strong>
              </p>
              <span>{`Entre R$ ${state.valorComprasInicio} e R$ ${state.valorComprasFim}.`}</span>
            </section>
          )}
          {state.ticketMedioInicio && state.ticketMedioFim && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Perído de ticket médio</strong>
              </p>
              <span>{`Entre R$${state.ticketMedioInicio} e R$${state.ticketMedioFim}.`}</span>
            </section>
          )}
          {state.termometro && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Termômetro</strong>
              </p>
              <span>{state.termometro.label}</span>
            </section>
          )}
          {state.categoria_1 && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Categoria 1</strong>
              </p>
              {state.categoria_1.length > 0 &&
                state.categoria_1.map(categoria => (
                  <span>
                    {categoria.desc_categoria_1}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.categoria_2 && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Categoria 2</strong>
              </p>
              {state.categoria_2.length > 0 &&
                state.categoria_2.map(categoria => (
                  <span>
                    {categoria.desc_categoria_2}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.categoria_3 && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Categoria 3</strong>
              </p>
              {state.categoria_3.length > 0 &&
                state.categoria_3.map(categoria => (
                  <span>
                    {categoria.desc_categoria_3}
                    <br />
                  </span>
                ))}
            </section>
          )}
          {state.categoria_4 && (
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Categoria 4</strong>
              </p>
              {state.categoria_4.length > 0 &&
                state.categoria_4.map(categoria => (
                  <span>
                    {categoria.desc_categoria_4}
                    <br />
                  </span>
                ))}
            </section>
          )}
        </section>
        <section style={{ textAlign: "center" }}>
          <MyButton onClick={() => props.onSave(this.state)} size="large">
            <Icon>send</Icon>&nbsp;Iniciar
          </MyButton>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      case 4:
        return this.step5(props, state);
      case 5:
        return this.step6(props, state);
      case 6:
        return this.step7(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, open } = this.props;
    const { activeStep } = this.state;
    return (
      <Modal
        size="lg"
        showModal={open}
        onClose={onClose}
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const mapStateToProps = ({ VendaMaisReducer, InsightsReducer }) => ({
  ...VendaMaisReducer,
  ...InsightsReducer
});

const mapDispatchToProps = {
  getDDDs,
  getUFs,
  getMunicipios,
  getBairros,
  getClienteTotalizadores,
  listaTodasProfissao,
  listaLojas,
  getListaCategoria1,
  getListaCategoria2,
  getListaCategoria3,
  getListaCategoria4,
  getListaCategoria5,
  getListaCategoria6,
  getListaCategoria7,
  getListaCategoria8,
  getListaCategoria9,
  getListaCategoria10,
  getListaCategoria11,
  getListaCategoria12
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CriarAutomacaoPersonalizado);
