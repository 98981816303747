import { GridList, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useDefaultStyles } from '../../../style';
import ProductCard from './ProductCard';

const useStyles = makeStyles(theme => ({
  gridList: { width: '100%' },
  skeleton: {
    borderRadius: theme.shape.borderRadius
  }
}));

function MagnumGrid({
  onClickImage,
  onClickMenu,
  type,
  array,
  loading,
  menuOut,
  selectedShareableItem,
  isGeracao1,
  onReprocessar,
  ...props
}) {
  const classes = useStyles();
  const magnumClasses = useDefaultStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <GridList
        className={classes.gridList}
        cellHeight="auto"
        cols={matches ? 6 : 4}
        spacing={3}
        style={{ padding: '16px 0px 16px 0px' }}
      >
        {loading
          ? Array(12)
            .fill(12)
            .map((_, i) => (
              <div key={i} style={{ padding: '16px' }}>
                <Skeleton
                  className={`${classes.skeleton} ${magnumClasses.gridMediaQueryHeight} ${magnumClasses.gridCard}`}
                  style={{ height: 290 }}
                  variant="rect"
                  height="90%"
                  width="100%"
                />
              </div>
            ))
          : array.map(item => (
            <ProductCard
              key={item.id}
              type={type}
              item={item}
              card={type}
              design={item?.json_url}
              menuOut={menuOut}
              onClickImage={onClickImage}
              onClickMenu={onClickMenu}
              disabled={item.disabled}
              isSelected={type === 'Video' && !item.id_videomatik ? false : item.selected}
              isGeracao1={isGeracao1}
              onReprocessar={onReprocessar}
            />
          ))}
      </GridList>
    </>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MagnumGrid);

