import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import api from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import useStylesChat from '../styles/chat';

export function ChatInformationTableEmployee() {
  const classesChat = useStylesChat();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  // hasMore should come from the place where you do the data fetching
  // for example, it could be a prop passed from the parent component
  // or come from some store

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const { content } = await api(
          `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/analytics/employees`
        );
        const _rows = content.map(_row => ({
          agent: _row.USERNAME,
          status: _row.ATENDIMENTOSABERTOS || 0,
          finished: _row.ATENDIMENTOSENCERRADOS || 0,
          hours_atendent: _row.HORASATENDIMENTO,
          time_awnser: _row.TEMPORESPOSTA,
          total: _row.TOTALATENDIMENTOS || 0,
        }));
        setRows(_rows);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <TableContainer
        className={[
          classesChat.tableContainer,
          classesChat.tableContainerEmployee,
        ]}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classesChat.tableCellHead} align="center">
                Agente
              </TableCell>
              <TableCell className={classesChat.tableCellHead} align="center">
                Em Aberto
              </TableCell>
              <TableCell className={classesChat.tableCellHead} align="center">
                Encerrado
              </TableCell>
              <TableCell className={classesChat.tableCellHead} align="center">
                Horas em atendimento
              </TableCell>
              <TableCell className={classesChat.tableCellHead} align="center">
                Tempo de Resposta
              </TableCell>
              <TableCell className={classesChat.tableCellHead} align="center">
                Total Atendimentos
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: '0 5rem' }}>
            {rows.map((_row, index) => (
              <TableRow key={index.toString()}>
                {Object.keys(_row).map((field, index2) => (
                  <TableCell
                    key={index2.toString()}
                    className={classesChat.tableCellBody}
                    align="center"
                  >
                    {_row[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <CircularProgress className={classesChat.progress} />}
      </TableContainer>
    </>
  );
}
