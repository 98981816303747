import { 
  Box, 
  Dialog, 
  Icon, 
  IconButton, 
  LinearProgress, 
  Typography 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { IframePreviewAds } from './components/IframePreview';
import { 
  generateHash, 
  getStatusUploadVideo, 
  generatePreviews, 
  deleteMediaAdAccount 
} from 'services/ads';
import { Hash } from 'types/ads';

const CustomDialog = styled(Dialog)({
  marginTop: 71,
  '& .MuiDialog-paper': {
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#f2f2f2'
  }
});

const BoxHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px 20px',
  width: '100%',
  background: '#FFF',
  borderRadius: '20px 20px 0 0',
  position: 'fixed',
  height: '60px',
  borderBottom: 'solid 1px #d6d6d6'
});

interface INewEmptyModalProps {
  open: boolean;
  selectedShareableItem: [];
  handleClose: () => void;
}

export const PreviewAds = ({ open, selectedShareableItem, handleClose }: INewEmptyModalProps) => {

  const [facebookReelsMobile, setFacebookReelsMobile] = useState<string | null>(null);
  const [facebookStoryMobile, setFacebookStoryMobile] = useState<string | null>(null);  
  const [instagramReels, setInstagramReels] = useState<string | null>(null);
  const [instagramStory, setInstagramStory] = useState<string | null>(null);
  const [arrayHash,setArrayHash] = useState<Hash[]>([]);
  const [errorProcess, setErrorProcess] = useState(false);

  const { isLoading: isLoadingCreateHash, mutateAsync: createHashMutateAsync } = useMutation(
    'generateHash',
    async () => await generateHash(selectedShareableItem),
    {
      onSuccess(arrayHash: Hash[]) {
        if(arrayHash.length){
          setArrayHash(arrayHash);
        }
      },
      onError() { setErrorProcess(true) },
    } 
  );

  const { isLoading: isLoadingFetchStatusUploadVideo, mutateAsync: fetchStatusUploadVideoMutateAsync } = useMutation(
    'fetchStatusUploadVideo',
    async ( arrayHashVideos: Hash[] ) => { 
      const status = await getStatusUploadVideo({ hashArray: arrayHashVideos });
      return { status, arrayHashVideos };
    },
    {
      onSuccess(data) {
        if(data.status !== 'ready'){
          fetchStatusUploadVideoMutateAsync(data.arrayHashVideos);
        }else{
          setTimeout(async () => { console.log('TIMEOUT')} ,2000);
          fetchPreviewsMutateAsync(arrayHash);
        }
      },
      onError(error) {
        console.log('onError_fetchStatusUploadVideo::>', error);
        setErrorProcess(true);
      },
    }
  );

  const { isLoading: isLoadingPreviews, mutateAsync: fetchPreviewsMutateAsync } = useMutation(
    'fetchPreviews',
    async (arrayHash: Hash[]) => generatePreviews(arrayHash),
    {
      onSuccess(data: string[]) {
        const iframes: string[] = data;
        iframes.forEach((value: any) => {
          if(value.format === 'FACEBOOK_STORY_MOBILE'){
            setFacebookStoryMobile(value.iframe);
          }
          if(value.format === 'FACEBOOK_REELS_MOBILE'){
            setFacebookReelsMobile(value.iframe);
          }
          if(value.format === 'INSTAGRAM_STORY'){
            setInstagramStory(value.iframe);
          }
          if(value.format === 'INSTAGRAM_REELS'){
            setInstagramReels(value.iframe);
          }
        })
      },
      onError(error) { 
        console.log('onError_fetchPreviews', error); 
        setErrorProcess(true)
      },
    }
  );

  const { mutateAsync: deleteMediaMutateAsync } = useMutation(
    'deleteHash',
    async (hashArray: Hash[]) => deleteMediaAdAccount(hashArray),
  );

  useEffect(() => {
    if(arrayHash.length){
      const arrayHashVideos: Hash[] = [];
      arrayHash.forEach((hash)=>{
        if(hash.isVideo){
          arrayHashVideos.push(hash);
        }
      })
      if(arrayHashVideos.length){
        fetchStatusUploadVideoMutateAsync(arrayHashVideos);
      }else{
        fetchPreviewsMutateAsync(arrayHash);
      }
    }
  },[arrayHash]);

  useEffect(() => {
     createHashMutateAsync();
  },[]);

  return (
    <CustomDialog fullScreen 
      open={open} 
      onClose={() => {  
        if(arrayHash?.length){
          deleteMediaMutateAsync(arrayHash); 
        }
        handleClose(); 
      }}>
      <BoxHeader>
        <Typography
          sx={{ color: '#7c7c7c', fontWeight: 700, fontSize: '16px', }}
        >
            Prévia
        </Typography>
        <IconButton 
          onClick={() => {  
            if(arrayHash?.length){
              deleteMediaMutateAsync(arrayHash); 
            }
            handleClose(); 
          }}>
          <Icon>clear</Icon>
        </IconButton>
      </BoxHeader>
      <Box
        pt="80px"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        {(errorProcess) ? 
          <Box display={'flex'} flexDirection={'column'}>
            <Box 
              component={'img'}
              src={'https://storage.googleapis.com/datasalesio-imagens/icons/server_down_svg.svg'}
              sx={{ height: '18.75rem', margin: '2rem'}}
            />
            <Typography 
              sx={{
                textAlign: 'center', 
                color: '#778799',
                fontSize: '14px',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                lineHeight: '24px', 
              }} 
            >
              Ops, falha ao processar as prévias.
              <br/>
              Pedimos desculpas pelo transtorno e solicitamos que tente novamente ou entre em contato com o nosso suporte.
            </Typography>
          </Box>
        :
        (isLoadingCreateHash || isLoadingFetchStatusUploadVideo || isLoadingPreviews ) ? 
           (<Box 
            sx={{ 
              width: '50%', 
              display: 'flex', 
              alignItems: 'center',
              flexDirection: 'column',
              gap: '2rem',
              justifyContent: 'center'
            }}>
              <Typography sx={{ fontSize: '14px' }} >Preparando Preview</Typography>
              <LinearProgress variant='indeterminate' sx={{ width: '100%' }}/>
            </Box>) : 
          (<Box
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              flexDirection: 'row',
              gap: '8px',
              justifyContent: 'center'
            }}
          >
            {facebookReelsMobile?.length && 
              <IframePreviewAds 
                socialNetwork={'Facebook'}
                type={'reels'}
                iframeStringMeta={facebookReelsMobile}
              />
            }
            {facebookStoryMobile?.length && 
              <IframePreviewAds 
                socialNetwork={'Facebook'}
                type={'stories'}
                iframeStringMeta={facebookStoryMobile}
              />
            }
            {instagramReels?.length && 
              <IframePreviewAds 
                socialNetwork={'Instagram'}
                type={'reels'}
                iframeStringMeta={instagramReels}
              />
            }
            {instagramStory?.length && 
              <IframePreviewAds
                socialNetwork={'Instagram'}
                type={'stories'}
                iframeStringMeta={instagramStory}
              />
            }
          </Box>)
        }
      </Box>
    </CustomDialog>
  );
};

