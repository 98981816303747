export enum ActionTypes {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_LOADING_SEGMENTS = 'SET_IS_LOADING_SEGMENTS',
  SET_IS_LOADING_PUT_SEGMENT_COMPANY = 'SET_IS_LOADING_PUT_SEGMENT_COMPANY',
  SET_IS_LOADING_COMPANY = 'SET_IS_LOADING_COMPANY',
  SET_IS_LOADING_CATEGORIES = 'SET_IS_LOADING_CATEGORIES',
  SET_IS_LOADING_SUBCATEGORIES = 'SET_IS_LOADING_SUBCATEGORIES',
  SET_IS_SEARCHING = 'SET_IS_SEARCHING',

  PUT_SEGMENT_COMPANY = 'PUT_SEGMENT_COMPANY',

  GET_SEGMENTS = 'GET_SEGMENTS',
  GET_COMPANY = 'GET_COMPANY',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_SUBCATEGORIES = 'GET_SUBCATEGORIES',
  GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS',
  GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS',

  SET_SELECTED_SEGMENT = 'SET_SELECTED_SEGMENT',
  SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY',
  SET_SELECTED_BACKGROUNDS = 'SET_SELECTED_BACKGROUNDS',

  SET_PAGINATION_PROPS = 'SET_PAGINATION_PROPS',

  CLEAR_SUBCATEGORIES = 'CLEAR_SUBCATEGORIES',
  CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
}

export interface IActionSetIsLoading {
  type: ActionTypes.SET_IS_LOADING;
  payload: boolean;
}
export interface IActionSetIsLoadingSegments {
  type: ActionTypes.SET_IS_LOADING_SEGMENTS;
  payload: boolean;
}
export interface IActionSetIsLoadingPutSegmentCompany {
  type: ActionTypes.SET_IS_LOADING_PUT_SEGMENT_COMPANY;
  payload: boolean;
}
export interface IActionSetIsLoadingCompany {
  type: ActionTypes.SET_IS_LOADING_COMPANY;
  payload: boolean;
}
export interface IActionSetIsLoadingCategories {
  type: ActionTypes.SET_IS_LOADING_CATEGORIES;
  payload: boolean;
}
export interface IActionSetIsLoadingSubCategories {
  type: ActionTypes.SET_IS_LOADING_SUBCATEGORIES;
  payload: boolean;
}
export interface IActionSetIsSearching {
  type: ActionTypes.SET_IS_SEARCHING;
  payload: boolean;
}

export interface IActionPutSegmentCompany {
  type: ActionTypes.PUT_SEGMENT_COMPANY;
  payload: [];
}

export interface IActionSetSelectedSegment {
  type: ActionTypes.SET_SELECTED_SEGMENT;
  payload: number;
}
export interface IActionSetSelectedCategory {
  type: ActionTypes.SET_SELECTED_CATEGORY;
  payload: number;
}
export interface IActionSetSelectedBackgrounds {
  type: ActionTypes.SET_SELECTED_BACKGROUNDS;
  payload: number;
}

export interface IActionSetPaginationProps {
  type: ActionTypes.SET_PAGINATION_PROPS;
  payload: number;
}

export interface IActionGetSegments {
  type: ActionTypes.GET_SEGMENTS;
  payload: [];
}
export interface IActionGetCompany {
  type: ActionTypes.GET_COMPANY;
  payload: [];
}
export interface IActionGetCategories {
  type: ActionTypes.GET_CATEGORIES;
  payload: [];
}

export interface IActionGetSubcategories {
  type: ActionTypes.GET_SUBCATEGORIES;
  payload: [];
}
export interface IActionGetSearchSuggestions {
  type: ActionTypes.GET_SEARCH_SUGGESTIONS;
  payload: [];
}
export interface IActionGetSearchResults {
  type: ActionTypes.GET_SEARCH_RESULTS;
  payload: [];
}

export interface IActionClearSubcategories {
  type: ActionTypes.CLEAR_SUBCATEGORIES;
  payload: [];
}

export interface IActionClearSearchResults {
  type: ActionTypes.CLEAR_SEARCH_RESULTS;
  payload: [];
}

export type ActionType =
  | IActionSetIsLoading
  | IActionSetIsLoadingSegments
  | IActionSetIsLoadingPutSegmentCompany
  | IActionSetIsLoadingCompany
  | IActionSetIsLoadingCategories
  | IActionSetIsLoadingSubCategories
  | IActionSetIsSearching
  | IActionPutSegmentCompany
  | IActionSetSelectedSegment
  | IActionSetSelectedCategory
  | IActionSetSelectedBackgrounds
  | IActionGetSegments
  | IActionGetCompany
  | IActionGetCategories
  | IActionGetSubcategories
  | IActionGetSearchSuggestions
  | IActionGetSearchResults
  | IActionClearSubcategories
  | IActionClearSearchResults
  | IActionSetPaginationProps;

export type Store = {
  complete?: {
    bairro: string;
    cep: string;
    cidade: string;
    descricao: string;
    endereco: string;
    id_loja: number;
    uf: string;
    whatsapp_phone_number: string;
  };
  id?: number;
  logo_url: string | null;
  name?: string;
  phone_number?: string;
  street_address?: string;
  zip_code?: string;
};

export type UpdateImageHandleSubmit = {
  address?: string;
  description?: any;
  currentBackground: number | null;
  currentPrice: any;
  phone_number?: string;
  store: Store;
  tag?: any;
  auxiliar_texts: object;
  auxiliar_texts_colors: object;
};

export type OutArray = {
  page: number;
  image_url: string;
  design_url: string;
  preview_url: string;
};

export type CreateFlyer = {
  message: string;
  outArray: OutArray[];
  _service: string;
};

