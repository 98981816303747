import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2.5),
  },
  tableContainer: {
    maxWidth: '100%',
    margin: 'auto',
    marginTop: theme.spacing(3),
    maxHeight: '482px',
    border: '1px solid #B4B4B4',
    borderRadius: '8px',
  },
  tableContainerEmployee: {
    maxHeight: '589px',
  },
  progress: {
    position: 'absolute',
    bottom: '60px',
    left: 'calc(50% - 16px)',
    width: 32,
    height: 32,
  },
  table: { padding: theme.spacing(0, 2) },
  tableCellHead: {
    backgroundColor: '#FFF',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #E5E5E5',
  },
  tableCellBody: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #F5F5F5',
  },
}));

export default useStyles;
