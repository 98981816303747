import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { DATASALES_BACKOFFICE_API } from '../../utils/helpers/Constants';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function ResetPassword() {
  const classes = useStyles();

  const history = useHistory();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!password || !confirmPassword || password !== confirmPassword) setDisabled(true);
    else {
      setDisabled(false);
    }
  }, [password, confirmPassword]);

  async function handleSubmit(pass) {
    try {
      setLoading(true);
      setDisabled(true);

      await axios.post(`${DATASALES_BACKOFFICE_API}p2-prd/session/reset`, {
        token,
        password: pass
      });

      setOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Atualizar senha
        </Typography>
        <div className={classes.form}>
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Digite uma nova senha"
            name="password"
            autoComplete="none"
            autoFocus
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Confirme a nova senha"
            name="password"
            autoComplete="none"
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
          />
          <Button
            fullWidth
            disabled={disabled}
            size="large"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleSubmit(password)}
          >
            {loading ? <CircularProgress size={26} color="inherit" /> : 'Atualizar senha'}
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Sucesso</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sua senha foi alterada, agora faça login para entrar no sistema.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              history.push('/');
              window.location.reload();
            }}
            color="primary"
            autoFocus
          >
            Fazer Login
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

