import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBirthReport } from '../actions/actionsRelatorios';
import {
  PagerMonths,
  ListReport,
  PagerDays,
  ShowMore,
  Panel,
} from '../../../utils/components';

class RelatorioPeloDiaeMes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyDate: new Date().getDate(),
      keyMonth: new Date().getMonth() + 1,
    };
  }

  componentDidMount = () => {
    const { keyDate, keyMonth } = this.state;
    this.props.getBirthReport({ keyDate, keyMonth }, 'day');
  };

  getDate = keyDate => {
    this.setState({ keyDate });
    this.props.getBirthReport(
      { keyDate, keyMonth: this.state.keyMonth },
      'day',
      this.props.selectedStores,
    );
  };

  getMonth = keyMonth => {
    this.setState({ keyMonth });
    this.props.getBirthReport(
      { keyDate: this.state.keyDate, keyMonth },
      'day',
      this.props.selectedStores,
    );
  };
  render() {
    return (
      <Panel noHeader>
        <PagerMonths onSelect={month => this.getMonth(month + 1)} />
        <PagerDays onSelect={day => this.getDate(day + 1)} />
        <ShowMore
          loading={this.props.isLoading}
          data={this.props.listReportBirthDate}
          dataFormat={(row, data) => <ListReport row={row} data={data} />}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ ReportsReducer }) => ({ ...ReportsReducer });

const mapDispatchToProps = {
  getBirthReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatorioPeloDiaeMes);
