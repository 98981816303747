import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Fade } from '@material-ui/core';
import TabPanel from '../TabPanel';
import PaidPosts from '../Facebook/PaidPosts';
import useStyles from '../../styles';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import FunilIcon from '../../../../assets/icons/marketing/Funil.svg';
import * as locales from 'react-date-range/dist/locale';
import { useHome } from '../../../../hooks/home';

export default function ActionsTurboCharged(props) {
  const classes = useStyles(),
    [currentTab, setCurrentTab] = useState(0);
  const [openFilter, setOpenFilterDefault] = useState(false);

  const { getPaidCampaigns } = useHome();

  useEffect(() => {
    getPaidCampaigns();
  }, []);

  const setOpenFilter = () => {
    setOpenFilterDefault(prev => !prev);
    if (openFilter) {
      props.getCampaigns();
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item container justify="space-between">
        <Grid item container spacing={1} xs={4} alignItems="center">
          <Grid item>
            <TextField
              className={classes.margin}
              id="input-with-icon-search"
              label="Procurar campanha por nome"
              style={{ width: 280 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={props.campaignNameSearch}
              onChange={event => {
                props.setCampaignNameSearch(event.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  props.getCampaigns();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenFilter()}
              startIcon={<img src={FunilIcon} />}
              color="primary"
              size="large"
            >
              Filtrar
            </Button>
            <Fade in={openFilter}>
              <Box position="absolute" zIndex="1">
                <DateRange
                  rangeColors={'primary'}
                  locale={locales.ptBR}
                  onChange={({ selection }) => {
                    props.setStartDate(
                      moment(selection.startDate).format('YYYY-MM-DD')
                    );
                    props.setEndDate(
                      moment(selection.endDate).format('YYYY-MM-DD')
                    );
                  }}
                  showMonthAndYearPickers={false}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={false}
                  showDateDisplay={false}
                  ranges={[
                    {
                      startDate: moment(props.startDate).toDate(),
                      endDate: moment(props.endDate).toDate(),
                      key: 'selection',
                    },
                  ]}
                />
              </Box>
            </Fade>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={4}
          justify="flex-end"
          alignItems="center"
        ></Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel tab={currentTab} value={0}>
          <PaidPosts />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
