import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, Slide, IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import api from '../../../../utils/helpers/Fetch';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import useDate from '../../../../hooks/date';
import useStyles from './styles';

export default function NotificationPayment() {
  const classes = useStyles(),
    { getDate, differenceNumber } = useDate(),
    history = useHistory(),
    location = useLocation(),
    [infoPayment, setInfoPayment] = useState({}),
    [show, setShow] = useState(false);

  function _translateTypePayment(type = '') {
    switch (type.toUpperCase()) {
      case 'BOLETO':
        return 0;
      case 'CARTÃO DE CRÉDITO':
        return 1;
      case 'CARTÃO DE DEBITO':
        return 2;
      case 'PIX':
        return 3;
      case 'OUTROS':
        return 4;
      default:
        return 0;
    }
  }

  useEffect(() => {
    (async function () {
      try {
        const { content } = await api(
          `${DATASALES_CRM_API}financial-${AMBIENTE}/payments/list`
        );
        if (content[0]) {
          const info = {
            type: _translateTypePayment(content[0].FORMA_PAGAMENTO),
            form: content[0].FORMA_PAGAMENTO,
            dueDate: getDate('DD/MM/YYYY', content[0].DTA_VENCIMENTO),
            daysToExpiration: differenceNumber(content[0].DTA_VENCIMENTO) * -1,
            status: content[0].STATUS_PAGAMENTO,
            link: content[0].URL_COBRANCA,
          };

          if (!location.pathname.includes('financial')) {
            if (
              (info.type === 0 || info.type === 3) &&
              info.status === 0 &&
              info.daysToExpiration >= 0 &&
              info.daysToExpiration <= 5
            ) {
              setShow(true);
              setInfoPayment({ ...info, opened: true });
            } else if (info.status === 2) {
              setShow(true);
              setInfoPayment({ ...info, opened: false });
            }
          }
        }
      } catch (err) {
        console.log('ERRO PAYMENTS => ', err);
      } finally {
      }
    })();
  }, []);

  return (
    <Slide direction="down" in={show} mountOnEnter unmountOnExit>
      <Box
        style={{ backgroundColor: infoPayment.opened ? '#F5F5F5' : '#D44D4D' }}
        className={classes.rootNotification}
      >
        <Grid container spacing={2}>
          <Grid className={classes.contentNotification} item xs={11}>
            {infoPayment.opened ? (
              <Typography
                style={{ color: '#1D1D1D' }}
                className={classes.textAlert}
              >
                {`Seu boleto com vencimento para dia ${infoPayment.dueDate} já está disponível.`}{' '}
                <span
                  className={classes.link}
                  onClick={() =>
                    window.open(infoPayment.link, '_blank').focus()
                  }
                >
                  Clique aqui para abrir
                </span>
              </Typography>
            ) : (
              <Typography
                style={{ color: '#FFF' }}
                className={classes.textAlert}
              >
                Há algo de errado com o pagamento.{' '}
                <span
                  className={classes.link}
                  onClick={() => history.push('/financial')}
                >
                  Regularize aqui
                </span>
              </Typography>
            )}
          </Grid>
          <Grid className={classes.contentBtnClose} item xs={1}>
            <IconButton onClick={() => setShow(false)} size="small">
              <CloseRounded
                style={{ color: infoPayment.opened ? '#1D1D1D' : '#FFF' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}
