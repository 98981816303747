const INITIAL_STATE = {
  activeKey: 0,
  listaTemplateTipo: [],
  listaTemplate: [],
  editor: null,
  templateSelecionado: null,
  url: "",
  isLoadingExportedTemplate: true,
  isLoadingTemplates: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TEMPLATE_TIPO":
      return { ...state, listaTemplateTipo: action.payload };
    case "LISTA_TEMPLATE":
      return {
        ...state,
        listaTemplate: action.payload,
        isLoadingTemplates: false
      };
    case "SET_TEMPLATE":
      return { ...state, templateSelecionado: action.payload };
    case "URL":
      return {
        ...state,
        url: action.payload,
        isLoadingExportedTemplate: false
      };

    case "ON_CLOSE":
      return {
        ...state,
        isLoadingExportedTemplate: true,
        url: ""
      };
    case "setEditor":
      return { ...state, editor: action.payload };
    default:
      return state;
  }
};
