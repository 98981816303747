import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import Fetch from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  cnpj: Yup.string().required('O CNPJ é obrigatório'),
  name: Yup.string().required('O nome é obrigatório'),
  trade_name: Yup.string().required('O nome é obrigatório'),
  address: Yup.object().shape({
    zip_code: Yup.string().required('O CEP é obrigatório'),
    street: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.string().required('O número é obrigatório'),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    complement: Yup.string(),
  }),
});

function NewProvider() {
  const classes = useStyles();

  const history = useHistory();

  async function onSubmit(data) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/provider/new`,
        data,
        'POST'
      );

      history.push('/trade/providers');
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.push(`/trade/providers`);
            }}
          >
            voltar
          </Button>
        </Grid>
        <Box className={classes.root}>
          <Grid container spacing={4}>
            <Grid item container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Adicionar Fornecedor</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} spacing={2}>
              <Form
                initialValues={{}}
                onSubmit={onSubmit}
                validate={makeValidate(schema)}
                render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          name="cnpj"
                          fullWidth
                          variant="outlined"
                          label="CNPJ"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="name"
                          fullWidth
                          variant="outlined"
                          label="Razão Social"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="trade_name"
                          fullWidth
                          variant="outlined"
                          label="Nome Fantasia"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.zip_code"
                          fullWidth
                          variant="outlined"
                          label="CEP"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.street"
                          fullWidth
                          variant="outlined"
                          label="Rua"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.number"
                          fullWidth
                          variant="outlined"
                          label="Número"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.neighborhood"
                          fullWidth
                          variant="outlined"
                          label="Bairro"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.city"
                          fullWidth
                          variant="outlined"
                          label="Cidade"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.state"
                          fullWidth
                          variant="outlined"
                          label="Estado"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="address.complement"
                          fullWidth
                          variant="outlined"
                          label="Complemento"
                        />
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={submitting}
                            startIcon={<SaveIcon />}
                          >
                            Salvar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="large"
                            disabled={submitting}
                            startIcon={<UndoIcon />}
                          >
                            Limpar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="large"
                            onClick={() => {
                              history.push('/modules');
                            }}
                            startIcon={<CloseIcon />}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}

export default NewProvider;
