import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menu: {
    marginTop: theme.spacing(7),
  },
  paper: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: '#F5F5F5',
    padding: 15,
  },
  chip: { width: '100%' },
  signOut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2, 4),
  },
  changeVersionButton: {
    marginTop: '8px',
  },

  exit: {
    marginTop: '8px',
  }
}));

export default useStyles;
