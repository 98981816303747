import React from "react";
import { Modal } from "react-bootstrap";
import { MyButton } from "../../../utils/components";
import "../style/ModalAddLojas.css";

export default props => (
  <Modal show={props.show}>
    <Modal.Header>
      <Modal.Title>
        {!props.editStore
          ? "Adicionar nova loja"
          : `Editar loja ${props.data.nome_loja}`}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <section className="containerModalAddLojasBody">
        <input
          value={props.data && props.data.nome_loja}
          placeholder="Digite o nome da loja..."
          className="form-control"
          style={{ gridColumn: "1/4" }}
          onChange={e => props.onChange("nome_loja", e.target.value)}
        />
        <input
          value={props.data && props.data.cep}
          onBlur={props.onSearchCEP}
          onChange={e => props.onChange("cep", e.target.value)}
          placeholder="Digite o CEP..."
          className="form-control"
        />
        <input
          disabled
          value={props.data && props.data.bairro}
          className="form-control"
          placeholder="Rua"
        />
        <input
          disabled
          value={props.data && props.data.cidade}
          className="form-control"
          placeholder="Cidade"
        />
        <input
          disabled
          value={props.data && props.data.uf}
          className="form-control"
          placeholder="UF"
        />
        <input
          value={props.data && props.data.numero}
          placeholder="Número"
          type="number"
          className="form-control"
          onChange={e => props.onChange("numero", e.target.value)}
        />
        <input
          value={props.data && props.data.codigo_erp}
          placeholder="Código ERP"
          className="form-control"
          onChange={e => props.onChange("codigo_erp", e.target.value)}
        />
      </section>
    </Modal.Body>
    <Modal.Footer>
      <MyButton
        bsstyle="success"
        disabled={
          !props.data
            ? true
            : !props.data.nome_loja
            ? true
            : !props.data.cep
            ? true
            : !props.data.bairro
            ? true
            : !props.data.cidade
            ? true
            : !props.data.numero
            ? true
            : !props.data.uf
            ? true
            : !props.data.codigo_erp
        }
        onClick={!props.editStore ? props.onSaveStore : props.onEditStore}
      >
        Adicionar
      </MyButton>
      <MyButton onClick={props.onClose}>Fechar</MyButton>
    </Modal.Footer>
  </Modal>
);
