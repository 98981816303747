import { Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { addDays, getDay } from 'date-fns';
import subDays from 'date-fns/subDays';
import {
  clearSelectedShareableItem,
  setSelectedScheduledItems,
  setSelectedShareableItem
} from 'modules/TabloideDigitalV2/action';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from 'store/types';
import {
  convertDaysInNumbers,
  handleDateToMonth,
  mappingPostsByWeekDays,
  sortArrayByPosition,
  transformInArray,
  updateWeeklyDateByMatrixOrder
} from 'utils/helpers/ConvertionsDatefns';

import { RQFindAutoMessage } from '../../../action';
import { CardEditItem } from '../CardEditItem';

export type ScheduleItem = {
  id: number;
  name: string;
  company_id: number;
  offer_id: number;
  position: number;
  image_url: string;
  preview_url: string;
  dateScheduled: Date[];
  description: string;
};

export function ListScheduleImages() {
  const [countSelectDays, setCountSelectDays] = useState(0);
  const dispatch = useDispatch();

  const { selectedShareableItem, selectedScheduledItems, frequenceScheduledPosts } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
  );

  const handleNewIAMessage = useMutation(async (data: any) => {
    const response = await RQFindAutoMessage(data);
    return response;
  });

  const handleSequencialScheduler = (itemsFactory: ScheduleItem[]) => {
    const {
      amountRepeat,
      typeRepeat,
      hour: defaultSendHour,
      monthlyDayPublish,
      weeklyDaysPublish,
      postsRepeat
    } = frequenceScheduledPosts;

    const yesterday = subDays(defaultSendHour, 1);
    const currentDate = new Date();

    let itemsSettedDates = [] as ScheduleItem[];
    let lastDay = defaultSendHour;
    switch (typeRepeat.children) {
      case 'Dias':
        let startedDayNumber = 0;
        if (defaultSendHour < currentDate) {
          startedDayNumber = 1;
        }
        let postRepeatDay = 0;
        do {
          if (postRepeatDay > 0) {
            let lengthDateScheduledItemSetted =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled.length;
            lastDay =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled[
                lengthDateScheduledItemSetted - 1
              ];
          }
          if (postRepeatDay === 0) {
            itemsSettedDates = itemsFactory.map((item, index) => ({
              ...item,
              dateScheduled: [addDays(defaultSendHour, startedDayNumber + index * amountRepeat)]
            }));
          } else {
            startedDayNumber = 1;
            itemsSettedDates.forEach((item, index) =>
              item.dateScheduled.push(addDays(lastDay, startedDayNumber + index * amountRepeat))
            );
          }
          postRepeatDay = postRepeatDay + 1;
        } while (postRepeatDay <= postsRepeat);
        dispatch(setSelectedScheduledItems(itemsSettedDates));
        break;

      case 'Semanas':
        //@ts-ignore
        const selectedDays = Object.keys(weeklyDaysPublish).filter(key => weeklyDaysPublish[key]);
        setCountSelectDays(selectedDays.length);
        if (selectedDays.length && itemsFactory.length) {
          const selectedDaysInNumbers = convertDaysInNumbers(selectedDays, defaultSendHour);
          let lastDateWeeks = {} as Date;
          let postWeekRepeat = 0;
          do {
            const matrixPostsWeekDay = mappingPostsByWeekDays(selectedDaysInNumbers, itemsFactory);

            if (postWeekRepeat > 0) {
              const getNumberLastDay = getDay(lastDateWeeks);
              const indexLastDay = selectedDaysInNumbers.indexOf(getNumberLastDay);
              const daysRemoved = selectedDaysInNumbers.splice(indexLastDay + 1);
              selectedDaysInNumbers.unshift(...daysRemoved);
            }
            const matrixPostsWeekDayDated = updateWeeklyDateByMatrixOrder(
              yesterday,
              amountRepeat,
              selectedDaysInNumbers,
              matrixPostsWeekDay,
              lastDateWeeks,
              postWeekRepeat
            );
            const arrayPosts = transformInArray(matrixPostsWeekDayDated);
            itemsSettedDates = sortArrayByPosition(arrayPosts);

            let lengthDateScheduledItemSetted =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled.length;
            lastDateWeeks =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled[
                lengthDateScheduledItemSetted - 1
              ];

            itemsFactory = itemsSettedDates;
            postWeekRepeat = postWeekRepeat + 1;
          } while (postWeekRepeat <= postsRepeat);

          dispatch(setSelectedScheduledItems(itemsSettedDates));
        }
        break;

      case 'Mês':
        let postRepeatMes = 0;
        do {
          if (postRepeatMes > 0) {
            let lengthDateScheduledItemSetted =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled.length;
            lastDay =
              itemsSettedDates[itemsSettedDates.length - 1].dateScheduled[
                lengthDateScheduledItemSetted - 1
              ];
            itemsSettedDates.forEach((item, index) =>
              item.dateScheduled.push(
                handleDateToMonth(monthlyDayPublish!, 1 + index, amountRepeat, lastDay)
              )
            );
          }
          if (postRepeatMes === 0) {
            itemsSettedDates = itemsFactory.map((item, index) => ({
              ...item,
              dateScheduled: [
                handleDateToMonth(monthlyDayPublish!, index, amountRepeat, defaultSendHour)
              ]
            }));
          }
          postRepeatMes = postRepeatMes + 1;
        } while (postRepeatMes <= postsRepeat);
        // console.log('itemsSettedDates', itemsSettedDates);
        dispatch(setSelectedScheduledItems(itemsSettedDates));
        break;
    }
  };

  useEffect(() => {
    const isAlreadySelected = selectedScheduledItems.length > 0;

    let itemsFactory;
    if (isAlreadySelected) {
      let scheduleItems = [...selectedScheduledItems];
      scheduleItems.forEach((item, _) => {
        item.description = frequenceScheduledPosts.descriptions;
      });
      itemsFactory = scheduleItems;
    } else {
      itemsFactory = selectedShareableItem.map((item, index) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          description: frequenceScheduledPosts.descriptions,
          company_id: item.company_id,
          offer_id: item.offer_id,
          position: index + 1,
          image_url: item.image_url,
          preview_url: item.preview_url,
          dateScheduled: [new Date()]
        };
      }) as ScheduleItem[];
    }

    handleSequencialScheduler(itemsFactory);
  }, [frequenceScheduledPosts]);

  useEffect(() => {
    //console.log('dispatch(setSelectedScheduledItems...');
    dispatch(setSelectedShareableItem(selectedScheduledItems));
  }, [selectedScheduledItems]);

  const handleUpdateItemDate = (cardIndex: number, newDate: Date, indexDate: number) => {
    let copy = [...selectedScheduledItems];

    copy[cardIndex].dateScheduled[indexDate] = newDate;

    dispatch(setSelectedScheduledItems(copy));
  };

  const handleUpdateItemDescription = (cardIndex: number, description: string) => {
    let copy = [...selectedScheduledItems];

    copy[cardIndex].description = description;

    dispatch(setSelectedScheduledItems(copy));
  };

  const handleUpdateItemDescriptionIA = async (cardIndex: number, backgroundId: number) => {
    const response = await handleNewIAMessage.mutateAsync({ background_id: backgroundId });
    let copy = [...selectedScheduledItems];

    copy[cardIndex].description = response;

    dispatch(setSelectedScheduledItems(copy));
  };

  const handleDeleteItem = (cardIndex: number) => {
    let copy = [...selectedScheduledItems];

    copy = copy.filter((_, index) => index !== cardIndex);

    if (copy.length) {
      handleSequencialScheduler(copy);
    } else {
      dispatch(setSelectedScheduledItems(copy));
    }
    if (copy.length <= 1) {
      dispatch(clearSelectedShareableItem());
    }
  };

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width={'52.19rem'}
    >
      {selectedScheduledItems.length > 0 ? (
        frequenceScheduledPosts.typeRepeat.children === 'Semanas' && countSelectDays === 0 ? (
          <Typography align="center">
            Por favor, selecione algum dia da semana e atualize a data de agendamento.
          </Typography>
        ) : (
          selectedScheduledItems.map((item, cardIndex) => (
            <CardEditItem
              messageIsLoading={handleNewIAMessage.isLoading}
              background_id={item.background_id}
              offer_id={item.offer_id}
              name={item.name}
              description={item.description}
              dateScheduled={item.dateScheduled}
              image_url={item.image_url}
              preview_url={item.preview_url}
              cardIndex={cardIndex}
              onUpdateDate={handleUpdateItemDate}
              onUpdateDescription={handleUpdateItemDescription}
              onUpdateDescriptionIA={handleUpdateItemDescriptionIA}
              onDelete={() => handleDeleteItem(cardIndex)}
              isPublicationDates={Boolean(frequenceScheduledPosts.postsRepeat > 0)}
            />
          ))
        )
      ) : (
        <Typography align="center">Você não tem nenhuma arte selecionada para agendar.</Typography>
      )}
    </Box>
  );
}

