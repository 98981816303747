import React, { useState, useEffect } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Surface,
  Symbols,
} from 'recharts';
import COLORS from './colors';
import './style.css';

export default function MultBarChart({ data = [], bars = [], nameKey }) {
  const [activeLegend, setActiveLegend] = useState(bars),
    [chartBars, setChartBars] = useState(bars),
    [disabledLegend, setDisabledLegend] = useState([]);

  useEffect(() => {
    const tempBar = bars.map((bar, index) => ({
      name: bar,
      color: COLORS[index % COLORS.length],
    }));

    setActiveLegend(tempBar);
    setChartBars(tempBar);
  }, []);

  function _filterChart(value) {
    let _disabled = [];
    if (disabledLegend.includes(value)) {
      _disabled = disabledLegend.filter(idDisabled => idDisabled !== value);
    } else {
      _disabled = disabledLegend.concat(value);
    }
    const newChartBars = activeLegend.filter(
      dt => !_disabled.includes(dt.name)
    );
    setChartBars(newChartBars);
    setDisabledLegend(_disabled);
  }

  function LegendComponent({ payload }) {
    return (
      <div className="containerLegend">
        {payload.map(entry => {
          const { color, name } = entry,
            active = disabledLegend.includes(name),
            style = { color: active ? '#AAA' : '#000' };

          return (
            <span
              className="textLegend"
              style={style}
              onClick={() => _filterChart(name)}
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div className="container">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={nameKey} />
          <YAxis />
          <Tooltip contentStyle={{ borderRadius: 15 }} />
          <Legend
            verticalAlign="bottom"
            payload={activeLegend}
            content={LegendComponent}
          />
          {chartBars.map((bar, index) => (
            <Bar
              key={index}
              dataKey={bar.name}
              fill={bar.color}
              radius={[5, 5, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
