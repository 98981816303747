import React from 'react';

//Using Components
import { Box, Grid } from '@material-ui/core';
import { Modal, MyButton, CountCard } from '../../../utils/components';

export default function ModalNumbersForm(props) {
  function Footer() {
    return (
      <Box>
        <MyButton onClick={props.onClose} color="default">
          Fechar
        </MyButton>
      </Box>
    );
  }

  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={props.onClose}
      header={props.form.nameForm}
      footer={<Footer />}
    >
      <Grid container spacing={3}>
        <Grid item xs>
          <CountCard
            count
            titleTypographyProps={{ variant: 'h6' }}
            end={props.form.acessos}
            title="Acessos"
            icon="access_time"
          />
        </Grid>
        <Grid item xs>
          <CountCard
            count
            titleTypographyProps={{ variant: 'h6' }}
            end={props.form.respostas}
            title="Respostas"
            icon="question_answer"
          />
        </Grid>
        <Grid item xs>
          <CountCard
            count
            decimals={2}
            suffix="%"
            titleTypographyProps={{ variant: 'h6' }}
            end={props.form.engajamento}
            title="Engajamento"
            icon="thumb_up"
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
