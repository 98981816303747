import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    //marginLeft: 240,
    // marginTop: '5vh'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  rootNotification: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '-webkit-fill-available',
    marginTop: '3.5vh',
    padding: theme.spacing(1),
    position: 'fixed',
    zIndex: 10
  },
  contentNotification: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentBtnClose: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textAlert: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '.05em'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

export default useStyles;

