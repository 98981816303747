/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
} from '@material-ui/core';
import moment from 'moment';
import { contadorPush } from '../../Insights/actions/actions';
import {
  MyButton,
  DatePicker,
  Select,
  Chip,
  Countup,
  TextField,
  Modal,
} from '../../../utils/components';
import { PARETO, HOST_TRACKER } from '../../../utils/helpers/Constants';
import '@webscopeio/react-textarea-autocomplete/style.css';

const steps = ['Informações de Envio', 'Mensagem', 'Disparar'];
class NovaCampanhaSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      show: false,
      showAlert: false,
      showVar: false,
      showAlertValidate: false,
      showNumeros: false,
    };
  }

  componentDidMount() {
    const { push } = this.props;

    if (push && (push.idListProspectei || push.optionList)) {
      this.props.contadorPush({
        value: push.idListProspectei || push.optionList.value,
        pra_quem: push.pra_quem,
        origem_campanha: push.origem_campanha,
      });
    }
  }

  tituloModal(titulo) {
    switch (titulo) {
      case 1:
        return 'Refazer campanha para pessoas que compraram';
      case 2:
        return 'Refazer campanha para pessoas que não compraram';
      case 3:
        return 'Refazer campanha para todas as pessoas';
      default:
        return 'Nova Campanha';
    }
  }

  header(props) {
    return (
      <Fragment>
        <strong>{this.tituloModal(props.push.pra_quem)}</strong>
        <section className="modal-header">
          <span className="modal-subtitle">
            <strong>Tipo de Campanha: </strong>PUSH
          </span>
          <span className="modal-subtitle">
            <strong>Push's na lista: </strong>
            <Countup
              isLoading={props.isLoadingCountPhone}
              end={props.contador_campanha.contador_push || 0}
            />
          </span>
          <span className="modal-subtitle">
            <strong>Investimento: </strong>
            <Countup
              prefix="R$"
              decimals={2}
              isLoading={props.isLoadingCountPhone}
              end={parseFloat(props.contador_campanha.valor_total || 0)}
            />
          </span>
        </section>
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div style={{ width: '100%' }}>
        <Stepper nonLinear activeStep={state.activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepButton onClick={() => this.setState({ activeStep: index })}>
                {step}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Informações da Campanha
          </Typography>
          <TextField
            label="Nome"
            className="form-control"
            onChange={({ target }) => {
              props.onChange('nomeCampanha', target.value);
            }}
            value={props.push.nomeCampanha}
            autoComplete="none"
          />
          <Select
            disabled
            options={props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao,
            }))}
            placeholder="Selecione uma lista..."
            onChange={option => props.contadorPush(option)}
            value={props.push.optionList}
          />

          {props.push.ofertas && (
            <Select
              options={props.listsProspectei.map(list => ({
                label: list.nameList,
                value: list.idListProspectei || list.idPromocao,
              }))}
              placeholder="Selecione uma lista..."
              onChange={option => {
                props.onChange('listaSelecionada', option);
                props.contadorPush(option);
              }}
              value={props.push.listaSelecionada}
            />
          )}

          <section>
            <DatePicker
              dateAndTime
              label="Início da campanha"
              onChange={date => props.onChange('dataInicioCampanha', date)}
              value={props.push.dataInicioCampanha}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              label="Término da campanha"
              onChange={date => props.onChange('dataFimCampanha', date)}
              value={props.push.dataFimCampanha}
            />
          </section>
          <Select
            label="Grupo do controle"
            options={PARETO}
            valueKey="id"
            placeholder="Grupo do controle..."
            onChange={option => props.onChange('pareto', option)}
            value={props.push.pareto}
          />
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Informações de Disparo
          </Typography>
          <DatePicker
            dateAndTime
            label="Envio de push"
            onChange={date => props.onChange('dataInicio', date)}
            value={props.push.dataInicio}
          />

          <TextField
            label="Qual o assunto do push ?"
            className="form-control"
            onChange={({ target }) => {
              props.onChange('subject', target.value);
            }}
            value={props.push.subject}
            autoComplete="none"
          />

          <TextField
            label="Link (URL)"
            className="form-control"
            onChange={({ target }) =>
              props.onChange('redirectTracker', target.value)
            }
            value={props.push.redirectTracker}
          />

          <Select
            options={HOST_TRACKER}
            valueKey="host"
            placeholder="Domínio..."
            onChange={option => props.onChange('hostTracker', option)}
            value={props.push.hostTracker}
          />
        </section>
      </section>
    );
  }
  step2(props, state) {
    return (
      <section style={{ padding: '2rem 15rem' }}>
        <Chip
          label="Primeiro nome"
          clickable
          onClick={() => {
            props.onChange('message', `${props.push.message} {{primeiroNome}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Nome completo"
          onClick={() => {
            props.onChange('message', `${props.push.message} {{nomeCompleto}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Cupom"
          onClick={() => {
            props.onChange('message', `${props.push.message} {{voucher}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <Chip
          clickable
          label="Click tracker"
          onClick={() => {
            props.onChange('message', `${props.push.message} {{clickTracker}}`);
            this.setState({ showVar: false });
          }}
        ></Chip>
        <TextField
          id="outlined-multiline-static"
          label="Digite a mensagem desejada..."
          fullWidth
          multiline
          rows="20"
          error={state.showAlert}
          value={props.push.message}
          margin="normal"
          variant="outlined"
          onChange={({ target }) => props.onChange('message', target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" direction="column">
                <div style={{ display: 'flex', flexDirection: 'column' }}></div>
              </InputAdornment>
            ),
          }}
        />
      </section>
    );
  }
  step3(props, state) {
    return (
      <section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Sobre a Campanha
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome</strong>
              </p>
              <span>{props.push.nomeCampanha}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data Disparo</strong>
              </p>
              <span>
                {moment(props.push.dataInicio).format('DD/MM/YYYY HH:mm')}
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Valor do Investimento</strong>
              </p>
              <span>
                <Countup
                  prefix="R$"
                  decimals={2}
                  isLoading={props.isLoadingCountPhone}
                  end={parseFloat(props.contador_campanha.valor_total || 0)}
                />
              </span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Total de Envios</strong>
              </p>
              <span>
                <Countup
                  isLoading={props.isLoadingCountPhone}
                  end={props.contador_campanha.contador_celular || 0}
                />
              </span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Template
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <span>{props.push.message}</span>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              disabled={_disableButtonSave(props.push, state.showAlert)}
              onClick={props.onSave}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, push } = this.props;

    const { activeStep } = this.state;

    return (
      <Modal
        size="lg"
        showModal={push.showModalPush}
        onClose={onClose}
        header={this.header(this.props)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const _disableButtonSave = (sms, alert) => {
  if (sms.message && !alert && (sms.optionList || sms.idListProspectei)) {
    return false;
  } else {
    return true;
  }
};

const mapStateToProps = ({
  CampaignReducer,
  InsightsReducer,
  TemplateReducer,
}) => ({
  ...CampaignReducer,
  ...InsightsReducer,
  ...TemplateReducer,
});

const mapDispatchToProps = {
  contadorPush,
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaCampanhaSMS);
