import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
// important Swiper required modules
import SwiperCore, { A11y, Navigation } from 'swiper';
// core Swiper
import 'swiper/components/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
// Styles must use direct files imports
import 'swiper/swiper.scss';

import { clearSearchResults, clearSubcategories, setPaginationProps } from '../../../actions';
import { CategoryType } from '../../../reducer';
import { useStyles } from '../styles';

// install Swiper modules
SwiperCore.use([Navigation, A11y]);

type CategoriesProps = {
  items: CategoryType[];
  isLoadingData?: boolean;
  selectedCategory?: CategoryType | undefined;
  numberSlidesPerView?: number;
  onSelectCategory: (category: CategoryType | undefined) => void;
};

export default function Categories({
  items,
  isLoadingData,
  selectedCategory,
  numberSlidesPerView = 5,
  onSelectCategory,
  ...props
}: CategoriesProps) {
  const classes = useStyles();

  const dispatch: any = useDispatch();

  return (
    <Box
      className={classes.categoriesContainer}
      data-testid="dsModule-fast-models-hero-component-categories"
    >
      {items.length > 0 ? (
        <Swiper
          slidesPerView={numberSlidesPerView}
          spaceBetween={8}
          loop={false}
          pagination={false}
          navigation={items.length > numberSlidesPerView ? true : false}
          className={classes.categoriesSlider}
        >
          {items.map((category, index) => (
            <SwiperSlide
              key={index}
              className={classes.categoriesSlide}
              data-testid="dsModule-fast-models-hero-component-categories-slide"
              onClick={() => {
                if(selectedCategory?.ID !== category.ID){
                  onSelectCategory(category);
                  dispatch(clearSearchResults());
                  dispatch(clearSubcategories());
                  dispatch(setPaginationProps(1));
                }
                
              }}
            >
              <img
                src={category?.URL_IMAGE_SHORTCUT}
                className={classes.categoriesSlideImage}
                alt={`Categoria de Segmento - ${category?.DESCRIPTION}`}
              />
              <Typography
                className={[
                  classes.categoriesSlideName,
                  selectedCategory?.ID === category.ID ? classes.categoriesSlideNameSelected : ''
                ].join(' ')}
                align="center"
                sx={{ FontSize: '16px' }}
              >
                {category?.DESCRIPTION.replace(/(\s\(.*?\))|<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '')}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : isLoadingData ? (
        <div className={[classes.categoriesSlider, classes.categoriesSliderSkeleton].join(' ')}>
          {Array(numberSlidesPerView)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={classes.categoriesSlide}
                style={{ width: '136px' }}
                data-testid="dsModule-fast-models-hero-component-categories-fake-slide"
              >
                <Skeleton variant="circular" width={80} height={80} />
                <div style={{ width: '100px' }}>
                  <Skeleton variant="text" animation="wave" />
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Box>Oops! Nenhuma Categoria foi encontrada para este segmento.</Box>
      )}
    </Box>
  );
}

