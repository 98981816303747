import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  logo: { width: '20%' },
  progress: { width: '20%', marginTop: '2vh' },
  colorProgress: { backgroundColor: theme.palette.primary.main },
}));

export default useStyles;
