/* eslint---disable */
import {
  Button,
  Dialog,
  IconButton,
  Checkbox,
  Typography,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTable } from '../../../../utils/components';
import { formatPrice } from '../../../../utils/helpers/Format';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function SelecionarProdutos(props) {
  const [produtos, setProdutos] = React.useState(
    props.tabloideProdutos.map(element => {
      let tmp = props.idsNaoIncluidos.find(
        element2 => element.id_tabloide_produto === element2
      );
      if (tmp) {
        tmp = false;
      } else {
        tmp = true;
      }
      return {
        ...element,
        selecionado: tmp,
      };
    })
  );

  useEffect(() => {
    props.onOpen();
  }, []);

  const [selecionarTodos, setSelecionarTodos] = useState(false);

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
      disableBackdropClick
      maxWidth={'sm'}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
        Selecione os produtos para gerar arte
      </DialogTitle>
      <DialogContent dividers>
        <CustomTable
          data={produtos}
          pageSize={50}
          paging
          search
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
          components={{
            Toolbar: props => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selecionarTodos}
                    onChange={event => {
                      let checked = event.target.checked;
                      setSelecionarTodos(checked);

                      let tmp = [...produtos];
                      tmp = tmp.map(element => ({
                        ...element,
                        selecionado: checked,
                      }));

                      setProdutos([...tmp]);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label="Selecionar Todos"
              />
            ),
          }}
          columns={[
            {
              field: 'selecionado',
              title: 'Selecionado',
              render: rowData => (
                <Checkbox
                  checked={rowData.selecionado}
                  onChange={event => {
                    let tmp = [...produtos];
                    tmp[rowData.tableData.id] = {
                      ...tmp[rowData.tableData.id],
                      selecionado: event.target.checked,
                    };
                    setProdutos([...tmp]);
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              ),
            },
            {
              field: 'imagem_produto',
              title: 'Foto do produto',
              render: rowData => (
                <img
                  crossOrigin='anonymous'
                  alt={rowData.imagem_produto}
                  src={rowData.imagem_produto}
                  style={{
                    width: 75,
                    height: 90,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7',
                  }}
                />
              ),
            },
            {
              title: 'Info.',
              field: 'ean',
              render: rowData => (
                <React.Fragment>
                  {!!rowData.ean && (
                    <span>
                      EAN: {rowData.ean}
                      <br />
                    </span>
                  )}
                  {!!rowData.id_produto && (
                    <span>
                      Código: {rowData.id_produto}
                      <br />
                    </span>
                  )}
                  {!!rowData.nome_produto && (
                    <span>
                      Nome: {rowData.nome_produto}
                      <br />
                    </span>
                  )}
                  {!!rowData.preco_original && (
                    <span>
                      Preço De: {formatPrice(rowData.preco_original)}
                      <br />
                    </span>
                  )}
                  {!!rowData.preco_dois && (
                    <span>
                      Preço Por: {formatPrice(rowData.preco_dois)}
                      <br />
                    </span>
                  )}
                  {!!rowData.gramatura && (
                    <span>
                      Unidade: {rowData.gramatura}
                      <br />
                    </span>
                  )}
                  {!!rowData.observacoes && (
                    <span>
                      Observações: {rowData.observacoes}
                      <br />
                    </span>
                  )}
                  <span>
                    Sequência: {rowData.sequence}
                    <br />
                  </span>
                </React.Fragment>
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            let tmp = produtos.filter(value => !value.selecionado);
            props.onSave(tmp.map(value => value.id_tabloide_produto));
          }}
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelecionarProdutos);
