import { useTheme } from '@material-ui/core';
import React from 'react';
import {
  Circle,
  GoogleMap,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

// This library "recompose" is outdated, but it's still used in the code.
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAFnRCI6kHw0ICofnhMoI9zI2B0HexETeU&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px`, borderRadius: 5 }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: 5 }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ location }) => {
  const theme = useTheme();

  return (
    <GoogleMap
      defaultZoom={10}
      options={{
        streetViewControl: false,
        disableDefaultUI: true,
      }}
      center={{
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      }}
    >
      <Circle
        defaultVisible
        visible
        center={{
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        }}
        radius={location.radius * 1000}
        options={{
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#c8e4fb',
          fillOpacity: 0.35,
        }}
      />
    </GoogleMap>
  );
});

export default MyMapComponent;
