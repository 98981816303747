import { 
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

export const StyledTypography = styled(Typography)({
  fontSize: '14px', lineHeight: '20px', fontWeight: 500
});

function GenerateWhatsAppLink({ open, onSubmit, onClose }) {
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [text, setText] = useState();

  function handlePhoneNumberChange(event) {
    setPhoneNumber(event.target.value);
  }

  function handleTextChange(event) {
    setText(event.target.value);
  }

  function handleGenerateLink() {
    const test = new URLSearchParams({
      text,
      phone: `55${phoneNumber.replace(/[^\d]+/g, '')}`
    }).toString();

    const link = `https://api.whatsapp.com/send?${test}`;

    onSubmit(link);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx:{
          backgroundColor: '#F2F3F5',
        }
      }}
    >
      <DialogContent sx={{ 
        padding: '1rem', gap: '1.5rem',
        display: 'flex', flexDirection: 'column',
        }}
      >
        <Box sx={{ backgroundColor: '#FDFAE6', border: '2px solid #F7B304', borderRadius: '0.5rem', padding: '0.5rem'}}>
          <StyledTypography 
            sx={{ 
              padding: '0.125rem 0.3rem', backgroundColor: '#0F0F10', color: '#FFF',
              display: 'inline-block', borderRadius: '0.25rem', marginBottom: '0.5rem',
            }}
          >
            ⚠️ É necessário inserir um link
          </StyledTypography>
          <StyledTypography >
            Os impulsionamentos do Facebook e Instagram precisam conter um link que vai direcionar
            as pessoas que clicarem no seu anúncio.
          </StyledTypography>
        </Box>

        <Box sx={{ backgroundColor: '#FFF', border: '2px solid #D3D5DB', borderRadius: '8px', padding: '0.5rem'}}>
          <StyledTypography 
            sx={{ 
              padding: '0.125rem 0.5rem', backgroundColor: '#0C20B5', color: '#FFF',
              display: 'inline-block', borderRadius: '0.25rem', marginBottom: '0.5rem',
              textAlign: 'center'
            }}
          >
            Dica Datasales ⭐
          </StyledTypography>
          <StyledTypography >
            Recomendamos colocar o link do whatsapp ou Instagram do seu negócio para redirecionar 
            as pessoas clicarem no seu anúncio.
          </StyledTypography>
        </Box>

        <Box>
          <TextField
            InputProps={{
              sx:{
                '&.MuiOutlinedInput-root':{
                  backgroundColor: '#FFF',
                  '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0C20B5 !important'
                  }
                },
              }
            }}
            label="Mensagem de texto que será enviada do seu Whats"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={text}
            onChange={handleTextChange}
          />
          <StyledTypography sx={{ marginTop: '0.5rem',fontSize: '12px', lineHeight: '15px', color: '#616161', letterSpacing: '0.05rem' }} >
              Esta mensagem será enviada no seu WhatsApp para as pessoas que clicarem no seu impulsionamento
          </StyledTypography>
        </Box>
        <InputMask mask="(99) 99999-9999" value={phoneNumber} onChange={handlePhoneNumberChange} >
          {() => (
            <TextField 
              InputProps={{
                sx:{
                  '&.MuiOutlinedInput-root':{
                    backgroundColor: '#FFF',
                    border: '#0C20B5 !important',
                    '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0C20B5 !important'
                    }
                  },
                }
              }}
              margin="normal" 
              label="Adicione um número" 
              variant="outlined"
              fullWidth 
            />
          )}
        </InputMask>
        
        <Divider style={{ margin: '0px 0px 16px', color: '#CFCFCF' }} />
      
      </DialogContent>
      <DialogActions style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '24px'}}>
        <Box  style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant='outlined' style={{ color: '#616161', borderColor: '#D3D5DB' }} onClick={onClose}>
            Cancelar
          </Button>
         
          <Button
            variant="contained"
            color="primary"
            disabled={!phoneNumber || !text}
            onClick={handleGenerateLink}
          >
            Salvar
          </Button>
        </Box>
        <StyledTypography sx={{ color: '#000000' }} >
            Nós da Datasales recomendamos que você sempre utilize o link do whatsapp ou do seu perfil do Instagram.
        </StyledTypography>

      </DialogActions>

    </Dialog>
  );
}

export default GenerateWhatsAppLink;
