import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import FacebookIcon from '@material-ui/icons/Facebook';
import LoopIcon from '@material-ui/icons/Loop';
import PeopleIcon from '@material-ui/icons/People';
import React, { useEffect, useState } from 'react';
import { AMBIENTE } from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import Insights from './components/Insights';
import useStyles from './styles';

const status = {
  ACTIVE: 'Ativo',
  PAUSED: 'Pausada',
  DELETED: 'Deletada',
  PENDING_REVIEW: 'Revisão pendente',
  DISAPPROVED: 'Reprovada',
  PREAPPROVED: 'Pré-aprovado',
  PENDING_BILLING_INFO: 'Informações de faturamento pendentes',
  CAMPAIGN_PAUSED: 'Campanha Pausada',
  ARCHIVED: 'Arquivada',
  ADSET_PAUSED: 'Conjunto de anúncios pausado',
  IN_PROCESS: 'Em análise',
  WITH_ISSUES: 'Com problemas',
};

function Ads({ offerId }) {
  const classes = useStyles();

  const [insightsIsOpen, setInsightsIsOpen] = useState(false);
  const [ads, setAds] = useState([]);
  const [adsId, setAdsId] = useState();
  const [creativeId, setCreativeId] = useState();
  const [loading, setLoading] = useState(false);

  const handleInsightsOpen = (id, cId) => {
    setAdsId(id);
    setCreativeId(cId);
    setInsightsIsOpen(true);
  };

  const handleInsightsClose = () => {
    setAdsId();
    setInsightsIsOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
          {
            offer_id: offerId,
            access_token:
              'EAALk5H3jEBoBALKjllI78aN93lLK82Jchg15HxJq1BSKHZBm4Os5cyQQ8Nf5k5OVRCsX3UniZA1jeTgZBqnDtCnXGZBacFXoWSB3MudV0vGw5eyzidRjJjJJmxeXFr0QFizZCOLo2HFufu7BqvAiBnaydF8HteEmWoRJKBjsZC4r8ybWPDF9qt',
          }
        );

        setAds(response.content);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (ads.length === 0 && !loading) {
    return (
      <React.Fragment>
        <div className={classes.emptyContainer}>
          <Typography variant="h6">Nenhum resultado</Typography>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {ads.map(item => (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar}>
                    <FacebookIcon />
                  </Avatar>
                }
                title={item.name}
                subheader={`Status: ${status[item.status]}`}
              />
              <Divider />
              {/* <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <PeopleIcon />
                          </Avatar>
                        }
                        title={item?.insights?.reach || 219}
                        subheader="Alcance"
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <CheckIcon />
                          </Avatar>
                        }
                        title={item?.insights?.clicks || 440}
                        subheader="Clicks"
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <LoopIcon />
                          </Avatar>
                        }
                        title={item?.insights?.impressions || 139}
                        subheader="Impressões"
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <ErrorIcon />
                          </Avatar>
                        }
                        title={item?.insights?.frequency || 322}
                        subheader="Frequência"
                      />
                    </Card>
                  </Grid>
                </Grid>
                      </CardContent> */}
              <Divider />
              <CardActions className={classes.actions}>
                <Button
                  onClick={() => {
                    handleInsightsOpen(item.id, item?.creative?.id);
                  }}
                >
                  ver mais
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {insightsIsOpen && (
        <Insights
          open={insightsIsOpen}
          adsId={adsId}
          creativeId={creativeId}
          onClose={handleInsightsClose}
        />
      )}
    </React.Fragment>
  );
}

export default Ads;
