import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
// import clsx from "clsx";
// import { loadCSS } from "fg-loadcss";
import { getVendas } from "../actions/index";
import {
  Button,
  // Typography,
  // Divider,
  // MenuItem,
  // Avatar,
  Icon,
  // Tooltip,
  // CircularProgress,
  TextField,
  IconButton,
  InputAdornment,
  withStyles
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MyDate from "../../../utils/helpers/Date";
import { CustomTable, DatePicker } from "../../../utils/components";
import { CPF } from "../../../utils/helpers/Masks";

class Vendas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtroDataInicio: new MyDate().getDate("YYYY-MM-DD"),
      filtroDataFim: new MyDate().addDate("1", "month")
    };
  }
  componentDidMount() {
    this.props.getVendas();
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim } = this.state;
      const request = {
        dataInicio: new MyDate(filtroDataInicio, "YYYY-MM-DD").getDate(),
        dataFim: new MyDate(filtroDataFim, "YYYY-MM-DD").getDate()
      };
      this.props.getVendas("", request.dataInicio, request.dataFim);
    });
  }

  render() {
    const { vendas, loadingVendas, classes } = this.props;

    return (
      <div className={classes.root}>
        <Link to="/database" style={{ textDecoration: "none" }}>
          <Button
            size="medium"
            style={{ marginBottom: "1rem" }}
            color="primary"
          >
            <Icon>arrow_back_ios</Icon>Voltar
          </Button>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h1>Vendas</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              margin="none"
              style={{ marginRight: 20 }}
              size="md"
              placeholder="Pesquisar  pelo cpf"
              autoComplete="false"
              value={CPF(this.state.documento)}
              onChange={event =>
                this.setState({ documento: event.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.props.getVendas(this.state.documento)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <DatePicker
                style={{ marginRight: 15 }}
                withKeyboard
                className={classes.dataPicker}
                value={this.state.filtroDataInicio}
                format="DD/MM/YYYY"
                disableFuture
                onChange={date => this.setState({ filtroDataInicio: date })}
              />
              <DatePicker
                withKeyboard
                className={classes.dataPicker}
                value={this.state.filtroDataFim}
                format="DD/MM/YYYY"
                onChange={date => this.setState({ filtroDataFim: date })}
              />
              <IconButton onClick={() => this.onChangePeriodo()}>
                <SearchIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <CustomTable
            title=""
            isLoading={loadingVendas}
            // onRowClick={(columns, data) => props.openModalPerfil(data)}
            pageSize={6}
            style={{
              border: "1px solid #e0e0e0",
              boxShadow: "none",
              borderRadius: 10
            }}
            columns={[
              { title: "id vendas", field: "id_vendas" },
              { title: "id vendas erp", field: "id_vendas_erp" },
              {
                title: "Cupom fiscal",
                field: "cupom_fiscal"
              },
              { title: "Data", field: "data" },
              { title: "id pessoa", field: "id_pessoa" },
              { title: "Documento", field: "documento" },
              { title: "id empresa", field: "id_empresa" },
              { title: "id usuario", field: "id_usuario" },
              { title: "id loja", field: "id_loja" },
              { title: "Data inserido ", field: "data_insert" },
              { title: "Status venda", field: "status_venda" },
              { title: "Produto", field: "produto" },
              { title: "Produto codigo ", field: "produto_codigo" },
              { title: "Grupo", field: "grupo" },
              { title: "Grupo codigo", field: "grupo_codigo" },
              { title: "Familia", field: "familia" },
              { title: "Familia codigo", field: "familia_codigo" },
              { title: "Condicao", field: "condicao" },
              { title: "Condicao codigo", field: "condicao_codigo" },
              { title: "Vendedor", field: "vendedor" },
              { title: "Vendedor codigo", field: "vendedor_codigo" },
              { title: "Departamento", field: "departamento" },
              { title: "Departamento codigo", field: "departamento_codigo" },
              { title: "Cor", field: "cor" },
              { title: "Cor codigo", field: "cor_codigo" },
              { title: "Fornecedor", field: "fornecedor" },
              {
                title: "Fornecedor codigo",
                field: "fornecedor_codigo"
              },
              { title: "Gerente", field: "gerente" },
              { title: "Gerente codigo", field: "gerente_codigo" },
              { title: "Etiqueta", field: "etiqueta" },
              { title: "Etiqueta codigo", field: "etiqueta_codigo" },
              { title: "Colecao", field: "colecao" },
              { title: "Colecao codigo", field: "colecao_codigo" },
              { title: "Tamanho", field: "tamanho" },
              {
                title: "Tamanho codigo",
                field: "tamanho_codigo"
              },
              { title: "Valor", field: "valor" },
              { title: "Valor bruto", field: "valor_bruto" },
              { title: "Valor cashback", field: "valor_cashback" },
              { title: "Markup", field: "markup" },
              { title: "Processado", field: "processado" },
              { title: "Cancelado", field: "cancelado" }
            ]}
            data={
              vendas
                ? vendas.map(data => ({
                    ...data,
                    data: moment(data.data)
                      .add(1, "days")
                      .format("DD/MM/YYYY")
                  }))
                : []
            }
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    paddingTop: "3rem",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flexGrow: 1,
    paddingTop: "1rem",
    height: "100%"
  },
  subList: {
    display: "flex",
    margin: theme.spacing(2)
  },
  section: { display: "flex" }
});

const mapStateToProps = ({ DatabaseReducer }) => ({
  ...DatabaseReducer
});

const mapDispatchToProps = { getVendas };

const VendasConnect = connect(mapStateToProps, mapDispatchToProps)(Vendas);

export default withStyles(styles)(VendasConnect);
