import React from 'react';
import { ArrowForward } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Autocomplete } from "../../../../utils/components/AutoComplete/AutoCompleteV2"
import { useStyles } from "./styles";

type MatchCampaignFileProps = {
  header: string;
  defaultValue?: any;
  options?: any[];
  onChange?: (data: any) => void;
};

export function MatchContatFile({
  header,
  defaultValue,
  options,
  onChange,
}: MatchCampaignFileProps) {
  const styles = useStyles();
  return (
    <Stack direction="row" spacing={2}>
      <div style={{ width: "30%" }}>
        <Typography color="info" fontWeight={600} style={{color: '#2813AD'}}>
          {header}
        </Typography>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <ArrowForward color="action" />
      </div>

      <Autocomplete
        fullWidth
        size="small"
        label="Coluna"
        options={options}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option.column}
        onChange={(_, option) => {
          onChange!(option);
        }}
        className={styles.auto}
      />
    </Stack>
  );
}
