import { createStyles, makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme =>
  createStyles({
    appBar: {
      position: 'relative',
      border: 'none'
    },
    avatar: { background: theme.palette?.primary?.main },
    budgedCardContent: { paddingLeft: theme?.spacing?.unit },
    container: {
      display: 'flex'
    },
    title: {
      flex: 1
    },
    content: {
      padding: theme?.spacing?.unit
    },
    formContainer: {
      // height: '82vh',
      padding: '0 20px',
      overflowY: 'hidden',
      overflowX: 'hidden',
      '&:hover': {
        // overflowY: 'scroll',
      }
    },
    previewContainer: {
      maxWidth: '100%',
      border: `1px solid ${theme?.palette?.divider}`,
      borderRadius: theme?.shape?.borderRadius
    },
    preview: {
      width: '100%',
      borderRadius: theme?.shape?.borderRadius
    },
    popper: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme?.palette?.divider,
      borderRadius: theme?.shape?.borderRadius
    },
    tab: { textTransform: 'none' },
    listItem: {
      border: `1px solid ${theme?.palette?.divider}`,
      borderRadius: theme?.shape?.borderRadius,
      marginBottom: theme?.spacing?.unit
    },
    listItemSelected: {
      border: `1px solid ${theme.palette?.primary?.main}`,
      borderRadius: theme?.shape?.borderRadius,
      marginBottom: theme?.spacing?.unit,
      backgroundColor: theme?.palette?.background?.paper
    },
    icon: {
      color: theme?.palette?.text?.secondary,
      marginRight: theme?.spacing?.unit
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme?.transitions?.create('transform', {
        duration: theme?.transitions?.duration?.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    textPrimary: {
      color: theme?.palette?.text?.primary
    },
    balanceAlert: {
      marginBottom: 10
    },
    chipCredits: {
      marginLeft: 15
    },
    iconCredit: {
      marginLeft: 14
    },
    chipForca: {
      marginLeft: 6
    },
    inputNoBorder: {
      border: 'none',
      borderColor: 'transparent !important'
    },
    inputBackgroundColor: {
      backgroundColor: '#F5F5F5'
    },
    disclaimerError: {
      marginTop: '8px',
      marginBottom: '24px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 0px 8px 8px',
      gap: '8px',
      width: '100%',
      height: '40px',
      border: '1px solid #D54D4D',
      borderRadius: '4px',
      flex: 'none',
      order: '6',
      flexGrow: '0',
      background: 'rgba(213, 77, 77, 0.05)'
    }
  })
);

export default styles;
