import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LogoDsMkt from '../../assets/preload/logo-marketing.png';
import { useSelectMenu } from '../../utils/helpers/Redirect';
import { setLogged } from '../Login/actions/actionLogin';
import useStyles from './styles';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignInAsUser(props) {
  const { selectMenuShort } = useSelectMenu({ reload: true });

  const classes = useStyles();

  const history = useHistory();

  const { token } = useParams();

  let query = useQuery();

  const shortToRedirect = query.get('shortToRedirect');

  const image = React.useMemo(() => {
    if (window.location.hostname.includes('redeminipreco')) {
      return 'https://datasalesio-imagens.s3.amazonaws.com/LogoMiniPreco.png';
    } else {
      return LogoDsMkt;
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { user_id } = jwt_decode(token);
        const response = await axios.get(
          `https://kldlktujc6.execute-api.us-east-1.amazonaws.com/prd/users/${user_id}`,
          { headers: { authorization: token } }
        );
        const { user, company, permissions } = response.data;
        localStorage.setItem('logged', true);
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('company', JSON.stringify(company));
        localStorage.setItem('companyId', company.id);
        localStorage.setItem('segmentId', company.ID_SEGMENT);
        localStorage.setItem('name', JSON.stringify(user.name));
        localStorage.setItem('email', JSON.stringify(user.email));
        localStorage.setItem('permissions', JSON.stringify(permissions));
      } catch (err) {
        history.push('/');
      } finally {
        // alert('Oloco');
        if (shortToRedirect) {
          if (shortToRedirect === 'sair') {
            props.setLogged(false);
            localStorage.clear();
            history.push('/');
          }
          selectMenuShort(shortToRedirect);
        } else {
          history.push('/');
        }
      }
    })();
  }, []);

  return (
    <Fade in>
      <div className={classes.root}>
        <img className={classes.logo} src={image} alt="logo" />
        <LinearProgress className={classes.progress} />
      </div>
    </Fade>
  );
}

const mapDispatchToProps = { setLogged };

const mapStateToProps = ({ loginReducer }) => ({ loginReducer });

export default connect(mapStateToProps, mapDispatchToProps)(SignInAsUser);

