import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import TabPanel from '../../../../utils/components/TabPanel';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 50,
    width: 50,
    borderRadius: 25,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
}));

const locale = {
  primary: 'Cor primaria',
  secondary: 'Cor secundaria',
  appbar_background: 'Cor de fundo do cabeçalho',
  appbar_text: 'Cor do texto do cabeçalho ',
  content_background: 'cor de fundo do conteúdo',
  // content_text: '',
  footer_background: 'Cor de fundo rodapé',
  footer_text: 'Cor do texto do rodapé',
  product_card_background: 'Cor de fundo card',
  product_card_text: 'Cor do texto do produto',
};

function Colors({ siteId }) {
  const classes = useStyles();

  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [colorId, setColorId] = useState();
  const [colors, setColors] = useState({
    appbar_background: '#ffffff',
    appbar_text: '#000000',
    content_background: '#ffffff',
    // content_text: '#000000',
    footer_background: '#ffffff',
    footer_text: '#000000',
    primary: '#2196f3',
    product_card_background: '#ffffff',
    product_card_text: '#000000',
    secondary: '#f50057',
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/themes`,
          { site_id: siteId }
        );

        const { content } = response;

        if (content) {
          const {
            id,
            appbar_background,
            appbar_text,
            content_background,
            footer_background,
            footer_text,
            primary,
            product_card_background,
            product_card_text,
            secondary,
          } = content;

          setColorId(id);

          setColors({
            appbar_background,
            appbar_text,
            content_background,
            footer_background,
            footer_text,
            primary,
            product_card_background,
            product_card_text,
            secondary,
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    if (colorId) {
      await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/themes/${colorId}`,
        colors,
        'PUT'
      );
    } else {
      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/themes`,
        { site_id: siteId, ...colors },
        'POST'
      );

      setColors(prevState => ({ ...response.content, ...prevState }));
    }
  };

  const handleChangeColor = state => async color => {
    try {
      setColors(prevState => ({ ...prevState, [state]: color.hex }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container>
      <Grid item container xs={12} justify="flex-end">
        <Grid item>
          <Box pb={3} pr={4}>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item container justify="center" xs={8}>
        <Grid item xs={8}>
          <Box
            height="600px"
            width="100%"
            border={`1px solid ${theme.palette.divider}`}
            borderRadius={theme.shape.borderRadius}
            bgcolor={colors.background}
          >
            <Box height="56px" width="100%" bgcolor={colors.appbar} p={2}>
              <Typography style={{ color: colors.typography }}>
                Preview
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={4}
        spacing={6}
        alignItems="center"
        direction="column"
      >
        <Grid item>
          {Object.keys(colors).map((k, i) => (
            <TabPanel value={value} index={i}>
              <ChromePicker
                color={colors[k]}
                onChangeComplete={handleChangeColor(k)}
                styles={{
                  default: {
                    picker: {
                      height: '158px',
                      width: '257px',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
          ))}
        </Grid>
        <Grid item></Grid>
        <Grid item>
          <List>
            {Object.keys(colors).map((k, i) => (
              <ListItem divider onClick={() => setValue(i)}>
                <ListItemAvatar>
                  <Box className={classes.avatar} bgcolor={colors[k]} />
                </ListItemAvatar>
                <ListItemText primary={locale[k]} secondary={colors[k]} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Colors;
