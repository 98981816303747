import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  /*rootOptions: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
  },*/

  step: {
    //paddingTop: '2rem',
    paddingBottom: '2rem'
  },
  rootContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
  },
}));