import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreatePage({ open, onSubmit, onClose }) {
  const emailEditorRef = useRef(null);

  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [path, setPath] = useState('');

  const onLoad = () => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const handleTitleChange = useCallback(
    event => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const handlePathChange = useCallback(
    event => {
      setPath(event.target.value);
    },
    [setPath]
  );

  const handleSubmit = useCallback(() => {
    emailEditorRef.current &&
      emailEditorRef.current.exportHtml(data => {
        const { design, html } = data;

        onSubmit({ title, path, html, design });
      });
  }, [title, path]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Criar Página
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSubmit}>
            salvar
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              label="Título"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Caminho"
              placeholder="Ex: quem-somos, duvidas-frequentes, formas-de-pagamento"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              
              value={path}
              onChange={handlePathChange}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              options={{
                locale: 'pt-BR',
              }}
              appearance={{
                theme: 'light',
                panels: {
                  tools: {
                    dock: 'right',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

CreatePage.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreatePage;
