import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  Slide,
  withStyles,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import ModalAddPessoa from '../../Pessoas/components/Pessoas/ModalAddPessoa';
import { CustomTable } from '../../../utils/components/index';
import {
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
} from '../actions/actions';
import { savePerson } from '../../Pessoas/actions/actionsPeople';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

function ModalPerfis(props) {
  const history = useHistory();
  const [modalAddPessoa, setModalAddPessoa] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullWidth="xl"
        maxWidth="xl"
      >
        <MuiDialogTitle onClose={props.onClose}>
          {!props.filtroSelecionado.nameList
            ? 'Perfis'
            : props.filtroSelecionado.nameList}
        </MuiDialogTitle>
        <DialogContent>
          <CustomTable
            isLoading={props.loadingClienteMapa}
            onRowClick={(columns, data) =>
              history.push(`/pessoas/${data.id_pessoa}`, data)
            }
            paging
            search
            columnsButton
            pageSize={7}
            actions={
              props.filtroSelecionado.nameList
                ? [
                    {
                      icon: () => (
                        <Icon size={16} className={clsx('fas fa-file-excel')} />
                      ),
                      disabled: !props.filtroSelecionado.idListProspectei,
                      tooltip: 'Exportar CVS ',
                      isFreeAction: true,
                      onClick: event =>
                        props.onExportCSV(
                          props.filtroSelecionado.idListProspectei
                        ),
                    },
                    {
                      icon: 'email',
                      tooltip: 'Exportar CSV Carta',
                      disabled: !props.filtroSelecionado.idListProspectei,
                      isFreeAction: true,
                      onClick: event =>
                        props.getListaFiltroCSV(
                          props.filtroSelecionado.idListProspectei
                        ),
                    },
                  ]
                : [
                    {
                      icon: 'add',
                      tooltip: 'Adicionar consumidor',
                      isFreeAction: true,
                      onClick: () => setModalAddPessoa(true),
                    },
                  ]
            }
            columns={[
              { title: '#ID', field: 'id_pessoa' },
              { title: 'Nome', field: 'nome' },
              { title: 'Celular', field: 'celular1' },
              { title: 'Email', field: 'email1' },
              { title: 'Documento', field: 'documento' },
              { title: 'Quantidade compras', field: 'qt_compras' },
              { title: 'Ticket médio', field: 'ticket_medio' },
              { title: 'Valor total', field: 'valor_total_gasto' },
              { title: 'Loja favorita', field: 'nome_loja' },
            ]}
            data={props.perfilCliente.mapa}
          />
        </DialogContent>
      </Dialog>
      {modalAddPessoa && (
        <ModalAddPessoa
          open={modalAddPessoa}
          onClose={() => setModalAddPessoa(false)}
          onSave={newPessoa => props.savePerson(newPessoa)}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ InsightsReducer }) => ({
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  savePerson,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPerfis);
