import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  getPersonProfile,
  getRetailByIdPerson,
  getRetailFamilyByIdPerson,
  getRetailConditionByIdPerson,
  getRetailGroupByIdPerson,
  getCamapanhaSMSByIdPerson,
  getRetencaoByIdPerson,
  getStatusByIdPerson,
  onSetSavePerson,
  putPerson
} from "../../actions/actionsPeople";
import {
  faShoppingCart,
  faCommentAlt,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Panel,
  Timeline,
  ChartPie,
  MyButton
} from "../../../../utils/components";
import ModalPessoa from "../Pessoas/ModalAddPessoa";
import DateCustom from "../../../../utils/helpers/Date";
import { CPF, RG, Telefone } from "../../../../utils/helpers/Masks";
import { MoedaBrasil } from "../../../../utils/helpers/Format";
import { Image } from "react-bootstrap";
import EnvioSMS from "./EnvioSMS";
import "./Perfil.css";
class Perfil extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };
  }
  componentDidMount() {
    this.props.getPersonProfile(this.props.match.params.id);
    this.props.getRetailByIdPerson(this.props.match.params.id);
    this.props.getRetailFamilyByIdPerson(this.props.match.params.id);
    this.props.getRetailConditionByIdPerson(this.props.match.params.id);
    this.props.getRetailGroupByIdPerson(this.props.match.params.id);
    this.props.getCamapanhaSMSByIdPerson(this.props.match.params.id);
    this.props.getRetencaoByIdPerson(this.props.match.params.id);
    this.props.getStatusByIdPerson(this.props.match.params.id);
  }

  render() {
    const {
      personProfile,
      listRetailPerson,
      listRetailFamilyPerson,
      listRetailConditionPerson,
      listRetailGroupPerson,
      listCampanhaSMSById,
      isLoadingPeople,
      retencaoPeople,
      statusPeople
    } = this.props;
    const { showModal } = this.state;
    return (
      <section className="containerPerfil">
        <Panel
          loading={isLoadingPeople}
          style={{ gridColumn: "1/5" }}
          title="Dados Pessoais"
        >
          {personProfile.map((person, key) => (
            <Fragment key={key}>
              <strong style={{ fontSize: "14pt" }}>{person.nome}</strong>
              <section className="imageProfile">
                <section style={{ display: "grid" }}>
                  <Image
                    style={{
                      height: "150px",
                      width: "150px"
                    }}
                    circle
                    src={
                      person.image_link ||
                      "https://s3-us-west-2.amazonaws.com/gestaosuper-images/user.png"
                    }
                  />
                  <MyButton
                    onClick={() =>
                      this.setState({ showModal: true }, () =>
                        this.props.onSetSavePerson(person)
                      )
                    }
                    bsSize="xs"
                  >
                    Editar
                  </MyButton>
                </section>
                <section className="dadosPessoais">
                  <section>
                    <strong>Idade: {person.age}</strong>
                    <p />
                    <strong>Aniversário: {person.birth_date}</strong>
                    <p />
                    <strong>CPF: {CPF(person.documento)}</strong>
                    <p />
                    <strong>RG: {RG(person.rg)}</strong>
                  </section>
                  <section>
                    <section style={{ display: "grid" }}>
                      {person.celulares ? (
                        <Fragment>
                          <strong>
                            Telefone: {Telefone(person.celulares.split(";")[0])}
                          </strong>
                          <EnvioSMS
                            idPerson={this.props.match.params.id}
                            number={
                              person.celulares && person.celulares.split(";")
                            }
                          />
                        </Fragment>
                      ) : (
                        <strong>Não possui telefone</strong>
                      )}
                    </section>

                    <p />
                    <section>
                      {person.email ? (
                        <strong>
                          Email: {Telefone(person.email.split(";")[0])}
                        </strong>
                      ) : (
                        <strong>Não possui email</strong>
                      )}
                    </section>

                    <p />
                    <strong>
                      <FontAwesomeIcon icon={faTachometerAlt} /> &nbsp;
                      {`${retencaoPeople.retencao} - ${
                        retencaoPeople.date !== "..."
                          ? new DateCustom(
                              retencaoPeople.date,
                              "DD/MM/YYYY"
                            ).getDate()
                          : retencaoPeople.date
                      }`}
                    </strong>
                    <p />
                    <strong>{statusPeople.status}</strong>
                  </section>
                  <section className="textGastos">
                    <span>Gasto em Compras</span>
                    <strong>
                      R$ {MoedaBrasil(this.props.dadoGastos.gastosTotal || 0)}
                    </strong>
                  </section>
                  <section className="textGastos">
                    <span>Quantidade de Compras</span>
                    <strong>{this.props.dadoGastos.qtdeTotal}</strong>
                  </section>
                  <section className="textGastos">
                    <span>Dia da Ultima Compra</span>
                    <strong>
                      {this.props.dadoGastos.ultimaData
                        ? new DateCustom(
                            this.props.dadoGastos.ultimaData.replace("Z", ""),
                            "DD/MM/YYYY"
                          ).getDate()
                        : "Nenhuma compra"}
                    </strong>
                  </section>
                </section>
              </section>
              <ModalPessoa
                open={showModal}
                onClose={() => this.setState({ showModal: false })}
                person={person}
                update
                onUpdatePerson={this.props.putPerson}
              />
            </Fragment>
          ))}
        </Panel>
        <Panel
          style={{ gridColumn: "1/5" }}
          title="Gráficos"
          loading={isLoadingPeople}
        >
          <section className="containerGraficos">
            <ChartPie
              width="260"
              height="200"
              heightCanvas="200"
              porcent
              data={listRetailFamilyPerson}
            />
            <ChartPie
              width="260"
              height="200"
              heightCanvas="200"
              porcent
              data={listRetailConditionPerson}
            />
            <ChartPie
              width="260"
              height="200"
              heightCanvas="200"
              porcent
              data={listRetailGroupPerson}
            />
          </section>
        </Panel>
        <Panel
          title="Timeline vendas"
          style={{ gridColumn: "1/3" }}
          loading={isLoadingPeople}
        >
          <Timeline
            data={listRetailPerson}
            title={row =>
              new DateCustom(row.data.replace("Z", ""), "DD/MM/YYYY").getDate()
            }
            subtitle={row => `${row.produto} - R$ ${MoedaBrasil(row.valor)}`}
            icon={<FontAwesomeIcon icon={faShoppingCart} />}
            dataFormat={row => (
              <section>
                <section className="timelineVendasTabelaHeader">
                  <strong>Grupo</strong>
                  <strong>Familia</strong>
                  <strong>Condição</strong>
                  <strong>Cor</strong>
                  <strong>Tamanho</strong>
                </section>
                <section className="timelineVendasTabelaDetalhes">
                  <span>{row.grupo}</span>
                  <span>{row.familia}</span>
                  <span>{row.condicao}</span>
                  <span>{row.cor}</span>
                  <span>{row.tamanho}</span>
                </section>
              </section>
            )}
            bubbleStyle={{ color: "rgb(111, 186, 28)" }}
          />
        </Panel>

        <Panel
          title="Timeline campanhas"
          style={{ gridColumn: "3/5" }}
          loading={isLoadingPeople}
        >
          <Timeline
            data={listCampanhaSMSById}
            title={row => new DateCustom(row.dataEnvio, "DD/MM/YYYY").getDate()}
            subtitle={row => `${row.nome_campanha} - ${Telefone(row.numero)}`}
            icon={<FontAwesomeIcon icon={faCommentAlt} />}
            dataFormat={row => (
              <section>
                <section className="timelineCampanhasTabelaHeader">
                  <strong>Mensagem</strong>
                  <strong>Status</strong>
                </section>
                <section className="timelineCampanhasTabelaDetalhes">
                  <span>{row.mensagem}</span>
                  <span>
                    {row.status_mensagem === "AGUARDANDO"
                      ? "ENVIADO"
                      : row.status_mensagem}
                  </span>
                </section>
              </section>
            )}
            bubbleStyle={{
              borderColor: "#2aabf2",
              background: "#E9F0F5",
              color: "#2aabf2"
            }}
          />
        </Panel>
      </section>
    );
  }
}

const mapDispatchToProps = {
  getPersonProfile,
  getRetailByIdPerson,
  getRetailFamilyByIdPerson,
  getRetailConditionByIdPerson,
  getRetailGroupByIdPerson,
  getCamapanhaSMSByIdPerson,
  getRetencaoByIdPerson,
  getStatusByIdPerson,
  onSetSavePerson,
  putPerson
};

const mapStateToProps = ({ PeopleReducer }) => ({ ...PeopleReducer });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Perfil);
