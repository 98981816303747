export const BASE_URL =
  'https://g9kl8ub30h.execute-api.us-east-1.amazonaws.com/prod';
export const URL_USERS = `${BASE_URL}/users`;
export const GET_USUARIOS = `GET_USUARIOS`;
export const POST_USUARIOS = `POST_USUARIOS`;
export const DELETE_USUARIOS = `POST_USUARIOS`;
export const URL_ROLES = `${BASE_URL}/roles`;
export const GET_ROLES = 'GET_ROLES';
export const CHANGE_STATUS_ROLE = 'CHANGE_STATUS_ROLE';
export const CHANGE_STATUS_SELECTED_ROLE = 'CHANGE_STATUS_SELECTED_ROLE';
export const SELECT_USER = 'SELECT_USER';
export const ALL_ROLES = 'ALL_ROLES';
export const RESET_USER = 'RESET_USER';
