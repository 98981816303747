import React, { useState } from 'react';
import { Box, /*IconButton,*/ Typography } from '@material-ui/core';
// import { ForwardRounded } from '@material-ui/icons';
// import { modalPessoa } from '../../../modules/Insights/actions/actions';
import { CPF, CEP } from '../../helpers/Masks';
import { Marker, InfoWindow } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

export default function MapClusterer(props) {
  const [openModal, setOpenModal] = useState(false),
    [marker, setMarker] = useState({});

  return (
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.isMarkerShown &&
        props.data.map(
          (data, index) =>
            !isNaN(parseFloat(data.lat)) &&
            !isNaN(parseFloat(data.lng)) && (
              <Marker
                key={index}
                onClick={() => {
                  setOpenModal(true);
                  setMarker(data);
                }}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
              >
                {openModal && marker.id_pessoa === data.id_pessoa && (
                  <InfoWindow onCloseClick={() => setOpenModal(false)}>
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        alignItems="center"
                        paddingY="5px"
                        paddingRight="12px"
                      >
                        <Typography variant="subtitle2">
                          {marker.nome}
                        </Typography>
                        &nbsp;
                        {/* <IconButton
                          size="small"
                          onClick={() => props.onModalPerson(true, marker)}
                        >
                          <ForwardRounded />
                        </IconButton> */}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        paddingY="5px"
                        paddingRight="12px"
                      >
                        <Typography variant="subtitle2">
                          Endereço:&nbsp;
                          {`${marker.endereco} nº${marker.numero ||
                            'S/N'} - ${marker.bairro || ''}, ${marker.cidade ||
                            ''}, ${marker.uf} ${CEP(marker.cep)} `}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        paddingY="5px"
                        paddingRight="12px"
                      >
                        <Typography variant="subtitle2">
                          CPF:&nbsp;{CPF(marker.documento)}
                        </Typography>
                      </Box>
                    </Box>
                  </InfoWindow>
                )}
              </Marker>
            )
        )}
    </MarkerClusterer>
  );
}
