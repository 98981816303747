import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Autocomplete, Button, Grid, IconButton, Menu, TextField } from '@mui/material';
import { Chip, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from 'utils/helpers/Constants';

import api from '../../../../../utils/helpers/Fetch';

const AutocompleteStylized = styled(Autocomplete)(({ theme }) => ({
  '& .MuiTextField-root': {
    padding: 0,
    borderColor: 'none',
    color: '#B0B0B0',
    width: '100%',
    margin: '0 0 24px 0'
  },

  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    margin: '5px 0',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.05em'
  },

  '& .MuiButtonBase-root, .MuiChip-root': {
    maxHeight: '25px'
  },

  '& .MuiChip-deleteIcon': {
    maxHeight: '20px'
  },

  '& .Mui-focused': {
    borderColor: 'none',
    background: '#FFF',
    marginBottom: '24px',
    zIndex: 4,
    maxHeight: 'auto'
  },

  '& .MuiFormLabel-root.Mui-focused': {
    zIndex: 5
  }
}));

const FilterTags = ({ tagsSelected, setTagsSelected, fetchTemplates }) => {
  const [tags, setTags] = useState([]);

  async function fetchTags() {
    try {
      await api(
        `https://1fqz7brbah.execute-api.us-east-1.amazonaws.com/prd/findAllTagsSelect?formatId=29`
      ).then(response => {
        setTags(response.content);
      });
      // await api(
      //   `https://api-backoffice.datasales.info/studio-${AMBIENTE}/findAllTagsSelect?formatId=29`
      // ).then(response => {
      //   setTags(response.content);
      // });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      <AutocompleteStylized
        value={tagsSelected}
        options={tags}
        getOptionValue={option => option}
        getOptionLabel={option => option}
        filterSelectedOptions={true}
        // limitTags={1}
        fullWidth
        multiple
        freeSolo
        onChange={(e, values: any) => {
          setTagsSelected(values);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
        }
        renderInput={params => (
          <TextField
            {...params}
            size={'small'}
            color="info"
            variant={'outlined'}
            label="Tags"
            placeholder="Selecione uma ou mais tags"
            InputProps={{
              ...params.InputProps,
              classes: {},
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => fetchTemplates()}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              )
            }}
          />
        )}
      />
    </>
  );
};

const ProductCard = ({
  product,
  productsSelected,
  setProductsSelected
}: {
  product: any;
  productsSelected: any;
  setProductsSelected: any;
}) => {
  const [productMouseOver, setProductMouseOver] = useState(false);

  const clickProduct = () => {
    setProductsSelected((prev: any) => {
      const tmp_array = [...prev];
      const tmp_index = tmp_array.findIndex((p: any) => p.id === product.id);

      if (tmp_index > -1) {
        tmp_array.splice(tmp_index, 1);
        return tmp_array;
      } else {
        return [...prev, product];
      }
    });
  };

  const yesProductsSelected = productsSelected.findIndex((el: any) => el.id === product.id) >= 0;

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setProductMouseOver(true)}
      onMouseLeave={() => setProductMouseOver(false)}
    >
      {(yesProductsSelected || productMouseOver) && (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            width: 30,
            height: 30,
            top: 8,
            left: 8,
            zIndex: 10,
            borderRadius: 4,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: yesProductsSelected ? '#2813AD' : 'white'
          }}
          onClick={() => clickProduct()}
        >
          {yesProductsSelected && <CheckIcon sx={{ color: 'white' }} />}
        </div>
      )}

      <div
        style={{
          padding: 8,
          ...(yesProductsSelected
            ? {
                // filter: 'opacity(0.5)',
                // backgroundColor: 'grey',
                borderRadius: 10,
                border: '2px solid #2813AD',
                opacity: 0.5
              }
            : {}),
          ...(!!productMouseOver
            ? {
                filter: 'brightness(0.5)',
                backgroundColor: 'white',
                borderRadius: 10
              }
            : {})
        }}
      >
        <img
          src={product.tag_personalized || product.image_url}
          crossOrigin="anonymous"
          alt=""
          title={product.name}
          style={{
            width: '100%',
            // height: 100,
            cursor: 'pointer'
          }}
          onClick={() => clickProduct()}
        />
        <div
          style={{
            // maxWidth: 80,
            textAlign: 'center',
            display: '-webkit-box',
            // @ts-ignore
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            'text-overflow': 'ellipsis'
          }}
        >
          {product.name}
        </div>
      </div>
    </div>
  );
};

const TenplateCard = ({
  template,
  setTemplateSelected
}: {
  template: any;
  setTemplateSelected: any;
}) => {
  const [templateMouseOver, setTemplateMouseOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <VisibilityIcon
        sx={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer', color: '#2813AD' }}
        onMouseEnter={(e: any) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <img
        src={template.thumbnail || template.imageURL}
        alt=""
        style={{
          width: '100%',
          cursor: 'pointer',
          padding: 8,
          ...(!!templateMouseOver
            ? {
                filter: 'brightness(0.5)',
                backgroundColor: 'white',
                borderRadius: 10
              }
            : {})
        }}
        onClick={() => {
          setTemplateSelected(template);
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <img
          src={template.thumbnail || template.imageURL}
          alt=""
          style={{
            width: '100%',
            cursor: 'pointer',
            borderRadius: 10,
            border: '2px solid #2813AD',
            padding: 12
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
          onClick={() => {
            setTemplateSelected(template);
          }}
        />
      </Menu>
    </div>
  );
};

function TagDialog({ onClose, products_list, set_products_list, setChangeTagPersonalized }: any) {
  const { enqueueSnackbar } = useSnackbar();

  const [actualStep, setActualStep] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [productsSelected, setProductsSelected] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productMouseOver, setProductMouseOver] = useState({});
  const [tagsSelected, setTagsSelected] = useState([]);

  async function fetchTemplates(params: any) {
    try {
      setLoadingTemplates(true);

      params = params
        ? `?${queryString.stringify({ ...params, tags: tagsSelected }, { arrayFormat: 'comma' })}`
        : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/templates${params}`).then(response => {
        setTemplates(response?.content);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTemplates(false);
    }
  }

  useEffect(() => {
    if (actualStep === 1) {
      fetchTemplates({ formats: [29] });
    }
  }, [actualStep]);

  const onCloseHere = () => {
    setActualStep(0);
    setProductsSelected([]);
    onClose();
  };

  async function handleSubmit() {
    try {
      setIsSubmitting(true);

      let template = templateSelected;
      let background = null;
      let products = productsSelected;
      let tag = null;

      let result = {
        outArray: []
      };

      let productsHere = [...products];

      let totalQuantity = template.productQuantity
        ? productsHere.length / template.productQuantity
        : 1;

      for (let k = 0; k < totalQuantity / 10; k++) {
        const tmp: { outArray: [] } = await api(
          'https://api.datasales.info/flyers-lambda-prd/processor',
          {
            // ...(values.isPreview ? { isBase64: 'json', pixelRatio: 0.8 } : {}),
            byGoogleCloud: true,
            // isPNG: values.isPreview ? false : !!values.isPNG,
            // offer_id: offerId,
            // templateId: template.id,
            // formatId: template.formatId,
            // tagId: tag ? tag.id : null,
            // tagURL: tag ? tag.imageURL : null,
            gerarSomenteJson: 'gerarJsonPuro',
            designURL: template.designURL,
            productQuantity: template.productQuantity,
            products: productsHere
              ?.slice(0, 10 * template.productQuantity)
              .map(({ fullData, ...rest }: { fullData: any }) => ({
                ...rest,
                dynamics: 'limpo',
                isOfertaSecreta: Boolean(fullData?.isOfertaSecreta),
                design_url: null,
                design_json_puro: null
              }))
            // backgroundURL: background ? background.imageURL : null,
            //
            // company_data,
            // colors: appliedColor
            //   ? {
            //       colorText,
            //       colorPrice
            //     }
            //   : {}
          },
          'POST',
          undefined,
          undefined,
          false
        );

        result.outArray.push(...tmp.outArray);

        productsHere.splice(0, 10 * template.productQuantity);
      }

      result.outArray.forEach((el, index) => {
        set_products_list((prev: any) => {
          const tmp_index = prev.findIndex((i: any) => i.id === productsSelected[index].id);
          let tmp_prev = prev;
          tmp_prev[tmp_index] = {
            ...tmp_prev[tmp_index],
            // design_url: el.design_url,
            // tag_personalized: el.image_url
            design_json_puro: { ...el.design_url, fonts: [] }
          };
          return tmp_prev;
        });
      });

      // enqueueSnackbar('Arte gerada com sucesso', { variant: 'success' });

      setIsSubmitting(false);
      setTemplateSelected(null);

      setChangeTagPersonalized((prev: number) => prev + 1);
      onCloseHere();
    } catch (err: any) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });

      setIsSubmitting(false);
      setTemplateSelected(null);
    }
  }

  useEffect(() => {
    if (templateSelected) {
      handleSubmit();
    }
  }, [templateSelected]);

  const [selecionarTodos, setSelecionarTodos] = useState(false);

  useEffect(() => {
    if (selecionarTodos) {
      setProductsSelected(products_list);
    } else {
      setProductsSelected([]);
    }
  }, [selecionarTodos]);

  return (
    <>
      {/* <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth={'md'}
      ></Dialog> */}
      <div>
        <>
          {actualStep === 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 24
                }}
              >
                Selecione os produtos
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    setActualStep(1);
                  }}
                >
                  Prosseguir
                </Button>
              </div>
              <div
                style={{
                  marginBottom: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                Selecionar todos
                <div
                  style={{
                    display: 'flex',
                    width: 30,
                    height: 30,
                    zIndex: 10,
                    borderRadius: 4,
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: selecionarTodos ? '#2813AD' : 'white',
                    border: '2px solid #2813AD',
                    marginLeft: 12
                  }}
                  onClick={() => setSelecionarTodos(prev => !prev)}
                >
                  {selecionarTodos && <CheckIcon sx={{ color: 'white' }} />}
                </div>
              </div>
              <Grid container spacing={2}>
                {products_list.map((product: any) => (
                  <Grid key={product.id} item xs={3}>
                    <ProductCard
                      product={product}
                      productsSelected={productsSelected}
                      setProductsSelected={setProductsSelected}
                    />
                  </Grid>
                ))}
              </Grid>
              {/* <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: 16
                }}
              >
                {products_list.map((product: any) => (
                  <div
                    style={{
                      padding: 8,
                      ...(productsSelected.findIndex((el: any) => el.id === product.id) >= 0
                        ? {
                            filter: 'brightness(0.7)',
                            backgroundColor: 'white',
                            borderRadius: 10
                          }
                        : {})
                    }}
                  >
                    <img
                      src={product.tag_personalized || product.image_url}
                      alt=""
                      style={{
                        height: 100,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setProductsSelected((prev: any) => {
                          const tmp_array = [...prev];
                          const tmp_index = tmp_array.findIndex((p: any) => p.id === product.id);

                          if (tmp_index > -1) {
                            tmp_array.splice(tmp_index, 1);
                            return tmp_array;
                          } else {
                            return [...prev, product];
                          }
                        });
                      }}
                    />
                    <div
                      style={{
                        maxWidth: 80,
                        textAlign: 'center',
                        display: '-webkit-box',
                        // @ts-ignore
                        '-webkit-line-clamp': '2',
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis'
                      }}
                    >
                      {product.name}
                    </div>
                  </div>
                ))}
              </div> */}
            </>
          )}

          {actualStep === 1 &&
            (loadingTemplates || isSubmitting ? (
              <>carregando...</>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 24,
                    marginBottom: 24
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                    <Button
                      // variant="contained"
                      color="info"
                      onClick={() => {
                        setActualStep(0);
                      }}
                    >
                      Voltar
                    </Button>
                    <div>Selecione um template</div>
                  </div>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      productsSelected.forEach((product: any) =>
                        set_products_list((prev: any) => {
                          const tmp_index = prev.findIndex((i: any) => i.id === product.id);
                          let tmp_prev = prev;
                          delete tmp_prev[tmp_index]?.design_url;
                          delete tmp_prev[tmp_index]?.design_json_puro;
                          delete tmp_prev[tmp_index]?.tag_personalized;
                          return tmp_prev;
                        })
                      );
                      setChangeTagPersonalized((prev: number) => prev + 1);
                      onCloseHere();
                    }}
                  >
                    Limpar
                  </Button>
                </div>

                <div>
                  <FilterTags
                    tagsSelected={tagsSelected}
                    setTagsSelected={setTagsSelected}
                    fetchTemplates={() => fetchTemplates({ formats: [29] })}
                  />
                </div>

                <Grid container spacing={2}>
                  {templates?.map((template: any) => (
                    <Grid key={template.id} item xs={3}>
                      <TenplateCard template={template} setTemplateSelected={setTemplateSelected} />
                    </Grid>
                  ))}
                </Grid>

                {/* <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: 16
                  }}
                >
                  <div>
                    <img
                      src={
                        'https://datasalesio-imagens.s3.amazonaws.com/limpar-template-fluxo-create-art.png?' +
                        Math.random()
                      }
                      alt=""
                      title="Limpar"
                      style={{ height: 200, cursor: 'pointer', padding: 32 }}
                      onClick={() => {
                        productsSelected.forEach((product: any) =>
                          set_products_list((prev: any) => {
                            const tmp_index = prev.findIndex((i: any) => i.id === product.id);
                            let tmp_prev = prev;
                            delete tmp_prev[tmp_index]?.design_url;
                            delete tmp_prev[tmp_index]?.design_json_puro;
                            delete tmp_prev[tmp_index]?.tag_personalized;
                            return tmp_prev;
                          })
                        );
                        setChangeTagPersonalized((prev: number) => prev + 1);
                        onCloseHere();
                      }}
                    />
                  </div>
                  {templates?.map((template: any) => (
                    <div>
                      <img
                        src={template.thumbnail || template.imageURL}
                        alt=""
                        style={{ height: 220, cursor: 'pointer' }}
                        onClick={() => {
                          // setActualStep(0);
                          setTemplateSelected(template);
                        }}
                      />
                    </div>
                  ))}
                </div> */}
              </>
            ))}
        </>
      </div>
    </>
  );
}

export default TagDialog;

