import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: { border: '2px solid #FFF' },
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: '2vh 1vw',
    width: '20vw',
    cursor: 'pointer',
  },
  typography: {
    fontSize: '12pt',
    color: '#FFF !important',
    fontWeight: 700,
  },
  typographySubtitle: {
    fontSize: '10pt',
    color: '#FFF !important',
  },
  typographyTitle: {
    fontSize: '17pt',
    color: '#FFF !important',
    fontWeight: 700,
    marginBottom: '2vh',
  },
}));

export default useStyles;
