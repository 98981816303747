import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const loadSubmenuFilho = () => dispatch =>
  dispatch({ type: "LOAD_SUBMENU_FILHO" });

export const getConfigSubmenuPai = () => dispatch => {
  dispatch(loadSubmenuFilho());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getConfigSubmenuPai`
  ).then(payload => dispatch({ type: "SUBMENU_PAI", payload }));
};

export const getConfigSubmenuFilho = idSubmenuPai => dispatch => {
  dispatch(loadSubmenuFilho());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getConfigSubmenuFilho`,
    { idSubmenuPai }
  ).then(payload => {
    dispatch({ type: "SUBMENU_FILHO", payload });
  });
};
