/* eslint-disable */
import {
  Button,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CustomTable,
  TextField,
} from '../../../utils/components';
import '../style/performance.css';
import { 
  getClusters,
  postCluster,
  setClusters,
  putCluster
} from '../action/action';
import {
  COLOR_PRIMARY,
} from '../../../utils/helpers/Constants';
import Alert from '../../../utils/helpers/Alert';

const alerta = new Alert();

class Cluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: 0,
      cluster: {
        descricao: "",
        descricao_marketing: "",
      },
    };
  }

  componentDidMount() {
    this.props.getClusters();
  } 

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Clusters
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 20,
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Adicionar Cluster
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ 
                  marginLeft: 10,
                }}>
                  <TextField
                    label="Descrição"
                    onChange={e => this.setState({ 
                        cluster: {
                          ...this.state.cluster,
                          descricao: e.target.value
                        }
                      })}
                    value={this.state.cluster.descricao}
                  />
                </div>
                <div style={{ 
                  marginLeft: 10,
                }}>
                  <TextField
                    label="Desc. Marketing"
                    onChange={e => this.setState({ 
                      cluster: {
                        ...this.state.cluster,
                        descricao_marketing: e.target.value
                      }
                    })}
                    value={this.state.cluster.descricao_marketing}
                  />
                </div> 
              </div>
            </div>
          </section>
          <div style={{ marginBottom: 20 }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              onClick={() => {
                if (this.state.cluster.descricao.trim() != "") {
                  this.props.postCluster({
                    ...this.state.cluster,
                    deletado: 0
                  }).then(() => {
                    this.setState({
                      cluster: {
                        descricao: "",
                        descricao_marketing: ""
                      }
                    })
                  })
                } else alerta.alertError("", "Campo Descrição vazio!")                
              }}
            >
              Adicionar
            </Button>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div
          className={classes.content}
        >
          <div className={classes.content}>
            <CustomTable
              data={this.props.clusters}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loadingClusters}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[                
                { 
                  title: 'Id', 
                  field: 'id', 
                  editable: 'never' 
                },
                { 
                  title: 'Descrição', 
                  field: 'descricao', 
                },
                { 
                  title: 'Desc. Marketing', 
                  field: 'descricao_marketing', 
                },
                {
                  title: "Ações",
                  field: "",
                  render: rowData => (
                    rowData && 
                    <Link to={`/subcluster/${rowData.id}_${rowData.descricao}`} style={{ marginTop: 15, textDecoration: 'none' }}>
                      <Button
                        size="large"
                        color="primary"
                      >
                        Abrir
                      </Button>
                    </Link>                    
                  ),
                }
              ]} 
              deleteText="Deseja desativar esse cluster?"
              editable={{
                // onRowAdd: newData =>
                //   new Promise(resolve => {
                //     this.props.postCluster({
                //       ...newData,
                //       deletado: 0
                //     }).then(() => {
                //       resolve();                       
                //     });
                //   }),                
                onRowUpdate: (newData, oldData) =>
                  new Promise(async resolve => {                    
                    this.props.putCluster(newData)
                      .then(() => {
                        let clusters = this.props.clusters;
                        const index = clusters.indexOf(oldData);
                        clusters[index] = newData;
                        this.props.setClusters(clusters);
                        this.setState({ forceUpdate: this.state.forceUpdate+1 });
                        resolve();
                      });                    
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props.putCluster({ ...oldData, deletado: 1 })
                      .then(() => {
                        let clusters = this.props.clusters;
                        const index = clusters.indexOf(oldData);
                        clusters.splice(index, 1);
                        this.props.setClusters(clusters);
                        this.setState({ forceUpdate: this.state.forceUpdate+1 });
                        resolve();
                      });                    
                  }),  
              }} 
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({
  ClusterReducer,
}) => ({
  ...ClusterReducer,
});

const mapDispatchToProps = {
  getClusters,
  postCluster,
  setClusters,
  putCluster
};

const ClusterConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Cluster);

export default withStyles(styles)(ClusterConnect);
