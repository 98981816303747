import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tab: {
    textTransform: 'none',
  },
  content: {
    paddingTop: theme.spacing(4),
  },
  preview: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
