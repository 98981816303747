import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionsChatbot from '../../actions';
import moment from 'moment';
import 'moment/locale/pt-br';

import PageWrapper from '../PageWrapper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import DatePicker from '../../../../utils/components/DatePicker';
import CalendarioDialog from '../Dialogs/CalendarioDialog';
import TabRelatorio from './TabRelatorio';
import Utils from '../../utils/Utils';

moment.locale('pt-br');

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const Calendario = props => {
  const { calendarios, instancias, totaisCalendarios, loadingChatbot } = props;
  const [calendarioFiltrado, setCalendarioFiltrado] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [calendarioOnDialog, setCalendarioOnDialog] = React.useState({});
  const [calendarioDialogOpen, setCalendarioDialogOpen] = React.useState(false);
  const [periodo, setPeriodo] = React.useState({
    dataInicio: moment().startOf('day').subtract(1, 'day'),
    dataFinal: moment().startOf('day').add(2, 'month'),
  });

  React.useEffect(() => {
    props.getInstancias();
    props.getCalendarios();
    handleCalendarioFiltrado();
  }, []);

  React.useEffect(() => {
    handleCalendarioFiltrado();
  }, [periodo]);

  const handlePeriodo = (name, value) => {
    setPeriodo({ ...periodo, [name]: value });
  };

  const toggleCalendarioDialog = calendario => {
    if (calendario) {
      setCalendarioOnDialog(calendario);
    } else {
      setCalendarioOnDialog({});
    }
    setCalendarioDialogOpen(!calendarioDialogOpen);
  };

  const salvarCalendario = calendario => {
    props.salvarCalendario(calendario);
    toggleCalendarioDialog();
  };

  const handleTab = (_, newTab) => {
    setActiveTab(newTab);
  };

  const handleCalendarioFiltrado = () => {
    const filtrados = calendarios.filter(
      calendario =>
        moment(calendario.data_final).isBetween(
          periodo.dataInicio,
          periodo.dataFinal,
          null,
          '[]'
        )
    );
    setCalendarioFiltrado([...filtrados]);
  };

  return (
    <PageWrapper
      title="Calendário"
      header={
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h6" noWrap>
              Filtrar por período
            </Typography>
          </Grid>
          <Grid item>
            <DatePicker
              label="De"
              style={{ marginRight: 15 }}
              withKeyboard
              format="DD/MM/YYYY"
              disableFuture
              value={periodo.dataInicio}
              onChange={date => handlePeriodo('dataInicio', date)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Até"
              withKeyboard
              format="DD/MM/YYYY"
              value={periodo.dataFinal}
              onChange={date => handlePeriodo('dataFinal', date)}
            />
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '60%',
            }}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              to="/chatbot"
            >
              Configurações
            </Button>
          </div>
        </Grid>
      }
      loading={loadingChatbot}
    >
      <Tabs
        value={activeTab}
        onChange={handleTab}
        aria-label="Tabs Calendário"
        indicatorColor="primary"
      >
        <Tab label="Calendário" />
        <Tab label="Relatório" />
      </Tabs>
      <Grid container direction="column" justify="center" alignItems="flex-end">
        <Grid item>
          <Box p={3}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => toggleCalendarioDialog()}
            >
              Novo calendário
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box pt={3} />
      <TabPanel value={activeTab} index={0}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          {!loadingChatbot &&
            calendarioFiltrado.map((calendario, index) => (
              <Grid key={index} item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item container>
                        <Typography component="h5" variant="h5" gutterBottom>
                          {`${calendario.id || ''} - ${
                            calendario.titulo || ''
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Divider />
                      </Grid>
                      <Grid item container direction="row">
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Loja</b>
                          </Typography>
                          <Typography variant="body1" color="primary">
                            <b>{calendario.instancia.nome}</b>
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Status</b>
                          </Typography>
                          <Chip
                            size="small"
                            variant="outlined"
                            color={calendario.ativo ? 'primary' : 'secondary'}
                            label={calendario.ativo ? 'Ativo' : 'Inativo'}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Palavra chave</b>
                          </Typography>
                          <Typography variant="body1" color="primary">
                            <b>{calendario.palavra_chave}</b>
                          </Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Data início</b>
                          </Typography>
                          <Typography variant="body1">
                            {Utils.formatData(calendario.data_inicio)}
                          </Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Typography variant="subtitle1" gutterBottom>
                            <b>Data da final</b>
                          </Typography>
                          <Typography variant="body1">
                            {Utils.formatData(calendario.data_final)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Box p={0.5}>
                          <Button
                            onClick={() => toggleCalendarioDialog(calendario)}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                          >
                            Editar
                          </Button>
                        </Box>
                        <Box p={0.5}>
                          <Button
                            onClick={() =>
                              props.ativarInativarCalendario(calendario)
                            }
                            size="medium"
                            variant="outlined"
                            color={calendario.ativo ? 'secondary' : 'primary'}
                            startIcon={
                              calendario.ativo ? <CloseIcon /> : <CheckIcon />
                            }
                          >
                            {calendario.ativo ? 'Inativar' : 'Ativar'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}

          {!loadingChatbot &&
            calendarios.length > 0 &&
            !calendarioFiltrado.length && (
              <Typography variant="subtitle1" align="center" gutterBottom>
                <b>
                  Nenhum calendário encontrado para o período informado. Tente
                  outro período
                </b>
              </Typography>
            )}
          {!loadingChatbot && !calendarios.length && (
            <Typography variant="subtitle1" align="center" gutterBottom>
              <b>Nenhum calendário cadastrado.</b>
            </Typography>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <TabRelatorio
          loading={loadingChatbot}
          totalGeral={totaisCalendarios}
          calendarios={calendarioFiltrado}
        />
      </TabPanel>
      <CalendarioDialog
        loading={loadingChatbot}
        open={calendarioDialogOpen}
        calendario={calendarioOnDialog}
        instancias={instancias}
        onClose={toggleCalendarioDialog}
        onSave={calendario => {
          salvarCalendario(calendario);
        }}
        onAddGatilho={({ calendario, gatilho }) => {
          props.novoGatilho(calendario.id, gatilho);
        }}
        onUpdateGatilho={gatilho => {
          props.updateGatilho(gatilho);
        }}
        onDelGatilho={gatilho => {
          props.deletarGatilho(gatilho);
        }}
      />
    </PageWrapper>
  );
};

const mapStateToProps = ({ ChatbotReducer }) => {
  return { ...ChatbotReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot })(Calendario);

