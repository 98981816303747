/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  Icon,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
  Divider,
} from '@material-ui/core';
import {
  Delete,
  CheckCircle,
  AccessTime,
  AttachMoney,
  Edit,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {
  Countup,
  CustomTable,
  MyButton,
  CountCard,
  Card,
} from '../../../utils/components';
import { CPF, Celular, CNPJ } from '../../../utils/helpers/Masks';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import {
  getExtratoCashback,
  getRetailByIdPerson,
  getCamapanhaSMSByIdPerson,
  onSetSavePerson,
  postCashback,
  putPerson,
  deletePeople,
  getPessoaNota,
  postPessoaNota,
  deletePessoaNota,
  putPessoaNota,
} from '../actions/actionsPeople';
import { listaLojas } from '../../Insights/actions/actions';
import ModalJustificativa from '../../Performance/components/ModalJustificativa';
import ModalEditPessoa from './Pessoas/ModalAddPessoa';
import ModalCashback from './Pessoas/ModalCashback';
import styles from '../styles/pessoa';
// Using Functions
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

function Pessoa(props) {
  const classes = styles(),
    location = useLocation(),
    params = useParams(),
    history = useHistory(),
    [modalCashback, setModalCashback] = useState(false),
    [modalJustificativa, setModalJustificativa] = useState(false),
    [modalEditPessoa, setModalEditPessoa] = useState(false),
    [justificativa, setJustificativa] = useState(''),
    [empresa, setEmpresa] = useState({}),
    [newValues, setNewValues] = useState({}),
    [cashback, setCashback] = useState({}),
    [pessoa, setPessoa] = useState({
      valor_total_gasto: 0,
      ticket_medio: 0,
      qt_compras: 0,
      saldo_cashback: 0,
      data_ultima_compra: '',
      nome: '',
    }),
    [actualTab, setActualTab] = useState(0),
    [cashbackStatement, setCashbackStatement] = useState(0),
    [openNewNote, setOpenNewNote] = useState(false),
    [pessoaNota, setPessoaNota] = useState({
      nota: '',
    }),
    [showNotaSelect, setShowNotaSelect] = useState(0),
    [notaType, setNotaType] = useState(0),
    [idNotadeletar, setIdNotadeletar] = useState();

  useEffect(() => {
    setEmpresa(JSON.parse(localStorage.getItem('company')));
    props.getRetailByIdPerson(params.id);
    props.getCamapanhaSMSByIdPerson(params.id);
    props.listaLojas();
    props.getPessoaNota({ id_pessoa: params.id });
  }, []);

  useEffect(() => {
    if (Boolean(pessoa.documento)) {
      props.getExtratoCashback(pessoa.documento);
    }
  }, [pessoa]);

  useEffect(() => {
    if (Boolean(location.state)) {
      setPessoa(location.state);
    }
  }, [location]);

  function executeAction(result) {
    switch (justificativa) {
      case 'del':
        props.deletePeople(
          { ...result, pessoa: pessoa.id_pessoa },
          props.filtroConsulta
        );
        setModalJustificativa(false);
        history.goBack();
        break;

      case 'edit':
        props.putPerson(
          {
            ...newValues,
            motivo: result.motivo,
            documentoMotivo: result.documento,
          },
          props.filtroConsulta
        );
        break;

      case 'cashback':
        const objCashback = {
          ...cashback,
          documento: pessoa.documento,
          comeca_valer: _myDate.getDate(
            'YYYY-MM-DD HH:mm',
            cashback.comeca_valer
          ),
          termina_valer: _myDate.getDate(
            'YYYY-MM-DD HH:mm',
            cashback.termina_valer
          ),
          tipo_cashback: Boolean(cashback.tipo_cashback)
            ? cashback.tipo_cashback.value
            : null,
          loja: Boolean(cashback.loja) ? cashback.loja.value : null,
          motivo: result.motivo,
          documentoMotivo: result.documento,
        };

        props.postCashback(objCashback);
        break;

      default:
        return;
    }
  }

  const handleClickOpen = () => {
    setPessoaNota({
      nota: '',
    });
    setOpenNewNote(true);
    setShowNotaSelect(1);
    setNotaType(1);
  };

  const handleDeleteClickOpen = data => {
    setIdNotadeletar(data.id);
    setPessoaNota({
      ...data,
    });
    setOpenNewNote(true);
    setShowNotaSelect(1);
    setNotaType(0);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Button
          onClick={() => history.goBack()}
          size="small"
          style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
          color="primary"
        >
          <Icon>arrow_back_ios</Icon>Voltar
        </Button>
      </Box>

      <Box marginBottom="20px">
        <Grid container spacing={5}>
          <Grid item xs>
            <Box
              marginBottom="20px"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Box width="100%">
                <Typography className={classes.typography}>
                  <b>{pessoa.nome}</b>
                </Typography>
              </Box>
              <Box width="100%">
                <Typography className={classes.typography}>
                  {CPF(pessoa.documento)}
                </Typography>
              </Box>
              <Box className={classes.actions}>
                <Box
                  display="flex"
                  marginTop="20px"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                    width="100%"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box>
                        <Button
                          onClick={() => setActualTab(0)}
                          className={
                            actualTab === 0 ? classes.activeButton : ''
                          }
                          color="primary"
                          size="large"
                        >
                          Visão geral
                        </Button>
                        <Button
                          onClick={() => setActualTab(1)}
                          className={
                            actualTab === 1 ? classes.activeButton : ''
                          }
                          color="primary"
                          size="large"
                        >
                          Notas
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => setModalCashback(true)}
                          color="primary"
                          size="large"
                          variant="text"
                          style={{
                            color: COLOR_PRIMARY,
                            marginRight: '1vw',
                          }}
                        >
                          <AttachMoney />
                          &nbsp;Cashback
                        </Button>
                        <Button
                          onClick={() => setModalEditPessoa(true)}
                          color="primary"
                          size="large"
                          variant="text"
                          style={{ color: COLOR_PRIMARY }}
                        >
                          <Edit />
                          &nbsp;Editar pessoa
                        </Button>
                        <IconButton color="primary" aria-label="close">
                          <Delete
                            onClick={() => {
                              setModalJustificativa(true);
                              setJustificativa('del');
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider className={classes.divider} />
            {actualTab === 0 && (
              <Fragment>
                <Alert
                  variant="filled"
                  severity="warning"
                  className={classes.alert}
                >
                  <b>ESTA É UMA INFORMAÇÃO CONFIDENCIAL </b>
                  <br />
                  Seu uso deve ser exclusivo da empresa{' '}
                  {String(empresa.name).toUpperCase()}, inscrita no CNPJ sob o
                  nº &nbsp;
                  {CNPJ(empresa.cnpj)}, e apenas para auxílio no processo de
                  relacionamento. A divulgação de quaisquer informações à
                  terceiros poderá ser punida de acordo com o previsto por lei.
                </Alert>
                <Box marginBottom="20px">
                  <Grid container spacing={5}>
                    <Grid item xs={3}>
                      <CountCard
                        count
                        decimals={2}
                        prefix="R$ "
                        titleTypographyProps={{ variant: 'h6' }}
                        end={pessoa.valor_total_gasto}
                        title="Valor em compras"
                        icon="people"
                        style={{ marginRight: 20 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={pessoa.qt_compras}
                        title="Quantidade em compras"
                        icon="people"
                        style={{ marginRight: 20 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CountCard
                        count
                        decimals={2}
                        prefix="R$ "
                        titleTypographyProps={{ variant: 'h6' }}
                        end={pessoa.saldo_cashback}
                        title="Cashback"
                        icon="people"
                        style={{ marginRight: 20 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CountCard
                        titleTypographyProps={{ variant: 'h6' }}
                        title="Última compra"
                        icon="people"
                        style={{ marginRight: 20 }}
                      >
                        {pessoa.data_ultima_compra
                          ? _myDate.getDate(
                              'DD/MM/YYYY HH:mm',
                              pessoa.data_ultima_compra.replace('Z', '')
                            )
                          : 'Não encontrado'}
                      </CountCard>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card
                      loading={props.isLoadingPeople}
                      title="Dados cadastrais"
                      showMore
                    >
                      <Grid container spacing={1} direction="column">
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>ID: </b>
                            {pessoa.id_pessoa}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>Nome: </b>
                            {pessoa.nome}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>Documento: </b>
                            {CPF(pessoa.documento)}
                          </Typography>
                        </Grid>
                        {Boolean(pessoa.data_nascimento) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Data de nascimento: </b>
                              {_myDate.getDate(
                                'DD/MM/YYYY',
                                pessoa.data_nascimento.replace('Z', '')
                              )}
                            </Typography>
                          </Grid>
                        )}
                        {Boolean(pessoa.celular1) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Celular: </b>
                              {Celular(pessoa.celular1)}
                            </Typography>
                          </Grid>
                        )}

                        {Boolean(pessoa.email1) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Email: </b>
                              {pessoa.email1}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>Endereço: </b>
                            {pessoa.endereco}
                            {Boolean(pessoa.bairro) ? `, ${pessoa.bairro}` : ''}
                            {Boolean(pessoa.cidade) ? `, ${pessoa.cidade}` : ''}
                            {Boolean(pessoa.uf) ? `, ${pessoa.uf}` : ''}
                            {Boolean(pessoa.cep) ? ` - ${pessoa.cep}` : ''}
                            {Boolean(pessoa.numero) ? `, ${pessoa.numero}` : ''}
                          </Typography>
                        </Grid>
                        {Boolean(pessoa.sexo) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Gênero: </b>
                              {pessoa.sexo === 'M'
                                ? 'Masculino'
                                : pessoa.sexo === 'F'
                                ? 'Feminino'
                                : 'Não informado'}
                            </Typography>
                          </Grid>
                        )}
                        {Boolean(pessoa.profissao) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Profissão: </b>
                              {pessoa.profissao}
                            </Typography>
                          </Grid>
                        )}
                        {Boolean(pessoa.data_insert) && (
                          <Grid item xs>
                            <Typography
                              className={classes.color}
                              variant="subtitle1"
                              gutterBottom
                            >
                              <b>Data de cadastro: </b>
                              {_myDate.getDate(
                                'DD/MM/YYYY HH:mm',
                                pessoa.data_insert.replace('Z', '')
                              )}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>Ticket médio: </b>
                            R$ {MoedaBrasil(pessoa.ticket_medio)}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography
                            className={classes.color}
                            variant="subtitle1"
                            gutterBottom
                          >
                            <b>Percentual de desconto: </b>%{' '}
                            {pessoa.percentual_desconto}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      loading={props.isLoadingPeople}
                      title="Cashback"
                      showMore
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={2}>
                          <Button
                            onClick={() => setCashbackStatement(0)}
                            className={
                              cashbackStatement === 0 && classes.activeButton
                            }
                            color="primary"
                            size="small"
                          >
                            Extrato
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            onClick={() => setCashbackStatement(1)}
                            className={
                              cashbackStatement === 1 && classes.activeButton
                            }
                            color="primary"
                            size="small"
                          >
                            Vencido
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTable
                            data={
                              cashbackStatement === 0
                                ? props.currentCashback
                                : props.expiredCashback
                            }
                            title=""
                            search={false}
                            toolbar={false}
                            pageSize={5}
                            paging
                            isLoading={props.isLoadingPeople}
                            style={{
                              border: 'none',
                              boxShadow: 'none',
                              width: '100%',
                              backgroundColor: 'transparent',
                              color: '#605a5a',
                            }}
                            headerStyle={{
                              backgroundColor: 'transparent',
                              zIndex: 0,
                              color: '#605a5a',
                            }}
                            columns={[
                              {
                                title: 'Cupom',
                                field: 'cupom',
                                render: row => (
                                  <Typography noWrap variant="body2">
                                    {row.cupom}
                                  </Typography>
                                ),
                              },
                              {
                                title: 'Valor',
                                field: 'valor_cashback',
                                render: row => (
                                  <strong
                                    style={{
                                      color:
                                        row.tipo_cashback === 'VENCIDO'
                                          ? COLOR_PRIMARY
                                          : row.tipo_cashback === 'ENTRADA'
                                          ? '#43a047'
                                          : '#ff1744',
                                    }}
                                  >
                                    <Countup
                                      start={0}
                                      separator="."
                                      decimal=","
                                      decimals={2}
                                      prefix={
                                        row.tipo_cashback === 'ENTRADA'
                                          ? '+R$ '
                                          : 'R$ '
                                      }
                                      end={parseFloat(row.valor_cashback)}
                                    />
                                  </strong>
                                ),
                              },
                              {
                                title:
                                  cashbackStatement === 0
                                    ? 'Vence em'
                                    : 'Vencido em',
                                field: 'termina_valer',
                                render: row =>
                                  row.tipo_cashback !== 'SAIDA' && (
                                    <Typography noWrap variant="body2">
                                      {_myDate.getDate(
                                        'DD/MM/YYYY',
                                        row.termina_valer
                                      )}
                                    </Typography>
                                  ),
                              },
                              {
                                title: 'Processado em',
                                field: 'data_insert',
                                render: row => (
                                  <Typography noWrap variant="body2">
                                    {Boolean(row.processado) ? (
                                      _myDate.getDate(
                                        'DD/MM/YYYY',
                                        row.data_insert
                                      )
                                    ) : (
                                      <Tooltip
                                        title="A processar"
                                        placement="top"
                                      >
                                        <AccessTime
                                          style={{ color: COLOR_PRIMARY }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Typography>
                                ),
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      loading={props.isLoadingPeople}
                      title="Campanhas"
                      showMore
                    >
                      <CustomTable
                        title=""
                        search={false}
                        toolbar={false}
                        pageSize={5}
                        paging
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          width: '100%',
                          backgroundColor: 'transparent',
                          color: '#605a5a',
                        }}
                        headerStyle={{
                          backgroundColor: 'transparent',
                          zIndex: 0,
                          color: '#605a5a',
                        }}
                        isLoading={props.isLoadingPeople}
                        columns={[
                          { title: 'Campanha', field: 'nome_campanha' },
                          {
                            title: 'Data do disparo',
                            field: 'data_agendamento',
                          },
                          {
                            title: 'Ações',
                            field: 'data_agendamento',
                            render: row => (
                              <MyButton
                                onClick={() =>
                                  history.push(
                                    `/detalhes-campanha/${row.id_campanha_lista}_`
                                  )
                                }
                              >
                                Visualizar campanha
                              </MyButton>
                            ),
                          },
                        ]}
                        data={props.listCampanhaSMSById.map(campanha => ({
                          ...campanha,
                          data_agendamento: campanha.data_agendamento
                            ? _myDate.getDate(
                                'DD/MM/YYYY HH:mm',
                                campanha.data_agendamento.replace('Z', '')
                              )
                            : 'Nenhum resultado ',
                        }))}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      loading={props.isLoadingPeople}
                      title="Compras"
                      showMore
                    >
                      <CustomTable
                        title=""
                        search={false}
                        toolbar={false}
                        pageSize={5}
                        paging
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          width: '100%',
                          backgroundColor: 'transparent',
                          color: '#605a5a',
                        }}
                        headerStyle={{
                          backgroundColor: 'transparent',
                          zIndex: 0,
                          color: '#605a5a',
                        }}
                        isLoading={props.isLoadingPeople}
                        columns={[
                          { title: 'Produto', field: 'nome_produto' },
                          { title: 'Familia', field: 'desc_categoria_2' },
                          {
                            title: 'Departamento',
                            field: 'desc_categoria_3',
                          },
                          { title: 'Grupo', field: 'desc_categoria_4' },
                          {
                            title: 'Valor',
                            field: 'valor_unitario',
                            render: rowData => (
                              <span>
                                R${' '}
                                {MoedaBrasil(
                                  rowData.total || rowData.valor_unitario
                                )}
                              </span>
                            ),
                          },
                        ]}
                        data={props.listRetailPerson}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Fragment>
            )}
            {actualTab === 1 && (
              <Grid item xs={20}>
                <Card loading={props.isLoadingPeople} title="Notas">
                  <CustomTable
                    title={
                      <div>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          style={{
                            alignItems: 'self-end',
                            justifyContent: 'end',
                            position: 'absolute',
                            right: 10,
                            top: 5,
                          }}
                          onClick={handleClickOpen}
                        >
                          Criar nota
                        </Button>
                        {notaType === 1 ? (
                          <Dialog
                            fullWidth="true"
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            open={openNewNote}
                            onClose={() => {
                              setOpenNewNote(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle>Informações da Nota</DialogTitle>

                            <DialogContent dividers>
                              <Grid justify="auto">
                                <Grid>
                                  <textarea
                                    style={{ width: 550, height: 50 }}
                                    value={pessoaNota.nota}
                                    maxWidth={'xs'}
                                    fullWidth="true"
                                    placeholder=" Coloque a sua nota aqui!"
                                    onChange={e =>
                                      setPessoaNota({
                                        ...pessoaNota,
                                        nota: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setOpenNewNote(false);
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  if (showNotaSelect === 1) {
                                    //setShowNotaSelect(0);
                                    props.postPessoaNota({
                                      ...pessoaNota,
                                      id_pessoa: params.id,
                                    });
                                  }
                                  if (showNotaSelect === 0) {
                                    //setShowNotaSelect(1);
                                    props.putPessoaNota({
                                      ...pessoaNota,
                                    });
                                  }
                                  setOpenNewNote(false);
                                }}
                              >
                                Salvar
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : (
                          <Dialog
                            fullWidth="true"
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            open={openNewNote}
                            onClose={() => {
                              setOpenNewNote(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle>
                              Deseja mesmo deletar essa nota ?
                            </DialogTitle>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setOpenNewNote(false);
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  props.deletePessoaNota({
                                    id: pessoaNota.id,
                                    deletado: 1,
                                    id_pessoa: pessoaNota.id_pessoa,
                                  }),
                                    setOpenNewNote(false);
                                }}
                              >
                                Deletar
                              </Button>
                            </DialogActions>
                          </Dialog>
                        )}
                      </div>
                    }
                    search={false}
                    toolbar={false}
                    pageSize={5}
                    paging
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      width: '100%',
                      backgroundColor: 'transparent',
                      color: '#605a5a',
                    }}
                    headerStyle={{
                      backgroundColor: 'transparent',
                      zIndex: 0,
                      color: '#605a5a',
                    }}
                    isLoading={false}
                    columns={[
                      { title: 'id', field: 'id' },
                      {
                        title: 'Nota',
                        field: 'nota',
                      },
                      {
                        title: 'Data',
                        field: 'data_insert',
                      },
                      {
                        render: rowData => (
                          <Button
                            onClick={() => {
                              setPessoaNota({
                                ...rowData,
                              });
                              setShowNotaSelect(0);
                              setOpenNewNote(true);
                              setNotaType(1);
                            }}
                          >
                            <Edit />
                          </Button>
                        ),
                      },
                      {
                        render: rowData => (
                          <div>
                            <IconButton
                              color="secondary"
                              aria-label="close"
                              onClick={() => handleDeleteClickOpen(rowData)}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        ),
                      },

                      // {
                      //   title: 'Ações',
                      //   field: 'data_agendamento',
                      //   render: row => (
                      //     <MyButton
                      //       onClick={() =>
                      //         history.push(
                      //           `/detalhes-campanha/${row.id_campanha_lista}_`
                      //         )
                      //       }
                      //     >
                      //       Visualizar campanha
                      //     </MyButton>
                      //   ),
                      // },
                    ]}
                    data={props.pessoaNota.map(nota => ({
                      ...nota,
                      data_insert: nota.data_insert
                        ? _myDate.getDate('DD/MM/YYYY')
                        : 'Nenhum resultado ',
                    }))}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      {modalJustificativa && (
        <ModalJustificativa
          showModal={modalJustificativa}
          onClose={() => setModalJustificativa(false)}
          onSave={result => executeAction(result)}
        />
      )}
      {modalEditPessoa && (
        <ModalEditPessoa
          editable
          defaultPessoa={pessoa}
          open={modalEditPessoa}
          onSave={newPessoa => {
            setModalJustificativa(true);
            setJustificativa('edit');
            setNewValues(newPessoa);
            setPessoa(newPessoa);
          }}
          onClose={() => setModalEditPessoa(false)}
        />
      )}
      {modalCashback && (
        <ModalCashback
          lojas={props.listLojas}
          value={cashback}
          open={modalCashback}
          onChange={(state, value) =>
            setCashback({ ...cashback, [state]: value })
          }
          onClose={() => setModalCashback(false)}
          onSave={() => {
            setModalJustificativa(true);
            setJustificativa('cashback');
            setModalCashback(false);
          }}
        />
      )}
    </Box>
  );
}

const mapStateToProps = ({ PeopleReducer, InsightsReducer }) => ({
  ...PeopleReducer,
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getExtratoCashback,
  getRetailByIdPerson,
  getCamapanhaSMSByIdPerson,
  onSetSavePerson,
  postCashback,
  putPerson,
  deletePeople,
  listaLojas,
  getPessoaNota,
  postPessoaNota,
  deletePessoaNota,
  putPessoaNota,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pessoa);
