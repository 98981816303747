/* eslint-disable */
import { CircularProgress, IconButton, InputAdornment, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import '@webscopeio/react-textarea-autocomplete/style.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Modal, TextField } from '../../../../../utils/components';
import { getPesquisaSerpApi } from '../../../action/index';

String.prototype.removeDiacritics = function () {
  var diacritics = [
    [/[\300-\306]/g, 'A'],
    [/[\340-\346]/g, 'a'],
    [/[\310-\313]/g, 'E'],
    [/[\350-\353]/g, 'e'],
    [/[\314-\317]/g, 'I'],
    [/[\354-\357]/g, 'i'],
    [/[\322-\330]/g, 'O'],
    [/[\362-\370]/g, 'o'],
    [/[\331-\334]/g, 'U'],
    [/[\371-\374]/g, 'u'],
    [/[\321]/g, 'N'],
    [/[\361]/g, 'n'],
    [/[\307]/g, 'C'],
    [/[\347]/g, 'c']
  ];
  var s = this;
  for (var i = 0; i < diacritics.length; i++) {
    s = s.replace(diacritics[i][0], diacritics[i][1]);
  }
  return s;
};

class ModalSerpApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      images_results: [],
      texto_pesquisa: '',
      carregando: false
    };
  }

  componentDidMount() {
    this.setState({ texto_pesquisa: this.props.nome_produto });
    if (this.props.nome_produto) {
      this.setState({ carregando: true });
      this.props
        .getPesquisaSerpApi({
          query: this.props.nome_produto.removeDiacritics()
        })
        .then(result => {
          if (result) {
            this.setState({
              images_results: result.map(value => value.original)
            });
          }
          this.setState({ carregando: false });
        });
    }
  }

  onSearch = () => {
    if (this.state.texto_pesquisa) {
      this.setState({ carregando: true });
      this.props
        .getPesquisaSerpApi({
          query: this.state.texto_pesquisa.removeDiacritics()
        })
        .then(result => {
          if (result) {
            this.setState({
              images_results: result.map(value => value.original)
            });
          }
          this.setState({ carregando: false });
        });
    }
  };

  header(props, state) {
    return <Fragment>Banco de imagens</Fragment>;
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <div></div>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section
        style={{
          height: '100%'
        }}
      >
        <div
          style={{
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 800,
              justifyContent: 'center',
              margin: 'auto',
              flexWrap: 'wrap'
            }}
          >
            <TextField
              label="Pesquisa"
              value={this.state.texto_pesquisa}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  texto_pesquisa: e.target.value
                })
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.onSearch();
                }
              }}
              style={{ width: '100%', margin: 'auto', marginBottom: 40 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.onSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            ></TextField>
            {this.state.carregando ? (
              <CircularProgress></CircularProgress>
            ) : (
              this.state.images_results.map(
                (image, i) =>
                  i < 30 &&
                  !image.includes('phpThumb.php?') && (
                    <div
                      key={i}
                      className="tile"
                      onClick={() => this.props.onClickSelecionar(image)}
                    >
                      <img
                        src={image}
                        alt={'image' + i}
                        style={{
                          height: 130,
                          margin: 8,
                          cursor: 'pointer',
                          border: '1px solid #e7e7e7'
                        }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.remove();
                          // currentTarget.src =
                          //   'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/error-image-generic.png';
                        }}
                      />
                    </div>
                  )
              )
            )}
          </div>
        </div>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'md'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  gridList: {
    width: 500,
    height: 450
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});
const mapDispatchToProps = {
  getPesquisaSerpApi
};

const ModalSerpApiConnect = connect(mapStateToProps, mapDispatchToProps)(ModalSerpApi);

export default withStyles(styles)(ModalSerpApiConnect);

