import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';

const loadingClusters = () => dispatch =>
  dispatch({ type: 'LOADING_CLUSTERS' });
const loadingSubClusters = () => dispatch =>
  dispatch({ type: 'LOADING_SUBCLUSTERS' });
const loadingClusterDetalhe = () => dispatch =>
  dispatch({ type: 'LOADING_CLUSTER_DETALHE' });
const isLoadingProdutos = () => dispatch =>
  dispatch({ type: "LOADING_PRODUTOS" });  
const isLoadingProduto = () => dispatch =>
  dispatch({ type: "LOADING_PRODUTO" }); 

export const setClusters = payload => dispatch =>
  dispatch({ type: "SET_CLUSTERS", payload: payload }); 
export const setSubClusters = payload => dispatch =>
  dispatch({ type: "SET_SUBCLUSTERS", payload: payload }); 
export const setDetalheCluster = payload => dispatch =>
  dispatch({ type: "SET_DETALHE_CLUSTER", payload: payload }); 

export const getClusters = request => async dispatch => {
  dispatch(loadingClusters());
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/getClusters`,
      request
    );
    dispatch({
      type: 'GET_CLUSTERS',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSubClusters = request => async dispatch => {
  dispatch(loadingSubClusters());
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/getSubClusters`,
      request
    );
    dispatch({
      type: 'GET_SUBCLUSTERS',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClusterDetalhe = request => async dispatch => {
  dispatch(loadingClusterDetalhe());
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/getClusterDetalhe`,
      request
    );
    dispatch({
      type: 'GET_CLUSTER_DETALHE',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postCluster = request => async dispatch => {
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/postCluster`,
      request,
      'POST'
    );
    dispatch({
      type: 'ADD_CLUSTER',
      payload: response.cluster,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postSubCluster = request => async dispatch => {
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/postSubCluster`,
      request,
      'POST'
    );
    dispatch({
      type: 'ADD_SUBCLUSTER',
      payload: response.subcluster,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postClusterDetalhe = request => async dispatch => {
  try {
    const response = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/postDetalheCluster`,
      request,
      'POST'
    );
    dispatch({
      type: 'ADD_CLUSTER_DETALHE',
      payload: response.clusterDetalhe,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFamalias = () => async dispatch => {
  //   dispatch(isLoadingPeople());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // {
    //   idStores
    // }
  );
  dispatch({ type: "GET_FAMILIAS", payload });
};

export const getProdutosByFamilia = familia => async dispatch => {
  dispatch(isLoadingProdutos());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getProdutosByfamilia`,

    {
      familia
    }
  );
  dispatch({ type: "GET_PRODUTOS", payload });
};

export const getProduto = request => async dispatch => {
  try {
    dispatch(isLoadingProduto());
    const payload = await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/getProduto`,
      request
    );
    dispatch({ type: "GET_PRODUTO", payload });
  } catch (err) {
    console.log(err);
  }
};

export const putCluster = request => async dispatch => {
  try {
    await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/putCluster`,
      request,
      'PUT'
    );
  } catch (err) {
    console.log(err);
  }
};

export const putSubCluster = request => async dispatch => {
  try {
    await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/putSubCluster`,
      request,
      'PUT'
    );
  } catch (err) {
    console.log(err);
  }
};

export const putDetalheCluster = request => async dispatch => {
  try {
    await Fetch(
      `https://52zwzihch7.execute-api.us-east-1.amazonaws.com/dev/crm/cluster/putDetalheCluster`,
      request,
      'PUT'
    );
  } catch (err) {
    console.log(err);
  }
};
