import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Tabs,
  Tab,
  Typography,
  TextField,
  Fade,
} from '@material-ui/core';

import TabPanel from '../TabPanel';
import InfoIcon from '../../../../assets/icons/marketing/Info.svg';
import useStyles from '../../styles';
import Overview from './Overview';
import SMS from './SMS';
import Email from './Email';
import OverviewPost from '../Overview/OverviewPost';
import { ReactComponent as HeartIcon } from '../../../../assets/icons/marketing/HeartIcon.svg';
import { ReactComponent as EyesIcon } from '../../../../assets/icons/marketing/EyesIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/marketing/PeopleIcon.svg';
import { ReactComponent as VectorIcon } from '../../../../assets/icons/marketing/VectorIcon.svg';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import FunilIcon from '../../../../assets/icons/marketing/Funil.svg';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import * as locales from 'react-date-range/dist/locale';
import { useHome } from '../../../../hooks/home';

const tabs = [
  { label: 'Visão geral', key: 0 },
  { label: 'SMS', key: 1 },
  { label: 'Email', key: 2 },
];

export default function Others(props) {
  const classes = useStyles(),
    { numberSMS } = useHome(),
    [currentTab, setCurrentTab] = useState(0);

  const [openFilter, setOpenFilterDefault] = useState(false);

  const setOpenFilter = () => {
    setOpenFilterDefault(prev => !prev);
    if (openFilter) {
      props.getCampaigns();
    }
  };

  function handleChangeTab(_, newValue) {
    setCurrentTab(newValue);
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" className={classes.subtitle}>
          Outros / Visão Geral
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<PeopleIcon />}
          value={numberSMS.total}
          text1="Total de envios"
        />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<HeartIcon />}
          value={numberSMS.clicks}
          text1="Cliques"
        />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<EyesIcon />}
          value={numberSMS.read}
          text1="Mensagens visualizadas"
        />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<VectorIcon />}
          value={numberSMS.received}
          text1="Mensagens recebidas"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography color="primary" className={classes.subtitle}>
            Métricas
          </Typography>
          <Button
            size="small"
            startIcon={<img src={InfoIcon} />}
            className={classes.btnKnowMore}
          >
            Saiba mais
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          indicatorColor="transparent"
          onChange={handleChangeTab}
        >
          {tabs.map(tab => (
            <Tab
              classes={{ wrapper: classes.tabWrapper }}
              key={tab.key}
              label={tab.label}
              disableTouchRipple
              style={{ paddingLeft: tab.key === 0 ? 0 : null }}
              className={
                currentTab === tab.key
                  ? classes.tabActiveUnderline
                  : classes.tabUnderline
              }
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item container justify="space-between">
        <Grid item container spacing={1} xs={4} alignItems="center">
          <Grid item>
            <TextField
              className={classes.margin}
              id="input-with-icon-search"
              label="Procurar campanha por nome"
              style={{ width: 280 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={props.campaignNameSearch}
              onChange={event => {
                props.setCampaignNameSearch(event.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  props.getCampaigns();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenFilter()}
              startIcon={<img src={FunilIcon} />}
              color="primary"
              size="large"
            >
              Filtrar
            </Button>
            <Fade in={openFilter}>
              <Box position="absolute" zIndex="1">
                <DateRange
                  rangeColors={'primary'}
                  locale={locales.ptBR}
                  onChange={({ selection }) => {
                    props.setStartDate(
                      moment(selection.startDate).format('YYYY-MM-DD')
                    );
                    props.setEndDate(
                      moment(selection.endDate).format('YYYY-MM-DD')
                    );
                  }}
                  showMonthAndYearPickers={false}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={false}
                  showDateDisplay={false}
                  ranges={[
                    {
                      startDate: moment(props.startDate).toDate(),
                      endDate: moment(props.endDate).toDate(),
                      key: 'selection',
                    },
                  ]}
                />
              </Box>
            </Fade>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={4}
          justify="flex-end"
          alignItems="center"
        ></Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel tab={currentTab} value={0}>
          <Overview />
        </TabPanel>
        <TabPanel tab={currentTab} value={1}>
          <SMS />
        </TabPanel>
        <TabPanel tab={currentTab} value={2}>
          <Email />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
