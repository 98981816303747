export const API_GET_UFs = `https://servicodados.ibge.gov.br/api/v1/localidades/estados`;
export const API_GET_DDDs = `https://ddd.pricez.com.br/ddds.json`;

export const LIST_UFs = "LIST_UFs";
export const LIST_DDDs = "LIST_DDDs";
export const LIST_MUNICIPIOS = "LIST_MUNICIPIOS";
export const LIST_BAIRROS = "LIST_BAIRROS";
export const LIST_SEGMENTOS = "LIST_SEGMENTOS";
export const LIST_CNPJ = "LIST_CNPJ";
export const COUNT_CNPJ = "COUNT_CNPJ";
export const LIST_CABECALHO_PROSPECTS = "LIST_CABECALHO_PROSPECTS";
export const LIST_DETALHE_PROSPECTS = "LIST_DETALHE_PROSPECTS";
export const LOADING_VENDA_MAIS = "LOADING_VENDA_MAIS";
export const LOADING_COUNT = "LOADING_COUNT";
export const DOWNLOAD_CSV = "DOWNLOAD_CSV";
export const GRAFICO_VENDA_MAIS = "GRAFICO_VENDA_MAIS";

export const LIST_EMPRESAS = [
  { value: "MICROEMPRESA", label: "Microempresa" },
  { value: "PEQUENA EMPRESA", label: "Pequeno Porte" },
  { value: "MEDIA EMPRESA", label: "Média Porte" },
  { value: "GRANDE EMPRESA", label: "Grande Porte" },
  { value: null, label: "Não Classificada" }
];
