/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  Slide,
  withStyles,
  IconButton,
  Typography,
  Divider,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { modalPessoa } from '../../Insights/actions/actions';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '../../../utils/components/index';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ModalEditarCampanha(props) {
  const {
    nome_campanha,
    data_inicio_campanha,
    data_fim_campanha,
    automacao,
  } = props.campanhaState;

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
        Editar Campanha
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Nome da Campanha"
            value={nome_campanha}
            onChange={event =>
              props.onChange('nome_campanha', event.target.value)
            }
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
            }}
          >
            <DatePicker
              label="De"
              style={{ marginRight: 15 }}
              withKeyboard
              value={data_inicio_campanha}
              format="DD/MM/YYYY"
              disableFuture
              onChange={date => props.onChange('data_inicio_campanha', date)}
            />

            <DatePicker
              label="Até"
              withKeyboard
              value={data_fim_campanha}
              format="DD/MM/YYYY"
              onChange={date => props.onChange('data_fim_campanha', date)}
            />
          </div>
          {Boolean(automacao) && (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={automacao}
                  onChange={e =>
                    props.onChange(
                      'automacao',
                      Boolean(e.target.checked) ? 1 : 0
                    )
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Ativar automação"
            />
          )}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" color="primary" onClick={props.onEditCampanha}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ InsightsReducer, PerformanceReducer }) => ({
  ...InsightsReducer,
  ...PerformanceReducer,
});

const mapDispatchToProps = { modalPessoa };

const ModalEditarCampanhaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEditarCampanha);

export default withStyles(styles)(ModalEditarCampanhaConnect);
