/* eslint-disable */
import moment from 'moment';

export default data => {
  const { loja, logo_url, data_inicio, data_fim, produtos } = data;
  const rows = [
    {
      cells: [1],
      columns: [
        {
          contents: [
            {
              type: 'image',
              values: {
                containerPadding: '0px',
                _meta: {
                  htmlID: 'u_content_image_8',
                  htmlClassNames: 'u_content_image',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                src: {
                  url: logo_url,
                  width: 368,
                  height: 167,
                },
                fullWidth: false,
                textAlign: 'center',
                maxWidth: '100%',
                altText: 'Image',
                action: { url: '', target: '' },
              },
            },
          ],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {},
            _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
          },
        },
      ],
      values: {
        columns: false,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: false,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: '0px',
        hideDesktop: false,
        hideMobile: false,
        noStackMobile: false,
        _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
        selectable: true,
        draggable: true,
        deletable: true,
      },
    },
    {
      cells: [1],
      columns: [
        {
          contents: [
            {
              type: 'text',
              values: {
                containerPadding: '10px',
                _meta: {
                  htmlID: 'u_content_text_32',
                  htmlClassNames: 'u_content_text',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                color: '#000000',
                textAlign: 'center',
                lineHeight: '140%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                text: `<p style="font-size: 14px; line-height: 140%;"><span style="color: #bd032a; font-family: 'Roboto Condensed', sans-serif; font-size: 20px; line-height: 28px;">Ofertas v&aacute;lidas de ${moment(
                  data_inicio
                ).format('DD/MM')} a ${moment(data_fim).format(
                  'DD/MM'
                )}</span><br style="box-sizing: border-box; color: #bd032a; font-family: 'Roboto Condensed', sans-serif; font-size: 15px;" /><span style="color: #bd032a; font-family: 'Roboto Condensed', sans-serif; font-size: 20px; line-height: 28px;">exclusivamente em ${loja}.</span><br style="box-sizing: border-box; color: #bd032a; font-family: 'Roboto Condensed', sans-serif; font-size: 15px;" /><span style="color: #bd032a; font-family: 'Roboto Condensed', sans-serif; font-size: 20px; line-height: 28px;">Imagens meramente ilustrativas.</span></p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>`,
              },
            },
          ],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {},
            _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
          },
        },
      ],
      values: {
        columns: false,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: '0px',
        hideDesktop: false,
        hideMobile: false,
        noStackMobile: false,
        _meta: { htmlID: 'u_row_12', htmlClassNames: 'u_row' },
        selectable: true,
        draggable: true,
        deletable: true,
      },
    },
  ];

  produtos.forEach((item, index) =>
    rows.push({
      cells: [1, 2, 1],
      columns: [
        {
          contents: [
            {
              type: 'image',
              values: {
                containerPadding: '0px 0px 0px 15px',
                _meta: {
                  htmlID: 'u_content_image_2',
                  htmlClassNames: 'u_content_image',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                src: {
                  url: item.imagem_produto
                    ? item.imagem_produto
                    : 'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png',
                  width: 150,
                  height: 150,
                },
                fullWidth: false,
                textAlign: 'center',
                maxWidth: '100%',
                altText: 'Image',
                action: { url: '', target: '' },
              },
            },
          ],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {
              borderTopWidth: '0px',
              borderTopStyle: 'dotted',
              borderTopColor: '#CCC',
              borderLeftWidth: '0px',
              borderLeftStyle: 'dotted',
              borderLeftColor: '#CCC',
              borderRightWidth: '0px',
              borderRightStyle: 'dotted',
              borderRightColor: '#CCC',
              borderBottomWidth: '0px',
              borderBottomStyle: 'dotted',
              borderBottomColor: '#CCC',
            },
            _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' },
          },
        },
        {
          contents: [
            {
              type: 'text',
              values: {
                containerPadding: '30px 4px 10px 10px',
                _meta: {
                  htmlID: 'u_content_text_6',
                  htmlClassNames: 'u_content_text',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                color: '#000000',
                textAlign: 'center',
                lineHeight: '140%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                text: `<p style="font-size: 14px; line-height: 140%;"><span style="font-family: arial, helvetica, sans-serif; font-size: 16px; line-height: 22.4px;"><strong><span style="line-height: 22.4px; font-size: 16px;">${item.nome_produto} - ${item.gramatura}</span></strong></span></p>`,
              },
            },
            {
              type: 'text',
              values: {
                containerPadding: '5px 5px 0px',
                _meta: {
                  htmlID: 'u_content_text_2',
                  htmlClassNames: 'u_content_text',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                color: '#000000',
                textAlign: 'center',
                lineHeight: '100%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                text: `<p style="font-size: 14px; line-height: 100%;"><span style="font-size: 14px; line-height: 14px;">de R$ ${item.preco}&nbsp;</span><strong style="font-size: 1rem; color: #bf0029;"><span style="line-height: 14px; font-size: 14px;">por R$ ${item.preco_dois}</span></strong></p>\n<p style="font-size: 14px; line-height: 100%;">&nbsp;</p>`,
              },
            },
          ],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {},
            _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' },
          },
        },
        {
          contents: [],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {},
            _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
          },
        },
        {
          contents: [
            {
              type: 'text',
              values: {
                containerPadding: '10px',
                _meta: {
                  htmlID: 'u_content_text_8',
                  htmlClassNames: 'u_content_text',
                },
                selectable: true,
                draggable: true,
                deletable: true,
                color: '#000000',
                textAlign: 'left',
                lineHeight: '140%',
                linkStyle: {
                  inherit: true,
                  linkColor: '#0000ee',
                  linkHoverColor: '#0000ee',
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                text: '<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>',
              },
            },
          ],
          values: {
            backgroundColor: '',
            padding: '0px',
            border: {},
            _meta: { htmlID: 'u_column_11', htmlClassNames: 'u_column' },
          },
        },
      ],
      values: {
        columns: false,
        backgroundColor: '',
        columnsBackgroundColor: '#ffffff',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: '0px',
        hideDesktop: false,
        hideMobile: false,
        noStackMobile: false,
        _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
        selectable: true,
        draggable: true,
        deletable: true,
      },
    })
  );

  return {
    counters: {
      u_column: 32,
      u_row: 10,
      u_content_image: 7,
      u_content_html: 4,
      u_content_text: 30,
      u_content_divider: 2,
    },
    body: {
      rows: rows,
      values: {
        backgroundColor: '#ffffff',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        contentWidth: '1000px',
        fontFamily: { label: 'Verdana', value: 'verdana,geneva' },
        linkStyle: {
          body: true,
          linkColor: '#0000ee',
          linkHoverColor: '#0000ee',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
      },
    },
  };
};
