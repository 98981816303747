/* eslint-disable */
import {
  Button,
  CircularProgress,
  Divider,
  Icon,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  Typography,
  withStyles
} from '@material-ui/core';
import '@webscopeio/react-textarea-autocomplete/style.css';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  Chip,
  Countup,
  CustomTable,
  DatePicker,
  DialogAlert,
  Modal,
  MyButton,
  Select,
  TextField
} from '../../../utils/components';
import { AMBIENTE, COLOR_PRIMARY, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import api from '../../../utils/helpers/Fetch';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import { listStore } from '../../Lojas/actions/action';
import {
  addProdutosOferta,
  clearPessoa,
  createCabecalhoOferta,
  createDetalheOferta,
  deleteProduto,
  getFamalias,
  getPessoaByCpf,
  getProdutosByFamilia,
  getProdutoSearch,
  onChangeState
} from '../actions/index';

class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: undefined,
      activeStep: 0,
      nomeOferta: '',
      listaSelecionada: null,
      media: null,
      lojas: null,
      dataInicio: moment(),
      dataFim: moment(),
      familia: '',
      idFamilia: '',
      nomeProduto: '',
      idProduto: '',
      preco: '',
      porcentagem: '',
      precoDesconto: '',
      pontos: '',
      cashback: '',
      produtos: [],
      familias: [],
      codigoCupom: '',
      valorMinimo: '',
      limiteUso: '',
      valorDesconto: '',
      tipoCupom: '',
      showDialog: false,
      showDeleteProduto: false,
      produto: {},
      cpf: null,
      pesquisa_produto: {
        ean: '',
        id_produto: '',
        resultado: []
      }
    };

    this.createCabecalhoOferta = this.createCabecalhoOferta.bind(this);
  }

  async componentDidMount() {
    try {
      this.props.listStore();
      const response = await api(`${DATASALES_CRM_API}providers-p1-${AMBIENTE}/campaigns`);

      this.setState({ campaigns: response });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.props.clearPessoa();
  }

  createCabecalhoOferta() {
    const { personalizada, tipoOferta, one2one } = this.props;
    const { nomeOferta, dataInicio, dataFim, media, lojas, listaSelecionada } = this.state;
    const objOferta = {
      idList: personalizada ? listaSelecionada.value : null,
      nomeOferta,
      media: media ? media.value : null,
      lojas: lojas ? lojas.map(loja => loja.value) : null,
      dataInicio,
      dataFim,
      tipoOferta,
      one2one: one2one ? 1 : null
    };
    this.props.createCabecalhoOferta(objOferta);
  }

  createDetalheOferta() {
    const { produtos, codigoCupom, valorMinimo, limiteUso, valorDesconto, tipoCupom } = this.state;
    const { tipoOferta, idOferta, personalizada, one2one } = this.props;
    if (this.props.idOferta !== null) {
      if (tipoOferta === 'desconto') {
        const objOferta = {
          idOferta: Array.isArray(idOferta) ? idOferta : [idOferta],
          personalizada: personalizada,
          one2one: one2one ? 1 : null
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipoOferta === 'cupom') {
        const objOferta = {
          idOferta: Array.isArray(idOferta) ? idOferta : [idOferta],
          tipoOferta,
          codigoCupom,
          valorMinimo,
          limiteUso,
          valorDesconto,
          tipoCupom,
          tipoOferta
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipoOferta === 'cashback') {
        const objOferta = {
          idOferta: Array.isArray(idOferta) ? idOferta : [idOferta],
          produtos,
          tipoOferta
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipoOferta === 'pontos') {
        const objOferta = {
          idOferta: Array.isArray(idOferta) ? idOferta : [idOferta],
          produtos,
          tipoOferta
        };

        this.props.createDetalheOferta(objOferta);
      }
    }
    this.props.onClose();
  }

  addProdutos() {
    const {
      familia,
      nomeProduto,
      idFamilia,
      idProduto,
      preco,
      porcentagem,
      precoDesconto,
      cashback,
      pontos,
      listaSelecionada
    } = this.state;
    const { tipoOferta, idOferta, personalizada, one2one, pessoa } = this.props;
    {
      if (familia && !nomeProduto) {
        this.setState({ showDialog: true });
      } else if (tipoOferta === 'desconto') {
        const atual = {
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          preco,
          porcentagem,
          precoDesconto
        };
        this.setState(
          {
            produtos: [],
            familia: '',
            nomeProduto: '',
            preco: '',
            porcentagem: '',
            precoDesconto: ''
          },
          () => {
            let objOferta = {
              idOferta: idOferta,
              idList: listaSelecionada ? listaSelecionada.value : null,
              produtos: [atual],
              tipoOferta,
              personalizada: personalizada,
              one2one: one2one ? 1 : null,
              nome_pessoa: pessoa ? pessoa.nome : null,
              documento: pessoa ? pessoa.documento : null,
              id_pessoa: pessoa ? pessoa.id_pessoa : null
            };

            this.props.addProdutosOferta(objOferta);
          }
        );
      } else if (tipoOferta === 'cashback') {
        const data = this.state.produtos;
        data.push({
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          preco,
          porcentagem,
          cashback
        });
        this.setState(
          {
            produtos: data,
            familia: '',
            nomeProduto: '',
            preco: '',
            porcentagem: '',
            cashback: ''
          },
          () => {
            () => {
              const objOferta = {
                idOferta: idOferta,
                idList: listaSelecionada ? listaSelecionada.value : null,
                produtos: [atual],
                tipoOferta,
                personalizada: personalizada
              };

              this.props.addProdutosOferta(objOferta);
            };
          }
        );
      } else if (tipoOferta === 'pontos') {
        const data = this.state.produtos;
        data.push({
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          pontos
        });
        this.setState(
          {
            produtos: data,
            familia: '',
            nomeProduto: '',
            pontos: ''
          },
          () => {
            const objOferta = {
              idOferta: idOferta,
              idList: listaSelecionada ? listaSelecionada.value : null,
              produtos: [atual],
              tipoOferta,
              personalizada: personalizada
            };
            this.props.addProdutosOferta(objOferta);
          }
        );
      }
    }
  }
  addFamilia() {
    const { produtos, tipoOferta } = this.props;

    if (tipoOferta === 'desconto') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          familia: prd.familia,
          nomeProduto: prd.label,
          preco: prd.preco,
          porcentagem: this.state.porcentagem,
          precoDesconto: this.calculaDescontoWithParams(this.state.porcentagem, prd.preco)
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: this.props.idOferta,
            produtos: this.state.produtos,
            tipoOferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    } else if (tipoOferta === 'cashback') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          nomeProduto: prd.label,
          familia: prd.familia,
          preco: prd.preco,
          porcentagem: this.state.porcentagem,
          cashback: this.calculaCashbackWithParams(this.state.porcentagem, prd.preco)
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: this.props.idOferta,
            produtos: this.state.produtos,
            tipoOferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    } else if (tipoOferta === 'pontos') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          familia: prd.familia,
          nomeProduto: prd.label,
          pontos: this.state.pontos
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: this.props.idOferta,
            produtos: this.state.produtos,
            tipoOferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    }
  }

  calculaDesconto() {
    const { porcentagem, preco } = this.state;
    if (porcentagem && preco) {
      const preco1 = parseFloat(preco);

      const porcentagem1 = parseFloat(porcentagem);

      const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));
      const total = parseFloat(preco1) - parseFloat(vdesconto);

      this.setState({ precoDesconto: total });
    }
  }

  calculaCashback() {
    const { porcentagem, preco } = this.state;

    if (porcentagem && preco) {
      const preco1 = parseFloat(preco);

      const porcentagem1 = parseFloat(porcentagem);

      const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));

      this.setState({ cashback: vdesconto });
    }
  }

  calculaCashbackWithParams(porcentagem, preco) {
    const preco1 = parseFloat(preco);

    const porcentagem1 = parseFloat(porcentagem);

    return parseFloat(preco1 * (porcentagem1 / 100));
  }

  calculaDescontoWithParams(porcentagem, preco) {
    const preco1 = parseFloat(preco);

    const porcentagem1 = parseFloat(porcentagem);

    const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));
    return parseFloat(preco1) - parseFloat(vdesconto);
  }

  header(props, state) {
    const { tipoOferta } = props;

    const steps = [
      'Informações de Oferta',
      tipoOferta === 'cupom' ? 'Informações do Cupom' : 'Produtos',
      'Iniciar'
    ];

    return (
      <Fragment>
        {this.props.tipoOferta.toUpperCase()}
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton disabled onClick={() => this.setState({ activeStep: index })}>
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return 'Salvar';
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return 'Proximo';
    }
  }

  footer(props, state) {
    const { idOferta, isLoadingOfertaCab } = props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {this.state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              !idOferta && !isLoadingOfertaCab
                ? this.createCabecalhoOferta()
                : this.setState({
                    activeStep: this.state.activeStep + 1
                  });
            }}
            disabled={
              this.props.personalizada && !this.state.listaSelecionada
                ? true
                : !this.state.nomeOferta
                ? true
                : this.state.activeStep === 2
                ? true
                : false
            }
          >
            {this.renderText()}
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { nomeOferta, datainicio, dataFim, listaSelecionada, lojas } = state;
    const { personalizada } = props;
    return (
      <section style={{ padding: '5rem 20rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Informações da Oferta
        </Typography>

        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          value={nomeOferta}
          onChange={event => this.setState({ nomeOferta: event.target.value })}
        />
        {personalizada && (
          <Select
            options={this.props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao
            }))}
            placeholder="Selecione uma Audiência..."
            onChange={value => this.setState({ listaSelecionada: value })}
            value={listaSelecionada}
          />
        )}
        <Select
          options={this.props.listStores.map(loja => ({
            value: loja.id_loja,
            label: loja.nome_loja
          }))}
          multiple
          label="Lojas participantes"
          onChange={value => this.setState({ lojas: value })}
          value={lojas}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <section>
            <DatePicker
              dateAndTime
              disableFuture
              label="Início da Oferta"
              onChange={date => this.setState({ datainicio: date })}
              value={datainicio}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              disablePast
              label="Término da Oferta"
              onChange={date => this.setState({ dataFim: date })}
              value={dataFim}
            />
          </section>
        </div>
      </section>
    );
  }
  step2(props, state) {
    const {
      preco,
      porcentagem,
      precoDesconto,
      pontos,
      cashback,
      codigoCupom,
      valorMinimo,
      limiteUso,
      valorDesconto,
      tipoCupom,
      cpf
    } = state;

    const {
      isLoadingProdutosLista,
      ofertaDetalhe,
      isLoadingEdit,
      tipoOferta,
      personalizada,
      one2one
    } = props;

    const columns = [
      {
        field: 'url',
        title: 'Produto',

        render: rowData =>
          rowData.url && (
            <img
              src={rowData.url}
              crossOrigin="anonymous"
              alt=""
              width={50}
              height={50}
              style={{ borderRadius: 10 }}
            />
          )
      },
      { title: 'id', field: 'idProduto', editable: 'never' },
      { title: 'Ean', field: 'SEQEAN', editable: 'never' },
      { title: 'Nome do Produto', field: 'nomeProduto' },
      { title: 'Familia', field: 'familia' },
      {
        title: 'Preço',
        field: 'preco',
        hidden: tipoOferta === 'pontos' ? true : false,
        render: rowData => (
          <Countup
            start={0}
            end={rowData.preco}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        )
      },
      {
        title: 'Porcentagem',
        field: 'porcentagem',
        hidden: tipoOferta === 'pontos' ? true : false,
        render: rowData => `${rowData.porcentagem}%`
      },
      {
        title: 'Preço dois',
        field: 'precoDesconto',
        hidden: tipoOferta === 'cashback' || tipoOferta === 'pontos' ? true : false,

        render: rowData => (
          <Countup
            start={0}
            end={rowData.precoDesconto}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        )
      },
      {
        title: 'Cashback',
        field: 'cashback',
        render: rowData => (
          <Countup
            start={0}
            end={rowData.cashback}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        ),
        hidden: tipoOferta === 'desconto' || tipoOferta === 'pontos' ? true : false
      },
      {
        title: 'Pontos',
        field: 'pontos',
        hidden: tipoOferta === 'desconto' || tipoOferta === 'cashback' ? true : false
      }
    ];
    (personalizada || one2one) &&
      columns.push({ title: 'Cliente', field: 'nome' }, { title: 'Documento', field: 'documento' });

    return tipoOferta === 'cupom' ? (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Cupom de Desconto
        </Typography>
        <TextField
          label="Código do Cupom"
          className="form-control"
          onChange={({ target }) => this.setState({ codigoCupom: target.value })}
          value={codigoCupom}
        />
        <TextField
          type="number"
          label="Valor mínimo de uso"
          className="form-control"
          onChange={({ target }) => this.setState({ valorMinimo: target.value })}
          value={valorMinimo}
        />
        <TextField
          type="number"
          label="Limite de Utilização do Cupom"
          className="form-control"
          onChange={({ target }) => this.setState({ limiteUso: target.value })}
          value={limiteUso}
        />
        <TextField
          type="number"
          label="Valor do Desconto"
          className="form-control"
          onChange={({ target }) => this.setState({ valorDesconto: target.value })}
          value={valorDesconto}
        />
        <section>
          <Chip
            active={tipoCupom === 'R$'}
            label="Reais (R$)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: 'R$' });
            }}
          />
          <Chip
            active={tipoCupom === '%'}
            label="Porcentagem (%)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: '%' });
            }}
          />
        </section>
      </section>
    ) : (
      <section
        style={{
          padding: '2rem 1rem 5rem 1rem',
          height: '100%'
        }}
      >
        {this.props.one2one && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
              marginTop: 24,
              marginBottom: 24
            }}
          >
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="CPF"
                value={cpf}
                shrink={true}
                disabled={false}
                onChange={e => this.setState({ cpf: e.target.value })}
              ></TextField>
            </div>
            <div>
              <Button
                size="large"
                variant="text"
                color="primary"
                disabled={cpf ? cpf.length === 0 : true}
                onClick={() => this.props.getPessoaByCpf(cpf)}
              >
                Buscar
              </Button>
            </div>
            <div style={{ marginLeft: 24 }}>
              {this.props.isLoadingPessoa ? (
                <span>Carregando...</span>
              ) : (
                <span>{this.props.pessoa && this.props.pessoa.nome}</span>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 20
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="EAN"
                shrink={true}
                onChange={e =>
                  this.setState({
                    pesquisa_produto: {
                      ...this.state.pesquisa_produto,
                      ean: e.target.value
                    }
                  })
                }
                value={this.state.pesquisa_produto.ean}
              />
            </div>
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="Id Produto"
                shrink={true}
                onChange={e =>
                  this.setState({
                    pesquisa_produto: {
                      ...this.state.pesquisa_produto,
                      id_produto: e.target.value
                    }
                  })
                }
                value={this.state.pesquisa_produto.id_produto}
              />
            </div>
            <div>
              <Button
                size="large"
                variant="text"
                color="primary"
                disabled={
                  this.state.pesquisa_produto.ean.trim() === '' &&
                  this.state.pesquisa_produto.id_produto.trim() === ''
                }
                onClick={() =>
                  this.props
                    .getProdutoSearch({
                      ean: this.state.pesquisa_produto.ean,
                      id: this.state.pesquisa_produto.id_produto
                    })
                    .then(() => {
                      this.setState({
                        nomeProduto: this.props.produto_search[0].PRODUTO,
                        preco: this.props.produto_search[0].PRECO,
                        idProduto: this.props.produto_search[0].SEQPRODUTO
                      });
                    })
                }
              >
                Buscar
              </Button>
            </div>
            <div
              style={{
                marginLeft: 24
              }}
            >
              {this.props.isLoadingProdutoSearch ? (
                <span>Carregando...</span>
              ) : (
                <span>
                  {this.props.produto_search.length > 0 && this.props.produto_search[0].PRODUTO}
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '75%',
              justifyContent: 'flex-start',
              marginTop: 24,
              marginBottom: 24
            }}
          >
            {tipoOferta === 'pontos' ? (
              <div style={{ width: '11%', marginRight: 10 }}>
                <TextField
                  label="Pontos"
                  value={pontos}
                  disabled={this.props.produto_search.length === 0}
                  shrink={true}
                  onChange={e =>
                    this.setState({ pontos: e.target.value }, () => {
                      this.calculaDesconto();
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress></CircularProgress>
                      </InputAdornment>
                    )
                  }}
                ></TextField>
              </div>
            ) : (
              <Fragment>
                <div style={{ width: '22%', marginRight: 10 }}>
                  <TextField
                    label="Preço"
                    value={preco}
                    shrink={true}
                    disabled={this.props.produto_search.length === 0}
                    onChange={e => this.setState({ preco: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>
                            <Icon>attach_money</Icon>
                          </div>
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                </div>
                <div style={{ width: '22%', marginRight: 10 }}>
                  <TextField
                    label="Porcentagem"
                    value={porcentagem}
                    disabled={this.props.produto_search.length === 0}
                    shrink={true}
                    onChange={e =>
                      this.setState({ porcentagem: e.target.value }, () =>
                        tipoOferta === 'cashback' ? this.calculaCashback() : this.calculaDesconto()
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div>
                            <strong style={{ fontSize: 18 }}>%</strong>
                          </div>
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                </div>
                <div style={{ width: '22%', marginRight: 10 }}>
                  {tipoOferta === 'cashback' ? (
                    <TextField
                      label="Cashback"
                      value={cashback ? MoedaBrasil(cashback) : cashback}
                      shrink={true}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div>
                              <Icon>attach_money</Icon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  ) : (
                    <TextField
                      label="Preço com desconto"
                      value={precoDesconto ? MoedaBrasil(precoDesconto) : precoDesconto}
                      shrink={true}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div>
                              <Icon>attach_money</Icon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          <div style={{ width: '11%' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              disabled={false}
              onClick={() => this.addProdutos()}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <CustomTable
          title=""
          paging
          isLoading={isLoadingProdutosLista || isLoadingEdit}
          pagesSize={5}
          actions={[
            {
              icon: () => <Icon color="error">delete</Icon>,
              tooltip: 'Excluir',
              onClick: (event, rowData) =>
                this.setState({
                  showDeleteProduto: true,
                  produto: {
                    id_oferta: rowData.id_oferta,
                    id_oferta_detalhe: rowData.id_oferta_detalhe,
                    id_oferta_personalizada: rowData.id_oferta_personalizada,
                    id_produto: rowData.idProduto
                  }
                })
            }
          ]}
          columns={columns}
          data={ofertaDetalhe}
        />
      </section>
    );
  }
  step3(props, state) {
    const { nomeOferta, datainicio, dataFim } = state;
    return (
      <section>
        <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
          <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
            Sobre a Oferta
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome da Oferta</strong>
              </p>
              <span>{nomeOferta}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(datainicio).format('DD/MM/YYYY HH:mm')} Até ${moment(
                dataFim
              ).format('DD/MM/YYYY HH:mm')}`}</span>
            </section>
          </section>
        </section>
        <Divider />
        <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
          <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton onClick={() => this.createDetalheOferta()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;
    const { activeStep, showDialog, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? 'xl' : 'lg'}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>

        <DialogAlert
          open={showDialog}
          title="Deseja dar desconto em todos produtos dessa familia?"
          onClick={() => this.setState({ showDialog: false }, () => this.addFamilia())}
          onClose={() => this.setState({ showDialog: false })}
        />

        <DialogAlert
          open={showDeleteProduto}
          title="Deseja remover este produto?"
          onClick={() =>
            this.setState({ showDeleteProduto: false }, () =>
              this.props.deleteProduto(this.state.produto)
            )
          }
          onClose={() => this.setState({ showDeleteProduto: false, produto: {} })}
        />
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({ OfertasReducer, CampaignReducer, InsightsReducer, LojasReducer }) => ({
  ...OfertasReducer,
  ...CampaignReducer,
  ...InsightsReducer,
  ...LojasReducer
});
const mapDispatchToProps = {
  onChangeState,
  getFamalias,
  getProdutosByFamilia,
  createCabecalhoOferta,
  createDetalheOferta,
  listStore,
  addProdutosOferta,
  deleteProduto,
  getPessoaByCpf,
  clearPessoa,
  getProdutoSearch
};

const NovaOfertaConnect = connect(mapStateToProps, mapDispatchToProps)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
