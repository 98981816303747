import { red } from '@material-ui/core/colors';
import { createTheme } from '@mui/material/styles';


const themeAdsDistribution = (classes: any) =>
  createTheme({
    palette: {
      primary: {
        main: '#2813AD'
        // main: '#127FFF'
      },
      secondary: red,
      divider: '#cccccc'
    },
    spacing: 8,
    shape: {
      borderRadius: 5
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none'
    ],
    typography: {
      fontFamily: 'Montserrat',
      button: {
        textTransform: 'none'
      }
    },
    components: {
      MuiTextField: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              border: 'none',
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#F5F5F5',
                border: 'none',
                '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent !important'
                }
              }
            }
          }
        ],
        defaultProps: {
          classes: {
            // root: classes.inputBackgroundColor,
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#127FFF',
            '&.Mui-checked': {
              color: '#127FFF'
            }
          }
        }
      },
      MuiTabs: {
        defaultProps: {
          classes: {
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: '#3B20E8',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#12017a'
              }
            }
          }
        ]
      }
    }
  });

export default themeAdsDistribution;