const INITIAL_STATE = {
  gatilhos: [],
  automacao: [],
  automacaoPersonalizada: [],
  loadingAutomacao: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_GATILHOS":
      return { ...state, gatilhos: action.payload };
    case "GET_AUTOMACAO":
      return { ...state, automacao: action.payload, loadingAutomacao: false };
    case "GET_AUTOMACAO_PERSONALIZADA":
      return {
        ...state,
        automacaoPersonalizada: action.payload,
        loadingAutomacao: false
      };
    case "LOADER_AUTOMACAO":
      return { ...state, loadingAutomacao: true };
    default:
      return state;
  }
};
