import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  MyButton,
  CustomTable,
  Countup,
} from '../../../utils/components';
import { getInfoCupom } from '../actions';
import { CPF } from '../../../utils/helpers/Masks';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

class ModalPerfis extends Component {
  componentDidMount() {
    const cupom = {
      id_oferta_cupom: this.props.id_oferta_cupom,
    };
    this.props.getInfoCupom(cupom);
  }
  header(props, state) {
    return (
      <strong>{`${props.id_oferta_cupom ? `#${props.id_oferta_cupom} - ` : ''}${
        props.nome_cupom
      }`}</strong>
    );
  }
  footer(props, state) {
    return (
      <MyButton
        size="large"
        color="primary"
        variant="text"
        onClick={props.onClose}
      >
        Fechar
      </MyButton>
    );
  }
  render() {
    const { open, onClose, infoCupom, isLoadingCupom } = this.props;

    return (
      <Modal
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
        showModal={open}
        onClose={onClose}
      >
        <CustomTable
          data={infoCupom}
          isLoading={isLoadingCupom}
          columns={[
            {
              field: 'nome_pessoa',
              title: 'Nome',
            },
            {
              title: 'Documento',
              field: 'documento',
              render: rowData => <span>{CPF(rowData.documento)}</span>,
            },
            {
              title: 'Quantidade de uso',
              field: 'qtde_uso',
              render: rowData => (
                <Countup
                  start={0}
                  end={parseInt(rowData.qtde_uso, 10)}
                  duration={5}
                />
              ),
            },
            {
              title: 'Data de uso',
              field: 'data_uso',
              render: rowData => (
                <span>
                  {rowData.data_uso ? _myDate.getDate(rowData.data_uso) : ''}
                </span>
              ),
            },
          ]}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ CupomReducer }) => ({ ...CupomReducer });

const mapDispatchToProps = { getInfoCupom };

export default connect(mapStateToProps, mapDispatchToProps)(ModalPerfis);
