import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

export default function SimpleSelect(props) {
  // const classes = useStyles();

  const [value, setValue] = React.useState(props.initial);

  const handleChange = event => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      // className={classes.formControl}
      style={{ ...props.style }}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {props.title}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={handleChange}
        label={props.title}
      >
        {props.list.map(item => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
