import { Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import Radio from './radio';

export default function DinamicaRadio(props) {
  const [list, setList] = useState([
    {
      value: null,
      label: 'Preço Único',
      //label1: 'De',
      label2: 'Preço'
    },
    {
      value: 'preco_unico',
      label: 'Preço Único',
      //label1: 'De',
      label2: 'Preço'
    },
    {
      value: 'de_por',
      label: 'De - Por',
      label1: 'De',
      label2: 'Por'
    },
    {
      value: 'varejo_atacado',
      label: 'Varejo - Atacado',
      label1: 'Varejo',
      label2: 'Atacado'
    },
    {
      value: 'pack_unitario',
      label: 'Pack - Unitário',
      label1: 'Pack',
      label2: 'Unitário'
    },
    {
      value: 'normal_clube',
      label: 'Normal - Clube',
      label1: 'Normal',
      label2: 'Clube'
    },
    {
      value: 'limpo',
      label: 'Dois preços',
      label1: 'Preço 1',
      label2: 'Preço 2'
    }
  ]);

  const [atual, setAtual] = useState(null);

  useEffect(() => {
    let tmp = list.find(itemList => itemList.value === props.initial);
    setAtual(tmp);
  }, []);

  return (
    atual && (
      <Fragment>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {/* <div
          style={{
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography component="h2">Dinâmica Comercial</Typography>
        </div> */}
          <Radio
            title={'Dinâmica Comercial'}
            initial={atual.value}
            list={list}
            onChange={item => {
              let tmp = list.find(itemList => itemList.value === item);
              setAtual({
                label1: tmp.label1,
                label2: tmp.label2
              });
              props.onChange(tmp.value);
            }}
            style={{ width: '50%' }}
          ></Radio>

          {props.children(atual.label1, atual.label2)}
        </div>
      </Fragment>
    )
  );
}

