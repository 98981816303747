import SmartToyIcon from '@mui/icons-material/SmartToy';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import { useCallback, useMemo } from 'react';
import { isLocalhostWithoutRelese, isReleaseEnvironment } from 'utils/helpers/Constants';

import useDate from '../../../../../../hooks/date';
import { Notification as TNotification } from '../../../../../../types/notification';
import useStyles from './styles';

type NotificationMenuProps = {
  onClose: () => void;
  anchorEl: any;
  notifications?: TNotification[];
};

const NotificationMenu = ({ anchorEl, notifications, onClose }: NotificationMenuProps) => {
  const { difference, getMoment } = useDate();
  const classes = useStyles();
  const theme: Theme = useTheme();

  const notifies = useMemo(() => {
    return notifications?.slice(0, 4);
  }, [notifications]);

  const token = useMemo(() => {
    return JSON.parse(localStorage.getItem('token')!);
  }, [localStorage.getItem('token')]);

  const handleGoToDsV3 = useCallback(
    (redirect: string) => {
      let hostNameV3 = '';
      isLocalhostWithoutRelese
        ? (hostNameV3 = 'market-dev')
        : isReleaseEnvironment
        ? (hostNameV3 = 'market-rls')
        : (hostNameV3 = 'market');
      const urlDsV3 = `https://${hostNameV3}.datasales.info/sign-as-user/${token}?shortToRedirect=${redirect}`;
      window.location.replace(urlDsV3);
    },
    [token]
  );

  const handleReadNotify = useCallback(
    (data: TNotification) => {
      handleGoToDsV3(`notification/${data.id}`);
      onClose();
    },
    [handleGoToDsV3, onClose]
  );

  const handleGoToNotify = useCallback(() => {
    handleGoToDsV3(`notification`);
    onClose();
  }, [handleGoToDsV3, onClose]);

  return (
    <Menu
      className={classes.menu}
      classes={{ paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      MenuListProps={{ sx: { py: 0 } }}
      PaperProps={{ sx: { borderRadius: '0.5rem' } }}
    >
      <nav aria-label="main mailbox folders">
        <MenuList disablePadding dense>
          {notifies?.map((data, index) => (
            <MenuItem
              onClick={() => {
                handleReadNotify(data);
              }}
              className={!Boolean(data.read) ? classes.unread : undefined}
              sx={{
                borderRadius:
                  index === 0
                    ? '0.5rem 0.5rem 0 0'
                    : notifies.length - 1 === index
                    ? '0 0 0 0'
                    : undefined
              }}
              key={data.id}
            >
              <Stack px={1} py={2} direction="row" spacing={2}>
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  color="primary"
                  variant="dot"
                  invisible={Boolean(data.read)}
                >
                  <Avatar
                    variant="rounded"
                    sx={{ backgroundColor: '#F7B303', width: 25, height: 25 }}
                  >
                    <SmartToyIcon fontSize="small" />
                  </Avatar>
                </Badge>
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontSize: '10pt' }} color="text.primary">
                      {data.title.length < 25 ? data.title : `${data.title.slice(0, 25)} ...`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: '7pt', whiteSpace: 'nowrap' }}
                      color="text.secondary"
                    >
                      {difference(getMoment('', data.sendingAt.replace('Z', '')))}
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: '8pt' }} color="text.secondary">
                    {`${data.description.slice(0, 40)} ...`}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
          <MenuItem
            onClick={handleGoToNotify}
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderRadius: '0 0 0.5rem 0.5rem',
              justifyContent: 'center'
            }}
          >
            <Stack px={1} py={1} direction="row">
              <Typography variant="button" color="primary">
                Ver todas as notificações
              </Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </nav>
    </Menu>
  );
};

export default NotificationMenu;

