import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Utils from "../../utils/Utils";

const DetalhesInstanciaDialog = ({ open, onClose, instancia }) => {
  if (!instancia.id) return "";

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        {`${instancia.id || ""} - ${instancia.nome || ""}`}
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item md={4}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Loja</b>
            </Typography>
            <Typography variant="body1">
              {instancia.loja && instancia.loja.nome_loja
                ? instancia.loja.nome_loja
                : "-"}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Status</b>
            </Typography>
            <Chip
              size="small"
              variant="outlined"
              color={instancia.status.connected ? "primary" : "secondary"}
              label={instancia.status.connected ? "Conectado" : "Desconectado"}
            />
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Data da criação</b>
            </Typography>
            <Typography variant="body1">
              {Utils.formatData(instancia.createdAt)}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Data da criação</b>
            </Typography>
            <Typography variant="body1">
              {Utils.formatData(instancia.updatedAt)}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Duração do chat</b>
            </Typography>
            <Typography variant="body1">
              {instancia.duracao_chat} Horas
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Mensagem inicial</b>
            </Typography>
            <Typography variant="body1">
              {instancia.mensagem_inicial}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Mensagem não entendi</b>
            </Typography>
            <Typography variant="body1">
              {instancia.mensagem_nao_entendi}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Mensagem finalização chat</b>
            </Typography>
            <Typography variant="body1">
              {instancia.mensagem_final_chat}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="outlined">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DetalhesInstanciaDialog.propTypes = {
  instancia: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

DetalhesInstanciaDialog.defaultProps = {
  instancia: {},
  open: false,
  onClose: () => {}
};

export default DetalhesInstanciaDialog;
