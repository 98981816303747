import { Box } from '@mui/material';

interface ITabelPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

export const TabPanelV2 = ({ children, value, index, ...other }: ITabelPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

