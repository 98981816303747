/* eslint-disable */
import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CachedIcon from '@mui/icons-material/Cached';
import '@webscopeio/react-textarea-autocomplete/style.css';
import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import refresh_icon from '../../../../assets/icons/icons/icon_refresh_removebg.png';
import { Modal, TextField } from '../../../../utils/components';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../utils/helpers/Functions';
import { cleanProductsForAdd, getProduct, getProductPhoto } from '../../action/index';
import Accordion from './Accordion';
import DinamicaRadio from './DinamicaRadio';
import ModalSearchProdutos from './ModalSearchProdutos/index';
import ModalSerpApi from './ModalSerpApi/index';

// ACHO QUE ESSA VARIAVEL NAO TA SENDO USADA POR CAUSA DO .this
const objProdutoReferencia = {
  imagem_produto: null,
  imagem_produto_original_link: null,
  imagem_produto_original_s3: null,
  ean: null,
  id_produto: null,
  nome_produto: '',
  preco_original: 0,
  preco_dois: 0,
  gramatura: null,
  categoria: null,
  observação: null,
  id_categoria: null,
  isOfertaSecreta: false,
  dinamica_comercial: 'preco_unico',
  var1: null,
  var2: null,
  var3: null,
  var4: null,
  var5: null,
  var6: null
};

class AdicionarProduto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      produto: this.props.produtoFoco
        ? {
            ...objProdutoReferencia,
            ...this.props.produtoFoco
          }
        : {
            ...objProdutoReferencia
          },
      search_photo: false,
      search_product_add: false,
      openModalSearchProdutos: false,
      openModalSerpApi: false,
      inputCategoria: '',
      openProdutoName: false,
      optionsProdutoName: [],
      loadingProdutoName: false,
      imagemPrincipal: [
        {
          imagem_produto: this.props.produtoFoco ? this.props.produtoFoco.imagem_produto : null,
          imagem_produto_original_link: this.props.produtoFoco
            ? this.props.produtoFoco.imagem_produto_original_link
            : null,
          imagem_produto_original_s3: this.props.produtoFoco
            ? this.props.produtoFoco.imagem_produto_original_s3
            : null
        }
      ],
      imagens:
        this.props.produtoFoco && this.props.produtoFoco.multProdutos
          ? [...this.props.produtoFoco.multProdutos]
          : [],
      //
      actualImage: 0,
      actualType: null
    };
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.produtoFoco) {
      //
    } else {
      this.setState({
        produto: {
          ...this.state.produto,
          id_categoria: this.props.categoriaPadrao
        }
      });
    }
  }

  header(props, state) {
    return <Fragment>Adicionar produto único</Fragment>;
  }

  salvarProdutoHandle(obj) {
    const LocalImagemPrincipal = this.state.imagemPrincipal;
    const actualImage = this.state.actualImage;
    if (obj?.refresh) {
      LocalImagemPrincipal[actualImage] = {
        ...LocalImagemPrincipal[actualImage],
        opcao_script_python_remocao_fundo: 1,
        imagem_produto:
          LocalImagemPrincipal[actualImage].imagem_produto_original_link ||
          LocalImagemPrincipal[actualImage].imagem_produto_original_s3,
        new: true
      };

      this.setState({
        LocalImagemPrincipal: [...LocalImagemPrincipal]
      });
    }
    !!this.state.produto.nome_produto && !!this.state.produto.preco_dois
      ? this.props.onSave({
          ...this.state.produto,
          imagens: [...this.state.imagens],
          imagemPrincipal: LocalImagemPrincipal
        })
      : window.alert('Campo "Produto" e "Por" obrigatórios! Por favor preencha.');
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <div></div>
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() => this.salvarProdutoHandle()}
          disabled={props.submiting}
        >
          {props.submiting ? 'Carregando...' : 'Salvar produto'}
        </Button>
      </div>
    );
  }

  carregarProdutoName = produtoName => {
    this.setState({ loadingProdutoName: true });
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      (async () => {
        const response = await Fetch(
          `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getProdutoNome`,
          { nome: produtoName, limit: 10 }
        );

        this.setState({
          optionsProdutoName: response.response,
          loadingProdutoName: false
        });
      })();
    }, 1000);
  };

  imageBloco = (cell, index, type) => (
    <div
      key={index}
      style={{
        display: 'flex',
        // display: 'none',
        flexDirection: 'column',
        // alignItems: 'center',
        marginBottom: 20
      }}
    >
      {/* <a href="https://photoroom.com" target="_blank" rel="noopener noreferrer">
        <img
          alt="photoroom_logo"
          src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/PhotoRoom_API_Attribution_Default_English.png"
          style={{ width: '90%', cursor: 'pointer' }}
        />
    </a>*/}
      {this.state.search_photo ? (
        <CircularProgress></CircularProgress>
      ) : (
        <div
          style={{
            width: '90%',
            backgroundColor: '#e7e7e7',
            borderRadius: '25px 25px 0px 0px'
          }}
        >
          <div style={{ position: 'relative' }}>
            <img
              alt="product"
              crossOrigin="anonymous"
              src={
                cell.imagem_produto +
                  (cell.imagem_produto && cell.imagem_produto.includes('http')
                    ? '?' + Math.random().toString()
                    : cell.imagem_produto
                    ? ''
                    : null) ||
                'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
              }
              style={{
                width: '100%',
                cursor: 'pointer'
              }}
            />

            {!cell?.imagem_produto?.includes('-removebg-') &&
              cell?.imagem_produto?.includes('datasalesio-imagens') && (
                <IconButton
                  style={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={() => this.salvarProdutoHandle({ refresh: true })}
                  disabled={this.props.submiting}
                >
                  <img src={refresh_icon} alt="refresh icon" />
                </IconButton>
              )}

            {type !== 'imagemPrincipal' && (
              <IconButton
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
                onClick={() => {
                  let tmpImagens = this.state[type];
                  tmpImagens.splice(index, 1);

                  this.setState({ [type]: [...tmpImagens] });
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          // marginTop: '2vh',
          width: '90%',
          // display: 'flex',
          // justifyContent: 'space-between',
          textAlign: 'center',
          backgroundColor: '#e7e7e7'
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id={'contained-button-file-' + index}
          type="file"
          onChange={async e => {
            const imageEdit = await toBase64(e.target.files[0]);

            let tmpImagens = this.state[type];
            tmpImagens[index] = {
              ...tmpImagens[index],
              imagem_produto: imageEdit.base64,
              new: true
            };

            this.setState({ [type]: [...tmpImagens] });
          }}
        />

        <ButtonGroup
          variant="contained"
          // color="primary"
          aria-label="contained primary button group"
        >
          <label htmlFor={'contained-button-file-' + index}>
            <Button
              // variant="contained"
              // color="primary"
              // size="small"
              // className={classes.button}
              startIcon={<GetAppIcon />}
              component="span"
            >
              Escolher
            </Button>
          </label>
          <label>
            <Button
              // variant="contained"
              // color="primary"
              // size="small"
              // className={classes.button}
              startIcon={<SearchIcon />}
              // style={{ marginLeft: 10 }}
              onClick={() =>
                this.setState({
                  openModalSerpApi: true,
                  actualType: type,
                  actualImage: index
                })
              }
            >
              Buscar
            </Button>
          </label>
        </ButtonGroup>
      </div>
    </div>
  );

  step1(props, state) {
    return (
      <section
        style={{
          padding: '0rem 10px 0rem',
          height: '304px'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex'
          }}
        >
          {/* BARRA LATERAL DE IMAGENS */}
          <div
            style={{
              // display: 'flex',
              // alignItems: 'center',
              width: '25%'
              // justifyContent: 'center', <<<<<
              // margin: 'auto',
              // marginTop: 24,
              // marginBottom: 24,
              // flexWrap: 'wrap',
            }}
          >
            {this.state.imagemPrincipal.map((cell, index) =>
              this.imageBloco(cell, index, 'imagemPrincipal')
            )}

            <Divider style={{ margin: '30px 20px 30px 0px' }} />

            {this.state.imagens.map((cell, index) => this.imageBloco(cell, index, 'imagens'))}

            <div
              style={{
                display: 'flex',
                width: '90%',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* {true && (
                <Button
                  style={{
                    margin: 5,
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  disableElevation
                  onClick={() => {
                    // let tmp = this.state.infiniteNumber;
                    // this.setState({ infiniteNumber: tmp - 1 });
                  }}
                >
                  <RemoveIcon fontSize="default" />
                </Button>
              )} */}
              {/* {true && (
                <Button
                  style={{
                    margin: 1,
                    cursor: 'pointer'
                  }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  disableElevation
                  onClick={() => {
                    let tmpImagens = this.state.imagens;
                    tmpImagens.push({
                      imagem_produto: null,
                      imagem_produto_original_link: null,
                      imagem_produto_original_s3: null
                    });
                    this.setState({ imagens: tmpImagens });
                  }}
                >
                  <AddIcon fontSize="default" />
                </Button>
              )} */}
            </div>
          </div>

          {/* DIV PRINCIPAL - DADOS DO PRODUTO */}
          <div
            style={{
              // display: 'flex',
              // alignItems: 'center',
              width: '75%',
              // justifyContent: 'center',
              // margin: 'auto',
              marginTop: '60px'
              // marginBottom: 24,
              // flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 20
              }}
            >
              {/* AGRUPAR SELECT POR LINK DE IMAGEM e id_empresa, PARA MOSTRAR imagens diversas */}
              <Autocomplete
                id="asynchronous-demo"
                style={{ width: '100%' }}
                open={this.state.openProdutoName}
                onOpen={() => {
                  this.setState({
                    openProdutoName: true
                  });
                }}
                onClose={() => {
                  this.setState({
                    openProdutoName: false,
                    optionsProdutoName: []
                  });
                }}
                getOptionLabel={option => option.nome_produto}
                options={this.state.optionsProdutoName}
                loading={this.state.loadingProdutoName}
                freeSolo
                value={this.state.produto.nome_produto}
                inputValue={this.state.produto.nome_produto}
                onChange={(event, newValue) => {
                  let tmpImagens = this.state['imagemPrincipal'];
                  tmpImagens[0] = {
                    ...tmpImagens[0],
                    imagem_produto: newValue ? newValue.imagem_produto : null,
                    imagem_produto_original_link: newValue
                      ? newValue.imagem_produto_original_link
                      : null,
                    imagem_produto_original_s3: newValue
                      ? newValue.imagem_produto_original_s3
                      : null
                  };

                  this.setState({
                    ['imagemPrincipal']: [...tmpImagens],
                    produto: {
                      ...this.state.produto,
                      nome_produto: newValue ? newValue.nome_produto : '',
                      preco_dois: newValue ? newValue.preco_sugerido : null
                    }
                  });
                }}
                renderOption={option => (
                  <React.Fragment>
                    <img
                      alt="product"
                      crossOrigin="anonymous"
                      src={
                        option.imagem_produto ||
                        'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                      }
                      style={{
                        width: 50,
                        backgroundColor: '#e7e7e7',
                        marginRight: 20
                      }}
                    />
                    {option.nome_produto}
                    {/* <span style={{ marginLeft: 20 }}>
                    <strong>{'De: '}</strong>
                    {option.preco_original}
                  </span> */}
                    <span style={{ marginLeft: 20 }}>
                      <strong>{'Preço sugerido: '}</strong>
                      {option.preco_dois}
                    </span>
                  </React.Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Produto"
                    variant="outlined"
                    onChange={ev => {
                      this.setState({
                        produto: {
                          ...this.state.produto,
                          nome_produto: ev.target.value
                        }
                      });
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== '' || ev.target.value !== null) {
                        this.carregarProdutoName(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingProdutoName ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </div>

            <DinamicaRadio
              initial={this.state.produto.dinamica_comercial}
              onChange={value =>
                this.setState(prev => ({
                  produto: {
                    ...prev.produto,
                    dinamica_comercial: value
                  }
                }))
              }
            >
              {(label1, label2) => (
                <Fragment>
                  {label1 && (
                    <TextField
                      label={label1}
                      value={
                        this.state.produto.preco_original
                          ? this.state.produto.preco_original.toFixed(2)
                          : this.state.produto.preco_original
                      }
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        this.setState({
                          produto: {
                            ...this.state.produto,
                            preco_original: !isNaN(parseFloat(e.target.value))
                              ? parseFloat(e.target.value.replace('.', '').replace(',', '')) / 100
                              : this.state.produto.preco_original || ''
                          }
                        });
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }}
                      style={{ width: '25%', marginLeft: 20 }}
                    />
                  )}

                  {label2 && (
                    <TextField
                      label={label2}
                      value={
                        this.state.produto.preco_dois
                          ? this.state.produto.preco_dois.toFixed(2)
                          : this.state.produto.preco_dois
                      }
                      shrink={true}
                      disabled={false}
                      onChange={e =>
                        this.setState({
                          produto: {
                            ...this.state.produto,
                            preco_dois: !isNaN(parseFloat(e.target.value))
                              ? parseFloat(e.target.value.replace('.', '').replace(',', '')) / 100
                              : this.state.produto.preco_dois || ''
                          }
                        })
                      }
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }}
                      style={{ width: label1 ? '25%' : '50%', marginLeft: 20 }}
                    />
                  )}
                </Fragment>
              )}
            </DinamicaRadio>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
                //marginBottom: 20,
              }}
            >
              <Accordion
                title={'Mais opções'}
                style={{
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginBottom: 20
                    }}
                  >
                    <TextField
                      label="Unidade"
                      value={this.state.produto.gramatura}
                      shrink={true}
                      disabled={false}
                      onChange={e =>
                        this.setState({
                          produto: {
                            ...this.state.produto,
                            gramatura: e.target.value
                          }
                        })
                      }
                      style={{ width: '30%' }}
                    />

                    <FormControl
                      variant="outlined"
                      //className={this.props.classes.formControl}
                      style={{ width: '66%' }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.produto.id_categoria}
                        onChange={event => {
                          this.setState({
                            produto: {
                              ...this.state.produto,
                              id_categoria: event.target.value
                            }
                          });
                        }}
                        label="Categoria"
                      >
                        <MenuItem value={null}>
                          <em>Nenhuma</em>
                        </MenuItem>
                        {props.categorias.map(item => (
                          <MenuItem value={item.id}>{item.nome_categoria}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <TextField
                    label="Observação"
                    value={this.state.produto.observacoes}
                    shrink={true}
                    disabled={false}
                    onChange={e =>
                      this.setState({
                        produto: {
                          ...this.state.produto,
                          observacoes: e.target.value
                        }
                      })
                    }
                    style={{ width: '100%' }}
                  />
                </div>
              </Accordion>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
                //marginBottom: 20,
              }}
            >
              <Accordion
                title={'Códigos'}
                style={{
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    label="EAN"
                    value={this.state.produto.ean}
                    shrink={true}
                    disabled={this.state.search_photo}
                    onChange={e =>
                      this.setState({
                        produto: {
                          ...this.state.produto,
                          ean: e.target.value
                        }
                      })
                    }
                    style={{ width: '50%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              this.setState({ search_photo: true });
                              this.props
                                .getProductPhoto({
                                  ean: this.state.produto.ean
                                })
                                .then(() => {
                                  this.setState({
                                    search_photo: false,
                                    produto: {
                                      ...this.state.produto,
                                      imagem_produto: this.props.imagem_add_produto
                                    }
                                  });
                                });
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <TextField
                    label="Código"
                    value={this.state.produto.id_produto}
                    shrink={true}
                    disabled={false}
                    onChange={e =>
                      this.setState({
                        produto: {
                          ...this.state.produto,
                          id_produto: e.target.value
                        }
                      })
                    }
                    style={{ width: '50%', marginLeft: 20 }}
                  />
                </div>
              </Accordion>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
                //marginBottom: 20,
              }}
            >
              <Accordion
                title={'Variáveis'}
                style={{
                  width: '100%'
                }}
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginBottom: 20
                    }}
                  >
                    <TextField
                      label="Variável 1"
                      value={this.state.produto.var1}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var1: value
                          }
                        }));
                      }}
                      style={{ width: '33%' }}
                    />

                    <TextField
                      label="Variável 2"
                      value={this.state.produto.var2}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var2: value
                          }
                        }));
                      }}
                      style={{ width: '33%', marginLeft: 20 }}
                    />

                    <TextField
                      label="Variável 3"
                      value={this.state.produto.var3}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var3: value
                          }
                        }));
                      }}
                      style={{ width: '33%', marginLeft: 20 }}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                      //marginBottom: 20,
                    }}
                  >
                    <TextField
                      label="Variável 4"
                      value={this.state.produto.var4}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var4: value
                          }
                        }));
                      }}
                      style={{ width: '33%' }}
                    />

                    <TextField
                      label="Variável 5"
                      value={this.state.produto.var5}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var5: value
                          }
                        }));
                      }}
                      style={{ width: '33%', marginLeft: 20 }}
                    />

                    <TextField
                      label="Variável 6"
                      value={this.state.produto.var6}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            var6: value
                          }
                        }));
                      }}
                      style={{ width: '33%', marginLeft: 20 }}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: 20
                    }}
                  >
                    <TextField
                      label="Categoria"
                      value={this.state.produto.categoria}
                      shrink={true}
                      disabled={false}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(prev => ({
                          produto: {
                            ...prev.produto,
                            categoria: value
                          }
                        }));
                      }}
                      style={{ width: '66%' }}
                    />
                  </div>
                </div>
              </Accordion>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: 20
              }}
            >
              <FormControlLabel
                //style={{ width: '50%' }}
                control={
                  <Checkbox
                    checked={this.state.produto.isOfertaSecreta}
                    onChange={event => {
                      this.setState({
                        produto: {
                          ...this.state.produto,
                          isOfertaSecreta: event.target.checked
                        }
                      });
                    }}
                    name="Oferta Secreta"
                    color="primary"
                  />
                }
                label="Oferta Secreta"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'md'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>

        {/* ACHO QUE ISSO NAO É MAIS USADO - openModalSearchProdutos */}
        {this.state.openModalSearchProdutos && (
          <ModalSearchProdutos
            open={this.state.openModalSearchProdutos}
            dataSearchProdutos={this.props.products_add}
            isLoadingSearchProdutos={this.state.search_product_add}
            onClose={() => {
              this.setState({ openModalSearchProdutos: false });
              this.props.cleanProductsForAdd();
            }}
            onClickSelecionar={obj => {
              this.setState({ openModalSearchProdutos: false });
              this.setState(
                {
                  produto: {
                    ...this.state.produto,
                    imagem_produto: obj.LINK_S3,
                    ean: obj.EAN,
                    nome_produto: obj.produto,
                    preco_original: 0,
                    preco_dois: 0,
                    gramatura: '',
                    categoria: ''
                  }
                },
                () => {
                  this.props.cleanProductsForAdd();
                }
              );
            }}
          ></ModalSearchProdutos>
        )}
        {this.state.openModalSerpApi && (
          <ModalSerpApi
            open={this.state.openModalSerpApi}
            onClose={() => {
              this.setState({ openModalSerpApi: false });
            }}
            nome_produto={this.state.produto.nome_produto}
            onClickSelecionar={obj => {
              let tmpImagens = this.state[this.state.actualType];
              tmpImagens[this.state.actualImage] = {
                ...tmpImagens[this.state.actualImage],
                imagem_produto: obj,
                new: true
              };

              this.setState({
                openModalSerpApi: false,
                [this.state.actualType]: [...tmpImagens]
              });
            }}
          ></ModalSerpApi>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  label: { marginBottom: theme.spacing(2) },
  gridList: {
    width: 500,
    height: 450
  },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});
const mapDispatchToProps = {
  getProductPhoto,
  getProduct,
  cleanProductsForAdd
};

const AdicionarProdutoConnect = connect(mapStateToProps, mapDispatchToProps)(AdicionarProduto);

export default withStyles(styles)(AdicionarProdutoConnect);
