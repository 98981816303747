import { Theme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) => ({

  gridGlobal: {
    borderRadius: '0.5rem',
    width: '52.19rem',
    background: '#F2F3F5',
    gap: '1rem',
    padding: '1rem',
  },
  gridSelect:{
    width: '100% !important',
    borderRadius: '0.5rem',
    display: 'flex !important',
    gap: '0.5rem',
    
  },
  buttonSetRepeatPost: {
    position: 'absolute',
    width: '1.5rem !important',
    height: '1.5rem !important',
    borderRadius: '4px !important',
    color: '#2813AD !important'
  },
  gridRepeatConfig:{
    display: 'flex',
    gap: '1rem !important',
  },
  typographyRepeatPost:{
    fontFamily: 'Montserrat !important',
    fontStyle: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    display: 'flex !important',
    alignItems: 'center !important',
  },
  buttonSelectRepeat:{
    width: '11.18rem !important',
  }

}));