import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;