import React, { Component } from "react";
import { connect } from "react-redux";
import {
  salvaConfigCashback,
  getConfigCashback,
  changeCashback
} from "../action/action";
import { Container, MyButton } from "../../../utils/components";
import "../style/styleConfigCashback.css";

class ConfigCashback extends Component {
  constructor() {
    super();
    this.state = {
      diasInicio: "0",
      diasValidade: "0"
    };
  }

  componentDidMount() {
    this.props.getConfigCashback();
  }

  salvarConfig() {
    const { configCashback } = this.props;
    const cashback = {
      id_cashback_configuracao: configCashback.id_cashback_configuracao,
      diasInicio:
        typeof configCashback.dias_inicio === "string"
          ? parseInt(configCashback.dias_inicio.replace(/\D/g, ""), 10)
          : configCashback.dias_inicio,
      diasValidade:
        typeof configCashback.dias_periodo === "string"
          ? parseInt(configCashback.dias_periodo.replace(/\D/g, ""), 10)
          : configCashback.dias_periodo
    };

    this.props.salvaConfigCashback(cashback);
  }

  render() {
    const { configCashback } = this.props;

    return (
      <Container loading={this.props.isLoadingCashback}>
        <section className="containerCashback">
          <section>
            <strong>Quantidade de dias que irá iniciar o cashback</strong>
            <input
              onChange={({ target: { value } }) =>
                this.props.changeCashback("dias_inicio", value)
              }
              className="form-control"
              type="number"
              value={configCashback.dias_inicio}
            />
          </section>
          <section className="containerButton">
            <section>
              <strong>Quantidade de dias de validade do cashback</strong>
              <input
                onChange={({ target: { value } }) =>
                  this.props.changeCashback("dias_periodo", value)
                }
                className="form-control"
                type="number"
                value={configCashback.dias_periodo}
              />
            </section>
            <MyButton bsstyle="success" onClick={() => this.salvarConfig()}>
              Salvar
            </MyButton>
          </section>
        </section>
      </Container>
    );
  }
}

const mapStateToProps = ({ ConfigCashbackReducer }) => ({
  ...ConfigCashbackReducer
});

const mapDispatchToProps = {
  salvaConfigCashback,
  getConfigCashback,
  changeCashback
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigCashback);
