import React, { Fragment } from 'react';
import { CircularProgress, Box, Backdrop } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Message,
  MessageImage,
  MessageAudio,
  MessageVideo,
  MessageFile,
  MessasgeLocation,
  MessageNotification,
} from './chat-elements/components';
import useStyles from '../styles';

export default function MessageList(props) {
  const classes = useStyles();
  function itemComponent(item) {
    switch (item.type) {
      case 'TEXT':
        return <Message key={item.message_id} item={item} />;
      case 'IMAGE':
        return (
          <MessageImage
            key={item.message_id}
            item={item}
            onClick={() => props.onClickMidia(item)}
          />
        );
      case 'STICKER':
        return <MessageImage key={item.message_id} item={item} sticker />;
      case 'LOCATION':
        return <MessasgeLocation key={item.message_id} item={item} />;
      case 'AUDIO':
        return (
          <MessageAudio
            key={item.message_id}
            avatar={!item.is_group && item.profile_thumbnail}
            item={item}
          />
        );
      case 'VIDEO':
        return (
          <MessageVideo
            key={item.message_id}
            item={item}
            onClick={() => props.onClickMidia(item)}
          />
        );
      case 'DOCUMENT':
        return (
          <MessageFile
            key={item.message_id}
            item={item}
            onClick={() => props.onClickMidia(item)}
          />
        );
      case 'NOTIFICATION':
        return <MessageNotification key={item.message_id} item={item} />;
      case 'DATE':
        return (
          <Box
            key={item.message_id}
            flex="0 0 auto"
            display="flex"
            marginTop="8px"
            marginLeft={'auto'}
            marginBottom="8px"
            marginRight={'auto'}
            justifyContent={'center'}
          >
            <Box
              color={'#000'}
              minWidth="0%"
              boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
              padding="10px"
              borderRadius="7.5px"
              bgcolor={'#B1DBDA'}
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              {item.date}
            </Box>
          </Box>
        );
      default:
        return <div key={item.message_id} />;
    }
  }

  function EndListChat() {
    return (
      <Box
        flex="0 0 auto"
        display="flex"
        marginTop="8px"
        marginLeft={'auto'}
        marginBottom="8px"
        marginRight={'auto'}
        justifyContent={'center'}
      >
        <Box
          color={'#000'}
          minWidth="0%"
          boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
          padding="10px"
          borderRadius="7.5px"
          bgcolor={'#FED8E2'}
          style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          Início do chat
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <div
        id="scrollableDiv"
        style={{
          height: '66vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          paddingBottom: '3vh',
        }}
      >
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={props.dataSource.length}
          next={props.next}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          inverse
          hasMore={props.hasMore}
          loader={
            <section className={classes.loadMessages}>
              <CircularProgress />
            </section>
          }
          endMessage={<EndListChat />}
        >
          {props.dataSource.map(item => itemComponent(item)).reverse()}
        </InfiniteScroll>
      </div>
    </Fragment>
  );
}
