import {
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  withStyles
} from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isLoading } from 'modules/Cupom/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import banner from '../../../assets/images/banner.png';
import LogoGoogle from '../../../assets/preload/logo-google.png';
import LogoIOS from '../../../assets/preload/logo-ios.png';
import LogoDsMkt from '../../../assets/preload/logo-marketing.png';
import { login, onChangeLogin } from '../actions/actionLogin';
import { Carousel } from './Carousel';

type LoginState = {
  user: string | null;
  pass: string | null;
  error: boolean;
  isBrowser: boolean;
  showPassword: boolean;
};

type LoginProps = {
  login: any;
  classes: {};
};

class Login extends Component<LoginProps, LoginState> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: null,
      pass: null,
      error: false,
      showPassword: false,
      isBrowser: false
    };
  }

  isOnResetRoute = (): boolean => {
    const pathname = this.props.history.location.pathname;
    const isOnTheResetRoute: boolean = pathname.substring(1, 6) === 'reset';
    const hasHash: boolean = typeof pathname[7] === 'string';

    return isOnTheResetRoute && hasHash;
  };

  isMiniPreco = window.location.hostname.includes('redeminipreco');
  // isMiniPreco = true;
  logoResult = (): any => {
    if (this.isMiniPreco) {
      return (
        <img
          width={100}
          alt=""
          src={'https://datasalesio-imagens.s3.amazonaws.com/LogoMiniPreco.png'}
        />
      );
    } else {
      return <img width={300} alt="" src={LogoDsMkt} />;
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  sendLogin = () => {
    if (this.props.loginReducer.isLoadingLogin) {
      alert('Por favor aguarde, você já tem um pedido de login em andamento.');
      return;
    }

    const { user, pass } = this.props.loginReducer;

    !user && !pass
      ? this.setState({ error: true })
      : this.setState({ error: false }, () => {
          this.props.login(this.props.loginReducer);
        });
  };

  render() {
    const { classes } = this.props;
    const { isLoadingLogin } = this.props.loginReducer;
    const { error } = this.state;

    if (
      window.screen.width <= 600 &&
      !this.state.isBrowser
    ) {
      return (
        <div className={classes.paper2}>
          <div style={{ marginTop: '9vh' }}>
            <img width={180} alt="" src={LogoDsMkt} />
          </div>
          <img
            src={banner}
            style={{
              marginTop: '3vh',
              maxWidth: '90%'
            }}
            alt="banner com link que redireciona ao login"
          />
          <p
            style={{
              textAlign: 'center',
              marginTop: '2vh',

              maxWidth: '90%'
            }}
          >
            Para usar a Datasales no celular, baixe o aplicativo. Já disponível para Android e
            iPhone.
          </p>
          <div
            style={{
              flexDirection: 'row',
              marginInline: '10px',
              marginTop: '10px',
              textAlign: 'center',
              maxWidth: '100%'
            }}
          >
            <a href={'https://play.google.com/store/apps/details?id=com.datasales.dsmarketing'}>
              <img
                width={'47%'}
                alt=""
                src={LogoGoogle}
                style={{
                  marginRight: '10px'
                }}
              />
            </a>
            <a href={'https://apps.apple.com/br/app/ds-marketing/id1614322651'}>
              <img width={'47%'} alt="" src={LogoIOS} />
            </a>
          </div>

          <p
            style={{
              textAlign: 'center',
              fontSize: 18,
              marginTop: '1.4vh'
            }}
          >
            ou
          </p>

          <button
            style={{
              textAlign: 'center',
              marginTop: '0.6vh',
              marginBottom: '100px',
              borderWidth: 0
            }}
            onClick={() => {
              this.setState({ isBrowser: true });
            }}
          >
            <p
              style={{
                textAlign: 'center',
                fontSize: 19,
                textDecoration: 'underline',
                color: 'blue'
              }}
            >
              entre pelo navegador do celular
            </p>
          </button>
        </div>
      );
    }

    if (!this.isOnResetRoute()) {
      return (
        <div className={classes.root}>
          <CssBaseline />

          {/* <div className={classes.image}>
            <div className={classes.LoginMaskImg} />
          </div> */}
          {this.isMiniPreco ? (
            <div
              style={{
                minWidth: ' 60%',
                width: ' 60%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                style={{
                  maxWidth: '100%'
                }}
                alt="post-preview"
                src={
                  'https://storage.googleapis.com/datasalesio-imagens/login_carousel_minipreco_01.png'
                }
              />
            </div>
          ) : (
            <>
            {window.screen.width >= 600 && (<Carousel />)}
            </>
          )}

          <div className={classes.paper}>
            <div style={{ marginBottom: '2vh' }}>{this.logoResult()}</div>
            <div className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                color="primary"
                required
                fullWidth
                id="email"
                label="Email "
                name="email"
                autoComplete="email"
                autoFocus
                error={error}
                onChange={e => this.props.onChangeLogin('user', e.target.value)}
                onKeyPress={e => (e.which === 13 || e.keyCode === 13) && this.sendLogin()}
              />

              <TextField
                error={error}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="senha"
                label="Senha"
                type={this.state.showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onKeyPress={e => (e.which === 13 || e.keyCode === 13) && this.sendLogin()}
                onChange={e => this.props.onChangeLogin('pass', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onMouseDown={this.handleMouseDownPassword}
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.sendLogin()}
              >
                {isLoadingLogin ? <CircularProgress className={classes.progress} /> : 'Entrar'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <MaterialLink variant="body2" component={Link} to="recover" target="_self">
                    Esqueci minha senha
                  </MaterialLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const styles = theme => ({
  root: {
    display: 'flex'
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(7)
  },
  image: {
    width: ' 100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundImage: 'url(https://datasalesio-imagens.s3.amazonaws.com/tmp/capa_app.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  LoginMaskImg: {
    height: '100vh',
    backgroundColor: 'rgba(54, 50, 94, 0.2)'
  },
  paper: {
    width: '100%',
    height: '100vh',
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F2F3F5'
  },
  paper2: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F2F3F5'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  progress: {
    color: '#fff',
    '&:hover': {
      backgroundColor: '#312a5e'
    }
  }
});

const mapDispatchToProps = {
  onChangeLogin,
  login
};

const mapStateToProps = ({ loginReducer }) => ({ loginReducer });

const LoginConnect = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withStyles(styles)(LoginConnect);

