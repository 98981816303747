import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Radio,
} from '@material-ui/core';
import React from 'react';
import DialogTitle from '../../../../utils/components/DialogTitle';
//import { AMBIENTE } from '../../../../utils/helpers/Constants';

const useStyles = makeStyles(theme => ({
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  listItemSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

function createOptions({ open, type, onSubmit, onClose }) {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle onClose={onClose}>Selecione um tipo</DialogTitle>
      <DialogContent dividers>
        <List disablePadding>
          {/* <ListItem
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
            }}
            button
            selected={selectedValue === 'atual'}
            onClick={() => {
              setSelectedValue('atual');
            }}
          >
            <ListItemText
              primary="Editar Meus Modelos"
              //secondary="Publicação no Facebook, Instagram e WhatsApp"
            />
            <ListItemSecondaryAction>
              <Radio
                checked={selectedValue === 'atual'}
                onChange={handleChange}
                value="atual"
              />
            </ListItemSecondaryAction>
          </ListItem> */}

          <ListItem
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
            }}
            button
            selected={selectedValue === 'templates'}
            onClick={() => {
              setSelectedValue('templates');
            }}
            //disabled={AMBIENTE !== 'dev'}
          >
            <ListItemText
              primary="Editar Meus Modelos (Novo)"
              //secondary="Publicação no Facebook, Instagram e WhatsApp"
            />
            <ListItemSecondaryAction>
              <Radio
                checked={selectedValue === 'templates'}
                onChange={handleChange}
                value="templates"
                //disabled={AMBIENTE !== 'dev'}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
            }}
            button
            selected={selectedValue === 'backgrounds'}
            onClick={() => {
              setSelectedValue('backgrounds');
            }}
            //disabled={AMBIENTE !== 'dev'}
          >
            <ListItemText
              primary="Editar Meus Fundos (Novo)"
              //secondary="Publicação no Facebook, Instagram e WhatsApp"
            />
            <ListItemSecondaryAction>
              <Radio
                checked={selectedValue === 'backgrounds'}
                onChange={handleChange}
                value="backgrounds"
                //disabled={AMBIENTE !== 'dev'}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!selectedValue}
          onClick={() => {
            onSubmit(selectedValue);
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default createOptions;
