import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import moment from 'moment';
import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import { ReactComponent as EyesIcon } from '../../../../assets/icons/marketing/EyesIcon.svg';
import FunilIcon from '../../../../assets/icons/marketing/Funil.svg';
import { ReactComponent as HeartIcon } from '../../../../assets/icons/marketing/HeartIcon.svg';
import InfoIcon from '../../../../assets/icons/marketing/Info.svg';
import InstagramOriginalIcon from '../../../../assets/icons/marketing/InstagramOriginal.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/marketing/PeopleIcon.svg';
import { ReactComponent as VectorIcon } from '../../../../assets/icons/marketing/VectorIcon.svg';
import { useHome } from '../../../../hooks/home';
import useStyles from '../../styles';
import PostGraphInstagram from '../GraphComponent/PostGraphInstagram';
import OverviewPost from '../Overview/OverviewPost';
import TabPanel from '../TabPanel';
import Posts from './Posts';

const tabs = [
  { label: 'Página do Instagram', key: 0 },
  { label: 'Posts no Instagram', key: 1 },
  { label: 'Carrossel', key: 2 }
];

export default function Instagram(props) {
  const classes = useStyles(),
    [currentTab, setCurrentTab] = useState(0);

  const [openFilter, setOpenFilterDefault] = useState(false);

  const setOpenFilter = () => {
    setOpenFilterDefault(prev => !prev);
    if (openFilter) {
      props.getCampaigns();
    }
  };

  function handleChangeTab(_, newValue) {
    setCurrentTab(newValue);
  }

  const {
    instagramLikesCounterData,
    instagramViewsCounterData,
    instagramEngajamentsCounterData,
    instagramImpressionsCounterData
  } = useHome();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" className={classes.subtitle}>
          <img src={InstagramOriginalIcon} className={classes.icon} /> Visão Geral do Instagram
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<PeopleIcon />}
          value={instagramViewsCounterData}
          text1="Contas alcançadas"
        />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost icon={<HeartIcon />} value={instagramLikesCounterData} text1="Curtidas" />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<EyesIcon />}
          value={instagramImpressionsCounterData}
          text1="Visualizações"
        />
      </Grid>
      <Grid item xs={3}>
        <OverviewPost
          icon={<VectorIcon />}
          value={instagramEngajamentsCounterData}
          text1="Engajamento"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography color="primary" className={classes.subtitle}>
            <img src={InstagramOriginalIcon} className={classes.icon} /> Métricas do Instagram
          </Typography>
          <Button size="small" startIcon={<img src={InfoIcon} />} className={classes.btnKnowMore}>
            Saiba mais
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={currentTab} indicatorColor="transparent" onChange={handleChangeTab}>
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              label={tab.label}
              disableTouchRipple
              style={{ paddingLeft: tab.key === 0 ? 0 : null }}
              className={currentTab === tab.key ? classes.tabActiveUnderline : classes.tabUnderline}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item container justify="space-between">
        <Grid item container spacing={1} xs={4} alignItems="center">
          <Grid item>
            <TextField
              className={classes.margin}
              id="input-with-icon-search"
              label="Procurar campanha por nome"
              style={{ width: 280 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              value={props.campaignNameSearch}
              onChange={event => {
                props.setCampaignNameSearch(event.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  props.getCampaigns();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenFilter()}
              startIcon={<img src={FunilIcon} />}
              color="primary"
              size="large"
            >
              Filtrar
            </Button>
            <Fade in={openFilter}>
              <Box position="absolute" zIndex="1">
                <DateRange
                  rangeColors={'primary'}
                  locale={locales.ptBR}
                  onChange={({ selection }) => {
                    props.setStartDate(moment(selection.startDate).format('YYYY-MM-DD'));
                    props.setEndDate(moment(selection.endDate).format('YYYY-MM-DD'));
                  }}
                  showMonthAndYearPickers={false}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={false}
                  showDateDisplay={false}
                  ranges={[
                    {
                      startDate: moment(props.startDate).toDate(),
                      endDate: moment(props.endDate).toDate(),
                      key: 'selection'
                    }
                  ]}
                />
              </Box>
            </Fade>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={4} justify="flex-end" alignItems="center"></Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel tab={currentTab} value={0}>
          <PostGraphInstagram />
        </TabPanel>
        <TabPanel tab={currentTab} value={1}>
          <Posts />
        </TabPanel>
        <TabPanel tab={currentTab} value={2}>
          <Posts isCarousel />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
