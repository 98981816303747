/* eslint-disable */
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import { ChromePicker } from 'react-color';
import {
  CustomTable,
  DatePicker,
  Dropzone,
} from '../../../../utils/components';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import MyDate from '../../../../utils/helpers/Date';
import Fetch from '../../../../utils/helpers/Fetch';
import {
  formatPrice,
  legendPositionBanner,
  legendDeviceBanner,
} from '../../../../utils/helpers/Format';
import { toBase64 } from '../../../../utils/helpers/Functions';
import Preview from '../Preview';
import UploadBanner from '../UploadBanner';
import AdicionarProduto from '../AdicionarProduto';
import useStyles from './styles';
import { postProdutoImagemTabloide, cleanProductsForAdd } from '../../action';
import { useEffect } from 'react';
import ModalSerpApi from '../AdicionarProduto/ModalSerpApi/index';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && children}
    </div>
  );
}

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const Date = new MyDate();

const steps = [
  'Informações da Propaganda',
  // 'Integração',
  // 'Banners',
  // 'Cores',
  // 'Arquivo',
  // 'Produtos',
];

const identificadores = [
  'EAN',
  'CODIGO',
  'PRODUTO',
  'DE',
  'POR',
  'GRAMATURA',
  'CATEGORIA',
  'OBSERVAÇÃO',
];

function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

function CreateTabloid(props) {
  const classes = useStyles();

  const theme = useTheme();

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [tabloidsBasic, setTabloidsBasic] = useState([]);
  const [tabloidBasic, setTabloidBasic] = useState(null);

  const [uploadBannerIsOpen, setOpen] = useState(false);
  const [openModalSerpApi, setOpenModalSerpApi] = useState(false);
  const [dadosModalSerpApi, setDadosModalSerpApi] = useState({});
  const [adicionarProdutoIsOpen, setOpenAdd] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [importar, setImportar] = useState(false);
  const [importarTexto, setImportarTexto] = useState(false);

  const [name, setName] = useState('Propaganda 1');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(Date.getDate('YYYY-MM-DD HH:mm'));
  const [endDate, setEndDate] = useState(Date.addDate(7, 'days'));

  const [googleId, setGoogleId] = useState('');
  const [facebookId, setFacebookId] = useState('');

  const [banners, setBanners] = useState([]);

  const [colors, setColors] = useState({
    background: theme.palette.background.paper,
    description: theme.palette.text.primary,
    card: theme.palette.background.paper,
    percent: theme.palette.text.primary,
    productName: theme.palette.text.primary,
    productPrice: theme.palette.text.primary,
    productDiscountPrice: theme.palette.text.primary,
  });

  const [products, setProducts] = useState([]);
  const [xlsxFile, setXlsxFile] = useState(null);

  const [loading, setLoading] = useState();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [value, setValue] = React.useState(0);

  const [textoImportacao, setTextoImportacao] = useState('');

  useEffect(() => {
    // async function getStores() {
    //   try {
    //     const response = await Fetch(
    //       `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
    //     );
    //     setStores(response);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
    // getStores();

    // async function getTabloidsBasic() {
    //   try {
    //     const response = await Fetch(
    //       `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais?all=true`
    //     );
    //     setTabloidsBasic(response.data);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
    // getTabloidsBasic();

    handleSubmit();
  }, []);

  async function onDropXlsx(file) {
    try {
      setLoading(true);
      const xlsx = await toBase64(file[0]);
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecebeArquivo`,
        xlsx,
        'POST'
      );

      setXlsxFile(response.arquivo_tabloide);
      setProducts(
        response.lista_produtos.map(product => ({
          ...product,
          produto_ativo: 1,
        }))
      );
      setLoading(false);
      //setActiveStep(prevActiveStep => prevActiveStep + 1);
      setImportar(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function handleSubmit() {
    const data = {
      nome_tabloide: name.replaceAll('/', '-'),
      descricao: description,
      data_inicio: startDate || Date.getDate('YYYY-MM-DD HH:mm'),
      data_fim: endDate || Date.getDate('YYYY-MM-DD HH:mm'),
      arquivo_tabloide_digital: xlsxFile,
      googleId,
      facebookId,
      storeId: store ? store.id_loja : null,
      colors,
      produtos: products.filter(product => product.produto_ativo === 1),
      copia: !!tabloidBasic,
      id_tabloide_digital: tabloidBasic
        ? tabloidBasic.id_tabloide_digital
        : null,
    };
    props.onSave(data, banners);
  }

  async function addProduto(dataProduct) {
    let new_image = '';
    let data = {
      ...dataProduct,
      preco_original:
        typeof dataProduct.preco_original === 'string'
          ? parseFloat(dataProduct.preco_original.replace(',', '.'))
          : dataProduct.preco_original,
      preco_dois:
        typeof dataProduct.preco_dois === 'string'
          ? parseFloat(dataProduct.preco_dois.replace(',', '.'))
          : dataProduct.preco_dois,
      produto_ativo: 1,
    };
    if (Boolean(data.imagem_produto) && /;base64,/g.test(data.imagem_produto)) {
      new_image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64: true,
      });
    } else {
      new_image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64: false,
      });
    }
    let dateNow = new window.Date();
    dateNow = dateNow.getTime().toString();
    if (validURL(new_image))
      new_image = new_image.split('?')[0] + '?' + dateNow;
    data = { ...data, imagem_produto: new_image };
    setProducts(prevState => [data, ...prevState]);
    forceUpdate();
  }

  const handleChangeColor = state => color =>
    setColors(prevState => ({ ...prevState, [state]: color.hex }));

  const handleNext = () =>
    importar ? setImportar(false) : setActiveStep(activeStep + 1);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    importar && setImportar(false);
  };

  function stepZero() {
    return (
      <React.Fragment>
        {/* <Typography className={classes.stepTitle} variant="h6">
          Informações da Propaganda
        </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              getOptionSelected={(option, value) =>
                option.id_tabloide_digital === value.id_tabloide_digital
              }
              getOptionLabel={option => option.nome_tabloide_digital}
              options={tabloidsBasic}
              loading={tabloidsBasic.length === 0}
              value={tabloidBasic}
              onChange={(event, newValue) => setTabloidBasic(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Selecione modelo caso desejar"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {tabloidsBasic.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function stepOne() {
    return (
      <React.Fragment>
        <Typography className={classes.stepTitle} variant="h6">
          Informações da Propaganda
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nome"
              fullWidth
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Autocomplete
              // open={open}
              // onOpen={() => {
              //   setOpen(true);
              // }}
              // onClose={() => {
              //   setOpen(false);
              // }}

              getOptionSelected={(option, value) =>
                option.id_loja === value.id_loja
              }
              getOptionLabel={option => option.nome_loja}
              options={stores}
              loading={stores.length === 0}
              value={store}
              onChange={(event, newValue) => setStore(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Selecionar loja"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stores.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descrição"
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={6}>
            <DatePicker
              label="De"
              dateAndTime
              value={startDate}
              onChange={date =>
                setStartDate(Date.getDate('YYYY-MM-DD HH:mm', date))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Até"
              dateAndTime
              value={endDate}
              onChange={date =>
                setEndDate(Date.getDate('YYYY-MM-DD HH:mm', date))
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function stepTwo() {
    return (
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <TextField
            label="Google"
            fullWidth
            variant="outlined"
            value={googleId}
            onChange={event => setGoogleId(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Facebook"
            fullWidth
            variant="outlined"
            value={facebookId}
            onChange={event => setFacebookId(event.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  function stepThree() {
    return (
      <React.Fragment>
        <Typography variant="h6">Banners</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTable
              data={banners}
              pageSize={10}
              paging
              search
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Adicionar banner',
                  isFreeAction: true,
                  onClick: () => {
                    setOpen(true);
                  },
                },
              ]}
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    const data = banners;
                    const index = banners.indexOf(oldData);
                    data.splice(index, 1);
                    setProducts(data);
                    forceUpdate();
                    resolve();
                  }),
              }}
              columns={[
                {
                  title: 'Preview',
                  render: rowData => (
                    <img
                      className={classes.bannerPreview}
                      alt={rowData.file.name}
                      src={URL.createObjectURL(rowData.file)}
                    />
                  ),
                },
                { title: 'Nome', field: 'file.name' },
                {
                  title: 'Posição',
                  field: 'position',
                  render: rowData => (
                    <span>{legendPositionBanner(rowData.position)}</span>
                  ),
                },
                {
                  title: 'Dispositivo',
                  field: 'device',
                  render: rowData => (
                    <span>{legendDeviceBanner(rowData.device)}</span>
                  ),
                },
                {
                  title: 'Tipo',
                  field: 'file.type',
                  render: rowData => (
                    <span>
                      {rowData.file ? rowData.file.type.split('/')[1] : ''}
                    </span>
                  ),
                },
                {
                  title: 'Tamanho',
                  field: 'file.size',
                  render: rowData => (
                    <span>
                      {humanFileSize(rowData.file ? rowData.file.size : 0)}
                    </span>
                  ),
                },
              ]}
              deleteText="Deseja remover esse banner?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function stepFour() {
    return (
      <Box display="flex" flexDirection="row" width="100%" overflow="hidden">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="60%"
          paddingX={10}
        >
          <Preview colors={colors} />
        </Box>
        <Box height="100%" width="40%" borderLeft="solid 1px rgba(0,0,0,0.12)">
          <Box width="100%">
            <TabPanel value={value} index={0}>
              <ChromePicker
                color={colors.background}
                onChangeComplete={handleChangeColor('background')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                      position: 'static',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChromePicker
                color={colors.description}
                onChangeComplete={handleChangeColor('description')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ChromePicker
                color={colors.card}
                onChangeComplete={handleChangeColor('card')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ChromePicker
                color={colors.percent}
                onChangeComplete={handleChangeColor('percent')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ChromePicker
                color={colors.productName}
                onChangeComplete={handleChangeColor('productName')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ChromePicker
                color={colors.productPrice}
                onChangeComplete={handleChangeColor('productPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ChromePicker
                color={colors.productDiscountPrice}
                onChangeComplete={handleChangeColor('productDiscountPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </TabPanel>
          </Box>

          <Box width="100%">
            <Divider />
            <List>
              <ListItem button divider onClick={() => setValue(0)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.background}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Cor de fundo"
                  secondary={colors.background}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(1)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.description}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Descrição"
                  secondary={colors.description}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(2)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.card} />
                </ListItemAvatar>
                <ListItemText primary="Card" secondary={colors.card} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(3)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.percent} />
                </ListItemAvatar>
                <ListItemText primary="Desconto" secondary={colors.percent} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(4)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productName}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Nome do produto"
                  secondary={colors.productName}
                />
              </ListItem>
              <ListItem button divider onClick={() => setValue(5)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productPrice}
                  />
                </ListItemAvatar>
                <ListItemText primary="De" secondary={colors.productPrice} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(6)}>
                <ListItemAvatar>
                  <Box
                    className={classes.colorBox}
                    bgcolor={colors.productDiscountPrice}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Por"
                  secondary={colors.productDiscountPrice}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  }

  function stepFive() {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={2}
          style={{ display: importarTexto ? 'none' : 'block' }}
        >
          <Grid item container alignItems="center" spacing={2}>
            <Grid item container spacing={2} xs={6}>
              <Grid item>
                <Typography>Ordem das colunas do excel</Typography>
              </Grid>
              <Grid item container spacing={2}>
                {identificadores.map(item => (
                  <Grid item>
                    <Chip label={item} clickable={false} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Alert severity="info">
                Só é permitido a importação de arquivos .xlsx
              </Alert>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              useChipsForPreview={true}
              maxFileSize={6000000}
              filesLimit={1}
              clearOnUnmount={false}
              style={{ backgroundColor: '#fff' }}
              dropzoneParagraphClass={`ds-text`}
              dropzoneText="Selecione ou arraste um arquivo xlsx"
              showPreviews={true}
              showFileNamesInPreview={true}
              showPreviewsInDropzone={false}
              previewChipProps={{ icon: <DescriptionIcon /> }}
              onDrop={onDropXlsx}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: importarTexto ? 'block' : 'none',
            textAlign: 'center',
          }}
        >
          <TextField
            label="Texto de importação"
            value={textoImportacao}
            shrink={true}
            disabled={false}
            multiline
            rows={20}
            onChange={e => setTextoImportacao(e.target.value)}
            style={{ width: '98%', margin: 10, border: '1px solid #e7e7e7' }}
          ></TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let array = textoImportacao.split(/\n/g);
              array = array.map(value => {
                return value.replaceAll('R$', '').replaceAll('$', '');
              });
              array = array.map(value => {
                return value.trim().split(' ');
              });
              array = array.map(value => {
                let precoA = null;
                let precoB = null;
                var j = null;
                for (j = value.length - 1; j >= 0; j--)
                  if (value[j] !== '') {
                    let preco_tmp = +value[j].replace(',', '.');
                    if (preco_tmp && (!precoB || !precoA)) {
                      if (!precoB) {
                        precoB = preco_tmp;
                      } else {
                        if (value[j].includes('.') || value[j].includes(',')) {
                          precoA = preco_tmp;
                          j--;
                        }
                        break;
                      }
                    } else {
                      break;
                    }
                  }

                let produto = '';
                for (var i = 0; i <= j; i++)
                  if (value[i] !== '') produto = produto + ' ' + value[i];

                if (!precoA) {
                  precoA = precoB;
                  precoB = 0;
                }

                return [produto.trim(), precoA, precoB];
              });

              setProducts(
                array.map(product => ({
                  nome_produto: product[0],
                  preco: product[2],
                  preco_dois: product[1] || 0,
                  produto_ativo: 1,
                }))
              );
              setLoading(false);
              setImportar(false);
              setImportarTexto(false);
            }}
          >
            Adicionar
          </Button>
        </div>
      </React.Fragment>
    );
  }

  function stepSix() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setImportar(true);
            setImportarTexto(true);
          }}
          style={{ marginRight: 10 }}
        >
          Importar Texto
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setImportar(true)}
          style={{ marginRight: 10 }}
        >
          Importar arquivo
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAdd(true)}
        >
          Adicionar Produto
        </Button>
        <CustomTable
          data={products}
          pageSize={10}
          paging
          search
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
          columns={[
            {
              field: 'imagem_produto',
              title: 'Foto do produto',
              editComponent: props => (
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#e7e7e7',
                  }}
                >
                  <img
                    alt="product"
                    src={
                      props.value ||
                      'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{ width: 100, cursor: 'pointer' }}
                  />
                  <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      type="file"
                      onChange={async e => {
                        const imageEdit = await toBase64(e.target.files[0]);
                        props.onChange(imageEdit.base64);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button size="small" component="span">
                        Escolher foto
                      </Button>
                    </label>
                    <Button
                      size="small"
                      component="span"
                      onClick={() => {
                        setDadosModalSerpApi({
                          index: props.rowData.tableData.id,
                          nome_produto: props.rowData.nome_produto,
                          onChangeImage: image => props.onChange(image),
                        });
                        setOpenModalSerpApi(true);
                      }}
                    >
                      Buscar Imagem
                    </Button>
                  </div>
                </section>
              ),
              render: rowData => (
                <img
                  alt={rowData.imagem_produto}
                  src={rowData.imagem_produto}
                  style={{
                    width: 75,
                    height: 90,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7',
                  }}
                />
              ),
            },
            { title: 'EAN', field: 'ean' },
            { title: 'Código', field: 'id_produto' },
            { title: 'Produto', field: 'nome_produto' },
            {
              title: 'De',
              field: 'preco',
              render: rowData => <span>{formatPrice(rowData.preco)}</span>,
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  type="numeric"
                  size="small"
                  InputProps={{
                    style: { fontSize: 12 },
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            {
              title: 'Por',
              field: 'preco_dois',
              render: rowData => (
                <span> {formatPrice(rowData.preco_dois)}</span>
              ),
              editComponent: props => (
                <TextField
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  type="numeric"
                  size="small"
                  InputProps={{
                    style: { fontSize: 12 },
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              ),
            },
            { title: 'Gramatura', field: 'gramatura' },
            { title: 'Categoria', field: 'categoria' },
            { title: 'Observação', field: 'observacoes' },
          ]}
          deleteText="Deseja desativar esse produto para o tabloide ?"
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(async resolve => {
                let new_image = '';
                let data = {
                  ...newData,
                  preco:
                    typeof newData.preco === 'string'
                      ? parseFloat(newData.preco.replace(',', '.'))
                      : newData.preco,
                  preco_dois:
                    typeof newData.preco_dois === 'string'
                      ? parseFloat(newData.preco_dois.replace(',', '.'))
                      : newData.preco_dois,
                };
                if (
                  Boolean(data.imagem_produto) &&
                  /;base64,/g.test(data.imagem_produto)
                ) {
                  try {
                    new_image = await props.postProdutoImagemTabloide({
                      ...data,
                      isBase64: true,
                    });
                  } catch (err) {
                    resolve();
                    return null;
                  }
                } else {
                  try {
                    new_image = await props.postProdutoImagemTabloide({
                      ...data,
                      isBase64: false,
                    });
                  } catch (err) {
                    resolve();
                    return null;
                  }
                }
                let dateNow = new window.Date();
                dateNow = dateNow.getTime().toString();
                if (validURL(new_image))
                  new_image = new_image.split('?')[0] + '?' + dateNow;
                data = { ...data, imagem_produto: new_image };
                const index = products.indexOf(oldData);
                products[index] = data;
                setProducts(products);
                forceUpdate();
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const index = products.indexOf(oldData);
                products.splice(index, 1);
                setProducts(products);
                forceUpdate();
                resolve();
              }),
          }}
        />
      </div>
    );
  }

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return stepZero();
      // case 1:
      //   return stepTwo();
      // case 2:
      //   return stepThree();
      // case 3:
      //   return stepFour();
      case 1:
        return importar ? stepFive() : stepSix();
      default:
        return stepZero();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        TransitionComponent={Transition}
        open={props.open}
        onClose={() => {
          setImportar(false);
          props.onClose();
        }}
        disableBackdropClick
      >
        {props.open && (
          <React.Fragment>
            <AppBar className={classes.appBar} color="unset">
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  Criar Propaganda
                </Typography>
                <IconButton
                  edge="start"
                  color="unset"
                  onClick={props.onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Container maxWidth="lg">
              <Paper className={classes.paper}>
                {/* <Stepper activeStep={activeStep} className={classes.stepper}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}

                {getStepContent()}

                <div className={classes.buttons}>
                  {activeStep !== 0 ? (
                    !loading && (
                      <Button onClick={handleBack} className={classes.button}>
                        Anterior
                      </Button>
                    )
                  ) : (
                    <div />
                  )}
                  {loading ? (
                    <CircularProgress
                      className={classes.loader}
                      color="primary"
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        activeStep === steps.length - 1 && !importar
                          ? handleSubmit
                          : handleNext
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 && !importar
                        ? 'Criar'
                        : 'Próximo'}
                    </Button>
                  )}
                </div>
              </Paper>
            </Container>
          </React.Fragment>
        )}
      </Dialog>
      {uploadBannerIsOpen && (
        <UploadBanner
          open={uploadBannerIsOpen}
          onSave={data => {
            setBanners(prevState => [...prevState, ...data]);

            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
      {adicionarProdutoIsOpen && (
        <AdicionarProduto
          open={adicionarProdutoIsOpen}
          onSave={data => {
            addProduto(data);
            setOpenAdd(false);
          }}
          onClose={() => {
            setOpenAdd(false);
            props.cleanProductsForAdd();
          }}
        />
      )}
      {openModalSerpApi && (
        <ModalSerpApi
          open={openModalSerpApi}
          onClose={() => {
            setOpenModalSerpApi(false);
          }}
          nome_produto={dadosModalSerpApi.nome_produto}
          onClickSelecionar={obj => {
            dadosModalSerpApi.onChangeImage(obj);
            setOpenModalSerpApi(false);
          }}
        ></ModalSerpApi>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  postProdutoImagemTabloide,
  cleanProductsForAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTabloid);
