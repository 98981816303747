import axios from "axios";
import {
  CreateHeaderResponse,
  CreateHeadersData,
  Import,
} from "../types/imports";
import {ChatBotFileData} from '../types/chatbot'
import apiV2 from '../utils/helpers/Fetch'
import {AMBIENTE, DATASALES_CRM_API} from '../utils/helpers/Constants'

export const linkDownload = 'https://log-crm.s3.amazonaws.com/arquivos/modelo_importacao_contatos/MODELO_IMPORTA%C3%87%C3%82O_CONTATOS.xlsx'

export const createHeaders = async (
  data: CreateHeadersData
): Promise<CreateHeaderResponse> => {
  console.log('chegueii')
  const response = await apiV2(
    `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileHeaderWhatsapp`,
    data, 'POST', null, null, false
  );
  return response.data;
};

export const createImportFile = async (
  data: ChatBotFileData
): Promise<string> => {
  const requestObjectData = {
    fileLength: data.fileRows,
    fileLink: data.fileUrl,
    fileName: data.fileName,
    headerLink: data.fileHeaderUrl,
    keyImport: data.identificator?.column,
    ruleImport: data.rule,
    typeImport: data.typeImport,
  };
  
  const response = await apiV2(
    `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileImportWhatsapp`,
    requestObjectData, 'POST', null, null, false
  );
  console.log('responseeeeeeeeee: ', response)
  return response.message;
};


