import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  label: { marginBottom: theme.spacing(2) },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    maxWidth: '100%',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
