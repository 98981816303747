import React from 'react';
import { Route } from 'react-router-dom';

import Layout from '../Layout';
import Preload from '../Preload';

export default function RoutePreload({ component: Component, ...rest }) {
  if (Boolean(Component)) {
    return (
      <Route
        {...rest}
        render={props =>
          props.match.path === '/main' ? (
            <Preload path={'main'}>
              <Component {...props} />
            </Preload>
          ) : (
            <Preload>
              <Layout>
                <Component {...props} />
              </Layout>
            </Preload>
          )
        }
      />
    );
  }
  return null;
}
