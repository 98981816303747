/* eslint-disable */
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Slide,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dialog } from '@mui/material';
import { Icon } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AlertDialogV2 } from 'utils/components/AlertDialogV2';

import {
  CustomTable,
  DatePicker,
  DialogAlert,
  Dropzone,
  SplitButton
} from '../../../../utils/components';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import MyDate from '../../../../utils/helpers/Date';
import Fetch from '../../../../utils/helpers/Fetch';
import {
  formatPrice,
  legendDeviceBanner,
  legendPositionBanner
} from '../../../../utils/helpers/Format';
import { toBase64 } from '../../../../utils/helpers/Functions';
import {
  cleanProductsForAdd,
  deleteTabloideCategoria,
  getTabloidsTemplatesFisico,
  postProdutoImagemTabloide,
  postTabloideCategoria,
  putTabloideCategoria,
  updateSequence,
  v2_getEncarteFisicoPixeMenor,
  v2_postEncarteFisicoPixeMenor
} from '../../action';
import AdicionarProduto from '../AdicionarProduto';
import ModalSerpApi from '../AdicionarProduto/ModalSerpApi/index';
import GeneratePixelixeAplicarCategorias from '../GeneratePixelixeAplicarCategorias';
import GeneratePixelixeMenor from '../GeneratePixelixeMenor';
import Preview from '../Preview';
import UploadBanner from '../UploadBanner';
import useStyles from './styles';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`}>
      {value === index && children}
    </div>
  );
}

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const dinamica_comercial_array = {
  preco_unico: ['Preço De:', 'Preço Por:'],
  de_por: ['Preço De:', 'Preço Por:'],
  varejo_atacado: ['Preço Varejo:', 'Preço Atacado:'],
  pack_unitario: ['Preço Pack:', 'Preço Unitário:'],
  normal_clube: ['Preço Normal:', 'Preço Clube:'],
  limpo: ['Preço 1:', 'Preço 2:']
};

const Date = new MyDate();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateTabloid(props) {
  const is396 = JSON.parse(localStorage.getItem('companyId')) === 396;

  const { tabloid } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);

  const [uploadBannerIsOpen, setOpen] = useState(false);
  const [openModalSerpApi, setOpenModalSerpApi] = useState(false);
  const [dadosModalSerpApi, setDadosModalSerpApi] = useState({});
  const [adicionarProdutoIsOpen, setOpenAdd] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(Date.getDate('YYYY-MM-DD HH:mm'));
  const [endDate, setEndDate] = useState(Date.getDate('YYYY-MM-DD HH:mm'));

  const [googleId, setGoogleId] = useState('');
  const [facebookId, setFacebookId] = useState('');

  const [identificacao, setIdentificacao] = useState({});

  const [banners, setBanners] = useState([]);

  const [generatePixelixeMenorOpen, setGeneratePixelixeMenorOpen] = useState(false);
  const [onGeneratePixelixeMenor, setOnGeneratePixelixeMenor] = useState(null);
  const [generatePixelixeAplicarCategoriasOpen, setGeneratePixelixeAplicarCategoriasOpen] =
    useState(false);

  const [produtoFoco, setProdutoFoco] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState([false, () => {}]);

  const [importar, setImportar] = useState(false);
  const [importarTexto, setImportarTexto] = useState(false);
  const [loading, setLoading] = useState();
  const [xlsxFile, setXlsxFile] = useState(null);
  const [textoImportacao, setTextoImportacao] = useState('');

  const [showModelosProntos, setShowModelosProntos] = useState(false);
  const [tabloidsBasic, setTabloidsBasic] = useState([]);
  const [tabloidBasic, setTabloidBasic] = useState(null);

  // const [headerBanners, setHeaderBanners] = useState([]);
  // const [footerBanners, setFooterBanners] = useState([]);

  const [colors, setColors] = useState({
    background: '',
    description: '',
    card: '',
    percent: '',
    productName: '',
    productPrice: '',
    productDiscountPrice: ''
  });

  const [products, setProducts] = useState([]);
  const [productsCheckeds, setProductsCheckeds] = useState([]);
  const [openConfirmationDelete, setOpenConfirmationDelete] = useState(false);
  const [allCheckeds, setAllCheckeds] = useState(false);
  const [productsFiltrado, setProductsFiltrado] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [value, setValue] = React.useState(0);

  const [nomeNovaCategoria, setNomeNovaCategoria] = useState('');
  const [showNovaCategoriaDialog, setShowNovaCategoriaDialog] = useState(false);
  const [showNovaCategoriaDialogEditar, setShowNovaCategoriaDialogEditar] = useState(false);

  const history = useHistory();

  useEffect(() => {
    history.push(history.location.pathname + '?EditTabloid');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  useEffect(() => {
    if (props.commandToAddProduct === 'TextImport') {
      setImportar(true);
      setImportarTexto(true);
    }
    if (props.commandToAddProduct === 'FilesImport') {
      setImportar(true);
    }
    if (props.commandToAddProduct === 'AddProduct') {
      setOpenAdd(true);
    }
    if (props.commandToAddProduct === 'Models') {
      setShowModelosProntos(true);
    }
  }, [props.commandToAddProduct]);

  const steps = props.completeEdit
    ? [
        'Loja',
        'Banners',
        'Cores',
        'Integração',
        'Identificação'
        // 'Produtos',
      ]
    : [
        'Informações da Propaganda',
        // 'Integração',
        // 'Banners',
        // 'Cores',
        'Produtos'
      ];

  const identificadores = [
    'EAN',
    'CODIGO',
    'PRODUTO',
    'DE',
    'POR',
    'GRAMATURA',
    'CATEGORIA',
    'OBSERVAÇÃO'
  ];

  useEffect(() => {
    setName(tabloid.nome_tabloide_digital);
    setDescription(tabloid.descricao);

    setStartDate(tabloid.data_inicio);
    setEndDate(tabloid.data_fim);

    setProducts(tabloid.produtos);

    !props.completeEdit && setActiveStep(1);

    setGoogleId(tabloid.googleId);
    setFacebookId(tabloid.facebookId);
    setBanners(tabloid.banners);
    setIdentificacao({
      identificacaoFormulario: tabloid.identificacaoFormulario,
      identificacaoObrigatoria: tabloid.identificacaoObrigatoria,
      identificacaoNome: tabloid.identificacaoNome,
      identificacaoTelefone: tabloid.identificacaoTelefone,
      identificacaoEmail: tabloid.identificacaoEmail
    });

    if (tabloid.colors) {
      setColors({
        background: Boolean(tabloid.colors.background) ? tabloid.colors.background : '',
        card: Boolean(tabloid.colors.card) ? tabloid.colors.card : '',
        description: Boolean(tabloid.colors.description) ? tabloid.colors.description : '',
        percent: Boolean(tabloid.colors.percent) ? tabloid.colors.percent : '',
        productDiscountPrice: Boolean(tabloid.colors.productDiscountPrice)
          ? tabloid.colors.productDiscountPrice
          : '',
        productName: Boolean(tabloid.colors.productName) ? tabloid.colors.productName : '',
        productPrice: Boolean(tabloid.colors.productPrice) ? tabloid.colors.productPrice : ''
      });
    }
  }, []);

  useEffect(() => {
    setProductsFiltrado(tabloid.produtos);
  }, [JSON.stringify(tabloid.produtos)]);

  const [intervalOn, setIntervalOn] = useState(false);

  const [tabloideMenor, setTabloideMenor] = useState({});

  function v2_getEncarteFisicoPixeMenor() {
    if (is396)
      props
        .v2_getEncarteFisicoPixeMenor({
          id_tabloide: tabloid.id_tabloide_digital
        })
        .then(payload => {
          let fakeArrayTmp = {};
          if (Array.isArray(payload))
            payload.forEach(element => {
              fakeArrayTmp[element.id_tabloide_produto] = { ...element };
            });
          setTabloideMenor(fakeArrayTmp);
        });
  }

  useEffect(() => {
    let timer = null;
    if (intervalOn) {
      timer = setInterval(() => {
        //condição
        Object.keys(tabloideMenor).every(element => {
          if (!tabloideMenor[element].link && !tabloideMenor[element].error) {
            v2_getEncarteFisicoPixeMenor();
            return false;
          } else {
            return true;
          }
        });
      }, 5000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [intervalOn, tabloideMenor]);

  useEffect(() => {
    v2_getEncarteFisicoPixeMenor();
  }, []);

  useEffect(() => {
    async function getStores() {
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
        );

        setStores(response);
      } catch (err) {
        console.error(err);
      }
    }

    getStores();

    async function getTabloidsBasic() {
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais?all=true`
        );
        setTabloidsBasic(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    getTabloidsBasic();
  }, []);

  const haveProductSelected = Object.keys(props.productSelected).length > 0;

  useEffect(() => {
    if (haveProductSelected) {
      setProdutoFoco(props.productSelected);
    }
  }, []);

  useEffect(() => {
    if (tabloid.storeId) {
      const indexStore = stores.findIndex(element => {
        return element.id_loja === tabloid.storeId;
      });
      if (indexStore >= 0) setStore(stores[indexStore]);
    }
  }, [JSON.stringify(stores)]);

  const [categorias, setCategorias] = useState({});
  const [categoriaEmFoco, setCategoriaEmFoco] = useState(0);
  const [categoriasCopiaLocal, setCategoriasCopiaLocal] = useState([...tabloid.categorias]);

  function produtosFiltrar() {
    if (categoriaEmFoco !== 0) {
      let productsTmp = products.filter(
        value => value.id_categoria === categorias[categoriaEmFoco].id
      );
      setProductsFiltrado([...productsTmp]);
    } else {
      setProductsFiltrado([...products]);
    }
  }

  useEffect(() => {
    let categoriasTmp = [{ nome_categoria: 'Todos' }];
    categoriasCopiaLocal.forEach((element, index) => {
      categoriasTmp.push({
        ...element,
        index
      });
    });
    setCategorias(categoriasTmp);

    if (products.length > 0) ordenaProducts();

    produtosFiltrar();
  }, [JSON.stringify(categoriasCopiaLocal), JSON.stringify(products)]);

  useEffect(() => {
    produtosFiltrar();
  }, [JSON.stringify(categorias)]);

  useEffect(() => {
    produtosFiltrar();
  }, [categoriaEmFoco]);

  useEffect(() => {
    let result = products.filter(product => product.checked);
    setProductsCheckeds(result);
  }, [products]);

  useEffect(() => {
    if (
      productsCheckeds.length > 0 &&
      products.length > 0 &&
      JSON.stringify(productsCheckeds) === JSON.stringify(products)
    ) {
      setAllCheckeds(true);
    }
  }, [productsCheckeds, products]);

  function onCloseParentDialog() {
    handleSubmit();
    props.onClose();
  }

  const toUpdateSequence = () => {
    let tmp = [...products];
    tmp.forEach((element, index) => {
      element.sequence = index;
    });
    let toUpdateSequence = null;
    if (!props.completeEdit) {
      toUpdateSequence = {
        products: [...tmp],
        categorias: [...categoriasCopiaLocal]
      };
    }
    return toUpdateSequence;
  };

  async function handleSubmit() {
    const data = {
      id_tabloide_digital: tabloid.id_tabloide_digital,
      uuid: tabloid.uuid,
      nome_tabloide_digital: name.replaceAll('/', '-'),
      descricao: description,
      data_inicio: startDate,
      data_fim: endDate,
      storeId: store ? store.id_loja : null,
      colors,
      googleId,
      facebookId,
      ...identificacao
    };

    await props.onSave(
      {
        ...data,
        toUpdateSequence: toUpdateSequence()
      },
      banners.filter(banner => banner.id === undefined)
    );
  }

  async function handleSubmitProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
        { produto: product },
        'POST',
        null,
        null,
        false
      );

      setProducts(prevState => [...prevState, response.produto]);
      //alert('Produto adicionado ao final da lista!');

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      props.getTabloids();

      enqueueSnackbar('Produto adicionado ao final da lista!', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleSubmitProductsTabloidBasic() {
    //modelo pronto de importação.
    try {
      const response = {
        produtos: [],
        categorias: []
      };
      if (!!tabloidBasic) {
        enqueueSnackbar('Processando...', { variant: 'info' });
        const response_x = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
          {
            produtos: [],
            modeloPronto: !!tabloidBasic,
            tabloidBasic,
            id_tabloide_digital: tabloid.id_tabloide_digital
          },
          'POST',
          null,
          null,
          false
        );
        response.produtos = [...response.produtos, ...(response_x.produtos || [])];
        response.categorias = [...new Set(response.categorias)];
        setTabloidBasic(null);
        setProducts(prevState => [...prevState, ...response.produtos]);

        if (!!tabloidBasic && response.categorias) {
          setCategoriasCopiaLocal([...response.categorias]);
        }
        setTextoImportacao('');
        enqueueSnackbar('Produtos adicionados ao final da lista!', { variant: 'success' });
      } else {
        enqueueSnackbar('Selecione um modelo pronto para importação', { variant: 'error' });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', { variant: 'error' });
    }
  }

  async function handleSubmitProducts(products = []) {
    try {
      const response = {
        produtos: [],
        categorias: []
      };

      for (var x = 0; x < products.length / 10; x++) {
        enqueueSnackbar('Processando...', {
          variant: 'info'
        });

        const response_x = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
          {
            produtos: products.slice(x * 10, x * 10 + 10),
            modeloPronto: !!tabloidBasic,
            tabloidBasic,
            id_tabloide_digital: tabloid.id_tabloide_digital
          },
          'POST',
          null,
          null,
          false
        );

        response.produtos = [...response.produtos, ...(response_x.produtos || [])];
        response.categorias = [...response.categorias, ...(response_x.categorias || [])];
      }

      response.categorias = [...new Set(response.categorias)];

      setTabloidBasic(null);
      setProducts(prevState => [...prevState, ...response.produtos]);
      if (!!tabloidBasic && response.categorias) setCategoriasCopiaLocal([...response.categorias]);
      setTextoImportacao('');
      //alert('Produtos adicionados ao final da lista!');
      enqueueSnackbar('Produtos adicionados ao final da lista!', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleEditProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
        { produto: product, toUpdateSequence: toUpdateSequence() },
        'PUT',
        null,
        null,
        false
      );

      setProducts(prevState => {
        let index = prevState.findIndex(
          element => element.id_tabloide_produto === product.id_tabloide_produto
        );
        prevState[index] = product;
        return [...prevState];
      });

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      props.getTabloids();

      enqueueSnackbar('Atualização concluída', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleEditProducts(products) {
    try {
      setOpenConfirmationDelete(false);

      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putManyProducts`,
        { products, toUpdateSequence: toUpdateSequence() },
        'PUT',
        null,
        null,
        false
      );

      products.forEach(product => {
        setProducts(prevState => {
          let index = prevState.findIndex(
            element => element.id_tabloide_produto === product.id_tabloide_produto
          );
          prevState[index] = product;
          return [...prevState];
        });
      });

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      props.getTabloids();

      enqueueSnackbar('Atualização concluída', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleDeleteBanner(id) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/tabloid/banner/${id}`,
        {},
        'DELETE'
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function postProdutoImagemTabloide(data, isBase64) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const image = await props.postProdutoImagemTabloide({
        ...data,
        isBase64
      });
      enqueueSnackbar('Upload de imagem com sucesso!', {
        variant: 'success'
      });
      return image;
    } catch (err) {
      enqueueSnackbar(err.message || err.title, {
        variant: 'error'
      });
      return null;
    }
  }

  const [submiting, setSubmiting] = useState(false);

  async function updateProduto(newData, type, close) {
    try {
      setSubmiting(true);

      let new_image = '';
      let data = {
        ...newData,
        preco_original:
          typeof newData.preco_original === 'string'
            ? parseFloat(newData.preco_original.replace(',', '.'))
            : newData.preco_original,
        preco_dois:
          typeof newData.preco_dois === 'string'
            ? parseFloat(newData.preco_dois.replace(',', '.'))
            : newData.preco_dois
      };

      if (type === 'add') {
        data = {
          ...data,
          produto_ativo: 1
        };
      }

      let multProdutos =
        produtoFoco && produtoFoco.multProdutos ? [...produtoFoco.multProdutos] : [];
      data.forDelete = [];
      for (let i in multProdutos) {
        let atual = multProdutos[i];

        if (data.imagens) {
          let index = data.imagens.findIndex(
            element => element.id_tabloide_produto === atual.id_tabloide_produto
          );

          if (index < 0) {
            data.forDelete = [...data.forDelete, atual];
          }
        }
      }

      if (data.imagemPrincipal) {
        data = {
          ...data,
          ...data.imagemPrincipal[0]
        };

        data.imagens = [...data.imagemPrincipal, ...data.imagens];
      }

      if (data.imagens) {
        for (let i in data.imagens) {
          let image = data.imagens[i];

          if (image.new && image.imagem_produto) {
            if (Boolean(image.imagem_produto) && /;base64,/g.test(image.imagem_produto)) {
              new_image = await postProdutoImagemTabloide(image, true);
            } else {
              new_image = await postProdutoImagemTabloide(image, false);
            }
            //
            // throw 500;
            //
            let new_image_object = new_image;
            new_image = new_image.image;
            if (!new_image_object.findExistingImage) {
              let dateNow = new window.Date();
              dateNow = dateNow.getTime().toString();
              if (validURL(new_image)) new_image = new_image.split('?')[0] + '?' + dateNow;
            }

            let objTmp = {
              ...image, // pra receber o new
              imagem_produto: new_image,
              imagem_produto_original_link: new_image_object.imagem_produto_original_link,
              imagem_produto_original_s3: new_image_object.imagem_produto_original_s3,
              new: true
            };

            if (i === '0') {
              data = {
                ...data,
                ...objTmp
              };
            }

            let tmpImagens = data.imagens;
            tmpImagens[i] = {
              ...objTmp
            };

            data.imagens = [...tmpImagens];
          }
        }

        data.imagens.shift();
      }

      if (type === 'add') {
        await handleSubmitProduct({
          ...data,
          id_tabloide_digital: tabloid.id_tabloide_digital
        });
      } else {
        const index = products.indexOf(produtoFoco);

        products[index] = data;
        setProducts(products);
        await handleEditProduct(data);
      }

      forceUpdate();

      setSubmiting(false);

      close();

      if (props.commandToAddProduct === 'AddProduct') {
        onCloseParentDialog();
      }
    } catch (e) {
      setSubmiting(false);

      console.log(e);

      enqueueSnackbar('Falha de Processamento! Tente novamente ou tente outra imagem.', {
        variant: 'error'
      });
    }
  }

  // async function addProduto(dataProduct) {
  //   let new_image = '';
  //   let data = {
  //     ...dataProduct,
  //     preco_original:
  //       typeof dataProduct.preco_original === 'string'
  //         ? parseFloat(dataProduct.preco_original.replace(',', '.'))
  //         : dataProduct.preco_original,
  //     preco_dois:
  //       typeof dataProduct.preco_dois === 'string'
  //         ? parseFloat(dataProduct.preco_dois.replace(',', '.'))
  //         : dataProduct.preco_dois,
  //     produto_ativo: 1,
  //   };
  //   if (
  //     data.imagem_produto &&
  //     !data.imagem_produto.includes('/datasalesio-imagens/')
  //   ) {
  //     //
  //     if (
  //       Boolean(data.imagem_produto) &&
  //       /;base64,/g.test(data.imagem_produto)
  //     ) {
  //       new_image = await postProdutoImagemTabloide(data, true);
  //     } else {
  //       new_image = await postProdutoImagemTabloide(data, false);
  //     }
  //     let new_image_object = new_image;
  //     new_image = new_image.image;
  //     if (!new_image_object.findExistingImage) {
  //       let dateNow = new window.Date();
  //       dateNow = dateNow.getTime().toString();
  //       if (validURL(new_image))
  //         new_image = new_image.split('?')[0] + '?' + dateNow;
  //     }
  //     data = {
  //       ...data,
  //       imagem_produto: new_image,
  //       imagem_produto_original_link:
  //         new_image_object.imagem_produto_original_link,
  //       imagem_produto_original_s3: new_image_object.imagem_produto_original_s3,
  //     };
  //   }
  //   handleSubmitProduct({
  //     ...data,
  //     id_tabloide_digital: tabloid.id_tabloide_digital,
  //   });
  //   forceUpdate();
  // }

  const handleChangeColor = state => color => {
    setColors(prevState => ({ ...prevState, [state]: color.hex }));
  };

  const handleNext = () => (importar ? setImportar(false) : setActiveStep(activeStep + 1));

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    importar && setImportar(false);
  };

  async function onDropXlsx(file) {
    try {
      setLoading(true);
      const xlsx = await toBase64(file[0]);
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecebeArquivo`,
        xlsx,
        'POST'
      );

      setXlsxFile(response.arquivo_tabloide);
      handleSubmitProducts(
        response.lista_produtos.map(product => ({
          ...product,
          produto_ativo: 1,
          preco_original: product.preco,
          id_tabloide_digital: tabloid.id_tabloide_digital,
          id_categoria: categoriaEmFoco !== 0 ? categorias[categoriaEmFoco].id : null
        }))
      );
      setLoading(false);
      //setActiveStep(prevActiveStep => prevActiveStep + 1);
      setImportar(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function stepOne() {
    return (
      <React.Fragment>
        {/* <Typography className={classes.stepTitle} variant="h6">
          Informações da Propaganda
        </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nome da Propaganda"
              fullWidth
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>

          <React.Fragment>
            {/* <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Autocomplete
                // open={open}
                // onOpen={() => {
                //   setOpen(true);
                // }}
                // onClose={() => {
                //   setOpen(false);
                // }}

                getOptionSelected={(option, value) => option.id_loja === value.id_loja}
                getOptionLabel={option => option.nome_loja}
                options={stores}
                loading={stores.length === 0}
                value={store}
                onChange={(event, newValue) => setStore(newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Selecionar loja"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {stores.length === 0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <DatePicker
                label="De"
                dateAndTime
                value={startDate}
                onChange={date => setStartDate(Date.getDate('YYYY-MM-DD HH:mm', date))}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Até"
                dateAndTime
                value={endDate}
                onChange={date => setEndDate(Date.getDate('YYYY-MM-DD HH:mm', date))}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      </React.Fragment>
    );
  }

  function stepTwo() {
    return (
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <TextField
            label="Google"
            fullWidth
            variant="outlined"
            value={googleId}
            onChange={event => setGoogleId(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Facebook"
            fullWidth
            variant="outlined"
            value={facebookId}
            onChange={event => setFacebookId(event.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  function stepThree() {
    return (
      <React.Fragment>
        {/* <Typography variant="h6">Banners</Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTable
              data={banners}
              pageSize={10}
              paging
              search
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%'
              }}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Adicionar banner',
                  isFreeAction: true,
                  onClick: () => {
                    setOpen(true);
                  }
                }
              ]}
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    handleDeleteBanner(oldData.id);
                    const data = banners;
                    const index = banners.indexOf(oldData);
                    data.splice(index, 1);
                    setProducts(data);
                    forceUpdate();
                    resolve();
                  })
              }}
              columns={[
                {
                  title: 'Preview',
                  render: rowData => (
                    <img
                      className={classes.bannerPreview}
                      crossOrigin="anonymous"
                      alt={rowData.name}
                      src={rowData.url || URL.createObjectURL(rowData.file)}
                    />
                  )
                },
                {
                  title: 'Nome',
                  field: 'name',
                  render: rowData => <span>{rowData.name ? rowData.name : rowData.file.name}</span>
                },
                {
                  title: 'Posição',
                  field: 'position',
                  render: rowData => <span>{legendPositionBanner(rowData.position)}</span>
                },
                {
                  title: 'Dispositivo',
                  field: 'device',
                  render: rowData => <span>{legendDeviceBanner(rowData.device)}</span>
                },
                {
                  title: 'Tipo',
                  field: 'type',
                  render: rowData => (
                    <span>
                      {rowData.type ? rowData.type.split('/')[1] : rowData.file.type.split('/')[1]}
                    </span>
                  )
                }
              ]}
              deleteText="Deseja remover esse banner?"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function stepFour() {
    return (
      <Box display="flex" flexDirection="row" width="100%" overflow="hidden">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="60%"
          paddingX={10}
        >
          <Preview colors={colors} />
        </Box>
        <Box height="100%" width="40%" borderLeft="solid 1px rgba(0,0,0,0.12)">
          <Box height="50%" width="100%">
            <TabPanel value={value} index={0}>
              <ChromePicker
                color={colors.background}
                onChangeComplete={handleChangeColor('background')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChromePicker
                color={colors.description}
                onChangeComplete={handleChangeColor('description')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ChromePicker
                color={colors.card}
                onChangeComplete={handleChangeColor('card')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ChromePicker
                color={colors.percent}
                onChangeComplete={handleChangeColor('percent')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ChromePicker
                color={colors.productName}
                onChangeComplete={handleChangeColor('productName')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ChromePicker
                color={colors.productPrice}
                onChangeComplete={handleChangeColor('productPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ChromePicker
                color={colors.productDiscountPrice}
                onChangeComplete={handleChangeColor('productDiscountPrice')}
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none'
                    }
                  }
                }}
              />
            </TabPanel>
          </Box>

          <Box className={classes.scrool} height="50%" width="100%" marginTop={6} paddingBottom={6}>
            <Divider />
            <List>
              <ListItem button divider onClick={() => setValue(0)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.background} />
                </ListItemAvatar>
                <ListItemText primary="Cor de fundo" secondary={colors.background} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(1)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.description} />
                </ListItemAvatar>
                <ListItemText primary="Descrição" secondary={colors.description} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(2)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.card} />
                </ListItemAvatar>
                <ListItemText primary="Card" secondary={colors.card} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(3)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.percent} />
                </ListItemAvatar>
                <ListItemText primary="Desconto" secondary={colors.percent} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(4)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.productName} />
                </ListItemAvatar>
                <ListItemText primary="Nome do produto" secondary={colors.productName} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(5)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.productPrice} />
                </ListItemAvatar>
                <ListItemText primary="De" secondary={colors.productPrice} />
              </ListItem>
              <ListItem button divider onClick={() => setValue(6)}>
                <ListItemAvatar>
                  <Box className={classes.colorBox} bgcolor={colors.productDiscountPrice} />
                </ListItemAvatar>
                <ListItemText primary="Por" secondary={colors.productDiscountPrice} />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  }

  const ordenaProducts = () => {
    let tmp3 = [...products];

    //ordernar segundo categorias
    tmp3.sort((a, b) => {
      const comparePtoP = (a, b) => {
        if (a.sequence !== null && b.sequence === null) {
          return -1;
        }
        if (a.sequence === null && b.sequence !== null) {
          return 1;
        }
        if (a.sequence === null && b.sequence === null) {
          if (a.id_tabloide_produto < b.id_tabloide_produto) {
            return -1;
          }
          if (a.id_tabloide_produto > b.id_tabloide_produto) {
            return 1;
          }
          return 0;
        }
        if (a.sequence !== null && b.sequence !== null) {
          if (a.sequence < b.sequence) {
            return -1;
          }
          if (a.sequence > b.sequence) {
            return 1;
          }
          return 0;
        }
        return 0;
      };

      let catA = categoriasCopiaLocal.find(element => element.id === a.id_categoria);
      let catB = categoriasCopiaLocal.find(element => element.id === b.id_categoria);
      if (catA !== undefined && catB === undefined) {
        return -1;
      }
      if (catA === undefined && catB !== undefined) {
        return 1;
      }
      if (catA === undefined && catB === undefined) {
        return comparePtoP(a, b);
      }
      if (catA !== undefined && catB !== undefined) {
        catA = catA.sequence;
        catB = catB.sequence;
        if (catA !== null && catB === null) {
          return -1;
        }
        if (catA === null && catB !== null) {
          return 1;
        }
        if (catA === null && catB === null) {
          return comparePtoP(a, b);
        }
        if (catA !== null && catB !== null) {
          if (catA < catB) {
            return -1;
          }
          if (catA > catB) {
            return 1;
          }
          if (catA === catB) {
            return comparePtoP(a, b);
          }
          return 0;
        }
      }
      return 0;
    });

    tmp3 = tmp3.map((element, index) => {
      return {
        ...element,
        sequence: index
      };
    });

    setProducts(tmp3);

    atualizarCategoriasCore([...categoriasCopiaLocal]);
  };

  const atualizarCategoriasCore = tmp => {
    tmp = tmp.map((element, index2) => {
      return {
        ...element,
        sequence: index2
      };
    });
    setCategoriasCopiaLocal(tmp);
  };

  const atualizarCategorias = (index, variante) => {
    let tmp = [...categoriasCopiaLocal];
    let tmpAux = { ...tmp[index + variante] };
    tmp[index + variante] = tmp[index];
    tmp[index] = tmpAux;
    setCategoriaEmFoco(categoriaEmFoco + variante);
    atualizarCategoriasCore(tmp);
  };

  function stepFive() {
    !intervalOn && setIntervalOn(true);

    return (
      <div style={{ textAlign: 'right' }}>
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            gap: 32,
            // height: 50,
            // textAlign: 'left',
            justifyContent: 'right'
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {categorias.map((value, index) => (
              <Fragment>
                <Button
                  color="primary"
                  // size="large"
                  style={
                    index === categoriaEmFoco
                      ? {
                        backgroundColor: COLOR_PRIMARY,
                        color: 'white',
                      }
                      : {}
                  }
                  onClick={() => setCategoriaEmFoco(index)}
                >
                  {value.nome_categoria}
                </Button>
                {index !== 0 && index === categoriaEmFoco && (
                  <SplitButtonIcon
                    label="label"
                    variant="contained"
                    color="inherit"
                    style={{ color: 'black', backgraundColor: 'black' }}
                    icon={true}
                  >
                    {index !== 0 && index === categoriaEmFoco && index !== 1 && (
                      <MenuItem
                        key="<<<"
                        style={{ borderRadius: 10, width: '100%' }}
                        onClick={() => {
                          atualizarCategorias(value.index, -1);
                        }}
                      >
                        <ChevronLeftIcon />
                        {'Mover para a esquerda'}
                      </MenuItem>
                    )}
                    {index !== 0 &&
                      index === categoriaEmFoco &&
                      index !== categorias.length - 1 && (
                        <MenuItem
                          key=">>>"
                          style={{ borderRadius: 10, width: '100%' }}
                          onClick={() => {
                            atualizarCategorias(value.index, 1);
                          }}
                        >
                          <ChevronRightIcon />
                          {'Mover para a direita'}
                        </MenuItem>
                      )}
                    <MenuItem
                      key=">>>"
                      style={{ borderRadius: 10, width: '100%' }}
                      onClick={() => {
                        setShowNovaCategoriaDialog(true);
                        setShowNovaCategoriaDialogEditar(true);
                        setNomeNovaCategoria(
                          categorias[categoriaEmFoco].nome_categoria
                        );
                      }}
                    >
                      <EditIcon style={{ margin: 2 }} /> {'Editar Categoria'}
                    </MenuItem>
                    <MenuItem
                      key=">>>"
                      style={{ borderRadius: 10, width: '100%' }}
                      onClick={() => {
                        props
                          .deleteTabloideCategoria({
                            id: categorias[categoriaEmFoco].id,
                          })
                          .then(() => {
                            let tmp = [...categoriasCopiaLocal];
                            tmp = tmp.filter(
                              item => item.id !== categorias[categoriaEmFoco].id
                            );
                            setCategoriaEmFoco(0);
                            setCategoriasCopiaLocal([...tmp]);
                          });
                      }}
                    >
                      <DeleteIcon style={{ margin: 2 }} /> {'Deletar Categoria'}
                    </MenuItem>
                  </SplitButtonIcon>
                )}
              </Fragment>
            ))}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                setShowNovaCategoriaDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </div> */}

          {/* <div style={{ width: '100%' }}></div> */}
          <SplitButton
            text="Adicionar mais produtos"
            label="label"
            // variant="contained"
            // color="primary"
            // style={{ color: 'white' }}
            // icon={true}
          >
            <MenuItem
              key="Importar Texto"
              style={{ borderRadius: 10, width: '100%' }}
              onClick={() => {
                setImportar(true);
                setImportarTexto(true);
              }}
            >
              Importar Texto
            </MenuItem>
            <MenuItem
              key="Importar arquivo"
              style={{ borderRadius: 10, width: '100%' }}
              onClick={() => setImportar(true)}
            >
              Importar Arquivo
            </MenuItem>
            <MenuItem
              key="Adicionar Produto"
              style={{ borderRadius: 10, width: '100%' }}
              onClick={() => setOpenAdd(true)}
            >
              Adicionar Produto
            </MenuItem>
            <MenuItem
              key="Modelos Prontos"
              style={{ borderRadius: 10, width: '100%' }}
              onClick={() => setShowModelosProntos(true)}
            >
              Modelos Prontos
            </MenuItem>
            {/* {is396 && (
              <Fragment>
                <MenuItem
                  key="Adicionar Produto"
                  style={{ borderRadius: 10, width: '100%' }}
                  onClick={() => {
                    props.getTabloidsTemplatesFisico({
                      tipoPixelixe: 'encarteMaior',
                    });
                    setGeneratePixelixeAplicarCategoriasOpen(true);
                  }}
                >
                  V3 - Aplicar Categorias
                </MenuItem>
                <MenuItem
                  key="Adicionar Produto"
                  style={{ borderRadius: 10, width: '100%' }}
                  onClick={() => {
                    setOnGeneratePixelixeMenor(() => async templateData => {
                      await props.v2_postEncarteFisicoPixeMenor({
                        id: tabloid.id_tabloide_digital,
                        ...templateData,
                      });
                      return tabloid.id_tabloide_digital;
                    });
                    setGeneratePixelixeMenorOpen(true);
                  }}
                >
                  V3 - Gerar para Todos
                </MenuItem>
              </Fragment>
            )} */}
          </SplitButton>

          <Button
            data-testid="delete-all-products"
            startIcon={<Icon>delete</Icon>}
            variant="text"
            size="large"
            disabled={!productsCheckeds.length}
            onClick={() => setOpenConfirmationDelete(true)}
            style={{ color: !productsCheckeds.length ? '#B0B0B0' : '#B02F2F' }}
          >
            Excluir selecionados
          </Button>
        </div>
        <CustomTable
          data={productsFiltrado}
          pageSize={10}
          paging
          search
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%'
          }}
          columns={[
            {
              field: 'imagem_produto',
              title: 'Foto do produto',
              editComponent: props => (
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#e7e7e7'
                  }}
                >
                  <img
                    alt="product"
                    crossOrigin="anonymous"
                    src={
                      props.value ||
                      'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{ width: 100, borderRadius: 5, cursor: 'pointer' }}
                  />
                  <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      type="file"
                      onChange={async e => {
                        const imageEdit = await toBase64(e.target.files[0]);
                        props.onChange(imageEdit.base64);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button size="small" component="span">
                        Escolher foto
                      </Button>
                    </label>
                    <Button
                      size="small"
                      component="span"
                      onClick={() => {
                        setDadosModalSerpApi({
                          index: props.rowData.tableData.id,
                          nome_produto: props.rowData.nome_produto,
                          onChangeImage: image => props.onChange(image)
                        });
                        setOpenModalSerpApi(true);
                      }}
                    >
                      Buscar Imagem
                    </Button>
                  </div>
                </section>
              ),
              render: rowData => (
                <img
                  alt={rowData.imagem_produto}
                  crossOrigin="anonymous"
                  src={
                    rowData.imagem_produto ||
                    'https://datasalesio-imagens.s3.amazonaws.com/transparent-product-datasales.png'
                  }
                  style={{
                    width: 75,
                    height: 90,
                    cursor: 'pointer',
                    backgroundColor: '#e7e7e7',
                    borderRadius: 10
                  }}
                />
              )
            },
            {
              title: 'Info.',
              field: 'nome_produto',
              render: rowData => (
                <React.Fragment>
                  {!!rowData.ean && (
                    <span>
                      EAN: {rowData.ean}
                      <br />
                    </span>
                  )}
                  {!!rowData.id_produto && (
                    <span>
                      Código: {rowData.id_produto}
                      <br />
                    </span>
                  )}
                  {!!rowData.nome_produto && (
                    <span>
                      Nome: {rowData.nome_produto}
                      <br />
                    </span>
                  )}
                  {!!rowData.preco_original && (
                    <span>
                      {rowData.dinamica_comercial
                        ? dinamica_comercial_array[rowData.dinamica_comercial][0]
                        : dinamica_comercial_array['de_por'][0]}{' '}
                      {formatPrice(rowData.preco_original)}
                      <br />
                    </span>
                  )}
                  {!!rowData.preco_dois && (
                    <span>
                      {rowData.dinamica_comercial
                        ? dinamica_comercial_array[rowData.dinamica_comercial][1]
                        : dinamica_comercial_array['de_por'][1]}{' '}
                      {formatPrice(rowData.preco_dois)}
                      <br />
                    </span>
                  )}
                  {!!rowData.gramatura && (
                    <span>
                      Unidade: {rowData.gramatura}
                      <br />
                    </span>
                  )}
                  {!!rowData.categoria && (
                    <span>
                      Categoria: {rowData.categoria}
                      <br />
                    </span>
                  )}
                  {!!rowData.id_categoria && (
                    <span>
                      Categoria:{' '}
                      {(() => {
                        let index = categoriasCopiaLocal.findIndex(
                          element => element.id === rowData.id_categoria
                        );
                        if (index > -1) {
                          return categoriasCopiaLocal[index].nome_categoria;
                        } else {
                          return '';
                        }
                      })()}
                      <br />
                    </span>
                  )}
                  {!!rowData.observacoes && (
                    <span>
                      Observações: {rowData.observacoes}
                      <br />
                    </span>
                  )}
                  <span>
                    Sequência: {rowData.sequence}
                    <br />
                  </span>
                </React.Fragment>
              )
            },

            // REFERENTE A V2/ETIQUETAS - PARTE REFERENTE A MINIATURAS
            is396
              ? {
                  title: '',
                  field: '',
                  render: rowData => (
                    <div style={{ textAlign: 'center' }}>
                      {
                        tabloideMenor[rowData.id_tabloide_produto] ? (
                          tabloideMenor[rowData.id_tabloide_produto].link ? (
                            <img
                              alt={tabloideMenor[rowData.id_tabloide_produto].link}
                              src={tabloideMenor[rowData.id_tabloide_produto].link}
                              crossOrigin="anonymous"
                              //"https://datasalesio-imagens.s3.amazonaws.com/image_empty_for_datasales.png"
                              style={{
                                width: 100,
                                borderRadius: 5,
                                cursor: 'pointer',
                                backgroundColor: '#e7e7e7'
                              }}
                              onClick={() => {
                                setOnGeneratePixelixeMenor(() => async templateData => {
                                  await props.v2_postEncarteFisicoPixeMenor({
                                    id: tabloid.id_tabloide_digital,
                                    id_tabloide_produto: rowData.id_tabloide_produto,
                                    ...templateData
                                  });
                                  return tabloid.id_tabloide_digital;
                                });
                                setGeneratePixelixeMenorOpen(true);
                              }}
                            />
                          ) : tabloideMenor[rowData.id_tabloide_produto].error ? (
                            <span
                              onClick={() => {
                                setOnGeneratePixelixeMenor(() => async templateData => {
                                  await props.v2_postEncarteFisicoPixeMenor({
                                    id: tabloid.id_tabloide_digital,
                                    id_tabloide_produto: rowData.id_tabloide_produto,
                                    ...templateData
                                  });
                                  return tabloid.id_tabloide_digital;
                                });
                                setGeneratePixelixeMenorOpen(true);
                              }}
                            >
                              Erro!
                            </span>
                          ) : (
                            <span>Carregando...</span>
                          )
                        ) : null
                        // <Tooltip
                        //   title="Processando"
                        //   placement="top"
                        // >
                        //   <CircularProgress size={22} thickness={4} />
                        // </Tooltip>
                        // <img
                        //   alt={'imagem vazia'}
                        //   src={
                        //     'https://datasalesio-imagens.s3.amazonaws.com/image_empty_for_datasales.png'
                        //   }
                        //   style={{
                        //     width: 100,
                        //     borderRadius: 5,
                        //     cursor: 'pointer',
                        //     backgroundColor: '#e7e7e7',
                        //   }}
                        //   onClick={() => {
                        //     setOnGeneratePixelixeMenor(
                        //       () => async templateData => {
                        //         await props.v2_postEncarteFisicoPixeMenor({
                        //           id: tabloid.id_tabloide_digital,
                        //           id_tabloide_produto:
                        //             rowData.id_tabloide_produto,
                        //           ...templateData,
                        //         });
                        //         return tabloid.id_tabloide_digital;
                        //       }
                        //     );
                        //     setGeneratePixelixeMenorOpen(true);
                        //   }}
                        // />
                      }
                    </div>
                  )
                }
              : {},
            {
              title: 'Mover',
              field: 'observacoes',
              render: rowData => (
                <div>
                  {rowData.tableData.id !== 0 && (
                    <ArrowDropUpIcon
                      style={{
                        cursor: 'pointer',
                        fontSize: 50
                      }}
                      onClick={() => {
                        const index = products.findIndex(
                          element => rowData.id_tabloide_produto === element.id_tabloide_produto
                        );
                        const index2 = products.findIndex(
                          element =>
                            productsFiltrado[rowData.tableData.id - 1].id_tabloide_produto ===
                            element.id_tabloide_produto
                        );
                        let tmp1 = {
                          ...products[index],
                          sequence: products[index].sequence - 1
                        };
                        let tmp2 = {
                          ...products[index2],
                          sequence: products[index2].sequence + 1
                        };
                        let tmp3 = [...products];
                        tmp3[index] = { ...tmp2 };
                        tmp3[index2] = { ...tmp1 };

                        setProducts(tmp3);
                        forceUpdate();
                      }}
                    />
                  )}
                  {rowData.tableData.id !== productsFiltrado.length - 1 && (
                    <ArrowDropDownIcon
                      style={{
                        cursor: 'pointer',
                        fontSize: 50
                      }}
                      onClick={() => {
                        const index = products.findIndex(
                          element => rowData.id_tabloide_produto === element.id_tabloide_produto
                        );
                        const index2 = products.findIndex(
                          element =>
                            productsFiltrado[rowData.tableData.id + 1].id_tabloide_produto ===
                            element.id_tabloide_produto
                        );
                        let tmp1 = {
                          ...products[index],
                          sequence: products[index].sequence + 1
                        };
                        let tmp2 = {
                          ...products[index2],
                          sequence: products[index2].sequence - 1
                        };
                        let tmp3 = [...products];
                        tmp3[index] = { ...tmp2 };
                        tmp3[index2] = { ...tmp1 };
                        setProducts(tmp3);
                        forceUpdate();
                      }}
                    />
                  )}
                </div>
              )
            },
            {
              title: (
                <Box>
                  <FormControlLabel
                    label="Selecionar todos"
                    control={
                      <Checkbox
                        data-testid="select-all-products"
                        checked={allCheckeds}
                        onChange={event => {
                          const newProducts = products.map(product => {
                            return { ...product, checked: event.target.checked };
                          });
                          setProducts(newProducts);
                          setAllCheckeds(event.target.checked);
                        }}
                        style={{ color: '#2813AD' }}
                      />
                    }
                  />
                </Box>
              ),
              field: 'ean',
              render: rowData => (
                <Box display="flex" alignItems="center">
                  <CreateIcon
                    style={{
                      cursor: 'pointer',
                      fontSize: 30,
                      marginRight: 12
                    }}
                    onClick={() => {
                      setProdutoFoco(rowData);
                      setOpenAdd(true);
                    }}
                  />
                  <DeleteIcon
                    style={{
                      cursor: 'pointer',
                      fontSize: 30,
                      marginRight: 12
                    }}
                    onClick={() => {
                      setShowDeleteDialog([
                        true,
                        () => {
                          const index = products.findIndex(
                            e => e.id_tabloide_produto === rowData.id_tabloide_produto
                          );
                          products.splice(index, 1);
                          setProducts(products);
                          handleEditProduct({ ...rowData, produto_ativo: 0 });
                          forceUpdate();
                        }
                      ]);
                    }}
                  />
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid="select-product"
                          checked={rowData.checked || allCheckeds}
                          onChange={event => {
                            if (allCheckeds) {
                              setAllCheckeds(false);
                            }

                            const newProducts = products.map(product => {
                              return product.id_tabloide_produto === rowData.id_tabloide_produto
                                ? { ...product, checked: event.target.checked }
                                : product;
                            });
                            setProducts(newProducts);
                          }}
                          style={{ color: '#2813AD' }}
                        />
                      }
                    />
                  </Box>
                </Box>
              )
            }
          ]}
        />
      </div>
    );
  }

  function stepAddTexto() {
    return (
      <React.Fragment>
        <Grid container spacing={2} style={{ display: importarTexto ? 'none' : 'block' }}>
          <Grid item container alignItems="center" spacing={2}>
            <Grid item container spacing={2} xs={6}>
              <Grid item>
                <Typography>Ordem das colunas do excel</Typography>
              </Grid>
              <Grid item container spacing={2}>
                {identificadores.map(item => (
                  <Grid item>
                    <Chip label={item} clickable={false} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Alert severity="info">Só é permitido a importação de arquivos .xlsx</Alert>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              useChipsForPreview={true}
              maxFileSize={6000000}
              filesLimit={1}
              clearOnUnmount={false}
              style={{ backgroundColor: '#fff' }}
              dropzoneParagraphClass={`ds-text`}
              dropzoneText="Selecione ou arraste um arquivo xlsx"
              showPreviews={true}
              showFileNamesInPreview={true}
              showPreviewsInDropzone={false}
              previewChipProps={{ icon: <DescriptionIcon /> }}
              onDrop={onDropXlsx}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              As categorias do arquivo só irão interferir no Link Online e podem ser editadas na
              área de variáveis. As categorias criadas aqui não serão válidas para as categorias em
              abas.
            </Alert>
          </Grid>
        </Grid>
        <div
          style={{
            display: importarTexto ? 'block' : 'none',
            textAlign: 'center'
          }}
        >
          <TextField
            label="Texto de importação"
            value={textoImportacao}
            shrink={true}
            disabled={false}
            multiline
            rows={20}
            onChange={e => setTextoImportacao(e.target.value)}
            style={{ width: '98%', margin: 10, border: '1px solid #e7e7e7' }}
          ></TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let array = textoImportacao.split(/\n/g);
              array = array.map(value => {
                return value.replaceAll('R$', '').replaceAll('$', '');
              });
              array = array.map(value => {
                return value.trim().split(' ');
              });
              array = array.map(value => {
                let precoA = null;
                let precoB = null;
                var j = null;
                for (j = value.length - 1; j >= 0; j--)
                  if (value[j] !== '') {
                    let preco_tmp = +value[j].replace(',', '.');
                    if (preco_tmp && (!precoB || !precoA)) {
                      if (!precoB) {
                        precoB = preco_tmp;
                      } else {
                        if (value[j].includes('.') || value[j].includes(',')) {
                          precoA = preco_tmp;
                          j--;
                        }
                        break;
                      }
                    } else {
                      break;
                    }
                  }

                let produto = '';
                for (var i = 0; i <= j; i++)
                  if (value[i] !== '') produto = produto + ' ' + value[i];

                if (!precoA) {
                  precoA = precoB;
                  precoB = 0;
                }

                return [produto.trim(), precoA, precoB];
              });

              let newProducts = [
                ...array.map(product => ({
                  id_tabloide_digital: tabloid.id_tabloide_digital,
                  nome_produto: product[0],
                  preco_original: product[2],
                  preco_dois: product[1] || 0,
                  produto_ativo: 1,
                  id_categoria: categoriaEmFoco !== 0 ? categorias[categoriaEmFoco].id : null,
                  ...(product[2] ? { dinamica_comercial: 'de_por' } : {})
                }))
              ];
              handleSubmitProducts(newProducts);
              setLoading(false);
              setImportar(false);
              setImportarTexto(false);
            }}
          >
            Adicionar
          </Button>
        </div>
      </React.Fragment>
    );
  }

  function stepIdentificacao() {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <div style={{ margin: '20px 0px 0px 10px' }}>
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={identificacao.identificacaoFormulario}
                  onChange={event => {
                    setIdentificacao({
                      ...identificacao,
                      identificacaoFormulario: event.target.checked
                    });
                  }}
                  name="Solicitar Formulário"
                  color="primary"
                />
              }
              label="Solicitar Formulário"
            />
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={identificacao.identificacaoObrigatoria}
                  onChange={event => {
                    setIdentificacao({
                      ...identificacao,
                      identificacaoObrigatoria: event.target.checked
                    });
                  }}
                  name="Formulário Obrigatório"
                  color="primary"
                />
              }
              label="Formulário Obrigatório"
            />
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={identificacao.identificacaoNome}
                  onChange={event => {
                    setIdentificacao({
                      ...identificacao,
                      identificacaoNome: event.target.checked
                    });
                  }}
                  name="Nome"
                  color="primary"
                />
              }
              label="Nome"
            />
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={identificacao.identificacaoTelefone}
                  onChange={event => {
                    setIdentificacao({
                      ...identificacao,
                      identificacaoTelefone: event.target.checked
                    });
                  }}
                  name="Telefone"
                  color="primary"
                />
              }
              label="Telefone"
            />
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={identificacao.identificacaoEmail}
                  onChange={event => {
                    setIdentificacao({
                      ...identificacao,
                      identificacaoEmail: event.target.checked
                    });
                  }}
                  name="Email"
                  color="primary"
                />
              }
              label="Email"
            />
          </div>
        </Grid>
      </React.Fragment>
    );
  }

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return stepOne();
      // case 1:
      //   return stepTwo();
      // case 2:
      //   return stepThree();
      // case 3:
      //   return stepFour();
      case 1:
        return importar ? stepAddTexto() : stepFive();
      default:
        return stepOne();
    }
  }

  function getStepContentComplete() {
    switch (activeStep) {
      case 0:
        return stepOne();
      case 1:
        return stepThree();
      case 2:
        return stepFour();
      case 3:
        return stepTwo();
      case 4:
        return stepIdentificacao();
      default:
        return stepThree();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={true}
        fullScreen
        TransitionComponent={Transition}
        onClose={onCloseParentDialog}
        style={{
          backgroundColor: '#fafafa',
          ...(haveProductSelected ? { display: 'none' } : {})
        }}
      >
        <AppBar className={classes.appBar} color="inherit">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {props.tabloid.nome_tabloide_digital}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseParentDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ backgroundColor: '#fafafa' }} dividers>
          <div style={{ backgroundColor: '#fafafa' }}>
            <Container maxWidth={false} style={{ backgroundColor: '#fafafa' }}>
              <Paper className={classes.paper} style={{ backgroundColor: '#fafafa', marginTop: 0 }}>
                {props.completeEdit && (
                  <Tabs
                    value={activeStep}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="disabled tabs example"
                    style={{ marginBottom: 20 }}
                  >
                    {steps.map((label, index) => (
                      <Tab
                        key={label}
                        label={label}
                        onClick={() => {
                          setActiveStep(index);
                        }}
                      />
                    ))}
                  </Tabs>
                )}

                {props.completeEdit ? getStepContentComplete() : getStepContent()}
              </Paper>
            </Container>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              margin: 5,
              width: '100%'
            }}
          >
            {activeStep !== 0 && props.completeEdit ? (
              !loading && <Button onClick={handleBack}>Anterior</Button>
            ) : (
              <div />
            )}
            {loading ? <CircularProgress className={classes.loader} color="primary" /> : null}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginLeft: 15, background: '#2813AD' }}
            >
              Salvar e Continuar
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {uploadBannerIsOpen && (
        <UploadBanner
          open={uploadBannerIsOpen}
          onSave={data => {
            setBanners(prevState => [...data, ...prevState]);
            setOpen(false);
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {adicionarProdutoIsOpen && (
        <AdicionarProduto
          submiting={submiting}
          open={adicionarProdutoIsOpen}
          produtoFoco={produtoFoco}
          categorias={categoriasCopiaLocal}
          categoriaPadrao={
            produtoFoco ? null : categoriaEmFoco !== 0 ? categorias[categoriaEmFoco].id : null
          }
          onSave={data => {
            const close = () => {
              setOpenAdd(false);
              setProdutoFoco(null);
              if (haveProductSelected) {
                props.onClose();
              }
            };

            if (produtoFoco && produtoFoco?.length !== 0) {
              updateProduto(data, 'update', close);
            } else {
              updateProduto(data, 'add', close);
            }
          }}
          onClose={() => {
            setOpenAdd(false);
            props.cleanProductsForAdd();
            setProdutoFoco(null);
            if (haveProductSelected) {
              props.onClose();
            }
          }}
        />
      )}
      {openModalSerpApi && (
        <ModalSerpApi
          open={openModalSerpApi}
          onClose={() => {
            setOpenModalSerpApi(false);
          }}
          nome_produto={dadosModalSerpApi.nome_produto}
          onClickSelecionar={obj => {
            dadosModalSerpApi.onChangeImage(obj);
            setOpenModalSerpApi(false);
          }}
        ></ModalSerpApi>
      )}
      {generatePixelixeMenorOpen && (
        <GeneratePixelixeMenor
          open={generatePixelixeMenorOpen}
          onClose={() => {
            setGeneratePixelixeMenorOpen(false);
            //handleNext();
          }}
          onGeneratePixelixeMenor={templateData => {
            onGeneratePixelixeMenor(templateData).then(id_tabloide => {
              v2_getEncarteFisicoPixeMenor();
              setIntervalOn(true);
            });
          }}
          tipoPixelixe={'encarte'}
        ></GeneratePixelixeMenor>
      )}
      {generatePixelixeAplicarCategoriasOpen && (
        <GeneratePixelixeAplicarCategorias
          open={generatePixelixeAplicarCategoriasOpen}
          tabloid={{
            id_tabloide_digital: tabloid.id,
            qtde_produtos_lista: products.length
          }}
          onClose={() => {
            setGeneratePixelixeAplicarCategoriasOpen(false);
          }}
          onSave={categorias => {
            let newProducts = [];
            var i = 1;
            for (var x = 0; x < categorias.length; x++) {
              for (var y = 0; y < categorias[x].quantidade; y++) {
                newProducts = [
                  ...newProducts,
                  {
                    id_tabloide_digital: tabloid.id_tabloide_digital,
                    nome_produto: 'Produto ' + i,
                    categoria: categorias[x].nome,
                    preco_original: 0,
                    preco_dois: 0,
                    produto_ativo: 1
                  }
                ];
                i++;
              }
            }
            handleSubmitProducts(newProducts);
            setGeneratePixelixeAplicarCategoriasOpen(false);
          }}
          tipoPixelixe={'encarteMaior'}
        ></GeneratePixelixeAplicarCategorias>
      )}
      {showDeleteDialog[0] && (
        <AlertDialogV2
          open={showDeleteDialog[0]}
          title="Deseja realmente excluir esse item?"
          textAgree="Sim"
          handleAgree={() => {
            showDeleteDialog[1]();
            setShowDeleteDialog([false, () => {}]);
          }}
          handleClose={() => setShowDeleteDialog([false, () => {}])}
        />
      )}
      {showNovaCategoriaDialog && (
        <Dialog
          open={showNovaCategoriaDialog}
          onClose={() => setShowNovaCategoriaDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Escolha um nome para a Categoria</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nome da Categoria"
              type="email"
              fullWidth
              value={nomeNovaCategoria}
              onChange={e => setNomeNovaCategoria(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowNovaCategoriaDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (nomeNovaCategoria === '') {
                  alert('Preencha o campo Nome da Categoria!');
                } else {
                  if (!showNovaCategoriaDialogEditar) {
                    let obj = {
                      id_tabloide: tabloid.id_tabloide_digital,
                      nome_categoria: nomeNovaCategoria,
                      sequence: categoriasCopiaLocal.length
                    };
                    props.postTabloideCategoria(obj).then(payload => {
                      setCategoriasCopiaLocal([
                        ...categoriasCopiaLocal,
                        { ...obj, id: payload.result }
                      ]);
                      setNomeNovaCategoria('');
                      setShowNovaCategoriaDialog(false);
                    });
                  } else {
                    let obj = {
                      id: categorias[categoriaEmFoco].id,
                      nome_categoria: nomeNovaCategoria,
                      sequence: categorias[categoriaEmFoco].sequence
                    };
                    props.putTabloideCategoria(obj).then(payload => {
                      let tmp = [...categoriasCopiaLocal];
                      tmp[tmp.findIndex(item => item.id === categorias[categoriaEmFoco].id)] = {
                        ...categorias[categoriaEmFoco],
                        nome_categoria: nomeNovaCategoria
                      };
                      setCategoriasCopiaLocal([...tmp]);
                      setNomeNovaCategoria('');
                      setShowNovaCategoriaDialog(false);
                      setShowNovaCategoriaDialogEditar(false);
                    });
                  }
                }
              }}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showModelosProntos && (
        <Dialog
          open={showModelosProntos}
          onClose={() => setShowModelosProntos(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogContent>
            {/* <DialogContentText> //
              Escolha um nome para a Categoria
            </DialogContentText> */}
            <Autocomplete
              getOptionLabel={option => {
                return option?.nome_tabloide_digital || '';
              }}
              options={tabloidsBasic}
              loading={tabloidsBasic.length === 0}
              value={tabloidBasic}
              onChange={(event, newValue) => {
                setTabloidBasic(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Selecione modelo pronto para importação"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {tabloidsBasic.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModelosProntos(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleSubmitProductsTabloidBasic();
                setShowModelosProntos(false);
              }}
              color="primary"
            >
              Importar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <AlertDialogV2
        open={openConfirmationDelete}
        title="Excluir os produtos selecionados da sua lista?"
        textAgree="Sim, excluir"
        handleAgree={() => {
          setAllCheckeds(false);
          productsCheckeds.forEach(product => {
            const index = products.findIndex(
              e => e.id_tabloide_produto === product.id_tabloide_produto
            );
            products.splice(index, 1);
            setProducts(products);
          });

          const newProductsCheckeds = productsCheckeds.map(product => {
            return { ...product, produto_ativo: 0 };
          });

          handleEditProducts(newProductsCheckeds);
        }}
        handleClose={() => setOpenConfirmationDelete(false)}
      />
    </React.Fragment>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});
const mapDispatchToProps = {
  cleanProductsForAdd,
  getTabloidsTemplatesFisico,
  postProdutoImagemTabloide,
  postTabloideCategoria,
  putTabloideCategoria,
  updateSequence,
  v2_getEncarteFisicoPixeMenor,
  v2_postEncarteFisicoPixeMenor,
  deleteTabloideCategoria
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTabloid);
