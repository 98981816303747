import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.5rem',
    width: '100%',
    marginBottom: '16px',
    padding: '16px 44px',
    background: '#F2F3F5',
    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px'
  },
  container2: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  container3: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-around'
  },
  imageView: {
    width: '100%',
    height: '150px',
    maxWidth: '150px',
    borderRadius: '4px',
    '&:hover': {
      opacity: '0.5'
    }
  },
  containerDatePicker: {
    maxWidth: '165px',

    '& .MuiOutlinedInput-root': {
      marginTop: '8px',
      height: '56px',
      background: '#FFF'
    },

    '&.MuiButton-outlined': {
      fontSize: '16px ',
      border: '1px solid #B0B0B0 !important'
    },

    '& img': {
      width: '30px'
    }
  },
  containerAction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  action: {
    '&.MuiButtonBase-root:hover': {
      border: '1px solid #B0B0B0'
    },
    '&.MuiButton-outlined': {
      width: '165px ',
      height: '56px ',
      marginTop: '32px',

      fontSize: '16px ',
      border: '1px solid #B0B0B0',
      background: '#FFF',
      color: '#B02F2F'
    },
    '&.MuiButton-startIcon,.MuiButton-iconSizeMedium': {
      marginRight: '22px'
    }
  },
  description: {
    '&.MuiInputBase-root,.MuiOutlinedInput-root': {
      // marginTop: '8px',
      height: '52px',
      background: '#FFF'
    }
  }
}));

