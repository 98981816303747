/* eslint-disable */
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Grid,
  Button,
  Typography,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
} from '@material-ui/core';
import { CheckCircle, HighlightOff, Search } from '@material-ui/icons';
import {
  TextField,
  CustomTable,
  CountCard,
} from '../../../../utils/components';
import * as actionsChatbot from '../../actions';
import PageWrapper from '../PageWrapper';
import ContactInvite from './ContactInvite';
import ContactActive from './ContactActive';
import ContactUpdate from './ContactUpdate';

const Whatsapp = props => {
  const { classes } = props,
    [contact, setContact] = useState({}),
    [nomeContato, setNomeContato] = useState(''),
    [numeroContato, setNumeroContato] = useState(''),
    [instancia, setInstancia] = useState(null),
    [status, setStatus] = useState(null),
    [menu, setMenu] = useState(null),
    { calendarios } = props,
    [, setCalendarioFiltrado] = useState([]),
    [periodo] = useState({
      dataInicio: moment().startOf('day').subtract(1, 'month'),
      dataFinal: moment().startOf('day').add(2, 'month'),
    }),
    [openAddContato, setOpenAddContato] = useState(false),
    [openInviteContato, setOpenInviteContato] = useState(false),
    [openActiveContato, setOpenActiveContato] = useState(false),
    [, updateState] = useState(),
    forceUpdate = useCallback(() => updateState({}), []),
    handleCalendarioFiltrado = () => {
      const filtrados = calendarios.filter(calendario =>
        moment(calendario.data_final).isBetween(
          periodo.dataInicio,
          periodo.dataFinal,
          null,
          '[]'
        )
      );
      setCalendarioFiltrado([...filtrados]);
    };

  useEffect(() => {
    handleCalendarioFiltrado();
    props.getInstancias();
    props.getContatosInstancia({
      nome_contato: nomeContato,
      numero: numeroContato,
      instancia,
      status,
    });
  }, []);

  useEffect(() => {
    handleCalendarioFiltrado();
  }, [periodo]);

  return (
    <Fragment>
      <PageWrapper
        {...props}
        title="Contatos"
        buttonBack
        header={
          <Fragment>
            <Box className={classes.actions}>
              <Box
                display="flex"
                marginTop="20px"
                marginBottom="0px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" marginRight="20px">
                  <Typography className={classes.label} variant="h6" noWrap>
                    Pesquisar
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Grid alignItems="center" container spacing={3}>
              <Grid item sm>
                <TextField
                  fullWidth
                  label="Nome do Contato"
                  onChange={({ target: { value } }) => setNomeContato(value)}
                  value={nomeContato}
                />
              </Grid>
              <Grid item sm>
                <TextField
                  fullWidth
                  label="Numero do Contato"
                  onChange={({ target: { value } }) => setNumeroContato(value)}
                  value={numeroContato}
                />
              </Grid>
              <Grid item sm>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Instância</InputLabel>
                  <Select
                    fullWidth
                    value={instancia}
                    onChange={({ target: { value } }) => setInstancia(value)}
                    label="Instância"
                  >
                    {props.instancias.map(instan => (
                      <MenuItem value={instan.id}>{instan.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status do contato</InputLabel>
                  <Select
                    fullWidth
                    value={status}
                    onChange={({ target: { value } }) => setStatus(value)}
                    label="Status do contato"
                  >
                    <MenuItem value={null}>Todos</MenuItem>
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={0}>Inativo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    props.getContatosInstancia({
                      nome_contato: nomeContato,
                      numero: numeroContato,
                      instancia,
                      status,
                    })
                  }
                >
                  <Search />
                </Button>
              </Grid>
              <Grid style={{ display: 'flex' }} justify="flex-end" item xs>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={({ currentTarget }) => setMenu(currentTarget)}
                >
                  Ações
                </Button>
                <Menu
                  anchorEl={menu}
                  keepMounted
                  open={Boolean(menu)}
                  onClose={() => setMenu(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setOpenAddContato(true);
                      setMenu(null);
                    }}
                  >
                    Adicionar
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenInviteContato(true);
                      setMenu(null);
                    }}
                  >
                    Convidar
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenActiveContato(true);
                      setMenu(null);
                    }}
                  >
                    Ativar
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Grid alignItems="center" container spacing={3}>
              <Grid item sm>
                <CountCard
                  count
                  loading={props.loadingChatbot}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={props.totaisContatos}
                  title="Total de contatos"
                  icon="people"
                />
              </Grid>
              <Grid item sm>
                <CountCard
                  count
                  loading={props.loadingChatbot}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={props.totaisContatosAtivos}
                  title="Total de contatos ativos"
                  icon="people"
                />
              </Grid>
              <Grid item sm>
                <CountCard
                  count
                  loading={props.loadingChatbot}
                  titleTypographyProps={{ variant: 'h6' }}
                  end={props.totaisContatosInativos}
                  title="Total de contatos inativos"
                  icon="people"
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      >
        <div className={classes.content}>
          <CustomTable
            data={props.contatos}
            title=""
            pageSize={10}
            paging
            isLoading={props.loadingChatbot}
            style={{
              border: 'none',
              boxShadow: 'none',
              width: '100%',
            }}
            columns={[
              {
                title: 'Instância',
                field: 'nome_instancia',
              },
              {
                title: 'Contato ativo',
                field: 'ativo_envio',
                render: props =>
                  Boolean(props.ativo_envio) ? (
                    <CheckCircle style={{ color: '#1b5e20' }} />
                  ) : (
                    <HighlightOff style={{ color: '#b71c1c' }} />
                  ),
              },
              {
                title: 'Numero',
                field: 'numero',
              },
              {
                title: 'Nome do contato',
                field: 'nome_contato',
              },
            ]}
            deleteText="Deseja desativar esse Contato?"
            onRowClick={(_, rowData) => {
              setContact(rowData);
              setOpenAddContato(true);
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  props
                    .deleteContato({
                      numero: oldData.numero,
                      id_instancia: oldData.id_instancia,
                    })
                    .then(() => {
                      props.getContatosInstancia({
                        nome_contato: nomeContato,
                        numero: numeroContato,
                        instancia,
                        status,
                      });
                    });
                  forceUpdate();
                  resolve();
                }),
            }}
          />
        </div>
      </PageWrapper>

      <ContactActive
        open={openActiveContato}
        instances={props.instancias}
        onClose={() => setOpenActiveContato(false)}
        onSave={message => props.ativarContatos(message)}
      />
      <ContactInvite
        open={openInviteContato}
        instances={props.instancias}
        onSave={message => props.convidarContatos(message)}
        onClose={() => setOpenInviteContato(false)}
      />
      <ContactUpdate
        open={openAddContato}
        instances={props.instancias}
        contact={contact}
        onChange={(name, value) => setContact({ ...contact, [name]: value })}
        onClose={() => {
          setOpenAddContato(false);
          setContact({});
        }}
        onSuccess={() => {
          props.getContatosInstancia({
            nome_contato: nomeContato,
            numero: numeroContato,
            instancia,
            status,
          });
          setOpenAddContato(false);
          setContact({});
        }}
      />
    </Fragment>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({ ChatbotReducer }) => ({
  ...ChatbotReducer,
});

const mapDispatchToProps = {
  ...actionsChatbot,
};

const WhatsappConnect = connect(mapStateToProps, mapDispatchToProps)(Whatsapp);

export default withStyles(styles)(WhatsappConnect);
