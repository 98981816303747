import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Menu,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useAds } from '../../../../../../hooks/ads';

const useStyles = makeStyles(theme => ({
  menu: {
    marginTop: theme.spacing(7),
  },
  paper: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  chip: { width: '100%' },
  signOut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2, 4),
  },
}));

const ProfileMenu = ({ anchorEl, onClose }) => {
  const classes = useStyles();

  const { user, signOut } = useAds();

  return (
    <Menu
      id="profile-menu"
      className={classes.menu}
      classes={{ paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box paddingX={2} paddingY={1}>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            xs={12}
            spacing={1}
          >
            <Grid item xs={12}>
              <Avatar alt={user.name} src={user.picture_url} />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                {user.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="body1">
                {user.email}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                color="default"
                onClick={() => {
                  signOut();
                  onClose();
                }}
              >
                Sair
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Menu>
  );
};

export default ProfileMenu;
