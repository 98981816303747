import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    preview: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '200px',
      borderRadius: theme.shape.borderRadius,
      objectFit: 'cover',
    },
  })
);

export default styles;
