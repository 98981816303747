import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as yup from 'yup';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  start_date: yup.date().required(),
  end_date: yup.date().required(),
});

const CreateAds = ({ open, data, onClose }) => {
  const classes = useStyles();

  const {
    // uuid,
    // offerId,
    // offerName,
    imageId,
    imageUrl,
    imageName,
    // imageType,
  } = data;

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async values => {
    try {
      await api(
        `https://api.datasales.info/distributions-${AMBIENTE}/distributions`,
        {
          offer_id: imageId,
          image_name: imageName,
          image_url: imageUrl,
          type: 'app',
          start_date: moment(values.start_date).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(values.end_date).format('YYYY-MM-DD HH:mm:ss'),
        },
        'POST',
        null,
        null,
        false
      );
      enqueueSnackbar('Distribuição enviado para analise com sucesso', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      start_date: moment(),
      end_date: moment().add(1, 'days'),
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <AppBar className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Encartes no App
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content} dividers>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className={classes.formContainer}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <DateRangeIcon />
                          </Avatar>
                        }
                        title="Programação"
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <DateTimePicker
                              label="Data de inicio"
                              ampm={false}
                              disablePast
                              fullWidth
                              inputVariant="outlined"
                              format="DD/MM/YYYY HH:mm"
                              value={formik.values.start_date}
                              onChange={date => {
                                formik.setFieldValue('start_date', date);
                              }}
                              error={
                                formik.touched.start_date &&
                                Boolean(formik.errors.start_date)
                              }
                              helperText={
                                formik.touched.start_date &&
                                formik.errors.start_date
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <DateTimePicker
                              label="Termino"
                              ampm={false}
                              disablePast
                              fullWidth
                              nullable
                              inputVariant="outlined"
                              format="DD/MM/YYYY HH:mm"
                              value={formik.values.end_date}
                              onChange={date => {
                                formik.setFieldValue('end_date', date);
                              }}
                              error={
                                formik.touched.end_date &&
                                Boolean(formik.errors.end_date)
                              }
                              helperText={
                                formik.touched.end_date &&
                                formik.errors.end_date
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>

          <Grid item container xs={4} spacing={4}>
            <Grid item xs={12}>
              <div className={classes.previewContainer}>
                <img
                  className={classes.preview}
                  alt="post-preview"
                  src={imageUrl}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          Distribuir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAds;
