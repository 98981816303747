import React from 'react';
import { Grid } from '@material-ui/core';
import { Modal, CountCard } from '../../../utils/components';

export default props => (
  <Modal
    onClose={props.onClose}
    showModal={props.open}
    header="Desempenho do envio"
  >
    <Grid container justify="center" spacing={3}>
      {props.data.statusEnvio &&
        props.data.statusEnvio.map((status, index) => (
          <Grid key={index} item>
            <CountCard
              count
              titleTypographyProps="subtitle1"
              end={status.quantidade}
              title={status.status_envio_externo}
            />
          </Grid>
        ))}
    </Grid>
  </Modal>
);
