import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Countup from 'react-countup';

const defaultFont = {
  fontFamily: "'Montserrat', sans-serif",
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  divRoot2: {
    width: '100%',
  },
  border: {
    border: '1px solid #F5F4F9',
    backgroundColor: '#F5F4F9',
    borderRadius: 8,
    height: '150px',
  },
  title1: {
    ...defaultFont,
    margin: '10px 10px 10px 8px',
    fontWeight: 500,
    fontSize: 13,
    color: '#312A5E',
  },
  divBar: {
    margin: 10,
  },
  bar: {
    width: '100%',
    height: 20,
    borderRadius: 8,
  },
  number: {
    ...defaultFont,
    margin: 10,
    color: '#312A5E',
    fontSize: 26,
  },
  img: {
    margin: 10,
    height: 50,
  },
}));

export default ({ example, ...props }) => {
  const classes = useStyles();

  return (
    <div class={classes.root}>
      <div class={classes.divRoot2}>
        <div class={classes.border}>
          <div class={classes.img}>{props.icon}</div>
          <div class={classes.number}>
            <Countup
              start={0}
              end={props.value || 0}
              duration={5}
              separator="."
              decimal=","
              decimals={0}
            />
          </div>
          <div class={classes.title1}>{props.text1}</div>
        </div>
      </div>
    </div>
  );
};
