/* eslint-disable */
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import '@webscopeio/react-textarea-autocomplete/style.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Modal, TextField } from '../../../../utils/components';
import { toBase64 } from '../../../../utils/helpers/Functions';
import { cleanProductsForAdd, getProduct, getProductPhoto } from '../../action/index';
import ModalSearchProdutos from './ModalSearchProdutos/index';
import ModalSerpApi from './ModalSerpApi/index';

class AdicionarProduto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: {},
      produto: {
        imagem_produto: null,
        ean: null,
        id_produto: null,
        nome_produto: null,
        preco_original: 0,
        preco_dois: 0,
        gramatura: null,
        categoria: null,
        observação: null
      },
      search_photo: false,
      search_product_add: false,
      openModalSearchProdutos: false,
      openModalSerpApi: false
    };
  }

  header(props, state) {
    return <Fragment>Adicionar Produto</Fragment>;
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        <div></div>
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() => {
            !!this.state.produto.nome_produto && !!this.state.produto.preco_dois
              ? this.props.onSave(this.state.produto)
              : window.alert('Campo "Produto" e "Por" obrigatórios! Por favor preencha.');
          }}
          disabled={false}
        >
          Salvar
        </Button>
      </div>
    );
  }

  step1(props, state) {
    return (
      <section
        style={{
          padding: '2rem 1rem 5rem 1rem',
          height: '100%'
        }}
      >
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20
          }}
        >
          {this.state.search_photo ? (
            <CircularProgress></CircularProgress>
          ) : (
            <img
              alt="product"
              crossOrigin="anonymous"
              src={
                this.state.produto.imagem_produto ||
                'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
              }
              style={{ width: 100, cursor: 'pointer', backgroundColor: '#e7e7e7' }}
            />
          )}
          <div style={{ marginTop: '2vh', textAlign: 'center' }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={async e => {
                const imageEdit = await toBase64(e.target.files[0]);
                this.setState({
                  produto: {
                    ...this.state.produto,
                    imagem_produto: imageEdit.base64
                  }
                });
              }}
            />
            <label htmlFor="contained-button-file">
              <Button size="small" component="span">
                Escolher foto
              </Button>
            </label>
            <Button
              size="small"
              component="span"
              onClick={() => this.setState({ openModalSerpApi: true })}
              style={{ width: '100%' }}
            >
              Pesquisar foto
            </Button>
          </div>
        </section>
        <div
          style={{
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 700,
              justifyContent: 'center',
              margin: 'auto',
              marginTop: 24,
              marginBottom: 24,
              flexWrap: 'wrap'
            }}
          >
            <TextField
              label="EAN"
              value={this.state.produto.ean}
              shrink={true}
              disabled={this.state.search_photo}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    ean: e.target.value
                  }
                })
              }
              style={{ width: 300, margin: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        this.setState({ search_photo: true });
                        this.props
                          .getProductPhoto({
                            ean: this.state.produto.ean
                          })
                          .then(() => {
                            this.setState({
                              search_photo: false,
                              produto: {
                                ...this.state.produto,
                                imagem_produto: this.props.imagem_add_produto
                              }
                            });
                          });
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            ></TextField>
            <TextField
              label="Código"
              value={this.state.produto.id_produto}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    id_produto: e.target.value
                  }
                })
              }
              style={{ width: 300, margin: 10 }}
            ></TextField>
            <TextField
              label="Produto"
              value={this.state.produto.nome_produto}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    nome_produto: e.target.value
                  }
                })
              }
              style={{ width: 620, margin: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        this.setState({ openModalSearchProdutos: true });
                        this.setState({ search_product_add: true });
                        this.props
                          .getProduct({
                            query: this.state.produto.nome_produto.trim().replace(' ', '%') + '%'
                          })
                          .then(() => {
                            this.setState({ search_product_add: false });
                          });
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            ></TextField>
            <TextField
              label="De"
              value={this.state.produto.preco_original}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    preco_original: e.target.value
                  }
                })
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
              style={{ width: 193, margin: 10 }}
            ></TextField>
            <TextField
              label="Por"
              value={this.state.produto.preco_dois}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    preco_dois: e.target.value
                  }
                })
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
              style={{ width: 193, margin: 10 }}
            ></TextField>
            <TextField
              label="Gramatura"
              value={this.state.produto.gramatura}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    gramatura: e.target.value
                  }
                })
              }
              style={{ width: 193, margin: 10 }}
            ></TextField>
            <TextField
              label="Categoria"
              value={this.state.produto.categoria}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    categoria: e.target.value
                  }
                })
              }
              style={{ width: 300, margin: 10 }}
            ></TextField>
            <TextField
              label="Observação"
              value={this.state.produto.observacao}
              shrink={true}
              disabled={false}
              onChange={e =>
                this.setState({
                  produto: {
                    ...this.state.produto,
                    observacao: e.target.value
                  }
                })
              }
              style={{ width: 300, margin: 10 }}
            ></TextField>
          </div>
        </div>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'md'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          disableBackdropClick
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {this.state.openModalSearchProdutos && (
          <ModalSearchProdutos
            open={this.state.openModalSearchProdutos}
            dataSearchProdutos={this.props.products_add}
            isLoadingSearchProdutos={this.state.search_product_add}
            onClose={() => {
              this.setState({ openModalSearchProdutos: false });
              this.props.cleanProductsForAdd();
            }}
            onClickSelecionar={obj => {
              this.setState({ openModalSearchProdutos: false });
              this.setState(
                {
                  produto: {
                    ...this.state.produto,
                    imagem_produto: obj.LINK_S3,
                    ean: obj.EAN,
                    nome_produto: obj.produto,
                    preco_original: 0,
                    preco_dois: 0,
                    gramatura: '',
                    categoria: ''
                  }
                },
                () => {
                  this.props.cleanProductsForAdd();
                }
              );
            }}
          ></ModalSearchProdutos>
        )}
        {this.state.openModalSerpApi && (
          <ModalSerpApi
            open={this.state.openModalSerpApi}
            onClose={() => {
              this.setState({ openModalSerpApi: false });
            }}
            nome_produto={this.state.produto.nome_produto}
            onClickSelecionar={obj => {
              this.setState({
                produto: {
                  ...this.state.produto,
                  imagem_produto: obj
                },
                openModalSerpApi: false
              });
            }}
          ></ModalSerpApi>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  gridList: {
    width: 500,
    height: 450
  },
  bannerPreview: {
    height: 50,
    borderRadius: 10
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({ ...TabloideDigitalReducer });
const mapDispatchToProps = {
  getProductPhoto,
  getProduct,
  cleanProductsForAdd
};

const AdicionarProdutoConnect = connect(mapStateToProps, mapDispatchToProps)(AdicionarProduto);

export default withStyles(styles)(AdicionarProdutoConnect);
