import { Divider, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Countup, CustomTable, LinearProgress, MyButton, Select } from '../../../utils/components';
import MyAlert from '../../../utils/helpers/Alert';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import {
  deleteProduto,
  getArquivos,
  getCategoriasDelivery,
  getImagemProduto,
  getLojas,
  getProdutosDelivery,
  onChange,
  postArquivoProdutosDelivery,
  postProdutoDelivery,
  putProdutoDelivery
} from '../action/action';
import ModalCriarProduto from './ModalCriarProduto';
import ModalEditaImagem from './ModalEditaImagem';
import ModalImportaArquivo from './ModalImportaArquivo';

const _myAlert = new MyAlert();

function Produtos(props) {
  let poolingArquivos;
  const { classes } = props;
  const [modalCriaProduto, setModalCriaProduto] = useState(true);
  const [modalImportaArquivo, setModalImportaArquivo] = useState(false);
  const [modalEditaImagem, setModalEditaImagem] = useState(false);
  const [editImagemProduto, setImagemProduto] = useState({ base64: null });
  const [loja, setLoja] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [status, setStatus] = useState(null);
  const [codInterno, setCodInterno] = useState(null);
  const [lookupCategoria, setLookupCategoria] = useState({});
  const [rowProduto, setRowProduto] = useState({});

  useEffect(() => {
    // props.getImagemProduto();
    props.getCategoriasDelivery();
    props.getProdutosDelivery();
    props.getLojas();
    props.getArquivos();
    poolingArquivos = setInterval(() => props.getArquivos(), 40000);
  }, []);

  useEffect(() => () => clearInterval(poolingArquivos), []);

  useEffect(() => {
    if (
      props.categorias.filter(categoria => categoria.ativo === 1).length > 0 &&
      Object.keys(lookupCategoria).length <= 0
    ) {
      const objLookupCategoria = {};
      props.categorias
        .filter(categoria => categoria.ativo === 1)
        .map(categoria =>
          Object.assign(objLookupCategoria, {
            [categoria.guid_categoria]: categoria.nome_categoria
          })
        );
      setLookupCategoria(objLookupCategoria);
    }
  });

  function onSave() {
    const objPostProduto = {
      nome_produto: props.produtoDelivery.nome_produto,
      id_categoria: props.produtoDelivery.categoria_produto
        ? props.produtoDelivery.categoria_produto.guid_categoria
        : null,
      id_loja: props.produtoDelivery.loja_produto
        ? props.produtoDelivery.loja_produto.id_loja
        : null,
      limite_produto: props.produtoDelivery.limite_produto,
      preco_produto: props.produtoDelivery.preco_produto,
      emb_produto: props.produtoDelivery.unid_medida_produto
        ? props.produtoDelivery.unid_medida_produto.value
        : null,
      medida_produto: props.produtoDelivery.medida_produto,
      ean_produto: props.produtoDelivery.ean_produto,
      imagem_produto: props.produtoDelivery.imagem_produto
    };
    props.postProdutoDelivery(objPostProduto);
    setModalCriaProduto(false);
  }

  function filtroProduto(id_loja, guid_categoria, ativo, cod_interno) {
    const objFiltro = {
      id_loja: id_loja ? id_loja.id_loja : null,
      guid_categoria: guid_categoria ? guid_categoria.guid_categoria : null,
      ativo: ativo ? ativo.value : null,
      cod_interno: cod_interno ? cod_interno.cod_interno_produto : null
    };
    props.getProdutosDelivery(objFiltro);
  }

  function onUploadArquivo(arquivo) {
    props.postArquivoProdutosDelivery(arquivo, () =>
      filtroProduto(loja, categoria, status, codInterno)
    );

    setModalImportaArquivo(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Produtos do pedido
        </Typography>
      </div>
      <div className={classes.actions}>
        <section style={{ width: '100%' }}>
          <Typography className={classes.label} variant="h6" noWrap>
            Filtrar por
          </Typography>
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            <section
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '70%'
              }}
            >
              <div
                style={{
                  width: '20vw'
                }}
              >
                <Select
                  label="Lojas"
                  options={props.lojas ? props.lojas.filter(loja => loja.tipo_pedido) : []}
                  labelKey="nome_loja"
                  valueKey="id_loja"
                  onChange={eLoja => {
                    setLoja(eLoja);
                    filtroProduto(eLoja, categoria, status, codInterno);
                    props.getCategoriasDelivery(eLoja ? eLoja.id_loja : null);
                  }}
                />
              </div>
              <div
                style={{
                  width: '16vw'
                }}
              >
                <Select
                  label="Categorias"
                  labelKey="nome_categoria"
                  valueKey="guid_categoria"
                  options={props.categorias.filter(categoria => categoria.ativo === 1)}
                  disabled={!loja}
                  onChange={eCategoria => {
                    setCategoria(eCategoria);
                    filtroProduto(loja, eCategoria, status, codInterno);
                  }}
                />
              </div>
              <div
                style={{
                  width: '12vw'
                }}
              >
                <Select
                  label="Código interno"
                  onChange={codigo => {
                    setCodInterno(codigo);
                    filtroProduto(loja, categoria, status, codigo);
                  }}
                  labelKey="cod_interno_produto"
                  valueKey="cod_interno_produto"
                  options={props.produtos}
                />
              </div>
              <div
                style={{
                  width: '16vw'
                }}
              >
                <Select
                  label="Status"
                  onChange={eStatus => {
                    setStatus(eStatus);
                    filtroProduto(loja, categoria, eStatus, codInterno);
                  }}
                  options={[
                    { label: 'Inativo', value: 0 },
                    { label: 'Ativo', value: 1 }
                  ]}
                />
              </div>
            </section>

            <section>
              <MyButton
                onClick={() => setModalImportaArquivo(true)}
                size="large"
                className={classes.button}
                variant="text"
                color="primary"
              >
                Importar arquivo
              </MyButton>
              {/* <MyButton
                onClick={() => setModalCriaProduto(true)}
                size="large"
                className={classes.button}
                variant="text"
                color="primary"
              >
                Criar produto
              </MyButton> */}
            </section>
          </section>
        </section>
      </div>
      <Divider className={classes.divider} />
      <div
        className={classes.content}
        style={{
          display: 'flex',
          height: '100%',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {props.arquivos
          .filter(arquivo => !arquivo.hidden)
          .map(arquivo => (
            <section
              style={{
                width: '100%',
                padding: '2vh 1vw',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <span>
                {arquivo.status === 0
                  ? `Importando o
                arquivo ${arquivo.nome_arquivo}.`
                  : arquivo.status === 1
                  ? `Arquivo ${arquivo.nome_arquivo} importado com sucesso.`
                  : `Erro ao importar o arquivo ${arquivo.nome_arquivo}.`}
              </span>
              <section
                style={{
                  marginTop: '1vh',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <i style={{ fontSize: '25pt', marginRight: '2vw' }} class="fas fa-file-csv" />
                {arquivo.status === 0 ? (
                  <LinearProgress />
                ) : arquivo.status === 1 ? (
                  <MyButton onClick={() => filtroProduto(loja, categoria, status, codInterno)}>
                    Atualizar lista de produtos
                  </MyButton>
                ) : (
                  <MyButton>Reprocessar arquivo</MyButton>
                )}
              </section>
            </section>
          ))}

        <CustomTable
          data={props.produtos || []}
          isLoading={props.loadingDelivery}
          title=""
          columnsButton
          pageSize={5}
          paging
          search
          options={{
            rowStyle: rowData => (rowData.ativo ? {} : { backgroundColor: '#ffcdd2' })
          }}
          headerStyle={{ backgroundColor: '#eee', zIndex: 0 }}
          style={{
            border: 'none',
            boxShadow: 'none',
            width: '100%',
            backgroundColor: '#eee'
          }}
          columns={[
            {
              title: 'Foto',
              field: 'imagem_produto',
              render: rowData => (
                <img
                  crossOrigin="anonymous"
                  alt={rowData.nome_produto}
                  src={rowData.imagem_produto}
                  style={{ width: 75, height: 75 }}
                />
              )
              // editComponent: ({ rowData }) => (
              //   <section
              //     style={{
              //       display: 'flex',
              //       flexDirection: 'column',
              //       alignItems: 'center',
              //     }}
              //   >
              //     <img
              //       alt={rowData.nome_produto}
              //       src={editImagemProduto.base64 || rowData.imagem_produto}
              //       style={{ width: 75, height: 75 }}
              //     />
              //     <div style={{ marginTop: '1vh' }}>
              //       <MyButton
              //         onClick={() => {
              //           setModalEditaImagem(true);
              //           setRowProduto(rowData);
              //         }}
              //         component="span"
              //         color="primary"
              //         variant="text"
              //       >
              //         Escolher foto
              //       </MyButton>
              //     </div>
              //   </section>
              // ),
            },
            {
              title: 'Produto',
              field: 'nome_produto'
            },
            {
              title: 'Categoria',
              field: 'id_categoria',
              editable: loja ? 'always' : 'never',
              lookup: lookupCategoria
            },
            {
              title: 'Código interno',
              field: 'cod_interno_produto'
            },

            {
              title: 'Gramatura',
              field: 'medida_produto',
              type: 'numeric',
              render: rowData => (
                <Countup start={0} end={rowData.medida_produto} duration={1} decimals={3} />
              )
            },
            {
              title: 'Limite de compra',
              field: 'limite_produto',
              type: 'numeric'
            },
            {
              title: 'Preço',
              field: 'preco_produto',
              type: 'numeric',
              render: rowData => (
                <Countup
                  prefix="R$ "
                  start={0}
                  end={rowData.preco_produto}
                  duration={1}
                  decimals={2}
                />
              )
            },
            {
              title: 'Loja',
              field: 'nome_loja',
              editable: 'never'
            }
          ]}
          deleteText="Deseja excluir esse produto ?"
          editable={{
            onRowUpdate: newData =>
              new Promise(resolve => {
                props.putProdutoDelivery(
                  {
                    ...newData,
                    imagem_produto: editImagemProduto.base64
                      ? editImagemProduto
                      : newData.imagem_produto
                  },
                  () => filtroProduto(loja, categoria, status, codInterno)
                );
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                props.deleteProduto(oldData, () =>
                  filtroProduto(loja, categoria, status, codInterno)
                );
                resolve();
              })
          }}
          actions={[
            rowData => ({
              icon: rowData.ativo ? 'remove' : 'add',
              tooltip: rowData.ativo ? 'Inativar produto' : 'Ativar produto',
              onClick: (event, rowData) =>
                _myAlert.alertConfirm(
                  `Deseja ${rowData.ativo ? 'inativar produto' : 'ativar produto'} ?`,
                  () =>
                    props.putProdutoDelivery(
                      {
                        ...rowData,
                        ativo: rowData.ativo ? 0 : 1
                      },
                      () => filtroProduto(loja, categoria, status, codInterno)
                    )
                )
            })
          ]}
        />
      </div>
      {modalEditaImagem && (
        <ModalEditaImagem
          row={rowProduto}
          open={modalEditaImagem}
          onClose={() => setModalEditaImagem(false)}
          onSave={newImage => {
            setImagemProduto(newImage);
            setModalEditaImagem(false);
          }}
        />
      )}
      {modalCriaProduto && (
        <ModalCriarProduto
          open={modalCriaProduto}
          onClose={() => {
            setModalCriaProduto(false);
            props.getCategoriasDelivery();
          }}
          onSave={() => onSave()}
          onChange={props.onChange}
        />
      )}
      {modalImportaArquivo && (
        <ModalImportaArquivo
          open={modalImportaArquivo}
          onClose={() => setModalImportaArquivo(false)}
          onSave={file => onUploadArquivo(file)}
        />
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2) },
  content: {
    flexGrow: 1,
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY }
});

const mapStateToProps = ({ DeliveryReducer }) => ({ ...DeliveryReducer });

const mapDispatchToProps = {
  getLojas,
  getProdutosDelivery,
  getCategoriasDelivery,
  getImagemProduto,
  getArquivos,
  postProdutoDelivery,
  postArquivoProdutosDelivery,
  putProdutoDelivery,
  deleteProduto,
  onChange
};

const ProdutosConn = connect(mapStateToProps, mapDispatchToProps)(Produtos);

export default withStyles(styles)(ProdutosConn);
