import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import useDate from '../../../../hooks/date';
import { useHome } from '../../../../hooks/home';
import useStyles from '../../styles';
import Card from '../CardCampaign';
import DialogViewMore from '../DialogViewMore';

export default function Posts({ isCarousel = false }) {
  const classes = useStyles(),
    [openViewMore, setOpenViewMore] = useState(false),
    [posts, setPosts] = useState([]),
    [dataViewMore, setDataViewMore] = useState({}),
    { getDate } = useDate(),
    { campaigns } = useHome();

  useEffect(() => {
    let _posts = [];

    if (isCarousel) {
      _posts = campaigns.filter(campaign => campaign.tipo_campanha === 'INSTAGRAM_CAROUSEL');
    } else {
      _posts = campaigns.filter(campaign => campaign.tipo_campanha === 'INSTAGRAM');
    }

    setPosts(_posts);
  }, [campaigns]);

  return (
    <Grid container spacing={3}>
      {Boolean(posts.length) ? (
        posts.map(
          (post, index) =>
            index < 100 && (
              <Grid xs={4} key={post.ID_CAMPANHA} item>
                <Card
                  title={post.nome_campanha}
                  startDate={getDate('DD/MM/YYYY', post.data_inicio_campanha)}
                  endDate={getDate('DD/MM/YYYY', post.data_fim_campanha)}
                  image={post.whatsapp_url}
                  onClickMore={() => {
                    setOpenViewMore(true);
                    setDataViewMore({
                      id: post.ID_CAMPANHA,
                      name: post.nome_campanha,
                      type: post.tipo_campanha,
                      send_date: getDate('DD/MM/YYYY', post.data_envio),
                      start_date: getDate('DD/MM/YYYY', post.data_inicio_campanha),
                      end_date: getDate('DD/MM/YYYY', post.data_fim_campanha)
                    });
                  }}
                />
              </Grid>
            )
        )
      ) : (
        <Grid xs={12} item>
          <Typography style={{ color: '#616161' }} className={classes.cardTitle}>
            Não encontramos posts no instagram
          </Typography>
        </Grid>
      )}
      <DialogViewMore
        open={openViewMore}
        data={dataViewMore}
        onClose={() => setOpenViewMore(false)}
      />
    </Grid>
  );
}

