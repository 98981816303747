import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

// Using Components
import { Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Modal } from '../../../utils/components';

// Using Functions
import { setTypeImport, setRuleImport } from '../../../utils/helpers/Functions';

// Using Styles
import useStyles from './styles';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

export default function ModalViewDetail(props) {
  const classes = useStyles(),
    [attr, setAttr] = useState({ listTempCustomHeaders: [] });

  useEffect(() => {
    if (Boolean(props.open)) {
      getCustomHeader();
    }
  }, [props.open]);

  async function getCustomHeader() {
    const headers = await axios.get(props.data.header_link),
      objAttr = {
        ...props.data,
        nameImport: setTypeImport(props.data.type_import),
        nameRule: setRuleImport(props.data.rule_import),
        listTempCustomHeaders: headers.data,
      };
    setAttr(objAttr);
  }

  return (
    <Modal
      showModal={props.open}
      onClose={props.onClose}
      header="Detalhes da importação"
      size="sm"
    >
      <section style={{ padding: '1rem 5rem', display: 'grid', gap: '2rem' }}>
        <Typography
          style={{ fontWeight: '900', color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Review
        </Typography>
        <div className={classes.containerColsImport}>
          <section>
            <strong>Tipo de importação: &nbsp;</strong>
            <span>{attr.nameImport}</span>
          </section>
          <section>
            <strong>Arquivo: &nbsp;</strong>
            <span
              onClick={() => window.open(attr.file_link, '_blank')}
              style={{
                fontSize: '11pt',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {attr.file_name}
            </span>
          </section>
          <section>
            <strong>Colunas que serão importadas: &nbsp;</strong>
            <section className={classes.listColsImport}>
              {attr.listTempCustomHeaders.map((headers, key) => (
                <Fragment key={key}>
                  <span style={{ fontSize: '12pt' }}>{headers.label}</span>
                  <ArrowForward style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  <span style={{ fontSize: '12pt' }}>{headers.column}</span>
                </Fragment>
              ))}
            </section>
          </section>
          <section>
            <strong>Regra: &nbsp;</strong>
            <span>{attr.nameRule}</span>
          </section>
        </div>
      </section>
    </Modal>
  );
}
