import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import location from '../../../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const last_days = [
  { label: '5 dias', value: 5 },
  { label: '10 dias', value: 10 },
  { label: '15 dias', value: 15 },
  { label: '20 dias', value: 20 },
  { label: '30 dias', value: 30 },
  { label: '60 dias', value: 60 },
  { label: '90 dias', value: 90 },
];

const formatDate = date => {
  return moment(date).format('DD/MM/YYYY');
};

function Strategy({ product }) {
  const classes = useStyles();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState();
  const [companyStores, setCompanyStores] = useState();
  const [lastDay, setLastDay] = useState();

  useEffect(() => {
    (async () => {
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/stores`
      );

      setCompanyStores(response);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/product/offers/${product.sku}`,
        {
          stores: stores
            ? stores.map(item => item.id_loja).toString()
            : undefined,
          last_days: lastDay ? lastDay.value : 60,
        }
      );

      setData(response.content);
      setLoading(false);
    })();
  }, [stores, lastDay]);

  if (!companyStores || !data) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Filtro" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Autocomplete
                  options={last_days}
                  getOptionLabel={option => option.label}
                  onChange={(e, value) => {
                    setLastDay(value);
                  }}
                  defaultValue={{ label: '60 dias', value: 60 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Últimos dias"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={companyStores}
                  getOptionLabel={option => option.nome_loja}
                  onChange={(e, value) => {
                    setStores(value);
                  }}
                  multiple
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Lojas"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<SearchIcon />}
            >
              filtrar
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Ofertas" />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Table
              title=""
              isLoading={loading}
              columns={[
                { title: 'Nome', field: 'nome_oferta' },
                {
                  title: 'data de inicio',
                  field: 'data_inicio',
                  render: rowData => (
                    <span>{formatDate(rowData.data_inicio)}</span>
                  ),
                },
                {
                  title: 'data de termino',
                  field: 'data_fim',
                  render: rowData => (
                    <span>{formatDate(rowData.data_fim)}</span>
                  ),
                },
                { title: 'Loja', field: 'NOME_LOJA' },
              ]}
              data={data}
              localization={location}
              options={{ search: false }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Strategy;
