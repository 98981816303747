import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button
} from "@material-ui/core";
import { postCupomSorteio } from "../actions/actions";
import {
  MyButton,
  DatePicker,
  TextField,
  Modal
} from "../../../utils/components";
import { inputMoney } from "../../../utils/helpers/Functions";
import MyDate from "../../../utils/helpers/Date";
import "@webscopeio/react-textarea-autocomplete/style.css";

const steps = ["Informações do cupom", "Iniciar"];
const _myDate = new MyDate();

class ModalCriarCupomSorteio extends Component {
  state = {
    activeStep: 0,
    nomeCupom: "",
    valorGasto: 0,
    qtdeCupom: 0,
    dataInicio: moment(),
    dataFim: moment()
  };

  onSave() {
    const objSorteio = {
      nomeCupom: this.state.nomeCupom,
      valorGasto: parseFloat(this.state.valorGasto),
      qtdeCupom: this.state.qtdeCupom,
      dataInicio: _myDate.getDate("YYYY-MM-DD HH:mm", this.state.dataInicio),
      dataFim: _myDate.getDate("YYYY-MM-DD HH:mm", this.state.dataFim)
    };
    this.props.postCupomSorteio(objSorteio);
    this.props.onClose();
  }

  header(props, state) {
    return (
      <Fragment>
        Cupom de sorteio
        <div
          style={{
            width: "100%"
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <Divider />
      </Fragment>
    );
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 1 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
            disabled={
              !state.nomeCupom ? true : state.activeStep === 1 ? true : false
            }
          >
            Próximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { nomeCupom, dataInicio, dataFim, valorGasto, qtdeCupom } = state;
    return (
      <section style={{ padding: "5rem 20rem", display: "grid", gap: "2rem" }}>
        <Typography style={{ fontWeight: "900" }} color="primary" variant="h5">
          Informações do cupom
        </Typography>

        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          value={nomeCupom}
          onChange={event => this.setState({ nomeCupom: event.target.value })}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DatePicker
            dateAndTime
            label="Início do cupom"
            onChange={date => this.setState({ dataInicio: date })}
            value={dataInicio}
          />

          <DatePicker
            dateAndTime
            label="Término do cupom"
            onChange={date => this.setState({ dataFim: date })}
            value={dataFim}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="A cada R$"
            className="form-control"
            autoComplete="none"
            value={valorGasto}
            onChange={event =>
              this.setState({ valorGasto: inputMoney(event.target.value) })
            }
          />

          <TextField
            type="number"
            label="Quantidade de cupom"
            className="form-control"
            autoComplete="none"
            value={qtdeCupom}
            onChange={event => this.setState({ qtdeCupom: event.target.value })}
          />
        </div>
      </section>
    );
  }

  step2(props, state) {
    const { nomeCupom, dataInicio, dataFim, valorGasto, qtdeCupom } = state;
    return (
      <section>
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre o cupom
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome do cupom</strong>
              </p>
              <span>{nomeCupom}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(dataInicio).format(
                "DD/MM/YYYY HH:mm"
              )} Até ${moment(dataFim).format("DD/MM/YYYY HH:mm")}`}</span>
            </section>

            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Regra do cupom</strong>
              </p>
              <span>{`A cada ${parseFloat(
                valorGasto
              )} reais, vale ${qtdeCupom} cupons `}</span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={() => this.onSave()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose, open } = this.props;
    const { activeStep } = this.state;

    return (
      <Modal
        size="lg"
        showModal={open}
        onClose={onClose}
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
      >
        {this.renderStep(activeStep, this.props, this.state)}
      </Modal>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = state => ({});

const mapDispatchToProps = { postCupomSorteio };

const ModalCriarCupomSorteioConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCriarCupomSorteio);

export default withStyles(styles)(ModalCriarCupomSorteioConnect);
