import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DSAWS from 'utils/helpers/DSAWS';
import * as Yup from 'yup';

import EmptyImage from '../../../../assets/icons/marketing/EmptyImage.svg';
import EmptyImageRed from '../../../../assets/icons/marketing/EmptyImageRed.svg';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import Fetch from '../../../../utils/helpers/Fetch';
import { asyncForEach, toBase64 } from '../../../../utils/helpers/Functions';

export default function FormDialog(props) {
  const open = props.open;
  const theme = useTheme();

  const [query, setQuery] = useState({});

  const [imageUrl, setImageUrl] = useState(null);
  const [loadingPostBackground, setLoadingPostBackground] = useState(false);
  const [loadingImagePreview, setLoadingImagePreview] = useState(false);
  const companyInfo = JSON.parse(localStorage.getItem('companyId'));

  const [formats, setFormats] = useState([]);
  // const [segments, setSegments] = useState([]);
  // const [occasions, setOccasions] = useState([]);

  const [name, setName] = useState('');

  const NEW_BACKGROUND_VALIDATION = Yup.object().shape({
    formats: Yup.array().nullable().required('*Campo obrigatório'),
    name: Yup.string().nullable().required('*Campo obrigatório'),
    imageUrl: Yup.mixed().nullable().required('Insira uma imagem')
  });

  const formik = useFormik({
    initialValues: {
      formats: [],
      name: null,
      imageUrl: null
    },
    validationSchema: NEW_BACKGROUND_VALIDATION,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: () => {
      handleSubmit();
    }
  });

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // async function fetchSegments() {
  //   try {
  //     await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(
  //       response => {
  //         setSegments(response.content);
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function fetchOccasions() {
  //   try {
  //     await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/occasions`).then(
  //       response => {
  //         setOccasions(response.content);
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  useEffect(() => {
    fetchFormats();
    // fetchSegments();
    // fetchOccasions();
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  function handleQueryChange(state, value = []) {
    setQuery(prevState => ({
      ...prevState,
      [state]: Array.isArray(value) ? value.map(v => v.id) : value
    }));
  }

  async function onUploadFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      setLoadingImagePreview(true);
      const imageEdit = await toBase64(event.target.files[0]);

      const splitName = imageEdit.name.split('.'),
        objFileTemp = {
          file: imageEdit.blob,
          name: new Date().getTime(),
          type: imageEdit.type,
          bucketName: 'datasalesio-imagens',
          folderName: ``,
          subFolderName: `my-backgrounds/idCompany-${companyInfo}`,
          extension: splitName[1],
          distinctName: false
        };

      const { Location } = await DSAWS.saveS3(objFileTemp);

      formik.setFieldValue('imageUrl', Location);
      setImageUrl(Location);
      setLoadingImagePreview(false);
    }
  }

  async function postBackground() {
    try {
      if (Object.keys(formik.errors).length > 0) {
        setLoadingPostBackground(false);

        return;
      }
      setLoadingPostBackground(true);

      await Fetch(
        `https://api-backoffice.datasales.io/studio-${AMBIENTE}/backgrounds`,
        {
          imageURL: imageUrl,
          formatId: query.formats[0],
          segmentId: null,
          occasionId: null,
          name: name,
          companyId: localStorage.getItem('companyId')
        },
        'POST'
        // null,
        // null,
        // false
      );

      setLoadingPostBackground(false);
      props.formatBackgroundSaved(query.formats[0]);

      handleClose();
    } catch (err) {
      console.log(err);
      setLoadingPostBackground(false);
    }
  }

  const handleSubmit = () => {
    // console.log('formik.values', formik.values);
    // console.log('formik.errors', formik.errors);

    postBackground();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          fullWidth
        >
          <DialogContent>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                width={'50%'}
                height={'100%'}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {loadingImagePreview ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={'#F5f5f5'}
                    width="100%"
                    height="380px"
                  >
                    <CircularProgress variant="indeterminate" color="info" />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={Boolean(formik.errors.imageUrl) ? '#D54D4D0D' : '#F5f5f5'}
                    border={Boolean(formik.errors.imageUrl) ? '1px solid #D54D4D' : null}
                    width="100%"
                    height="380px"
                  >
                    <img
                      src={
                        imageUrl
                          ? imageUrl
                          : Boolean(formik.errors.imageUrl)
                          ? EmptyImageRed
                          : EmptyImage
                      }
                      alt={'image to up'}
                      style={{
                        width: imageUrl ? '100%' : null,
                        maxHeight: imageUrl ? '100%' : null,
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                )}
                <Box alignSelf="flex-start">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={onUploadFile}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      disabled={loadingImagePreview}
                      sx={{
                        marginTop: '16px',
                        background: '#F5F5F5',
                        color: '#2813AD',
                        border: 0,

                        '&:hover': {
                          border: 0
                        }
                      }}
                    >
                      Importar imagem
                      {imageUrl && <DoneAllIcon style={{ marginLeft: 10 }} />}
                    </Button>
                  </label>
                </Box>
              </Box>
              <Box width={'50%'} marginLeft="32px">
                <div>
                  <Typography fontSize={16} fontWeight={500} lineHeight={3} marginBottom={0}>
                    Adicione um fundo do seu computador
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={1.4}
                    marginBottom={3}
                    color="#616161"
                  >
                    Adicione informações do seu fundo para encontrá-las mais facilmente.
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={formats}
                    disabled={formats.length <= 0}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => {
                      handleQueryChange('formats', value);
                      formik.setFieldValue('formats', value);
                    }}
                    // defaultValue={[{ id: 2, name: 'Encarte' }]}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="info"
                        label="Formato"
                        placeholder="Selecione o formato"
                        error={Boolean(formik.errors.formats)}
                        helperText={
                          Boolean(formik.errors.formats)
                            ? formik.errors.formats
                            : 'Seu fundo aparecerá no formato que você salvar'
                        }
                        sx={{
                          margin: '0 20px 20px 0px'
                        }}
                      />
                    )}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Nome do fundo"
                    placeholder="Digite um nome para o seu fundo"
                    variant="outlined"
                    color="info"
                    error={Boolean(formik.errors.name)}
                    helperText={Boolean(formik.errors.name) && formik.errors.name}
                    onChange={({ target: { value } }) => {
                      setName(value);
                      formik.setFieldValue('name', value);
                    }}
                    sx={{
                      padding: '0px 0px 20px 0px',
                      width: '100%'
                    }}
                  />

                  <Box display="flex" alignItems="center">
                    <Button onClick={handleClose} sx={{ color: '#616161' }}>
                      Cancelar
                    </Button>
                    <Button
                      disabled={loadingPostBackground}
                      onClick={formik.handleSubmit}
                      color="info"
                      variant="contained"
                      sx={{
                        marginLeft: '16px',
                        // background: loadingPostBackground? 'inherit' : '#2813AD',
                        // color: '#FFF',
                        border: 0,
                        width: '80px',
                        height: '38px'

                        // '&:hover': {
                        //   border: 0,
                        //   background: '#2813AD'
                        // }
                      }}
                    >
                      Salvar
                    </Button>
                    {/* <Button type='submit' onClick={handleSubmit}> Arrocha</Button> */}
                  </Box>
                </div>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </form>
  );
}

