import { createContext, useState, useContext } from 'react';

const TabloidsContext = createContext({});

const TabloidsProvider = ({ children }) => {
  const [data, setData] = useState(16);
  const [draweSideOpened, setDraweSideOpened] = useState(null);

  const changeData = () => {
    setData(prev => prev + 1);
  };

  return (
    <TabloidsContext.Provider value={{ data, changeData, draweSideOpened, setDraweSideOpened }}>
      {children}
    </TabloidsContext.Provider>
  );
};

function useTabloids() {
  const context = useContext(TabloidsContext);

  if (!context) {
    throw new Error('algum erro no context');
  }
  return context;
}

export { TabloidsProvider, useTabloids, TabloidsContext };

