import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Slide,
  InputBase,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import {
  CloseRounded as CloseIcon,
  SendRounded as SendIcon,
} from '@material-ui/icons';
import ReactPlayer from 'react-player';
import { TabPanel, Chip } from '../../../utils/components';
import useStyles from '../styles';
import { asyncForEach, toBase64 } from '../../../utils/helpers/Functions';
import DSAWS from '../../../utils/helpers/DSAWS';

export default function PreviewMidia({
  open,
  files,
  onClose,
  onVideo,
  onImage,
}) {
  const theme = useTheme(),
    companyInfo = JSON.parse(localStorage.getItem('companyId')),
    classes = useStyles(),
    [tabActive, setTabActive] = useState(0),
    [newFiles, setNewFiles] = useState([]),
    [loadFiles, setLoadFiles] = useState(false),
    [uploadMidia, setUploadMidia] = useState(false);

  useEffect(() => {
    async function loadFilesBase64() {
      if (Boolean(files.length)) {
        setLoadFiles(true);
        const filesTemp = [];
        await asyncForEach(files, async file => {
          const newFile = await toBase64(file);
          filesTemp.push({
            base64: newFile.base64,
            typeFile: newFile.type.split('/')[0],
            type: newFile.type,
            name: newFile.name,
            file,
          });
        });
        setNewFiles(filesTemp);
        setLoadFiles(false);
      }
    }

    loadFilesBase64();
  }, [files]);

  function _onClose() {
    setNewFiles([]);
    setLoadFiles(false);
    setUploadMidia(false);
    setTabActive(0);
    onClose();
  }

  function _setCaption(caption) {
    if (Boolean(newFiles[tabActive])) {
      newFiles[tabActive].caption = caption;
    }
  }

  async function _sendMidia() {
    setUploadMidia(true);
    const linkFiles = [];
    await asyncForEach(newFiles, async file => {
      const splitName = file.name.split('.'),
        redirectFolderS3 = file.type.split('/'),
        objFileTemp = {
          file: file.file,
          name: `${file.file.lastModified}-${splitName[0]}`,
          type: file.type,
          bucketName: 'files-whatsapp',
          folderName: `${companyInfo}`,
          subFolderName: `${redirectFolderS3[0]}/`,
          extension: splitName[1],
          distinctName: false,
        },
        { Location } = await DSAWS.saveS3(objFileTemp);
      linkFiles.push({
        url: Location,
        type: file.typeFile,
        caption: file.caption,
      });
    });
    const video = linkFiles.filter(({ type }) => type === 'video'),
      image = linkFiles.filter(({ type }) => type === 'image');

    if (Boolean(video.length)) {
      onVideo(video);
    }
    if (Boolean(image.length)) {
      onImage(image);
    }

    _onClose();
  }

  return (
    <Slide direction="up" in={open}>
      <Grid item xs={12}>
        <Box
          style={{ backgroundColor: theme.palette.primary.main }}
          paddingX="2vw"
          paddingY="1vh"
          color="#FFF"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton
              style={{ marginRight: '1vw', color: '#FFF' }}
              onClick={() => _onClose()}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h5" style={{ color: '#FFF' }}>
              Pré-visualizar
            </Typography>
          </Box>

          <IconButton
            disabled={uploadMidia}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: '#FFF',
            }}
            onClick={() => _sendMidia()}
          >
            {Boolean(uploadMidia) ? (
              <CircularProgress style={{ color: '#FFF' }} size="1em" />
            ) : (
              <SendIcon />
            )}
          </IconButton>
        </Box>
        <Box
          paddingY="2vh"
          paddingX="4vw"
          display="flex"
          flexDirection="column"
          height="64vh"
          justifyContent="space-between"
        >
          <Box
            paddingY="1.5vh"
            paddingX="2.5vw"
            display="flex"
            justifyContent="center"
          >
            {Boolean(loadFiles) ? (
              <CircularProgress size="3em" />
            ) : (
              newFiles.map((file, index) => (
                <TabPanel
                  styleChild={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '50vh',
                  }}
                  key={index}
                  index={index}
                  value={tabActive}
                >
                  <Box key={index} display="flex" justifyContent="center">
                    {file.typeFile === 'video' ? (
                      <ReactPlayer
                        width="20%"
                        height="auto"
                        url={file.base64}
                        controls
                        playing
                      />
                    ) : file.typeFile === 'image' ? (
                      <img
                        style={{ maxWidth: '25%' }}
                        src={file.base64}
                        alt="preview"
                      />
                    ) : (
                      <span />
                    )}
                  </Box>
                  <InputBase
                    onKeyDown={({ keyCode, ctrlKey, shiftKey, altKey }) =>
                      keyCode === 13 &&
                      (ctrlKey || shiftKey || altKey) &&
                      _setCaption(file.caption + '\n')
                    }
                    onChange={({ target: { value } }) =>
                      value !== '\n' && _setCaption(value)
                    }
                    disabled={uploadMidia}
                    className={classes.inputMessage}
                    value={file.caption}
                    rowsMax={2}
                    placeholder="Adicionar uma legenda..."
                    fullWidth
                    multiline
                  />
                </TabPanel>
              ))
            )}
          </Box>
          <Box overflowX="scroll" display="flex" overflowY="clip">
            {Boolean(newFiles.length) &&
              newFiles.map((file, index) => (
                <Chip
                  clickable
                  active={tabActive === index}
                  onClick={() => setTabActive(index)}
                  label={file.name}
                  key={index}
                />
              ))}
          </Box>
        </Box>
      </Grid>
    </Slide>
  );
}
