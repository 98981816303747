import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

export default function CheckboxLabels({
  checked,
  onChange,
  value = false,
  label,
  disabled,
  styleControl,
  classNameLabel
}) {
  return (
    <FormControlLabel
      style={styleControl}
      classes={{ label: classNameLabel }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          value={value}
          color="primary"
          size="medium"
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}

