import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    appBar: {
      position: 'relative',
      border: 'none',
    },
    avatar: { background: theme.palette.primary.main },
    budgedCardContent: { paddingLeft: theme.spacing(4) },
    container: {
      display: 'flex',
    },
    title: {
      flex: 1,
    },
    content: {
      padding: theme.spacing(2, 4),
    },
    formContainer: {
      height: '82vh',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    previewContainer: {
      maxWidth: '100%',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    preview: {
      width: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    popper: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    tab: { textTransform: 'none' },
    listItem: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
    },
    listItemSelected: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
  })
);

export default styles;
