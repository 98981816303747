import { makeStyles, Chip } from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  img: {
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
  },
}));

function TileTemplate({ item }) {
  const classes = useStyles();

  const [onMouse, setOnMouse] = useState(false);

  return (
    <>
      <img
        className={classes.img}
        src={item.imageURL}
        alt={item.name + ' - ' + item.id}
        title={item.name + ' - ' + item.id}
        loading="lazy"
        style={{ position: 'relative' }}
        onMouseEnter={() => setOnMouse(true)}
        onMouseLeave={() => setOnMouse(false)}
      />

      {!onMouse && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            // height: '40%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: 10,
          }}
        >
          <span style={{ color: 'white' }}>{item.name}</span>
          <div style={{ width: '100%' }}>
            {(item?.tags ? item.tags.split(',') : []).map(tag => (
              <Chip size="small" label={tag} style={{ margin: 3 }} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default TileTemplate;
