import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FacebookIcon from '@material-ui/icons/Facebook';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PeopleIcon from '@material-ui/icons/People';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DatePicker } from '@material-ui/pickers';
import axios from 'axios';
import Table from 'material-table';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useHistory } from 'react-router-dom';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import { useAds } from '../../../../hooks/ads';
import localization from '../../../../localization/table';
import api from '../../../../utils/helpers/Fetch';
import getInitials from '../../../../utils/helpers/getInitials';
import Accounts from './components/Accounts';
import AdsDetails from './components/AdsDetails';
import CreateAds from './components/CreateAds';
import ProfileMenu from './components/ProfileMenu';
import useStyles from './styles';

const status = {
  ACTIVE: 'Ativo',
  PAUSED: 'Pausada',
  DELETED: 'Deletada',
  PENDING_REVIEW: 'Revisão pendente',
  DISAPPROVED: 'Reprovada',
  PREAPPROVED: 'Pré-aprovado',
  PENDING_BILLING_INFO: 'Informações de faturamento pendentes',
  CAMPAIGN_PAUSED: 'Campanha Pausada',
  ARCHIVED: 'Arquivada',
  ADSET_PAUSED: 'Conjunto de anúncios pausado',
  IN_PROCESS: 'Em análise',
  WITH_ISSUES: 'Com problemas',
};

function Campaigns() {
  const classes = useStyles();

  const { user, signIn, selectAccount } = useAds();

  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  const history = useHistory();

  const [ads, setAds] = useState();
  const [adsId, setAdsId] = useState(1);
  const [accounts, setAccounts] = useState();
  const [loading, setLoading] = useState(false);
  const [, setAccountsIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [adsDetailsIsOpen, setAdsDetailsIsOpen] = useState(false);
  const [accountsIsOpen, setAccountsIsOpen] = useState(false);
  const [createAdsIsOpen, setCreateAdsIsOpen] = useState(false);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleAdsDetailsOpen = id => {
    setAdsId(id);
    setAdsDetailsIsOpen(true);
  };

  const handleAdsDetailsClose = useCallback(() => {
    setAdsId(undefined);
    setAdsDetailsIsOpen(false);
  }, []);

  // const handleAccountsOpen = () => {
  //   setAccountsIsOpen(true);
  // };

  const handleAccountsClose = useCallback(() => {
    setAccountsIsOpen(false);
  }, []);

  const handleCreateAdsOpen = () => {
    setCreateAdsIsOpen(true);
  };

  const handleCreateAdsClose = useCallback(() => {
    setCreateAdsIsOpen(false);
  }, []);

  const handleProfileMenuOpen = event => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const findAds = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
        { access_token: user.access_token, ad_account_id: user.ad_account_id }
      );

      const { content } = response;

      setAds(content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [user]);

  const findAdsAccounts = useCallback(async () => {
    try {
      setAccountsIsLoading(true);
      const response = await axios.get(
        'https://graph.facebook.com/v10.0/me/adaccounts',
        {
          params: {
            access_token: user.access_token,
            fields: JSON.stringify([
              'id',
              'account_id',
              'name',
              'account_status',
              'disable_reason',
              'funding_source',
              'funding_source_details',
            ]),
          },
        }
      );

      const { data } = response.data;

      setAccounts(data);
    } catch (err) {
      console.log(err);
    } finally {
      setAccountsIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    findAdsAccounts();
  }, [findAdsAccounts]);

  useEffect(() => {
    findAds();
  }, []);

  const handleSignIn = useCallback(
    async data => {
      try {
        await signIn(data);
      } catch (err) {
        console.log(err);
      }
    },
    [signIn]
  );

  const handleCreateAds = useCallback(
    async values => {
      try {
        const data = {
          ...values,
          user_id: user.id,
          ad_account_id: user.ad_account_id,
          access_token: user.access_token,
          adSet: {
            ...values.adSet,
            targeting: JSON.stringify(values.adSet.targeting),
            optimization_goal: values.campaign.objective,
          },
          adCreative: {
            name: values.adCreative.name,
            object_story_spec: {
              page_id: values.adSet.page_id,
              link_data: {
                image_hash: values.adCreative.image.hash,
                link: values.adCreative.link,
                message: values.adCreative.message,
              },
            },
          },
        };

        await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
          data,
          'POST'
        );

        findAds();

        enqueueSnackbar('Campanha criada com sucesso', { variant: 'success' });
        handleCreateAdsClose();
      } catch (err) {
        if (err.isAxiosError) {
          const message =
            err.response && err.response.data && err.response.data.message;

          enqueueSnackbar(message, { variant: 'error' });
        }
        enqueueSnackbar('Erro ao criar campanha', { variant: 'error' });
      }
    },
    [user, enqueueSnackbar]
  );

  const handleUpdateAds = useCallback(
    async data => {
      try {
        await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/ads/${data.id}`,
          {
            ...data,
            access_token: user.access_token,
          },
          'PUT'
        );

        enqueueSnackbar('Campanha atualizada com sucesso', {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar('Error ao atualizar campanha', { variant: 'error' });
        console.log(err);
      } finally {
        findAds();
      }
    },
    [user, enqueueSnackbar]
  );

  const handleDeleteAds = useCallback(
    id => {
      confirm({
        title: 'Deseja excluir a campanha?',
        description:
          'Se você excluir essa campanha, não será possível recuperá-la mais tarde.',
        confirmationText: 'Excluir',
      }).then(async () => {
        try {
          await api(
            `https://api.datasales.info/ads-manager-${AMBIENTE}/ads/${id}`,
            {
              access_token: user.access_token,
            },
            'DELETE'
          );

          enqueueSnackbar('Campanha removida com sucesso', {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar('Error ao remover campanha', { variant: 'error' });
          console.log(err);
        } finally {
          findAds();
        }
      });
    },
    [user, confirm, enqueueSnackbar]
  );

  if (!user) {
    return (
      <Box
        display="flex"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
        minWidth="100%"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">
              Conecte a uma conta de anúncios antes de fazer um impulsionamento
            </Typography>
          </Grid>
          <Grid item>
            <FacebookLogin
              appId="814619969130522"
              autoLoad
              fields="name,email,picture"
              scope="ads_management,public_profile, email,pages_show_list"
              callback={handleSignIn}
              render={renderProps => (
                <Button
                  className={classes.signInWithFacebookButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<FacebookIcon />}
                  onClick={renderProps.onClick}
                >
                  Continuar com o Facebook
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (user && !user.ad_account_id) {
    return (
      <Box
        display="flex"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
        minWidth="100%"
      >
        <Card variant="outlined">
          <CardContent>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h6">
                  Selecione abaixo a conta que será vinculada a este perfil
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <List>
                  {accounts &&
                    accounts.map(item => (
                      <ListItem
                        button
                        onClick={() => {
                          selectAccount(user.id, item.id);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            {getInitials(item.name)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={`Account ID: ${item.account_id}`}
                        />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Grid container direction="column" spacing={4}>
          <Grid item container justify="space-between" align="center">
            <Grid item>
              <Typography variant="h3" noWrap>
                Campanhas ADS
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                avatar={<Avatar alt={user.name} src={user.picture_url} />}
                label={user.name}
                clickable
                onClick={handleProfileMenuOpen}
                color="primary"
                variant="outlined"
                icon={<KeyboardArrowDownIcon />}
              />
              {/* <TextField
                placeholder="pesquisar campanha..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography>Filtrar por período</Typography>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item container spacing={2} xs={6}>
                <Grid item>
                  <DatePicker
                    inputVariant="outlined"
                    label="De"
                    format="DD/MM/YYYY"
                    animateYearScrolling
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    inputVariant="outlined"
                    label="Até"
                    format="DD/MM/YYYY"
                    animateYearScrolling
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={6} justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddIcon />}
                    onClick={handleCreateAdsOpen}
                  >
                    Criar Campanha
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<PeopleIcon />}
                    onClick={() => {
                      history.push('/ads/publicos');
                    }}
                  >
                    Públicos
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AccountCircleIcon />}
                    onClick={handleAccountsOpen}
                  >
                    Contas
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                action={
                  <IconButton onClick={findAds}>
                    <RefreshIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent className={classes.content}>
                <Table
                  isLoading={loading}
                  title=""
                  actions={[
                    {
                      icon: 'bar_chart',
                      label: 'Visualizar desempenho',
                      onClick: (_, rowData) => {
                        handleAdsDetailsOpen(rowData.id);
                      },
                    },
                    {
                      icon: 'delete',
                      label: 'Excluir campanha',
                      onClick: (_, rowData) => {
                        handleDeleteAds(rowData.id);
                      },
                    },
                  ]}
                  columns={[
                    {
                      title: 'Ativado',
                      render: rowData => (
                        <Switch
                          color="primary"
                          classes={{ switchBase: classes.switchBase }}
                          checked={rowData.status === 'ACTIVE'}
                          onChange={() => {
                            handleUpdateAds({
                              id: rowData.id,
                              status:
                                rowData.status === 'ACTIVE'
                                  ? 'PAUSED'
                                  : 'ACTIVE',
                            });
                          }}
                          name="status"
                        />
                      ),
                    },
                    { field: 'name', title: 'Nome' },
                    {
                      field: 'effective_status',
                      title: 'Status',
                      render: rowData => (
                        <Chip
                          className={classes[rowData.effective_status]}
                          label={status[rowData.effective_status]}
                          variant="outlined"
                        />
                      ),
                    },

                    // {
                    //   field: 'createdAt',
                    //   title: 'Criado em',
                    //   render: rowData => (
                    //     <span>
                    //       {moment(rowData.createdAt).format('DD/MM/YYYY')}
                    //     </span>
                    //   ),
                    // },
                  ]}
                  data={ads}
                  options={{ actionsColumnIndex: -1 }}
                  localization={localization}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {adsDetailsIsOpen && (
        <AdsDetails
          open={adsDetailsIsOpen}
          adsId={adsId}
          onClose={handleAdsDetailsClose}
        />
      )}
      {accountsIsOpen && (
        <Accounts open={accountsIsOpen} onClose={handleAccountsClose} />
      )}
      {createAdsIsOpen && (
        <CreateAds
          open={createAdsIsOpen}
          refreshAds={findAds}
          onSubmit={handleCreateAds}
          onClose={handleCreateAdsClose}
        />
      )}
      <ProfileMenu
        anchorEl={profileMenuAnchorEl}
        onClose={handleProfileMenuClose}
      />
    </React.Fragment>
  );
}

export default Campaigns;
