import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid, styled, Tab } from '@mui/material';
import useUser from 'hooks/api/users/useUser';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/types';
import Layout from 'utils/routes/components/Layout';

import FacebookIcon from '../../../../../assets/icons/marketing/FacebookOriginal.svg';
import InstagramIcon from '../../../../../assets/icons/marketing/InstagramOriginal.svg';
import TikTokIcon from '../../../../../assets/icons/marketing/tiktok.svg';
import WhatsappIcon from '../../../../../assets/icons/marketing/WhatsappOriginal.svg';
import { LabelDot } from '../../AdsDistribution/components/LabelDot';
import { ToDistribute } from '../DistributionChannels/ToDistribute';
import { useStyles } from './styles';
import { ChannelsProps } from './types/types';

const CustomFooter = styled(Box)({
  width: '100%',
  height: '10.41vh',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 40px',
  gap: '40px',
  bottom: 0,
  background: '#FFF',
  boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)'
});

export function DistributionChannels() {
  const styles = useStyles();
  const [tabs, setTabs] = useState<Array<string>>(['Onde distribuir']);
  const [value, setValue] = useState<string>('1');
  //const { data: userProfilesData, loading: loadingProfilesData, getUserProfiles } = useUser();
  const [enabledTabs, setEnabledTabs] = useState<string[] | []>(['1', '2']);
  const [channels, setChannels] = useState<ChannelsProps[]>([
    {
      grid: 4,
      title: 'Redes sociais',
      name: 'social-networks',
      selected: false,
      validated: false,
      disabled: false,
      distributed: false,
      channels: [{ icon: FacebookIcon }, { icon: InstagramIcon }, { icon: TikTokIcon }]
    },
    {
      grid: 4,
      title: 'Mensagem WhatsApp',
      name: 'whatsapp',
      selected: false,
      validated: false,
      disabled: true,
      distributed: false,
      channels: [{ icon: WhatsappIcon }]
    }
  ]);
  const [, updateState] = useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    return;
  };
  const { selectedShareableItem, adsDistribution, isLoadingAdsDistributionAdd } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
  );

  const onSelectedChannel = useCallback(
    (idChannel: number, isSelected: boolean) => {
      const newTabs = tabs;
      const tempChannel = channels;
      console.log({ isSelected });

      if (isSelected) {
        console.log('temp: ', tempChannel[idChannel].title);
        newTabs.push(tempChannel[idChannel].title);
      } else {
        const index = newTabs.indexOf(tempChannel[idChannel].title);
        newTabs.splice(index, 1);
      }
      tempChannel[idChannel] = { ...tempChannel[idChannel], selected: isSelected };
      setTabs(newTabs);
      setChannels(() => [...tempChannel]);
      forceUpdate();
    },
    [tabs, channels]
  );

  const renderTab = (tab: string) => {
    switch (tab.toUpperCase()) {
      case 'REDES SOCIAIS':
        return <h1>Redes Sociais</h1>;
      case 'ONDE DISTRIBUIR':
        return <ToDistribute channels={channels} onChange={onSelectedChannel} />;
      case 'MENSAGEM WHATSAPP':
        return <h1>Whatsapp</h1>;
    }
  };
  return (
    <Layout showBtnMenu={false}>
      <TabContext value={value}>
        <Grid item xs={12}>
          <Box className={styles.container}>
            <TabList onChange={handleChange} className={styles.indicatorSelect}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index.toString()}
                  icon={<LabelDot labelNumber={Number(index + 1)} dot={true} />}
                  label={tab}
                  iconPosition={'start'}
                  disabled={
                    parseInt(value) === index + 1 || parseInt(value) > index + 1 ? false : true
                  }
                  value={Number(index + 1).toString()}
                  className={styles.myTab}
                  sx={{
                    cursor: !enabledTabs.some(_tab => Number(_tab) === index + 1)
                      ? 'not-allowed !important'
                      : 'pointer',
                    pointerEvents: 'all !important',
                    paddingLeft: index === 0 ? '0px' : '0px',
                    color:
                      parseInt(value) === index + 1 || parseInt(value) > index + 1
                        ? '#2813AD !important'
                        : '#CFCFCF !important'
                  }}
                />
              ))}
            </TabList>
          </Box>
          <Grid container className={styles.gridContent}>
            {tabs.map((tab, index) => (
              <TabPanel
                key={index.toString()}
                value={Number(index + 1).toString()}
                style={{ padding: '0px 0px 0px' }}
              >
                {renderTab(tab)}
              </TabPanel>
            ))}
          </Grid>
          <CustomFooter>
            <Button
              color="info"
              onClick={() => {
                if (value === '1') {
                  history.back();
                } else {
                  setValue((parseInt(value) - 1).toString());
                }
              }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                if (tabs.length > 1 && parseInt(value) !== tabs.length) {
                  setValue((parseInt(value) + 1).toString());
                }
              }}
            >
              Continuar
            </Button>
          </CustomFooter>
        </Grid>
      </TabContext>
    </Layout>
  );
}
