import { makeStyles } from '@material-ui/core/styles';

import { primary } from '../../../constants/DefaultFonts';

const useStyles = makeStyles(theme => {
  return {
    container: {
      position: 'fixed'
    },
    root: {
      //border:'solid 1px blue',
      zIndex: 99999,
      justifyContent: "space-between",
      alignItems: "space-between",
      display: "flex",
      backgroundColor: "#FFF",
      boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.08) !important",
      padding: theme.spacing(2, 3),
      position: 'fixed',
      width: '100%'
    },
    rootAppBar: {
      zIndex: 999998,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08) !important',
    },
    image: {
      width: theme.spacing(16),
    },
    grow: { flexGrow: 1 },
    suite: {
      cursor: 'pointer',
      display: 'flex',
      height: '90%',
      zIndex: 100,
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    suite_logo: {
      height: theme.typography.h5.fontSize,
      alignSelf: 'center'
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: '#F5F5F5',
      width: theme.spacing(5.5),
      height: theme.spacing(5.5),
      padding: 10
    },
    appButton: {
      ...primary
    },
    appButtonActive: {
      ...primary,
      color: '#0C20B5 !important',
      borderBottom: '2px solid #0C20B5 !important'
    },
    appButtonEndIcon: {
      marginLeft: '2px !important'
    },
    appButtonNewColor: {
      backgroundColor: '#2813AD'
    },
    appButtonLongPadding: {
      ...primary,
      padding: '12px 16px 12px 16px',
      margin: '8px 0px 8px 0px'
    },
    IconButtonMenu: {
      color: '#fff',
      fontSize: '26px'
    },
    btn: {
      marginRight: `${theme.spacing(1)} !important`,
      lineHeight: "26px",
      height: "40px",
      borderRadius: '4.75rem',

      "&:hover": {
        backgroundColor: "#f5f5f5",
        color: "black",
      },
    }
  };
});

export default useStyles;

