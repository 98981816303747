import React from "react";
import { connect } from "react-redux";
import { Modal, TextField, Select, MyButton } from "../../../utils/components";
import { COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import { Typography } from "@material-ui/core";

function ModalCriarCategoria(props) {
  function footer() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        <MyButton
          size="large"
          color="primary"
          variant="text"
          onClick={props.onClose}
        >
          Fechar
        </MyButton>

        <MyButton
          onClick={props.onSave}
          size="large"
          color="primary"
          variant="text"
        >
          Salvar
        </MyButton>
      </div>
    );
  }
  return (
    <Modal
      size="lg"
      showModal={props.open}
      onClose={props.onClose}
      header="Criar categoria"
      footer={footer()}
    >
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          Informações da categoria
        </Typography>
        <TextField
          label="Categoria"
          className="form-control"
          autoComplete="none"
          onChange={({ target: { value } }) =>
            props.onChange("categoriaDelivery", "nome_categoria", value)
          }
          value={props.categoriaDelivery.nome_categoria}
        />
        <Select
          onChange={value =>
            props.onChange("categoriaDelivery", "lojas_categoria", value)
          }
          value={props.categoriaDelivery.lojas_categoria}
          options={
            props.lojas ? props.lojas.filter(loja => loja.tipo_pedido) : []
          }
          labelKey="nome_loja"
          valueKey="id_loja"
          multiple
          label="Lojas"
        />
      </section>
    </Modal>
  );
}
const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCriarCategoria);
