import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  nameChat: {
    fontSize: '13pt',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '700',
  },
  groupsList: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(85vh - 300px)',
  },
  loader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  linearProgress: { width: 'calc(100vw / 4)', marginTop: '1vh' },
}));

export default useStyles;
