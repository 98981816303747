/* eslint-disable */
import AWS from 'aws-sdk';

import { toBase64 } from './Functions';

const API_VERSION = '2006-03-01';
const BUCKET_NAME = 'datasalesio-imagens';
const BUCKET_REGION = 'us-east-1';
const IDENTITY_POOL_ID = 'us-east-1:5876d72e-99c7-4a3d-a6e8-1a4da44e4835';

class DSAWS {
  s3 = null;
  constructor() {
    AWS.config.update({
      region: BUCKET_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID
      })
    });

    this.s3 = new AWS.S3({
      apiVersion: API_VERSION,
      params: { Bucket: BUCKET_NAME }
    });
  }

  saveS3({
    folderName,
    subFolderName = '',
    file,
    name = '',
    distinctName = true,
    bucketName = BUCKET_NAME,
    type = '',
    extension = ''
  }) {
    if (!file) {
      return Promise.reject('Arquivo não informado!');
    }
    const timestamp = new Date().getTime();
    const fileName = `${distinctName ? timestamp : name}`;
    const folderFilesKey =
      subFolderName || folderName ? `${subFolderName}${encodeURIComponent(folderName)}/` : '';
    const fileKey = Boolean(extension)
      ? `${folderFilesKey}${fileName}.${extension}`
      : `${folderFilesKey}${fileName}`;

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: fileKey,
        Body: file,
        ACL: 'public-read',
        ContentType: type
      }
    });

    return upload.promise();
  }

  getObject = async ({ bucket = BUCKET_NAME, key }) => {
    try {
      function encode(data) {
        var str = data.reduce(function (a, b) {
          return a + String.fromCharCode(b);
        }, '');
        return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
      }
      const data = await this.s3
        .getObject({
          Bucket: bucket,
          Key: key
        })
        .promise();
      return 'data:image/png;base64,' + encode(data.Body);
    } catch (err) {
      console.log(err);
    }
  };

  getObjectGeral = async ({ link }) => {
    try {
      return (await toBase64(link, { noHeaders: true })).base64;
    } catch (err) {
      console.log(err);
    }
  };
}

export default new DSAWS();

