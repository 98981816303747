import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  hasSelectedItems: {
    bottom: '0 !important',
    transition: 'all .5s ease'
  },
  distributionFooter: {
    width: '100vw',
    minWidth: '100vw',
    maxHeight: '76px',
    minHeight: '76px',
    background: '#FFFFFF',
    position: 'fixed',
    bottom: '-400px',
    left: 0,
    boxShadow: '-8px -8px 38px rgb(0, 0, 0, 15%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s ease',
    zIndex: 1201
  },
  footerContainer: {
    width: '100%',
    maxHeight: '100%'
    // padding: '16px 2rem !important',
  },
  informationMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  informationMessageText: {
    fontSize: '.8rem !important'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  distributionButton: {
    marginLeft: '6px',
    color: '#127FFF !important',
    whiteSpace: 'nowrap'
  },
  distributionButtonDisabled: {
    color: '#C4C4C4 !important'
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    color: '#127FFF',
    backgroundColor: 'transparent',
    whiteSpace: 'nowrap'
  },
  selectedItemsAndCancelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end'
  },
  downloadButtonMenu: {
    // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.30))',
  },
  downloadButtonMenuRoot: {
    padding: 0
  },
  downloadButtonMenuPaper: {
    display: 'inline-block',
    overflow: 'visible !important',
    filter: 'drop-shadow(0px -4px 18px rgba(0,0,0,0.20))',
    padding: 0
  },
  downloadButtonMenuList: {
    borderRadius: '8px',
    padding: 0
  },
  // downloadButtonMenuItem: {
  //   // borderRadius: '4px'
  //   padding: '8px 16px'
  // },
  wrapDownloadOptions: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  titleBold: {
    fontWeight: 600,
    color: '#1D1D1D'
  },
  descriptionOption: {
    fontWeight: 500,
    color: '#616161'
  }
});

