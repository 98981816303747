import Fetch from '../../../utils/helpers/Fetch';
import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';

export const loadListForm = () => dispatch =>
  dispatch({ type: 'LOAD_LIST_FORM' });

export const loadAnswerForm = () => dispatch =>
  dispatch({ type: 'LOAD_ANSWER_FORM' });

export const getForms = () => async dispatch => {
  try {
    dispatch(loadListForm());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/getForms`
    );
    dispatch({ type: 'LIST_FIELDS_FORM_CLIENTS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getAnswerForm = question => async dispatch => {
  try {
    dispatch(loadAnswerForm());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/getAnswerForm`,
      question
    );
    dispatch({ type: 'ANSWER_FORM', payload });
  } catch (err) {
    console.log(err);
  }
};

export const postForm = field => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/postForm`,
      field,
      'POST'
    );
    dispatch(getForms());
  } catch (err) {
    console.log(err);
  }
};

export const putForm = field => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/putForm`,
      field,
      'PUT'
    );
    dispatch(getForms());
  } catch (err) {
    console.log(err);
  }
};
