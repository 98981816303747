const INITIAL_STATE = {
  listFieldsFormClients: [],
  answerForm: {
    answers: [],
    count: [],
  },
  loadListForm: false,
  loadAnswerForm: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_PROSPECCAO':
      return { ...state, listProspeccao: action.payload };

    case 'LIST_FIELDS_FORM_CLIENTS':
      return {
        ...state,
        listFieldsFormClients: action.payload,
        loadListForm: false,
      };

    case 'ANSWER_FORM':
      return { ...state, answerForm: action.payload, loadAnswerForm: false };

    case 'POLICIES_COMPANY':
      return { ...state, policiesCompany: action.payload };
    case 'LOAD_LIST_FORM':
      return { ...state, loadListForm: true };
    case 'LOAD_ANSWER_FORM':
      return { ...state, loadAnswerForm: true };

    default:
      return state;
  }
};
