import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

export default function AtivarContatos({ onClose, open, instances, onSave }) {
  const [instance, setInstance] = useState(null);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Ativar contatos</DialogTitle>
      <DialogContent>
        <Box width="35vw" padding="2rem 5rem" marginBottom="2vh">
          <Typography
            style={{
              fontWeight: '900',
              color: COLOR_PRIMARY,
            }}
            variant="h5"
          >
            Instâncias
          </Typography>
          <Select
            fullWidth
            variant="outlined"
            onChange={({ target: { value } }) => setInstance(value)}
            value={instance}
            label="Instância"
          >
            {instances.map(instan => (
              <MenuItem value={instan.id}>{instan.nome}</MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={!Boolean(instance)}
          onClick={() => {
            onSave(instance);
            onClose();
          }}
          color="primary"
        >
          Ativar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
