import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as actionsChatbot from '../../actions';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  Tooltip,
  Typography,
  Avatar,
} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { AddRounded, GroupRounded, EditRounded } from '@material-ui/icons';
import DSAWS from '../../../../utils/helpers/DSAWS';
import PageWrapper from '../PageWrapper';
import ParticipantsDialog from '../Dialogs/ParticipantsDialog';
import CreateGroupDialog from '../Dialogs/CreateGroupDialog';
import useStyles from './styles';

function Grupos(props) {
  const { id } = useParams(),
    classes = useStyles(),
    [loading, setLoading] = useState(false),
    [loadingParticipants, setLoadingParticipants] = useState(false),
    [loadingCreate, setLoadingCreate] = useState(false),
    [loadingContacts, setLoadingContacts] = useState(false),
    [groups, setGroups] = useState([]),
    [contacts, setContacts] = useState([]),
    [participants, setParticipants] = useState([]),
    [group, setGroup] = useState({}),
    [newGroup, setNewGroup] = useState({
      name: '',
      participants: [],
      image: null,
    }),
    [searchContacts, setSearchContacts] = useState({
      nome_contato: '',
      numero: '',
      instancia: id,
      status: 1,
    }),
    [openPartDialog, setOpenPartDialog] = useState(false),
    [openCreateDialog, setOpenCreateDialog] = useState(false);

  async function listGroups() {
    try {
      setLoading(true);
      const response = await props.listGroups(id);

      setGroups([...response]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    listGroups();
    getContacts();
  }, [id]);

  async function getContacts() {
    try {
      setLoadingContacts(true);
      const response = await props.getContatosInstancia(searchContacts);
      setContacts(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingContacts(false);
    }
  }

  async function getParticipants() {
    try {
      setLoadingParticipants(true);
      const response = await props.groupParticipants(group.ID);
      setParticipants([...response]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingParticipants(false);
    }
  }

  async function createGroup() {
    try {
      setLoadingCreate(true);
      let s3 = { Location: null };
      if (Boolean(newGroup.image)) {
        s3 = await DSAWS.saveS3({
          folderName: 'whatsapp-groups',
          file: newGroup.image,
          type: newGroup.image.type,
          extension: newGroup.image.name.split('.')[1],
        });
      }

      delete newGroup.image;

      await props.createGroup({
        ...newGroup,
        image: s3.Location,
        instancia: id,
        adminOnlyMessage: 1,
        adminOnlySettings: 1,
      });

      setOpenCreateDialog(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCreate(false);
      listGroups();
    }
  }

  return (
    <PageWrapper {...props} title="Grupos" buttonBack>
      <Box display="flex" justifyContent="right">
        <Button
          startIcon={<AddRounded />}
          size="size"
          variant="contained"
          color="primary"
          onClick={() => setOpenCreateDialog(true)}
        >
          Novo Grupo
        </Button>
      </Box>
      <Box px={2} marginTop="4vh">
        <List id="scrollableList" className={classes.groupsList}>
          {Boolean(groups.length)
            ? groups.map(group => (
                <ListItem key={group.ID}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      {group.IMAGE ? (
                        <img
                          className={classes.avatar}
                          src={group.IMAGE}
                          alt={group.NAME}
                        />
                      ) : (
                        <GroupRounded />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.nameChat }}
                    primary={group.NAME}
                  />

                  <Box marginX=".5vw">
                    <Tooltip title="Link de convite" placement="bottom">
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                          navigator.clipboard.writeText(group.INVITE);
                          alert('Link de convite copiado para áre de trabalho');
                        }}
                        aria-label="close"
                      >
                        <GroupAddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Participantes" placement="bottom">
                      <IconButton
                        onClick={() => {
                          setGroup(group);
                          setOpenPartDialog(true);
                        }}
                        edge="end"
                        aria-label="view-participants"
                      >
                        <EditRounded color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ListItem>
              ))
            : !loading && (
                <Box className={classes.loader}>
                  <Typography className={classes.nameChat}>
                    Nenhum grupo foi encontrado.
                  </Typography>
                </Box>
              )}
        </List>
      </Box>
      <ParticipantsDialog
        loading={loadingParticipants}
        open={openPartDialog}
        group={group}
        participants={participants}
        onLoad={getParticipants}
        onClose={() => {
          setOpenPartDialog(false);
          setGroup({});
          setParticipants([]);
        }}
      />

      <CreateGroupDialog
        loading={loadingCreate}
        loadingContacts={loadingContacts}
        open={openCreateDialog}
        group={newGroup}
        contacts={contacts}
        onChange={(value, key) => setNewGroup({ ...newGroup, [key]: value })}
        onSave={createGroup}
        onClose={() => {
          setOpenCreateDialog(false);
          setNewGroup({ name: '', participants: [], image: null });
        }}
      />
    </PageWrapper>
  );
}

const mapStateToProps = ({ ChatbotReducer }) => {
    return { ...ChatbotReducer };
  },
  mapDispatchToProps = { ...actionsChatbot };

export default connect(mapStateToProps, mapDispatchToProps)(Grupos);
