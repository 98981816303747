import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Icon,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import { getConfigSubmenuFilho } from '../action/action';
import { getDashboardMetabase } from '../../Metabase/action/action';
import { BG_PRIMARY, COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import '../style/comercial.css';

export class ComercialId extends Component {
  state = { checked: false };
  componentDidMount() {
    this.props.getConfigSubmenuFilho(this.props.match.params.id);
  }

  changeDashboard(filho) {
    this.props.getDashboardMetabase(filho.id_dashboard);
    this.setState({ checked: filho.id_submenu_filho });
  }

  render() {
    const { classes } = this.props;

    return this.props.loaderSubmenuFilho ? (
      <section style={{ marginTop: '20vh', textAlign: 'center' }}>
        <CircularProgress color="primary" size={40} />
      </section>
    ) : (
      <div className={classes.root}>
        <div>
          <Link to="/analise" style={{ textDecoration: 'none' }}>
            <Button
              size="small"
              style={{ marginBottom: '1rem' }}
              color="primary"
            >
              <Icon>arrow_back_ios</Icon>Voltar
            </Button>
          </Link>
        </div>
        <div>
          <h1 style={{ paddingBottom: '1rem' }}>
            {this.props.location.pai
              ? this.props.location.pai.nome_submenu_pai
              : 'Nome não encontrado'}
          </h1>
        </div>
        <section className="contCard">
          {this.props.submenuFilho &&
            this.props.submenuFilho.map(filho => (
              <Link
                to={{
                  pathname: `/metabase/${this.props.match.params.id}`,
                }}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  button
                  onClick={() => this.changeDashboard(filho)}
                  style={{ backgroundColor: '#FFF', borderRadius: '5px' }}
                >
                  <ListItemIcon style={{ color: COLOR_PRIMARY }}>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: BG_PRIMARY }}
                    primary={filho.nome_submenu_filho}
                  />
                </ListItem>
              </Link>
            ))}
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

const mapStateToProps = ({ ComercialReducer, MetabaseReducer }) => ({
  ...ComercialReducer,
  ...MetabaseReducer,
});

const mapDispatchToProps = { getConfigSubmenuFilho, getDashboardMetabase };

const ComercialIdConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComercialId);

export default withStyles(styles)(ComercialIdConnect);
