import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CodeIcon from '@material-ui/icons/Code';
import Table from 'material-table';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CreateScript from './components/CreateScript';
import EditScript from './components/EditScript';
import useStyles from './styles';

function Scripts({ siteId }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [script, setScript] = useState();
  const [createScriptIsOpen, setCreateScriptIsOpen] = useState(false);
  const [editScriptIsOpen, setEditScriptIsOpen] = useState(false);

  const getScripts = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/scripts`,
        { site_id: siteId }
      );

      setData(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getScripts();
  }, [getScripts]);

  const handleCreateScriptOpen = () => {
    setCreateScriptIsOpen(true);
  };

  const handleCreateScriptClose = useCallback(() => {
    setCreateScriptIsOpen(false);
  }, [setCreateScriptIsOpen]);

  const handleEditScriptOpen = data => {
    setScript(data);
    setEditScriptIsOpen(true);
  };

  const handleEditScriptClose = useCallback(() => {
    setScript(undefined);
    setEditScriptIsOpen(false);
  }, [setEditScriptIsOpen]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/scripts`,
          { site_id: siteId, ...data },
          'POST'
        );
      } catch (err) {
        console.log(err);
      } finally {
        handleCreateScriptClose();
        getScripts();
      }
    },
    [handleCreateScriptClose, getScripts]
  );

  const handleEditScript = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/scripts/${data.id}`,
          data,
          'PUT'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getScripts();
      }
    },
    [getScripts]
  );

  const handleRemoveScript = useCallback(
    async id => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/scripts/${id}`,
          {},
          'DELETE'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getScripts();
      }
    },
    [getScripts]
  );

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <CodeIcon />
            </Avatar>
          }
          title="Scripts"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateScriptOpen}
            >
              Adicionar script
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              { title: 'Nome', field: 'name' },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            actions={[
              {
                icon: 'edit',
                onClick: (e, rowData) => {
                  handleEditScriptOpen(rowData);
                },
              },
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleRemoveScript(rowData.id);
                },
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={localization}
          />
        </CardContent>
      </Card>
      {createScriptIsOpen && (
        <CreateScript
          open={createScriptIsOpen}
          onSubmit={handleSubmit}
          onClose={handleCreateScriptClose}
        />
      )}
      {editScriptIsOpen && (
        <EditScript
          open={editScriptIsOpen}
          script={script}
          onSubmit={handleEditScript}
          onClose={handleEditScriptClose}
        />
      )}
    </Fragment>
  );
}

export default Scripts;
