/* eslint---disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CardActionArea,
  Divider,
  Fade,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Modal, DialogAlert } from '../../../../utils/components';
import {
  createTemplatePersonalizado,
  getTabloidsTemplatesFisico,
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import { TextField as TextFieldAC } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PixelixeIframe } from '../';
import ModalCriar from '../GeneratePixelixeDetalhe/modalCriar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import { withSnackbar } from 'notistack';

const fator = 8;

//APAGAR ESSE JSON
//var jsonTeste = `{\"modifications\":{\"imagem-fundo\":{\"name\":\"imagem-fundo\",\"type\":\"image\",\"image_url\":\""},\"produto-imagem\":{\"name\":\"produto-imagem-&numb&\",\"type\":\"image\",\"image_url\":\"\"},\"produto-descricao\":{\"name\":\"produto-descricao-&numb&\",\"type\":\"text\",\"text\":\"produto\"},\"produto-preco-por\":{\"name\":\"produto-preco-por-&numb&\",\"type\":\"text\",\"text\":\"R$ 10,90\"}},\"extras\":[{\"name\":\"logo-empresa-01\",\"type\":\"image\",\"image_url\":\"https://s3.amazonaws.com/datasalesio-imagens/6cdb6708-d38b-4140-065e-5b6c674f9bc9.png\"},{\"name\":\"descritivo-texto-001\",\"type\":\"text\",\"text\":\"Ofertas válidas até XX/XX ou enquanto durarem os estoques\"},{\"name\":\"endereco-01\",\"type\":\"text\",\"text\":\"Av. Santos Drumont, 990\"},{\"name\":\"endereco-02\",\"type\":\"text\",\"text\":\"19999081150\"}]}`;

class EditMeusModelos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: 'Encarte-A4-12P-CPD-inicio_do_inverno-001',
      qtde_produtos: 12,
      tipoPixelixe: '',
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: { r: 0, g: 0, b: 0, a: 1 },
      showDesativarModelo: false,
      etiquetas: [],
      etiqueta: null,
      font_size: null,
      listaSegmentos: {},
      segmentoSelected: null,
      inputSegmentoSelected: '',
      listaTiposPixelixe: {},
      tipoSelected: null,
      inputTipoSelected: '',
      listaFormatos: {},
      formatoSelected: null,
      inputFormatoSelected: '',
      listaQtdes: {},
      qtdeSelected: null,
      inputQtdeSelected: '',
      listaOcasioes: {},
      ocasiaoSelected: null,
      inputOcasiaoSelected: '',
      listaTemplates: {},
      templateSelected: null,
      loadingTemplates: false,
      page: 8,
      paginaDe: null,
      paginaAte: null,
      miniatura: null,
      gerarEtiquetaPersonalizada: false,
      //
      categoriaEmFoco: 0,
      //
      selecionarProdutosOpen: false,
      //
      moreEtiqueta: 1,
      //
      idsNaoIncluidos: [],
      //
      pixelixeIframe_open: false,
      imgSelected: null,
      //
      openCriarTemplate: false,
      nomeModelo: null,
      ocasiaoModelo: null,
      qtdeModelo: null,
      onCreateNovoModelo: () => {},
      anchorElTemplate: null,
      templateForEdit: null,
      //LIMPAR ESSE STATE TODO
    };
  }

  componentDidMount() {
    this.carregaTemplates();
  }

  carregaTemplates = () => {
    this.setState({ loadingTemplates: true });
    this.props
      .getTabloidsTemplatesFisico({
        pagina: 1,
        segmento: this.state.segmentoSelected,
        tipoPixelixe: this.state.tipoSelected,
        formato: this.state.formatoSelected,
        qtde_produtos: this.state.qtdeSelected,
        ocasiao: this.state.ocasiaoSelected,
        myTemplates: true,
      })
      .then(payload => {
        this.setState({
          listaSegmentos: payload.listaSegmentos,
          listaTiposPixelixe: payload.listaTiposPixelixe,
          listaFormatos: payload.listaFormatos,
          listaQtdes: payload.listaQtdes,
          listaOcasioes: payload.listaOcasioes,
          //
          segmentoSelected:
            this.state.segmentoSelected || payload.listaSegmentos[0],
          tipoSelected:
            this.state.tipoSelected || payload.listaTiposPixelixe[0],
          formatoSelected:
            this.state.formatoSelected || payload.listaFormatos[0],
          qtdeSelected: this.state.qtdeSelected || payload.listaQtdes[0],
          //
          page: 8,
          loadingTemplates: false,
        });

        let listaTemplates = {};

        payload.response.reduce(
          (acum, curr, index) =>
            (listaTemplates[curr['nome'] + ' / id:' + curr['id']] = [
              curr['miniatura'],
              curr['template'],
              curr['id'],
              index,
            ]),
          {}
        );

        this.setState({ listaTemplates: listaTemplates });
      });
  };

  showPopover = current => {
    this.setState({ anchorEl: current });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeComplete = color => {
    this.setState(
      {
        colorText: color.rgb,
      },
      () => {
        let tmp = { ...this.state.json_parse };

        let indexOf = null;

        if (tmp.extras) {
          indexOf = tmp.extras.findIndex(i => i.name === 'endereco-01');
          if (indexOf > -1) {
            tmp.extras[
              indexOf
            ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
          }

          indexOf = tmp.extras.findIndex(i => i.name === 'endereco-02');
          if (indexOf > -1) {
            tmp.extras[
              indexOf
            ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
          }

          indexOf = tmp.extras.findIndex(i => i.name === 'telefone-01');
          if (indexOf > -1) {
            tmp.extras[
              indexOf
            ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
          }

          indexOf = tmp.extras.findIndex(
            i => i.name === 'descritivo-texto-001'
          );
          if (indexOf > -1) {
            tmp.extras[
              indexOf
            ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
          }
        }

        if (tmp.modifications && tmp.modifications['produto-descricao']) {
          tmp.modifications[
            'produto-descricao'
          ].color = `rgb(${this.state.colorText.r}, ${this.state.colorText.g}, ${this.state.colorText.b})`;
        }

        this.setState({ json_parse: { ...tmp } });
      }
    );
  };

  header(props, state) {
    return <Fragment>Edição de Templates</Fragment>;
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: 5,
          width: '100%',
        }}
      ></div>
    );
  }

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  loadData = async () => {
    await this.sleep(300).then(() => {
      this.setState({ page: this.state.page + 1 });
    });
  };

  selectOnChangeSegmento = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          segmentoSelected: newValue,
          listaTiposPixelixe: {},
          tipoSelected: null,
          listaFormatos: {},
          formatoSelected: null,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeTipo = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          tipoSelected: newValue,
          listaFormatos: {},
          formatoSelected: null,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeFormato = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          formatoSelected: newValue,
          listaQtdes: {},
          qtdeSelected: null,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeQtde = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          qtdeSelected: newValue,
          listaOcasioes: {},
          ocasiaoSelected: null,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  selectOnChangeOcasiao = (event, newValue) => {
    if (newValue)
      this.setState(
        {
          page: 8,
          ocasiaoSelected: newValue,
          listaTemplates: {},
          templateSelected: null,
        },
        () => this.carregaTemplates()
      );
  };

  step1(props, state) {
    const { classes } = this.props;

    const ObjectKeysTemplates = Object.keys(this.state.listaTemplates).slice(
      0,
      this.state.page * fator
    );

    return (
      <Fragment>
        {this.state.categoriaEmFoco === 0 && (
          <InfiniteScroll
            dataLength={ObjectKeysTemplates.length}
            next={this.loadData}
            loader={<h4>Loading...</h4>}
            hasMore={this.state.page * fator <= ObjectKeysTemplates.length}
            height={950}
            style={{ overflowAnchor: 'none' }}
            //scrollableTarget="scrollableDiv"
          >
            <section>
              <section>
                <div style={{ display: 'flex', margin: '20px 30px 30px' }}>
                  <Autocomplete
                    value={this.state.segmentoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeSegmento(null, newValue)
                    }
                    inputValue={this.state.inputSegmentoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputSegmentoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaSegmentos}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Segmento"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.tipoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeTipo(null, newValue)
                    }
                    inputValue={this.state.inputTipoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputTipoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaTiposPixelixe}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Tipo"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.formatoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeFormato(null, newValue)
                    }
                    inputValue={this.state.inputFormatoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputFormatoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaFormatos}
                    style={{
                      width: 200,
                      marginRight: 15,
                    }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Formato"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.qtdeSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeQtde(null, newValue)
                    }
                    inputValue={this.state.inputQtdeSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputQtdeSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaQtdes}
                    style={{ width: 200, marginRight: 15 }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Produtos por página"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />

                  <Autocomplete
                    value={this.state.ocasiaoSelected}
                    onChange={(event, newValue) =>
                      this.selectOnChangeOcasiao(null, newValue)
                    }
                    inputValue={this.state.inputOcasiaoSelected}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputOcasiaoSelected: newInputValue });
                    }}
                    id="controllable-states-demo"
                    options={this.state.listaOcasioes}
                    style={{ width: 200, marginRight: 15 }}
                    renderInput={params => (
                      <TextFieldAC
                        {...params}
                        label="Ocasião"
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.loadingTemplates}
                  />
                </div>
              </section>
              <Divider />
              <section
                style={{
                  padding: '2rem 1rem',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <GridList
                      cellHeight="auto"
                      cols={8} //matches ? 8 : 6
                      spacing={8}
                      className={classes.gridList}
                    >
                      {this.state.loadingTemplates
                        ? Array(10)
                            .fill(10)
                            .map((_, i) => (
                              <GridListTile>
                                <Skeleton
                                  variant="rect"
                                  height={160}
                                  width="100%"
                                />
                              </GridListTile>
                            ))
                        : ObjectKeysTemplates.map((valueAtual, index) => (
                            <GridListTile
                              className={classes.tile}
                              key={valueAtual}
                            >
                              <CardActionArea>
                                <img
                                  className={classes.img}
                                  src={this.state.listaTemplates[valueAtual][0]}
                                  alt={valueAtual}
                                  title={valueAtual}
                                  style={{ width: '100%', maxWidth: 400 }}
                                />
                              </CardActionArea>
                              <GridListTileBar
                                title={valueAtual}
                                classes={{
                                  root: classes.titleBar,
                                  title: classes.title,
                                }}
                              />
                              <GridListTileBar
                                title={valueAtual}
                                classes={{
                                  root: classes.titleBar,
                                  title: classes.title,
                                }}
                                actionIcon={
                                  <IconButton
                                    className={classes.title}
                                    onClick={e => {
                                      let tmp = this.props
                                        .tabloids_templates_fisico[
                                        this.state.listaTemplates[valueAtual][3]
                                      ];

                                      this.setState({
                                        anchorElTemplate: e.currentTarget,
                                        templateForEdit: tmp,
                                      });
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                }
                              />
                            </GridListTile>
                          ))}
                    </GridList>
                  </Grid>
                </div>
              </section>
            </section>
          </InfiniteScroll>
        )}
      </Fragment>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          fullScreen
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
          dividers
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {this.state.showDesativarModelo && (
          <DialogAlert
            open={this.state.showDesativarModelo}
            title="Deseja desativar este template?"
            onClick={() =>
              this.setState({ showDesativarModelo: false }, () => {
                this.props.enqueueSnackbar('Processando...', {
                  variant: 'info',
                });
                this.props
                  .createTemplatePersonalizado({
                    id: this.state.templateForEdit.id,
                    deletar: true,
                  })
                  .then(resp => {
                    this.props.enqueueSnackbar(resp.message, {
                      variant: 'success',
                    });
                    this.setState({
                      showDesativarModelo: false,
                    });
                    this.carregaTemplates();
                  })
                  .catch(err => {
                    this.props.enqueueSnackbar('Falha de processamento', {
                      variant: 'error',
                    });
                  });
              })
            }
            onClose={() => this.setState({ showDesativarModelo: false })}
          ></DialogAlert>
        )}

        {this.state.pixelixeIframe_open && this.state.imgSelected && (
          <PixelixeIframe
            open={this.state.pixelixeIframe_open}
            onClose={() => {
              this.setState({ pixelixeIframe_open: false });
            }}
            // justTemplate: true,
            // stage: AMBIENTE,
            // //add_template: true,
            // modelo: tmp.id,
            // nome_modelo: 'teste1727272mmm',
            // tipoPixelixe: tmp.tipoPixelixe,
            // id_empresa: tmp.id_cliente,
            src={
              'https://studio.pixelixe.com/#api?apiKey=EzWaSycXeWchL44HPUtcnLpZes63&graphicUrl=' +
              (this.state.imgSelected.link_html
                ? this.state.imgSelected.link_html.split('?')[0]
                : null) +
              `&graphicName=${
                this.state.imgSelected.nome_modelo
              }&uid=${'justTemplate'}_${this.state.imgSelected.stage}_${
                this.state.imgSelected.modelo
              }_${this.state.imgSelected.nome_modelo}_${
                this.state.imgSelected.tipoPixelixe
              }_${this.state.imgSelected.id_empresa}_${
                this.state.imgSelected.ocasiaoModelo
              }_${this.state.imgSelected.qtdeModelo}_link_${
                window.location.href.split('/enviado')[0]
              }/enviadoEstudio_link_&lang=po`
            }
          ></PixelixeIframe>
        )}

        {this.state.openCriarTemplate && (
          <ModalCriar
            open={this.state.openCriarTemplate}
            onClose={() =>
              this.setState({
                nomeModelo: null,
                ocasiaoModelo: null,
                qtdeModelo: null,
                openCriarTemplate: false,
              })
            }
            value={{
              nomeModelo: this.state.nomeModelo,
              ocasiaoModelo: this.state.ocasiaoModelo,
              qtdeModelo: this.state.qtdeModelo,
            }}
            onChange={(e, campo) =>
              this.setState({
                [campo]: e.target.value,
              })
            }
            onClick={() => {
              if (!this.state.nomeModelo) {
                alert('Preencha o campo Nome do Modelo!');
              } else {
                this.state.onCreateNovoModelo();
              }
            }}
          ></ModalCriar>
        )}

        <Menu
          classes={{ paper: classes.menuPaper }}
          anchorEl={this.state.anchorElTemplate}
          TransitionComponent={Fade}
          open={Boolean(this.state.anchorElTemplate)}
          onClose={() => {
            this.setState({ anchorElTemplate: null });
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                let tmp = this.state.templateForEdit;

                this.setState({
                  anchorElTemplate: null,
                  openCriarTemplate: true,
                  ocasiaoModelo: tmp.ocasiao,
                  onCreateNovoModelo: () => {
                    this.setState({
                      pixelixeIframe_open: true,
                      imgSelected: {
                        id: tmp.id,
                        link_html: tmp.template,
                        justTemplate: true,
                        stage: AMBIENTE,
                        //add_template: true,
                        modelo: tmp.id,
                        nome_modelo: this.state.nomeModelo,
                        tipoPixelixe: tmp.tipoPixelixe,
                        id_empresa: tmp.id_cliente,
                        ocasiaoModelo: this.state.ocasiaoModelo,
                        qtdeModelo: this.state.qtdeModelo,
                      },
                    });
                  },
                });
              }}
            >
              <ListItemIcon>
                <EditOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Editar</Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.setState({
                  showDesativarModelo: true,
                  anchorElTemplate: null,
                });
              }}
            >
              <ListItemIcon>
                <BlockOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Desativar</Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  tile: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    color: theme.palette.common.white,
  },
  titleBar: {
    borderRadius: theme.shape.borderRadius,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
  },
  gridList: {
    width: '100%',
  },
  menuPaper: {
    border: '1px solid #d3d4d5',
    width: 300,
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  createTemplatePersonalizado,
  getTabloidsTemplatesFisico,
};

const EditMeusModelosConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMeusModelos);

export default withSnackbar(withStyles(styles)(EditMeusModelosConnect));
