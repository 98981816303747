import Fetch from '../../../utils/helpers/Fetch';
import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';
import moment from 'moment';

const date = {
  dataInicio: moment()
    .subtract('1', 'month')
    .format('YYYY-MM-DD'),
  dataFim: moment()
    .add('1', 'days')
    .format('YYYY-MM-DD'),
};

export const onChangeState = (state, value) => dispatch =>
  dispatch({
    type: 'onChange',
    payload: {
      state,
      value,
    },
  });

export const onChangeOfertaCabecalho = (state, value) => dispatch =>
  dispatch({
    type: 'onChangeOfertaCabecalho',
    payload: {
      state,
      value,
    },
  });
export const onChangeOfertaDetalhe = (state, value) => dispatch =>
  dispatch({
    type: 'onChangeOfertaDetalhe',
    payload: {
      state,
      value,
    },
  });

export const isLoadingOfertas = () => dispatch =>
  dispatch({ type: 'LOADING_OFERTAS' });

export const isLoadingPerformanceOfertas = () => dispatch =>
  dispatch({ type: 'LOADING_PERFORMANCE_OFERTAS' });

export const isLoadingVendasOfertaHora = () => dispatch =>
  dispatch({ type: 'LOADING_VENDAS_OFERTA_HORA' });

export const isLoadingVendasOfertaDia = () => dispatch =>
  dispatch({ type: 'LOADING_VENDAS_OFERTA_DIA' });

export const isLoadingVendasOfertaLoja = () => dispatch =>
  dispatch({ type: 'LOADING_VENDAS_OFERTA_LOJA' });

export const isLoadingProdutos = () => dispatch =>
  dispatch({ type: 'LOADING_PRODUTOS' });

export const isLoadingPessoas = () => dispatch =>
  dispatch({ type: 'LOADING_PESSOAS' });

export const isLoadingProdutosOferta = () => dispatch =>
  dispatch({ type: 'LOADING_PRODUTOS_OFERTA' });

export const isLoadingCategoriasOferta = () => dispatch =>
  dispatch({ type: 'LOADING_CATEGORIAS_OFERTA' });

export const isLoadingPostOfertaCab = () => dispatch =>
  dispatch({ type: 'LOADING_OFERTA_CABECALHO' });

export const isLoadingEdit = () => dispatch =>
  dispatch({ type: 'LOADING_EDIT_OFERTA' });

export const isLoadingDetalhesProduto = () => dispatch =>
  dispatch({ type: 'LOADING_DETALHES_PRODUTO' });

export const isLoadingAddProdutosOferta = () => dispatch =>
  dispatch({ type: 'LOADING_PRODUTOS_LISTA' });

export const isLoadingPessoa = () => dispatch =>
  dispatch({ type: 'LOADING_PESSOA' });

export const clearPessoa = () => dispatch => dispatch({ type: 'CLEAR_PESSOA' });

export const isLoadingProdutoSearch = () => dispatch =>
  dispatch({ type: 'LOADING_PRODUTO_SEARCH' });

export const isLoadingProdutosImagens = () => dispatch =>
  dispatch({ type: 'LOADING_PRODUTOS_IMAGENS' });

export const isloadingPhoto = () => dispatch =>
  dispatch({ type: 'LOADING_PHOTO' });

export const getFamalias = () => async dispatch => {
  //   dispatch(isLoadingPeople());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // {
    //   idStores
    // }
  );
  dispatch({ type: 'GET_FAMILIAS', payload });
};

export const getOfertas = (request = date) => async dispatch => {
  dispatch(isLoadingOfertas());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getOfertas`,
    request
  );

  dispatch({
    type: 'GET_OFERTAS',
    payload,
  });
};

export const getOfertasCompletas = (request = date) => async dispatch => {
  dispatch(isLoadingOfertas());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getOfertasCompletas`,
    request
  );

  dispatch({
    type: 'GET_OFERTAS_COMPLETAS',
    payload,
  });
};

export const getPerformanceOfertas = (request = date) => async dispatch => {
  dispatch(isLoadingPerformanceOfertas());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getPerformanceOfertas`,
    request
  );

  dispatch({
    type: 'GET_PERFORMANCE_OFERTAS',
    payload,
  });
};

export const getOfertasNaoCompletas = (request = date) => async dispatch => {
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getOfertasNaoCompletas`,
    request
  );

  dispatch({
    type: 'GET_OFERTA_NAO_COMPLETAS',
    payload,
  });
};

export const getVendasOfertaHora = (request = date) => async dispatch => {
  dispatch(isLoadingVendasOfertaHora());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getVendasOfertaHora`,
    request
  );

  dispatch({
    type: 'GET_VENDAS_OFERTA_HORA',
    payload,
  });
};

export const getVendasOfertaDia = (request = date) => async dispatch => {
  dispatch(isLoadingVendasOfertaDia());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getVendasOfertaDia`,
    request
  );

  dispatch({
    type: 'GET_VENDAS_OFERTA_DIA',
    payload,
  });
};

export const getVendasOfertaLoja = (request = date) => async dispatch => {
  dispatch(isLoadingVendasOfertaLoja());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getVendasOfertaLoja`,
    request
  );

  dispatch({
    type: 'GET_VENDAS_OFERTA_LOJA',
    payload,
  });
};

export const getLojas = () => async dispatch => {
  dispatch(isLoadingCategoriasOferta());
  const data = await Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getLojas`
  );

  const payload = data.map(item => ({
    label: item.nome_loja,
    value: item.id_loja,
  }));
  dispatch({ type: 'GET_LOJAS', payload });
};

export const getCategoriasOferta = id => async dispatch => {
  dispatch(isLoadingCategoriasOferta());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getDetalhesOferta`,
    { idOferta: id }
  );
  dispatch({ type: 'GET_DETALHES_OFERTA', payload });
};

export const getProdutosCategoria = oferta => async dispatch => {
  dispatch(isLoadingProdutosOferta());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getProdutosCategoria`,
    oferta
  );
  dispatch({ type: 'GET_PRODUTOS_CATEGORIA', payload });
};

export const getDetahesProduto = oferta => async dispatch => {
  dispatch(isLoadingDetalhesProduto());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getDetalhesProduto`,
    oferta
  );
  dispatch({ type: 'GET_DETALHES_PRODUTO', payload });
};

export const getEditOferta = id => async dispatch => {
  dispatch(isLoadingEdit());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getEditOferta`,
    { id_oferta: id }
  );
  dispatch({
    type: 'GET_EDIT_OFERTA',
    payload: {
      ofertaCabecalho: payload.ofertaCabecalho,
      ofertaDetalhe: payload.ofertaDetalhe,
    },
  });
  return true;
};

export const getPessoasByOferta = oferta => async dispatch => {
  dispatch(isLoadingPessoas());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getPessoasByOferta`,
    oferta
  );

  dispatch({ type: 'GET_PESSOAS_OFERTA', payload });
};

export const getProdutosByFamilia = familia => async dispatch => {
  dispatch(isLoadingProdutos());
  const payload = await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/getProdutosByfamilia`,

    {
      familia,
    }
  );
  dispatch({ type: 'GET_PRODUTOS', payload });
};

export const createCabecalhoOferta = oferta => async dispatch => {
  dispatch(isLoadingPostOfertaCab());
  try {
    const payload = await Fetch(
      ` ${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/postCabecalhoOferta`,
      oferta,
      'POST'
    );
    dispatch({ type: 'POST_CABECALHO', payload: payload.id_oferta });
    dispatch(getOfertas());
  } catch (err) {
    console.log(err);
  }
};

export const getPessoaByCpf = documento => async dispatch => {
  dispatch(isLoadingPessoa());
  await Fetch(
    ` ${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getPessoaByCpf`,
    { documento },
    'GET'
  ).then(resp => dispatch({ type: 'GET_PESSOA', payload: resp }));
};

export const getProdutoSearch = request => async dispatch => {
  try {
    dispatch(isLoadingProdutoSearch());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/getProduto`,
      request
    );
    dispatch({ type: 'GET_PRODUTO_SEARCH', payload });
  } catch (err) {
    console.log(err);
  }
};

export const addProdutosOferta = oferta => async dispatch => {
  dispatch(isLoadingAddProdutosOferta());
  await Fetch(
    ` ${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/postProdutosOferta`,
    oferta,
    'POST'
  ).then(resp =>
    dispatch({ type: 'POST_PRODUTOS', payload: resp.ofertaDetalhe })
  );
};

export const createDetalheOferta = oferta => async dispatch => {
  await Fetch(
    ` ${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/produto/postDetalheOferta`,
    oferta,
    'POST'
  ).then(() => dispatch(getOfertas()));
};

export const editOferta = oferta => async dispatch => {
  await Fetch(
    ` ${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/putEditarOferta`,
    oferta,
    'PUT'
  ).then(() => dispatch(getOfertas()));
};

export const deleteProduto = (
  oferta,
  dispatchResponse = true
) => async dispatch => {
  await Fetch(
    `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteProduto`,
    oferta,
    'PUT'
  ).then(() => {
    dispatchResponse && dispatch(getEditOferta(oferta.id_oferta));
    return true;
  });
};

export const putEditarDetalheOferta = oferta => async dispatch => {
  await Fetch(
    ` ${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/putEditarDetalheOferta`,
    oferta,
    'PUT'
  ).then(() => {
    return true;
  });
};

export const getProdutosImagens = data => async dispatch => {
  dispatch(isLoadingProdutosImagens());
  await Fetch(
    `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/getProdutos`,
    data
  ).then(payload =>
    dispatch({ type: 'GET_PRODUTOS_IMAGENS', payload: payload })
  );
};

export const postProdutoImagemTabloide = data => async dispatch => {
  try {
    //dispatch(loadingPhoto());
    await Fetch(
      `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postProdutoImagem`,
      data,
      'POST'
    );
    //dispatch({ type: 'PRODUTO_IMAGEM_OFERTA', payload: response.image, data: data });
    //resolve(response.image);
    return true;
  } catch (err) {
    console.log(err);
  }
};
