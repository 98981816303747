const INITIAL_STATE = {
  objPessoa: {},
  familias: [],
  produtos: [],
  loadingPessoas: false,
  vendas: [],
  loadingFamilias: false,
  loadingProdutos: false,
  loadingPhoto: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_PRODUTOS":
      return { ...state, produtos: action.payload, loadingProdutos: false };
    case "GET_FAMILIAS":
      return { ...state, familias: action.payload, loadingFamilias: false };
    case "LOADING_PRODUTOS":
      return { ...state, loadingProdutos: true };
    case "LOADING_PHOTO":
      return { ...state, loadingPhoto: true };
    case "VENDAS":
      return { ...state, vendas: action.payload, loadingVendas: false };
    case "LOADING_VENDAS":
      return { ...state, loadingVendas: true };
    case "LOADING_FAMILIAS":
      return { ...state, loadingFamilias: true };
    case "PRODUTO_IMAGEM_": 
      let produtos = state.produtos;
      const objIndex = produtos.findIndex((obj => (
        obj.SEQEAN === action.data.ean &&
        obj.SEQPRODUTO === action.data.id_produto
      )));
      produtos[objIndex].LINK_S3 = action.payload + '?' 
        + (new Date().getTime());
      return { 
        ...state,
        produtos: produtos,
        loadingPhoto: false
      };
    default:
      return state;
  }
};
