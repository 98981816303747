import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

interface propsProductsText {
  open: boolean;
  onClose: () => void;
  onSave: (arrayProducts: (string | number | null)[][]) => void;
}

export function ProductsText({ open, onClose, onSave }: propsProductsText) {
  const [textoImportacao, setTextoImportacao] = useState('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
      aria-labelledby="import-text-dialog-title"
    >
      <DialogTitle
        fontStyle={'normal'}
        fontSize={'1rem'}
        lineHeight={'1.625rem'}
        margin={'16px 0 16px 0'}
      >
        Adicionar produtos por texto
      </DialogTitle>
      <DialogContent
        sx={{
          width: '50rem',
          height: '19rem'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%'
          }}
          component="form"
        >
          <TextField
            label="Adicione produtos por texto"
            value={textoImportacao}
            disabled={false}
            multiline
            maxRows={10}
            rows={6}
            onChange={e => setTextoImportacao(e.target.value)}
            sx={{
              border: '1px solid #e7e7e7',
              width: '100%',
              marginTop: '0.5rem'
            }}
          ></TextField>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '2rem'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              sx={{
                background: '#FFFFFF',
                border: '1px solid #B0B0B0',
                color: '#1E1F21'
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let array: string[] = textoImportacao.split(/\n/g);
                array = array.map((value: string) => {
                  return value.replaceAll('R$', '').replaceAll('$', '').replaceAll(/\t/g, ' ');
                });
                let array2 = array.map(value => {
                  return value.trim().split(' ');
                });
                let array3: (string | number | null)[][] = [];
                array2.forEach(value => {
                  let precoA = null;
                  let precoB = null;
                  let j = null;
                  for (j = value.length - 1; j >= 0; j--) {
                    if (value[j] !== '') {
                      let preco_tmp = +value[j].replace(',', '.');
                      if (preco_tmp && (!precoB || !precoA)) {
                        if (!precoB) {
                          precoB = preco_tmp;
                        } else {
                          if (value[j].includes('.') || value[j].includes(',')) {
                            precoA = preco_tmp;
                            j--;
                          }
                          break;
                        }
                      } else {
                        break;
                      }
                    }
                  }

                  let produto = '';
                  for (let i = 0; i <= j; i++) {
                    if (value[i] !== '') produto = produto + ' ' + value[i];
                  }

                  if (!precoA) {
                    precoA = precoB;
                    precoB = 0;
                  }
                  if (produto.length) {
                    array3.push([produto.trim(), precoA, precoB]);
                  }
                });
                if (array3.length) {
                  onSave(array3);
                }
              }}
            >
              Salvar Produtos
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
