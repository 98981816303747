import {
  DATASALES_CRM_API,
  API_SOCIAL_NETWORKS_V2,
  AMBIENTE,
} from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';

const loadingPerformance = () => dispatch =>
    dispatch({ type: 'LOADING_PERFORMANCE' }),
  loadingPessoas = () => dispatch => dispatch({ type: 'LOADING_PESSOAS' }),
  loadingDetalhes = () => dispatch => dispatch({ type: 'LOADING_DETALHES' }),
  loadingResposta = () => dispatch => dispatch({ type: 'LOADING_RESPOSTA' });

export const setCampanha = campanha => dispatch =>
  dispatch({
    type: 'setCampanha',
    payload: campanha,
  });

export const editarCampanha = (campanha, request) => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/putCampanha`,
    campanha,
    'POST'
  ).then(() => dispatch(getTodasPerformanceMes(request)));

export const pauseCampanha = (campanha, request) => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/pauseCampanha`,
    { campanha },
    'PUT'
  ).then(() => dispatch(getTodasPerformanceMes(request)));

export const continueCampanha = (campanha, request) => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/continueCampanha`,
    { campanha },
    'PUT'
  ).then(() => dispatch(getTodasPerformanceMes(request)));

export const getTodasPerformanceMes = request => async dispatch => {
  dispatch(loadingPerformance());

  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/getPerformanceMes`,
      request
    );

    dispatch({
      type: 'TODAS_PERFORMANCE',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDetalhesCampanha = campanha => async dispatch => {
  dispatch(loadingDetalhes());

  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/getDetalhesCampanha`,
      campanha
    );

    dispatch({
      type: 'DETALHES_CAMPANHA',
      payload,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getPessoasByIDCampanha = campanha => async dispatch => {
  dispatch(loadingPessoas());
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/pessoasByIdCampanha`,
      campanha
    );

    dispatch({
      type: 'PESSOAS_CAMPANHA',
      payload: response.resposta,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTodasPerformancePeriodo = periodo => async dispatch => {
  dispatch(loadingPerformance());
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/performance/getPerformanceMes`,
      periodo
    );

    dispatch({
      type: 'TODAS_PERFORMANCE',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getResgateCampanha = campanha => async dispatch => {
  dispatch(loadingPerformance());
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getResgateCampanha`,
      campanha
    );
    dispatch({
      type: 'RESGATE_CAMPANHA',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDesempenhoEnvio = async idCampanha => {
  try {
    return Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/insights/getDesempenhoEnvio`,
      { idCampanha }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getDesempenhoFacebook = async campaign_id => {
  try {
    return Fetch(
      `${API_SOCIAL_NETWORKS_V2}insights/facebook`,
      { campaign_id },
      'POST',
      null,
      null,
      false
    );
  } catch (err) {
    console.log(err);
  }
};

export const getCommentsFacebook = async campaign_id => {
  try {
    return Fetch(
      `${API_SOCIAL_NETWORKS_V2}comments/facebook`,
      { campaign_id },
      'POST',
      null,
      null,
      false
    );
  } catch (err) {
    console.log(err);
  }
};

export const getDesempenhoInstagram = async campaign_id => {
  try {
    return Fetch(
      `${API_SOCIAL_NETWORKS_V2}insights/instagram`,
      { campaign_id },
      'POST',
      null,
      null,
      false
    );
  } catch (err) {
    console.log(err);
  }
};

export const getCommentsInstagram = async campaign_id => {
  try {
    return Fetch(
      `${API_SOCIAL_NETWORKS_V2}comments/instagram`,
      { campaign_id },
      'POST',
      null,
      null,
      false
    );
  } catch (err) {
    console.log(err);
  }
};

export const getTaxaClique = idCampanha =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/getTaxaClique`,
    { idCampanha }
  );

export const getRespostaCampanha = id_campanha => async dispatch => {
  dispatch(loadingResposta());
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getRespostasCampanha`,
      { id_campanha }
    );
    dispatch({
      type: 'CAMPANHA_RESPOSTA',
      payload,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProdutosTabloideCampanha = campanha => async dispatch => {
  dispatch(loadingPerformance());
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getProdutosTabloideCampanha`,
      campanha
    );
    dispatch({
      type: 'PRODUTOS_TABLOIDE_CAMPANHA',
      payload,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCestaProduto = produto => async dispatch => {
  try {
    dispatch(loadingPerformance());
    const payload = await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getCestaProduto`,
      produto
    );
    dispatch({
      type: 'CESTA_PRODUTO_TABLOIDE',
      payload,
    });
  } catch (err) {
    console.log(err);
  }
};
