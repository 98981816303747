import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4, 2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  textFormTest:{
    '& label.Mui-focused': {
      color: '#2813AD !important',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2813AD !important',
      },
    },   
  }
}));

export default useStyles;