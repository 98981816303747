/* eslint-disable */
import axios from 'axios';
import Alert from './Alert';
import ExpirationVerify from './ExpirationVerify';

const alerta = new Alert();

// para caso der problema em varias funcoes,
// faz um timer pra nao aparecer alert pra cada uma
var timer;
function timerFunc(func, msec) {
  window.clearTimeout(timer);
  timer = window.setTimeout(func, msec);
}

export const Fetch = (
  url,
  data,
  method = 'GET',
  token,
  responseType,
  showAlert = true
) =>
  new Promise(async (resolve, reject) => {
    try {
      const payload = await _fetch(
        url,
        data,
        method,
        token,
        responseType,
        showAlert
      );

      resolve(payload);
    } catch (error) {
      console.log(error);
      if (
        error.status === 0 &&
        (process.env.NODE_ENV === 'production' || true)
      ) {
        // localStorage.removeItem('email');
        // localStorage.removeItem('name');
        // localStorage.removeItem('token');
        // localStorage.removeItem('permissions');
        // localStorage.removeItem('logged');
        // localStorage.removeItem('refresh_token');
        // localStorage.removeItem('company');
        // localStorage.removeItem('conpass.token');
        // localStorage.removeItem('conpass.user.data');
        // localStorage.removeItem('conpass.publicToken');
        // localStorage.removeItem('companyId');
        // window.history.go('/');
        timerFunc(
          () => {},
          // ExpirationVerify({
          //   forceCleanStorage: true,
          //   msg: ' (st0)',
          // }),
          1000
        );
      } else {
        showAlert && alerta.alertError(error.title, error.message);
      }
      reject(error);
    }
  });

const _fetch = (
  url,
  data,
  method = 'GET',
  token,
  responseType,
  showAlert = true
) => {
  if (
    method !== 'GET' &&
    !url.includes('login') &&
    !url.includes('banner') &&
    !url.includes('postRecebeArquivo') &&
    !url.includes('ads-manager')
  ) {
    showAlert &&
      alerta.alertProcess(
        'Aguarde um momento.',
        'Estamos organizando os dados, esse processo pode demorar alguns segundos.'
      );
  }
  return new Promise((resolve, reject) => {
    const timeStart = Date.now();
    axios({
      responseType: responseType || 'json',
      method,
      params: method !== 'GET' ? null : data,
      url,
      data,
      headers: {
        Authorization: token || `${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
      .then(response => {
        const data = response.data;
        if (data && data.warning) {
          showAlert && alerta.alertWarning(data.title, data.warning);
        }
        if (method !== 'GET' && data && data.message && !url.includes('ads')) {
          showAlert &&
            alerta.alertSuccess(data.title, data.message, data.footer);
        }
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          const { data, status } = error.response;
          if (status === 401) {
            // localStorage.removeItem('email');
            // localStorage.removeItem('name');
            // localStorage.removeItem('token');
            // localStorage.removeItem('permissions');
            // localStorage.removeItem('logged');
            // localStorage.removeItem('refresh_token');
            // localStorage.removeItem('company');
            // localStorage.removeItem('conpass.token');
            // localStorage.removeItem('conpass.user.data');
            // localStorage.removeItem('conpass.publicToken');
            // localStorage.removeItem('companyId');
            // window.history.go('/');
            timerFunc(
              () => {},
              // ExpirationVerify({
              //   forceCleanStorage: true,
              //   msg: ' (401)',
              // }),
              1000
            );
            reject(401);
          } else {
            const message =
              data && data.warning
                ? data.warning
                : data.message
                ? data.message
                : 'Desculpe ocorreu algum erro na sua requisição.';

            reject({ message, title: data.title, status });
          }
        } else if (error.request) {
          const message =
            'Não foi possível completar a sua ação. Tente novamente. Caso esta situação persista, entre em contato com o suporte.';
          const title = 'Infelizmente algo deu errado';

          let errorStatus = error.request.status;
          Date.now() - timeStart >= 15 * 1000 && (errorStatus = 504); // Estratégia para detectar falha API Gateway 30seg

          reject({ message, title, status: errorStatus });
          //reject({ message, title, status: error.request.status });
        } else {
          // Something happened in setting up the request that triggered an Error
          reject({ message, title: data.title, status });
          console.log('Error', error.message);
        }
      });
  });
};

export default Fetch;
