import React from 'react';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';

import { Modal, Select, TextField, MyButton } from '../../../utils/components';
import {
  COLOR_PRIMARY,
  UNIDADE_TEMPO,
  DIAS,
  DIAS_MES,
} from '../../../utils/helpers/Constants';

function textAutomation(state) {
  const unit = state.unitAutomacao ? `${state.unitAutomacao}` : '',
    time = state.unitTimeAutomacao
      ? state.unitTimeAutomacao.label.toLowerCase()
      : '',
    dia = state.diaDisparoAutomacao
      ? state.diaDisparoAutomacao.label.toLowerCase()
      : '';

  const text = `A cada ${unit} ${time}${dia ? `, todos os dias ${dia}` : ''}`;
  return text;
}

export default ({ value, open, onClose, onChange, onSave }) => (
  <Modal
    size="sm"
    showModal={open}
    onClose={onClose}
    header="Automação"
    footer={
      <MyButton
        size="large"
        color="primary"
        variant="text"
        onClick={() => {
          onSave();
          onClose();
        }}
      >
        Salvar
      </MyButton>
    }
  >
    <section style={{ padding: '2rem', display: 'grid', gap: '2rem' }}>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Informações da automação
      </Typography>
      <span>{textAutomation(value)}</span>
      <TextField
        type="number"
        label="A cada"
        className="form-control"
        onChange={({ target }) => onChange({ unitAutomacao: target.value })}
        value={value.unitAutomacao}
      />
      <Select
        label="Unidade de tempo"
        options={UNIDADE_TEMPO}
        onChange={tipo => onChange({ unitTimeAutomacao: tipo })}
        value={value.unitTimeAutomacao}
      />
      {value.unitTimeAutomacao &&
        value.unitTimeAutomacao.value !== 'days' &&
        value.unitTimeAutomacao.value !== 'hours' && (
          <Select
            label="Todas às"
            options={
              value.unitTimeAutomacao.value !== 'months' ? DIAS : DIAS_MES
            }
            onChange={dia => onChange({ diaDisparoAutomacao: dia })}
            value={value.diaDisparoAutomacao}
          />
        )}
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            checked={value.active}
            onChange={e => onChange({ active: e.target.checked })}
            name="checkedB"
            color="primary"
          />
        }
        label="Ativar automação"
      />
    </section>
  </Modal>
);
