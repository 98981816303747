/* eslint-disable */
import {
  Button,
  Divider,
  Typography,
  withStyles,
  Icon,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CustomTable,
  TextField,
} from '../../../../utils/components';
import {
  COLOR_PRIMARY,
} from '../../../../utils/helpers/Constants';
import { 
  getTabloidsEtiquetas,
  addTabloidsEtiqueta
} from '../../action/index';
import { toBase64 } from '../../../../utils/helpers/Functions';

class EditEtiquetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ocasiao: null,
      nome: null,
      tipoPixelixe: null,
      id_cliente: 396,
      formato: null,
      base64: null,
      //
      id_cliente_search: null,
      tipoPixelixe_search: null
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Link 
          to={`/propaganda-de-ofertas`} 
          style={{ marginTop: 15, textDecoration: 'none' }}
        >
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Propaganda de Ofertas
          </Button>
        </Link>        
        <div className={classes.actions}>
          <div className={classes.actions} style={{ maxWidth: '65vw' }}>          
            <Typography className={classes.margin} variant="h3">
              Edit Etiquetas
            </Typography>
          </div>
          <div style={{ marginBottom: 20, display: 'flex' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15, marginBottom: 10 }}
              onClick={() => this.props.getTabloidsEtiquetas()}
            >
              Pesquisar
            </Button>
          </div>
        </div>
        <div className={classes.actions} style={{ marginLeft: 'auto' }}>
          <div style={{ marginBottom: 20, display: 'flex' }}>            
            <div style={{ margin: 10 }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={async e => {
                  const imageEdit = await toBase64(e.target.files[0]);
                  this.setState({
                    base64: imageEdit.base64
                  }); 
                }}
              />
              <label htmlFor="contained-button-file">
                <Button size="small" component="span">
                  Escolher Imagem {this.state.base64 && <DoneAllIcon/>}
                </Button>
              </label>
            </div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15, marginBottom: 10 }}
              onClick={() => {
                this.props.addTabloidsEtiqueta({
                  base64: this.state.base64
                }).then(() => {
                    this.props.getTabloidsEtiquetas();
                  }
                )
              }}
            >
              Adicionar
            </Button>
          </div>  
        </div>     

        <Divider className={classes.divider} />

        <div
          className={classes.content}
        >
          <div className={classes.content}>
            <CustomTable
              data={this.props.tabloids_etiquetas}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loadind_tabloides_etiquetas}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[    
                { 
                  title: 'Id', 
                  field: 'id' 
                },        
                { 
                  title: 'Original', 
                  field: 'original',
                  render: rowData => (
                    <img
                      alt={rowData.original}
                      src={rowData.original}
                      style={{ width: 75, height: 75, cursor: 'pointer', backgroundColor: '#e7e7e7' }}
                    />
                  ),
                },
                { 
                  title: 'Etiqueta', 
                  field: 'etiqueta',
                  render: rowData => (
                    <img
                      alt={rowData.etiqueta}
                      src={rowData.etiqueta}
                      style={{ width: 75, height: 75, cursor: 'pointer', backgroundColor: '#e7e7e7' }}
                    />
                  ),
                },
                { 
                  title: 'Data Insert', 
                  field: 'data_insert' 
                }               
              ]} 
              deleteText="Deseja REALMENTE desativar essa etiqueta? CUIDADO!!!!"
              editable={{              
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props.addTabloidsEtiqueta({
                      id: oldData.id,
                      delete: true
                    }).then(() => {
                      this.props.getTabloidsEtiquetas();
                      resolve();
                    });               
                  }),
              }}    
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({
  TabloideDigitalReducer
}) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  getTabloidsEtiquetas,
  addTabloidsEtiqueta
};

const EditEtiquetasConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEtiquetas);

export default withStyles(styles)(EditEtiquetasConnect);
