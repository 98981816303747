import { Button } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CustomTable, MyButton } from '../../../../utils/components';
import { AlertDialogV2 } from '../../../../utils/components/AlertDialogV2';
import { toBase64 } from '../../../../utils/helpers/Functions';
import { disableLoja, getLojas, postLoja, putInfoLoja } from '../../../Delivery/action/action';
import ModalAddLoja from '../../../Delivery/components/ModalAddLoja';
import { postProdutoImagemTabloide } from '../../../TabloideDigital/action';
import { FormEditLoja } from './components/FormEditLoja';
import ModalAddNewStore from './components/ModalAddNewStore';
import useStyles from './styles';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

function Lojas(props) {
  const [newData, setNewData] = useState(null),
    [modalAddLoja, setModalAddLoja] = useState(false),
    //[naoRemoverFundo, setNaoRemoverFundo] = useState(false),
    [openAlertDialog, setAlertDialog] = useState(false),
    [storeDisableConfirm, setStoreDisableConfirm] = useState(null),
    [openModalEditRow, setOpenModalEditRow] = useState(null),
    [rowEdit, setRowEdit] = useState({});

  const classes = useStyles();

  useEffect(() => {
    props.getLojas();
  }, []);

  const handleOpenAlertDialog = () => {
    setAlertDialog(true);
  };

  const handleCloseAlertDialog = () => {
    setAlertDialog(false);
  };

  function deleteRow(row) {
    if (openAlertDialog && storeDisableConfirm) {
      handleCloseAlertDialog();
      const loja = storeDisableConfirm;
      setStoreDisableConfirm(null);
      props.disableLoja(loja);
    } else {
      const loja = {
        id_loja: row.id_loja,
        nome_loja: row.nome_loja
      };
      setStoreDisableConfirm(loja);
      handleOpenAlertDialog();
    }
  }

  async function onSaveLoja(loja) {
    const objSaveLoja = {
      bairro: loja.bairro,
      cep: loja.cep,
      cidade: loja.cidade,
      descricao: loja.descricao,
      endereco: loja.endereco,
      frete: parseFloat(loja.frete.replace(',', '.')),
      maximo_item: loja.maximo_item,
      minimo_compra: parseFloat(loja.minimo_compra.replace(',', '.')),
      nome_loja: loja.nome_loja,
      numero: loja.numero,
      tipo_pedido: loja.tipo_pedido,
      taxa_servico: parseFloat(loja.taxa_servico.replace(',', '.')),
      forma_pagamento: loja.forma_pagamento,
      uf: loja.uf,
      whatsapp_phone_number: loja.whatsapp_phone_number,
      aceita_dinheiro: loja.aceita_dinheiro,
      aceita_debito: loja.aceita_debito,
      aceita_credito: loja.aceita_credito,
      aceita_boleto: loja.aceita_boleto,
      aceita_vr: loja.aceita_vr,
      aceita_pix: loja.aceita_pix,
      chave_pix: loja.chave_pix,
      logo1: loja.logo1,
      naoRemoverFundo: loja.naoRemoverFundo
    };

    if (objSaveLoja.logo1 != null) {
      let new_image = '';
      new_image = await props
        .postProdutoImagemTabloide({
          imagem_produto: objSaveLoja.logo1,
          isBase64: true,
          naoRemoverFundo: Boolean(objSaveLoja.naoRemoverFundo),
          isLogo: true
        })
        .then(dados => {
          objSaveLoja.logo1 = dados.image;
        });
    }

    setModalAddLoja(false);
    props.postLoja(objSaveLoja);
  }

  async function onEditRow(objEditLoja) {
    setOpenModalEditRow(false);
    let new_image = '';
    if (Boolean(objEditLoja.logo1) && /;base64,/g.test(objEditLoja.logo1)) {
      new_image = await props.postProdutoImagemTabloide({
        imagem_produto: objEditLoja.logo1,
        isBase64: true,
        naoRemoverFundo: Boolean(objEditLoja.naoRemoverFundo),
        isLogo: true
      });
    }
    let dateNow = new window.Date();
    dateNow = dateNow.getTime().toString();
    if (validURL(new_image)) new_image = new_image.split('?')[0] + '?' + dateNow;
    objEditLoja = { ...objEditLoja, logo1: new_image };

    objEditLoja = {
      ...objEditLoja,
      frete: objEditLoja.frete ? parseFloat(objEditLoja.frete.toString().replace(',', '.')) : null,
      minimo_compra: objEditLoja.minimo_compra
        ? parseFloat(objEditLoja.minimo_compra.toString().replace(',', '.'))
        : null,
      taxa_servico: objEditLoja.minimo_compra
        ? parseFloat(objEditLoja.taxa_servico.toString().replace(',', '.'))
        : null
    };
    props.putInfoLoja(objEditLoja);
  }

  function openEditRow() {
    //não usei o useEffect por causa do re-rendering
    setOpenModalEditRow(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <MyButton
          onClick={() => setModalAddLoja(true)}
          size="large"
          className={classes.button}
          color="secondary"
        >
          Adicionar nova loja
        </MyButton>
      </div>
      <div className={classes.content}>
        <CustomTable
          data={props.lojas || []}
          isLoading={props.loadingDelivery}
          pageSize={10}
          paging
          onRowClick={(evt, selectedRow) => {
            if (evt.target.id !== 'deleteRow') {
              // setSelectedRow(selectedRow.tableData.id);
              setRowEdit(selectedRow);
              openEditRow();
            }
          }}
          style={{
            width: '100%'
          }}
          headerStyle={{
            backgroundColor: '#F4F3FB',
            color: '#1D1D1D',
            zIndex: 0,
            padding: '25px 0 25px 25px',
            textAlign: 'center'
          }}
          rowStyle={{
            width: '100%',
            maxHeight: '80px',
            backgroundColor: '#FFFFFF',
            color: '#707070',
            lineHeight: '22px',
            fontWeight: '500',
            fontFamily: 'Montserrat'
          }}
          columns={[
            {
              field: 'logo1',
              title: 'Logo',
              align: 'center',
              editComponent: props => (
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#e7e7e7'
                  }}
                >
                  <img
                    alt="logo"
                    src={
                      props.value
                        ? props.value.includes('data:')
                          ? props.value
                          : props.value.split('?')[0] + '?' + Math.random().toString()
                        : null ||
                          'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                    }
                    style={{ width: 70 }}
                  />
                  <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="change-image"
                      type="file"
                      onChange={async ({ target: { files } }) => {
                        const imageEdit = await toBase64(files[0]);
                        props.onChange(imageEdit.base64);
                      }}
                    />
                    <label htmlFor="change-image">
                      <Button size="small" component="span">
                        Escolher foto
                      </Button>
                    </label>
                  </div>
                </section>
              ),
              render: rowData => (
                <img
                  alt={rowData.logo1}
                  src={
                    rowData.logo1
                      ? rowData.logo1.split('?')[0] + '?' + Math.random().toString()
                      : null ||
                        'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png'
                  }
                  style={{
                    width: 70,
                    height: 80,
                    borderColor: 'white'
                  }}
                />
              )
            },
            {
              title: 'Loja',
              field: 'nome_loja',
              editable: 'never',
              align: 'center',
              cellStyle: {
                textAlign: 'center !important'
                // background: 'blue'
              }
            },
            {
              title: 'Whatsapp',
              field: 'whatsapp_phone_number',
              align: 'center'
            },
            {
              title: 'CEP',
              field: 'cep',
              align: 'center'
            },

            {
              title: 'Endereco',
              field: 'endereco',
              align: 'center'
            },
            {
              title: 'Bairro',
              field: 'bairro',
              align: 'center'
            },

            {
              title: 'Cidade',
              field: 'cidade',
              align: 'center'
            },
            {
              title: '',
              field: 'deleteRow',
              align: 'center',
              disableClick: true,
              render: rowData => (
                <div
                  onClick={() => {
                    deleteRow(rowData);
                  }}
                  style={{
                    padding: 0,
                    width: '100%',
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <DeleteOutlinedIcon
                    id={'deleteRow'}
                    style={{
                      display: 'flex',
                      color: '#B02F2F'
                    }}
                  />
                </div>
              )
            }
          ]}
        />
      </div>

      <AlertDialogV2
        open={openAlertDialog}
        title={'Tem certeza que deseja excluir esta loja?'}
        contentText={'Se você confirmar, todas as informações serão excluídas.'}
        textAgree={'Sim, excluir'}
        textClose={'Cancelar'}
        handleClose={() => {
          setStoreDisableConfirm(null);
          handleCloseAlertDialog();
        }}
        handleAgree={() => {
          deleteRow();
        }}
      />

      {/*
      <ModalAddLoja
        showModal={modalAddLoja}
        onSave={loja => onSaveLoja(loja)}
        onClose={() => setModalAddLoja(false)}
      />
*/}
      <ModalAddNewStore
        showModal={modalAddLoja}
        onSave={loja => onSaveLoja(loja)}
        onClose={() => setModalAddLoja(false)}
      />

      {openModalEditRow && (
        <FormEditLoja
          open={openModalEditRow}
          data={rowEdit}
          onSave={dataStore => {
            onEditRow(dataStore);
          }}
          onClose={() => {
            setRowEdit({});
            setOpenModalEditRow(false);
          }}
        />
      )}

      {/*<Dialog  //MODAL QUE ERA USADO PARA PERGUNTAR AO USUARIO QUE ELE QUER REMOVER O FUNDO DE UMA IMAGEM.
        open={modalRemoverFundo}
        onClose={() => setModalRemoverFundo(false)}
      >
        <DialogTitle>Deseja remover o fundo da imagem?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setModalRemoverFundo(false);
              setNaoRemoverFundo(true);
              setCount(prev => prev + 1);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              setModalRemoverFundo(false);
              setNaoRemoverFundo(false);
              setCount(prev => prev + 1);
            }}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
          </Dialog>*/}
    </div>
  );
}

const mapStateToProps = ({ DeliveryReducer }) => ({ ...DeliveryReducer });

const mapDispatchToProps = {
  getLojas,
  putInfoLoja,
  postLoja,
  postProdutoImagemTabloide,
  disableLoja
};

const LojasConn = connect(mapStateToProps, mapDispatchToProps)(Lojas);

export default LojasConn;

