//import { MenuItem } from "@material-ui/core";
import { 
  Checkbox,
  Grid,
  //Select,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useStyles } from './styles';
import { StateProps } from "store/types";
import { setSelectedPostsRepeat } from "modules/TabloideDigitalV2/action";

export function RepeatPosts(){
  const styles = useStyles();
  const dispatch = useDispatch();
  const [repeatPosts, setRepeatPosts] = useState<boolean> (false);

  const { frequenceScheduledPosts } = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer
    );

  return (
    <Grid 
      className={styles.gridGlobal}
      flexDirection={'column'}
      display={'flex'}
    >
      
      <Grid className={styles.gridSelect}>
        <Checkbox
          className={styles.buttonSetRepeatPost}
          size={'medium'}
          value={repeatPosts}
          onClick={(event: any)=>{
            setRepeatPosts(event.target.checked);
            if(!event.target.checked){
              dispatch(setSelectedPostsRepeat(0));
            }
          }}
        />
        <Typography fontWeight={500}>Repetir Publicações</Typography>
      </Grid>
      
      {repeatPosts && 
      <Grid
        className={styles.gridRepeatConfig}
        flexDirection={'column'}
      >
        <Typography
          className={styles.typographyRepeatPost}
          color={'#616161'}
          fontWeight={500}
          >
            Ao selecionar esta opção, as artes serão publicadas repetidamente,
            ou seja, 
            depois que todas as artes forem publicadas, 
            os posts serão postados novamente.
        </Typography>
        <Typography
          className={styles.typographyRepeatPost}
          color={'#1E1F21'}
          fontWeight={500}
        >
          Por quantas vezes deseja publicar cada post? (1 - 52)
        </Typography>

        <TextField
          type="number"
          placeholder="1-52"
          value={frequenceScheduledPosts.postsRepeat}
          variant="outlined"
          onChange={({ target: { value } }) => {
            let number = Number(value);
            if(number <= 52 && number >= 0){
              dispatch(setSelectedPostsRepeat(value));
            }
          }}
          sx={{
            width: '3.975rem',
            '&.MuiTextField-root,.MuiOutlinedInput-input': {
              background: '#FFF'
            }
          }}
        />
        {/*<Select
          labelId="id-select-repeat-post-label"
          id="id-select-repeat-post"
          className={styles.buttonSelectRepeat}
          defaultValue={0}
          sx={{
            '&.MuiSelect-outlined,.MuiOutlinedInput-input': {
              background: '#FFF'
            }
          }}
          onChange={(_,element: any) =>{
              dispatch(setSelectedPostsRepeat(element.props.value));
            }
          }
        >
          <MenuItem value={0}>Não Repetir</MenuItem>
          <MenuItem value={1}>Repetir 1 Vez</MenuItem>
          <MenuItem value={2}>Repetir 2 Vezes</MenuItem>
          <MenuItem value={3}>Repetir 3 Vezes</MenuItem>
        </Select>*/}

      </Grid>}
    
    </Grid>
  )


}