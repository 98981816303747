import { makeStyles } from '@material-ui/core';

const color1 = '#312A5E';
const color2 = '#1D1D1D';
const color3 = '#0077e5';
const color4 = '#616161';
const color5 = '#2813AD';
const color6 = '#000000';

export const useDefaultStyles = makeStyles(theme => ({
  root: {
    letterSpacing: '0.05em'
  },
  //   avatar: { backgroundColor: theme.palette.primary.main },
  gridMediaQueryHeight: { //
    height: '250px',
    display: 'flex',
    backgroundColor: '#F5F5F5',
    borderRadius: '4px'
    // alignItems: 'center',
    // width: '100%',
    // width: 180,
    // 'max-height': 250,
    // position: 'relative',
    // background: "#EFEFAF"

    // [theme.breakpoints.down('lg')]: {
    //   height: '100%'
    // }
  },
  // gridMediaQueryHeight2: {
  //   width: 125,
  //   'min-height': 125,
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '290px'
  //   }
  // },
  // gridMediaQueryHeight250: {
  //   height: 'auto',
  //   alignItems: 'center',
  //   width: 'auto',
  //   'max-height': 290,
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '100%'
  //   }
  // },
  // gridMediaQueryHeight180: {
  //   height: 'auto',
  //   alignItems: 'center',
  //   width: 'auto',
  //   'min-height': 180,
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '100%'
  //   }
  // },
  // gridMediaQueryHeight128: {
  //   alignItems: 'center',
  //   width: '100%',
  //   'min-height': '128px',
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '100%'
  //   }
  // },
  // gridMediaQueryHeight164: {
  //   alignItems: 'center',
  //   width: 180,
  //   'min-height': '164px',
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '100%'
  //   }
  // },
  // gridMediaQueryHeight102: {
  //   alignItems: 'center',
  //   width: '100%',
  //   'min-height': '102px',
  //   position: 'relative',

  //   [theme.breakpoints.down('lg')]: {
  //     height: '100%'
  //   }
  // },
  gridImageOver: {
    filter: 'brightness(0.5)'
  },
  gridImage: {
    // 'max-height': '250px',
    'max-width': '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
    // marginTop: '24px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    borderRadius: 8

    // [theme.breakpoints.down('md')]: {
    //   'max-width': '140px',
    //   width: 'auto'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   'max-width': '100px',
    //   width: 'auto'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   'max-width': '60px',
    //   width: 'auto'
    // }
  },
  gridImageEstudio: {
    'max-height': '250px',
    'max-width': '100%',
    width: 180,
    height: 'auto',
    position: 'relative',
    // marginTop: '24px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    borderRadius: 8
  },
  gridImageEstudio2: {
    'max-height': '225px',
    'max-width': '100%',
    width: 180,
    height: 'auto',
    // marginTop: '24px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    borderRadius: 8
  },
  gridImage2: {
    'max-height': '100%',
    'max-width': '100%',
    width: 125,
    height: 125,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    // margin: 'auto',
    borderRadius: 8
  },
  cardTabloids: {
    marginRight: 24,
    marginBottom: 32,
    width: 180,
    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'space-between'
  },
  gridCard: {
    cursor: 'pointer',
    borderRadius: 8,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
    // height: '100%',
    // 'max-width': '183px',
    // 'max-height': '250px',

    // transition: 'background-color 2s ease-out 100ms',

    // [theme.breakpoints.down('lg')]: {
    //   'max-width': '182px'
    // },

    // [theme.breakpoints.down('md')]: {
    //   'max-width': '142px'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   'max-width': '102px'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   'max-width': '62px'
    // }
  },
  gridCard2: {
    backgroundColor: 'whitesmoke',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: 8,
    'min-height': 250,

    // '-webkit-box-shadow': '2px 2px 13px -1px #D1D1D1',
    // 'box-shadow': '2px 2px 13px -1px #D1D1D1',

    transition: 'background-color 2s ease-out 100ms',
    '&:hover': {
      'background-color': '#A2A2A2'
    }
  },
  gridTitle: {
    color: color2,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    letterSpacing: '0.05em'
  },
  gridTitle2: {
    color: color6,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    'text-overflow': 'ellipsis',
    letterSpacing: '0.05em',
    'white-space': 'break-spaces',
    display: '-webkit-box',
    maxWidth: '180px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis'
  },
  gridMoreOptions: {
    color: color3,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    letterSpacing: '0.05em'
  },
  cardText: {
    fontStyle: 'normal',
    letterSpacing: '0.05em'
  },
  textTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
    color: color2
  },
  textSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '22px',
    color: color4
  },
  rootTimeline: {
    width: '100vw',
    fontStyle: 'normal',
    letterSpacing: '0.05em',

    '& .MuiTab-root': {
      padding: 0,
      lineHeight: '22px',
      color: color4
    },

    '& .MuiButtonBase-root-MuiTab-root': {},

    '& .MuiButtonBase-root.Mui-selected': {
      color: color5
    },

    '& .MuiTabs-indicator': {
      backgroundColor: color5
    },

    '& .MuiDivider-root': {
      borderColor: '#D3D3D3'
    },

    '& .MuiGrid-root': {
      marginTop: 0
    },

    '& .MuiGrid-root>MuiGrid-item': {
      padding: '16px 0 8px 16px'
    },

    '& .MuiTabs-flexContainer': {
      gap: 32
    }
  },
  rootCardSearch: {
    padding: '0 0 40px 0',
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    minHeight: 40
  },
  cardTextField: {
    padding: 0,

    '& .MuiInputBase-root': {
      fontFamily: 'Montserrat',
      color: '#B0B0B0',
      borderRadius: '5px',
      padding: '12px 0',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '0.05em'
    },

    '& .MuiOutlinedInput-root': {
      width: 315,
      maxHeight: 40,
      padding: '12px'
      // // borderColor: '#2813AD !important',

      // '&:hover': {
      //   borderRadius: 5,
      //   border: '2px solid #2813AD'
      // },

      // '&:focus': {
      //   borderRadius: 5,
      //   border: '2px solid #2813AD !important'
      // },

      // '&:focused': {
      //   borderRadius: 5,
      //   border: '2px solid #2813AD !important'
      // }
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: '#2813AD !important'
    },

    '& .MuiInputLabel-root': {
      color: '#2813AD',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '0.05em'
    }
  },
  textField: {
    // width: '100%',
    // height: '100%',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    padding: 0
  },
  inputAdorment: {
    padding: 0,
    width: 16,
    height: 16
  },
  cardOptions: { //
    minHeight: 106,
    maxHeight: 250,
    height: '100%',
    width: '180px',
    position: 'absolute',
    '&:hover': {
      pointer: 'none',
      background: 'rgba(0,0,0,.3)',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,

      '& $wrapTrashIcon': {
        visibility: 'visible'
      },

      '& $wrapTrashText': {
        visibility: 'visible'
      },
      '& $favoriteDiv':{
        background: 'rgba(255, 255, 255, 0.5);',

        '& $favoriteIcon':{
          color: '#FADB14'
        }
      }
    }
  },
  redirectEditor: {
    minHeight: 106,
    maxHeight: 250,
    height: '100%',
    width: '100%'
  },
  wrapTrashIcon: {
    visibility: 'hidden',
    background: '#FFF',
    width: '25px',
    height: '25px',
    right: 5,
    top: 8,
    position: 'absolute',
    borderRadius: '2px'
  },
  wrapTrashText: { //
    visibility: 'hidden',
    background: '#FFF',
    display: 'flex',
    width: '55px',
    height: '25px',
    left: 6,
    top: 8,
    position: 'absolute',
    borderRadius: '2px',
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.05em',
    color: color4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  favoriteDiv:{
    boxSizing:'border-box',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'column',
    width: '27.85px',
    height: '26.96px',
    left: 7,
    top: 6,
    cursor: 'pointer',
    borderRadius: '4px'
  },
  favoriteIcon:{
    color: '#FFF'
  },
  favoriteIcon2:{
    color: '#FADB14'
  },
  title1: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: 24,
    color: color1,
    letterSpacing: '0.05em'
  },
  'title1-2': {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    color: color1,
    letterSpacing: '0.05em'
  },
  title2: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 16,
    color: color1,
    letterSpacing: '0.05em'
  },
  greyBox: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    color: color1,
    backgroundColor: '#F5F5F5',
    padding: 10,
    letterSpacing: '0.05em'
  },
  tab: {
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '0.05em'
  },

  productText: {
    color: color2,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    letterSpacing: '0.05em'
  },
  productText2: {
    color: color4,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.05em',
    'white-space': 'break-spaces',
    display: '-webkit-box',
    maxWidth: '125px',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis'
  },
  productPrice: {
    color: color2,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '26px',
    'white-space': 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: '0.05em'
  },
  titleFavoriteBackgrounds:{
    left: '20px',
    width: '110px',
    height: '25px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.05em',
    color: '#1D1D1D',
    flex: 'none',
    order: 1,
    flexGrow: 0
  }
  
}));

