import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardContent,
  CardHeader,
  Card,
  Divider,
  CardActions
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { BG_PRIMARY } from "../../helpers/Constants";

export default function Panel({
  width,
  noHeader,
  avatar,
  action,
  title,
  subheader,
  noDivider,
  children,
  style,
  onClick,
  cardActions
}) {
  const classes = useStyles();

  return (
    <div onClick={onClick} style={{ width: width ? width : "100%" }}>
      <Card className={classes.card} style={style}>
        {!noHeader && (
          <CardHeader
            avatar={avatar}
            action={action}
            title={title}
            titleTypographyProps={{ variant: "h5" }}
            subheader={subheader}
          />
        )}

        {!noDivider && <Divider className={classes.divider}></Divider>}
        <CardContent className={classes.cardContent}>{children}</CardContent>
        {cardActions && (
          <React.Fragment>
            <Divider />
            <CardActions style={{ justifyContent: "flex-end" }}>
              {cardActions}
            </CardActions>
          </React.Fragment>
        )}
      </Card>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    borderRadius: 7,
    color: BG_PRIMARY
  },
  divider: {
    margin: theme.spacing(2)
  },
  cardContent: {
    width: "100%",
    color: BG_PRIMARY
  },

  avatar: {
    backgroundColor: red[500]
  }
}));
