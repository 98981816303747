import { withStyles, LinearProgress } from '@material-ui/core';

export const normalise = value => ((value - 1000) * 100) / (5000 - 1000);

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export default BorderLinearProgress;
