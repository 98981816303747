import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Search from '@mui/icons-material/Search';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TypographySubTitle, TypographyTitle } from '../Studio/styles';

import {
  IconButtonFooter,
  TextFieldCustom,
  TypographyCategory,
  TypographySubcategory,
  useStyles
} from './styles';
import 'swiper/components/navigation/navigation.scss';

import {
  findCategories,
  findSubcategories,
  findSubcategoriesDescription,
  findSubcategoriesInAll
} from 'services/professional';
import {
  ResponseSubcategories,
  ResponseSubcategoriesBackground,
  ImageDownloadType
} from 'types/professional';

import ImagePreview from '../../FastModels/components/ImagePreview';
import PricePage from '../../FastModels/pages/prices';

import { setSelectedBackgrounds } from '../../FastModels/actions';
import { Grid } from '@mui/material';

function Professional() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [product, setProduct] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [openSearchProduct, setOpenSearchProduct] = useState<boolean>(false);
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
  const [categorySelected, setCategorySelected] = useState<number>();
  const [subCategorySelected, setSubCategorySelected] = useState<number>();
  const [hoverImage, setHoverImage] = useState<number | null>();
  const [offset, setOffset] = useState<number>(1);
  const [subcategoriesData, setSubcategoriesData] = useState<ResponseSubcategories[]>([]);
  const [backgroundsSelected, setBackgroundSelected] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<ImageDownloadType>({
    name: undefined,
    url: undefined
  });

  const handleChangeProduct = (product: string) => {
    setProduct(product);
  };

  const handleChangeCategory = (categoryId: number) => {
    setSubcategoriesData([]);
    setSubCategorySelected(undefined);
    setProduct(undefined);
    setOffset(1);
    setCategorySelected(categoryId);
  };

  const handleOpenAutocomplete = () => {
    setOpenSearchProduct(prev => !prev);
  };

  const handleChangePage = (page: number) => {
    if (page === 2) {
      //@ts-ignore
      dispatch(setSelectedBackgrounds(backgroundsSelected));
    }
    setPage(page);
  };

  const handleHoverImage = (subcategoryId?: number) => {
    if (Boolean(subcategoryId)) {
      setHoverImage(subcategoryId);
    } else {
      setHoverImage(null);
    }
  };

  const isBackgroundSelected = (subcategoryId: number, backgroundId: number) => {
    return (
      backgroundsSelected?.some(
        (background: any) =>
          background.subcategoryId === subcategoryId &&
          background.backgroundData.id === backgroundId
      ) || false
    );
  };

  const handleSelectedBackgrounds = (
    subcategoryDescription: string,
    subcategoryId: number,
    data: ResponseSubcategoriesBackground
  ) => {
    const currentBackground = [
      {
        subcategoryId: subcategoryId,
        subcategoryDescription: subcategoryDescription,
        backgroundData: data
      }
    ];

    if (!isBackgroundSelected(subcategoryId, data.id)) {
      if (backgroundsSelected.length < 10) {
        setBackgroundSelected(prev => [...prev, ...currentBackground]);
      } else {
        enqueueSnackbar('Uuupss!! Apenas é possível selecionar até 10 imagens para edição.', {
          variant: 'error'
        });
      }
    } else {
      const removeSelectedBackgrounds = backgroundsSelected.filter(
        background =>
          background.subcategoryId !== subcategoryId || background.backgroundData.id !== data.id
      );
      setBackgroundSelected(removeSelectedBackgrounds);
    }
  };

  const handleRemoveBackground = (subcategoryId: number, data: ResponseSubcategoriesBackground) => {
    const removeSelectedBackgrounds = backgroundsSelected.filter(
      background =>
        background.subcategoryId !== subcategoryId || background.backgroundData.id !== data.id
    );
    setBackgroundSelected(removeSelectedBackgrounds);
  };

  const segmentId = localStorage.getItem('segmentId');

  const categories = useQuery('categories', () => findCategories(Number(segmentId)), {
    enabled: Boolean(segmentId),
    cacheTime: 0,
    onSuccess: response => {
      setCategorySelected(response[0].ID);
    }
  });

  const subcategories = useQuery(
    ['subcategories', categorySelected, offset],
    () => findSubcategories(Number(categorySelected), 6, offset),
    {
      enabled: Boolean(categorySelected),
      cacheTime: 0
    }
  );

  const subcategoriesDescription = useQuery(
    ['subcategories', categorySelected],
    () => findSubcategoriesDescription(Number(categorySelected), product),
    {
      enabled: Boolean(categorySelected),
      cacheTime: 0
    }
  );

  useQuery(
    ['subcategories', categorySelected, subCategorySelected],
    () => findSubcategoriesInAll(Number(categorySelected), Number(subCategorySelected)),
    {
      enabled: Boolean(subCategorySelected),
      cacheTime: 0,
      onSuccess: response => {
        const hasExistSubcategory = subcategoriesData.findIndex(
          subcategory => subcategory.DESCRIPTION === response[0].DESCRIPTION
        );

        if (hasExistSubcategory > -1) {
          const subcategory = subcategoriesData.splice(hasExistSubcategory, 1)[0];
          subcategoriesData.splice(0, 0, subcategory);
        } else {
          subcategoriesData.unshift(response[0]);
        }
      }
    }
  );

  useEffect(() => {
    if (subcategories.data) {
      setSubcategoriesData(prev => [...prev, ...subcategories.data]);
    }
  }, [subcategories.data]);

  const handleRefreshSubcategories = async () => {
    await subcategoriesDescription.refetch();
  };

  if (page === 2) {
    return (
      <div className={styles.containerEditBackgrounds}>
        <PricePage
          onChangePage={value => setPage(value)}
          onOpenBackground={(name, url) => {
            setPreviewImage({ name, url });
            setOpenImagePreview(true);
          }}
        />
        ;
        <ImagePreview
          open={openImagePreview}
          imageName={previewImage?.name}
          imageUrl={previewImage?.url}
          onClose={() => setOpenImagePreview(false)}
        />
      </div>
    );
  }

  return (
    <>
      <Stack 
        pl="2vw" 
        pr="1vw" 
        pb="5vh" 
        spacing={5} 
        sx={{ 
          backgroundColor: '#F2F3F5',
          paddingTop: '5vh',
        }}>
        <Stack>
          <TypographyTitle
            sx={{
              '&.MuiTypography-root': {
                fontSize: '1.25rem',
                lineHeight: '2rem'
              }
            }}
          >
            Modelos profissionais
          </TypographyTitle>

          <TypographySubTitle>
            Selecione artes prontas e distribua sem precisar criar ou editar
          </TypographySubTitle>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            data-testid="dsModule-fast-models-hero-component-searchBar"
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                padding: '5px 10px !important'
              }
            }}
            open={openSearchProduct}
            onOpen={handleOpenAutocomplete}
            onClose={handleOpenAutocomplete}
            onInputChange={(event, newValue, reason) => {
              const searchText = newValue || '';
              handleChangeProduct(searchText);
            }}
            onChange={(event, value) => {
              const selectedSubcategoryID = value?.ID;

              if (categorySelected && selectedSubcategoryID) {
                setSubCategorySelected(selectedSubcategoryID);

                if (categorySelected !== value.ID_CATEGORY_FK) {
                  setSubcategoriesData([]);
                  setCategorySelected(value.ID_CATEGORY_FK);
                }
              }
            }}
            disabled={subcategoriesDescription.isFetching}
            loadingText={`Pesquisando imagens em "${product}"...`}
            noOptionsText={
              <TypographySubTitle>
                Sem resultados para "{product}".
                <br />
                <a onClick={handleRefreshSubcategories}>Atualizar lista</a>
              </TypographySubTitle>
            }
            isOptionEqualToValue={(option, value) => option?.ID === value.ID}
            getOptionLabel={option => option.DESCRIPTION || ''}
            options={subcategoriesDescription.data || []}
            loading={subcategoriesDescription.isFetching}
            renderInput={params => (
              <TextFieldCustom
                {...params}
                placeholder="Pesquisar pelo nome do produto"
                color="info"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: !product && (
                    <InputAdornment position="start">
                      <Search sx={{ padding: 0, width: 22, height: 22 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {subcategoriesDescription.isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                    </>
                  )
                }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="96vw" direction="row" justifyContent="center">
          {categories.isFetching ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            <Swiper
              allowTouchMove={false}
              noSwiping
              className={styles.categoriesSlider}
              slidesPerView={8}
              spaceBetween={20}
              loop={false}
              pagination={false}
              navigation
            >
              {categories.data?.map(category => (
                <SwiperSlide
                  style={{ width: '120px' }}
                  className={styles.categoriesSlide}
                  key={category.ID}
                >
                  <Chip
                    color={category.ID === categorySelected ? 'primary' : undefined}
                    variant={category.ID === categorySelected ? 'outlined' : 'filled'}
                    sx={{
                      backgroundColor: category.ID === categorySelected ? 'transparent' : '#FFF',
                      borderRadius: '4px',
                      width: '100%'
                    }}
                    label={category.DESCRIPTION}
                    onClick={() => {
                      handleChangeCategory(category.ID);
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Stack>
        {console.log(subcategories.isLoading, subcategoriesData.length)}
        {categories.isFetching ? (
          ''
        ) : subcategories.isLoading && subcategoriesData.length <= 0 ? (
          <Stack direction="row" pt="2vh" justifyContent="center">
            <CircularProgress color="primary" size={36} />
          </Stack>
        ) : subcategoriesData.length > 0 ? (
          <InfiniteScroll
            initialScrollY={0}
            dataLength={Number(subcategoriesData?.length)}
            scrollThreshold={1}
            style={{ overflow: 'hidden' }}
            next={() => {
              setOffset(prev => prev + 1);
            }}
            hasMore={!(Number(subcategoriesData?.length) < 6 && !subcategories.isFetching)}
            loader={
              <Stack direction="row" pt="2vh" justifyContent="center">
                <CircularProgress color="primary" size={36} />
              </Stack>
            }
          >
            {subcategoriesData?.map(subcategory => (
              <Stack mb="5vh" maxWidth="96vw" key={subcategory.ID} spacing={1}>
                <TypographyCategory marginLeft="2vw">{subcategory.DESCRIPTION}</TypographyCategory>
                <Swiper
                  allowTouchMove={false}
                  noSwiping={true}
                  slidesPerView="auto"
                  spaceBetween={80}
                  loop={false}
                  pagination={false}
                  navigation
                  className={styles.subcategoriesSlider}
                >
                  {subcategory.backgrounds.map(background => (
                    <SwiperSlide
                      key={background.id}
                      className={styles.subcategoriesSlide}
                      data-testid="dsModule-fast-models-hero-component-subcategories-slide"
                    >
                      <div
                        onClick={() => {
                          handleSelectedBackgrounds(
                            subcategory.DESCRIPTION,
                            subcategory.ID,
                            background
                          );
                          handleHoverImage(0);
                        }}
                        onMouseEnter={() => {
                          if (!isBackgroundSelected(subcategory.ID, background.id)) {
                            handleHoverImage(background.id);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!isBackgroundSelected(subcategory.ID, background.id)) {
                            handleHoverImage(0);
                          }
                        }}
                        className={
                          isBackgroundSelected(subcategory.ID, background.id)
                            ? styles.subcategoriesSlideImageContainerSelected
                            : styles.subcategoriesSlideImageContainer
                        }
                        data-background-selector={subcategory.ID}
                        style={{
                          position: 'relative'
                        }}
                      >
                        {isBackgroundSelected(subcategory.ID, background.id) && (
                          <Checkbox sx={{ position: 'absolute', top: 0, left: 0 }} checked />
                        )}
                        <img
                          style={{ display: hoverImage !== background.id ? 'inherit' : 'none' }}
                          className={styles.subcategoriesSlideImage}
                          src={background.thumbnail || background.imageURL}
                          alt={`Subcategory ${subcategory.DESCRIPTION} Image - ${background.name} (${subcategory.ID})`}
                        />

                        <Stack
                          display={hoverImage !== background.id ? 'none' : 'flex'}
                          justifyContent="center"
                          alignItems="center"
                          flex={1}
                        >
                          <TypographySubcategory>Selecione para editar</TypographySubcategory>
                        </Stack>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Stack>
            ))}
          </InfiniteScroll>
        ) : (
          <Stack direction="row" pt="2vh" justifyContent="center">
            <TypographyTitle
              sx={{
                '&.MuiTypography-root': {
                  fontSize: '1.25rem',
                  lineHeight: '2rem'
                }
              }}
            >
              Não encontramos artes profissionais para seu filtro
            </TypographyTitle>
          </Stack>
        )}
      </Stack>
      <Slide direction="up" in={backgroundsSelected.length > 0} mountOnEnter unmountOnExit 
        style={{
          width: '85%', 
          overflow:'hidden',
        }}>
        <Stack
          direction="row"
          position="fixed"
          width="100%"
          bottom={0}
          height="10vh"
          bgcolor="#FFF"
          zIndex={99999}
          overflow={'hidden'}
          p="1vh 6vw"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            {backgroundsSelected.map((background, index) => (
              <Grow key={index} in>
                <div
                  className={styles.backgroundImageFooter}
                  style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${
                      background.backgroundData.thumbnail || background.backgroundData.imageURL
                    })`
                  }}
                >
                  <IconButtonFooter
                    onClick={() => {
                      handleRemoveBackground(background.subcategoryId, background.backgroundData);
                    }}
                    color="primary"
                    size="small"
                  >
                    <CloseRounded fontSize="small" />
                  </IconButtonFooter>
                </div>
              </Grow>
            ))}
          </Stack>
          <Button
            onClick={() => {
              handleChangePage(2);
            }}
            size="small"
            variant="contained"
          >
            Editar selecionadas ({backgroundsSelected.length})
          </Button>
        </Stack>
      </Slide>
    </>
  );
}

export default Professional;

