import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Button,
  CircularProgress,
  Icon,
  Avatar,
} from '@material-ui/core';

import { getLojas } from '../../Cupom/actions/index';
import { getCupomCashback } from '../actions/action';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import {
  DatePicker,
  // Select,
  Panel,
  CountCard,
} from '../../../utils/components';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

function CupomCashback(props) {
  const { classes } = props;
  const [filtroDataInicio, setDataInicio] = useState(
    _myDate.subtractDate(30, 'days')
  );
  const [filtroDataFim, setDataFim] = useState(_myDate.getDate('YYYY-MM-DD'));
  // const [idLoja, setIdLoja] = useState(null);

  useEffect(() => {
    props.getCupomCashback();
    if (props.lojas === null) {
      props.getLojas();
    }
  }, []);

  function onChangePeriodo(inicio, fim) {
    const periodo = {
      dataInicio: _myDate.getDate('YYYY-MM-DD', inicio),
      dataFim: _myDate.getDate('YYYY-MM-DD', fim),
    };
    props.getCupomCashback(periodo);
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Typography className={classes.margin} variant="h3" noWrap>
          Cupom Cashback
        </Typography>
      </div>
      <div className={classes.actions}>
        <section
          style={{
            display: 'flex',
            marginTop: 20,
            marginBottom: 20,
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
            }}
          >
            <Typography className={classes.label} variant="h6" noWrap>
              Filtrar por período
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <DatePicker
                label="De"
                style={{ marginRight: 15 }}
                withKeyboard
                className={classes.dataPicker}
                value={filtroDataInicio}
                format="DD/MM/YYYY"
                disableFuture
                onChange={date => {
                  setDataInicio(date);
                  onChangePeriodo(date, filtroDataFim);
                }}
              />

              <DatePicker
                label="Até"
                withKeyboard
                className={classes.dataPicker}
                value={filtroDataFim}
                format="DD/MM/YYYY"
                onChange={date => {
                  setDataFim(date);
                  onChangePeriodo(filtroDataInicio, date);
                }}
              />
            </div>
          </div>
        </section>

        <Button
          size="large"
          variant="contained"
          style={{ backgroundColor: COLOR_PRIMARY }}
          color="primary"
          component={Link}
          to="/criar-cupom-cashback"
        >
          Criar cupom de cashback
        </Button>
      </div>
      <div
        className={classes.content}
        style={
          props.isLoaderCupomCashback
            ? {
                display: 'flex',
                height: '100%',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }
            : {}
        }
      >
        {props.isLoaderCupomCashback ? (
          <CircularProgress />
        ) : (
          props.cuponsCashback &&
          props.cuponsCashback.map(cupom => (
            <Fragment>
              <Panel
                avatar={
                  <Avatar className={classes.avatar}>
                    <Icon>shopping_cart</Icon>
                  </Avatar>
                }
                title={cupom.nome_cupom_cashback}
                subheader={`${_myDate.getDate(
                  'DD/MM/YYYY',
                  cupom.data_inicio
                )} até ${_myDate.getDate('DD/MM/YYYY', cupom.data_fim)}`}
                style={{ marginBottom: 20 }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginBottom: 20,
                    }}
                  >
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.valor_cupom_cashback}
                      decimals={2}
                      prefix="R$ "
                      title="Valor do cupom"
                      icon="attach_money"
                      style={{ marginRight: 20 }}
                    />
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.total_valor}
                      decimals={2}
                      prefix="R$ "
                      title="Valor em vendas"
                      icon="attach_money"
                      style={{ marginRight: 20 }}
                    />
                    <CountCard
                      count
                      titleTypographyProps={{ variant: 'h6' }}
                      end={cupom.qtde_vendas}
                      title="Quantidade em vendas"
                      icon="shopping_cart"
                      style={{ marginRight: 20 }}
                    />
                  </div>
                </div>
              </Panel>
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1), color: COLOR_PRIMARY },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ CupomReducer, CupomCashbackReducer }) => ({
  ...CupomReducer,
  ...CupomCashbackReducer,
});

const mapDispatchToProps = { getLojas, getCupomCashback };

const CupomCashbackConn = connect(
  mapStateToProps,
  mapDispatchToProps
)(CupomCashback);

export default withStyles(styles)(CupomCashbackConn);
