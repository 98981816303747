import React, { Fragment, useEffect, useState } from 'react';

//Using Components
import {
  Grid,
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { MyButton, TextField, Select } from '../../../../utils/components';

//Using Variables
import {
  COLOR_PRIMARY,
  FORM_FIELDS_PERSON,
  FORM_INPUT_TYPE,
} from '../../../../utils/helpers/Constants';

// Using Styles
import useStyles from '../styles';

export default function(props) {
  const form = props.form,
    classes = useStyles(),
    [fields, setFields] = useState([]);

  useEffect(() => {
    setFieldsByTypeForm();
  }, []);

  function setFieldsByTypeForm() {
    if (Boolean(form) && Boolean(form.typeForm)) {
      switch (String(form.typeForm.value).toUpperCase()) {
        case 'ADD_CLIENTS':
          setFields(FORM_FIELDS_PERSON);
          break;
        default:
          setFields([]);
          break;
      }
    }
  }

  function changePropsFields(index, value, attr) {
    form.fields[index] = { ...form.fields[index], [attr]: value };
    props.onChange('fields', form.fields);
  }

  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Campos do formulário
      </Typography>

      {Boolean(form.fields) &&
        Boolean(form.fields.length) &&
        form.fields.map((field, index) => (
          <Paper
            variant="outlined"
            className={classes.paperField}
            elevation={3}
            key={index}
          >
            <TextField
              defaultValue={field.label}
              label="Pergunta"
              onBlur={({ target: { value } }) =>
                changePropsFields(index, value, 'label')
              }
            />
            <Grid style={{ marginTop: '2vh' }} container spacing={2}>
              <Grid item xs>
                <Box display="flex" flexDirection="column">
                  <Select
                    defaultValue={field.inputType}
                    inputId={`react-select-single-type-${index}`}
                    label="Tipo de entrada"
                    options={FORM_INPUT_TYPE}
                    onChange={type =>
                      changePropsFields(index, type, 'inputType')
                    }
                  />
                  {Boolean(field.inputType) &&
                    field.inputType.value === 'select' &&
                    field.optionSelect.map((option, y) => (
                      <Box
                        key={y}
                        display="flex"
                        alignItems="center"
                        marginBottom=".5rem"
                      >
                        <TextField
                          size="small"
                          defaultValue={option}
                          onBlur={({ target: { value } }) => {
                            field.optionSelect[y] = value;
                            changePropsFields(
                              index,
                              [...field.optionSelect],
                              'optionSelect'
                            );
                          }}
                        />{' '}
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            field.optionSelect.splice(y, 1);
                            changePropsFields(
                              index,
                              [...field.optionSelect],
                              'optionSelect'
                            );
                          }}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </Box>
                    ))}
                  {Boolean(field.inputType) &&
                    field.inputType.value === 'select' && (
                      <MyButton
                        variant="text"
                        onClick={() =>
                          changePropsFields(
                            index,
                            [...field.optionSelect, ''],
                            'optionSelect'
                          )
                        }
                      >
                        <AddCircleOutline />
                        &nbsp; Nova opção
                      </MyButton>
                    )}
                </Box>
              </Grid>
              {Boolean(form) &&
                Boolean(form.typeForm) &&
                form.typeForm.value === 'ADD_CLIENTS' && (
                  <Grid item xs={3}>
                    <Select
                      defaultValue={field.key}
                      inputId={`react-select-single-key-${index}`}
                      label="Chave"
                      options={fields}
                      onChange={key => changePropsFields(index, key, 'key')}
                    />
                  </Grid>
                )}
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="active"
                      checked={field.active}
                      onChange={({ target: { checked } }) =>
                        changePropsFields(index, checked, 'active')
                      }
                    />
                  }
                  label="Ativo"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="required"
                      checked={field.required}
                      onChange={({ target: { checked } }) =>
                        changePropsFields(index, checked, 'required')
                      }
                    />
                  }
                  label="Obrigatório"
                  labelPlacement="top"
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              marginTop="2vh"
              width="100%"
            >
              <MyButton
                onClick={() => {
                  form.fields.splice(index, 1);
                  props.onChange('fields', [...form.fields]);
                }}
                variant="text"
                color="secondary"
              >
                <RemoveCircleOutline /> &nbsp; Remover campo
              </MyButton>
            </Box>
          </Paper>
        ))}
      <MyButton
        variant="text"
        onClick={() =>
          props.onChange('fields', [
            ...form.fields,
            {
              label: '',
              key: '',
              required: false,
              active: true,
              inputType: '',
              optionSelect: [''],
            },
          ])
        }
      >
        <AddCircleOutline />
        &nbsp; Novo campo
      </MyButton>
    </Fragment>
  );
}
