import Fetch from "../../../utils/helpers/Fetch";
import { AMBIENTE, DATASALES_CRM_API } from "../../../utils/helpers/Constants";

export const isLoading = () => dispatch =>
  dispatch({ type: "LOADING_CUPOM_SORTEIO" });

export const postCupomSorteio = cupom => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/postCupomSorteio`,
      cupom,
      "POST"
    );
    dispatch(getCupomSorteio());
  } catch (err) {
    console.log(err);
  }
};

export const getCupomSorteio = cupons => async dispatch => {
  try {
    dispatch(isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getCupomSorteio`,
      cupons
    );
    dispatch({ type: "GET_CUPOM_SORTEIO", payload });
  } catch (err) {
    console.log(err);
  }
};

export const getCupomSorteioById = idCupomSorteio => async dispatch => {
  try {
    dispatch(isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/getCupomSorteio`,
      { idCupomSorteio }
    );
    dispatch({ type: "GET_CUPOM_SORTEIO_PESSOA", payload });
  } catch (err) {
    console.log(err);
  }
};
