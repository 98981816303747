import { styled } from '@material-ui/styles';
import { Avatar, Box, CircularProgress, Icon, Popover, Typography } from '@mui/material';
import validator from 'validator';

import { AMBIENTE } from '../../../../utils/helpers/Constants';

const CardList = styled(Box)({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #D3D3D3',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: 0,
  borderRadius: 4
});

const ListItem = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 10,
  gap: 10,
  width: '100%',

  '&:hover': {
    background: '#F5F5F5'
  }
});

const ListItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  padding: 0,
  gap: 8
});

const ListItemContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 0
});

const Text = styled(Typography)({
  alignItems: 'center',
  letterSpacing: '0.05em'
});

const ContentAlert = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0 10px',
  gap: 10,
  background: '#EA5151',
  borderRadius: '200px'
});

function DistributeMenu({ open, onSubmit, onClose, anchorEl, qtdProducts, loading }) {
  let geracao_2 =
    JSON.parse(localStorage.getItem('permissions'))?.criacao?.findIndex(i => i.serviceId === 64) >=
    0;

  let geracao_1 =
    JSON.parse(localStorage.getItem('permissions'))?.criacao?.findIndex(i => i.serviceId === 65) >=
    0;

  // liberado_estudio_3 = false;

  let padrao = [
    {
      label: 'Estúdio datasales',
      label2: 'Crie artes com a sua lista de produtos',
      value: 'automaticNewEditor',
      icon: 'https://datasalesio-imagens.s3.amazonaws.com/logos/logo-fundoAzul+(1).png'
      // icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-dark.jpg'
    },
    // {
    //   label: 'Modelos prontos',
    //   label2: 'Dispare artes com produtos prontos',
    //   value: 'readyMadeTemplates',
    //   icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-dark.jpg'
    // },
    // {
    //   label: 'Vídeos (Novo)',
    //   label2: "...",
    //   value: 'videos',
    //   icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-dark.jpg',
    // },
    {
      label: 'Manual',
      label2: 'Use o estúdio datasales para criar do zero',
      value: 'manual',
      icon: 'https://datasalesio-imagens.s3.amazonaws.com/logos/logo-fundoAzul+(1).png'
      // icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-light.jpg'
    },
    {
      label: 'Modelos prontos',
      label2: 'Use artes prontas da Datasales',
      value: 'newReadyModels',
      icon: 'https://datasalesio-imagens.s3.amazonaws.com/logos/logo-fundoAzul+(1).png'
    },
    {
      label: 'Canva',
      label2: 'Crie artes no estúdio do Canva',
      value: 'canva',
      icon: 'https://datasalesio-imagens.s3.amazonaws.com/logos/15937108142136881.webp'
      // icon: 'https://datasalesio-imagens.s3.amazonaws.com/canva-icon.png'
    },
    {
      label: 'Importar imagem',
      label2: 'Utilize uma arte do seu computador',
      value: 'upload',
      icon: 'file_upload'
    }
  ];

  let options = {
    dev: [...padrao],
    prd: [...padrao]
  };

  if (geracao_2) {
    options = {
      ...options,
      dev: [...options.dev],
      prd: [...options.prd]
    };
  }

  if (geracao_1) {
    options = {
      ...options,
      dev: [
        // {
        //   label: 'Estúdio Antigo',
        //   label2: 'Será descontinuado em 30 de Abril',
        //   value: 'automatic',
        //   icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-dark.jpg'
        // },
        ...options.dev
      ],
      prd: [
        // {
        //   label: 'Estúdio Antigo',
        //   label2: 'Será descontinuado em 30 de Abril',
        //   value: 'automatic',
        //   icon: 'https://datasalesio-imagens.s3.amazonaws.com/datasales-icon-dark.jpg'
        // },
        ...options.prd
      ]
    };
  }

  return (
    <Popover
      title="menu criar nova arte"
      id="simple-pop"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top', //
        horizontal: 'left'
      }}
    >
      <CardList>
        {loading ? (
          <CircularProgress variant="indeterminate" />
        ) : (
          options[AMBIENTE].map((option, index) => (
            <ListItem
              id={`create-flyer-${option.label}`}
              title={option.label}
              key={index}
              fullWidth
              disabled={option.value === 'automaticNewEditor' && qtdProducts == 0 ? true : false}
              onClick={() => {
                onSubmit(option.value, true);
              }}
            >
              <ListItemContainer>
                {validator.isURL(option.icon) ? (
                  <Avatar variant="circle" src={option.icon} />
                ) : (
                  <Icon
                    color="primary"
                    sx={option.icon === 'local_offer' ? { transform: 'rotate(135deg)' } : undefined}
                  >
                    {option.icon}
                  </Icon>
                )}
                <ListItemContent>
                  <Text fontSize={14} fontWeight={500} lineHeight="22px" color="#000000">
                    {option.label}
                  </Text>
                  <Text fontSize={14} fontWeight={500} lineHeight="22px" color="#616161">
                    {option.label2}
                  </Text>
                  {option.value === 'automaticNewEditor' && qtdProducts == 0 && (
                    <ContentAlert>
                      <Text fontSize={12} fontWeight={500} lineHeight="22px" color="#FFF">
                        Adicione produtos para continuar
                      </Text>
                    </ContentAlert>
                  )}
                </ListItemContent>
              </ListItemContainer>
            </ListItem>
          ))
        )}
      </CardList>
    </Popover>
  );
}

export default DistributeMenu;
