/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  MyButton,
  DatePicker,
  Select,
  TextField,
  Modal,
} from '../../../utils/components';
import { postOfertaCashback } from '../actions/';
import { listStore } from '../../Lojas/actions/action';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate();

class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      nomeCashback: '',
      lojas: null,
      dataInicio: _myDate.getDate(
        'YYYY-MM-DD HH:mm:ss',
        moment().add(3, 'days')
      ),
      dataFim: _myDate.getDate('YYYY-MM-DD HH:mm:ss', moment().add(30, 'days')),
    };
  }

  componentDidMount() {
    this.props.listStore();
  }

  onSave() {
    const {
      nomeCashback,
      lojas,
      dias_inicio,
      dias_periodo,
      dataInicio,
      dataFim,
    } = this.state;
    const objCashback = {
      nome_cashback: nomeCashback,
      lojas: lojas.length > 0 ? lojas.map(loja => loja.value) : null,
      dias_inicio,
      dias_periodo,
      comeca_valer: dataInicio,
      termina_valer: dataFim,
    };
    this.props.postOfertaCashback(objCashback);
    this.props.onClose();
  }

  header(props, state) {
    const steps = ['Informações do Cashback', 'Iniciar'];

    return (
      <Fragment>
        Cashback moda
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return 'Próximo';
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return 'Próximo';
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1,
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 1 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1,
              })
            }
            disabled={
              !state.nomeCashback ? true : state.activeStep === 1 ? true : false
            }
          >
            {this.renderText()}
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { nomeCashback, dataInicio, dataFim, media, lojas } = state;
    return (
      <section style={{ padding: '5rem 20rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Informações do cashback
        </Typography>
        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          value={nomeCashback}
          onChange={event =>
            this.setState({ nomeCashback: event.target.value })
          }
        />
        <Select
          options={props.listStores.map(loja => ({
            value: loja.id_loja,
            label: loja.nome_loja,
          }))}
          multiple
          label="Lojas participantes"
          onChange={value => this.setState({ lojas: value })}
          value={lojas}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <section>
            <DatePicker
              dateAndTime
              label="Começa a valer em"
              onChange={date =>
                this.setState({
                  dataInicio: _myDate.getDate('YYYY-MM-DD HH:mm:ss', date),
                })
              }
              value={dataInicio}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              disablePast
              label="Termina de valer em"
              onChange={date =>
                this.setState({
                  dataFim: _myDate.getDate('YYYY-MM-DD HH:mm:ss', date),
                })
              }
              value={dataFim}
            />
          </section>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <section>
            <TextField
              label="Dias para creditar o cashback"
              className="form-control"
              autoComplete="none"
              type="number"
              onChange={({ target: { value } }) =>
                this.setState({ dias_inicio: value })
              }
            />
          </section>
          <section>
            <TextField
              label="Dias de validade do cashback"
              className="form-control"
              autoComplete="none"
              type="number"
              onChange={({ target: { value } }) =>
                this.setState({ dias_periodo: value })
              }
            />
          </section>
        </div>
      </section>
    );
  }

  step2(props, state) {
    const {
      nomeCashback,
      dataInicio,
      dataFim,
      dias_periodo,
      dias_inicio,
    } = state;
    return (
      <section>
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Sobre o cashback
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome do cashback</strong>
              </p>
              <span>{nomeCashback}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Começa valer em</strong>
              </p>
              <span>{_myDate.getDate('DD/MM/YYYY HH:mm', dataInicio)}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Termina de valer em</strong>
              </p>
              <span>{_myDate.getDate('DD/MM/YYYY HH:mm', dataFim)}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Dias para creditar o cashback</strong>
              </p>
              <span>{dias_inicio}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Dias de validade do cashback</strong>
              </p>
              <span>{dias_periodo}</span>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}
        >
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton onClick={() => this.onSave()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? 'xl' : 'lg'}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({
  OfertasReducer,
  CampaignReducer,
  InsightsReducer,
  LojasReducer,
}) => ({
  ...OfertasReducer,
  ...CampaignReducer,
  ...InsightsReducer,
  ...LojasReducer,
});
const mapDispatchToProps = {
  listStore,
  postOfertaCashback,
};

const NovaOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
