import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, ImageList } from '@mui/material';
import { styled } from '@mui/styles';
import { useDefaultStyles } from 'modules/TabloideDigitalV2/style';
import { useLayoutEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BackgroundsWithFavorites } from '../../types';
import { NotFoundBackgrounds } from '../../../NotFoundBackgrounds';

const Div = styled('div')(() => ({}));
const Img = styled('img')(() => ({}));

export function Backgrounds({
  setCurrentTab,
  indexTab,
  backgrounds,
  setBackgroundSelected,
  funFavoriteBackground,
  handleClose,
  handleNextPage,
  handleMore,
  loadingBackgrounds
}: {
  setCurrentTab: () => void;
  indexTab: number;
  backgrounds: BackgroundsWithFavorites;
  setBackgroundSelected: (param: any) => void;
  funFavoriteBackground: (param: any) => void;
  handleClose: () => void;
  handleNextPage: () => void;
  handleMore: boolean;
  loadingBackgrounds?: boolean;
}) {
  const magnumClasses = useDefaultStyles();

  function handleNext() {
    handleNextPage();
  }

  useLayoutEffect(() => {
    setCurrentTab();
  }, []);

  return (
    <Box
      id={'referenceScrollable'}
      component={'div'}
      sx={{
        position: 'relative',
        overflow: 'auto',
        maxHeight: '75vh',
        top: '-28px'
      }}
    > 
      { backgrounds.backgrounds.length === 0 && !loadingBackgrounds ? 
        <NotFoundBackgrounds/> :
        <InfiniteScroll
          scrollableTarget={'referenceScrollable'}
          hasMore={handleMore}
          dataLength={backgrounds.backgrounds.length}
          next={() => {
            handleNext();
          }}
          loader={undefined}
        >
          {Boolean(backgrounds.backgroundsFavorites.length) && (
            <>
              <Div
                style={{
                  display: 'flex',
                  marginBottom: '16px',
                  marginTop: '16px'
                }}
              >
                <StarIcon
                  style={{
                    color: '#FADB14'
                  }}
                />
                <Div
                  className={magnumClasses.titleFavoriteBackgrounds}
                  style={{
                    marginLeft: '16px'
                  }}
                >
                  Favoritos
                </Div>
              </Div>
              <ImageList
                rowHeight={'auto'}
                gap={3}
                sx={{
                  padding: theme => theme.spacing(0, '5%', 0),
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  top: '16px'
                }}
              >
                {backgrounds.backgroundsFavorites.map((item, index) => (
                  <div key={item.id} className={magnumClasses.cardTabloids}>
                    <Div
                      key={index}
                      style={{
                        marginBottom: 8,
                        width: 180,
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer'
                      }}
                      title={item.name + ' - id: ' + item.id}
                    >
                      <div className={magnumClasses.gridMediaQueryHeight}>
                        <div className={magnumClasses.cardOptions}>
                          <div
                            className={magnumClasses.redirectEditor}
                            onClick={() => {
                              setBackgroundSelected(item);
                              handleClose();
                            }}
                          ></div>
                          <Div className={magnumClasses.favoriteDiv}>
                            <StarIcon
                              className={magnumClasses.favoriteIcon2}
                              onClick={() => {
                                funFavoriteBackground(item);
                              }}
                            />
                          </Div>
                        </div>
                        <Box
                          sx={{
                            'max-height': '250px',
                            'max-width': '100%',
                            width: 180,
                            margin: 'auto',
                            borderRadius: '8px',
                            padding: '12px 8px'
                          }}
                        >
                          <Img
                            className={magnumClasses.gridImageEstudio2}
                            crossOrigin='anonymous'
                            src={item.thumbnail}
                            alt={item.name + ' - id: ' + item.id}
                            title={item.name + ' - id: ' + item.id}
                            loading="lazy"
                          />
                        </Box>
                      </div>
                    </Div>
                  </div>
                ))}
              </ImageList>
            </>
          )}

          {Boolean(backgrounds.backgroundsFavorites.length) &&
            Boolean(backgrounds.backgrounds.length) && (
              <Div
                style={{
                  display: 'flex',
                  marginBottom: '16px'
                }}
              >
                <Div className={magnumClasses.titleFavoriteBackgrounds}>
                  {indexTab === 0 ? <p>Geral</p> : <p>Meus Fundos</p>}
                </Div>
              </Div>
            )}

          <ImageList
            rowHeight={'auto'}
            gap={3}
            sx={{
              padding: theme => theme.spacing(0, '5%', 0),
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {backgrounds.backgrounds.map((item, index) => (
              <div key={item.id} className={magnumClasses.cardTabloids}>
                <Div
                  key={index}
                  style={{
                    marginBottom: 8,
                    width: 180,
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer'
                  }}
                  title={item.name + ' - id: ' + item.id}
                >
                  <div className={magnumClasses.gridMediaQueryHeight}>
                    <div className={magnumClasses.cardOptions}>
                      <div
                        className={magnumClasses.redirectEditor}
                        onClick={() => {
                          setBackgroundSelected(item);
                          handleClose();
                        }}
                      ></div>
                      <Div className={magnumClasses.favoriteDiv}>
                        {Boolean(item.favorite) ? (
                          <StarIcon
                            className={magnumClasses.favoriteIcon2}
                            onClick={() => {
                              funFavoriteBackground(item);
                            }}
                          />
                        ) : (
                          <StarBorderIcon
                            className={magnumClasses.favoriteIcon}
                            onClick={() => {
                              funFavoriteBackground(item);
                            }}
                          />
                        )}
                      </Div>
                    </div>
                    <Box
                      sx={{
                        'max-height': '250px',
                        'max-width': '100%',
                        width: 180,
                        margin: 'auto',
                        borderRadius: '8px',
                        padding: '12px 8px'
                      }}
                    >
                      <Img
                        className={magnumClasses.gridImageEstudio2}
                        crossOrigin='anonymous'
                        src={item.thumbnail}
                        alt={item.name + ' - id: ' + item.id}
                        title={item.name + ' - id: ' + item.id}
                        loading="lazy"
                      />
                    </Box>
                  </div>
                </Div>
              </div>
            ))}
          </ImageList>
        </InfiniteScroll>
      }
    </Box>
  );
}
