const INITIAL_STATE = {
  whiteList: { familias: [], produtos: [] },
  recomendacoes: { gerais: [], personalizadas: [] },
  recomendacaoDetalhes: [],
  familias: [],
  produtos: [],
  xlsx: "",
  personalized_xlsx:"",
  loading_recomendacoes: false,
  loading_detalhes: false,
  loading_xlsx: false,
  loading_familias: false,
  loading_produtos: false,
  loading_whiteList: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_SOLICITACOES":
      return {
        ...state,
        recomendacoes: action.payload,
        loading_recomendacoes: false
      };
    case "GET_DETALHES":
      return {
        ...state,
        recomendacaoDetalhes: action.payload,
        loading_detalhes: false
      };
    case "GET_WHITELIST":
      return {
        ...state,
        whiteList: action.payload,
        loading_whiteList: false
      };
    case "GET_XLSX":
      return {
        ...state,
        xlsx: action.payload,
        loading_xlsx: false
      };

      case "GET_PERSONALIZED_XLSX":
      return {
        ...state,
        personalized_xlsx: action.payload,
        loading_xlsx: false
      };

    case "GET_PDF":
      return {
        ...state,
        xlsx: action.payload,
        loading_xlsx: false
      };

    case "GET_FAMILIAS":
      return {
        ...state,
        familias: action.payload,
        loading_familias: false
      };
    case "GET_PRODUTOS":
      return {
        ...state,
        produtos: action.payload,
        loading_produtos: false
      };

    case "LOAD_SOLICITACOES":
      return { ...state, loading_recomendacoes: true };
    case "LOAD_DETALHES":
      return { ...state, loading_detalhes: true };
    case "LOAD_XLSX":
      return { ...state, loading_xlsx: true };
    case "LOAD_FAMILIAS":
      return { ...state, loading_familias: true };
    case "LOAD_PRODUTOS":
      return { ...state, loading_produtos: true };
    case "LOAD_WHITELIST":
      return { ...state, loading_whiteList: true };

    default:
      return state;
  }
};
