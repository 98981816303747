import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  lazyComponents: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}));

