import React, { Fragment, Component } from "react";
import ShowMore from "@tedconf/react-show-more";
import MyButton from "../Button/Button";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import "./ShowMore.css";

export default class MyShowMore extends Component {
  constructor() {
    super();
    this.state = { dataList: [], dataListFiltro: [], ordenaLista: false };
  }
  componentDidMount() {
    this.setState({ dataList: this.props.data });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({ dataList: this.props.data });
    } else if (this.props.header) {
      let updateList = false;
      this.props.data.forEach((data, indexD) => {
        updateList = this.props.header.some(
          header =>
            data[header.name] &&
            data[header.name] !== prevProps.data[indexD][header.name]
        );
      });
      if (updateList) {
        this.setState({ dataList: this.props.data });
      }
    }
  }

  _filtraLista(lista, work, columnFilter) {
    const dataListFiltro = lista.filter(data =>
      new RegExp(
        work.toUpperCase().replace(/[[\].!'@,><|:\\;&*()_+=]/g, "")
      ).test(String(data[columnFilter]).toUpperCase())
    );
    this.setState({ dataListFiltro });
  }
  _ordenaListaCrescente(lista, prop) {
    const novaLista = lista.sort((a, b) => {
      if (a[prop] > b[prop]) return 1;
      if (a[prop] < b[prop]) return -1;
      return 0;
    });
    this.setState({
      [this.state.dataListFiltro.length > 0
        ? "dataListFiltro"
        : "dataList"]: novaLista
    });
  }
  _ordenaListaDecrescente(lista, prop) {
    const novaLista = lista.sort((a, b) => {
      if (a[prop] < b[prop]) return 1;
      if (a[prop] > b[prop]) return -1;
      return 0;
    });
    this.setState({
      [this.state.dataListFiltro.length > 0
        ? "dataListFiltro"
        : "dataList"]: novaLista
    });
  }
  render() {
    const { dataList, ordenaLista, dataListFiltro } = this.state;
    const {
      limitPage,
      dataFormat,
      header,
      headerStyle,
      loading,
      noButton,
      filter,
      columnFilter,
      placeholderFilter,
      noHeader
    } = this.props;
    return loading ? (
      <div className="loader-example">
        <div className="loader-bar" />
      </div>
    ) : (
      <Fragment>
        {filter && (
          <section className="inputFiltro">
            <input
              onChange={({ target: { value } }) =>
                this._filtraLista(dataList, value, columnFilter)
              }
              className="form-control"
              style={{ width: "30%" }}
              placeholder={
                placeholderFilter || `Pesquisar por ${columnFilter}...`
              }
            />
          </section>
        )}
        <ShowMore
          by={limitPage || 10}
          items={dataListFiltro.length > 0 ? dataListFiltro : dataList}
        >
          {({ current, onMore }) => (
            <section style={{ display: "grid" }}>
              {current.length > 0 ? (
                <Fragment>
                  <ListGroup>
                    {header && !noHeader && (
                      <ListGroupItem
                        style={{ ...headerStyle, background: "whitesmoke" }}
                      >
                        {header.map((header, key) => (
                          <strong key={key} style={{ textAlign: header.align }}>
                            {header.title}
                            {header.name && (
                              <button
                                className="botaoOrdena"
                                onClick={() =>
                                  this.setState(
                                    { ordenaLista: !ordenaLista },
                                    () =>
                                      ordenaLista
                                        ? this._ordenaListaDecrescente(
                                            dataListFiltro.length > 0
                                              ? dataListFiltro
                                              : dataList,
                                            header.name
                                          )
                                        : this._ordenaListaCrescente(
                                            dataListFiltro.length > 0
                                              ? dataListFiltro
                                              : dataList,
                                            header.name
                                          )
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faSort} />
                              </button>
                            )}
                          </strong>
                        ))}
                      </ListGroupItem>
                    )}

                    {current.map((person, key) => (
                      <Fragment key={key}>
                        {dataFormat(person, dataList)}
                      </Fragment>
                    ))}
                  </ListGroup>

                  {!noButton && (
                    <MyButton
                      bsstyle="primary"
                      disabled={!onMore}
                      bsSize="xs"
                      onClick={() => {
                        if (!!onMore) onMore();
                      }}
                    >
                      <span style={{ fontSize: "1.2em" }}>
                        <FontAwesomeIcon icon={faSearchPlus} /> &nbsp; Ver Mais
                      </span>
                    </MyButton>
                  )}
                </Fragment>
              ) : (
                <section className="semResultado">
                  Nenhum resultado encontrado
                </section>
              )}
            </section>
          )}
        </ShowMore>
      </Fragment>
    );
  }
}
