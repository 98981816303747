import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelectMenu } from 'utils/helpers/Redirect';
/* import DialogWpp from './DialogWpp'; */
import { Swipe } from '../Carrosel/swipe';
import { SwipeTwo } from '../Carrosel/swipe2'
import DialogSelectVersion from './DialigSelectVersion';

import ImageStudioChat from '../../../assets/images/newBanner.svg';
import formatCanvaBilhete from '../../../assets/newStudio/formatCanvaBilhete.png';
import formatCanvaCardapio from '../../../assets/newStudio/formatCanvaCardapio.png';
import formatCanvaCartaoReceitas from '../../../assets/newStudio/formatCanvaCartaoReceitas.png';
import formatCanvaCartaoVisitas from '../../../assets/newStudio/formatCanvaCartaoVisitas.png';
import formatCanvaLogotipo from '../../../assets/newStudio/formatCanvaLogotipo.png';
import formatCartazPaisagem from '../../../assets/newStudio/formatCartazPaisagem.png';
import formatCartazRetrato from '../../../assets/newStudio/formatCartazRetrato.png';
import formatEncarte from '../../../assets/newStudio/formatEncarte.png';
import formatEtiquetas from '../../../assets/newStudio/formatEtiquetas.png';
import formatImportar from '../../../assets/newStudio/formatImportar.png';
import formatModelosProntos from '../../../assets/newStudio/formatModelosProntos.png';
import formatPersonalizado from '../../../assets/newStudio/formatPersonalizado.png';
import formatPost from '../../../assets/newStudio/formatPost.png';
import formatPostVideo from '../../../assets/newStudio/formatPostVideo.png';
import formatStory from '../../../assets/newStudio/formatStory.png';
import formatStoryVideo from '../../../assets/newStudio/formatStoryVideo.png';
import formatTvIndoor from '../../../assets/newStudio/formatTvIndoor.png';
import iconCanva from '../../../assets/newStudio/iconLogoCanva.png';
import iconLogoDatasales from '../../../assets/newStudio/iconLogoDatasales.png';
import {
  BoxFormat,
  DivTitle,
  DivVideo,
  Img,
  TypographyMenuMoreFormats,
  TypographySubTitle,
  TypographyTitle,
  TypographyVideo,
  VideoLabel
} from './styles';

type FormartType = {
  companyId: number;
  createdAt: string;
  height: number;
  id: number;
  name: string;
  updatedAt: string;
  width: number;
};

type FormartSelectedType = {
  createFlyerIsOpen: {
    open: boolean;
    type: string;
  };
};

export const NewStudio = () => {
  const { selectMenu, selectSettings } = useSelectMenu();
  const [myFormats, setMyFormats] = useState<Array<FormartType>>([]);
  const [anchorElMoreFormats, setAnchorElMoreFormats] = useState<null | HTMLElement>(null);
  const [formatselectedState, setFormatSelectedState] = useState<null | FormartSelectedType>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const openMoreFormats = Boolean(anchorElMoreFormats);
  const MY_COMPANY_ID = 396;

  const handleClickMoreFormats = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMoreFormats(event.currentTarget);
  };
  const handleCloseMoreFormats = () => {
    setAnchorElMoreFormats(null);
  };

  useEffect(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const selectFormat = ({ formatString }: { formatString: string }) => {
    setFormatSelectedState({ createFlyerIsOpen: { open: true, type: formatString } });

    selectMenu(
      'criacao',
      'templates,propaganda_de_ofertas',
      `/estudio?createNewTabloid=true&createArtFormat=${formatString}`,
      'Estúdio'
    );
  };

  const redirectCanva = ({ formatCanva }: { formatCanva: string }) => {
    selectMenu(
      'criacao',
      'templates,propaganda_de_ofertas',
      `/estudio?createNewTabloid=true&createFormatCanva=${formatCanva}`,
      'Estúdio'
    );
  };

  const handleRedirectToYouTube = () => {
    window.open('https://www.youtube.com/watch?v=bDIfQ6qv7Bo');
  };

  return (
    <>

      <Box px="2vw" pb="5vh" sx={{ backgroundColor: '#F2F3F5', paddingTop: '5vh' }}>
      {openDialog && (
        <DialogSelectVersion
          onClose={handleCloseDialog}
          open={openDialog}
        />
      )}
        <Box sx={{ alignItem: 'center'}}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DivTitle>
              <TypographyTitle
                sx={{
                  '&.MuiTypography-root': {
                    fontSize: '1.25rem',
                    lineHeight: '2rem'
                  }
                }}
              >
                Estúdio de criação Datasales
              </TypographyTitle>
              <Img
                src={iconLogoDatasales}
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '8px'
                }}
              />
            </DivTitle>
            <TypographySubTitle marginBottom={'0.5rem'}>
              Crie artes de maneira prática com o estudio da Datasales
            </TypographySubTitle>
          </Box>

          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              /* justifyContent: "center",
              alignItems: "center", */
               // Isso garante que o contêiner ocupe a altura total da página
            }}
          >
            <Swipe />
            {/* <SwipeTwo /> */}
          </Box>


          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <BoxFormat onClick={() => selectFormat({ formatString: 'Post' })}>
              <Img src={formatPost} />
              <TypographyTitle>Post</TypographyTitle>
              <TypographySubTitle>Formato quadrado, ideal para Redes Sociais</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Post-video' })}>
              <Img src={formatPostVideo} />
              <DivVideo>
                <TypographyTitle>Post</TypographyTitle>
                <VideoLabel>
                  <TypographyVideo>Vídeo</TypographyVideo>
                </VideoLabel>
              </DivVideo>
              <TypographySubTitle>Vídeo em formato em quadrado</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Story' })}>
              <Img src={formatStory} />
              <TypographyTitle>Story</TypographyTitle>
              <TypographySubTitle>Formato ideal para stories e status</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Story-video' })}>
              <Img src={formatStoryVideo} />
              <DivVideo>
                <TypographyTitle>Story</TypographyTitle>
                <VideoLabel>
                  <TypographyVideo>Vídeo</TypographyVideo>
                </VideoLabel>
              </DivVideo>
              <TypographySubTitle>Vídeo no formato para stories e status</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Encarte' })}>
              <Img src={formatEncarte} />
              <TypographyTitle>Encarte</TypographyTitle>
              <TypographySubTitle>Ideal para criar artes com até 30 produtos</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Cartaz-a4-retrato' })}>
              <Img src={formatCartazRetrato} />
              <TypographyTitle>Cartaz retrato</TypographyTitle>
              <TypographySubTitle>Encartes retrato, ideal para impressão</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Cartaz-a4-paisagem' })}>
              <Img src={formatCartazPaisagem} />
              <TypographyTitle>Cartaz paisagem</TypographyTitle>
              <TypographySubTitle>Encarte deitado, ideal para impressão</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Tv-indoor' })}>
              <Img src={formatTvIndoor} />
              <TypographyTitle>Tv (indoor)</TypographyTitle>
              <TypographySubTitle>Faça ofertas nas TVs do seu estabelecimento</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Etiqueta' })}>
              <Img src={formatEtiquetas} />
              <TypographyTitle>Etiqueta</TypographyTitle>
              <TypographySubTitle>Ideal para impressão A4</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Modelo Pronto' })}>
              <Img src={formatModelosProntos} />
              <TypographyTitle>Modelos Prontos</TypographyTitle>
              <TypographySubTitle>Adicione seus produtos a imagens prontas</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'upload' })}>
              <Img src={formatImportar} />
              <TypographyTitle>Importar</TypographyTitle>
              <TypographySubTitle>Faça upload de uma imagem</TypographySubTitle>
            </BoxFormat>

            <BoxFormat onClick={() => selectFormat({ formatString: 'Manual' })}>
              <Img src={formatPersonalizado} />
              <TypographyTitle>Personalizado</TypographyTitle>
              <TypographySubTitle>Crie um modelo em branco</TypographySubTitle>
            </BoxFormat>
          </Box>

          <Box>
            {parseInt(localStorage.getItem('companyId') || '') === 2996 && (
              <BoxFormat>
                <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/tv-indoor.png" />
                <TypographyTitle>Banner 9x3m</TypographyTitle>
                <TypographySubTitle>Formato de banner personalizado</TypographySubTitle>
              </BoxFormat>
            )}

            {parseInt(localStorage.getItem('companyId') || '') === 1115 && (
              <BoxFormat>
                <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
                <TypographyTitle>Encarte - PET CAMP</TypographyTitle>
                <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
              </BoxFormat>
            )}

            {parseInt(localStorage.getItem('companyId') || '') === 1115 && (
              <BoxFormat>
                <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
                <TypographyTitle>Encarte Aberto - PET CAMP</TypographyTitle>
                <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
              </BoxFormat>
            )}

            {parseInt(localStorage.getItem('companyId') || '') === 37814 && (
              <BoxFormat onClick={() => selectFormat({ formatString: 'Encarte-personalizado-Ibraim' })}>
                <Img src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/format-page/encarte.png" />
                <TypographyTitle>Encarte personalizado</TypographyTitle>
                <TypographySubTitle>Formato de encarte personalizado</TypographySubTitle>
              </BoxFormat>
            )}

          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: '#FFFFFF',
          padding: '5vh 2vw'
        }}
      >
        <DivTitle>
          <TypographyTitle
            sx={{
              '&.MuiTypography-root': {
                fontSize: '1.25rem',
                lineHeight: '2rem'
              }
            }}
          >
            Estúdio Canva
          </TypographyTitle>

          <Img
            src={iconCanva}
            style={{
              width: '24px',
              height: '24px',
              marginLeft: '8px'
            }}
          />
        </DivTitle>

        <TypographySubTitle marginBottom={'2rem'}>
          Use o editor do Canva para criar suas artes
        </TypographySubTitle>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <BoxFormat>
            <Img src={formatCanvaLogotipo} onClick={() => redirectCanva({ formatCanva: 'Logo' })} />
            <TypographyTitle>Logotipo</TypographyTitle>
            <TypographySubTitle>Crie o logo da sua loja</TypographySubTitle>
          </BoxFormat>

          <BoxFormat>
            <Img src={formatCanvaCardapio} onClick={() => redirectCanva({ formatCanva: 'Menu' })} />
            <TypographyTitle>Cardápio</TypographyTitle>
            <TypographySubTitle>Estilize o cardápio do seu estabelecimento</TypographySubTitle>
          </BoxFormat>

          <BoxFormat>
            <Img
              src={formatCanvaCartaoReceitas}
              onClick={() => redirectCanva({ formatCanva: 'RecipeCard' })}
            />
            <TypographyTitle>Cartão de receitas</TypographyTitle>
            <TypographySubTitle>Compartilhe receitas com seus clientes</TypographySubTitle>
          </BoxFormat>

          <BoxFormat>
            <Img
              src={formatCanvaCartaoVisitas}
              onClick={() => redirectCanva({ formatCanva: 'BusinessCard' })}
            />
            <TypographyTitle>Cartão de visitas</TypographyTitle>
            <TypographySubTitle>Faça seu cliente lembrar de você</TypographySubTitle>
          </BoxFormat>

          <BoxFormat>
            <Img
              src={formatCanvaBilhete}
              onClick={() => redirectCanva({ formatCanva: 'TumblrGraphic' })}
            />
            <TypographyTitle>Bilhete</TypographyTitle>
            <TypographySubTitle>Dê dicas e avisos aos seus clientes</TypographySubTitle>
          </BoxFormat>

          {Boolean(myFormats.length) && (
            <BoxFormat>
              <IconButton
                onClick={handleClickMoreFormats}
                aria-controls={openMoreFormats ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMoreFormats ? 'true' : undefined}
                sx={{
                  height: '180px',
                  width: '180px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#F2F3F5'
                }}
              >
                <Img
                  style={{
                    width: '3.5rem',
                    height: '3.5rem'
                  }}
                  src="https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/icons/iconMaisFormatos.png"
                />
              </IconButton>
              <Menu
                anchorEl={anchorElMoreFormats}
                id={'more-formats'}
                open={openMoreFormats}
                onClose={handleCloseMoreFormats}
                onClick={handleCloseMoreFormats}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                PaperProps={{
                  sx: {
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    width: '210px'
                  }
                }}
              >
                {myFormats.map(format => (
                  <MenuItem
                    style={{
                      height: '2.5rem'
                    }}
                  >
                    <TypographyMenuMoreFormats>{format.name}</TypographyMenuMoreFormats>
                  </MenuItem>
                ))}
              </Menu>
              <TypographyTitle>Todos os formatos</TypographyTitle>
              <TypographySubTitle>Escolha o formato que mais te atende</TypographySubTitle>
            </BoxFormat>
          )}
        </Box>
      </Box>
    </>
  );
};

