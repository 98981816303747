import React, { useState, useRef, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  InputAdornment,
  Popover,
  Grid,
  Box,
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  IconButton,
  ButtonGroup,
  LinearProgress,
  Paper,
  Fab,
  Zoom,
} from '@material-ui/core';
import PickerEmoji from 'emoji-picker-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  Delete,
  SentimentSatisfiedRounded,
} from '@material-ui/icons';
import {
  MyButton,
  DatePicker,
  Select,
  Countup,
  TextField,
  Modal,
  Dropzone,
} from '../../../utils/components';
import {
  ONE_MB_SIZE_IN_BYTES,
  COLOR_PRIMARY,
  BG_PRIMARY,
  TIPO_ENVIO_CAMPANHA,
  HOST_TRACKER,
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../utils/helpers/Constants';
import { toBase64, asyncForEach } from '../../../utils/helpers/Functions';
import {
  contadorWhatsapp,
  addNumeroCampanha,
  removeNumeroCampanha,
} from '../../Insights/actions/actions';
import api from '../../../utils/helpers/Fetch';

const steps = [
    'Tipo da campanha',
    'Informações do envio',
    'Rastreio',
    'Mensagem',
    'Mídia',
    'Disparar',
  ],
  regex = /\d{2}\d{4,5}\d{4}/g;

function NovaCampanhaWhatsapp({
  mensagem,
  isLoadingCountPhone,
  contador_campanha,
  listsProspectei,
  tipo_envio_campanha,
  addNumeroCampanha,
  removeNumeroCampanha,
  contadorWhatsapp,
  onClose,
  onChange,
  onSave,
  instances,
  tabInstances,
}) {
  const inputRef = useRef(),
    [selectionText, setSelectionText] = useState({
      selectionStart: 0,
      selectionEnd: 0,
    }),
    [activeStep, setActiveStep] = useState(0),
    [anchorEmoji, setAnchorEmoji] = useState(false),
    [instanceConnected, setInstanceConnected] = useState(false),
    [showNumeros, setShowNumeros] = useState(false),
    [showButtonDelete, setShowButtonDelete] = useState(false),
    [phoneTest, setPhoneTest] = useState(''),
    [midiaMessage, setMidiaMessage] = useState([]);

  useEffect(() => {
    if (mensagem) {
      const request = {
        celulares: mensagem.selectedCelular.map(opt => opt.value),
        key: mensagem.key,
        pra_quem: mensagem.pra_quem,
        origem_campanha: mensagem.origem_campanha,
      };
      if (mensagem.optionList) {
        contadorWhatsapp({ ...request, value: mensagem.optionList.value });
      } else {
        setInstance(mensagem.instance);
        contadorWhatsapp({ ...request, value: mensagem.instance.id });
      }
    }
  }, []);

  useEffect(() => {
    if (Boolean(mensagem.files.length)) {
      async function getBase64Files() {
        const newFiles = [];
        await asyncForEach(mensagem.files, async file => {
          const base64 = await toBase64(file),
            newFile = {
              file: base64.base64,
              width: base64.width,
              height: base64.height,
            };

          newFiles.push(newFile);
        });
        setMidiaMessage(newFiles);
      }

      getBase64Files();
    }
  }, [mensagem]);

  async function setInstance(instance) {
    onChange('instance', instance);
    if (instance) {
      const isConnected = await api(
        `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/zapi/is-connected`,
        {
          client: instance.id,
        }
      );
      setInstanceConnected(isConnected.content.connected);
    }
  }

  function updateSelectionText() {
    const newPosition = {
      selectionStart: inputRef.current.selectionStart,
      selectionEnd: inputRef.current.selectionEnd,
    };
    setSelectionText(newPosition);
  }

  function _disableButtonSave() {
    if (
      (mensagem.message &&
        instanceConnected &&
        (mensagem.optionList ||
          mensagem.instance ||
          mensagem.idListProspectei)) ||
      mensagem.uploadingWhatsApp
    ) {
      return false;
    } else {
      return true;
    }
  }

  function adicionarNumero(numero) {
    if (numero) {
      mensagem.numerosList.push({
        value: numero,
        label: numero,
      });
      addNumeroCampanha('contador_celular');
      setShowNumeros(true);
      setPhoneTest('');
    }
  }

  function removerNumero(index) {
    mensagem.numerosList.splice(index, 1);
    removeNumeroCampanha('contador_celular');
    setShowNumeros(true);
  }

  function onPasteAdd(value) {
    setPhoneTest(value);
    let isPhone = false;
    if (/,/g.test(value)) {
      value.split(',').forEach(numero => {
        isPhone = regex.test(numero);
        if (Boolean(isPhone)) {
          adicionarNumero(numero);
        }
      });
    }
  }

  function onAdd(key, value) {
    setPhoneTest(value);
    if (key === 13) {
      const isPhone = regex.test(value);
      if (Boolean(isPhone)) {
        adicionarNumero(value);
      }
    }
  }

  function findTypeFormat(type) {
    switch (String(type).toUpperCase()) {
      case 'BOLD':
        return '*';
      case 'ITALIC':
        return '_';
      case 'STRIKE':
        return '~';
      default:
        return '';
    }
  }

  function onEmojiText(emoji) {
    if (Boolean(mensagem.message)) {
      const newText = `${mensagem.message.slice(
        0,
        selectionText.selectionStart
      )}${emoji}${mensagem.message.slice(selectionText.selectionStart)}`;

      onChange('message', newText);
    } else {
      onChange('message', emoji);
    }
  }

  function onFormatText(type) {
    if (Boolean(mensagem.message)) {
      if (selectionText.selectionStart !== selectionText.selectionEnd) {
        const typeFormat = findTypeFormat(type),
          message = mensagem.message;
        let textFormat = [
          message.slice(0, selectionText.selectionStart),
          typeFormat,
          message.slice(selectionText.selectionStart),
        ].join('');
        textFormat = [
          textFormat.slice(0, selectionText.selectionEnd + 1),
          typeFormat,
          textFormat.slice(selectionText.selectionEnd + 1),
        ].join('');

        onChange('message', textFormat);
      }
    }
  }

  function onRemoveFile(index) {
    midiaMessage.splice(index, 1);
    mensagem.files.splice(index, 1);
  }

  function tituloModal() {
    switch (mensagem.pra_quem) {
      case 1:
        return 'Refazer campanha para pessoas que compraram';
      case 2:
        return 'Refazer campanha para pessoas que não compraram';
      case 3:
        return 'Refazer campanha para todas as pessoas';
      default:
        return 'Nova Campanha';
    }
  }

  function header() {
    return (
      <Box>
        <Typography variant="strong" component="strong">
          {tituloModal()}
        </Typography>
        <Box className="modal-header">
          <Box className="modal-subtitle">
            <Typography variant="strong" component="strong">
              Tipo de Campanha:{' '}
            </Typography>
            WHATSAPP
          </Box>
          <Box className="modal-subtitle">
            <Typography variant="strong" component="strong">
              Mensagens na lista:{' '}
            </Typography>
            <Countup
              isLoading={isLoadingCountPhone}
              end={parseInt(contador_campanha.contador_celular || 0, 10)}
            />
          </Box>
        </Box>
        <Divider style={{ marginTop: '2rem' }} />
        <Box width="100%">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton onClick={() => setActiveStep(index)}>
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider />
      </Box>
    );
  }

  function footer() {
    return (
      <Box width="100%" display="flex" justifyContent="space-between">
        {activeStep !== 0 ? (
          <MyButton
            variant="text"
            style={{ color: COLOR_PRIMARY }}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Voltar
          </MyButton>
        ) : (
          <div />
        )}

        {activeStep !== 5 && (
          <MyButton
            variant="text"
            style={{ color: COLOR_PRIMARY }}
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Avançar
          </MyButton>
        )}
      </Box>
    );
  }

  function files(editable = true) {
    return Boolean(midiaMessage.length) ? (
      midiaMessage.map((midia, index) => (
        <Grid key={index} item>
          <Paper
            style={{
              width: midia.width / 4,
              height: midia.height / 4,
            }}
            elevation={3}
          >
            <Box
              width="100%"
              height="100%"
              borderRadius={5}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              paddingBottom="2vh"
              style={{
                backgroundImage: `url(${midia.file})`,
                backgroundSize: 'cover',
              }}
              onMouseEnter={() => setShowButtonDelete(true)}
              onMouseLeave={() => setShowButtonDelete(false)}
            >
              {Boolean(editable) && (
                <Zoom in={showButtonDelete}>
                  <Fab
                    size="small"
                    aria-label="delete"
                    onClick={() => onRemoveFile(index)}
                  >
                    <Delete />
                  </Fab>
                </Zoom>
              )}
            </Box>
          </Paper>
        </Grid>
      ))
    ) : (
      <Box />
    );
  }

  function step1() {
    return (
      <Box paddingX="15rem" paddingBottom="2rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              variant="h5"
            >
              Tipo da campanha
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo da campanha"
              options={TIPO_ENVIO_CAMPANHA.slice(0, 1)}
              placeholder="Selecione o tipo da campanha..."
              value={tipo_envio_campanha}
              onChange={tipo_campanha =>
                onChange('tipo_envio_campanha', tipo_campanha)
              }
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  function step2() {
    return (
      <Fragment>
        <Box paddingX="15rem" paddingY="2rem">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                variant="h5"
              >
                Informações da campanha
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome"
                className="form-control"
                onChange={({ target: { value } }) =>
                  onChange('nomeCampanha', value)
                }
                value={mensagem.nomeCampanha}
                autoComplete="none"
              />
            </Grid>
            {!tabInstances && (
              <Grid item xs={12}>
                <Select
                  label="Audiência"
                  disabled
                  value={mensagem.optionList}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Select
                label="Instâncias"
                disabled={tabInstances}
                placeholder="Selecione uma instância..."
                options={instances}
                labelKey="nome"
                valueKey="id"
                onChange={instance => setInstance(instance)}
                value={mensagem.instance}
              />
            </Grid>

            {mensagem.ofertas && (
              <Grid item xs={12}>
                <Select
                  label="Audiência"
                  options={listsProspectei.map(list => ({
                    label: list.nameList,
                    value: list.idListProspectei || list.idPromocao,
                  }))}
                  placeholder="Selecione uma audiência..."
                  onChange={option => {
                    onChange('listaSelecionada', option);
                    contadorWhatsapp({
                      celulares: mensagem.selectedCelular.map(opt => opt.value),
                      ...option,
                    });
                  }}
                  value={mensagem.listaSelecionada}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <DatePicker
                dateAndTime
                label="Início da campanha"
                onChange={date => onChange('dataInicioCampanha', date)}
                value={mensagem.dataInicioCampanha}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                dateAndTime
                label="Término da campanha"
                onChange={date => onChange('dataFimCampanha', date)}
                value={mensagem.dataFimCampanha}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box paddingX="15rem" paddingY="2rem">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: '900', color: COLOR_PRIMARY }}
                variant="h5"
              >
                Informações do disparo
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                dateAndTime
                label="Envio do whatsapp"
                onChange={date => onChange('dataInicio', date)}
                value={mensagem.dataInicio}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <TextField
                  label="Números copiados na campanha"
                  className="form-control"
                  onChange={({ target: { value } }) => onPasteAdd(value)}
                  onKeyUp={({ keyCode, target: { value } }) =>
                    onAdd(keyCode, value)
                  }
                  value={phoneTest}
                  autoComplete="none"
                />
                <Typography variant="caption">
                  *Separe os números por <strong>vírgula</strong>, ou pressione{' '}
                  <Typography variant="strong" component="strong">
                    enter
                  </Typography>
                  .
                </Typography>
              </Box>
            </Grid>
            {showNumeros &&
              mensagem.numerosList.map((numero, index) => (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    marginTop="2vh"
                  >
                    <Typography variant="strong" component="strong">
                      {numero.label}
                    </Typography>
                    <MyButton
                      bsSize="xs"
                      bsstyle="danger"
                      onClick={() => removerNumero(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </MyButton>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Fragment>
    );
  }

  function step3() {
    return (
      <Box paddingX="15rem" paddingY="2rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              variant="h5"
            >
              Rastreio
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Link (URL)"
              className="form-control"
              disabled={
                mensagem.redirectTracker &&
                (mensagem.redirectTracker.includes('ofertasdoclube') ||
                  mensagem.redirectTracker.includes('cobratudo'))
              }
              onChange={({ target: { value } }) =>
                onChange('redirectTracker', value)
              }
              value={mensagem.redirectTracker}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Domínio"
              options={HOST_TRACKER}
              disabled
              valueKey="host"
              placeholder="Domínio..."
              onChange={option => onChange('hostTracker', option)}
              value={HOST_TRACKER[0]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Facebook Pixel"
              className="form-control"
              autoComplete="none"
              value={mensagem.facebook_id}
              onChange={({ target: { value } }) =>
                onChange('facebook_id', value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Google Tag"
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows="10"
              value={mensagem.google_tag}
              onChange={({ target: { value } }) =>
                onChange('google_tag', value)
              }
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  function step4() {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        paddingX="15rem"
        paddingBottom="2rem"
      >
        <ButtonGroup size="small" color="default">
          <MyButton onClick={() => onFormatText('bold')} variant="outlined">
            <FormatBold />
          </MyButton>
          <MyButton onClick={() => onFormatText('italic')} variant="outlined">
            <FormatItalic />
          </MyButton>
          <MyButton onClick={() => onFormatText('strike')} variant="outlined">
            <FormatStrikethrough />
          </MyButton>
        </ButtonGroup>
        <Box marginTop="2vh" width="100%">
          <Popover
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            anchorEl={anchorEmoji}
            open={Boolean(anchorEmoji)}
            onClose={() => setAnchorEmoji(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <PickerEmoji
              onEmojiClick={(_, { emoji }) => onEmojiText(emoji)}
              disableAutoFocus
              disableSearchBar
              disableSkinTonePicker
              groupNames={{
                smileys_people: 'Smileys e pessoas',
                animals_nature: 'Animais e natureza',
                food_drink: 'Comidas e bebidas',
                travel_places: 'Viagens e lugares',
                activities: 'Atividades',
                objects: 'Objetos',
                symbols: 'Símbolos',
                flags: 'Bandeiras',
                recently_used: 'Recentes',
              }}
              pickerStyle={{ boxShadow: '' }}
            />
          </Popover>

          <TextField
            fullWidth
            multiline
            autoFocus
            focused
            placeholder="Digite uma mensagem"
            rowsMax="10"
            inputRef={inputRef}
            value={mensagem.message}
            onSelect={updateSelectionText}
            onChange={({ target: { value } }) => onChange('message', value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={({ currentTarget }) =>
                      setAnchorEmoji(currentTarget)
                    }
                  >
                    <SentimentSatisfiedRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    );
  }

  function step5() {
    return (
      <Box paddingX="15rem" paddingBottom="2rem">
        <Dropzone
          filesLimit={1}
          maxFileSize={ONE_MB_SIZE_IN_BYTES * 50}
          showPreviewsInDropzone={false}
          clearOnUnmount={false}
          showAlerts={false}
          dropzoneText="Arraste ou clique para adicionar"
          initialFiles={mensagem.files}
          onChange={files => onChange('files', files)}
          acceptedFiles={[
            'application/pdf',
            'image/jpeg',
            'image/jpeg',
            'image/svg+xml',
            'image/png',
            'application/msword',
            'video/x-sgi-movie',
            'video/mp4',
            'video/x-msvideo',
          ]}
        />
        <Grid container justify="center" spacing={3}>
          {files()}
        </Grid>
      </Box>
    );
  }

  function step6() {
    return (
      <Box color={BG_PRIMARY}>
        <Grid style={{ padding: '2rem 15rem' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              color="primary"
              variant="h5"
            >
              Sobre a Campanha
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box paddingBottom="2rem">
              <p>
                <Typography variant="strong" component="strong">
                  Nome
                </Typography>
              </p>
              <Typography>{mensagem.nomeCampanha}</Typography>
            </Box>
            {mensagem.instance && (
              <Box paddingBottom="2rem">
                <p>
                  <Typography variant="strong" component="strong">
                    Instância
                  </Typography>
                </p>
                <Typography>{mensagem.instance.nome}</Typography>
              </Box>
            )}
            {mensagem.optionList && (
              <Box paddingBottom="2rem">
                <p>
                  <Typography variant="strong" component="strong">
                    Audiência
                  </Typography>
                </p>
                <Typography>{mensagem.optionList.label}</Typography>
              </Box>
            )}
            <Box paddingBottom="2rem">
              <p>
                <Typography variant="strong" component="strong">
                  Data Disparo
                </Typography>
              </p>
              <Typography>
                {moment(mensagem.dataInicio).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Box>

            <Box paddingBottom="2rem">
              <p>
                <Typography variant="strong" component="strong">
                  Mensagens na lista
                </Typography>
              </p>

              <Countup
                isLoading={isLoadingCountPhone}
                end={contador_campanha.contador_celular || 0}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid style={{ padding: '2rem 15rem' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              color="primary"
              variant="h5"
            >
              Mensagem
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{mensagem.message}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid style={{ padding: '2rem 15rem' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              color="primary"
              variant="h5"
            >
              Mídia
            </Typography>
          </Grid>
          <Grid container justify="center" spacing={3}>
            {files(false)}
          </Grid>
        </Grid>
        <Divider />
        <Grid
          style={{ padding: '2rem 15rem', textAlign: 'center' }}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: '900', color: COLOR_PRIMARY }}
              color="primary"
              variant="h5"
            >
              Tudo Pronto ?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MyButton
              disabled={_disableButtonSave()}
              onClick={onSave}
              size="large"
            >
              <Icon>send</Icon>&nbsp;Disparar
            </MyButton>
            <Box paddingTop="2vh">
              {mensagem.uploadingWhatsApp && <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderStep() {
    switch (activeStep) {
      case 0:
        return step1();
      case 1:
        return step2();
      case 2:
        return step3();
      case 3:
        return step4();
      case 4:
        return step5();
      case 5:
        return step6();
      default:
        return step1();
    }
  }

  return (
    <Modal
      size="lg"
      showModal={mensagem.showModalWhatsApp}
      onClose={onClose}
      header={header()}
      footer={footer()}
    >
      {renderStep()}
    </Modal>
  );
}

const mapStateToProps = ({ CampaignReducer, InsightsReducer }) => ({
  ...CampaignReducer,
  ...InsightsReducer,
});

const mapDispatchToProps = {
  contadorWhatsapp,
  addNumeroCampanha,
  removeNumeroCampanha,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaCampanhaWhatsapp);
