import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import AddIcon from '../../../../assets/icons/marketing/Add.svg';
import CalendarIcon from '../../../../assets/icons/marketing/Calendar.svg';
import useStyles from '../../styles';

export default function CardCampaign({ image, title, startDate, endDate, onClickMore }) {
  const classes = useStyles();
  return (
    <Box className={classes.card} style={{ height: '100%' }}>
      {image ? <img className={classes.cardImage} src={image} /> : <div />}
      <Box className={classes.cardDescription} style={{ width: image ? 240 : '100%' }}>
        <Typography color="primary" className={classes.cardTitle}>
          {title}
        </Typography>

        <Box display="flex">
          <img src={CalendarIcon} className={classes.icon} />
          <Box className={classes.cardContainerDescription}>
            <Typography className={classes.textDescription}>Início: {startDate}</Typography>
            {Boolean(endDate) ? (
              <Typography className={classes.textDescription}>Término: {endDate}</Typography>
            ) : null}
          </Box>
        </Box>
        <Button
          size="small"
          startIcon={<img src={AddIcon} />}
          className={classes.btnViewMore}
          onClick={onClickMore}
        >
          Ver mais
        </Button>
      </Box>
    </Box>
  );
}

CardCampaign.defaultProps = {
  image: '',
  title: '',
  startDate: '',
  endDate: '',
  onClickMore: Function
};

CardCampaign.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onClickMore: PropTypes.func
};

