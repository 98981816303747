import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { AMBIENTE } from '../../../../../../../../utils/helpers/Constants';
import api from '../../../../../../../../utils/helpers/Fetch';
import { useStyles } from '../../styles';

export default function Credits() {
  const classes = useStyles();
  const [credits, setCredits] = useState(0);
  const getLastLimitHistory = async function () {
    try {
      let result = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/lastLimitHistory`,
        null,
        'GET',
        null,
        null,
        false
      );

      if (result?.content?.balance) setCredits(result.content.balance / 1000);
      else setCredits(0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLastLimitHistory();
  }, []);

  return (
    <Typography 
      gutterBottom
      className={classes.postText}
      component="span"
      data-testid="createAds-form-credit-component"
    >
      Saldo de crédito{credits > 1 ? 's' : ''}: {credits}
    </Typography>
  );
}