import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { getUsers } from '../actions/actions';
import api from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import useDate from '../../../hooks/date';
import useStyles from '../styles';
import useStylesChat from '../styles/chat';
import ChatInformationFilter from './ChatInformationFilter';

function ChatInformationTable(props) {
  const classes = useStyles();
  const classesChat = useStylesChat();
  const { getDate, getMoment, subtractDate } = useDate();
  const tableEl = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [filterAgent, setFilterAgent] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterInstance, setFilterInstance] = useState(null);
  const [filterPeriod, setFilterPeriod] = useState({
    start: getDate('YYYY-MM-DD', subtractDate(1, 'month')),
    end: getDate('YYYY-MM-DD', getMoment('YYYY-MM-DD')),
  });

  const [rows, setRows] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [agentFilterItems, setAgentFilterItems] = useState([]);
  const [statusFilterItems] = useState([
    { id: 0, name: 'Abertos' },
    { id: 1, name: 'Em Andamento' },
    { id: 2, name: 'Concluídos' },
  ]);
  const [instanceFilterItems, setInstanceFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);
  // hasMore should come from the place where you do the data fetching
  // for example, it could be a prop passed from the parent component
  // or come from some store
  const [hasMore, setHasMore] = useState(true);

  async function getCalls(page = 1) {
    setLoading(true);
    setCurrentPage(page);
    let params = {};

    if (filterAgent) params = { ...params, user: filterAgent.id };
    if (filterStatus) params = { ...params, status: filterStatus.id };
    if (filterInstance) params = { ...params, instance: filterInstance.id };
    if (filterPeriod)
      params = {
        ...params,
        start_date: filterPeriod.start,
        end_date: filterPeriod.end,
      };

    try {
      const response = await api(
        `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/analytics/called?page=${page}`,
        params
      );
      const { list } = await response?.content;

      setHasMore(list.length >= 10 ? true : false);

      if (list.length > 0) {
        return list.map(_list => ({
          id: _list.USER,
          instance: _list.INSTANCE,
          call: _list.SHORT_TICKET,
          client: _list.NAME,
          currentAgent: _list.USERNAME,
          status: statusFilterItems.filter(
            ({ id }) => id === parseInt(_list.STATUS)
          )[0].name,
          start: _list.CREATED_AT,
          end: _list.FINISHED_AT,
          duration: `${_list.DURATION}${_list.UNIT_DURATION}`,
        }));
      } else {
        hasMore(false);
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  const handleClearFilters = () => {
    setClearFilters(true);
    setTimeout(() => setClearFilters(false), 400);
  };

  const loadMore = useCallback(async () => {
    const nextPage = currentPage + 1;
    setLoading(true);

    try {
      const nextPageCalls = await getCalls(nextPage);
      setRows([...rows, ...nextPageCalls]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [rows]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;

    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    setRows([]);
    (async function () {
      const filteredCalls = await getCalls(1);
      setRows(filteredCalls);
    })();
  }, [filterAgent, filterStatus, filterInstance, filterPeriod]);

  useEffect(() => {
    (function () {
      if (props.users) {
        const agents = Object.keys(props.users).map(key => ({
          id: props.users[parseInt(key)].id,
          name: props.users[parseInt(key)].name,
        }));

        setAgentFilterItems(agents);
      }
    })();
  }, [props.users]);

  useEffect(() => {
    // Get Users Request
    props.getUsers();
    // Instance Request
    (async function () {
      try {
        const response = await api(
          `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/instances/client/list`
        );
        const filteredInstancesByType2 = response.content.filter(
          ({ tipo_instancia }) => tipo_instancia === 2
        );
        const instances = Object.keys(filteredInstancesByType2).map(key => ({
          id: filteredInstancesByType2[key].id,
          name: filteredInstancesByType2[key].nome,
        }));

        setInstanceFilterItems(instances);
      } catch (err) {
        console.log(err);
      }
    })();

    // Chat Infomation (Analytics) Request
    (async function () {
      try {
        const calls = await getCalls();
        setRows(calls);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <Typography
        color="primary"
        className={classes.title}
        style={{ paddingTop: '2rem' }}
      >
        Filtrar
      </Typography>

      <Box className={classesChat.filterContainer}>
        <ChatInformationFilter
          name="Agente"
          list={agentFilterItems}
          setSelectedValue={setFilterAgent}
          clear={clearFilters}
        />
        <ChatInformationFilter
          name="Status"
          list={statusFilterItems}
          setSelectedValue={setFilterStatus}
          clear={clearFilters}
        />

        <ChatInformationFilter
          name="Período"
          isDate
          startDate={filterPeriod.start}
          endDate={filterPeriod.end}
          onChangeDate={date => setFilterPeriod(date)}
          clear={clearFilters}
        />
        <ChatInformationFilter
          name="Instância"
          list={instanceFilterItems}
          setSelectedValue={setFilterInstance}
          clear={clearFilters}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleClearFilters}
        >
          Limpar
        </Button>
      </Box>

      <TableContainer className={classesChat.tableContainer} ref={tableEl}>
        <Table stickyHeader className={classesChat.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Instância
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Chamado
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Cliente
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Agente
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Status
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Início
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Fim
              </TableCell>
              <TableCell align="center" className={classesChat.tableCellHead}>
                Duração
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: '0 5rem' }}>
            {rows.map(({ id, ...fields }) => (
              <TableRow key={id}>
                {Object.keys(fields).map((field, index) => (
                  <TableCell
                    align="center"
                    className={classesChat.tableCellBody}
                    key={index.toString()}
                  >
                    {fields[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Typography
            color="primary"
            className={classes.dashboardText}
            variant="h4"
            align="center"
          >
            {loading
              ? 'Carregando...'
              : filterAgent || filterStatus || filterInstance
              ? 'Não existem chamados para o(s) filtro(s) selecionados!'
              : 'Não existem chamados!'}
          </Typography>
        )}
        {loading && <CircularProgress className={classesChat.progress} />}
      </TableContainer>
    </>
  );
}

const mapStateToProps = ({ ChatReducer }) => ({ ...ChatReducer });
const mapDispatchToProps = { getUsers };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatInformationTable);
