import jwt_decode from 'jwt-decode';

import { logError } from './LogError';

export default async params => {
  const showAlert = params.showAlert === undefined ? true : params.showAlert;
  const clearAndRedirect = params.clearAndRedirect === undefined ? true : params.clearAndRedirect;
  const forceCleanStorage =
    params.forceCleanStorage === undefined ? false : params.forceCleanStorage;
  const msg = params.msg === undefined ? '' : params.msg;

  const token = JSON.parse(localStorage.getItem('token'));
  const companyId = Number(JSON.parse(localStorage.getItem('companyId')));
  const decodedToken = jwt_decode(token?.toString());
  const dateNow = new Date();
  const expirou = (decodedToken?.exp || 0) * 1000 < dateNow.getTime();
  if (companyId === 33533) {
    console.log({
      companyId,
      token,
      dateNow,
      expirou,
      decodedToken
    });
  }
  if (expirou === true) {
    if (showAlert) alert('Sua sessão expirou faça login novamente' + msg);

    if (clearAndRedirect) localStorage.clear();
    if (clearAndRedirect) window.history.go('/');
  } else if (forceCleanStorage) {
    alert('Algo aconteceu no caminho, por gentileza, tente novamente... ' + msg);
    // coloquei pra nao forçar mais redirecionar pro inicio, apenas se estiver expirado token

    if (params.notLog) return;

    logError(params);
  }

  return expirou; //componente Error usa esse retorno
};
