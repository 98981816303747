import { Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Field } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  },
  input: {
    display: 'none',
  },
}));

const FileField = ({ name, ...props }) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => {
        const handleChange = ({ target }) => {
          onChange(target.files[0]);
        };
        return (
          <React.Fragment>
            <input
              {...input}
              id="contained-button-file"
              className={classes.input}
              type="file"
              onChange={handleChange}
              {...props}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                color="default"
                size="large"
                startIcon={<AddIcon />}
              >
                Upload
              </Button>
            </label>
          </React.Fragment>
        );
      }}
    </Field>
  );
};

export default FileField;
