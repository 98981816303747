import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Dialog,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: 24
}));

const TypographyTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '26px',
  color: '#000000',
  letterSpacing: '0.05em'
}));

const ContainerContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: 9
}));

const TypographyContent = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 500,
  letterSpacing: '0.05em'
}));

const ContainerActions = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: 0,
  gap: 16,
  width: '100%'
}));

const ActionClose = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '8px 16px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.05em',
  color: '#616161'
}));

const ActionAgree = styled(Button)<ButtonProps>(({ theme }) => ({
  marginLeft: 'auto',
  padding: '8px 16px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.05em'
}));

interface IAlertDialogV2Props {
  open: boolean;
  maxWidth?: number;
  paddingInPx?: string;
  title?: string;
  contentText?: string;
  contentTextColor?: string;
  handleAgree: VoidFunction;
  handleClose: VoidFunction;
  textAgree?: string;
  textClose?: string;
  loading?: boolean;
}
export const AlertDialogV2 = ({
  open,
  maxWidth,
  paddingInPx,
  title,
  contentText,
  contentTextColor,
  handleAgree,
  handleClose,
  textAgree,
  textClose,
  loading
}: IAlertDialogV2Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box maxWidth={maxWidth || 511} padding={paddingInPx || '32px'}>
        <Container>
          {title && <TypographyTitle id="title">{title}</TypographyTitle>}
          {contentText && (
            <ContainerContent id="content">
              <TypographyContent color={contentTextColor || '#000000'} style={{color: '#616161'}}>
                {contentText}
              </TypographyContent>
            </ContainerContent>
          )}
          <ContainerActions>
            <ActionClose onClick={handleClose}>{textClose || 'Cancelar'}</ActionClose>
            <ActionAgree
              disabled={loading}
              onClick={handleAgree}
              variant="contained"
              color={loading ? 'inherit' : 'info'}
            >
              {textAgree || 'Remover'}
            </ActionAgree>
          </ContainerActions>
        </Container>
      </Box>
    </Dialog>
  );
};

