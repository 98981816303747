import { blue, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { COLOR_PRIMARY } from '../utils/helpers/Constants';

const themeMUI5 = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    button: {
      textTransform: 'none'
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'
  ],
  palette: {
    primary: {
      ...blue,
      500: COLOR_PRIMARY,
      main: COLOR_PRIMARY,
      100: '#B336325e'
    },
    secondary: red,
    info: {
      main: '#2813AD'
    },
    error: {
      main: '#f44336'
    }
  },
  shape: {
    borderRadius: 5
  },
  spacing: 8,
  zIndex: { snackbar: 999999999 }
});

export default themeMUI5;

