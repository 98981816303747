import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import { useStyles } from './styles';

interface ExitConfirmationModalProps {
  openModal: boolean;
  confirmate: () => void;
  handleClose: () => void;
}

export default function ExitConfirmationModal({
  openModal,
  confirmate,
  handleClose
}: ExitConfirmationModalProps) {
  const classes = useStyles();

  return (
    <Dialog open={openModal} onClose={() => handleClose} className={classes.root}>
      {/* modal */}
      <Box className={classes.card}>
        <DialogTitle className={classes.title}>
          Tem certeza que deseja sair?
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content}>
            Ao sair, você perderá as configurações de distribuição.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <Box className={classes.cardButtons}>
          <Box className={classes.button1}>
            <Button color="inherit" onClick={handleClose}>
              Ficar nesta página
            </Button>
          </Box>
          <Box className={classes.button2}>
            <Button variant="contained" onClick={confirmate}>
              Sair desta página
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}