import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ImageList from '@mui/material/ImageList';
import Skeleton from '@mui/material/Skeleton';
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { COLOR_NEW_PRIMARY } from 'utils/helpers/Constants';

import { Template } from '../..';
import { useDefaultStyles } from '../../../../../../TabloideDigitalV2/style';

interface RenderImagesProps {
  templates: Array<Template>;
  templatesFull: Array<Template>;
  loading: boolean;
  setTemplates: Dispatch<SetStateAction<Array<Template>>>;
  setLayoutSelected: Dispatch<SetStateAction<Template>>;
  setCurrentTab: () => void;
  handleClose: () => void;
}

export default function RenderImages({
  templates,
  templatesFull,
  loading,
  setTemplates,
  setLayoutSelected,
  setCurrentTab,
  handleClose
}: RenderImagesProps): JSX.Element {
  const magnumClasses = useDefaultStyles();

  useEffect(() => {
    setCurrentTab();
  }, []);

  return (
    <>
      <Box
        id="referenceScrollable"
        component="div"
        sx={{
          position: 'relative',
          overflow: 'auto',
          maxHeight: '800px',
          paddingBottom: '180px'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '800px',
            zIndex: 5
          }}
        ></Box>
        <InfiniteScroll
          scrollableTarget="referenceScrollable"
          dataLength={templates.length}
          next={() => {
            setTemplates(templatesFull.slice(0, templates.length + 12));
          }}
          hasMore={templates.length < templatesFull.length}
          loader={undefined}
        >
          <ImageList
            rowHeight={'auto'}
            gap={3}
            sx={{
              padding: theme => theme.spacing(0, 0, 0),
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {loading
              ? Array(20)
                  .fill(20)
                  .map((_, i) => (
                    <div key={i} style={{ padding: '13px' }}>
                      <Skeleton
                        className={`${magnumClasses.gridMediaQueryHeight} ${magnumClasses.gridCard}}`}
                        style={{ height: 290, width: 180, borderRadius: 5 }}
                        variant="rectangular"
                      />
                    </div>
                  ))
              : templates.map((item, index) => (
                  <>
                    {/* {index < 5 && console.log(item)} */}
                    <div key={item.id} className={magnumClasses.cardTabloids}>
                      <div
                        key={index}
                        style={{
                          marginRight: 24,
                          marginBottom: 8,
                          width: 180,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          cursor: 'pointer'
                        }}
                      >
                        <div className={magnumClasses.gridMediaQueryHeight}>
                          <div className={magnumClasses.cardOptions}>
                            <div
                              className={magnumClasses.redirectEditor}
                              onClick={() => {
                                setLayoutSelected(item);
                                handleClose();
                              }}
                            ></div>
                          </div>

                          <Box
                            sx={{
                              'max-height': '250px',
                              'max-width': '100%',
                              width: 180,
                              margin: 'auto',
                              borderRadius: '8px',
                              padding: '12px 8px'
                            }}
                          >
                            <img
                              className={magnumClasses.gridImageEstudio2}
                              src={item.thumbnail || item.imageURL}
                              crossOrigin='anonymous'
                              alt={item.name + ' - id: ' + item.id}
                              title={item.name + ' - id: ' + item.id}
                              loading="lazy"
                            />
                          </Box>
                        </div>
                      </div>

                      <div style={{ marginTop: 8 }}>
                        <div
                          className={magnumClasses.gridTitle2}
                          title={item.name + ' - id: ' + item.id}
                        >
                          {item.name + ' - id: ' + item.id}
                        </div>

                        <div style={{ width: '100%' }}>
                          {(item?.tags ? item.tags.split(',') : []).map(tag => (
                            <Chip
                              size="small"
                              label={tag}
                              style={{
                                margin: 3,
                                backgroundColor: tag === 'recomendado' ? COLOR_NEW_PRIMARY : '',
                                color: tag === 'recomendado' ? '#FFF' : ''
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ))}

            {!loading && templates && templates.length === 0 && (
              <div style={{ width: '100%', textAlign: 'center', marginTop: 120 }}>
                Nenhuma arte encontrada!
              </div>
            )}
          </ImageList>
        </InfiniteScroll>
      </Box>
    </>
  );
}

