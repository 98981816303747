import Fetch /*FetchSocket*/ from '../../../utils/helpers/Fetch';
import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';

const _isLoading = () => dispatch => dispatch({ type: 'IS_LOADING' });
const _loadProdPedido = () => dispatch =>
  dispatch({ type: 'LOAD_PRODUTO_PEDIDO' });

export const onChange = (object, state, value) => dispatch =>
  dispatch({ type: 'ON_CHANGE', payload: { object, state, value } });

export const getLojas = pedidos => async dispatch => {
  try {
    dispatch(_isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`,
      pedidos
    );
    dispatch({ type: 'LOJAS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getPedidosDelivery = pedidos => async dispatch => {
  try {
    dispatch(_isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getPedidos`,
      pedidos
    );
    dispatch({ type: 'PEDIDOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getPedidosDeliveryPooling = pedidos => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getPedidos`,
      pedidos
    );
    dispatch({ type: 'PEDIDOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getProdutosDelivery = produtos => async dispatch => {
  try {
    dispatch(_isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getProdutos`,
      produtos
    );
    dispatch({ type: 'PRODUTOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getProdutosPedidoDelivery = id_pedido => async dispatch => {
  try {
    dispatch(_loadProdPedido());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getProdutosPedidos`,
      { id_pedido }
    );
    dispatch({ type: 'PRODUTOS_PEDIDOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getCategoriasDelivery = loja => async dispatch => {
  try {
    dispatch(_isLoading());
    // await FetchSocket();
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getCategorias`,
      { loja }
    );
    dispatch({ type: 'CATEGORIAS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getHistoricoStatusPedido = guid_pedido => async dispatch => {
  try {
    dispatch(_loadProdPedido());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getHistoricoPedido`,
      { guid_pedido }
    );
    dispatch({ type: 'HISTORICO_PEDIDO', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getImagemProduto = () => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getImagemProduto`
    );
    dispatch({ type: 'IMAGEM_PRODUTO', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getArquivos = status => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getArquivos`,
      { status }
    );
    dispatch({ type: 'ARQUIVOS', payload });
  } catch (err) {
    console.log(err);
  }
};

export const postCategoriasDelivery = categoria => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/postCategorias`,
      categoria,
      'POST'
    );
    dispatch(getCategoriasDelivery());
  } catch (err) {
    console.log(err);
  }
};

export const postProdutoDelivery = produto => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/postProduto`,
      produto,
      'POST'
    );
    dispatch(getProdutosDelivery());
  } catch (err) {
    console.log(err);
  }
};

export const postArquivoProdutosDelivery = (
  arquivo,
  callback
) => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/postArquivoProduto`,
      arquivo,
      'POST'
    );
    callback();
    setTimeout(() => dispatch(getArquivos()), 700);
  } catch (err) {
    console.log(err);
  }
};

export const postLoja = loja => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/postLoja`,
      loja,
      'POST'
    );
    dispatch(getLojas());
  } catch (err) {
    console.log(err);
  }
};

export const putProdutoDelivery = (produto, callback) => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putProduto`,
      produto,
      'PUT'
    );
    callback();
  } catch (err) {
    console.log(err);
  }
};

export const putStatusCategorias = status_categoria => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putStatusCategoria`,
      status_categoria,
      'PUT'
    );
    dispatch(getCategoriasDelivery());
  } catch (err) {
    console.log(err);
  }
};

export const putStatusPedido = status_pedido => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putStatusPedido`,
      status_pedido,
      'PUT'
    );
    dispatch(getPedidosDelivery());
  } catch (err) {
    console.log(err);
  }
};

export const putProdutoPedido = produto => async dispatch => {
  try {
    dispatch(_loadProdPedido());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putProdutoPedido`,
      produto,
      'PUT'
    );
    dispatch(getProdutosPedidoDelivery(produto.id_pedido));
  } catch (err) {
    console.log(err);
  }
};

export const putInfoLoja = loja => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putLojas`,
      loja,
      'PUT'
    );
    dispatch(getLojas());
  } catch (err) {
    console.log(err);
  }
};

export const disableLoja = loja => async dispatch => {
  try{
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/disableLoja`,
      loja,
      'PUT'
    );
    dispatch(getLojas());
  }catch(err){
    console.log(err);
  }
};

export const putCategoriasDelivery = categoria => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putCategoria`,
      categoria,
      'PUT'
    );
    dispatch(getCategoriasDelivery());
  } catch (err) {
    console.log(err);
  }
};

export const deleteProdutoPedido = produto => async dispatch => {
  try {
    dispatch(_loadProdPedido());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteProdutoPedido`,
      produto,
      'DELETE'
    );
    dispatch(getProdutosPedidoDelivery(produto.id_pedido));
  } catch (err) {
    console.log(err);
  }
};

export const deleteProduto = (produto, callback) => async dispatch => {
  try {
    dispatch(_isLoading());
    await Fetch(
      `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteProduto`,
      produto,
      'DELETE'
    );
    callback();
  } catch (err) {
    console.log(err);
  }
};
