import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomTable, SplitButton } from '../../../utils/components';
import {
  BG_PRIMARY,
  CELULARES,
  COLOR_PRIMARY,
  EMAILS,
  HOST_TRACKER,
  MES,
  PARETO,
  TIPO_CAMPANHA,
  TIPO_CAMPANHA_SIMPLIFICADO,
} from '../../../utils/helpers/Constants';
import MyDate from '../../../utils/helpers/Date';
import DSAWS from '../../../utils/helpers/DSAWS';
import {
  envioCampanhaCarta,
  envioCampanhaEmail,
  envioCampanhaPush,
  envioCampanhaRedeSocial,
  envioCampanhaSMS,
  envioCampanhaWhatsApp,
  getCabecalhoProspects,
  getInstancesStatusWhatsApp,
  getInstancesWhatsApp,
  getListaSMSById,
  salvarTemplate,
} from '../../Campanhas/action/actionCampanha';
import { getInstancias, getStatusInstancia, reloadQrCode } from '../../Chatbot/actions';
import {
  changeFiltro,
  changeFiltroSpread,
  changePerformance,
  clearFilter,
  contadorEmail,
  excluirFiltro,
  filtroConsulta,
  getListaFiltro,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  getPeopleProspecteiPF,
  postArquivoAudienciaCompleto,
  modalPessoa,
  saveListProspecsPF,
  savePromocao,
  setFiltro,
  showFilter,
} from '../../Insights/actions/actions';
import { getRecomedacoes } from '../../Recomendacoes/actions/actions';
import { getTabloides } from '../../TabloideDigital/action';
import {
  getPessoasByIDCampanha,
  getTodasPerformanceMes,
  getTodasPerformancePeriodo,
  setCampanha,
} from '../action/action';
import { getForms } from '../../AccountSettings/action';
import ImportaArquivoAudiencia from '../../Insights/components/ImportaArquivoAudiencia';
import ModalPerfil from './ModalPerfil';
import NovaCampanhaCarta from './NovaCampanhaCarta';
import NovaCampanhaEmail from './NovaCampanhaEmail';
import NovaCampanhaPush from './NovaCampanhaPush';
import NovaCampanhaRedeSocial from './NovaCampanhaRedeSocial';
import NovaCampanhaSMS from './NovaCampanhaSMS';
import NovaCampanhaWhatsApp from './NovaCampanhaWhatsappV2';
import QRCodeDialog from '../../Chatbot/component/Dialogs/QRCodeDialog';

class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostTracker: HOST_TRACKER[0],
      nome_campanha: '',
      tipo_envio_campanha: null,
      mensurar_campanha: null,
      facebook_id: null,
      google_tag: null,
      filtroDataInicio: moment().subtract(30, 'days'),
      filtroDataFim: moment(),
      filtroMes: MES[new Date().getMonth()],
      dataInicio: moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
      dataFim: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      dataInicioCampanha: moment_tz(moment()).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'),
      dataFimCampanha: moment_tz(moment().add(7, 'days'))
        .tz('America/Sao_Paulo')
        .format('YYYY-MM-DD HH:mm:ss'),
      pareto: PARETO[0],
      optionList: null,
      nomeLista: '',
      disabled: true,
      showModal: false,
      showModalPerfis: false,
      showModalDetalhesCampanha: false,
      showModalSMS: false,
      showModalCarta: false,
      showModalEmail: false,
      showModalRedeSocial: false,
      showModalWhatsApp: false,
      showModalPush: false,
      showModalSalvarFiltro: false,
      uploadingWhatsApp: false,
      nomeCampanha: '',
      message: '',
      numero: '',
      nomeRemetente: '',
      remetente: '',
      email: '',
      subject: '',
      template: '',
      templateName: '',
      listasPromocao: [],
      selectedCelular: CELULARES,
      selectedTemplate: {},
      selectedTabloide: {},
      numerosList: [],
      emailList: [],
      files: [],
      whatsApp_midia: false,
      whatsApp_url: '',
      selectedEmail: EMAILS,
      key: 4,
      tipo_usuario: '',
      intervalId: null,
      QRCodeDialogOpen: false,
      instanciaOnDialog: {},
      automacao_campanha_sms: false,
      automacao_campanha_email: false,
      importaArquivoAudiencia: false,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  /*
    Polling para pegar o status das instâncias a cada x segundos
  */

  loadStatusInstancias() {
    this.props.instancias.forEach(instancia => {
      setTimeout(() => {
        const this_ = this;
        this.props.getStatusInstancia(instancia.id).then(() => {
          const keys = Object.keys(this_.props.statusInstancias);
          keys.forEach(key => {
            let status = this_.props.statusInstancias[key],
              instancia = this_.props.instancias.find(
                instancia => instancia.id === status.idInstancia,
              );
            if (instancia) {
              instancia.status = status;
              // Fecha o modal do QR Code, caso o mesmo esteja aberto e a instância esteja conectada
              if (
                this_.state.QRCodeDialogOpen &&
                this_.state.instanciaOnDialog.id === status.idInstancia
              ) {
                if (status.connected) {
                  this_.setState({ QRCodeDialogOpen: false });
                } else {
                  this_.setState({ InstanciaOnDialog: { ...instancia } });
                }
              }
            }
          });
        });
      }, 200);
    });
  }

  handleInstanciaOnDialog(instancia) {
    if (instancia) {
      this.setState({ instanciaOnDialog: { ...instancia } });
    } else {
      this.setState({ instanciaOnDialog: {} });
    }
  }

  toggleQRCodeDialog(instancia) {
    this.handleInstanciaOnDialog(instancia);
    this.setState({ QRCodeDialogOpen: !this.state.QRCodeDialogOpen });
    if (instancia) {
      this.props.getStatusInstancia(instancia.id);
    }
  }

  clearAndCreateInterval() {
    clearInterval(this.state.intervalId);
    if (this.props.instancias && this.props.instancias.length > 0) {
      var intervalId = setInterval(() => this.loadStatusInstancias(), 5000);
      this.setState({ intervalId: intervalId });
    }
  }

  componentDidMount() {
    this.props.getListaFiltro();
    this.props.getCabecalhoProspects();
    this.props.getRecomedacoes();
    this.props.getTabloides();
    this.props.getForms();
    const { filtroDataInicio, filtroDataFim } = this.state;
    const request = {
      filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
      filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
    };
    this.props.getTodasPerformanceMes(request);

    this.props.getInstancias().then(() => {
      this.clearAndCreateInterval();
    });

    const permissions = JSON.parse(localStorage.getItem('permissions')),
      hasAud = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'audiencia'),
      ),
      hasOffers = Boolean(
        permissions.promocoes && permissions.promocoes.find(item => item.serviceName === 'ofertas'),
      ),
      hasCreateCampaign = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'criar_campanha'),
      ),
      hasWhatsapp = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'whatsapp'),
      );

    if (!hasAud && hasOffers) {
      this.setState({ key: 5 });
    } else if (!hasAud && !hasOffers && hasWhatsapp) {
      this.setState({ key: 6 });
      this.clearAndCreateInterval();
    } else if (!hasAud && !hasOffers && !hasWhatsapp && hasCreateCampaign) {
      this.setState({ key: 4 });
    } else if (!hasAud && !hasOffers && !hasWhatsapp && !hasCreateCampaign) {
      this.setState({ key: 7 });
    }

    const has_campanha_simplificado = permissions.relacionamento
      ? permissions.relacionamento.find(
          ({ serviceName }) => serviceName === 'campanha_simplificado',
        )
      : false;

    if (has_campanha_simplificado) {
      this.setState({ tipo_usuario: 'simplificado' });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim } = this.state;
      const request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
      };
      this.props.getTodasPerformanceMes(request);
    });
  }

  selecionaLista(lista) {
    if (lista) {
      const filtro_completo = JSON.parse(lista.filtro_completo);
      const filtro = JSON.parse(lista.filtro);
      this.props.filtroConsulta(filtro);
      this.props.changeFiltroSpread(filtro_completo);
      this.props.getPeopleProspecteiPF(filtro);
    } else {
      this.props.filtroConsulta({});
      this.props.getPeopleProspecteiPF();
      this.props.changeFiltroSpread();
    }

    this.props.setFiltro(lista);
  }

  criarCampanhaSMS() {
    const objCampanha = {
      nomeCampanha: this.state.nomeCampanha,
      numero: this.state.numero,
      numerosList: this.state.numerosList && this.state.numerosList.map(value => value.value),
      idListProspectei: this.state.optionList.value,
      message: this.state.message,
      dataInicio: moment(this.state.dataInicio).format('DD/MM/YYYY HH:mm'),
      dataFim: moment(this.state.dataFim).format('DD/MM/YYYY HH:mm'),
      dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
      dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
      nameList: this.state.optionList.label,
      origin: 'Minha Base',
      pareto: this.state.pareto.id,
      selectedCelular:
        this.state.selectedCelular && this.state.selectedCelular.map(value => value.value),
      selectedTemplate: this.state.selectedTemplate
        ? this.state.selectedTemplate.id_template
        : null,
      selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
      redirectTracker: this.state.redirectTracker,
      custoCampanha: this.props.contador_celular ? this.props.contador_celular.valor_total : 0,
      hostTracker: !this.state.hostTracker ? '' : this.state.hostTracker.host,
      tipo_envio_campanha: !Boolean(this.state.tipo_envio_campanha)
        ? null
        : this.state.tipo_envio_campanha.id,
      mensurar_campanha: !Boolean(this.state.tipo_envio_campanha)
        ? null
        : this.state.tipo_envio_campanha.id === 1
        ? null
        : !Boolean(this.state.mensurar_campanha)
        ? null
        : this.state.mensurar_campanha.id,
      google_tag: this.state.google_tag,
      facebook_id: this.state.facebook_id,
      automacaoCampanha: this.state.automacao_campanha_sms,
      unid_automacao: this.state.unitAutomacao,
      unid_tempo_automacao: this.state.unitTimeAutomacao && this.state.unitTimeAutomacao.value,
      dia_automacao: this.state.diaDisparoAutomacao && this.state.diaDisparoAutomacao.value,
      hora_automacao: this.state.horaDisparoAutomacao
        ? moment(this.state.horaDisparoAutomacao, 'HH:mm:ss').format('HH:mm:00')
        : moment().format('HH:mm:00'),
    };
    this.props.envioCampanhaSMS(objCampanha);

    this.setState({ showModalSMS: false });
  }

  async criarCampanhaWhatsApp() {
    try {
      let s3Response = {};

      if (this.state.files.length) {
        this.setState({ uploadingWhatsApp: true });
        s3Response = await DSAWS.saveS3({
          folderName: 'whatsappcampanha',
          file: this.state.files[0],
        });
        this.setState({ uploadingWhatsApp: false });
      }

      let objCampanha = {
        nomeCampanha: this.state.nomeCampanha,
        numerosList: this.state.numerosList && this.state.numerosList.map(value => value.value),
        message: this.state.message,
        dataInicio: moment(this.state.dataInicio).format('DD/MM/YYYY HH:mm'),
        dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
        dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
        nameList: this.state.optionList && this.state.optionList.label,
        origin: 'Minha Base',
        pareto: this.state.pareto.id,
        selectedCelular:
          this.state.selectedCelular && this.state.selectedCelular.map(value => value.value),
        redirectTracker: this.state.redirectTracker,
        custoCampanha: this.props.contador_celular ? this.props.contador_celular.valor_total : 0,
        whatsapp_midia: !!this.state.files.length,
        whatsapp_url: s3Response.Location || '',
        hostTracker: !this.state.hostTracker ? '' : this.state.hostTracker.host,
        selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
        tipo_envio_campanha: !Boolean(this.state.tipo_envio_campanha)
          ? null
          : this.state.tipo_envio_campanha.id,
        google_tag: this.state.google_tag,
        facebook_id: this.state.facebook_id,
        idInstancia: this.state.instance && this.state.instance.id,
      };
      if (this.state.key !== 6) {
        objCampanha = {
          ...objCampanha,
          idListProspectei: this.state.optionList.value,
        };
      }

      this.props.envioCampanhaWhatsApp(objCampanha);
      this.setState({ showModalWhatsApp: false });
    } catch (error) {
      console.log(error);
    }
  }

  criarCampanhaPush() {
    const objCampanha = {
      nomeCampanha: this.state.nomeCampanha,
      subject: this.state.subject,
      idListProspectei: this.state.optionList.value,
      message: this.state.message,
      dataInicio: moment(this.state.dataInicio).format('DD/MM/YYYY HH:mm'),
      dataFim: moment(this.state.dataFim).format('DD/MM/YYYY HH:mm'),
      dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
      dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
      nameList: this.state.optionList.label,
      origin: 'Minha Base',
      pareto: this.state.pareto.id,

      redirectTracker: this.state.redirectTracker,
      custoCampanha: this.props.contador_celular ? this.props.contador_celular.valor_total : 0,
      hostTracker: !this.state.hostTracker ? '' : this.state.hostTracker.host,
      selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
    };
    this.props.envioCampanhaPush(objCampanha);
    this.setState({ showModalPush: false });
  }

  salvarTemplate() {
    const date = new Date();

    const objTemplate = {
      template: this.state.template,
      templateName: this.state.templateName,
      date: moment(date.getDate()).format('DD/MM/YYYY HH:mm'),
    };

    this.props.salvarTemplate(objTemplate);
  }

  criarCampanhaEmail() {
    const objCampanha = {
      nomeCampanha: this.state.nomeCampanha,
      nomeRemetente: this.state.nomeRemetente,
      remetente: this.state.remetente,
      email: this.state.email,
      idListProspectei: this.state.optionList.value,
      subject: this.state.subject,
      template: this.state.template,
      dataInicio: moment(this.state.dataInicio).format('DD/MM/YYYY HH:mm'),
      dataFim: moment(this.state.dataFim).format('DD/MM/YYYY HH:mm'),
      dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
      dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
      nameList: this.state.optionList.label,
      origin: 'Minha Base',
      pareto: this.state.pareto.id,
      emailList: this.state.emailList.map(value => value.value),
      selectedEmail: this.state.selectedEmail.map(value => value.value),
      redirectTracker: this.state.redirectTracker,
      custoCampanha: this.props.contador_campanha.valor_total || 0,
      hostTracker: !this.state.hostTracker ? '' : this.state.hostTracker.host,
      selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
      automacaoCampanha: this.state.automacao_campanha_email,
      unid_automacao: this.state.unitAutomacao,
      unid_tempo_automacao: this.state.unitTimeAutomacao && this.state.unitTimeAutomacao.value,
      dia_automacao: this.state.diaDisparoAutomacao && this.state.diaDisparoAutomacao.value,
      hora_automacao: this.state.horaDisparoAutomacao
        ? moment(this.state.horaDisparoAutomacao, 'HH:mm:ss').format('HH:mm:00')
        : moment().format('HH:mm:00'),
    };
    this.props.envioCampanhaEmail(objCampanha);

    this.setState({ showModalEmail: false });
  }

  criarCampanhaCarta() {
    const objCampanha = {
      nomeCampanha: this.state.nomeCampanha,
      idListProspectei: this.state.optionList.value,
      dataInicio: moment(this.state.dataInicio).format('DD/MM/YYYY HH:mm'),
      dataFim: moment(this.state.dataFim).format('DD/MM/YYYY HH:mm'),
      dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
      dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
      nameList: this.state.optionList.label,
      origin: 'Minha Base',
      custo_campanha: this.state.custoCampanha,
      selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
    };

    this.props.envioCampanhaCarta(objCampanha);
    this.setState({ showModalCarta: false });
  }

  criarCampanhaRedeSocial() {
    const objCampanha = {
      nomeCampanha: this.state.nomeCampanha,
      idListProspectei: this.state.optionList.value,
      dataInicioCampanha: moment(this.state.dataInicioCampanha).format('DD/MM/YYYY HH:mm'),
      dataFimCampanha: moment(this.state.dataFimCampanha).format('DD/MM/YYYY HH:mm'),
      nameList: this.state.optionList.label,
      tipo_campanha: this.state.tipo_campanha.value,
      link_campanha: this.state.linkCampanha,
      custo_campanha: this.state.custoCampanha,
      redirectTracker: this.state.redirectTracker,
      hostTracker: !this.state.hostTracker ? '' : this.state.hostTracker.host,
      hash_tracker: this.state.hash_tracker,
      selectedTabloide: this.state.selectedTabloide.id_tabloide_digital,
    };

    this.props.envioCampanhaRedeSocial(objCampanha);
    this.setState({
      showModalRedeSocial: false,
    });
  }

  onKeyPressed(e) {
    const { nome_campanha } = this.state;
    if (e.keyCode === 13 && nome_campanha.length > 0) {
      this.props.getTodasPerformanceMes({ nome_campanha });
    } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
      this.onChangePeriodo();
    }
  }

  render() {
    const {
      classes,
      pessoaModal,
      listaInstancias,
      listaFiltro,
      isLoadingCampaign,
      isLoadingInsights,
    } = this.props;
    const {
      showModalSMS,
      showModalEmail,
      showModalCarta,
      showModalRedeSocial,
      showModalWhatsApp,
      showModalPush,
      importaArquivoAudiencia,
      key,
    } = this.state;

    if (key !== 6) {
      clearInterval(this.state.intervalId);
    }

    let TIP_CAMPANHA = [];
    this.state.tipo_usuario === 'simplificado'
      ? (TIP_CAMPANHA = TIPO_CAMPANHA_SIMPLIFICADO)
      : (TIP_CAMPANHA = TIPO_CAMPANHA);

    const permissions = JSON.parse(localStorage.getItem('permissions')),
      hasAud = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'audiencia'),
      ),
      hasCreateCampaign = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'criar_campanha'),
      ),
      hasWhatsapp = Boolean(
        permissions.relacionamento &&
          permissions.relacionamento.find(item => item.serviceName === 'whatsapp'),
      );

    return (
      <div className={classes.root}>
        {isLoadingCampaign ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          <Fragment>
            <Typography
              style={{
                height: '6rem',
              }}
              className={classes.label}
              variant="h3"
              noWrap
            >
              Criar Campanha
            </Typography>
            <div className={classes.actions}>
              <section
                style={{
                  display: 'flex',
                  marginTop: 20,
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>
                      <Button
                        color="primary"
                        size="large"
                        disabled={!hasAud}
                        className={key === 4 && classes.activeButton}
                        onClick={() => this.setState({ key: 4 })}
                      >
                        Minhas audiências
                      </Button>

                      {hasWhatsapp && (
                        <Button
                          color="primary"
                          size="large"
                          className={key === 6 && classes.activeButton}
                          onClick={() => {
                            this.setState({ key: 6 });
                            this.clearAndCreateInterval();
                          }}
                        >
                          Minhas instâncias
                        </Button>
                      )}
                    </div>
                    <Box>
                      {hasAud && (
                        <Button
                          color="primary"
                          size="large"
                          variant="text"
                          component={Link}
                          style={{ color: COLOR_PRIMARY }}
                          to="/filtros"
                        >
                          Criar audiência
                        </Button>
                      )}

                      {hasCreateCampaign && (
                        <Button
                          color="primary"
                          size="large"
                          variant="text"
                          style={{ color: COLOR_PRIMARY }}
                          onClick={() => this.setState({ importaArquivoAudiencia: true })}
                        >
                          Importar arquivo
                        </Button>
                      )}

                      <Tooltip title="Atualizar">
                        <Button
                          color="primary"
                          size="large"
                          variant="text"
                          style={{ color: COLOR_PRIMARY }}
                          onClick={() => this.props.getListaFiltro()}
                        >
                          <Replay />
                        </Button>
                      </Tooltip>
                    </Box>
                  </div>
                </div>
              </section>
            </div>
            <Divider className={classes.divider} />
            <div
              className={classes.content}
              style={
                this.props.loadingPerformance
                  ? {
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      flexGrow: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : {}
              }
            >
              {key === 4 && (
                <CustomTable
                  title=""
                  columnsButton
                  pageSize={5}
                  paging
                  search
                  headerStyle={{
                    backgroundColor: '#fff',
                    zIndex: 0,
                    color: BG_PRIMARY,
                  }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    backgroundColor: '#fff',
                    color: BG_PRIMARY,
                  }}
                  columns={[
                    { title: 'Nome', field: 'nameList' },
                    {
                      title: 'Ações',
                      field: '',
                      render: rowData => (
                        <SplitButton
                          text={!rowData.processado ? 'Processando' : 'Iniciar Campanha'}
                          label="label"
                          className={classes.margin}
                          disabled={!rowData.processado}
                        >
                          {TIP_CAMPANHA.map(campanha => {
                            if (campanha.label === 'WhatsApp' && hasWhatsapp) {
                              return (
                                <MenuItem
                                  key={campanha.label}
                                  style={{ borderRadius: 10, width: '100%' }}
                                  onClick={() => {
                                    this.setState({
                                      [campanha.state]: true,
                                      ofertas: false,
                                      optionList: {
                                        label: rowData.nameList,
                                        value: rowData.idListProspectei,
                                      },
                                      listaSelecionada: false,
                                    });
                                    if (campanha.label === 'Email')
                                      this.props.contadorEmail({
                                        emails: this.state.selectedEmail.map(opt => opt.value),
                                        optionList: {
                                          label: rowData.nameList,
                                          value: rowData.idListProspectei,
                                        },
                                      });
                                    else {
                                      return null;
                                    }
                                  }}
                                >
                                  {campanha.label}
                                </MenuItem>
                              );
                            } else if (campanha.label === 'WhatsApp' && !hasWhatsapp) {
                              return null;
                            } else {
                              return (
                                <MenuItem
                                  key={campanha.label}
                                  style={{ borderRadius: 10, width: '100%' }}
                                  onClick={() => {
                                    this.setState({
                                      [campanha.state]: true,
                                      ofertas: false,
                                      optionList: {
                                        label: rowData.nameList,
                                        value: rowData.idListProspectei,
                                      },
                                      listaSelecionada: false,
                                    });
                                    if (campanha.label === 'Email')
                                      this.props.contadorEmail({
                                        emails: this.state.selectedEmail.map(opt => opt.value),
                                        optionList: {
                                          label: rowData.nameList,
                                          value: rowData.idListProspectei,
                                        },
                                      });
                                    else {
                                      return null;
                                    }
                                  }}
                                >
                                  {campanha.label}
                                </MenuItem>
                              );
                            }
                          })}
                        </SplitButton>
                      ),
                    },
                  ]}
                  data={listaFiltro}
                  isLoading={isLoadingInsights}
                />
              )}

              {key === 6 && (
                <CustomTable
                  title=""
                  columnsButton
                  pageSize={5}
                  paging
                  search
                  headerStyle={{
                    backgroundColor: '#fff',
                    zIndex: 0,
                    color: BG_PRIMARY,
                  }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    backgroundColor: '#fff',
                    color: BG_PRIMARY,
                  }}
                  columns={[
                    { title: 'Nome', field: 'nome' },
                    { title: 'Codigo', field: 'codigo' },
                    {
                      title: 'Ações',
                      field: '',
                      render: rowData => (
                        <Button
                          variant="text"
                          color="primary"
                          disabled={!rowData.status.connected}
                          className={classes.margin}
                          onClick={() => {
                            this.setState({
                              showModalWhatsApp: true,
                              ofertas: false,
                              listaSelecionada: false,
                              instance: {
                                nome: rowData.nome,
                                id: rowData.id,
                              },
                            });
                          }}
                        >
                          {rowData.status.connected
                            ? 'Iniciar Campanha'
                            : 'Conecte primeiro para iniciar'}
                        </Button>
                      ),
                    },
                    {
                      title: '',
                      field: '',
                      render: rowData => (
                        <Button
                          variant={rowData.status.connected ? 'outlined' : 'contained'}
                          color="primary"
                          onClick={() =>
                            !rowData.status.connected &&
                            this.setState({
                              QRCodeDialogOpen: true,
                              instanciaOnDialog: rowData,
                            })
                          }
                        >
                          {rowData.status.connected ? 'Conectado' : 'QRCode'}
                        </Button>
                      ),
                    },
                  ]}
                  data={listaInstancias}
                />
              )}
            </div>
          </Fragment>
        )}
        {showModalSMS && (
          <NovaCampanhaSMS
            showModal={showModalSMS}
            sms={this.state}
            onSave={() => this.criarCampanhaSMS()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalSMS: false })}
            tipo_usuario={this.state.tipo_usuario}
          />
        )}
        {showModalEmail && (
          <NovaCampanhaEmail
            showModal={showModalEmail}
            email={this.state}
            onSave={() => this.criarCampanhaEmail()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalEmail: false, message: '' })}
          />
        )}
        {showModalCarta && (
          <NovaCampanhaCarta
            showModal={showModalCarta}
            carta={this.state}
            onSave={() => this.criarCampanhaCarta()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalCarta: false })}
          />
        )}
        {showModalRedeSocial && (
          <NovaCampanhaRedeSocial
            showModal={showModalRedeSocial}
            rede={this.state}
            onSave={() => this.criarCampanhaRedeSocial()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalRedeSocial: false })}
          />
        )}

        {showModalWhatsApp && (
          <NovaCampanhaWhatsApp
            showModal={showModalWhatsApp}
            mensagem={this.state}
            instances={this.props.instancias}
            onSave={() => this.criarCampanhaWhatsApp()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() =>
              this.setState({
                showModalWhatsApp: false,
                instance: null,
                optionList: null,
              })
            }
            tabInstances={this.state.key === 6}
          />
        )}
        {showModalPush && (
          <NovaCampanhaPush
            showModal={showModalPush}
            push={this.state}
            onSave={() => this.criarCampanhaPush()}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalPush: false })}
          />
        )}
        {pessoaModal.visible && (
          <ModalPerfil
            openModal={pessoaModal.visible}
            pessoa={pessoaModal.pessoa}
            onClose={() => this.props.modalPessoa(false, {})}
          />
        )}
        {this.state.QRCodeDialogOpen && (
          <QRCodeDialog
            open={this.state.QRCodeDialogOpen}
            instancia={this.state.instanciaOnDialog}
            onClose={() => this.toggleQRCodeDialog()}
            reloadQrCode={this.props.reloadQrCode}
          />
        )}

        <ImportaArquivoAudiencia
          open={importaArquivoAudiencia}
          onClose={() => this.setState({ importaArquivoAudiencia: false })}
          onSave={arquivo => this.props.postArquivoAudienciaCompleto(arquivo)}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({
  RoutesReducer,
  InsightsReducer,
  PerformanceReducer,
  CampaignReducer,
  RecomendacoesReducer,
  OfertasReducer,
  ChatbotReducer,
}) => ({
  ...PerformanceReducer,
  ...CampaignReducer,
  ...RoutesReducer,
  ...InsightsReducer,
  ...RecomendacoesReducer,
  ...OfertasReducer,
  ...ChatbotReducer,
  listaInstancias: ChatbotReducer.instancias,
});

const mapDispatchToProps = {
  getListaSMSById,
  showFilter,
  setFiltro,
  changeFiltro,
  clearFilter,
  getListaFiltro,
  getPeopleProspecteiPF,
  changeFiltroSpread,
  saveListProspecsPF,
  getListaFiltroCSV,
  getListaFiltroFacebookCSV,
  getListaFiltroLinkedinCSV,
  savePromocao,
  changePerformance,
  filtroConsulta,
  excluirFiltro,
  getCabecalhoProspects,
  getInstancesStatusWhatsApp,
  envioCampanhaSMS,
  envioCampanhaWhatsApp,
  envioCampanhaEmail,
  envioCampanhaCarta,
  envioCampanhaPush,
  envioCampanhaRedeSocial,
  salvarTemplate,
  modalPessoa,
  getTodasPerformanceMes,
  getPessoasByIDCampanha,
  getTodasPerformancePeriodo,
  setCampanha,
  getRecomedacoes,
  contadorEmail,
  getInstancesWhatsApp,
  getTabloides,
  getForms,
  getInstancias,
  getStatusInstancia,
  postArquivoAudienciaCompleto,
  reloadQrCode,
};

const PerformanceConnect = connect(mapStateToProps, mapDispatchToProps)(Performance);

export default withStyles(styles)(PerformanceConnect);
