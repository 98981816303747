import Fetch from "../../../utils/helpers/Fetch";

const loadingPerformance = () => dispatch =>
  dispatch({ type: "LOADING_PERFORMANCE" });

export const getPerformanceTotal = request => dispatch => {
  dispatch(loadingPerformance());
  Fetch(
    `https://a001l2pgal.execute-api.us-east-1.amazonaws.com/dev/crm/dashboardData`,
    request
  ).then(payload => {
    dispatch({
      type: "PERFORMANCE",
      payload: payload
    });
  });
};
