import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import ContactIcon from '@material-ui/icons/Domain';
import Table from 'material-table';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

function Contacts({ siteId }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContacts = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/contacts`,
        { site_id: siteId }
      );

      setData(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleRemoveContact = useCallback(
    async id => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/contacts/${id}`,
          {},
          'DELETE'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getContacts();
      }
    },
    [getContacts]
  );

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <ContactIcon />
            </Avatar>
          }
          title="Contatos"
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              { title: 'Nome', field: 'name' },
              { title: 'Telefone', field: 'phone_number' },
              { title: 'E-mail', field: 'email' },
              { title: 'Observações', field: 'comments' },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            actions={[
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleRemoveContact(rowData.id);
                },
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={localization}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default Contacts;
