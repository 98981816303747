import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import MapClusterer from './MapClusterer';
import Heatmap from './HeatMap';

let lat = -22.9058338,
  lng = -47.0663981;
navigator?.geolocation?.getCurrentPosition(resp => {
  lat = resp.coords.latitude;
  lng = resp.coords.longitude;
});

const typeMaps = props => {
    switch (props.typeMap?.toUpperCase()) {
      case 'CLUSTER':
        return <MapClusterer {...props} />;
      case 'HEAT':
        return <Heatmap {...props} />;
      default:
        return <span>Erro ao gerar o mapa.</span>;
    }
  },
  Maps = compose(
    withProps({
      googleMapURL:
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLZixBmYAI90rcKSG_8MFWKAidmRcvFQU&libraries=visualization,geometry,drawing,places',
      loadingElement: <div style={{ height: `100%`, borderRadius: 10 }} />,
      containerElement: <div style={{ height: `400px`, borderRadius: 10 }} />,
      mapElement: <div style={{ height: `100%`, borderRadius: 10 }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap
      defaultZoom={props.defaultZoom || 4}
      defaultCenter={{
        lat: parseFloat(props.lat) || lat,
        lng: parseFloat(props.lng) || lng,
      }}
    >
      {typeMaps(props)}
    </GoogleMap>
  ));

export default Maps;
