import { AutoFixHighOutlined } from '@mui/icons-material';
import { Button, MenuItem, Select, TextField, Box, Grid, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { setFrequenceScheduledPosts } from 'modules/TabloideDigitalV2/action';
import { Days, FrequenceScheduledPosts, TypeRepeat } from 'modules/TabloideDigitalV2/reducer/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from 'store/types';
import * as yup from 'yup';
import { NumberSchema, ObjectSchema } from 'yup';
import { findAutoMessage } from '../../../action';
import { SelectDays } from './SelectDays';
import { useStyles } from './styles';

export const FREQUENCE_POSTS_VALIDATION = yup.object().shape({
  amountRepeat: yup.number().min(1).max(20).required(),
  typeRepeat: yup.object().shape({ children: yup.string(), value: yup.number() }).required(),
  hour: yup.date().required(),
  weeklyDaysPublish: yup
    .object()
    .shape({ children: yup.string(), value: yup.number() })
    .when('typeRepeat', {
      is: typeRepeat => {
        return typeRepeat.value === 2;
      },
      then: (schema: ObjectSchema) => {
        return schema.required();
      }
    }),
  monthlyDayPublish: yup
    .number()
    .nullable()
    .when('typeRepeat', {
      is: typeRepeat => {
        return typeRepeat.value === 3;
      },
      then: (schema: NumberSchema) => {
        return schema.min(1).max(31);
      }
    })
});

export function FrequencePosts() {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { frequenceScheduledPosts, selectedScheduledItems, auto_message, loading_auto_message } =
    useSelector((state: StateProps) => state.TabloideDigitalReducer);

  function handleSubmit(values: FrequenceScheduledPosts) {
    dispatch(setFrequenceScheduledPosts(values));
  }

  const handleChangeAutoMessage = useCallback(() => {
    dispatch(
      //@ts-ignore
      findAutoMessage({ background_id: selectedScheduledItems?.[0]?.background_id, channel: 'all' })
    );
  }, [selectedScheduledItems?.[0]?.background_id, dispatch]);

  const INITIAL_FORMIK_DATA = useMemo(() => {
    return {
      amountRepeat: frequenceScheduledPosts.amountRepeat || 1,
      monthlyDayPublish: frequenceScheduledPosts.monthlyDayPublish || null,
      typeRepeat:
        frequenceScheduledPosts.typeRepeat || ({ children: 'Dias', value: 1 } as TypeRepeat),
      hour: frequenceScheduledPosts.hour || new Date(),
      weeklyDaysPublish: frequenceScheduledPosts.weeklyDaysPublish || ({} as Days),
      postsRepeat: frequenceScheduledPosts.postsRepeat || 0,
      descriptions: frequenceScheduledPosts.descriptions || ''
    };
  }, [frequenceScheduledPosts]);

  useEffect(() => {
    formik.setFieldValue('descriptions', auto_message.all);
  }, [auto_message.all]);

  const formik = useFormik({
    initialValues: INITIAL_FORMIK_DATA,
    validationSchema: FREQUENCE_POSTS_VALIDATION,
    isInitialValid: false,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  const hasValidationError =
    !!formik.errors.amountRepeat ||
    !!formik.errors.hour ||
    !!formik.errors.monthlyDayPublish ||
    !!formik.errors.typeRepeat ||
    !!formik.errors.weeklyDaysPublish;

  return (
    <Grid className={styles.gridGlobal} sx={{ flexDirection: 'column' }}>
      <Grid className={styles.gridTypography} sx={{ flexDirection: 'column' }}>
        <Typography className={styles.title}>Escolha a frequência das publicações:</Typography>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid sx={{ flexDirection: 'column' }} className={styles.gridContent}>
          <Grid sx={{ display: 'flex', gap: '2rem' }}>
            <Grid className={styles.gridDateTime}>
              {!(formik.values.typeRepeat.value === 3) && (
                <Box>
                  <Typography className={styles.labels} marginBottom={'8px'}>
                    Data de Início
                  </Typography>
                  <DatePicker
                    className={styles.PickerDateTime}
                    value={formik.values.hour}
                    disablePast={true}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    onChange={(date, keyboardInput) => {
                      console.log('Date', date);
                      formik.setFieldValue('hour', date);
                    }}
                    renderInput={params => <TextField {...params} />}
                    InputAdornmentProps={{ position: 'start' }}
                  />
                </Box>
              )}

              <Box>
                <Typography className={styles.labels} marginBottom={'8px'}>
                  Hora
                </Typography>
                <TimePicker
                  className={styles.PickerDateTime}
                  ampm={false}
                  value={formik.values.hour}
                  onChange={newValue => {
                    formik.setFieldValue('hour', newValue);
                  }}
                  renderInput={params => <TextField {...params} />}
                  InputAdornmentProps={{ position: 'start' }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid className={styles.gridScheduleEvery} flexDirection={'column'}>
            <Typography className={styles.labels} marginBottom={'8px'}>
              Agendar a cada
            </Typography>
            <Box className={styles.boxTypeRepeat}>
              <TextField
                type="number"
                value={formik.values.amountRepeat}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('amountRepeat', parseInt(value));
                }}
                sx={{
                  width: '3.5rem',
                  '&.MuiTextField-root,.MuiOutlinedInput-input': {
                    background: '#FFF'
                  }
                }}
              />
              <Select
                labelId="demo-simple-select-label"
                className={styles.autoCompleteRepeat}
                id="demo-simple-select"
                value={formik.values.typeRepeat.value}
                onChange={(e, dataSelected: any) => {
                  formik.resetForm();
                  formik.setFieldValue('typeRepeat', {
                    children: dataSelected.props.children,
                    value: parseInt(dataSelected.props.value)
                  });
                  if (dataSelected.props.value !== 3) {
                    formik.setFieldValue('monthlyDayPublish', undefined);
                  } else {
                    formik.setFieldValue('monthlyDayPublish', 1);
                  }
                }}
                sx={{
                  '&.MuiSelect-outlined,.MuiOutlinedInput-input': {
                    background: '#FFF'
                  }
                }}
              >
                <MenuItem value={1}>Dias</MenuItem>
                <MenuItem value={2}>Semanas</MenuItem>
                <MenuItem value={3}>Mês</MenuItem>
              </Select>
              {formik.values.typeRepeat.value === 2 ? (
                <SelectDays setFieldValue={formik.setFieldValue} values={formik.values} />
              ) : formik.values.typeRepeat.value === 3 ? (
                <Grid className={styles.gridRepeatMonth}>
                  <Typography className={styles.labels}>Publicar no dia</Typography>
                  <TextField
                    type="number"
                    sx={{
                      width: '2.975rem',
                      '&.MuiTextField-root,.MuiOutlinedInput-input': {
                        background: '#FFF'
                      }
                    }}
                    variant="outlined"
                    value={formik.values.monthlyDayPublish}
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue('monthlyDayPublish', parseInt(value));
                    }}
                  />
                  <Typography className={styles.labels}>dos meses selecionados</Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Box
            component={'form'}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '787px' }
            }}
          >
            <Box component="div" width="100%" display="flex" justifyContent="flex-end">
              <Button
                onClick={() => {
                  handleChangeAutoMessage();
                }}
                disabled={loading_auto_message}
                color="primary"
                variant="text"
                size="small"
                startIcon={<AutoFixHighOutlined />}
              >
                {loading_auto_message ? 'Digitando ...' : 'Outra sugestão de mensagem'}
              </Button>
            </Box>
            <TextField
              id="hyago"
              value={formik.values.descriptions}
              multiline={true}
              maxRows={4}
              sx={{
                '&.MuiInputBase-root,.MuiOutlinedInput-root': {
                  background: '#FFF',
                  height: '136px'
                }
              }}
              variant="outlined"
              label="Adicione uma descrição para todas as postagens"
              onChange={(evt: any) => {
                const value = evt.target.value;
                formik.setFieldValue('descriptions', value);
              }}
            />
          </Box>
          <Button
            sx={{ width: '188px', height: '44px' }}
            variant="contained"
            color="info"
            type="submit"
            disabled={hasValidationError}
          >
            Atualizar informações
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}

