import { AppBar, Backdrop, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSnackbar } from 'notistack';

import DSAWS from '../../../../utils/helpers/DSAWS';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99999,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  appBar: { background: 'transparent' },
  img: {
    height: '80%',
  },
  title: {
    flex: 1,
  },
}));

const formatDateTime = (dateTimeString) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
  return new Date(dateTimeString).toLocaleString('pt-BR', options);
};

const ProductList = ({ open, imageName, imageUrl, imageUrlToDownload, onClose, imageDataTime }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadImage = async () => {
    enqueueSnackbar('Baixando...', { variant: 'info', autoHideDuration: null });

    DSAWS.getObjectGeral({
      link: imageUrlToDownload,
    })
      .then(response => {
        var link = document.createElement('a');
        link.href = response;
        link.download = `${imageName}.${
          imageUrl.split('?')[0].split('.')[imageUrl.split('?')[0].split('.').length - 1]
        }`;

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch(error => {
        console.log({ error });
      });
  };

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="body1">
            {imageName} Criada em: {formatDateTime(imageDataTime)}
          </Typography>
          <IconButton color="inherit" onClick={handleDownloadImage}>
            <GetAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <img className={classes.img} src={imageUrl} alt={imageName} />
    </Backdrop>
  );
};

export default ProductList;
