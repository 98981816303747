import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 3, 3),
    maxWidth: '100%',
    overflow: 'hidden',
  },
  titleTypography: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    border: 'none',
    backgroundColor: '#FFF',
  },
  paperPaymentForm: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    border: '1px solid #B4B4B4',
  },
  paperPayments: {
    marginTop: theme.spacing(3),
    border: '1px solid #B4B4B4',
    maxHeight: '40vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  iconPaymentForm: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(2, 0.3),
    border: '1px solid #B4B4B4',
    borderRadius: 5,
    maxWidth: '5vw',
  },
  tableRowOdd: {
    border: 'none',
    fontWeight: 500,
    fontWize: '16px',
    lineHeight: '24px',
    letterSpacing: '.05em',
  },
  tableRowPair: {
    border: 'none',
    fontWeight: 500,
    fontWize: '16px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    backgroundColor: '#F5F5F5;',
  },
}));

export default useStyles;
