import {
  AppBar,
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import React, { memo } from 'react';
import useStyles from './styles';

function Preview({ colors }) {
  const classes = useStyles(colors);

  return (
    <Box className={classes.root}>
      <AppBar className={classes.appBar} position="relative" disableElevation>
        <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Busque por um ou mais produtos"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <IconButton className={classes.shareButton}>
            <ShareIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box paddingY={2}>
        <Typography
          className={classes.description}
          variant="body1"
          align="center"
        >
          DESCRIÇÃO
        </Typography>
      </Box>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          variant="scrollable"
          value={0}
          scrollButtons={false}
          TabIndicatorProps={{
            style: { backgroundColor: colors.description },
          }}
        >
          <Tab className={classes.tab} label="Categoria 1" />
          <Tab className={classes.tab} label="Categoria 2" />
          <Tab className={classes.tab} label="Categoria 3" />
        </Tabs>
      </AppBar>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Paper className={classes.paper} style={{ height: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="body1"
                      className={classes.discountText}
                    >
                      DESCONTO
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.productName}
                        >
                          NOME
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.price}
                          variant="body2"
                          color="error"
                        >
                          PREÇO
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.discountPrice}
                          variant="body1"
                          color="secondary"
                        >
                          PREÇO DOIS
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <img
                        className={classes.img}
                        alt="preview"
                        src="https://static.thenounproject.com/png/220984-200.png"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item container justify="flex-end" xs={12}>
                    <Grid item>
                      <IconButton color="secondary">
                        <FavoriteBorderIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default memo(Preview);
