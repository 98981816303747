import React, { Component } from 'react';
import { MyButton } from './utils/components';
import Fetch from './utils/helpers/Fetch';
import App from './App';
import ExpirationVerify from './utils/helpers/ExpirationVerify';

export default class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentError: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (
      !ExpirationVerify({
        showAlert: false,
        clearAndRedirect: false,
        msg: ' (Catch)',
      })
    ) {
      this.setState({
        currentError: error,
        errorInfo,
      });

      try {
        Fetch(
          'https://api.datasales.info/lista-ofertas-p1-dev/post-error-data',
          { error, errorInfo },
          'POST',
          null,
          null,
          false
        );
      } catch {}
    }
  }

  render() {
    if (this.state.currentError) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            position: 'absolute',
            top: '28vh',
            left: '28vw',
          }}>
          <h1>Desculpe!</h1>
          <p>
            Um erro ocorreu na sua requisição, por favor tente novamente ou entre em contato com
            nosso suporte.
          </p>
          <section>
            <MyButton bsstyle="primary" onClick={() => window.location.assign('/')}>
              Voltar
            </MyButton>
          </section>
        </div>
      );
    }
    return <App />;
  }
}
