import React from 'react';
import Countup from 'react-countup';
import { CircularProgress } from '@material-ui/core/';
import { COLOR_PRIMARY } from '../../helpers/Constants';

export default props =>
  props.isLoading ? (
    <CircularProgress style={{ color: COLOR_PRIMARY }} size={20} />
  ) : (
    <Countup
      suffix={props.suffix}
      prefix={props.prefix}
      start={props.start}
      end={props.end}
      duration={props.duration}
      separator={props.separator || '.'}
      decimal={props.decimal || ','}
      decimals={props.decimals}
    />
  );
