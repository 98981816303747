import { Box, Icon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLayoutEffect, useState } from 'react';
import { Facebook, Instagram } from '@mui/icons-material';

const Iframe = styled('iframe')({});

interface IframeProps {
  socialNetwork: ('Facebook' | 'Instagram'),
  type: ('reels' | 'stories'),
  iframeStringMeta: string;
}

export const IframePreviewAds = ({ iframeStringMeta, socialNetwork, type }: IframeProps) => {

  const [urlIframe, setUrlIframe] = useState<string>('');

  useLayoutEffect(()=>{
    if(iframeStringMeta.includes('iframe')){
      const iframe = window.document.createElement('div');
      iframe.innerHTML = iframeStringMeta; 
      const url = iframe.querySelector('iframe')!.getAttribute('src')!;
      setUrlIframe(url);
    }
  }, [iframeStringMeta]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          margin: '6px',
          gap: '4px',
          alignItems: 'center'
        }}
      >
        {socialNetwork === 'Facebook' ? <Facebook /> : <Instagram />}
        <Box>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '12px',
              color: '#7c7c7c'
            }}
          >
            {socialNetwork}
          </Typography>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '14px'
            }}
          >
            {type === 'reels' ? 'Reels' : 'Stories' }  
          </Typography>
        </Box>
      </Box>
      
      <Iframe 
        scrolling={'no'}
        sx={{
          height: '570px',
          width: '330px',
          border: 'none',
          borderRadius: '20px'
        }}
        src={urlIframe ? urlIframe : ''}
      />
    </Box>
  );
};

