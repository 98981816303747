import Socket from '../../../utils/helpers/Socket';
import Fetch from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import { WS_CHAT } from '../../../utils/helpers/Constants';
import { LOAD_USERS } from '../types';

let wss = {};

export function closeConnection() {
  if (
    Boolean(wss.readyState) &&
    (wss.readyState === 1 || wss.readyState === 0)
  ) {
    wss.close();
    wss = {};
  }
}

export function openConnection(cb) {
  if (Boolean(wss.readyState) && wss.readyState === 1) {
    return cb(wss.readyState === 1 ? true : false, wss.readyState, wss);
  } else {
    const socket = new Socket(WS_CHAT);
    wss = socket.ws();
    wss.onopen = () =>
      cb(wss.readyState === 1 ? true : false, wss.readyState, wss);
  }
}
export const getUsers = () => dispatch => {
    Fetch(
      `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/users`
    ).then(payload => dispatch({ type: LOAD_USERS, payload }));
  },
  persistConnection = (route = 'persist-connection') => {
    const objPayload = { route },
      payload = JSON.stringify(objPayload);
    wss.send(payload);
  },
  isConnected = client =>
    Fetch(`${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/zapi/is-connected`, {
      client,
    }),
  listContacts = instance_id =>
    Fetch(`${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/contacts/list`, {
      id: instance_id,
      status: 1,
    }),
  createContact = contact =>
    Fetch(
      `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/contacts/create`,
      contact,
      'POST'
    ),
  updateContact = contact =>
    Fetch(
      `${DATASALES_CRM_API}whatsapp-v2-${AMBIENTE}/contacts/update`,
      contact,
      'PUT',
      null,
      null,
      false
    ),
  listChats = (instance_id, user, manual_service) =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/chats/list`,
      {
        instance_id,
        user,
        manual_service,
      },
      'GET',
      null,
      null,
      true,
      {
        title: 'Seu WhatsApp não está conectado!',
        message:
          'Para usar o chat, reconecte o seu WhatsApp. Clique no botão "Reconectar WhatsApp" abaixo e saiba como.',
        cancelButtonText: 'Deixar para depois',
        confirmButtonText: 'Reconectar WhatsApp',
        confirmButtonOpenLinkOnNewTab:
          'https://datasales.stonly.com/kb/guide/pt/5-conectando-o-whatsapp-Ig5h1qw60i/Steps/919489',
      }
    ),
  listMessages = (instance_id, phone, lastId) =>
    Fetch(`${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/messages/list`, {
      instance_id,
      phone,
      lastId,
    }),
  readMessage = message =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/messages/read`,
      message,
      'POST',
      null,
      null,
      false
    ),
  setAttendant = attendant =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/chats/setChatAttendant`,
      attendant,
      'POST',
      null,
      null,
      false
    ),
  sendText = message =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/send/text`,
      message,
      'POST',
      null,
      null,
      false
    ),
  sendAudio = audio =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/send/audio`,
      audio,
      'POST',
      null,
      null,
      false
    ),
  sendImage = image =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/send/image`,
      image,
      'POST',
      null,
      null,
      false
    ),
  sendVideo = video =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/send/video`,
      video,
      'POST',
      null,
      null,
      false
    ),
  receiveMessage = message =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/messages/list/${message}`
    ),
  endTicket = ticket =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/tickets/finalize`,
      ticket,
      'POST',
      null,
      null,
      false
    ),
  transferTicket = ticket =>
    Fetch(
      `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/tickets/tickettransfer`,
      ticket,
      'POST',
      null,
      null,
      false
    );
