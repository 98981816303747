import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import axios from 'axios';
import { Autocomplete, Radios, Select, TextField, makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useAds } from '../../../../../../hooks/ads';
import DialogTitle from '../../../../../../utils/components/DialogTitle';
import useStyles from './styles';
import api from '../../../../../../utils/helpers/Fetch';
import * as Yup from 'yup';

const genres = [
  { label: 'Todos', value: '0' },
  { label: 'Homens', value: '1' },
  { label: 'Mulheres', value: '2' },
];

const ages = new Array(53).fill(null).map((_, i) => ({
  label: i === 52 ? '65 +' : `${13 + i}`,
  value: 13 + i,
}));

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  targeting: Yup.object().shape({
    age_min: Yup.number().required(),
    age_max: Yup.number().required(),
    geo_locations: Yup.object()
      .shape({
        cities: Yup.array().of(
          Yup.object().shape({
            key: Yup.number('').required(''),
            radius: Yup.string().required(''),
            distance_unit: Yup.string().required(''),
          })
        ),
      })
      .required(''),
  }),
});

function CreateAudience({ open, refreshAudiences, onClose }) {
  const classes = useStyles();

  const theme = useTheme();

  const { user } = useAds();

  const [initialValues] = useState({
    targeting: {
      age_min: 13,
      age_max: 65,
      geo_locations: {
        cities: [{ key: undefined, radius: 20, distance_unit: 'kilometer' }],
      },
    },
  });

  const [locations, setLocations] = useState([]);

  const handleSubmit = useCallback(async values => {
    try {
      await api(
        'https://api.datasales.info/ads-manager-dev/audiences',
        values,
        'POST'
      );

      refreshAudiences();
      onClose();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSearchLocations = useCallback(async event => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v10.0/search`,
        {
          params: {
            access_token: user.access_token,
            location_types: ['city'],
            type: 'adgeolocation',
            limit: 500,
            region_id: 460,
            q: event.target.value,
          },
        }
      );

      const { data } = response.data;

      setLocations(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <Form
        initialValues={initialValues}
        validate={makeValidate(schema)}
        onSubmit={handleSubmit}
      >
        {form => (
          <form onSubmit={form.handleSubmit} noValidate>
            <DialogTitle onClose={onClose}>Criar Público</DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField name="name" variant="outlined" label="Nome" />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>Localizações</Typography>
                </Grid>

                <Grid item xs={10}>
                  <Autocomplete
                    label="Cidades"
                    name="targeting.geo_locations.cities[0].key"
                    textFieldProps={{ variant: 'outlined' }}
                    options={locations}
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.name}
                    noOptionsText="Sem opções"
                    onInputChange={handleSearchLocations}
                    renderOption={option => (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                          <span style={{ fontWeight: 500 }}>
                            {option.name} - {option.region.split('(')[0]}
                          </span>
                          <Typography variant="body2" color="textSecondary">
                            {option.type === 'city' ? 'cidade' : 'bairro'}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="targeting.geo_locations.cities[0].radius"
                    variant="outlined"
                    label="Raio KM"
                    inputProps={{ type: 'number' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>Gênero</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Radios
                    name="targeting.gender"
                    radioGroupProps={{ row: true }}
                    label=""
                    data={genres}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>Idade</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select name="targeting.age_min" variant="outlined">
                    {ages.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select name="targeting.age_max" variant="outlined">
                    {ages.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button>cancelar</Button>
              <Button type="submit">
                {form.submitting ? (
                  <CircularProgress
                    size={theme.typography.subtitle1.fontSize}
                  />
                ) : (
                  'salvar'
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
}

CreateAudience.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateAudience;
