import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";
import BarChartIcon from "@material-ui/icons/BarChart";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { CountCard, Panel } from "../../../../utils/components";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
}));

const TabRelatorio = props => {
  const { loading, totalGeral, calendarios } = props;
  const classes = useStyles(props);

  const countCardProps = {
    count: true,
    style: { minWidth: 250 },
    loading: loading,
    titleTypographyProps: { variant: "h6" }
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Panel
          avatar={
            <Avatar classes={{ root: classes.avatar }}>
              <BarChartIcon />
            </Avatar>
          }
          title="Totais Gerais"
        >
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <CountCard
                {...countCardProps}
                end={totalGeral.total_mensagens}
                title="Mensagens totais"
                icon="email"
              />
            </Box>
            <Box p={1}>
              <CountCard
                {...countCardProps}
                end={totalGeral.total_recebidas}
                title="Mensagens recebidas"
                icon="check_circle"
              />
            </Box>
            <Box p={1}>
              <CountCard
                {...countCardProps}
                end={totalGeral.total_respondidas}
                title="Mensagens respondidas"
                icon="reply"
              />
            </Box>
            <Box p={1}>
              <CountCard
                {...countCardProps}
                end={totalGeral.total_confirmadas}
                title="Mensagens confirmadas"
                icon="done"
              />
            </Box>
            <Box p={1}>
              <CountCard
                {...countCardProps}
                end={totalGeral.total_lidas}
                title="Mensagens lidas"
                icon="done_all"
              />
            </Box>
          </Box>
        </Panel>
      </Grid>
      <Box pt={3} pb={3}>
        <Divider />
      </Box>
      {calendarios.map((calendario, index) => {
        const { totais } = calendario;
        return (
          <Grid item key={index}>
            <Panel
              avatar={
                <Avatar classes={{ root: classes.avatar }}>
                  <InsertInvitationIcon />
                </Avatar>
              }
              title={`Calendário: ${calendario.titulo}`}
            >
              <Box display="flex" justifyContent="center">
                <Box p={1}>
                  <CountCard
                    {...countCardProps}
                    end={totais.total_mensagens || 0}
                    title="Mensagens totais"
                    icon="email"
                  />
                </Box>

                <Box p={1}>
                  <CountCard
                    {...countCardProps}
                    end={totais.total_recebidas || 0}
                    title="Mensagens recebidas"
                    icon="check_circle"
                  />
                </Box>
                <Box p={1}>
                  <CountCard
                    {...countCardProps}
                    end={totais.total_respondidas || 0}
                    title="Mensagens respondidas"
                    icon="reply"
                  />
                </Box>
                <Box p={1}>
                  <CountCard
                    {...countCardProps}
                    end={totais.total_confirmadas || 0}
                    title="Mensagens confirmadas"
                    icon="done"
                  />
                </Box>
                <Box p={1}>
                  <CountCard
                    {...countCardProps}
                    end={totais.total_lidas || 0}
                    title="Mensagens lidas"
                    icon="done_all"
                  />
                </Box>
              </Box>
            </Panel>
          </Grid>
        );
      })}
    </Grid>
  );
};

TabRelatorio.propTypes = {
  loading: PropTypes.bool,
  totalGeral: PropTypes.object.isRequired,
  calendarios: PropTypes.array.isRequired
};

TabRelatorio.defaultProps = {
  loading: false,
  totalGeral: {},
  calendarios: []
};

export default TabRelatorio;
