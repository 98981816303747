import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { StateProps } from '../../../../../../store/types';
// import SiteIcon from '../../../../../../assets/icons/marketing/Site.svg';
// import CellphoneIcon from '../../../../../../assets/icons/marketing/Cellphone.svg';
import { useStyles } from '../../styles';
import { ChannelsProps } from '../../utils/types';
import ButtonChannel from './components/ButtonChannel';

type Props = {
  onChange: (idChannel: number, isSelected: boolean) => void;
  channels: ChannelsProps[];
};

const WhereToDistribute: React.FC<Props> = ({ onChange, channels }) => {
  const classes = useStyles();
  const selectedShareableItem = useSelector(
    (state: StateProps) => state.TabloideDigitalReducer.selectedShareableItem
  );
  const [, updateState] = useState<object>();

  const forceUpdate = useCallback(() => updateState({}), []);
  const onSelectChannel = useCallback((isSelected: boolean, idChannel: number) => {
    onChange(idChannel, isSelected);
    forceUpdate();
  }, []);

  useEffect(() => {
    let newOptions = channels;
    if (selectedShareableItem.length > 1) {
      const isPost = selectedShareableItem.find((item: any) => item.type === 'Post');
      newOptions = channels.map(option => {
        return {
          ...option,
          disabled:
            option.title === 'SMS' ? true : isPost ? false : option.title === 'Redes sociais'
        };
      });
    }
    forceUpdate();
  }, [selectedShareableItem]);

  return (
    <Grid item xs={12} >
      <Typography classes={{ root: classes.typographyTitle }}>
        Para onde você deseja distribuir essas imagens?
      </Typography>

      <Grid className={classes.gridContentBtn} container spacing={2}>
        {channels.map((option, index) => (
          <Grid
            key={index.toString()}
            style={{ display: 'flex', flexDirection: 'column' }}
            xs={12}
            item
          >
            <ButtonChannel
              onSelected={checked => onSelectChannel(checked, index)}
              selected={option.selected}
              title={option.title}
              name={option.name}
              channels={option.channels}
              disabled={option.disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default WhereToDistribute;

