import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localization from '../../../../localization/table';
import api from '../../../../utils/helpers/Fetch';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import CreateAccount from './components/CreateAccount';
import ConnectAccount from './components/ConnectAccount';
import EditAccount from './components/EditAccount';
import useStyles from './styles';

function SocialNetworks() {
  const classes = useStyles();

  const [profiles, setProfiles] = useState();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editIsOpen, setEditOpen] = useState(false);

  const [isConnectAccountOpen, setIsConnectAccountOpen] = useState(false);

  const history = useHistory();

  const getProfiles = async () => {
    try {
      setLoading(true);
      const response = await api(
        `https://api.datasales.info/social-networks-v2-${AMBIENTE}/pages`
      );

      setProfiles(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);

  const handleSubmit = async values => {
    setOpen(false);
    getProfiles();
  };

  const handleUpdate = async values => {
    try {
      await api(
        `https://wtx4z9hj2e.execute-api.us-east-1.amazonaws.com/prd/profile/${values.id}`,
        values,
        'PUT'
      );
    } catch (err) {
      console.log(err);
    } finally {
      setEditOpen(false);
      getProfiles();
    }
  };

  const handleDelete = async (page_id, fb_user_id) => {
    try {
      await api(
        `https://api.datasales.info/social-networks-v2-${AMBIENTE}/pages`,
        {
          page_id,
          fb_user_id,
          active: 0,
        },
        'PUT'
      );
    } catch (err) {
      console.log(err);
    } finally {
      getProfiles();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container direction="column" style={{padding: '0 2rem'}}>
        <Grid item>
          {/*<Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>*/}
        </Grid>
        <Grid item>
          <Box pt={4}>
            <Typography variant="h3" noWrap>
              Contas
            </Typography>
          </Box>
        </Grid>
        <Grid item container justify="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleOpen}
            endIcon={<AddIcon />}
          >
            Adicionar conta
          </Button>
        </Grid>

        <Grid item>
          <Box pt={4} pb={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid item>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>
                  <PeopleAltIcon />
                </Avatar>
              }
              title="Contas"
            />
            <Divider />
            <CardContent className={classes.content}>
              <Table
                title=""
                isLoading={loading}
                columns={[
                  {
                    title: 'Nome da página',
                    field: 'page_name',
                    render: rowData =>
                      rowData.page_name +
                      (rowData.instagram_username
                        ? ' (Instagram: ' + rowData.instagram_username + ')'
                        : ''),
                  },
                ]}
                data={profiles || []}
                actions={[
                  // {
                  //   icon: 'language',
                  //   tooltip: 'Ativar contas',
                  //   onClick: (e, rowData) => {
                  //     setIsConnectAccountOpen(true);
                  //   },
                  // },
                  // {
                  //   icon: 'edit',
                  //   tooltip: 'Editar conta',
                  //   onClick: (e, rowData) => {
                  //     setProfile(rowData);
                  //     setEditOpen(true);
                  //   },
                  // },
                  {
                    icon: 'delete',
                    tooltip: 'Remover conta',
                    onClick: (e, rowData) => {
                      handleDelete(rowData.page_id, rowData.fb_user_id);
                    },
                  },
                ]}
                options={{ actionsColumnIndex: -1 }}
                localization={localization}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {open && (
        <CreateAccount
          open={open}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}

      {isConnectAccountOpen && (
        <ConnectAccount
          open={isConnectAccountOpen}
          onSubmit={handleSubmit}
          onClose={() => {
            setIsConnectAccountOpen(false);
          }}
        />
      )}

      {editIsOpen && (
        <EditAccount
          open={editIsOpen}
          values={profile}
          onSubmit={handleUpdate}
          onClose={() => {
            setProfile(undefined);
            setEditOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default SocialNetworks;
