import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { getPessoas, savePessoa, editPessoa } from "../actions/index";
import { listaTodasProfissao } from "../../Insights/actions/actions";
import PesquisaCEP from "../../../utils/helpers/CEP";
import {
  Button,
  Icon,
  withStyles,
  TextField,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { CustomTable } from "../../../utils/components";
import { COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import { CPF } from "../../../utils/helpers/Masks";
import ModalAddPessoa from "./ModalAddPessoa";
import ModalEdit from "./ModalEditPessoa";
import { ESCOLARIDADE, ESTADO_CIVIL } from "../../../utils/helpers/Constants";

class Pessoas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEdit: false,
      documento: "",
      nome: "",
      pessoa: { documento: "" }
    };

    this.onInputChange = this.onInputChange.bind(this);
  }
  componentDidMount() {
    this.props.getPessoas();
    this.props.listaTodasProfissao();
  }

  onInputChange(state, value) {
    this.setState({ pessoa: { ...this.state.pessoa, [state]: value } });
  }

  filterData(type) {
    const { nome, documento } = this.state;
    if (type === "cpf" && CPF.length > 0) {
      this.props.getPessoas({ documento });
    } else if (type === "nome" && nome.length > 0) {
      this.props.getPessoas({ nome });
    } else {
      this.props.getPessoas();
    }
  }

  render() {
    const { classes } = this.props;
    const { showModal, showEdit } = this.state;

    return (
      <div className={classes.root}>
        <Link to="/database" style={{ textDecoration: "none" }}>
          <Button
            size="medium"
            style={{ marginBottom: "1rem", color: COLOR_PRIMARY }}
            color="primary"
          >
            <Icon>arrow_back_ios</Icon>Voltar
          </Button>
        </Link>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Pessoas</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              margin="none"
              style={{ marginRight: 20 }}
              size="md"
              placeholder="Pesquisar  pelo nome"
              autoComplete="false"
              value={this.state.nome}
              onChange={event => this.setState({ nome: event.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.filterData("nome")}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              variant="outlined"
              margin="none"
              style={{
                marginRight: 20,
                "&:focus": { borderColor: COLOR_PRIMARY }
              }}
              size="md"
              placeholder="Pesquisar  pelo cpf"
              autoComplete="false"
              value={CPF(this.state.documento)}
              onChange={event =>
                this.setState({ documento: event.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.filterData("cpf")}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY }}
              onClick={() =>
                this.setState({ showModal: true, pessoa: { documento: "" } })
              }
            >
              Adicionar pessoa
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          <CustomTable
            title=""
            actions={[
              {
                icon: "edit",

                tooltip: "Editar",
                onClick: (event, rowData) => {
                  this.setState(
                    {
                      pessoa: {
                        ...rowData,
                        id_profissao: rowData.id_profissao
                          ? {
                              value: rowData.id_profissao,
                              label: this.props.listTodasProfissao.filter(
                                profissao =>
                                  profissao.id_pessoa_profissao ===
                                  rowData.id_profissao
                              )[0].descricao
                            }
                          : null,
                        id_escolaridade: rowData.id_escolaridade
                          ? {
                              value: rowData.id_escolaridade,
                              label: ESCOLARIDADE.filter(
                                data => data.value === rowData.id_escolaridade
                              )[0].label
                            }
                          : null,
                        id_estado_civil: rowData.id_estado_civil
                          ? {
                              value: rowData.id_estado_civil,
                              label: ESTADO_CIVIL.filter(
                                data => data.value === rowData.id_estado_civil
                              )[0].label
                            }
                          : null,
                        sexo: rowData.sexo
                          ? {
                              value: rowData.sexo,
                              label:
                                rowData.sexo === "M" ? "Masculino" : "Feminino"
                            }
                          : null,
                        blacklist_email: rowData.blacklist_email
                          ? {
                              value: rowData.blacklist_email === "Não" ? 0 : 1,
                              label:
                                rowData.blacklist_email === "Não"
                                  ? "Não"
                                  : "Sim"
                            }
                          : null,
                        blacklist_sms: rowData.blacklist_sms
                          ? {
                              value: rowData.blacklist_sms === "Não" ? 0 : 1,
                              label:
                                rowData.blacklist_sms === "Não" ? "Não" : "Sim"
                            }
                          : null
                      }
                    },
                    () => this.setState({ showEdit: true })
                  );
                }
              }
            ]}
            isLoading={this.props.loadingPessoas}
            // onRowClick={(columns, data) => props.openModalPerfil(data)}
            pageSize={5}
            style={{
              border: "1px solid #e0e0e0",
              boxShadow: "none",
              borderRadius: 10
            }}
            columns={[
              { title: "id pessoa", field: "id_pessoa" },
              { title: "Nome", field: "nome" },
              { title: "Sexo", field: "sexo" },
              {
                title: "Data de nascimento",
                field: "data_nascimento"
              },
              { title: "Documento", field: "documento" },
              { title: "Rua", field: "endereco" },
              { title: "Bairro", field: "bairro" },
              { title: "Complemento", field: "complemento" },
              { title: "Numero", field: "numero" },
              { title: "Cidade", field: "cidade" },
              { title: "Numero", field: "numero" },
              { title: "Estado", field: "uf" },
              { title: "CEP", field: "cep" },
              { title: "Telefone 1", field: "telefone1" },
              { title: "Telefone 2", field: "telefone2" },
              { title: "Telefone 3", field: "telefone3" },
              { title: "Telefone 4", field: "telefone4" },
              { title: "Celular 1", field: "celular1" },
              { title: "Celular 2", field: "celular2" },
              { title: "Celular 3", field: "celular3" },
              { title: "Celular 4", field: "celular4" },
              { title: "Email 1", field: "email1" },
              { title: "Email 2", field: "email2" },
              { title: "Email 3", field: "email3" },
              { title: "Email 4", field: "email4" },
              { title: "Id usuario", field: "id_usuario" },
              { title: "Id profissão", field: "id_profissao" },
              { title: "Id escolaridade", field: "id_escolaridade" },
              { title: "Id estado civil", field: "id_estado_civil" },
              { title: "Tipo pessoa", field: "tipo_pessoa" },
              { title: "Id status", field: "id_status" },
              { title: "Id termometro", field: "id_termometro" },
              { title: "Id loja preferida", field: "id_loja_preferida" },
              {
                title: "Id termometro proximo",
                field: "id_pessoa_termometro_proximo"
              },
              { title: "Quantidade compras", field: "qt_compras" },
              { title: "Devoluçoes", field: "qt_devolucoes" },
              { title: "Ticket médio", field: "ticket_medio" },
              { title: "Valor total", field: "valor_total_gasto" },
              { title: "Dias ultima compra", field: "dias_ultima_compra" },
              { title: "Data ultima compra", field: "data_ultima_compra" },
              { title: "Data inserido", field: "data_insert" },
              {
                title: "Dias ultimo envio sms",
                field: "dias_ultimo_envio_sms"
              },
              { title: "Tipo pessoa", field: "tipo_pessoa" },
              { title: "Blacklist sms", field: "blacklist_sms" },
              { title: "Blacklist email", field: "blacklist_email" }
            ]}
            data={
              this.props.pessoas
                ? this.props.pessoas.map(data => ({
                    ...data,
                    data_nascimento: moment(data.data_nascimento).format(
                      "DD/MM/YYYY"
                    ),
                    data_ultima_compra: moment(data.data_ultima_compra).format(
                      "DD/MM/YYYY"
                    ),
                    data_insert: moment(data.data_insert).format("DD/MM/YYYY"),
                    blacklist_sms: data.blacklist_sms === 0 ? "Não" : "Sim",
                    blacklist_email: data.blacklist_email === 0 ? "Não" : "Sim"
                  }))
                : []
            }
          />
        </div>
        {showModal && (
          <ModalAddPessoa
            open={showModal}
            onSave={() => this.props.savePessoa(this.state.pessoa)}
            onClose={() => this.setState({ showModal: false, pessoa: {} })}
            onChange={this.onInputChange}
            data={this.state.pessoa}
            onSearchCEP={e => PesquisaCEP(e.target.value, this.onInputChange)}
            profissoes={this.props.listTodasProfissao}
          ></ModalAddPessoa>
        )}
        {showEdit && (
          <ModalEdit
            open={showEdit}
            onSave={() =>
              this.setState({ showEdit: false }, () =>
                this.props.editPessoa(this.state.pessoa)
              )
            }
            onClose={() => this.setState({ showEdit: false, pessoa: {} })}
            onChange={this.onInputChange}
            data={this.state.pessoa}
            profissoes={this.props.listTodasProfissao}
            onSearchCEP={e => PesquisaCEP(e.target.value, this.onInputChange)}
          ></ModalEdit>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    paddingTop: "3rem",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flexGrow: 1,
    paddingTop: "1rem",
    height: "100%"
  },
  subList: {
    display: "flex",
    margin: theme.spacing(2)
  },
  section: { display: "flex" }
});

const mapStateToProps = ({ DatabaseReducer, InsightsReducer }) => ({
  ...DatabaseReducer,
  listTodasProfissao: InsightsReducer.listTodasProfissao
});

const mapDispatchToProps = {
  getPessoas,
  savePessoa,
  editPessoa,
  listaTodasProfissao
};

const PessoasConnect = connect(mapStateToProps, mapDispatchToProps)(Pessoas);

export default withStyles(styles)(PessoasConnect);
