import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Radios, TextField } from 'mui-rff';
import React from 'react';
import useStyles from './styles';
import { useFormState } from 'react-final-form';

const awareness = [
  { label: 'Reconhecimento de Marca', value: 'BRAND_AWARENESS' },
  { label: 'Alcance', value: 'REACH' },
];

const consideration = [
  { label: 'Engajamento com a publicação', value: 'POST_ENGAGEMENT' },
  { label: 'Curtidas na Página', value: 'PAGE_LIKES' },
  { label: 'Participações no evento', value: 'EVENT_RESPONSES' },
  { label: 'Instalações de aplicativo', value: 'APP_INSTALLS' },
  { label: 'Visualizações de vídeo', value: 'VIDEO_VIEWS' },
  { label: 'Geração de cadastros', value: 'LEAD_GENERATION' },
  { label: 'Mensagens', value: 'MESSAGES' },
];

const conversion = [
  { label: 'Conversões', value: 'CONVERSIONS' },
  { label: 'Vendas do catálogo', value: 'PRODUCT_CATALOG_SALES' },
  { label: 'Tráfego no estabelecimento', value: 'STORE_VISITS' },
];

function Campaign() {
  const classes = useStyles();

  const { values } = useFormState();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Objetivo da campanha" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Radios
                  formLabelProps={{ className: classes.margin }}
                  name="campaign.objective"
                  label="Reconhecimento"
                  data={awareness}
                  disabled={values.campaign.id}
                />
              </Grid>
              <Grid item xs={4}>
                <Radios
                  formLabelProps={{ className: classes.margin }}
                  name="campaign.objective"
                  label="Consideração"
                  data={consideration}
                  disabled={values.campaign.id}
                />
              </Grid>
              <Grid item xs={4}>
                <Radios
                  formLabelProps={{ className: classes.margin }}
                  name="campaign.objective"
                  label="Conversão"
                  data={conversion}
                  disabled={values.campaign.id}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Nome da campanha" />
          <CardContent>
            <Grid item xs={12}>
              <TextField
                name="campaign.name"
                variant="outlined"
                disabled={values.campaign.id}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Campaign;
