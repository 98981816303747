import React, { Fragment } from 'react';
import { TextField, Select, Modal } from '../../../utils/components';
import { Button } from '@material-ui/core';

export default ({ template, onClose, onChange, onSave, showModal, onEdit }) => (
  <Modal
    size="md"
    showModal={showModal}
    onClose={onClose}
    header="Salvar Template"
    footer={
      <Fragment>
        <Button color="primary" variant="text" size="large" onClick={onSave}>
          Salvar cópia
        </Button>
        <Button
          color="primary"
          variant="text"
          size="large"
          onClick={template.idTemplate ? onEdit : onSave}
        >
          Salvar
        </Button>
      </Fragment>
    }
    // footer={this.footer(this.props, this.state)}
  >
    <section
      style={{
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10%',
      }}
    >
      <div style={{ width: '73%' }}>
        <TextField
          className="form-control"
          label="Nome"
          onChange={({ target }) => {
            onChange('templateName', target.value);
          }}
          value={template.templateName}
        />
      </div>
      <div style={{ width: '23%' }}>
        <Select
          valueKey="value"
          placeholder="Tipo"
          onChange={option => onChange('templateType', option)}
          value={template.templateType}
          options={[
            { value: 'html', label: 'Email' },
            { value: 'html', label: 'Landing Page' },
          ]}
        />
      </div>
    </section>
    {/* </Modal.Body>
    <Modal.Footer>
      <MyButton
        disabled={
          !template.templateName ? true : !template.templateType ? true : false
        }
        onClick={onSave}
        bsstyle="success"
      >
        Salvar
      </MyButton>
      <MyButton onClick={onClose}>Fechar</MyButton>
    </Modal.Footer> */}
  </Modal>
);
