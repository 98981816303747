import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';

import Login from './modules/Login/components/Login';
import RecoverPassword from './modules/RecoverPassword/index';
import ResetPassword from './modules/ResetPassword';
import SignInAsUser from './modules/SignInAsUser';
import ExpirationVerify from './utils/helpers/ExpirationVerify';
import history from './utils/routes/history';
import Routes from './utils/routes/Rotas';

const dataStorage = dataItem => (localStorage.getItem(dataItem) || '').replaceAll('"', '');
const App = props => {
    useEffect(() => {
      const iconHtml = document.getElementById('favicon');
      if (window.location.hostname.includes('redeminipreco')) {
        document.title = 'Rede Minipreço | Marketing';
        iconHtml.href = 'https://www.redeminipreco.com.br/images/favicon.png';
      } else {
        document.title = 'DS Marketing';
        iconHtml.href = 'https://datasales.io/assets/img/favicon.png';
      }
    }, []);

    if (props.logged) {
      ExpirationVerify({
        msg: ' (App)'
      });

      try {
        localStorage.setItem(
          'numeroRastreio',
          dataStorage('companyId') + '-' + new Date().getTime()
        );
      } catch {}

      try {
        const companyId = localStorage.getItem('companyId');
        // const numeroRastreio = localStorage.getItem('numeroRastreio');
        window._mfq?.push(['setVariable', 'companyId', companyId]);
        // window._mfq.push(['setVariable', 'numeroRastreio', numeroRastreio]);
      } catch (err) {
        console.log(err);
      }



      try {
        LOU.identify(localStorage.getItem('userId'), {
          company: localStorage.getItem('companyId')
        });
      } catch (err) {
        console.log(err);
      }
    }

    return props.logged ? (
      <Router history={history}>
        <Routes {...props} />
      </Router>
    ) : (
      <Router>
        <Route path="/reset/:token" component={ResetPassword} exact />
        <Route path="/recover" component={RecoverPassword} />
        <Route path="/sign-as-user/:token" component={SignInAsUser} />
        {/* <Route path="/versions" component={VersionOption} /> */}
        {/* <Route path="/" render={() => <Redirect to="/login" />} /> */}
        <Route path="/login" render={() => <Login history={history} />} />
        <Route path="/" render={() => <Login history={history} />} />
      </Router>
    );
  },
  mapStateToProps = ({ loginReducer }) => ({ ...loginReducer });

export default connect(mapStateToProps, null)(App);

