import axios from 'axios';
import { Canvg } from 'canvg';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const arraysEqual = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function asyncMap(array, callback) {
  const promises = array.map(callback);
  return Promise.all(promises);
}

export function setTypeImport(type) {
  switch (String(type).toUpperCase()) {
    case 'PESSOA':
      return 'Dados cadastrais';
    case 'VENDAS':
      return 'Dados de vendas';
    case 'CAMPANHA':
      return 'Dados cadastrais para campanha';
    default:
      return '';
  }
}

export function setRuleImport(rule) {
  switch (String(rule).toUpperCase()) {
    case 'UPDATE':
      return 'Substituir valores existentes';
    case 'COMPLETE':
      return 'Completar campos em branco';
    default:
      return '';
  }
}

export const ordenaArray = (array, prop) =>
  array.sort((a, b) => {
    if (a[prop] > b[prop]) return 1;
    if (a[prop] < b[prop]) return -1;
    return 0;
  });

export const isCPF = cpf => /^((\d{3})(\d{3})(\d{3})(\d{2}))*$/g.test(cpf);

export function formataDinheiro(n) {
  return parseFloat(n)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
}

export function inputMoney(text) {
  let v = text.replace(/\D/g, '');
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return v;
}

export const toBlob = file =>
  new Promise(async (resolve, reject) => {
    let newFile = file;

    if (typeof newFile === 'string') {
      try {
        const response = await axios.get(newFile, {
          responseType: 'blob',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,HEAD',
            'Content-Type': 'application/json;charset=utf-8'
          }
        });
        if (response.status && response.status === 200) {
          if (response && response.data) {
            resolve(response.data);
          }
        } else {
          reject();
        }
      } catch (err) {
        reject(err);
      }
    }
  });

export const dimensionsImage = (base64 = '') =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64;
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.onerror = error => reject(error);
  });

export const toBase64 = (file, options = {}) =>
  new Promise(async (resolve, reject) => {
    options = {
      noHeaders: options?.noHeaders || false
    };
    let newFile = file,
      name = null,
      size = null,
      type = null;
    if (typeof newFile === 'string') {
      try {
        const response = await axios.get(newFile, {
          responseType: 'blob',
          ...(options?.noHeaders
            ? {}
            : {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,HEAD',
                  'Content-Type': 'application/json;charset=utf-8'
                }
              })
        });
        if (response.status && response.status === 200) {
          if (response && response.data) {
            type = response.data.type;
            name = newFile.split('/').pop();
            size = file.size;
            newFile = response.data;
          }
        } else {
          reject();
        }
      } catch (err) {
        reject(err);
      }
    }
    if (newFile instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = async () => {
        const typeFile = String(
          reader.result.split(';')[0].split(':')[1].split('/')[0]
        ).toLowerCase();
        let dimensions = { width: 0, height: 0 };
        if (typeFile === 'image') {
          dimensions = await dimensionsImage(reader.result);
        }
        resolve({
          base64: reader.result,
          name: name || file.name,
          type: type || file.type,
          size: size || file.size,
          blob: newFile,
          ...dimensions
        });
      };
      reader.onerror = error => reject(error);
    }
  });

export async function parseSvgToBase64(id) {
  const serializer = new XMLSerializer(),
    SVGDomElement = document.getElementById(id);
  SVGDomElement.style.display = 'initial';
  const serializedSVG = serializer.serializeToString(SVGDomElement),
    base64Data = window.btoa(serializedSVG),
    canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  SVGDomElement.style.display = 'none';
  let data = `data:image/svg+xml;base64,${base64Data}`;

  try {
    const v = await Canvg.from(ctx, data);
    v.start();
    data = canvas.toDataURL('image/png', 100);
    v.stop();
  } catch (err) {
    console.log('ERRO => ', err);
  }
  return data;
}

export function _statusPedido(status) {
  switch (status) {
    case 0:
      return 'Pedido cancelado';
    case 1:
      return 'Pedido realizado';
    case 2:
      return 'Em separação';
    case 3:
      return 'Saiu para entrega';
    case 4:
      return 'Disponível para retirada';
    case 5:
      return 'Pedido entregue/retirada';
    default:
      return 'Pedido realizado';
  }
}

export function _tipoPedido(status) {
  switch (status) {
    case 1:
      return 'Delivery';
    case 2:
      return 'Drive';
    case 3:
      return 'Delivery/Drive';
    default:
      return 'Pedido realizado';
  }
}

export function _tipoPagamento(pagamento) {
  switch (pagamento) {
    case 1:
      return 'Dinheiro';
    case 2:
      return 'Débito';
    case 3:
      return 'Crédito';
    case 4:
      return 'Boleto';
    case 5:
      return 'VR';
    case 6:
      return 'PIX';
    default:
      return 'Pagamento não informado';
  }
}

export function _corStatusPedido(cor) {
  switch (cor) {
    case 0:
      return '#f44336';
    case 1:
      return '#ffc107';
    case 2:
      return '#ff5722';
    case 3:
      return '#312a5e';
    case 4:
      return '#312a5e';
    case 5:
      return '#4caf50';
    default:
      return '#ffc107';
  }
}

export const mountTemplate = data => {
  return {
    counters: {
      u_column: 32,
      u_row: 10,
      u_content_image: 7,
      u_content_html: 4,
      u_content_text: 30,
      u_content_divider: 2
    },
    body: {
      rows: data.map(item => ({
        cells: [1, 2, 1, 2],
        columns: [
          {
            contents: [
              {
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 15px',
                  _meta: {
                    htmlID: 'u_content_image_2',
                    htmlClassNames: 'u_content_image'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  src: {
                    url: 'https://datasales.io/wp-content/uploads/2020/01/Bebida-Láctea-Iogurte-Veneza-540g-150x150.png',
                    width: 150,
                    height: 150
                  },
                  fullWidth: false,
                  textAlign: 'center',
                  maxWidth: '100%',
                  altText: 'Image',
                  action: { url: '', target: '' }
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'dotted',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'dotted',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'dotted',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'dotted',
                borderBottomColor: '#CCC'
              },
              _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [
              {
                type: 'text',
                values: {
                  containerPadding: '30px 4px 10px 10px',
                  _meta: {
                    htmlID: 'u_content_text_6',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: `<p style="font-size: 14px; line-height: 140%;"><span style="font-family: arial, helvetica, sans-serif; font-size: 16px; line-height: 22.4px;"><strong><span style="line-height: 22.4px; font-size: 16px;">${item.nome_produto}</span></strong></span></p>`
                }
              },
              {
                type: 'text',
                values: {
                  containerPadding: '5px 5px 0px',
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'center',
                  lineHeight: '100%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: `<p style="font-size: 14px; line-height: 100%;"><span style="font-size: 14px; line-height: 14px;">de R$ ${item.preco}&nbsp;</span><strong style="font-size: 1rem; color: #bf0029;"><span style="line-height: 14px; font-size: 14px;">por R$ ${item.preco_dois}</span></strong></p>\n<p style="font-size: 14px; line-height: 100%;">&nbsp;</p>`
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [
              {
                type: 'text',
                values: {
                  containerPadding: '10px',
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: '<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>'
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_11', htmlClassNames: 'u_column' }
            }
          }
        ],
        values: {
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: '0px',
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          deletable: true
        }
      })),
      values: {
        backgroundColor: '#ffffff',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false
        },
        contentWidth: '1000px',
        fontFamily: { label: 'Verdana', value: 'verdana,geneva' },
        linkStyle: {
          body: true,
          linkColor: '#0000ee',
          linkHoverColor: '#0000ee',
          linkUnderline: true,
          linkHoverUnderline: true
        },
        _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' }
      }
    }
  };
};

export const json = {
  counters: {
    u_column: 32,
    u_row: 10,
    u_content_image: 7,
    u_content_html: 4,
    u_content_text: 30,
    u_content_divider: 2
  },
  body: {
    rows: [
      {
        cells: [1, 2, 1, 2],
        columns: [
          {
            contents: [
              {
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 15px',
                  _meta: {
                    htmlID: 'u_content_image_2',
                    htmlClassNames: 'u_content_image'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  src: {
                    url: 'https://datasales.io/wp-content/uploads/2020/01/Bebida-Láctea-Iogurte-Veneza-540g-150x150.png',
                    width: 150,
                    height: 150
                  },
                  fullWidth: false,
                  textAlign: 'center',
                  maxWidth: '100%',
                  altText: 'Image',
                  action: { url: '', target: '' }
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'dotted',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'dotted',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'dotted',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'dotted',
                borderBottomColor: '#CCC'
              },
              _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [
              {
                type: 'text',
                values: {
                  containerPadding: '30px 4px 10px 10px',
                  _meta: {
                    htmlID: 'u_content_text_6',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: '<p style="font-size: 14px; line-height: 140%;"><span style="font-family: arial, helvetica, sans-serif; font-size: 16px; line-height: 22.4px;"><strong><span style="line-height: 22.4px; font-size: 16px;">Bebida Lactea Veneza editado 540G</span></strong></span></p>'
                }
              },
              {
                type: 'text',
                values: {
                  containerPadding: '5px 5px 0px',
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'center',
                  lineHeight: '100%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: `<p style="font-size: 14px; line-height: 100%;"><span style="font-size: 14px; line-height: 14px;">de R$ 2,99&nbsp;</span><strong style="font-size: 1rem; color: #bf0029;"><span style="line-height: 14px; font-size: 14px;">de R$ 5,99</span></strong></p>\n<p style="font-size: 14px; line-height: 100%;">&nbsp;</p>`
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' }
            }
          },
          {
            contents: [
              {
                type: 'text',
                values: {
                  containerPadding: '10px',
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text'
                  },
                  selectable: true,
                  draggable: true,
                  deletable: true,
                  color: '#000000',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  text: `<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>`
                }
              }
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_11', htmlClassNames: 'u_column' }
            }
          }
        ],
        values: {
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: '0px',
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          deletable: true
        }
      }
    ],
    values: {
      backgroundColor: '#ffffff',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false
      },
      contentWidth: '1000px',
      fontFamily: { label: 'Verdana', value: 'verdana,geneva' },
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' }
    }
  }
};

