import React from 'react';
import { Box } from '@material-ui/core';
import {
  MyButton,
  InputFile,
  ReactDate,
  Modal,
} from '../../../utils/components';
import DateCustom from '../../../utils/helpers/Date';
import '../style/ConfigClubeStyle.css';

export default ({ showModal, onClose, onSend, onChange, recomendacoes }) => (
  <Modal
    showModal={showModal}
    header="Adicionar recomendações"
    footer={
      <Box>
        <MyButton
          disabled={
            !recomendacoes
              ? true
              : !recomendacoes.tituloRecomendacao
              ? true
              : !recomendacoes.descricaoRecomendacao
          }
          bsstyle="success"
          onClick={onSend}
        >
          Salvar
        </MyButton>
        <MyButton onClick={onClose}>Fechar</MyButton>
      </Box>
    }
  >
    <input
      className="form-control"
      placeholder="Título para a recomendação"
      onChange={({ target: { value } }) =>
        onChange('tituloRecomendacao', value)
      }
    />
    <textarea
      style={{ resize: 'vertical', marginTop: '2vh' }}
      className="form-control"
      placeholder="Descrição para a recomendação"
      onChange={({ target: { value } }) =>
        onChange('descricaoRecomendacao', value)
      }
    />
    <section className="datasRecomendacoes">
      <section>
        <strong>Data inicio da recomendação</strong>
        <ReactDate
          onChange={date =>
            onChange(
              'dataInicioRecomendacao',
              new DateCustom(date, 'YYYY-MM-DD').getDate()
            )
          }
          placeholder="Data início da recomendação"
        />
      </section>
      <section>
        <strong>Data fim da recomendação</strong>
        <ReactDate
          onChange={date =>
            onChange(
              'dataFimRecomendacao',
              new DateCustom(date, 'YYYY-MM-DD').getDate()
            )
          }
          placeholder="Data fim da recomendação"
        />
      </section>
    </section>
    <section style={{ marginTop: '2vh' }}>
      <InputFile
        files={file => onChange('imagensRecomendacao', file)}
        filesFormat
      />
    </section>
  </Modal>
);
