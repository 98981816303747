import {
  API_URL_ACCOUTING,
  DATASALES_CRM_API,
  AMBIENTE,
} from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';

export const isLoadingReport = () => dispatch =>
  dispatch({ type: 'LOADING_REPORT' });

export const getBirthReport = (dateBirth, type, idStores) => dispatch => {
  dispatch(isLoadingReport());
  const getDateBirth = {
    dateBirth: dateBirth.keyDates || dateBirth,
    type,
    monthBirth:
      type.toUpperCase() === 'MONTH'
        ? dateBirth
        : dateBirth.keyMonth || new Date().getMonth() + 1,
    idStores: idStores && idStores.map(store => store.value),
  };
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getRelatorioAniversario`,
    getDateBirth,
  ).then(resp =>
    dispatch({
      type: 'LIST_BIRTH_REPORT',
      payload: resp,
    }),
  );
};

export const getBirthReportPeriod = (dateBirth, type, idStores) => dispatch => {
  const getDateBirth = {
    ...dateBirth,
    type,
    idStores: idStores && idStores.map(store => store.value),
  };
  dispatch(isLoadingReport());
  Fetch(`${API_URL_ACCOUTING}retail/getPeopleBirth`, getDateBirth).then(resp =>
    dispatch({
      type: 'LIST_BIRTH_REPORT',
      payload: resp,
    }),
  );
};

export const getSMS = objSMS => dispatch => {
  dispatch(isLoadingReport());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getSMS`,
    objSMS,
  ).then(resp => dispatch({ type: 'GET_SMS', payload: resp }));
};

export const selectStore = idStores => dispatch =>
  dispatch({ type: 'SELECT_STORE', payload: idStores });
