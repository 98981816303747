import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import { Autocomplete, DateTimePicker, Select, TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { useAds } from '../../../../../../../../hooks/ads';
import TabPanel from '../../../../../../../../utils/components/TabPanel';
import api from '../../../../../../../../utils/helpers/Fetch';
import useStyles from './styles';
import { useForm, useFormState } from 'react-final-form';
import NumberFormat from '../../../../../../../../utils/components/NumberFormat';
// const genres = [
//   { label: 'Todos', value: '0' },
//   { label: 'Homens', value: '1' },
//   { label: 'Mulheres', value: '2' },
// ];

const ages = new Array(53).fill(null).map((_, i) => ({
  label: i === 52 ? '65 +' : `${13 + i}`,
  value: 13 + i,
}));

function AdSet() {
  const classes = useStyles();

  const { user } = useAds();

  const form = useForm();
  const { values } = useFormState();

  const [pages, setPages] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [value, setValue] = useState(0);

  const [cities, setCities] = useState([]);

  const getUserLocation = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const response = await axios.get(
            `https://graph.facebook.com/v10.0/search`,
            {
              params: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                type: 'adradiussuggestion',
                distance_unit: 'kilometer',
                access_token: user.access_token,
              },
            }
          );
        },
        null,
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v10.0/${user.user_id}/accounts`,
          {
            params: {
              access_token: user.access_token,
            },
          }
        );

        const { data } = response.data;

        setPages(data);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        const response = await api(
          'https://wpz6lzooh6.execute-api.us-east-1.amazonaws.com/dev/audiences'
        );

        const { content } = response;

        setAudiences(content);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchCities = async event => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v10.0/search`,
        {
          params: {
            access_token: user.access_token,
            location_types: ['city'],
            type: 'adgeolocation',
            limit: 500,
            region_id: 460,
            q: event.target.value,
          },
        }
      );

      const { data } = response.data;

      setCities(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAudienceChange = audience => {
    form.change('adSet.targeting', JSON.parse(audience.targeting));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Nome do conjunto de anúncios" />
          <CardContent>
            <TextField name="adSet.name" label="Nome" variant="outlined" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title="Página"
            subtitle="Escolha a Página do Facebook que você quer promover.
"
          />
          <CardContent>
            <Select name="adSet.page_id" label="Página" variant="outlined">
              {pages.map(page => (
                <MenuItem value={page.id}>{page.name}</MenuItem>
              ))}
            </Select>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Orçamento" />
          <CardContent>
            <NumberFormat
              name="adSet.daily_budget"
              variant="outlined"
              label="Orçamento diário"
              fullWidth
            />
            {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select name="adSet.budget" variant="outlined">
                  <MenuItem value="daily_budget">Orçamento diário</MenuItem>
                  <MenuItem value="lifetime_budget">Orçamento total</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={values.adSet.lifetime_budget}
                  variant="outlined"
                  // fieldProps={{ format: currencyFormatter }}
                />
              </Grid>
            </Grid> */}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Programação" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DateTimePicker
                  name="adSet.start_time"
                  label="Data de início"
                  required
                  format="DD/MM/YYYY HH:mm"
                  inputVariant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  name="adSet.end_time"
                  label="Término - Opcional"
                  format="DD/MM/YYYY HH:mm"
                  inputVariant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title="Público"
            subtitle="Defina quem você quer que veja seus anúncios."
          />
          <Tabs
            indicatorColor="primary"
            onChange={handleChange}
            value={value}
            handleChange
          >
            <Tab label="Criar novo público" />
            <Tab label="Usar público existente" />
          </Tabs>
          <Divider />
          <CardContent>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>Localizações</Typography>
                </Grid>

                <Grid item xs={10}>
                  <Autocomplete
                    label="Cidades"
                    name="adSet.targeting.geo_locations.cities[0].key"
                    textFieldProps={{ variant: 'outlined' }}
                    options={cities}
                    getOptionValue={option => option.key}
                    getOptionLabel={option => option.name}
                    noOptionsText="Sem opções"
                    onInputChange={handleSearchCities}
                    renderOption={option => (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                          <span style={{ fontWeight: 500 }}>{option.name}</span>
                          <Typography variant="body2" color="textSecondary">
                            {option.type === 'city' ? 'cidade' : 'bairro'}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="adSet.targeting.geo_locations.cities[0].radius"
                    variant="outlined"
                    label="Raio KM"
                    inputProps={{ type: 'number' }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography gutterBottom>Gênero</Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Radios
                    name="adset.targeting.gender"
                    radioGroupProps={{ row: true }}
                    label=""
                    data={genres}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography gutterBottom>Idade</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select name="adSet.targeting.age_min" variant="outlined">
                    {ages.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select name="adSet.targeting.age_max" variant="outlined">
                    {ages.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <List>
                {audiences.map(audience => (
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => {
                      handleAudienceChange(audience);
                    }}
                  >
                    <ListItemText primary={audience.name} />
                    {JSON.stringify(values.adSet.targeting) ===
                      audience.targeting && (
                      <ListItemSecondaryAction>
                        <CheckIcon color="primary" />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>

      {/* <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="strong">
            Posicionamentos
          </Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="strong">
            Otimização e veiculação
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  );
}

export default AdSet;
