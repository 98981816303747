import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  MyButton,
  CustomTable,
  Countup
} from "../../../utils/components";
import { getInfoCashback } from "../actions/index";
import { CPF, Celular } from "../../../utils/helpers/Masks";

class ModalVerDetalhe extends Component {
  componentDidMount() {
    const objInfoCashback = {
      id_oferta_cashback: this.props.id_oferta_cashback,
      dataInicio: this.props.comeca_valer,
      dataFim: this.props.termina_valer
    };
    this.props.getInfoCashback(objInfoCashback);
  }
  header(props, state) {
    return <strong>{`${props.nome_loja} - ${props.nome_cashback}`}</strong>;
  }
  footer(props, state) {
    return (
      <MyButton
        size="large"
        color="primary"
        variant="text"
        onClick={props.onClose}
      >
        Fechar
      </MyButton>
    );
  }
  render() {
    const { onClose, open, info_cashback, isLoadingCashback } = this.props;
    return (
      <Modal
        header={this.header(this.props, this.state)}
        footer={this.footer(this.props, this.state)}
        showModal={open}
        onClose={onClose}
      >
        <CustomTable
          isLoading={isLoadingCashback}
          data={info_cashback}
          columns={[
            {
              field: "nome",
              title: "Nome"
            },
            {
              title: "Documento",
              field: "documento",
              render: rowData => <span>{CPF(rowData.documento)}</span>
            },
            {
              title: "Celular",
              field: "celular1",
              render: rowData => <span>{Celular(rowData.celular1)}</span>
            },
            {
              title: "Cashback",
              field: "total_cashback",
              render: rowData => (
                <Countup
                  start={0}
                  end={parseFloat(rowData.total_cashback)}
                  duration={5}
                  separator="."
                  decimal=","
                  decimals={2}
                  prefix="R$ "
                />
              )
            },
            {
              title: "Compras",
              field: "qtde_vendas",
              render: rowData => (
                <Countup
                  start={0}
                  end={parseFloat(rowData.qtde_vendas)}
                  duration={5}
                />
              )
            }
          ]}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ CashbackReducer }) => ({ ...CashbackReducer });

const mapDispatchToProps = { getInfoCashback };

export default connect(mapStateToProps, mapDispatchToProps)(ModalVerDetalhe);
