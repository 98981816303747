import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import Badge from "../Badge/Badge";
import "./Sidebar.css";

export default class Sidebar extends Component {
  state = { showSubmenuPai: false };
  openToggleMenu(index) {
    this.setState({
      [`showSubmenuPai${index}`]: true
    });
  }

  closeToggleMenu(index) {
    this.setState({
      [`showSubmenuPai${index}`]: false
    });
  }

  openToggleSubmenu(data, index, subindex) {
    this.setState({
      [`showSubmenuFilho${index}_${subindex}`]: !this.state[
        `showSubmenuFilho${index}_${subindex}`
      ]
    });
    this.props.onSelectSubmenu(data, subindex);
  }

  render() {
    const { rotas } = this.props;
    return (
      <div style={{ width: "4vw" }} className="div-container-sidebar">
        {rotas
          .filter(rota => !rota.hidden)
          .map((rota, index) => (
            <ul key={index} className="ul-sidebar">
              <li
                className="linha-sidebar"
                onMouseEnter={() => this.openToggleMenu(index)}
                onMouseLeave={() => this.closeToggleMenu(index)}
              >
                <span>
                  {rota.beta && (
                    <Badge
                      backgroundColor="#e85764"
                      style={{
                        width: "3vw",
                        position: "absolute",
                        left: "50%"
                      }}
                    >
                      Beta
                    </Badge>
                  )}

                  {rota.path ? (
                    <i>
                      <Link to={rota.path} className="link">
                        <FontAwesomeIcon icon={rota.icon} />
                      </Link>
                    </i>
                  ) : (
                    <i>
                      <FontAwesomeIcon icon={rota.icon} />
                    </i>
                  )}

                  {!rota.path && this.state[`showSubmenuPai${index}`] && (
                    <Menu
                      index={index}
                      rota={rota}
                      closeToggleMenu={() => this.closeToggleMenu(index)}
                      openToggleSubmenu={(subrota, inx, subindex) =>
                        this.openToggleSubmenu(subrota, inx, subindex)
                      }
                    />
                  )}
                </span>
              </li>
            </ul>
          ))}
      </div>
    );
  }
}
