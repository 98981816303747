import { ActionType, ActionTypes } from './types';

export type CategoryType = {
  ID: number;
  DESCRIPTION: string;
  IS_ACTIVE: number;
  BACKGROUND_COLOR_HEX: string;
  URL_IMAGE_SHORTCUT: string;
  URL_IMAGE_BACKGROUND_RIGHT: null | string;
  URL_IMAGE_BACKGROUND_LEFT: null | string;
  CREATEDAT: string;
  UPDATEDAT: string;
  DELETEDAT: null | string;
};

export type SearchResultsBackgroundType = {
  COPY_BOOST: string;
  COPY_SOCIAL_NETWORKS: string;
  COPY_WHATSAPP: string;
  COPY_SMS: string;
  FAST_MODELS: number;
  ID_SUBCATEGORY: string;
  companyId: null | string;
  formatId: number;
  id: number;
  imageURL: string;
  name: string;
  tags: string;
  thumbnail: string;
};

export type SubcategoriesBackgroundsType = {
  id: number;
  companyId: number;
  formatId: number;
  occasionId: number;
  segmentId: number;
  name: string;
  imageURL: string;
  designURL: string;
  productQuantity: number;
  tags: string;
  video_template_id: number;
  video_composition: number;
  ID_SUBCATEGORY: number;
  FAST_MODELS: number;
  thumbnail: string;
  COPY_WHATSAPP: string;
  COPY_SOCIAL_NETWORKS: string;
  COPY_BOOST: string;
  COPY_SMS: string;
  auxiliarTextQtde: number;
};

export type SelectedBackgroundsType = {
  subcategoryId: number;
  subcategoryDescription: string;
  backgroundData: SubcategoriesBackgroundsType;
  price: number;
  generatedImage: string | null;
  generatedImagePreview: string | null;
  design_url: string | null;
  isSubmitting: boolean | null;
};

export type SegmentType = {
  id: number;
  description: string;
  is_active: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

export type CompanyType = {
  id: number;
  cnpj: string;
  name: string;
  companyBasePath: string;
  ID_SEGMENT: number;
};

export type SubcategoriesType = {
  ID: number;
  DESCRIPTION: string;
  backgrounds: SubcategoriesBackgroundsType[];
};

export type SearchResultsType = {
  CREATEDAT: string;
  DELETEDAT: null | string;
  DESCRIPTION: string;
  ID: number;
  IS_ACTIVE: number;
  UPDATEDAT: string;
};

export type InitialStateFastModels = {
  isLoading: boolean;
  isLoadingSegments: boolean;
  isLoadingPutSegmentCompany: boolean;
  isLoadingCompany: boolean;
  isLoadingCategories: boolean;
  isLoadingSubcategories: boolean;
  isSearching: boolean;
  segments: SegmentType[];
  company: CompanyType[];
  categories: CategoryType[];
  subcategories: [] | SubcategoriesType[];
  searchSuggestions: [] | SearchResultsType[];
  searchResults: [] | SubcategoriesType[];
  selectedSegment: undefined | SegmentType[];
  selectedCategory: undefined | CategoryType;
  selectedBackgrounds: [] | SelectedBackgroundsType[];
  offset: number;
  limit: number;
};

export const INITIAL_STATE_FAST_MODELS = {
  isLoading: false,
  isLoadingSegments: false,
  isLoadingPutSegmentCompany: false,
  isLoadingCompany: false,
  isLoadingCategories: false,
  isLoadingSubcategories: false,
  isSearching: false,
  segments: [],
  company: [],
  categories: [],
  subcategories: [],
  searchSuggestions: [],
  searchResults: [],
  selectedSegment: undefined,
  selectedCategory: undefined,
  selectedBackgrounds: [],
  offset: 1,
  limit: 6
} as InitialStateFastModels;

export default function fastModelsReducer(state = INITIAL_STATE_FAST_MODELS, action: ActionType) {
  switch (action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.SET_IS_LOADING_SEGMENTS:
      return {
        ...state,
        isLoadingSegments: action.payload
      };
    case ActionTypes.SET_IS_LOADING_PUT_SEGMENT_COMPANY:
      return {
        ...state,
        isLoadingPutSegmentCompany: action.payload
      };
    case ActionTypes.SET_IS_LOADING_COMPANY:
      return {
        ...state,
        isLoadingCompany: action.payload
      };
    case ActionTypes.SET_IS_LOADING_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: action.payload
      };
    case ActionTypes.SET_IS_LOADING_SUBCATEGORIES:
      return {
        ...state,
        isLoadingSubcategories: action.payload
      };
    case ActionTypes.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload
      };

    case ActionTypes.GET_SEGMENTS:
      return {
        ...state,
        segments: action.payload
      };
    case ActionTypes.GET_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case ActionTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case ActionTypes.GET_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload
      };
    case ActionTypes.GET_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: action.payload
      };
    case ActionTypes.GET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload
      };

    case ActionTypes.SET_SELECTED_SEGMENT:
      return {
        ...state,
        selectedSegment: action.payload
      };
    case ActionTypes.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload
      };
    case ActionTypes.SET_SELECTED_BACKGROUNDS:
      return {
        ...state,
        selectedBackgrounds: action.payload
      };
    case ActionTypes.SET_PAGINATION_PROPS:
      return {
        ...state,
        offset: action.payload
      };
    case ActionTypes.CLEAR_SUBCATEGORIES:
      return {
        ...state,
        subcategories: []
      };
    case ActionTypes.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: []
      };
    default:
      return state;
  }
}

