import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Paper, Avatar, Box, Typography } from '@material-ui/core';
import { Celular2 } from '../../../utils/helpers/Masks';
import useStyles from '../styles/notification';
import MyDate from '../../../utils/helpers/Date';

const _myDate = new MyDate(),
  _audio = new Audio(
    'https://datasalesio-imagens.s3.amazonaws.com/audio/notification.ogg'
  );
export default function Notification(props) {
  const classes = useStyles(),
    playRingtone = useCallback(async () => {
      try {
        await _audio.play();
      } catch (err) {
        console.log(err);
      }
    }, []);

  useEffect(() => {
    playRingtone();
  }, [playRingtone]);

  function maskNotify() {
    switch (props.message.type) {
      case 'TEXT':
        return props.message.message;
      case 'AUDIO':
        return '🎤 Mensagem de voz';
      case 'VIDEO':
        return '📽 Vídeo';
      case 'IMAGE':
        return '📷 Imagem';
      case 'STICKER':
        return '💟 Figurinha';
      case 'DOCUMENT':
        return '📄 Documento';
      case 'LOCATION':
        return '📌 Localização';
      default:
        return props.message.message;
    }
  }

  return (
    <Paper elevation={1} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Avatar
            className={classes.avatar}
            src={props.chat.profile_thumbnail}
          />
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Typography noWrap className={classes.typography}>
              {props.chat.name_chat}
            </Typography>
            {props.chat.is_group ? (
              <Box display="flex" alignItems="center">
                <Typography
                  style={{ fontWeight: 700, minWidth: '20%' }}
                  className={classes.typographySubtitle}
                  noWrap
                >
                  {props.isMyContact
                    ? props.message.participant_name
                    : Celular2(props.message.participant_phone)}
                </Typography>
                &nbsp;
                <Typography noWrap className={classes.typographySubtitle}>
                  {maskNotify()}
                </Typography>
              </Box>
            ) : (
              <Typography noWrap className={classes.typographySubtitle}>
                {maskNotify()}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{ fontSize: '8pt', textAlign: 'end' }}
            noWrap
            className={classes.typographySubtitle}
          >
            {_myDate.difference(
              props.message.data_envio,
              'YYYY-MM-DDTHH:mm:ss'
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
