import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { PieChart, Recharts } from '../../../../utils/components';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  chartContainer: {
    position: 'relative',
    height: '200px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.primary.main,
  },
}));

function AccessLog({ open, tabloid, onClose }) {
  const classes = useStyles();

  const [accessLog, setAccessLog] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getAccessLog() {
      try {
        setLoading(true);
        const response = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/tabloid/accessLog`,
          {
            tabloidId: tabloid.uuid,
          }
        );

        setAccessLog({
          ...response,
          devices: response.device.map(item => ({
            name: item.deviceType,
            value: item.total,
          })),
          os: response.os.map(item => ({
            name: item.osName,
            value: item.total,
          })),
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    getAccessLog();
  }, []);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth="lg" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {tabloid.nome_tabloide_digital}
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box
            display="flex"
            height="50vh"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" size={44} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        Total de acessos
                      </Typography>
                      <Typography variant="h3">
                        {accessLog.total && accessLog.total[0].total}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <PeopleIcon className={classes.icon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        Total de likes
                      </Typography>
                      <Typography variant="h3">
                        {accessLog.total && accessLog.likes[0].total
                          ? accessLog.likes[0].total
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <FavoriteIcon className={classes.icon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <IconButton size="small">
                      <RefreshIcon />
                    </IconButton>
                  }
                  title="Usuários por dispositivo"
                />
                <Divider />
                <CardContent>
                  <PieChart data={accessLog.devices} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <IconButton size="small">
                      <RefreshIcon />
                    </IconButton>
                  }
                  title="Usuários por SO"
                />
                <Divider />
                <CardContent>
                  <PieChart data={accessLog.os} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <IconButton size="small">
                      <RefreshIcon />
                    </IconButton>
                  }
                  title="Acessos por dia"
                />
                <Divider />
                <CardContent>
                  <Recharts
                    data={accessLog.day}
                    barDataKey="total"
                    xDataKey="dia"
                    hide
                    legend="Acessos"
                    noLegend
                    // xDataKey="nome_loja"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <IconButton size="small">
                      <RefreshIcon />
                    </IconButton>
                  }
                  title="Acessos por hora"
                />
                <Divider />
                <CardContent>
                  <Recharts
                    data={accessLog.hour}
                    barDataKey="total"
                    xDataKey="hora"
                    hide
                    legend="Acessos"
                    noLegend
                    // xDataKey="nome_loja"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AccessLog;
