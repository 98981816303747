import React from 'react';
import { DistributionTypeProps } from 'hooks/api/distribution/useAdsDistribution';
import { Box, Checkbox, Typography } from '@mui/material';
import { useStyles } from './styles'
import { ButtonMessage } from './ButtonMessage';

type ChannelsProps = {
  icon: string;
};
type Props = {
  name: DistributionTypeProps;
  title: string;
  disabled?: boolean;
  selected?: boolean;
  onSelected: (isSelected: boolean) => void;
  channels: Array<ChannelsProps>;
};

export function ButtonChennels(props : Props) {
  const classes = useStyles();
  function handleSelect() {
    props.onSelected(!props.selected);
  }
  return (
    <>
      <Box
        className={[
          props.disabled
            ? classes.boxContentBtnDisabled
            : classes.boxContentBtn + (props.selected ? ` ${classes.boxContentBtnSelected}` : ''),
        ].join(' ')}
        onClick={handleSelect}
        data-testid="adsDistribution-WhereToDistribute-ButtonChannel-component" 
      >
        {props.channels.length > 1 ? (
          <Box className={classes.boxContentIcon2}>
            {props.channels.map((channel, index) => (
              <img
                key={index.toString()}
                className={props.disabled ? classes.iconDisabled : classes.icon}
                src={channel.icon}
                alt={`icon-${index}`}
                data-testid="ButtonChannel-channel-icon"
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.boxContentIcon}>
            <img
              className={props.disabled ? classes.iconDisabled : classes.icon}
              src={props.channels[0].icon}
              alt="facebook-icon"
              data-testid="ButtonChannel-channel-icon"
            />
          </Box>
        )}
        <Box className={classes.boxContentText}>
          <Box className={classes.boxContentText2} sx={{ flexDirection: 'column' }}>
            <Typography
              //style={{ opacity: !props.disabled ? 1 : 0.5 }}
              className={classes.typographyBtn}
              data-testid="title-component"
            >
              {props.title}
            </Typography>
            {/*<ButtonChannelMessage name={props.name}/>*/}
            <ButtonMessage name={props.name}/>
          </Box>
        </Box>
        <Box
          className={classes.boxContentCheckbox} >
          {!props.disabled && (
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              checked={props.selected}
              className={props.selected ? classes.checkboxSelected : classes.checkbox}
              aria-label="Global Card Checkbox"
              //onChange={(_, checked) => onSelected(checked)} Disabled because of card selection
              data-testid="dsMarketing-TabloideDigitalV2-AdsDistribution-WhereToDistribute-Checkbox"
            />
          )}
        </Box>
      </Box>
  </>
  );
}