import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState } from 'react';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function CustomizedDialogs({ open, onSave, onClose, encarteFisico = false }) {
  const [position, setPosition] = useState('header');
  const [device, setDevice] = useState('both');
  const [href, setHref] = useState('');

  const handlePositionChange = event => {
    setPosition(event.target.value);
  };

  const handleDeviceChange = event => {
    setDevice(event.target.value);
  };

  const onDrop = files =>
    onSave(
      files.map(file => ({
        position,
        device,
        href,
        file,
      }))
    );

  return (
    <Dialog open={open} maxWidth="md" fullWidth="md" onClose={onClose}>
      <DialogTitle onClose={onClose}>Upload banner</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Posição</FormLabel>
              <RadioGroup
                name="position"
                row
                value={position}
                onChange={handlePositionChange}
              >
                <FormControlLabel
                  value="header"
                  control={<Radio color="primary" />}
                  label="Cabeçalho"
                />
                <FormControlLabel
                  value="footer"
                  control={<Radio color="primary" />}
                  label="Rodape"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid 
            item
            style={
              encarteFisico 
                ? { display: 'none' }
                : {}
            }
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Dispositivo</FormLabel>
              <RadioGroup
                name="device"
                row
                value={device}
                onChange={handleDeviceChange}
              >
                <FormControlLabel
                  value="both"
                  control={<Radio color="primary" />}
                  label="Ambos"
                />
                <FormControlLabel
                  value="smartphone"
                  control={<Radio color="primary" />}
                  label="Smartphone"
                />
                <FormControlLabel
                  value="desktop"
                  control={<Radio color="primary" />}
                  label="Computador"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid 
            item xs={12}
            style={
              encarteFisico 
                ? { display: 'none' }
                : {}
            }
          >
            <TextField
              label="URL de redirecionamento"
              variant="outlined"
              fullWidth
              value={href}
              onChange={e => {
                setHref(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DropzoneArea
              dropzoneClass="ds-dropzone-one"
              dropzoneParagraphClass={`ds-text`}
              maxFileSize={6000000}
              clearOnUnmount
              showAlerts={false}
              showPreviews={false}
              showPreviewsInDropzone={false}
              dropzoneText="Selecione ou arraste uma imagem"
              acceptedFiles={['image/jpeg', 'image/png']}
              onDrop={onDrop}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Save changes
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default CustomizedDialogs;
