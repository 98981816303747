import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

function ContentRadio(props) {
  return (
    <RadioGroup
      {...props}
      defaultValue={props.defaultValue}
      name={props.value}
      value={props.value}
      onChange={props.onChange}
    >
      {props.children}
    </RadioGroup>
  );
}

function MyRadio(props) {
  return (
    <FormControlLabel
      value={props.value}
      control={<Radio color="primary" {...props} />}
      label={props.label}
    />
  );
}

export default { Radio: MyRadio, ContentRadio };
