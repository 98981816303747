/* eslint-disable prettier/prettier */
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faFileCsv,
  faMobile,
  faPaperPlane,
  faSms
} from '@fortawesome/free-solid-svg-icons';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname.includes('qa') ||
    window.location.hostname.includes('tech') ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const config_ds = async () => {
  let config_tmp = {
    api_editor: 'https://api-editor-ctg.datasales.info',
    publicacao_agendada_ativada: false
  };
  try {
    let response: any = await fetch(
      'https://datasalesio-imagens.s3.amazonaws.com/configuracoes_important/config.json',
      // 'https://storage.googleapis.com/config-ds/config2.json',
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    );

    response = await response.json();

    const response_final = {
      ...config_tmp,
      ...(isLocalhost ? { ...response?.dev } : { ...response?.prd })
    };

    console.log({ response_final });

    return response_final;
  } catch (err) {
    console.log({ err });
    return { ...config_tmp };
  }
};

export const isLocalhostWithoutRelese = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname.includes('tech') ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const isReleaseEnvironment = Boolean(window.location.hostname.includes('qa'));

export const isLocalhostNew = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname.includes('app-development') ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const API_DATA = 'https://kldlktujc6.execute-api.us-east-1.amazonaws.com/prd/';

export const WS_CHAT = 'wss://xw9k5h8t58.execute-api.us-east-1.amazonaws.com/dev';

export const API_URL_CLUBE = isLocalhost
  ? 'https://x2i8cdyymg.execute-api.us-east-1.amazonaws.com/dev/'
  : 'https://erxe310r78.execute-api.us-east-1.amazonaws.com/prd/';

// export const API_URL_BACKOFFICE = 'https://api-backoffice.datasales.info/p2-prd/';
export const API_URL_BACKOFFICE = 'https://pvk3iy472f.execute-api.us-east-1.amazonaws.com/prd/';
// export const API_URL_DEV_BACKOFFICE = 'https://api-backoffice.datasales.info/p2-dev/';
export const API_URL_DEV_BACKOFFICE = 'https://rkkarloumi.execute-api.us-east-1.amazonaws.com/dev/';

export const API_URL_ACCOUTING = 'https://api-internal.datasales.io/';
export const DATASALES_BACKOFFICE_API = 'https://api-backoffice.datasales.io/';
export const DATASALES_CRM_API = 'https://api.datasales.info/';
export const API_CONSULTA_CPF =
  'https://services.assertivasolucoes.com.br:443/v1/localize/1000/consultar';
export const AMBIENTE = isLocalhost ? 'dev' : 'prd';
export const API_WHATSAPP = isLocalhost
  ? 'https://k5f5s4uhyh.execute-api.us-east-1.amazonaws.com/dev/'
  : 'https://n9xjeqxno5.execute-api.us-east-1.amazonaws.com/prd/';
export const API_WHATSAPP_V2 = isLocalhost
  ? 'https://api.datasales.info/whatsapp-v2-dev/'
  : 'https://api.datasales.info/whatsapp-v2-prd/';
export const API_CHATBOT_V2 = isLocalhost
  ? 'https://api.datasales.info/chatbot-v2-dev/'
  : 'https://api.datasales.info/chatbot-v2-prd/';
export const API_SOCIAL_NETWORKS_V2 = isLocalhost
  ? 'https://api.datasales.info/social-networks-v2-dev/'
  : 'https://api.datasales.info/social-networks-v2-prd/';
export const API_SMS_V2 = isLocalhost
  ? 'https://api.datasales.info/sms-v2-dev/'
  : 'https://api.datasales.info/sms-v2-prd/';

export const API_POLOTNO_SERVER = 'https://k8s.datasales.info/api-editor';

// CONST PERMISSIONS TOKEN
export const GET_CONTABILIDADE = 'CONTABILIDADE_GET_PERMISSION';
export const GET_CRM = 'CRM_GET_PERMISSION';
export const GET_FINANCEIRO = 'FINANCEIRO_GET_PERMISSION';
export const GET_BACKOFFICE = 'BACKOFFICE_PERMISSION';
export const GET_USER = 'USER_GET_PERMISSION';
export const AUTHORIZATION = 'E13C4A20-F554-428F-A3DA-969C3D9F60AF';
export const LIST_EMPRESAS = [
  { value: 'MICROEMPRESA', label: 'Microempresa' },
  { value: 'PEQUENA EMPRESA', label: 'Pequeno Porte' },
  { value: 'MEDIA EMPRESA', label: 'Média Porte' },
  { value: 'GRANDE EMPRESA', label: 'Grande Porte' },
  { value: null, label: 'Não Classificada' }
];
export const PARETO = [
  { id: '00/100', label: '100%' },
  { id: '10/90', label: '90%' },
  { id: '20/80', label: '80%' },
  { id: '30/70', label: '70%' },
  { id: '40/60', label: '60%' },
  { id: '50/50', label: '50%' },
  { id: '60/40', label: '40%' },
  { id: '70/30', label: '30%' },
  { id: '80/20', label: '20%' },
  { id: '90/10', label: '10%' },
  { id: '100/00', label: '0%' }
].reverse();

export const HOST_TRACKER = [
  {
    host: 'v-ja.co/a/',
    label: 'v-ja.co'
  }
];

export const HOST_LINK = [
  {
    host: 'https://tabloide.ofertasdoclube.com.br',
    label: 'tabloide.ofertasdoclube.com.br'
  },
  {
    host: 'https://cobratudo.datasales.info',
    label: 'cobratudo.datasales.info'
  }
];

export const TIPO_ENVIO_CAMPANHA = [
  { id: 1, label: 'Informativo' },
  { id: 2.1, label: 'Mensurável' },
  { id: 2, label: 'Todos os produtos comprados' },
  { id: 3, label: 'Produtos do tabloide digital' },
  { id: 4, label: 'Produtos da oferta personalizada' }
];

export const MES = [
  { value: 1, label: 'Janeiro' },
  { value: 2, label: 'Fevereiro' },
  { value: 3, label: 'Março' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Maio' },
  { value: 6, label: 'Junho' },
  { value: 7, label: 'Julho' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Setembro' },
  { value: 10, label: 'Outubro' },
  { value: 11, label: 'Novembro' },
  { value: 12, label: 'Dezembro' }
];

export const DIAS_MES = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' },
  { value: 31, label: '31' }
];

export const SN = [
  { value: null, label: 'Todos' },
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' }
];

export const CHECK = [
  { value: null, label: 'Todos' },
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' }
];

export const GENRE = [
  { id: 'Feminino,F', label: 'Feminino' },
  { id: 'Masculino,M', label: 'Masculino' }
];

export const STATUS = [
  { value: null, label: 'Todos' },
  { value: 1, label: 'Ativo' },
  { value: 2, label: 'Inativo' }
];

export const CORES = [
  { name: 'Azul', color: '#312a5e' },
  { name: 'Amarelo', color: '#ffeb3b' },
  { name: 'Branco', color: '#fff' },
  { name: 'Cinza', color: '#9e9e9e' },
  { name: 'Laraja', color: '#ff9800' },
  { name: 'Roxo', color: '#9c27b0' },
  { name: 'Verde', color: '#4caf50' },
  { name: 'Vermelho', color: '#f44336' }
];

export const TERMOMETRO = [
  { value: null, label: 'Todos' },
  { value: 1, label: 'Quente' },
  { value: 2, label: 'Morno' },
  { value: 3, label: 'Congelando' },
  { value: 4, label: 'Congelado' }
];

export const POTENCIAIS_CONSUMIDORES = [
  { value: 1, label: 'Heavy' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'Light' }
];

export const CAMPANHAS = [
  { value: 'S', label: 'SMS' },
  { value: 'E', label: 'Email' },
  { value: 'W', label: 'Whatsapp' }
];

export const SENSIBILIDADE = [
  { value: null, label: 'Todos' },
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' }
];

export const CELULARES = [
  { value: 'celular1', label: 'Celular 1' },
  { value: 'celular2', label: 'Celular 2' },
  { value: 'celular3', label: 'Celular 3' },
  { value: 'celular4', label: 'Celular 4' }
];

export const EMAILS = [
  { value: 'email1', label: 'email 1' },
  { value: 'email2', label: 'email 2' },
  { value: 'email3', label: 'email 3' },
  { value: 'email4', label: 'email 4' }
];

export const TIPO_CAMPANHA = [
  { state: 'showModalSMS', label: 'SMS', icon: faSms },
  { state: 'showModalEmail', label: 'Email', icon: faPaperPlane },
  { state: 'showModalWhatsApp', label: 'WhatsApp', icon: faWhatsapp },
  { state: 'showModalPush', label: 'Push', icon: faMobile }
];

export const TIPO_CAMPANHA_SIMPLIFICADO = [
  { state: 'showModalSMS', label: 'SMS', icon: faSms },
  { state: 'showModalEmail', label: 'Email', icon: faPaperPlane },
  { state: 'showModalWhatsApp', label: 'WhatsApp', icon: faWhatsapp }
];

export const STATUS_PEDIDO = [
  { value: 0, label: 'Pedido cancelado' },
  { value: 1, label: 'Pedido realizado' },
  { value: 2, label: 'Em separação' },
  { value: 3, label: 'Saiu para entrega' },
  { value: 4, label: 'Disponível para retirada' },
  { value: 5, label: 'Pedido entregue/retirado' }
];

export const TIPO_PEDIDO = [
  { value: 1, label: 'Delivery' },
  { value: 2, label: 'Drive' },
  { value: 3, label: 'Delivery/Drive' }
];

export const REDES_SOCIAIS = [
  {
    value: 'FACEBOOK',
    label: 'Facebook'
  },
  {
    value: 'LINKEDIN',
    label: 'LinkedIn'
  },
  {
    value: 'INSTAGRAM',
    label: 'Instagram'
  }
];

export const EXPORTAR_CSV = [
  {
    action: 'getListaFiltroCSV',
    label: 'Carta',
    icon: faEnvelope
  },
  {
    action: 'getListaFiltroFacebookCSV',
    label: 'Facebook',
    icon: faFacebookSquare
  },
  {
    action: 'getListaFiltroFacebookCSV',
    label: 'Instagram',
    icon: faInstagram
  },
  {
    action: 'getListaFiltroLinkedinCSV',
    label: 'LinkedIn',
    icon: faLinkedin
  },
  {
    action: 'getListaFiltroCSV',
    label: 'CSV',
    icon: faFileCsv
  }
];

export const QTDE_COMPRAS = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 100,
    label: '100'
  },
  {
    value: 200,
    label: '200'
  },
  {
    value: 300,
    label: '300'
  },
  {
    value: 400,
    label: '400'
  },
  {
    value: 500,
    label: '500'
  },
  {
    value: 600,
    label: '600'
  },
  {
    value: 700,
    label: '700'
  },
  {
    value: 800,
    label: '800'
  },
  {
    value: 900,
    label: '900'
  },
  {
    value: 1000,
    label: '1000'
  }
];

export const FAIXA_ETARIA = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 40,
    label: '40'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 60,
    label: '60'
  },
  {
    value: 70,
    label: '70'
  },
  {
    value: 80,
    label: '80'
  },
  {
    value: 90,
    label: '90'
  },
  {
    value: 100,
    label: '100'
  }
];

export const FAIXA_CASHBACK = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 40,
    label: '40'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 60,
    label: '60'
  },
  {
    value: 70,
    label: '70'
  },
  {
    value: 80,
    label: '80'
  },
  {
    value: 90,
    label: '90'
  },
  {
    value: 100,
    label: '100'
  },
  {
    value: 125,
    label: '125'
  },
  {
    value: 150,
    label: '150'
  },
  {
    value: 175,
    label: '175'
  },
  {
    value: 200,
    label: '200'
  }
];

export const ESCOLARIDADE = [
  { value: 1, label: 'Nunca frequentou escola, analfabeto' },
  { value: 2, label: 'Primário incompleto, ou menos da 4ª série' },
  { value: 3, label: 'Primário completo, ou 4ª série Fundamental' },
  { value: 4, label: 'Ginasial incompleto, ou entre 4ª e 8ª série' },
  { value: 5, label: 'Ginasial completo, ou Fundamental completo' },
  { value: 6, label: 'Colegial incompleto, ou Médio incompleto' },
  { value: 7, label: 'Colegial completo, ou Médio completo' },
  { value: 8, label: 'Superior incompleto' },
  { value: 9, label: 'Superior completo' },
  { value: 10, label: 'Mestrado completo' },
  { value: 11, label: 'Doutorado completo' }
];

export const ESTADO_CIVIL = [
  { value: 9, label: 'Casado(a)' },
  { value: 10, label: 'Solteiro(a)' },
  { value: 11, label: 'Viuvo(a)' },
  { value: 12, label: 'Amasiado(a)' }
];

export const MEDIA = [
  { value: 'tv', label: 'TV' },
  { value: 'impresso', label: 'Impresso' },
  { value: 'radio', label: 'Radio' },
  { value: 'digital', label: 'Digital' },
  { value: 'carro de som', label: 'Carro de som' }
];

export const ONE_MB_SIZE_IN_BYTES = 1000000;

export const COLOR_PRIMARY = '#0C20B5';
export const COLOR_PRIMARY_2 = '#2813AD';
export const COLOR_SECONDARY = '#034873';
export const COLOR_TERTIARY = '#02395a';
export const COLOR_NEW_PRIMARY = '#2813AD';
export const COLOR_LIGHT_GRAY = '#DADADA';
export const COLOR_LIGHT_BLACK = '#616161';
export const BG_PRIMARY = '#605a5a';
export const BG_SECONDARY = '#a6a6a6';

export const UNIDADE_TEMPO = [
  // { label: 'Horas', value: 'hours' },
  { label: 'Dias', value: 'days' },
  { label: 'Semanas', value: 'weeks' },
  { label: 'Meses', value: 'months' }
  // { label: "Anos", value: "years" }
];

export const DIAS = [
  { label: 'Domingo', value: 0 },
  { label: 'Segunda', value: 1 },
  { label: 'Terça', value: 2 },
  { label: 'Quarta', value: 3 },
  { label: 'Quinta', value: 4 },
  { label: 'Sexta', value: 5 },
  { label: 'Sábado', value: 6 }
];

export const WEEKLY_DAYS_DATE_FNS = [
  { label: 'sunday', value: 0 },
  { label: 'monday', value: 1 },
  { label: 'tuesday', value: 2 },
  { label: 'wednesday', value: 3 },
  { label: 'thursday', value: 4 },
  { label: 'friday', value: 5 },
  { label: 'saturday', value: 6 }
];

export const FORM_TYPE = [
  { label: 'Questionário', value: 'QUIZ' },
  { label: 'Cadastro de clientes', value: 'ADD_CLIENTS' }
];

export const FORM_INPUT_TYPE = [
  { value: 'text', label: 'Resposta curta' },
  { value: 'textarea', label: 'Parágrafo' },
  { value: 'select', label: 'Lista suspena' },
  { value: 'date', label: 'Data' },
  { value: 'yesOrNo', label: 'Sim ou Não' }
];

export const FORM_FIELDS_PERSON = [
  { value: 'profissao', label: 'Profissão' },
  {
    value: 'documento',
    label: 'Documento'
  },
  {
    value: 'sexo',
    label: 'Gênero'
  },
  { value: 'nome', label: 'Nome' },
  { value: 'apelido', label: 'Apelido' },
  {
    value: 'data_nascimento',
    label: 'Data Nascimento'
  },
  {
    value: 'telefone1',
    label: 'Telefone 1'
  },
  {
    value: 'telefone2',
    label: 'Telefone 2'
  },
  {
    value: 'telefone3',
    label: 'Telefone 3'
  },
  {
    value: 'telefone4',
    label: 'Telefone 4'
  },
  {
    value: 'celular1',
    label: 'Celular 1'
  },
  {
    value: 'celular2',
    label: 'Celular 2'
  },
  {
    value: 'celular3',
    label: 'Celular 3'
  },
  {
    value: 'celular4',
    label: 'Celular 4'
  },
  {
    value: 'email1',
    label: 'Email 1'
  },
  {
    value: 'email2',
    label: 'Email 2'
  },
  {
    value: 'email3',
    label: 'Email 3'
  },
  {
    value: 'email4',
    label: 'Email 4'
  },
  { value: 'endereco', label: 'Endereço' },
  { value: 'cep', label: 'CEP' },
  { value: 'numero', label: 'Número' },
  { value: 'bairro', label: 'Bairro' },
  { value: 'cidade', label: 'Cidade' },
  { value: 'uf', label: 'UF' },
  { value: 'complemento', label: 'Complemento' },
  { value: 'blacklist_email', label: 'Receber email ?' },
  { value: 'blacklist_sms', label: 'Receber SMS ?' },
  { value: 'blacklist_whats', label: 'Receber whatsapp ?' }
];

export const VARIAVEL_CAMPANHA = [
  { name: 'ID Pessoa', value: '{{idPessoa}}' },
  { name: 'CPF', value: '{{documento}}' },
  { name: 'Primeiro Nome', value: '{{primeiroNome}}' },
  { name: 'Nome Completo', value: '{{nomeCompleto}}' },
  { name: 'Cupom', value: '{{voucher}}' },
  { name: 'Cashback', value: '{{cashback}}' },
  { name: 'Click Tracker', value: '{{clickTracker}}' },
  { name: 'Identificador', value: '{{identificador}}' },
  { name: 'Campo 1', value: '{{campo1}}' },
  { name: 'Campo 2', value: '{{campo2}}' },
  { name: 'Campo 3', value: '{{campo3}}' },
  { name: 'Campo 4', value: '{{campo4}}' }
];

export const CANVA_DESIGNS = [
  { label: 'Documento A4', value: 'A4Document' },
  { label: 'Anúncio ', value: 'Announcement' },
  { label: 'Cartão de aniversário ', value: 'BirthdayCard' },
  { label: 'Convite de aniversário ', value: 'BirthdayInvitation' },
  { label: 'Blog Banner', value: 'BlogBanner' },
  { label: 'Capa do livro ', value: 'BookCover' },
  { label: 'Favorito', value: 'Bookmark' },
  { label: 'Brochura', value: 'Brochure' },
  { label: 'Cartão de visitas', value: 'BusinessCard' },
  { label: 'Calendário', value: 'Calendar' },
  { label: 'Cartão', value: 'Card' },
  { label: 'Certificado', value: 'Certificate' },
  { label: 'Papel de parede', value: 'DesktopWallpaper' },
  { label: 'Cabeçalho de Email ', value: 'EmailHeader' },
  { label: 'Capa da Loja', value: 'EtsyShopCover' },
  { label: 'Ícone da loja ', value: 'EtsyShopIcon' },
  { label: 'Anúncio do Facebook', value: 'FacebookAd' },
  { label: 'Anúncio do Facebook - Aplicativo', value: 'FacebookAppAd' },
  { label: 'Capa do Facebook', value: 'FacebookCover' },
  { label: 'Capa de evento do Facebook', value: 'FacebookEventCover' },
  { label: 'Postagem no Facebook', value: 'FacebookPost' },
  { label: 'Folheto', value: 'Flyer' },
  { label: 'Certificado de presente', value: 'GiftCertificate' },
  { label: 'Infográfico', value: 'Infographic' },
  { label: 'Postagem no Instagram', value: 'InstagramPost' },
  { label: 'Instagram Story', value: 'InstagramStory' },
  { label: 'Convite', value: 'Invitation' },
  { label: 'Fatura ', value: 'Invoice' },
  { label: 'Rótulo ', value: 'Label' },
  { label: 'Anúncio de retângulo grande', value: 'LargeRectangleAd' },
  { label: 'Anúncio de cabeçalho', value: 'LeaderboardAd' },
  { label: 'Plano de aula', value: 'LessonPlan' },
  { label: 'Carta', value: 'Letter' },
  { label: 'LinkedInBanner', value: 'LinkedInBanner' },
  { label: 'Logotipo', value: 'Logo' },
  { label: 'Capa de revista', value: 'MagazineCover' },
  { label: 'Anúncio de retângulo médio', value: 'MediumRectangleAd' },
  { label: 'Cardápio', value: 'Menu' },
  { label: 'Mapa mental', value: 'MindMap' },
  { label: 'Boletim de Notícias', value: 'Newsletter' },
  { label: 'Colagem de fotos', value: 'PhotoCollage' },
  { label: 'Pinterest Gráfico', value: 'PinterestGraphic' },
  { label: 'Cartão postal', value: 'Postcard' },
  { label: 'Poster', value: 'Poster' },
  { label: 'Apresentação (16: 9)', value: 'Presentation(16:9)' },
  { label: 'Apresentação (4: 3) ', value: 'Presentation(4:3)' },
  { label: 'Rótulo do produto', value: 'ProductLabel' },
  { label: 'Cartão de Receita ', value: 'RecipeCard' },
  { label: 'Currículos', value: 'Resume' },
  { label: 'Snapchat Geofilter', value: 'SnapchatGeofilter' },
  { label: 'Postagem em mídia social', value: 'SocialMediaPost' },
  { label: 'Mídia Social Postar ', value: 'Ticket' },
  { label: 'Bilhete ', value: 'TumblrGraphic' },
  { label: 'Twitter Header', value: 'TwitterHeader' },
  { label: 'Postagem no Twitter', value: 'TwitterPost' },
  { label: 'Capa de livro Digital ', value: 'WattpadBookCover' },
  { label: 'Convite de casamento', value: 'WeddingInvitation' },
  { label: 'Anúncio de arranha-céu largo', value: 'WideSkyscraperAd' },
  { label: 'Planilha ', value: 'Worksheet' },
  { label: 'Anuário', value: 'Yearbook' },
  { label: 'anúncios em arranha-céu ', value: 'Arte do canal do YouTube' },
  { label: 'Miniatura do YouTube ', value: 'YouTubeThumbnail' }
];

