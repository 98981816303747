import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import GraphComponent from '../GraphComponent';
import { useHome } from '../../../../hooks/home';

export default function Overview() {
  const { getGraphsWhatsapp, graphsWhatsapp, loadingGraphsWhatsapp } =
    useHome();

  useEffect(() => {
    getGraphsWhatsapp();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <GraphComponent
          text1="Em espera"
          text2="Quantas mensagens faltam processar"
          array={graphsWhatsapp.wait}
          loading={loadingGraphsWhatsapp}
        />
      </Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1="Enviadas"
          text2="Quantas mensagens foram enviadas"
          array={graphsWhatsapp.sent}
          loading={loadingGraphsWhatsapp}
        />
      </Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1="Recebidos"
          text2="Quantos números receberam as mensagens"
          array={graphsWhatsapp.received}
          loading={loadingGraphsWhatsapp}
        />
      </Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1="Lidos"
          text2="Quantos números leram as mensagens"
          array={graphsWhatsapp.read}
          loading={loadingGraphsWhatsapp}
        />
      </Grid>
      <Grid item xs={4}>
        <GraphComponent
          text1="Erros"
          text2="Quantas mensagens deram erro"
          array={graphsWhatsapp.erro}
          loading={loadingGraphsWhatsapp}
        />
      </Grid>
    </Grid>
  );
}
