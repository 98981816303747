export function converterFormatoData(data: string) {
  const partes = data.split('/');
  const novaData = `${partes[2]}-${partes[1]}-${partes[0]}T00:00:00.000Z`;
  return new Date(novaData);
}

export function filtrarPorData(listaArtes: any[], dataParametro: string) {
  const dataComparacao = converterFormatoData(dataParametro);

  const artesFiltradas = listaArtes.filter(arte => {
    const dataArte = new Date(arte.createdAt);
    return dataArte <= dataComparacao;
  });

  return artesFiltradas;
}
