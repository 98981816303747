import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { MyButton } from '../../../../utils/components';

export default class ModalEnvioSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      length: 0,
      cellphone: props.number && props.number[0],
    };
  }
  render() {
    return (
      <Modal show={this.props.open}>
        <Modal.Header>
          <Modal.Title>Enviar SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '2vh',
            }}
          >
            <strong>Número destinatário: </strong>
            &nbsp;
            <select
              onChange={e => this.setState({ cellphone: e.target.value })}
              style={{ width: '15vw' }}
              className="form-control"
            >
              {this.props.number &&
                this.props.number.map((number, key) => (
                  <option key={key} value={number}>
                    {number}
                  </option>
                ))}
            </select>
          </section>
          <textarea
            className="form-control"
            style={{ resize: 'vertical' }}
            onChange={e => this.setState({ message: e.target.value })}
            onKeyPress={e => this.state.message.length - 1}
            maxLength={160}
            placeholder="Mensagem"
          />
          <span>
            {this.state.message.length}
            /160
          </span>
        </Modal.Body>
        <Modal.Footer>
          <MyButton
            bsstyle="success"
            onClick={() => {
              this.props.onSendSMS({
                cellphone: this.state.cellphone,
                id_people: this.props.idPerson,
                message: this.state.message,
              });
              this.props.onClose();
            }}
          >
            Enviar
          </MyButton>
          <MyButton onClick={this.props.onClose}>Fechar</MyButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
