import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import DialogTitle from '../../../../../../utils/components/DialogTitle';

function CreateScript({ open, onSubmit, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Adicionar script</DialogTitle>
      <Divider />
      <DialogContent>
        <Form
          initialValues={{}}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form id="upload-form" onSubmit={handleSubmit} noValidate>
              <TextField
                name="name"
                label="Nome"
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                name="code"
                label="Script"
                fullWidth
                variant="outlined"
                multiline
                rows={5}
              />
            </form>
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            document
              .getElementById('upload-form')
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateScript.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateScript;
