import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import RadioOutlined from '@material-ui/icons/RadioOutlined';
import Table from 'material-table';
import React, { useEffect, useState } from 'react';
import localization from '../../localization/table';
import api from '../../utils/helpers/Fetch';
import useStyles from './styles';

function Radios() {
  const classes = useStyles();

  const [radios, setRadios] = useState();
  const [actualRadio, setActualRadio] = useState();

  const getRadios = async () => {
    try {
      const response = await api(
        `https://4th5rl6ba7.execute-api.us-east-1.amazonaws.com/dev/radios`
      );

      setRadios(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRadios();
  }, []);

  const handleOpenURL = url => {
    window.open(url, '_blank');
  };

  return (
    <Grid container direction="column" 
      style={{ padding: '0 2rem' }}>
      <Grid item>
        <Box pt={4}>
          <Typography variant="h3" noWrap>
            Rádios
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Box pt={4} pb={2}>
          <Divider />
        </Box>
      </Grid>
      {!actualRadio && (
        <Grid item>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>
                  <RadioOutlined />
                </Avatar>
              }
              title="Rádios"
            />
            <Divider />
            <CardContent>
              <Table
                title=""
                isLoading={!radios}
                columns={[{ title: 'Nome', field: 'name' }]}
                data={radios || []}
                actions={[
                  {
                    icon: 'play_arrow',
                    tooltip: 'Abrir Player',
                    onClick: (e, rowData) => {
                      // handleOpenURL(rowData.player_url);
                      setActualRadio(rowData.player_url);
                    },
                  },
                ]}
                options={{ actionsColumnIndex: -1 }}
                localization={localization}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {actualRadio && (
        <>
          <Dialog open={!!actualRadio} fullScreen>
            <Grid item style={{ textAlign: 'end' }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={e => {
                  setActualRadio();
                }}
                // style={{
                //   position: 'absolute',
                //   right: 15,
                //   top: 11,
                // }}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item>
              <iframe
                title={'Rádio'}
                src={actualRadio}
                frameBorder="0"
                width="100%"
                height="800"
                allowtransparency="true"
              />
            </Grid>
          </Dialog>
        </>
      )}
    </Grid>
  );
}

export default Radios;

