/* eslint-disable */
import {
  Button,
  Divider,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  withStyles,
  Icon,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CustomTable,
  AutoComplete,
  TextField,
} from '../../../utils/components';
import Alert from '../../../utils/helpers/Alert';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import '../style/performance.css';
import {
  getClusterDetalhe,
  getFamalias,
  getProdutosByFamilia,
  postClusterDetalhe,
  setDetalheCluster,
  putDetalheCluster,
  getProduto,
} from '../action/action';

const alerta = new Alert();

class ClusterDetalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_subcluster: null,
      id_cluster: null,
      descricao_subcluster: '',
      descricao_cluster: '',
      familia: null,
      idFamilia: null,
      nomeProduto: '',
      idProduto: null,
      ean: '',
      id_produto: '',
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id_subcluster: id.split('_')[0] });
    this.setState({ id_cluster: id.split('_')[1] });
    this.setState({ descricao_subcluster: id.split('_')[2] });
    this.setState({ descricao_cluster: id.split('_')[3] });

    this.props.getClusterDetalhe({ id_subcluster: id.split('_')[0] });
  }

  addProduto() {
    const { id_cluster, id_subcluster } = this.state;

    if (this.props.produto.length > 0) {
      const objProduto = {
        id_cluster: id_cluster,
        id_subcluster: id_subcluster,
        deletado: 0,
        seqproduto: this.props.produto[0].SEQPRODUTO,
        produto: this.props.produto[0].PRODUTO,
        ean: this.props.produto[0].SEQEAN,
      };

      this.props.postClusterDetalhe(objProduto).then(() => {
        this.setState({
          ean: '',
          id_produto: '',
        });
      });
    } else alerta.alertError('', 'Nenhum produto selecionado!');
  }

  render() {
    const { classes } = this.props;

    const { familia, nomeProduto } = this.state;

    const { isLoadingProdutosFamilia } = this.props;

    return (
      <div className={classes.root}>
        <Link
          to={`/subcluster/${this.state.id_cluster}_${this.state.descricao_cluster}`}
          style={{ marginTop: 15, textDecoration: 'none' }}
        >
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para{' '}
            {this.state.descricao_cluster}
          </Button>
        </Link>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            {this.state.descricao_subcluster}
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 20,
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Adicionar Produto
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <TextField
                    label="EAN"
                    onChange={e =>
                      this.setState({
                        ean: e.target.value,
                      })
                    }
                    value={this.state.ean}
                  />
                </div>
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <TextField
                    label="Id Produto"
                    onChange={e =>
                      this.setState({
                        id_produto: e.target.value,
                      })
                    }
                    value={this.state.id_produto}
                  />
                </div>
                <Button
                  size="large"
                  variant="text"
                  color="primary"
                  onClick={() =>
                    this.props.getProduto({
                      ean: this.state.ean,
                      id: this.state.id_produto,
                    })
                  }
                >
                  Buscar
                </Button>
                <div
                  style={{
                    marginLeft: 24,
                  }}
                >
                  {this.props.isLoadingProduto ? (
                    <span>Carregando...</span>
                  ) : (
                    <span>
                      {this.props.produto.length > 0 &&
                        this.props.produto[0].PRODUTO}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </section>
          <div style={{ marginBottom: 20 }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              onClick={() => this.addProduto()}
            >
              Adicionar
            </Button>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div className={classes.content}>
            <CustomTable
              data={this.props.cluster_detalhe}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loadingClusterDetalhe}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  editable: 'never',
                },
                {
                  title: 'EAN',
                  field: 'ean',
                },
                {
                  title: 'Seq. Produto',
                  field: 'seqproduto',
                },
                {
                  title: 'Produto',
                  field: 'produto',
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(async resolve => {
                    this.props.putDetalheCluster(newData).then(() => {
                      let cluster_detalhe = this.props.cluster_detalhe;
                      const index = cluster_detalhe.indexOf(oldData);
                      cluster_detalhe[index] = newData;
                      this.props.setDetalheCluster(cluster_detalhe);
                      this.setState({
                        forceUpdate: this.state.forceUpdate + 1,
                      });
                      resolve();
                    });
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props
                      .putDetalheCluster({ ...oldData, deletado: 1 })
                      .then(() => {
                        let cluster_detalhe = this.props.cluster_detalhe;
                        const index = cluster_detalhe.indexOf(oldData);
                        cluster_detalhe.splice(index, 1);
                        this.props.setDetalheCluster(cluster_detalhe);
                        this.setState({
                          forceUpdate: this.state.forceUpdate + 1,
                        });
                        resolve();
                      });
                  }),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({ ClusterReducer }) => ({
  ...ClusterReducer,
});

const mapDispatchToProps = {
  getClusterDetalhe,
  getFamalias,
  getProdutosByFamilia,
  postClusterDetalhe,
  setDetalheCluster,
  putDetalheCluster,
  getProduto,
};

const ClusterDetalheConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterDetalhe);

export default withStyles(styles)(ClusterDetalheConnect);
