import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(7, 3, 3),
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    content: { padding: 0 },
    signInWithFacebookButton: {
      backgroundColor: '#1877F2',
    },
  })
);

export default styles;
