import { makeStyles, createStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';

const styles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(7, 3, 3),
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    signInWithFacebookButton: {
      backgroundColor: theme.palette.primary.main,
    },
    content: {
      padding: 0,
    },
    switchBase: {
      color: grey[800],
      '&$checked': {
        color: blue[500],
      },
      '&$checked + $track': {
        backgroundColor: blue[500],
      },
    },
    ACTIVE: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    PAUSED: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    PENDING_REVIEW: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    CREDIT_CARD_NEEDED: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    PREAPPROVED: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    DISABLED: {
      borderColor: theme.palette.grey[500],
      color: theme.palette.success.main,
    },
  })
);

export default styles;
