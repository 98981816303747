import Avatar from '@material-ui/core/Avatar';
import Button from '@mui/material/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import jwt_decode from 'jwt-decode';
import { makeValidate, TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import getInitials from '../../../../utils/helpers/getInitials';
import FileField from './components/FileField';
import useStyles from './styles';

const schema = Yup.object().shape({
  cnpj: Yup.string().required('O CNPJ é obrigatorio'),
  name: Yup.string().required('O nome é obrigatorio'),
});

const getBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

function Company() {
  const classes = useStyles();

  const [data, setData] = useState();

  const name = localStorage.getItem('name').split('"')[1];
  const email = localStorage.getItem('email').split('"')[1];

  const token = jwt_decode(`${JSON.parse(localStorage.getItem('token'))}`);
  const { isAdmin, isOperator } = token;

  const getCompany = async () => {
    try {
      const response = await api(
        `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company`
      );

      setData(response);
    } catch (err) {}
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onSubmit = async data => {
    try {
      if (data.avatar) data.avatar.base64Data = await getBase64(data.avatar);

      await api(
        `${DATASALES_CRM_API}account-settings-p1-${AMBIENTE}/crm/account-settings/company/edit`,
        data,
        'PUT'
      );
    } catch (err) {
      console.log(err);
    } finally {
      getCompany();
    }
  };

  if (!data) {
    return <h1>carregando...</h1>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9} lg={5} md={8} sm={12}> 
        <Card>
          <Form
            initialValues={data}
            validate={makeValidate(schema)}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} noValidate>

               <Grid>
                <Typography variant="h4" noWrap className={classes.titulo}>
                    Empresa
                </Typography>
                <Typography variant="p" noWrap className={classes.subTitulo}>
                    Veja as informações da sua empresa.
                </Typography>
               </Grid>
                
                <Grid item xs={3} lg={5} md={4} sm={12} 
                  style={{
                          marginTop: '24px', 
                          marginBottom: '24px',
                        }}
                          >       
                  <Paper>
                  <Grid style={{
                    display: 'flex',
                  }}
                  >
                    <Grid item style={{marginRight: '16px'}}>
                      <Avatar className={classes.avatar}>{getInitials(name)}</Avatar>
                    </Grid>
                    <Grid item 
                      style={{display: 'flex', 
                              flexDirection: 'column', 
                              justifyContent: 'space-evenly'
                              }}>
                      <Grid>
                        <Typography variant="h6" gutterBottom className={classes.nome}>
                          {name}
                        </Typography>
                        <Typography variant="body1" align="center" className={classes.email}>
                          {email}
                        </Typography>
                      </Grid>
                   </Grid>
                  </Grid>
                  </Paper>
                </Grid>


                <CardContent style={{padding:'0px', marginBottom: '15px'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField className={classes.textFormTest}
                        name="cnpj"
                        label="CNPJ"
                        variant="outlined"
                        disabled={!isAdmin && isOperator}
                        //color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField className={classes.textFormTest}
                        name="name"
                        label="Nome"
                        //color="info"
                        variant="outlined"
                        disabled={!isAdmin && isOperator}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField className={classes.textFormTest}
                        name="companyBasePath"
                        label="Path"
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    {/* 
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Avatar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FileField name="avatar" />
                    </Grid>
                    */}
                    {/*values.companyImage && !values.avatar && (
                      <Grid item xs={12}>
                        <img
                          className={classes.avatarPreview}
                          alt="company-avatar"
                          src={values.companyImage}
                        />
                      </Grid>
                    )*/}
                    {/*values.avatar && (
                      <Grid item xs={12}>
                        <img
                          className={classes.avatarPreview}
                          alt={values.avatar.name}
                          src={URL.createObjectURL(values.avatar)}
                        />
                      </Grid>
                    )*/}
                  </Grid>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    // eslint-disable-next-line
                    disabled={submitting || (!isAdmin && isOperator)}
                  >
                    Salvar
                  </Button>
                </CardActions>
              </form>
            )}
          </Form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Company;
