import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon, ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AutoCompleteLab,
  Checkbox,
  Countup,
  CustomTable,
  DatePicker,
  DialogAlert,
  Select
} from '../../../utils/components';
import MyDate from '../../../utils/helpers/Date';
import { toBase64 } from '../../../utils/helpers/Functions';
import {
  deleteProduto,
  getFamalias,
  getLojas,
  getProdutosImagens,
  postProdutoImagemTabloide,
  putEditarDetalheOferta
} from '../actions';
import useStyles from '../styles';
import DescricaoPopover from './DescricaoPopover';

const _myDate = new MyDate(),
  INITIAL_QUERY = {
    familia: null,
    codProduto: null,
    codEan: null,
    nomeProduto: null,
    emOferta: 1,
    principal: null,
    data: _myDate.getDate('YYYY-MM-DD HH:mm:ss', _myDate.getMoment()),
    noPhoto: null,
    codOferta: null,
    codAgof: null,
    idLoja: null
  },
  base64_default =
    'https://datasalesio-imagens.s3.amazonaws.com/imagem-na%CC%83o-disponivel-png-3.png';

function MonitorOfertas(props) {
  const classes = useStyles(),
    [family, setFamily] = useState({}),
    [product, setProduct] = useState({}),
    [query, setQuery] = useState(INITIAL_QUERY),
    [image, setImage] = useState({ base64: base64_default }),
    [showDeleteProduto, setShowDeleteProduto] = useState(false),
    permissions = JSON.parse(localStorage.getItem('permissions')),
    hasCreateOffer = permissions.relacionamento
      ? permissions.relacionamento.find(({ serviceName }) => serviceName === 'criar_oferta')
      : false;

  useEffect(() => {
    props.getFamalias();
    props.getLojas();
    props.getProdutosImagens({
      ...query,
      data: _myDate.getDate('YYYY-MM-DD HH:mm:ss', query.data)
    });
  }, []);

  function onInputChange(state, value) {
    const newQuery = { ...query, [state]: value };
    setQuery(newQuery);
  }

  function getProdutosImagens(query) {
    props.getProdutosImagens(query);
  }

  function updateProduct(newData, oldData) {
    return new Promise(async resolve => {
      const oferta = {
          preco_original: newData.preco_original,
          preco_desconto: newData.preco_dois,
          preco_dois: newData.preco_dois,
          porcentagem: newData.porcentagem,
          alterado_preco_original: oldData.preco_original,
          alterado_preco_desconto: oldData.preco_dois,
          alterado_preco_dois: oldData.preco_dois,
          alterado_porcentagem: oldData.porcentagem
        },
        objectUpdate = {
          oferta,
          personalizada: newData.id_oferta_personalizada === 0 ? null : true,
          one2one: newData.one2one,
          id_oferta_detalhe: newData.id_oferta_detalhe || 0,
          id_oferta_personalizada: newData.id_oferta_personalizada || 0,
          id_oferta: newData.id_oferta
        };
      await props.putEditarDetalheOferta(objectUpdate);

      if (newData.imagem_produto !== oldData.imagem_produto) {
        await props.postProdutoImagemTabloide({
          imagem_produto: newData.imagem_produto,
          id_produto: newData.SEQPRODUTO,
          ean: newData.SEQEAN
        });
      }

      getProdutosImagens(query);
      resolve();
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box pt={2} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" noWrap>
                Monitor de ofertas
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtros
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <DatePicker
                    label="Data"
                    value={query.data}
                    withKeyboard
                    format="DD/MM/YYYY"
                    onChange={date => {
                      getProdutosImagens({
                        ...query,
                        data: _myDate.getDate('YYYY-MM-DD HH:mm:ss', date)
                      });
                      onInputChange('data', _myDate.getDate('YYYY-MM-DD HH:mm:ss', date));
                    }}
                  />
                </Grid>
                <Grid item>
                  <Select
                    label="Lojas"
                    options={props.lojas}
                    value={query.idLoja}
                    onChange={loja => {
                      getProdutosImagens({
                        ...query,
                        idLoja: loja.value
                      });
                      onInputChange('idLoja', loja);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {hasCreateOffer && (
              <Grid style={{ display: 'flex' }} alignItems="flex-end" justify="flex-end" item xs>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/criar-oferta"
                >
                  Criar oferta
                </Button>
              </Grid>
            )}
          </Grid>
          <Divider className={classes.divider} />
          <Grid item container spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                margin="none"
                size="md"
                placeholder="Código do produto..."
                autoComplete="false"
                value={query.codProduto}
                onChange={({ target: { value } }) => onInputChange('codProduto', value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => getProdutosImagens(query)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="none"
                size="md"
                placeholder="Código ean..."
                autoComplete="false"
                value={query.codEan}
                onChange={({ target: { value } }) => onInputChange('codEan', value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => getProdutosImagens(query)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="none"
                size="md"
                placeholder="Código da oferta..."
                autoComplete="false"
                value={query.codOferta}
                onChange={({ target: { value } }) => onInputChange('codOferta', value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => getProdutosImagens(query)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="none"
                size="md"
                placeholder="Código ERP..."
                autoComplete="false"
                value={query.codAgof}
                onChange={({ target: { value } }) => onInputChange('codAgof', value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => getProdutosImagens(query)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <AutoCompleteLab
                disabled={props.loadingFamilias}
                groupLabel="label"
                label="Categorias"
                placeholder="Selecionar categoria..."
                loading={props.loadingFamilias}
                getOptionSelected={(option, value) => option.id_familia === value.id_familia}
                value={family}
                getOptionLabel={option => option.label}
                onChange={(event, value) => {
                  const familia = value ? value.id_familia : null;
                  getProdutosImagens({
                    ...query,
                    familia
                  });
                  setFamily(value);
                  onInputChange('familia', familia);
                }}
                options={props.familias}
              />
            </Grid>
            <Grid item>
              <Checkbox
                label="Sem foto"
                onChange={({ target: { checked } }) => {
                  const data = _myDate.getDate('YYYY-MM-DD HH:mm:ss', query.data),
                    noPhoto = checked ? 1 : null;
                  getProdutosImagens({
                    ...query,
                    noPhoto,
                    data
                  });
                  onInputChange('noPhoto', noPhoto);
                }}
              />
            </Grid>
            <Grid item>
              <Checkbox
                label="Principal"
                disabled={!query.emOferta}
                onChange={({ target: { checked } }) => {
                  const data = _myDate.getDate('YYYY-MM-DD HH:mm:ss', query.data),
                    principal = checked ? 1 : null;
                  getProdutosImagens({
                    ...query,
                    principal,
                    data
                  });
                  onInputChange('principal', principal);
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <ShoppingCartIcon />
                  </Avatar>
                }
                title="Produtos"
              />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <CustomTable
                  toolbar={false}
                  data={props.produtosImagens}
                  title=""
                  pageSize={10}
                  paging
                  isLoading={props.isLoadingProdutosImagens}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%'
                  }}
                  columns={[
                    {
                      title: '',
                      render: rowData => (
                        <DescricaoPopover
                          title={'Código ERP: ' + rowData.AGOF_CODIGO}
                          helpContent={
                            <Box display="flex" flexDirection="column" textAlign="center">
                              <Typography className={classes.label} noWrap>
                                {rowData.nome_oferta}
                              </Typography>
                            </Box>
                          }
                        />
                      )
                    },
                    {
                      field: 'imagem_produto',
                      editComponent: ({ rowData }) => (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <img
                            src={
                              rowData.imagem_produto
                                ? rowData.imagem_produto
                                : rowData.LINK_S3 || rowData.imagem_produto || image
                            }
                            crossOrigin="anonymous"
                            alt={rowData.PRODUTO}
                            style={{ width: 75, cursor: 'pointer' }}
                          />
                          <Box marginTop="2vh">
                            <input
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="contained-button-file"
                              type="file"
                              onChange={async ({ target: { files } }) => {
                                const imageEdit = await toBase64(files[0]);
                                setImage(imageEdit.base64);
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <Button size="small" component="span">
                                Upload
                              </Button>
                            </label>
                          </Box>
                        </Box>
                      ),
                      render: rowData => (
                        <img
                          alt={rowData.PRODUTO}
                          crossOrigin="anonymous"
                          src={rowData.LINK_S3 || image.base64}
                          style={{ width: 75, cursor: 'pointer' }}
                        />
                      )
                    },
                    {
                      title: '#ID',
                      field: 'id_oferta',
                      editable: 'never'
                    },
                    { title: 'Loja', field: 'nome_loja', editable: 'never' },
                    { title: 'EAN', field: 'SEQEAN', editable: 'never' },
                    { title: 'Código', field: 'SEQPRODUTO', editable: 'never' },
                    { title: 'Produto', field: 'PRODUTO', editable: 'never' },
                    { title: 'Família', field: 'FAMILIA', editable: 'never' },
                    {
                      title: 'Preço original',
                      field: 'preco_original',
                      type: 'numeric',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.preco_original}
                          duration={5}
                          separator="."
                          decimal=","
                          decimals={2}
                          prefix="R$ "
                        />
                      )
                    },
                    {
                      title: 'Preço dois',
                      field: 'preco_dois',
                      type: 'numeric',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.preco_dois}
                          duration={5}
                          separator="."
                          decimal=","
                          decimals={2}
                          prefix="R$ "
                        />
                      )
                    },
                    {
                      title: 'Porcentagem',
                      field: 'porcentagem',
                      type: 'numeric',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.porcentagem || 0}
                          duration={5}
                          separator="."
                          decimal=","
                          decimals={2}
                          suffix="%"
                        />
                      )
                    }
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) => updateProduct(newData, oldData)
                  }}
                  actions={[
                    {
                      icon: () => <Icon color="error">delete</Icon>,
                      tooltip: 'Excluir',
                      onClick: (event, rowData) => {
                        setShowDeleteProduto(true);
                        setProduct({
                          id_oferta: rowData.id_oferta,
                          id_oferta_detalhe: rowData.id_oferta_detalhe,
                          id_oferta_personalizada: rowData.id_oferta_personalizada,
                          id_produto: rowData.SEQPRODUTO
                        });
                      }
                    }
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <DialogAlert
        open={showDeleteProduto}
        title="Deseja desativar este produto da oferta?"
        onClick={() => {
          props.deleteProduto(product, false);
          getProdutosImagens(query);
          setShowDeleteProduto(false);
        }}
        onClose={() => {
          setShowDeleteProduto(false);
          setProduct({});
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ OfertasReducer }) => ({
    ...OfertasReducer
  }),
  mapDispatchToProps = {
    getFamalias,
    getProdutosImagens,
    getLojas,
    deleteProduto,
    putEditarDetalheOferta,
    postProdutoImagemTabloide
  };

export default connect(mapStateToProps, mapDispatchToProps)(MonitorOfertas);
