import React, { Fragment } from 'react';

//Using Components
import { Typography } from '@material-ui/core';
import { Select, TextField } from '../../../../utils/components';

//Using Variables
import { COLOR_PRIMARY, FORM_TYPE } from '../../../../utils/helpers/Constants';

export default function(props) {
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Informações do formulário
      </Typography>
      <Select
        defaultValue={props.form.typeForm}
        label="Tipo do formulário"
        options={FORM_TYPE}
        onChange={type => props.onChange('typeForm', type)}
      />
      <TextField
        defaultValue={props.form.nameForm}
        label="Nome do formulário"
        onChange={({ target: { value } }) => props.onChange('nameForm', value)}
      />
    </Fragment>
  );
}
