import { createStyles, makeStyles } from '@material-ui/styles';


export const useStyles = makeStyles(theme =>
  createStyles({
    bannerInsert: {
      width: '100%',
      height: '71px',
      background: '#2813AD',
      borderRadius: '4px',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.05em',
      color: 'white',

      [theme.breakpoints.down('md')]: {
        fontSize: 14
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },

      '& .MuiButtonBase-root': {
        padding: '12px 16px',
        background: '#FFFFFF',
        color: '#2813AD',
        height: 40,
        fontSize: 16,
        width: 'auto',

        '&:hover': {
          color: '#FFFFFF',
          border: '1px solid #FFFFFF'
        },

        '& .css-11lq3yg-MuiGrid-root': {
          height: '71px'
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
          padding: '6px',
          marginRight: 8,
          height: 40,
          width: 'auto'
        }
      }
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '71px'
    },
    img: {
      marginLeft: 122,

      [theme.breakpoints?.down('sm')]: {
        marginLeft: 0
      }
    }
  })

);