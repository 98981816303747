import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { COLOR_PRIMARY } from '../../helpers/Constants';

export default function CustomChip({
  active,
  label,
  clickable,
  onClick,
  onDelete,
  color,
}) {
  const classes = useStyles();

  return (
    <Chip
      active={active || false}
      label={label}
      className={classes.chip}
      component="a"
      clickable={clickable || false}
      color="primary"
      style={{
        color: !active ? (color ? color : COLOR_PRIMARY) : null,
        borderColor: !active ? (color ? color : COLOR_PRIMARY) : null,
        backgroundColor: active ? (color ? color : COLOR_PRIMARY) : null,
        borderRadius: 6,
      }}
      onClick={onClick}
      onDelete={onDelete}
      variant={active ? 'default' : 'outlined'}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1, 2, 1, 0),
  },
}));
