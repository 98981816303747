import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';

import oldPortalImg from '../../../../assets/images/oldPortalImg.png';
import { useStyles } from '../../styles';

export default function OldPortal() {
  const classes = useStyles();
  const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')!));

  return (
    <Box className={[classes.cardItem, classes.oldPortal].join(' ')}>
      <Box className={classes.title} style={{ fontWeight: 500, marginBottom: 8 }}>
        Portal tradicional
      </Box>
      <Box className={classes.cardContent}>
        <Box>
          Este é o portal da Tradicional da Datasales. Ele contém todas as funcionalidades que você
          já usa todos os dias sem nenhuma atualização.
        </Box>
        <Box className={classes.cardWarning}>
          Você está usando a versão antiga da Datasales, que será desabilitada no dia 01/04/2023. 
          Acesse o novo portal ao lado e acompanhe as novidades
        </Box>
        <img src={oldPortalImg} alt="Portal Tradicional" />
        <Box style={{ fontSize: 12, visibility: 'hidden' }}>
          *esta versão será descontinuada no dia
        </Box>
        <Button
          className={classes.button}
          style={{
            color: '#2813AD',
            border: '1px solid #2813AD',
            // marginTop: 32,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.05em'
          }}
          href={`https://old.datasales.info/sign-as-user/${token}`}
        >
          Acessar Portal tradicional
        </Button>
      </Box>
    </Box>
  );
}

