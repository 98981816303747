import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  active: {
    backgroundColor: blue[100],
    fontWeight: theme.typography.fontWeightBold,
    color: blue[600],
    transition: '0.5s all ease-out',
    '&:hover': {
      backgroundColor: blue[100],
      borderColor: blue[100],
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: blue[100],
      borderColor: blue[100],
    },
    '&:focus': {
      boxShadow: blue[100],
    },
  },
}));

export default useStyles;
