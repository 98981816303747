import apiV2 from '../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../utils/helpers/Constants';
import { ResponseCategories, ResponseSubcategories } from 'types/professional';

export const findCategories = async (segmentId: number): Promise<ResponseCategories[]> => {
  const response = await apiV2(
    `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/categories/listBySegment/${segmentId}`,
    null,
    'GET',
    null,
    null,
    false
  );

  return response.content;
};

export const findSubcategories = async (
  categoryId: number,
  limit?: number,
  offset?: number
): Promise<ResponseSubcategories[]> => {
  const query = limit && offset ? `?limit=${limit}&offset=${offset}` : undefined;
  const response = await apiV2(
    `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/templates/listByCategory/${categoryId}${query}`,
    null,
    'GET',
    null,
    null,
    false
  );

  return response.content;
};

export const findSubcategoriesDescription = async (
  categoryId: number,
  text?: string
): Promise<ResponseSubcategories[]> => {
  const description = text ? `?description=${text}` : '?description=';
  const response = await apiV2(
    `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/subcategories/listByDescription/${categoryId}${description}`,
    null,
    'GET',
    null,
    null,
    false
  );

  return response.content;
};

export const findSubcategoriesInAll = async (
  categoryId: number,
  subcategoryId: number
): Promise<ResponseSubcategories[]> => {
  const query = subcategoryId
    ? `?id_subcategory=${subcategoryId}&searchInAllCategories=true`
    : undefined;

  const response = await apiV2(
    `${DATASALES_CRM_API}fast-models-dsmarketing-v1-${AMBIENTE}/${AMBIENTE}/templates/listByCategory/${categoryId}${query}`,
    null,
    'GET',
    null,
    null,
    false
  );

  return response.content;
};

