import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Card, CustomTable } from '../../../utils/components';
import {
  getReprocessoAudienciaSaida,
  getReprocessoAudienciaEntrada,
} from '../actions/actions';

function MovimentacaoReprocesso(props) {
  const reprocesso = props.reprocessoAudiencia;
  const saidas = props.reprocessoAudienciaSaida;
  const entradas = props.reprocessoAudienciaEntrada;
  const classes = useStyles();

  useEffect(() => {
    props.getReprocessoAudienciaSaida(reprocesso.ID_PROSPECCAO_LISTA);
    props.getReprocessoAudienciaEntrada(reprocesso.ID_PROSPECCAO_LISTA);
  }, [reprocesso]);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Card title="Entrada" showMore>
          <CustomTable
            paging
            search
            columnsButton
            pageSize={7}
            columns={[
              { title: '#ID', field: 'id_pessoa' },
              { title: 'Nome', field: 'nome' },
              { title: 'Celular', field: 'celular1' },
              { title: 'Email', field: 'email1' },
              { title: 'Documento', field: 'documento' },
              { title: 'Quantidade compras', field: 'qt_compras' },
              { title: 'Ticket médio', field: 'ticket_medio' },
              { title: 'Valor total', field: 'valor_total_gasto' },
            ]}
            data={entradas}
          />
        </Card>

        <Card title="Saída" showMore>
          <CustomTable
            paging
            search
            columnsButton
            pageSize={7}
            columns={[
              { title: '#ID', field: 'id_pessoa' },
              { title: 'Nome', field: 'nome' },
              { title: 'Celular', field: 'celular1' },
              { title: 'Email', field: 'email1' },
              { title: 'Documento', field: 'documento' },
              { title: 'Quantidade compras', field: 'qt_compras' },
              { title: 'Ticket médio', field: 'ticket_medio' },
              { title: 'Valor total', field: 'valor_total_gasto' },
            ]}
            data={saidas}
          />
        </Card>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const mapStateToProps = ({ InsightsReducer }) => ({
  ...InsightsReducer,
});

const mapDispatchToProps = {
  getReprocessoAudienciaSaida,
  getReprocessoAudienciaEntrada,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimentacaoReprocesso);
