import { WhatsApp as WhatsAppIcon } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import { AutoFixHighOutlined } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateProps } from '../../../../../../store/types';
import { findAutoMessage } from '../../../../action';
import { DataProps } from '../../../../reducer/types';
import FormObserver from '../../../FormObserver';
import useStyles from '../../styles';
import Maps from '../Maps';
import PlacesAutocomplete from '../PlacesAutocomplete';
import Slider from '../Slider';
import Credits from './components/Credits';
import { useStyles as useStylesForm } from './styles';

export type FormDataProps = {
  initialValues: {
    start_time: Moment;
    end_time: Moment;
    lifetime_budget: number;
    custom_audience: null;
    age_min: number;
    age_max: number;
    gender: string;
    interests: [];
    objective: {
      subtitle: string;
      title: string;
      value: string;
    };
    location: {
      latitude: string | undefined | null;
      longitude: string | undefined | null;
      radius: number;
    };
    link: string;
    text: string;
  };
  validateOnChange: boolean;
  validationSchema: any;
  onSubmit: () => void;
};

const objectiveOptions = [
  {
    subtitle: 'Alcance',
    title: 'Mostrar para o maior número de pessoas',
    value: 'OUTCOME_AWARENESS'
  },
  {
    subtitle: 'Engajamento',
    title: 'Obter mais curtidas, comentários ou compartilhamentos na publicação',
    value: 'OUTCOME_ENGAGEMENT'
  },
  {
    subtitle: 'Tráfego',
    title: 'Levar o maior número de pessoas para o Link da Publicação',
    value: 'OUTCOME_TRAFFIC'
  }
];

const estimatedReach = [
  '3.500 a 7.000',
  '6.000 a 10.500',
  '10.000 a 15.000',
  '14.500 a 19.000',
  '19.500 a 25.000'
];

const minDistance = 6;

export type FormProps = {
  formik: any;
  uuid: string;
  targetOptions: [];
  balance: string;
  enoughCredits: boolean;
  isModal: boolean;
  expanded: boolean;
  setTargetQuery: (targetQuery: string) => void;
  loadingInterests: boolean;
  handlePreviewAds: () => void;
  handleLocationChange: () => void;
  handleExpandClick: () => void;
  handleGenerateLinkOpen: () => void;
  handleChangeOnClickAutoMessage?: () => void;
  backgroundId: number;
};

function Form({
  formik,
  uuid,
  targetOptions,
  balance,
  enoughCredits,
  isModal = false,
  expanded,
  setTargetQuery,
  loadingInterests,
  handlePreviewAds,
  handleLocationChange,
  handleExpandClick,
  handleGenerateLinkOpen,
  backgroundId,
  handleChangeOnClickAutoMessage,
  ...props
}: FormProps) {
  const classes = useStyles();
  const classesForm = useStylesForm();
  const dispatch = useDispatch();
  const {
    adsDistribution: { selectedChannelsFormData },
    selectedShareableItem,
    loading_auto_message
  } = useSelector((state: StateProps) => state.TabloideDigitalReducer);

  useEffect(() => {
    const compare = (field: DataProps) =>
      'post' in field && field?.post ? 'post' : 'text' in field && field?.text ? 'text' : 'post';

    const valueFirst =
      selectedChannelsFormData[0]?.data[compare(selectedChannelsFormData[0]?.data)];
    if (valueFirst) {
      formik.setFieldValue('text', valueFirst);
    }
  }, []);

  useEffect(() => {
    if (formik.errors.hasOwnProperty('link')) {
      handleGenerateLinkOpen();
    }
  }, [formik.errors.link]);

  const handleChangeAutoMessage = useCallback(() => {
    dispatch(findAutoMessage({ background_id: backgroundId, channel: 'action' }));
  }, [backgroundId]);

  const isScheduled = selectedShareableItem?.[0]?.dateScheduled;

  return (
    <form data-testid="dsMarketing-turbocharged-action-form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontSize: '24px', fontWeight: '500' }}>
            Impulsionamento
          </Typography>
          <Button onClick={() => handlePreviewAds()}> Visualizar Preview </Button>
          <Typography
            style={{
              padding: '8px 0 32px',
              fontSize: '14px',
              borderBottom: '1px solid #CFCFCF'
            }}
          >
            As publicações do tipo impulsionamento não aparecem no perfil das suas redes sociais.
            Elas aparecem apenas para as pessoas das quais você seleciona no perfil abaixo.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item>
            <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
              Objetivo da publicação
            </Typography>
          </Grid>
          <Autocomplete
            classes={{ popper: classes.popper }}
            options={objectiveOptions}
            autoComplete
            getOptionLabel={option => option.title || ''}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            value={formik.values.objective}
            onChange={(_, newValue) => {
              formik.setFieldValue('objective', newValue);
            }}
            renderOption={(props, option) => (
              <li style={{ textAlign: 'left' }} {...props}>
                <Grid container>
                  <Grid item>
                    <Typography align="left" variant="body1" color="textPrimary" display="block">
                      {option.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="left" variant="body2" color="textSecondary" display="block">
                      {option.subtitle}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                name="objective"
                placeholder="Pesquisa por interesse ou comportamento"
                variant="outlined"
                fullWidth
                data-testid="dsMarketing-turbocharged-action-interests-or-behavior-field"
                error={
                  formik.touched.hasOwnProperty('objective') ||
                  formik.errors.hasOwnProperty('objective')
                }
                helperText={
                  formik.touched.hasOwnProperty('objective')
                    ? formik?.touched?.objective
                    : formik?.errors?.objective
                }
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}
          />

          {!isNaN(parseInt(balance)) && (parseInt(balance) <= 0 || !enoughCredits) && (
            <Alert severity="error" className={classes.balanceAlert}>
              Créditos insuficientes! Adquira mais para continuar.
            </Alert>
          )}

          <Typography
            style={{
              fontWeight: '500',
              marginTop: '24px',
              color: '#616161'
            }}
          >
            Força da publicação
          </Typography>
          <Slider
            name="post-power"
            getAriaLabel={index => 'post-power' + index}
            defaultValue={1000}
            valueLabelDisplay="auto"
            step={1000}
            min={1000}
            max={5000}
            value={formik.values.lifetime_budget}
            valueLabelFormat={value => value / 1000}
            data-testid="dsMarketing-turbocharged-action-post-power-field"
            onChange={(_, newValue) => {
              formik.setFieldValue('lifetime_budget', newValue);

              const newDate = moment(formik.values.start_time).add(
                formik.values.lifetime_budget / 1000,
                'day'
              );

              if (newDate < formik.values.end_time) formik.setFieldValue('end_time', newDate);
            }}
          />
          {!isModal && (
            <Box>
              <Typography gutterBottom className={classesForm.postText} component="span">
                Alcance estimado:{' '}
                {estimatedReach[parseInt(formik.values.lifetime_budget) / 1000 - 1]}
              </Typography>
              <Credits />
              <Typography
                gutterBottom
                className={classesForm.postText}
                style={{ color: '#2813AD', fontWeight: 500 }}
                component="span"
              >
                Esta ação vai consumir {parseInt(formik.values.lifetime_budget) / 1000} créditos
              </Typography>
            </Box>
          )}
          <Divider style={{ marginTop: '32px' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
            Para qual público deseja enviar
          </Typography>

          <Box
            sx={{
              backgroundColor: '#F5F5F5',
              borderRadius: '4px',
              padding: '12px',
              marginBottom: '24px'
            }}
          >
            <Typography gutterBottom component="strong">
              Faixa etária
            </Typography>
            <Slider
              getAriaLabel={index => 'public-age' + index}
              defaultValue={18}
              valueLabelDisplay="auto"
              step={1}
              min={13}
              max={65}
              value={[formik.values.age_min, formik.values.age_max]}
              data-testid="dsMarketing-turbocharged-action-age-range-field"
              onChange={(event, newValue, activeThumb) => {
                if (!Array.isArray(newValue)) return;

                if (newValue[1] - newValue[0] < minDistance) {
                  if (activeThumb === 0) {
                    const clamped = Math.min(newValue[0], 100 - minDistance);
                    formik.setFieldValue('age_min', clamped);
                    formik.setFieldValue('age_max', clamped + minDistance);
                  } else {
                    const clamped = Math.max(newValue[1], minDistance);
                    formik.setFieldValue('age_min', clamped - minDistance);
                    formik.setFieldValue('age_max', clamped);
                  }
                } else {
                  formik.setFieldValue('age_min', newValue[0]);
                  formik.setFieldValue('age_max', newValue[1]);
                }
              }}
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
                Localidade
              </Typography>
              <PlacesAutocomplete name="locales" onChange={handleLocationChange} formik={formik} />
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
                Distância
              </Typography>
              <TextField
                name="location.radius"
                variant="outlined"
                type="number"
                fullWidth
                value={formik.values.location.radius}
                onChange={formik.handleChange}
                data-testid="dsMarketing-turbocharged-action-radius-field"
                error={
                  formik.touched?.location?.hasOwnProperty('radius') ||
                  formik.errors.location?.hasOwnProperty('radius')
                }
                helperText={
                  formik.touched?.location?.hasOwnProperty('radius')
                    ? formik?.touched?.location?.radius
                    : formik?.errors?.location?.radius
                }
              />
            </Grid>
            {formik.values.location.latitude && (
              <Grid item xs={12}>
                <Maps location={formik.values.location} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
                classes={{ popper: classes.popper }}
                options={targetOptions}
                autoComplete
                multiple
                value={formik.values.interests}
                onChange={(_, newValue) => {
                  formik.setFieldValue('interests', newValue);
                }}
                onInputChange={(_, newInputValue) => {
                  setTargetQuery(newInputValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Pesquisar Interesses"
                    variant="outlined"
                    fullWidth
                    data-testid="dsMarketing-turbocharged-action-interests-field"
                    error={
                      formik.touched.hasOwnProperty('interests') || Boolean(formik.errors.interests)
                    }
                    helperText={
                      formik.touched.hasOwnProperty('interests')
                        ? formik?.touched?.interests
                        : formik?.errors?.interests
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingInterests ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel className={classes.textPrimary} component="legend" classes={{}}>
                  Gênero
                </FormLabel>
                <RadioGroup
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  row
                  data-testid="dsMarketing-turbocharged-action-gender-field"
                >
                  <FormControlLabel value="all" control={<Radio />} label="Todos" />
                  <FormControlLabel value="male" control={<Radio />} label="Homens" />
                  <FormControlLabel value="female" control={<Radio />} label="Mulheres" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '32px' }} />
        </Grid>

        {!isScheduled && (
          <Grid item xs={12} style={{ paddingTop: '32px' }}>
            <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
              Quando deseja enviar a mensagem?
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: '40px' }}>
              <Grid item xs={6}>
                <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#616161' }}>
                  Data de inicio
                </Typography>

                <DateTimePicker
                  placeholder="Data de início"
                  ampm={false}
                  disablePast
                  fullWidth
                  inputVariant="outlined"
                  format="DD/MM/YYYY HH:mm"
                  value={formik.values.start_time}
                  data-testid="dsMarketing-turbocharged-action-start-date-field"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.inputNoBorder,
                      root: classes.inputBackgroundColor
                    }
                  }}
                  onChange={date => {
                    if (
                      moment(formik.values.end_time).isAfter(
                        moment(date).add(formik.values.lifetime_budget / 1000, 'day')
                      )
                    ) {
                      formik.setFieldValue(
                        'end_time',
                        moment(date).add(formik.values.lifetime_budget / 1000, 'day')
                      );
                    }
                    formik.setFieldValue('start_time', date);
                  }}
                  error={
                    formik.touched.hasOwnProperty('start_time') ||
                    formik.errors.hasOwnProperty('start_time')
                  }
                  helperText={`${
                    formik.touched.hasOwnProperty('start_time')
                      ? formik.touched.start_time
                      : formik.errors?.start_time || ''
                  }`}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#616161' }}>
                  Data de Término
                </Typography>

                <DateTimePicker
                  placeholder="Data de término"
                  ampm={false}
                  minDate={moment(formik.values.start_time).add(1, 'day')}
                  maxDate={moment(formik.values.start_time).add(
                    formik.values.lifetime_budget / 1000,
                    'day'
                  )}
                  disablePast
                  fullWidth
                  // nullable
                  inputVariant="outlined"
                  format="DD/MM/YYYY HH:mm"
                  value={formik.values.end_time}
                  data-testid="dsMarketing-turbocharged-action-end-data-field"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.inputNoBorder,
                      root: classes.inputBackgroundColor
                    }
                  }}
                  onChange={date => {
                    if (
                      moment(date).isAfter(
                        moment(formik.values.start_time).add(
                          formik.values.lifetime_budget / 1000,
                          'day'
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        'end_time',
                        moment(formik.values.start_time).add(
                          formik.values.lifetime_budget / 1000,
                          'day'
                        )
                      );
                      return;
                    }
                    formik.setFieldValue('end_time', date);
                  }}
                  error={
                    formik.touched.hasOwnProperty('end_time') ||
                    formik.errors.hasOwnProperty('end_time')
                  }
                  helperText={`${
                    formik.touched.hasOwnProperty('end_time')
                      ? formik.touched.end_time
                      : formik.errors.end_time || ''
                  }`}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: '500', color: '#000000' }}>
                Qual texto da publicação
              </Typography>
              <Button
                onClick={() => {
                  if (handleChangeOnClickAutoMessage) {
                    handleChangeOnClickAutoMessage();
                  }
                  handleChangeAutoMessage();
                }}
                disabled={loading_auto_message}
                color="primary"
                variant="text"
                size="small"
                startIcon={<AutoFixHighOutlined />}
              >
                {loading_auto_message ? 'Digitando ...' : 'Outra sugestão de mensagem'}
              </Button>
            </Box>
            <TextField
              fullWidth
              name="text"
              margin="normal"
              variant="outlined"
              multiline
              placeholder="Digite o seu texto"
              disabled={loading_auto_message}
              rows={6}
              value={formik?.values?.text}
              onChange={formik.handleChange}
              data-testid="dsMarketing-turbocharged-action-text-message-field"
              error={formik.touched.hasOwnProperty('text') || formik.errors.hasOwnProperty('text')}
              helperText={
                formik.touched.hasOwnProperty('text') ? formik?.errors?.text || 'Necessário' : ''
              }
            />

            <Divider style={{ marginTop: '32px' }} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: '500', marginBottom: '16px', color: '#000000' }}>
                Link da publicação
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="link"
                variant="outlined"
                placeholder="https://"
                value={formik.values.link}
                onChange={formik.handleChange}
                data-testid="dsMarketing-turbocharged-action-link-field"
                error={
                  formik.touched.hasOwnProperty('link') || formik.errors.hasOwnProperty('link')
                }
                helperText={`${
                  formik.touched.hasOwnProperty('link')
                    ? formik.touched.link
                    : formik.errors.link || ''
                }`}
              />

              <Typography
                gutterBottom
                style={{ fontSize: '14px', fontWeight: 500, margin: '8px 0' }}
              >
                Link da publicação é o link que vai direcionar as pessoas que clicarem no seu
                anúncio.
              </Typography>

              <Box
                sx={{
                  backgroundColor: '#E6F7FF',
                  border: '1px solid #1890FF',
                  borderRadius: '8px',
                  padding: '0.5rem'
                }}
              >
                <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: 500 }}>
                  Dica Datasales: nós recomendamos colocar o link do whatsapp (clicando no botão
                  abaixo) ou Instagram do seu negócio para redirecionar as pessoas clicarem no seu
                  anúncio.
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={handleGenerateLinkOpen}
                startIcon={<WhatsAppIcon />}
                data-testid="form-button-add-whatsapp"
                sx={{
                  color: '#0C20B5 !important'
                }}
              >
                Adicionar WhatsApp
              </Button>
            </Grid>
          </Grid>
          {(Object.keys(formik.touched).length > 0 || Object.keys(formik.errors).length > 0) && (
            <Alert variant="outlined" severity="error" className={classes.disclaimerError}>
              Preencha todos os campos obrigatórios
            </Alert>
          )}
        </Grid>
      </Grid>

      <FormObserver channel="turbocharged-action" values={formik.values} />
    </form>
  );
}

export default React.memo(Form);
