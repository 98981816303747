import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const InfoDeviceDialog = ({ open, instancia, onClose }) => {
  if (!instancia || (instancia && !instancia.status)) return "";
  const { dispositivo = {} } = instancia.status;
  const { Phone = {} } = dispositivo;

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle>Informações do dispositivo</DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Nome</b>
            </Typography>
            <Typography variant="body1">{dispositivo.Pushname}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Número</b>
            </Typography>
            <Typography variant="body1">
              {(dispositivo.Wid || "").replace(/\D/g, "")}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Plataforma</b>
            </Typography>
            <Typography variant="body1">{dispositivo.Platform}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Modelo</b>
            </Typography>
            <Typography variant="body1">{Phone.DeviceManufacturer}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="outlined">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InfoDeviceDialog.propTypes = {
  instancia: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

InfoDeviceDialog.defaultProps = {
  instancia: {},
  open: false,
  onClose: () => {}
};

export default InfoDeviceDialog;
