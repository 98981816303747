import React, { Fragment } from 'react';

//Using Components
import { Typography } from '@material-ui/core';
import { Select, Countup } from '../../../../utils/components';

//Using Variables
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

export default function TabAudiencia(props) {
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Escolha uma audiência para enriquecer
      </Typography>
      <Select
        onChange={payload => {
          const idListProspectei = Boolean(payload)
            ? payload.idListProspectei
            : null;
          props.onSelectAudiencia(idListProspectei);
          props.onChange('audiencia', payload);
        }}
        value={props.enrichment.audiencia}
        labelKey="nameList"
        valueKey="idListProspectei"
        options={props.prospeccao}
      />
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Informações do enriquecimento
      </Typography>
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <strong style={{ fontSize: '14pt' }}>
          Valor total:{' '}
          <Countup decimals={2} prefix="R$ " end={props.price.total_price} />
        </strong>
        <span>
          Valor unitário:{' '}
          <strong>
            <Countup decimals={2} prefix="R$ " end={props.price.unit_price} />
          </strong>
        </span>
        <span>
          Quantidade:{' '}
          <strong>
            <Countup end={props.price.quantity} />
          </strong>
        </span>
      </section>
    </Fragment>
  );
}
