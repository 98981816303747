import Fetch from "../../../utils/helpers/Fetch";
import { DATASALES_CRM_API, AMBIENTE } from "../../../utils/helpers/Constants";

const isLoading = () => dispatch => dispatch({ type: "LOADER" });

export const onChange = (prop, value) => dispatch =>
  dispatch({ type: "ON_CHANGE", payload: { prop, value } });

export const postCupomCashback = cupomCash => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}oferta-p1-${AMBIENTE}/${AMBIENTE}/crm/postCupomCashback`,
      cupomCash,
      "POST"
    );
    dispatch(getCupomCashback());
  } catch (err) {
    console.log(err);
  }
};

export const getCupomCashback = cupom => async dispatch => {
  try {
    dispatch(isLoading());
    const payload = await Fetch(
      `${DATASALES_CRM_API}oferta-p2-${AMBIENTE}/${AMBIENTE}/crm/getCupomCashback`,
      cupom
    );
    dispatch({ type: "GET_CUPOM_CASHBACK", payload });
  } catch (err) {
    console.log(err);
  }
};
