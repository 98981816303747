import { Box, CircularProgress, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import DialogTitle from '../../../../../utils/components/DialogTitle';
import { translateStatusSocialNetworks } from '../../../../../utils/helpers/Format';
import {
  getCommentsFacebook,
  getCommentsInstagram,
  getDesempenhoEnvio,
  getDesempenhoFacebook,
  getDesempenhoInstagram,
  getTaxaClique
} from '../../../action/action';
import useStyles from '../styles';
import ClickRate from './ClickRate';
import Comments from './Comments';
import Preview from './Preview';
import Reach from './Reach';
import Resume from './Resume';
import { Alert } from '@material-ui/lab';
import AlertTitle from '@mui/material/AlertTitle';

export default function Details({ open, details, onClose }) {
  const classes = useStyles(),
    [error, setError] = useState(null),
    [loading, setLoading] = useState(false),
    [campaign, setCampaign] = useState({}),
    [reach, setReach] = useState([]),
    [rate, setRate] = useState([]),
    [comments, setComments] = useState([]),
    clickRate = useCallback(async () => {
      try {
        setLoading(true);
        setError(null);
        const content = await getTaxaClique(details.ID_CAMPANHA);
        setRate(content);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, [details]),
    insightsFacebook = useCallback(async () => {
      try {
        setLoading(true);
        setError(null);
        const { content } = await getDesempenhoFacebook(details.ID_CAMPANHA);

        if (content.error) {
          setError(content.search_error.friendly_message || content.post.error_message);
          console.log(setError)
          return;
        }

        setCampaign(content.campaign[0]);
        if (content.insights.comments_count > 0) {
          const response = await getCommentsFacebook(details.ID_CAMPANHA);
          setComments(response.content.comments);
        }
        delete content.insights.fb_post_id;
        const status = Object.entries(content.insights).map(value => ({
          quantidade: value[1],
          status_envio_externo: translateStatusSocialNetworks(value[0])
        }));
        setReach(status);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, [details]),
    insightsInstagram = useCallback(async () => {
      try {
        setLoading(true);
        setError(null);
        const { content } = await getDesempenhoInstagram(details.ID_CAMPANHA);

        if (content.error) {
          setError(content.search_error.friendly_message || content.post.error_message);
          return;
        }

        setCampaign(content.campaign[0]);
        if (content.insights.comments_count > 0) {
          const response = await getCommentsInstagram(details.ID_CAMPANHA);
          const comments = response.content.comments.map(comment => ({
            ...comment,
            replies: response.content.replies
              .filter(reply => reply && reply.comment_id === comment.comment_id)
              .reverse()
          }));

          setComments(comments);
        }
        delete content.insights.post_id_fk;
        const status = Object.entries(content.insights).map(value => ({
          quantidade: value[1],
          status_envio_externo: translateStatusSocialNetworks(value[0])
        }));
        setReach(status);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, [details]),
    insights = useCallback(async () => {
      try {
        setLoading(true);
        setError(null);
        const content = await getDesempenhoEnvio(details.ID_CAMPANHA);
        setCampaign(content.lista[0]);
        setReach(content.status);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, [details]),
    _onClose = useCallback(() => {
      setCampaign({});
      setReach([]);
      setRate([]);
      setComments([]);
      setError(null);
      onClose();
    }, [onClose]);

  useEffect(() => {
    if (details) {
      if (details.tipo_campanha.includes('FACEBOOK')) {
        insightsFacebook();
      } else if (details.tipo_campanha.includes('INSTAGRAM')) {
        insightsInstagram();
      } else {
        insights();
        if (details.tipo_campanha === 'SMS' || details.tipo_campanha === 'EMAIL') {
          clickRate();
        }
      }
    }
  }, [details]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={_onClose}>
      <DialogTitle onClose={_onClose}>Detalhes</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          {loading ? (
            <Grid item xs={12}>
              <Box className={classes.loading}>
                <CircularProgress color="primary" />
              </Box>
            </Grid>
          ) : !Boolean(error) ? (
            <>
              <Grid item xs={5}>
                <Preview campaign={campaign} type={details.tipo_campanha} />
              </Grid>
              <Grid item xs={7}>
                <Grid container justify="space-between" style={{ display: 'block' }}>
                  <Resume data={campaign} details={details} />
                  <Reach data={reach} />
                  {rate.length ? <ClickRate data={rate} /> : <div />}
                  {comments.length ? (
                    <Comments data={comments} type={details.tipo_campanha} />
                  ) : (
                    <div />
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Alert
              severity="error"
              style={{ color: '#616161', backgroundColor: '#ffccc793', width: '100%', borderRadius: '2px', margin: '30px' }}
            >
              {/* Call the friendely message*/}
              <AlertTitle>A arte não foi postada devido a um erro!</AlertTitle>
              {error}
              {/* error.friendely_message*/}
            </Alert>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

