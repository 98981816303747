import React, { useState } from 'react';

import { Box, Typography, Tabs, Tab } from '@material-ui/core';

import { TabPanel } from './TabPanel';
import Chat from './Chat';
import { GeneralNumbersMetrics } from './GeneralNumbersMetrics';
import ChatInformationTable from './ChatInformationTable';
import { ChatInformationTableEmployee } from './ChatInformationTableEmployee';

import useStyles from '../styles';

const tabs = [
  { label: 'Visão consolidada', key: 0 },
  { label: 'Resultado dos agentes', key: 1 },
];

export default function () {
  const classes = useStyles();
  const [chatTabValue, setChatTabValue] = useState(0);
  const handleChangeChatTabValue = (_, newValue) => setChatTabValue(newValue);

  function a11yProps(index) {
    return {
      id: `dsomni-tab-${index}`,
      'aria-controls': `dsomni-tabpanel-${index}`,
    };
  }

  return (
    <Box className={classes.containerChat}>
      <Typography color="primary" className={classes.title}>
        Visão geral
      </Typography>
      {/* TabController */}
      <Box className={classes.informationTabContainer}>
        <Tabs
          value={chatTabValue}
          onChange={handleChangeChatTabValue}
          aria-label="Abas do ds.omni Chat"
          indicatorColor="transparent"
        >
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              style={{ paddingLeft: tab.key === 0 ? 0 : null }}
              label={tab.label}
              disableTouchRipple
              className={
                chatTabValue === tab.key ? classes.tabActive : classes.tab
              }
              {...a11yProps(tab.key)}
            />
          ))}
        </Tabs>
      </Box>
      {/* Chats TabPanel */}

      {/* Chat Information TabPanel */}
      <TabPanel value={chatTabValue} index={0}>
        <GeneralNumbersMetrics />
        <ChatInformationTable />
      </TabPanel>
      {/* Employees TabPanel */}
      <TabPanel value={chatTabValue} index={1}>
        <ChatInformationTableEmployee />
      </TabPanel>
    </Box>
  );
}
