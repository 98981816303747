import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderBottom: '1px solid #CFCFCF !important',
      display: 'flex !important',
      justifyContent: 'space-evenly !important',
      paddingTop: '2.5rem !important'
    },
    myTab: {
      minHeight: '48px !important',
      fontSize: '16px !important',
      fontWeight: '500 !important',
      lineHeight: '26px !important',
      letterSpacing: '0.05em !important',
      marginRight: '16px !important',
      paddingRight: '0 !important',
      '&:before': {
        content: '""',
        height: '0px !important',
        width: '130% !important',
        position: 'absolute !important',
        bottom: 0
      }
    },
    gridContent: {
      display: 'flex !important',
      justifyContent: 'space-evenly !important'
    },
    indicatorSelect: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#2813AD !important'
      }
    }
  })
);
