/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  TextField,
  MenuItem,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Chip,
  Box,
  Button,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import DatePicker from '../../../../utils/components/DatePicker';
import CalendarioDialog from '../Dialogs/CalendarioDialog';
import PageWrapper from '../PageWrapper';
import Utils from '../../utils/Utils';
import * as actionsChatbot from '../../actions';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const Chatbot = props => {
  const { calendarios, instancias, loadingChatbot } = props,
    [instanceId, setInstanceId] = useState(0),
    [hasTranshipment, setHasTranshipment] = useState(false),
    [calendarioFiltrado, setCalendarioFiltrado] = useState([]),
    [calendarioOnDialog, setCalendarioOnDialog] = useState({}),
    [calendarioDialogOpen, setCalendarioDialogOpen] = useState(false),
    [periodo, setPeriodo] = useState({
      dataInicio: moment().startOf('day').subtract(1, 'year'),
      dataFinal: moment().startOf('day'),
    });

  useEffect(() => {
    props.getInstancias();
  }, []);

  useEffect(() => {
    if (instanceId) {
      const instance = instancias.find(({ id }) => id === instanceId);
      if (instance) {
        props.getCalendarios(instance);
      }
    }
  }, [instanceId]);

  useEffect(() => {
    handleCalendarioFiltrado();
  }, [periodo]);

  useEffect(() => {
    handleCalendarioFiltrado();
  }, [JSON.stringify(calendarios)]);

  const handlePeriodo = (name, value) => {
      setPeriodo({ ...periodo, [name]: value });
    },
    toggleCalendarioDialog = calendario => {
      if (calendario && calendario.id) {
        props.getGatilhos(calendario).then(gatilhos => {
          setCalendarioOnDialog({
            ...calendario,
            gatilhos,
          });
        });
      } else {
        setCalendarioOnDialog({});
      }
      setCalendarioDialogOpen(!calendarioDialogOpen);
    },
    salvarCalendario = calendario => {
      const instance = instancias.find(({ id }) => id === instanceId);
      if (instance) {
        props.salvarCalendario(calendario, instance);
        toggleCalendarioDialog();
      }
    },
    handleCalendarioFiltrado = () => {
      const filtrados = calendarios.filter(
          calendario =>
            (moment(calendario.data_inicio).isAfter(periodo.dataInicio) &&
              moment(calendario.data_inicio).isBefore(periodo.dataFinal)) ||
            (moment(calendario.data_final).isAfter(periodo.dataInicio) &&
              moment(calendario.data_final).isBefore(periodo.dataFinal)) ||
            (moment(calendario.data_inicio).isBefore(periodo.dataInicio) &&
              moment(calendario.data_final).isAfter(periodo.dataFinal))
        ),
        _hasTranshipment = filtrados.find(({ transbordo }) => transbordo === 1);
      setHasTranshipment(Boolean(_hasTranshipment));
      setCalendarioFiltrado([...filtrados]);
    };

  return (
    <PageWrapper
      {...props}
      title="Chatbot"
      buttonBack
      header={
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" noWrap>
              Filtrar por período
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <DatePicker
              label="De"
              withKeyboard
              format="DD/MM/YYYY"
              disableFuture
              value={periodo.dataInicio}
              onChange={date => handlePeriodo('dataInicio', date)}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              label="Até"
              withKeyboard
              format="DD/MM/YYYY"
              value={periodo.dataFinal}
              onChange={date => handlePeriodo('dataFinal', date)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              select
              fullWidth
              label="Instância"
              variant="outlined"
              onChange={({ target: { value } }) => setInstanceId(value)}>
              {instancias.map((instancia, index) => (
                <MenuItem value={instancia.id} key={index}>
                  {instancia.id} - {instancia.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      }>
      <Grid container direction="column" justify="center" alignItems="flex-end">
        <Grid item>
          <Box p={3} hidden={!Boolean(instanceId)}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => toggleCalendarioDialog()}>
              Nova Resposta Automática
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box pt={3} />
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        {calendarioFiltrado.map((calendario, index) => (
          <Grid key={index} item>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item container>
                    <Typography component="h5" variant="h5" gutterBottom>
                      {`${calendario.instancia.nome} - ${calendario.titulo || ''}`}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Divider />
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item md={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Status</b>
                      </Typography>
                      <Chip
                        size="small"
                        variant="outlined"
                        color={calendario.ativo ? 'primary' : 'secondary'}
                        label={calendario.ativo ? 'Ativo' : 'Inativo'}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Palavra chave</b>
                      </Typography>
                      <Typography variant="body1" color="primary">
                        <b>{calendario.palavra_chave}</b>
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Data início</b>
                      </Typography>
                      <Typography variant="body1">
                        {Utils.formatData(calendario.data_inicio)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Data da final</b>
                      </Typography>
                      <Typography variant="body1">
                        {Utils.formatData(calendario.data_final)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Box p={0.5}>
                      <Button
                        onClick={() => toggleCalendarioDialog(calendario)}
                        size="medium"
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}>
                        Editar
                      </Button>
                    </Box>
                    <Box p={0.5}>
                      <Button
                        onClick={() => props.ativarInativarCalendario(calendario)}
                        size="medium"
                        variant="outlined"
                        color={calendario.ativo ? 'secondary' : 'primary'}
                        startIcon={calendario.ativo ? <CloseIcon /> : <CheckIcon />}>
                        {calendario.ativo ? 'Inativar' : 'Ativar'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {loadingChatbot && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <CircularProgress />
          </div>
        )}

        {!loadingChatbot && calendarios.length > 0 && !calendarioFiltrado.length && (
          <Typography variant="subtitle1" align="center" gutterBottom>
            <b>Nenhum calendário encontrado para o período informado. Tente outro período</b>
          </Typography>
        )}
        {!loadingChatbot && !calendarios.length && (
          <Typography variant="subtitle1" align="center" gutterBottom>
            <b>Nenhum calendário cadastrado.</b>
          </Typography>
        )}
      </Grid>

      <CalendarioDialog
        loading={loadingChatbot}
        loadingGatilhos={props.loadingGatilhos}
        open={calendarioDialogOpen}
        calendario={calendarioOnDialog}
        instancias={instancias}
        instanciaSelected={instanceId}
        hasTranshipment={hasTranshipment}
        onClose={toggleCalendarioDialog}
        onSave={calendario => salvarCalendario(calendario)}
        onAddGatilho={({ calendario, gatilho }) => props.novoGatilho(calendario.id, gatilho)}
        onDelGatilho={async gatilho => {
          await props.deleteGatilho(gatilho);
          toggleCalendarioDialog({ id: gatilho.calendar_id });
        }}
        onUpdateGatilho={gatilho => props.updateGatilho(gatilho)}
      />
    </PageWrapper>
  );
};

const mapStateToProps = ({ ChatbotReducer }) => {
  return { ...ChatbotReducer };
};

export default connect(mapStateToProps, { ...actionsChatbot })(Chatbot);








