const INITIAL_STATE = {
  graficoBaseCNPJ: [],
  mapaBaseCNPJ: [],
  listaBaseCNPJ: [],
  somaBaseCNPJ: {
    celular: 0,
    email: 0,
    socios: 0,
  },
  headerProspecteiById: {
    nameList: '',
    filter: [],
  },
  somaProspecteiById: {
    email: 0,
    pessoa: 0,
    phone: 0,
  },
  listCampaign: [],
  listSMSLote: [],
  listSMSLoteID: [],
  graficoSMSLoteID: [],
  totalSMSLoteID: [],
  efetividadeSMSLoteID: [],
  originListSMS: '',
  listAttachment: [],
  listProspectei: [],
  listProspecteiById: [],
  listsProspectei: [],
  totalPhone: [],
  totalAddress: [],
  totalEmail: [],
  totalCity: [],
  totalUF: [],
  lengthData: 0,
  loadingWhatsApp: false,
  isLoadingCampaign: false,
  isLoadingExportCSV: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_SMS_LOTE':
      return {
        ...state,
        listSMSLote: action.payload,
        isLoadingCampaign: false,
      };
    case 'LIST_SMS_ID':
      const { efetividade, lista, total } = action.payload;
      return {
        ...state,
        graficoSMSLoteID: _BarSimples(efetividade, 'qtde', 'status'),
        originListSMS: efetividade[0].origem,
        listSMSLoteID: lista,
        totalSMSLoteID: total[0],
        efetividadeSMSLoteID: efetividade[0],
        isLoadingCampaign: false,
      };
    case 'LIST_CAMPAIGN':
      return {
        ...state,
        listCampaign: action.payload,
        isLoadingCampaign: false,
      };
    case 'LIST_ATTACHMENT':
      return {
        ...state,
        listAttachment: action.payload,
        isLoadingCampaign: false,
      };
    case 'LIST_PROSPECTEI_PF':
      return {
        ...state,
        listProspectei: action.payload.data,
        lengthData: action.payload.length,
        isLoadingCampaign: false,
      };
    case 'LIST_PROSPECTEI_PF_BY_ID':
      return {
        ...state,
        listProspecteiById: action.payload.lista,
        headerProspecteiById: action.payload.header,
        somaProspecteiById: action.payload.soma,
        isLoadingCampaign: false,
      };

    case 'GRAPHICS_PROSPECTEI_PF_BY_ID':
      return {
        ...state,
        totalPhone: _graphPieChart(action.payload.totalPhone, 'nulled'),
        totalAddress: _graphPieChart(action.payload.totalAddress, 'nulled'),
        totalEmail: _graphPieChart(action.payload.totalEmail, 'nulled'),
        totalCity: action.payload.totalCity.map(city => ({
          label: city.cidade,
          y: city.qtdeData,
        })),
        totalUF: action.payload.totalUF.map(uf => ({
          label: uf.uf,
          y: uf.qtdeData,
        })),
        isLoadingCampaign: false,
      };
    case 'GRAFICO_BASE_CNPJ':
      return {
        ...state,
        graficoBaseCNPJ: action.payload,
        isLoadingCampaign: false,
      };
    case 'COORDINATES_CNPJ':
      return {
        ...state,
        mapaBaseCNPJ: action.payload,
        isLoadingCampaign: false,
      };
    case 'LIST_CABECALHO_PROSPECTS':
      return {
        ...state,
        listsProspectei: action.payload,
        isLoadingCampaign: false,
      };
    case 'BASE_CNPJ_LIST':
      return {
        ...state,
        listaBaseCNPJ: action.payload.lista,
        somaBaseCNPJ: action.payload.soma,
        isLoadingCampaign: false,
      };
    case 'LOADING_MINHAS_LISTAS':
      return { ...state, isLoadingCampaign: true };
    case 'SEND_SMS':
      return { ...state, isLoadingCampaign: false };
    case 'LOADING_EXPORT_CSV':
      return { ...state, isLoadingExportCSV: action.payload };
    case 'LOADING_CAMPANHA':
      return { ...state, isLoadingCampaign: true };
    case 'LOADING_WHATSAPP':
      return { ...state, loadingWhatsApp: true };
    case 'GET_INSTANCE_WHATSAPP':
      return {
        ...state,
        instancesWhatsApp: action.payload,
        loadingWhatsApp: false,
      };
    case 'GET_INSTANCE_STATUS_WHATSAPP':
      return {
        ...state,
        statusWhatsApp: action.payload,
        loadingWhatsApp: false,
      };
    case 'GET_INSTANCE_QRCODE_WHATSAPP':
      return {
        ...state,
        qrCodeWhatsApp: action.payload,
        loadingWhatsApp: false,
      };
    default:
      return state;
  }
};

const _BarSimples = (data, y, label) => [
  {
    type: 'column',
    dataPoints: data.map(dt => ({ ...dt, y: dt[y], label: dt[label] })),
  },
];

const _graphPieChart = (data, param) =>
  Object.entries(data).map(data => ({
    label: data[0] === param ? 'Não' : 'Sim',
    y: data[1],
  }));
