import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: { padding: theme.spacing(1, 2) },
  contentDisclaimmer: {
    paddingRight: `${theme.spacing(6)}px !important`,
    paddingLeft: `${theme.spacing(6)}px !important`,
  },
  boxDisclaimmer: {
    backgroundColor: '#F5F5F5',
    borderRadius: 2,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  imgIcon: {
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;

