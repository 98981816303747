import Fetch from '../../../utils/helpers/Fetch';
import {
  API_URL_ACCOUTING,
  DATASALES_CRM_API,
  AMBIENTE,
} from '../../../utils/helpers/Constants';
import { getClienteMapa } from '../../Insights/actions/actions';

export const isLoadingPeople = () => dispatch =>
  dispatch({ type: 'LOADING_PEOPLE' });

export const getPeopleByStore = idStores => async dispatch => {
  dispatch(isLoadingPeople());
  const payload = await Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getPessoaLoja`,
    {
      idStores,
    }
  );
  dispatch({ type: 'GET_PEOPLE', payload });
};

export const getMaritalStatus = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getEstadoCivil`,
    null
  ).then(payload => dispatch({ type: 'GET_MARITAL_STATUS', payload }));

export const getSchooling = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getEscolaridade`,
    null
  ).then(payload => dispatch({ type: 'GET_SCHOOLING', payload }));

export const getStatusPeople = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getStatus`,
    null
  ).then(payload => dispatch({ type: 'GET_STATUS_PEOPLE', payload }));

export const filterPeople = objSearch => dispatch => {
  dispatch(isLoadingPeople());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getPessoaLojaId`,
    objSearch
  ).then(payload => dispatch({ type: 'FILTER_PEOPLE', payload }));
};
export const getPersonProfile = idPessoa => async dispatch => {
  dispatch(isLoadingPeople());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/getPessoaLojaId`,
    { idPessoa }
  ).then(payload => dispatch({ type: 'PERSON_PROFILE', payload }));
};

export const getRetailByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'RETAIL_PERSON', payload }));

export const getRetailFamilyByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/FamiliaById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'RETAIL_FAMILY_PERSON', payload }));

export const getExtratoCashback = documento => dispatch => {
  dispatch(isLoadingPeople());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/getExtratoCashback`,
    {
      documento,
    }
  ).then(payload => dispatch({ type: 'EXTRATO_CASHBACK', payload }));
};

export const getRetailConditionByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getCondicoesById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'RETAIL_CONDITION_PERSON', payload }));

export const getRetailGroupByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getGrupoById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'RETAIL_GROUP_PERSON', payload }));

export const getCamapanhaSMSByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getCampanhasSMSById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'CAMPANHA_SMS', payload }));

export const getRetencaoByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getRetencaoById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'RETENCAO_PERSON', payload }));

export const getStatusByIdPerson = idPerson => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/getStatusById`,
    {
      idPerson,
    }
  ).then(payload => dispatch({ type: 'STATUS_PERSON', payload }));

export const postCashback = cashback => dispatch => {
  dispatch(isLoadingPeople());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/postCashback`,
    cashback,
    'POST'
  ).then(() => dispatch(getExtratoCashback(cashback.documento)));
};

export const savePerson = objPerson => dispatch => {
  dispatch(isLoadingPeople());
  Fetch(
    `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/postPessoa`,
    objPerson,
    'POST'
  ).then(() => dispatch(getPeopleByStore()));
};

export const putPerson = (objPerson, filtro) => async dispatch => {
  try {
    dispatch(isLoadingPeople());
    const payload = await Fetch(
      `${DATASALES_CRM_API}pessoa-p1-${AMBIENTE}/${AMBIENTE}/crm/putPessoa`,
      objPerson,
      'PUT'
    );
    dispatch(getClienteMapa(filtro));
    dispatch(getPersonProfile(payload.idPeople));
  } catch (err) {
    console.log(err);
  }
};

export const sendSMS = objSMS => () =>
  Fetch(`${API_URL_ACCOUTING}people/sendSMS`, objSMS, 'POST');

export const deletePeople = (pessoa, filtro) => async dispatch => {
  try {
    dispatch(isLoadingPeople());
    await Fetch(
      `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/deletePessoa`,
      pessoa,
      'DELETE'
    );
    dispatch(getClienteMapa(filtro));
    dispatch(getPeopleByStore());
  } catch (err) {
    console.log(err);
  }
};

export const getPessoaNota = data => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/getPessoasNota`,
    data
  ).then(payload =>
    dispatch({
      type: 'GET_PESSOA_NOTA',
      payload: payload.pessoaNota,
    })
  );

export const postPessoaNota = data => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/postPessoaNota`,
    data,
    'POST'
  ).then(payload => dispatch(getPessoaNota({ id_pessoa: data.id_pessoa })));

export const putPessoaNota = data => async dispatch => {
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/putPessoaNota`,
    data,
    'PUT'
  ).then(payload => dispatch(getPessoaNota({ id_pessoa: data.id_pessoa })));
};

export const deletePessoaNota = data => async dispatch => {
  Fetch(
    `${DATASALES_CRM_API}pessoa-p2-${AMBIENTE}/${AMBIENTE}/crm/deletePessoaNota`,
    data,
    'DELETE'
  ).then(payload => dispatch(getPessoaNota({ id_pessoa: data.id_pessoa })));
};

export const onSetSavePerson = payload => dispatch =>
  dispatch({ type: 'SET_SAVE_PERSON', payload });
export const onInputChange = (string, data) => dispatch =>
  dispatch({ type: 'INPUT_CHANGE', payload: { string, data } });
export const onChangeStore = stores => dispatch =>
  dispatch({ type: 'CHANGE_STORE', payload: stores });
