/* eslint-disable */
import {
  Button,
  Icon,
  IconButton,
  Typography,
  withStyles,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PixelixeIframe } from '../';
import {
  CustomTable,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import {
  deleteEncarteFisico,
  createTemplatePersonalizado,
} from '../../action/index';
import CreatePost from '../CreatePost/index';
import ModalCriar from './modalCriar';
import NovaCampanhaWhatsApp from '../NovaCampanhaWhatsApp/NovaCampanhaWhatsApp';
import Pagination from '@material-ui/lab/Pagination';

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class GeneratePixelixeDetalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      image_url: undefined,
      activeStep: 0,
      itemSelected: null,
      pixelixeIframe_open: false,
      showModalWhatsApp: false,
      file: null,
      whatsapp_url: '',
      openCriarTemplate: false,
      nomeModelo: null,
      onCreateNovoModelo: null,
      expanded: {},
      showDialog: false,
      deleteId: null,
    };
    this.myRef = React.createRef();
  }

  handleOpen = url => {
    this.setState({ open: true, image_url: url });
  };

  handleClose = () => {
    this.setState({ open: false, image_url: undefined });
  };

  handleExpandClick = j => {
    let tmp = this.state.expanded;
    tmp[j] = !tmp[j];
    this.setState({ expanded: tmp });
  };

  header(props, state) {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.actions}>
          <div className={classes.actions}>
            <Typography className={classes.margin} variant="h3" noWrap>
              Detalhe
            </Typography>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              onClick={() => this.props.onRefresh()}
            >
              <Icon>sync</Icon>
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {/* <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() =>
            {}
          }
        >
          Salvar
        </Button> */}
      </div>
    );
  }

  render() {
    const { activeStep, itemSelected } = this.state;

    const { classes } = this.props;

    const preventDefault = event => event.preventDefault();

    const permissions = JSON.parse(localStorage.getItem('permissions'));

    const has_whatsapp = permissions.relacionamento
      ? permissions.relacionamento.find(
          ({ serviceName }) => serviceName === 'whatsapp'
        )
      : false;

    const has_social_network = permissions.relacionamento
      ? permissions.relacionamento.find(
          ({ serviceName }) => serviceName === 'redes_sociais'
        )
      : false;

    const data = this.props.itemSelected;
    const loading = this.props.loading_tabloide_fisico;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <div className={classes.content} ref={this.myRef}>
            <div
              style={{
                display: loading ? 'block' : 'none',
                textAlign: 'center',
              }}
            >
              <CircularProgress></CircularProgress>
            </div>

            <div
              style={{
                display: loading ? 'none' : 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              {data.map((value, index) => (
                <div
                  style={{
                    margin: 20,
                  }}
                >
                  <Card className={classes.rootCard}>
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {value.nome_modelo}
                      </Typography>
                    </CardContent>
                    <CardContent
                      style={{
                        height: '300px',
                        display:
                          value.link && value.link_html ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {((!value.link && !value.error) ||
                        (!value.link_html && !value.error_html)) && (
                        <Tooltip title="Processando" placement="top">
                          <CircularProgress size={22} thickness={4} />
                        </Tooltip>
                      )}

                      {((!value.link && value.error) ||
                        (!value.link_html && value.error_html)) && (
                        <p>Processamento não finalizado</p>
                      )}

                      {(value.error || value.error_html) && (
                        <div>
                          {value.error && (
                            <React.Fragment>
                              <IconButton
                                onClick={() => window.alert(value.error)}
                                style={{ padding: 0, margin: 10 }}
                              >
                                <Icon style={{ fontSize: 20 }}>error</Icon>
                              </IconButton>
                            </React.Fragment>
                          )}

                          {value.error_html && (
                            <React.Fragment>
                              <IconButton
                                onClick={() => window.alert(value.error_html)}
                                style={{ padding: 0, margin: 10 }}
                              >
                                <Icon style={{ fontSize: 20, color: 'blue' }}>
                                  error
                                </Icon>
                              </IconButton>
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </CardContent>
                    <CardMedia
                      className={classes.media}
                      image={value.link}
                      style={
                        value.link && value.link_html ? {} : { display: 'none' }
                      }
                    />
                    <CardActions disableSpacing>
                      <Button
                        style={{
                          backgroundColor: COLOR_PRIMARY,
                          marginRight: 15,
                        }}
                        variant={'contained'}
                        color={'primary'}
                        href={value.link}
                        target={'_blank'}
                      >
                        Abrir imagem
                      </Button>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: !!this.state.expanded[index],
                        })}
                        onClick={() => this.handleExpandClick(index)}
                        aria-expanded={!!this.state.expanded[index]}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse
                      in={!!this.state.expanded[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Typography paragraph>Id: {value.id}</Typography>
                        <Typography paragraph>
                          Arquivo: {value.pagina}
                        </Typography>
                        <Typography paragraph>
                          {moment(value.data).format('DD/MM/YYYY hh:mm:ss')}
                        </Typography>
                        <React.Fragment>
                          <Button
                            style={{
                              backgroundColor: COLOR_PRIMARY,
                            }}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() =>
                              this.setState({
                                pixelixeIframe_open: true,
                                itemSelected:
                                  'https://studio.pixelixe.com/#api?apiKey=EzWaSycXeWchL44HPUtcnLpZes63&graphicUrl=' +
                                  value.link_html +
                                  `&graphicName=${value.nome}&uid=${value.id}_${
                                    value.id_tabloide
                                  }_${value.modelo}_${value.pagina}_${
                                    value.code
                                  }_${value.id_empresa}_${
                                    value.tipoPixelixe
                                  }_${value.from || 'null'}_link_${
                                    window.location.href.split('__enviado')[0]
                                  }__enviado_link_&lang=po`,
                              })
                            }
                          >
                            Editar
                          </Button>
                          <Button
                            style={{
                              backgroundColor: COLOR_PRIMARY,
                              marginTop: 10,
                            }}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() =>
                              this.setState({
                                openCriarTemplate: true,
                                onCreateNovoModelo: () =>
                                  this.props
                                    .createTemplatePersonalizado({
                                      id: value.id,
                                      id_tabloide: value.id_tabloide,
                                      modelo: value.modelo,
                                      pagina: value.pagina,
                                      link: value.link,
                                      link_html: value.link_html,
                                      code: value.code,
                                      id_empresa: value.id_empresa,
                                      tipoPixelixe: value.tipoPixelixe,
                                      from: value.from || null,
                                      data: moment().format(
                                        'YYYY-MM-DD hh:mm:ss'
                                      ),
                                      nome_modelo:
                                        this.state.nomeModelo || null,
                                      add_template: true,
                                    })
                                    .then(() => {
                                      this.props.onRefresh();
                                      this.setState({
                                        onCreateNovoModelo: null,
                                        nomeModelo: null,
                                        openCriarTemplate: false,
                                        expanded: {},
                                      });
                                    }),
                              })
                            }
                          >
                            Criar Template
                          </Button>
                          {value.ativo === 1 && (
                            <Tooltip title="Compartilhar">
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  this.handleOpen(value.link);
                                }}
                              >
                                <ShareIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!!has_whatsapp && (
                            <IconButton
                              aria-label="whatsapp"
                              style={{ color: 'green' }}
                              onClick={() => {
                                this.setState({
                                  showModalWhatsApp: true,
                                  whatsapp_url: value.link,
                                });
                              }}
                            >
                              <WhatsAppIcon />
                            </IconButton>
                          )}
                          {value.from && (
                            <Button
                              size="large"
                              color="secondary"
                              variant="text"
                              onClick={() =>
                                this.setState({
                                  showDialog: true,
                                  deleteId: value.id,
                                })
                              }
                              style={{ marginTop: 20 }}
                            >
                              Desativar
                            </Button>
                          )}
                        </React.Fragment>
                      </CardContent>
                    </Collapse>
                  </Card>
                </div>
              ))}
            </div>

            <CustomTable
              data={this.props.itemSelected}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loading_tabloide_fisico}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
                display: 'none',
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                },
                {
                  title: 'Modelo',
                  field: 'nome_modelo',
                },
                {
                  title: 'Arquivo',
                  field: 'pagina',
                },
                {
                  title: 'Data',
                  field: 'data',
                  render: rowData =>
                    moment(rowData.data).format('DD/MM/YYYY hh:mm:ss'),
                },
              ]}
              deleteText="Deseja desativar esse item?"
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props
                      .deleteEncarteFisico({
                        id: oldData.id,
                      })
                      .then(() => {
                        this.props.onRefresh();
                        resolve();
                      });
                  }),
              }}
            />
          </div>
        </Modal>
        {this.state.pixelixeIframe_open && (
          <PixelixeIframe
            open={this.state.pixelixeIframe_open}
            onClose={() => {
              this.setState({ pixelixeIframe_open: false });
            }}
            src={itemSelected}
          ></PixelixeIframe>
        )}
        {this.state.open && (
          <CreatePost
            open={this.state.open}
            url={this.state.image_url}
            onClose={this.handleClose}
          />
        )}
        {this.state.showModalWhatsApp && (
          <NovaCampanhaWhatsApp
            showModal={this.state.showModalWhatsApp}
            mensagem={this.state}
            whatsapp_url={this.state.whatsapp_url}
            onChange={(state, value) => this.onChange(state, value)}
            onClose={() => this.setState({ showModalWhatsApp: false })}
          />
        )}
        {this.state.showDialog && (
          <DialogAlert
            open={this.state.showDialog}
            title="Deseja realmente desativar esse item?"
            onClick={() => {
              this.setState({ showDialog: false, expanded: {} });
              this.props
                .deleteEncarteFisico({
                  id: this.state.deleteId,
                })
                .then(() => {
                  this.props.onRefresh();
                });
            }}
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
        {this.state.openCriarTemplate && (
          <ModalCriar
            open={true}
            onClose={() => this.setState({ openCriarTemplate: false })}
            nomeModelo={this.state.nomeModelo}
            onChange={e =>
              this.setState({
                nomeModelo: e.target.value,
              })
            }
            onClick={() => {
              if (this.state.nomeModelo === '' || !this.state.nomeModelo) {
                alert('Preencha o nome do modelo!');
              } else {
                this.state.onCreateNovoModelo();
              }
            }}
          ></ModalCriar>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  rootCard: {
    width: 200,
    backgroundColor: '#F6F6F6',
  },
  media: {
    height: 0,
    paddingTop: '300px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  deleteEncarteFisico,
  createTemplatePersonalizado,
};

const GeneratePixelixeDetalheConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePixelixeDetalhe);

export default withStyles(styles)(GeneratePixelixeDetalheConnect);
