const INITIAL_STATE = {
  performances: [],
  xlsx: "",
  loading_recomendacoes: false,
  loading_detalhes: false,
  loading_xlsx: false,
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_PERFORMANCES":
      return {
        ...state,
        performances: action.payload,
        loading_recomendacoes: false
      };
    case "GET_DETALHES":
      return {
        ...state,
        recomendacaoDetalhes: action.payload,
        loading_detalhes: false
      };
    case "GET_WHITELIST":
      return {
        ...state,
        whiteList: action.payload,
        loading_whiteList: false
      };
    case "GET_XLSX":
      return {
        ...state,
        xlsx: action.payload,
        loading_xlsx: false
      };

    case "GET_PDF":
      return {
        ...state,
        xlsx: action.payload,
        loading_xlsx: false
      };

    case "GET_FAMILIAS":
      return {
        ...state,
        familias: action.payload,
        loading_familias: false
      };
    case "GET_PRODUTOS":
      return {
        ...state,
        produtos: action.payload,
        loading_produtos: false
      };

    case "LOAD_SOLICITACOES":
      return { ...state, loading_recomendacoes: true };
    

    default:
      return state;
  }
};
