import {
  loaderClienteTotal,
  loaderClienteSexo,
  loaderClienteStatus,
  loaderClienteTermometro,
  loaderClienteIdade,
  loaderClienteMapa,
  loaderClienteProfissao,
  loaderClienteTotalizadores,
  loaderOverviewGenre,
  loaderOverviewDDD,
  loaderOverviewAge,
  loaderOverviewRegisterDay,
  loaderOverviewRegisterMonth,
} from './loaders';
import {
  DATASALES_CRM_API,
  AMBIENTE,
  API_WHATSAPP,
} from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import { sleep } from '../../../utils/helpers/Functions';

export const isLoadingInsights = () => dispatch =>
  dispatch({ type: 'LOADING_INSIGHTS' });
export const isLoadingCampanhaCSV = payload => dispatch =>
  dispatch({ type: 'LOADING_CAMPANHA_CSV', payload });
export const isLoadingCountPhone = () => dispatch =>
  dispatch({ type: 'LOADING_COUNT_PHONE' });
export const isLoadingCountEmail = () => dispatch =>
  dispatch({ type: 'LOADING_COUNT_EMAIL' });

export const getPeopleProspecteiPF = objParameters => async dispatch => {
  try {
  } catch (err) {
    console.log(err);
  }
};

export const getTotalCadastros = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteTotal());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/total`,
      objParameters
    );

    dispatch({ type: 'GET_TOTAL_CADASTROS', payload: payload.total });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteTotal = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteTotal());
    const total = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/total`,
      objParameters
    );
    const listaCliente = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/perfil/getListaClientes`,
      objParameters
    );
    const naoCliente = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/perfil/getNaoClientes`,
      objParameters
    );
    const response = {
      total: total.total,
      n_compraram: naoCliente,
      clientes: listaCliente,
    };
    dispatch({
      type: 'GET_CLIENTE_TOTAL',
      payload: { response, total: response.clientes },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteSexo = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteSexo());
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/sexo`,
      objParameters
    );
    dispatch({ type: 'GET_CLIENTE_SEXO', payload: response });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteStatus = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteStatus());
    await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/status`,
      objParameters
    ).then(resp => dispatch({ type: 'GET_CLIENTE_STATUS', payload: resp }));
  } catch (err) {
    console.log(err);
  }
};

export const getClienteTermometro = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteTermometro());
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/termometro`,
      objParameters
    );
    dispatch({ type: 'GET_CLIENTE_TERMOMETRO', payload: response });
  } catch (err) {
    console.log(err);
  }
};

export const getListaCategoria1 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria1`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA1', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria2 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria2`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA2', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria3 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria3`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA3', payload: response });
  } catch (error) {
    console.log(error);
  }
};
export const getListaCategoria4 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria4`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA4', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria5 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria5`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA5', payload: response });
  } catch (error) {
    console.log(error);
  }
};
export const getListaCategoria6 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria6`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA6', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria7 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria7`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA7', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria8 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria8`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA8', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria9 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria9`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA9', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria10 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria10`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA10', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria11 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria11`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA11', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getListaCategoria12 = objParameters => async dispatch => {
  try {
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/insights/getInsightsCategoria12`,
      objParameters
    );

    dispatch({ type: 'GET_LISTA_CATEGORIA12', payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getClienteIdade = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteIdade());
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/idade`,
      objParameters
    );
    dispatch({ type: 'GET_CLIENTE_IDADE', payload: response });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteMapa = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteMapa());
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/mapa`,
      objParameters
    );
    dispatch({ type: 'GET_CLIENTE_MAPA', payload: response });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteTotalizadores = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteTotalizadores());
    await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/totalizadores`,
      objParameters
    ).then(resp => {
      dispatch({ type: 'GET_CLIENTE_TOTALIZADORES', payload: resp });
      dispatch(getContadorCelularQtde({ qtdeCelulares: resp[0].celular }));
      dispatch(getContadorEmailQtde({ qtdeEmails: resp[0].email }));
    });
  } catch (err) {
    console.log(err);
  }
};

export const getContadorCelularQtde = objParameters => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorCelularQtde`,
      objParameters
    ).then(resp => {
      dispatch({
        type: 'GET_CLIENTE_TOTALIZADORES_CELULAR_VALOR',
        payload: resp.valor_total,
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getContadorEmailQtde = objParameters => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorEmailQtde`,
      objParameters
    ).then(resp => {
      dispatch({
        type: 'GET_CLIENTE_TOTALIZADORES_EMAIL_VALOR',
        payload: resp.valor_total,
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClienteProfissao = objParameters => async dispatch => {
  try {
    dispatch(loaderClienteProfissao());
    const response = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/profissao`,
      objParameters
    );

    dispatch({ type: 'GET_CLIENTE_PROFISSAO', payload: response });
  } catch (err) {
    console.log(err);
  }
};

export const getListaFiltro = () => async dispatch => {
  try {
    dispatch(isLoadingInsights());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getListaProspeccao`
    );
    dispatch({ type: 'LISTA_FILTRO', payload });
  } catch (err) {}
};

export const getPromocoes = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}promocao-p1-${AMBIENTE}/${AMBIENTE}/crm/getPromocoes`
  ).then(payload =>
    dispatch({
      type: 'LIST_PROMOCOES',
      payload,
    })
  );

export const saveListProspecsPF =
  (objParameters, filtroSegmentacao, callback) => dispatch => {
    dispatch(isLoadingInsights());
    Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/saveProspeccao`,
      { objParameters, filtroSegmentacao },
      'POST'
    )
      .then(response => {
        if (response.nova_lista) {
          const payload = {
            type: 'NOVA_LISTA',
            payload: {
              dataFilter: response.nova_lista.data_filtro,
              filtro: response.nova_lista.filtro,
              filtro_completo: response.nova_lista.filtro_completo,
              idListProspectei: response.nova_lista.id_prospeccao_lista,
              nameList: response.nova_lista.nome_lista,
            },
          };
          dispatch(payload);
          return payload;
        } else {
          return false;
        }
      })
      .then(response => callback && callback(response))
      .then(() => dispatch(getListaFiltro()));
  };

export const updateListProspecsPF =
  (objParameters, filtroSegmentacao, callback) => dispatch => {
    dispatch(isLoadingInsights());
    Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/updateProspeccao`,
      { objParameters, filtroSegmentacao },
      'PUT'
    )
      .then(response => {
        if (response.nova_lista) {
          const payload = {
            type: 'NOVA_LISTA',
            payload: {
              dataFilter: response.nova_lista.data_filtro,
              filtro: response.nova_lista.filtro,
              filtro_completo: response.nova_lista.filtro_completo,
              idListProspectei: response.nova_lista.id_prospeccao_lista,
              nameList: response.nova_lista.nome_lista,
            },
          };
          dispatch(payload);
          return payload;
        } else {
          return false;
        }
      })
      .then(response => callback && callback(response))
      .then(() => dispatch(getListaFiltro()));
  };

export const savePromocao = promocao => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}promocao-p1-${AMBIENTE}/${AMBIENTE}/crm/postPromocao`,
    promocao,
    'POST'
  ).then(payload => dispatch(getPromocoes()));

export const getListaFiltroCSV = idListProspectei => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getCSVProspeccao`,
      {
        idListProspectei,
      },
      'POST'
    );
  } catch (err) {
    console.log(err);
  }
};

export const getListaFiltroFacebookCSV = idListProspectei => dispatch => {
  dispatch(isLoadingCampanhaCSV(true));
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getCSVFacebookProspeccao`,
    {
      idListProspectei,
    }
  ).then(payload => {
    window.location.assign(payload.urlS3);
    dispatch(isLoadingCampanhaCSV(false));
  });
};

export const getListaFiltroLinkedinCSV = idListProspectei => dispatch => {
  dispatch(isLoadingCampanhaCSV(true));
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getCSVLinkedinProspeccao`,
    {
      idListProspectei,
    }
  ).then(payload => {
    window.location.assign(payload.urlS3);
    dispatch(isLoadingCampanhaCSV(false));
  });
};

export const getPerformance = performance => dispatch => {
  dispatch(isLoadingInsights());
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/segmentacao/getSegmentacaoPerformance`,
    { idLista: performance }
  )
    .then(payload => dispatch({ type: 'LISTA_PERFORMANCE', payload }))
    .catch(err => console.log(err, 'ERRO'));
};

export const getPerformancePooling = performance => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/segmentacao/getSegmentacaoPerformance`,
    { idLista: performance }
  ).then(payload => dispatch({ type: 'LISTA_PERFORMANCE', payload }));

export const pauseSendSMS = idListaCampanha => async dispatch => {
  dispatch(isLoadingInsights());
  try {
    await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postPausarLote`,
      idListaCampanha,
      'POST'
    );
    dispatch(getPerformance(idListaCampanha.id_lista_prospeccao));
  } catch (err) {
    console.log(err);
  }
};

export const startSendSMS = idListaCampanha => async dispatch => {
  dispatch(isLoadingInsights());
  try {
    await Fetch(
      `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/postIniciarLote`,
      idListaCampanha,
      'POST'
    );
    dispatch(getPerformance(idListaCampanha.id_lista_prospeccao));
  } catch (err) {
    console.log(err);
  }
};

export const listaTodasProfissao = idStore => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/todas/profissao`,
    {
      idStore,
    }
  ).then(payload => dispatch({ type: 'LISTA_TODAS_PROFISSAO', payload }));

export const listaProfissao = idStore => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/profissao`,
    {
      idStore,
    }
  ).then(payload => dispatch({ type: 'LISTA_PROFISSAO', payload }));

export const listaProdutos = idStore => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/perfil/produtos`,
    {
      idStore,
    }
  ).then(payload => dispatch({ type: 'LISTA_PRODUTOS', payload }));

export const getOverviewGenre = objParameters => async dispatch => {
  try {
    dispatch(loaderOverviewGenre());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/overview/genre`,
      objParameters
    );
    dispatch({ type: 'OVERVIEW_GENRE', payload });
  } catch (err) {}
};

export const getOverviewDDD = objParameters => async dispatch => {
  try {
    dispatch(loaderOverviewDDD());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/overview/ddd`,
      objParameters
    );
    dispatch({ type: 'OVERVIEW_DDD', payload });
  } catch (err) {}
};

export const getOverviewAge = objParameters => async dispatch => {
  try {
    dispatch(loaderOverviewAge());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/overview/age`,
      objParameters
    );
    dispatch({ type: 'OVERVIEW_AGE', payload });
  } catch (err) {}
};

export const getOverviewRegisterDay = objParameters => async dispatch => {
  try {
    dispatch(loaderOverviewRegisterDay());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/overview/register-day`,
      objParameters
    );
    dispatch({ type: 'OVERVIEW_REGISTER_DAY', payload });
  } catch (err) {}
};

export const getOverviewRegisterMonth = objParameters => async dispatch => {
  try {
    dispatch(loaderOverviewRegisterMonth());
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p1-${AMBIENTE}/${AMBIENTE}/crm/overview/register-month`,
      objParameters
    );
    dispatch({ type: 'OVERVIEW_REGISTER_MONTH', payload });
  } catch (err) {}
};

export const listaLojas = () => dispatch => {
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
  ).then(payload => dispatch({ type: 'LISTA_LOJAS', payload }));
};

export const clearFilter = callback => async dispatch => {
  dispatch({ type: 'CLEAR_FILTER' });
  if (Boolean(callback)) {
    await sleep(500);
    callback();
  }
};

export const excluirFiltro = (idList, callback) => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/deleteListaProspeccao`,
      { idList },
      'DELETE'
    );
    dispatch(getListaFiltro());
    callback();
  } catch (err) {
    console.log(err);
  }
};

export const contadorCelular = celular => dispatch => {
  const request = {
    pra_quem: celular.pra_quem,
    origem_campanha: celular.origem_campanha,
    idListProspectei: celular.value,
    celular: celular.celulares,
  };

  dispatch(isLoadingCountPhone());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorCelular`,
    request
  ).then(payload => dispatch({ type: 'CONTADOR_CELULAR', payload }));
};

export const contadorWhatsapp = celular => dispatch => {
  let request = {
    pra_quem: celular.pra_quem,
    origem_campanha: celular.origem_campanha,
    celular: celular.celulares,
  };
  if (celular.key === 6) {
    request = {
      ...request,
      idInstancia: celular.value,
    };
  } else {
    request = {
      ...request,
      idListProspectei: celular.value,
    };
  }

  dispatch(isLoadingCountPhone());
  Fetch(`${API_WHATSAPP}crm/getContadoresWhatsapp`, request).then(payload =>
    dispatch({ type: 'CONTADOR_WHATSAPP', payload })
  );
};

export const contadorPush = push => dispatch => {
  const request = {
    pra_quem: push.pra_quem,
    origem_campanha: push.origem_campanha,
    idListProspectei: push.value,
  };
  dispatch(isLoadingCountPhone());
  Fetch(
    `${DATASALES_CRM_API}sms-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorPush`,
    request
  ).then(payload => dispatch({ type: 'CONTADOR_PUSH', payload }));
};

export const contadorEmail = email => dispatch => {
  const request = {
    idListProspectei: email.value,
    email: email.emails,
    pra_quem: email.pra_quem,
    origem_campanha: email.origem_campanha,
  };

  if (request.idListProspectei) {
    dispatch(isLoadingCountEmail());
    Fetch(
      `${DATASALES_CRM_API}email-p1-${AMBIENTE}/${AMBIENTE}/crm/getContadorEmail`,
      request
    ).then(payload => dispatch({ type: 'CONTADOR_EMAIL', payload }));
  }
};

export const getAllDocumentos = partDocumento => async dispatch => {
  try {
    const tempDoc = Boolean(partDocumento)
      ? partDocumento.replace(/\D+/g, '')
      : false;
    if (Boolean(tempDoc) && tempDoc.length >= 3) {
      const part = { partDocumento: tempDoc };
      const payload = await Fetch(
        `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/getDocumentos`,
        part
      );
      dispatch({ type: 'LIST_DOCUMENTOS', payload });
      return new Promise(resolve => resolve(payload));
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTagOrigem = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/getTagOrigem`
  ).then(payload => dispatch({ type: 'LIST_TAG_ORIGEM', payload }));

export const postReprocessaAudiencia = reprocessa => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/postReprocessaAudiencia`,
    reprocessa,
    'POST'
  );

export const putReprocessaAudiencia = reprocessa => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/putReprocessaAudiencia`,
    reprocessa,
    'PUT'
  );

export const getReprocessoAudiencia = id_prospeccao => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/getReprocessoAudiencia`,
    { id_prospeccao }
  ).then(payload => dispatch({ type: 'REPROCESSO_AUDIENCIA', payload }));

export const getReprocessoAudienciaSaida = id_prospeccao => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/getReprocessoAudienciaSaida`,
    { id_prospeccao }
  ).then(payload => dispatch({ type: 'REPROCESSO_AUDIENCIA_SAIDA', payload }));

export const getReprocessoAudienciaEntrada = id_prospeccao => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}insights-p3-${AMBIENTE}/${AMBIENTE}/crm/getReprocessoAudienciaEntrada`,
    { id_prospeccao }
  ).then(payload =>
    dispatch({ type: 'REPROCESSO_AUDIENCIA_ENTRADA', payload })
  );

export const getClusterAudiencia = () => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}cluster-p1-${AMBIENTE}/${AMBIENTE}/crm/cluster/getClusterAudiencia`
  ).then(payload => dispatch({ type: 'CLUSTER_AUDIENCIA', payload }));

export const postClusterAudiencia = cluster => dispatch =>
  Fetch(
    `${DATASALES_CRM_API}cluster-p1-${AMBIENTE}/${AMBIENTE}/crm/cluster/postClusterAudiencia`,
    { cluster },
    'POST'
  ).then(payload => dispatch(getClusterAudiencia()));

export const postArquivoAudiencia = arquivo => dispatch =>
  new Promise((resolve, reject) =>
    Fetch(
      `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileHeaderInsights`,
      arquivo,
      'POST'
    )
      .then(payload => {
        dispatch({ type: 'HEADER_FILE_INSIGHTS', payload: payload.data });
        resolve();
      })
      .catch(reject)
  );

export const postArquivoAudienciaCompleto =
  (arquivo, callback) => async dispatch => {
    try {
      await Fetch(
        `${DATASALES_CRM_API}importacao-p2-${AMBIENTE}/${AMBIENTE}/crm/postFileImportInsights`,
        arquivo,
        'POST'
      );
      if (Boolean(callback)) {
        callback();
      }
      dispatch(getListaFiltro());
    } catch (err) {}
  };

export const addNumeroCampanha = tipo => async dispatch =>
  dispatch({ type: 'ADD_NUMERO_CAMPANHA', payload: tipo });
export const removeNumeroCampanha = tipo => async dispatch =>
  dispatch({ type: 'REMOVE_NUMERO_CAMPANHA', payload: tipo });

export const setNome = nome => ({
  type: 'setNome',
  payload: nome,
});

export const setCpf = cpf => ({
  type: 'setCpf',
  payload: cpf,
});

export const setFiltro = value => ({
  type: 'setFiltro',
  payload: value,
});

export const setEstado = estado => ({
  type: 'setEstado',
  payload: estado,
});

export const filtroConsulta = parametros => dispatch =>
  dispatch({ type: 'FILTRO_CONSULTA', payload: parametros });

export const changeFiltro = (key, value) => dispatch =>
  dispatch({ type: 'CHANGE_FILTRO', payload: { key, value } });

export const changeTextSelect = payload => dispatch =>
  dispatch({ type: 'CHANGE_TEXT_SELECT', payload });

export const changeFiltroSpread = payload => dispatch =>
  dispatch({ type: 'CHANGE_FILTRO_SPREAD', payload });

export const changePerformance = (visible, lista) => dispatch =>
  dispatch({ type: 'CHANGE_PERFORMANCE', payload: { visible, lista } });

export const changeTabs = visible => ({
  type: 'CHANGE_VISIBLE',
  payload: visible,
});

export const modalPessoa = (visible, pessoa) => dispatch => {
  dispatch({ type: 'MODAL_PESSOA', payload: { visible, pessoa } });
};

export const openModal = (show, pessoa) => {
  return {
    type: 'openModal',
    payload: {
      show: show,
      pessoa: pessoa,
    },
  };
};

export const showFilter = () => {
  return {
    type: 'showFiltro',
  };
};

export const setFaixaEtaria = value => ({
  type: 'SET_FAIXA_ETARIA',
  payload: value,
});

export const setQtdeCompras = value => ({
  type: 'SET_QTDE_COMPRAS',
  payload: value,
});

export const setValorGasto = value => ({
  type: 'SET_VALOR_GASTO',
  payload: value,
});

export const openModalSMS = show => ({ type: 'OPEN_MODAL_SMS', payload: show });
export const openModalEmail = show => {
  return {
    type: 'OPEN_MODAL_EMAIL',
    payload: show,
  };
};
