import React from 'react';
import MaterialTable from 'material-table';

export default function MaterialTableDemo(props) {
  //console.log('PROPS_MATERIAL_TABLE_DEMO=>',props);
  return (
    <MaterialTable
      {...props}
      title={props.title || ''}
      columns={props.columns}
      data={props.data}
      style={
        props.style
          ? props.style
          : {
              boxShadow: 'none',
              border: 'none',
            }
      }
      isLoading={props.isLoading}
      actions={props.actions}
      onRowClick={props.onRowClick}
      options={{
        sorting: true,
        selection: props.selection,
        selectionProps: () => ({
          color: 'primary',
          backgroundColor: 'primary',
        }),
        pageSize: props.pageSize || 10,
        header: props.header || true,
        columnsButton: props.columnsButton || false,
        exportButton: props.exportButton || false,
        exportCsv: props.exportCsv,
        search: props.search || false,
        pageSizeOptions: [5, 10, 20],
        actionsColumnIndex: -1,
        headerStyle: props.headerStyle,
        rowStyle: props.rowStyle,
        toolbar: props.toolbar || false,
        showTextRowsSelected: props.showTextRowsSelected || false,
        showTitle: props.showTitle || true,
        ...props.options,
      }}
      detailPanel={props.detailPanel}
      localization={{
        header: {
          actions: props.headerActions || 'Ações',
        },
        body: {
          addTooltip: 'Adicionar',
          emptyDataSourceMessage:
            props.emptyDataSourceMessage || 'Nenhum resultado encontrado',
          editRow: {
            saveTooltip: 'Salvar',
            cancelTooltip: 'Cancelar',
            deleteText: props.deleteText || 'Você deseja deletar essa linha?',
          },
        },
        toolbar: {
          showColumnsTitle: 'Exibir colunas',
          showColumnsAriaLabel: 'Exibir colunas',
          searchPlaceholder: 'Pesquisar',
          addRemoveColumns: true,
          exportTitle: 'Exportar',
          exportName: 'Exportar CSV',
          nRowsSelected: '{0} linha(s) selecionada(s).',
        },
        pagination: {
          labelRowsSelect: 'Linhas',
          firstTooltip: 'Primeira página',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Próxima página  ',
          lastTooltip: 'Ultima página',
          labelDisplayedRows: '{from}-{to} de {count}',
        },
      }}
    />
  );
}
