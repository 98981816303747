const INITIAL_STATE = {
  clusters: [],
  subclusters: [],
  cluster_detalhe: [],
  loadingClusters: false,
  loadingSubClusters: false,
  loadingClusterDetalhe: false,
  isLoadingProdutosFamilia: false,
  isLoadingProduto: false,
  familias: [],
  produtos: [],
  produto: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CLUSTERS':
      return {
        ...state,
        clusters: action.payload,
        loadingClusters: false,
      };
    case 'GET_SUBCLUSTERS':
      return {
        ...state,
        subclusters: action.payload,
        loadingSubClusters: false,
      };
    case 'GET_CLUSTER_DETALHE':
      return {
        ...state,
        cluster_detalhe: action.payload,
        loadingClusterDetalhe: false,
      };
    case 'LOADING_CLUSTERS':
      return { ...state, loadingClusters: true };  
    case 'LOADING_SUBCLUSTERS':
      return { ...state, loadingSubClusters: true };
    case 'LOADING_CLUSTER_DETALHE':
      return { ...state, loadingClusterDetalhe: true };
    case 'LOADING_PRODUTOS':
      return { ...state, isLoadingProdutosFamilia: true };
    case 'LOADING_PRODUTO':
      return { ...state, isLoadingProduto: true };
    case 'ADD_CLUSTER':
      return {
        ...state,
        clusters: [
          action.payload,
          ...state.clusters
        ]
      };
    case 'ADD_SUBCLUSTER':
      return {
        ...state,
        subclusters: [
          action.payload,
          ...state.subclusters
        ]
      };
    case 'ADD_CLUSTER_DETALHE':
      return {
        ...state,
        cluster_detalhe: [
          action.payload,
          ...state.cluster_detalhe
        ],
        produto: []
      };
    case 'SET_CLUSTERS': {
      return {
        ...state,
        clusters: action.payload
      };}
    case 'SET_SUBCLUSTERS': {
      return {
        ...state,
        subclusters: action.payload
      };}
    case 'SET_DETALHE_CLUSTER': {
      return {
        ...state,
        cluster_detalhe: action.payload
      };}
    case 'GET_FAMILIAS':
      return { ...state, familias: action.payload };
    case 'GET_PRODUTOS':
      return {
        ...state,
        produtos: action.payload,
        isLoadingProdutosFamilia: false,
      };
    case 'GET_PRODUTO':
      return {
        ...state,
        produto: action.payload,
        isLoadingProduto: false,
      };  
    default:
      return state;
  }
};
