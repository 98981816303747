import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Slide,
  Typography,
  IconButton,
  Divider,
  // DialogTitle,
  DialogActions,
  // DialogContent,
  Dialog,
  Button,
  Grid,
  Container,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { TextField, DatePicker, Select } from "../../../utils/components";
import { CEP } from "../../../utils/helpers/Masks";
import { ESCOLARIDADE, ESTADO_CIVIL } from "../../../utils/helpers/Constants";

export default function ModalEditPessoa(props) {
  const classes = useStyles();

  const { open, onClose, onSearchCEP, data, onSave, profissoes } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth="xl"
      maxWidth="xl"
    >
      <DialogTitle onClose={props.onClose}>Editar pessoa</DialogTitle>
      <Divider></Divider>
      <DialogContent className={classes.dialogContent}>
        <Container component="main" maxWidth="xl">
          <div className={classes.form} noValidate>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Nome"
                  onChange={event => props.onChange("nome", event.target.value)}
                  value={data.nome}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="CPF"
                  error={data.documento.length > 11 ? true : false}
                  onChange={e => props.onChange("documento", e.target.value)}
                  value={data.documento}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  fullWidth
                  label="Sexo"
                  onChange={value => props.onChange("sexo", value)}
                  value={data.sexo}
                  options={[
                    { value: "M", label: "Masculino" },
                    { value: "F", label: "Feminino" }
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <DatePicker
                  disableFuture
                  withKeyboard
                  fullWidth
                  label="Data nascimento"
                  value={data.data_nascimento}
                  onChange={date => props.onChange("data_nascimento", date)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="CEP"
                  value={CEP(data.cep)}
                  onBlur={onSearchCEP}
                  onChange={e => props.onChange("cep", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField value={data.rua || ""} fullWidth label="Rua" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField value={data.bairro || ""} fullWidth label="Bairro" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField value={data.cidade || ""} fullWidth label="Cidade" />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField value={data.uf || ""} fullWidth label="Uf" />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  fullWidth
                  value={data.numero}
                  label="Número"
                  onChange={e => props.onChange("numero", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  fullWidth
                  label="Complemento"
                  onChange={e => props.onChange("complemento", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Telefone 1"
                  onChange={event =>
                    props.onChange("telefone1", event.target.value)
                  }
                  value={data.telefone1}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Telefone 2"
                  onChange={event =>
                    props.onChange("telefone2", event.target.value)
                  }
                  value={data.telefone2}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Telefone 3"
                  onChange={event =>
                    props.onChange("telefone3", event.target.value)
                  }
                  value={data.telefone3}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Telefone 4"
                  onChange={event =>
                    props.onChange("telefone4", event.target.value)
                  }
                  value={data.telefone4}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Celular 1"
                  onChange={event =>
                    props.onChange("celular1", event.target.value)
                  }
                  value={data.celular1}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Celular 2"
                  onChange={event =>
                    props.onChange("celular2", event.target.value)
                  }
                  value={data.celular2}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Celular 3"
                  onChange={event =>
                    props.onChange("celular3", event.target.value)
                  }
                  value={data.celular3}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Celular 4"
                  onChange={event =>
                    props.onChange("celular4", event.target.value)
                  }
                  value={data.celular4}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Email 1"
                  onChange={event =>
                    props.onChange("email1", event.target.value)
                  }
                  value={data.email1}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Email 2"
                  onChange={event =>
                    props.onChange("email2", event.target.value)
                  }
                  value={data.email2}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Email 3"
                  onChange={event =>
                    props.onChange("email3", event.target.value)
                  }
                  value={data.email3}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Email 4"
                  onChange={event =>
                    props.onChange("email4", event.target.value)
                  }
                  value={data.email4}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  fullWidth
                  type="number"
                  label="Profissao"
                  onChange={value => props.onChange("id_profissao", value)}
                  value={data.id_profissao}
                  options={profissoes.map(profissao => ({
                    value: profissao.id_pessoa_profissao,
                    label: profissao.descricao
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label="Estado civil"
                  onChange={value => props.onChange("id_estado_civil", value)}
                  value={data.id_estado_civil}
                  options={ESTADO_CIVIL}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  fullWidth
                  label="Escolaridade"
                  onChange={value => props.onChange("id_escolaridade", value)}
                  value={data.id_escolaridade}
                  options={ESCOLARIDADE}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  fullWidth
                  label="BlackList sms"
                  onChange={value => props.onChange("blacklist_sms", value)}
                  value={data.blacklist_sms}
                  options={[
                    { value: 0, label: "Não" },
                    { value: 1, label: "Sim" }
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  fullWidth
                  label="BlackList email"
                  onChange={value => props.onChange("blacklist_email", value)}
                  value={data.blacklist_email}
                  options={[
                    { value: 0, label: "Não" },
                    { value: 1, label: "Sim" }
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            !data.nome
              ? true
              : !data.documento
              ? true
              : !data.data_nascimento
              ? true
              : data.documento.length > 11 || data.documento.length < 11
              ? true
              : false
          }
          color="primary"
          onClick={onSave}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  dialogContent: {
    paddingBottom: 200
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
