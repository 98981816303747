import moment from 'moment';



import { ISelectedShareableItem, ISelectedShareableItemVideo, SelectedShareableItemProps } from '../../../../modules/TabloideDigitalV2/reducer/types';
import { AMBIENTE, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { socialNetworkState } from './distributeSocialNetworkPost';


type URLsProps = {
  item_advertisement_id: string;
  url: string;
  is_video_media: boolean;
};

type DataToSendProps = {
  name: string;
  message: string;
  send_date: string;
  start_date_campaign: string;
  end_date_campaign: string;
  is_midia: boolean;
  urls: URLsProps[];
  advertising_id: string | undefined;
  page_id: string;
  fb_id: string;
  preview_url: string;
};

type DistributionInstagramCarouselProps = {
  title: string;
  post: string;
  profile: {
    page_id: string;
    fb_user_id: string;
  };
  offerId: number | undefined;
  scheduleDate: string;
  carouselSelectedItems: SelectedShareableItemProps;
};

const distributeInstagramCarousel = async ({
  title: name,
  post: message,
  profile,
  offerId,
  scheduleDate,
  carouselSelectedItems
}: DistributionInstagramCarouselProps) => {
  const state = socialNetworkState;

  let dataToSend: DataToSendProps = {
    name,
    message,
    send_date: moment(scheduleDate).format('DD/MM/YYYY HH:mm'),
    start_date_campaign: state.dataInicioCampanha,
    end_date_campaign: state.dataFimCampanha,
    is_midia: true,
    urls: [],
    advertising_id: offerId?.toString(),
    page_id: profile.page_id,
    fb_id: profile.fb_user_id,
    preview_url: carouselSelectedItems[0].preview_url
  };

  for (let selectedCard of carouselSelectedItems) {
    // const isVideoCard = Object.keys(selectedCard).includes('link');
    const currentCardLink = selectedCard?.link ? selectedCard?.link : selectedCard.image_url;
    dataToSend.urls.push({
      item_advertisement_id: `${selectedCard?.id}`,
      url: currentCardLink,
      is_video_media: currentCardLink.endsWith('.mp4')
    });
  }

  return api(
    `${DATASALES_CRM_API}social-networks-v2-${AMBIENTE}/campaing/instagram/carousel`,
    dataToSend,
    'POST',
    null,
    null,
    false
  );

  /**
   * @description Fechar a Janela "Meus Clientes após Distribuir campanha"
   */
  // setTimeout(() => onClose(), 3000);
};


export default distributeInstagramCarousel;