import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Surface,
  Symbols,
} from 'recharts';
import COLORS from './colors';
import './style.css';

export default function MyPieChart({
  data,
  dataKey = 'value',
  nameKey = 'name',
}) {
  const [newData, setNewData] = useState(data),
    [chartData, setChartData] = useState([]),
    [activeLegend, setActiveLegend] = useState([]),
    [disabledLegend, setDisabledLegend] = useState([]);

  useEffect(() => {
    const tempData = data.map((dt, index) => ({
        ...dt,
        id: index,
        color: COLORS[index % COLORS.length],
      })),
      tempLegend = tempData.map(dt => ({
        id: dt.id,
        value: dt[nameKey],
        color: dt.color,
        type: 'circle',
      }));
    setNewData(tempData);
    setChartData(tempData);
    setActiveLegend(tempLegend);
  }, [data]);

  function _filterChart(id) {
    let _disabled = [];
    if (disabledLegend.includes(id)) {
      _disabled = disabledLegend.filter(idDisabled => idDisabled !== id);
    } else {
      _disabled = disabledLegend.concat(id);
    }
    const newChartData = newData.filter(dt => !_disabled.includes(dt.id));
    setChartData(newChartData);
    setDisabledLegend(_disabled);
  }

  function LegendComponent({ payload }) {
    return (
      <div className="containerLegend">
        {payload.map(entry => {
          const { id, color, value } = entry,
            active = disabledLegend.includes(id),
            style = { color: active ? '#AAA' : '#000' };

          return (
            <span
              className="textLegend"
              style={style}
              onClick={() => _filterChart(id)}
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span>{value}</span>
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div className="container">
      <ResponsiveContainer width="100%">
        <PieChart>
          <Tooltip
            contentStyle={{ borderRadius: 15 }}
            formatter={(value, name) => [value, name]}
          />
          <Legend
            verticalAlign="bottom"
            payload={activeLegend}
            content={LegendComponent}
          />

          <Pie data={chartData} dataKey={dataKey} nameKey={nameKey} label>
            {chartData &&
              chartData.map(dt => <Cell key={dt.id} fill={dt.color} />)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
