// Using Components
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import { useToast } from 'hooks/useToast';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { useMutation, useQuery } from 'react-query';
import { listNotificationChannel, updateNotificationPermission } from 'services/notifications';
import { queryClient } from 'services/queryClient';

const Notifications = () => {
  const [collapseEditWhatsApp, setCollapseEditWhatsApp] = useState<boolean>(false);
  const [numberNotify, setNumberNotify] = useState<string>();
  const theme: Theme = useTheme();
  const toast = useToast();

  const channel = useQuery('channel', listNotificationChannel, {
    onSuccess: response => {
      setNumberNotify(response.number_notify);
    }
  });

  const changePermission = useMutation(
    async (data: any) => {
      const response = await updateNotificationPermission(data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('channel');

        toast.show('Permissão atualizada com sucesso!', { color: 'success' });
      },
      onError: () => {
        toast.show('Erro ao atualizar a permissão de notficação.', { color: 'error' });
      }
    }
  );

  const handleChangePermission = useCallback(
    async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      try {
        let attribute = '';
        switch (_.target.name) {
          case 'datasales':
            attribute = 'notifyDatasales';
            break;
          case 'whatsapp':
            setCollapseEditWhatsApp(false);
            attribute = 'notifyWhatsApp';
            break;
          case 'sms':
            attribute = 'notifySMS';
            break;
          case 'email':
            attribute = 'notifyEmail';
            break;
        }

        await changePermission.mutateAsync({ [attribute]: checked });
      } catch (err) {
        console.log(err);
      }
    },
    [changePermission]
  );

  const handleChangeNumberNotify = useCallback(async () => {
    try {
      setCollapseEditWhatsApp(false);
      const number_notify = numberNotify?.replace(/[^0-9]/g, '');
      await changePermission.mutateAsync({ number_notify });
      queryClient.invalidateQueries('user');
    } catch (err) {
      console.log(err);
    }
  }, [changePermission]);

  const handleCollapseEditWhatsApp = () => {
    setCollapseEditWhatsApp(prev => !prev);
  };

  const handleNumberNotifyChange = (event: any) => {
    setNumberNotify(event.target.value);
  };

  const isLoading = useMemo(() => {
    return changePermission.isLoading || channel.isFetching;
  }, [changePermission.isLoading, channel.isFetching]);

  if (channel.isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="subtitle1">Buscando informações</Typography>
        <LinearProgress variant="indeterminate" sx={{ width: '50%' }} />
      </Stack>
    );
  }

  return (
    <FormGroup>
      <FormControlLabel
        disabled={isLoading}
        checked={Boolean(channel.data?.notifyDatasales)}
        control={
          <Checkbox
            name="datasales"
            onChange={handleChangePermission}
            icon={
              isLoading ? (
                <CircularProgress color="inherit" size={theme.typography.body1.fontSize} />
              ) : (
                <NotificationsNoneRoundedIcon />
              )
            }
            checkedIcon={
              isLoading ? (
                <CircularProgress color="inherit" size={theme.typography.body1.fontSize} />
              ) : (
                <NotificationsActiveRoundedIcon />
              )
            }
          />
        }
        label="Receber via Datasales"
      />
      <Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControlLabel
            disabled={isLoading}
            checked={Boolean(channel.data?.notifyWhatsApp)}
            control={
              <Checkbox
                name="whatsapp"
                onChange={handleChangePermission}
                checked={Boolean(channel.data?.notifyWhatsApp)}
                icon={
                  isLoading ? (
                    <CircularProgress color="inherit" size={theme.typography.body1.fontSize} />
                  ) : (
                    <NotificationsNoneRoundedIcon />
                  )
                }
                checkedIcon={
                  isLoading ? (
                    <CircularProgress color="inherit" size={theme.typography.body1.fontSize} />
                  ) : (
                    <NotificationsActiveRoundedIcon />
                  )
                }
              />
            }
            label="Receber via WhatsApp"
          />
          {Boolean(channel.data?.notifyWhatsApp) && (
            <IconButton
              disabled={isLoading}
              onClick={handleCollapseEditWhatsApp}
              aria-label="edit"
              color="primary"
              size="small"
            >
              <EditRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>

        <Collapse in={collapseEditWhatsApp || Boolean(channel.data?.notifyWhatsApp)}>
          <Stack direction="row" spacing={1} alignItems="center">
            <InputMask
              mask="+55 \(99) 99999-9999"
              value={numberNotify}
              onChange={handleNumberNotifyChange}
              disabled={isLoading}
            >
              {/* @ts-ignore */}
              {inputProps => (
                <TextField
                  {...inputProps}
                  size="small"
                  margin="normal"
                  label="Adicione um número"
                  variant="outlined"
                  type="tel"
                  value={numberNotify}
                />
              )}
            </InputMask>

            <Tooltip placement="right" title="Confirmar WhatsApp">
              <IconButton
                disabled={isLoading}
                size="small"
                aria-label="confirm edit"
                onClick={handleChangeNumberNotify}
                edge="end"
              >
                <CheckRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Collapse>
      </Stack>
      <FormControlLabel
        disabled
        checked={Boolean(channel.data?.notifySMS)}
        control={
          <Checkbox
            name="sms"
            onChange={handleChangePermission}
            icon={<NotificationsNoneRoundedIcon />}
            checkedIcon={<NotificationsActiveRoundedIcon />}
          />
        }
        label="Receber via SMS"
      />
      <FormControlLabel
        disabled
        checked={Boolean(channel.data?.notifyEmail)}
        control={
          <Checkbox
            name="email"
            onChange={handleChangePermission}
            icon={<NotificationsNoneRoundedIcon />}
            checkedIcon={<NotificationsActiveRoundedIcon />}
          />
        }
        label="Receber via Email"
      />
    </FormGroup>
  );
};

export default Notifications;

