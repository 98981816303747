import React from 'react';
import {
  ResponsiveContainer,
  FunnelChart,
  Tooltip,
  Funnel,
  LabelList,
} from 'recharts';
import randomColor from 'randomcolor';
import { COLOR_PRIMARY } from '../../helpers/Constants';
import './style.css';

export default function({ width = '100%', data }) {
  const newData = data.map(dt => ({
    fill: randomColor({ luminosity: 'light', hue: COLOR_PRIMARY }),
    ...dt,
    ...(dt?.value === 0 ? { value: 1 } : {}),
  }));

  return (
    <div className="container">
      <ResponsiveContainer height="100%" width="50%">
        <FunnelChart width={width}>
          <Tooltip
            payload={data}
            formatter={(value, name, { payload: { payload } }) => [
              Boolean(payload.percentual)
                ? `${payload.value} | ${payload.percentual}%`
                : value,
              name,
            ]}
          />
          <Funnel dataKey="value" data={newData} isAnimationActive>
            <LabelList
              position="right"
              fill="#000"
              stroke="none"
              dataKey="name"
            />
          </Funnel>
        </FunnelChart>
      </ResponsiveContainer>
    </div>
  );
}
