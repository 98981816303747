import Box from '@mui/material/Box';
import queryString from 'query-string';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TimelineV2 } from 'utils/components/TimelineV2';
import { AMBIENTE, DATASALES_CRM_API } from 'utils/helpers/Constants';
import { filtrarPorData } from 'utils/helpers/dateFormat';

import api from '../../../../../utils/helpers/Fetch';
import { NewEmptyModal } from '../../NewEmptyModal';
import FilterModels from './components/FilterModels';
import RenderImages from './components/RenderImages';

interface FindLayouts {
  open: boolean;
  formatType: string;
  qtdeProductsInList: number;
  modeloPronto: boolean;
  handleClose: () => void;
  setLayoutSelected: Dispatch<SetStateAction<Template>>;
}

export type Template = {
  id: number;
  companyId: number;
  formatId: number;
  occasionId?: any;
  segmentId?: any;
  name: string;
  imageURL: string;
  thumbnail?: string;
  designURL: string;
  productQuantity: number;
  tags: string;
  video_template_id?: number | any;
  video_composition?: any;
  FAST_MODELS?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
};

export type Format = {
  id: number;
  companyId?: number;
  name: string;
  height: number;
  width: number;
  createdAt: Date;
  updatedAt: Date;
};
export type Query = {
  formats?: Array<number>;
  productQuantity?: number;
  tags?: string | Array<string>;
  video: boolean | null;
};

const fixedTags = [
  {
    name: 'recommended_layouts',
    values: ['recomendado']
  },
  {
    name: 'modelo_pronto',
    values: ['modelo_pronto']
  }
];

function FindLayouts({
  open,
  formatType,
  handleClose,
  modeloPronto = false,
  setLayoutSelected,
  qtdeProductsInList
}: FindLayouts): JSX.Element {
  const [templates, setTemplates] = useState<Array<Template>>([]);
  const [templatesFull, setTemplatesFull] = useState<Array<Template>>([]);
  const [tags, setTags] = useState<string[] | null>([]);
  const [formats, setFormats] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState<number | null>(null);
  const [selectedProductQuantity, setSelectedProductQuantity] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  // const [countForFirstRender, setCountForFirstRender] = useState(0);
  const [currentTab, setCurrentTab] = useState(modeloPronto ? 3 : 0);
  const [isCartazOrIndoor, setIsCartazOrIndoor] = useState(false);
  const [query, setQuery] = useState<Query>({
    formats: [],
    // segments: [],
    // occasions: [],
    productQuantity: !modeloPronto
      ? formatType === 'Cartaz-a4-retrato' ||
        formatType === 'Cartaz-a4-paisagem' ||
        formatType === 'Tv-indoor' ||
        formatType === 'Post-video' ||
        formatType === 'Story-video'
        ? qtdeProductsInList
        : qtdeProductsInList
      : undefined,
    tags: !modeloPronto ? [] /*(['recomendado'] as string[])*/ : (['modelo_pronto'] as string[]),
    video: formatType.includes('video') ? true : null
  });

  useEffect(() => {
    if (
      formatType === 'Cartaz-a4-retrato' ||
      formatType === 'Cartaz-a4-paisagem' ||
      formatType === 'Tv-indoor' ||
      formatType === 'Post-video' ||
      formatType === 'Story-video'
    ) {
      setIsCartazOrIndoor(true);
      setSelectedProductQuantity(1);
    }
  }, [formatType]);

  useEffect(() => {
    setLoading(true);
    fetchTags();
    fetchFormats();
  }, []);

  useEffect(() => {
    if (currentTab === 0) {
      // Não apagar esse código comentado

      // setQuery({
      //   ...query,
      //   productQuantity: selectedProductQuantity || qtdeProductsInList,
      //   formats: [selectedFormat!],
      //   tags: [...fixedTags[0].values!] as string[]
      // });
      setQuery({
        ...query,
        productQuantity: isCartazOrIndoor ? 1 : qtdeProductsInList,
        formats: [selectedFormat!],
        tags: []
      });
    } else if (currentTab === 1) {
      setQuery({ ...query, tags: ['Meu Modelo'], productQuantity: 0 });
    } else if (currentTab === 2) {
      setQuery({
        ...query,
        productQuantity: selectedProductQuantity || qtdeProductsInList,
        formats: [selectedFormat!],
        tags: []
      });
    } else {
      return;
    }
  }, [currentTab]);

  useEffect(() => {
    if (query?.formats?.join(',') || modeloPronto) {
      if (!modeloPronto) {
        setSelectedProductQuantity(query.productQuantity! as number);
      }

      fetchTemplates(query);
    }
  }, [query]);

  // useEffect(() => {
  //   console.log('currentTab', currentTab);
  //   console.log('formatType', formatType);
  //   console.log('isCartazOrIndoor', isCartazOrIndoor);
  //   console.log('selectedProductQuantity', selectedProductQuantity);
  // }, [currentTab]);

  async function fetchTemplates(params: any) {
    try {
      // setCountForFirstRender(prev => prev + 1);

      setLoading(true);

      params = params ? `?${queryString.stringify(params, { arrayFormat: 'comma' })}` : '';

      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/templates${params}`).then(response => {
        if (currentTab === 0) {
          const filteredImages: Template[] = response.content.filter((template: Template) => {
            if (template.tags !== null) {
              return (
                !template.tags.includes('modelo_rapido') && !template.tags.includes('Meu Modelo')
              );
            } else {
              return true;
            }
          });

          setTemplatesFull(filtrarPorData(filteredImages, '26/12/2023'));
          setTemplates(filtrarPorData(filteredImages.slice(0, 24), '26/12/2023'));
        } else if (currentTab === 2) {
          const filteredImages: Template[] = response.content.filter((template: Template) => {
            if (template.tags !== null) {
              return (
                !template.tags.includes('recomendado') && !template.tags.includes('Meu Modelo')
              );
            } else {
              return true;
            }
          });

          setTemplatesFull(filteredImages);
          setTemplates(filteredImages.slice(0, 24));
        } else {
          setTemplatesFull(response.content);
          setTemplates(response.content.slice(0, 24));
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTags() {
    try {
      await api(
        `https://1fqz7brbah.execute-api.us-east-1.amazonaws.com/prd/findAllTagsSelect`
      ).then(response => {
        setTags(response.content);
      });
      // await api(`https://api-backoffice.datasales.info/studio-${AMBIENTE}/findAllTagsSelect`).then(
      //   response => {
      //     setTags(response.content);
      //   }
      // );
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchFormats() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/formats`).then(response => {
        setFormats(response.content);

        switch (formatType) {
          case 'Post':
            setQuery({ ...query, formats: [1] });
            setSelectedFormat(1);
            break;
          case 'Post-video':
            setQuery({ ...query, formats: [1] });
            setSelectedFormat(1);
            break;
          case 'Story':
            setQuery({ ...query, formats: [3] });
            setSelectedFormat(3);
            break;
          case 'Story-video':
            setQuery({ ...query, formats: [3] });
            setSelectedFormat(3);
            break;
          case 'Encarte':
            setQuery({ ...query, formats: [2] });
            setSelectedFormat(2);
            break;
          case 'Cartaz-a4-retrato':
            setQuery({ ...query, formats: [14] });
            setSelectedFormat(14);
            break;
          case 'Cartaz-a4-paisagem':
            setQuery({ ...query, formats: [15] });
            setSelectedFormat(15);
            break;
          case 'Tv-indoor':
            setQuery({ ...query, formats: [5] });
            setSelectedFormat(5);
            break;
          case 'Etiqueta':
            setQuery({ ...query, formats: [16] });
            setSelectedFormat(16);
            break;
          case 'Encarte - PET CAMP':
            setQuery({ ...query, formats: [20] });
            setSelectedFormat(20);
            break;
          case 'Banner 9x3m':
            setQuery({ ...query, formats: [22] });
            setSelectedFormat(22);
            break;
          case 'Encarte Aberto - PET CAMP':
            setQuery({ ...query, formats: [23] });
            setSelectedFormat(23);
            break;
          default:
            const moreFormat = response.content.find(
              (format: Format) => format.name === formatType
            );
            setQuery({ ...query, formats: [moreFormat.id] });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  const handleQueryChange = useCallback(
    (state: string, value = [] as any) => {
      setQuery(prevState => ({
        ...prevState,
        [state]: Array.isArray(value) ? value.map(v => v?.id) : value
      }));
    },
    [query]
  );

  const handleTagChange = useCallback(
    (state: string, value = [] as any) => {
      setQuery(prevState => ({
        ...prevState,
        [state]: Array.isArray(value) ? value.map(v => v) : value
      }));
    },
    [query.tags]
  );

  const tabs = [
    {
      label: 'Layouts',
      index: 0,
      children: (
        <RenderImages
          templates={templates}
          templatesFull={templatesFull}
          loading={loading}
          setTemplates={setTemplates}
          setCurrentTab={() => setCurrentTab(0)}
          setLayoutSelected={setLayoutSelected}
          handleClose={handleClose}
        />
      )
    },
    // Não apagar as opções abaixo. Estão comentadas temporariamente.
    // {
    //   label: 'Layouts recomendados',
    //   index: 0,
    //   children: (
    //     <RenderImages
    //       templates={templates}
    //       templatesFull={templatesFull}
    //       loading={loading}
    //       setTemplates={setTemplates}
    //       setCurrentTab={() => setCurrentTab(0)}
    //       setLayoutSelected={setLayoutSelected}
    //       handleClose={handleClose}
    //     />
    //   )
    // },
    {
      label: 'Meus layouts',
      index: 1,
      children: (
        <RenderImages
          templates={templates}
          templatesFull={templatesFull}
          loading={loading}
          setTemplates={setTemplates}
          setCurrentTab={() => setCurrentTab(1)}
          setLayoutSelected={setLayoutSelected}
          handleClose={handleClose}
        />
      )
    }
    // {
    //   label: 'Outros',
    //   index: 2,
    //   children: (
    //     <RenderImages
    //       templates={templates}
    //       templatesFull={templatesFull}
    //       loading={loading}
    //       setTemplates={setTemplates}
    //       setCurrentTab={() => setCurrentTab(2)}
    //       setLayoutSelected={setLayoutSelected}
    //       handleClose={handleClose}
    //     />
    //   )
    // }
  ];

  return (
    <Fragment>
      <NewEmptyModal
        open={open}
        handleClose={handleClose}
        filter={
          <FilterModels
            query={query}
            tags={tags}
            formats={formats}
            currentTab={currentTab}
            modeloPronto={modeloPronto}
            handleQueryChange={handleQueryChange}
            handleTagChange={handleTagChange}
          />
        }
      >
        {!modeloPronto ? (
          <Box>
            <TimelineV2 tabs={tabs} />
          </Box>
        ) : (
          <Box>
            <RenderImages
              templates={templates}
              templatesFull={templatesFull}
              loading={loading}
              setTemplates={setTemplates}
              setCurrentTab={() => setCurrentTab(3)}
              setLayoutSelected={setLayoutSelected}
              handleClose={handleClose}
            />
          </Box>
        )}
      </NewEmptyModal>
    </Fragment>
  );
}

export default FindLayouts;

