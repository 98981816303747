import React, { useState } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { Modal, MyButton, Select } from "../../../utils/components";
import { COLOR_PRIMARY } from "../../../utils/helpers/Constants";
import { toBase64 } from "../../../utils/helpers/Functions";

function ModalImportaArquivo(props) {
  const [arquivo, setArquivo] = useState({ base64: "", name: "", type: "" });
  const [lojas, setLoja] = useState([]);
  function footer() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        <MyButton
          size="large"
          color="primary"
          variant="text"
          onClick={props.onClose}
        >
          Fechar
        </MyButton>

        <MyButton
          disabled={!arquivo.base64 || lojas.length <= 0}
          onClick={() => props.onSave({ arquivo, lojas })}
          size="large"
          color="primary"
          variant="text"
        >
          Salvar
        </MyButton>
      </div>
    );
  }

  return (
    <Modal
      size="md"
      showModal={props.open}
      onClose={props.onClose}
      header="Importar arquivo"
      footer={footer()}
    >
      <section style={{ padding: "0 2rem", display: "grid", gap: "2rem" }}>
        <p>
          Para evitar constrangimentos com o processo de importação, <br />
          estamos disponibilizando o download do modelo correto do arquivo.
        </p>
        <p>
          Para o processo acontecer sem nenhum incidente,
          <br />
          <strong>
            Não utilize vírgulas em nenhuma palavra ou número que está no
            arquivo.
          </strong>
        </p>
        <p>
          Caso tenha os mesmos produtos no arquivo e nos produtos dos Pedidos,
          <br />
          os produtos ofertados serão <strong>atualizados</strong> pelos os
          produtos do arquivo importado.
        </p>
        <p>
          O importador só aceita arquivos com a extensão <strong>CSV</strong>.{" "}
          <a href="https://datasalesio-imagens.s3.amazonaws.com/rondelli/modelo-importacao-delivery.csv">
            Download do modelo
          </a>
        </p>
        <Typography
          style={{ fontWeight: "900", color: COLOR_PRIMARY }}
          color="primary"
          variant="h5"
        >
          {arquivo.name || "Selecionar arquivo"}
        </Typography>
        <Select
          onChange={loja => setLoja(loja)}
          value={lojas}
          options={
            props.lojas ? props.lojas.filter(loja => loja.tipo_pedido) : []
          }
          multiple
          labelKey="nome_loja"
          valueKey="id_loja"
          label="Lojas"
        />
        <section style={{ display: "flex", justifyContent: "center" }}>
          <input
            accept=".csv"
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={async e => {
              if (typeof e.target.files[0] !== "undefined") {
                const file = await toBase64(e.target.files[0]);
                setArquivo(file);
              }
            }}
          />
          <label htmlFor="contained-button-file">
            <MyButton
              size="large"
              component="span"
              color="primary"
              variant="text"
            >
              Upload do arquivo
            </MyButton>
          </label>
        </section>
      </section>
    </Modal>
  );
}

const mapStateToProps = ({ DeliveryReducer }) => ({
  ...DeliveryReducer
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalImportaArquivo);
