const INITIAL_STATE = {
  listSMSAutomaticoAniversario: [],
  isLoadingSMS: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_ROBOT_SMS':
      return {
        ...state,
        listSMSAutomaticoAniversario: action.payload.filter(
          list => list.id_tipo_sms === 1,
        )[0],
        isLoadingSMS: false,
      };
    case 'ON_CHANGE_STRING':
      return {
        ...state,
        listSMSAutomaticoAniversario: {
          ...state.listSMSAutomaticoAniversario,
          [action.payload.state]: action.payload.string,
        },
      };
    case 'LOADING_SMS':
      return { ...state, isLoadingSMS: true };
    default:
      return state;
  }
};
