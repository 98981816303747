import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    height: '100%',
  },
  large: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  loadingContainer: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
