import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Typography,
  withStyles,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import { DatePicker, SplitButton } from '../../../utils/components';
import {
  getGatilhos,
  saveAutomacao,
  saveAutomacaoPersonalizada,
  getAutomacaoPersonalizada,
  putAutomacao,
  getAutomacao,
} from '../actions';

import ListaAutomacao from './ListaAutomacao';
import ModalAutomacaoWifi from './CriarAutomacaoWifi';
import ModalAutomacaoPersonalizado from './CriarAutomacaoPersonalizado';
import EditarAutomacaoWifi from './EditarAutomacaoWifi';
class Ofertas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      gatilho: '',
      tipo: '',
      mensagem: '',
      key: 0,
      automacaoEdit: null,
      showModalWifi: false,
      showEditModalWifi: false,
      showModalPersonalizado: false,
      edit: false,
    };
  }

  componentDidMount() {
    this.props.getGatilhos();
    this.props.getAutomacao();
    this.props.getAutomacaoPersonalizada();
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  salvaAutomacaoPersonalizada(personalizado) {
    const filtroAutomacao = {
      uf:
        personalizado.selectedEstados &&
        personalizado.selectedEstados.map(uf => uf.sigla),
      cidades:
        personalizado.selectedMunicipios &&
        personalizado.selectedMunicipios.map(cidade => cidade.cidade),
      bairros:
        personalizado.selectedBairros &&
        personalizado.selectedBairros.map(bairro => bairro.bairro),
      ddd:
        personalizado.selectedDDD &&
        personalizado.selectedDDD.map(ddd => ddd.value),
      aniversario:
        personalizado.selectedMesNascimento &&
        personalizado.selectedMesNascimento.value,
      profissao:
        personalizado.selectedProfissao &&
        personalizado.selectedProfissao.map(pro => pro.value),
      faixaEtaria: personalizado.faixaEtaria,
      dataCadastroInicio: personalizado.dataCadastroInicio,
      dataCadastroFim: personalizado.dataCadastroFim,
      genre: personalizado.genre && personalizado.genre.id,
      status: personalizado.status && personalizado.status.value,
      email: personalizado.email && personalizado.email.value,
      celular: personalizado.celular && personalizado.celular.value,
      dataCompraInicio: personalizado.dataCompraInicio,
      dataCompraFim: personalizado.dataCompraFim,
      qtdeComprasInicio: personalizado.qtdeComprasInicio,
      qtdeComprasFim: personalizado.qtdeComprasFim,
      valorComprasInicio: personalizado.valorComprasInicio,
      valorComprasFim: personalizado.valorComprasFim,
      ticketMedioInicio: personalizado.ticketMedioInicio,
      ticketMedioFim: personalizado.ticketMedioFim,
      termometro: personalizado.termometro && personalizado.termometro.value,
      lojas:
        personalizado.selectedStores &&
        personalizado.selectedStores.map(loja => loja.value),
      categoria_1:
        personalizado.categoria_1 &&
        personalizado.categoria_1.map(cat => cat.categoria_1),
      categoria_2:
        personalizado.categoria_2 &&
        personalizado.categoria_2.map(cat => cat.categoria_2),
      categoria_3:
        personalizado.categoria_3 &&
        personalizado.categoria_3.map(cat => cat.categoria_3),
      categoria_4:
        personalizado.categoria_4 &&
        personalizado.categoria_4.map(cat => cat.categoria_4),
      categoria_5:
        personalizado.categoria_5 &&
        personalizado.categoria_5.map(cat => cat.categoria_5),
      categoria_6:
        personalizado.categoria_6 &&
        personalizado.categoria_6.map(cat => cat.categoria_6),
      categoria_7:
        personalizado.categoria_7 &&
        personalizado.categoria_7.map(cat => cat.categoria_7),
      categoria_8:
        personalizado.categoria_8 &&
        personalizado.categoria_8.map(cat => cat.categoria_8),
      categoria_9:
        personalizado.categoria_9 &&
        personalizado.categoria_9.map(cat => cat.categoria_9),
      categoria_10:
        personalizado.categoria_10 &&
        personalizado.categoria_10.map(cat => cat.categoria_10),
      categoria_11:
        personalizado.categoria_11 &&
        personalizado.categoria_11.map(cat => cat.categoria_11),
      categoria_12:
        personalizado.categoria_12 &&
        personalizado.categoria_12.map(cat => cat.categoria_12),
    };
    const objAutomacao = {
      nome_automacao: personalizado.nomeAutomacao,
      tipo_automacao: personalizado.tipo.label,
      unid_tempo_automacao: personalizado.unitTimeAutomacao.value,
      unid_automacao: personalizado.unitAutomacao,
      hora_automacao:
        personalizado.horaDisparoAutomacao &&
        moment(personalizado.horaDisparoAutomacao).format('HH:mm:ss'),
      dia_automacao:
        personalizado.diaDisparoAutomacao &&
        personalizado.diaDisparoAutomacao.value,
      mensagem: personalizado.mensagem,
      filtro_automacao: JSON.stringify(filtroAutomacao),
    };

    this.props.saveAutomacaoPersonalizada(objAutomacao);
    this.setState({ showModalPersonalizado: false, edit: false });
  }

  salvaAutomacao() {
    const objAutomacao = {
      id_automacao_gatilho:
        this.state.gatilho && this.state.gatilho.id_automacao_gatilho,
      tipo_gatilho: this.state.gatilho && this.state.gatilho.tipo_gatilho,
      tipo_automacao: this.state.tipo && this.state.tipo.value.toUpperCase(),
      nome_automacao: this.state.name,
      mensagem: this.state.mensagem,
    };

    this.props.saveAutomacao(objAutomacao);

    this.setState({ showModalWifi: false });
  }

  editarAutomacao(automacao) {
    const objAutomacao = {
      id_automacao_gatilho:
        automacao.gatilho && automacao.gatilho.id_automacao_gatilho,
      tipo_gatilho: automacao.gatilho && automacao.gatilho.tipo_gatilho,
      tipo_automacao: automacao.tipo && automacao.tipo.value.toUpperCase(),
      nome_automacao: automacao.nome_automacao,
      mensagem: automacao.mensagem,
      ativo: automacao.ativo,
      id_automacao_mensagem: automacao.id_automacao_mensagem,
    };
    this.props.putAutomacao(objAutomacao);
    this.setState({ showEditModalWifi: false, automacaoEdit: null });
  }

  render() {
    const { classes, automacao, automacaoPersonalizada } = this.props;
    const {
      filtroDataInicio,
      filtroDataFim,
      showModalWifi,
      showModalPersonalizado,
      showEditModalWifi,
      key,
      automacaoEdit,
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Automações
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date =>
                    this.onChangePeriodo('filtroDataInicio', date)
                  }
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={filtroDataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('filtroDataFim', date)}
                />
              </div>
            </div>
          </section>

          <SplitButton
            text="Criar automação"
            label="label"
            className={classes.margin}
          >
            <MenuItem
              onClick={() =>
                this.setState({
                  showModalWifi: true,
                })
              }
            >
              WiFi
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({
                  showModalPersonalizado: true,
                })
              }
            >
              Personalizado
            </MenuItem>
          </SplitButton>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <div>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 0 && classes.activeButton}
              onClick={() => this.setState({ key: 0 })}
            >
              WiFi
            </Button>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 1 && classes.activeButton}
              onClick={() => this.setState({ key: 1 })}
            >
              Personalizado
            </Button>
          </div>
        </div>

        <div
          className={classes.content}
          style={
            this.props.isLoadingOfertas
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          {this.props.loadingAutomacao ? (
            <CircularProgress />
          ) : key === 0 ? (
            <ListaAutomacao
              classes={classes}
              data={automacao}
              onUpdate={auto =>
                this.setState({ showEditModalWifi: true, automacaoEdit: auto })
              }
            />
          ) : (
            key === 1 && (
              <ListaAutomacao classes={classes} data={automacaoPersonalizada} />
            )
          )}
        </div>
        {showEditModalWifi && (
          <EditarAutomacaoWifi
            showModal={showEditModalWifi}
            automacaoEdit={automacaoEdit}
            onEdit={newAutomacao => this.editarAutomacao(newAutomacao)}
            onClose={() =>
              this.setState({ showEditModalWifi: false, automacaoEdit: null })
            }
          />
        )}
        {showModalWifi && (
          <ModalAutomacaoWifi
            {...this.props}
            {...this.state}
            open={showModalWifi}
            onChange={(state, value) => this.onChange(state, value)}
            onSave={() => this.salvaAutomacao()}
            onClose={() => this.setState({ showModalWifi: false, edit: false })}
          />
        )}
        {showModalPersonalizado && (
          <ModalAutomacaoPersonalizado
            {...this.props}
            {...this.state}
            open={showModalPersonalizado}
            onSave={personalizado =>
              this.salvaAutomacaoPersonalizada(personalizado)
            }
            onClose={() =>
              this.setState({ showModalPersonalizado: false, edit: false })
            }
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 50,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatarDisabled: {
    backgroundColor: theme.palette.background.disabled,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ AutomacaoReducer }) => ({ ...AutomacaoReducer });

const mapDispatchToProps = {
  getGatilhos,
  saveAutomacao,
  putAutomacao,
  getAutomacao,
  saveAutomacaoPersonalizada,
  getAutomacaoPersonalizada,
};

const OfertasConnect = connect(mapStateToProps, mapDispatchToProps)(Ofertas);

export default withStyles(styles)(OfertasConnect);
