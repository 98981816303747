import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Fab,
  Grid,
  Paper,
  Typography,
  TextField,
  Zoom,
  useTheme,
} from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import { CustomTable, Dropzone } from '../../../../utils/components';
import { ONE_MB_SIZE_IN_BYTES } from '../../../../utils/helpers/Constants';
import { toBase64 } from '../../../../utils/helpers/Functions';
import useStyles from '../Grupos/styles';

const CreateGroupDialog = ({
  loading,
  loadingContacts,
  open,
  group,
  contacts,
  onClose,
  onChange,
  onSave,
}) => {
  const classes = useStyles(),
    theme = useTheme(),
    [imageSelected, setImageSelected] = useState(null);

  async function convertImage(images = []) {
    if (Boolean(images.length)) {
      const image = images[0],
        response = await toBase64(image);
      onChange(image, 'image');
      setImageSelected(response);
    } else {
      onChange(null, 'image');
      setImageSelected(null);
    }
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>
        <Typography className={classes.nameChat}>Criar grupo</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              label="Nome"
              variant="outlined"
              size="small"
              value={group.name}
              onChange={({ target: { value } }) => onChange(value, 'name')}
            />
          </Grid>
          <Grid style={{ display: 'flex' }} item xs={12} justify="center">
            {Boolean(imageSelected) ? (
              <Paper
                style={{
                  width: imageSelected.width / 4,
                  height: imageSelected.height / 4,
                }}
                elevation={3}
              >
                <Box
                  width="100%"
                  height="100%"
                  borderRadius={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-end"
                  paddingBottom="2vh"
                  style={{
                    backgroundImage: `url(${imageSelected.base64})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <Zoom in>
                    <Fab
                      size="small"
                      aria-label="delete"
                      onClick={() => convertImage()}
                    >
                      <DeleteRounded />
                    </Fab>
                  </Zoom>
                </Box>
              </Paper>
            ) : (
              <Dropzone
                filesLimit={1}
                maxFileSize={ONE_MB_SIZE_IN_BYTES * 50}
                dropzoneText="Arraste, ou clique, para adicionar uma imagem"
                acceptedFiles={['image/jpeg', 'image/jpg', 'image/png']}
                showAlerts={false}
                showPreviewsInDropzone={false}
                onChange={files => convertImage(files)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              paging
              search
              selection
              title="Contatos"
              pageSize={5}
              isLoading={loadingContacts}
              data={contacts}
              columns={[
                { title: 'Nome', field: 'nome_contato' },
                { title: 'Número', field: 'numero' },
              ]}
              onSelectionChange={selected =>
                onChange(
                  selected.map(({ numero }) => numero),
                  'participants'
                )
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          disabled={
            loading ||
            !Boolean(group.participants.length) ||
            !Boolean(group.name)
          }
          onClick={onSave}
          color="primary"
        >
          {loading ? (
            <CircularProgress size={theme.typography.subtitle1.fontSize} />
          ) : (
            'Ok'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  loadingContacts: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

CreateGroupDialog.defaultProps = {
  open: false,
  loading: false,
  loadingContacts: false,
  contacts: [],
  group: { name: null, participants: [] },
  onClose: () => {},
  onChange: () => {},
  onSave: () => {},
};

export default CreateGroupDialog;
