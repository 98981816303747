import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menu: { marginTop: theme.spacing(7), maxWidth: theme.spacing(52) },
  paper: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    maxHeight: theme.spacing(50),
  },
  icons: { width: theme.spacing(5), marginBottom: theme.spacing(1) },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    maxWidth: theme.spacing(10),
  },
  btnBase: { borderRadius: theme.spacing(1) },
  typography: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  subheader: { lineHeight: 2 },
}));

export default useStyles;
