import React, { Children } from 'react';
import { Formik, FormikProps } from "formik";
import { useState, useMemo, useCallback, useRef } from "react";
import { ButtonProps } from "@mui/material/Button";
import {Dialog} from '../../../../../utils/components/Dialog/DialogV2'
import {ChatBotFileData} from '../../../../../types/chatbot'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { map } from "lodash";
import {File} from './File'
import {Match} from './Match'
import {Review} from './Review'
import {Rule} from './Rule'
import { useStyles } from "./styles";
import * as Yup from "yup";
import { useMutation } from "react-query";
import {CreateHeadersData} from '../../../../../types/imports'
import {createHeaders} from '../../../../../services/imports'
import {createImportFile} from '../../../../../services/imports'
import { filter } from "lodash";
import { toBase64 } from 'utils/helpers/Functions';
import DSAWS from '../../../../../utils/helpers/DSAWS'
import { Buffer } from "buffer";
import {queryClient} from '../../../../../services/queryClient'
import { useToast } from "../../../../../hooks/useToast"
import { useSnackbar } from 'notistack';


type NewImportProps = {
  identificator: "NUMERO";
  open: boolean;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({});

export function ListContat({identificator, open, onClose }: NewImportProps) {

  const formikRef = useRef<FormikProps<ChatBotFileData>>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(true)
  const styles = useStyles();
  const [loadingHeader, setLoadingHeader] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const companyInfo = JSON.parse(localStorage.getItem('companyId')!);
  const toast = useToast();
  const { enqueueSnackbar } = useSnackbar();
  
  function onChecked(){
    setChecked((prev) => !prev)
  }
  
  const newHeaderFile = useMutation(
    async (data: CreateHeadersData) => {
      const response = await createHeaders(data);
      return response;
    },
    {
      onSuccess: (response : any) => {
        enqueueSnackbar('leitura bem sucedida', { variant: 'success' });
        const columns = map(
          filter(response.cols, (col: any) => Boolean(col.checked)),
          (col) => ({ label: col.label, column: col.column })
        );
        formikRef.current?.setFieldValue(
          "fileName",
          response.attrFile.file_name
        );
        formikRef.current?.setFieldValue(
          "fileRows",
          response.attrFile.file_length
        );
          
        formikRef.current!.setFieldValue("fileUrl", response.attrFile.link);
        formikRef.current!.setFieldValue("columns", columns);

        setActiveStep((prev) => prev + 1);
      },
      onError: (erro: any) => {
        enqueueSnackbar(erro.message, { variant: 'error' });
      },
    }
  );

  const newImportFile = useMutation(
    async (data: ChatBotFileData) => {
      const response = await createImportFile(data);
      return response;
      
    },
    {
      onSuccess: (message) => {
        console.log('Sucess! ', message)
        enqueueSnackbar('Sucesso!', { variant: 'success' });
        queryClient.invalidateQueries("imports");
        handleClose();
      },
      onError: () => {
        enqueueSnackbar('Não conseguimos fazer a importação!', { variant: 'error' });
        console.log('Não conseguimos fazer a importação')
      },
    }
  );

  const handleSaveFile = useCallback(
    async (data: ChatBotFileData) => {
      try {
        if (data.file) {
          setLoadingHeader(true);
          const base64 = await toBase64(data.file);
          const file = base64.base64.split(";base64,")[1];
          const splitName = data.file.name.split(".");
          const uploadFile = {
            file: Buffer.from(file, "base64"),
            type: data.file.type,
            bucketName: "log-crm",
            folderName: "arquivos",
            name: `${data.file.lastModified}-${splitName[0]}`,
            subFolderName: `${companyInfo}/importacao/`,
            distinctName: false,
            extension: splitName[1],
          };
          const url = await DSAWS.saveS3(uploadFile);
          const objectRequest = {
            name: uploadFile.name,
            type: uploadFile.extension,
            url: url.Location,
            identificator,
          };
          await newHeaderFile.mutateAsync(objectRequest);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingHeader(false);
      }
    },
    [companyInfo, newHeaderFile, identificator, DSAWS.saveS3]
  );

  const handleSave = useCallback(
    async (data: ChatBotFileData) => {
      try {
        setLoadingFile(true);
        const splitName = data.file.name.split(".");
        const uploadFile = {
          file: Buffer.from(JSON.stringify(data.columns)),
          name: `${data.file.lastModified}-${splitName[0]}`,
          type: "json",
          bucketName: "log-crm",
          folderName: "cabecalho",
          subFolderName: `${companyInfo}/importacao/`,
          distinctName: false,
          extension: "json",
        };
        const url = await DSAWS.saveS3(uploadFile);
        data.fileHeaderUrl = url.Location;
        await newImportFile.mutateAsync(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingFile(false);
      }
    },
    [companyInfo, newImportFile, DSAWS.saveS3]
  );

  const steps = useMemo(() => {
    return ["Arquivo", "Colunas", "Regras", "Resumo"];
  }, []);

  const handleClose = useCallback(() => {
    setActiveStep(0);
    onClose();
  }, [onClose, setActiveStep]);


  function validation(){
    if('file' in formikRef.current!.values) {
      handleSaveFile(formikRef.current!.values)!
    }else{
      enqueueSnackbar('Importe um arquivo para poder continuar!', { variant: 'error' });
    }
  }


  const renderContent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <File openCheck={onChecked} checked={checked}/>;
      case 1:
        return (
          <Match
            colums={newHeaderFile.data?.cols}
            headers={newHeaderFile.data?.fileCols}
          />
        );
      case 2:
        return <Rule />;
      case 3:
        return <Review />;
    }
  },[activeStep, checked, newHeaderFile]);


  const actions: ButtonProps[] = useMemo(() => {
    return [
      {
        children: "Voltar",
        onClick: () => setActiveStep((prev) => prev - 1),
        disabled: activeStep === 0 || loadingHeader || loadingFile,
        color: 'info'
      },
      {
        children:
        loadingHeader || loadingFile
          ? "Enviado solicitação..."
          : activeStep === 3
          ? "Importar"
          : "Continuar",
        variant: "contained",
        onClick: () =>
          activeStep === 0
            ? validation()
            : activeStep === 3
            ? formikRef.current?.handleSubmit()
            : setActiveStep((prev) => prev + 1),
          //setActiveStep((prev) => prev + 1)
        disabled: checked || loadingHeader || loadingFile,
        color: 'info',
      },
    ];
  }, [validation, loadingFile, loadingHeader, activeStep, formikRef, handleSaveFile, checked]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      title={' '}
      open={open}
      actions={actions}
      onClose={handleClose}
    >
       <Stepper activeStep={activeStep} className={styles.step}>
        {map(steps, (step) => {
          return (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      
      <div className={styles.rootContent}>
        <Formik
          innerRef={formikRef}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          initialValues={
            {
              typeImport: "WHATSAPP",
              rule: "update",
              identificator: { column: identificator },
            } as ChatBotFileData
          }
        >
          {renderContent()}
        </Formik>
      </div>

    </Dialog>
  );
}