import Fetch from '../../../utils/helpers/Fetch';
import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';

export const loadListEnrichment = () => dispatch =>
  dispatch({ type: 'LOAD_LIST_ENRICHMENT' });

export const getListProspeccao = () => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/prospeccao/getListaProspeccao`
    );
    dispatch({ type: 'LIST_PROSPECCAO', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getPriceEnrichment = idProspeccao => async dispatch => {
  try {
    const payload = await Fetch(
      `${DATASALES_CRM_API}enriquecimento-p1-${AMBIENTE}/${AMBIENTE}/crm/getPriceEnriquecimento`,
      { idProspeccao }
    );
    dispatch({ type: 'PRICE_ENRICHMENT', payload });
  } catch (err) {
    console.log(err);
  }
};

export const getEnrichment = () => async dispatch => {
  try {
    dispatch(loadListEnrichment());
    const payload = await Fetch(
      `${DATASALES_CRM_API}enriquecimento-p1-${AMBIENTE}/${AMBIENTE}/crm/getEnriquecimento`
    );
    dispatch({ type: 'LIST_ENRICHMENT', payload });
  } catch (err) {
    console.log(err);
  }
};

export const postEnrichment = enrichment => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}enriquecimento-p1-${AMBIENTE}/${AMBIENTE}/crm/postEnriquecimento`,
      enrichment,
      'POST'
    );
    dispatch(getEnrichment());
  } catch (err) {
    console.log(err);
  }
};
