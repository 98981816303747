import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePreview: {
    width: '100%',
    objectFit: 'cover',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
