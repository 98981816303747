import {
  addDays,
  addMonths,
  addWeeks,
  getDay,
  getDaysInMonth,
  nextDay,
  setDate,
} from 'date-fns';
import { ScheduleItem } from 'modules/TabloideDigitalV2/components/ScheduledPosts/ListScheduleImages';

import { WEEKLY_DAYS_DATE_FNS } from './Constants';

export function convertDaysInNumbers(days: string[], defaultSendHour: Date): Day[] {
  const convertedDays = days.map(day => {
    const mapping = WEEKLY_DAYS_DATE_FNS.find(weeklyDay => weeklyDay.label === day);

    return mapping?.value!;
  });
  convertedDays.sort((a, b) => a - b); 
  
  const currentDateTime = new Date();
  const dayNumberSendHour = getDay(defaultSendHour);
  const todayNumber = convertedDays.find( numberDay => numberDay === dayNumberSendHour );

  if( (defaultSendHour > currentDateTime) && todayNumber ){
    const indexTodayNumber = convertedDays.indexOf(todayNumber);
    const daysRemoved = convertedDays.splice(indexTodayNumber);
    convertedDays.unshift(...daysRemoved);
  }

  if( (defaultSendHour < currentDateTime) && todayNumber ){
    const indexTodayNumber = convertedDays.indexOf(todayNumber);
    if( indexTodayNumber !== (convertedDays.length - 1) ){
      const daysRemoved = convertedDays.splice(indexTodayNumber + 1);
      convertedDays.unshift(...daysRemoved);
    }
  }

  if(!todayNumber){
    const nextDay = convertedDays.find( numberDay => numberDay > dayNumberSendHour );
    if(nextDay){
      const indexNextDay = convertedDays.indexOf(nextDay);
      const daysRemoved = convertedDays.splice(indexNextDay);
      convertedDays.unshift(...daysRemoved);
    }
  }
  
  //console.log('days - function convertDaysInNumbers', days);
  //console.log('convertedDays - function convertDaysInNumbers', convertedDays);

  return convertedDays as Day[];
}

// TO COUNT BY WEEK
/**
 * @description This function serves to organize the posts according to the days selected in the
 * following sequence:
 *  -> Assuming that the selected days were Monday, Wednesday and Friday. And there are
 *   7 posts to be scheduled
 *    - monday: post 1,
 *    - tuesday: post 2,
 *    - friday: post 3,
 *
 *    - monday: post 4,
 *    - tuesday: post 5,
 *    - friday: post 6,
 *
 *    - monday: post 7
 *
 * @param selectedWeeklyDays Array containing all days of the week selected in number format. (ex: 0 = Sunday, 1 = Monday, etc.)
 * @param selectedScheduledItems Array of items (or posts) selected for scheduling
 * @returns Returns a matrix with posts separated by selected day
 */
export function mappingPostsByWeekDays(
  selectedWeeklyDays: Day[],
  selectedScheduledItems: ScheduleItem[]
) {
  const amountSelectedDays = selectedWeeklyDays.length;

  const convertedMapping = selectedWeeklyDays.map((weekDay, weekDayIndex) => {
    const postsByWeekDays = selectedScheduledItems.filter(
      (item, itemIndex) =>
        itemIndex === weekDayIndex || weekDayIndex === itemIndex % amountSelectedDays
    );

    return postsByWeekDays;
  });

  return convertedMapping;
}

/**
 *
 * @param yesterday The date of yesterday with send time
 * @param amountRepeat Define the interval that the date will be counted
 * @param selectedWeeklyDays Array containing all days of the week selected in
 * number format. (ex: 0 = Sunday, 1 = Monday, etc)
 * @param matrixPosts The matrix containing all of posts separated by day of post
 * @param lastDateWeeks The day the last post was published at the end of a replay
 * @param repeat The current repeat number 
 * @returns A new matrix containing all of posts separated by day of post but with
 * the sequential dates by day of the week, added.
 */
export function updateWeeklyDateByMatrixOrder(
  yesterday: Date,
  amountRepeat: number,
  selectedWeeklyDays: Day[],
  matrixPosts: ScheduleItem[][],
  lastDateWeeks: Date,
  repeat: number
): ScheduleItem[][] {
  const currentDateTime = new Date();
  const today = addDays(yesterday, 1); // today with send time

  const matrixPostsDated = matrixPosts.map((weekDay, weekDayIndex) => {
    const schedulePostsByRow = weekDay.map((post, postIndex) => {
      if(repeat){
        const dayPost = nextDay(lastDateWeeks,selectedWeeklyDays[weekDayIndex]);
        return {
          ...post,
          dateScheduled: [...post.dateScheduled,...[addWeeks(dayPost, postIndex * amountRepeat)]]
        };
      }else{
        // first post of each selected week
        let firstPost = {} as Date;
        if (today > currentDateTime) {
          firstPost = nextDay(yesterday, selectedWeeklyDays[weekDayIndex]);
        } else {
          firstPost = nextDay(today, selectedWeeklyDays[weekDayIndex]);
        }
        return {
        ...post,
        dateScheduled: [addWeeks(firstPost, postIndex * amountRepeat)]
        };
      }
    });

    return schedulePostsByRow;
  });

  return matrixPostsDated;
}

export function transformInArray(matrixToTransform: ScheduleItem[][]): ScheduleItem[] {
  const arrayTransformed = [] as ScheduleItem[];

  matrixToTransform.forEach(row => {
    row.forEach(schedulePost => arrayTransformed.push(schedulePost));
  });

  return arrayTransformed;
}

export function sortArrayByPosition(arrayPosts: ScheduleItem[]): ScheduleItem[] {
  // Array.sort() - WEB DOCS - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  const sortedPosts = [...arrayPosts].sort((a, b) => a.position - b.position);

  return sortedPosts;
}

// TO COUNT BY MONTH
export function handleDateToMonth(
  selectedDay: number,
  itemIndex: number,
  interval: number,
  defaultDateSend: Date
): Date {
  const selectedMonthDay = setDate(defaultDateSend, selectedDay);
  const currentDate = new Date();

  let updatedDate = {} as Date;

  if (selectedDay <= getDaysInMonth(defaultDateSend)) {
    // the date already past
    if (selectedMonthDay < defaultDateSend || currentDate > defaultDateSend) {
      updatedDate = addMonths(selectedMonthDay, 1 + itemIndex * interval);
    } else {
      updatedDate = addMonths(selectedMonthDay, itemIndex * interval);
    }
  } else {
    // The selectedDay doesnt exists in current month
    const monthToSend = addMonths(selectedMonthDay, itemIndex * interval);
    // const lastDayThisMonth =

    updatedDate = setDate(monthToSend, getDaysInMonth(monthToSend));
  }

  return updatedDate;
}
