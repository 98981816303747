import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LanguageIcon from '@mui/icons-material/Language';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { loadCSS } from 'fg-loadcss';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useAdsDistribution from '../../../../hooks/api/distribution/useAdsDistribution';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import { asyncMap } from '../../../../utils/helpers/Functions';
import makeId from '../../../../utils/helpers/makeId';
import DistributionPreview from '../Carousel/DistributionPreview';
import Form from './components/Form';
import GenerateWhatsAppLink from './components/GenerateWhatsAppLink';
import LinearProgress, { normalise } from './components/LinearProgress';
import { PreviewAds } from './components/PreviewAds';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const estimatedReach = {
  1: '3.500 a 7.000',
  2: '6.000 a 10.500',
  3: '10.000 a 15.000',
  4: '14.500 a 19.000',
  5: '19.500 a 25.000'
};

export const TURBOCHARGED_ACTION_VALIDATION_SCHEMA = yup.object({
  objective: yup.object().required('Adicione um objetivo para Distribuição.').nullable(),
  location: yup.object().shape({
    latitude: yup.number().required('Adicione uma Localidade de distribuição.'),
    radius: yup.number().required('Adicione a Distância.')
  }),
  start_time: yup.date().required(),
  end_time: yup.date().required(),
  link: yup.string().url('Link deve ser uma URL válida').required('Link deve ser uma URL válida')
});

const CreateAds = ({
  open,
  isModal = true,
  hasCarousel,
  data,
  onClose,
  backgroundId,
  isFromSchedulePost = false,
  ...props
}) => {
  const {
    adsDistribution: { selectedChannelsFormData, validateForm, currentTab },
    auto_message,
    selectedShareableItem
  } = useSelector(state => state.TabloideDigitalReducer);

  const { validateThisForm } = useAdsDistribution();

  const savedData = selectedChannelsFormData?.filter(
    currentChannel => currentChannel.name === 'turbocharged-action'
  )[0]?.data;

  const [onClickAutoMessage, setOnClickAutoMessage] = useState(false);

  const classes = useStyles();

  const { offerId, offerName, imageId, imageUrl, imageName } = data;

  const { enqueueSnackbar } = useSnackbar();

  const [generateLinkIsOpen, setGenerateLinkIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [balance, setBalance] = useState(null);

  const [targetQuery, setTargetQuery] = useState('');
  const [loadingInterests, setLoadingInterests] = useState(false);
  const [targetOptions, setTargetOptions] = useState([]);

  const [onPreviewAds, setOnPreviewAds] = useState(false);
  const handlePreviewAds = () => {
    setOnPreviewAds(!onPreviewAds);
  };

  const history = useHistory();

  const handleExpandClick = () => {
    setExpanded(prevState => !prevState);
  };

  const handleGenerateLinkOpen = () => {
    setGenerateLinkIsOpen(true);
  };

  const handleGenerateLinkClose = () => {
    setGenerateLinkIsOpen(false);
  };

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetQuery.length > 0) {
        setLoadingInterests(true);
        (async () => {
          await api(`https://api.datasales.info/ads-manager-${AMBIENTE}/targeting-search`, {
            q: targetQuery
          })
            .then(response => {
              setTargetOptions(response.content);
            })
            .finally(() => {
              setLoadingInterests(false);
            });
        })();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [targetQuery]);

  const handleSubmit = async values => {
    let attachedFiles = [];
    let imagesHashes;
    let hash;

    try {
      const isVideo = imageUrl ? imageUrl.endsWith('.mp4') : false;

      let previewHash = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
        {
          isVideo: false,
          image_url:
            'https://s3.amazonaws.com/datasalesio-imagens/produto-imagem/new/logo_veja_essa_oferta.png'
        },
        'POST'
      ).then(resp => resp.content.hash);

      if (hasCarousel) {
        imagesHashes = await asyncMap(
          data.selectedItems,
          async currentCard =>
            await api(
              `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
              {
                isVideo: currentCard?.link ? true : false,
                image_url: currentCard?.link ? currentCard.link : currentCard.image_url
              },
              'POST'
            ).then(resp => resp.content.hash)
        );
      } else {
        hash = await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/adimages`,
          {
            isVideo: isVideo,
            image_url: imageUrl
          },
          'POST'
        ).then(resp => resp.content.hash);
      }

      attachedFiles =
        hasCarousel && imagesHashes
          ? data?.selectedItems.map((card, index) => {
              const cardHash = imagesHashes[index];

              if (!card.link)
                return {
                  image_hash: cardHash,
                  link: values.link
                };
              else
                return {
                  video_id: cardHash,
                  link: values.link,
                  image_hash: previewHash
                };
            })
          : {};

      const genders = values.gender === 'male' ? [1] : values.gender === 'female' ? [2] : [];

      const targeting = JSON.stringify({
        age_min: values.age_min,
        age_max: values.age_max,
        genders,
        publisher_platforms: ['facebook', 'instagram'],
        ...(values.interests &&
          values.interests.length > 0 && {
            flexible_spec: [
              {
                interests: values?.interests.map(interest => ({
                  id: interest.id,
                  name: interest.name
                }))
              }
            ]
          }),
        geo_locations: {
          custom_locations: [
            {
              latitude: String(values.location.latitude),
              longitude: String(values.location.longitude),
              radius: String(values.location.radius),
              distance_unit: 'kilometer'
            }
          ]
        }
      });

      const name = `${JSON.parse(localStorage.getItem('companyId'))}_${
        offerId ? offerId : props.offerId
      }_${offerName ? offerName : props.offerName}${imageId ? `_${imageId}` : ''}${
        imageName ? `_${imageName}` : ''
      }${data.selectionType ? `_${data.selectionType}` : ''}_CARROSSEL_IMPULSIONAMENTO_${makeId(
        8
      )}`.toUpperCase();

      const adsManagerData = {
        campaign: {
          status: 'ACTIVE',
          special_ad_categories: [],
          objective: !hasCarousel ? values.objective.value : 'REACH',
          name
        },
        adSet: {
          start_time: values.start_time,
          end_time: values.end_time,
          bid_amount: 1000,
          billing_event: 'IMPRESSIONS',
          targeting,
          status: 'ACTIVE',
          page_id: '105559578146515',
          name,
          lifetime_budget: values.lifetime_budget === 1000 ? 900 : values.lifetime_budget,
          optimization_goal: !hasCarousel ? values.objective.value : 'REACH'
        },
        adCreative: {
          name,
          object_story_spec: {
            page_id: '105559578146515',
            instagram_actor_id: '3618289208274064',
            ...(hasCarousel
              ? {
                  link_data: {
                    child_attachments: attachedFiles,
                    link: values.link
                  }
                }
              : isVideo
              ? {
                  video_data: {
                    image_url:
                      'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/273579519_306486794833299_1766943318475173688_n.png',
                    video_id: hash,
                    call_to_action: {
                      type: 'LEARN_MORE'
                    }
                  }
                }
              : {
                  link_data: {
                    name: offerName ? offerName : props.offerName,
                    description: values.text,
                    call_to_action: {
                      type: 'LEARN_MORE'
                    },
                    image_hash: hash,
                    link: values.link,
                    message: values.text
                  }
                })
          }
        },
        user_id: 2,
        offer_id: imageId ? imageId : offerId ? offerId : props.offerId
      };

      await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/ads`,
        adsManagerData,
        'POST',
        null,
        null,
        false
      );
      enqueueSnackbar('Distribuição enviado para analise com sucesso', {
        variant: 'success'
      });
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const findLastLimitHistory = async values => {
    try {
      let result = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/lastLimitHistory`,
        {},
        'GET',
        null,
        null,
        false
      );

      if (result && result.content && result.content.balance) {
        setBalance(result.content.balance);
      }
      if (result && result.content && result.content.balance === 0) {
        setBalance(result.content.balance);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    findLastLimitHistory();

    history.push(history.location.pathname + '?CreateAds');
  }, []);

  useEffect(() => () => history.push(history.location.pathname), []);

  const formik = useFormik({
    initialValues: {
      start_time: savedData?.start_time || moment(),
      end_time: savedData?.end_time || moment().add(1, 'days'),
      lifetime_budget: savedData?.lifetime_budget || 1000,
      custom_audience: savedData?.custom_audience || null,
      age_min: savedData?.age_min || 18,
      age_max: savedData?.age_max || 65,
      gender: savedData?.gender || 'all',
      interests: savedData?.interests || [],
      objective: {
        subtitle: savedData?.objective?.subtitle || 'Alcance',
        title: savedData?.objective?.title || 'Mostrar para o maior número de pessoas',
        value: savedData?.objective?.value || 'OUTCOME_AWARENESS'
      },
      location: {
        latitude: savedData?.location?.latitude || undefined,
        longitude: savedData?.location?.longitude || undefined,
        radius: savedData?.location?.radius || 2
      },
      link: savedData?.link || '',
      text: auto_message.message
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: TURBOCHARGED_ACTION_VALIDATION_SCHEMA,
    onSubmit: handleSubmit
  });

  const enoughCredits = formik.values.lifetime_budget <= parseInt(balance);

  const handleLocationChange = async selectedLocation => {
    if (selectedLocation) {
      const place_id = selectedLocation?.place_id;
      try {
        const response = await api('https://api.datasales.info/ads-manager-dev/places/details', {
          place_id
        });

        const { lat, lng } = response.content.result.geometry.location;

        formik.setFieldValue('location.latitude', lat);
        formik.setFieldValue('location.longitude', lng);
        formik.setFieldValue('location.radius', 2);
      } catch (err) {
        console.log(err);
      }
    } else {
      formik.setFieldValue('location.latitude', undefined);
      formik.setFieldValue('location.longitude', undefined);
      formik.setFieldValue('location.radius', 2);
    }
  };

  useEffect(() => {
    validateThisForm(formik, 'turbocharged-action');
  }, [formik.values, validateForm]);

  const handleChangeOnClickAutoMessage = () => {
    setOnClickAutoMessage(prev => !prev);
  };

  useEffect(() => {
    if ((Number(currentTab) === 2 || onClickAutoMessage) && !isFromSchedulePost) {
      if (auto_message.action) {
        formik.setFieldValue('text', auto_message.action);
      } else {
        formik.setFieldValue('text', auto_message.all);
      }
      handleChangeOnClickAutoMessage();
    }
  }, [auto_message.action]);

  if (isModal) {
    return (
      <React.Fragment>
        <Dialog open={open} fullScreen TransitionComponent={Transition} onClose={onClose}>
          <AppBar className={classes.appBar} color="inherit">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Atração de Novos clientes
                {!!!isNaN(parseInt(balance)) && (
                  <Fragment>
                    <Chip
                      className={classes.chipCredits}
                      icon={<CreditCardIcon className={classes.iconCredit} />}
                      label={'Créditos: ' + balance / 1000}
                      color="primary"
                    />
                    {/* <Chip
                      className={classes.chipForca}
                      label={formik.values.lifetime_budget / 1000}
                      color="secondary"
                    /> */}
                  </Fragment>
                )}
                objective
              </Typography>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.content} dividers>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className={classes.formContainer}>
                  <Form
                    backgroundId={backgroundId}
                    formik={formik}
                    uuid={balance}
                    balance={balance}
                    targetOptions={targetOptions}
                    enoughCredits={enoughCredits}
                    isModal={true}
                    expanded={expanded}
                    setTargetQuery={setTargetQuery}
                    loadingInterests={loadingInterests}
                    handlePreviewAds={handlePreviewAds}
                    handleLocationChange={handleLocationChange}
                    handleExpandClick={handleExpandClick}
                    handleGenerateLinkOpen={handleGenerateLinkOpen}
                    handleChangeOnClickAutoMessage={handleChangeOnClickAutoMessage}
                  />
                </div>
              </Grid>

              <Grid item container xs={4} spacing={4}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      avatar={
                        <Avatar className={classes.avatar} variant="rounded">
                          <LanguageIcon />
                        </Avatar>
                      }
                      title="Alcance estimado"
                    />
                    <Divider />
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {estimatedReach[String(formik.values.lifetime_budget / 1000)]}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={normalise(formik.values.lifetime_budget)}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.previewContainer}>
                    {hasCarousel ? (
                      <DistributionPreview carouselItemsStateData={data.selectedItems} />
                    ) : imageUrl.endsWith('.mp4') ? (
                      <video style={{ width: '100%' }} controls loop autoplay="autoplay">
                        <source src={imageUrl} type="video/mp4" />
                      </video>
                    ) : (
                      <img className={classes.preview} alt="post-preview" src={imageUrl} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={'submitADS'}
              disabled={
                !formik.values.text ||
                !formik.values.link ||
                !formik.values.location.latitude ||
                !formik.values.location.radius ||
                formik.isSubmitting ||
                (!isNaN(parseInt(balance)) && (balance <= 0 || !enoughCredits))
              }
              onClick={formik.handleSubmit}
            >
              Distribuir
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={Boolean(formik.isSubmitting)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <CircularProgress size={32} color="primary" />
              </Grid>
              <Grid item>
                <DialogContentText>Distribuindo...</DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <GenerateWhatsAppLink
          open={generateLinkIsOpen}
          onSubmit={value => {
            formik.setFieldValue('link', value);
          }}
          onClose={handleGenerateLinkClose}
        />
        {onPreviewAds && <PreviewAds open={onPreviewAds} handleClose={() => handlePreviewAds()} />}
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.formContainer}>
              <Form
                backgroundId={backgroundId}
                formik={formik}
                uuid={balance}
                balance={balance}
                targetOptions={targetOptions}
                enoughCredits={enoughCredits}
                expanded={expanded}
                setTargetQuery={setTargetQuery}
                loadingInterests={loadingInterests}
                handlePreviewAds={handlePreviewAds}
                handleLocationChange={handleLocationChange}
                handleExpandClick={handleExpandClick}
                handleGenerateLinkOpen={handleGenerateLinkOpen}
                handleChangeOnClickAutoMessage={handleChangeOnClickAutoMessage}
              />
            </div>
          </Grid>
        </Grid>
        <GenerateWhatsAppLink
          open={generateLinkIsOpen}
          onSubmit={value => {
            formik.setFieldValue('link', value);
          }}
          onClose={handleGenerateLinkClose}
        />
        {onPreviewAds && (
          <PreviewAds
            open={onPreviewAds}
            selectedShareableItem={selectedShareableItem}
            handleClose={() => handlePreviewAds()}
          />
        )}
      </>
    );
  }
};

export default CreateAds;
