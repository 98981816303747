import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CustomTable, Modal, Countup } from '../../../utils/components';

const columns = [
  { title: 'Nome', field: 'nome' },
  {
    title: 'Cupons impresso',
    field: 'impresso',
    render: rowData => <Countup end={rowData.impresso} />,
  },
  {
    title: 'Cupons não impresso',
    field: 'n_impresso',
    render: rowData => <Countup end={rowData.n_impresso} />,
  },
  {
    title: 'Data da última compra',
    field: 'ultima_compra',
    render: rowData => (
      <span>
        {moment(
          rowData.ultima_compra ? rowData.ultima_compra.replace('Z', '') : null
        ).format('DD/MM/YYYY HH:mm')}
      </span>
    ),
  },
  {
    title: 'Total gasto no período da campanha',
    field: 'total_gasto',
    render: rowData => (
      <Countup decimals={2} prefix="R$ " end={rowData.total_gasto} />
    ),
  },
];

const ModalPerfil = props => (
  <Modal
    header={props.nomeCupom}
    size="lg"
    showModal={props.open}
    onClose={props.onClose}
  >
    <CustomTable
      data={props.cuponsSorteioPessoas}
      columns={columns}
      paging
      search
      columnsButton
      pageSize={5}
    />
  </Modal>
);

const mapStateToProps = ({ CupomSorteioReducer }) => ({
  ...CupomSorteioReducer,
});

export default connect(mapStateToProps, null)(ModalPerfil);
