import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {
  Autocomplete,
  Checkboxes,
  DateTimePicker,
  makeValidate,
  TextField,
} from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import DialogTitle from '../../../../utils/components/DialogTitle';
import { AMBIENTE } from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  profile: Yup.object()
    .shape({
      profile_key: Yup.string().required(),
    })
    .required('A conta é obrigatório'),
  post: Yup.string().required('A descrição é obrigatório'),
  scheduleDate: Yup.date(),
});

const getCheckboxData = activeSocialAccounts =>
  activeSocialAccounts.map(socialAccount => ({
    label: socialAccount,
    value: socialAccount,
  }));

function CreateAccount({ open, url, onClose }) {
  const classes = useStyles();

  const [profiles, setProfiles] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `https://wtx4z9hj2e.execute-api.us-east-1.amazonaws.com/prd/profile`
        );

        setProfiles(response);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const onSubmit = async values => {
    try {
      const api_url =
        AMBIENTE === 'dev'
          ? 'https://yrwyq4jw43.execute-api.us-east-1.amazonaws.com/dev/post'
          : 'https://wtx4z9hj2e.execute-api.us-east-1.amazonaws.com/prd/post';
      await api(
        api_url,
        {
          ...values,
          platforms:
            values.platforms === true
              ? values.profile.active_social_accounts
              : values.platforms,
          scheduleDate:
            values.scheduleDate &&
            moment(values.scheduleDate).format('YYYY-MM-DDThh:mm:ssZ'),
        },
        'POST'
      );
    } catch (err) {
      console.log(err);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle onClose={onClose}>Criar post</DialogTitle>
      <Divider />
      <DialogContent>
        {profiles ? (
          <Form
            initialValues={{ media_urls: [url] }}
            validate={makeValidate(schema)}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form id="form" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      label="Selecione uma conta"
                      name="profile"
                      required
                      options={profiles}
                      textFieldProps={{ variant: 'outlined' }}
                      getOptionValue={option => option}
                      getOptionLabel={option => option.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="post"
                      label="Descrição"
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateTimePicker
                      label="Data Agendada"
                      name="scheduleDate"
                      inputVariant="outlined"
                      format="DD/MM/YYYY HH:mm"
                      autoOk
                      ampm={false}
                    />
                  </Grid>
                  {values.profile &&
                    values.profile.active_social_accounts.length > 0 && (
                      <Grid item xs={12}>
                        <Checkboxes
                          label="Redes sociais"
                          name="platforms"
                          color="primary"
                          formGroupProps={{ row: true }}
                          required={true}
                          data={getCheckboxData(
                            values.profile.active_social_accounts
                          )}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <img
                      className={classes.imagePreview}
                      alt="preview"
                      src={url}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          />
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            document
              .getElementById('form')
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateAccount;
