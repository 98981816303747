import React from 'react';
import { Box, Chip } from '@material-ui/core';
import DateCustom from '../../../utils/helpers/Date';

export default props => (
  <Box onSelect={props.onSelect}>
    {new DateCustom().arrayMonth().map((month, index) => (
      <Chip key={index}>{month}</Chip>
    ))}
  </Box>
);
