import React, { Fragment } from 'react';
import { ListItem } from '@material-ui/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyButton, ShowMore } from '../../../../utils/components';
import DateCustom from '../../../../utils/helpers/Date';

export default ({
  isLoadingRecomendacao,
  listRecomendacoes,
  onChange,
  deleteRecomendacoes,
}) => (
  <Fragment>
    <MyButton
      onClick={() => onChange('showModalRecomendacoes', true)}
      bsstyle="success"
    >
      Add Recomendações
    </MyButton>
    <ShowMore
      loading={isLoadingRecomendacao}
      filter
      columnFilter="tituloRecomendacao"
      placeholderFilter="Pesquisar por título..."
      headerStyle={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 20%)',
        textAlign: 'center',
      }}
      header={[
        { name: 'tituloRecomendacao', title: 'Título' },
        { name: 'descricaoRecomendacao', title: 'Descrição' },
        { name: false, title: 'Período' },
        { name: false, title: 'Qtde. imagens' },
        { name: false, title: 'Ações' },
      ]}
      data={listRecomendacoes}
      dataFormat={row => (
        <ListItem>
          <section className="listaRecomendacoes">
            <strong>{row.titulo_recomendacao}</strong>
            <span className="descricaoLista">{row.descricao_recomendacao}</span>
            <span>{`${new DateCustom(
              row.dataInicio,
              'DD/MM/YYYY'
            ).getDate()} até ${new DateCustom(
              row.dataFim,
              'DD/MM/YYYY'
            ).getDate()}`}</span>
            <span>{row.images.length}</span>
            <section>
              <MyButton
                onClick={() => deleteRecomendacoes(row.id_recomendacoes)}
                bsstyle="danger"
              >
                <FontAwesomeIcon icon={faTimes} />
              </MyButton>
            </section>
          </section>
        </ListItem>
      )}
    />
  </Fragment>
);
