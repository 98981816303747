import { Grid } from '@material-ui/core';
import { FilterAdvertisements } from 'modules/TabloideDigitalV2/components/FilterAdvertisements';
import moment from 'moment';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AMBIENTE, DATASALES_CRM_API } from 'utils/helpers/Constants';
import Fetch from 'utils/helpers/Fetch';

import FullCalendar from '@fullcalendar/react';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import { StyleWrapper } from '../Projects';
import { DivTitle, TypographySubTitle, TypographyTitle } from '../Studio/styles';

export function Calendar() {
  const [filtroDataInicio, setFiltroDataInicio] = useState(moment().subtract(1, 'month').toDate());
  const [filtroDataFim, setFiltroDataFim] = useState(moment().toDate());
  const [menuSelected, setMenuSelected] = useState(0);
  const [tabloids, setTabloids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openStudio, setOpenStudio] = useState(false);
  const [tabloid, setTabloid] = useState(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const params = useParams();
  const history = useHistory();

  const handleMenu = (valueMenu: number) => {
    setMenuSelected(valueMenu);
  };

  const clickOnTabloid = (item: { [x: string]: any; id_tabloide_digital?: any; }) => {
    history.push('/estudio/' + item.id_tabloide_digital.toString());
  };

  async function getTabloids(data: { isBasicResults?: boolean; dataInicio: any; dataFim: any; } | undefined) {
    // this.props.clearSelectedShareableItem();

    data = {
      ...data,
      dataInicio: (data && data.dataInicio) || moment(filtroDataInicio).format('YYYY-MM-DD'),
      dataFim: (data && data.dataFim) || moment(filtroDataFim).add('1', 'days').format('YYYY-MM-DD')
    };
    if (params?.id) {
      data = {
        ...data,
        id_tabloide: params?.id
      };
    }
    if (nameSearch) {
      data = {
        ...data,
        nome: nameSearch
      };
    }
    try {
      setLoading(true);

      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        data
      );

      setTabloids(response);

      setLoading(false);

      if (params?.id) {
        //testar retorno nulo

        if (response.length > 0) {
          setTabloid(response[0]);
          setOpenStudio(true);
        } else {
          setTabloid({});
        }
      }
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{padding: '1px 39px 80px' }}>
        {' '}
        <StyleWrapper>
          <DivTitle>
            <TypographyTitle
              sx={{
                '&.MuiTypography-root': {
                  fontSize: '1.25rem',
                  lineHeight: '2rem'
                }
              }}
            >
              Calendário de postagens
            </TypographyTitle>
          </DivTitle>
          <TypographySubTitle marginBottom={'2rem'}>
            Veja as datas das postagens e das propagandas criadas
          </TypographySubTitle>

          {/* <Grid item xs={12}>
            {' '}
            <FilterAdvertisements
              nameSearch={nameSearch}
              onChangeText={event => {
                setNameSearch(event.target.value);
              } }
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  history.push('/estudio/' + e.target.value.toString());
                }
              } }
              onClickOpen={() => setOpenFilter(prev => !prev)}
              openFilter={openFilter}
              onChangeDateRange={({ selection }) => {
                console.log(selection);
                setFiltroDataInicio(selection.startDate);
                setFiltroDataFim(selection.endDate);
              } }
              ranges={[
                {
                  startDate: filtroDataInicio,
                  endDate: filtroDataFim,
                  key: 'selection'
                }
              ]}
              onClickSerch={() => getTabloids()} button={undefined}            />
          </Grid> */}
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            datesSet={dateInfo => {
              getTabloids({
                isBasicResults: true,
                dataInicio: moment(dateInfo.startStr).format('YYYY-MM-DD HH:mm:ss'),
                dataFim: moment(dateInfo.endStr).format('YYYY-MM-DD HH:mm:ss')
              });
            }}
            dateClick={arg => alert(arg.dateStr)}
            eventClick={info => {
              clickOnTabloid({ ...info.event.extendedProps });

              // alert('Event: ' + info.event.title);
              // alert(
              //   'Coordinates: ' +
              //     info.jsEvent.pageX +
              //     ',' +
              //     info.jsEvent.pageY
              // );
              // alert('View: ' + info.view.type);

              // change the border color just for fun
              //info.el.style.borderColor = 'red';
            }}
            //dayHeaderContent={arg => <div>TESTE</div>}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today dayGridMonth,timeGridWeek,timeGridDay,listMonth'
            }}
            events={[
              ...tabloids.map(value => ({
                title: value.nome_tabloide_digital,
                date: moment(value.data_insert).format('YYYY-MM-DD'),
                extendedProps: { ...value }
              }))
            ]}
            locale={ptBrLocale}
          />
        </StyleWrapper>
      </Grid>
    </Grid>
  );
}

