/* eslint-disable */
// import moment_tz from "moment-timezone";
import {
  Avatar,
  Button, // Tooltip,
  CircularProgress,
  Divider,
  Icon, //ButtonGroup
  // TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
// import clsx from "clsx";
// import SearchIcon from "@material-ui/icons/Search";
import { loadCSS } from 'fg-loadcss';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { BarChart, CountCard, Panel, PieChart, TextField } from '../../../utils/components';
import MyDate from '../../../utils/helpers/Date';
import { getProdutosCategoria, onChangeState } from '../actions/index';

class DetalhesOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeOferta: '',
      nomeCategoria: '',
      nomeProduto: '',
      produtos: [],
      key: 0,
      showModalProdutos: false
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({
      idOferta: id.split('_')[0],
      nomeOferta: id.split('_')[2],
      nomeCategoria: id.split('_')[3]
    });
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );

    this.props.getProdutosCategoria({
      idOferta: id.split('_')[0],
      idFamilia: id.split('_')[1]
    });
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangeOferta(state, value) {
    this.setState({ oferta: { ...this.state.oferta, [state]: value } });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim } = this.state;
      const request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate()
      };
      this.props.getTodasPerformanceMes(request);
    });
  }

  onKeyPressed(e) {
    const { nome_campanha } = this.state;

    if (e.keyCode === 13 && nome_campanha.length > 0) {
      this.props.getTodasPerformanceMes({ nome_campanha });
    } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
      this.onChangePeriodo();
    }
  }

  async renderImg(url) {
    return (
      <img
        src={'https://trali.com.br/wp-content/uploads/2018/04/Produto-sem-imagem.jpg'}
        crossOrigin="anonymous"
        alt=""
        width={80}
        height={80}
        style={{ borderRadius: 10 }}
      />
    );
  }

  render() {
    const { classes, isLoadingProdutosCategoria, produtosCategoria } = this.props;

    const { produtos, visaoGeral, vendasDia, vendasHora, genero, faixaEtaria, vendasLoja } =
      this.props.produtosCategoria;
    const { key, nomeProduto, nomeCategoria, nomeOferta } = this.state;
    const { id } = this.props.match.params;

    return (
      <div className={classes.root}>
        <Link
          to={`/detalhes-oferta/${id.split('_')[0]}_${nomeOferta}`}
          style={{ textDecoration: 'none' }}
        >
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>
            {`Voltar para ${nomeOferta}`}
          </Button>
        </Link>
        <Typography className={classes.label} variant="h4" noWrap>
          {nomeCategoria}
        </Typography>
        <div className={classes.actions}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            {/* <Typography className={classes.label} variant="h6" noWrap>
              Sugestões de oferta
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    "&:hover": { borderRadius: 10 }
                  }}
                  color="inherit"
                  size="large"
                  className={key === 0 && classes.activeButton}
                  onClick={() => this.setState({ key: 0 })}
                >
                  Categoria
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    "&:hover": { borderRadius: 10 }
                  }}
                  color="inherit"
                  size="large"
                  className={key === 1 && classes.activeButton}
                  onClick={() => this.setState({ key: 1 })}
                >
                  Padrão
                </Button>
              </div>
            </div> */}
          </div>
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              size="lg"
              placeholder="Pesquisar produto..."
              autoComplete="false"
              onChange={e => this.setState({ nomeProduto: e.target.value })}
              value={nomeProduto}
              // onKeyDown={this.onKeyPressed}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled
                      // onClick={() =>
                      //   nomeOferta.length > 0
                      //     ? this.props.getOfertas({ nomeOferta })
                      //     : this.onChangePeriodo()
                      // }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20
          }}
        >
          <div>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 }
              }}
              color="inherit"
              size="large"
              className={key === 0 && classes.activeButton}
              onClick={() => this.setState({ key: 0 })}
            >
              Visão geral
            </Button>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 }
              }}
              color="inherit"
              size="large"
              className={key === 1 && classes.activeButton}
              onClick={() => this.setState({ key: 1 })}
            >
              Produtos
            </Button>
          </div>
        </div>
        <div
          className={classes.content}
          style={
            isLoadingProdutosCategoria
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              : {}
          }
        >
          {isLoadingProdutosCategoria ? (
            <CircularProgress></CircularProgress>
          ) : key === 0 ? (
            <Fragment>
              {visaoGeral.map(prd => (
                <Fragment>
                  <Panel title="Visão geral - Clube" style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.qtde_produtos}
                        title=" Produtos"
                        icon="shop_two"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.qtde_cupom}
                        title="Vendas"
                        icon="score"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.investimento}
                        decimals={2}
                        prefix="R$ "
                        title="Investimento"
                        icon="build"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.faturamento}
                        decimals={2}
                        prefix="R$ "
                        title="Faturamento"
                        icon="add_shopping_cart"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.roi}
                        decimals={2}
                        prefix="R$ "
                        title="ROI"
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={prd.ticket_medio}
                        decimals={2}
                        prefix="R$ "
                        title="Ticket Medio"
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </Panel>

                  {/* <Panel
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Icon>shopping_cart</Icon>
                      </Avatar>
                    }
                    title="Não Clube"
                    subheader={` ${moment(prd.data_inicio).format(
                      "DD/MM/YYYY"
                    )} até ${moment(prd.data_fim).format("DD/MM/YYYY")}`}
                    style={{ marginBottom: 20 }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: 20
                        }}
                      >
                        <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={prd.qtde_cupom_n_clube}
                          title=" Vendas"
                          icon="score"
                          style={{ marginRight: 20 }}
                        />

                        <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={prd.faturamento_n_clube}
                          decimals={2}
                          prefix="R$ "
                          title="Faturamento"
                          icon="add_shopping_cart"
                          style={{ marginRight: 20 }}
                        />
                        <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={prd.ticket_medio_n_clube}
                          decimals={2}
                          prefix="R$ "
                          title="Ticket médio"
                          icon="attach_money"
                          style={{ marginRight: 20 }}
                        />
                      </div>
                    </div>
                  </Panel> */}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Panel
                      width="100%"
                      title="Por loja "
                      //  subheader={detalhesProduto.familia}
                      style={{ marginBottom: 20 }}
                    >
                      <BarChart
                        data={vendasLoja}
                        barDataKey="vendas"
                        xDataKey="nome_loja"
                        hide
                        // xDataKey="nome_loja"
                      />
                    </Panel>
                    <Panel
                      width="100%"
                      title="Por dia "
                      //  subheader={detalhesProduto.familia}
                      style={{ marginBottom: 20 }}
                    >
                      <BarChart data={vendasDia} barDataKey="vendas" xDataKey="data_analise" />
                    </Panel>
                    <Panel
                      width="100%"
                      title="Por Hora "
                      //  subheader={detalhesProduto.familia}
                      style={{ marginBottom: 20 }}
                    >
                      <BarChart data={vendasHora} barDataKey="vendas" xDataKey="horario_analise" />
                    </Panel>
                    <Panel
                      width="49%"
                      title=" Genero"
                      //  subheader={detalhesProduto.familia}
                      style={{ marginBottom: 20 }}
                    >
                      <PieChart data={genero} />
                    </Panel>
                    <Panel
                      width="49%"
                      title=" Faixa etaria"
                      //  subheader={detalhesProduto.familia}
                      style={{ marginBottom: 20 }}
                    >
                      <PieChart data={faixaEtaria} />
                    </Panel>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ) : (
            produtos.map(prd => (
              <Panel
                avatar={
                  <img
                    src={
                      produtosCategoria.url ||
                      'https://trali.com.br/wp-content/uploads/2018/04/Produto-sem-imagem.jpg'
                    }
                    crossOrigin="anonymous"
                    alt=""
                    width={80}
                    height={80}
                    style={{ borderRadius: 10 }}
                  />
                }
                title={prd.PRODUTO}
                subheader={prd.categoria}
                style={{ marginBottom: 20 }}
                // {oferta.tipo_oferta !== "cupom" && (
                action={
                  <Button
                    size="large"
                    className={classes.button}
                    variant="text"
                    color="primary"
                    component={Link}
                    to={`/detalhes-produto/${prd.id_oferta}_${prd.id_produto}_${prd.id_categoria}_${nomeOferta}_${prd.PRODUTO}_${nomeCategoria}`}
                  >
                    Detalhes do Produto
                  </Button>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.preco_original}
                    decimals={2}
                    prefix="R$ "
                    title="Preço"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  />
                  {/* <CountCard
                    count
                    titleTypographyProps={{ variant: "h6" }}
                    end={prd.preco_dois}
                    decimals={2}
                    prefix="R$ "
                    title="Preço dois"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  /> */}
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.qtde_cupom}
                    // decimals={2}
                    // prefix="R$ "
                    title="Vendas"
                    icon="score"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.investimento}
                    decimals={2}
                    prefix="R$ "
                    title="Investimento"
                    icon="build"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.faturamento}
                    decimals={2}
                    prefix="R$ "
                    title="Faturamento"
                    icon="add_shopping_cart"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.roi}
                    decimals={2}
                    prefix="R$ "
                    title="ROI"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={prd.ticket_medio}
                    decimals={2}
                    prefix="R$ "
                    title="Ticket Medio"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Panel>
            ))
          )}
        </div>
        {/* {showModalProdutos && (
          <ModalProdutos
            open={showModalProdutos}
            onClose={() => this.setState({ showModalProdutos: false })}
            produtosCategoria={produtos}
          ></ModalProdutos>
        )} */}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2)
  },
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main
    }
  }
});

const mapStateToProps = ({ OfertasReducer }) => ({
  ...OfertasReducer
});

const mapDispatchToProps = {
  onChangeState,
  getProdutosCategoria
};

const DetalhesOfertaConnect = connect(mapStateToProps, mapDispatchToProps)(DetalhesOferta);

export default withStyles(styles)(DetalhesOfertaConnect);
