import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { StateProps } from '../../../../../store/types';
import {
  clearSelectedShareableItem,
  setAdsDistributionCurrentChannel,
  setFlyersToSelectWhenRedirectToOffer,
  setIsDistributionOpened
} from '../../../action';
import { TabloideDigitalAdsDistributionStateProps } from '../../../reducer/types';
import { useStyles } from '../styles';
import DialogAlertVideo from './DialogAlertVideo';

type ProductsFooterProps = {
  show: any;
  isDistributionOpen: any;
  selectedShareableItem: any;
  adsDistribution: TabloideDigitalAdsDistributionStateProps;
  onDistributeCarousel: any;
  onClearDistribution: any;
  onDownloadImage: any;
  onDownloadZipFooter: any;
  onDownloadPdf: any;
  onDownloadPdfTrimArea: any;
  clearSelectedShareableItem: any;
  onDisableManyItems: any;
  setIsDistributionOpened: any;
  setAdsDistributionCurrentChannel: any;
  getEncartesNovoEstudio: any;
  progressDownload: any;
};

function ProductsFooter({
  show,
  isDistributionOpen,
  selectedShareableItem,
  adsDistribution,
  onDistributeCarousel,
  onClearDistribution,
  onDownloadImage,
  onDownloadZipFooter,
  onDownloadPdf,
  onDownloadPdfTrimArea,
  clearSelectedShareableItem,
  onDisableManyItems,
  setIsDistributionOpened,
  setAdsDistributionCurrentChannel,
  getEncartesNovoEstudio,
  progressDownload,
  ...props
}: ProductsFooterProps) {
  const classes = useStyles();

  const history = useHistory();

  const permissions = JSON.parse(localStorage?.getItem('permissions') || '{}');
  const noDistribution = permissions?.criacao?.find((item: any) => item?.serviceId === 66);

  const [showDialogAlertVideo, setShowDialogAlertVideo] = useState<boolean>(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [countSelectedItems, setCountSelectedItems] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTypePublication, setAnchorElTypePublication] = useState(null);

  const toggleDownloadButtonMenu = event => {
    if (hasMoreThanOneVideo) {
      setShowDialogAlertVideo(true);
    } else if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const toggleTypePublicationMenu = event => {
    if (anchorElTypePublication !== event.currentTarget) {
      setAnchorElTypePublication(event.currentTarget);
    }
  };

  const hasVideoInItems = useMemo(() => {
    return selectedShareableItem?.find((item: any) => item.id_videomatik) as boolean;
  }, [selectedShareableItem]);

  const hasMoreThanOneVideo = useMemo(() => {
    const onlyVideos = selectedShareableItem?.filter((item: any) => item.id_videomatik);
    return onlyVideos.length > 1;
  }, [selectedShareableItem]);

  function isDistributionButtonDisabled() {
    if (!hasSelected) {
      return true;
    }

    return false;
  }

  function handleCloseDownloadButtonMenu() {
    setAnchorEl(null);
  }

  function handleCloseTypePublicationMenu() {
    setAnchorElTypePublication(null);
  }

  const handleCloseDistribution = () => {
    onClearDistribution();
    clearSelectedShareableItem();
  };

  useEffect(() => {
    if (selectedShareableItem.length > 0) {
      setHasSelected(true);
      setCountSelectedItems(selectedShareableItem.length);
    } else {
      setHasSelected(false);
      setCountSelectedItems(0);
    }
  }, [selectedShareableItem.length]);

  useEffect(() => {
    if (isDistributionOpen) {
      setIsDistributionOpened(true);
    } else {
      setIsDistributionOpened(false);
    }
  }, [isDistributionOpen]);

  const defaultDistribution = () => {
    if (selectedShareableItem.length <= 10) {
      onDistributeCarousel();
    } else {
      isDistributionButtonDisabled();
    }
  };

  const scheduledDistribution = () => {
    history.push('/posts');
  };

  useEffect(() => {
    if (props.flyersToSelectWhenRedirectToOffer?.readyToDistribute) {
      switch (props.flyersToSelectWhenRedirectToOffer?.typeToDistribute) {
        case 'default':
          props.setFlyersToSelectWhenRedirectToOffer(null);
          defaultDistribution();
          break;
        case 'scheduled':
          props.setFlyersToSelectWhenRedirectToOffer(null);
          scheduledDistribution();
          break;
      }
    }
  }, [props.flyersToSelectWhenRedirectToOffer]);

  return (
    <Box className={classes.distributionFooter + (show ? ` ${classes.hasSelectedItems} ` : '')}>
      <Grid container rowSpacing={1} direction="row" className={classes.footerContainer}>
        <Grid item xs={!isDistributionOpen ? 4 : 0} className={classes.informationMessage}>
          <Typography
            className={classes.informationMessageText}
            style={{ paddingLeft: '40px', color: '#000' }}
          >
            Selecione imagens para distribuir. <br />
            Para distribuir em carrossel, selecione múltiplas imagens.
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.buttonsWrapper}>
          <Box style={{ position: 'relative' }}>
            <Button
              size="large"
              variant="text"
              className={
                classes.distributionButton +
                (!hasSelected ? ` ${classes.distributionButtonDisabled}` : '')
              }
              disabled={!hasSelected || progressDownload}
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              startIcon={<FileDownloadOutlinedIcon className={classes.buttonIconCentralizer} />}
              onClick={toggleDownloadButtonMenu}
              onMouseOver={handleCloseDownloadButtonMenu}
            >
              {progressDownload ? `${Math.round(progressDownload)}%` : 'Baixar'}
            </Button>

            <Menu
              id="simple-menu"
              variant="menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDownloadButtonMenu}
              className={classes.downloadButtonMenu}
              classes={{
                root: classes.downloadButtonMenuRoot,
                paper: classes.downloadButtonMenuPaper,
                list: classes.downloadButtonMenuList
              }}
              style={{ zIndex: 99999, top: '-48px' }}
              color="primary"
              MenuListProps={{
                onMouseLeave: handleCloseDownloadButtonMenu,
                style: {
                  padding: 0,
                  color: '#000'
                }
              }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              {(parseInt(localStorage.getItem('companyId')!) === 1115 ||
                parseInt(localStorage.getItem('companyId')!) === 396 ||
                parseInt(localStorage.getItem('companyId')!) === 9628) && (
                <MenuItem
                  disabled={hasVideoInItems}
                  onClick={onDownloadPdfTrimArea}
                  style={{
                    padding: '8px 16px'
                  }}
                >
                  <ListItemIcon style={{ minWidth: '32px' }}>
                    <PictureAsPdfOutlinedIcon fontSize="small" style={{ color: '#000' }} />
                  </ListItemIcon>
                  <Box className={classes.wrapDownloadOptions}>
                    <Typography variant="inherit" noWrap className={classes.titleBold}>
                      PDF para impressão
                    </Typography>
                    <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                      PDF com marca de corte
                    </Typography>
                  </Box>
                </MenuItem>
              )}

              <MenuItem
                disabled={hasVideoInItems}
                onClick={onDownloadPdf}
                style={{
                  padding: '8px 16px'
                }}
              >
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <PictureAsPdfOutlinedIcon fontSize="small" style={{ color: '#000' }} />
                </ListItemIcon>

                <Box className={classes.wrapDownloadOptions}>
                  <Typography variant="inherit" noWrap className={classes.titleBold}>
                    PDF padrão
                  </Typography>
                  <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                    PDF ideal para uso digital
                  </Typography>
                </Box>
              </MenuItem>

              <MenuItem onClick={onDownloadImage} style={{ padding: '8x 16px' }}>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <BurstModeOutlinedIcon fontSize="small" style={{ color: '#000' }} />
                </ListItemIcon>
                <Box className={classes.wrapDownloadOptions}>
                  <Typography variant="inherit" noWrap className={classes.titleBold}>
                    Arquivos unitários (mp4 ou jpeg)
                  </Typography>
                  <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                    Imagens para uso digital
                  </Typography>
                </Box>
              </MenuItem>

              <MenuItem
                disabled={hasVideoInItems}
                onClick={onDownloadZipFooter}
                style={{
                  padding: '8px 16px'
                }}
              >
                <ListItemIcon style={{ minWidth: '32px' }}>
                  <FolderZipOutlinedIcon fontSize="small" style={{ color: '#000' }} />
                </ListItemIcon>
                <Box className={classes.wrapDownloadOptions}>
                  <Typography variant="inherit" noWrap className={classes.titleBold}>
                    Arquivos compactados (zip)
                  </Typography>
                  <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                    Arquivo único com todas as artes
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>

          <Box style={{ position: 'relative' }}>
            {!noDistribution && (
              <Button
                size="large"
                variant="text"
                className={
                  classes.distributionButton +
                  (!hasSelected ? ` ${classes.distributionButtonDisabled}` : '')
                }
                disabled={isDistributionButtonDisabled()}
                aria-owns={anchorElTypePublication ? 'simple-menu-type-publication' : undefined}
                startIcon={<ShareOutlinedIcon className={classes.buttonIconCentralizer} />}
                onClick={e => toggleTypePublicationMenu(e)}
                onMouseOver={handleCloseTypePublicationMenu}
              >
                Distribuir
              </Button>
            )}

            <Menu
              id="simple-menu-type-publication"
              variant="menu"
              anchorEl={anchorElTypePublication}
              open={Boolean(anchorElTypePublication)}
              onClose={handleCloseTypePublicationMenu}
              className={classes.downloadButtonMenu}
              classes={{
                root: classes.downloadButtonMenuRoot,
                paper: classes.downloadButtonMenuPaper,
                list: classes.downloadButtonMenuList
              }}
              style={{ zIndex: 99999, top: '-48px' }}
              color="primary"
              MenuListProps={{
                onMouseLeave: handleCloseTypePublicationMenu,
                style: {
                  padding: 0,
                  color: '#000'
                }
              }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <MenuItem
                disabled={selectedShareableItem.length > 10 ? true : false}
                onClick={() => defaultDistribution()}
              >
                <Box className={classes.wrapDownloadOptions}>
                  <Typography variant="inherit" noWrap className={classes.titleBold}>
                    Publicação comum
                  </Typography>
                  <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                    Publique suas artes agora
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={() => scheduledDistribution()} disabled={hasVideoInItems}>
                <Box className={classes.wrapDownloadOptions}>
                  <Typography variant="inherit" noWrap className={classes.titleBold}>
                    Publicação agendada
                  </Typography>
                  <Typography variant="inherit" noWrap className={classes.descriptionOption}>
                    Programe seus posts para o ano todo
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>

          <Button
            size="large"
            variant="text"
            className={
              classes.distributionButton +
              (!hasSelected ? ` ${classes.distributionButtonDisabled}` : '')
            }
            disabled={!hasSelected}
            startIcon={<DeleteOutlinedIcon className={classes.buttonIconCentralizer} />}
            onClick={() => {
              onDisableManyItems(selectedShareableItem);
              handleCloseDistribution();
            }}
          >
            Apagar
          </Button>
        </Grid>

        <Grid item xs={4} className={classes.selectedItemsAndCancelWrapper}>
          <Typography>
            {countSelectedItems} Selecionado{countSelectedItems > 1 ? 's' : ''}
          </Typography>
          <Button
            size="large"
            variant="text"
            className={classes.cancelButton}
            startIcon={
              <ClearIcon
                className={classes.buttonIconCentralizer}
                style={{
                  fontSize: '1.8rem'
                }}
              />
            }
            onClick={handleCloseDistribution}
          />
        </Grid>
      </Grid>
      <DialogAlertVideo
        show={showDialogAlertVideo}
        onClose={() => setShowDialogAlertVideo(false)}
      />
    </Box>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }: StateProps) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  clearSelectedShareableItem,
  setIsDistributionOpened,
  setAdsDistributionCurrentChannel,
  setFlyersToSelectWhenRedirectToOffer
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFooter);

