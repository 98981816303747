import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import Fetch from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  provider_id: Yup.number().required(),
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Email invalído')
    .required('O nome é obrigatório'),
  password: Yup.string()
    .min(6, 'A senha tem que ter mais que 6 caractéres.')
    .required('A senha é obrigatório.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas estão diferentes.')
    .required('Confirmação da senha é obrigatório.'),
});

function NewUser() {
  const classes = useStyles();

  const history = useHistory();

  const { id } = useParams();

  async function onSubmit(data) {
    try {
      await Fetch(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/provider/user/new`,
        data,
        'POST'
      );

      history.push(`/trade/provider/${id}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item>
          <Grid item>
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                history.push(`/trade/provider/${id}`);
              }}
            >
              voltar
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Box className={classes.root}>
            <Grid container spacing={4}>
              <Grid item container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6">Adicionar Usuário</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Form
                  initialValues={{ provider_id: id }}
                  onSubmit={onSubmit}
                  validate={makeValidate(schema)}
                  render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            name="name"
                            fullWidth
                            variant="outlined"
                            label="Nome"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="email"
                            fullWidth
                            variant="outlined"
                            label="Email"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="password"
                            fullWidth
                            variant="outlined"
                            label="Senha"
                            security
                            type="password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="confirmPassword"
                            fullWidth
                            variant="outlined"
                            label="Confirme sua Senha"
                            security
                            type="password"
                          />
                        </Grid>

                        <Grid item container spacing={2}>
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              disabled={submitting}
                              startIcon={<SaveIcon />}
                            >
                              Salvar
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="large"
                              disabled={submitting}
                              startIcon={<UndoIcon />}
                            >
                              Limpar
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="large"
                              onClick={() => {
                                history.push('/modules');
                              }}
                              startIcon={<CloseIcon />}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewUser;
