import * as constants from "../constants/constants";
const INITIAL_STATE = {
  loading: false,
  selectedUser: undefined,
  selectedUserRoles: undefined,
  roles: [],
  users: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.GET_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case constants.GET_USUARIOS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case constants.SELECT_USER:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload
      };
    case constants.RESET_USER:
      return {
        ...state,
        loading: false,
        selectedUser: undefined,
        selectedUserRoles: undefined
      };
    case constants.CHANGE_STATUS_ROLE: {
      const novoState = state.roles.map(ele =>
        ele.roleId === action.payload.roleId ? (ele = action.payload) : ele
      );
      return {
        ...state,
        roles: novoState
      };
    }
    case constants.CHANGE_STATUS_SELECTED_ROLE: {
      const novoState = state.selectedUserRoles.map(ele =>
        ele.roleId === action.payload.roleId ? (ele = action.payload) : ele
      );
      return {
        ...state,
        selectedUserRoles: novoState
      };
    }
    case constants.ALL_ROLES: {
      if (state.selectedUser.roles && state.selectedUser.roles.length <= 0)
        return {
          ...state,
          rolesDoGrupo: state.roles
        };
      const roles = state.selectedUser.roles.map(x => ({
        ...x,
        status: true
      }));
      const idRolesUser = roles.map(x => x.idRole);
      const filteredRoles = state.roles.filter(
        x => idRolesUser.indexOf(x.idFlag) < 0
      );
      const newroles = [...roles, ...filteredRoles];
      const rolesOrganizadas = newroles.sort((a, b) =>
        a.status < b.status ? 1 : a.status === b.status ? 0 : -1
      );

      return {
        ...state,
        selectedUserRoles: rolesOrganizadas,
        loading: false
      };
    }

    default:
      return state;
  }
};
