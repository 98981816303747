import React, { Fragment, useEffect } from 'react';

// Usage Components
import { Typography } from '@material-ui/core';
import { MyRadio } from '../../../../utils/components';

// Usage Constants
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

export default function TabRules(props) {
  useEffect(() => props.onChange('ruleEnrichment', 'update'), []);
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Regras
      </Typography>
      <MyRadio.ContentRadio
        name="rules1"
        defaultValue="update"
        onChange={({ target: { value } }) =>
          props.onChange('ruleEnrichment', value)
        }
      >
        <MyRadio.Radio
          value="update"
          label="Substituir valores existentes"
        ></MyRadio.Radio>
        <MyRadio.Radio
          value="complete"
          label="Completar campos em branco"
        ></MyRadio.Radio>
      </MyRadio.ContentRadio>
    </Fragment>
  );
}
