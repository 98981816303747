/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { EditJson, GeneratePixelixe, GeneratePixelixeDetalhe, PixelixeIframe } from '../';
import { DialogAlert } from '../../../../utils/components';
import Alert from '../../../../utils/helpers/Alert';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import DSAWS from '../../../../utils/helpers/DSAWS';
import { toBase64 } from '../../../../utils/helpers/Functions';
import {
  deleteEncarteFisico,
  getOfertaFisicoAll,
  getTabloidsTemplatesFisico
} from '../../action/index';

const alerta = new Alert();

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

class ListaOfertasEncarte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      qtde_produtos_lista: null,
      tipoPixelixe: null,
      generatePixelixe: false,
      pixelixeIframe: false,
      openDetalhe: false,
      openEditJson: false,
      itemSelected: [],
      nome_tabloide: null,
      download: false,
      expanded: {},
      page: 1,
      showDialog: false,
      deleteId: null,
      progressDownload: 0
    };
    this.myRef = React.createRef();
  }

  handleExpandClick = j => {
    let tmp = this.state.expanded;
    tmp[j] = !tmp[j];
    this.setState({ expanded: tmp });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id: id.split('_')[0] });
    this.setState({ qtde_produtos_lista: id.split('_')[1] });
    this.setState({ tipoPixelixe: id.split('_')[2] });
    this.setState({ nome_tabloide: id.split('__')[1] });

    this.props.getOfertaFisicoAll({
      id: id.split('_')[0],
      tipoPixelixe: id.split('_')[2]
    });

    this.props.getTabloidsTemplatesFisico({
      tipoPixelixe: id.split('_')[2]
    });

    if (window.location.href.indexOf('_enviado') > -1)
      alerta.alertSuccess('Enviado!', 'Pedido enviado para processamento.');

    this.interval = setInterval(() => {
      let array = this.props.paginas_fisico_all.response.filter(
        value => (!value.link && !value.error) || (!value.link_html && !value.error_html)
      );
      if (array.length > 0) {
        this.props.getOfertaFisicoAll(
          {
            id: id.split('_')[0],
            tipoPixelixe: id.split('_')[2]
          },
          false
        );
        this.props.getTabloidsTemplatesFisico(
          {
            tipoPixelixe: id.split('_')[2]
          },
          false
        );
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onRefresh() {
    this.props.getOfertaFisicoAll({
      id: this.state.id,
      tipoPixelixe: this.state.tipoPixelixe
    });
    this.props.getTabloidsTemplatesFisico({
      tipoPixelixe: this.state.tipoPixelixe
    });
  }

  render() {
    const { classes } = this.props;
    const { page } = this.state;

    const data = this.props.paginas_fisico_all.response.filter(value => value.from === null);
    const loading = this.props.loading_tabloide_fisico;

    return (
      <div className={classes.root} ref={this.myRef}>
        <Link to={`/propaganda-de-ofertas`} style={{ marginTop: 15, textDecoration: 'none' }}>
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Propaganda de Ofertas
          </Button>
        </Link>
        <div className={classes.actions}>
          <div className={classes.actions} style={{ maxWidth: '65vw' }}>
            <Typography className={classes.margin} variant="h3">
              {this.state.tipoPixelixe === 'encarte' && 'Encarte - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'facebook' && 'Facebook - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'instagram' && 'Instagram - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'whatsapp' && 'Whatsapp - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'cartaz' &&
                'Cartazeamento - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'etiqueta' && 'Etiqueta - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'email' &&
                'Email Marketing - ' + this.state.nome_tabloide}
              {this.state.tipoPixelixe === 'indoor' && 'Mídia Indoor - ' + this.state.nome_tabloide}
            </Typography>
          </div>
          <div style={{ marginBottom: 20, display: 'flex' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: COLOR_PRIMARY,
                marginRight: 15,
                marginBottom: 10,
                display: 'none'
              }}
              onClick={() => this.setState({ openEditJson: true })}
            >
              Editar Json
            </Button>
            {this.state.download ? (
              <div style={{ marginRight: 30, marginBottom: 10 }}>
                <Box position="relative" display="inline-flex">
                  <CircularProgress />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >{`${Math.round(this.state.progressDownload)}%`}</Typography>
                  </Box>
                </Box>
              </div>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: COLOR_PRIMARY,
                  marginRight: 15,
                  marginBottom: 10
                }}
                onClick={async () => {
                  this.setState({ download: true, progressDownload: 0 });
                  const imageFile = [];
                  for (let i = 0; i < this.props.paginas_fisico_all.response.length; i++) {
                    let link = this.props.paginas_fisico_all.response[i].link;
                    if (validURL(link)) {
                      //let imageBlob = await toBase64(link);
                      let imageBlob = await DSAWS.getObjectGeral({
                        link
                      });

                      imageFile.push({
                        name: i + '.' + link.split('.')[link.split('.').length - 1],
                        base64: imageBlob
                      });
                    }
                    this.setState({
                      progressDownload: (i / this.props.paginas_fisico_all.response.length) * 100
                    });
                  }
                  let zip = new JSZip();
                  let folder = zip.folder('collection');
                  imageFile.forEach((image, index) =>
                    folder.file(image.name, image.base64.split(',')[1], {
                      base64: true
                    })
                  );
                  folder.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'files'));
                  this.setState({ download: false });
                }}
              >
                <GetAppIcon />
              </Button>
            )}
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: COLOR_PRIMARY,
                marginRight: 15,
                marginBottom: 10
              }}
              onClick={() => this.setState({ generatePixelixe: true })}
            >
              Gerar novo
            </Button>
            {/* <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15, marginBottom: 10 }}
              onClick={() => this.onRefresh()}
            >
              <Icon>sync</Icon>
            </Button> */}
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div className={classes.content}>
            <div
              style={{
                margin: 'auto',
                marginBottom: 20,
                display: loading ? 'none' : 'block'
              }}
            >
              <Pagination
                classes={{
                  ul: classes.ul
                }}
                count={Math.ceil(data.length / 20)}
                page={page}
                onChange={(e, v) => this.setState({ page: v })}
              />
            </div>

            <div
              style={{
                display: loading ? 'block' : 'none',
                textAlign: 'center'
              }}
            >
              <CircularProgress></CircularProgress>
            </div>

            <div
              style={{
                display: loading ? 'none' : 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              {data.length === 0 && 'Agora é só clicar em Gerar novo!'}
              {data.map(
                (value, index) =>
                  index < page * 20 &&
                  index >= page * 20 - 20 && (
                    <div
                      style={{
                        margin: 20
                      }}
                    >
                      <Card className={classes.rootCard}>
                        <CardContent>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {value.nome_modelo}
                          </Typography>
                        </CardContent>
                        <CardContent
                          style={{
                            height: '300px',
                            display: value.link && value.link_html ? 'none' : 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {((!value.link && !value.error) ||
                            (!value.link_html && !value.error_html)) && (
                            <Tooltip title="Processando" placement="top">
                              <CircularProgress size={22} thickness={4} />
                            </Tooltip>
                          )}

                          {((!value.link && value.error) ||
                            (!value.link_html && value.error_html)) && (
                            <p>Processamento não finalizado</p>
                          )}

                          {(value.error || value.error_html) && (
                            <div>
                              {value.error && (
                                <React.Fragment>
                                  <IconButton
                                    onClick={() => window.alert(value.error)}
                                    style={{ padding: 0, margin: 10 }}
                                  >
                                    <Icon style={{ fontSize: 20 }}>error</Icon>
                                  </IconButton>
                                </React.Fragment>
                              )}

                              {value.error_html && (
                                <React.Fragment>
                                  <IconButton
                                    onClick={() => window.alert(value.error_html)}
                                    style={{ padding: 0, margin: 10 }}
                                  >
                                    <Icon style={{ fontSize: 20, color: 'blue' }}>error</Icon>
                                  </IconButton>
                                </React.Fragment>
                              )}
                            </div>
                          )}
                        </CardContent>
                        <CardMedia
                          className={classes.media}
                          image={value.link}
                          style={value.link && value.link_html ? {} : { display: 'none' }}
                        />
                        <CardActions disableSpacing>
                          {value.link && value.link_html && (
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              style={{ backgroundColor: COLOR_PRIMARY }}
                              onClick={() => {
                                this.setState({
                                  itemSelected: value.id,
                                  openDetalhe: true
                                });
                              }}
                            >
                              Visualizar
                            </Button>
                          )}
                          <IconButton
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: !!this.state.expanded[index]
                            })}
                            onClick={() => this.handleExpandClick(index)}
                            aria-expanded={!!this.state.expanded[index]}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </CardActions>
                        <Collapse in={!!this.state.expanded[index]} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Typography paragraph>Id: {value.id}</Typography>
                            <Typography paragraph>Arquivo: {value.pagina}</Typography>
                            <Typography paragraph>
                              {moment(value.data).format('DD/MM/YYYY hh:mm:ss')}
                            </Typography>
                            <Button
                              size="large"
                              color="secondary"
                              variant="text"
                              onClick={() =>
                                this.setState({
                                  showDialog: true,
                                  deleteId: value.id
                                })
                              }
                              style={{ marginTop: 20 }}
                            >
                              Desativar
                            </Button>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  )
              )}
            </div>

            <div
              style={{
                margin: 'auto',
                marginTop: 20,
                display: loading ? 'none' : 'block'
              }}
            >
              <Pagination
                classes={{
                  ul: classes.ul
                }}
                count={Math.ceil(data.length / 20)}
                page={page}
                onChange={(e, v) => {
                  this.setState({ page: v });
                  this.myRef.current.scrollIntoView();
                }}
              />
            </div>

            {/* ANTIGA TABELA - FUTURAMENTE APAGAR */}
            {/* <CustomTable
              data={this.props.paginas_fisico_all.filter((value) => value.from === null)}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loading_tabloide_fisico}
              emptyDataSourceMessage={"Agora é só clicar em Gerar novo!"}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
                display: 'none'
              }}
              columns={[    
                { 
                  title: 'Id', 
                  field: 'id' 
                },        
                { 
                  title: 'Modelo', 
                  field: 'nome_modelo' 
                },
                { 
                  title: 'Arquivo', 
                  field: 'pagina' 
                },
                { 
                  title: 'Data', 
                  field: 'data',
                  render: rowData => (
                    moment(rowData.data).format('DD/MM/YYYY hh:mm:ss')
                  )
                },
                { 
                  title: '', 
                  field: '',
                  render: rowData => (
                    <div style={{ textAlign: 'center' }}>

                      {((!rowData.link && !rowData.error) || (!rowData.link_html && !rowData.error_html)) 
                       && (
                        <Tooltip
                          title="Processando"
                          placement="top"
                        >
                          <CircularProgress size={22} thickness={4} />
                        </Tooltip>
                       )}

                      {((!rowData.link && rowData.error) || (!rowData.link_html && rowData.error_html)) 
                       && (<p>Processamento não finalizado</p>)}

                      {rowData.link && rowData.link_html && (
                        <Button
                          variant="contained" color="primary" disableElevation
                          style={{ backgroundColor: COLOR_PRIMARY }}
                          onClick={() => {
                            this.setState({ 
                              itemSelected: rowData.id,
                              openDetalhe: true 
                            });
                          }}
                        >
                          Visualizar
                        </Button>
                      )}

                      {rowData.error &&
                      <React.Fragment>
                        <IconButton
                          onClick={() => window.alert(rowData.error)}
                          style={{ padding: 0, margin: 10 }}
                        >
                          <Icon style={{ fontSize: 20 }}>error</Icon>
                        </IconButton>
                      </React.Fragment>}

                      {rowData.error_html &&
                      <React.Fragment>
                        <IconButton
                          onClick={() => window.alert(rowData.error_html)}
                          style={{ padding: 0, margin: 10 }}
                        >
                          <Icon style={{ fontSize: 20, color: 'blue' }}>error</Icon>
                        </IconButton>
                      </React.Fragment>}

                    </div>
                  ),
                },
              ]}
              deleteText="Deseja desativar esse item?"
              editable={{              
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props.deleteEncarteFisico({
                      from: oldData.id
                    }).then(() => {
                      this.props.getOfertaFisicoAll({ 
                        id: this.state.id,
                        tipoPixelixe: this.state.tipoPixelixe,
                      });
                      resolve();
                    });                    
                  }),
              }}          
            /> */}
          </div>
        </div>
        {this.state.generatePixelixe && (
          <GeneratePixelixe
            open={this.state.generatePixelixe}
            tabloid={{
              id_tabloide_digital: this.state.id,
              qtde_produtos_lista: this.state.qtde_produtos_lista
            }}
            onClose={() => {
              this.setState({ generatePixelixe: false });
              this.onRefresh();
            }}
            tipoPixelixe={this.state.tipoPixelixe}
          ></GeneratePixelixe>
        )}
        {this.state.pixelixeIframe && (
          <PixelixeIframe
            open={this.state.pixelixeIframe}
            onClose={() => {
              this.setState({ pixelixeIframe: false });
            }}
            tipoPixelixe={this.state.tipoPixelixe}
          ></PixelixeIframe>
        )}
        {this.state.openDetalhe && (
          <GeneratePixelixeDetalhe
            open={this.state.openDetalhe}
            onClose={() => {
              this.setState({ openDetalhe: false });
              this.props.getTabloidsTemplatesFisico({
                tipoPixelixe: this.state.tipoPixelixe
              });
            }}
            itemSelected={this.props.paginas_fisico_all.response.filter(
              value =>
                value.from === this.state.itemSelected || value.id === this.state.itemSelected
            )}
            tipoPixelixe={this.state.tipoPixelixe}
            onRefresh={() =>
              this.props.getOfertaFisicoAll({
                id: this.state.id,
                tipoPixelixe: this.state.tipoPixelixe
              })
            }
          ></GeneratePixelixeDetalhe>
        )}
        {this.state.openEditJson && (
          <EditJson
            open={this.state.openEditJson}
            onClose={() => {
              this.setState({ openEditJson: false });
            }}
            tipoPixelixe={this.state.tipoPixelixe}
          ></EditJson>
        )}
        {this.state.showDialog && (
          <DialogAlert
            open={this.state.showDialog}
            title="Deseja realmente desativar o arquivo?"
            onClick={() => {
              this.setState({ showDialog: false, expanded: {} });
              this.props
                .deleteEncarteFisico({
                  from: this.state.deleteId
                })
                .then(() => {
                  this.props.getOfertaFisicoAll({
                    id: this.state.id,
                    tipoPixelixe: this.state.tipoPixelixe
                  });
                });
            }}
            onClose={() => this.setState({ showDialog: false })}
          ></DialogAlert>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  ul: {
    width: 'fit-content',
    margin: 'auto'
  },

  rootCard: {
    width: 200
  },
  media: {
    height: 0,
    paddingTop: '300px'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  getOfertaFisicoAll,
  getTabloidsTemplatesFisico,
  deleteEncarteFisico
};

const ListaOfertasEncarteConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaOfertasEncarte);

export default withStyles(styles)(ListaOfertasEncarteConnect);

