import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useFormState } from 'react-final-form';
import useStyles from './styles';

const formatDate = date => moment(date).format('DD/MM/YYYY HH:mm');

function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

const objectives = {
  BRAND_AWARENESS: 'Reconhecimento de Marca',
  REACH: 'Alcance',
  POST_ENGAGEMENT: 'Engajamento com a publicação',
  PAGE_LIKES: 'Curtidas na Página',
  EVENT_RESPONSES: 'Participações no evento',
  APP_INSTALLS: 'Instalações de aplicativo',
  VIDEO_VIEWS: 'Visualizações de vídeo',
  LEAD_GENERATION: 'Geração de cadastros',
  MESSAGES: 'Mensagens',
  CONVERSIONS: 'Conversões',
  PRODUCT_CATALOG_SALES: 'Vendas do catálogo',
  STORE_VISITS: 'Tráfego no estabelecimento',
};

function Review() {
  const classes = useStyles();

  const { values } = useFormState();

  const { campaign, adSet, adCreative } = values;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Campanha" />
          <CardContent>
            <List disablePadding>
              <ListItem>
                <ListItemText primary="Nome" secondary={campaign.name} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Objetivo"
                  secondary={objectives[campaign.objective]}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Orçamento" />
          <CardContent>
            <List disablePadding>
              <ListItem>
                <ListItemText
                  primary="Orçamento diário"
                  secondary={currencyFormatter(adSet.daily_budget)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Data de inicio"
                  secondary={formatDate(adSet.start_time)}
                />
              </ListItem>
              {adSet.end_time && (
                <ListItem>
                  <ListItemText
                    primary="Data de termino"
                    secondary={formatDate(adSet.end_time)}
                  />
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Conteúdo" />
          <CardContent>
            <List disablePadding>
              <ListItem>
                <ListItemText
                  primary="URL do site"
                  secondary={adCreative.link}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Mensagem"
                  secondary={adCreative.message}
                />
              </ListItem>
            </List>
            <img
              className={classes.preview}
              alt="adCreative preview"
              src={adCreative.image.url}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Review;
