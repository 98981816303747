import { useEffect, useState } from 'react';
import { NewStudio } from './Studio';
import Professional from './Professional';
//import { MenuHome } from './Menu';
//import { DSFaz } from './DSFaz';
import Projects from './Projects';
import { Calendar } from './Calendar';
import { useHistory } from 'react-router-dom';

export function Home() {
  const searchParams = new URLSearchParams(document.location.search);
  const step = searchParams.get('step');
  const [menuSelected, setMenuSelected] = useState(step ? Number(step) : 0);
  const history = useHistory();
  /*const handleMenu = (valueMenu: number) => {
    setMenuSelected(valueMenu);
  };*/

  useEffect(() => {
    if (Number(step) >= 0 && Number(step) <= 3 ) {
      searchParams.delete('step')
      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [Number(step) > 0]);

  return (
    <div 
      style={{ 
        backgroundColor: '#F2F3F5', 
        paddingTop: '5vh', 
        marginTop: '-3vh',
      }}>
      {/*<MenuHome onSelect={menuSelected} onMenuSelect={handleMenu} />

      <DSFaz hasBackground={menuSelected >= 2} />*/}

      {menuSelected === 0 && <NewStudio />}
      {menuSelected === 1 && <Professional />}
      {menuSelected === 2 && <Projects />}
      {menuSelected === 3 && <Calendar />}
    </div>
  );
}

