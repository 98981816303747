// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import "isomorphic-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function   Asynchronous({
  options,
  loading,
  getOptionSelected,
  getOptionLabel,
  label,
  placeholder,
  onChange,
  onSelect,
  onInputChange,
  multiple,
  disabled,
  group,
  groupLabel,
  style,
  disableCloseOnSelect,
  ...other
}) {
  const [open, setOpen] = React.useState(false);



  return (
    <Autocomplete
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      options={options}
      id="asynchronous-demo"
      style={{ width: 300, ...style }}
      open={open}
      onSelect={onSelect}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={disabled}
      loadingText="Carregando..."
      noOptionsText="Nenhum Resultado"
      onChange={onChange}
      
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          // onChange={onChange}
          fullWidth
          placeholder={placeholder}
          variant="outlined"
          InputLabelProps={{ shrink: placeholder ? true : false }}
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
