import React from 'react';
import { TextField, InputAdornment, Box } from '@material-ui/core';
import { MyButton, InputFile, Modal } from '../../../utils/components';
import { TwitterPicker } from 'react-color';
import '../style/ConfigClubeStyle.css';
export default ({
  onClose,
  onChange,
  onSend,
  showModal,
  dominio,
  nome_clube,
  sobre,
  facebook,
  whatsapp,
  youtube,
  twitter,
  instagram,
  configuracoes,
}) => (
  <Modal
    onClose={onClose}
    showModal={showModal}
    header="Configuração do clube"
    footer={
      <Box>
        <MyButton onClick={onSend} bsstyle="success">
          Salvar
        </MyButton>
        <MyButton onClick={onClose}>Fechar</MyButton>
      </Box>
    }
  >
    <section className="modalConfig">
      <input
        className="form-control"
        placeholder="Domínio..."
        onChange={({ target: { value } }) => onChange('dominio', value)}
        defaultValue={dominio}
      />
      <input
        className="form-control"
        placeholder="Nome do clube..."
        onChange={({ target: { value } }) => onChange('nome_clube', value)}
        defaultValue={nome_clube}
      />
      <section>
        <strong>Regulamento</strong> <br />
        <input
          type="file"
          name=""
          id=""
          onChange={({ target }) => onChange('regulamento', target.files[0])}
        />
      </section>
      <section>
        <strong>Logo</strong> <br />
        <InputFile
          hiddenFiles={true}
          files={file => onChange('logo', file)}
          filesFormat
        />
      </section>
      <section>
        <strong>Cor</strong>
        <TwitterPicker
          triangle="hide"
          color={configuracoes.cor}
          onChange={color => onChange('cor', color.hex)}
        />
      </section>
      <section style={{ gridColumn: '1/3', marginTop: '1vh' }}>
        <textarea
          placeholder="Sobre..."
          style={{ resize: 'vertical' }}
          onChange={({ target: { value } }) => onChange('sobre', value)}
          defaultValue={sobre}
          className="form-control"
        />
      </section>
      <TextField
        placeholder="Facebook..."
        onChange={({ target: { value } }) => onChange('facebook', value)}
        defaultValue={facebook}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">facebook.com/</InputAdornment>
          ),
        }}
      />

      <TextField
        placeholder="Whatsapp..."
        onChange={({ target: { value } }) => onChange('whatsapp', value)}
        defaultValue={whatsapp}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Nº Whatsapp</InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="Youtube..."
        onChange={({ target: { value } }) => onChange('youtube', value)}
        defaultValue={youtube}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">youtube.com/</InputAdornment>
          ),
        }}
      />

      <TextField
        placeholder="Twitter..."
        onChange={({ target: { value } }) => onChange('twitter', value)}
        defaultValue={twitter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">twitter.com/</InputAdornment>
          ),
        }}
      />

      <TextField
        placeholder="Instagram..."
        onChange={({ target: { value } }) => onChange('instagram', value)}
        defaultValue={instagram}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">instagram.com/</InputAdornment>
          ),
        }}
      />
    </section>
  </Modal>
);
