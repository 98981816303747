import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  active: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  // gridCarousel: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   maxWidth: '96.5vw',
  //   ['@media(max-width: 425px)']: {
  //     display: 'none'
  //   }
  // },
  // indicatorIconButtonProps: {
  //   padding: '5px',
  //   color: '#312a5e'
  // },
  // activeIndicatorIconButtonProps: {
  //   backgroundColor: '#73C1C2',
  // },
  // navButtonsProps: {
  //   borderRadius: '4px',
  //   '&:hover': {
  //     opacity: 1
  //   }
  // },
  containerBanners: {
    width: '100vw',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'noWrap',
  },
  imgCarousel: {
    width: '99%',
    maxWidth: '680px',
    maxHeight: '240px',
    ['@media(max-width: 425px)']: {
      display: 'none'
    }
  },
}));

export default useStyles;
