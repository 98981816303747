import { Box, Typography } from '@material-ui/core';
import React from 'react';

const TabPanel = ({ children, value, index, styleChild, ...rest }) => (
  <Typography
    {...rest}
    component="div"
    hidden={value !== index}
    style={{ width: '100%' }}
  >
    {value === index && <Box style={{ ...styleChild }}>{children}</Box>}
  </Typography>
);

export default TabPanel;
