import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import { Checkbox } from '../../../utils/components';

const EndTicketDialog = ({ open, onClose, onSave }) => {
  const [transferConfirm, setTransferConfirm] = useState(false);

  const _onSave = useCallback(() => {
    onSave();
    setTransferConfirm(false);
  }, []);

  const _onClose = useCallback(() => {
    onClose();
    setTransferConfirm(false);
  }, []);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={_onClose}>
      <DialogTitle onClose={_onClose}>Encerrar conversa</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Checkbox
              onChange={(_, checked) => setTransferConfirm(checked)}
              label="Você deseja encerrar essa conversa ?"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={!transferConfirm} color="primary" onClick={_onSave}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EndTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

EndTicketDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
};

export default EndTicketDialog;
