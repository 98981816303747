import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  PeopleRounded,
  ExpandMoreRounded,
  ExpandLessRounded,
  TimelineRounded,
} from '@material-ui/icons';
import { Countup } from '../../../../../utils/components';
import { BG_PRIMARY } from '../../../../../utils/helpers/Constants';
import useStyles from '../styles';

export default function Reach({ data = [] }) {
  const classes = useStyles(),
    [expanded, setExpanded] = useState(true);

  const expandedClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item style={{ marginBottom: '2vh' }}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} variant="rounded">
              <PeopleRounded />
            </Avatar>
          }
          action={
            <IconButton
              size="small"
              onClick={expandedClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </IconButton>
          }
          title="Alcance"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={1} style={{ color: BG_PRIMARY }}>
              {data.map((dt, index) => (
                <Grid key={index} item xs={6}>
                  <Card variant="outlined">
                    <CardHeader
                      avatar={
                        <Avatar className={classes.avatar} variant="rounded">
                          <TimelineRounded />
                        </Avatar>
                      }
                      disableTypography
                      classes={{ content: classes.reachTitle }}
                      title={
                        <Tooltip
                          placement="top"
                          title={dt.status_envio_externo}
                        >
                          <Box className={classes.reachTitle}>
                            <b>{dt.status_envio_externo}</b>
                            <br />
                            <span>
                              <Countup start={0} end={dt.quantidade} />
                            </span>
                          </Box>
                        </Tooltip>
                      }
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
