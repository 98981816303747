import React from 'react';
import { Box, Fade, Grid, Typography, Link } from '@material-ui/core';
import safeBoxImage from '../../../../assets/images/safeBox.png';

export default function WithoutAccess() {
  /* function openChat() {
    const widget = document.getElementById('comTomTicketChatWidget'),
      chat = document.getElementById('comTomTicketChatContainer');

    if (Boolean(chat.classList.length)) {
      chat.classList.add('tt-fadeInUp');
    } else {
      chat.style.display = 'initial';
      chat.classList.add('tt-animated');
      chat.classList.add('tt-fadeInUp');
    }

    if (Boolean(widget.classList.length)) {
      widget.classList.remove('tt-fadeInUp');
    } else {
      widget.classList.add('tt-animated');
      widget.classList.add('tt-fadeOutDown');
    }
  } */

  function handleRedirectToSupport() {
    if (typeof window !== 'undefined') {
      window.location.replace(
        'https://wa.me/551141187715?text=Ol%C3%A1+%21+Gostaria+de+saber+mais+sobre+o+CRM'
      );
    }
  }

  return (
    <Fade in>
      <Box
        height="96vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Você precisa conhecer essa novidade! 🎉
        </Typography>
        <Box marginTop="5vh">
          <Grid container spacing={0} alignItems="center">
            <Grid style={{ display: 'flex' }} item xs={6} justify="center">
              <img src={safeBoxImage} alt="safe-box-image" style={{ width: '30%' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Você não tem acesso a esse serviço, mas fique tranquilo!
              </Typography>
              <Typography variant="subtitle1">
                Para conhecer mais desse novo serviço, é só clicar{' '}
                <Link onClick={() => handleRedirectToSupport()} underline="always">
                  aqui
                </Link>{' '}
                para falar com alguns dos nossos atendentes. 🤩
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fade>
  );
}

