/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  Icon,
  withStyles,
  Button,
  CircularProgress
} from "@material-ui/core";
import {
  MyButton,
  DatePicker,
  Select,
  TextField,
  Modal,
  Chip,
  DialogAlert
} from "../../../utils/components";
import { onChangeState, postOfertaCupom } from "../actions/index";
import { listStore } from "../../Lojas/actions/action";
import { getCabecalhoProspects } from "../../Campanhas/action/actionCampanha";
import { MEDIA } from "../../../utils/helpers/Constants";
import MyDate from "../../../utils/helpers/Date";
import "@webscopeio/react-textarea-autocomplete/style.css";

const steps = ["Informações de Oferta", "Informações do Cupom", "Iniciar"];
const _myDate = new MyDate();
class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      nomeOferta: "",
      listaSelecionada: null,
      media: null,
      lojas: null,
      dataInicio: moment(),
      dataFim: moment(),
      familia: "",
      idFamilia: "",
      nomeProduto: "",
      idProduto: "",
      preco: "",
      porcentagem: "",
      precoDesconto: "",
      pontos: "",
      cashback: "",
      produtos: [],
      familias: [],
      codigoCupom: "",
      valorMinimo: "",
      limiteUso: "",
      valorDesconto: "",
      tipoCupom: "",
      showDialog: false,
      showDeleteProduto: false,
      produto: {}
    };
  }

  componentDidMount() {
    this.props.listStore();
    this.props.getCabecalhoProspects();
  }

  onSave() {
    const {
      nomeOferta,
      listaSelecionada,
      lojas,
      media,
      datainicio,
      dataFim,
      codigoCupom,
      limiteUso,
      valorMinimo,
      valorDesconto,
      tipoCupom
    } = this.state;
    const objCupom = {
      nome_cupom: nomeOferta,
      lojas: lojas && lojas.length > 0 ? lojas.map(loja => loja.value) : null,
      id_lista_prospeccao: listaSelecionada ? listaSelecionada.value : null,
      media_cupom: media ? media.value : null,
      data_inicio: _myDate.getDate("YYYY-MM-DD HH:mm:ss", datainicio),
      data_fim: _myDate.getDate("YYYY-MM-DD HH:mm:ss", dataFim),
      tipo_cupom: tipoCupom,
      valor_cupom: valorDesconto,
      limite_uso: limiteUso,
      valor_minimo_cupom: valorMinimo,
      codigo_cupom: codigoCupom
    };

    this.props.postOfertaCupom(objCupom);
    this.props.onClose();
  }

  header(props, state) {
    const { tipoOferta } = props;

    return (
      <Fragment>
        {tipoOferta.toUpperCase()}
        <div
          style={{
            width: "100%",
            marginTop: 40,
            marginBottom: -40
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return "Próximo";
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return "Proximo";
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
            disabled={
              !state.nomeOferta ? true : state.activeStep === 2 ? true : false
            }
          >
            {this.renderText()}
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const {
      nomeOferta,
      datainicio,
      dataFim,
      listaSelecionada,
      media,
      lojas
    } = state;
    const { personalizada } = props;
    return (
      <section style={{ padding: "5rem 20rem", display: "grid", gap: "2rem" }}>
        <Typography style={{ fontWeight: "900" }} color="primary" variant="h5">
          Informações da Oferta
        </Typography>

        <TextField
          label="Nome"
          className="form-control"
          autoComplete="none"
          value={nomeOferta}
          onChange={event => this.setState({ nomeOferta: event.target.value })}
        />
        {personalizada && (
          <Select
            options={props.listsProspectei.map(list => ({
              label: list.nameList,
              value: list.idListProspectei || list.idPromocao
            }))}
            placeholder="Selecione uma lista..."
            onChange={value => this.setState({ listaSelecionada: value })}
            value={listaSelecionada}
          />
        )}
        <Select
          options={props.listStores.map(loja => ({
            value: loja.id_loja,
            label: loja.nome_loja
          }))}
          multiple
          label="Lojas participantes"
          onChange={value => this.setState({ lojas: value })}
          value={lojas}
        />

        <Select
          options={MEDIA}
          label="Media"
          onChange={value => this.setState({ media: value })}
          value={media}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <section>
            <DatePicker
              dateAndTime
              disableFuture
              label="Início da Oferta"
              onChange={date => this.setState({ datainicio: date })}
              value={datainicio}
            />
          </section>
          <section>
            <DatePicker
              dateAndTime
              disablePast
              label="Término da Oferta"
              onChange={date => this.setState({ dataFim: date })}
              value={dataFim}
            />
          </section>
        </div>
      </section>
    );
  }
  step2(props, state) {
    const {
      codigoCupom,
      valorMinimo,
      limiteUso,
      valorDesconto,
      tipoCupom
    } = state;

    return (
      <section style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}>
        <Typography style={{ fontWeight: "900" }} color="primary" variant="h5">
          Cupom de Desconto
        </Typography>
        <TextField
          label="Código do Cupom"
          className="form-control"
          onChange={({ target }) =>
            this.setState({ codigoCupom: target.value })
          }
          value={codigoCupom}
        />
        <TextField
          type="number"
          label="Valor mínimo de uso"
          className="form-control"
          onChange={({ target }) =>
            this.setState({ valorMinimo: target.value })
          }
          value={valorMinimo}
        />
        <TextField
          type="number"
          label="Limite de Utilização do Cupom"
          className="form-control"
          onChange={({ target }) => this.setState({ limiteUso: target.value })}
          value={limiteUso}
        />
        <TextField
          type="number"
          label="Valor do Desconto"
          className="form-control"
          onChange={({ target }) =>
            this.setState({ valorDesconto: target.value })
          }
          value={valorDesconto}
        />
        <section>
          <Chip
            active={tipoCupom === "R$"}
            label="Reais (R$)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: "R$" });
            }}
          />
          <Chip
            active={tipoCupom === "%"}
            label="Porcentagem (%)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: "%" });
            }}
          />
        </section>
      </section>
    );
  }
  step3(props, state) {
    const { nomeOferta, datainicio, dataFim, produtos, familias } = state;
    return (
      <section>
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Sobre a Oferta
          </Typography>
          <section style={{ padding: "0 7rem" }}>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Nome da Oferta</strong>
              </p>
              <span>{nomeOferta}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(datainicio).format(
                "DD/MM/YYYY HH:mm"
              )} Até ${moment(dataFim).format("DD/MM/YYYY HH:mm")}`}</span>
            </section>
            <section style={{ paddingBottom: "2rem" }}>
              <p>
                <strong>Produtos</strong>
              </p>
              <div
                style={{
                  display: "flex",

                  flexWrap: "wrap",
                  alignItems: "flex-start"
                }}
              >
                {produtos.map(prd => (
                  <Chip label={prd.nomeProduto}></Chip>
                ))}
                {familias.map(fam => (
                  <Chip label={fam.familia}></Chip>
                ))}
              </div>
            </section>
          </section>
        </section>
        <Divider />
        <section
          style={{ padding: "2rem 15rem", display: "grid", gap: "2rem" }}
        >
          <Typography
            style={{ fontWeight: "900" }}
            color="primary"
            variant="h5"
          >
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: "center" }}>
            <MyButton onClick={() => this.onSave()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;
    const { activeStep, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? "xl" : "lg"}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
        {showDeleteProduto && (
          <DialogAlert
            open={showDeleteProduto}
            title="Deseja remover este produto?"
            onClick={() =>
              this.setState({ showDeleteProduto: false }, () =>
                this.props.deleteProduto(this.state.produto)
              )
            }
            onClose={() =>
              this.setState({ showDeleteProduto: false, produto: {} })
            }
          ></DialogAlert>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({
  OfertasReducer,
  CampaignReducer,
  InsightsReducer,
  LojasReducer
}) => ({
  ...OfertasReducer,
  ...CampaignReducer,
  ...InsightsReducer,
  ...LojasReducer
});
const mapDispatchToProps = {
  onChangeState,
  listStore,
  postOfertaCupom,
  getCabecalhoProspects
};

const NovaOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
