import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { makeValidate, TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import Fetch from '../../../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../../../utils/helpers/Functions';
import useStyles from './styles';

const schema = Yup.object().shape({
  provider_id: Yup.number().required(),
  ean: Yup.string().required('O nome é obrigatório'),
  sku: Yup.string().required('O nome é obrigatório'),
  name: Yup.string().required('O nome é obrigatório'),
  category: Yup.string().required('O nome é obrigatório'),
  price: Yup.string().required('O nome é obrigatório'),
  // thumbnail: Yup.array(),
});

function NewUser() {
  const classes = useStyles();

  const history = useHistory();

  const { id, productId } = useParams();

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    async function getInitialValues() {
      try {
        const response = await Fetch(
          `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/provider/product/${productId}`
        );

        setInitialValues(response);
      } catch (err) {
        console.error(err);
      }
    }

    getInitialValues();
  }, []);

  async function onSubmit(data) {
    try {
      const thumbnail =
        typeof data.thumbnail === 'string'
          ? data.thumbnail
          : await toBase64(data.thumbnail[0]);

      await Fetch(
        `${DATASALES_CRM_API}providers-p1-${AMBIENTE}/crm/provider/product/edit/${productId}`,
        {
          ...data,
          thumbnail,
        },
        'PUT'
      );

      history.push(`/trade/provider/${id}`);
    } catch (err) {
      console.error(err);
    }
  }

  if (!initialValues) {
    return null;
  }

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.push(`/trade/provider/${id}`);
            }}
          >
            voltar
          </Button>
        </Grid>
        <Grid item>
          <Box className={classes.root}>
            <Grid container spacing={4}>
              <Grid item container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6">Editar Produto</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Form
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validate={makeValidate(schema)}
                  mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value);
                    },
                  }}
                  render={({ handleSubmit, submitting, form, values }) => {
                    if (!window.setFormValue)
                      window.setFormValue = form.mutators.setValue;
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              name="ean"
                              fullWidth
                              variant="outlined"
                              label="EAN"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="sku"
                              fullWidth
                              variant="outlined"
                              label="Codigo Interno"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="name"
                              fullWidth
                              variant="outlined"
                              label="Nome"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="category"
                              fullWidth
                              variant="outlined"
                              label="Categoria"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="price"
                              fullWidth
                              variant="outlined"
                              label="Preço"
                            />
                          </Grid>
                          <Grid item container direction="column" spacing={2}>
                            {values.thumbnail && (
                              <Grid item>
                                <img
                                  crossOrigin='anonymous'
                                  className={classes.thumbnail}
                                  alt={values.name}
                                  src={
                                    typeof values.thumbnail === 'string'
                                      ? values.thumbnail
                                      : URL.createObjectURL(values.thumbnail[0])
                                  }
                                />
                              </Grid>
                            )}
                            <Grid item>
                              <Field name="thumbnail">
                                {({ input: { value, onChange, ...input } }) => {
                                  const handleChange = ({ target }) => {
                                    onChange(target.files); // instead of the default target.value
                                  };
                                  return (
                                    <React.Fragment>
                                      <input
                                        {...input}
                                        className={classes.fileIput}
                                        id="contained-button-file"
                                        accept="image/*"
                                        type="file"
                                        onChange={handleChange}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <Button
                                          variant="outlined"
                                          component="span"
                                          color="default"
                                          size="large"
                                          startIcon={<AddIcon />}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                    </React.Fragment>
                                  );
                                }}
                              </Field>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                        <input
                          accept="image/*"
                          className={classes.fileIput}
                          id="contained-button-file"
                          type="file"
                          onChange={handleAvatarChange}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="outlined"
                            component="span"
                            color="default"
                            size="large"
                            startIcon={<AddIcon />}
                          >
                            Upload
                          </Button>
                        </label>
                      </Grid> */}

                          <Grid item container spacing={2}>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={submitting}
                                startIcon={<SaveIcon />}
                              >
                                Salvar
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                size="large"
                                disabled={submitting}
                                startIcon={<UndoIcon />}
                              >
                                Limpar
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                size="large"
                                onClick={() => {
                                  history.push('/modules');
                                }}
                                startIcon={<CloseIcon />}
                              >
                                Cancelar
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewUser;
