import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography, Divider, withStyles, Avatar, Icon } from '@material-ui/core';
import { getPerformanceTotal } from '../actions/index';
import { Panel, CountCard, DatePicker } from '../../../utils/components';

import MyDate from '../../../utils/helpers/Date';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicio: moment().subtract(30, 'days'),
      dataFim: moment(),
    };
  }

  componentDidMount() {
    const { dataInicio, dataFim } = this.state;
    const request = {
      dataInicio: new MyDate(dataInicio, 'YYYY-MM-DD').getDate(),
      dataFim: new MyDate(dataFim, 'YYYY-MM-DD').getDate(),
    };
    this.props.getPerformanceTotal(request);
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data }, () => {
      const { dataInicio, dataFim } = this.state;

      const request = {
        dataInicio: new MyDate(dataInicio, 'YYYY-MM-DD').getDate(),
        dataFim: new MyDate(dataFim, 'YYYY-MM-DD').getDate(),
      };
      this.props.getPerformanceTotal(request);
    });
  }

  render() {
    const { classes, loadingPerformance } = this.props;
    const { contadores, percentualVendas, visaoGeralCampanhas, visaoGeralOfertas } =
      this.props.data;
    const { dataInicio, dataFim } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            Dashboard
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 40,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date => this.onChangePeriodo('dataInicio', date)}
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('dataFim', date)}
                />
              </div>
            </div>
          </section>
          <div></div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          <Panel
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>email</Icon>
              </Avatar>
            }
            title="Total"
            style={{ marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}>
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={contadores.qtde_clientes}
                title="Clientes"
                icon="people"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={contadores.qtde_emails}
                title="Emails"
                icon="email"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={contadores.qtde_celulares}
                title="Celulares"
                icon="smartphone"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={contadores.qtde_enderecos}
                title="Endereços"
                icon="home"
                style={{ marginRight: 20 }}
              />
            </div>
          </Panel>
          <Panel
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>score</Icon>
              </Avatar>
            }
            title="Vendas"
            style={{ marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}>
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={percentualVendas.qtde_vendas_identificas}
                title="Identificadas"
                icon="attach_money"
                style={{ marginRight: 20 }}
              />

              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={percentualVendas.qtde_vendas_n_identificas}
                title="Não Identificadas"
                icon="money_off"
                style={{ marginRight: 20 }}
              />
              <CountCard
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={percentualVendas.qtde_vendas_identificas}
                title="Percentual de Identificadas"
                style={{ marginRight: 20 }}></CountCard>
              <CountCard
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={percentualVendas.qtde_vendas_identificas}
                title="Percentual de não identificadas"
                style={{ marginRight: 20 }}></CountCard>
            </div>
          </Panel>
          <Panel
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>email</Icon>
              </Avatar>
            }
            title="Campanhas"
            style={{ marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}>
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralCampanhas.qtde_campanha}
                title="Quantidade de Campanhas"
                icon="people"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={
                  visaoGeralCampanhas.gastos_campanha ? visaoGeralCampanhas.gastos_campanha : 0.0
                }
                decimals={2}
                prefix="R$ "
                title="Investimento"
                icon="build"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralCampanhas.qtde_vendas ? visaoGeralCampanhas.qtde_vendas : 0}
                title="Vendas"
                icon="score"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralCampanhas.valor_roi ? visaoGeralCampanhas.valor_roi : 0}
                decimals={2}
                prefix="R$ "
                title="ROI"
                icon="score"
                style={{ marginRight: 20 }}
              />
            </div>
          </Panel>
          <Panel
            avatar={
              <Avatar className={classes.avatar}>
                <Icon>shopping_cart</Icon>
              </Avatar>
            }
            title="Ofertas"
            style={{ marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}>
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralOfertas.qtde_oferta}
                title="Quantidade Ofertas"
                icon="people"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralOfertas.investimento ? visaoGeralOfertas.investimento : 0.0}
                decimals={2}
                prefix="R$ "
                title="Investimento"
                icon="build"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralOfertas.qtde_vendas ? visaoGeralOfertas.qtde_vendas : 0}
                title="Vendas"
                icon="score"
                style={{ marginRight: 20 }}
              />
              <CountCard
                count
                loading={loadingPerformance}
                titleTypographyProps={{ variant: 'h6' }}
                end={visaoGeralOfertas.roi ? visaoGeralOfertas.roi : 0}
                decimals={2}
                prefix="R$ "
                title="ROI"
                icon="score"
                style={{ marginRight: 20 }}
              />
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },

  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
});

const mapStateToProps = ({ DashboardReducer }) => ({
  ...DashboardReducer,
});

const mapDispatchToProps = {
  getPerformanceTotal,
};

const DashboardConnect = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStyles(styles)(DashboardConnect);
