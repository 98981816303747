import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(3),
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  cardContent: {
    padding: 0,
  },
  thumbnail:{
    height: 50,
    borderRadius: theme.shape.borderRadius,
  }
}));

export default useStyles;
