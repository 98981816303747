import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import { GroupRounded, PersonRounded } from '@material-ui/icons';
import { Celular2 } from '../../../../utils/helpers/Masks';
import useStyles from '../Grupos/styles';

const ParticipantsDialog = ({
  open,
  group,
  participants,
  loading,
  onClose,
  onLoad,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (Boolean(open)) {
      onLoad();
    }
  }, [open]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          <Avatar className={classes.avatar}>
            {group.IMAGE ? (
              <img
                className={classes.avatar}
                src={group.IMAGE}
                alt={group.NAME}
              />
            ) : (
              <GroupRounded />
            )}
          </Avatar>
          <Typography
            className={classes.nameChat}
            style={{ marginLeft: '1vw' }}
          >
            {group.NAME}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <List id="scrollableParticipants" className={classes.groupsList}>
          {loading && (
            <ListItem>
              <Box className={classes.loader}>
                <Typography className={classes.nameChat}>
                  Buscando participantes...
                </Typography>
                <LinearProgress
                  color="primary"
                  classes={{ root: classes.linearProgress }}
                />
              </Box>
            </ListItem>
          )}
          {Boolean(participants.length)
            ? participants.map((participant, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      {participant.profileThumbnail ? (
                        <img
                          className={classes.avatar}
                          src={participant.profileThumbnail}
                          alt={participant.name}
                        />
                      ) : (
                        <PersonRounded />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  {participant.name ? (
                    <ListItemText
                      classes={{ primary: classes.nameChat }}
                      primary={participant.name}
                      secondary={Celular2(participant.phone)}
                    />
                  ) : (
                    <ListItemText
                      classes={{ primary: classes.nameChat }}
                      primary={Celular2(participant.phone)}
                    />
                  )}
                </ListItem>
              ))
            : !loading && (
                <Box className={classes.loader}>
                  <Typography className={classes.nameChat}>
                    Nenhum participante foi encontrado.
                  </Typography>
                </Box>
              )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ParticipantsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  participants: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
  onNextPage: PropTypes.func,
};

ParticipantsDialog.defaultProps = {
  open: false,
  loading: false,
  group: {},
  participants: [],
  onClose: () => {},
  onLoad: () => {},
  onNextPage: () => {},
};

export default ParticipantsDialog;
