import { DATASALES_CRM_API, AMBIENTE } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import moment from 'moment';

const isLoadingSolicitacoes = () => dispatch =>
  dispatch({ type: 'LOAD_SOLICITACOES' });

const isLoadingDetalhes = () => dispatch => dispatch({ type: 'LOAD_DETALHES' });

const isLoadingXlsx = () => dispatch => dispatch({ type: 'LOAD_XLSX' });

export const isLoadingFamilias = () => dispatch =>
  dispatch({ type: 'LOAD_FAMILIAS' });

export const isLoadingProdutos = () => dispatch =>
  dispatch({ type: 'LOAD_PRODUTOS' });

export const isLoadingWhitelList = () => dispatch =>
  dispatch({ type: 'LOAD_WHITELIST' });

export const getSolicitacoes = (dataInicio, dataFim) => async dispatch => {
  dispatch(isLoadingSolicitacoes());

  Fetch(
    `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/getRecomendacoes
    `
  ).then(payload => {
    dataInicio = moment(dataInicio).format('YYYY-MM-DD');
    dataFim = moment(dataFim).format('YYYY-MM-DD');
    payload = payload.filter(
      value =>
        value.data_insert.slice(0, 10) <= dataFim &&
        value.data_insert.slice(0, 10) >= dataInicio
    );

    dispatch({
      type: 'GET_SOLICITACOES',
      payload: { gerais: payload, personalizadas: [] },
    });
  });
};

export const getRecomendacaoDetalhes =
  (id_recomendacao, personalizada) => async dispatch => {
    try {
      dispatch(isLoadingDetalhes());
      let payload = await Fetch(
        `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/getRecomendacaoDetalhes`,
        { id_recomendacao, personalizada }
      );

      dispatch({ type: 'GET_DETALHES', payload });
    } catch (err) {
      console.log(err);
    }
  };

export const getXlsx = data => async dispatch => {
  const { id_recomendacao, nome_recomendacao } = data;
  dispatch(isLoadingXlsx());

  Fetch(
    `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/getXlsx`,
    { id_recomendacao, nome_recomendacao }
  ).then(payload => {
    window.open(payload.link, '_blank');
    return dispatch({ type: 'GET_XLSX', payload: payload.link });
  });
};

export const getPersonalizedXlsx = data => async dispatch => {
  const { id_recomendacao, link_recomendacao, nome_recomendacao } = data;
  if (link_recomendacao) {
    window.open(link_recomendacao);
  } else {
    dispatch(isLoadingXlsx());

    Fetch(
      `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/GetPersonalizedXlsx`,
      { id_recomendacao, nome_recomendacao }
    ).then(payload => {
      window.open(payload.link, '_blank');
      return dispatch({ type: 'GET_PERSONALIZED_XLSX', payload: payload.link });
    });
  }
};

export const getPdf = data => async dispatch => {
  const { id_recomendacao, link_pdf, nome_recomendacao } = data;
  if (!link_pdf) {
    window.open(link_pdf);
  } else {
    dispatch(isLoadingXlsx());

    Fetch(
      `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/getPdf`,
      { id_recomendacao, nome_recomendacao }
    ).then(payload => {
      window.open(payload.link, '_blank');
      return dispatch({ type: 'GET_PDF', payload: payload.link });
    });
  }
};

export const getWhiteList = () => async dispatch => {
  dispatch(isLoadingWhitelList());

  Fetch(
    `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/getWhiteList`
  ).then(payload => dispatch({ type: 'GET_WHITELIST', payload }));
};

export const getFamilias = () => async dispatch => {
  dispatch(isLoadingFamilias());
  const payload = await Fetch(
    `https://4hx27tk197.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/produto/getFamilias`
    // `${DATASALES_CRM_API}insights-p2-${AMBIENTE}/${AMBIENTE}/crm/produto/getFamilias`
    // {
    //   idStores
    // }
  );
  dispatch({ type: 'GET_FAMILIAS', payload });
};

export const getProdutosByFamilia = familia => async dispatch => {
  dispatch(isLoadingProdutos());
  const payload = await Fetch(
    `https://4hx27tk197.execute-api.us-east-1.amazonaws.com/${AMBIENTE}/crm/produto/getProdutosByfamilia`,

    {
      familia,
    }
  );
  dispatch({ type: 'GET_PRODUTOS', payload });
};

export const postWhiteListItem = data => async dispatch => {
  dispatch(isLoadingWhitelList());
  await Fetch(
    `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/postWhitelist`,
    data,
    'POST'
  );
  dispatch(getWhiteList());
};

export const deleteWhiteListItem = idWhiteList => async dispatch => {
  dispatch(isLoadingWhitelList());
  await Fetch(
    `${DATASALES_CRM_API}recomendacoes-p2-${AMBIENTE}/${AMBIENTE}/crm/deleteWhiteListItem`,
    { idWhiteList },
    'PUT'
  );
  dispatch(getWhiteList());
};
