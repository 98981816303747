/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Typography, withStyles, Button } from "@material-ui/core";
import { DatePicker, Select, Modal } from "../../../utils/components";
import { onChangeState } from "../actions/index";
import { listStore } from "../../Lojas/actions/action";

import "@webscopeio/react-textarea-autocomplete/style.css";

class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      activeStep: 0,
      nomeOferta: "",
      listaSelecionada: null,
      media: null,
      lojas: null,
      dataInicio: moment(),
      dataFim: moment(),
      familia: "",
      idFamilia: "",
      nomeProduto: "",
      idProduto: "",
      preco: "",
      porcentagem: "",
      precoDesconto: "",
      pontos: "",
      cashback: "",
      produtos: [],
      familias: [],
      codigoCupom: "",
      valorMinimo: "",
      limiteUso: "",
      valorDesconto: "",
      tipoCupom: "",
      showDialog: false
    };
  }

  header(props, state) {
    return <Fragment>Oferta Inteligênte</Fragment>;
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
          width: "100%"
        }}
      >
        <div />
        <Button
          size="large"
          color="primary"
          variant="text"
          onClick={() =>
            this.setState({ showSnackbar: true }, () => props.onClose())
          }
        >
          Salvar
        </Button>
      </div>
    );
  }

  render() {
    const { onClose } = this.props;
    const { datainicio, dataFim, listaSelecionada } = this.state;

    return (
      <Fragment>
        <Modal
          size="lg"
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          <section
            style={{ padding: "2rem 20rem", display: "grid", gap: "2rem" }}
          >
            <Typography
              style={{ fontWeight: "900" }}
              color="primary"
              variant="h5"
            >
              Informações da Oferta
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <section>
                <DatePicker
                  dateAndTime
                  disableFuture
                  label="Início da Oferta"
                  onChange={date => this.setState({ datainicio: date })}
                  value={datainicio}
                />
              </section>
              <section>
                <DatePicker
                  dateAndTime
                  disablePast
                  label="Término da Oferta"
                  onChange={date => this.setState({ dataFim: date })}
                  value={dataFim}
                />
              </section>
            </div>

            <Select
              options={this.props.familias}
              label="Família"
              placeholder="Selecione uma família..."
              onChange={value => this.setState({ familia: value })}
              value={this.state.familia}
              multiple
            />
            <Select
              options={this.props.listsProspectei.map(list => ({
                label: list.nameList,
                value: list.idListProspectei || list.idPromocao
              }))}
              label="Filtro"
              placeholder="Selecione um filtro..."
              onChange={value => this.setState({ listaSelecionada: value })}
              value={listaSelecionada}
            />
            <Select
              options={[
                { label: "0.5 a 1", value: "0.5a1" },
                { label: "1 a 1.10", value: "0.5a1" },
                { label: "1.10 a 1.20", value: "0.5a1" },
                { label: "Maior que 1.30", value: "0.5a1" }
              ]}
              label="Faixa Elástica"
              onChange={value => this.setState({ faixaElastica: value })}
              value={this.state.faixaElastica}
            />

            <Select
              options={[
                { label: "R$: 0,50", value: "0.5a1" },
                { label: "R$: 1,00", value: "0.5a1" },
                { label: "R$: 2,00", value: "0.5a1" },
                { label: "R$: 3,00", value: "0.5a1" },
                { label: "R$: 4,00", value: "0.5a1" },
                { label: "R$: 5,00", value: "0.5a1" },
                { label: "R$: 6,00", value: "0.5a1" },
                { label: "R$: 7,00", value: "0.5a1" },
                { label: "R$: 8,00", value: "0.5a1" },
                { label: "R$: 9,00", value: "0.5a1" },
                { label: "R$: 10,00", value: "0.5a1" },
                { label: "R$: 11,00", value: "0.5a1" },
                { label: "R$: 12,00", value: "0.5a1" },
                { label: "R$: 13,00", value: "0.5a1" },
                { label: "R$: 14,00", value: "0.5a1" },
                { label: "R$: 15,00", value: "0.5a1" }
              ]}
              label="Faixa de preço"
              onChange={value => this.setState({ faixa: value })}
              value={this.state.faixa}
            />
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3)
  }
});

const mapStateToProps = ({
  OfertasReducer,
  CampaignReducer,
  InsightsReducer,
  LojasReducer
}) => ({
  ...OfertasReducer,
  ...CampaignReducer,
  ...InsightsReducer,
  ...LojasReducer
});
const mapDispatchToProps = {
  onChangeState
};

const NovaOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
