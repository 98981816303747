import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DevicesIcon from '@material-ui/icons/Devices';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import ErrorIcon from '@material-ui/icons/Error';
import LoopIcon from '@material-ui/icons/Loop';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import WcIcon from '@material-ui/icons/Wc';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { BarChart } from '../../../../../../../../utils/components';
import DialogTitle from '../../../../../../../../utils/components/DialogTitle';
import { AMBIENTE } from '../../../../../../../../utils/helpers/Constants';
import api from '../../../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

const access_token =
  'EAALk5H3jEBoBALKjllI78aN93lLK82Jchg15HxJq1BSKHZBm4Os5cyQQ8Nf5k5OVRCsX3UniZA1jeTgZBqnDtCnXGZBacFXoWSB3MudV0vGw5eyzidRjJjJJmxeXFr0QFizZCOLo2HFufu7BqvAiBnaydF8HteEmWoRJKBjsZC4r8ybWPDF9qt';

function Details({ open, adsId, creativeId, onClose }) {
  const classes = useStyles();

  const [insights, setInsights] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `https://api.datasales.info/ads-manager-${AMBIENTE}/ads/insights/${adsId}/${creativeId}`,
          { access_token }
        );

        setInsights(response.content);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (!insights) {
    return (
      <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
        <DialogTitle onClose={onClose}>Detalhes</DialogTitle>
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle onClose={onClose}>Detalhes</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <iframe
              src={`https://www.facebook.com/plugins/post.php?href=https://www.facebook.com/${
                insights.effective_object_story_id.split('_')[0]
              }/posts/${
                insights.effective_object_story_id.split('_')[1]
              }/&show_text=true&width=500`}
              width="500"
              height="725"
              style={{
                border: 'none',
                overflow: 'hidden',
              }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </Grid>

          <Grid item xs={6}>
            {!insights.overview.reach && (
              <Alert severity="warning" style={{ marginBottom: 20 }}>
                Essa campanha pode estar ainda em análise.
              </Alert>
            )}
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <TimelineIcon />
                  </Avatar>
                }
                title="Desempenho"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  justify="space-between"
                  style={{ display: 'block' }}
                >
                  <Grid item style={{ marginBottom: 20 }}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <PeopleIcon />
                          </Avatar>
                        }
                        title={insights.overview.reach || 0}
                        subheader="Alcance"
                      />
                    </Card>
                  </Grid>
                  <Grid item style={{ marginBottom: 20 }}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <CheckIcon />
                          </Avatar>
                        }
                        title={insights.overview.clicks || 0}
                        subheader="Clicks"
                      />
                    </Card>
                  </Grid>
                  <Grid item style={{ marginBottom: 20 }}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <LoopIcon />
                          </Avatar>
                        }
                        title={insights.overview.impressions || 0}
                        subheader="Impressões"
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <ErrorIcon />
                          </Avatar>
                        }
                        title={insights.overview.frequency || 0}
                        subheader="Frequência"
                      />
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <DevicesIcon />
                  </Avatar>
                }
                title="Plataformas"
              />
              <Divider />
              <CardContent>
                <BarChart
                  data={insights.platforms}
                  barLegend
                  randomFill
                  barDataKey="reach"
                  xDataKey="publisher_platform"
                  tooltipFormatter="Alcance"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <DevicesOtherIcon />
                  </Avatar>
                }
                title="Dispositivo"
              />
              <Divider />
              <CardContent>
                <BarChart
                  data={insights.device}
                  barLegend
                  randomFill
                  barDataKey="reach"
                  xDataKey="device_platform"
                  tooltipFormatter="Alcance"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <WcIcon />
                  </Avatar>
                }
                title="Gênero"
              />
              <Divider />
              <CardContent>
                <BarChart
                  data={insights.gender}
                  barLegend
                  randomFill
                  barDataKey="spend"
                  xDataKey="gender"
                  tooltipFormatter="Custo por resultado R$"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <DateRangeIcon />
                  </Avatar>
                }
                title="Idade"
              />
              <Divider />
              <CardContent>
                <BarChart
                  data={insights.age}
                  barLegend
                  randomFill
                  barDataKey="spend"
                  xDataKey="age"
                  tooltipFormatter="Custo por resultado R$"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Details;
