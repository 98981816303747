import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import Loader from './Loader';
import SelectTemplate from './SelectTemplate';
import StoreLogoPreview from './StoreLogoPreview';
import TabPanel from '../../../../utils/components/TabPanel';
import DialogTitle from '../../../../utils/components/DialogTitle';
import SelecionarProdutos from '../SelecionarProdutos';
import { CompactPicker } from 'react-color';

const useStyles = makeStyles(theme => ({
  avatar: { backgroundColor: theme.palette.primary.main },
  title: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none !important',
  },
  tab: {
    textTransform: 'none',
  },
  tagImg: {
    height: 50,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  content: {
    minHeight: '70vh',
  },
  balanceAlert: {
    marginBottom: 10,
  },
}));

function CreateFlyer(props) {
  let { open, onClose } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { id: offer_id } = useParams();

  const [tabValue, setTabValue] = useState(!props.modelo_pronto ? 0 : 1);
  const [stores, setStores] = useState([]);
  const [tags, setTags] = useState([]);

  const [openDialogContinuar, setOpenDialogContinuar] = useState(false);

  const [openSelecionarProdutos, setOpenSelecionarProdutos] = useState(false);
  const [idsNaoIncluidos, setIdsNaoIncluidos] = useState([]);

  const [balance, setBalance] = useState(null);

  const [colorText, setColorText] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [colorPrice, setColorPrice] = useState({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  });

  const [expandedAccordion, setExpandedAcoordion] = useState(false);

  const onClickAccordion = panel => (event, isExpanded) => {
    setExpandedAcoordion(isExpanded ? panel : false);
  };

  const formik = useFormik({
    initialValues: {
      offer_id,
    },
    // validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values) {
    try {
      const { template } = values;

      const result = await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postVideomatik`,
        {
          id_tabloide: offer_id,
          id_template: template.id,
          idsNaoIncluidos,
        },
        'POST',
        undefined,
        undefined,
        false
      );

      enqueueSnackbar('Arte enviada para processamento', {
        variant: 'success',
      });

      setOpenDialogContinuar(true);
    } catch (err) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  function handleSelectTemplate(template) {
    formik.setFieldValue('template', template);

    setTabValue(prevState => prevState + 1);
  }

  async function fetchStores() {
    try {
      await api(
        `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`
      ).then(response => {
        setStores(
          map(response, s => ({
            id: s.id_loja,
            name: s.nome_loja,
            zip_code: s.cep,
            street_address: s.endereco,
            phone_number: s.whatsapp_phone_number,
            logo_url: s.logo1,
            complete: {
              ...s,
            },
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchProducts() {
    try {
      await api(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        { id_tabloide: offer_id }
      ).then(response => {
        formik.setFieldValue(
          'products',
          map(response[0].produtos, p => ({
            id: p.id_tabloide_produto,
            name: p.nome_produto,
            // price: currencyFormat(p.preco_original),
            price: p.preco_original,
            club_price: p.preco_dois,
            image_url: p.imagem_produto,
            is_active: p.ativo,
            fullData: { ...p },
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  const findLastLimitHistory = async values => {
    try {
      let result = await api(
        `https://api.datasales.info/ads-manager-${AMBIENTE}/lastLimitHistory?id_type=2`,
        {},
        'GET',
        null,
        null,
        false
      );

      if (result && result.content && result.content.balance) {
        setBalance(result.content.balance);
      }
      if (result && result.content && result.content.balance === 0) {
        setBalance(result.content.balance);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStores();
    fetchProducts();
    findLastLimitHistory();
  }, []);

  let consumo = 0;

  if (formik.values?.template?.qtde_produtos) {
    consumo = Math.ceil(
      (props.tabloid.qtde_produtos_lista - idsNaoIncluidos.length) /
        formik.values.template.qtde_produtos
    );
  }

  const enoughCredits = balance >= consumo * 1000;

  if (!stores) {
    return null;
  }

  return (
    <Fragment>
      <Dialog open={open} maxWidth="lg" fullWidth fullScreen onClose={onClose}>
        <DialogTitle onClose={onClose}>
          {tabValue > 0 && !props.modelo_pronto && (
            <Button
              onClick={() => {
                setTabValue(prevState => prevState - 1);
              }}
              style={{ marginRight: 40 }}
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          )}
          {`Automático Vídeos - ${props.tabloid.qtde_produtos_lista} Produtos no Total`}
          {!!!isNaN(parseInt(balance)) && (
            <Chip
              style={{ marginLeft: 15 }}
              icon={<CreditCardIcon style={{ marginLeft: 14 }} />}
              label={'Créditos: ' + balance / 1000}
              color="primary"
            />
          )}
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <form onSubmit={formik.handleSubmit}>
            <TabPanel value={tabValue} index={0}>
              <SelectTemplate
                id_tabloide={offer_id}
                onSelect={handleSelectTemplate}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Container maxWidth="md">
                <Grid container spacing={4} style={{ marginTop: 10 }}>
                  <Grid item xs={8}>
                    {(balance <= 0 || !enoughCredits) && (
                      <Alert severity="error" className={classes.balanceAlert}>
                        Créditos insuficientes! Adquira mais para continuar.
                      </Alert>
                    )}
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar className={classes.avatar} variant="rounded">
                            <CreditCardIcon />
                          </Avatar>
                        }
                        title={
                          'Essa ação vai consumir' +
                          consumo +
                          ' crédito' +
                          (consumo > 1 ? 's' : '') +
                          '.'
                        }
                        // subheader={}
                      />
                      <Divider />
                      {/* <CardHeader
                        title="Loja"
                        avatar={
                          <Avatar className={classes.avatar}>
                            <StoreOutlinedIcon />
                          </Avatar>
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>
                          {formik.values.store && formik.values.store.logo_url && (
                            <Grid item xs={12}>
                              <StoreLogoPreview
                                src={formik.values.store.logo_url}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Autocomplete
                              options={stores}
                              getOptionLabel={option => option.name}
                              value={formik.values.store}
                              onChange={(e, value) => {
                                const storeComplete = storeVar => {
                                  let enderecoComplete = '';
                                  if (storeVar) {
                                    if (storeVar.endereco) {
                                      enderecoComplete =
                                        enderecoComplete + storeVar.endereco;
                                    }
                                    if (storeVar.numero) {
                                      enderecoComplete =
                                        enderecoComplete +
                                        ', ' +
                                        storeVar.numero;
                                    }
                                    if (storeVar.bairro) {
                                      enderecoComplete =
                                        enderecoComplete +
                                        ', ' +
                                        storeVar.bairro;
                                    }
                                    if (storeVar.cep) {
                                      enderecoComplete =
                                        enderecoComplete + ', ' + storeVar.cep;
                                    }
                                    if (storeVar.cidade) {
                                      enderecoComplete =
                                        enderecoComplete +
                                        ', ' +
                                        storeVar.cidade;
                                    }
                                    if (storeVar.uf) {
                                      enderecoComplete =
                                        enderecoComplete + ', ' + storeVar.uf;
                                    }
                                  }

                                  return enderecoComplete;
                                };

                                formik.setFieldValue('store', value);
                                formik.setFieldValue(
                                  'address',
                                  storeComplete(value?.complete)
                                );
                                formik.setFieldValue(
                                  'phone_number',
                                  value?.phone_number
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Selecione uma loja"
                                  error={
                                    formik.touched.store &&
                                    Boolean(formik.errors.store)
                                  }
                                  helperText={
                                    formik.touched.store && formik.errors.store
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="description"
                              name="description"
                              placeholder="Descrição"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                              }
                              helperText={
                                formik.touched.description &&
                                formik.errors.description
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="address"
                              name="address"
                              placeholder="Endereço"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.address &&
                                Boolean(formik.errors.address)
                              }
                              helperText={
                                formik.touched.address && formik.errors.address
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="phone_number"
                              name="phone_number"
                              placeholder="Celular"
                              value={formik.values.phone_number}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.phone_number &&
                                Boolean(formik.errors.phone_number)
                              }
                              helperText={
                                formik.touched.phone_number &&
                                formik.errors.phone_number
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent> */}
                    </Card>

                    {/* <Card variant="outlined" style={{ marginTop: 20 }}>
                      <CardHeader
                        title="Etiquetas"
                        avatar={
                          <Avatar className={classes.avatar}>
                            <LocalOfferOutlinedIcon />
                          </Avatar>
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Autocomplete
                          options={tags}
                          getOptionLabel={option => option.name}
                          value={formik.values.tag}
                          onChange={(e, value) => {
                            formik.setFieldValue('tag', value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Selecione uma etiqueta"
                              error={
                                formik.touched.store &&
                                Boolean(formik.errors.tag)
                              }
                              helperText={
                                formik.touched.tag && formik.errors.tag
                              }
                            />
                          )}
                          renderOption={option => {
                            return (
                              <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                  <Avatar
                                    // className={classes.tagImg}
                                    src={option.imageURL}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {option.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }}
                        />
                      </CardContent>
                    </Card>  */}

                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'left', marginTop: 30 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenSelecionarProdutos(true)}
                      >
                        Selecionar Produtos
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <img
                      src={formik.values?.template?.miniatura || ''}
                      alt={'Preview do Fundo'}
                      style={{ width: '100%', borderRadius: 15 }}
                    />

                    {/* <Accordion
                      expanded={expandedAccordion === 'panelCores'}
                      onChange={onClickAccordion('panelCores')}
                      style={{ marginTop: 20 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, .03)',
                          borderBottom: '1px solid rgba(0, 0, 0, .125)',
                        }}
                      >
                        <Typography>
                          Cores{' '}
                          {expandedAccordion === 'panelCores' ? '(Enviar)' : ''}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 12,
                                textAlign: 'center',
                              }}
                            >
                              Cor do Texto
                            </div>
                            <div
                              style={{
                                width: '90%',
                                margin: 'auto',
                              }}
                            >
                              <CompactPicker
                                color={colorText}
                                onChangeComplete={color =>
                                  setColorText(color.rgb)
                                }
                                styles={{
                                  default: {
                                    picker: {
                                      boxShadow: 'none',
                                      margin: 'auto',
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                marginTop: 20,
                                marginBottom: 12,
                                textAlign: 'center',
                              }}
                            >
                              Cor do Preço
                            </div>
                            <div
                              style={{
                                width: '90%',
                                margin: 'auto',
                              }}
                            >
                              <CompactPicker
                                color={colorPrice}
                                onChangeComplete={color =>
                                  setColorPrice(color.rgb)
                                }
                                styles={{
                                  default: {
                                    picker: {
                                      boxShadow: 'none',
                                      margin: 'auto',
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                  </Grid>
                </Grid>
              </Container>

              {openSelecionarProdutos && (
                <SelecionarProdutos
                  open={openSelecionarProdutos}
                  onOpen={() => {
                    //this.setState({ idsNaoIncluidos: [] });
                  }}
                  onClose={() => {
                    setOpenSelecionarProdutos(false);
                  }}
                  tabloideProdutos={formik.values.products.map(
                    item => item.fullData
                  )}
                  idsNaoIncluidos={idsNaoIncluidos}
                  onSave={value => {
                    setIdsNaoIncluidos([...value]);
                    setOpenSelecionarProdutos(false);
                  }}
                />
              )}
            </TabPanel>
          </form>

          {/* <TabPanel value={tabValue} index={0}>
          <BackgroundsList />
        </TabPanel> */}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          {tabValue === 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
              disabled={
                consumo === 0 ||
                isNaN(parseInt(balance)) ||
                (!isNaN(parseInt(balance)) && (balance <= 0 || !enoughCredits))
              }
            >
              Gerar
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {formik.isSubmitting && (
        <Loader open={true} isSubmiting={formik.isSubmitting} />
      )}

      {openDialogContinuar && (
        <Dialog
          open={openDialogContinuar}
          onClose={() => setOpenDialogContinuar(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Deseja gerar mais artes?'}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);
                onClose();
              }}
              color="primary"
            >
              Não
            </Button>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);

                if (!props.modelo_pronto) {
                  setTabValue(0);
                  findLastLimitHistory();
                }
              }}
              color="primary"
              autoFocus
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
}

export default CreateFlyer;
