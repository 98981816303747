import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import DomainIcon from '@material-ui/icons/Domain';
import Table from 'material-table';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import localization from '../../../../localization/table';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import CreateDomain from './components/CreateDomain';
import useStyles from './styles';

function Domains({ siteId }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createDomainIsOpen, setCreateDomainIsOpen] = useState(false);

  const getDomains = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api(
        `${DATASALES_CRM_API}sites-${AMBIENTE}/domains`
      );

      setData(response.content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDomains();
  }, [getDomains]);

  const handleCreateDomainOpen = () => {
    setCreateDomainIsOpen(true);
  };

  const handleCreateDomainClose = useCallback(() => {
    setCreateDomainIsOpen(false);
  }, [setCreateDomainIsOpen]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/domains`,
          { site_id: siteId, ...data },
          'POST'
        );
      } catch (err) {
        console.log(err);
      } finally {
        handleCreateDomainClose();
        getDomains();
      }
    },
    [handleCreateDomainClose, getDomains]
  );

  // const handleEditDomain = useCallback(
  //   async data => {
  //     try {
  //       await api(
  //         `${DATASALES_CRM_API}sites-${AMBIENTE}/domains/${data.id}`,
  //         { site_id: data.id, active: !data.active },
  //         'PUT'
  //       );
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       getDomains();
  //     }
  //   },
  //   [getDomains]
  // );

  const handleRemoveDomain = useCallback(
    async id => {
      try {
        await api(
          `${DATASALES_CRM_API}sites-${AMBIENTE}/domains/${id}`,
          {},
          'DELETE'
        );
      } catch (err) {
        console.log(err);
      } finally {
        getDomains();
      }
    },
    [getDomains]
  );

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <DomainIcon />
            </Avatar>
          }
          title="Domínios"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateDomainOpen}
            >
              Adicionar domínio
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Table
            title=""
            isLoading={loading}
            columns={[
              { title: 'Nome', field: 'name' },
              {
                title: 'Criado em',
                field: 'created_at',
                render: rowData => (
                  <span>{moment(rowData.created_at).format('DD/MM/YYYY')}</span>
                ),
              },
            ]}
            actions={[
              {
                icon: 'delete',
                onClick: (e, rowData) => {
                  handleRemoveDomain(rowData.id);
                },
              },
            ]}
            data={data}
            options={{ actionsColumnIndex: -1 }}
            localization={localization}
          />
        </CardContent>
      </Card>
      {CreateDomain && (
        <CreateDomain
          open={createDomainIsOpen}
          onSubmit={handleSubmit}
          onClose={handleCreateDomainClose}
        />
      )}
    </Fragment>
  );
}

export default Domains;
