import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Checkbox } from '../../../utils/components';
import useStyles from '../styles';

const EditChatDialog = ({ open, onClose, onSave, chat, users }) => {
  const classes = useStyles();
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [oldAgent, setOldAgent] = useState(null);
  const [newAgent, setNewAgent] = useState(null);
  const [transferConfirm, setTransferConfirm] = useState(false);

  useEffect(() => {
    if (chat) {
      const _user = users.find(_user => _user.id === chat.USER);
      setOldAgent(_user);
    }
  }, [chat, users]);

  const _onSave = useCallback(() => {
    const _chat = { ...chat, USER: newAgent.id };
    const _transfer = {
      from: oldAgent.id,
      to: newAgent.id,
      ticket: chat.TICKET,
    };

    onSave(_chat, _transfer);
    setOldAgent(null);
    setNewAgent(null);
    setTransferConfirm(false);
  }, [oldAgent, newAgent]);

  const _onClose = useCallback(() => {
    onClose();
    setOldAgent(null);
    setNewAgent(null);
    setTransferConfirm(false);
  }, []);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={_onClose}>
      <DialogTitle onClose={_onClose}>Transferir conversa</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disabled
              defaultValue={oldAgent}
              options={users}
              getOptionLabel={option => `#${option.id} - ${option.name}`}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="De"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={!oldAgent || userId !== oldAgent.id}
              options={users}
              getOptionLabel={option => `#${option.id} - ${option.name}`}
              onChange={(_, user) => setNewAgent(user)}
              value={newAgent}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Para"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={!oldAgent || userId !== oldAgent.id}
              onChange={(_, checked) => setTransferConfirm(checked)}
              label="Confirmação de transferência"
            />
          </Grid>
          {(!oldAgent || userId !== oldAgent.id) && (
            <Grid item xs={12}>
              <Box bgcolor="#F5F5F5" p={2} borderRadius="5px">
                <Typography className={classes.textAlert} color="primary">
                  Você não pode transferir essa conversa para outro agente,
                  entre em contato com o dono da conversa para realizar a
                  transferência.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={
            !transferConfirm || !newAgent || !oldAgent || userId !== oldAgent.id
          }
          color="primary"
          onClick={_onSave}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditChatDialog.propTypes = {
  chat: PropTypes.object,
  open: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

EditChatDialog.defaultProps = {
  chat: {},
  users: [],
  open: false,
  onClose: () => {},
};

export default EditChatDialog;
