/* eslint-disable */
import {
  Button,
  Divider,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  withStyles,
  Icon,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatePicker, CustomTable, TextField } from '../../../utils/components';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import Alert from '../../../utils/helpers/Alert';
import '../style/performance.css';
import {
  getSubClusters,
  postSubCluster,
  setSubClusters,
  putSubCluster,
} from '../action/action';

const alerta = new Alert();

class SubCluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_cluster: null,
      descricao_cluster: '',
      forceUpdate: 0,
      subcluster: {
        descricao: '',
        descricao_marketing: '',
      },
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id_cluster: id.split('_')[0] });
    this.setState({ descricao_cluster: id.split('_')[1] });

    this.props.getSubClusters({ id_cluster: id.split('_')[0] });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Link to="/cluster" style={{ marginTop: 15, textDecoration: 'none' }}>
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Clusters
          </Button>
        </Link>
        <div className={classes.actions}>
          <Typography className={classes.margin} variant="h3" noWrap>
            {this.state.descricao_cluster}
          </Typography>
        </div>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 20,
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Adicionar SubCluster
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <TextField
                    label="Descrição"
                    onChange={e =>
                      this.setState({
                        subcluster: {
                          ...this.state.subcluster,
                          descricao: e.target.value,
                        },
                      })
                    }
                    value={this.state.subcluster.descricao}
                  />
                </div>
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <TextField
                    label="Desc. Marketing"
                    onChange={e =>
                      this.setState({
                        subcluster: {
                          ...this.state.subcluster,
                          descricao_marketing: e.target.value,
                        },
                      })
                    }
                    value={this.state.subcluster.descricao_marketing}
                  />
                </div>
              </div>
            </div>
          </section>
          <div style={{ marginBottom: 20 }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              onClick={() => {
                if (this.state.subcluster.descricao.trim() != '') {
                  this.props
                    .postSubCluster({
                      ...this.state.subcluster,
                      id_cluster: this.state.id_cluster,
                      deletado: 0,
                    })
                    .then(() => {
                      this.setState({
                        subcluster: {
                          descricao: '',
                          descricao_marketing: '',
                        },
                      });
                    });
                } else alerta.alertError('', 'Campo Descrição vazio!');
              }}
            >
              Adicionar
            </Button>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div className={classes.content}>
            <CustomTable
              data={this.props.subclusters}
              title=""
              pageSize={10}
              paging
              search
              isLoading={this.props.loadingSubClusters}
              style={{
                border: 'none',
                boxShadow: 'none',
                width: '100%',
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  editable: 'never',
                },
                {
                  title: 'Descrição',
                  field: 'descricao',
                },
                {
                  title: 'Desc. Marketing',
                  field: 'descricao_marketing',
                },
                {
                  title: 'Ações',
                  field: '',
                  render: rowData =>
                    rowData && (
                      <Link
                        to={`/clusterdetalhe/${rowData.id}_${this.state.id_cluster}_${rowData.descricao}_${this.state.descricao_cluster}`}
                        style={{ marginTop: 15, textDecoration: 'none' }}
                      >
                        <Button size="large" color="primary">
                          Abrir
                        </Button>
                      </Link>
                    ),
                },
              ]}
              deleteText="Deseja desativar esse subcluster?"
              editable={{
                // onRowAdd: newData =>
                //   new Promise(resolve => {
                //     this.props.postSubCluster({
                //       ...newData,
                //       id_cluster: this.state.id_cluster,
                //       deletado: 0
                //     }).then(() => {
                //       resolve();
                //     });
                //   }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(async resolve => {
                    this.props.putSubCluster(newData).then(() => {
                      let subclusters = this.props.subclusters;
                      const index = subclusters.indexOf(oldData);
                      subclusters[index] = newData;
                      this.props.setSubClusters(subclusters);
                      this.setState({
                        forceUpdate: this.state.forceUpdate + 1,
                      });
                      resolve();
                    });
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props
                      .putSubCluster({ ...oldData, deletado: 1 })
                      .then(() => {
                        let subclusters = this.props.subclusters;
                        const index = subclusters.indexOf(oldData);
                        subclusters.splice(index, 1);
                        this.props.setSubClusters(subclusters);
                        this.setState({
                          forceUpdate: this.state.forceUpdate + 1,
                        });
                        resolve();
                      });
                  }),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
});

const mapStateToProps = ({ ClusterReducer }) => ({
  ...ClusterReducer,
});

const mapDispatchToProps = {
  getSubClusters,
  postSubCluster,
  setSubClusters,
  putSubCluster,
};

const SubClusterConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCluster);

export default withStyles(styles)(SubClusterConnect);
