import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { GeneralNumbersMetricsBox } from './GeneralNumbersMetricsBox';
import api from '../../../utils/helpers/Fetch';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';

export function GeneralNumbersMetrics() {
  const [numbers, setNumbers] = useState({
    TOTAL: 0,
    FINISHED: 0,
    ACTIVE: 0,
    ONLINES: 0,
  });

  useEffect(() => {
    (async function () {
      try {
        const response = await api(
          `${DATASALES_CRM_API}chatbot-v2-${AMBIENTE}/analytics/numbers`
        );
        setNumbers(response.content);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs>
          <GeneralNumbersMetricsBox
            number={numbers.TOTAL}
            title="Total de Atendimentos"
          />
        </Grid>
        <Grid item xs>
          <GeneralNumbersMetricsBox
            number={numbers.FINISHED}
            title="Atendimentos Finalizados"
          />
        </Grid>
        <Grid item xs>
          <GeneralNumbersMetricsBox
            number={numbers.ACTIVE}
            title="Atendimentos Ativos"
          />
        </Grid>
        <Grid item xs>
          <GeneralNumbersMetricsBox
            number={numbers.ONLINES}
            title="Funcionários Online"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
