import {
  LIST_DDDs,
  LIST_UFs,
  LIST_MUNICIPIOS,
  LIST_BAIRROS,
  LIST_SEGMENTOS,
  LIST_CNPJ,
  COUNT_CNPJ,
  LOADING_VENDA_MAIS,
  LOADING_COUNT,
  LIST_CABECALHO_PROSPECTS,
  LIST_DETALHE_PROSPECTS,
  DOWNLOAD_CSV,
  GRAFICO_VENDA_MAIS
} from "../ducks/ducks";
const INITIAL_STATE = {
  listDDDs: [],
  listUFs: [],
  listMunicipios: [],
  listBairros: [],
  listSegmentos: [],
  listCNPJ: [],
  listCabecalhoProspects: [],
  listDetalheProspects: [],
  graficoVendaMais: {},
  countCNPJ: 0,
  isLoadingVendaMais: false,
  isLoadingCount: true,
  isDownloadCSV: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_DDDs:
      return {
        ...state,
        listDDDs: action.payload.map(ddd => ({ label: ddd, value: ddd }))
      };
    case LIST_UFs:
      return { ...state, listUFs: action.payload };
    case LIST_MUNICIPIOS:
      return { ...state, listMunicipios: action.payload };
    case LIST_BAIRROS:
      return { ...state, listBairros: action.payload };
    case LIST_SEGMENTOS:
      return { ...state, listSegmentos: action.payload };
    case LIST_CNPJ:
      return { ...state, listCNPJ: action.payload, isLoadingVendaMais: false };
    case COUNT_CNPJ:
      return {
        ...state,
        countCNPJ: action.payload[0].QtdeCNPJ,
        isLoadingCount: false
      };
    case LIST_CABECALHO_PROSPECTS:
      return {
        ...state,
        listCabecalhoProspects: action.payload,
        isLoadingVendaMais: false
      };
    case LIST_DETALHE_PROSPECTS:
      return {
        ...state,
        listDetalheProspects: action.payload,
        isLoadingVendaMais: false
      };
    case GRAFICO_VENDA_MAIS:
      return {
        ...state,
        graficoVendaMais: action.payload,
        isLoadingVendaMais: false
      };
    case LOADING_VENDA_MAIS:
      return { ...state, isLoadingVendaMais: action.payload };
    case LOADING_COUNT:
      return { ...state, isLoadingCount: action.payload };
    case DOWNLOAD_CSV:
      return { ...state, isDownloadCSV: action.payload };
    default:
      return state;
  }
};
