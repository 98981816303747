import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 3, 3),
    maxWidth: '100%',
    overflow: 'hidden',
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '.05em',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    textTransform: 'none',
    opacity: 1,
  },
  tabActive: {
    backgroundColor: '#E8E8FD',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    textTransform: 'none',
    opacity: 1,
  },
  tabUnderline: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: '#616161',
    textTransform: 'none',
    opacity: 1,
    whiteSpace: 'nowrap',
    overflow: 'inherit',
  },
  tabActiveUnderline: {
    textDecorationLine: 'underline',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '.05em',
    color: theme.palette.primary.main,
    textTransform: 'none',
    opacity: 1,
    whiteSpace: 'nowrap',
    overflow: 'inherit',
  },
  tabWrapper: { display: 'inline' },
  icon: { marginRight: '8px' },
  card: {
    border: '1px solid #B4B4B4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '16px',

  },
  cardImage: {
    width: '10.416vw',
    height: '21.887vh',
    objectFit: 'scale-down',
    paddingRight: '3.710vw',
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '.05em',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

  },
  cardDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',

  },
  cardContainerDescription: {
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '16px 0',

  },
  textDescription: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '.05em',
    color: '#616161',
  },
  btnViewMore: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '.05em',
    color: '#0077E6',
  },
  btnKnowMore: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '.05em',
    color: '#0077E6',
    marginLeft: '1.0416vw',
  },
  dialog: {
    padding: theme.spacing(4),
    '&:first-child': { paddingTop: theme.spacing(4) },
  },
  dialogIcon: {
    padding: 8,
    width: 48,
    height: 48,
    backgroundColor: '#F5F5F5',
  },
  dialogInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  content: {
    padding: 0,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    width: '100%',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    width: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
