import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useParams } from 'react-router-dom';
import Delivery from '../../../Delivery/components/Delivery';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function Statistics({ open, onClose }) {
  const classes = useStyles();

  const { id } = useParams();

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <AppBar className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Vendas
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Delivery offerId={Number(id)} />
    </Dialog>
  );
}

export default Statistics;
