/* eslint-disable */
import {
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CustomTable, TextField } from '../../../../utils/components';
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';
import { toBase64 } from '../../../../utils/helpers/Functions';
import {
  addTabloidsTemplate,
  createTemplatePersonalizado,
  getTabloidsTemplatesFisico
} from '../../action/index';

class EditTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segmento: 'supermercado',
      ocasiao: null,
      nome: null,
      tipoPixelixe: null,
      id_cliente: localStorage.getItem('companyId'),
      formato: null,
      base64: null,
      //
      id_cliente_search: null,
      tipoPixelixe_search: null,
      //
      editOcasiao: ''
    };
  }

  render() {
    const is396 = JSON.parse(localStorage.getItem('companyId')) === 396;

    const { classes } = this.props;

    return (
      is396 && (
        <div className={classes.root}>
          <Link to={`/estudio`} style={{ marginTop: 15, textDecoration: 'none' }}>
            <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
              <Icon>arrow_back_ios</Icon>Voltar para Estúdio
            </Button>
          </Link>
          <div className={classes.actions}>
            <div className={classes.actions} style={{ maxWidth: '65vw' }}>
              <Typography className={classes.margin} variant="h3">
                Edição de Templates
              </Typography>
            </div>
            <div style={{ marginBottom: 20, display: 'flex' }}>
              <TextField
                label="ID do cliente"
                value={this.state.id_cliente_search}
                shrink={true}
                disabled={false}
                onChange={e =>
                  this.setState({
                    id_cliente_search: e.target.value === '' ? null : e.target.value
                  })
                }
                style={{ width: 150, margin: 10 }}
              ></TextField>
              <Autocomplete
                id="combo-box-tipoPixelixe"
                options={[
                  'encarte',
                  'facebook',
                  'instagram',
                  'whatsapp',
                  'redes sociais',
                  'cartaz',
                  'etiqueta',
                  'indoor'
                ]}
                getOptionLabel={option => option}
                style={{ width: 300, margin: 10 }}
                onChange={(event, newValue) => {
                  this.setState({ tipoPixelixe_search: newValue });
                }}
                onInputChange={(event, newInputValue) => {
                  this.setState({ tipoPixelixe_search: newInputValue });
                }}
                renderInput={params => <TextField {...params} label="Tipo" variant="outlined" />}
              />
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: COLOR_PRIMARY,
                  marginRight: 15,
                  marginBottom: 10
                }}
                onClick={() =>
                  this.props.getTabloidsTemplatesFisico(
                    {
                      tipoPixelixe: this.state.tipoPixelixe_search,
                      id_cliente: this.state.id_cliente_search,
                      order: true,
                      editTemplate: true
                    },
                    true,
                    false
                  )
                }
              >
                Pesquisar
              </Button>
            </div>
          </div>
          <div className={classes.actions} style={{ marginLeft: 'auto' }}>
            <div style={{ marginBottom: 20, display: 'flex' }}>
              <TextField
                label="Segmento"
                value={this.state.segmento}
                shrink={true}
                disabled={false}
                onChange={e => this.setState({ segmento: e.target.value })}
                style={{ width: 180, margin: 10 }}
              ></TextField>
              <TextField
                label="Ocasião"
                value={this.state.ocasiao}
                shrink={true}
                disabled={false}
                onChange={e => this.setState({ ocasiao: e.target.value })}
                style={{ width: 180, margin: 10 }}
              ></TextField>
              <TextField
                label="Nome"
                value={this.state.nome}
                shrink={true}
                disabled={false}
                onChange={e => this.setState({ nome: e.target.value })}
                style={{ width: 180, margin: 10 }}
              ></TextField>
              <Autocomplete
                id="combo-box-tipoPixelixe"
                options={[
                  { label: 'encarte', tipo: 'encarte', formato: null },
                  {
                    label: 'redes sociais - post',
                    tipo: 'redes sociais',
                    formato: 'Post[1200X1200]'
                  },
                  {
                    label: 'redes sociais - story',
                    tipo: 'redes sociais',
                    formato: 'Story[1080X1920]'
                  },
                  // { label: 'facebook - post', tipo: 'facebook', formato: 'Post' },
                  // {
                  //   label: 'facebook - story',
                  //   tipo: 'facebook',
                  //   formato: 'Story',
                  // },
                  // {
                  //   label: 'instagram - post',
                  //   tipo: 'instagram',
                  //   formato: 'Post',
                  // },
                  // {
                  //   label: 'instagram - story',
                  //   tipo: 'instagram',
                  //   formato: 'Story',
                  // },
                  // { label: 'whatsapp - post', tipo: 'whatsapp', formato: 'Post' },
                  // {
                  //   label: 'whatsapp - story',
                  //   tipo: 'whatsapp',
                  //   formato: 'Story',
                  // },
                  { label: 'cartaz', tipo: 'cartaz', formato: null },
                  { label: 'etiqueta', tipo: 'etiqueta', formato: null },
                  {
                    label: 'indoor',
                    tipo: 'indoor',
                    formato: 'Indoor[1920X1080]'
                  }
                ]}
                getOptionLabel={option => option.label}
                style={{ width: 180, margin: 10 }}
                onChange={(event, newValue) => {
                  this.setState({
                    tipoPixelixe: newValue.tipo,
                    formato: newValue.formato
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  this.setState({
                    tipoPixelixe: newInputValue,
                    formato: null
                  });
                }}
                renderInput={params => <TextField {...params} label="Tipo" variant="outlined" />}
              />
              <TextField
                label="ID do cliente"
                value={this.state.id_cliente}
                shrink={true}
                disabled={false}
                onChange={e =>
                  this.setState({
                    id_cliente: e.target.value === '' ? null : e.target.value
                  })
                }
                style={{ width: 150, margin: 10 }}
              ></TextField>
              <div style={{ margin: 10, width: 80 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={async e => {
                    const imageEdit = await toBase64(e.target.files[0]);
                    this.setState({
                      base64: imageEdit.base64
                    });
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button size="small" component="span">
                    Escolher Imagem {this.state.base64 && <DoneAllIcon />}
                  </Button>
                </label>
              </div>
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: COLOR_PRIMARY,
                  marginRight: 15,
                  marginBottom: 10
                }}
                onClick={() => {
                  this.props
                    .addTabloidsTemplate({
                      segmento: this.state.segmento,
                      ocasiao: this.state.ocasiao,
                      nome: this.state.nome,
                      tipoPixelixe: this.state.tipoPixelixe,
                      id_cliente: this.state.id_cliente,
                      formato: this.state.formato,
                      base64: this.state.base64
                    })
                    .then(() => {
                      this.props.getTabloidsTemplatesFisico(
                        {
                          tipoPixelixe: this.state.tipoPixelixe,
                          id_cliente: this.state.id_cliente,
                          order: true,
                          editTemplate: true
                        },
                        true,
                        false
                      );
                    });
                }}
              >
                Adicionar
              </Button>
            </div>
          </div>

          <Divider className={classes.divider} />

          <div className={classes.content}>
            <div className={classes.content}>
              <CustomTable
                data={this.props.tabloids_templates_fisico}
                title=""
                pageSize={10}
                paging
                search
                isLoading={this.props.loadind_tabloides_template_fisico}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  width: '100%'
                }}
                columns={[
                  {
                    title: 'Id',
                    field: 'id'
                  },
                  {
                    title: 'ID do Cliente',
                    field: 'id_cliente'
                  },
                  {
                    title: 'Segmento',
                    field: 'segmento',
                    editComponent: props => (
                      <TextField
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        type="numeric"
                        size="small"
                      />
                    )
                  },
                  {
                    title: 'Formato',
                    field: 'formato',
                    editComponent: props => (
                      <TextField
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        type="numeric"
                        size="small"
                      />
                    )
                  },
                  {
                    title: 'Ocasião',
                    field: 'ocasiao',
                    editComponent: props => (
                      <TextField
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        type="numeric"
                        size="small"
                      />
                    )
                  },
                  {
                    title: 'Qtde de Produtos',
                    field: 'qtde_produtos'
                  },
                  {
                    title: 'Nome',
                    field: 'nome',
                    editComponent: props => (
                      <TextField
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        type="numeric"
                        size="small"
                      />
                    )
                  },
                  {
                    title: 'Template',
                    field: 'template'
                  }
                ]}
                deleteText="Deseja REALMENTE desativar esse template? CUIDADO!!!!"
                editable={{
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      this.props
                        .createTemplatePersonalizado({
                          id: oldData.id,
                          deletar: true,
                          all: true
                        })
                        .then(() => {
                          this.props.getTabloidsTemplatesFisico(
                            {
                              tipoPixelixe: this.state.tipoPixelixe_search,
                              id_cliente: this.state.id_cliente_search,
                              order: true,
                              editTemplate: true
                            },
                            true,
                            false
                          );
                          resolve();
                        });
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(async resolve => {
                      this.props
                        .createTemplatePersonalizado({
                          id: oldData.id,
                          all: true,
                          objEdit: {
                            segmento: newData.segmento,
                            formato: newData.formato,
                            ocasiao: newData.ocasiao,
                            nome: newData.nome
                          }
                        })
                        .then(() => {
                          this.props.getTabloidsTemplatesFisico(
                            {
                              tipoPixelixe: this.state.tipoPixelixe_search,
                              id_cliente: this.state.id_cliente_search,
                              order: true,
                              editTemplate: true
                            },
                            true,
                            false
                          );
                          resolve();
                        });
                    })
                }}
                options={{
                  rowStyle: rowData => ({
                    backgroundColor: !rowData.deletado ? '#FFF' : '#F7B8AA'
                  })
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 40,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%'
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  }
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});

const mapDispatchToProps = {
  getTabloidsTemplatesFisico,
  addTabloidsTemplate,
  createTemplatePersonalizado
};

const EditTemplatesConnect = connect(mapStateToProps, mapDispatchToProps)(EditTemplates);

export default withStyles(styles)(EditTemplatesConnect);

