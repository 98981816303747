/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import moment_tz from "moment-timezone";
import {
  Button,
  Typography,
  Divider,
  withStyles,
  MenuItem,
  Avatar,
  Icon,
  // Tooltip,
  CircularProgress,
  //ButtonGroup
  // TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
// import clsx from "clsx";
// import SearchIcon from "@material-ui/icons/Search";
import { loadCSS } from 'fg-loadcss';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import {
  onChangeState,
  getCategoriasOferta,
  getDetahesProduto,
} from '../actions/index';
import MyDate from '../../../utils/helpers/Date';
import {
  Panel,
  CountCard,
  TextField,
  Recharts,
  PieChart,
  CustomTable,
  Countup,
} from '../../../utils/components';

import ModalProdutos from './ModalProdutos';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import ModalCategoriasAnalitico from './ModalCategoriasAnalitico';
import ModalProdutosAnalitico from './ModalProdutosAnalitico';

class DetalhesOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeOferta: '',
      idOferta: '',
      nomeProduto: '',
      produtos: [],
      key: 0,
      showModalProdutos: false,
      keyVisao: 0,
      showModalCategoriasAnalitico: false,
      showModalProdutosAnalitico: false,
      selectedCategoria: null,
      selectedProduto: null,
      produto: {
        detalhesProduto: [],
        vendasHora: [],
        vendasDia: [],
        faixaEtaria: [],
        genero: [],
        vendasLoja: [],
      },
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ nomeOferta: id.split('_')[1] });
    this.setState({ idOferta: id.split('_')[0] });

    this.props.getCategoriasOferta(id.split('_')[0]);
    this.props
      .getDetahesProduto({
        idOferta: id.split('_')[0],
        idProduto: 0,
      })
      .then(() => {
        this.setState({ produto: this.props.produto });
      });
  }

  onChange(state, value) {
    this.setState({ [state]: value });
  }

  onChangeOferta(state, value) {
    this.setState({ oferta: { ...this.state.oferta, [state]: value } });
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data, filtroMes: null }, () => {
      const { filtroDataInicio, filtroDataFim } = this.state;
      const request = {
        filtroDataInicio: new MyDate(filtroDataInicio, 'YYYY-MM-DD').getDate(),
        filtroDataFim: new MyDate(filtroDataFim, 'YYYY-MM-DD').getDate(),
      };
      this.props.getTodasPerformanceMes(request);
    });
  }

  onKeyPressed(e) {
    const { nome_campanha } = this.state;

    if (e.keyCode === 13 && nome_campanha.length > 0) {
      this.props.getTodasPerformanceMes({ nome_campanha });
    } else if (e.keyCode === 13 && !nome_campanha.length > 0) {
      this.onChangePeriodo();
    }
  }

  renderCard(faturamento_clube, faturamento_n_clube) {
    if (faturamento_clube > faturamento_n_clube) {
      return (
        <CountCard
          titleTypographyProps={{ variant: 'inherit' }}
          // decimals={2}
          // prefix="R$ "
          icon="shop_two"
          avatar={
            <Avatar className={this.props.classes.avatar}>
              <Icon>shopping_cart</Icon>
            </Avatar>
          }
          title="Oferta aderente. Os consumidores preferem comprar com o desconto.
          "
          noDivider
          // subheader={}
          style={{ marginBottom: 20, width: '30%' }}
        >
          <Typography variant="inherit">Teste</Typography>
        </CountCard>
      );
    } else if (faturamento_clube <= faturamento_n_clube) {
      return (
        <CountCard
          avatar={
            <Avatar className={classes.avatar}>
              <Icon>shopping_cart</Icon>
            </Avatar>
          }
          title="Oferta sem aderencia. Os consumidores preferem comprar sem o desconto."
          // subheader={}
          style={{ marginBottom: 20 }}
        ></CountCard>
      );
    }
  }

  render() {
    const { classes, produtosCategoria, isLoadingCategorias } = this.props;
    const {
      categorias,
      visaoGeral,
      vendasDia,
      vendasHora,
      genero,
      faixaEtaria,
      vendasLoja,
    } = this.props.detalhesOferta;
    const { detalhesProduto } = this.state.produto;
    const {
      key,
      keyVisao,
      nomeProduto,
      showModalProdutos,
      produtos,
      nomeOferta,
      idOferta,
      showModalCategoriasAnalitico,
      showModalProdutosAnalitico,
      selectedCategoria,
      selectedProduto,
    } = this.state;

    return (
      <div className={classes.root}>
        <Link to="/ofertas" style={{ textDecoration: 'none' }}>
          <Button size="small" style={{ marginBottom: '1rem' }} color="primary">
            <Icon>arrow_back_ios</Icon>Voltar para Ofertas
          </Button>
        </Link>
        <Typography className={classes.label} variant="h4" noWrap>
          #{idOferta} - {nomeOferta}
        </Typography>
        {/* <div className={classes.actions}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          > */}
        {/* <Typography className={classes.label} variant="h6" noWrap>
              Sugestões de oferta
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    "&:hover": { borderRadius: 10 }
                  }}
                  color="inherit"
                  size="large"
                  className={key === 0 && classes.activeButton}
                  onClick={() => this.setState({ key: 0 })}
                >
                  Categoria
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                    borderRadius: 10,
                    "&:hover": { borderRadius: 10 }
                  }}
                  color="inherit"
                  size="large"
                  className={key === 1 && classes.activeButton}
                  onClick={() => this.setState({ key: 1 })}
                >
                  Padrão
                </Button>
              </div>
            </div> */}
        {/* </div>
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              size="lg"
              placeholder="Pesquisar categoria..."
              autoComplete="false"
              onChange={e => this.setState({ nomeProduto: e.target.value })}
              value={nomeProduto}
              // onKeyDown={this.onKeyPressed}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled
                      // onClick={() =>
                      //   nomeOferta.length > 0
                      //     ? this.props.getOfertas({ nomeOferta })
                      //     : this.onChangePeriodo()
                      // }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <div>
            <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 0 && classes.activeButton}
              onClick={() => this.setState({ key: 0 })}
            >
              Visão geral
            </Button>
            {/* <Button
              style={{
                marginRight: 10,
                borderRadius: 10,
                '&:hover': { borderRadius: 10 },
              }}
              color="inherit"
              size="large"
              className={key === 1 && classes.activeButton}
              onClick={() => this.setState({ key: 1 })}
            >
              Categorias
            </Button> */}
          </div>
        </div>
        <div
          className={classes.content}
          style={
            isLoadingCategorias
              ? {
                  display: 'flex',
                  height: '100%',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          {isLoadingCategorias ? (
            <CircularProgress></CircularProgress>
          ) : key === 0 ? (
            <Fragment>
              {visaoGeral.map(oferta => (
                <Fragment>
                  {/* {this.renderCard(
                    oferta.faturamento_clube,
                    oferta.faturamento_n_clube
                  )} */}
                  <Panel
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Icon>shopping_cart</Icon>
                      </Avatar>
                    }
                    title={`Visão Geral - Clube`}
                    // subheader={}
                    style={{ marginBottom: 20 }}
                    // {oferta.tipo_oferta !== "cupom" && (
                    // action={
                    //   <Button
                    //     size="large"
                    //     className={classes.button}
                    //     variant="text"
                    //     color="primary"
                    //     // component={Link}
                    //     onClick={() =>
                    //       this.setState({
                    //         produtos: produtosOferta,
                    //         showModalProdutos: true
                    //       })
                    //     }
                    //     //   to={`/detalhes-campanha/${lista.ID_CAMPANHA}`}
                    //   >
                    //     Detalhes
                    //   </Button>
                    // }
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.qtde_produtos}
                        loading={isLoadingCategorias}
                        // decimals={2}
                        // prefix="R$ "
                        title=" Produtos"
                        icon="shop_two"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.qtde_vendas}
                        loading={isLoadingCategorias}
                        // decimals={2}
                        // prefix="R$ "
                        title=" Vendas"
                        icon="score"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.investimento}
                        loading={isLoadingCategorias}
                        decimals={2}
                        prefix="R$ "
                        title="Investimento"
                        icon="build"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        // variant="sucess"
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.faturamento}
                        loading={isLoadingCategorias}
                        decimals={2}
                        prefix="R$ "
                        title="Faturamento"
                        icon="add_shopping_cart"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        helpButton
                        helpContent={
                          <section
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span>R$ {MoedaBrasil(oferta.roi || 0)}</span>

                            <span>{MoedaBrasil(oferta.roi_perc)}%</span>
                          </section>
                        }
                        prefix="R$ "
                        end={oferta.roi ? parseFloat(oferta.roi) : 0}
                        loading={isLoadingCategorias}
                        titleTypographyProps={{ variant: 'h6' }}
                        title="ROI"
                        decimals={2}
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        titleTypographyProps={{ variant: 'h6' }}
                        end={oferta.ticket_medio}
                        loading={isLoadingCategorias}
                        decimals={2}
                        prefix="R$ "
                        title="Ticket Medio"
                        icon="attach_money"
                        style={{ marginRight: 20 }}
                      />
                      <CountCard
                        count
                        loading={isLoadingCategorias}
                        helpButton
                        helpContent={
                          <section
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span>R$ {MoedaBrasil(oferta.margem || 0)}</span>

                            <span>
                              {MoedaBrasil(oferta.margem_percentual || 0)}%
                            </span>
                          </section>
                        }
                        end={
                          oferta.margem_percentual
                            ? parseFloat(oferta.margem_percentual)
                            : 0
                        }
                        suffix="%"
                        icon="trending_up"
                        titleTypographyProps={{ variant: 'h6' }}
                        title="Margem"
                        decimals={2}
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </Panel>

                  {/* <Panel
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Icon>shopping_cart</Icon>
                      </Avatar>
                    }
                    title="Não Clube"
                    subheader={` ${moment(oferta.data_inicio).format(
                      "DD/MM/YYYY"
                    )} até ${moment(oferta.data_fim).format("DD/MM/YYYY")}`}
                    style={{ marginBottom: 20 }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: 20
                        }}
                      >
                        <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={oferta.qtde_cupom_n_clube}
                          // decimals={2}
                          // prefix="R$ "
                          title=" Vendas"
                          icon="score"
                          style={{ marginRight: 20 }}
                        />

                        <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={oferta.faturamento_n_clube}
                          decimals={2}
                          prefix="R$ "
                          title="Faturamento"
                          icon="add_shopping_cart"
                          style={{ marginRight: 20 }}
                        /> */}
                  {/*---------------------------*/}
                  {/* <CountCard
                count
                titleTypographyProps={{ variant: "h6" }}
                end={oferta.investimento}
                decimals={2}
                prefix="R$ "
                title="Investimento"
                icon="build"
                style={{ marginRight: 20 }}
              /> */}
                  {/* <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={oferta.roi_n_clube}
                          decimals={2}
                          prefix="R$ "
                          title="ROI"
                          icon="attach_money"
                          style={{ marginRight: 20 }}
                        /> */}
                  {/*---------------------------*/}
                  {/* <CountCard
                          count
                          titleTypographyProps={{ variant: "h6" }}
                          end={oferta.ticket_medio_n_clube}
                          decimals={2}
                          prefix="R$ "
                          title="Ticket médio"
                          icon="attach_money"
                          style={{ marginRight: 20 }}
                        />
                      </div>
                    </div>
                  </Panel> */}
                </Fragment>
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 20,
                }}
              >
                <div>
                  <Button
                    style={{
                      marginRight: 10,
                      borderRadius: 10,
                      '&:hover': { borderRadius: 10 },
                    }}
                    color="inherit"
                    size="large"
                    className={keyVisao === 0 && classes.activeButton}
                    onClick={() => this.setState({ keyVisao: 0 })}
                  >
                    Analítico
                  </Button>
                  <Button
                    style={{
                      marginRight: 10,
                      borderRadius: 10,
                      '&:hover': { borderRadius: 10 },
                    }}
                    color="inherit"
                    size="large"
                    className={keyVisao === 1 && classes.activeButton}
                    onClick={() => this.setState({ keyVisao: 1 })}
                  >
                    Categorias
                  </Button>
                  <Button
                    style={{
                      marginRight: 10,
                      borderRadius: 10,
                      '&:hover': { borderRadius: 10 },
                    }}
                    color="inherit"
                    size="large"
                    className={keyVisao === 2 && classes.activeButton}
                    onClick={() => this.setState({ keyVisao: 2 })}
                  >
                    Produtos
                  </Button>
                </div>
              </div>
              {keyVisao === 0 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Panel
                    width="100%"
                    title="Por Loja "
                    //  subheader={detalhesProduto.familia}
                    style={{ marginBottom: 20 }}
                  >
                    <Recharts
                      data={vendasLoja}
                      barDataKey="vendas"
                      xDataKey="nome_loja"
                      hide
                      // xDataKey="nome_loja"
                    />
                  </Panel>
                  <Panel
                    width="100%"
                    title="Por Dia "
                    //  subheader={detalhesProduto.familia}
                    style={{ marginBottom: 20 }}
                  >
                    <Recharts
                      data={vendasDia}
                      barDataKey="vendas"
                      xDataKey="data_analise"
                    />
                  </Panel>
                  <Panel
                    width="100%"
                    title="Por Hora "
                    //  subheader={detalhesProduto.familia}
                    style={{ marginBottom: 20 }}
                  >
                    <Recharts
                      data={vendasHora}
                      barDataKey="vendas"
                      xDataKey="horario_analise"
                    />
                  </Panel>
                  <Panel
                    width="49%"
                    title=" Gênero"
                    //  subheader={detalhesProduto.familia}
                    style={{ marginBottom: 20 }}
                  >
                    <PieChart data={genero} />
                  </Panel>
                  <Panel
                    width="49%"
                    title=" Faixa Etária"
                    //  subheader={detalhesProduto.familia}
                    style={{ marginBottom: 20 }}
                  >
                    <PieChart data={faixaEtaria} />
                  </Panel>
                </div>
              )}
              {keyVisao === 1 && (
                <CustomTable
                  isLoading={this.props.isLoadingCategorias}
                  data={categorias}
                  title=""
                  paging
                  onRowClick={(e, rowData) =>
                    this.setState({
                      selectedCategoria: rowData,
                      showModalCategoriasAnalitico: true,
                    })
                  }
                  pageSize={5}
                  headerStyle={{ zIndex: 0 }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  columns={[
                    { title: 'Categoria', field: 'categoria' },
                    {
                      title: 'Produtos',
                      field: 'qtde_produtos',
                      render: rowData => (
                        <Countup start={0} end={rowData.qtde_produtos} />
                      ),
                    },
                    {
                      title: 'Vendas',
                      field: 'qtde_vendas_clube',
                      render: rowData => (
                        <Countup start={0} end={rowData.qtde_vendas_clube} />
                      ),
                    },
                    {
                      title: 'Investimento',
                      field: 'investimento ',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.investimento}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Faturamento',
                      field: 'faturamento',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.faturamento}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'ROI',
                      field: 'roi',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.roi}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Ticket Médio',
                      field: 'ticket_medio',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.ticket_medio}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem',
                      field: 'MARGEM',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Percentual',
                      field: 'margem_percentual',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.margem_percentual}
                          suffix="%"
                          decimals={2}
                        />
                      ),
                    },
                  ]}
                />
              )}
              {keyVisao === 2 && (
                <CustomTable
                  isLoading={this.props.isLoadingDetalhesProduto}
                  data={detalhesProduto}
                  title=""
                  paging
                  onRowClick={(e, rowData) =>
                    this.setState({
                      selectedProduto: rowData,
                      showModalProdutosAnalitico: true,
                    })
                  }
                  pageSize={5}
                  headerStyle={{ zIndex: 0 }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  columns={[
                    { title: 'Produto', field: 'PRODUTO' },
                    {
                      title: 'Preço',
                      field: 'preco_original',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.preco_original}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Vendas',
                      field: 'qtde_vendas',
                      render: rowData => (
                        <Countup start={0} end={rowData.qtde_vendas} />
                      ),
                    },
                    {
                      title: 'Investimento',
                      field: 'investimento ',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.investimento}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Faturamento',
                      field: 'faturamento_clube',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.faturamento_clube}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'ROI',
                      field: 'roi_clube',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.roi_clube}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Ticket Médio',
                      field: 'ticket_medio',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.ticket_medio}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem',
                      field: 'MARGEM',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.MARGEM}
                          prefix="R$ "
                          decimals={2}
                        />
                      ),
                    },
                    {
                      title: 'Margem Percentual',
                      field: 'margem_percentual',
                      render: rowData => (
                        <Countup
                          start={0}
                          end={rowData.margem_percentual}
                          suffix="%"
                          decimals={2}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </Fragment>
          ) : (
            categorias.map(categoria => (
              <Panel
                // avatar={
                //   <img
                //     src={
                //       produto.url ||
                //       "https://trali.com.br/wp-content/uploads/2018/04/Produto-sem-imagem.jpg"
                //     }
                //     alt=""
                //     width={80}
                //     height={80}
                //     style={{ borderRadius: 10 }}
                //   />
                // }
                title={categoria.categoria}
                // subheader={produto.FAMILIA}
                style={{ marginBottom: 20 }}
                // {oferta.tipo_oferta !== "cupom" && (
                action={
                  <Button
                    size="large"
                    className={classes.button}
                    variant="text"
                    color="primary"
                    component={Link}
                    to={`/produtos-categoria/${categoria.id_oferta}_${categoria.id_categoria}_${nomeOferta}_${categoria.categoria}`}
                    // onClick={() =>
                    //   this.props.onChangeState(
                    //     "produtosCategoria",
                    //     produtosCategoria.filter(
                    //       prd => prd.id_familia === categoria.id_familia
                    //     )
                    //   )
                    // }
                    //   to={`/detalhes-campanha/${lista.ID_CAMPANHA}`}
                  >
                    Detalhes
                  </Button>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* <CountCard
              count
              titleTypographyProps={{ variant: "h6" }}
              end={produto.preco_original}
              decimals={2}
              prefix="R$ "
              title="Preço original"
              icon="add_shopping_cart"
              style={{ marginRight: 20 }}
            />

            <CountCard
              count
              titleTypographyProps={{ variant: "h6" }}
              end={produto.preco_dois}
              decimals={2}
              prefix="R$ "
              title="Preço dois"
              icon="add_shopping_cart"
              style={{ marginRight: 20 }}
            /> */}

                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.qtde_produtos}
                    // decimals={2}
                    // prefix="R$ "
                    title=" Produtos"
                    icon="shop_two"
                    style={{ marginRight: 20 }}
                  />

                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.qtde_cupom}
                    // decimals={2}
                    // prefix="R$ "
                    title="Vendas"
                    icon="score"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.investimento}
                    decimals={2}
                    prefix="R$ "
                    title="Investimento"
                    icon="build"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.faturamento}
                    decimals={2}
                    prefix="R$ "
                    title="Faturamento"
                    icon="add_shopping_cart"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.roi}
                    decimals={2}
                    prefix="R$ "
                    title="ROI"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  />
                  <CountCard
                    count
                    titleTypographyProps={{ variant: 'h6' }}
                    end={categoria.ticket_medio}
                    decimals={2}
                    prefix="R$ "
                    title="Ticket Medio"
                    icon="attach_money"
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Panel>
            ))
          )}
        </div>
        {showModalProdutos && (
          <ModalProdutos
            open={showModalProdutos}
            onClose={() =>
              this.setState({
                showModalProdutos: false,
              })
            }
            produtosCategoria={produtos}
          ></ModalProdutos>
        )}
        {showModalCategoriasAnalitico && (
          <ModalCategoriasAnalitico
            data={{
              id_oferta: visaoGeral[0].id_oferta,
              id_categoria: selectedCategoria.id_categoria,
              categoria: selectedCategoria.categoria,
            }}
            open={showModalCategoriasAnalitico}
            onClose={() =>
              this.setState({
                showModalCategoriasAnalitico: false,
                selectedCategoria: null,
              })
            }
          />
        )}
        {showModalProdutosAnalitico && (
          <ModalProdutosAnalitico
            data={{
              id_oferta: visaoGeral[0].id_oferta,
              id_produto: selectedProduto.id_produto,
              produto: selectedProduto.PRODUTO,
            }}
            open={showModalProdutosAnalitico}
            onClose={() =>
              this.setState({
                showModalProdutosAnalitico: false,
                selectedProduto: null,
              })
            }
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2), marginLeft: '18px' },
  filterContent: {},
  content: {
    flexGrow: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = ({ OfertasReducer }) => ({
  ...OfertasReducer,
});

const mapDispatchToProps = {
  onChangeState,
  getCategoriasOferta,
  getDetahesProduto,
};

const DetalhesOfertaConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalhesOferta);

export default withStyles(styles)(DetalhesOfertaConnect);
