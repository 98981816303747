import moment from 'moment';
const INITIAL_STATE = {
  listTags: [],
  listAgenda: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_TAGS':
      return { ...state, listTags: action.payload };
    case 'LIST_AGENDA':
      const listAgenda = action.payload.map(agenda => {
        const start = moment(agenda.dataInicio)
          .format('YYYY-MM-DD-HH-mm')
          .split('-');
        const end = moment(agenda.dataFim)
          .format('YYYY-MM-DD-HH-mm')
          .split('-');

        return {
          ...agenda,
          title: agenda.titulo,
          start: new Date(
            start[0],
            parseInt(start[1], 10) - 1,
            start[2],
            start[3],
            start[4],
          ),
          end: new Date(
            end[0],
            parseInt(end[1], 10) - 1,
            end[2],
            end[3],
            end[4],
          ),
        };
      });

      return { ...state, listAgenda };
    default:
      return state;
  }
};
