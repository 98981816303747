export const primary = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '22px',
  /* identical to box height, or 157% */
  // display: 'flex',
  // 'align-items': 'center',
  letterSpacing: '0.05em'
};

