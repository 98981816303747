import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatePicker, Panel, Snackbar } from '../../../utils/components';
import {
  getPersonalizedXlsx,
  getRecomendacaoDetalhes,
  getSolicitacoes,
  getXlsx,
} from '../action/index';
import ModalDetalhes from './ModalDetahes';

class Solicitacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recomendacao: {},
      key: 0,
      dataInicio: moment().set({ month: 0, date: 1 }),
      dataFim: moment(),
      showSnackbar: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.getSolicitacoes(this.state.dataInicio, this.state.dataFim);
  }

  formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  onChangePeriodo(state, data) {
    this.setState({ [state]: data }, () => {
      this.props.getSolicitacoes(this.state.dataInicio, this.state.dataFim);
    });
  }

  render() {
    const {
      classes,
      recomendacoes,
      loading_recomendacoes,
      loading_xlsx,
      getRecomendacaoDetalhes,
    } = this.props;
    const { recomendacao, dataInicio, dataFim, showModal, key } = this.state;

    return (
      <div className={classes.root}>
        {/* <div>
          <Button
            onClick={() => this.props.history.goBack()}
            size="medium"
            style={{ marginBottom: '1rem', color: COLOR_PRIMARY }}
            color="primary"
          >
            <Icon>arrow_back_ios</Icon>Voltar
          </Button>
        </div> */}
        <Typography className={classes.label} variant="h4" noWrap>
          Recomendações
        </Typography>
        <div className={classes.actions}>
          <section
            style={{
              display: 'flex',
              marginTop: 20,
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Typography className={classes.label} variant="h6" noWrap>
                Filtrar por periodo
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <DatePicker
                  label="De"
                  style={{ marginRight: 15 }}
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataInicio}
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={date => this.onChangePeriodo('dataInicio', date)}
                />

                <DatePicker
                  label="Até"
                  withKeyboard
                  className={classes.dataPicker}
                  value={dataFim}
                  format="DD/MM/YYYY"
                  onChange={date => this.onChangePeriodo('dataFim', date)}
                />
              </div>
            </div>
          </section>
          <div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              to="/recomendacoes-configuracao"
            >
              Configuraçōes
            </Button>
          </div>
        </div>
        <div style={{ marginBottom: 15 }}>
          <Button
            style={{
              borderRadius: 10,
              marginRight: 15,
              '&:hover': { borderRadius: 10 },
            }}
            color="inherit"
            size="large"
            className={key === 0 && classes.activeButton}
            onClick={() => this.setState({ key: 0 })}
          >
            Gerais
          </Button>
          {/* <Button
            style={{
              borderRadius: 10,
              '&:hover': { borderRadius: 10 },
            }}
            color="inherit"
            size="large"
            className={key === 1 && classes.activeButton}
            onClick={() => this.setState({ key: 1 })}
          >
            Personalizada
          </Button> */}
        </div>

        <Divider className={classes.divider} />
        <div className={classes.content}></div>

        {loading_recomendacoes ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {key === 0 &&
              recomendacoes.gerais &&
              recomendacoes.gerais.map((item, index) => (
                <Panel
                  key={index}
                  title={`${item.id_recomendacao} - ${item.nome_recomendacao}`}
                  noDivider
                  avatar={
                    <Avatar className={classes.avatar}>
                      <DescriptionIcon />
                    </Avatar>
                  }
                  cardActions={
                    <Fragment>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState(
                            { showModal: true, recomendacao: item },
                            () =>
                              getRecomendacaoDetalhes(
                                item.id_recomendacao,
                                item.personalizada
                              )
                          )
                        }
                      >
                        Detalhes
                      </Button>

                      <Button
                        size="small"
                        disabled={loading_xlsx}
                        onClick={() => this.props.getXlsx(item)}
                      >
                        Download xlsx
                      </Button>
                    </Fragment>
                  }
                  width="49%"
                  subheader={`Criação ${this.formatDate(item.data_insert)} `}
                  style={{ marginBottom: 20 }}
                ></Panel>
              ))}
            {key === 1 &&
              recomendacoes.personalizadas &&
              recomendacoes.personalizadas.map((item, index) => (
                <Panel
                  key={index}
                  title={`${item.id_recomendacao} - ${item.nome_recomendacao}`}
                  noDivider
                  avatar={
                    <Avatar className={classes.avatar}>
                      <DescriptionIcon />
                    </Avatar>
                  }
                  cardActions={
                    <Fragment>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState(
                            { showModal: true, recomendacao: item },
                            () =>
                              getRecomendacaoDetalhes(
                                item.id_recomendacao,
                                item.personalizada
                              )
                          )
                        }
                      >
                        Detalhes
                      </Button>

                      <Button
                        size="small"
                        disabled={loading_xlsx}
                        onClick={() => this.props.getPersonalizedXlsx(item)}
                      >
                        Download xlsx
                      </Button>
                    </Fragment>
                  }
                  width="49%"
                  subheader={`Criação ${this.formatDate(item.data_insert)} `}
                  style={{ marginBottom: 20 }}
                ></Panel>
              ))}
          </div>
        )}

        <Snackbar
          variant="success"
          message="Texto copiado"
          open={this.state.showSnackbar}
          onClose={() => this.setState({ showSnackbar: false })}
        />
        {showModal && (
          <ModalDetalhes
            open={showModal}
            recomendacao={recomendacao}
            download={() => this.props.getXlsx(recomendacao)}
            onClose={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flex: 1,
    height: '100%',
    paddingTop: '3rem',
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  label: { marginBottom: theme.spacing(2) },
  filterContent: {},
  content: {
    display: 'flex',
    flexGrow: 1,
    flex: 1,
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: { margin: theme.spacing(1) },
  margin: {
    marginRight: theme.spacing(2),
  },
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: theme.spacing(2),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  input: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderStyle: 'dashed',
    borderColor: '#727272',
    borderWidth: 0.8,
    borderRadius: 5,
    padding: 10,
    color: '#424242',
    fontSize: 14,
  },
});

const mapStateToProps = ({ RecomendacaoReducer }) => ({
  ...RecomendacaoReducer,
});

const mapDispatchToProps = {
  getSolicitacoes,
  getRecomendacaoDetalhes,
  getXlsx,
  getPersonalizedXlsx,
};

const SolicitacoesConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Solicitacoes);

export default withStyles(styles)(SolicitacoesConnect);
