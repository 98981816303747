import React from 'react';
import { connect } from 'react-redux';
import './Loader.css';

const Loader = props =>
  props.isLoading && (
    <div className="containerLoader">
      <div id="loader">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
    </div>
  );
const mapStateToProps = ({ RoutesReducer }) => ({ ...RoutesReducer });

export default connect(
  mapStateToProps,
  null,
)(Loader);
