import { CircularProgress } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { CompanyType } from 'modules/FastModels/reducer';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { isLocalhostWithoutRelese, isReleaseEnvironment } from 'utils/helpers/Constants';

import dsVersionOption from '../../assets/images/dsVersionOption.svg';
import HeaderInfo from './components/HeaderInfo';
import NewPortal from './components/NewPortal';
import OldPortal from './components/OldPortal';
import SegmentModal from './components/SegmentModal';
import { useStyles } from './styles';

export default function VersionOption() {
  const classes = useStyles();
  const history = useHistory();

  //define a data limite para poder escolher a versão
  const deadline = moment('2022-09-07', 'YYYY-MM-DD').format('YYYY-MM-DD');
  const deadlineV2 = moment('2023-04-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
  const [registrationDate, setRegistrationDate] = useState<string | null>(null);
  const [company, setCompany] = useState<CompanyType>();
  const token = useMemo(() => {
    return JSON.parse(localStorage.getItem('token'));
  }, []);

  const decodeToken: JWTBody = jwt_decode(`${JSON.parse(localStorage.getItem('token')!)}`);

  var data = new Date();
  var dia = String(data.getDate()).padStart(2, '0');
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var ano = data.getFullYear();
  var currentData = moment('' + ano + '-' + mes + '-' + dia + '', 'YYYY-MM-DD').format(
    'YYYY-MM-DD'
  );

  const isMiniPreco = useMemo(() => {
    return window.location.hostname.includes('redeminipreco');
  }, []);

  const handleGoToDsMktV3 = useCallback(
    (option: string) => {
      const alias = isLocalhostWithoutRelese
        ? 'https://market-dev.datasales.info'
        : isReleaseEnvironment
        ? 'https://market-rls.datasales.info'
        : 'https://market.datasales.info'; //qa.datasales.info";

      const urlDsV2 = ` ${alias}/sign-as-user/${token}?shortToRedirect=${option}`;

      window.location.assign(urlDsV2);
    },
    [token]
  );

  // Ids que podem acessar a nova versão
  // const allowedIds = [
  //   67, //supermercado
  //   68, //hortifrutti
  //   69, //Padaria
  //   70, //Mercearia
  //   71, //Açougue
  //   76, //Materiais de construção
  //   80, //Restaurante
  //   85, //Lanchonete/Hamburgueria
  //   87, //Pizzaria
  //   88 //Astrologia
  // ];

  useEffect(() => {
    (async () => {
      try {
        const user_jwt: JWTBody = jwt_decode(`${JSON.parse(localStorage.getItem('token')!)}`);

        const response = await axios.get(
          `https://kldlktujc6.execute-api.us-east-1.amazonaws.com/prd/users/${user_jwt.user_id}`,
          { headers: { authorization: token } }
        );

        const { user, company } = response.data;

        user.createdAt == null
          ? setRegistrationDate(moment('2022-09-06', 'YYYY-MM-DD').format('YYYY-MM-DD'))
          : setRegistrationDate(moment(user.createdAt, 'YYYY-MM-DD').format('YYYY-MM-DD'));

        setCompany(company);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [company?.ID_SEGMENT]);

  function handleClick() {
    history.push('/home');
  }

  // const handleRedirect = () => {
  //   window.location.href = `https://old.datasales.info/sign-as-user/${token}`;
  // };

  //importante
  if (company == undefined) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  // if (company.ID_SEGMENT == null) {
  //   return <SegmentModal company={company} />;
  // }

  // ver se o id tem permissão para acessar a nova versão
  // const find = allowedIds.find(allowedId => company.ID_SEGMENT == allowedId);

  //se tem permissão, vai seguir o fluxo
  if (!registrationDate) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (decodeToken.onboard !== 1) {
    handleGoToDsMktV3('initial-settings');
    // ${option}
    //`https://market.datasales.info/sign-as-user/${token}?shortToRedirect=${option}`;
    // const urlDsV3 =
    // false
    // ? `https://market-dev.datasales.info/sign-as-user/${token}?shortToRedirect=initial-settings`
    // : `https://market.datasales.info/sign-as-user/${token}?shortToRedirect=initial-settings`;

    // window.location.href = urlDsV3;

    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  return (
    <>
      {!isMiniPreco &&
      moment(registrationDate).isBefore(deadline) &&
      moment(currentData).isBefore(deadlineV2) ? (
        // find ? (
        <Grid container className={classes.versionOption}>
          <img className={classes.elementImg} src={dsVersionOption} alt="Elemento" />
          <Grid item xs={12} className={classes.cardInfo} sx={{ marginTop: 5, height: 'auto' }}>
            <HeaderInfo />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box container className={classes.cardMainContent}>
              <OldPortal />
              <NewPortal handleClick={handleClick} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        // ) : (
        //   handleRedirect()
        // )
        handleClick()
      )}
    </>
  );
}

