import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  MenuItem,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Box,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
} from '@material-ui/core';
import {
  AddRounded as AddIcon,
  DeleteForeverRounded as DeleteForeverRoundedIcon,
} from '@material-ui/icons';
import DialogTitle from '../../../../utils/components/DialogTitle';
import DatePicker from '../../../../utils/components/DatePicker';
import { Checkbox } from '../../../../utils/components';
import Utils from '../../utils/Utils';
import { ONE_MB_SIZE_IN_BYTES } from '../../../../utils/helpers/Constants';
import { Dropzone } from '../../../../utils/components/Dropzone';
import useStyles from './styles';

const initState = () => ({
  data_inicio: moment(),
  data_final: moment().add(1, 'month'),
  titulo: '',
  descricao: '',
  palavra_chave: '',
  webhook_endereco: '',
  gatilhos: [{ url: '', mensagem: '' }],
  ativo: true,
  transbordo: false,
  webhook: false,
});

const CalendarioDialog = ({
  open,
  onClose,
  onSave,
  calendario,
  instancias,
  instanciaSelected,
  hasTranshipment,
  onAddGatilho,
  onDelGatilho,
  onUpdateGatilho,
  loading,
  loadingGatilhos,
}) => {
  const classes = useStyles(),
    [activeStep, setActiveStep] = useState(0),
    [state, setState] = useState(initState()),
    [expanded, setExpanded] = useState('trigger0'),
    [messageError, setMessageError] = useState({});

  useEffect(() => {
    setActiveStep(0);
    if (!open) {
      setState(initState());
    }
  }, [open]);

  useEffect(() => {
    if (calendario && calendario.id) {
      setState({
        ...calendario,
        webhook: Boolean(calendario.webhook),
        transbordo: Boolean(calendario.transbordo),
        data_inicio: moment.utc(calendario.data_inicio),
        data_final: moment.utc(calendario.data_final),
      });
    } else {
      novoGatilho();
    }
  }, [calendario]);

  const handleGatilhos = (index, attr, value) => {
    const newGatilhos = state.gatilhos;
    newGatilhos[index][attr] = value;
    handleCalendario('gatilhos', [...newGatilhos]);
  };

  const handleCalendario = (attr, value) => {
    setState({ ...state, [attr]: value });
  };

  const getSteps = () => {
    return ['Dados gerais', 'Gatilhos'];
  };

  const prepareCalendario = () => ({
    ...state,
    id_instancia: instanciaSelected,
    data_inicio: Utils.formatData(state.data_inicio, 'YYYY-MM-DD'),
    data_final: Utils.formatData(state.data_final, 'YYYY-MM-DD'),
    gatilhos: state.gatilhos.filter(gatilho => gatilho.url || gatilho.mensagem),
  });

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      const step = prevActiveStep + 1;
      if (step >= getSteps().length) {
        if (validate()) {
          onSave(prepareCalendario());
        }
        return getSteps().length - 1;
      }
      return step;
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      const step = prevActiveStep - 1;
      return step < 0 ? 0 : step;
    });
  };

  const clearErrors = nome => {
    if (nome) {
      if (messageError[nome]) {
        delete messageError[nome];
        setMessageError({ ...messageError });
      }
    } else {
      setMessageError({});
    }
  };

  const validate = () => {
    clearErrors();
    const calendario = prepareCalendario();
    let messages = {};
    // Step 1
    if (!calendario.titulo) {
      messages['titulo'] = 'Informe o título';
    }
    if (!calendario.id_instancia) {
      messages['instancia'] = 'Selecione a instância';
    }
    if (!calendario.palavra_chave) {
      messages['palavra_chave'] = 'Selecione a palavra chave';
    }
    if (calendario.webhook && !calendario.webhook_endereco) {
      messages['webhook_endereco'] = 'Digite um endereço válido';
    }
    // Step 2
    if (activeStep === 1) {
      if (!state.gatilhos.length) {
        messages['gatilhos'] = 'informe ao menos um gatilho';
      } else if (state.gatilhos.length) {
        state.gatilhos.forEach((gatilho, index) => {
          if (!gatilho.url && !gatilho.mensagem) {
            messages[`gatilhos-${index}`] = 'Selecione um arquivo e/ou informe uma mensagem';
          }
        });
      }
    }
    setMessageError({ ...messageError, ...messages });
    return !Object.keys(messages).length;
  };

  const novoGatilho = () => {
    let lastGatilho;

    if (state.gatilhos.length > 0) {
      lastGatilho = state.gatilhos[state.gatilhos.length - 1];
      if ((lastGatilho.url || lastGatilho.mensagem) && calendario.id) {
        if (lastGatilho.id) {
          onUpdateGatilho(lastGatilho);
        } else {
          onAddGatilho({ calendario, gatilho: lastGatilho });
        }
      }
    }
    if ((lastGatilho && (lastGatilho.url || lastGatilho.mensagem)) || state.gatilhos.length === 0) {
      const newGatilhos = [...state.gatilhos, { url: null, mensagem: '' }];
      setExpanded(`trigger${newGatilhos.length - 1}`);
      handleCalendario('gatilhos', newGatilhos);
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                disabled
                fullWidth
                label="Instância"
                variant="outlined"
                value={instanciaSelected}>
                {instancias.map((instancia, index) => (
                  <MenuItem value={instancia.id} key={index}>
                    {instancia.id} - {instancia.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                label="De"
                style={{ marginRight: 15 }}
                withKeyboard
                value={moment.utc(state.data_inicio).format('YYYY-MM-DD')}
                onChange={date => handleCalendario('data_inicio', date)}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label="Até"
                style={{ marginRight: 15 }}
                withKeyboard
                value={moment.utc(state.data_final).format('YYYY-MM-DD')}
                onChange={date => handleCalendario('data_final', date)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Titulo"
                variant="outlined"
                value={state.titulo}
                onChange={e => handleCalendario('titulo', e.target.value)}
                error={!!messageError['titulo']}
                helperText={messageError['titulo']}
                onFocus={() => clearErrors('titulo')}
                onBlur={() => validate()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="nome"
                label="Descrição"
                variant="outlined"
                multiline
                rows="7"
                value={state.descricao}
                onChange={e => handleCalendario('descricao', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Palavra chave"
                value={state.palavra_chave}
                onChange={e => handleCalendario('palavra_chave', e.target.value)}
                error={!!messageError['palavra_chave']}
                helperText={messageError['palavra_chave']}
                onFocus={() => clearErrors('palavra_chave')}
                onBlur={() => validate()}
              />
            </Grid>
            <Grid item xs={3}>
              <Tooltip
                placement="top"
                title={
                  hasTranshipment
                    ? 'A opção de transbordo está desativada pois já existe um gatilho de transbordo ativa.'
                    : 'Abrir um chamado para um agente.'
                }>
                <Checkbox
                  disabled={hasTranshipment}
                  onChange={(_, checked) => handleCalendario('transbordo', checked)}
                  value={state.transbordo}
                  checked={state.transbordo}
                  label="Transbordo humano"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Checkbox
                onChange={(_, checked) => handleCalendario('webhook', checked)}
                value={state.webhook}
                checked={state.webhook}
                label="Webhook"
              />
            </Grid>
            {state.webhook && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Endereço do webhook"
                  value={state.webhook_endereco}
                  onChange={({ target: { value } }) => handleCalendario('webhook_endereco', value)}
                  error={!!messageError['webhook_endereco']}
                  helperText={messageError['webhook_endereco']}
                  onFocus={() => clearErrors('webhook_endereco')}
                  onBlur={() => validate()}
                />
              </Grid>
            )}
          </Grid>
        );
      case 1:
        return (
          <Box width="100%">
            {state.gatilhos.map((gatilho, index) => (
              <Accordion
                square
                classes={{ root: classes.accordion, expanded: null }}
                expanded={expanded === `trigger${index}`}
                onChange={(_, isExpanded) => setExpanded(isExpanded ? `trigger${index}` : false)}>
                <AccordionSummary
                  classes={{
                    root: classes.accordionSummary,
                    content: classes.accordionSummaryContent,
                    expanded: null,
                  }}
                  aria-controls={`trigger${index}-content`}
                  id={`trigger${index}-header`}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Typography>{`#Gatilho ${index + 1}`}</Typography>
                    {index >= 0 && (
                      <IconButton
                        aria-label="remover"
                        onClick={() =>
                          onDelGatilho({
                            trigger_id: gatilho.id,
                            calendar_id: gatilho.id_calendario,
                          })
                        }>
                        <DeleteForeverRoundedIcon />
                      </IconButton>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                  <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                      spacing={2}
                      md={11}>
                      <Grid item xs={12}>
                        <Dropzone
                          readonly={loading}
                          variant="two"
                          filesLimit={1}
                          maxFileSize={ONE_MB_SIZE_IN_BYTES * 50}
                          showFileNames={false}
                          clearOnUnmount={false}
                          showFileNamesInPreview={false}
                          dropzoneParagraphClass={`ds-text`}
                          dropzoneText="Arraste e solte algum arquivo ou clique para adicionar"
                          initialFiles={gatilho.url ? [gatilho.url] : []}
                          acceptedFiles={['image/jpeg', 'image/jpg', 'image/png']}
                          onChange={files => {
                            validate();
                            if (files.length) {
                              handleGatilhos(index, 'url', files[0]);
                            }
                          }}
                          onFocus={() => clearErrors(`gatilhos-${index}`)}
                          onBlur={() => validate()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nome"
                          label="Mensagem"
                          variant="outlined"
                          multiline
                          rows="5"
                          value={gatilho.mensagem}
                          onChange={e => handleGatilhos(index, 'mensagem', e.target.value)}
                          disabled={loading}
                          error={!!messageError[`gatilhos-${index}`]}
                          helperText={messageError[`gatilhos-${index}`]}
                          onFocus={() => clearErrors(`gatilhos-${index}`)}
                          onBlur={() => validate()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        );
      default:
        return <Box minHeight={350}>#Resumo</Box>;
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item>{calendario.id ? 'Atualizar' : 'Nova'} Resposta Automática</Grid>

          <Grid item>
            <Stepper alternativeLabel activeStep={activeStep}>
              {getSteps().map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {activeStep === 1 && (
            <Grid item>
              <Button
                onClick={() => novoGatilho()}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<AddIcon />}>
                Adicionar gatilho
              </Button>
            </Grid>
          )}
          <Grid item>{(loading || loadingGatilhos) && <LinearProgress />}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item>
            <Grid container>{getStepContent(activeStep)}</Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {activeStep > 0 && (
          <Button onClick={() => handleBack()} color="primary" variant="outlined">
            Anterior
          </Button>
        )}
        <Button onClick={() => validate() && handleNext()} color="primary" variant="contained">
          {activeStep === getSteps().length - 1 ? 'Salvar' : 'Próximo'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CalendarioDialog.propTypes = {
  calendario: PropTypes.object,
  instancias: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onAddGatilho: PropTypes.func.isRequired,
  onDelGatilho: PropTypes.func.isRequired,
  onUpdateGatilho: PropTypes.func.isRequired,
  hasTranshipment: PropTypes.bool,
  loading: PropTypes.bool,
};

CalendarioDialog.defaultProps = {
  calendario: {},
  instancias: [],
  hasTranshipment: false,
  open: false,
  loading: false,
  onClose: () => {},
};

export default CalendarioDialog;

























