import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// Function
import { formatBytes } from '../helpers/Format';
import { Celular2 } from '../../../../../utils/helpers/Masks';
import { COLOR_PRIMARY } from '../../../../../utils/helpers/Constants';

// Icons
import ExcelIcon from '../assets/icons/excel.svg';
import WordIcon from '../assets/icons/word.svg';
import PowerPointIcon from '../assets/icons/powerpoint.svg';
import { pdf as PdfIcon, file as FileIcon } from '../assets/icons/otherIcons';

import useDate from '../../../../../hooks/date';

// Styles
import './styles/MessageFile.css';

function MessageFile({
  name,
  item,
  bgColorSelf = COLOR_PRIMARY,
  bgColorNoSelf = '#fff',
}) {
  name = item && item.caption;

  const pdfRef = useRef(),
    { getDate } = useDate(),
    newExtension = String(item.message.split('.').pop()).toUpperCase(),
    [bytes, setBytes] = useState(0),
    [numPages, setNumPages] = useState(0),
    [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (item && item.thumbnail) {
      const info = item.thumbnail.split('|');
      setBytes(info[1]);
      setNumPages(info[0]);
    }
  }, []);

  async function onDownload() {
    const file = await fetch(item.message);
    const fileBlog = await file.blob();
    const fileURL = URL.createObjectURL(fileBlog);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = item.caption;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function fileIcon() {
    switch (newExtension) {
      case 'CSV':
      case 'XLSX':
        return ExcelIcon;
      case 'DOCX':
      case 'DOC':
        return WordIcon;
      case 'PPT':
      case 'PPTX':
      case 'PPTM':
        return PowerPointIcon;
      case 'PDF':
        return PdfIcon;
      default:
        return FileIcon;
    }
  }

  return (
    <Box
      flex="0 0 auto"
      display="flex"
      marginTop="1px"
      marginLeft={item.i_send ? '0px' : '10px'}
      marginBottom="1px"
      marginRight={item.i_send ? '10px' : '0px'}
      paddingLeft={item.i_send ? '20%' : '0px'}
      paddingRight={item.i_send ? '0px' : '20%'}
      justifyContent={item.i_send ? 'flex-end' : 'flex-start'}
      style={{
        opacity: 1,
        transform: 'none',
        transition: item.i_send
          ? 'opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          : 'opacity 257ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 171ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
    >
      <Box
        color={item.i_send ? '#fff' : 'rgba(0, 0, 0, 0.45)'}
        width="22vw"
        boxShadow="0 1px .5px rgba(0, 0, 0, 0.13)"
        padding="3px"
        borderRadius="7.5px"
        bgcolor={item.i_send ? bgColorSelf : bgColorNoSelf}
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
      >
        {item.is_group && !item.i_send && (
          <Typography
            noWrap
            style={{
              fontSize: 11,
              padding: '3px 4px 5px 6px',
            }}
          >
            {`${Celular2(item.participant_phone)} ~${item.participant_name}`}
          </Typography>
        )}
        <Grid onClick={onDownload} container style={{ cursor: 'pointer' }}>
          {newExtension === 'PDF' && (
            <Grid item xs={12}>
              <Box
                style={{
                  backgroundImage: `url(${thumbnail})`,
                  backgroundSize: 'cover',
                  backgroundColor: '#f0f0f0',
                }}
                borderRadius="5px 5px 0px 0px"
                height={100}
              />
              <Box hidden={true}>
                <Document file={{ url: item.message }}>
                  <Page
                    canvasRef={pdfRef}
                    pageNumber={1}
                    onRenderSuccess={() =>
                      setThumbnail(pdfRef.current.toDataURL())
                    }
                  />
                </Document>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              borderRadius={newExtension === 'PDF' ? '0px 0px 5px 5px' : '5px'}
              padding="13px 19px"
              justifyContent="flex-start"
            >
              <img src={fileIcon()} alt={newExtension} width="30px" />
              <Typography
                noWrap
                style={{ fontSize: 14.2, flexGrow: 1, margin: '0 10px' }}
              >
                {name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          marginTop="1px"
          display="flex"
          justifyContent="space-between"
          width="100%"
          // color="rgba(0, 0, 0, 0.45)"
        >
          {newExtension ? (
            <Box width="100%" display="flex" alignItems="center">
              {newExtension === 'PDF' && (
                <Typography className="pages">
                  {numPages} {numPages <= 1 ? 'página' : 'páginas'}
                </Typography>
              )}
              <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
                {newExtension}
              </Typography>
              <Typography className="bytes">{formatBytes(bytes, 1)}</Typography>
            </Box>
          ) : (
            <span />
          )}
          <Typography style={{ fontSize: 11, paddingRight: '3px' }}>
            {getDate('HH:mm', item.data_envio)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

MessageFile.propTypes = {
  name: PropTypes.string.isRequired,
  bytes: PropTypes.number.isRequired,
  file: PropTypes.string.isRequired,
  self: PropTypes.bool,
};

export default MessageFile;
