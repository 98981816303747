import {
  DATASALES_CRM_API,
  AMBIENTE
} from "../../../utils/helpers/Constants";
import Fetch from "../../../utils/helpers/Fetch";
import Alert from "../../../utils/helpers/Alert";

const isLoadingRecomendacao = () => dispatch =>
  dispatch({ type: "LOADING_RECOMENDACAO" });
const isLoadingClube = () => dispatch => dispatch({ type: "LOADING_CLUBE" });

export const getConfigClube = () => dispatch => {
  dispatch(isLoadingClube());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getConfigClube`
  ).then(payload => dispatch({ type: "CONFIG_CLUBE", payload }));
};

export const getRecomendacoes = () => dispatch => {
  dispatch(isLoadingRecomendacao());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/getRecomendacoes`
  ).then(payload => dispatch({ type: "LIST_RECOMENDACOES", payload }));
};

export const saveConfigClube = configClube => async dispatch => {
  dispatch(isLoadingClube());
  const request = {
    ...configClube
  };
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/postConfigClube`,
    request,
    "POST"
  ).then(() => dispatch(getConfigClube()));
};
export const saveRecomendacaoClube = configClube => dispatch => {
  dispatch(isLoadingRecomendacao());

  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecomendacoes`,
    configClube,
    "POST"
  ).then(() => dispatch(getRecomendacoes()));
};

export const updateConfigClube = configClube => async dispatch => {
  dispatch(isLoadingClube());
  // const logo = await SaveS3(configClube.logo);
  // const regulamento = await SaveS3(configClube.regulamento);
  const request = {
    ...configClube
    // logo: logo.location,
    // regulamento: regulamento.location,
  };

  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/putConfigClube`,
    request,
    "PUT"
  ).then(() => dispatch(getConfigClube()));
};

export const putStatusConfigClube = idClube => dispatch => {
  dispatch(isLoadingClube());
  Fetch(
    `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/putStatusConfigClube`,
    { idClube },
    "PUT"
  ).then(() => dispatch(getConfigClube()));
};

export const deleteRecomendacoes = idRecomendacao => dispatch =>
  new Alert().confirm("Deseja deletar mesmo essa recomendação ?", () => {
    dispatch(isLoadingRecomendacao());
    Fetch(
      `${DATASALES_CRM_API}config-p1-${AMBIENTE}/${AMBIENTE}/crm/deleteRecomendacao`,
       {idRecomendacao},
      "PUT"
    ).then(() => dispatch(getRecomendacoes()));
  });
