import { AppBar, Backdrop, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99999,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  },
  appBar: { background: 'transparent' },
  img: {
    height: '80%'
  },
  title: {
    flex: 1
  }
}));

type ImagePreview = {
  open: boolean;
  imageName: string | undefined;
  imageUrl: string | undefined;
  onClose: () => void;
};

export default function ImagePreview({ open, imageName, imageUrl, onClose }: ImagePreview) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="body1">
            {imageName}
          </Typography>
        </Toolbar>
      </AppBar>
      <img className={classes.img} src={imageUrl} alt={imageName} />
    </Backdrop>
  );
}
