import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '70vh',
    height: '100%',
    width: '100%',
    background: props => props.background,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  appBar: {
    backgroundColor: props =>
      props.background || theme.palette.background.paper,
    boxShadow: 'none',
    overflow: 'hidden',
  },
  description: {
    color: props => props.description || theme.palette.text.primary,
  },
  shareButton: {
    color: props => props.description || theme.palette.text.primary,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    width: '100%',
    marginRight: theme.spacing(1),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1.5, 6, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  paper: {
    width: '100%',
    backgroundColor: props => props.card || theme.palette.background.paper,
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.12)',
    padding: theme.spacing(2),
    '&:hover': {
      boxShadow: '2px 4px 8px 0 rgba(0,0,0,0.2)',
    },
  },
  img: {
    borderRadius: 5,
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  productName: {
    color: props => props.productName || theme.palette.text.primary,
  },
  price: {
    fontWeight: 'bold',
    textDecoration: 'line-through',
    color: props => props.productPrice || theme.palette.text.primary,
  },
  discountPrice: {
    fontWeight: 'bold',
    color: props => props.productDiscountPrice || theme.palette.text.primary,
  },
  discountText: {
    fontWeight: 'bold',
    fontSize: 22,
    color: props => props.percent || theme.palette.text.primary,
  },
  typography: {
    padding: theme.spacing(2),
  },
  tab: {
    textTransform: 'none',
    color: props => props.description || theme.palette.text.primary,
  },
}));

export default useStyles;
