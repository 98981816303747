import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CircularProgress,
  Icon,
  Divider,
  CardContent,
  Typography,
  IconButton,
  Popover,
} from '@material-ui/core';
import { COLOR_PRIMARY, BG_PRIMARY } from '../../../../utils/helpers/Constants';

export default function TabloidCard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function showPopover(current) {
    setAnchorEl(current);
  }

  function closePopover() {
    setAnchorEl(null);
  }

  return (
    <Card onClick={props.onClick} style={props.style} className={classes.card}>
      <CardContent className={classes.header}>
        <center>
          <Typography variant={props.titleTypographyProps || 'h6'}>
            {props.title}
          </Typography>
        </center>
        {props.helpButton && (
          <Fragment>
            <IconButton
              onClick={({ currentTarget }) => showPopover(currentTarget)}
              style={{ padding: 0 }}
            >
              <Icon>help_outline</Icon>
            </IconButton>
            <Popover
              id="simple-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <section style={{ padding: '1rem' }}>
                <section
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2vh',
                  }}
                >
                  <strong>{props.title}</strong>
                </section>
                {props.helpContent}
              </section>
            </Popover>
          </Fragment>
        )}
      </CardContent>
      <Divider className={classes.divider} style={{ marginTop: -10 }}></Divider>
      <CardContent className={classes.cardContent}>
        {props.loading ? (
          <CircularProgress
            className={classes.progress}
            color="primary"
            size={40}
          />
        ) : props.count ? (
          <Fragment>
            <div className={classes.cardContent}>
              {props.icon && (
                <Fragment>  
                  {props.buttons.map((item, index) => (
                    <IconButton
                      className={
                        props.variant
                          ? classes[props.variant]
                          : !props.disabled
                          ? classes.avatar
                          : classes.avatarDisabled
                      }
                      style={item.style || {}}
                      onClick={item.onClick}
                    >
                      {item.icon}
                    </IconButton>
                  ))}
                </Fragment>
              )}
            </div>

            {props.subtitle && (
              <p
                style={{
                  marginLeft: '6vw',
                  fontSize: '11pt',
                  color: 'darkgray',
                }}
              >
                {props.subtitle}
              </p>
            )}
          </Fragment>
        ) : (
          props.children
        )}
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    border: '1px solid #e0e0e0',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: BG_PRIMARY,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    size: 30,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cover: {
    width: 151,
  },

  playIcon: {
    height: 38,
    width: 38,
  },
  avatarDisabled: {
    backgroundColor: '#bdbdbd',
    borderRadius: 15,
    padding: theme.spacing(3),
    //marginRight: theme.spacing(2),
    width: 0,
    height: 0,
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
    '&:active': {
      backgroundColor: '#bdbdbd',
    },
    '&:focus': {
      backgroundColor: '#bdbdbd',
    },
    color: '#fafafa'
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 15,
    padding: theme.spacing(3),
    //marginRight: theme.spacing(2),
    width: 0,
    height: 0,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
    },
    '&:active': {
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      backgroundColor: COLOR_PRIMARY,
    },
    color: '#fafafa'
  },
  sucess: {
    backgroundColor: '#4caf50',
    borderRadius: 15,
    padding: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  error: {
    backgroundColor: '#f44336',
    borderRadius: 15,
    padding: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2),
  },
}));
