import React, { Fragment } from 'react';

//Using Components
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ChromePicker } from 'react-color';
import { TextField } from '../../../../utils/components';

//Using Variables
import { COLOR_PRIMARY } from '../../../../utils/helpers/Constants';

// Using Styles
import useStyles from '../styles';

export default function(props) {
  const form = props.form,
    classes = useStyles();
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: '900', color: COLOR_PRIMARY }}
        color="primary"
        variant="h5"
      >
        Cores do formulário
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box
          padding="2rem"
          borderRadius="5px"
          width="55%"
          bgcolor={form.bgColorForm}
        >
          <Typography
            style={{ fontWeight: '900', color: form.fontColorForm }}
            color="primary"
            variant="h5"
          >
            Nome
          </Typography>
          <TextField />
        </Box>
        <Box width="40%">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.heading}>Cor de fundo</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChromePicker
                color={form.bgColorForm}
                onChangeComplete={color =>
                  props.onChange('bgColorForm', color.hex)
                }
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                      position: 'static',
                    },
                  },
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.heading}>Cor da fonte</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChromePicker
                color={form.fontColorForm}
                onChangeComplete={color =>
                  props.onChange('fontColorForm', color.hex)
                }
                styles={{
                  default: {
                    picker: {
                      height: '100%',
                      width: '100%',
                      boxShadow: 'none',
                      position: 'static',
                    },
                  },
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Fragment>
  );
}
