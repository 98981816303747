import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menu: { marginTop: theme.spacing(7), maxWidth: theme.spacing(28) },
  paper: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    width: '100%',
    maxHeight: theme.spacing(50),
    top: '0px !important'
  },
  gridItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'left'
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  btnBase: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    width: '100%',
    justifyContent: 'left'
  },
  typography: { fontSize: theme.typography.subtitle2.fontSize },
  subheader: { lineHeight: 2 }
}));

export default useStyles;

