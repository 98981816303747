import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { Autocomplete, makeValidate, TextField } from 'mui-rff';
import PropTypes from 'prop-types';
// import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../utils/helpers/Constants';
import api from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

// const months = [
//   { label: 'Janeiro', value: 1 },
//   { label: 'Fevereiro', value: 2 },
//   { label: 'Março', value: 3 },
//   { label: 'Abril', value: 4 },
//   { label: 'Maio', value: 5 },
//   { label: 'Junho', value: 6 },
//   { label: 'Julho', value: 7 },
//   { label: 'Agosto', value: 8 },
//   { label: 'Setembro', value: 9 },
//   { label: 'Outubro', value: 10 },
//   { label: 'Novembro', value: 11 },
//   { label: 'Dezembro', value: 12 },
// ];

// const weeks = [
//   { label: 'Semana 1', value: 1 },
//   { label: 'Semana 2', value: 2 },
//   { label: 'Semana 3', value: 3 },
//   { label: 'semana 4', value: 4 },
// ];

const curves = [
  { label: 'A', value: 'a' },
  { label: 'B', value: 'b' },
  { label: 'C', value: 'c' },
];

const elasticity_ranges = [
  { label: 'Inelástico', value: 0 },
  { label: 'Elasticidade media', value: 1 },
  { label: 'Elasticidade alta', value: 2 },
];

const schema = Yup.object().shape({});

function Filter({
  open,
  values,
  onValuesChange,
  onSubmit,
  onCreateFilterOpen,
  onClose,
}) {
  const classes = useStyles();

  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/categories`
        );

        setCategories(response);
      } catch (err) {}
    })();

    (async () => {
      try {
        const response = await api(
          `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/products`
        );

        setProducts(response);
      } catch (err) {}
    })();
  }, []);

  if (!categories || !products) {
    return null;
  }

  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <Toolbar />
          <Grid container spacing={4}>
            <Grid item container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Filtro</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Form
                initialValues={values}
                validate={makeValidate(schema)}
                onSubmit={onSubmit}
                render={({ values, submitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Categorias</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              label="Selecione uma ou mais categorias"
                              name="categories"
                              options={categories}
                              getOptionValue={option => option.id}
                              getOptionLabel={option => option.name}
                              multiple
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">EANS</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              name="eans"
                              placeholder="Digite eans sepados por vírgula"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Códigos internos</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              name="skus"
                              placeholder="Digite os códigos internos sepados por vírgula"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Produtos</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              label="Selecione um ou mais Produtos"
                              name="products"
                              options={products}
                              getOptionValue={option => option.id}
                              getOptionLabel={option => option.name}
                              multiple
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Curva quantidade de vendas
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              name="sales_quantity"
                              options={curves}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Curva valor de vendas
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              name="sales_value"
                              options={curves}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Curva Contribuição em vendas
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              name="contribution_in_sales"
                              options={curves}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Maior preço</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="biggest_price.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="biggest_price.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Menor preço</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="lowest_price.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="lowest_price.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Faixa de Elasticidade
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            label="Selecione uma faixa"
                            name="elasticity_range"
                            options={elasticity_ranges}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            textFieldProps={{ variant: 'outlined' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Cesta Média</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="medium_basket.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="medium_basket.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Pessoas que sempre levam
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_always_take.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_always_take.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Pessoas que estão deixando de levar
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_are_failing_to_take.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_are_failing_to_take.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Pessoas que podem gostar
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_may_like.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="people_who_may_like.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Estoque em dias</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              name="stock_in_days.from"
                              label="De"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="stock_in_days.to"
                              label="Até"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}
                      {/* <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Comprador</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              label="Selecione um comprador"
                              name="buyer"
                              options={autocompleteData}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                              textFieldProps={{ variant: 'outlined' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}
                      {/* <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Lojas</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            label="Selecione uma loja"
                            name="store"
                            options={autocompleteData}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            
                            textFieldProps={{ variant: 'outlined' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Sazonalidade Mêses</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            label="Selecione um ou mais mêses"
                            name="seasonality_months"
                            options={months}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            
                            multiple
                            textFieldProps={{ variant: 'outlined' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Sazonalidade Semana
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            label="Selecione um ou mais semanas"
                            name="seasonality_weeks"
                            options={weeks}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            
                            multiple
                            textFieldProps={{ variant: 'outlined' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                      <Grid item>
                        <Toolbar />
                      </Grid>

                      <Grid
                        className={classes.actions}
                        item
                        container
                        direction="row"
                        spacing={2}
                      >
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<CheckIcon />}
                            // onClick={() => handleSave(values)}
                          >
                            Aplicar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="large"
                            disabled={submitting}
                            startIcon={<SaveIcon />}
                            onClick={() => {
                              onValuesChange(values);
                              onCreateFilterOpen();
                            }}
                          >
                            Salvar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="large"
                            // disabled={submitting}
                            startIcon={<UndoIcon />}
                          >
                            Limpar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Filter;
