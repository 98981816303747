import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import api from '../../../utils/helpers/Fetch';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  QuestionAnswerRounded as QuestionAnswerIcon,
  SearchRounded as SearchIcon,
} from '@material-ui/icons';
import { CustomTable, AutoCompleteLab } from '../../../utils/components';
import { CPF, Celular } from '../../../utils/helpers/Masks';
import useStyles from '../style';

export default function Answers() {
  const classes = useStyles(),
    [loadingCampaigns, setLoadingCampaigns] = useState(false),
    [loadingAnswer, setLoadingAnswer] = useState(false),
    [selectedCampaign, setSelectedCampaign] = useState(null),
    [campaigns, setCampaigns] = useState([]),
    [answers, setAnswers] = useState([]),
    [toPhone, setToPhone] = useState(null);

  async function fetchCampaings() {
    try {
      setLoadingCampaigns(true);
      const { content } = await api(
          `${DATASALES_CRM_API}report-${AMBIENTE}/campaigns/list-with-answers`
        ),
        campaignsWithLabel = content.map(resp => ({
          ...resp,
          label: `${resp.id_campanha} - ${resp.nome_campanha}`,
        }));
      setCampaigns(campaignsWithLabel);
    } catch (err) {
    } finally {
      setLoadingCampaigns(false);
    }
  }

  async function fetchAnswers(id_campanha, phone) {
    try {
      setLoadingAnswer(true);
      const { content } = await api(
        `${DATASALES_CRM_API}report-${AMBIENTE}/answers/list`,
        { id_campanha, phone }
      );
      setAnswers(content);
    } catch (err) {
    } finally {
      setLoadingAnswer(false);
    }
  }

  useEffect(() => {
    fetchCampaings();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              pt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h4" noWrap>
                Respostas
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography className={classes.label} variant="h6" noWrap>
                Filtros
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <AutoCompleteLab
                  disabled={loadingCampaigns}
                  groupLabel="label"
                  label="Campanhas"
                  placeholder="Selecione uma campanha"
                  loading={loadingCampaigns}
                  getOptionSelected={(option, value) =>
                    option.id_campanha === value.id_campanha
                  }
                  value={selectedCampaign}
                  getOptionLabel={option => option.label}
                  onChange={(event, value) => {
                    const id_campanha = value ? value.id_campanha : null;
                    setSelectedCampaign(value);
                    if (Boolean(id_campanha)) {
                      fetchAnswers(id_campanha, toPhone);
                    } else {
                      setAnswers([]);
                    }
                  }}
                  options={campaigns}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Destinatário"
                  placeholder="Digite um número de celular"
                  value={toPhone}
                  onChange={({ target: { value } }) => setToPhone(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            fetchAnswers(
                              selectedCampaign && selectedCampaign.id_campanha,
                              toPhone
                            )
                          }
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} variant="rounded">
                    <QuestionAnswerIcon />
                  </Avatar>
                }
                title="Respostas"
              />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <CustomTable
                  showTitle={false}
                  toolbar={false}
                  data={answers}
                  pageSize={10}
                  isLoading={loadingAnswer}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  columns={[
                    {
                      title: '#ID Envio',
                      field: 'id_campanha_envio',
                      editable: 'never',
                    },
                    {
                      title: 'Nome',
                      field: 'nome_pessoa',
                      editable: 'never',
                    },
                    {
                      title: 'Documento',
                      field: 'documento',
                      editable: 'never',
                      render: row =>
                        row.documento ? CPF(row.documento) : 'Não informado',
                    },
                    {
                      title: 'Destinatário',
                      field: 'destinatario',
                      editable: 'never',
                      render: row =>
                        row.destinatario
                          ? Celular(row.destinatario)
                          : 'Não informado',
                    },
                    { title: 'Resposta', field: 'resposta', editable: 'never' },
                    {
                      title: 'Recebido em',
                      field: 'data_recebimento',
                      render: row =>
                        row.data_recebimento
                          ? moment(
                              row.data_recebimento.replace('Z', '')
                            ).format('DD/MM/YYYY HH:mm')
                          : 'Não informado',
                      editable: 'never',
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
