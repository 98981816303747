import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  titulo: {
    fontSize: '1.3rem !important', 
    color: '#1D1D1D !important',
    fontWeight: '500 !important',
    padding: '0px',
  },
}));