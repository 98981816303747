import React from 'react';
import { useFormikContext } from "formik";
import { useCallback } from "react";
import {ChatBotFileData} from '../../../../../../types/chatbot'
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography"
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useStyles } from "./styles";

export function Rule() {
  const formik = useFormikContext<ChatBotFileData>();
  const styles = useStyles();
  const handleChange = useCallback(
    (_: any, value: string) => {
      formik.setFieldValue("rule", value);
    },
    [formik]
  );
  
  return (
    <Stack spacing={4}>
      <Typography className={styles.titulo}>
        Regra
      </Typography>
      <RadioGroup
        value={formik.values.rule}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={handleChange}
      >
        <FormControlLabel
          value="update"
          control={<Radio color='info'/>}
          label="Substituir valores existentes"
        />
      </RadioGroup>
    </Stack>
  );
}