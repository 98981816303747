import {
  Dialog,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import queryString from 'query-string';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonContainer: {
    position: 'absolute',
    left: 8,
    top: 0,
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function PolotnoEditor({ open, flyerId, offerId, onClose }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const params = queryString.stringify({
    ...(flyerId && { design_id: flyerId }),
    offer_id: offerId,
    access_token: JSON.parse(localStorage.getItem('token')),
    redirect_url: window.location.href,
  });

  function handleLoad() {
    setLoading(false);
  }

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={60} />
        </div>
      )}
      <iframe
        title="editor"
        width="100%"
        height="100%"
        allowfullscreen
        frameborder="0"
        allowTransparency
        onLoad={handleLoad}
        src={`https://editor.datasales.info/fundos?${params}`}
      />
    </Dialog>
  );
}

export default PolotnoEditor;
