/* eslint-disable */
import {
  Button,
  CircularProgress,
  Divider,
  Icon,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  Typography,
  withStyles
} from '@material-ui/core';
import '@webscopeio/react-textarea-autocomplete/style.css';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  AutoComplete,
  Chip,
  Countup,
  CustomTable,
  DatePicker,
  DialogAlert,
  Modal,
  MyButton,
  Panel,
  Select,
  TextField
} from '../../../utils/components';
import { MEDIA } from '../../../utils/helpers/Constants';
import { COLOR_PRIMARY } from '../../../utils/helpers/Constants';
import { MoedaBrasil } from '../../../utils/helpers/Format';
import { formataDinheiro } from '../../../utils/helpers/Functions';
import {
  addProdutosOferta,
  clearPessoa,
  createCabecalhoOferta,
  createDetalheOferta,
  deleteProduto,
  getEditOferta,
  getFamalias,
  getPessoaByCpf,
  getProdutosByFamilia,
  getProdutoSearch,
  onChangeState,
  putEditarDetalheOferta
} from '../actions/index';

const steps = ['Informações de Oferta', 'Produtos', 'Iniciar'];
class NovaOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      nomeOferta: '',
      listaSelecionada: null,
      media: {},
      dataInicio: moment(),
      dataFim: moment(),
      familia: '',
      idFamilia: '',
      nomeProduto: '',
      idProduto: '',
      preco: '',
      porcentagem: '',
      precoDesconto: '',
      pontos: '',
      cashback: '',
      url: '',
      produtos: [],
      familias: [],
      codigoCupom: '',
      valorMinimo: '',
      limiteUso: '',
      valorDesconto: '',
      tipoCupom: '',
      showDialog: false,
      showDeleteProduto: false,
      produto: {},
      cpf: null,
      pesquisa_produto: {
        ean: '',
        id_produto: '',
        resultado: []
      },
      forceUpdate: 1
    };

    this.createCabecalhoOferta = this.createCabecalhoOferta.bind(this);
  }

  componentDidMount() {
    //this.props.getFamalias();
  }

  componentWillUnmount() {
    this.props.clearPessoa();
  }

  createCabecalhoOferta() {
    const { personalizada } = this.props;
    const { nomeOferta, dataInicio, dataFim, media, listaSelecionada } = this.state;
    const objOferta = {
      idList: personalizada ? listaSelecionada.value : null,
      nomeOferta,
      media,
      dataInicio,
      dataFim
    };

    this.props.createCabecalhoOferta(objOferta);
  }
  createDetalheOferta() {
    const { produtos, codigoCupom, valorMinimo, limiteUso, valorDesconto, tipoCupom } = this.state;
    const { personalizada, one2one } = this.props;
    const {
      id_oferta,
      tipo_oferta,
      nome_oferta,
      data_inicio,
      media,
      data_fim,
      id_lista_prospeccao,
      filtro
    } = this.props.oferta;

    if (id_oferta !== null) {
      if (tipo_oferta === 'desconto') {
        const objOferta = {
          idOferta: Array.isArray(id_oferta) ? id_oferta : [id_oferta],
          personalizada: personalizada,
          one2one: one2one ? 1 : null
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipo_oferta === 'cupom') {
        const objOferta = {
          idOferta: Array.isArray(id_oferta) ? id_oferta : [id_oferta],
          nome_oferta,
          media: media.value,
          data_inicio,
          data_fim,
          idList: personalizada ? filtro.value : null,
          tipo_oferta,
          codigoCupom,
          valorMinimo,
          limiteUso,
          valorDesconto,
          tipoCupom,
          tipoOferta: tipo_oferta
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipo_oferta === 'cashback') {
        const objOferta = {
          idOferta: Array.isArray(id_oferta) ? id_oferta : [id_oferta],
          nome_oferta,
          media: media.value,
          data_inicio,
          data_fim,
          idList: personalizada ? filtro.value : null,
          produtos,
          tipoOferta: tipo_oferta
        };

        this.props.createDetalheOferta(objOferta);
      } else if (tipo_oferta === 'pontos') {
        const objOferta = {
          idOferta: Array.isArray(id_oferta) ? id_oferta : [id_oferta],
          nome_oferta,
          media: media.value,
          data_inicio,
          data_fim,
          idList: personalizada ? filtro.value : null,
          produtos,
          tipoOferta: tipo_oferta
        };

        this.props.createDetalheOferta(objOferta);
      }
    }
    this.props.onClose();
  }

  addProdutos() {
    const {
      familia,
      nomeProduto,
      idFamilia,
      idProduto,
      preco,
      porcentagem,
      precoDesconto,
      cashback,
      pontos,
      produtos
    } = this.state;

    const { personalizada, one2one, pessoa } = this.props;
    const { id_oferta, tipo_oferta, filtro } = this.props.oferta;
    {
      if (familia && !nomeProduto) {
        this.setState({ showDialog: true });
      } else if (tipo_oferta === 'desconto') {
        const atual = {
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          preco,
          porcentagem,
          precoDesconto
        };
        this.setState(
          {
            produtos: [],
            familia: '',
            nomeProduto: '',
            preco: '',
            porcentagem: '',
            precoDesconto: ''
          },
          () => {
            const objOferta = {
              idOferta: [id_oferta],
              idList: personalizada ? filtro.value : null,
              produtos: [atual],
              tipoOferta: tipo_oferta,
              personalizada: personalizada,
              one2one: one2one ? 1 : null,
              nome_pessoa: pessoa ? pessoa.nome : null,
              documento: pessoa ? pessoa.documento : null,
              id_pessoa: pessoa ? pessoa.id_pessoa : null
            };

            this.props.addProdutosOferta(objOferta);
          }
        );
      } else if (tipo_oferta === 'cashback') {
        const data = this.state.produtos;
        data.push({
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          preco,
          porcentagem,
          cashback
        });
        this.setState(
          {
            produtos: data,
            familia: '',
            nomeProduto: '',
            preco: '',
            porcentagem: '',
            cashback: ''
          },
          () => {
            () => {
              const objOferta = {
                idOferta: id_oferta,
                idList: personalizada ? filtro.value : null,
                produtos,
                tipoOferta: tipo_oferta
              };

              this.props.addProdutosOferta(objOferta);
            };
          }
        );
      } else if (tipo_oferta === 'pontos') {
        const data = this.state.produtos;
        data.push({
          idFamilia,
          familia,
          idProduto,
          nomeProduto,
          pontos
        });
        this.setState(
          {
            produtos: data,
            familia: '',
            nomeProduto: '',
            pontos: ''
          },
          () => {
            const objOferta = {
              idOferta: id_oferta,
              idList: personalizada ? filtro.value : null,
              produtos,
              tipoOferta: tipo_oferta
            };
            this.props.addProdutosOferta(objOferta);
          }
        );
      }
    }
  }
  addFamilia() {
    const { produtos } = this.props;
    const { personalizada } = this.props;
    const {
      id_oferta,
      tipo_oferta,
      nome_oferta,
      data_inicio,
      media,
      data_fim,
      id_lista_prospeccao,
      filtro
    } = this.props.oferta;

    if (tipo_oferta === 'desconto') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          nomeProduto: prd.label,
          familia: prd.familia,
          preco: prd.preco,
          porcentagem: this.state.porcentagem,
          precoDesconto: this.calculaDescontoWithParams(this.state.porcentagem, prd.preco)
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: id_oferta,
            idList: personalizada ? filtro.value : null,
            produtos: this.state.produtos,
            tipoOferta: tipo_oferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    } else if (tipo_oferta === 'cashback') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          familia: prd.familia,
          nomeProduto: prd.label,
          preco: prd.preco,
          porcentagem: this.state.porcentagem,
          cashback: this.calculaCashbackWithParams(this.state.porcentagem, prd.preco)
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: id_oferta,
            idList: personalizada ? filtro.value : null,
            produtos: this.state.produtos,
            tipoOferta: tipo_oferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    } else if (tipo_oferta === 'pontos') {
      const data = this.state.produtos;
      data.push(
        ...produtos.map(prd => ({
          idFamilia: prd.id_familia,
          idProduto: prd.id_produto,
          familia: prd.familia,
          nomeProduto: prd.label,
          pontos: this.state.pontos
        }))
      );
      this.setState(
        {
          produtos: data,
          familia: ''
        },
        () => {
          const objOferta = {
            idOferta: id_oferta,
            idList: personalizada ? filtro.value : null,
            produtos: this.state.produtos,
            tipoOferta: tipo_oferta
          };
          this.props.addProdutosOferta(objOferta);
        }
      );
    }
  }

  calculaDesconto() {
    const { porcentagem, preco } = this.state;
    if (porcentagem && preco) {
      const preco1 = parseFloat(preco);

      const porcentagem1 = parseFloat(porcentagem);

      const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));
      const total = parseFloat(preco1) - parseFloat(vdesconto);

      this.setState({ precoDesconto: total });
    }
  }

  calculaCashback() {
    const { porcentagem, preco } = this.state;

    if (porcentagem && preco) {
      const preco1 = parseFloat(preco);

      const porcentagem1 = parseFloat(porcentagem);

      const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));

      this.setState({ cashback: vdesconto });
    }
  }

  calculaCashbackWithParams(porcentagem, preco) {
    const preco1 = parseFloat(preco);

    const porcentagem1 = parseFloat(porcentagem);

    return (vdesconto = parseFloat(preco1 * (porcentagem1 / 100)));
  }

  calculaDescontoWithParams(porcentagem, preco) {
    const preco1 = parseFloat(preco);

    const porcentagem1 = parseFloat(porcentagem);

    const vdesconto = parseFloat(preco1 * (porcentagem1 / 100));
    return parseFloat(preco1) - parseFloat(vdesconto);
  }

  header(props, state) {
    const { tipo_oferta } = props.oferta;

    const steps = [
      'Informações de Oferta',
      tipo_oferta === 'cupom' ? 'Informações do Cupom' : 'Produtos',
      'Iniciar'
    ];

    return (
      <Fragment>
        {tipo_oferta ? tipo_oferta.toUpperCase() : ''}
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton disabled onClick={() => this.setState({ activeStep: index })}>
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  renderText() {
    const { idOferta, isLoadingOfertaCab } = this.props;
    if (idOferta === null && isLoadingOfertaCab === false) {
      return 'Salvar';
    } else if (isLoadingOfertaCab === true && idOferta === null) {
      return <CircularProgress size={22}></CircularProgress>;
    } else {
      return 'Proximo';
    }
  }

  footer(props, state) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%'
        }}
      >
        {state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep - 1
              })
            }
            disabled={state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
        {state.activeStep !== 2 && (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: state.activeStep + 1
              })
            }
            disabled={false}
          >
            Proximo
          </Button>
        )}
      </div>
    );
  }

  step1(props, state) {
    const { nome_oferta, data_inicio, data_fim, media, filtro, id_lista_prospeccao } = props.oferta;

    return (
      <section>
        <section style={{ padding: '5rem 20rem', display: 'grid', gap: '2rem' }}>
          <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
            Informações da Oferta
          </Typography>

          <TextField
            label="Nome"
            className="form-control"
            autoComplete="none"
            value={nome_oferta}
            onChange={event => this.props.onChange('nome_oferta', event.target.value)}
          />
          {id_lista_prospeccao && (
            <Select
              disabled
              options={[filtro]}
              label="Filtro"
              onChange={value => this.props.onChange('filtro', value)}
              value={filtro}
            />
          )}
          <Select
            options={MEDIA}
            label="Media"
            onChange={value => this.props.onChange('media', value)}
            value={media}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <section>
              <DatePicker
                dateAndTime
                disableFuture
                label="Início da Oferta"
                onChange={date => this.props.onChange('data_inicio', date)}
                value={data_inicio}
              />
            </section>
            <section>
              <DatePicker
                dateAndTime
                disablePast
                label="Término da Oferta"
                onChange={date => this.props.onChange('data_fim', date)}
                value={data_fim}
              />
            </section>
          </div>
        </section>
      </section>
    );
  }
  step2(props, state) {
    const {
      preco,
      porcentagem,
      precoDesconto,
      pontos,
      cashback,
      codigoCupom,
      valorMinimo,
      limiteUso,
      valorDesconto,
      tipoCupom,
      cpf
    } = state;

    const { tipo_oferta } = props.oferta;

    const { isLoadingProdutosLista, ofertaDetalhe, isLoadingEdit, personalizada, one2one } = props;

    const columns = [
      {
        field: 'url',
        title: 'Produto',
        editable: 'never',
        render: rowData => (
          <img
            src={rowData.url}
            crossOrigin="anonymous"
            alt=""
            width={50}
            height={50}
            style={{ borderRadius: 10 }}
          />
        )
      },
      { title: 'id', field: 'idProduto', editable: 'never' },
      { title: 'Ean', field: 'SEQEAN', editable: 'never' },
      { title: 'Nome do Produto', field: 'nomeProduto', editable: 'never' },
      { title: 'Familia', field: 'familia', editable: 'never' },
      {
        title: 'Preço',
        field: 'preco',
        hidden: tipo_oferta === 'pontos' ? true : false,
        render: rowData => (
          <Countup
            start={0}
            end={rowData.preco}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        )
      },
      {
        title: 'Porcentagem',
        field: 'porcentagem',
        hidden: tipo_oferta === 'pontos' ? true : false,
        render: rowData => `${rowData.porcentagem || 0}%`
      },
      {
        title: 'Preço dois',
        field: 'precoDesconto',
        hidden: tipo_oferta === 'cashback' || tipo_oferta === 'pontos' ? true : false,
        render: rowData => (
          <Countup
            start={0}
            end={rowData.precoDesconto}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        )
      },
      {
        title: 'Cashback',
        field: 'cashback',
        render: rowData => (
          <Countup
            start={0}
            end={rowData.cashback}
            duration={5}
            separator="."
            decimal=","
            decimals={2}
            prefix="R$ "
          />
        ),
        hidden: tipo_oferta === 'desconto' || tipo_oferta === 'pontos' ? true : false
      },
      {
        title: 'Pontos',
        field: 'pontos',
        hidden: tipo_oferta === 'desconto' || tipo_oferta === 'cashback' ? true : false
      }
    ];
    (personalizada || one2one) &&
      columns.push(
        { title: 'Cliente', field: 'nome', editable: 'never' },
        { title: 'Documento', field: 'documento', editable: 'never' }
      );

    return tipo_oferta === 'cupom' ? (
      <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
        <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
          Cupom de Desconto
        </Typography>
        <TextField
          label="Código do Cupom"
          className="form-control"
          onChange={({ target }) => this.setState({ codigoCupom: target.value })}
          value={codigoCupom}
        />
        <TextField
          type="number"
          label="Valor mínimo de uso"
          className="form-control"
          onChange={({ target }) => this.setState({ valorMinimo: target.value })}
          value={formataDinheiro(valorMinimo)}
        />
        <TextField
          type="number"
          label="Limite de Utilização do Cupom"
          className="form-control"
          onChange={({ target }) => this.setState({ limiteUso: target.value })}
          value={limiteUso}
        />
        <TextField
          type="number"
          label="Valor do Desconto"
          className="form-control"
          onChange={({ target }) => this.setState({ valorDesconto: target.value })}
          value={formataDinheiro(valorDesconto)}
        />
        <section>
          <Chip
            active={tipoCupom === 'R$'}
            label="Reais (R$)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: 'R$' });
            }}
          />
          <Chip
            active={tipoCupom === '%'}
            label="Porcentagem (%)"
            clickable
            onClick={() => {
              this.setState({ tipoCupom: '%' });
            }}
          />
        </section>
      </section>
    ) : (
      <section
        style={{
          padding: '2rem 1rem 5rem 1rem',
          height: '100%'
        }}
      >
        {this.props.one2one && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
              marginTop: 24,
              marginBottom: 24
            }}
          >
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="CPF"
                value={cpf}
                shrink={true}
                disabled={false}
                onChange={e => this.setState({ cpf: e.target.value })}
              ></TextField>
            </div>
            <div>
              <Button
                size="large"
                variant="text"
                color="primary"
                disabled={cpf ? cpf.length === 0 : true}
                onClick={() => this.props.getPessoaByCpf(cpf)}
              >
                Buscar
              </Button>
            </div>
            <div style={{ marginLeft: 24 }}>
              {this.props.isLoadingPessoa ? (
                <span>Carregando...</span>
              ) : (
                <span>{this.props.pessoa && this.props.pessoa.nome}</span>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 20
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="EAN"
                shrink={true}
                onChange={e =>
                  this.setState({
                    pesquisa_produto: {
                      ...this.state.pesquisa_produto,
                      ean: e.target.value
                    }
                  })
                }
                value={this.state.pesquisa_produto.ean}
              />
            </div>
            <div style={{ width: '25%', marginRight: 10 }}>
              <TextField
                label="Id Produto"
                shrink={true}
                onChange={e =>
                  this.setState({
                    pesquisa_produto: {
                      ...this.state.pesquisa_produto,
                      id_produto: e.target.value
                    }
                  })
                }
                value={this.state.pesquisa_produto.id_produto}
              />
            </div>
            <div>
              <Button
                size="large"
                variant="text"
                color="primary"
                disabled={
                  this.state.pesquisa_produto.ean.trim() === '' &&
                  this.state.pesquisa_produto.id_produto.trim() === ''
                }
                onClick={() =>
                  this.props
                    .getProdutoSearch({
                      ean: this.state.pesquisa_produto.ean,
                      id: this.state.pesquisa_produto.id_produto
                    })
                    .then(() => {
                      this.setState({
                        nomeProduto: this.props.produto_search[0].PRODUTO,
                        preco: this.props.produto_search[0].PRECO,
                        idProduto: this.props.produto_search[0].SEQPRODUTO
                      });
                    })
                }
              >
                Buscar
              </Button>
            </div>
            <div
              style={{
                marginLeft: 24
              }}
            >
              {this.props.isLoadingProdutoSearch ? (
                <span>Carregando...</span>
              ) : (
                <span>
                  {this.props.produto_search.length > 0 && this.props.produto_search[0].PRODUTO}
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '75%',
              justifyContent: 'flex-start',
              marginTop: 24,
              marginBottom: 24
            }}
          >
            {tipo_oferta === 'pontos' ? (
              <div style={{ width: '11%', marginRight: 10 }}>
                <TextField
                  label="Pontos"
                  value={pontos}
                  disabled={this.props.produto_search.length === 0}
                  shrink={true}
                  onChange={e =>
                    this.setState({ pontos: e.target.value }, () => {
                      this.calculaDesconto();
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress></CircularProgress>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            ) : (
              <Fragment>
                <div style={{ width: '22%', marginRight: 10 }}>
                  <TextField
                    label="Preço"
                    value={preco}
                    shrink={true}
                    disabled={this.props.produto_search.length === 0}
                    onChange={e => this.setState({ preco: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>
                            <Icon>attach_money</Icon>
                          </div>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div style={{ width: '22%', marginRight: 10 }}>
                  <TextField
                    label="Porcentagem"
                    value={porcentagem}
                    disabled={this.props.produto_search.length === 0}
                    shrink={true}
                    onChange={e =>
                      this.setState({ porcentagem: e.target.value }, () =>
                        tipo_oferta === 'cashback' ? this.calculaCashback() : this.calculaDesconto()
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div>
                            <strong style={{ fontSize: 18 }}>%</strong>
                          </div>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div style={{ width: '22%', marginRight: 10 }}>
                  {tipo_oferta === 'cashback' ? (
                    <TextField
                      label="Cashback"
                      value={cashback ? MoedaBrasil(cashback) : cashback}
                      shrink={true}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div>
                              <Icon>attach_money</Icon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  ) : (
                    <TextField
                      label="Preço com desconto"
                      value={precoDesconto ? MoedaBrasil(precoDesconto) : precoDesconto}
                      shrink={true}
                      disable
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div>
                              <Icon>attach_money</Icon>
                            </div>
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          <div style={{ width: '11%' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ backgroundColor: COLOR_PRIMARY, marginRight: 15 }}
              disabled={false}
              onClick={() => this.addProdutos()}
            >
              Adicionar
            </Button>
          </div>
        </div>
        <CustomTable
          title=""
          paging
          isLoading={isLoadingProdutosLista || isLoadingEdit}
          pagesSize={5}
          editable={{
            onRowUpdate: (newData, oldData, index) =>
              new Promise((resolve, reject) => {
                this.props
                  .putEditarDetalheOferta({
                    oferta: {
                      preco_original: newData.preco,
                      preco_desconto: newData.precoDesconto,
                      preco_dois: newData.precoDesconto,
                      porcentagem: newData.porcentagem,
                      alterado_preco_original: oldData.preco,
                      alterado_preco_desconto: oldData.precoDesconto,
                      alterado_preco_dois: oldData.precoDesconto,
                      alterado_porcentagem: oldData.porcentagem
                    },
                    personalizada: this.props.personalizada,
                    one2one: this.props.one2one,
                    id_oferta_detalhe: newData.id_oferta_detalhe || 0,
                    id_oferta_personalizada: newData.id_oferta_personalizada || 0,
                    id_oferta: newData.id_oferta
                  })
                  .then(() => {
                    this.props.getEditOferta(newData.id_oferta);
                    resolve();
                  });
              })
          }}
          actions={[
            {
              icon: () => <Icon color="error">delete</Icon>,
              tooltip: 'Excluir',
              onClick: (event, rowData) =>
                this.setState({
                  showDeleteProduto: true,
                  produto: {
                    id_oferta: rowData.id_oferta,
                    id_oferta_detalhe: rowData.id_oferta_detalhe,
                    id_oferta_personalizada: rowData.id_oferta_personalizada,
                    id_produto: rowData.idProduto
                  }
                })
            }
          ]}
          columns={columns}
          data={ofertaDetalhe}
        />
      </section>
    );
  }
  step3(props, state) {
    const { nome_oferta, data_inicio, data_fim } = props.oferta;
    return (
      <section>
        <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
          <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
            Sobre a Oferta
          </Typography>
          <section style={{ padding: '0 7rem' }}>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Nome da Oferta</strong>
              </p>
              <span>{nome_oferta}</span>
            </section>
            <section style={{ paddingBottom: '2rem' }}>
              <p>
                <strong>Data oferta</strong>
              </p>
              <span>{`De ${moment(data_inicio).format('DD/MM/YYYY HH:mm')} Até ${moment(
                data_fim
              ).format('DD/MM/YYYY HH:mm')}`}</span>
            </section>
          </section>
        </section>
        <Divider />
        <section style={{ padding: '2rem 15rem', display: 'grid', gap: '2rem' }}>
          <Typography style={{ fontWeight: '900' }} color="primary" variant="h5">
            Tudo Pronto ?
          </Typography>
          <section style={{ textAlign: 'center' }}>
            <MyButton
              onClick={() =>
                this.props.deleteProduto({
                  id_oferta: this.props.oferta.id_oferta,
                  ofertaCabecalho: true
                })
              }
              size="large"
              color="secondary"
              style={{ marginRight: 40 }}
            >
              <Icon>cancel</Icon>&nbsp;Desativar
            </MyButton>
            <MyButton onClick={() => this.createDetalheOferta()} size="large">
              <Icon>send</Icon>&nbsp;Iniciar
            </MyButton>
          </section>
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { onClose } = this.props;

    const { activeStep, showDialog, showDeleteProduto } = this.state;

    return (
      <Fragment>
        <Modal
          size={activeStep === 1 ? 'xl' : 'lg'}
          showModal={this.props.open}
          onClose={onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>

        <DialogAlert
          open={showDialog}
          title="Deseja dar desconto em todos produtos dessa familia?"
          onClick={() => this.setState({ showDialog: false }, () => this.addFamilia())}
          onClose={() => this.setState({ showDialog: false })}
        />

        <DialogAlert
          open={showDeleteProduto}
          title="Deseja remover este produto?"
          onClick={() =>
            this.setState({ showDeleteProduto: false }, () =>
              this.props.deleteProduto(this.state.produto)
            )
          }
          onClose={() => this.setState({ showDeleteProduto: false, produto: {} })}
        />
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5)
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3)
  },
  label: { marginBottom: theme.spacing(2) }
});

const mapStateToProps = ({ OfertasReducer, CampaignReducer, InsightsReducer }) => ({
  ...OfertasReducer,
  ...CampaignReducer,
  ...InsightsReducer
});
const mapDispatchToProps = {
  onChangeState,
  getFamalias,
  getProdutosByFamilia,
  createCabecalhoOferta,
  createDetalheOferta,
  addProdutosOferta,
  deleteProduto,
  getPessoaByCpf,
  clearPessoa,
  getProdutoSearch,
  putEditarDetalheOferta,
  getEditOferta
};

const NovaOfertaConnect = connect(mapStateToProps, mapDispatchToProps)(NovaOferta);

export default withStyles(styles)(NovaOfertaConnect);
