/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  Stepper,
  Step,
  StepButton,
  Typography,
  withStyles,
  CircularProgress,
  Button,
  IconButton,
  Popover,
  Icon,
  Select,
  MenuItem,
} from '@material-ui/core';
import { CompactPicker } from 'react-color';
import {
  ImageCard,
  Modal,
  TextField,
  DialogAlert,
} from '../../../../utils/components';
import {
  putOfertaFisico,
  createTemplatePersonalizado,
  v2_putOfertaFisicoMaior,
} from '../../action';
import '@webscopeio/react-textarea-autocomplete/style.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AMBIENTE,
  DATASALES_CRM_API,
} from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

class GeneratePixelixeAplicarCategorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      template: 'Encarte-A4-12P-CPD-inicio_do_inverno-001',
      qtde_produtos: 12,
      formato: '',
      ocasiao: '',
      id_template: null,
      nome_modelo: null,
      json: null,
      json_parse: null,
      variaveis: [],
      forceUpdate: {},
      stepTemplate: 1,
      anchorEl: null,
      stores: [],
      store: {},
      colorText: { r: 0, g: 0, b: 0, a: 1 },
      showDesativarModelo: false,
      etiqueta: null,
      font_size: null,
    };
  }

  async componentDidMount() {}

  showPopover = current => {
    this.setState({ anchorEl: current });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  header(props, state) {
    const steps = [
      'Formato',
      'Qtde Produtos',
      'Ocasião',
      'Template',
      'Variáveis',
      'Páginas',
    ];

    return (
      <Fragment>
        Gerar Arte - {this.props.tabloid.qtde_produtos_lista} Produtos no Total
        <div
          style={{
            width: '100%',
            marginTop: 40,
            marginBottom: -40,
          }}
        >
          <Stepper nonLinear activeStep={state.activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton
                  disabled
                  onClick={() => this.setState({ activeStep: index })}
                >
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
      </Fragment>
    );
  }

  footer(props, state) {
    const { activeStep } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 5,
          width: '100%',
        }}
      >
        {this.state.activeStep !== 0 ? (
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() =>
              this.setState({
                activeStep: this.state.activeStep - 1,
              })
            }
            disabled={this.state.activeStep === 0 ? true : false}
          >
            Voltar
          </Button>
        ) : (
          <div />
        )}
      </div>
    );
  }

  step1(props, state) {
    let formato = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      formato[curr['formato']] = 1;
    }, {});

    return (
      <section>
        <section style={{ padding: '2rem 4rem' }}>
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Formato
          </Typography>
        </section>
        <Divider />
        <section
          style={{
            padding: '2rem 4rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {Object.keys(formato).map((value, index) => (
            <ImageCard
              descricao={value}
              style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  formato: value,
                });
              }}
            />
          ))}
        </section>
      </section>
    );
  }

  step2(props, state) {
    let qtde_produtos = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      curr['formato'] === this.state.formato &&
        (qtde_produtos[curr['qtde_produtos']] = 1);
    }, {});

    return (
      <section>
        <section style={{ padding: '2rem 4rem' }}>
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Modelo (Qtde de Produtos)
          </Typography>
        </section>
        <Divider />
        <section
          style={{
            padding: '2rem 4rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {Object.keys(qtde_produtos).map((value, index) => (
            <ImageCard
              descricao={value}
              style={{ backgroundColor: '#E0E0E0', marginRight: 20 }}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  qtde_produtos: value,
                });
              }}
            />
          ))}
        </section>
      </section>
    );
  }

  step3(props, state) {
    let ocasiao = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      curr['formato'] === this.state.formato &&
        curr['qtde_produtos'].toString() ===
          this.state.qtde_produtos.toString() &&
        (ocasiao[curr['ocasiao']] = curr['miniatura']);
    }, {});

    return (
      <section>
        <section style={{ padding: '2rem 4rem' }}>
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha uma Ocasião
          </Typography>
        </section>
        <Divider />
        <section
          style={{
            padding: '2rem 4rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Object.keys(ocasiao).map((value, index) => (
            <ImageCard
              descricao={value}
              height={
                [
                  'facebook',
                  'instagram',
                  'whatsapp',
                  'redes sociais',
                  'cartaz',
                ].includes(this.props.tipoPixelixe) &&
                ['Post'].includes(this.state.formato) &&
                150
              }
              style={{
                backgroundColor: '#E0E0E0',
                marginRight: 20,
                marginBottom: 20,
              }}
              image={ocasiao[value]}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  ocasiao: value,
                });
              }}
            />
          ))}
        </section>
      </section>
    );
  }

  step4(props, state) {
    let template = {};

    this.props.tabloids_templates_fisico.reduce((acum, curr) => {
      curr['formato'] === this.state.formato &&
        curr['qtde_produtos'].toString() ===
          this.state.qtde_produtos.toString() &&
        curr['ocasiao'] === this.state.ocasiao &&
        (template[curr['nome']] = [
          curr['miniatura'],
          curr['template'],
          curr['id'],
        ]);
    }, {});

    return (
      <section>
        <section style={{ padding: '2rem 4rem' }}>
          <Typography
            style={{ fontWeight: '900' }}
            color="primary"
            variant="h5"
          >
            Escolha um Template
          </Typography>
        </section>
        <Divider />
        <section
          style={{
            padding: '2rem 4rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Object.keys(template).map((value, index) => (
            <ImageCard
              descricao={value}
              height={
                [
                  'facebook',
                  'instagram',
                  'whatsapp',
                  'redes sociais',
                  'cartaz',
                ].includes(this.props.tipoPixelixe) &&
                ['Post'].includes(this.state.formato) &&
                150
              }
              style={{
                backgroundColor: '#E0E0E0',
                marginRight: 20,
                marginBottom: 20,
              }}
              image={template[value][0]}
              onClick={() => {
                this.setState({
                  activeStep: this.state.activeStep + 1,
                  template: template[value][1],
                });
                this.props.tabloids_templates_fisico.reduce((acum, curr) => {
                  if (
                    curr['formato'] === this.state.formato &&
                    curr['qtde_produtos'].toString() ===
                      this.state.qtde_produtos.toString() &&
                    curr['ocasiao'] === this.state.ocasiao &&
                    curr['id'] === template[value][2]
                  ) {
                    this.setState(
                      {
                        id_template: curr['id'],
                        nome_modelo: curr['nome'],
                        json: curr['json'],
                        json_parse: JSON.parse(curr['json']),
                      },
                      () => {
                        this.props.onSave(this.state.json_parse.categorias);
                      }
                    );
                  }
                }, {});
              }}
            />
          ))}
        </section>
      </section>
    );
  }

  renderStep(activeStep, props, state) {
    switch (activeStep) {
      case 0:
        return this.step1(props, state);
      case 1:
        return this.step2(props, state);
      case 2:
        return this.step3(props, state);
      case 3:
        return this.step4(props, state);
      default:
        return this.step1(props, state);
    }
  }

  render() {
    const { activeStep } = this.state;

    return (
      <Fragment>
        <Modal
          showModal={this.props.open}
          size={'lg'}
          onClose={this.props.onClose}
          header={this.header(this.props, this.state)}
          footer={this.footer(this.props, this.state)}
        >
          {this.renderStep(activeStep, this.props, this.state)}
        </Modal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  header: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  label: { marginBottom: theme.spacing(2) },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
});

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer,
});
const mapDispatchToProps = {
  putOfertaFisico,
  createTemplatePersonalizado,
  v2_putOfertaFisicoMaior,
};

const GeneratePixelixeAplicarCategoriasConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePixelixeAplicarCategorias);

export default withStyles(styles)(GeneratePixelixeAplicarCategoriasConnect);
