import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    postText: {
      display: 'table',
      width: 'auto !important',
      fontSize: '14px !important',
      padding: '4px',
      backgroundColor: '#F0F0F0',
      borderRadius: '2px',
      marginBottom: '8px !important'
    }
  })
);
