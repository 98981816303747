import { Notification, NotificationChannel } from '../types/notification';
import { AMBIENTE } from '../utils/helpers/Constants';
import api from './api';
import axios from 'axios';

export const listNotification = async (): Promise<Notification[]> => {
  //const response = await api.get(`notification-${AMBIENTE}/notification`);
    const response = await axios.get(`https://api.datasales.info/notification-${AMBIENTE}/notification`,
    { 
      headers:{
        Authorization: Boolean(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')!) : '',
      }
    })

  return response.data.content;
};

export const listNotificationChannel = async (): Promise<NotificationChannel> => {
  const response = await api.get(`notification-${AMBIENTE}/datasales-client/notification-channel`);

  return response.data.content;
};

export const updateNotificationPermission = async (data: any): Promise<any> => {
  const response = await api.put(
    `notification-${AMBIENTE}/datasales-client/notification/permission`,
    data
  );

  return response.data.content;
};

