import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ImagePreview from 'modules/FastModels/components/ImagePreview';
import { useState } from 'react';

import DeleteIcon from '../../../../../assets/icons/marketing/DeleteIcon.svg';
import { ScheduleItem } from '../ListScheduleImages';
import { useStyles } from './styles';
import { ModalPublicationDates } from '../ModalPublicationDates';
import { styled } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';
import { AutoFixHighOutlined } from '@mui/icons-material';

type CardEditItemProps = {
  name: string;
  image_url: string;
  preview_url: string;
  dateScheduled: Date[];
  description: string;
  offer_id: number;
  cardIndex: number;
  onUpdateDate: (itemIndex: number, newDate: Date, indexDate: number) => void;
  onUpdateDescription: (itemIntex: number, description: string) => void;
  onUpdateDescriptionIA: (itemIntex: number, backgroundId: number) => void;
  onDelete: () => void;
  isPublicationDates: boolean;
  background_id: number;
  messageIsLoading: boolean;
};

type ImageDownloadType = {
  name: string | undefined;
  url: string | undefined;
};

const Img = styled('img')({});

export function CardEditItem({
  background_id,
  offer_id,
  name,
  preview_url,
  dateScheduled,
  image_url,
  description,
  cardIndex,
  onUpdateDate,
  onUpdateDescription,
  onUpdateDescriptionIA,
  onDelete,
  isPublicationDates,
  messageIsLoading
}: CardEditItemProps) {
  const styles = useStyles();
  const [openModalPublicationDates, setOpenModalPublicationDates] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<ImageDownloadType>({
    name: undefined,
    url: undefined
  });

  return (
    <Box className={styles.container}>
      <img
        src={preview_url}
        alt="Ilustração da arte que será agendada"
        className={styles.imageView}
        onClick={() => {
          setPreviewImage({ name: `${offer_id} ${name}`, url: image_url });
          setOpenImagePreview(true);
        }}
      />
      <Box className={styles.container2}>
        <Box className={styles.container3}>
          <Box className={styles.containerAction}>
            <Typography sx={{ color: '#1E1F21', fontWeight: '500' }}>Data programada</Typography>
            <DatePicker
              value={dateScheduled[0]}
              disablePast={true}
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
              className={styles.containerDatePicker}
              onChange={(date, keyboardInput) => {
                if (date) {
                  onUpdateDate(cardIndex, date, 0);
                }
              }}
              renderInput={params => <TextField {...params} />}
              InputAdornmentProps={{ position: 'start' }}
            />
          </Box>

          <Box className={styles.containerAction}>
            <Typography sx={{ color: '#1E1F21', fontWeight: '500' }}>Hora programada</Typography>
            <TimePicker
              value={dateScheduled[0]}
              className={styles.containerDatePicker}
              ampm={false}
              onChange={time => {
                if (time) {
                  onUpdateDate(cardIndex, time, 0);
                }
              }}
              renderInput={params => <TextField {...params} />}
              InputAdornmentProps={{ position: 'start' }}
            />
          </Box>

          <Box className={styles.containerAction}>
            <Button
              startIcon={<Img src={DeleteIcon} />}
              variant="outlined"
              className={[styles.action].join(' ')}
              onClick={onDelete}
            >
              Deletar
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} gap={'1rem'} flexDirection={'column'}>
          <Box display={'flex'} justifyContent="space-between" alignItems="center">
            <TextField
              disabled={messageIsLoading}
              fullWidth
              label="Descrição da postagem"
              className={styles.description}
              value={description ? description : ''}
              onChange={(evt: any) => {
                const value = evt.target.value;
                onUpdateDescription(cardIndex, value);
              }}
            />
            {background_id ? (
              <Tooltip title="Outra sugestão de mensagem" placement="right">
                <IconButton
                  disabled={messageIsLoading}
                  onClick={() => {
                    onUpdateDescriptionIA(cardIndex, background_id);
                  }}
                  color="primary"
                >
                  <AutoFixHighOutlined />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
          {isPublicationDates && (
            <Typography
              color={'#2813AD'}
              display={'flex'}
              alignItems={'center'}
              fontSize={'14px'}
              lineHeight={'20px'}
              fontStyle={'normal'}
              fontWeight={600}
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => setOpenModalPublicationDates(true)}
            >
              Ver todas as datas das Publicações
            </Typography>
          )}
        </Box>
      </Box>
      <ImagePreview
        open={openImagePreview}
        imageName={previewImage?.name}
        imageUrl={previewImage?.url}
        onClose={() => setOpenImagePreview(false)}
      />
      <ModalPublicationDates
        showModal={openModalPublicationDates}
        item={
          {
            offer_id,
            name,
            preview_url,
            dateScheduled,
            image_url
          } as ScheduleItem
        }
        onUpdateDate={(newDate, indexDate) => {
          onUpdateDate(cardIndex, newDate, indexDate);
        }}
        onClose={() => setOpenModalPublicationDates(false)}
      />
    </Box>
  );
}

