import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { LIGHT_GREY } from '../../../../../colors';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiMenu-list': {
      padding: '0 !important'
    }
  },
  paper: {
    border: '1px solid #E2E2E2',
    minWidth: '240px !important',
    'box-shadow': '0px 0px 10px 0px #0000001A !important',
    borderRadius: '8px !important',
    '& .MuiMenu-list': {
      padding: '0 !important'
    }
  },
  font: {
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '22px !important'
  },
  icon: {
    backgroundColor: LIGHT_GREY,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5)
  }
}));

