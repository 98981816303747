import { makeStyles } from '@material-ui/core/styles';
import { COLOR_NEW_PRIMARY, COLOR_PRIMARY } from 'utils/helpers/Constants';

const useStyles = makeStyles(theme => ({
  root: {
    //div geral
    padding: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
    // border: 'solid 1px blue'
  },
  actions: {
    // botão das ações
    padding: '8px 24px 24px 0px',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    // div da tabela
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
    // border: 'solid 1px red'
  },
  button: {
    width: '180px',
    color: 'white',
    background: COLOR_NEW_PRIMARY,
    padding: '8px 16px',
    gap: '10px',
    lineHeight: '22px',
    fontSize: '14px',
    '&:hover': {
      background: COLOR_NEW_PRIMARY,
      opacity: 0.9
    }
  },
  tableRow: {}
}));

export default useStyles;

