import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { TextField } from 'mui-rff';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useAds } from '../../../../../../../../hooks/ads';
import useStyles from './styles';

const getBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

function AdCreative() {
  const classes = useStyles();

  const { user } = useAds();

  const form = useForm();

  const { values } = useFormState();

  const [adCreatives, setAdCreatives] = useState();

  const getAdCreatives = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://graph.facebook.com/v10.0/act_2190352007750439/adimages',
        {
          params: {
            fields: "['id', 'hash', 'url', 'height', 'width']",
            limit: 50,
            access_token: user.access_token,
          },
        }
      );

      const { data } = response.data;

      setAdCreatives(data);
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  useEffect(() => {
    getAdCreatives();
  }, [getAdCreatives]);

  const HandleAddImage = async file => {
    try {
      const base64Data = await getBase64(file);

      const response = await axios.post(
        'https://graph.facebook.com/v10.0/act_606188629965422/adimages',
        {
          access_token: user.access_token,
          bytes: base64Data.split('base64,')[1],
        }
      );

      const { bytes } = response.data.images;

      form.change('adCreative.image', bytes);
    } catch (err) {
      console.log(err);
    } finally {
      getAdCreatives();
    }
  };

  const handleSelectImage = image => {
    form.change('adCreative.image', image);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Nome do anúncio" />
          <CardContent>
            <TextField name="adCreative.name" label="Nome" variant="outlined" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title=" Criativo do anúncio" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="strong">
                  Mídia
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {values.adCreative &&
                values.adCreative.image &&
                values.adCreative.image.url ? (
                  <img
                    className={classes.preview}
                    alt="media-preview"
                    src={values.adCreative.image.url}
                  />
                ) : (
                  <Alert severity="error">
                    Carregue uma imagem para o seu anúncio
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  type="file"
                  id="raised-button-file"
                  style={{ display: 'none' }}
                  onChange={({ target }) => HandleAddImage(target.files[0])} // instead of the default target.value
                />

                <label htmlFor="raised-button-file">
                  <Button
                    startIcon={<ImageOutlinedIcon />}
                    variant="outlined"
                    component="span"
                    size="large"
                  >
                    Carregar
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item container spacing={2}>
                {adCreatives &&
                  adCreatives.map(item => (
                    <Grid
                      item
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleSelectImage(item);
                      }}
                    >
                      <img
                        className={classes.adCreativePreview}
                        alt="adCreative"
                        src={item.url}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="adCreative.link"
                  label="URL do site"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<OpenInNewIcon />}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!values.adCreative.link}
                  onClick={() => {
                    window.open(values.adCreative.link, '__blank');
                  }}
                >
                  acessar url
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="adCreative.message"
                  label="Texto principal"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AdCreative;
