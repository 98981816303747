import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { CNPJ } from 'utils/helpers/Masks';
import * as Yup from 'yup';

import LogoDeliveryVip from '../../../../assets/images/logo.png';
import { API_URL_DEV_BACKOFFICE } from '../../../../utils/helpers/Constants';
import Fetch from '../../../../utils/helpers/Fetch';
import useStyles from './styles';

const schema = Yup.object().shape({
  company_name: Yup.string().required('O Nome da Empresa é obrigatório'),
  cnpj: Yup.string().required('O CNPJ é obrigatório'),
  company_name_social: Yup.string().required('A Razão Social é obrigatória'),
  user_name: Yup.string().required('A nome é obrigatória'),
  email: Yup.string().required('O email é obrigatório'),
  document: Yup.string().required('O CPF é obrigatório'),
  phone: Yup.string().required('O Telefone é obrigatório'),
  address: Yup.object().shape({
    postalCode: Yup.string().required('O CEP é obrigatório'),
    street: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.string().required('O número é obrigatório'),
    district: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    complement: Yup.string()
  })
});

export default function NewStore() {
  const [user] = useState(JSON.parse(localStorage.getItem('name')!));
  const [email] = useState(JSON.parse(localStorage.getItem('email')!));
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')!));

  const classes = useStyles();

  const history = useHistory();

  async function onSubmit(data: any) {
    try {
      const payload = {
        companyId: company.id,
        merchantName: data.company_name,
        identifier: data.cnpj,
        email: data.email,
        userName: data.user_name,
        phone: data.phone,
        address: data.address
      };

      const result = await Fetch(`${API_URL_DEV_BACKOFFICE}new/store`, payload, 'POST');

      console.log({
        ...company,
        deliveryVip: { merchantId: result.result.merchantId, memberId: result.result.memberId }
      });

      localStorage.setItem(
        'company',
        JSON.stringify({
          ...company,
          deliveryVip: {
            merchantId: result.result.merchantId,
            memberId: result.result.memberId
          }
        })
      );
      setCompany({
        ...company,
        deliveryVip: {
          merchantId: result.result.merchantId,
          memberId: result.result.memberId
        }
      });

      history.push('/home');
    } catch (err) {
      console.error(err);
    }
  }
  console.log(company);

  useEffect(() => {
    console.log(company.deliveryVip?.merchantId);
    if (company.deliveryVip !== null) {
      if (company?.deliveryVip?.merchantId) {
        console.log('teste');
        history.push('/home');
      }
    }
  }, [company]);

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start'
        }}
      >
        <Grid container spacing={6}>
          <Grid item container alignItems="center" justifyContent="center" xs={6} style={{ position: 'fixed' }}>
            <Grid item xs={8} spacing={6}>
              <img
                style={{
                  cursor: 'pointer'
                }}
                alt={`Logo Delivery Vip`}
                src={LogoDeliveryVip}
              />
              <Grid item style={{ marginTop: 16 }}>
                <Typography
                  variant="h5"
                  style={{ color: '#2813AD', marginTop: 16, fontWeight: 'bold' }}
                >
                  Plataforma de Delivery Inteligente para negócios locais.
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Typography variant="p" style={{ color: '#616161', marginTop: 16 }}>
                  Sua marca, Seu aplicativo, Sua plataforma de delivery própria, Sem custos de
                  comissão e com uma experiência de compra incrível para conquistar clientes e
                  lucrar mais.
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Typography
                  variant="h7"
                  style={{
                    textDecorationLine: 'underline',
                    textAlign: 'left',
                    marginTop: 16,
                    fontWeight: 'bold'
                  }}
                >
                  Preencha o cadastro ao lado para concluir a compra
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: 16 }}>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      background: '#2813AD',
                      color: '#fff',
                      height: '40px'
                    }}
                    size="large"
                    onClick={() => (window.location.href = 'https://deliveryvip.com.br/')}
                  >
                    Acessar site do Delivery Vip
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ backgroundColor: '#F2F3F5' }}>
          <Grid item xs={12}>
            <Form
              initialValues={{
                company_name: company.name,
                company_name_social: company.name,
                cnpj: CNPJ(company.cnpj),
                email: email,
                address: {
                  postalCode: '',
                  street: '',
                  number: '',
                  district: '',
                  city: '',
                  state: ''
                },
                user_name: user.name,
                document: CNPJ(company.cnpj),
                phone: ''
              }}
              onSubmit={onSubmit}
              validate={makeValidate(schema)}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid item container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item container alignItems="center" justifyContent="space-between" xs={8}>
                      <Grid item>
                        <Typography variant="h6">Dados da Empresa</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="company_name"
                        fullWidth
                        variant="outlined"
                        label="Nome da empresa"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="company_name_social"
                        fullWidth
                        variant="outlined"
                        label="Razão Social"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="cnpj"
                        fullWidth
                        variant="outlined"
                        label="CNPJ"
                        disabled={company.cnpj}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="email"
                        fullWidth
                        variant="outlined"
                        label="E-mail"
                        disabled
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item container alignItems="center" justify="space-between" xs={8}>
                      <Grid item>
                        <Typography variant="h6">Endereço da Empresa</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.postalCode"
                        fullWidth
                        variant="outlined"
                        label="CEP"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.street"
                        fullWidth
                        variant="outlined"
                        label="Rua"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.number"
                        fullWidth
                        variant="outlined"
                        label="Número"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.district"
                        fullWidth
                        variant="outlined"
                        label="Bairro"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.city"
                        fullWidth
                        variant="outlined"
                        label="Cidade"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.state"
                        fullWidth
                        variant="outlined"
                        label="Estado"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="address.complement"
                        fullWidth
                        variant="outlined"
                        label="Complemento"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>

                    <Grid item container alignItems="center" justify="space-between" xs={8}>
                      <Grid item>
                        <Typography variant="h6">Responsável legal</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="user_name"
                        fullWidth
                        variant="outlined"
                        label="Nome"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="document"
                        fullWidth
                        variant="outlined"
                        label="CPF"
                        disabled={company.cnpj}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        name="phone"
                        fullWidth
                        variant="outlined"
                        label="Telefone"
                        style={{ backgroundColor: '#fff' }}
                      />
                    </Grid>

                    <Grid item container alignItems="center" justify="space-between" xs={8}>
                      <Grid item>
                        <Typography variant="h9">
                          O Delivery Vip é um parceiro oficial da Datasales. Ao ativar o cadastro,
                          será cobrado o valor adicional de R$159,90 por mês a partir da sua próxima
                          fatura. Se tiver alguma dúvida, fale com nosso time de atendimento.
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item container alignItems="center" justifyContent="center">
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            background: '#2813AD',
                            color: '#fff',
                            height: '40px'
                          }}
                          size="large"
                          disabled={submitting}
                        >
                          Cadastrar loja
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

