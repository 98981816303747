import {
  CircularProgress,
} from '@mui/material';
import ListaOfertasEncarte from './components/ListaOfertasEncarte';
import { CreateTabloid, EditTabloid } from './components';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Route, Redirect, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import _Alert from '../../utils/helpers/Alert';
import { AMBIENTE, DATASALES_CRM_API } from '../../utils/helpers/Constants';
import Fetch from '../../utils/helpers/Fetch';
import { toBase64 } from '../../utils/helpers/Functions';

function Tabloid() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [tabloid, setTabloid] = useState(null);
  const [countSearch, setCountSearch] = useState(0);
  const [handleEditTabloidCount, setHandleEditTabloidCount] = useState(0);
  const [openStudio, setOpenStudio] = useState(false);
  const [open, setOpen] = useState({
    createTabloid: false,
    createPlanejamento: false,
    createOptions: false,
    editTabloid: false,
    accessLog: false,
    editModelosOptions: false,
    deleteTabloid: false,
    duplicateTabloid: false
  });

  const tabloidIsEmpty =
    tabloid &&
    Object.keys(tabloid).length === 0 &&
    Object.getPrototypeOf(tabloid) === Object.prototype;

  async function getTabloids(data) {
    const filtroDataInicio = moment().subtract(1, 'month').toDate();
    const filtroDataFim = moment().toDate();
    data = {
      ...data,
      dataInicio: (data && data.dataInicio) || moment(filtroDataInicio).format('YYYY-MM-DD'),
      dataFim: (data && data.dataFim) || moment(filtroDataFim).add('1', 'days').format('YYYY-MM-DD')
    };
    if (params?.id) {
      data = {
        ...data,
        id_tabloide: params?.id
      };
    }
    try {
      setLoading(true);
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        data
      );
      setLoading(false);
      if (params?.id) {
        //testar retorno nulo
        if (response.length > 0) {
          setTabloid(response[0]);
          setOpenStudio(true);
        } else {
          setTabloid({});
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  async function handleCreateTabloid(data, banners) {
    console.log('data::>',data);
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
        data,
        'POST',
        null,
        null,
        false
      );
      const { tabloide } = response;
      // if (banners && banners.length > 0) {
      //   await asyncForEach(
      //     banners,
      //     async banner => await handleSubmitBanner(id, banner)
      //   );
      // }

      //getTabloids();
      enqueueSnackbar(response.title, {
        variant: 'success'
      });
      history.push(
        '/estudio/' +
          tabloide.id_tabloide_digital +
          (open.createArtFormat ? `?createArtFormat=${open.createArtFormat}` : '') +
          (open.createFormatCanva ? `?createFormatCanva=${open.createFormatCanva}` : '')
      );
    } catch (err) {
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
      console.log(err);
      //getTabloids();
    }
  }

  async function handleSubmitBanner(tabloidId, banner) {
    try {
      const { position, device, href, file } = banner;
      const base64 = await toBase64(file);
      return await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/banner`,
        {
          tabloidId,
          position,
          device,
          href,
          ...base64
        },
        'POST'
      );
    } catch (err) {
      console.log(err);
    }
  };

  async function handleEditTabloid(data, banners, posExec) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      setOpen(prevState => ({ ...prevState, editTabloid: false }));
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
        data,
        'PUT',
        null,
        null,
        false
      );
      if (banners && banners.length > 0) {
        await Promise.all(banners.map(banner => handleSubmitBanner(data.uuid, banner)));
      }
      setTabloid({
        ...tabloid,
        ...data
      });
      enqueueSnackbar(response.title, {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    } finally {
      setHandleEditTabloidCount(handleEditTabloidCount + 1);
      if (posExec) posExec();
    }
  };

  useEffect(() => {
    console.log(queryString.parse(location?.search))
    if (queryString.parse(location?.search)?.createNewTabloid) {
      setOpen({
        ...open,
        createOptions: false,
        createTabloid: true,
        createArtFormat: queryString.parse(location?.search)?.createArtFormat,
        createFormatCanva: queryString.parse(location?.search)?.createFormatCanva
      });
      history.push({
        search: ''
      });
    }
  },[]);

  useEffect(() => {
    if (handleEditTabloidCount > 0) {
      params?.id && getTabloids();
    }
  }, [handleEditTabloidCount]);

  useEffect(() => {
    let timer1;
    setLoading(true);
    if (countSearch === 0) {
      if (queryString.parse(location?.search)?.createNewTabloid) {
      } else {
        getTabloids();
      }
    } else {
      timer1 = setTimeout(() => getTabloids(), 1500);
    }
    setCountSearch(prev => prev + 1);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <>
      <Switch>
        <Route path="/estudio" exact>
          {queryString.parse(location?.search)?.createArtFormat || 
            open?.createArtFormat || 
            open?.createFormatCanva || 
            queryString.parse(location?.search)?.createFormatCanva 
            ? (
            <div style={{ marginTop: '20vh', textAlign: 'center' }}>
              <CircularProgress color="primary" />{' '}
            </div>
          ) : (
            <Redirect to={'/newStudio'}/>
          )}
        </Route>
        <Route path="/estudio/:id">
          {openStudio && (
            <ListaOfertasEncarte
              loadingGetTabloids={loading}
              onOpen={() => setLoading(true)}
              tabloid={tabloid}
              getTabloids={getTabloids}
              onSave={handleEditTabloid}
              EditTabloid={props => (
                <EditTabloid
                  tabloid={tabloid}
                  productSelected={props.productSelected}
                  onSave={(data, banners) => {
                    handleEditTabloid(data, banners, props.posExec);
                    props.closeBox();
                  }}
                  onClose={() => props.closeBox()}
                  getTabloids={getTabloids}
                  {...props}
                />
              )}
            />
          )}
          {!openStudio && loading && (
            <div style={{ marginTop: '20vh', textAlign: 'center' }}>
              <CircularProgress color="primary" />{' '}
            </div>
          )}
          {!openStudio && !loading && tabloidIsEmpty && (
            <div style={{ marginTop: '20vh', textAlign: 'center' }}>
              {'Propaganda não encontrada!'}
            </div>
          )}
        </Route>
      </Switch>
          
      {open.createTabloid && (
        <CreateTabloid
          open={false} //open.createTabloid
          onSave={handleCreateTabloid}
          onClose={() => setOpen({ ...open, createTabloid: false })}
        />
      )}
    </>
  );
}

export default Tabloid;

