import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BarChart } from '../../../../../../utils/components';
import {
  AMBIENTE,
  DATASALES_CRM_API
} from '../../../../../../utils/helpers/Constants';
import api from '../../../../../../utils/helpers/Fetch';
import useStyles from './styles';

function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

function Overview({ sku }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment());

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api(
        `${DATASALES_CRM_API}promotional-intelligence-p1-${AMBIENTE}/product/overview/${sku}`,
        {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        }
      );

      setData(response.content);

      setLoading(false);
    })();
  }, [startDate, endDate]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Filtro" />
          <Divider />
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>Filtrar por período</Typography>
              </Grid>
              <Grid item container spacing={2} xs={6}>
                <Grid item>
                  <DatePicker
                    label="De"
                    format="DD/MM/YYYY"
                    animateYearScrolling
                    inputVariant="outlined"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label="Até"
                    format="DD/MM/YYYY"
                    animateYearScrolling
                    inputVariant="outlined"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title="Loja" />
          <Divider />
          <CardContent>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress color="primary" size={40} />
              </div>
            ) : (
              <BarChart
                barLegend
                randomFill
                stacked={[
                  // 'Faturamento',
                  'QTDVENDA',
                  // 'Margem %',
                  // 'Margem R$',
                ]}
                xDataKey="NOME_LOJA"
                width="99%"
                // tooltipFormatter="Total por categprias"
                data={data || []}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Overview;
