import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Radio, RadioGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import AlertDialog from '../../../../utils/components/AlertDialog';

const EditarInstanciaDialog = ({
  open,
  onClose,
  onSave,
  onDelete,
  instancia,
  lojas,
}) => {
  const [instanciaOut, setInstanciaOut] = React.useState({});
  const [messageError, setMessageError] = React.useState({});
  const [deleteInstance, setDeleteInstance] = React.useState(null);

  let haveChatPermission =
    JSON.parse(localStorage.getItem('permissions'))?.relacionamento?.findIndex(
      i => i.serviceId === 53
    ) >= 0;

  React.useEffect(() => {
    setInstanciaOut({ ...instancia });
  }, [open, instancia]);

  const clearErrors = nome => {
    if (nome) {
      if (messageError[nome]) {
        delete messageError[nome];
        setMessageError({ ...messageError });
      }
    } else {
      setMessageError({});
    }
  };

  const handleInstancia = (attr, value) => {
    setInstanciaOut({ ...instanciaOut, [attr]: value });
  };

  const validate = () => {
    clearErrors();
    let messages = {};

    if (!instanciaOut.id_loja) {
      messages['loja'] = 'Informe a loja';
    }

    if (!instanciaOut.nome) {
      messages['nome'] = 'Informe o nome';
    }

    // if (!instanciaOut.own_number) {
    //   messages['own_number'] = 'Informe o número do telefone da instância';
    // }

    if (instanciaOut.tipo_instancia === 2) {
      if (!instanciaOut.mensagem_inicial) {
        messages['mensagem_inicial'] = 'Informe a mensagem inicial';
      }

      if (!instanciaOut.mensagem_nao_entendi) {
        messages['mensagem_nao_entendi'] = 'Informe a mensagem de não entendi';
      }

      if (!instanciaOut.resposta_automatica) {
        messages['resposta_automatica'] = 'Informe a mensagem automática';
      }

      if (!instanciaOut.mensagem_final_chat) {
        messages['mensagem_final_chat'] =
          'Informe a mensagem de finalização do chat';
      }

      if (!instanciaOut.minutos_sem_resposta) {
        messages['minutos_sem_resposta'] =
          'Informe os minutos sem resposta autompatica';
      }

      if (instanciaOut.minutos_sem_resposta <= 0) {
        messages['minutos_sem_resposta'] =
          'Os minutos sem resposta precisam ser maior que 0';
      }
    }

    setMessageError({ ...messageError, ...messages });
    return !Object.keys(messages).length;
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle>Configurar instância</DialogTitle>
      <Box pt={1} pb={1}>
        <Divider variant="middle" />
      </Box>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          {Object.keys(messageError).length > 0 && (
            <Grid item xs={12}>
              <span style={{ color: 'red' }}>
                Preencha corretamente os campos destacados
              </span>
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-label="atendimento"
              name="tipo_instancia"
              value={instanciaOut.tipo_instancia}
              onChange={({ target: { value, name } }) => {
                handleInstancia(name, +value);
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Disparo"
              />
              {haveChatPermission && (
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="Disparo + Atendimento"
                />
              )}
            </RadioGroup>
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <TextField
                fullWidth
                name="nome"
                label="Nome"
                variant="outlined"
                value={instanciaOut.nome}
                onChange={e => handleInstancia(e.target.name, e.target.value)}
                error={!!messageError['nome']}
                helperText={messageError['nome']}
                onFocus={() => clearErrors('nome')}
                onBlur={() => validate()}
              />

              <TextField
                select
                fullWidth
                label="Loja"
                variant="outlined"
                value={instanciaOut.id_loja}
                onChange={e => handleInstancia('id_loja', e.target.value)}
                error={!!messageError['id_loja']}
                helperText={messageError['id_loja']}
                onFocus={() => clearErrors('id_loja')}
                onBlur={() => validate()}
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {lojas.map((loja, index) => (
                  <MenuItem value={loja.id_loja} key={index}>
                    {loja.id_loja} - {loja.nome_loja}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                InputProps={{ inputProps: { min: 1 } }}
                name="own_number"
                label="Número de telefone"
                variant="outlined"
                value={instanciaOut.own_number}
                onChange={e => handleInstancia(e.target.name, e.target.value)}
                error={!!messageError['own_number']}
                helperText={messageError['own_number']}
                onFocus={() => clearErrors('own_number')}
                onBlur={() => validate()}
                style={{
                  marginBottom: 20,
                }}
              />
            </div>
          </Grid>

          <Grid item container xs={4} direction="column">
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanciaOut.automatically_activate_contacts}
                    onChange={e =>
                      handleInstancia(
                        'automatically_activate_contacts',
                        e.target.checked
                      )
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Ativar Contatos Automaticamente"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanciaOut.automatically_save_groups}
                    onChange={e =>
                      handleInstancia(
                        'automatically_save_groups',
                        e.target.checked
                      )
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Salvar Grupos Automaticamente"
              />
            </Grid>

            <Grid item>
              <Button
                onClick={() => setDeleteInstance(instanciaOut)}
                color="secondary"
                variant="contained"
                style={{ marginBottom: 20 }}
              >
                Deletar Instância
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: -20 }}>
            <Alert severity="info">
              As configurações serão aplicadas quando o whatsapp for conectado
              novamente
            </Alert>
          </Grid>

          {instanciaOut.tipo_instancia === 2 && (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="7"
                  label="Mensagem inicial"
                  value={instanciaOut.mensagem_inicial}
                  onChange={e =>
                    handleInstancia('mensagem_inicial', e.target.value)
                  }
                  inputProps={{ maxLength: 2000 }}
                  error={!!messageError['mensagem_inicial']}
                  helperText={messageError['mensagem_inicial']}
                  onFocus={() => clearErrors('mensagem_inicial')}
                  onBlur={() => validate()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="7"
                  label="Mensagem não entendi"
                  value={instanciaOut.mensagem_nao_entendi}
                  onChange={e =>
                    handleInstancia('mensagem_nao_entendi', e.target.value)
                  }
                  inputProps={{ maxLength: 2000 }}
                  error={!!messageError['mensagem_nao_entendi']}
                  helperText={messageError['mensagem_nao_entendi']}
                  onFocus={() => clearErrors('mensagem_nao_entendi')}
                  onBlur={() => validate()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="7"
                  label="Resposta Automática"
                  value={instanciaOut.resposta_automatica}
                  onChange={e =>
                    handleInstancia('resposta_automatica', e.target.value)
                  }
                  inputProps={{ maxLength: 2000 }}
                  error={!!messageError['resposta_automatica']}
                  helperText={messageError['resposta_automatica']}
                  onFocus={() => clearErrors('resposta_automatica')}
                  onBlur={() => validate()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="7"
                  label="Mensagem de finalização de chat"
                  value={instanciaOut.mensagem_final_chat}
                  onChange={e =>
                    handleInstancia('mensagem_final_chat', e.target.value)
                  }
                  inputProps={{ maxLength: 2000 }}
                  error={!!messageError['mensagem_final_chat']}
                  helperText={messageError['mensagem_final_chat']}
                  onFocus={() => clearErrors('mensagem_final_chat')}
                  onBlur={() => validate()}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  name="minutos_sem_resposta"
                  label="Minutos sem resposta automática"
                  variant="outlined"
                  value={instanciaOut.minutos_sem_resposta}
                  onChange={e => handleInstancia(e.target.name, e.target.value)}
                  error={!!messageError['minutos_sem_resposta']}
                  helperText={messageError['minutos_sem_resposta']}
                  onFocus={() => clearErrors('minutos_sem_resposta')}
                  onBlur={() => validate()}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="outlined">
          Fechar
        </Button>
        <Button
          onClick={() => validate() && onSave(instanciaOut)}
          color="primary"
          variant="contained"
        >
          Salvar
        </Button>
      </DialogActions>
      {deleteInstance && (
        <AlertDialog
          open={!!deleteInstance}
          title={'Aviso'}
          contentText={
            'Deseja continuar e apagar a instância e dados agregados permanentemente?'
          }
          handleAgree={() => onDelete(deleteInstance)}
          handleClose={() => setDeleteInstance(null)}
          textAgree={'DELETAR'}
          textClose={'Voltar'}
        />
      )}
    </Dialog>
  );
};

EditarInstanciaDialog.propTypes = {
  instancia: PropTypes.object,
  lojas: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

EditarInstanciaDialog.defaultProps = {
  instancia: {},
  lojas: [],
  open: false,
  onClose: () => {},
};

export default EditarInstanciaDialog;

