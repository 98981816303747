import React, { Fragment, useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import PickerEmoji from 'emoji-picker-react';
import { SentimentSatisfiedRounded } from '@material-ui/icons';

export default function MenuEmojis(props) {
  const [anchorEmoji, setAnchorEmoji] = useState(null);
  return (
    <Fragment>
      <Popover
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        anchorEl={anchorEmoji}
        open={Boolean(anchorEmoji)}
        onClose={() => setAnchorEmoji(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PickerEmoji
          onEmojiClick={(_, { emoji }) => props.onChange(emoji)}
          disableAutoFocus
          disableSearchBar
          disableSkinTonePicker
          groupNames={{
            smileys_people: 'Smileys e pessoas',
            animals_nature: 'Animais e natureza',
            food_drink: 'Comidas e bebidas',
            travel_places: 'Viagens e lugares',
            activities: 'Atividades',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Bandeiras',
            recently_used: 'Recentes',
          }}
          pickerStyle={{ boxShadow: '' }}
        />
      </Popover>
      <IconButton
        onClick={({ currentTarget }) => setAnchorEmoji(currentTarget)}
      >
        <SentimentSatisfiedRounded />
      </IconButton>
    </Fragment>
  );
}
