import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme =>
  createStyles({
    content: {
      padding: 0,
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

export default styles;
